import CloseButton from '@baseComponents/buttons/CloseButton';
import { ThemeType } from '@map/services/mapTypes';
import { PropsWithChildren } from 'react';
import { createUseStyles } from 'react-jss';

type ContainerProps = {
    height?: number | string;
    width?: number | string;
}

type ContainerClasses = 'container';

const useContainerStyles = createUseStyles<ContainerClasses, ContainerProps, ThemeType>(theme => ({
    container: {
        position: 'absolute',
        zIndex: 1002,
        width: props => props.width,
        height: props => props.height,
        backgroundColor: theme.color.white,
        borderRadius: 10,
        boxShadow: theme.shadows.map,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
}));

export function LayerModalContainer({ children, height = 560, width = 860 }: PropsWithChildren<ContainerProps>) {
    const classes = useContainerStyles({ height, width });

    return (
        <div className={classes.container}>
            {children}
        </div>
    );
}

type HeaderProps = {
    title: string,
    subTitle?: string,
    close: () => void,
}

type HeaderClasses = 'container' | 'title' | 'subTitle' | 'textSection';

const useHeaderStyles = createUseStyles<HeaderClasses, unknown, ThemeType>(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: [20, 30],
    },
    title: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontFamily: 'Rubik',
    },
    subTitle: {
        fontSize: 18,
        color: theme.color.stone,
        fontWeight: 500,
        fontStyle: 'normal',
        fontFamily: 'Rubik',
        margin: 0,
    },
    textSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
    },
}));

export function LayerModalHeader({ title, subTitle, close, children }: PropsWithChildren<HeaderProps>) {
    const classes = useHeaderStyles();

    return (
        <div className={classes.container}>
            <div className={classes.textSection}>
                <h1 className={classes.title}>{title}</h1>
                {subTitle && <h2 className={classes.subTitle}>{`(${subTitle})`}</h2>}
            </div>
            {children}
            <CloseButton onClick={() => close()} />
        </div>
    );
}

type ContentClasses = 'contentSection';

type ContentProps = {
    height?: number | string;
    backgroundColor?: 'white' | 'gray';
    yPadding?: number;
    xPadding?: number;
}

const useContentClasses = createUseStyles<ContentClasses, ContentProps, ThemeType>(theme => ({
    contentSection: {
        width: '100%',
        height: props => props.height,
        backgroundColor: props => (props.backgroundColor === 'white' ? theme.color.white : theme.color.fog),
        padding: props => `${props.yPadding}px ${props.xPadding}px`,
        overflow: 'auto',
    },
}));

export function LayerModalContent({ children, backgroundColor = 'white', height = 450, yPadding = 20, xPadding = 30 }: PropsWithChildren<ContentProps>) {
    const classes = useContentClasses({ height, backgroundColor, yPadding, xPadding });

    return (
        <div className={classes.contentSection}>
            {children}
        </div>
    );
}

type BottomBarClasses = 'buttonRowContainer';

const useStyles = createUseStyles<BottomBarClasses, TProps, ThemeType>(theme => ({
    buttonRowContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: props => props.justifyContent,
        padding: [15, 30],
    },
}));

type TProps = {
    justifyContent?: string,
}

export function LayerModalBottomBar({ children, justifyContent = 'space-between' }: PropsWithChildren<TProps>) {
    const classes = useStyles({ justifyContent });

    return (
        <div className={classes.buttonRowContainer}>
            {children}
        </div>
    );
}
