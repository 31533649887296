import { isEmpty } from 'lodash';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    menuIconContainer: {
        lineHeight: '44px',
        marginLeft: (props: any) => !props.hasPreIcon && (!props.expanded ? 15 : 20),
    },
    menuIcon: {
        verticalAlign: 'middle',
        height: 30,
        width: 30,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
    },
    menuPreIcon: {
        display: 'inline-block',
        boxSizing: 'border-box',
        height: 10,
        width: 10,
        borderRadius: 6,
        marginLeft: 5,
        marginRight: (props: any) => props.expanded && 5,
        border: (props: any) => {
            if (props.hasSeparatedBackground && !props.isPlanningMode) {
                return `3px solid ${theme.color.beta}`;
            }
            if (props.style && props.style.hoverBackground) {
                return `3px solid ${props.style.hoverBackground}`;
            }
            if (props.hasSubscriptionBackground) {
                return `3px solid ${theme.color.subscriptionBlue}`;
            }
        },
    },
}));

export default function SideNavBarItemOldImage(props) {
    const { menuIcon, menuTitle, hasPreIcon, hasSeparatedBackground, hasSubscriptionBackground, style, expanded, isPlanningMode } = props;
    const classes = useStyles({ isPlanningMode, menuIcon, expanded, hasSubscriptionBackground, hasSeparatedBackground, style, hasPreIcon: checkHasPreIcon() });

    function checkHasPreIcon() {
        return hasPreIcon && (hasSeparatedBackground || hasSubscriptionBackground || (style && style.hoverBackground));
    }

    return (
        <>
            {!isEmpty(menuIcon) && (
                <div title={menuTitle} className={classes.menuIconContainer}>
                    {checkHasPreIcon() && <div className={classes.menuPreIcon} />}
                    <img alt="" src={`${menuIcon}.svg`} className={classes.menuIcon} />
                </div>
            )}
        </>
    );
}
