import { errorActions } from '../actions/errorActions';

const initialState = {
    errorMessage: null,
    errorCode: null,
};

export function error(state = initialState, action) {
    switch (action.type) {
        case errorActions.SET_ERROR:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                errorCode: action.payload.errorCode,
            };
        case errorActions.SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
            };
        case errorActions.REMOVE_ERROR:
            return {
                ...state,
                errorMessage: null,
                errorCode: null,
            };
        case errorActions.SET_ERROR_CODE:
            return {
                ...state,
                errorCode: action.payload.errorCode,
            };
        default:
            return state;
    }
}
