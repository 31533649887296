import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { PARTNER } from 'shared/src/constants';
import { isFunction } from 'lodash';

import Radio from '@baseComponents/controls/Radio';

const useStyles = createUseStyles({
    partnerTypeLabel: {
        marginTop: 0,
        marginBottom: 0,
        marginRight: 15,
        fontFamily: 'Rubik',
        fontWeight: 'normal',
    },
    partnerTypeMargin: {
        marginRight: 15,
        marginBottom: 0,
        fontSize: 13,
    },
});

export default function PartnerTypeRadio(props) {
    const { partnerTypeId, changePartnerType } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    function onChange(event) {
        if (isFunction(changePartnerType)) {
            const type = +event.target.value;
            changePartnerType(type);
        }
    }

    return (
        <>
            <h4 className={classes.partnerTypeLabel}>{t('partnerTableFilter.partnerType', 'Partner type')}</h4>
            <Radio
                group="partnersModalPartnerType"
                text={t('partnerTableFilter.company', 'Company')}
                value={PARTNER.TYPES.COMPANY}
                checked={partnerTypeId === PARTNER.TYPES.COMPANY}
                onChange={onChange}
                className={classes.partnerTypeMargin}
            />
            <Radio
                group="partnersModalPartnerType"
                text={t('partnerTableFilter.partner', 'Partner')}
                value={PARTNER.TYPES.PARTNER}
                checked={partnerTypeId === PARTNER.TYPES.PARTNER}
                onChange={onChange}
                className={classes.partnerTypeMargin}
            />
            <Radio
                group="partnersModalPartnerType"
                text={t('default.both', 'Both')}
                value={PARTNER.TYPES.PARTNER_AND_COMPANY}
                checked={partnerTypeId === PARTNER.TYPES.PARTNER_AND_COMPANY}
                onChange={onChange}
                className={classes.partnerTypeMargin}
            />
        </>
    );
}
