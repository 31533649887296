import React, { useState, useCallback } from 'react';
import { isNull, isUndefined } from 'lodash';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { useNumberFormat } from '@common_modules';

import Svg from '@baseComponents/Svg';
import { UnitInput } from '@baseComponents/inputs/inputField';

const useStyles = createUseStyles((theme: any) => ({
    supportCard: {
        width: '100%',
        height: 93,
        borderRadius: 4,
        border: '1px solid',
        borderColor: theme.color.pearl,
        backgroundColor: theme.color.lilian,
        display: 'flex',
    },
    cardLeft: {
        padding: '10px 15px',
        '& h3': {
            fontFamily: 'Rubik',
            margin: 0,
        },
    },
    cardRight: {
        width: 54,
        marginLeft: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderLeft: '1px solid',
        borderLeftColor: theme.color.pearl,
        outline: 'none',
        cursor: 'pointer',
    },
    icon: {
        height: 24,
        width: 24,
        fill: theme.color.destructive,
    },
    inputContainer: {
        display: 'flex',
        marginTop: 10,
    },
    input: {
        width: 110,
        marginRight: 15,
    },
}));

export default function SupportCard(props) {
    const { support, setValue, deleteItem, className, supportList } = props;
    const classes = useStyles();
    const { numberFormatter } = useNumberFormat();
    const [supportArea, setSupportArea] = useState(support.area);

    const handleChangeArea = useCallback(
        (_event, value) => {
            const name = 'area';
            const numberValue = numberFormatter.getValueFromString(value);
            setValue(numberValue, name);
            setSupportArea(numberValue);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [supportList, support],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const areaFormat = useCallback(() => numberFormatter.getAreaFormats(), [numberFormatter.isInitialized]);

    function deleteSupport() {
        deleteItem(support);
    }

    return (
        <div className={clsx(classes.supportCard, className)}>
            <div className={classes.cardLeft}>
                <h3>{support.support.shortName}</h3>
                <div className={classes.inputContainer}>
                    <UnitInput
                        type="number"
                        defaultValue={support.area}
                        onBlur={handleChangeArea}
                        numberFormat={areaFormat()}
                        className={classes.input}
                        positiveOnly
                        isInvalid={isNull(supportArea) || isUndefined(supportArea)}
                    />
                </div>
            </div>
            <div className={classes.cardRight} onClick={deleteSupport} onKeyDown={deleteSupport} role="button" tabIndex={0}>
                <Svg iconId="icon-bin" style={classes.icon} />
            </div>
        </div>
    );
}
