export const USER = {
    MODULE_ID: 19,
    NAME: 'user',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'user',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Agrovir',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        USERS: {
            CONTENT_URL_ID: { users: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'users',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.users',
                TRANSLATOR_DEFAULT: 'Users',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        GROUPS: {
            CONTENT_URL_ID: { groups: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'userGroups',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.userGroups',
                TRANSLATOR_DEFAULT: 'User groups',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        GROUP_RIGHTS: {
            CONTENT_URL_ID: { groupRights: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'userGroupRights',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.userGroupRights',
                TRANSLATOR_DEFAULT: 'User group rights',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        ACTIVITY: {
            CONTENT_URL_ID: { activity: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'userActivity',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.userActivity',
                TRANSLATOR_DEFAULT: 'User activity',
            },
            SHOW_IN_PANNING_MODE: true,
        },
    },
};
