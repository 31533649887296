import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    modalBody: {
        width: '100%',
        height: '100%',
        background: theme.color.lilian,
        paddingLeft: 30,
        paddingRight: 30,
        position: 'relative',
    },
}));

export default function ModalBody(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.modalBody, className)}>
            {children}
        </div>
    );
}
