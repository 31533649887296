import { isBoolean, isEmpty } from 'lodash';
import { simpleAction } from './baseActions';

const simpleTableActions = {
    initSimpleTable,
    resetSimpleTable,
    setColumnWidths,
    setAggregateType,
    addSelectedIds,
    setSelectedIds,
    removeSelectedIds,
    removeAllSelectedId,
    selectAllIds,
    setTableEnabledSearching,
    setTableGlobalSearchValue,
    setTableGlobalColumnSearchValues,
    addTableGlobalColumnSearchValue,
    removeTableGlobalColumnSearchValue,
    addTableGlobalColumnSortingType,
    removeTableGlobalColumnSortingType,
    setTableFilteredDatas,
    removeTableFilteredDatas,
    setTableData,
    getTableDataList,
    removeRowFromTableDataById,
    addRowToTableData,
    updateDomTableData,
    getSelectedRowIds,
    setTableMultilineView,
    getTableMultilineView,
    INIT_SIMPLE_TABLE: 'SIMPLE_TABLE_INIT_SIMPLE_TABLE',
    RESET_SIMPLE_TABLE: 'SIMPLE_TABLE_RESET_SIMPLE_TABLE',
    SET_COLUMN_WIDTHS: 'SIMPLE_TABLE_SET_COLUMN_WIDTHS',
    SET_AGGREGATE_TYPE: 'SIMPLE_TABLE_SET_AGGREGATE_TYPE',
    ADD_SELECTED_IDS: 'SIMPLE_TABLE_ADD_SELECTED_IDS',
    REMOVE_SELECTED_IDS: 'SIMPLE_TABLE_REMOVE_SELECTED_IDS',
    SET_SELECTED_IDS: 'SIMPLE_TABLE_SET_SELECTED_IDS',
    REMOVE_ALL_SELECTED_ID: 'SIMPLE_TABLE_REMOVE_ALL_SELECTED_ID',
    SELECT_ALL_IDS: 'SIMPLE_TABLE_SELECT_ALL_IDS',
    SET_TABLE_ENABLED_SEARCHING: 'SIMPLE_TABLE_SET_TABLE_ENABLED_SEARCHING',
    SET_TABLE_GLOBAL_SEARCH_VALUE: 'SIMPLE_TABLE_SET_TABLE_GLOBAL_SEARCH_VALUE',
    SET_TABLE_GLOBAL_COLUMN_SEARCH_VALUES: 'SIMPLE_TABLE_SET_TABLE_GLOBAL_COLUMN_SEARCH_VALUES',
    ADD_TABLE_GLOBAL_COLUMN_SEARCH_VALUE: 'SIMPLE_TABLE_ADD_TABLE_GLOBAL_COLUMN_SEARCH_VALUE',
    REMOVE_TABLE_GLOBAL_COLUMN_SEARCH_VALUE: 'SIMPLE_TABLE_REMOVE_TABLE_GLOBAL_COLUMN_SEARCH_VALUE',
    ADD_TABLE_GLOBAL_COLUMN_SORTING_TYPE: 'SIMPLE_TABLE_ADD_TABLE_GLOBAL_COLUMN_SORTING_TYPE',
    REMOVE_TABLE_GLOBAL_COLUMN_SORTING_TYPE: 'SIMPLE_TABLE_REMOVE_TABLE_GLOBAL_COLUMN_SORTING_TYPE',
    SET_TABLE_FILTERED_DATAS: 'SIMPLE_TABLE_SET_TABLE_FILTERED_DATAS',
    REMOVE_TABLE_FILTERED_DATAS: 'SIMPLE_TABLE_REMOVE_TABLE_FILTERED_DATAS',
    SET_TABLE_DATA: 'SIMPLE_TABLE_SET_TABLE_DATA',
    REMOVE_TABLE_DATA_ROW_BY_ID: 'SIMPLE_TABLE_REMOVE_TABLE_DATA_ROW_BY_ID',
    UPDATE_DOM_TABLE_DATA: 'SIMPLE_TABLE_UPDATE_DOM_TABLE_DATA',
    ADD_TABLE_DATA_ROW: 'SIMPLE_TABLE_ADD_TABLE_DATA_ROW',
    SET_TABLE_MULTILINE_VIEW: 'SIMPLE_TABLE_SET_TABLE_MULTILINE_VIEW',
    GET_TABLE_MULTILINE_VIEW: 'SIMPLE_TABLE_GET_TABLE_MULTILINE_VIEW',
};

export { simpleTableActions };

function initSimpleTable(tableKey, columns, tableData, tableGroup) {
    return simpleAction(simpleTableActions.INIT_SIMPLE_TABLE, { tableKey, columns, tableData, tableGroup });
}

function resetSimpleTable(tableKey) {
    return simpleAction(simpleTableActions.RESET_SIMPLE_TABLE, { tableKey });
}

function setColumnWidths(tableKey, columnWidths) {
    return simpleAction(simpleTableActions.SET_COLUMN_WIDTHS, { tableKey, columnWidths });
}

function setAggregateType(tableKey, aggregateType) {
    return simpleAction(simpleTableActions.SET_AGGREGATE_TYPE, { tableKey, aggregateType });
}

function addSelectedIds(tableKey, selectedIds) {
    return simpleAction(simpleTableActions.ADD_SELECTED_IDS, { tableKey, selectedIds });
}

function removeSelectedIds(tableKey, selectedIds) {
    return simpleAction(simpleTableActions.REMOVE_SELECTED_IDS, { tableKey, selectedIds });
}

function setSelectedIds(tableKey, selectedIds) {
    return simpleAction(simpleTableActions.SET_SELECTED_IDS, { tableKey, selectedIds });
}

function removeAllSelectedId(tableKey) {
    return simpleAction(simpleTableActions.REMOVE_ALL_SELECTED_ID, { tableKey });
}

function selectAllIds(tableKey) {
    return simpleAction(simpleTableActions.SELECT_ALL_IDS, { tableKey });
}

function setTableEnabledSearching(tableKey, isEnabledSearching) {
    return (dispatch, getState) => {
        const { simpleTable } = getState();
        const simpleTableSearchingState = simpleTable.table[tableKey] ? !simpleTable.table[tableKey].isEnabledSearching : true;
        const selectionState = isBoolean(isEnabledSearching) ? isEnabledSearching : simpleTableSearchingState;
        dispatch(simpleAction(simpleTableActions.SET_TABLE_ENABLED_SEARCHING, { tableKey, isEnabledSearching: selectionState }));
    };
}

function setTableGlobalSearchValue(tableKey, globalSearchValue) {
    return simpleAction(simpleTableActions.SET_TABLE_GLOBAL_SEARCH_VALUE, { tableKey, globalSearchValue });
}

function setTableGlobalColumnSearchValues(tableKey, globalColumnSearchValues) {
    return simpleAction(simpleTableActions.SET_TABLE_GLOBAL_COLUMN_SEARCH_VALUES, { tableKey, globalColumnSearchValues });
}

function addTableGlobalColumnSearchValue(tableKey, columnId, searchValue) {
    return simpleAction(simpleTableActions.ADD_TABLE_GLOBAL_COLUMN_SEARCH_VALUE, { tableKey, columnId, searchValue });
}

function removeTableGlobalColumnSearchValue(tableKey, columnId) {
    return simpleAction(simpleTableActions.REMOVE_TABLE_GLOBAL_COLUMN_SEARCH_VALUE, { tableKey, columnId });
}

function addTableGlobalColumnSortingType(tableKey, columnId, sortingType) {
    return simpleAction(simpleTableActions.ADD_TABLE_GLOBAL_COLUMN_SORTING_TYPE, { tableKey, columnId, sortingType });
}

function removeTableGlobalColumnSortingType(tableKey, columnId) {
    return simpleAction(simpleTableActions.REMOVE_TABLE_GLOBAL_COLUMN_SORTING_TYPE, { tableKey, columnId });
}

function setTableFilteredDatas(tableKey, filteredDatas) {
    return simpleAction(simpleTableActions.SET_TABLE_FILTERED_DATAS, { tableKey, filteredDatas });
}

function removeTableFilteredDatas(tableKey) {
    return simpleAction(simpleTableActions.BASE_PAGE_REMOVE_TABLE_FILTERED_DATAS, { tableKey });
}

function setTableData(tableKey, tableData) {
    return simpleAction(simpleTableActions.SET_TABLE_DATA, { tableKey, tableData });
}

function getTableDataList(tableKey) {
    return (__, getState) => {
        if (isEmpty(tableKey)) {
            return null;
        }

        const { simpleTable } = getState();
        return simpleTable.table[tableKey]?.tableData;
    };
}

function removeRowFromTableDataById(tableKey, rowId, rowIdName) {
    if (tableKey && rowId) {
        return simpleAction(simpleTableActions.REMOVE_TABLE_DATA_ROW_BY_ID, { tableKey, rowId, rowIdName });
    }
    return null;
}

function addRowToTableData(tableKey, rowData) {
    if (tableKey && rowData) {
        return simpleAction(simpleTableActions.ADD_TABLE_DATA_ROW, { tableKey, rowData });
    }
    return null;
}

function updateDomTableData(tableKey, isUpdateDomTable) {
    if (tableKey) {
        return (dispatch, getState) => {
            const { simpleTable } = getState();
            const simpleTableIsUpdateDomTableState = simpleTable.table[tableKey] ? !simpleTable.table[tableKey].isUpdateDomTable : true;
            const selectionState = isBoolean(isUpdateDomTable) ? isUpdateDomTable : simpleTableIsUpdateDomTableState;
            dispatch(simpleAction(simpleTableActions.UPDATE_DOM_TABLE_DATA, { tableKey, isUpdateDomTable: selectionState }));
        };
    }
    return null;
}

function getSelectedRowIds(tableKey) {
    if (tableKey) {
        return (__, getState) => {
            const { simpleTable } = getState();
            return simpleTable.table[tableKey]?.selectedItemIds;
        };
    }
    return null;
}

function setTableMultilineView(multilineViewType, tableKey) {
    return simpleAction(simpleTableActions.SET_TABLE_MULTILINE_VIEW, { multilineViewType, tableKey });
}

function getTableMultilineView(tableKey) {
    return (__, getState) => {
        if (isEmpty(tableKey)) {
            return null;
        }

        const { simpleTable } = getState();
        return simpleTable.table[tableKey]?.multilineViewType;
    };
}
