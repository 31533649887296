import { RIGHTS, SYSTEM } from '../constants';

export const LIVESTOCK_FEEDING = {
    MODULE_ID: 38,
    NAME: 'livestockFeeding',
    EXCLUDE_SYSTEM_MODULES: [
        SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
        SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD,
        SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
        SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
    ],
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockFeeding',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.livestockFeeding',
                TRANSLATOR_DEFAULT: 'Feeding',
            },
        },
        RECIPES: {
            CONTENT_URL_ID: { recipes: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'recipes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.recipes',
                TRANSLATOR_DEFAULT: 'Recipes',
            },
            RIGHTS: [RIGHTS.RECIPES_INTERFACE],
        },
        RECIPES_EDIT: {
            CONTENT_URL_ID: { recipesEdit: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'recipesEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.recipesEdit',
                TRANSLATOR_DEFAULT: 'Recipe edit',
            },
            RIGHTS: [RIGHTS.RECIPES_EDIT_INTERFACE],
        },
        FEEDING_PLANS: {
            CONTENT_URL_ID: { feedingPlans: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'feedingPlans',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.feedingPlans',
                TRANSLATOR_DEFAULT: 'Feeding plans',
            },
            RIGHTS: [RIGHTS.FEEDING_PLANS_INTERFACE],
        },
        FEEDING_REPORTS: {
            CONTENT_URL_ID: { feedingReports: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'feedingReports',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.feedingReports',
                TRANSLATOR_DEFAULT: 'Feeding reports',
            },
            RIGHTS: [RIGHTS.FEEDING_REPORTS_INTERFACE],
        },
        FEEDING_REPORTS_EDIT: {
            CONTENT_URL_ID: { feedingReportsEdit: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'feedingReportsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.feedingReportsEdit',
                TRANSLATOR_DEFAULT: 'Feeding report edit',
            },
            RIGHTS: [RIGHTS.FEEDING_REPORTS_INTERFACE],
        },
        FEEDING_REPORT_SETS: {
            CONTENT_URL_ID: { feedingReportSets: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'feedingReportSets',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.feedingReportSets',
                TRANSLATOR_DEFAULT: 'Feeding report sets',
            },
            RIGHTS: [RIGHTS.FEEDING_REPORTS_INTERFACE],
        },
    },
};
