import { arrayUtils } from 'shared/src/modules';
import { companyActions } from '../actions/companyActions';

const initialState = {
    company: {
        settings: null,
        currencies: null,
        globalData: {
            companySubscriptionPackage: null,
        },
        currency: {
            isVisibleCurrencySwitch: false,
            isSelectedCurrencySwitch: null, //currency switch selected value
            currentCurrency: { //selectedCurrencyData
                id: null,
                text: null,
                decimalNumber: 0,
                fullText: null,
            },
            primaryCurrency: {
                id: null,
                text: null,
                decimalNumber: 0,
                fullText: null,
            },
            secondaryCurrency: {
                id: null,
                text: null,
                decimalNumber: 0,
                fullText: null,
            },
        },
        map: {
            googleMapApiKey: null,
            googleMapScriptUrl: null,
            serverUrl: null,
        },
        numberFormatSettings: null,
    },
    activeCompanyList: null,
    activeCompanyIds: null,
};

export function company(state = initialState, action) {
    switch (action.type) {
        case companyActions.SET_IS_SELECTED_CURRENCRY_SWITCH: {
            if (action.payload.isSelectedCurrencySwitch) {
                return {
                    ...state,
                    company: {
                        ...state.company,
                        currency: {
                            ...state.company.currency,
                            isSelectedCurrencySwitch: action.payload.isSelectedCurrencySwitch,
                            currentCurrency: state.company.currency.secondaryCurrency,
                        },
                    },
                };
            }
            return {
                ...state,
                company: {
                    ...state.company,
                    currency: {
                        ...state.company.currency,
                        isSelectedCurrencySwitch: action.payload.isSelectedCurrencySwitch,
                        currentCurrency: state.company.currency.primaryCurrency,
                    },
                },
            };
        }
        case companyActions.SET_CURRENT_CURRENCRY: {
            return {
                ...state,
                company: {
                    ...state.company,
                    currency: {
                        ...state.company.currency,
                        currentCurrency: action.payload.currentCurrency,
                    },
                },
            };
        }
        case companyActions.SET_PRIMARY_CURRENCY: {
            return {
                ...state,
                company: {
                    ...state.company,
                    currency: {
                        ...state.company.currency,
                        primaryCurrency: action.payload.primaryCurrency,
                    },
                },
            };
        }
        case companyActions.SET_SECONDARY_CURRENCY: {
            return {
                ...state,
                company: {
                    ...state.company,
                    currency: {
                        ...state.company.currency,
                        secondaryCurrency: action.payload.secondaryCurrency,
                    },
                },
            };
        }
        case companyActions.SET_COMPANY:
            return {
                ...state,
                company: {
                    currency: state.company.currency,
                    ...action.payload.company,
                },
            };
        case companyActions.SET_SETTINGS:
            return {
                ...state,
                settings: action.payload.settings,
            };
        case companyActions.SET_NUMBER_FORMAT_SETTINGS:
            return {
                ...state,
                company: {
                    ...state.company,
                    numberFormatSettings: action.payload.numberFormatSettings,
                },
            };
        case companyActions.SET_ACTIVE_COMPANY_LIST:
            return {
                ...state,
                activeCompanyList: action.payload.activeCompanyList,
                activeCompanyIds: arrayUtils.getValuesByKey(action.payload.activeCompanyList, 'checked', 'id'),
            };
        case companyActions.DELETE_ACTIVE_COMPANY_LIST:
            return {
                ...state,
                activeCompanyList: null,
                activeCompanyIds: null,
            };
        case companyActions.SET_COMPANY_LIST:
            return {
                ...state,
                company: { ...state.company, companyList: action.payload.companyList },
            };
        case companyActions.SET_CURRENCY_VISIBILITY:
            return {
                ...state,
                company: {
                    ...state.company,
                    currency: {
                        ...state.company.currency,
                        isVisibleCurrencySwitch: action.payload.isVisibleCurrencySwitch,
                    },
                },
            };
        default:
            return state;
    }
}
