import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    firstLine: {
        fontWeight: '500',
        color: 'red',
    },
    secondLine: {
        fontWeight: 'normal',
        color: 'blue',
        whiteSpace: 'nowrap',
    },
});

export default function DateAndCost(date, cost) {
    const simpleTooltipClasses = useStyles();

    return (
        <>
            <div className={simpleTooltipClasses.firstLine}>
                {date}
            </div>
            <div className={simpleTooltipClasses.secondLine}>
                {cost}
            </div>
        </>
    );
}
