import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        height: 18,
        fontSize: 12,
        lineHeight: 1.5,
        color: theme.color.jet,
        textAlign: 'right',
        ...theme.overFlowText,
    },
}));

export default function CostCell(props) {
    const { value, style } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.baseStyle, style)}>
            {value}
        </div>
    );
}
