import { ModalTemplate } from '@baseComponents/modals';
import MapScreenContainer from '@map/screens/Map/MapScreenContainer';
import { BuiltInLayerTypes } from '@map/services/mapEnums';

export const GeomertySelectorContainer = (props: any) => {
    const { hideModal, entityIds, selectedEntityIds, onSelect, layerId } = props;

    const onGeometrySelector = (selectedIds: number[]) => {
        if (onSelect) {
            onSelect({ selectedIds });
            hideModal();
        }
    };

    return (
        <ModalTemplate hideModal={hideModal}>
            <MapScreenContainer
                defaultMapFilter={{ productionYearId: -1 }}
                filterEntityIds={entityIds}
                withCoordinates
                layerType={layerId}
                withSelector
                layerPanelOptions={{
                    withLayerPanel: true,
                    islayerPanelEnabledDefault: true,
                    isDefaultLayerTypeMandatory: true,
                    isFileUploadAllowed: false,
                }}
                onGeometrySelector={onGeometrySelector}
                selectorDefaultEntityIds={selectedEntityIds}
                withMapStyleModifier
            />
        </ModalTemplate>
    );
};
