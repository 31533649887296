import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

async function getCompany() {
    const response = await sendServerRequest('GET', '/api/company');
    return response;
}

async function getAgent(agentName) {
    const response = await sendServerRequest('GET', `/api/company/agent/${agentName}`);
    return response;
}

async function getCompanyByAgent(agentName) {
    const response = await sendServerRequest('GET', `/api/company/companyByAgent/${agentName}`);
    return response;
}

async function getSettings() {
    const response = await sendServerRequest('GET', '/api/company/settings');
    return response;
}

async function getNumberFormatSettings() {
    const response = await sendServerRequest('GET', '/api/company/numberFormatSettings');
    return response;
}

/**
 * Cég és cégkerület listát adja vissza (modules\ceg\control\get_ceg_lista.php)
 * @param modul -
 * 'warehouse' - raktár
 *
 * default: undefined
 * @returns dataList - cég, cégkerület lista
 */
async function getCompanyList(props) {
    const url = routerHelper.getUrlWithParameters('/api/company/companyList', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

export const companyService = {
    getCompany,
    getAgent,
    getCompanyByAgent,
    getSettings,
    getNumberFormatSettings,
    getCompanyList,
};
