/* eslint-disable no-console */
import { useEffect, useState } from 'react';
import { translationService } from '@services';
import i18n from './i18n';
import i18NextOptions from './i18NextOptions';

export default function useTranslater() {
    const [hasTranslaterError, setHasTranslaterError] = useState<boolean>();

    useEffect(() => {
        async function initTranslater() {
            try {
                const versions = await translationService.getVersions();

                const translaterOptions = i18NextOptions as any;
                if (versions) {
                    translaterOptions.backend.backendOptions[0].versions = versions;

                    i18n.init(translaterOptions, error => {
                        if (error) {
                            setHasTranslaterError(true);
                            console.error(error);
                        } else {
                            setHasTranslaterError(false);
                        }
                    });
                } else {
                    i18n.isInitialized = true;
                    setHasTranslaterError(true);
                    console.error('i18n version not found!');
                }
            } catch (error) {
                i18n.isInitialized = true;
                setHasTranslaterError(false);
                console.error(error);
            }
        }

        initTranslater();
    }, []);

    return {
        hasTranslaterError,
        i18n,
    };
}
