import { RIGHTS, SETTINGS, ACCOUNTING_SYSTEM, COUNTRY } from '../constants';

export const CONTROLLING = {
    MODULE_ID: 12,
    NAME: 'controlling',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'controlling',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.controlling',
                TRANSLATOR_DEFAULT: 'Controlling',
            },
        },
        VALUE_ORDER: {
            CONTENT_URL_ID: { valueOrder: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'valueOrder',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.valueOrder',
                TRANSLATOR_DEFAULT: 'Value order',
            },
            RIGHTS: [RIGHTS.VALUE_ORDER_INTERFACE],
        },
        STOCK_TRANSFER: {
            CONTENT_URL_ID: { stockTransfer: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockTransfer',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockTransfer',
                TRANSLATOR_DEFAULT: 'Stock transfer',
            },
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
            SETTINGS: [
                {[SETTINGS.ACCOUNTING_SYSTEM]: [
                    ACCOUNTING_SYSTEM.TYPE.DIRECT_ACCOUNTING,
                    ACCOUNTING_SYSTEM.TYPE.MANAGEMENT_SYSTEM,
                    ACCOUNTING_SYSTEM.TYPE.WINMENTOR,
                    ACCOUNTING_SYSTEM.TYPE.COBRA,
                    ACCOUNTING_SYSTEM.TYPE.AX,
                    ACCOUNTING_SYSTEM.TYPE.NAVISION,
                    ACCOUNTING_SYSTEM.TYPE.FAP,
                    ACCOUNTING_SYSTEM.TYPE.KRAFT,
                    ACCOUNTING_SYSTEM.TYPE.OTHER,
                ]},
                {[SETTINGS.STOCK_MOVEMENT_AUTO_DISPATCH]: [0]}
            ],
        },
        AUXILIARY_OPERATION_RECORDING: {
            CONTENT_URL_ID: { auxiliaryOperationRecording: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'auxiliaryOperationRecording',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.auxiliaryOperationRecording',
                TRANSLATOR_DEFAULT: 'Auxiliary operation recording',
            },
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
            SETTINGS: [
                {[SETTINGS.ACCOUNTING_SYSTEM]: [
                    ACCOUNTING_SYSTEM.TYPE.DIRECT_ACCOUNTING,
                    ACCOUNTING_SYSTEM.TYPE.MANAGEMENT_SYSTEM,
                    ACCOUNTING_SYSTEM.TYPE.WINMENTOR,
                    ACCOUNTING_SYSTEM.TYPE.COBRA,
                    ACCOUNTING_SYSTEM.TYPE.AX,
                    ACCOUNTING_SYSTEM.TYPE.NAVISION,
                    ACCOUNTING_SYSTEM.TYPE.FAP,
                    ACCOUNTING_SYSTEM.TYPE.KRAFT,
                    ACCOUNTING_SYSTEM.TYPE.OTHER,
                ]},
            ],
        },
        TREATMENT_OF_NOT_CATEGORIZED_WORK_OPERATION: {
            CONTENT_URL_ID: { treatmentOfNotCategorizedWorkOperations: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'treatmentOfNotCategorizedWorkOperations',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.treatmentOfNotCategorizedWorkOperations',
                TRANSLATOR_DEFAULT: 'Treatment of not categorized work operations',
            },
            RIGHTS: [RIGHTS.TREATMENT_OF_NOT_CATEGORIZED_WORK_OPERATION_INTERFACE],
        },
        GROUP_DATA: {
            CONTENT_URL_ID: { groupData: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'groupData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.groupData',
                TRANSLATOR_DEFAULT: 'Group data',
            },
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
            SHOW_IN_PANNING_MODE: true,
        },
        TYPES_OF_MOVEMENTS: {
            CONTENT_URL_ID: { typesOfMovements: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'typesOfMovements',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.typesOfMovements',
                TRANSLATOR_DEFAULT: 'Types of movements',
            },
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
            SHOW_IN_PANNING_MODE: true,
        },
        VALUE_ORDER_EDIT: {
            CONTENT_URL_ID: { valueOrderEdit: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'valueOrderEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.valueOrderEdit',
                TRANSLATOR_DEFAULT: 'Value order edit',
            },
        },
        STOCK_TRANSFER_EDIT: {
            CONTENT_URL_ID: { stockTransferEdit: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockTransferEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockTransferEdit',
                TRANSLATOR_DEFAULT: 'Stock transfer edit',
            },
        },
        AUXILIARY_OPERATION_ORDER: {
            CONTENT_URL_ID: { auxiliaryOperationOrder: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'auxiliaryOperationOrder',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.auxiliaryOperationOrder',
                TRANSLATOR_DEFAULT: 'Auxiliary operation order edit',
            },
        },
        IBS_EXPORT: {
            CONTENT_URL_ID: { ibsExport: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'ibsExport',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'IBS export',
            },
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
            SETTINGS: [
                { [SETTINGS.ACCOUNTING_SYSTEM]: [ACCOUNTING_SYSTEM.TYPE.IBS] },
            ],
        },
        IBS_SERVICE: {
            CONTENT_URL_ID: { ibsService: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'ibsService',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'IBS karbantartás',
            },
        },
        TYPE_OF_MOVEMENT_EDIT: {
            CONTENT_URL_ID: { typeOfMovementEdit: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'typeOfMovementEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.typeOfMovementEdit',
                TRANSLATOR_DEFAULT: 'Type of movement edit',
            },
        },
        FIXED_CURRENCIES: {
            CONTENT_URL_ID: { fixedCurrencies: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'fixedCurrencies',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.fixedCurrencies',
                TRANSLATOR_DEFAULT: 'Fixed currencies',
            },
            RIGHTS: [RIGHTS.FIXED_CURRENCIES_INTERFACE],
        },
        CONSOLIDATION_BUYER_SUPPLIER_INVOICES: {
            CONTENT_URL_ID: { consolidationBuyerSupplierInvoices: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'consolidationBuyerSupplierInvoices',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.consolidationBuyerSupplierInvoices',
                TRANSLATOR_DEFAULT: 'Consolidateion buyer supplier invoices',
            },
            RIGHTS: [RIGHTS.CONSOLIDATION_BUYER_SUPPLIER_INVOICES_INTERFACE],
        },
        DATA_FROM_ACCOUNTING_SYSTEM: {
            CONTENT_URL_ID: { dataFromAccountingSystem: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'dataFromAccountingSystem',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.dataFromAccountingSystem',
                TRANSLATOR_DEFAULT: 'Data from accounting system',
            },
            RIGHTS: [RIGHTS.DATA_FROM_BOOKKEEPING],
        },
        ACCOUNTING_UTILITY_INVOICE_POSTING: {
            CONTENT_URL_ID: { accountingUtilityInvoicePosting: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'accountingUtilityInvoicePosting',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.accountingUtilityInvoicePosting',
                TRANSLATOR_DEFAULT: 'Accounting utility invoice posting',
            },
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
            SETTINGS: [
                {[SETTINGS.ACCOUNTING_SYSTEM]: [
                    ACCOUNTING_SYSTEM.TYPE.DIRECT_ACCOUNTING,
                    ACCOUNTING_SYSTEM.TYPE.MANAGEMENT_SYSTEM,
                    ACCOUNTING_SYSTEM.TYPE.WINMENTOR,
                    ACCOUNTING_SYSTEM.TYPE.COBRA,
                    ACCOUNTING_SYSTEM.TYPE.AX,
                    ACCOUNTING_SYSTEM.TYPE.NAVISION,
                    ACCOUNTING_SYSTEM.TYPE.FAP,
                    ACCOUNTING_SYSTEM.TYPE.KRAFT,
                ]},
            ],
        },
        ACCOUNTING_UTILITY_INVOICE_POSTING_EDIT: {
            CONTENT_URL_ID: { accountingUtilityInvoicePostingEdit: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'accountingUtilityInvoicePostingEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.accountingUtilityInvoicePostingEdit',
                TRANSLATOR_DEFAULT: 'Accounting utility invoice posting edit',
            },
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
        },
        DATA_RECEIVED_FROM_ACCOUNTING_SYSTEM: {
            CONTENT_URL_ID: { dataReceivedFromAccountingSystem: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'dataReceivedFromAccountingSystem',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.dataReceivedFromAccountingSystem',
                TRANSLATOR_DEFAULT: 'Data received from accounting system',
            },
            RIGHTS: [RIGHTS.DATA_FROM_BOOKKEEPING],
        },
        MANUFACTURING_ORDER_CULTIVATION_PERIOD_CLASSIFICATION: {
            CONTENT_URL_ID: { manufacturingOrderCultivationPeriodClassification: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'manufacturingOrderCultivationPeriodClassification',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.manufacturingOrderCultivationPeriodClassification',
                TRANSLATOR_DEFAULT: 'Manufacturing order and cultivation period classification',
            },
            RIGHTS: [RIGHTS.ACCESSING_MANUFACTURING_ORDER_CULTIVATION_PERIOD_CLASSIFICATION_INTERFACE],
            SETTINGS: [
                {[SETTINGS.ACCOUNTING_SYSTEM]: [
                    ACCOUNTING_SYSTEM.TYPE.OTHER,
                ]},
                SETTINGS.ACCOUNTING_MANUFACTURING_ORDER 
            ],
        },
        STOCK_MOVEMENT_DISPATCHES: {
            CONTENT_URL_ID: { stockMovementDispatches: 21 },
            COMPONENT_URL: 'contents/controlling/stockMovementDispatches/StockMovementDispatches',
            EVENT_KEY: 'stockMovementDispatches',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockTransfer',
                TRANSLATOR_DEFAULT: 'Stock transfer',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY, COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
            SETTINGS: [
                {[SETTINGS.ACCOUNTING_SYSTEM]: [
                    ACCOUNTING_SYSTEM.TYPE.DIRECT_ACCOUNTING,
                    ACCOUNTING_SYSTEM.TYPE.MANAGEMENT_SYSTEM,
                    ACCOUNTING_SYSTEM.TYPE.WINMENTOR,
                    ACCOUNTING_SYSTEM.TYPE.COBRA,
                    ACCOUNTING_SYSTEM.TYPE.AX,
                    ACCOUNTING_SYSTEM.TYPE.NAVISION,
                    ACCOUNTING_SYSTEM.TYPE.FAP,
                    ACCOUNTING_SYSTEM.TYPE.KRAFT,
                    ACCOUNTING_SYSTEM.TYPE.OTHER,
                ]},
            ],
        },
        WORK_OPERATION_DISPATCHES: {
            CONTENT_URL_ID: { workOperationDispatches: 22 },
            COMPONENT_URL: 'contents/controlling/workOperationDispatches/WorkOperationDispatches',
            EVENT_KEY: 'workOperationDispatches',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.auxiliaryOperationRecording',
                TRANSLATOR_DEFAULT: 'Auxiliary operation recording',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
            SETTINGS: [
                {[SETTINGS.ACCOUNTING_SYSTEM]: [
                    ACCOUNTING_SYSTEM.TYPE.DIRECT_ACCOUNTING,
                    ACCOUNTING_SYSTEM.TYPE.MANAGEMENT_SYSTEM,
                    ACCOUNTING_SYSTEM.TYPE.WINMENTOR,
                    ACCOUNTING_SYSTEM.TYPE.COBRA,
                    ACCOUNTING_SYSTEM.TYPE.AX,
                    ACCOUNTING_SYSTEM.TYPE.NAVISION,
                    ACCOUNTING_SYSTEM.TYPE.FAP,
                    ACCOUNTING_SYSTEM.TYPE.KRAFT,
                    ACCOUNTING_SYSTEM.TYPE.OTHER,
                ]},
            ],
        },
        ACCOUNTING_CUSTOMER_INVOICE_POSTING: {
            CONTENT_URL_ID: { accountingCustomerInvoicePosting: 23 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'accountingCustomerInvoicePosting',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.accountingCustomerInvoices',
                TRANSLATOR_DEFAULT: 'Accounting customer invoices',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
            SETTINGS: [
                {[SETTINGS.ACCOUNTING_SYSTEM]: [
                    ACCOUNTING_SYSTEM.TYPE.MANAGEMENT_SYSTEM,
                ]},
                SETTINGS.ACCOUNTING_FORRAS_DIRECT_CUSTOMER_INVOICE,
            ],
        },
        ACCOUNTING_CUSTOMER_INVOICE_POSTING_EDIT: {
            CONTENT_URL_ID: { accountingCustomerInvoicePostingEdit: 24 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'accountingCustomerInvoicePostingEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.accountingCustomerInvoicesEdit',
                TRANSLATOR_DEFAULT: 'Accounting customer invoices edit',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.ACCOUNTING_CONTACT_INTERFACE],
            SETTINGS: [
                {[SETTINGS.ACCOUNTING_SYSTEM]: [
                    ACCOUNTING_SYSTEM.TYPE.MANAGEMENT_SYSTEM,
                ]},
                SETTINGS.ACCOUNTING_FORRAS_DIRECT_CUSTOMER_INVOICE,
            ],
        },        
    },
};
