import Svg from '@baseComponents/Svg';
import Button from '@baseComponents/buttons/Button';
import useTranslate from '@i18n/useTranslate';
import { ThemeType } from '@map/services/mapTypes';
import { isEmpty, isFunction, isNil } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

type Classes = 'container' | 'button' | 'buttonContainer' | 'numOfSelectedContainer' | 'numOfSelected' | 'numOfSelectedLabel';

type StyleProps = {
    left: number,
    top: number,
}

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    container: {
        display: 'flex',
        position: 'absolute',
        zIndex: 4,
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: 50,
        padding: 5,
        backgroundColor: 'white',
        borderRadius: 8,
        height: 45,
    },
    buttonContainer: {
        display: 'inline-block',
        verticalAlign: 'top',
    },
    button: {
        border: 'none',
        marginRight: 0,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.color.opacity.main,
            border: 'none',
        },
        '&:focus': {
            backgroundColor: theme.color.opacity.main,
            border: 'none',
        },
    },
    numOfSelectedContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 12,
    },
    numOfSelectedLabel: {
        fontSize: 14,
        fontWeight: 400,
        color: '#454F54',
        margin: [0, 12, 0, 5],
    },
    numOfSelected: {
        fontSize: 16,
        fontWeight: 500,
        color: '#21282C',
    },
}));

type TProps = {
    selectedFeatureIds: number[],
    unSelectAllFeature: () => void,
    editFeatures: () => void,
}

export function ContextMenuPlanning({
    selectedFeatureIds,
    unSelectAllFeature,
    editFeatures,
}: TProps) {
    const classes = useStyles();

    const { t } = useTranslate();

    const contextMenuRef = useRef<HTMLDivElement>(null);

    if (isEmpty(selectedFeatureIds)) {
        return null;
    }
    return (
        <div
            ref={contextMenuRef}
            className={classes.container}
        >
            <div className={classes.numOfSelectedContainer}>
                <div className={classes.numOfSelected}>{selectedFeatureIds?.length ?? 0}</div>
                <div className={classes.numOfSelectedLabel}>{t('map.planningContextMenuNumOfSelected', 'item selected')}</div>
            </div>

            <div className={classes.buttonContainer}>

                <Button
                    title={t('map.planningContextMenuEdit', 'Edit')}
                    size="small"
                    type="secondary"
                    iconId="icon-edit"
                    iconColor="black"
                    onClick={() => { editFeatures(); unSelectAllFeature(); }}
                    className={classes.button}
                />

                <Button
                    title={t('map.planningContextMenuUnselectAll', 'Unselect all')}
                    size="small"
                    type="secondary"
                    iconId="icon-bin"
                    iconColor="black"
                    onClick={() => unSelectAllFeature()}
                    className={classes.button}
                />

            </div>
        </div>
    );
}
