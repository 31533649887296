import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    commentCardWrapper: {
        borderRadius: 4,
        border: `1px solid ${theme.color.pearl}`,
        padding: 20,
        marginTop: 15,
        marginBottom: 15,
    },
    commentTitle: {
        ...theme.firstLetterUppercase,
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
        marginBottom: 5,
    },
    comment: {
        whiteSpace: 'pre-line',
        marginBottom: 5,
        fontSize: 13,
    },
}));

export default function PeriodViewCommentCard(props) {
    const { comment, label, serialNumber = 1 } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    if (isEmpty(comment)) {
        return null;
    }

    function getCommentLabel() {
        switch (serialNumber) {
            case 1:
                return t('newCultivationPeriod.comment', 'Comment');
            case 2:
                return t('cultivationPeriodsColumn.comment2', 'Comment II');
            case 3:
                return t('cultivationPeriodsColumn.comment3', 'Comment III');
            default:
                return '';
        }
    }

    return (
        <div className={classes.commentCardWrapper}>
            <div className={classes.commentTitle}>
                {label || getCommentLabel()}
            </div>
            <div className={classes.comment}>
                {comment}
            </div>
        </div>
    );
}
