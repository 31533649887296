import { COUNTRY, LANGUAGE } from '../constants';

export class CompanyPermissions {
    constructor(countryCode) {
        this.countryCode = countryCode;
    }

    //termesztési időszakok kezelése - bank, biztosító
    hasCultivationPeriodBankAndInsurer() {
        return this.countryCode === COUNTRY.ROMANIA;
    }

    //termesztési időszakok kezelése - mepár létrehozás jog, 
    hasLpisCreationPermission() {
        return this.countryCode !== COUNTRY.ARGENTINA;
    }

    //termesztési időszakok kezelése - időjárás, ndvi gomb
    hasWeatherAndNdviPermission() {
        return this.countryCode === COUNTRY.HUNGARY;
    }

    //termesztési időszakok kezelése - Fajta automatikus beemelése
    hasPeriodVerietyAutomaticPermission() {
        return this.countryCode === COUNTRY.HUNGARY;
    }
    
    //termesztési időszakok kezelése - Csoportos művelet
    hasCultivationPeriodEditTableOfficialDataRight() {
        return this.countryCode !== COUNTRY.ARGENTINA;
    }

    //termesztési időszakok kezelése - Csoportos művelet
    hasCultivationPeriodGroupOperationPermission() {
        return this.countryCode === COUNTRY.HUNGARY || this.countryCode === COUNTRY.ROMANIA;
    }

    hasLoginAdPermission() {
        return this.countryCode === COUNTRY.HUNGARY;
    }

    //termesztési időszak kezelése - kerület
    hasCultivationPeriodDistrictPermission() {
        return this.countryCode === COUNTRY.ARGENTINA;
    }

    hasManagementLotCheckboxPermission() {
        return this.basicLanguage === LANGUAGE.HUNGARIAN;
    }
}
