import { RIGHTS, SETTINGS } from '../constants';

export const DATA_ANALYSIS = {
    MODULE_ID: 30,
    NAME: 'dataAnalysis',
    SHOW_IN_PANNING_MODE: true,
    SETTINGS: [
        { [SETTINGS.USE_GLOBAL_LITE]: 0 }
    ],
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'dataAnalysis',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.dataAnalysis',
                TRANSLATOR_DEFAULT: 'Data analysis',
            },
        },
        NOTIFICATIONS: {
            CONTENT_URL_ID: { notifications: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'notifications',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.notifications',
                TRANSLATOR_DEFAULT: 'Notifications',
            },
            RIGHTS: [RIGHTS.DATA_ANALYSIS_NOTIFICATIONS],
        },
        NOTIFICATIONS_SETTINGS: {
            CONTENT_URL_ID: { notificationsSetting: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'notificationsSetting',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.notificationsSetting',
                TRANSLATOR_DEFAULT: 'Notifications setting',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.DATA_ANALYSIS_SETTINGS],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        EXPECTED_PERFORMANCE_OF_MACHINES: {
            CONTENT_URL_ID: { expectedPerformanceOfMachines: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'expectedPerformanceOfMachines',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.expectedPerformanceOfMachines',
                TRANSLATOR_DEFAULT: 'Expected performance of machines',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.DATA_ANALYSIS_SETTINGS],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        COMPARING_OF_VARIETES: {
            CONTENT_URL_ID: { comparingOfVarietes: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'comparingOfVarietes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.comparingOfVarietes',
                TRANSLATOR_DEFAULT: 'Comparing of varietes',
            },
        },
        INPUT_PRICE: {
            CONTENT_URL_ID: { inputPrice: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'inputPrice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.inputPrice',
                TRANSLATOR_DEFAULT: 'Input price',
            },
        },
        DQS: {
            CONTENT_URL_ID: { DQS: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'DQS',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.DQS',
                TRANSLATOR_DEFAULT: 'DQS',
            },
            RIGHTS: [RIGHTS.DQS_INTERFACE],
            SETTINGS: [SETTINGS.USING_DQS],
        },
        MANAGEMENT_ANALYSIS: {
            CONTENT_URL_ID: { managementAnalysis: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'managementAnalysis',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.managementAnalysis',
                TRANSLATOR_DEFAULT: 'Management dashboard',
            },
            RIGHTS: [RIGHTS.MANAGEMENT_DASHBOARD],
        },
        AGROINDEX: {
            CONTENT_URL_ID: { agroindex: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'agroindex',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.agroindex',
                TRANSLATOR_DEFAULT: 'AGROiNDEX',
            },
        },
        FEVER_SHEET: {
            CONTENT_URL_ID: { feverSheet: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'feverSheet',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.feverSheet',
                TRANSLATOR_DEFAULT: 'Fever sheet',
            },
        },        
    },
};
