import { numberUtils } from 'shared/src/modules';

function formatNumber(originalValue, numberFormat) {
    const { decimals, decimalSeparator, thousandsSeparator } = numberFormat || {}; //number input

    let displayedValue: any = null;

    displayedValue = numberUtils.getDisplayedNumberFormat(originalValue, decimals, decimalSeparator, thousandsSeparator);

    //végére kell rakni a serparator charactert
    //végére kell rakni 0-t
    if (originalValue?.toString()?.charAt(originalValue.toString()?.length - 1) === '0') { //ha utoljára 0-t írtunk be
        const numberParts = originalValue?.toString()?.includes(decimalSeparator) ? originalValue?.toString()?.split(decimalSeparator) : originalValue?.toString()?.split('.');
        const decimalPart = numberParts?.[1];

        if (decimalPart?.length < decimals) {
            displayedValue = `${numberParts?.[0]}${decimalSeparator}${decimalPart}`;
        }
    } else if (originalValue?.toString()?.charAt(originalValue.length - 1) === '.') {
        displayedValue = `${displayedValue?.toString()}${decimalSeparator}`;
    }

    return displayedValue;
}

export { formatNumber };
