/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-cycle */
import { isObject, isEmpty, isNull, isUndefined, isNumber, toLower } from 'lodash';
import { MODULES } from 'shared/src/routing';
import { IMAGE, DEFAULT_SYSTEM_DATA, CULTIVATION_PERIODS } from 'shared/src/constants';
import { companyService, sessionService } from '@services';

const routerHelper = {
    getAgentName,
    checkAgent,
    getCurrentRouteItem,
    getCurrentMenuItem,
    hasParamsHash,
    getHashFromLocation,
    getHashCode,
    isPasswordConfirm,
    generateUrl,
    getBaseUrl,
    getCurrentModuleName,
    getCurrentContentName,
    getContentObjectByName,
    getModuleObjectByName,
    getModuleContentNameByUrl,
    getModuleObjectByUrl,
    getContentObjectByUrl,
    getUserProfilePicUrl,
    getContentTitle,
    getBaseUrlFromLocation,
    getContentIdFromUrl,
    getContentId,
    getContentName,
    getModuleIdFromUrl,
    getUrlParamValueBySearch,
    getUrlWithParameters,
    getQuickLinkFromUrl,
    getIsAgrovirUrl,
    isEqualUrlsWithoutParams,
    getContentTitleByUrl,
    isHomePage,
    getAgentNameFromWindowUrl,
    getMachinePictureUrl,
};

export { routerHelper };

//temp function - many agent one browser
function getAgentNameFromWindowUrl() {
    if (window.location && window.location.pathname) {
        const urlParts = window.location.pathname.split('/');
        if (urlParts.length > 1) {
            return toLower(urlParts[1]);
        }
    }
    return null;
}

function getAgentName(location) {
    const urlPathParts = location.split('/');
    if (urlPathParts.length > 1) {
        return urlPathParts[1] !== '' ? toLower(urlPathParts[1].toString()) : null;
    }

    throw new Error('Agent not found in url!');
}

async function checkAgent(agentName) {
    if (agentName === '' || !agentName) {
        return false;
    }

    return companyService.getAgent(agentName);
}

function generateUrl(moduleObject, contentObject, params = '') {
    let paramsString = '';

    //get parameters string
    if (isObject(params)) {
        paramsString = '?';
        Object.entries(params).forEach(([paramKey, paramValue], index) => {
            if (index > 0) {
                paramsString += '&';
            }
            paramsString += `${paramKey}=${paramValue || ''}`;
        });
    } else {
        paramsString = params;
        if (!params.includes('?')) {
            paramsString = `?${params}`;
        }
    }

    const moduleName = moduleObject.NAME;
    const contentName = Object.keys(contentObject.CONTENT_URL_ID)[0].toString();
    const agentName = sessionService.getAgentName();

    return `/${agentName}/${moduleName}/${contentName}${paramsString}`;
}

function getBaseUrl(moduleObject, contentObject) {
    try {
        const moduleName = moduleObject.NAME;
        const contentName = Object.keys(contentObject.CONTENT_URL_ID)[0].toString();

        return `${moduleName}/${contentName}`;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('getBaseUrl error:', error);
    }
}

// Get current route and menu item
function getCurrentPath(history) {
    const currentUrlArray = history.location ? history.location.pathname.split('/') : history.pathname.split('/');
    let moduleName = '';
    let pageName = '';

    if (currentUrlArray[2] && currentUrlArray[2] !== '') {
        moduleName = currentUrlArray[2].toString();
    }
    if (currentUrlArray[3] && currentUrlArray[3] !== '') {
        pageName = `/${currentUrlArray[3].toString()}`;
    }

    return `${moduleName}${pageName}`;
}

function getCurrentRouteItem(history, routeList) {
    const currentPath = getCurrentPath(history);
    return routeList.find(routeItem => routeItem.path === currentPath);
}

function getCurrentMenuItem(sideMenuList, routeItem, defaultPage) {
    if (isUndefined(routeItem)) {
        return sideMenuList.find(sideMenuItem => sideMenuItem.eventKey === defaultPage);
    }
    return routeItem;
}

function getHashFromLocation(location) {
    if (isNull(location)) {
        return null;
    }

    const locationSplit = location.split('#');
    if (!isUndefined(locationSplit) && locationSplit.length > 1) {
        return locationSplit[1];
    }
    return null;
}

function hasParamsHash(params) {
    if (params.hash.length > 0) {
        return true;
    }
    return false;
}

function getHashCode(url) {
    const path = url.split('/');
    if (!path[3]) {
        return null;
    }

    const hashCode = path[3].toString();
    return hashCode;
}

function isPasswordConfirm(url) {
    const path = url.split('/');
    if (path[2] === 'passwordConfirm') {
        return true;
    }
    return false;
}

function getCurrentModuleName(url) {
    const path = url.split('/');
    return path[2] ? path[2].toString() : null;
}

function getCurrentContentName(url) {
    const path = url.split('/');
    const pathWithoutParams = path[3] ? path[3].toString().split('?') : null;

    return pathWithoutParams ? pathWithoutParams[0].toString() : null;
}

function getModuleContentNameByUrl(url) {
    const moduleName = getCurrentModuleName(url);
    const contentName = getCurrentContentName(url);

    return `${moduleName}/${contentName}`;
}

function getContentObjectByName(moduleName, contentName) {
    const moduleObj = Object.values(MODULES).find((moduleItem: any) => moduleItem.NAME === moduleName) as any;
    if (!moduleObj) {
        return null;
    }

    const contentObj = Object.entries(moduleObj.CONTENTS).find((route: any) => Object.entries(route[1]).find((routeObj: any) => (routeObj[0] === 'CONTENT_URL_ID' && Object.entries(routeObj[1])[0][0].toString() === contentName)));
    if (!contentObj) {
        return null;
    }

    return contentObj[1];
}

function getModuleObjectByName(moduleName) {
    return Object.values(MODULES).find((moduleItem: any) => moduleItem.NAME === moduleName);
}

function getModuleObjectByUrl(url) {
    if (isEmpty(url)) {
        return null;
    }

    const moduleName = getCurrentModuleName(url);

    return getModuleObjectByName(moduleName);
}

function getContentObjectByUrl(url): any {
    if (isEmpty(url)) {
        return null;
    }

    const moduleName = getCurrentModuleName(url);
    const contentName = getCurrentContentName(url);

    return getContentObjectByName(moduleName, contentName);
}

function getUserProfilePicUrl(userId, size = IMAGE.SIZE.SMALL, extension = IMAGE.EXTENSION.JPG) {
    const agentName = sessionService.getAgentName();
    return `/uploads/profilepictures/${agentName}/user/${userId}_${size}_profilepic.${extension}`;
}

function getMachinePictureUrl(machineId, size = IMAGE.SIZE.SMALL, extension = IMAGE.EXTENSION.JPG) {
    const agentName = sessionService.getAgentName();
    return `/uploads/profilepictures/${agentName}/machine/${machineId}_${size}_profilepic.${extension}`;
}

function getBaseUrlFromLocation(location) {
    const agentName = sessionService.getAgentName();
    const parts = location.split(`/${agentName}`);
    return parts[0];
}

function getContentTitle(t, content) {
    if (content && content.TITLE && !content.TITLE.USE_TRANSLATOR && content.TITLE.DEFAULT_TEXT) {
        return content.TITLE.DEFAULT_TEXT;
    }

    if (content && content.TITLE && content.TITLE.TRANSLATOR_KEY && content.TITLE.TRANSLATOR_DEFAULT) {
        return t(content.TITLE.TRANSLATOR_KEY, content.TITLE.TRANSLATOR_DEFAULT);
    }

    return '';
}

function getContentIdFromUrl(url) {
    const path = url.split('/');
    if (!path || path.length < 3 || !path[3]) {
        return null;
    }

    path[3] = path[3].split('?')[0].toString();
    const moduleName = path[2] ? path[2].toString() : null;
    const contentName = path[3] ? path[3].toString() : null;
    const contentObj = getContentObjectByName(moduleName, contentName);

    if (contentObj) {
        return getContentId(contentObj);
    }

    return null;
}

function getContentId(content) {
    if (!content) {
        return null;
    }
    return (Object.entries(content.CONTENT_URL_ID) as any)[0][1].toString();
}

function getContentName(content) {
    if (!content) {
        return null;
    }
    return Object.entries(content.CONTENT_URL_ID)[0][0].toString();
}

function getModuleIdFromUrl(url) {
    const path = url.split('/');
    if (!path || path.length < 3) {
        return null;
    }

    const moduleName = path[2] ? path[2].toString() : null;
    const moduleObj = getModuleObjectByName(moduleName) as any;

    if (moduleObj) {
        return moduleObj.MODULE_ID;
    }
    return null;
}

function getUrlParamValueBySearch(urlParamsString, searchName) {
    const url = new URLSearchParams(urlParamsString);
    const param = url.get(searchName);

    return param;
}

function getUrlWithParameters(urlString, parameters?: any) {
    let url = urlString;
    if (parameters) {
        Object.entries(parameters).forEach((parameter, index) => {
            if (parameter[0] && parameter[1] !== null) {
                if (index > 0) { url += '&'; } else { url += '?'; }
                url += `${parameter[0]}=${parameter[1]}`;
            }
        });
    }

    return url;
}

function getQuickLinkFromUrl(url) {
    const moduleId = getModuleIdFromUrl(url);
    const contentId = getContentIdFromUrl(url);

    return `${moduleId}/${contentId}`;
}

function getIsAgrovirUrl(location) {
    const parts = location.split('/');

    return Object.values(DEFAULT_SYSTEM_DATA.SITE).find((siteUrl: any) => siteUrl === parts[2] || siteUrl === `www.${parts[2]}`);
}

function isEqualUrlsWithoutParams(firstUrl, secondUrl) {
    if (!firstUrl || !secondUrl) {
        return false;
    }

    const firstUrlWithoutParams = firstUrl.split('?')[0].toString();
    const secondUrlWithoutParams = secondUrl.split('?')[0].toString();

    return firstUrlWithoutParams === secondUrlWithoutParams;
}

function getContentTitleByUrl(t, url, urlParam) {
    const contentObject = getContentObjectByUrl(url) as any;

    if (contentObject) {
        if (urlParam) {
            const id = getUrlParamValueBySearch(urlParam, 'id');
            const type = getUrlParamValueBySearch(urlParam, 'type');

            //edit or new page title
            if (id && isNumber(Number(id))) {
                const editTitle = getContentTitleByIdParam(t, contentObject, id);
                if (!isEmpty(editTitle)) {
                    return editTitle;
                }
            }

            //custom title detection
            if (type && (contentObject.EVENT_KEY === MODULES.CULTIVATION_PERIODS.CONTENTS.OTHER_REVENUE_EDIT.EVENT_KEY
                || contentObject.EVENT_KEY === MODULES.CULTIVATION_PERIODS.CONTENTS.INCOMES.EVENT_KEY
                || contentObject.EVENT_KEY === MODULES.PLANNING.CONTENTS.INCOMES.EVENT_KEY
                || contentObject.EVENT_KEY === MODULES.PLANNING.CONTENTS.INCOMES_EDIT.EVENT_KEY)
            ) {
                return getContentTitleByParamType(t, contentObject, type);
            }
        }

        return getContentTitle(t, contentObject);
    }

    return null;
}

function isHomePage(location) {
    if (isNull(location)) {
        return null;
    }

    const moduleName = getCurrentModuleName(location);
    if (isEmpty(moduleName)) {
        return true;
    }
    return false;
}

function getContentTitleByParamType(t, content, type) {
    switch (Number(type)) {
        case CULTIVATION_PERIODS.INCOME_TYPE.SUPPORT:
            return t('pageTitle.support', 'Support');
        case CULTIVATION_PERIODS.INCOME_TYPE.WILDLIFE_DAMAGE:
            return t('pageTitle.wildlifeDamage', 'Wildlife damage');
        case CULTIVATION_PERIODS.INCOME_TYPE.INSURANCE:
            return t('pageTitle.insurance', 'Insurance');
        case CULTIVATION_PERIODS.INCOME_TYPE.OTHER_INCOME:
            return t('pageTitle.otherIncome', 'Other income');
        default:
            return getContentTitle(t, content);
    }
}

function getContentTitleByIdParam(t, contentObject, id) {
    const titleObject = contentObject && contentObject.EDIT_TITLE ? contentObject.EDIT_TITLE : null;

    if (titleObject && !titleObject.USE_TRANSLATOR && titleObject.DEFAULT_TEXT && id > 0) {
        return titleObject.DEFAULT_TEXT;
    }

    if (titleObject && titleObject.TRANSLATOR_KEY && titleObject.TRANSLATOR_DEFAULT && id > 0) {
        return t(titleObject.TRANSLATOR_KEY, titleObject.TRANSLATOR_DEFAULT);
    }

    return '';
}
