import { isFunction, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import * as turf from '@turf/helpers';
import { useLayerCreateContext } from '@map/services/layerCreate/layerCreateContext';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { GeomDto } from '@map/services/mapTypes';
import { useMapContext } from '@map/services/mapContext';
import { MeteorolgyFormType } from './MeteorolgySchema';

export interface MeteorologyCities {
  kijeloles: string;
  id: string;
  orszag: string;
  orszagrovidnev: string;
  megye: string;
  irsz: string;
  telepules: string;
  aktiv: string;
  // eslint-disable-next-line camelcase
  gn_kod: string;
}

interface MeteorologyCompanies {
  id: string;
  nev: string;
  isChild: number;
}

interface CityDefaultCompany {
  // eslint-disable-next-line camelcase
  ceg_id: string;
  // eslint-disable-next-line camelcase
  connect_szerzodo_ceg: string;
}

export type MeteorolgyServiceProps = {
    entityId: number,
    initCompanyIds: number[],
    onSuccess: any;
    onFailure: any;
}

export const useMeteorolgyService = ({ entityId, initCompanyIds, onSuccess, onFailure } : MeteorolgyServiceProps) => {
    const [cities, setCities] = useState<MeteorologyCities[]>([]);
    const [defaultComapnies, setDefaultCompanies] = useState<CityDefaultCompany[]>([]);
    const [preGeom, setPreGeom] = useState<GeomDto>();
    const [store, controler] = useMapContext();

    const [_, layerCreateController] = useLayerCreateContext();

    useEffect(() => {
        if (isNil(store.layering.layers)) {
            return;
        }

        const fetchCities = async () => {
            const body = new FormData();
            body.append('aktivak', '1');

            const response = await fetch('/modules/partner/control/get_telepules.php', { method: 'POST', body });
            if (response.status === 200) {
                const responseParsed = await response.json() as MeteorologyCities[];
                setCities(responseParsed);
            }
        };

        const fetchDefaultCompanies = async (id: number) => {
            if (id) {
                const body = new FormData();
                body.append('id', id.toString());
                body.append('connect_szerzodo_ceggel', '1');

                const response = await fetch('/modules/altalanos/control/get_location_company.php', { method: 'POST', body });
                if (response.status === 200) {
                    const responseParsed = await response.json() as CityDefaultCompany[];
                    setDefaultCompanies(responseParsed);
                }
            }
        };

        const fetchGeometry = async (id: number) => {
            if (id !== 0) {
                const meteorologyLayer = controler.getLayerByName(BuiltInLayerTypes.METEOROLOGY);
                if (isNil(meteorologyLayer)) {
                    return;
                }
                const preGeomRes = await layerCreateController.findGeometryByEntityId(entityId, meteorologyLayer.id);
                if (!isNil(preGeomRes)) {
                    setPreGeom(preGeomRes);
                }
            }
        };

        fetchCities();
        fetchDefaultCompanies(entityId);
        fetchGeometry(entityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, store.layering.layers]);

    const saveCity = async (city: MeteorolgyFormType) => {
        const body = new FormData();
        body.append('location_name', city.name);
        body.append('settlement', city.cityId.toString());
        city.companyIds.forEach(c => {
            body.append('location_partner', c.toString());
        });
        body.append('id', city.id.toString());
        body.append('partner_ids', city.companyIds.join(','));
        body.append('device_reference', city.deviceReference ?? '');
        const token = sessionStorage.getItem('gisAuthToken');
        if (!isNil(token)) {
            body.append('gisToken', token);
        }

        if (!isNil(preGeom) && !isNil(preGeom?.id)) {
            body.append('geomId', preGeom.id.toString());
        }

        if (!isNil(city.location.lng) && !isNil(city.location.lat)) {
            const geometry = turf.point([city.location.lng, city.location.lat]);
            body.append('geometry', JSON.stringify(geometry.geometry));
        }

        const meteorologyLayer = controler.getLayerByName(BuiltInLayerTypes.METEOROLOGY);

        if (isNil(meteorologyLayer)) {
            return;
        }

        body.append('layerId', JSON.stringify(meteorologyLayer.id));

        const response = await fetch('/modules/altalanos/control/set_location.php', { method: 'POST', body });
        if (response.status === 200) {
            const resBody = await response.json();
            if (isFunction(onSuccess)) {
                onSuccess({ ...resBody, locationName: city?.name });
                return;
            }
        }
        if (isFunction(onFailure)) {
            const resBody = await response.json();
            onFailure(resBody);
        }
    };

    return { cities, defaultComapnies, saveCity, preGeom };
};
