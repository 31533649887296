import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import clsx from 'clsx';
import { isString, isNumber, isObject, isEmpty, isFunction } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        ...theme.overFlowText,
        color: (props: any) => (!isEmpty(props.color) ? props.color : theme.color.jet),
        //display: 'block',
        //height: '100%',
        maxHeight: 56,
        fontSize: (props: any) => (props.isTitle ? 16 : 13),
        fontWeight: (props: any) => (props.isTitle ? 600 : 500),
        verticalAlign: 'middle',
        backgroundColor: (props: any) => props.background || 'transparent',
        paddingLeft: (props: any) => (props.background ? 5 : 0),

        //multiline
        wordWrap: (props: any) => props.isAllowLineBreak && 'break-word',
        lineHeight: (props: any) => (props.isAllowLineBreak ? '18px' : '56px'),
        display: (props: any) => (props.isAllowLineBreak ? '-webkit-box' : 'block'),
        whiteSpace: (props: any) => (props.isAllowLineBreak ? 'normal' : 'nowrap'),
        '-webkit-box-orient': (props: any) => props.isAllowLineBreak && 'vertical',
        '-webkit-line-clamp': (props: any) => props.isAllowLineBreak && 2,
        minHeight: (props: any) => (props.isAllowLineBreak ? 36 : 56),
        maxhHeight: (props: any) => (props.isAllowLineBreak ? 36 : 56),
        height: (props: any) => (props.isAllowLineBreak ? 36 : '100%'),
        marginTop: (props: any) => props.isAllowLineBreak && 8,
    },
}));

export default function SimpleTextCell(props) {
    const { isTitle, value, style, secondColumnId, rowData, backgroundStatusId, getBackgroundColor, getTextValue, getTitle, isAllowLineBreak, getFontColor } = props;
    const classes = useStyles({
        background: setBackgroundColor(),
        isTitle,
        isAllowLineBreak,
        color: isFunction(getFontColor) && getFontColor(rowData),
    } as any);

    const secondColumnItem = arrayUtils.getArrayItemByKeyString(rowData, secondColumnId);
    const [textValue, setTextValue] = useState(getValue());
    const [titleValue, setTitleValue] = useState(getValue());

    useEffect(() => {
        if (value) {
            setTextValue(value);
        }
        if (isFunction(getTitle)) {
            setTitleValue(getTitle(rowData));
        }
    }, [getTitle, rowData, value]);

    function getValue() {
        if (isFunction(getTextValue)) {
            return getTextValue(rowData);
        }
        if (secondColumnItem) {
            return `${value} ${secondColumnItem}`;
        }
        if (isString(value) || isNumber(value)) {
            return value;
        }
        if (isObject(value)) {
            if (!isEmpty(value.otherLanguage)) {
                return value.otherLanguage[0].name;
            }
            return value.name;
        }

        return null;
    }

    function setBackgroundColor() {
        if (backgroundStatusId && isFunction(getBackgroundColor)) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, backgroundStatusId);
            const backgroundColor = getBackgroundColor(columnItem);

            return backgroundColor;
        }
        if (!backgroundStatusId && isFunction(getBackgroundColor)) {
            const backgroundColor = getBackgroundColor(rowData);

            return backgroundColor;
        }
        return null;
    }

    return (
        <div className={clsx(classes.baseStyle)} title={titleValue || textValue} style={style}>
            {textValue}
        </div>
    );
}
