import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import useTranslate from '@i18n/useTranslate';
import { sessionService } from '@services';

const useStyles = createUseStyles((theme: any) => ({
    selectedPlanWrapper: {
        paddingLeft: 30,
        paddingRight: 20,
        paddingTop: 5,
        width: '100%',
        height: 25,
    },
    selectedPlanTitle: {
        display: 'inline-flex',
        color: theme.color.jet,
        fontSize: 13,
    },
    selectedPlanName: {
        display: 'inline-flex',
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.shadow_green,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: 'auto',
        marginLeft: 5,
    },
}));

export default function PhpPlanningHeader() {
    const { t } = useTranslate();
    const classes = useStyles();

    const planningData = sessionService.getPlanningData();
    const planningModeisActive = useSelector((state: any) => state.system.planningModeisActive);

    if (!planningModeisActive) {
        return null;
    }

    return (
        <div className={classes.selectedPlanWrapper}>
            <div className={classes.selectedPlanTitle}>{t('userMenu.selectedPlan', 'Selected plan')}:</div>
            <div className={classes.selectedPlanName}>{planningData?.selectedPlanName}</div>
        </div>
    );
}
