import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isFunction } from 'lodash';

import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Svg from '@baseComponents/Svg';
import CostumReactTooltip from '@baseComponents/tooltips/CostumReactTooltip';

const useStyles = createUseStyles((theme: any) => ({
    dropdownButton: {
        padding: 0,
        display: 'inline-block',
        verticalAlign: 'top',
        minWidth: 50,
        borderRadius: 4,
        height: (props: any) => {
            switch (props.buttonSize) {
                case 'small':
                    return 36;
                case 'large':
                    return 46;
                default:
                    return 40;
            }
        },
        boxShadow: '0 3px 5px 0 rgba(175, 180, 182, 0.3)',
        border: `1px solid ${theme.color.gray}`,
        backgroundColor: `${theme.color.white} !important`,
        color: (props: any) => (props.disabled ? theme.color.pearl : theme.color.jet),
        '&:hover': {
            background: theme.color.buttonHover.secondary,
            border: `1px solid ${theme.color.gray}`,
            boxShadow: (props: any) => !props.disabled && '0 5px 10px 0 rgba(175, 180, 182, 0.5)',
            cursor: (props: any) => props.disabled && 'default',
        },
        '&:focus': {
            border: `1px solid ${theme.color.gray}`,
            backgroundColor: `${theme.color.white} !important`,
            outline: 'none',
        },
        '&:active': {
            color: `${theme.color.jet} !important`,
            border: `1px solid ${theme.color.gray} !important`,
            backgroundColor: `${theme.color.white} !important`,
            outline: 'none',
        },
    },
    title: {
        display: 'inline-block',
        textTransform: 'uppercase',
        fontSize: (props: any) => (props.buttonSize === 'large' ? 14 : 13),
        lineHeight: (props: any) => (props.buttonSize === 'large' ? 1.86 : 1.54),
        fontWeight: 500,
    },
    after: {
        verticalAlign: 'middle',
        width: (props: any) => (props.buttonSize === 'large' ? 20 : 16),
        height: (props: any) => (props.buttonSize === 'large' ? 20 : 16),
        marginLeft: (props: any) => {
            switch (props.buttonSize) {
                case 'small':
                    return 7;
                case 'large':
                    return 18;
                default:
                    return 12;
            }
        },
    },
    before: {
        verticalAlign: 'middle',
        width: (props: any) => (props.buttonSize === 'large' ? 20 : 16),
        height: (props: any) => (props.buttonSize === 'large' ? 20 : 16),
        marginRight: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 7;
                case 'large':
                    return 18;
                default:
                    return 12;
            }
        },
    },
    icon: {
        verticalAlign: 'middle',
        width: (props: any) => (props.size === 'large' ? 26 : 20),
        height: (props: any) => (props.size === 'large' ? 26 : 20),
        fill: (props: any) => props.iconColor,
    },
    content: {
        padding: (props: any) => {
            switch (props.buttonSize) {
                case 'small':
                    return '0px 13px';
                case 'large':
                    return '0px 23px';
                default:
                    return '0px 18px';
            }
        },
        lineHeight: 'normal',
    },
    dropdown: {
        border: 'none !important',
        padding: 0,
        backgroundColor: theme.color.white,
        borderRadius: '6px',
        boxShadow: '0 6px 22px 4px rgba(33, 40, 44, 0.1), 0 12px 18px 2px rgba(33, 40, 44, 0.15), 0 4px 8px -4px rgba(33, 40, 44, 0.2)',
        '&:focus': {
            outline: 'none',
        },
        top: (props: any) => props.direction === 'up' && 'auto !important',
        transform: (props: any) => props.direction === 'up' && 'translate3d(0px, 0px, 0px) !important',
    },
    dataItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        minWidth: 250,
        fontSize: '15px',
        fontWeight: 'normal',
        lineHeight: '1.33',
        color: theme.color.jet,
        outline: 'none',
        '&:hover': {
            backgroundColor: theme.color.opacity.main,
            color: theme.color.main,
            cursor: 'pointer',
            '& $functionIcons': {
                fill: theme.color.main,
            },
        },
        '&:first-child': {
            '&:hover': {
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
            },
        },
        '&:last-child': {
            '&:hover': {
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
            },
        },
        '& .faIcon': {
            fontSize: 20,
            marginRight: 10,
        },
    },
    destructive: {
        '&:hover': {
            background: theme.color.destructiveFaded,
            cursor: 'pointer',
            '& $functionIcons': {
                fill: theme.color.destructive,
            },
        },
        padding: '10px 15px',
        fontSize: '15px',
        fontWeight: 'normal',
        lineHeight: '1.33',
        color: theme.color.destructive,
        outline: 'none',
    },
    buttom: {
        boxShadow: `inset 0 1px 0 0 ${theme.color.pearl}`,
    },
    dropdownContent: {
        display: 'inline-flex !important',
        textAlign: 'center',
    },
    disabled: {
        opacity: 1,
        backgroundColor: theme.color.white,
        color: theme.color.gray,
        '&:hover': {
            backgroundColor: theme.color.white,
            cursor: 'default',
            color: theme.color.gray,
        },
    },
    functionIcons: {
        width: 20,
        height: 20,
        minHeight: 20,
        minWidth: 20,
        marginRight: 10,
    },
    functionDisabledIcon: {
        opacity: 0.5,
        fill: [theme.color.jet, '!important'],
    },
    functionDestructiveIcon: {
        fill: theme.color.destructive,
    },
}));

export default function DropdownButton(props) {
    const { itemList, direction, buttonSize, buttonIconId, buttonIconPosition, children, tableRowItem, className, disabled, onChange/*, title*/ } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => {
        if (!disabled) {
            setDropdownOpen(!dropdownOpen);
        }
    };
    const classes = useStyles({ direction, buttonSize, children, disabled } as any);

    function onClick(event, dataItem) {
        if (disabled) {
            return;
        }

        if (isDisabled(dataItem)) {
            return;
        }
        if (dataItem?.isOnChange && onChange) {
            onChange();
        }
        setDropdownOpen(false);
        if (dataItem && dataItem.onClick) {
            event.stopPropagation();
            dataItem.onClick(tableRowItem, event);
        }
    }

    function isDisabled(dataItem) {
        if (dataItem && dataItem.isDisabled) {
            if (isFunction(dataItem.isDisabled)) {
                return dataItem.isDisabled(tableRowItem);
            }
            return true;
        }
        return false;
    }

    function isDestructive(dataItem) {
        return dataItem.type && dataItem.type === 'destructive';
    }

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction} className={clsx(classes.dropdownContent, className)}>
            <DropdownToggle className={classes.dropdownButton} name="dropdownButton"/*data-tip={title}*/>
                <div className={classes.content}>
                    { buttonIconId && buttonIconPosition === 'before' && <Svg iconId={buttonIconId} style={classes.before} />}
                    <div className={classes.title}>{children}</div>
                    { !buttonIconPosition && buttonIconId && <Svg iconId={buttonIconId} style={classes.icon} /> }
                    { buttonIconId && buttonIconPosition === 'after' && <Svg iconId={buttonIconId} style={classes.after} />}
                </div>
            </DropdownToggle>
            <CostumReactTooltip />
            <DropdownMenu className={classes.dropdown}>
                <div>
                    { itemList && itemList.map((dataItem, index) => (!dataItem.type && (

                        <div key={index} className={clsx(classes.dataItem, isDisabled(dataItem) && classes.disabled)} onClick={event => onClick(event, dataItem)} onKeyDown={event => onClick(event, dataItem)} role="button" tabIndex={0}>
                            {dataItem.iconId && <Svg iconId={dataItem.iconId} style={clsx(classes.functionIcons, isDestructive(dataItem) && classes.functionDestructiveIcon, isDisabled(dataItem) && classes.functionDisabledIcon)} />}
                            {dataItem.title}
                        </div>
                    )))}
                </div>
                <div className={classes.buttom}>
                    { itemList && itemList.map((dataItem, index) => (dataItem.type && (
                        <div key={index} className={clsx(classes.destructive, isDisabled(dataItem) && classes.disabled)} onClick={event => onClick(event, dataItem)} onKeyDown={event => onClick(event, dataItem)} role="button" tabIndex={0}>
                            {dataItem.iconId && <Svg iconId={dataItem.iconId} style={clsx(classes.functionIcons, isDestructive(dataItem) && classes.functionDestructiveIcon, isDisabled(dataItem) && classes.functionDisabledIcon)} />}
                            {dataItem.title}
                        </div>
                    )))}
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}
