export const PARTNER = {
    TYPES: {
        PARTNER: 1,
        COMPANY: 2,
        PARTNER_AND_COMPANY: 3,
        DISCTRICT: 3,
    },
    PERIOD: {
        PARTNER: 0,
        COMPANY: 1,
    },
    ACTIVE_STATUS: {
        INACTIVE: 0,
        ACTIVE: 1,
        ALL: 2,
    },
};
