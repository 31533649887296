import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAPIEndpoint } from 'shared/src/routing';
import { domHelper, phpHelper } from '@utils';
import { userActions, errorActions } from '@states/actions';
import { sessionService, phpService } from '@services';

export function usePhpEventHandler() {
    const dispatch = useDispatch();
    const history = useHistory();
    const userId = useSelector((state: any) => state.user?.user?.id);
    const isCheckedUserToken = useSelector((state: any) => state.user?.isCheckedUserToken);

    const [isCheckedPhpSession, setIsCheckedPhpSession] = useState(false);
    const [hasPhpSession, setHasPhpSession] = useState(false);

    //php ctrl + click event handler
    useEffect(() => {
        window.addEventListener('click', domHelper.handleLinksCtrlClick);
    }, []);

    //if php page opened in new tab
    useEffect(() => {
        if (phpHelper.checkHrefIsNewTabLink(history.location.hash)) {
            const agentName = sessionService.getAgentName();
            let locationUrl = history.location.pathname;

            locationUrl = getAPIEndpoint(history.location.hash, agentName);
            history.replace(locationUrl); //remove php url (#1/1) from browser url
        }
    }, [history]);

    //onload php session check
    useEffect(() => {
        async function checkPhpSession() {
            try {
                const hasSession = await phpService.checkPhpSession();

                if (!hasSession) {
                    dispatch(userActions.logout());
                    setHasPhpSession(false);
                } else {
                    setHasPhpSession(true);
                }

                setIsCheckedPhpSession(true);
            } catch (error) {
                dispatch(errorActions.setError(error, 'ScreenLoader'));
            }
        }

        if (isCheckedUserToken && userId) {
            checkPhpSession();
        } else if (isCheckedUserToken && !userId) {
            setHasPhpSession(false);
        }
    }, [dispatch, isCheckedUserToken, userId]);

    return {
        isCheckedPhpSession,
        hasPhpSession,
    };
}
