import { arrayUtils, numberUtils } from 'shared/src/modules';
import { GENERAL } from 'shared/src/constants';
import { isArray, isNull } from 'lodash';
import { simpleAction } from './baseActions';

const dataListsActions = {
    addDataList,
    addNewProductionYear,
    removeDataList,
    getCultureDataList,
    getWarehouses,
    getFarmDataList,
    //setterek
    ADD_DATA_LIST: 'DATA_LIST_ADD_DATA_LIST',
    ADD_PRODUCTION_YEAR: 'DATA_LIST_ADD_PRODUCTION_YEAR',
    REMOVE_DATA_LIST: 'DATA_LIST_REMOVE_DATA_LIST',
};

export { dataListsActions };

function addDataList(dataList, listName) {
    return simpleAction(dataListsActions.ADD_DATA_LIST, { listName, dataList });
}

function removeDataList(listName) {
    return simpleAction(dataListsActions.REMOVE_DATA_LIST, { listName });
}

function addNewProductionYear(periodYear) {
    return simpleAction(dataListsActions.ADD_PRODUCTION_YEAR, { periodYear });
}

function getCultureDataList(status = null, selectedCultureId) {
    return (__, getState) => {
        const { dataLists } = getState();
        let cultureList = [];

        if (isNull(status)) {
            cultureList = dataLists?.cultures;
        } else {
            cultureList = dataLists?.cultures?.filter(culture => (isArray(status) ? status.includes(culture.isActive) : +status === +culture.isActive));
        }

        if (numberUtils.isNumber(selectedCultureId)) {
            const selectedInactiveCulture = dataLists?.cultures?.find(culture => culture.isActive === GENERAL.FILTER.STATUS.INACTIV && culture.id === selectedCultureId);
            if (selectedInactiveCulture) {
                cultureList.push(selectedInactiveCulture);
                arrayUtils.sortByParamAsc(cultureList, 'shortName');
            }
        }

        return cultureList;
    };
}

function getWarehouses() {
    return (__, getState) => {
        const { dataLists } = getState();

        return dataLists?.warehouses;
    };
}

function getFarmDataList(status = null, selectedFarmId) {
    return (__, getState) => {
        const { dataLists } = getState();
        let farmList = [];

        if (isNull(status)) {
            farmList = dataLists?.farms;
        } else {
            farmList = dataLists?.farms?.filter(farm => (isArray(status) ? status.includes(farm.isActive) : +status === +farm.isActive));
        }

        if (numberUtils.isNumber(selectedFarmId)) {
            const selectedInactiveFarm = dataLists?.farms?.find(farm => farm.isActive === GENERAL.FILTER.STATUS.INACTIV && farm.id === selectedFarmId);
            if (selectedInactiveFarm) {
                farmList.push(selectedInactiveFarm);
                arrayUtils.sortByParamAsc(farmList, 'name');
            }
        }

        return farmList;
    };
}
