import React from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { MapPolygon } from '../../../formatters';

const useStyles = createUseStyles({
    mapCellWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    testAreaImage: {
        width: 18,
        height: 18,
        right: 28,
        position: 'relative',
    },
});

export default function MapCell(props) {
    const { mapId, rowData, onClick, isTestAreaId } = props;
    const classes = useStyles();
    const history = useHistory();

    function getMapSvg() {
        if (mapId && rowData && rowData[mapId]) {
            return rowData[mapId];
        }

        if (props.cellValue) {
            return props.cellValue;
        }
        return null;
    }

    function getIsTestArea() {
        if (isTestAreaId) {
            return !!arrayUtils.getArrayItemByKeyString(rowData, isTestAreaId);
        }
        return false;
    }

    function onClickPolygon(event) {
        event.stopPropagation();

        if (onClick) {
            onClick(rowData, history, event);
        }
    }

    return (
        <div className={classes.mapCellWrapper}>
            <MapPolygon mapSvg={getMapSvg()} onClick={event => onClickPolygon(event)} />
            {getIsTestArea() && <img src="/styles/img/menu/kiserlet.svg" className={classes.testAreaImage} alt="testAreaImage" />}
        </div>
    );
}
