import React from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import FormGroup from '@customComponents/form/FormGroup';
import CultureDropdown from '@customComponents/dropdowns/cultureDropdown/CultureDropdown';
import CultureVarietyDropDown from '@customComponents/dropdowns/cultureDropdown/CultureVarietyDropDown';

const useStyles = createUseStyles({
    inputSpace: {
        marginTop: 15,
    },
});

export default function CultureAndVarietySelect(props) {
    const { culture, setCulture, variety, setVariety } = props;
    const { t } = useTranslate();
    const classes = useStyles();

    return (
        <>
            <FormGroup label={t('closeOpenCultivationPeriod.culture', 'Culture')} hasValue={culture} className={classes.inputSpace}>
                <CultureDropdown
                    cultureId={culture}
                    setCultureId={value => setCulture(value)}
                />
            </FormGroup>
            <FormGroup label={t('closeOpenCultivationPeriod.variety', 'Variety')} hasValue={variety} className={classes.inputSpace}>
                <CultureVarietyDropDown
                    variety={variety}
                    onChange={value => setVariety(value)}
                    culture={culture}
                    setCulture={value => setCulture(value)}
                />
            </FormGroup>
        </>
    );
}
