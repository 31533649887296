import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Translation from './Translation';

export default function useTranslate() {
    const { t: translater } = useTranslation() as any;

    const t = useCallback(
        (moduleAndKey, defaultText) => <Translation moduleAndKey={moduleAndKey} defaultText={defaultText} />,
        [],
    );

    return {
        t,
        translater,
    };
}
