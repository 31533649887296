/* eslint-disable id-match */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { createUseStyles } from 'react-jss';
import { errorActions } from '@states/actions';
import { isNull, isArray, uniqBy, isEmpty } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import Svg from '@baseComponents/Svg';
import FormSelect from '@baseComponents/select/formSelect/FormSelect';
import { Form } from 'react-bootstrap';
import { MESSAGES } from 'shared/src/constants';
import { messageService } from '@services';
import { arrayUtils } from 'shared/src/modules';
import { setPageLoading } from '@baseComponents/PageLoader';
import NoData from '@baseComponents/chips/NoData';
import Message from './Message';

const useStyles = createUseStyles((theme: any) => ({
    messageContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        overflowY: 'auto',
        ...theme.verticalScrollbar,
    },
    menuBottomControls: {
        display: 'flex',
        paddingTop: '10px',
    },
    menuBottomFormSelect: {
        width: 'max-content',
        paddingLeft: '30px',
    },
    menuBottomControlsSearchBar: {
        borderRadius: 4,
        border: `1px solid ${theme.color.gray}`,
        textAlign: 'left',
        width: '200px',
        marginLeft: 'auto',
        '&:focus-within': {
            border: `1px solid ${theme.color.main}`,
            color: theme.color.jet,
        },
    },
    menuBottomControlsSearch: {
        width: '20px',
        height: '20px',
    },
    menuBottomControlsSearchButton: {
        border: 'none',
        background: 'none',
        margin: '0',
        padding: '0',
        marginRight: '20px',
        marginLeft: (props: any) => (props.showSearchBar ? '0' : 'auto'),
    },
    noData: {
        fontSize: 15,
    },
}));

export default function MessageList(props) {
    const { setUnreadMessagesNumber, user, selectedMessage, setSelection, changeFlag } = props;
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [messagesType, setMessagesType] = useState(MESSAGES.TYPE.ALL);
    const [messages, setMessages] = useState<any>([]);
    const [tempMessages, setTempMessages] = useState<any>([]);
    const classes = useStyles({ showSearchBar } as any);
    const { t, translater } = useTranslate();
    const dispatch = useDispatch();
    const location = useLocation().pathname;

    useEffect(() => {
        async function loadMessages() {
            try {
                setPageLoading(true);

                if (messagesType === MESSAGES.TYPE.ALL) {
                    let incomingMessages = await messageService.getMessages(MESSAGES.TYPE.INCOMING);
                    let outgoingMessages = await messageService.getMessages(MESSAGES.TYPE.OUTGOING);

                    if (!isArray(incomingMessages)) {
                        incomingMessages = [];
                    }
                    if (!isArray(outgoingMessages)) {
                        outgoingMessages = [];
                    }

                    incomingMessages.forEach(messageElement => { messageElement.direction = MESSAGES.TYPE.INCOMING; });
                    outgoingMessages.forEach(messageElement => { messageElement.direction = MESSAGES.TYPE.OUTGOING; });

                    let allMessages = [...incomingMessages, ...outgoingMessages];
                    arrayUtils.sortDateByParamDesc(allMessages, 'date');

                    allMessages = uniqBy(allMessages, 'id');

                    setMessages(allMessages);
                    setTempMessages(allMessages);
                } else {
                    let receivedMessages = await messageService.getMessages(messagesType);

                    if (!isArray(receivedMessages)) {
                        receivedMessages = [];
                    }

                    receivedMessages.forEach(messageElement => { messageElement.direction = messagesType; });

                    setMessages(receivedMessages);
                    setTempMessages(receivedMessages);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            } finally {
                setPageLoading(false);
            }
        }

        loadMessages();
    }, [messagesType, changeFlag, location, dispatch]);

    function setMessageType(inputMessageType) {
        setShowSearchBar(false);

        const messageType = parseInt(inputMessageType, 10);
        setMessagesType(messageType);
    }

    const toggleSearchBar = () => {
        setMessages(tempMessages);
        setShowSearchBar(!showSearchBar);
    };

    function filterMessages(event) {
        const filterPhrases = event.target.value.toString().toLowerCase();
        const filteredMessages = getFilteredMessages(filterPhrases);
        setMessages(filteredMessages);
    }

    function getFilteredMessages(input) {
        function getMessageUser(message) {
            if (message.user && messagesType === MESSAGES.TYPE.INCOMING) {
                return message?.user?.name;
            }
            if (message.messageToUser) {
                return message?.messageToUser?.[0]?.user?.name;
            }
            return translater('messages.systemMessage', 'System message');
        }

        return tempMessages.filter(message => [
            getMessageUser(message),
            message.subject,
            message.message,
        ].some(property => property.toLowerCase().includes(input)));
    }

    async function loadUnreadMessagesNumber() {
        try {
            messageService.setSystemMessagesRead().then(isSuccesUpdate => {
                if (isSuccesUpdate) {
                    messageService.getUnreadMeassagesCount().then(unreadMessages => {
                        setUnreadMessagesNumber(unreadMessages?.messageCount || 0);
                    }).catch(error => { throw error; });
                }
            });
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }

    return (
        <>
            <div className={classes.menuBottomControls}>
                <FormSelect
                    className={`${classes.menuBottomFormSelect} messages_menu_form_select`}
                    dataList={[
                        { key: MESSAGES.TYPE.ALL, value: t('messages.all_short', 'All') },
                        { key: MESSAGES.TYPE.INCOMING, value: t('messages.incoming_short', 'Incoming') },
                        { key: MESSAGES.TYPE.OUTGOING, value: t('messages.outgoing_short', 'Outgoing') },
                    ]}
                    onChange={setMessageType}
                    selected={MESSAGES.TYPE.ALL}
                />
                {showSearchBar && (
                    <Form.Control className={classes.menuBottomControlsSearchBar} placeholder={translater('default.search', 'Search')} onChange={filterMessages} />
                )}
                <button onClick={toggleSearchBar} className={classes.menuBottomControlsSearchButton} type="button">
                    <Svg style={classes.menuBottomControlsSearch} iconId="icon-search" />
                </button>
            </div>
            <div className={classes.messageContainer}>
                {!isEmpty(messages)
                    ? (
                        messages && messages.map(message => (
                            <Message
                                key={`message_${message.id}_${message.typeId || 'system'}`}
                                message={message}
                                selected={isNull(selectedMessage) ? false : (selectedMessage.id === message.id)}
                                loadUnreadMessagesNumber={loadUnreadMessagesNumber}
                                messagesType={message.direction}
                                setSelectedMessage={setSelection}
                                resetAddressNumber={() => {}}
                                addressNumber={0}
                                userId={user.id}
                            />
                        )))
                    : <NoData className={classes.noData} />
                }
            </div>
        </>
    );
}
