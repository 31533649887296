/* eslint-disable id-blacklist */
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Svg from '../baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: 80,
    },
    dropdownToggle: {
        minWidth: 'auto',
        height: 'auto',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 10,
        paddingRight: 10,
        background: theme.color.white,
        border: 'none',
        borderRadius: 6,
        '&:hover': {
            cursor: 'pointer',
            borderRadius: 6,
            background: theme.color.lilian,
        },
        '&:focus': {
            background: theme.color.lilian,
        },
        '&:active': {
            background: theme.color.lilian,
        },
    },
    toggleFlag: {
        width: 22,
        height: 16,
        marginRight: 5,
        border: 'none',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        cursor: 'inherit',
    },
    icon: {
        height: 20,
        width: 20,
        float: 'right',
    },
    dropdown: {
        width: 225,
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 6,
        border: 'none',
        boxShadow: '0 6px 22px 4px rgba(33, 40, 44, 0.1), 0 12px 18px 2px rgba(33, 40, 44, 0.15), 0 4px 8px -4px rgba(33, 40, 44, 0.2)',
        backgroundColor: theme.color.white,
        top: 'auto !important',
        left: '102px !important',
        bottom: '35px !important',
        transform: 'translateX(-50%) !important',
        outline: 'none',
    },
    item: {
        height: 35,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        '&:hover': {
            background: theme.color.lilian,
            color: theme.color.main,
            cursor: 'pointer',
            borderRadius: 6,
        },
    },
    flag: {
        width: 22,
        height: 16,
        marginRight: 10,
        border: 'none',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        cursor: 'inherit',
    },
    itemText: {
        width: 150,
        fontSize: 14,
        color: 'inherit',
        ...theme.firstLetterUppercase,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        marginBottom: 0,
        cursor: 'inherit',
    },
}));

export default function SelectLanguage(props) {
    const { langList, langCode, setLangCode } = props;
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(!dropdownOpen);
    const classes = useStyles();

    function selectItem(selectedLangCode) {
        setDropdownOpen(false);
        setLangCode(selectedLangCode);
    }

    return (
        <div className={classes.container}>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className={classes.dropdownToggle}>
                    <img className={classes.toggleFlag} src={`/styles/img/flags/${langCode?.toUpperCase()}.png`} alt="" />
                    <Svg iconId={!dropdownOpen ? 'icon-expand_more' : 'icon-expand_less'} style={classes.icon} />
                </DropdownToggle>
                <DropdownMenu className={classes.dropdown}>
                    <div>
                        { langList.map(item => (
                            <div key={item.langCode} className={classes.item} onClick={() => selectItem(item.langCode)} onKeyDown={() => selectItem(item.langCode)} role="button" tabIndex={0}>
                                <img className={classes.flag} src={`/styles/img/flags/${item.langCode}.png`} alt="" /><label className={classes.itemText} title={item.name}>{item.name}</label>
                            </div>
                        ))}
                    </div>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
}
