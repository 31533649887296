import { useSelector } from 'react-redux';
import React, { useEffect } from 'react';
import { ModalTemplate } from '@baseComponents/modals';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { createUseStyles } from 'react-jss';
import SoilAnalysisImportBody from './SoilAnalysisImportBody';
import SoilAnalysisImportHeader from './SoilAnalysisImportHeader';
import SoilAnalysisImportFooter from './SoilAnalysisImportFooter';

const useStyles = createUseStyles({
    modalContainer: {
        display: 'block',
    },
});

export default function SoilAnalysisImport(props) {
    const { hideModal, isExcelImport, cultivationPeriodId } = props;
    const classes = useStyles();

    const cultivationPeriodList = useSelector((state: any) => state.basePage.table.tableData || []);
    const companyId = cultivationPeriodList.find(period => period.id === cultivationPeriodId)?.companyId;

    const validationSchema = Yup.object().shape({
        soilAnalysisData: Yup.array().required('No data imported'),
    });

    const methods = useForm({
        defaultValues: {
            isExcelImport,
            companyId,
        },
        resolver: yupResolver(validationSchema),
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });

    return (
        <ModalTemplate hideModal={hideModal} className={classes.modalContainer}>
            <FormProvider {...methods}>
                <SoilAnalysisImportHeader />
                <SoilAnalysisImportBody
                    isExcelImport={isExcelImport}
                    cultivationPeriodId={cultivationPeriodId}
                />
                <SoilAnalysisImportFooter
                    hideModal={hideModal}
                />
            </FormProvider>
        </ModalTemplate>
    );
}
