import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    badge: {
        borderRadius: 4,
        backgroundColor: theme.color.raven,
        color: theme.color.white,
        fontWeight: 500,
        display: 'inline-block',
        padding: '3px 10px',
        fontSize: 14,
        textTransform: 'lowercase',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
}));

export default function TitleBadge(props) {
    const { className, children } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.badge, className)}>
            {children}
        </div>
    );
}
