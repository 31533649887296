import React from 'react';
import useTranslate from '@i18n/useTranslate';
import { useDispatch } from 'react-redux';
import { cultivationPeriodsService } from '@services';
import { alertActions, basePageActions, errorActions } from '@states/actions';
import { ConfirmDialog } from '@baseComponents/modals';

export default function FixOrUnlockPeriod(props) {
    const { periodIds, isFixing, hideModal } = props;
    const dispatch = useDispatch();
    const { t } = useTranslate();

    function getTitleAndMessage() {
        let title = t('cultivationPeriods.recordCultivationPeriod', 'Record cultivation period');
        let message = t('cultivationPeriods.surelyRecordCultivationPeriod', 'Are you sure to record the selected cultivation period?');

        if (!isFixing) {
            title = t('cultivationPeriods.unlockCultivationPeriod', 'Unlock cultivation period');
            message = t('cultivationPeriods.surelyUnlockCultivationPeriod', 'Are you sure to unlock the selected cultivation period?');
        }

        if (periodIds?.length > 1) {
            title = t('cultivationPeriods.recordCultivationPeriods', 'Unlock cultivation periods');
            message = t('cultivationPeriods.surelyRecordCultivationPeriods', 'Are you sure to record the selected cultivation period?');

            if (!isFixing) {
                title = t('cultivationPeriods.unlockCultivationPeriods', 'Unlock cultivation periods');
                message = t('cultivationPeriods.surelyUnlockCultivationPeriods', 'Are you sure to disengage the selected cultivation periods?');
            }
        }

        return {
            title,
            message,
        };
    }

    async function onClick() {
        try {
            const fixOrUnlockCultivationPeriod = await cultivationPeriodsService.fixOrUnlockCultivationPeriod({ periodIds, isFixing });

            if (fixOrUnlockCultivationPeriod) {
                dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved.')));
                dispatch(basePageActions.setReloadTable());
                hideModal();
            } else {
                dispatch(alertActions.errorAlert(t('default.failedSave', 'Failed saved.')));
            }
        } catch (error) {
            dispatch(errorActions.setError(error, 'Fix or unlock cultivation period'));
        }
    }

    return (
        <ConfirmDialog
            iconId={isFixing ? 'icon-lock_locked' : 'icon-lock_unlocked'}
            isAcceptCancelDialog
            acceptButtonText={t('default.yes', 'Yes')}
            cancelButtonText={t('default.no', 'No')}
            questionText={getTitleAndMessage()?.title}
            descriptionText={getTitleAndMessage()?.message}
            confirmCallback={onClick}
            hideModal={hideModal}
        />
    );
}
