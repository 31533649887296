import Button from '@baseComponents/buttons/Button';
import useTranslate from '@i18n/useTranslate';
import { ThemeType } from '@map/services/mapTypes';
import clsx from 'clsx';
import { isFunction } from 'lodash';
import { createUseStyles } from 'react-jss';

type Classes = 'label' | 'labelDot' | 'labelContainer' | 'labelDotError' |
    'locationContainer' | 'locationValueContainer';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    label: {
        height: 15,
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    labelDot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: theme.color.main,
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    labelDotError: {
        backgroundColor: theme.color.status_error,
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: 'fit-content',
        marginTop: 10,
    },
    locationContainer: {
        display: 'flex',
    },
    locationValueContainer: {
        flex: 1,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 3,
        margin: [0, 6, 0, 0],
        borderColor: theme.color.gray,
        boxShadow: theme.shadows.secondary.normal,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 5,
    },
}));

export const Coordinates = (props: any) => {
    const { longitude, latitude, onChangeLocationCoors, errorMessage, isInvalid, marker } = props;

    const classes = useStyles();

    const { t } = useTranslate();

    const onClearCoordinates = () => {
        if (isFunction(onChangeLocationCoors)) {
            onChangeLocationCoors();
        }
        marker.current?.setPosition(null);
    };

    return (
        <>
            <div className={classes.labelContainer}>
                <p className={classes.label}>{t('meteorology.coordinates', 'Coordinates')}</p>
                <div className={clsx(classes.labelDot, { [classes.labelDotError]: isInvalid })} />
            </div>
            <div className={classes.locationContainer}>
                <div className={classes.locationValueContainer}>
                    <span>{latitude?.toFixed(5) ?? ''}</span>
                </div>
                <div className={classes.locationValueContainer}>
                    <span>{longitude?.toFixed(5) ?? ''}</span>
                </div>
                <Button type="destructive" iconId="icon-bin" onClick={onClearCoordinates} />
            </div>
        </>
    );
};
