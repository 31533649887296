import React from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    noDataCard: {
        margin: 0,
        marginTop: 15,
        padding: 0,
        paddingLeft: 10,
        display: 'inline-block',
        color: theme.color.jet,
        fontSize: '14px !important',
        fontWeight: 500,
    },
}));

export default function NoDataCard(props) {
    const { className } = props;
    const { t } = useTranslate();
    const classes = useStyles();

    return (
        <h3 className={clsx(classes.noDataCard, className)}>
            {t('default.noData', 'No data to display')}
        </h3>
    );
}
