export const LANGUAGE = {
    ARGENTINA: 'AR',
    BULGARIAN: 'BG',
    GERMAN: 'DE',
    ENGLISH: 'EN',
    HUNGARIAN: 'HU',
    ROMANIAN: 'RO',
    RUSSIAN: 'RU',
    SLOVAK: 'SK',
    UKRAINIAN: 'UK',
    AZERBAIJANI: 'AZ',
    SERBIAN: 'SR',
    NAMESPACE: {
        DEFAULT: 'translation',
    },
    LANGUAGE_CODE: { //lang codes to datepicker
        AR: 'es',
        BG: 'bg',
        DE: 'de',
        EN: 'en-GB',
        HU: 'hu',
        RO: 'ro',
        RU: 'ru',
        SK: 'sk',
        UK: 'uk',
        AZ: 'az',
        SR: 'sr-Latn',
    },
};

export const TRANSLATER_LANGUAGE_CODES = [
    LANGUAGE.ARGENTINA.toLowerCase(),
    LANGUAGE.BULGARIAN.toLowerCase(),
    LANGUAGE.GERMAN.toLowerCase(),
    LANGUAGE.ENGLISH.toLowerCase(),
    LANGUAGE.HUNGARIAN.toLowerCase(),
    LANGUAGE.ROMANIAN.toLowerCase(),
    LANGUAGE.RUSSIAN.toLowerCase(),
    LANGUAGE.SLOVAK.toLowerCase(),
    LANGUAGE.UKRAINIAN.toLowerCase(),
    LANGUAGE.AZERBAIJANI.toLowerCase(),
    LANGUAGE.SERBIAN.toLowerCase(),
];
