/* eslint-disable no-console */
/* eslint-disable no-cond-assign */
/* eslint-disable no-useless-escape */
import { domHelper } from '@utils/dom/domHelper';
import { numberUtils } from 'shared/src/modules/numberUtils';
import { isUndefined, isEmpty } from 'lodash';
import { phpService, sessionService } from '@services';
import { getAPIEndpoint } from 'shared/src/routing';

const fixingSearchNames = [
    'search',
    'search-hrsz',
    'search-szerzodesek',
    'search-alhaszonberlet',
    'search-kifizetesek',
    'search-idoszak',
    'search-hozamolas',
    'search_agronomia',
    'search_operativ',

    'search-termesztesi-tabla-modal',
    'search-egyed',
    'search-torzs',
    'search-modal',
    'search-data',
    'search-raktar',
    'search-felhasznalo',
    'search-sablon',
    'search-kifizetesek',
    'search-tombok',
    'search-sorszamtombok',
    'search-egyseglista',
    'search-egysegar',
    'search_kif_jegy_tomb_ro',
    'search-szerzodesek-edit',
    'search-szerzodesek',
    'search-alhaszonberlet',
    'search_tar_modal',
    'search-besorolas',
    'search-koltseg',
    'search-teljesitmeny',
    'search-allattenyesztes',
    'search-novenytermesztes',
    'search-eszkoz',
    'search-munkaero',
    'search-besorolas-modal',
    'search-gep',
    'search_csoportos_gepkolseg',
    'search-gep-tab-lista-modal',
    'search-gepsor',
    'search-gepkapcsolatok',
    'search-mepar-munkamuvelet-modal',
    'search-idoszak',
    'search-mepar-tabla-modal',
    'search-mepar-munkamuvelet-modal',
    'search-szall-import-modal',
    'search-hozamolas',
    'search-alap-elem',
    'search_bizonylat',
    'search_leltar',
    'search_selejtezes',
    'search_tarolasi_veszteseg',
    'search_tulajdon',
    'search_felvasarlas',
    'search_szamla',
    'search_szamla2',
    'search_atmozgatas',
    'search_ujraminosites',
    'search_termenyertekesites_szerzodes',
    'search_megrendeles',
    'search-anyk',
    'search-1',
    'search-2',
    'search-3',
    'search-4',
    'search-5',
    'search-6',
    'search-7',
    'search-8',
    'search-9',
    'search-10',
    'search-11',
    'search-12',
    'search_kulso_rendszam',
    'search_gepkocsivezeto',
    'search_felrako',
    'search_szallito',
    'search_vevo',
    'search_termek',
    'search_megjegyzes',
    'search_input',
    'search-munkaero-tab-lista-modal',
    'search-partner',
    'search-modal-focsoport-edit',
    'search-tabla',
    'search-szerviz',
    'search-tankolas',
    'search-modal-szl',
    'search-termesztesi-partner-modal',
    'search-elszamolo-aras-termekek',
    'search-tenyleges-ar-valtozas-modal',
    'search-ertekesitesi-arak',
    'search-onkoltseg',
    'search_tetel',
    'search_dij',
    'search-raterhelt-koltseg',
    'search_terv',
    'search-gepek',
    'modal_kiserlet_search',
    'search-gepek',
    'search_tar_modal',
    'modal_idoszak_search',
    'search-fenntarthatosag',
];

function runScripts(scripts) {
    try {
        window.eval(scripts);
    } catch (error) {
        console.error(error);
    }
}

async function loadDefaultScripts() {
    try {
        const defaultInfoScripts = await phpService.getPhpDefaultInfo();
        const defaultInfoDiv = document.getElementById('defaultInfo') as HTMLElement;

        if (!isUndefined(defaultInfoDiv)) {
            defaultInfoDiv.innerHTML = defaultInfoScripts;
            const scripts = defaultInfoDiv.getElementsByTagName('script');

            if (!isUndefined(scripts)) {
                const scriptList = Array.prototype.slice.call(scripts);
                scriptList.forEach(script => {
                    runScripts(script.innerText);
                });
            }
        }
    } catch (error: any) {
        throw new Error(error);
    }
}

function getScriptsFromContent(content) {
    const wrapperDiv = document.createElement('div');
    wrapperDiv.innerHTML = content;
    const scripts = wrapperDiv.getElementsByTagName('script');
    wrapperDiv.remove();
    const scriptsList = Array.prototype.slice.call(scripts);

    return scriptsList.filter(script => script.type !== 'reactEventHandler');
}

function getReactScriptsFromContent(content) {
    const wrapperDiv = document.createElement('div');
    wrapperDiv.innerHTML = content;
    const scripts = wrapperDiv.getElementsByTagName('script');
    wrapperDiv.remove();
    const scriptsList = Array.prototype.slice.call(scripts);

    return [scriptsList.find(script => script.type === 'reactEventHandler')];
}

function getPureContent(content, extractscript) {
    const pureContent = content;
    extractscript.map(script => pureContent.replace(script.outerHTML, ''));

    return pureContent;
}

function cleanAndRunScripts(scripts) {
    try {
        scripts.forEach(script => {
            const scriptLines = script.outerHTML.split('\n');
            scriptLines.pop();
            scriptLines.shift();
            let cleanScript = scriptLines.join('\n');

            //redirect replace
            cleanScript = cleanScript.split('window.open(').join('window.openUrlWindowInReact(');
            cleanScript = cleanScript.split('$(location).attr(').join('window.openUrlInReact(');

            runScripts(cleanScript);
        });
    } catch (error: any) {
        throw new Error(error);
    }
}

function changeLanguage(langCode) {
    const agentName = sessionService.getAgentName();
    setCookie(`avlang_${agentName}`, langCode, 20 * 365);
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue}; ${expires}; path=/`;
}

function changeSearchBarFormat() {
    fixingSearchNames.forEach(searchBarName => {
        const searchBar = document.getElementById(searchBarName);
        if (searchBar) {
            const searchBarRootKind1 = searchBar.parentElement?.parentElement;
            if (searchBarRootKind1 && searchBarRootKind1.style.marginBottom === '10px') {
                searchBarRootKind1.style.marginBottom = '0px';
            } else {
                const searchBarRootKind2 = searchBar.parentElement?.parentElement?.parentElement;
                if (searchBarRootKind2) {
                    searchBarRootKind2.style.marginRight = '20px';
                    searchBarRootKind2.style.marginBottom = '0px';
                }
            }
        }
    });
}

function checkHrefStartHashmark(hrefUrl) {
    if (hrefUrl && hrefUrl.charAt(0) === '#') {
        return true;
    }

    return false;
}

function checkHrefIsTabControl(hrefUrl) {
    if (hrefUrl && hrefUrl.split('#')[1].toString().split('/').length < 2) { //tab control
        return true;
    }

    return false;
}

function checkHrefIsFullUrl(hrefUrl) {
    const httpPattern = /^((http|https):\/\/)/;

    if (httpPattern.test(hrefUrl)) {
        return true;
    }

    return false;
}

function checkHrefIsNewTabLink(url) {
    if (url) {
        const hrefParts = url.split('#');
        if (hrefParts) {
            const hrefPartsWithoutParams = hrefParts[1].split('?');
            const hashParts = hrefPartsWithoutParams[0].toString().split('/');
            if (hashParts.length === 2 && numberUtils.isNumber(hashParts[0]) && numberUtils.isNumber(hashParts[1])) {
                return true;
            }
        }
    }

    return false;
}

function getParamsFromNewTabLink(url) {
    if (url) {
        const hrefParts = url.split('#');
        if (hrefParts) {
            const hrefPartParams = hrefParts[1].split('?');
            if (hrefPartParams.length > 1) {
                return `?${hrefPartParams[1]}`;
            }
        }
    }

    return '';
}

function checkHrefTargetisBlank(hrefTarget) {
    if (hrefTarget === '_blank') {
        return true;
    }

    return false;
}

function getHrefExportUrl(hrefUrl) {
    if (hrefUrl && hrefUrl.includes('export.php')) {
        const urlFull = hrefUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').toString();
        const urlEnd = urlFull.replace(urlFull.split('/')[0], '');

        return { exportLink: urlEnd };
    }

    return null;
}

function getQtipIdFromNode(node) {
    if (!node) {
        return null;
    }

    const nodeQtip = node.getAttribute('data-hasqtip');
    let nodeParentQtip = null;
    if (node.parentElement) {
        nodeParentQtip = node.parentElement.getAttribute('data-hasqtip');
    }

    return nodeQtip || nodeParentQtip;
}

function checkContentHrefSubmenu(hrefTarget) {
    const qtipClassName = 'qtip';
    let hrefElement = hrefTarget;

    // eslint-disable-next-line curly
    while ((hrefElement = hrefElement.parentElement) && !hrefElement.classList.contains(qtipClassName));

    return hrefElement;
}

function isPageRedirectHash(hash) {
    const urlRedirectModuleParts = hash.split('/');

    return Number.isInteger(urlRedirectModuleParts[0]) && Number.isInteger(urlRedirectModuleParts[1]);
}

function clearQtipPanels(panels?: any) {
    const qtipPanels = panels || document.querySelectorAll("[id^='qtip-']");

    if (qtipPanels) {
        qtipPanels.forEach(node => {
            node.remove();
        });
    }
}

function useBootstrapTooltip() {
    if ((window as any).useBootstrapTooltip) {
        (window as any).useBootstrapTooltip();
    }
}

function clearPrevMultiselectCache() {
    const prevMultiselectMenus = document.querySelectorAll('.ui-multiselect-menu');

    prevMultiselectMenus?.forEach(multiselectMenu => {
        if (multiselectMenu.id !== 'nav-ceg') {
            multiselectMenu.remove();
        }
    });
}

function getPhpBackButtonUrl(agentName, isPlanningModeEnabled) {
    const backButton = document.querySelector('.btn.btn-default.btn-return');
    if (backButton) {
        const hrefUrl = backButton.getAttribute('href');
        if (hrefUrl) {
            return getAPIEndpoint(hrefUrl, agentName, isPlanningModeEnabled);
        }
    }
    return null;
}

function getRedirectorNodeParent(node, isOnlyButton, isHandleInput) {
    function isButtonNode(actualNode) {
        return domHelper.isButtonNode(actualNode, isHandleInput);
    }

    const maxNodeDepth = 3;
    const parentNode = domHelper.getNodeParentByDepth(node, maxNodeDepth, isButtonNode);

    if (isOnlyButton && !isButtonNode(parentNode)) {
        return null;
    }

    return parentNode;
}

function handleRedirectFunctions(content) {
    let pageContent = content;

    pageContent = pageContent.split('window.open(').join('window.openUrlWindowInReact(');
    pageContent = pageContent.split('$(location).attr(').join('window.openUrlInReact(');

    return pageContent;
}

function handleSelectErrors(content) {
    let pageContent = content;
    const selectRegExp = /(\s|\t|\n)(select|select([a-zA-Z_0-9])+) = document.getElementById\(\$\(\"\#(.*)\"\).attr\(\'id\'\)\);/gm; //select = document.getElementById($("#termek_altipus_filter").attr('id'));
    const selectParts = new Set(pageContent.match(selectRegExp));

    if (!isEmpty(selectParts)) {
        selectParts.forEach((selectPart: any) => {
            const selectPartsRegExp = /((\w+)-(\w+)-(\w+)-(\w+)|(\w+)-(\w+)-(\w+)|(\w+)-(\w+)|(\w+)) \=/gm; //select_ki =
            const selectWithSpace = selectPart.match(selectPartsRegExp);
            if (!isEmpty(selectWithSpace)) {
                const selectName = selectWithSpace[0]?.replace('=', '').replaceAll(' ', ''); //select_ki
                pageContent = pageContent.split(selectPart).join(`${selectPart} if (${selectName} == null) { return; }`);
            }
        });
    }

    return pageContent.split('$select = editor.getSelectEL();').join('$select = editor.getSelectEL(); if ($select == null) { return; }'); //$select = editor.getSelectEL();
}

function handleSlickGridErrors(content, gridAndViewNames) {
    let pageContent = content;
    const gridRegExp = /(\s|\t|\n)((\w+)-(\w+)-(\w+)-(\w+)|(\w+)-(\w+)-(\w+)|(\w+)-(\w+)|(\w+)) = new Slick.Grid\(\"\#(.*?);/gm; // (tab|space|newLine) grid = new Slick.Grid("#grid", dataView, columns, options);
    const gridParts = new Set(pageContent.match(gridRegExp));

    if (!isEmpty(gridParts)) {
        gridParts.forEach((gridPart: any) => {
            const gridIdRegExp = /\"\#(.*?)\"/gm; //#grid
            const gridId = gridPart.match(gridIdRegExp);

            const gridNameRegExp = /((\w+)-(\w+)-(\w+)-(\w+)|(\w+)-(\w+)-(\w+)|(\w+)-(\w+)|(\w+)) =/gm; //grid =
            const dataViewNameRegExp = /\"\, ((\w+)-(\w+)-(\w+)-(\w+)|(\w+)-(\w+)-(\w+)|(\w+)-(\w+)|(\w+)),/gm; //", dataView,

            const gridNameOriginal = gridPart.match(gridNameRegExp);
            const dataViewNameOriginal = gridPart.match(dataViewNameRegExp);

            if (gridNameOriginal && dataViewNameOriginal) {
                const gridName = gridNameOriginal[0]?.replace('=', '').replaceAll(' ', ''); //grid
                const dataViewName = dataViewNameOriginal[0]?.replace('\", ', '').replace(',', ''); //dataView

                //handle clear function errors
                if (gridName) { //grid_tamogatas.invalidateAllRows();
                    const invalidateAllRowsRegexp = new RegExp(`(\\s|\\t|\\n)${gridName}\\.invalidateAllRows\\(\\)\\;`, 'gm'); //separate similar named string: grid.invalidateAllRows(); other_grid.invalidateAllRows();
                    pageContent = pageContent.replace(invalidateAllRowsRegexp, `if (${gridName} == undefined) { return; } ${gridName}.invalidateAllRows();`);
                }

                gridAndViewNames.push({ gridName, dataViewName });
            }

            //handle grid init error -> if ($('#grid').length > 0) grid = new Slick.Grid("#grid", dataView, columns, options); else return;
            if (gridId[0]) {
                pageContent = pageContent.split(gridPart).join(`if ($(${gridId[0]}).length > 0) { ${gridPart} } else { return; }`);
            }
        });
    }

    return pageContent;
}

function handleSlickGridDataViewErrors(content, gridAndViewNames) {
    let pageContent = content;
    const dataViewExp = /(\s|\t|\n)((\w+)-(\w+)-(\w+)-(\w+)|(\w+)-(\w+)-(\w+)|(\w+)-(\w+)|(\w+)).setGrouping\((\{|\[)/gm; //dataView.setGrouping({ || dataView.setGrouping([
    const dataViewParts = new Set(pageContent.match(dataViewExp));

    if (!isEmpty(dataViewParts) && !isEmpty(gridAndViewNames)) {
        dataViewParts.forEach((dataViewPart: any) => {
            const dataViewNameRegExp = /((\w+)-(\w+)-(\w+)-(\w+)|(\w+)-(\w+)-(\w+)|(\w+)-(\w+)|(\w+))\./gm; //dataView.
            const dataViewNameWithDot = dataViewPart.match(dataViewNameRegExp);

            if (dataViewNameWithDot[0]) {
                const dataViewName = dataViewNameWithDot[0].slice(0, -1); //dataView
                const gridAndViewName = gridAndViewNames?.find(nameObject => nameObject.dataViewName === dataViewName);
                const gridName = gridAndViewName?.gridName;

                if (gridName) {
                    const dataViewPartLastCharacter = dataViewPart[dataViewPart.length - 1];
                    const dataViewPartRegexp = new RegExp(`(\\s|\\t|\\n)${dataViewName}\\.setGrouping\\(\\${dataViewPartLastCharacter}`, 'gm');
                    pageContent = pageContent.replace(dataViewPartRegexp, `
                        if (${gridName} == undefined || ${dataViewName}.parent_grid == undefined) { return; }
                        ${dataViewPart}`);
                }
            }
        });
    }

    return pageContent;
}

function handleOldMapErrors(content) { //var original = document.getElementsByTagName("iframe")[0];
    let pageContent = content;

    pageContent = pageContent.split('var original = document.getElementsByTagName("iframe")[0];').join('var original = document.getElementsByTagName("iframe")[0]; if (original == undefined) { return; }');

    return pageContent;
}

function clearOldDomElements() {
    const requiredContainerElements = document.querySelectorAll('.required_container');

    if (requiredContainerElements) {
        requiredContainerElements?.forEach(domElement => {
            const domChildrens = Object.values(domElement.children);
            const requiredPanelNode = domChildrens?.find(requiredPanel => requiredPanel.id === 'required_field_list');
            if (isEmpty(requiredPanelNode?.children)) {
                domElement.remove();
            }
        });
    }
}

function handleCtrlClick(pageContent) { //firefox + jquery specific error
    const ctrlClickEventHandler = `
        <script type="text/javascript">
            $('a').click(function(event) {
                if (event.ctrlKey && event.target && event.target.href && event.target.href.length > 0) {
                    event.preventDefault();
                }
            });
        </script>`;

    return ctrlClickEventHandler + pageContent;
}

export const phpHelper = {
    loadDefaultScripts,
    getScriptsFromContent,
    getReactScriptsFromContent,
    getPureContent,
    cleanAndRunScripts,
    changeLanguage,
    changeSearchBarFormat,
    isPageRedirectHash,
    checkContentHrefSubmenu,
    checkHrefTargetisBlank,

    checkHrefStartHashmark,
    checkHrefIsTabControl,
    checkHrefIsFullUrl,
    checkHrefIsNewTabLink,
    getParamsFromNewTabLink,
    getHrefExportUrl,
    getQtipIdFromNode,

    clearQtipPanels,
    useBootstrapTooltip,
    getPhpBackButtonUrl,
    clearPrevMultiselectCache,
    getRedirectorNodeParent,

    handleRedirectFunctions,
    handleSelectErrors,
    handleSlickGridErrors,
    handleSlickGridDataViewErrors,
    handleOldMapErrors,
    handleCtrlClick,
    clearOldDomElements,
};
