import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { dataListsActions, errorActions } from '@states/actions';
import { GENERAL } from 'shared/src/constants';

export default function useCulturesModalLoadData() {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState();
    const location = useLocation();

    const loadCulturesList = useCallback(async () => {
        try {
            const cultures = dispatch(dataListsActions.getCultureDataList(GENERAL.FILTER.STATUS.ACTIV, null));
            setTableData(cultures?.map(culture => ({
                id: culture.id,
                cultureName: culture.name,
                cultureActive: culture.isActive,
                cultureIsLandleasePayment: culture.isLandleasePayment,
                cultureCode: culture.cultureCode,
                cultureColor: culture.colorCode,
                cultureShortName: culture.shortName,
            })));
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }, [dispatch, location]);

    useEffect(() => {
        loadCulturesList();
    }, [dispatch, loadCulturesList]);

    return {
        tableData,
    };
}
