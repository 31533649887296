import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyle = createUseStyles((theme: any) => ({
    fader: {
        display: 'none', //on visible: block
        position: 'absolute',
        right: props => props.rightPosition,
        top: 0,
        height: props => props.height || theme.table.tableRowFaderHeight,
        width: theme.table.rightPagerWidth,
        background: 'linear-gradient(270deg, #F0F3F4 0%, rgba(240,243,244,0) 100%)',
        zIndex: theme.zIndex.tableColumnFader,
        pointerEvents: 'none',
    },
}));

export default function TableColumnRightFader(props) {
    const { rightPosition, height = null } = props;
    const { reference } = props; //TODO: current table fader
    const classes = useStyle({ rightPosition, height });

    return (
        <div className={clsx(classes.fader, 'rightFader')} ref={reference} />
    );
}
