import { isFunction } from 'lodash';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    switch: {
        position: 'relative',
        display: 'inline-block',
        width: 44,
        height: 26,
        '& input': {
            opacity: 0,
            width: 0,
            height: 0,
        },
        '& .slider': {
            position: 'absolute',
            cursor: 'pointer',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: theme.color.white,
            border: `1px solid ${theme.color.gray}`,
            webkitTransition: '.4s',
            transition: '.4s',
        },
        '& .slider:before': {
            position: 'absolute',
            content: "''",
            height: 20,
            width: 20,
            top: 2,
            left: 2,
            boxShadow: '0 2px 3px 0 rgba(175, 180, 182, 0.15)',
            border: `1px solid ${theme.color.gray}`,
            backgroundColor: theme.color.white,
            webkitTransition: '.4s',
            transition: '.4s',
        },
        '& input:checked + .slider': {
            backgroundColor: theme.color.main,
            boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.3)',
            border: 'solid 2px rgba(255, 255, 255, 0.3)',
        },
        '& input:checked + .slider:before': {
            marginTop: -1,
            webkitTransform: 'translateX(15px)',
            msTransform: 'translateX(15px)',
            transform: 'translateX(15px)',
            border: `1px solid ${theme.color.main}`,
        },
        '& .slider.round': {
            borderRadius: 15,
        },
        '& .slider.round:before': {
            borderRadius: '50%',
        },
    },
}));

export default function Toogle(props) {
    const { isEnabled, title, onStateTitle, offStateTitle, onChange } = props;
    const classes = useStyles();

    function onChangeState(event) {
        const state = event?.target?.checked;

        if (isFunction(onChange)) {
            onChange(state);
        }
    }

    function getTitle() {
        if (title) {
            return title;
        }
        if (isEnabled && offStateTitle) {
            return onStateTitle;
        }
        if (!isEnabled && onStateTitle) {
            return offStateTitle;
        }
        return null;
    }

    return (
        <label className={classes.switch} title={getTitle()}>
            <input type="checkbox" onChange={onChangeState} checked={isEnabled} />
            <span className="slider round" />
        </label>
    );
}
