import { useState } from 'react';
import { MESSAGES } from 'shared/src/constants';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import Svg from '@baseComponents/Svg';
import { isUndefined, isNull } from 'lodash';

import { errorActions } from '@states/actions/errorActions';
import { dateTimeUtils } from 'shared/src/modules';
import { messageService } from '@services';
import { routerHelper } from '@utils/router/RouterHelper';

const useStyles = createUseStyles((theme: any) => ({
    messageListElement: {
        fontWeight: (props: any) => (props.unread ? 700 : 400),
        color: theme.color.jet,
        border: 'none',
        borderRight: '5px solid transparent',
        fontSize: 13,
        cursor: 'pointer',
        width: '100%',
        backgroundColor: (props: any) => (props.selected ? theme.color.opacity.slow : 'transparent'),
        textAlign: 'left',
        '&:hover': {
            backgroundColor: theme.color.mainFaded,
            borderRight: `5px solid ${theme.color.main}`,
        },
        display: 'flex',
        paddingTop: '15px',
        paddingBottom: '15px',
        paddingLeft: '10px',
        paddingRight: '30px',
        alignItems: 'stretch',
    },
    messageListTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    messageListIconContainer: {
        backgroundColor: theme.color.white,
        borderRadius: '50%',
        alignSelf: 'center',
    },
    messageListIcon: {
        height: 36,
        width: 36,
        minWidth: 36,
        minHeight: 36,
        display: 'block',
        backgroundImage: (props: any) => (props.userProfilePicUrl ? `url("${props.userProfilePicUrl}"), url("/styles/img/ures-profil.png")` : 'url("/styles/img/av_logo_slidebar_left.png")'),
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: (props: any) => (props.userProfilePicUrl ? 'contain' : '20px'),
        backgroundPositionX: (props: any) => (props.userProfilePicUrl ? 'center' : '9px'),
        borderRadius: '50%',
        border: (props: any) => (props.userProfilePicUrl ? `1px solid ${theme.color.pearl}` : `1px solid ${theme.color.main}`),
    },
    messageListUserName: {
        display: 'inline-block',
        fontSize: '13px',
        color: theme.color.raven,
    },
    messageListOtherText: {
        marginLeft: 32,
    },
    messageListDate: {
        marginLeft: 'auto',
    },
    arrowIcon: {
        width: '22px',
        height: '15px',
    },
    messageListSubject: {
        fontSize: '14px',
        fontWeight: '400',
        color: theme.color.jet,
    },
    messageListInfo: {
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    messageListUnreadIconContainer: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        visibility: (props: any) => (props.unread ? 'visible' : 'hidden'),
    },
    messageListUnreadIcon: {
        boxSizing: 'border-box',
        height: '13px',
        width: '13px',
        border: '3px solid rgba(255,0,85,0.1)',
        backgroundColor: theme.color.destructive,
        boxShadow: '0 0 5px 0 rgba(255,0,85,0.5)',
        borderRadius: '50%',
    },
}));

export default function Message(props) {
    const { message, loadUnreadMessagesNumber, messagesType, selected, setSelectedMessage, resetAddressNumber, userId } = props;
    let addressNumber = props.addressNumber;
    const { t } = useTranslation();
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    const userProfilePicUrl = getProfilePic();
    const [isUnReaded, setIsUnReaded] = useState(!isReadByUser());

    const classes = useStyles({ unread: isUnReaded, userProfilePicUrl, selected });

    function isReadByUser() {
        if (messagesType === MESSAGES.TYPE.OUTGOING) {
            return true;
        }

        if (!isUndefined(message?.isReadedMessage)) {
            if (message.isReadedMessage) {
                return true;
            }
        }

        if (messagesType === MESSAGES.TYPE.INCOMING) {
            if (!isUndefined(message?.messageToUser)) {
                const foundElement = message?.messageToUser?.find(
                    messageObj => {
                        if (!isNull(messageObj.readDate) && userId === messageObj.userId) {
                            return true;
                        }
                        return false;
                    },
                );

                if (!isUndefined(foundElement)) {
                    return true;
                }
            }
        }

        return false;
    }

    function getProfilePic() {
        if (messagesType === MESSAGES.TYPE.INCOMING) {
            return message.user ? routerHelper.getUserProfilePicUrl(message?.user?.id) : null;
        }
        return message.messageToUser ? routerHelper.getUserProfilePicUrl(message?.messageToUser?.[0]?.userId) : null;
    }

    function handleMessageClicked(msg) {
        return () => {
            try {
                if (!isReadByUser() && messagesType === MESSAGES.TYPE.INCOMING) {
                    messageService.setMessageRead(msg.id).then(() => {
                        loadUnreadMessagesNumber();
                    });
                    setIsUnReaded(false);
                }

                if (message.isReadedMessage === false) {
                    msg.isReadedMessage = true;
                    message.isReadedMessage = true;
                    setIsUnReaded(false);
                    loadUnreadMessagesNumber();
                }
                if (messagesType === MESSAGES.TYPE.INCOMING) {
                    msg.type = MESSAGES.TYPE.INCOMING;
                } else {
                    msg.type = MESSAGES.TYPE.OUTGOING;
                }
                setSelectedMessage(msg, true);
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        };
    }

    return (
        <div>
            <button type="button" className={classes.messageListElement} onClick={handleMessageClicked(message)}>

                <div className={classes.messageListUnreadIconContainer}>
                    <div className={classes.messageListUnreadIcon} />
                </div>
                <div className={classes.messageListIconContainer}>
                    <div className={classes.messageListIcon} />
                </div>
                <div className={classes.messageListInfo}>
                    <div className={classes.messageListUserName}>
                        {messagesType === MESSAGES.TYPE.OUTGOING && (<Svg style={classes.arrowIcon} iconId="icon-arrow_forward" />)}
                        {resetAddressNumber()}
                        {messagesType === MESSAGES.TYPE.INCOMING && (message.userId ? message.user.name : t('messages.systemMessage', 'System message'))}
                        {messagesType === MESSAGES.TYPE.OUTGOING && message?.messageToUser?.map(messageItem => {
                            if (!messageItem.user) {
                                return '';
                            }
                            addressNumber += 1;
                            if (addressNumber === 1) {
                                return `${messageItem.user.name}`;
                            }
                            return `, ${messageItem.user.name}`;
                        })}
                    </div>
                    <div className={classes.messageListSubject}>{message.subject}</div>
                </div>
                <div className={classes.messageListDate}>{dateTimeUtils.getDate(message.date)}</div>
            </button>
        </div>
    );
}
