import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { UNITS } from 'shared/src/constants';
import { useNumberFormat } from '@common_modules';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        height: 18,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        color: theme.color.jet,
        width: '100%',
        display: 'block',
        textAlign: 'right',
    },
}));

export default function Area(areaProps) {
    const { value, style, children, unit } = areaProps;
    const { t } = useTranslation();
    const { numberFormatter } = useNumberFormat();
    const classes = useStyles();

    return (
        <div className={clsx(classes.baseStyle, style, 'areaFormatter')}>
            {numberFormatter.getFormattedArea(value)} {unit || t('default.areUnit', UNITS.DEFAULT_AREA)}
            {children}
        </div>
    );
}
