import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const registryNumberService = {
    getRegistryNumbers,
    getRegistryNumbersWithPeriod,
};

async function getRegistryNumbers(partnerId, partnerType) {
    const url = routerHelper.getUrlWithParameters('/api/registryNumbers', { partnerId, partnerType });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getRegistryNumbersWithPeriod(periodId) {
    if (periodId && periodId > 0) {
        const url = routerHelper.getUrlWithParameters('/api/registryNumbers', { periodId });
        const response = await sendServerRequest('GET', url);
        return response;
    }
}
