import { ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';

type Classes = 'main' | 'container' | 'icon' | 'iconImg' | 'attributes' | 'name' | 'info' | 'company' | 'plateId';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    main: {
        height: 66,
    },
    container: {
        margin: '20px 15px 0px 15px',
        display: 'flex',
    },
    icon: {
        height: 50,
        width: 50,
        marginRight: 10,
    },
    iconImg: {
        height: 50,
        width: 50,
    },
    attributes: {
        fontSize: 16,
        fontWeight: 400,
        color: theme.color.raven,
        width: 240,
    },
    name: {
        color: theme.color.jet,
        fontSize: 20,
        fontWeight: 700,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        lineHeight: 1,
    },
    info: {
        color: theme.color.raven,
        fontSize: 16,
        fontWeight: 400,
    },
    company: {
        fontSize: 14,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    plateId: {
        fontSize: 14,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));

type TProps = {
    companyName: string;
    name: string;
    iconUrl: string;
    idNum: string;
}

function MachinePanelHeader({
    companyName,
    name,
    iconUrl,
    idNum,
}: TProps) {
    const classes = useStyles();

    return (
        <div className={classes.main}>
            <div className={classes.container}>
                <div className={classes.icon}>
                    <img src={iconUrl} className={classes.iconImg} alt="" />
                </div>
                <div className={classes.attributes}>
                    <div className={classes.name}>{name}</div>
                    <div className={classes.company}>{companyName}</div>
                    <div className={classes.plateId}>{idNum}</div>
                </div>
            </div>
        </div>
    );
}

export default MachinePanelHeader;
