import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import clsx from 'clsx';
import { theme as themes } from '@styles/theme';
import '@styles/components/table/table-group.css';

import useTableConfig from '@baseComponents/tableNew/tableParts/useTableConfig';
import useTableFixedColumn from '@baseComponents/tableNew/tableParts/useTableFixedColumn';
import { useTableColumnFader } from '@baseComponents/tableNew/tableParts/tableColumnFader';
import GlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/GlobalSearchInput';
import TableHeaderRow from '@baseComponents/tableNew/header/TableHeaderRow';
import useTableGroupedDataHandler from './useTableGroupedDataHandler';
import NoDataCard from './NoDataCard';

import ListRow from './ListRow';
import useTableGroup from './useTableGroup';

const useStyle = createUseStyles((theme: any) => ({
    tableRowWrapper: {
        '& .table-row': {
            paddingRight: props => (props.isBasePageTable ? `${theme.table.rowPaddingRight}px` : 0),
            marginRight: 0,
        },
    },
    row: {
        tableLayout: 'auto',
        width: '100%',
        whiteSpace: 'nowrap',
        borderTop: props => !props.isDataCard && `1px solid ${theme.color.pearl}`,
        borderBottom: props => !props.isDataCard && `1px solid ${theme.color.pearl}`,
    },
    tableHeaderRow: {
        marginTop: props => (props.isDataCard ? '39px' : '15px'),
        borderTopLeftRadius: '6px',
        borderTopRightRadius: '6px',
        backgroundColor: props => (!props.isDataCard ? theme.color.white : 'transparent'),
    },
    tableDataRow: {
        borderRadius: props => (props.isDataCard ? '6px' : 0),
        backgroundColor: theme.color.white,
        boxShadow: props => (props.isDataCard ? 'rgba(33, 40, 44, 0.15) 0px 2px 4px 0px' : 'none'),
    },
    tableFooterRow: {
        borderBottomLeftRadius: '6px',
        borderBottomRightRadius: '6px',
        boxShadow: 'rgba(33, 40, 44, 0.15) 0px 2px 4px 0px',
        borderLeft: props => (!props.isDataCard ? `1px solid ${theme.color.pearl}` : 'none'),
        borderRight: props => (!props.isDataCard ? `1px solid ${theme.color.pearl}` : 'none'),
        height: '100%',
    },
    autoSizerWrapper: {
        flex: 1,
        overflowY: 'hidden !important',
    },
    virtualList: {
        overflowX: 'hidden !important',
    },
    noData: {
        overflow: 'hidden',
    },
}));

function TableGroup(props) {
    const { isEnabledSearching } = props; //default display
    const { aggregateType, tableFunctions, className, onListItemsRendered } = props; //table dataGrouping
    const { useAutoGrouping = true, useAutoSorting = true } = props; //table data operations
    const { isDataCard, isBasePageTable = true, useRowSelection = false, useGlobalSearch = false, useColumnSorter = true, useColumnSearcher = true, tableId, hasProgressBar } = props; //table type

    //simple table
    const { tableData, tableColumns, tableGroup, selectedRowIds } = props;

    const listRef = React.useRef();
    const middleScrollLeftRef = useRef(0);
    const [rowHeight] = useState(isDataCard ? themes.table.dataCardRowHeight + (hasProgressBar ? themes.table.progressBarHeight : 0) : themes.table.tableRowHeight);

    const { canUseGroupOperation, tableKey } = useTableConfig({ isEnabledSearching, isBasePageTable, useRowSelection, aggregateType, tableId, tableData, tableColumns, tableGroup, selectedRowIds });
    const { displayNoDataCard, groupedTableData } = useTableGroupedDataHandler({ useAutoGrouping, useAutoSorting, isBasePageTable, tableKey });
    const { leftSideWidth, rightSideWidth, leftFixedColumns, rightFixedColumns, mainColumns } = useTableFixedColumn({ isBasePageTable, canUseGroupOperation, tableKey, useRowSelection });
    const { displayTableColumnFaders } = useTableColumnFader();

    const columnWidths = useSelector((state: any) => state.basePage.table.headerColumnWidths);
    const simpleTableColumnWidths = useSelector((state: any) => state.simpleTable.table[tableKey]?.columnWidths);
    const {
        flatList,
        isGroupCollapsed,
        isGroupCollapseable,
        toggleCollapsible,
        onWindowResize,
        handleMidScroll,
        //handleScroll,
    } = useTableGroup({ isBasePageTable, isDataCard, groupedTableData, middleScrollLeftRef, displayTableColumnFaders, tableKey });

    const classes = useStyle({ isDataCard, isBasePageTable } as any);

    const VirtualRow = ({ index, style }) => {
        const tableRowRef = useRef();

        //init faders
        useEffect(() => {
            document.querySelectorAll(`.midCol${tableKey}`).forEach(midColItem => {
                if (middleScrollLeftRef) {
                    midColItem.scrollLeft = middleScrollLeftRef.current;
                }
                displayTableColumnFaders(midColItem);
            });
        }, []);

        return (
            <div className={classes.tableRowWrapper} ref={tableRowRef}>
                <div className="table-row" style={style}>
                    <ListRow
                        classes={classes}
                        index={index}
                        rowData={flatList[index]}
                        canUseGroupOperation={canUseGroupOperation}
                        isGroupCollapseable={isGroupCollapseable}
                        isGroupCollapsed={isGroupCollapsed}
                        isBasePageTable={isBasePageTable}
                        onToggleCollapsable={event => toggleCollapsible(event, index, flatList[index])}
                        leftFixedColumns={leftFixedColumns}
                        mainColumns={mainColumns}
                        rightFixedColumns={rightFixedColumns}
                        handleMidScroll={handleMidScroll}
                        columnWidths={isBasePageTable ? columnWidths : simpleTableColumnWidths}
                        leftSideWidth={leftSideWidth}
                        rightSideWidth={rightSideWidth}
                        isDataCard={isDataCard}
                        onSelectRow={tableFunctions?.onSelectRow}
                        tableFunctions={tableFunctions}
                        aggregateType={aggregateType}
                        tableKey={tableKey} //simple table
                    />
                </div>
            </div>
        );
    };

    const innerElementType = forwardRef(({ style, ...rest }, ref) => (
        <div
            ref={ref}
            style={{
                ...style,
                height: `${parseFloat(style.height) + rowHeight}px`,
            }}
            {...rest}
        />
    ));

    return (
        <>
            {!isBasePageTable && useGlobalSearch && <GlobalSearchInput tableKey={tableKey} />}
            <div className={clsx(classes.autoSizerWrapper, className)}>
                <>
                    <TableHeaderRow
                        leftSideWidth={leftSideWidth}
                        rightSideWidth={rightSideWidth}
                        leftFixedColumns={leftFixedColumns}
                        rightFixedColumns={rightFixedColumns}
                        mainColumns={mainColumns}
                        handleMidScroll={handleMidScroll}
                        tableKey={tableKey} //simple table
                        isBasePageTable={isBasePageTable}
                        useColumnSorter={useColumnSorter}
                        useColumnSearcher={useColumnSearcher}
                    />
                    {displayNoDataCard()
                        ? <NoDataCard />
                        : (
                            <AutoSizer onResize={onWindowResize}>
                                {({ height, width }) => (
                                    <List
                                        ref={listRef}
                                        innerElementType={innerElementType}
                                        className={clsx(classes.virtualList, 'List', displayNoDataCard() && classes.noData)}
                                        height={height - themes.table.tableHeaderRowHeight}
                                        itemCount={flatList.length}
                                        itemSize={rowHeight}
                                        width={width}
                                        onItemsRendered={onListItemsRendered}
                                        overscanCount={3}
                                        //onScroll={throttle(handleScroll, 200, { leading: true })}
                                    >
                                        {VirtualRow}
                                    </List>
                                )}
                            </AutoSizer>
                        )
                    }
                </>
            </div>
        </>
    );
}

export default React.memo(TableGroup);
