import { sendServerRequest } from './baseService';
import { sessionService } from './sessionService';

export const systemService = {
    getSystemSettings,
    getVersion,
    getActiveSystemLangs,
};

async function getSystemSettings() {
    const agentName = sessionService.getAgentName();
    const response = await sendServerRequest('GET', `/api/system/settings/${agentName}`);
    return response;
}

async function getVersion() {
    const response = await sendServerRequest('GET', '/api/system/version');
    return response;
}

async function getActiveSystemLangs() {
    const agentName = sessionService.getAgentName();
    const response = await sendServerRequest('GET', `/api/system/lang/${agentName}`);
    return response;
}
