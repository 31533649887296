import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const warehouseService = {
    getWarehouses,
};

/**
 * Get all warehouses datas
 * @param {object} props Component props
 * @property {array<int>} props.companyIds IsOptional - company ID list eg.: [1]
 * @property {array<int>} props.typeIds IsOptional - warehouse type ID list eg.: [1, 2, 3]
 * 1 - OWN
 * 2 - FOREIGN
 * 3 - OWN_AND_FOREIGN
 * @return {array} Warehouses array
 */
async function getWarehouses(props) {
    const url = routerHelper.getUrlWithParameters('/api/warehouses', props);
    const response = await sendServerRequest('GET', url);
    return response;
}
