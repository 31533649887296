import { RIGHTS, COUNTRY } from '../constants';

export const INVOICE = {
    MODULE_ID: 3,
    NAME: 'invoice',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'invoice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.invoice',
                TRANSLATOR_DEFAULT: 'Invoice',
            },
        },
        SUPPLIER_INVOICE_MANAGEMENT: {
            CONTENT_URL_ID: { supplierInvoiceManagement: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'supplierInvoiceManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.supplierInvoiceManagement',
                TRANSLATOR_DEFAULT: 'Supplier invoice management',
            },
            RIGHTS: [RIGHTS.SUPPLIER_INVOICE_MANAGEMENT_INTERFACE],
        },
        DELIVERY_NOTES: {
            CONTENT_URL_ID: { deliveryNotes: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'deliveryNotes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.deliveryNotes',
                TRANSLATOR_DEFAULT: 'Delivery notes',
            },
            RIGHTS: [RIGHTS.DELIVERY_NOTES_INTERFACE],
            EXCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
        },
        DELIVERY_NOTE_EDIT: {
            CONTENT_URL_ID: { deliveryNoteEdit: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'deliveryNoteEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.deliveryNoteEdit',
                TRANSLATOR_DEFAULT: 'Delivery note edit',
            },
            RIGHTS: [RIGHTS.CREATE_DELIVERY_NOTE],
            EXCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
        },
        WORKOPERATION_SUPPLIER_INVOICE: {
            CONTENT_URL_ID: { workoperationDeliveryInvoice: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workoperationDeliveryInvoice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workoperationDeliveryInvoice',
                TRANSLATOR_DEFAULT: 'Workoperation delivery invoice',
            },
        },
        COST_SUPPLIER_INVOICE: {
            CONTENT_URL_ID: { costDeliveryInvoice: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'costDeliveryInvoice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costDeliveryInvoice',
                TRANSLATOR_DEFAULT: 'Cost delivery invoice',
            },
        },
        DELIVERY_NOTE_BOOKS: {
            CONTENT_URL_ID: { deliveryNoteBooks: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'deliveryNoteBooks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.deliveryNoteSets',
                TRANSLATOR_DEFAULT: 'Delivery note sets',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        DELIVERY_NOTE_ISSUER_DETAILS: {
            CONTENT_URL_ID: { deliveryNoteIssuerDetails: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'deliveryNoteIssuerDetails',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.deliveryNoteIssuerDetails',
                TRANSLATOR_DEFAULT: 'Delivery note issuer details',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        CUSTOMER_INVOICE_MANAGEMENT: {
            CONTENT_URL_ID: { customerInvoiceManagement: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'customerInvoiceManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.customerInvoiceManagement',
                TRANSLATOR_DEFAULT: 'Customer invoice management',
            },
            RIGHTS: [RIGHTS.CUSTOMER_INVOICE_MANAGEMENT],
        },
        ACCOUNT_EDIT: {
            CONTENT_URL_ID: { accountEdit: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'accountEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.accountEdit',
                TRANSLATOR_DEFAULT: 'Edit account',
            },
            RIGHTS: [RIGHTS.SUPPLIER_INVOICE_RECORD],
        },
        COSTUMER_ACCOUNT_EDIT: {
            CONTENT_URL_ID: { customerAccountEdit: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'customerAccountEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.customerAccountEdit',
                TRANSLATOR_DEFAULT: 'Edit costumer account',
            },
            RIGHTS: [RIGHTS.CUSTOMER_INVOICE_RECORDING],
        },
    },
};
