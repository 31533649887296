import { RIGHTS, SYSTEM, COUNTRY, SETTINGS } from '../constants';

export const LAND_ADMINISTRATION = {
    MODULE_ID: 4,
    NAME: 'landAdministration',
    EXCLUDE_SYSTEM_MODULES: [
        SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
        SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
        SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
        SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
    ],
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landAdministration',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.landAdministration',
                TRANSLATOR_DEFAULT: 'Land administration',
            },
        },
        REGISTRY_NUMBERS: {
            CONTENT_URL_ID: { registryNumbers: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'registryNumbers',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.registryNumbers',
                TRANSLATOR_DEFAULT: 'Registry numbers',
            },
        },
        LAND_LEASE_CONTRACTS: {
            CONTENT_URL_ID: { landLeaseContracts: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landLeaseContracts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.landLeaseContracts',
                TRANSLATOR_DEFAULT: 'Land lease contracts',
            },
            RIGHTS: [
                RIGHTS.LAND_LEASE_CONTRACTS_INTERFACE,
                RIGHTS.SUB_LEASING_EDIT_INTERFACE,
            ],
        },
        LAND_LEASE_FEE: {
            CONTENT_URL_ID: { landLeaseFee: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landLeaseFee',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.landLeaseFee',
                TRANSLATOR_DEFAULT: 'Land lease fee',
            },
            EXCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.LAND_LEASE_FEE_INTERFACE],
        },
        LIST_OF_LAND_OWNERS_CREDITINGS: {
            CONTENT_URL_ID: { listOfLandOwnersCreditings: 29 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'listOfLandOwnersCreditings',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Földtulajdon bevételei',
            },
            RIGHTS: [RIGHTS.LIST_OF_LAND_OWNERS_CREDITINGS_INTERFACE],
            SETTINGS: [SETTINGS.ADMINISTRATION_OF_OWN_LAND_LEASING],
        },
        LAND_OWNERS: {
            CONTENT_URL_ID: { landOwners: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landOwners',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.landOwners',
                TRANSLATOR_DEFAULT: 'Land owners',
            },
            RIGHTS: [RIGHTS.LAND_OWNERS_INTERFACE],
        },
        REGISTRY_NUMBER: {
            CONTENT_URL_ID: { registryNumber: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'registryNumber',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.registryNumber',
                TRANSLATOR_DEFAULT: 'Registry number',
            },
            RIGHTS: [RIGHTS.REGISTRY_NUMBER_INTERFACE],
        },
        REGISTRY_NUMBER_EDIT: {
            CONTENT_URL_ID: { registryNumberEdit: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'registryNumberEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.registryNumberEdit',
                TRANSLATOR_DEFAULT: 'Registry number edit',
            },
            RIGHTS: [RIGHTS.ADDING_REGISTRY_NUMBER],
        },

        REGISTRY_NUMBER_SALES: {
            CONTENT_URL_ID: { registryNumberSales: 30 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'registryNumberSales',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.registryNumberSales',
                TRANSLATOR_DEFAULT: 'Registry number sales',
            },
            RIGHTS: [RIGHTS.REGISTRY_NUMBER_SALES_INTERFACE],
            SETTINGS: [SETTINGS.REGISTRY_NUMBER_SALES_ADMINISTRATION],
        },
        REGISTRY_NUMBER_SALES_EDIT: {
            CONTENT_URL_ID: { registryNumberSalesEdit: 31 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'registryNumberSalesEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.registryNumberSalesEdit',
                TRANSLATOR_DEFAULT: 'Registry number sales edit',
            },
            RIGHTS: [RIGHTS.REGISTRY_NUMBER_SALES_INTERFACE],
            SETTINGS: [SETTINGS.REGISTRY_NUMBER_SALES_ADMINISTRATION],
        },


        LAND_LEASE_CONTRACTS_EDIT: {
            CONTENT_URL_ID: { landLeaseContractsEdit: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landLeaseContractsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.landLeaseContractsEdit',
                TRANSLATOR_DEFAULT: 'Land lease contract edit',
            },
        },
        SUB_LEASING_EDIT: {
            CONTENT_URL_ID: { subLeasingEdit: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'subLeasingEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.subLeasingEdit',
                TRANSLATOR_DEFAULT: 'Sub leasing edit',
            },
        },
        LIST_OF_PAYEMENT_EDIT: {
            CONTENT_URL_ID: { listOfPaymentEdit: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'listOfPayementEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.listOfPayementEdit',
                TRANSLATOR_DEFAULT: 'List of payement edit',
            },
        },
        LIST_OF_CREDITINGS_EDIT: {
            CONTENT_URL_ID: { listOfCreditingsEdit: 28 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'listOfCreditingsEdit',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Földbérleti jóváírás szerkesztése',
            },
        },
        ANNUAL_PAY_OFF_UNIT: {
            CONTENT_URL_ID: { annualPayOffUnit: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'annualPayOffUnit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.annualPayOffUnit',
                TRANSLATOR_DEFAULT: 'Annual pay off unit',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        LAND_LEASE_CONTRACT_NUMBER_BLOCK: {
            CONTENT_URL_ID: { landLeaseContractNumberBlock: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landLeaseContractNumberBlock',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.landLeaseContractNumberBlock',
                TRANSLATOR_DEFAULT: 'Land lease contract number block',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        SUB_LEASING_NUMBER_BLOCKS: {
            CONTENT_URL_ID: { subLeasingNumberBlocks: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'subLeasingNumberBlocks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.subLeasingNumberBlocks',
                TRANSLATOR_DEFAULT: 'Subleasing number blocks',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        PRICE_CONSTRUCTION: {
            CONTENT_URL_ID: { priceConstruction: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'priceConstruction',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.priceConstruction',
                TRANSLATOR_DEFAULT: 'Price construction',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        PRICE_OF_GRAIN: {
            CONTENT_URL_ID: { priceOfGrain: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'priceOfGrain',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.priceOfGrain',
                TRANSLATOR_DEFAULT: 'Price of grain',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        ENTRY_REMOVAL_REASONS: {
            CONTENT_URL_ID: { entryRemovalReasons: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'entryRemovalReasons',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.entryRemovalReasons',
                TRANSLATOR_DEFAULT: 'Entry removal reasons',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        INFLATION_RATE: {
            CONTENT_URL_ID: { inflationRate: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'inflationRate',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.inflationRate',
                TRANSLATOR_DEFAULT: 'Inflation rate',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        LOCAL_TAXES: {
            CONTENT_URL_ID: { localTaxes: 27 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'localTaxes',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Helyi adók',
            },
        },
        POSTAL_COSTS: {
            CONTENT_URL_ID: { postalCosts: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'postalCosts',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Postai költségek',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        PARTY_RENT_PRODUCT: {
            CONTENT_URL_ID: { partyRentProduct: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'partyRentProduct',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Feles bérlet adott évi terménye',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.PARTY_RENT_PRODUCT_INTERFACE_ACCESS_EDIT],
            SETTINGS: [SETTINGS.HALF_BENEFIT],
        },
        PARTY_RENT_ACCOUNTING: {
            CONTENT_URL_ID: { partyRentAccounting: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'partyRentAccounting',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Feles bérlet elszámolása',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.PARTY_RENT_PRODUCT_INTERFACE_ACCESS_EDIT],
            SETTINGS: [SETTINGS.HALF_BENEFIT],
        },
        LAND_LEASE_FEE_CALCULATION_BASIC_DATA_RO: {
            CONTENT_URL_ID: { landLeaseFeeCalculationBasicDataRO: 21 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landLeaseFeeCalculationBasicDataRO',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Bază de calcul tarif arendă',
            },
        },
        BILL_OF_PAYMENT_BLOCKS_RO: {
            CONTENT_URL_ID: { billOfPayementBlocksRO: 22 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'billOfPayementBlocksRO',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Blocuri dispoziții de plată',
            },
        },
        LAND_LEASE_FEE_RO: {
            CONTENT_URL_ID: { landLeaseFeeRO: 23 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landLeaseFeeRO',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Situații plăți, plăți de arendă',
            },
            INCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.LAND_LEASE_FEE_INTERFACE],
        },
        LIST_OF_PAYEMENT_EDIT_RO: {
            CONTENT_URL_ID: { listOfPaymentEditRO: 24 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'listOfPaymentEditRO',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Lista plăților',
            },
        },
        LAND_CONVENTIONS_RO: {
            CONTENT_URL_ID: { landConventionsRo: 25 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landConventionsRo',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Tipuri de conveții',
            },
            INCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.LAND_CONVENTIONS_RO], //settingsMenu
        },
        LAND_CONTRACTS_CONVENTIONS_RO: {
            CONTENT_URL_ID: { landContractsConventionsRo: 26 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landContractsConventionsRo',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Evidența convenție',
            },
            INCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.LAND_CONTRACTS_CONVENTIONS],
        },
        LAND_LEASE_FEE_EXCEL_INTERFACE: {
            CONTENT_URL_ID: { landLeaseFeeExcelInterface: 32 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landLeaseFeeExcelInterface',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.landLeaseFeeExcelInterface',
                TRANSLATOR_DEFAULT: 'Land lease fee excel interface',
            },
        },
    },
};
