/* eslint-disable react-hooks/exhaustive-deps */
import Radio from '@baseComponents/controls/Radio';
import useTranslate from '@i18n/useTranslate';
import { ThemeType } from '@map/services/mapTypes';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { CROP } from 'shared/src/constants';
import MapFilterBase from './MapFilterBase';
import { MapFilterSchema } from './MapFilterSchema';

type Classes = 'container' | 'title';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    title: {
        marginBottom: 15,
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
    },
}));
export default function MapFilterCropType() {
    const classes = useStyles();
    const filterContext = useFormContext<MapFilterSchema>();

    const { translater, t } = useTranslate();

    function changeTypeFilter(event: any) {
        const value = event.target.value;
        filterContext.setValue('cropType', Number(value));
    }

    // const { translater, t } = useTranslate();
    const cropType = filterContext.watch('cropType') ?? CROP.TYPE.PLANTATION;

    return (
        <MapFilterBase>
            <div className={classes.container}>
                <div className={classes.title}>{t('map.filterCrop', 'Crop')}</div>
                <form>
                    <Radio text={translater('map.filterCropMain', 'Main')} checked={cropType === CROP.TYPE.MAIN_CROP} group="stategrp" value={CROP.TYPE.MAIN_CROP} onChange={changeTypeFilter} />
                    <Radio text={translater('map.filterCropSecondary', 'Secondary')} checked={cropType === CROP.TYPE.SECOND_CROP} group="stategrp" value={CROP.TYPE.SECOND_CROP} onChange={changeTypeFilter} />
                    <Radio text={translater('map.filterWorkOpertaionStateInBoth', 'Both')} checked={cropType === CROP.TYPE.PLANTATION} group="stategrp" value={CROP.TYPE.PLANTATION} onChange={changeTypeFilter} />
                </form>
            </div>
        </MapFilterBase>
    );
}
