import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

import Button from '../../../../baseComponents/buttons/Button';
import DropdownButton from '../../../../baseComponents/dropdowns/DropdownButton';

const useStyles = createUseStyles((theme: any) => ({
    footerWrapper: {
        bottom: 25,
        width: 320,
        display: 'flex',
        justifyContent: 'center',
        zIndex: theme.zIndex.loadSpinner,
        margin: 'auto',
        paddingTop: 15,
    },
    editBtn: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default function PeriodViewFooterButtons(props) {
    const { cultivationPeriodData, closePeriodFunctionObject, editFunctionObject, otherFunctionList, closeModal } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    function closingPeriodOnClick() {
        if (closePeriodFunctionObject?.onClick) {
            closeModal();
            closePeriodFunctionObject.onClick(cultivationPeriodData);
        }
    }

    function editOnClick() {
        if (editFunctionObject?.onClick) {
            closeModal();
            editFunctionObject.onClick(cultivationPeriodData);
        }
    }

    return (
        <div className={classes.footerWrapper}>
            <Button type="destructive" onClick={closingPeriodOnClick} disabled={closePeriodFunctionObject?.isDisabled(cultivationPeriodData)}>
                {t('periodView.close', 'Close')}
            </Button>
            <Button type="secondary" className={classes.editBtn} onClick={editOnClick} disabled={editFunctionObject?.isDisabled(cultivationPeriodData)}>
                {t('default.editing', 'Editing')}
            </Button>
            <DropdownButton onChange={closeModal} itemList={otherFunctionList} direction="up" buttonType="secondary" buttonIconId="icon-more" tableRowItem={cultivationPeriodData} />
        </div>
    );
}
