import { translationActions } from '../actions/translationActions';

const initialState = {
    t: null,
};

export function translation(state = initialState, action) {
    switch (action.type) {
        case translationActions.SET_TRANSLATE_FUNCTION: {
            const { t } = action.payload;
            return { t };
        }
        default:
            return state;
    }
}
