export enum SKLayerType {
  HEAT_MAP = 'heat-map',
  FEATURE = 'feature',
}

type SKLegendItem = {
  id?: number;
  name?: string;
  strokeColor?: string;
  fillColor?: string;
  fillOpacity?: number;
  strokeWeight?: number;
};

export type SKLayer = {
  id?: number;
  externalId?: number;
  type: SKLayerType;
  name: string;
  legendProperty?: string;
  legendIdProperty?: string;
  legends?: SKLegendItem[];
};

type SKLayerGroup = {
  name: string;
  children: SKLayer[];
};

export const SK_PUBLISHED_POLYGONS_LAYER = {
  id: 1,
  type: SKLayerType.FEATURE,
  name: 'Zverejnené HU',
  legends: [
    {name: 'Zverejnené HU', strokeColor: '#00FF00'},
  ],
};

export const SK_NON_PRODUCTIVE_ELEMENTS_LAYER = {
  id: 2,
  externalId: 0,
  type: SKLayerType.FEATURE,
  name: 'Neproduktívne prvky',
  legendProperty: 'POPIS',
  legends: [
    {name: 'Terasy', strokeColor: '#786a5f'},
    {name: 'Priekopy', strokeColor: '#045abd'},
    {name: 'Chránené prvky na OP', strokeColor: '#00b035'},
  ],
};

export const SK_HEAT_MAP_LAYERS: Record<string, SKLayer> = {
  SLOPE: {id: 3, type: SKLayerType.HEAT_MAP, name: 'Svahovitosť', externalId: 0},
  WATER_EROSION: {id: 4, type: SKLayerType.HEAT_MAP, name: 'Vodná erózia', externalId: 1},
  WIND_EROSION: {id: 5, type: SKLayerType.HEAT_MAP, name: 'Veterná erózia', externalId: 2},
};

export const SK_EXTERNAL_LAYERS: Record<string, SKLayer> = {
  NON_PRODUCTIVE_ELEMENTS: {
    id: 6,
    externalId: 11,
    type: SKLayerType.FEATURE,
    name: 'Neproduktívne prvky',
    legendProperty: 'POPIS',
    legends: [
      {name: 'Terasy', strokeColor: '#786a5f'},
      {name: 'Priekopy', strokeColor: '#045abd'},
      {name: 'Chránené prvky na OP', strokeColor: '#00b035'},
    ],
  },
  TTP_BIOTOPES: {id: 7, externalId: 2, type: SKLayerType.FEATURE, name: 'Biotopy TTP', legendProperty: 'BIOTOP', legends: [{strokeColor: '#d99b38'}]},
  STEPPE_GROUND_SQUIRREL_BIOTOPES: {
    id: 8,
    externalId: 3,
    type: SKLayerType.FEATURE,
    name: 'Biotopy sysľa pasienkového',
    legendProperty: 'POPIS',
    legends: [{strokeColor: '#b00522'}],
  },
  PROTECTED_AREA_LEVELS: {
    id: 9,
    externalId: 4,
    type: SKLayerType.FEATURE,
    name: 'ÚEV - stupne ochrany',
    legendProperty: 'POPIS',
    legendIdProperty: 'STO',
    legends: [
      {id: 2, name: '2. stupeň ochrany', strokeColor: '#fadbbb', fillColor: '#fadbbb', fillOpacity: 0.5},
      {id: 3, name: '3. stupeň ochrany', strokeColor: '#fabf84', fillColor: '#fabf84', fillOpacity: 0.5},
      {id: 4, name: '4. stupeň ochrany', strokeColor: '#faa046', fillColor: '#faa046', fillOpacity: 0.5},
      {id: 5, name: '5. stupeň ochrany', strokeColor: '#ad2a05', fillColor: '#ad2a05', fillOpacity: 0.5},
    ],
  },
  GREAT_BUSTARD_BIOTOPES: {
    id: 10,
    externalId: 5,
    type: SKLayerType.FEATURE,
    name: 'Biotopy dropa fúzatého',
    legendProperty: 'LEGENDA',
    legends: [{strokeColor: '#301efa'}],
  },
  PROTECTED_WATER_MANAGEMENT_AREAS: {
    id: 11,
    externalId: 6,
    type: SKLayerType.FEATURE,
    name: 'CHVO - Chránené vodohosp. Oblasti',
    legendProperty: 'POPIS',
    legends: [{strokeColor: '#0289ba'}],
  },
  QUALITY_GROUP_6_9: {
    id: 12,
    externalId: 7,
    type: SKLayerType.FEATURE,
    name: 'BPEJ - skupina kvality 6-9',
    legendProperty: 'KVALITA',
    legendIdProperty: 'KOD',
    legends: [
      {id: 2, name: 'skupina kvality 6', strokeColor: '#5df0ba', fillColor: '#5df0ba', fillOpacity: 0.25, strokeWeight: 1},
      {id: 1, name: 'skupina kvality 7', strokeColor: '#27c289', fillColor: '#27c289', fillOpacity: 0.25, strokeWeight: 1},
      {id: 4, name: 'skupina kvality 8', strokeColor: '#20c6e3', fillColor: '#20c6e3', fillOpacity: 0.25, strokeWeight: 1},
      {id: 3, name: 'skupina kvality 9', strokeColor: '#217a8a', fillColor: '#217a8a', fillOpacity: 0.25, strokeWeight: 1},
    ],
  },
  AREAS_WITH_NATURAL_LIMITATIONS: {
    id: 13,
    externalId: 8,
    type: SKLayerType.FEATURE,
    name: 'ANC - Oblasti s prír. Obmedzeniami',
    legendProperty: 'ANC',
    legends: [{strokeColor: '#02ccf0', strokeWeight: 2 }],
  },
  SODDING_ARABLE_LAND: {
    id: 14,
    externalId: 9,
    type: SKLayerType.FEATURE,
    name: 'Zatrávňovanie ornej pôdy',
    legendProperty: 'POPIS',
    legends: [{strokeColor: '#2b8528'}],
  },
  BUFFER_ZONES: {id: 15, externalId: 12, type: SKLayerType.FEATURE, name: 'Nárazníkové zóny', legendProperty: 'POZNAMKA', legends: [{strokeColor: '#91d3fa'}]},
  TERRACES: {id: 16, externalId: 13, type: SKLayerType.FEATURE, name: 'Terasy', legendProperty: 'NAZOVKU', legends: [{strokeColor: '#dbb63d'}]},
  PROTECTED_BIRD_AREAS: {
    id: 17,
    externalId: 14,
    type: SKLayerType.FEATURE,
    name: 'Chránené vtáčie územia',
    legendProperty: 'LEGENDA',
    legends: [{strokeColor: '#c091fa'}],
  },
  AREAS_OF_EUROPEAN_IMPORTANCE: {
    id: 18,
    externalId: 15,
    type: SKLayerType.FEATURE,
    name: 'Územia európskeho významu',
    legendProperty: 'LEGENDA',
    legends: [{strokeColor: '#225ff0'}],
  },
  VULNERABLE_AREAS_NDIR_ABC: {
    id: 19,
    externalId: 16,
    type: SKLayerType.FEATURE,
    name: 'Zraniteľné oblasti (NDIR ABC)',
    legendIdProperty: 'KOD',
    legendProperty: 'SKRATKA',
    legends: [
      {id: 3, name: 'NDIR-A', strokeColor: '#f7fca7', fillColor: '#f7fca7', fillOpacity: 0.5},
      {id: 1, name: 'NDIR-B', strokeColor: '#fcdca7', fillColor: '#fcdca7', fillOpacity: 0.5},
      {id: 2, name: 'NDIR-C', strokeColor: '#fcaba7', fillColor: '#fcaba7', fillOpacity: 0.5},
    ],
  },
  LAST_YEAR_CROPS: {
    id: 20,
    externalId: 17,
    type: SKLayerType.FEATURE,
    name: 'Minuloročné plodiny',
    legendProperty: 'NAZOV',
    legends: [{strokeColor: '#ebc986', fillColor: '#ebc986', fillOpacity: 0.5}],
  },
  SENSITIVE_TTP: {id: 21, externalId: 18, type: SKLayerType.FEATURE, name: 'Citlivé TTP', legends: [{strokeColor: '#d086eb'}]},
  REFERENCE_TTP: {id: 22, externalId: 19, type: SKLayerType.FEATURE, name: 'Referenčné TTP', legends: [{strokeColor: '#de6114'}]},
  PURCHASED_LANDS_FENCES_ACCESS_ROADS: {
    id: 23,
    externalId: 21,
    type: SKLayerType.FEATURE,
    name: 'Vykúpené pozemky, oplotenia, prístupové cesty',
    legendProperty: 'popis',
    legends: [{strokeColor: '#000000'}],
  },
  GAS_PIPELINE_WORK_STRIP: {
    id: 24,
    externalId: 22,
    type: SKLayerType.FEATURE,
    name: 'Pracovný pruh plynovodu',
    legendProperty: 'KU',
    legends: [{strokeColor: '#000000'}],
  },
  VINEYARD_RESTRUCTURING_2019: {
    id: 25,
    externalId: 23,
    type: SKLayerType.FEATURE,
    name: 'Reštrukturalizácia vinohradov 2019',
    legends: [{strokeColor: '#de143c'}],
  },
  VINEYARD_RESTRUCTURING_2020: {
    id: 26,
    externalId: 24,
    type: SKLayerType.FEATURE,
    name: 'Reštrukturalizácia vinohradov 2020',
    legends: [{strokeColor: '#de143c'}],
  },
  VINEYARD_RESTRUCTURING_2021: {
    id: 27,
    externalId: 25,
    type: SKLayerType.FEATURE,
    name: 'Reštrukturalizácia vinohradov 2021',
    legends: [{strokeColor: '#de143c'}],
  },
};

export const SK_LAYERS_ARRAY = Object.values({SK_NON_PRODUCTIVE_ELEMENTS_LAYER, ...SK_EXTERNAL_LAYERS, ...SK_HEAT_MAP_LAYERS});

export const SK_LAYERS: SKLayerGroup[] = [
  {
    name: 'Zverejnené HU',
    children: [SK_PUBLISHED_POLYGONS_LAYER],
  },
  {
    name: 'Neproduktívne prvky',
    children: [SK_NON_PRODUCTIVE_ELEMENTS_LAYER],
  },
  {
    name: 'Kondicionalita',
    children: [
      SK_HEAT_MAP_LAYERS.SLOPE,
      SK_HEAT_MAP_LAYERS.WATER_EROSION,
      SK_HEAT_MAP_LAYERS.WIND_EROSION,
      SK_EXTERNAL_LAYERS.NON_PRODUCTIVE_ELEMENTS,
      SK_EXTERNAL_LAYERS.BUFFER_ZONES,
      SK_EXTERNAL_LAYERS.TERRACES,
      SK_EXTERNAL_LAYERS.LAST_YEAR_CROPS,
      SK_EXTERNAL_LAYERS.PROTECTED_BIRD_AREAS,
      SK_EXTERNAL_LAYERS.AREAS_OF_EUROPEAN_IMPORTANCE,
      SK_EXTERNAL_LAYERS.VULNERABLE_AREAS_NDIR_ABC,
      SK_EXTERNAL_LAYERS.SENSITIVE_TTP,
      SK_EXTERNAL_LAYERS.REFERENCE_TTP,
    ],
  },
  {
    name: 'Neprojektové opatrenia',
    children: [
      SK_EXTERNAL_LAYERS.TTP_BIOTOPES,
      SK_EXTERNAL_LAYERS.STEPPE_GROUND_SQUIRREL_BIOTOPES,
      SK_EXTERNAL_LAYERS.PROTECTED_AREA_LEVELS,
      SK_EXTERNAL_LAYERS.GREAT_BUSTARD_BIOTOPES,
      SK_EXTERNAL_LAYERS.PROTECTED_WATER_MANAGEMENT_AREAS,
      SK_EXTERNAL_LAYERS.QUALITY_GROUP_6_9,
      SK_EXTERNAL_LAYERS.AREAS_WITH_NATURAL_LIMITATIONS,
      SK_EXTERNAL_LAYERS.SODDING_ARABLE_LAND,
    ],
  },
  {
    name: 'Ostatné EV',
    children: [
      SK_EXTERNAL_LAYERS.PURCHASED_LANDS_FENCES_ACCESS_ROADS,
      SK_EXTERNAL_LAYERS.GAS_PIPELINE_WORK_STRIP,
      SK_EXTERNAL_LAYERS.VINEYARD_RESTRUCTURING_2019,
      SK_EXTERNAL_LAYERS.VINEYARD_RESTRUCTURING_2020,
      SK_EXTERNAL_LAYERS.VINEYARD_RESTRUCTURING_2021,
    ],
  },
];
