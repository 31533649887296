import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import WeatherModal from '@customComponents/modals/WeatherModal/WeatherModal';
import { rowFunctions } from '@contents/cultivationPeriods/cultivationPeriodsManagement/cultivationPeriodsTable/rowFunctions';
import renderModal from '../../../../modals/modalNew/modalFunctions';
import Button from '../../../buttons/Button';

const useStyles = createUseStyles({
    iconButtonWithTemperature: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        '& button': {
            '& div': {
                padding: 0,
                height: 16,
                lineHeight: '16px',
                verticalAlign: 'middle',
                display: 'inline-flex',
            },
        },
    },
});

export default function WeatherButtonCell(props) {
    const { rowData, isDisabled } = props;
    const { id, mapSvg } = rowData;
    const weatherList = useSelector((state: any) => state.basePage.table.otherDataList);
    const mapCompanyId = useSelector((state: any) => state.company.company.globalData.mapCompanyId);
    const [weatherData, setWeatherData] = useState();
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        if (!isEmpty(weatherList) && id) {
            const weatherObj = weatherList.find(weatherItem => weatherItem.av_id === id);
            setWeatherData(weatherObj);
        }
    }, [weatherList, id]);

    function ignoreRowSelection(event) {
        event.stopPropagation();
    }

    if (!mapSvg) {
        return null;
    }

    return (
        <div className={classes.iconButtonWithTemperature} onClick={ignoreRowSelection} onKeyDown={ignoreRowSelection} role="button" tabIndex={0}>
            <Button
                ignoreOtherClick
                size="small"
                type="secondary"
                iconId={weatherData ? weatherData.ikon : 'icon-day-rain'}
                iconPosition="before"
                onClick={() => renderModal(WeatherModal, {
                    periodId: rowData.id,
                    periodName: rowData.name,
                    mapCompanyId,
                    showPeriodModal: () => rowFunctions.showPeriodModal(rowData),
                },
                false)}
                disabled={isDisabled}
                disabledPointerEvents="auto"
                tooltipDataEvent={!isDisabled ? 'click' : null}
                title={t('cultivationPeriods.showWeather', 'Show weather forecast')}
            >
                {weatherData ? `${weatherData.celsius} °C` : '- °C'}
            </Button>
        </div>
    );
}
