/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import { isFunction, isEmpty, uniqueId } from 'lodash';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import clsx from 'clsx';

import Svg from '@baseComponents/Svg';
import WarehouseSelectorPopup from './WarehouseSelectorPopup';

const useStyles = createUseStyles((theme: any) => ({
    popover: {
        minWidth: 'fit-content',
        border: 'none',
        borderRadius: 6,
        backgroundColor: theme.color.white,
        zIndex: theme.zIndex.popover,
        boxShadow: theme.shadows.dropdown,
        '& .popover-body': {
            display: 'grid',
            gridTemplateColumns: 'repeat( auto-fit, minmax(40%, 1fr) )',
            gridGap: 10,
            minWidth: 200,
            padding: 0,
        },
        '& .arrow::before': {
            borderBottomColor: theme.color.white,
            borderWidth: 8,
            top: -20,
            left: -15,
        },
    },
    dropdownIcon: {
        height: 20,
        width: 20,
        float: 'right',
        margin: 'auto',
    },
    buttonWrapper: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
    },
    popupButton: {
        width: '100%',
        height: '100%',
        borderRadius: 4,
        boxShadow: 'rgba(175, 180, 182, 0.3) 0px 3px 5px 0px',
        background: (props: any) => (props.isDisabled ? theme.color.disabled : theme.color.white),
        cursor: (props: any) => (props.isDisabled ? 'default' : 'pointer'),
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 5,
        paddingRight: 5,

        '&:hover': {
            background: theme.color.buttonHover.secondary,
            boxShadow: (props: any) => !props.isDisabled && '0 5px 10px 0 rgba(175, 180, 182, 0.5)',
        },
    },
    warehouseColumn: {
        width: '100%',
        maxWidth: 'calc(100% - 20px)',
        display: 'block',
    },
    iconColumn: {
        width: 20,
        display: 'flex',
    },
    warehouseName: {
        fontSize: 13,
        lineHeight: 1.2,
        color: theme.color.jet,
        fontWeight: 500,
        ...theme.overFlowText,
    },
    stockRecorderName: {
        fontSize: 12,
        lineHeight: 1.2,
        color: theme.color.stone,
        ...theme.overFlowText,
    },
    pleaseSelect: {
        fontSize: 14,
        color: theme.color.stone,
        lineHeight: '34px',
    },
    pleaseSelectCentered: {
        textAlign: 'center',
    },
    oneRowTitle: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function ProductUsageWarehouseSelectorCell(props) {
    const { rowData, column, closeModal, isDisabled, isOnlyNewUsage, onChange, isReadOnly } = props;
    const [isOpened, setIsOpened] = useState(false);
    const classes = useStyles({ isDisabled } as any);
    const { translater } = useTranslate();
    const [popoverId] = useState(uniqueId('warehousePopover'));

    function ignoreRowSelection(event) {
        document.body.click();
        event.stopPropagation();
    }

    function onToggle() {
        if (isReadOnly) {
            return;
        }
        const popovers = document.getElementsByClassName(classes.popover);
        if (!isEmpty(popovers)) {
            Array.from(popovers).forEach(popover => {
                if (popover.id !== popoverId && popover.id) {
                    document.getElementById(`${popover.id}_toggle`)?.click();
                }
            });
        }

        document.body.click();
        setIsOpened(currentState => !currentState);

        if (closeModal && isOpened) {
            closeModal();
        }
    }

    function onSubmit(onSumbmitProps) {
        const { releaseWarehouse, usageType, partner, releaseType, stockRecorder, company, receivingWarehouse } = onSumbmitProps;

        if (isFunction(onChange)) {
            onChange({
                usageTypeId: usageType,
                releaseTypeId: releaseType,
                stockRecorderId: stockRecorder?.id,
                stockRecorderName: stockRecorder?.name,
                partnerId: partner?.id,
                partnerName: partner?.name,
                companyId: company?.id,
                companyName: company?.name,
                partnerCompanyId: 0,
                releaseWarehouseId: releaseWarehouse?.id,
                releaseWarehouseName: releaseWarehouse?.name,
                receivingWarehouseId: receivingWarehouse?.id,
                receivingWarehouseName: receivingWarehouse?.name,
                stockPrice: releaseWarehouse?.stockPrice || 0,
            });
        }

        onToggle();
    }

    return (
        <div className={classes.buttonWrapper} onClick={ignoreRowSelection} onKeyDown={ignoreRowSelection} role="button" tabIndex={0}>
            <OverlayTrigger
                trigger="click"
                show={isOpened}
                placement="auto"
                rootClose
                overlay={
                    <Popover className={clsx(classes.popover, 'warehousePopover')} id={popoverId}>
                        <Popover.Content>
                            <WarehouseSelectorPopup
                                rowData={rowData}
                                column={column}
                                closeModal={onToggle}
                                isOnlyNewUsage={isOnlyNewUsage}
                                onSubmit={onSubmit}
                                isReadOnly={isReadOnly}
                            />
                        </Popover.Content>
                    </Popover>
                }
            >
                <div id={`${popoverId}_toggle`} className={classes.popupButton} onClick={onToggle} role="button" tabIndex={0}>
                    <div className={clsx(classes.warehouseColumn, isEmpty(rowData?.stockRecorderName) && classes.oneRowTitle)}>
                        {!rowData?.releaseWarehouseName && !rowData?.stockRecorderName && !rowData?.partnerName && !rowData?.releaseWarehouseCompanyName
                            ? (
                                <div className={clsx(classes.pleaseSelect, isReadOnly && classes.pleaseSelectCentered)}>
                                    {isReadOnly
                                        ? '-'
                                        : `${translater('default.pleaseSelect', 'Please select')}...`
                                    }
                                </div>
                            ) : (
                                <>
                                    <div className={classes.warehouseName} title={rowData?.releaseWarehouseName || rowData?.partnerName || rowData?.releaseWarehouseCompanyName}>
                                        {rowData?.releaseWarehouseName || rowData?.partnerName || rowData?.releaseWarehouseCompanyName}
                                    </div>
                                    {!isEmpty(rowData?.stockRecorderName) && (
                                        <div className={classes.stockRecorderName} title={rowData?.stockRecorderName}>
                                            {rowData?.stockRecorderName}
                                        </div>
                                    )}
                                </>
                            )
                        }
                    </div>
                    <div className={classes.iconColumn}>
                        <Svg iconId="icon-arrow_drop_down" style={classes.dropdownIcon} />
                    </div>
                </div>
            </OverlayTrigger>
        </div>
    );
}
