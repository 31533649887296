import { createUseStyles } from 'react-jss';
import { ThemeType } from '@map/services/mapTypes';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { useSlovakReportContext } from '@map/services/slovakianReports/slovakReportContext';
import { isEmpty } from 'lodash';
import { SKLayerType, SK_LAYERS, SK_LAYERS_ARRAY } from '../slovakReportsLayers';

const useStyles = createUseStyles<ThemeType | any>((theme: any) => ({
    overlay: {
        position: 'absolute',
        zIndex: 2,
        marginBottom: 30,
        marginLeft: 30,
        display: 'flex',
        gap: 15,
        bottom: 8,
        right: 64,
    },
    container: {
        width: 280,
        backgroundColor: theme.color.white,
        borderRadius: 6,
        boxShadow: theme.shadows.map,
        alignSelf: 'flex-start',
        padding: 10,
        paddingRight: 0,
    },
    itemTitle: {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 600,
        color: theme.color.raven,
    },
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 15,
        justifyContent: 'space-between',
    },
    legendWrapper: {
        overflowY: 'auto',
        ...theme.verticalScrollbar,
        maxHeight: 200,
        paddingRight: 4,
    },
    legendLabel: {
        margin: 0,
        marginLeft: 10,
    },
    legendItem: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        margin: [8, 0],
    },
    rectangle: {
        width: 20,
        height: 20,
    },
}));

export default function SlovakReportLayersLegend(props: any) {
    const { map } = props;
    const classes = useStyles();
    const [store, controller] = useSlovakReportContext();

    if (isEmpty(store.layering.layers?.filter(l => SK_LAYERS_ARRAY.find(ll => ll.id === l)?.type === SKLayerType.FEATURE))) {
        return null;
    }

    return (
        <div className={classes.overlay}>
            <div className={classes.container}>
                <div className={classes.headerWrapper}>
                    <span className={classes.itemTitle}>Legenda</span>
                </div>

                <div className={classes.legendWrapper}>
                    {SK_LAYERS.map((layerGroup, lidx) => {
                        const layers = layerGroup.children.filter(layer => store.layering.layers?.includes(layer.id as number) && layer.type === SKLayerType.FEATURE);

                        if (isEmpty(layers)) {
                            return null;
                        }

                        return (
                            <div key={`lidx_${lidx}`}>
                                <h3>{layerGroup.name}</h3>
                                {layers.map(layer => (
                                    <div key={layer.id}>
                                        <h4>{layer.name}</h4>
                                        {layer.legends?.map((legend, idx) => (
                                            <div key={`legend_${idx}`} className={classes.legendItem}>
                                                <div className={classes.rectangle} style={{ background: legend.strokeColor }} />
                                                <p className={classes.legendLabel}>{legend?.name || layer.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}
