/* eslint-disable react/no-danger */
import { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { alertActions } from '@states/actions/alertActions';
import Svg from '../Svg';

const useStyles = createUseStyles((theme: any) => ({
    alertTemplateContainer: {
        boxShadow: theme.shadows.alert,
        minWidth: 300,
        maxWidth: 600,
        display: 'flex !important',
        minHeight: 60,
        marginBottom: 25,
        fontSize: 13,
        borderRadius: 7.2,
        border: 'none !important',
        padding: 0,
        backgroundColor: theme.color.white,
        zIndex: theme.zIndex.alert,
    },
    title: {
        display: 'block',
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.25,
        marginBottom: 5,
    },
    close: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 26,
        width: 26,
        borderRadius: '50%',
        border: 'none',
        backgroundColor: theme.color.jet,
        marginTop: -10,
        right: -10,
        zIndex: theme.zIndex.close,
        '&:hover': {
            backgroundColor: theme.color.icons.deleteIcon,
        },
    },
    icon: {
        height: 28,
        width: 28,
        fill: (props: any) => (props.type === 'info' ? theme.color.jet : theme.color.white),
    },
    closeIcon: {
        fill: theme.color.white,
        height: 'inherit',
    },
    leftContainer: {
        height: 'auto',
        width: 50,
        borderBottomLeftRadius: 7.2,
        borderTopLeftRadius: 7.2,
        backgroundColor: (props: any) => {
            switch (props.type) {
                case 'info':
                    return theme.color.lilian;
                case 'success':
                    return theme.color.main;
                case 'error':
                    return theme.color.stop;
                default:
                    return theme.color.slow;
            }
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rightContainer: {
        minWidth: 250,
        maxWidth: 550,
        padding: [12, 18],
        display: 'flex',
        alignItems: 'center',
    },
    more: {
        display: 'block',
        fontSize: 12,
        fontWeight: 500,
        marginTop: 5,
        color: theme.color.main,
    },
}));

export const alertTimeout = 5000;

export default function AlertTemplate(props) {
    const { alertData } = props;
    const { type, message, title, moreInfo, id, isHtmlCodeAlert = false } = alertData;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const classes = useStyles({ type } as any);

    function getTypeSvg() {
        switch (type) {
            case 'info':
                return 'icon-info';
            case 'success':
                return 'icon-done';
            default:
                return 'icon-flag_warn';
        }
    }

    const disappearAlert = useRef<any>();

    useEffect(() => {
        disappearAlert.current = setTimeout(() => {
            dispatch(alertActions.removeAlert(id));
        }, alertTimeout);
    }, [disappearAlert, dispatch, id]);

    function closeAlert() {
        dispatch(alertActions.removeAlert(id));
    }

    function onMouseEnter() {
        clearTimeout(disappearAlert.current);
    }

    function onMouseLeave() {
        disappearAlert.current = setTimeout(() => {
            dispatch(alertActions.removeAlert(id));
        }, alertTimeout);
    }

    return (
        <Alert className={classes.alertTemplateContainer} onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
            <button type="button" className={classes.close} onClick={closeAlert}>
                <Svg iconId="icon-close" style={classes.closeIcon} />
            </button>
            <div className={classes.leftContainer}>
                <Svg iconId={getTypeSvg()} style={classes.icon} />
            </div>
            <div className={classes.rightContainer}>
                <div>
                    {title && <span className={classes.title}>{title}</span>}
                    {isHtmlCodeAlert
                        ? (
                            <span id={`alertMessage_${id}`} dangerouslySetInnerHTML={{ __html: message }} />
                        ) : message
                    }
                    {moreInfo && <Link to={moreInfo} className={classes.more}>{t('alert.learnMore', 'Learn More')}</Link>}
                </div>
            </div>
        </Alert>
    );
}
