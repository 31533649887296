import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import HttpStatus from 'http-status-codes';
import { errorActions } from '@states/actions/errorActions';
import { alertActions } from '@states/actions/alertActions';
import { alertTimeout } from './AlertTemplate';

export default function ErrorInfoPopup() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const errorCode = useSelector((state: any) => state.error.errorCode);
    const errorMessage = useSelector((state: any) => state.error.errorMessage);

    useEffect(() => {
        function resetErrorState() {
            setTimeout(() => {
                dispatch(errorActions.removeError());
            }, alertTimeout);
        }

        if (errorMessage && t && dispatch) {
            switch (errorCode) {
                case HttpStatus.BAD_REQUEST:
                    dispatch(alertActions.errorAlert(t('error.badRequest', 'An error occurred during the query!')));
                    break;
                case HttpStatus.PRECONDITION_FAILED:
                    dispatch(alertActions.errorAlert(errorMessage));
                    break;
                default:
                    dispatch(alertActions.errorAlert(t('error.default', 'An error occurred while performing the operation!')));
                    break;
            }
            resetErrorState();
        }
    }, [errorMessage, errorCode, t, dispatch]);

    return null;
}
