import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInAttributeTypes, BuiltInLayerTypes } from '@map/services/mapEnums';
import { InfoPanelLayerAttribute, Layer, LayerAttribute, ThemeType } from '@map/services/mapTypes';
import { getLayerAttributeCaptionString, getLayerCaptionString } from '@map/utils/mapCaptionUtils';
import { Feature } from 'geojson';
import { isNil } from 'lodash';
import { createUseStyles } from 'react-jss';
import { useMemo } from 'react';
import ActionButtons from './ActionButtons';
import CultureBlock from './CultureBlock';
import { InfoPanelScoutingFiles } from './InfoPanelScoutingFiles';
import { PropertyGrid, PropertyItem } from './PropertyBlock';

type Classes = 'main' | 'closeButton' | 'headerMain' | 'headerName' | 'headerCompany' | 'panelMain' | 'separator' | 'actionContainer' | 'loadingContainer' | 'loadingImage';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    main: {
        width: 330,
        right: 61,
        marginTop: 70,
        position: 'absolute',
        zIndex: 2,
        backgroundColor: theme.color.white,
        fontFamily: 'Rubik',
        borderRadius: 5,
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '66px auto 40px',
        gridTemplateAreas: "'panelHeader' 'panelBody' 'panelFooter'",
        background: 'blue',
        maxHeight: 'max(60%, 300px)',
        height: 'max(60%, 300px)',
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 12,
        display: 'block',
        boxSizing: 'border-box',
        width: 30,
        height: 30,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: theme.color.pearl,
        borderRadius: '100%',
        background: '-webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, #0B0D0F 46%, #0B0D0F 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, #0B0D0F 46%, #0B0D0F 56%,transparent 56%, transparent 100%)',
        backgroundColor: '#E3E6E8',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
    },
    headerMain: {
        height: 66,
        gridArea: 'panelHeader',
    },
    headerName: {
        fontSize: 20,
        fontWeight: 700,
        color: '#21282C',
        textAlign: 'left',
        marginLeft: 20,
        marginTop: 15,
        width: 270,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    headerCompany: {
        fontSize: 16,
        fontWeight: 400,
        color: '#454F54',
        float: 'left',
        marginLeft: 20,
        width: 270,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    panelMain: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#F0F3F4',
        paddingTop: '15px',
        overflowY: 'auto',
        overflowX: 'auto',
        gridArea: 'panelBody',
        '&::-webkit-scrollbar': {
            width: 7,
            position: 'absolute',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            borderRadius: 7,
            background: 'rgba(0, 0, 0, 0.4)',
            position: 'absolute',
        },
        scrollbarWidth: 'thin !important',
    },
    separator: {
        height: '1px',
        borderTop: `1px solid ${theme.color.pearl}`,
        margin: 10,
        color: '#E3E6E8',
    },
    actionContainer: {
        gridArea: 'panelFooter',
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    loadingContainer: {
        height: 20,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 5,
    },
    loadingImage: {
        height: 20,
        width: 20,
        objectFit: 'cover',
    },
}));

type TProps = {
    close: () => void;
    attributes: LayerAttribute[];
    cultureAttributes: LayerAttribute[];
    additionalAttributes?: InfoPanelLayerAttribute[];
    geom: Feature;
    selectedLayer?: Layer;
    geomId: number,
    isLoadingData: boolean,
    files?: any[];
    isMapRoute: boolean
}

function InfoPanelComponent({
    close,
    attributes,
    cultureAttributes,
    geom,
    additionalAttributes,
    selectedLayer,
    geomId,
    isLoadingData,
    files,
    isMapRoute,
}: TProps) {
    const { translater } = useTranslate();
    const classes = useStyles();

    const [store, controller] = useMapContext();

    const isPlanningLayer = controller.isPlanningLayer(selectedLayer?.id);
    const labelLayer = isPlanningLayer ? controller.getLayerByName(BuiltInLayerTypes.PLANNING) : selectedLayer;
    const layerName = useMemo(() => getLayerCaptionString(translater, labelLayer), [labelLayer, translater]);
    if (isNil(geom?.properties)) {
        return null;
    }

    const { name, companyName, area } = geom.properties;

    const getHeaderName = () => {
        if (selectedLayer?.name === BuiltInLayerTypes.METEOROLOGY) {
            if (!isNil(geom.properties?.locationName)) {
                return geom.properties?.locationName;
            }
        }
        if (selectedLayer?.isCustomLayer) {
            return selectedLayer.name;
        }

        return name || layerName || 'N/A';
    };

    const getHeaderCompanyName = () => {
        if (([BuiltInLayerTypes.METEOROLOGY, BuiltInLayerTypes.RECEIVED_SCOUTINGS, BuiltInLayerTypes.SENT_TASK]).includes((selectedLayer?.name ?? '') as BuiltInLayerTypes)) {
            return '';
        }
        if (selectedLayer?.isCustomLayer) {
            if (area) { return `${area?.toFixed(2)} ha`; }
            return null;
        }
        return `${companyName || 'N/A'} | ${area?.toFixed(2)} ha`;
    };

    const getSuffix = (attribute: LayerAttribute) => {
        if (selectedLayer?.name !== BuiltInLayerTypes.METEOROLOGY) {
            return attribute.suffix;
        }

        switch (attribute.attributeKey) {
            case BuiltInAttributeTypes.ATMOSPHERIC_PRESSURE:
                return geom?.properties?.atmosphericPressureUnit ?? attribute.suffix;
            case BuiltInAttributeTypes.TEMPERATURE:
                return geom?.properties?.temperatureUnit ?? attribute.suffix;
            case BuiltInAttributeTypes.SOIL_TEMPERATURE:
                return geom?.properties?.temperatureUnit ?? attribute.suffix;
            default:
                return attribute.suffix;
        }
    };

    return (
        <div className={classes.main}>
            <span
                className={classes.closeButton}
                onClick={() => close()}
                onKeyDown={() => close()}
                role="button"
                aria-label="Close"
                aria-hidden="true"
            />
            <div className={classes.headerMain}>
                <div className={classes.headerName} title={getHeaderName()}>{getHeaderName()} </div>
                <div className={classes.headerCompany} title={getHeaderCompanyName() ?? ''}> {getHeaderCompanyName()} </div>
            </div>
            <div className={classes.panelMain}>
                {cultureAttributes.map((cultureAttribute, index) => (
                    <CultureBlock
                        key={`${cultureAttribute.attributeKey}CultureIdInfoPanel${index}`}
                        name={geom.properties && geom.properties[`${cultureAttribute.attributeKey}Name`]}
                        label={getLayerAttributeCaptionString(translater, labelLayer, cultureAttribute)}
                        cultureId={geom.properties && geom.properties[`${cultureAttribute.attributeKey}Id`]}
                    />
                ))}
                {cultureAttributes.some(cultureAttribute => geom.properties && geom.properties[`${cultureAttribute.attributeKey}Name`] && geom.properties[`${cultureAttribute.attributeKey}Id`]) && (
                    <div className={classes.separator} />
                )
                }
                {isLoadingData && (
                    <div className={classes.loadingContainer}>
                        <img alt="" className={classes.loadingImage} src="/styles/img/page_loading.gif" />
                    </div>
                )}
                <PropertyGrid>
                    {(attributes).map((attribute, index) => (
                        <PropertyItem
                            key={`${attribute.id}PropIdInfoPanel${index}Layer${selectedLayer?.name}`}
                            label={getLayerAttributeCaptionString(translater, labelLayer, attribute)}
                            value={geom.properties && geom.properties[attribute.attributeKey]}
                            suffix={attribute.suffix}
                            attribute={attribute}
                        />
                    ))}
                    {(additionalAttributes)?.map((attribute, index) => (
                        <PropertyItem
                            key={`${attribute.id}PropIdInfoPanel${index}Layer${selectedLayer?.name}`}
                            label={getLayerAttributeCaptionString(translater, labelLayer, attribute)}
                            value={geom.properties && geom.properties[attribute.attributeKey]}
                            suffix={getSuffix(attribute)}
                            attribute={attribute}
                        />
                    ))}
                </PropertyGrid>
                {!isNil(files) && (
                    <div style={{ marginTop: 'auto' }}>
                        <InfoPanelScoutingFiles files={files} />
                    </div>
                )}
            </div>
            <div className={classes.actionContainer}>
                <ActionButtons selectedLayer={selectedLayer} geomId={geomId} geom={geom} isMapRoute={isMapRoute} />
            </div>
        </div>
    );
}

export default InfoPanelComponent;
