import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles({
    modalRight: {
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        minWidth: 780,
        height: '100%',
        borderBottomRightRadius: 12,
    },
});

export default function ModalRightPanel(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.modalRight, className)}>
            {children}
        </div>
    );
}
