import Button from '@baseComponents/buttons/Button';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import { LayerAttributeStatus, ThemeType } from '@map/services/mapTypes';
import { isNumber } from 'lodash';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';

type Classes = 'container';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        // padding: 20,
        // height: 60,
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 3,
    },
    selectorButton: {
        width: 160,
        height: 60,
    },
}));

type CProp = {
    selectorDefaultEntityIds?: number[]
    onGeometrySelector?: (selectedEntityIds: number[]) => void,
}

export const FeatureSelectorComponent = ({ onGeometrySelector, selectorDefaultEntityIds }: CProp) => {
    const classes = useStyles();
    const { t } = useTranslate();

    const [selectorButtonDisabled, setSelectorButtonDisabled] = useState(false);
    const [store, controller] = useMapContext();

    const calculateSelectedIds = () => {
        const geomSelectorDataLayers = store.multiSelectedGeometries?.map(geomSelector => geomSelector.dataLayer);

        const selectedIdsFromSelector: number[] = [];
        geomSelectorDataLayers.forEach(dataLayer => {
            dataLayer?.forEach(feature => {
                const entityId = feature.getProperty('entityId');
                if (isNumber(entityId)) {
                    selectedIdsFromSelector.push(entityId);
                }
            });
        });

        const activeAttributeLayer = store.layering.attributes?.find(x => x.isActive !== LayerAttributeStatus.INACTIVE)?.dataLayer;

        const idsFromActiveLayer: number[] = [];
        activeAttributeLayer?.forEach(feature => {
            const entityId = feature.getProperty('entityId');
            if (isNumber(entityId)) {
                idsFromActiveLayer.push(entityId);
            }
        });

        const allEntityIds = store.filters.entity?.ids ?? [];

        const idsWithGeometries = [...idsFromActiveLayer, ...selectedIdsFromSelector];

        const idsWithoutGeometris = allEntityIds.filter(id => !idsWithGeometries.includes(id));

        const addittionalSelectedIds = idsWithoutGeometris.filter(id => selectorDefaultEntityIds?.includes(id));

        if (onGeometrySelector) {
            onGeometrySelector([...selectedIdsFromSelector, ...addittionalSelectedIds]);
        }
    };

    return (
        <div className={classes.container}>
            <Button
                disabled={selectorButtonDisabled}
                onClick={calculateSelectedIds}
            >
                {t('map.layerFeatureSelectorSelect', 'Select')}
            </Button>
        </div>);
};
