export const INTERFACE_SETTINGS = {
    ACTIVE_CURRENCY: 'activeCurrency',
    PERIOD_YEAR: 'periodYear',
    TABLE_COLUMN_SWITCH_TYPE: 'tableColumnSwitchType',
    TABLE_SELECTED_CUSTOM_COLUMNS: 'tableSelectedCustomColumns',
    TABLE_VIEW_TYPE: 'tableViewType',
    GROUPING_BY: 'groupingBy',
    STATUS: 'status',
    WORKOPERATIONSMANAGEMENT_FILTERS: 'workOperationsManagementFilters',
    WORKOPERATIONSMANAGEMENT_SUBFILTERS: 'workOperationsManagementSubFilters',
    CULTIVATIONPERIODSMANAGEMENT_FILTERS: 'cultivationPeriodsManagementFilters',
    CULTIVATIONPERIODSEXPENSES_FILTERS: 'cultivationPeriodsExpensesFilters',
    CULTIVATIONPERIODSEXPENSES_SUBFILTERS: 'cultivationPeriodsExpensesSubFilters',
    CULTIVATIONPERIODSYIELDS_FILTERS: 'cultivationPeriodsYieldsFilters',
    CULTIVATIONPERIODSYIELDS_SUBFILTERS: 'cultivationPeriodsYieldsSubFilters',
    MAP_FILTERS: 'mapFilters',
    CULTIVATIONPERIODSSCOUTINGS_FILTERS: 'cultivationPeriodsScoutingsFilters',
    CULTIVATIONPERIODSSCOUTINGS_SUBFILTERS: 'cultivationPeriodsScoutingsSubFilters',
    WORKOPERATION_INDEX_CALENDER: 'workOperationsIndexCalendar',
    STOCK_MOVEMENT_DISPATCH_FILTERS: 'stockMovementDispatchFilters',
    WORK_OPERATION_DISPATCH_FILTERS: 'workOperationDispatchesFilter',
    WORKOPERATIONSQUALIFICATION_FILTERS: 'workOperationsQualificationFilter',
    WORKOPERATIONSQUALIFICATION_SUBFILTERS: 'workOperationsQualificationSubFilter',
    RECIVEDFLEETMOBILEDATA_MATERIAL_GRID_GROUPING_TYPE: 'recivedfleetmobiledata_materialGridGroupingType',
    RECIVEDFLEETMOBILEDATA_CUSTOM_COLUMNS_LAYOUTS: 'recivedfleetmobiledata_customColumnsLayouts',
    RECIVEDFLEETMOBILEDATA_FILTERS: 'recivedfleetmobiledata_filters',
    SOIL_ANALYSIS_FILTERS: 'soilAnalysisFilter',
    SOIL_ANALYSIS_SUBFILTERS: 'soilAnalysisSubFilter', 
    ANALYSIS_DATA_SERVICE_FILTERS: 'analysisDataServiceFilter',
    ANALYSIS_DATA_SERVICE_SUBFILTERS: 'analysisDataServiceSubFilter', 
    METEOROLOGY_FILTERS: 'meteorologyFilters',
    METEOROLOGY_SUBFILTERS: 'meteorologySubFilters',    
    LOCATION: 'location',
    DATE_RANGE: 'dateRange',
    TASKS_MANAGEMENT_FILTERS: 'TaskManagementFilters',
    TASKS_MANAGEMENT_SUBFILTERS: 'TaskManagementSubFilters',
    CULTIVATION_PERIODS_WORK_OPERATIONS_FILTERS: 'CultivationPeriodsWorkOperationsFilters',
    CULTIVATION_PERIODS_WORK_OPERATIONS_SUBFILTERS: 'CultivationPeriodsWorkOperationsSubFilters',
    MAP_SETTINGS: 'mapSettings',
    MATERIAL_CONSUMPTIOIN_FILTERS: 'MaterialConsumptionFilters',
    MATERIAL_CONSUMPTIOIN_SUBFILTERS: 'MaterialConsumptionSubFilters',
    PRODUCTION_PROCESS_START_DATE: 'startDate',
    PRODUCTION_PROCESS_END_DATE: 'endDate',
    EQUIPMENT_ANNOUNCEMENT_FILTERS: 'EquipmentAnnouncementFilters',
    EQUIPMENT_ANNOUNCEMENT_SUBFILTERS: 'EquipmentAnnouncementSubFilters',
    STOCK_DISTRIBUTION_FILTERS: 'StockDistributionFilters',
};

export const INTERFACE_FILENAME = {
    CURRENCY_SWITCH: 'CurrencySwitch',
    CULTIVATION_PERIODS_MANAGEMENT: 'CultivationPeriodsManagement',
    WORK_OPERATION: 'WorkOperation',
    WORK_OPERATION_QUALIFIATION: 'WorkOperationsQualification',
    RECIVEDFLEETMOBILEDATA: 'RecivedFleetMobileData',
    SCOUTING: 'Scouting',
    SOIL_ANALYSIS: 'SoilAnalysis',
    METEOROLOGY: 'Meteorology',
    MAP: 'Map',
    TASKS_MANAGEMENT: 'TaskManagement',
    CULTIVATION_PERIODS_WORK_OPERATIONS: 'CultivationPeriodsWorkOperations',
    MATERIAL_CONSUMPTION: 'MaterialConsumption',
};

export const INTERFACE_SAGA_FILENAME = {
    WORK_OPERATION: 'employment/newWorkOperations',
};
