import Svg from '@baseComponents/Svg';
import useTranslate from '@i18n/useTranslate';
import { ThemeType } from '@map/services/mapTypes';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';
import { createUseStyles } from 'react-jss';
import { FILES } from 'shared/src/constants';
import { fileUtils } from 'shared/src/modules';
import { Link } from 'react-router-dom';

type Tprops = {
    files?: any[];
}

type Classes = 'container' | 'filesBody' | 'header' | 'toggle' | 'expand' | 'file' | 'image' | 'iconFile' | 'iconSvg' | 'noDataCard';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: [10, 20],
    },
    filesBody: {
        display: 'grid',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        gridTemplateColumns: 'repeat(auto-fit, minmax(40%, 1fr))',
        gridGap: 5,
    },
    header: {
        fontSize: 12,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '16ch',
        display: 'flex',
        alignItems: 'center',
        color: theme.color.jet,
        ...theme.firstLetterUppercase,
        marginBottom: 3,
    },
    toggle: {
        height: 18,
        width: 18,
    },
    expand: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        height: 30,
        width: 30,
        borderRadius: '50%',
        border: '1px solid',
        borderColor: theme.color.pearl,
        outline: 'none',
        marginLeft: 'auto',
    },
    file: {
        position: 'relative',
        width: '100%',
        aspectRatio: '1 / 1',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        overflow: 'hidden',
        color: theme.color.white,
        '&:hover': {
            color: theme.color.jet,
            '& svg': {
                fill: [theme.color.jet, '!important'],
            },
        },
    },
    image: {
        alignSelf: 'stretch',
    },
    iconFile: {
        backgroundColor: theme.color.stone,
        color: theme.color.white,
    },
    iconSvg: {
        height: 50,
        width: 50,
        fill: theme.color.white,
    },
    noDataCard: {
        margin: 0,
        marginTop: 15,
        padding: 0,
        paddingLeft: 10,
        display: 'inline-block',
        color: theme.color.jet,
        fontSize: '14px !important',
        fontWeight: 500,
    },
}));

export const InfoPanelScoutingFiles = ({ files }: Tprops) => {
    const { t } = useTranslate();
    const classes = useStyles();

    function getFileTypeIcon(file) {
        const fileType = fileUtils.getFileType(file.fileName);
        return fileType ? `icon-file_${fileType}` : 'icon-file_default';
    }

    function getFileClassName(file) {
        return clsx(classes.file, file.typeId !== FILES.TYPE_ID.IMAGE && classes.iconFile);
    }

    function displayFile(file) {
        if (!file?.url) {
            return t('scoutingView.fileDoesntExists', 'File doesn`t exists');
        }

        if (file.typeId === FILES.TYPE_ID.IMAGE) {
            return <img alt="" src={file.url} className={classes.image} />;
        }

        return (
            <>
                <Svg
                    iconId={file.typeId === FILES.TYPE_ID.VOICE ? 'icon-file_audio' : getFileTypeIcon(file)}
                    style={classes.iconSvg}
                />
                <span>{file?.size} MB</span>
            </>
        );
    }
    return (
        <div className={classes.container}>

            <div className={classes.header}>
                {t('scoutingView.files', 'Files')}
            </div>
            <div className={classes.filesBody}>
                {!isNil(files) && !isEmpty(files)
                    ? (
                        files.map(file => (
                            file.url
                                ? (
                                    <Link to={file.url} target="_blank" download className={getFileClassName(file)} key={file.id}>
                                        {displayFile(file)}
                                    </Link>
                                )
                                : (
                                    <div className={clsx(classes.file, classes.iconFile)} key={file.id}>
                                        {displayFile(file)}
                                    </div>
                                )
                        ))
                    ) : (
                        <h3 className={classes.noDataCard}>
                            {t('default.noData', 'No data to display')}
                        </h3>
                    )
                }
            </div>
        </div>
    );
};
