/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { routerHelper } from '@utils/router/RouterHelper';
import { MODULES } from 'shared/src/routing';
import { getSystemModuleSvg } from 'shared/src/systemModuls/systemModuleList';

import Svg from '@baseComponents/Svg';
import Avatar from '@baseComponents/avatars/Avatar';
import MessagesCard from '../userMenu/MessagesCard';

const useStyles = createUseStyles((theme: any) => ({
    footerWrapper: {
        display: 'block',
        minHeight: (props: any) => (props.expanded ? 70 : 170),
        height: (props: any) => (props.expanded ? 70 : 170),
        width: (props: any) => (props.expanded ? 240 : 60),
    },
    expandFooter: {
        textAlign: 'center',
        padding: (props: any) => (props.expanded ? '15px 20px' : '10px 15px'),
        display: (props: any) => props.expanded && 'flex',
        backgroundColor: theme.color.white,
    },
    footerButton: {
        height: (props: any) => (props.expanded ? 42 : 30),
        width: (props: any) => (props.expanded ? 52 : 40),
        minWidth: (props: any) => (props.expanded ? 52 : 40),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.color.lilian,
        marginLeft: (props: any) => (props.expanded ? 9 : -4),
        marginTop: (props: any) => !props.expanded && 10,
        borderRadius: 4,
        border: '1px solid',
        borderColor: theme.color.pearl,
    },
    btnIcon: {
        height: 20,
        width: 20,
        fill: theme.color.raven,
    },
    capsuleAvatar: {
        '& > img': { // exp 32 !30
            width: (props: any) => (props.expanded ? 32 : 30),
            height: (props: any) => (props.expanded ? 32 : 30),
        },
    },
    moduleAvatar: {
        width: (props: any) => (props.expanded ? 32 : 30),
        height: (props: any) => (props.expanded ? 32 : 30),
        backgroundImage: (props: any) => props?.moduleAvatarUrl,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: (props: any) => (props.expanded ? '100%' : '90%'),
        marginBottom: (props: any) => !props.expanded && 6,
    },
    capsuleContainer: {
        display: 'flex',
        flexDirection: (props: any) => !props.expanded && 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.color.lilian,
        border: '1px solid',
        borderColor: theme.color.pearl,
        borderRadius: 9999,
        height: (props: any) => (props.expanded ? 42 : 70),
        marginLeft: (props: any) => !props.expanded && -4,
        width: (props: any) => (props.expanded ? 82 : 40),
        cursor: 'pointer',
        '&:focus': {
            outline: 'none !important',
        },
    },
}));

export default function SideNavBarFooter(props) {
    const { expanded, setShowUserMenu } = props;
    const userName = useSelector((state: any) => state.user.user.name);
    const currentSystemModule = useSelector((state: any) => state.user.user.settings.activeSystemModule);
    const userProfilePictureUrl = useSelector((state: any) => state.user.user.profilePictureUrl);
    const isConsultantUser = useSelector((state: any) => state.user.user.consultant);
    const isActivePlanningMode = useSelector((state: any) => state.system.planningModeisActive);

    const classes = useStyles({ expanded, moduleAvatarUrl: getSystemModuleSvg(currentSystemModule, isActivePlanningMode) });

    function handleSetShowUserMenu() {
        setShowUserMenu(currentState => !currentState);
    }

    return (
        <div className={classes.footerWrapper}>
            <div className={classes.expandFooter}>
                <div className={classes.capsuleContainer} onClick={handleSetShowUserMenu} role="button" tabIndex={0}>
                    <Avatar
                        name={userName}
                        className={classes.capsuleAvatar}
                        imageUrl={userProfilePictureUrl}
                        image
                    />
                    <div className={classes.moduleAvatar} />
                </div>

                <MessagesCard className={classes.footerButton} expanded={expanded} setShowUserMenu={setShowUserMenu} />

                {!isConsultantUser
                    ? (
                        <Link to={routerHelper.generateUrl(MODULES.SETTINGS, MODULES.SETTINGS.CONTENTS.INDEX)} onClick={() => setShowUserMenu(false)}>
                            <button type="button" className={classes.footerButton}>
                                <Svg iconId="icon-settings" style={classes.btnIcon} />
                            </button>
                        </Link>)
                    : null
                }
            </div>
        </div>
    );
}
