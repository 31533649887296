// eslint-disable-next-line import/no-cycle
import { routerHelper } from '@utils/router/RouterHelper';

function setCookie(name, value = '', days = 7) {
    let expires = '';

    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value}${expires}; path=/;`;
}

function getCookie(name?: string) {
    const nameString = `${name}=`;
    const cookieList = document.cookie.split(';');

    for (let i = 0; i < cookieList.length; i++) {
        let cookieValue = cookieList[i];
        while (cookieValue.charAt(0) === ' ') {
            cookieValue = cookieValue.substring(1, cookieValue.length);
        }

        if (cookieValue.indexOf(nameString) === 0) {
            return cookieValue.substring(nameString.length, cookieValue.length);
        }
    }

    return null;
}

function eraseCookie(name) {
    document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

const sessionService = {
    getAgentName,
    setAgentName,
    removeAgentName,
    getAuthToken,
    setAuthToken,
    removeAuthToken,
    getGisToken,
    getPlanningMode,
    setPlanningMode,
    removePlanningMode,
    getPlanningData,
    setPlanningData,
    removePlanningData,
    setLangCode,
    getLangCode,
    checkAuthToken,
    setDarkMode,
    getDarkMode,

    setGisTokenToAgent,
    getGisTokenToAgent,
    setGisDefaultCenter,
    getGisDefaultCenter,
};

export { sessionService };

function getAgentName() {
    const storageAgentName = routerHelper.getAgentNameFromWindowUrl();
    return getCookie(`${storageAgentName}_authAgent`);
}
function setAgentName(agentName) {
    const storageAgentName = routerHelper.getAgentNameFromWindowUrl();
    return setCookie(`${storageAgentName}_authAgent`, agentName);
}
function removeAgentName() {
    const storageAgentName = routerHelper.getAgentNameFromWindowUrl();
    return eraseCookie(`${storageAgentName}_authAgent`);
}

function getAuthToken() {
    const agentName = getAgentName();
    return getCookie(`${agentName}_authToken`);
}
function setAuthToken(authToken) {
    const agentName = getAgentName();
    return setCookie(`${agentName}_authToken`, authToken);
}
function removeAuthToken() {
    const agentName = getAgentName();
    return eraseCookie(`${agentName}_authToken`);
}

function getGisToken() {
    return sessionStorage.getItem('gisAuthToken');
}

function getPlanningMode() {
    const agentName = getAgentName();
    const planningMode = getCookie(`${agentName}_planningMode`);
    if (planningMode === 'true') {
        return true;
    }
    return false;
}
function setPlanningMode(planningMode) {
    const agentName = getAgentName();
    return setCookie(`${agentName}_planningMode`, planningMode);
}
function removePlanningMode() {
    const agentName = getAgentName();
    return eraseCookie(`${agentName}_planningMode`);
}

function getPlanningData() {
    const agentName = getAgentName();
    const planningData = getCookie(`${agentName}_planningData`);

    if (planningData) {
        return JSON.parse(planningData);
    }

    return {};
}
function setPlanningData(planningData) {
    const agentName = getAgentName();
    return setCookie(`${agentName}_planningData`, JSON.stringify(planningData));
}
function removePlanningData() {
    const agentName = getAgentName();
    return eraseCookie(`${agentName}_planningData`);
}

function setLangCode(langcode) {
    const agentName = getAgentName();
    return setCookie(`${agentName}_langCode`, langcode);
}
function getLangCode() {
    const agentName = getAgentName();
    return getCookie(`${agentName}_langCode`);
}
function checkAuthToken() {
    const agentName = getAgentName();
    return !!getCookie(`${agentName}_authToken`);
}

function setDarkMode(darkMode) {
    const agentName = getAgentName();
    return setCookie(`${agentName}_darkMode`, darkMode);
}

function getDarkMode() {
    const agentName = getAgentName();
    const darkMode = getCookie(`${agentName}_darkMode`);
    if (darkMode === 'true') {
        return true;
    }
    return false;
}

function setGisTokenToAgent(token) {
    const agentName = getAgentName();
    return setCookie(`${agentName}_gisAuthToken`, token);
}

function getGisTokenToAgent() {
    const agentName = getAgentName();
    return getCookie(`${agentName}_gisAuthToken`);
}

function setGisDefaultCenter(center) {
    const agentName = getAgentName();
    return setCookie(`${agentName}_gisDefaultCenter`, center);
}

function getGisDefaultCenter() {
    const agentName = getAgentName();
    return getCookie(`${agentName}_gisDefaultCenter`);
}
