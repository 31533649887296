import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { arrayUtils, numberUtils } from 'shared/src/modules';
import { isFunction } from 'lodash';
import clsx from 'clsx';
import { useNumberFormat } from '@common_modules/numberFormat';
import { UnitInput } from '@baseComponents/inputs/inputField';

const useStyles = createUseStyles((theme: any) => ({
    quantityInputWrapper: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    quantityInput: {
        '& div': {
            borderColor: (props: any) => props.isRequired && (!props.hasInputValue || props.isInvalid) && theme.color.required && !props.isDisabled && theme.color.stop,
        },
    },
}));

export default function QuantityInputCell(props) {
    const { getIsDisabled, isDisabled, value, onBlur, onChange, rowData, unitId, columnId, isRequired, maxValue, minValue, cellId, maxValueColumnId, quantityFormat, showDependOnCheck, tableKey, className, isDisabledValidation } = props;
    const [formattedCellId] = useState(cellId ? `${cellId}_${rowData.id}` : null);
    const { numberFormatter } = useNumberFormat();
    const [isDisabledState] = useState(checkIsDisabled());
    const [inputValue, setInputValue] = useState(quantityFormat ? numberFormatter.getRoundedValue(value, quantityFormat, ',') : value);
    const [isInvalid, setIsInvalid] = useState(false);
    const classes = useStyles({ isDisabled, isRequired, hasInputValue: numberFormatter.getValueFromString(inputValue) > 0, isInvalid });

    //FIXME: temp functionality
    const selectedItemIds = useSelector((state: any) => (!tableKey ? state.basePage.table.selectedItemIds : state.simpleTable.table[tableKey]?.selectedItemIds));

    const [inputNumberFormatter, setInputNumberFormatter] = useState();

    useEffect(() => {
        if (quantityFormat && rowData) {
            const unit = rowData ? arrayUtils.getArrayItemByKeyString(rowData, unitId) : null;
            const quantityFormatCopy = { ...quantityFormat };
            quantityFormatCopy.unit = unit || quantityFormatCopy.unit;
            setInputNumberFormatter(quantityFormatCopy);
        }
    }, [quantityFormat, rowData, unitId]);

    //check value is valid
    useEffect(() => {
        if (isRequired && maxValueColumnId && value && rowData && formattedCellId) {
            validateInput(rowData, maxValueColumnId, value, formattedCellId);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRequired, maxValueColumnId, value, rowData, formattedCellId]);

    function checkIsDisabled() {
        if (isFunction(isDisabledValidation)) {
            return isDisabledValidation(rowData);
        }

        if (isFunction(getIsDisabled)) {
            return getIsDisabled(rowData);
        }

        return isDisabled || false;
    }
    function validateInput(row, maxColumnId, currentValue, cellElementId) {
        const maxValidValue = maxValue || arrayUtils.getArrayItemByKeyString(row, maxColumnId);
        if (Number(maxValidValue)) {
            setIsInvalid(+maxValidValue <= +currentValue);
        } else if (numberUtils.isNumber(minValue)) {
            setIsInvalid(+currentValue <= +minValue);
        }

        if (cellElementId) {
            const inputCell = document.getElementById(cellElementId);
            if (inputCell) {
                inputCell.style = null;
            }
        }
    }

    function onChangeValue(_event, currentValue) {
        let formattedValue = currentValue;

        if (quantityFormat) {
            formattedValue = numberFormatter.getRoundedValue(currentValue, quantityFormat, ',');
        }

        setInputValue(formattedValue || 0);

        if (isRequired) {
            validateInput(rowData, maxValueColumnId, currentValue, formattedCellId);
        }

        if (isFunction(onChange)) {
            onChange(currentValue, rowData, columnId);
        }
    }

    function onBlurValue(_event, currentValue) {
        if (isFunction(onBlur)) {
            onBlur(currentValue, rowData, columnId);
        }
    }

    //FIXME: temp functionality
    if (showDependOnCheck && !selectedItemIds?.includes(rowData.id)) {
        return null;
    }

    return (
        <div className={clsx(classes.quantityInputWrapper, className)}>
            <UnitInput
                id={formattedCellId}
                type="number"
                defaultValue={inputValue}
                onChange={onChangeValue}
                onBlur={onBlurValue}
                numberFormat={quantityFormat}
                className={classes.quantityInput}
                isDisabled={checkIsDisabled()}
                min={minValue}
                max={maxValue}
                positveOnly
            />
        </div>
    );
}
