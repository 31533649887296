import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const certificateService = {
    getCertificatesByRequestIds,
    getCertificatesByIds,
};

async function getCertificatesByRequestIds(props) {
    const { requestIds } = props || {};
    const url = routerHelper.getUrlWithParameters('/api/certificates/getCertificatesByRequestIds', { requestIds });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCertificatesByIds(props) {
    const { releasedCertificateIds, receptionCertificateIds } = props || {};
    const url = routerHelper.getUrlWithParameters('/api/certificates/getCertificatesByIds', { releasedCertificateIds, receptionCertificateIds });
    const response = await sendServerRequest('GET', url);
    return response;
}
