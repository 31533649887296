import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { isArray, isEmpty } from 'lodash';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import GroupOperationButton from '@baseComponents/basePageNew/body/bodyFunctionLine/bodyFunctionLineLeftSide/groupOperationButton/GroupOperationButton';
import useSoilAnalysisTable from './useAnalysisDataTable';

const useStyles = createUseStyles((theme: any) => ({
    table: {
        position: 'relative',
        height: '100%',
    },
    tableWrapper: {
        height: '100%',
        '& .table-row': {
            paddingRight: 0,
        },
        '& .List': {
            ...theme.verticalScrollbar,
        },
        '& .bodyRow': {
            height: 56,
        },
        '& .tableHeaderRow': {
            marginRight: 0,
        },
    },
    tableScrollerFooter: {
        paddingTop: 7,
        background: theme.color.transparent,
    },
}));

export default function AnalysisDataEditTable(props) {
    const { tableId } = props;
    const classes = useStyles();
    const { watch, setValue } = useFormContext();
    const selectedRowIds = useSelector((state: any) => state.simpleTable.table?.[tableId]?.selectedItemIds);

    const [tableGroup] = useState(
        {
            value: null,
            text: 'No breakdown',
        },
    );

    const { soilAnalysisTableData, columns } = useSoilAnalysisTable({ tableId, soilAnalysisId: watch('id') });

    return (
        <>
            <div className={classes.table}>
                <LoadSpinner value={!watch('isLoading')} />
                {!isEmpty(soilAnalysisTableData) && !isEmpty(columns)
                    && (
                        <>
                            <GroupOperationButton tableKey={tableId} />
                            <TableGroup
                                useRowSelection
                                tableId={tableId}
                                tableData={soilAnalysisTableData}
                                tableColumns={columns}
                                isBasePageTable={false}
                                isCollapsible={false}
                                tableGroup={tableGroup}
                                className={classes.tableWrapper}
                                selectedRowIds={selectedRowIds}
                                onListItemsRendered={() => setValue('isLoading', false)}
                            />
                        </>
                    )}
            </div>
            <div className={classes.tableScrollerFooter}>
                <TableScrollerFooter tableKey={tableId} isLoaded={isArray(soilAnalysisTableData)} useRowSelection />
            </div>
        </>
    );
}
