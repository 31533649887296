import Button from '@baseComponents/buttons/Button';
import CloseButton from '@baseComponents/buttons/CloseButton';
import DeleteConfirmationModal from '@baseComponents/modals/DeleteConfirmationModal';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import MapController from '@map/services/mapController';
import { Layer, ThemeType } from '@map/services/mapTypes';
import { isEmpty } from 'lodash';
import { createUseStyles } from 'react-jss';
import { LayerFolderEditMode } from './LayerFolderEdit.enum';
import { getLayerName } from './LayerFolderUtils';

type Classes = 'container' | 'containerHeader' | 'headerTitle' | 'actionContainer';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        position: 'absolute',
        zIndex: 100003,
        width: 'fit-content',
        height: 'fit-content',
        backgroundColor: theme.color.white,
        borderRadius: 10,
        boxShadow: theme.shadows.map,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 10,
    },
    containerHeader: {
        display: 'flex',
        width: '100%',
        height: 32,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    headerTitle: {
        margin: 0,
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
    },
}));

type Tprops = {
    setEditPanelMode: (mode: LayerFolderEditMode) => void;
    editPanelMode: LayerFolderEditMode
    layer: Layer,
    mapController: MapController
}

export function LayerFolderDelete({ editPanelMode, setEditPanelMode, layer, mapController }: Tprops) {
    const classes = useStyles();

    const closePanel = () => {
        setEditPanelMode(LayerFolderEditMode.NONE);
    };

    const [store, controller] = useMapContext();

    const { t, translater } = useTranslate();

    const findLayerChildren = (layerIds: number[]): number[] => {
        let children: number[] = [];

        for (let i = 0; i < layerIds.length; i++) {
            const layerChildren = store.layering.layers?.filter(lay => lay.parentId === layerIds[i]).map(lay => lay.id);
            if (layerChildren) {
                children = [...children, ...layerChildren];
            }
        }

        if (!isEmpty(children)) {
            return [...children, ...findLayerChildren(children)];
        }

        return [];
    };

    const removeLayerClick = async () => {
        const children = findLayerChildren([layer.id]);

        await mapController.removeLayerData(layer.id, children);
        closePanel();
    };

    //TODO: Tranlations
    const questionText = translater<string>('map.layerDeleteTitle', 'Delete layer/folder');
    const descriptionText = translater<string>('map.layerDeleteQuestion', 'Do you really want to delete {{layername}} layer/folder?', { layername: getLayerName(layer, translater) });

    return (
        <div className={classes.container}>
            <DeleteConfirmationModal descriptionText={descriptionText} questionText={questionText} handleDeleteClicked={removeLayerClick} hideModal={closePanel} />;
        </div>
    );
}
