import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { PARTNER, SETTINGS } from 'shared/src/constants';
import { stringUtils } from 'shared/src/modules/stringUtils';
import { errorActions, companyActions } from '@states/actions';
import { partnerService } from '@services';

import { SimpleSelect } from '@baseComponents/select';

export default function CompanyDropdown(props) {
    const { companyId, setCompanyId, onChange, className, useAbsoluteMenu, isDisabled } = props;
    const dispatch = useDispatch();
    const companyIds = useSelector((state: any) => state.company.activeCompanyIds);
    const [companyList, setCompanyList] = useState<Array<any>>();
    const [filteredCompanyList, setFilteredCompanyList] = useState<Array<any>>();
    const [selectedCompanyId, setSelectedCompanyId] = useState<any>(null);
    const [isNonAccentSearch] = useState(dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)));

    //initial datas
    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const partnerAndCompany = await partnerService.getPartnersAndCompanies({ companyIds });

                let companiesAndPartners = partnerAndCompany?.filter(companyOrPartner => companyOrPartner.typeId === PARTNER.TYPES.COMPANY);
                companiesAndPartners = companiesAndPartners?.map(companyObj => ({ value: companyObj.name, key: companyObj.id, typeId: companyObj.typeId }));

                if (!isCancelled) {
                    setCompanyList(companiesAndPartners); //init original data list
                    setFilteredCompanyList(companiesAndPartners); //init filtered data list
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'CompanyDropdown'));
            }
        }

        if (companyIds) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [companyIds, dispatch]);

    //init selected
    useEffect(() => {
        if (companyId && !selectedCompanyId) {
            setSelectedCompanyId(companyId);
        } else if (!companyId && selectedCompanyId) { //reset value
            setSelectedCompanyId(undefined);
        }
    }, [companyId, selectedCompanyId]);

    //search function
    function searchCompany(searchValue) {
        if (!isEmpty(searchValue) && !isEmpty(companyList)) {
            const filteredList = companyList?.filter(companyObj => stringUtils.includesNoCase(companyObj.value, searchValue, isNonAccentSearch));
            setFilteredCompanyList(filteredList);
        } else {
            setFilteredCompanyList(companyList);
        }
    }

    function changeCompany(selectedId) {
        setSelectedCompanyId(selectedId);

        if (setCompanyId) {
            setCompanyId(selectedId);
        }

        if (onChange) {
            const selectedObj = filteredCompanyList?.find(companyObj => stringUtils.isSameNoCase(companyObj.key, selectedId));
            onChange(selectedObj);
        }
    }

    return (
        <SimpleSelect
            selected={selectedCompanyId}
            dataList={filteredCompanyList}
            onChange={changeCompany}
            search={searchCompany}
            className={className}
            useAbsoluteMenu={useAbsoluteMenu}
            disabled={isDisabled}
        />
    );
}
