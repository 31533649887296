/* eslint-disable max-lines-per-function */
/* eslint-disable no-console */
/* eslint-disable id-blacklist */
/* eslint-disable no-undef */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HttpStatus from 'http-status-codes';
import { LANGUAGE } from 'shared/src/constants';
import { MODULES, getAPIEndpoint, getPHPEndpoint } from 'shared/src/routing';
import { numberUtils, languageUtils } from 'shared/src/modules';
import { phpService, sessionService } from '@services';
import { routerHelper } from '@utils/router/RouterHelper';
import { errorActions, userActions } from '@states/actions';
import { phpHelper } from '@utils/php/PhpHelper';

import { setPageLoading } from '@baseComponents/PageLoader';

import { isEmpty, isNull } from 'lodash';

export default function usePhpContent() {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation().pathname;
    const locationParams = useHistory().location.search;
    const { i18n } = useTranslation();

    const planningModeisActive = useSelector((state: any) => state.system.planningModeisActive);
    const [currentLanguageCode] = useState(i18n.language);
    const [isPlanningMode] = useState(sessionService.getPlanningMode());
    const [agentName] = useState(sessionService.getAgentName());
    const [qTipId, setQTipId] = useState(null);
    const [isLoadedPage, setIsLoadedPage] = useState(false);
    const [isSettingsContentActiv, setSettingsContentActiv] = useState(false);

    const [isReloadPage, setIsReloadPage] = useState(false);

    const isTranslaterUser = useSelector((state: any) => state.user.user.translater);

    //init hibrid global functions
    useEffect(() => {
        if (agentName && history && location) {
            window.openUrlWindowInReact = (url, blank) => {
                if (url) {
                    const hrefObj = phpHelper.getHrefExportUrl(url);

                    //href blank exportlink
                    if (hrefObj && hrefObj.exportLink) {
                        const exportLink = phpService.getExportLink(hrefObj.exportLink);
                        window.open(exportLink, '_blank');
                        return false;
                    }

                    //simple new blank
                    const hash = routerHelper.getHashFromLocation(url);
                    const redirectUrl = getAPIEndpoint(hash, agentName, isPlanningMode);
                    if (!redirectUrl) {
                        return false;
                    }

                    if (blank) {
                        window.open(redirectUrl, '_blank');
                    } else {
                        history.push(redirectUrl);
                    }
                }
            };

            //$(location).attr
            window.openUrlInReact = (__, url) => {
                if (url) {
                    const hash = routerHelper.getHashFromLocation(url);
                    const redirectUrl = getAPIEndpoint(hash, agentName, isPlanningMode);
                    history.push(redirectUrl);

                    if (url.indexOf('?id=0&') !== -1 || url.indexOf('&id=0&') !== -1) { // save 'something' on edit page and reload to create new 'something' ('SAVE AND NEW' buttons)
                        window.reloadPhpContent(url);
                    }
                }
            };

            window.reloadPhpContent = url => {
                let redirectUrl = null;

                if (url) {
                    const hash = routerHelper.getHashFromLocation(url);
                    redirectUrl = getAPIEndpoint(hash, agentName, isPlanningMode);
                    history.push(redirectUrl);
                }
                if (!redirectUrl || routerHelper.isEqualUrlsWithoutParams(redirectUrl, location)) {
                    setIsReloadPage(currentState => !currentState);
                }
            };

            window.onerror = error => {
                console.error(error);
                return true;
            };
        }
    }, [agentName, history, location, isPlanningMode]);

    //init php href handler
    useEffect(() => {
        let isMounted = true;

        function handleFilterPanelVisibility(event, checkNodeParent, filterPanelId) {
            const filterButton = document.getElementById('btn-szuro');

            setTimeout(() => {
                const filterPanel = document.getElementById(`qtip-${filterPanelId}`);
                if (filterButton) {
                    const onFilterClicked = filterButton.contains(event.target);
                    const node = checkNodeParent(event.target, true, true);

                    //if clicked on navigator button then hide panel
                    if (filterPanel && !onFilterClicked && node) {
                        filterPanel.style.display = '';
                    }
                }
            }, 50); //first time need delay to create and find qtip panel
        }

        function handleGroupPanelVisiblity(event, checkNodeParent) {
            const groupButton = document.getElementById('btn-csoportositas');

            setTimeout(() => {
                const groupPanel = document.getElementById('qtip-av2-qtip');
                const groupPanels = document.querySelectorAll("[id^='qtip-']");

                if (groupButton) {
                    const onGroupClicked = groupButton.contains(event.target);
                    const node = checkNodeParent(event.target, true, true);

                    //if clicked on navigator button then hide panel
                    if (groupPanel && !onGroupClicked && node) {
                        groupPanel.style.display = '';
                    }

                    if (groupPanels && !onGroupClicked && node) {
                        groupPanels.forEach(panel => {
                            panel.style.display = '';
                        });
                    }
                }
            }, 50); //first time need delay to create and find qtip panel
        }

        window.onclick = event => {
            const node = phpHelper.getRedirectorNodeParent(event.target);

            if (node) {
                //qtip panels visibility handler
                const qtipId = phpHelper.getQtipIdFromNode(event.target);
                let currentQtipId = null;
                if (numberUtils.isNumber(qtipId)) {
                    currentQtipId = qtipId;
                    if (isMounted) {
                        setQTipId(qtipId);
                    }
                }
                if (numberUtils.isNumber(qtipId)) {
                    handleFilterPanelVisibility(event, phpHelper.getRedirectorNodeParent, qTipId);
                }
                handleGroupPanelVisiblity(event, phpHelper.getRedirectorNodeParent);

                //node don't have redirect url (simple btn)
                if (!node.href) {
                    return true;
                }

                const hrefUrl = node.href?.baseVal || node.href;
                const subMenuElem = phpHelper.checkContentHrefSubmenu(event.target);

                //href start with #
                if (phpHelper.checkHrefStartHashmark(hrefUrl)) {
                    if (phpHelper.checkHrefIsTabControl(hrefUrl)) { //href is tabcontrol - don't have redirect url
                        return true;
                    }
                    event.preventDefault();

                    //get api endpoint and redirect to url
                    const redirectUrl = getAPIEndpoint(hrefUrl, agentName, isPlanningMode);
                    if (event?.ctrlKey && redirectUrl) {
                        window.open(redirectUrl, '_blank');
                    } else if (redirectUrl) {
                        if (subMenuElem) {
                            subMenuElem.style.display = 'none';
                        }
                        history.push(redirectUrl);
                    } else {
                        history.push(`/${agentName}`);
                    }
                    return false;
                }

                //href is export link
                const hrefObj = phpHelper.getHrefExportUrl(hrefUrl);
                if (hrefObj && hrefObj.exportLink) {
                    event.preventDefault();
                    const exportLink = phpService.getExportLink(hrefObj.exportLink);
                    window.open(exportLink, '_blank');
                    return false;
                }

                //href is full url
                if (phpHelper.checkHrefIsFullUrl(hrefUrl)) {
                    event.preventDefault();
                    if (currentQtipId) { //if the url open from qtip then close qtip-popup
                        document.getElementById(`qtip-${currentQtipId}`).style.display = 'none';
                    }
                    const hash = routerHelper.getHashFromLocation(hrefUrl);

                    //url open in new tab
                    const target = node.getAttribute('target');
                    if (phpHelper.checkHrefTargetisBlank(target) || event?.ctrlKey) {
                        const redirectUrl = getAPIEndpoint(hash, agentName, isPlanningMode);
                        if (redirectUrl) {
                            window.open(redirectUrl, '_blank'); //system url
                        } else {
                            window.open(hrefUrl, '_blank'); //external url
                        }
                        return false;
                    }

                    //url open new tab when url is part of agrovir
                    if (routerHelper.getIsAgrovirUrl(hrefUrl)) {
                        window.open(hrefUrl, '_blank');
                        return false;
                    }

                    //redirect to the converted url
                    const redirectUrl = getAPIEndpoint(hash, agentName, isPlanningMode);
                    if (redirectUrl) {
                        if (subMenuElem) {
                            subMenuElem.style.display = 'none';
                        }

                        //workoperation page - fix auxiliary exception
                        const moduleContentName = routerHelper.getModuleContentNameByUrl(location);
                        if (moduleContentName === `${MODULES.EMPLOYMENT.NAME}/${Object.keys(MODULES.EMPLOYMENT.CONTENTS.FIX_AUXILIARY_OPERATION.CONTENT_URL_ID)[0]}`) {
                            if (isMounted) {
                                setIsReloadPage(currentState => !currentState);
                            }
                        }
                        history.push(redirectUrl);
                    }
                    return false;
                }
            }
            return true;
        };

        return () => {
            isMounted = false;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //php content loader
    useEffect(() => {
        let ajaxRequest = null;
        let isMounted = true;

        function addLineNumbers(str, errorLine) {
            const lines = str.split('\n'); //A szöveg sortörések mentén való szétválasztása egy tömbbe

            for (let i = 0; i < lines.length; i++) { // A sorszám hozzáadása minden sor elejéhez
                if (errorLine === i + 1) {
                    lines[i] = `>>> ${i + 1}: ${lines[i]}`;
                } else {
                    lines[i] = `${i + 1}: ${lines[i]}`;
                }
            }

            return lines.join('\n'); // A módosított sorok újraegyesítése stringgé
        }

        function checkSyntax(string) {
            const scriptRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
            const scripts = string.match(scriptRegex) || [];

            for (let i = 0; i < scripts.length; i++) {
                const script = scripts[i].replace(/<\/?script[^>]*>/g, '');
                try {
                    // eslint-disable-next-line no-new, no-new-func
                    new Function(script);
                } catch (error) {
                    //console.error('Syntax error in script:\n', script, '\n', e.message);
                    const errorLine = error.lineNumber - 2;
                    console.error(addLineNumbers(script, errorLine));
                    console.error(`Hiba a(z) ~${errorLine}. sorban: ${error.message}`);
                }
            }
        }

        async function postAjaxRequest(url, contentData, domName, useOldTranslater, isSettingsMenu, contentUrl) {
            if ($ && !isEmpty(url)) {
                if (!isSettingsMenu && !isEmpty(domName)) {
                    $(`#${domName}`).ajaxloader('load', { opacity: 100 });
                }

                //in dev mode - check php file syntax
                if (numberUtils.getBoolean(process.env?.REACT_APP_PHP_MANUAL_HMR) && contentData) {
                    $.ajax({
                        type: 'POST',
                        url: '/modules/react/devDebug.php',
                        data: {
                            data: contentData.data,
                            php_fpm_url: process.env?.REACT_APP_PHP_FPM_URL,
                        },
                        dataType: 'json',
                        success: ajaxResult => {
                            if (ajaxResult && ajaxResult.errorMessages && ajaxResult.errorMessages.length > 0) {
                                alert_message('DEV ERROR', ajaxResult.errorMessages);
                                console.error('[DEV ERROR]: ', ajaxResult.errorMessages);
                            }
                        },
                        error: (__, error) => {
                            console.log('Dev debug - Ajax loader error: ', error);
                        },
                    });
                }

                ajaxRequest = $.ajax({
                    type: 'POST',
                    url,
                    data: contentData ? {
                        data: contentData.data,
                        parameters: contentData.parameters,
                    } : null,
                    dataType: 'json',
                    success: ajaxResult => {
                        if (!isSettingsMenu && !isEmpty(domName)) {
                            $(`#${domName}`).ajaxloader('hide', { fade: true, fadespeed: 'fast' });
                        }

                        let pageContent = ajaxResult.page_content;
                        const gridAndViewNames = [];

                        //in dev mode - check php file javascript syntax
                        if (numberUtils.getBoolean(process.env?.REACT_APP_PHP_MANUAL_HMR)) {
                            checkSyntax(pageContent);
                        }

                        //old system unhandled errors - temp fix
                        try {
                            pageContent = phpHelper.handleRedirectFunctions(pageContent);
                            pageContent = phpHelper.handleSelectErrors(pageContent);
                            pageContent = phpHelper.handleSlickGridErrors(pageContent, gridAndViewNames);
                            pageContent = phpHelper.handleSlickGridDataViewErrors(pageContent, gridAndViewNames);
                            pageContent = phpHelper.handleOldMapErrors(pageContent);
                            pageContent = phpHelper.handleCtrlClick(pageContent); //firefox + jquery specific error
                        } catch (error) {
                            console.error('Unhandled error fixer: ', error);
                        }

                        //set dom
                        if (!isEmpty(domName)) {
                            try {
                                if (isMounted) {
                                    $(`#${domName}`).html(pageContent);
                                }
                            } catch (error) {
                                console.error(error);
                            }

                            phpHelper.clearOldDomElements();
                            phpHelper.changeSearchBarFormat();
                            phpHelper.useBootstrapTooltip();

                            if (numberUtils.getBoolean(process.env?.REACT_APP_PHP_MANUAL_HMR)) {
                                console.log('Content loaded!');
                            }

                            if (isMounted) {
                                setIsLoadedPage(true);
                            }
                        }

                        if (isSettingsMenu) {
                            //collapse setup side menu
                            const moduleId = routerHelper.getModuleIdFromUrl(location);
                            const contentId = routerHelper.getContentIdFromUrl(location);
                            const setupHref = `#${moduleId}/${contentId}?setupcontent=1`;
                            const subMenuElem = document.querySelector(`a[href='${setupHref}']`);
                            const subMenuParentElem = subMenuElem?.parentElement?.parentElement;
                            subMenuParentElem?.classList?.remove('collapse');
                            subMenuParentElem?.classList?.add('in');
                            subMenuElem?.classList?.add('active');

                            //load settings content
                            const contentDomId = 'setup_main_content';
                            const phpUrlData = getPHPEndpoint(contentUrl, isPlanningMode);
                            postAjaxRequest('/modules/content.php', phpUrlData, contentDomId, true);
                        }

                        //set translater fn
                        if (useOldTranslater && isTranslaterUser && !languageUtils.isSameLanguage(currentLanguageCode, LANGUAGE.HUNGARIAN)) {
                            AV2Lang.contextMenu(
                                ajaxResult.page_filename.toString(),
                                ajaxResult.module_name.toString(),
                            );
                        }

                        if (ajaxResult.statusCode === HttpStatus.FORBIDDEN) {
                            throw HttpStatus.FORBIDDEN;
                        }
                    },
                    failure: (__, error) => {
                        if (!isSettingsMenu && !isEmpty(domName)) {
                            $(`#${domName}`).ajaxloader('hide', { fade: true, fadespeed: 'fast' });
                        }

                        console.error('Hibrid php content ajax loader failure: ', error);
                    },
                    error: (__, error) => {
                        if (!isSettingsMenu && !isEmpty(domName)) {
                            $(`#${domName}`).ajaxloader('hide', { fade: true, fadespeed: 'fast' });
                        }

                        console.error('Hibrid php content ajax loader error: ', error);
                        //throw HttpStatus.MOVED_PERMANENTLY;
                    },
                });
            }
        }

        async function loadContent(url) {
            try {
                setPageLoading(true);
                phpHelper.clearPrevMultiselectCache();
                const contentDomId = 'main_content';

                //check the setup page param
                const settingMenu = routerHelper.getUrlParamValueBySearch(locationParams, 'setupcontent');
                const actualContentId = routerHelper.getContentIdFromUrl(location);
                const settingsContentId = routerHelper.getContentId(MODULES.SETTINGS.CONTENTS.INDEX);
                const actualModuleId = routerHelper.getModuleIdFromUrl(location);

                //navigating within the settings menu
                if (!isNull(settingMenu) && actualContentId !== settingsContentId) { //actual page is a setup content
                    if (isMounted) {
                        setSettingsContentActiv(true);
                    }
                    const settingsUrl = `/${agentName}/${MODULES.SETTINGS.NAME}/${routerHelper.getContentName(MODULES.SETTINGS.CONTENTS.INDEX)}`;
                    const phpUrlData = getPHPEndpoint(settingsUrl, isPlanningMode);

                    //load settings side menu
                    await postAjaxRequest('/modules/content.php', phpUrlData, contentDomId, false, true, url);
                } else if (isMounted) {
                    if (actualModuleId === MODULES.SETTINGS.MODULE_ID) {
                        setSettingsContentActiv(true);
                    } else {
                        setSettingsContentActiv(false);
                    }

                    //load php content
                    const phpUrlData = getPHPEndpoint(url, isPlanningMode);
                    await postAjaxRequest('/modules/content.php', phpUrlData, contentDomId, true);
                }
            } catch (errorCode) {
                if (errorCode === HttpStatus.FORBIDDEN) {
                    dispatch(userActions.logout());
                    setPageLoading(false);
                } else if (errorCode !== HttpStatus.MOVED_PERMANENTLY) { //redirect execute error
                    dispatch(errorActions.setError(errorCode, location));
                }
            } finally {
                setPageLoading(false);
            }
        }

        if (agentName && location && history && currentLanguageCode) {
            loadContent(`${location}${locationParams}`);
        }

        return () => {
            isMounted = false;

            if (ajaxRequest) {
                ajaxRequest.abort();
            }
        };
    }, [agentName, isPlanningMode, location, locationParams, history, dispatch, isTranslaterUser, currentLanguageCode, isReloadPage]);

    return {
        isLoadedPage,
        isSettingsContentActiv,
        planningModeisActive,
    };
}
