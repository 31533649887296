import Svg from '@baseComponents/Svg';
import { ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';

type Classes = 'container' | 'icon';

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        width: 34,
        height: 34,
        backgroundColor: (props: StyleProps) => (props.isActive ? theme.color.main : theme.color.white),
        boxShadow: theme.shadows.map,
        borderRadius: 6,
        margin: '8px 0',
        cursor: 'pointer',
    },
    icon: {
        width: 24,
        height: 24,
        fill: (props: StyleProps) => (props.isActive ? theme.color.white : theme.color.jet),
    },
}));

type StyleProps = {
    isActive: boolean,
}

type TProps = {
    iconId: string,
    onClick: () => void,
    isActive: boolean,
}

export default function MapIconButton({ iconId, onClick, isActive }: TProps) {
    const classes = useStyles({ isActive });

    return (
        <div className={classes.container} onClick={onClick} role="button" aria-hidden="true">
            <Svg iconId={iconId} style={classes.icon} />
        </div>
    );
}
