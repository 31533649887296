import { isNil, isNumber } from 'lodash';

export function currencyFormatter(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}

export function labelNumberFormatter(n: number | string, suffix?: string) {
    if (!isNumber(n) || isNil(suffix)) {
        return n;
    }

    return currencyFormatter(Number(n).toFixed(2));
}
