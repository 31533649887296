import { SYSTEM } from '../constants';

export function getSystemModuleList(i18n, activeSystemModules, currentSystemModule, planningMode) {
    const moduleList = activeSystemModules.map(moduleItem => {
        const module = {
            id: moduleItem.id,
            eventKey: `systemModule_${moduleItem.id}`,
            title: getSystemModuleName(i18n, moduleItem.id),
            style: getSystemModuleStyle(moduleItem.id, currentSystemModule),
            isVisible: checkSystemModuleIsVisible(moduleItem.id, planningMode),
        };
        return module;
    });
    return moduleList;
}

function getSystemModuleName(i18n, systemModuleId) {
    switch (systemModuleId) {
    case SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION: //1
        return i18n.t('systemModule.cropProduction', 'Crop Production');
    case SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD: //2
        return i18n.t('systemModule.vineryVineyard', 'Vinery, vineyard');
    case SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING: //3
        return i18n.t('systemModule.cattleFarming', 'Cattle farming');
    case SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM: //4
        return i18n.t('systemModule.pigFarm', 'Pig farm');
    case SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM: //5
        return i18n.t('systemModule.rabbitFarm', 'Rabbit farm');
    case SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM: //6
        return i18n.t('systemModule.poultryFarm', 'Poultry farm');
    case SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION: //7
        return i18n.t('systemModule.fruitPlantation', 'Fruit, plantation');
    case SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION: //8
        return i18n.t('systemModule.seedProduction', 'Seed production');
    default:
        return '';
    }
}

export function getSystemModuleSvg(currentSystemModule, isActivePlanningMode = false) {
    if (isActivePlanningMode) {
        return 'url("/styles/img/modul/wheat.svg")';
    }

    switch (currentSystemModule) {
    case SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION: //1
        return 'url("/styles/img/modul/wheat.svg")';
    case SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD: //2
        return 'url("/styles/img/modul/grape.svg")';
    case SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING: //3
        return 'url("/styles/img/modul/cow.svg")';
    case SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM: //4
        return  'url("/styles/img/modul/pig.svg")';
    case SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM: //5
        return 'url("/styles/img/modul/rabbit.svg")';
    case SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM: //6
        return 'url("/styles/img/modul/chicken.svg")';
    case SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION: //7
        return 'url("/styles/img/modul/fruit.svg")';
    case SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION: //8
        return 'url("/styles/img/modul/seedproduction.svg")';
    default:
        return '';
    }   
}

function getSystemModuleStyle(systemModuleId, currentSystemModule) {
    switch (systemModuleId) {
    case SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION: //1
        return {
            background: currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION ? 'url("/styles/img/modul/wheat_active.svg")' : 'url("/styles/img/modul/wheat.svg")',
        };
    case SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD: //2
        return {
            background: currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD ? 'url("/styles/img/modul/grape_active.svg")' : 'url("/styles/img/modul/grape.svg")',
        };
    case SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING: //3
        return {
            background: currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING ? 'url("/styles/img/modul/cow_active.svg")' : 'url("/styles/img/modul/cow.svg")',
        };
    case SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM: //4
        return {
            background: currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM ? 'url("/styles/img/modul/pig_active.svg")' : 'url("/styles/img/modul/pig.svg")',
        };
    case SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM: //5
        return {
            background: currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM ? 'url("/styles/img/modul/rabbit_active.svg")' : 'url("/styles/img/modul/rabbit.svg")',
        };
    case SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM: //6
        return {
            background: currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM ? 'url("/styles/img/modul/chicken_active.svg")' : 'url("/styles/img/modul/chicken.svg")',
        };
    case SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION: //7
        return {
            background: currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION ? 'url("/styles/img/modul/fruit_active.svg")' : 'url("/styles/img/modul/fruit.svg")',
        };
    case SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION: //8
        return {
            background: currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION ? 'url("/styles/img/modul/seedproduction_active.svg")' : 'url("/styles/img/modul/seedproduction.svg")',
        };
    default:
        return '';
    }
}

export function checkSystemModuleIsVisible(systemModuleId, planningMode) {
    switch (systemModuleId) {
    case SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING: //3
        if (planningMode === true) {
            return false;
        }
        return true;
    case SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM: //4
        if (planningMode === true) {
            return false;
        }
        return true;
    case SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM: //5
        if (planningMode === true) {
            return false;
        }
        return true;
    case SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM: //6
        if (planningMode === true) {
            return false;
        }
        return true;
    default:
        return true;
    }
}
