import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import { dataListsActions, errorActions } from '@states/actions';
import { GENERAL } from 'shared/src/constants';

export default function useFarmModalLoadData() {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState();
    const location = useLocation();

    const loadFarmList = useCallback(async () => {
        try {
            const farms = dispatch(dataListsActions.getFarmDataList(GENERAL.FILTER.STATUS.ACTIV, null));
            setTableData(farms?.map(farm => ({
                id: farm.id,
                farmName: farm.name,
            })));
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }, [dispatch, location]);

    useEffect(() => {
        loadFarmList();
    }, [dispatch, loadFarmList]);

    return {
        tableData,
    };
}
