/* eslint-disable no-console */
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { STORECONFIG } from 'shared/src/constants';
import { sentryService } from '@services';
import rootSaga from './sagas/rootSaga';
import rootReducer from './reducers';

const actionSanitizer = action => (
    action.basePage
        ? { ...action, basePage: '<<LONG_BLOB>>' } : action
);

const devToolsOptions = {
    maxAge: 50,
    latency: 300,
    actionSanitizer,
    stateSanitizer: (state) => state.basePage ? { ...state, basePage: '<<LONG_BLOB>>' } : state, // eslint-disable-line
    serialize: true,
};

const sagaMiddleware = createSagaMiddleware({
    onError(error) {
        console.error('SAGA ERROR', error.message);
        sentryService.captureException(error);
    },
});

//sentryService.captureMessage('Saga middleware initialized');

const middlewares = [
    sagaMiddleware,
    thunkMiddleware,
];

// DEV
function configureStoreDev() {
    //sentryService.captureMessage('Redux dev store initialized');
    const { LOGGER, REDUXDEVTOOLS } = STORECONFIG;
    const shouldLog = [
        // LOGGER,
        REDUXDEVTOOLS,
    ];
    if (shouldLog.includes(LOGGER)) {
        middlewares.push(logger);
    }
    const composeEnhancers = window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && shouldLog.includes(REDUXDEVTOOLS)
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(devToolsOptions) || compose
        : compose;

    const sentryReduxEnhancer = sentryService.createReduxEnhancer();

    let store = null;
    if (sentryReduxEnhancer) {
        store = createStore(rootReducer, composeEnhancers(
            applyMiddleware(...middlewares), sentryReduxEnhancer,
        ));
    } else {
        store = createStore(rootReducer, composeEnhancers(
            applyMiddleware(...middlewares),
        ));
    }
    sagaMiddleware.run(rootSaga);

    return store;
}

// PROD
function configureStoreProd() {
    const sentryReduxEnhancer = sentryService.createReduxEnhancer();

    let store: any = null;
    if (sentryReduxEnhancer) {
        store = createStore(
            rootReducer, compose(applyMiddleware(...middlewares), sentryReduxEnhancer),
        );
    } else {
        store = createStore(
            rootReducer, compose(applyMiddleware(...middlewares)),
        );
    }

    sagaMiddleware.run(rootSaga);
    return store;
}

export const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;
export const reduxStore = configureStore();
