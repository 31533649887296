import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { SETTINGS } from 'shared/src/constants';
import { stringUtils } from 'shared/src/modules/stringUtils';

import { companyActions } from '@states/actions';
import { SimpleSelect } from '@baseComponents/select';

export default function CultureDropdown(props) {
    const { cultureId, setCultureId, className } = props;

    const dispatch = useDispatch();

    const cultures = useSelector((state: any) => state.dataLists.cultures);
    const [cultureList, setCultureList] = useState(null);
    const [filteredCultureList, setFilteredCultureList] = useState(null);
    const [selectedCultureId, setSelectedCultureId] = useState(null);
    const [isNonAccentSearch] = useState(dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)));

    //initial datas
    useEffect(() => {
        if (cultures) {
            let formattedCultureList = cultures.filter(culture => culture.isActive);
            formattedCultureList = formattedCultureList?.map(cultureObj => ({ value: cultureObj.shortName, key: cultureObj.id }));
            setCultureList(formattedCultureList); //init original data list
            setFilteredCultureList(formattedCultureList); //init filtered data list
        }
    }, [cultures]);

    //init selected
    useEffect(() => {
        if (cultureId && !selectedCultureId) {
            setSelectedCultureId(cultureId);
        } else if (!cultureId && selectedCultureId) { //reset value
            setSelectedCultureId();
        }
    }, [cultureId, selectedCultureId]);

    //search function
    function searchCulture(searchValue) {
        if (!isEmpty(searchValue) && !isEmpty(cultureList)) {
            const filteredList = cultureList?.filter(companyObj => stringUtils.includesNoCase(companyObj.value, searchValue, isNonAccentSearch));
            setFilteredCultureList(filteredList);
        } else {
            setFilteredCultureList(cultureList);
        }
    }

    function changeCompany(selectedId) {
        setSelectedCultureId(selectedId);

        if (setCultureId) {
            setCultureId(selectedId);
        }
    }

    return (
        <>
            <SimpleSelect
                selected={selectedCultureId}
                dataList={filteredCultureList}
                onChange={changeCompany}
                search={searchCulture}
                className={className}
            />
        </>
    );
}
