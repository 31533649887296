import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { Form, Button as BootstrapButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userService } from '@services';
import { routerHelper } from '@utils/router/RouterHelper';
import Svg from '@baseComponents/Svg';
import Input from '@baseComponents/inputs/Input';
import Button from '@baseComponents/buttons/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLocation } from 'react-router';
import { alertActions, errorActions } from '@states/actions';
import { setPageLoading } from '@baseComponents/PageLoader';

const useStyles = createUseStyles((theme: any) => ({
    paddingLeft: {
        paddingLeft: 70,
    },
    logoContainer: {
        width: '100%',
        marginBottom: 50,
        position: 'relative',
    },
    logo: {
        backgroundImage: (props: any) => {
            if (props.isTestSystem) {
                return 'url("/styles/img/av_small_logo_test.png")';
            }
            return 'url("/styles/img/av_small_logo.png")';
        },
        margin: 'auto',
        display: 'block',
        width: 106,
        height: 38,
        float: 'middle',
    },
    systemCountryCode: {
        position: 'absolute',
        top: 0,
        left: 273,
        color: theme.color.raven,
        fontSize: 10,
    },
    backButton: {
        width: 40,
        height: 40,
        margin: 0,
        padding: 0,
        minWidth: 'auto',
        borderRadius: 50,
        backgroundColor: `${theme.color.pearl} !important`,
        borderColor: `${theme.color.pearl} !important`,
    },
    backIcon: {
        width: 40,
        height: 40,
        padding: 8,
    },
    forgottenPasswordContainer: {
        display: 'flex',
    },
    title: {
        marginTop: 0,
        marginBottom: 26,
        fontWeight: 700,
        fontSize: '24px!important',
        fontFamily: 'Rubik',
        textAlign: 'center',
    },
    rightContainer: {
        paddingLeft: 30,
    },
    text: {
        fontSize: 16,
        lineHeight: '22px',
        marginBottom: 30,
        color: theme.color.jet,
        textAlign: 'center',
    },
    inputSpace: {
        marginBottom: 20,
        height: 50,
    },
    button: {
        width: '100%',
    },
    thanks: {
        color: theme.color.jet,
        fontSize: 24,
        fontWeight: 'bold',
        lineHeight: '40px',
        marginBottom: 26,
        textAlign: 'center',
    },
    thanksText: {
        color: theme.color.jet,
        fontSize: 14,
        lineHeight: '22px',
        marginBottom: 40,
        textAlign: 'center',
    },
    returnButton: {
        width: '100%',
        color: theme.color.jet,
        backgroundColor: 'transparent!important',
        border: `1px solid ${theme.color.gray}!important`,
        boxShadow: 'none!important',
        height: 46,
        '&:hover': {
            boxShadow: '0 3px 5px 0 rgba(175,180,182,0.3)!important',
        },
    },
}));

export default function ForgetPassword(props) {
    const { onBack, countryCode } = props;
    const { t } = useTranslation();
    const agentName = useSelector((state: any) => state.user.agent);
    const isTestSystem = useSelector((state: any) => state.system.systemInfo.isTestSystem);
    const dispatch = useDispatch();
    const componentClasses = useStyles({ isTestSystem });
    const location = useLocation();
    const [isDone, setDone] = useState(false);

    const { values, handleChange, handleBlur, handleSubmit, errors } = useFormik({
        initialValues: { username: '' },
        validationSchema: Yup.object({
            username: Yup.string()
                .required(t('login.userNameRequired', 'Username is required')),
        }),
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: value => handleUsernameSubmit(value),
    });

    async function handleUsernameSubmit(event) {
        try {
            setPageLoading(true);
            const userName = event.username;
            const pageUrl = `${routerHelper.getBaseUrlFromLocation(window.location.href)}/${agentName}`;
            const messageSubject = t('forgetPasswordModal.avForgetPassword', 'AgroVIR: forgotten password');
            const messageBody = getPasswordReminderMessageBody();
            const isValid = await userService.sendPasswordReminder(userName, pageUrl, messageSubject, messageBody);
            if (isValid) {
                setDone(true);
            } else {
                setDone(false);
                dispatch(alertActions.errorAlert(t('forgetPasswordModal.validUserName', 'User name is not valid!')));
            }
        } catch (error) {
            dispatch(errorActions.setError(error, location.pathname));
        } finally {
            setPageLoading(false);
        }
    }

    function getPasswordReminderMessageBody() {
        return `<div>
                    <div>${t('forgetPasswordModal.kind', 'Kind')} {user_name}!</div><br /><br />
                    <div>${t('forgetPasswordModal.clickOnLink', 'Click on this link to change your password')}:</div>
                    <a href="{passwordConfirmUrl}">{passwordConfirmUrl}</a><br /><br />
                    <div>${t('forgetPasswordModal.expireLink', 'The link to change your password expires')}: {expire_date}</div><br />
                    <div><span style="font-size: 13px;"><strong>${t('forgetPasswordModal.ignoreInfo', 'If you did not request password change, please ignore this message')}.</strong></span></div>
                    <div><br />${t('forgetPasswordModal.greetings', 'Greetings')}, <br />{from_name} Support</div>
                </div>`;
    }

    return (
        <div>
            { !isDone
                && (
                <div>
                    <div className={clsx(componentClasses.logoContainer, componentClasses.paddingLeft)}>
                        <div className={componentClasses.logo} />
                        <div className={componentClasses.systemCountryCode}>{countryCode}</div>
                    </div>
                    <div className={componentClasses.forgottenPasswordContainer}>
                        <BootstrapButton onClick={onBack} className={componentClasses.backButton}>
                            <Svg style={componentClasses.backIcon} iconId="icon-arrow_back" />
                        </BootstrapButton>
                        <div className={componentClasses.rightContainer}>
                            <h2 className={componentClasses.title}>{t('forgetPasswordModal.forgottenPassword', 'Forgotten password')}</h2>
                            <div className={componentClasses.text}>{t('forgetPasswordModal.enterUserName', 'Please enter your user name to reset your password.')}</div>
                            <Form key="forgotPasswordForm" onSubmit={event => handleSubmit(event)}>
                                <Form.Group>

                                    <Input
                                        value={values.username}
                                        onChange={handleChange}
                                        onBlue={handleBlur}
                                        name="username"
                                        placeholder={t('login.userNamePlaceholder', 'Username')}
                                        required
                                        invalid={!!errors.username}
                                        errorMessage={errors.username}
                                        autoComplete="username"
                                        className={componentClasses.inputSpace}
                                    />

                                    <Button className={componentClasses.button} submit>{t('forgottenPassword.proceed', 'Proceed')}</Button>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                </div>
                )
            }

            { isDone
                && (
                    <>
                        <div className={clsx(componentClasses.logoContainer)}>
                            <div className={componentClasses.logo} />
                        </div>
                        <div className={componentClasses.thanks}>{t('forgottenPassword.thankyou', 'Thank you!')}</div>
                        <div className={componentClasses.thanksText}>{t('forgetPasswordModal.newPasswordEmail', 'We sent you an email with a new password!')}</div>
                        <Button className={componentClasses.returnButton} onClick={onBack}>{t('forgottenPassword.backToLogin', 'Back to login')}</Button>
                    </>)
            }
        </div>
    );
}
