import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { formUtils } from '@utils/form/formUtils';
import { isEmpty } from 'lodash';
import Svg from '../Svg';

const useStyles = createUseStyles((theme: any) => ({
    wrapper: {
        position: 'relative',
        display: 'inline-block',
    },
    container: {
        width: 152,
        display: 'flex',
        borderRadius: 4,
        border: 'solid 1px',
        boxShadow: (props: any) => (props.invalid ? theme.shadows.destructive.normal : theme.shadows.lightGrey),
        borderColor: (props: any) => (props.invalid ? theme.color.destructive : theme.color.gray),
    },
    addButton: {
        height: 38,
        width: 40,
        borderRadius: 4,
        backgroundColor: theme.color.white,
        '&:focus': {
            outline: 'none',
        },
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        boxShadow: '0 2px 3px 0 rgba(175, 180, 182, 0.15)',
        border: 'none',
        borderLeft: `1px solid ${theme.color.gray}`,
    },
    subtractButton: {
        height: 38,
        width: 40,
        border: 'none',
        borderRadius: 4,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: theme.color.white,
        '&:focus': {
            outline: 'none',
        },
        boxShadow: '0 2px 3px 0 rgba(175, 180, 182, 0.15)',
        borderRight: `1px solid ${theme.color.gray}`,
    },
    iconPlus: {
        height: 16,
        width: 16,
        objectFit: 'contain',
    },
    iconMinus: {
        height: 16,
        width: 16,
        objectFit: 'contain',
    },
    inputContainer: {
        display: 'inline-flex',
        height: 38,
        width: 70,
        backgroundColor: theme.color.white,
        border: 'none',
        color: theme.color.stone,
    },
    input: {
        width: '100%',
        fontSize: 15,
        paddingLeft: 10,
        fontWeight: 300,
        color: props => (props.invalid ? [theme.color.destructive, '!important'] : theme.color.jet),
        lineHeight: 1.33,
        border: 'none',
        paddingRight: props => (props.unit && 25),
        outline: 'none',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            '-moz-appearance': 'none',
            margin: 0,
            appearance: 'none',
        },
        MozAppearance: 'textfield',
    },
    unit: {
        display: 'inline-flex',
        alignItems: 'center',
        paddingRight: 10,
        fontSize: 15,
        fontWeight: 300,
        lineHeight: 1.33,
        zIndex: 2,
        marginLeft: -20,
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: props => (props.value ? theme.color.main : theme.color.destructive),
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        color: theme.color.destructive,
    },
}));

export default function Counter(props) {
    const { value, setValue, unit, className, label, required, errorMessage, invalid, maxCounterLength } = props;
    const classes = useStyles({ value, unit, invalid });

    function add() {
        if (!value) {
            setValue(1);
        } else {
            setValue((+value) + 1);
        }
    }

    function subtract() {
        if (value > 0) {
            setValue((+value) - 1);
        }
    }

    function handleChange(event) {
        if (!isEmpty(event.target?.value?.toString())) {
            setValue(parseInt(event.target.value, 10));
        } else {
            setValue('');
        }
    }

    return (
        <div className={classes.wrapper}>
            {label
                && (
                <div className={classes.label}>
                    {label}
                    {required && <span className={classes.dot} />}
                </div>
                )}
            <div className={clsx(classes.container, className)}>
                <button type="button" className={classes.subtractButton} onClick={subtract}>
                    <Svg iconId="icon-subtract" style={classes.iconMinus} />
                </button>
                <div className={classes.inputContainer}>
                    <input type="text" value={parseInt(value || 0, 10)} placeholder="0" onChange={handleChange} maxLength={maxCounterLength || Infinity} className={classes.input} onKeyPress={event => formUtils.preventNonNumericalInput(event)} />
                    {unit
                        && (<div className={classes.unit}>
                                {unit}
                            </div>)
                    }
                </div>
                <button type="button" className={classes.addButton} onClick={add}>
                    <Svg iconId="icon-add" style={classes.iconPlus} />
                </button>
            </div>
            { invalid && errorMessage && (
                <div className={classes.errorMessage}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
}
