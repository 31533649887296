import { sendServerRequest } from './baseService';

export const farmService = {
    getFarms,
};

/**
 * Get all farms datas
 * @return {array} Farms array
 */
async function getFarms() {
    const response = await sendServerRequest('GET', '/api/farm/farmList');
    return response;
}
