import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import useTranslate from '@i18n/useTranslate';
import { isEmpty } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    footer: {
        height: 70,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: [0, 40],
        borderRadius: [0, 0, 10, 0],
        backgroundColor: theme.color.white,
        boxShadow: theme.shadows.footer,
    },
    footerTitle: {
        fontSize: 16,
        lineHeight: 1.25,
        fontWeight: 500,
        textTransform: 'lowercase',
    },
}));

export default function RegistryNumberFooter() {
    const { t } = useTranslate();
    const table = useSelector((state: any) => state.simpleTable.table);
    const selectedItemIds = table?.registryNumber?.selectedItemIds;

    const classes = useStyles();

    if (isEmpty(selectedItemIds)) {
        return null;
    }

    return (
        <div className={classes.footer}>
            <div className={classes.footerTitle}>
                {selectedItemIds.length} {t('default.selectedUnit', 'selected unit')}
            </div>
        </div>
    );
}
