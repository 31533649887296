import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Svg from '@baseComponents/Svg';
import { CULTIVATION_PERIODS } from 'shared/src/constants';
import useTranslate from '@i18n/useTranslate';

const useStyles = createUseStyles((theme: any) => ({
    infoPopover: {
        border: 'none',
        maxWidth: 'fit-content',
        zIndex: theme.zIndex.popover,
        fontSize: 12,
        padding: 10,
        '& .arrow::before': {
            borderRightColor: theme.color.white,
            borderWidth: 8,
            top: -12,
            left: -20,
        },
    },
    infoButton: {
        display: 'inline-block',
        marginLeft: 5,
        backgroundColor: theme.color.white,
        padding: 0,
        border: 'none',
    },
    info: {
        height: 18,
        width: 18,
    },
}));

export default function QualificationInfo() {
    const { t } = useTranslate();
    const classes = useStyles();

    const [qualificationList] = useState([
        {
            key: CULTIVATION_PERIODS.QUALIFICATION.VERY_BAD,
            text: t('qualification.veryBad', 'Very bad'),
        },
        {
            key: CULTIVATION_PERIODS.QUALIFICATION.BAD,
            text: t('qualification.bad', 'Bad'),
        },
        {
            key: CULTIVATION_PERIODS.QUALIFICATION.MIDDLE,
            text: t('qualification.middle', 'Middle'),
        },
        {
            key: CULTIVATION_PERIODS.QUALIFICATION.GOOD,
            text: t('qualification.good', 'Good'),
        },
        {
            key: CULTIVATION_PERIODS.QUALIFICATION.VERY_GOOD,
            text: t('qualification.veryGood', 'Very good'),
        },
    ]);

    return (
        <OverlayTrigger
            placement="right"
            trigger={['hover', 'focus']}
            rootClose
            overlay={
                <Popover className={classes.infoPopover}>
                    {qualificationList?.map(qualificationData => (
                        <div key={qualificationData.key}>
                            {qualificationData.key} - {qualificationData.text}
                        </div>
                    ))}
                </Popover>
            }
        >
            <button type="button" className={classes.infoButton}>
                <Svg iconId="icon-info" style={classes.info} />
            </button>
        </OverlayTrigger>
    );
}
