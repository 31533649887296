import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '@states/actions';
import { isNumber, isEqual } from 'lodash';

export default function useLoadCloseDialog(props) {
    const { formInitialValues, periodYearId, isEdit, isGeometryEdited } = props;
    const dispatch = useDispatch();
    const { values } = useFormikContext();
    const hasPreventCloseDialog = useSelector((state: any) => state.modal.hasPreventCloseDialog);

    function getCultivationPeriodBaseData(cultivationPeriodValues) {
        return {
            basicFields: cultivationPeriodValues?.basicFields,
            comment: cultivationPeriodValues?.comment,
            comment2: cultivationPeriodValues?.comment2,
            comment3: cultivationPeriodValues?.comment3,
            crop: cultivationPeriodValues?.crop,
            endDate: cultivationPeriodValues?.endDate,
            farmId: cultivationPeriodValues?.farmId,
            name: cultivationPeriodValues?.name,
            owner: cultivationPeriodValues?.owner,
            ownerType: cultivationPeriodValues?.ownerType,
            region: cultivationPeriodValues?.region,
            sownArea: cultivationPeriodValues?.sownArea,
            startDate: cultivationPeriodValues?.startDate,
            qualification: cultivationPeriodValues?.qualification,
            support: cultivationPeriodValues?.support || [],
            selectedRegistryNumbers: cultivationPeriodValues?.selectedRegistryNumbers || [],
            isActive: cultivationPeriodValues?.isActive,
            bankId: cultivationPeriodValues?.bankId,
            insurerId: cultivationPeriodValues?.insurerId,
            insuranceCountyNumber: cultivationPeriodValues?.insuranceCountyNumber,
            damageFileNumber: cultivationPeriodValues?.damageFileNumber,
        };
    }

    useEffect(() => {
        if (dispatch
            && (!isEdit || values.isEditDataLoaded)
            && (!isEqual(getCultivationPeriodBaseData(formInitialValues), getCultivationPeriodBaseData(values))
            || isGeometryEdited
            || (isNumber(values.period) && values.period !== periodYearId))
        ) {
            dispatch(modalActions.setPreventCloseDialog(true));
        } else {
            dispatch(modalActions.setPreventCloseDialog(false));
        }
    }, [dispatch, formInitialValues, hasPreventCloseDialog, values, periodYearId, isEdit, isGeometryEdited]);
}
