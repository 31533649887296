import { ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';

type Classes = 'mapSliderLabelContainer' | 'mapSliderClosing' | 'container';

type StyleProps = {
    hasBorder: boolean,
    flexGrow: number,
}

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    container: {
        flex: props => props.flexGrow,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    mapSliderLabelContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: 20,
        borderRight: props => (props.hasBorder ? '1px solid white' : 'none'),
    },
    mapSliderClosing: {
        fontSize: '1.6rem',
        fontWeight: 400,
    },
}));

type TProps = {
    hasBorder: boolean,
    label: string,
    flexGrow: number,
}

export const MapSliderLabelComponent = ({ hasBorder, label, flexGrow }: TProps) => {
    const classes = useStyles({ hasBorder, flexGrow });

    return (
        <div className={classes.container}>
            <div className={classes.mapSliderLabelContainer}>
                {label}
            </div>
        </div>
    );
};
