/* eslint-disable no-restricted-imports */
import Button from '@baseComponents/buttons/Button';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import GlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/GlobalSearchInput';
import renderModal from '@modals/modalNew/modalFunctions';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { useLayerCreateContext } from '@map/services/layerCreate/layerCreateContext';
import useTranslate from '@i18n/useTranslate';
import { isEmpty } from 'lodash';
import { useMemo, useState, memo, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { LayerModalBottomBar, LayerModalContainer, LayerModalContent, LayerModalHeader } from '../LayerModal.styles';
import { LayerUploadSteps } from '../modalSteps.enum';
import { ModalStepProps } from '../types';
import LayerUploadCustomLayerAttributesModal from './LayerUploadCustomLayerAttributesModal/LayerUploadCustomLayerAttributesModal';
import LayerUploadKeyPairingModal from './LayerUploadKeyPairingModal/LayerUploadKeyPairingModal';
import LayerUploadModalPairFeatureListTable from './LayerUploadModalPairFeatureListTable/LayerUploadModalPairFeatureListTable';
import LayerUploadModalPairMap from './LayerUploadModalPairMap/LayerUploadModalPairMap';
import useGetEntityPairingModal from './useGetEntityPairingModal';
import { useLayerUploadModalPairData } from './useLayerUploadModalPairData';
import { useAlert } from '@utils/index';

const useStyles = createUseStyles((theme: any) => ({
    tableWrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        margin: [10, 0],
        padding: [0, 20],
    },
    marginRight: {
        marginRight: 16,
    },
    searchBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        // marginTop: 10,
        padding: [0, 20],
    },
    pairMapWrapper: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
}));

export type PropertyField = {
    id: number,
    name: string,
    propertyKey: Nullable<string>,
}

function getAttributesWithTypes(dataList: any[], propertyList: string[]) {
    if (!dataList || !propertyList) { return {}; }

    const attributeMap = {};

    dataList?.forEach((currentData: any) => {
        propertyList?.forEach(property => {
            if (attributeMap?.[property]) {
                return;
            }

            let dataType;

            switch (typeof currentData?.[property]) {
                case 'string':
                    dataType = 'STR';
                    break;
                case 'number':
                    dataType = 'NUM';
                    break;
                default:
                    break;
            }

            if (dataType && !['entityId', 'area', 'centroid', 'perimeter', 'mapSvg'].includes(property)) {
                attributeMap[property] = dataType;
            }
        });
    });

    return attributeMap;
}

const TABLE_KEY = 'MAP_LAYER_UPLOAD_MODAL_PAIRING_FEATURE_LIST_TABLE';

const LayerUploadModalPair = ({ setStep, close }: ModalStepProps) => {
    const [isMapView, setIsMapView] = useState(false);
    const [store, controller] = useLayerCreateContext();
    const periodYears = useSelector((state: any) => state.dataLists.productionYears);
    const { t, translater } = useTranslate();
    const [isSaving, setIsSaving] = useState(false);

    const isCustomLayerUpload = useMemo(() => store.upload.isCustomLayerUpload, [store.upload.isCustomLayerUpload]);
    const productionYear = useMemo(() => periodYears?.find(periodYear => periodYear?.id === store.upload.productionYearId)?.period, [store, periodYears]);
    const pairableEntityIds = useMemo(() => store.upload.pairableEntities?.map(pairableEntity => pairableEntity.entityId) || [], [store.upload.pairableEntities]);
    const { warningAlert } = useAlert();

    function doAlert() {
        warningAlert(translater('layerUploadModal.warningMsg', 'The selected item already has a polygon!'));
    }

    const modalTitle = useMemo(() => ((productionYear && !isCustomLayerUpload) ?
        translater<string>('map.fieldMapping', 'Field mapping for production year {{pYear}}', { pYear: productionYear })
        : translater<string>('map.fieldMappingCustomLayer', 'Field mapping for custom layer')
    ), [productionYear, isCustomLayerUpload, translater]);

    const classes = useStyles();

    const { currentModalComponent, currentModalComponentProps } = useGetEntityPairingModal({ selectedLayerId: store.upload.selectedLayerId, productionYearId: store.upload.productionYearId });

    const { tableData, onSave, excludedEntityIds, propertyDataList, idColumnName, handleFeatureKeyPairing } = useLayerUploadModalPairData({
        close,
        store,
        controller,
        tableKey: TABLE_KEY,
        setIsSaving: (state: boolean) => { setIsSaving(state); },
    });

    const tableDataProperties = useMemo(() => Object.keys(store.upload.pairableEntities?.[0]?.properties || {}), [store.upload.pairableEntities]);
    const existingLayerAttributes = useMemo(() => store.upload.existingLayerAttributes, [store.upload.existingLayerAttributes]);
    const layerAttributeWithTypes = useMemo(() => getAttributesWithTypes(tableData as [], propertyDataList), [tableData, propertyDataList]);

    function openKeyPairingModal() {
        if (propertyDataList && tableDataProperties) {
            renderModal(LayerUploadKeyPairingModal, {
                propertyDataList,
                tableDataProperties,
                onSubmit: handleFeatureKeyPairing,
            } as any, true);
        }
    }

    const handleSave = useCallback(() => {
        if (isCustomLayerUpload && !isEmpty(existingLayerAttributes)) {
            renderModal(LayerUploadCustomLayerAttributesModal, {
                existingLayerAttributes,
                currentLayerAttributes: layerAttributeWithTypes,
                onSubmit: onSave,
            } as any, true);
        } else {
            onSave(isCustomLayerUpload, layerAttributeWithTypes);
        }
    }, [existingLayerAttributes, isCustomLayerUpload, layerAttributeWithTypes, onSave]);

    return (
        <LayerModalContainer width="calc(100vw - 44px)" height="calc(100vh - 44px)">
            <LayerModalHeader
                title={modalTitle}
                subTitle={store.upload.fileName}
                close={close}
            />
            <LayerModalContent
                height="100%"
                backgroundColor="gray"
                xPadding={0}
                yPadding={0}
            >
                <div className={classes.tableWrapper}>
                    <div className={classes.header}>
                        <Button type="secondary" onClick={() => setIsMapView(p => !p)} className={classes.marginRight} iconId={!isMapView ? 'icon-map_view' : 'icon-view_list'} iconPosition="before">
                            {!isMapView ? t('map.mapView', 'Map view') : t('map.tableView', 'Table view')}
                        </Button>

                        {
                            !isCustomLayerUpload ? (
                                <Button type="secondary" onClick={() => openKeyPairingModal()} disabled={!propertyDataList || !tableDataProperties}>
                                    {t('map.autoMapping', 'Auto mapping')}
                                </Button>
                            ) : null
                        }
                    </div>
                    {!isMapView
                        ? (<div className={classes.searchBar}><GlobalSearchInput tableKey={TABLE_KEY} isSearchButton={false} isSearchIcon /></div>)
                        : null}
                    <LoadSpinner value={tableData} isAutoDetect />
                    {
                        !isMapView ? <LayerUploadModalPairFeatureListTable
                            tableKey={TABLE_KEY}
                            isCustomLayerUpload={isCustomLayerUpload}
                            propertyDataList={propertyDataList}
                            tableData={tableData as any}
                            idColumnName={idColumnName}
                            // excludedEntityIds={excludedEntityIds}
                            currentModalComponent={currentModalComponent}
                            currentModalComponentProps={currentModalComponentProps}
                            controller={controller}
                            pairableEntityIds={pairableEntityIds}
                            doAlert={doAlert}
                        /> : (
                            <div className={classes.pairMapWrapper}>
                                <LayerUploadModalPairMap
                                    isCustomLayerUpload={isCustomLayerUpload}
                                    controller={controller}
                                    pairableEntityIds={pairableEntityIds}
                                    currentModalComponent={currentModalComponent}
                                    store={store}
                                    // excludedEntityIds={excludedEntityIds}
                                    currentModalComponentProps={currentModalComponentProps}
                                    doAlert={doAlert}
                                />
                            </div>
                        )
                    }
                </div>
            </LayerModalContent>
            <LayerModalBottomBar justifyContent="center">
                <Button
                    title={t('default.back', 'Back')}
                    type="secondary"
                    iconId="icon-arrow_back"
                    iconPosition="before"
                    className={classes.marginRight}
                    onClick={() => setStep(LayerUploadSteps.FILES)}
                >
                    {t('default.back', 'Back')}
                </Button>
                <Button
                    title={t('map.continueToAttach', 'Continue to attach')}
                    onClick={() => handleSave()}
                    disabled={isSaving}
                >
                    {t('default.save', 'Save')}
                </Button>
            </LayerModalBottomBar>
        </LayerModalContainer>
    );
};

export default memo(LayerUploadModalPair);
