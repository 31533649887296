import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import { stringUtils } from 'shared/src/modules';
import { GENERAL } from 'shared/src/constants';
import { dataListsActions } from '@states/actions';
import { SimpleSelect } from '@baseComponents/select';

const useStyles = createUseStyles((theme: any) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        color: theme.color.jet,
        marginBottom: 5,
        marginTop: 15,
    },
    validationDot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: props => (props.farmId ? theme.color.main : theme.color.destructive),
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    dropdownWrapper: {
        display: 'block',
        width: '100%',
        height: 'auto',
    },
    dropdown: {
        '&.dropdown': {
            width: '100%',
            '& .dropdown-menu.show': {
                transform: 'translate(0px, 42px) !important',
                width: '100%',
                maxWidth: 320,
            },
            '& .dropdown-item': {
                height: 40,
                lineHeight: '40px',
                width: '100%',
                alignItems: 'center',
                padding: '0px 15px',
                paddinTop: 0,
                paddingBottom: 0,
                ...theme.overFlowText,
            },
        },
    },
    errorMessage: {
        display: 'block',
        fontSize: 12,
        fontWeight: 500,
        color: theme.color.destructive,
    },
}));

export default function FarmDropdown(props) {
    const { farmId, setFarmId, validation, isRequired } = props;
    const dispatch = useDispatch();
    const farms = useSelector((state: any) => state.dataLists.farms);
    const [farmList, setFarmList] = useState(null);
    const [filteredFarmList, setFilteredFarmList] = useState();
    const classes = useStyles({ farmId });
    const { t } = useTranslate();

    useEffect(() => {
        if (!isEmpty(farms) && dispatch) {
            const activeFarms = dispatch(dataListsActions.getFarmDataList(GENERAL.FILTER.STATUS.ACTIV, farmId));
            const selectFarms = activeFarms?.map(farmObj => ({
                value: farmObj.name,
                key: farmObj.id,
            }));

            const dataList = [{ key: null, value: '' }, ...selectFarms];
            setFarmList(dataList);
            setFilteredFarmList(dataList);
        }
    }, [dispatch, farms, farmId]);

    function changeFarm(selectedFarmId) {
        if (setFarmId) {
            setFarmId(selectedFarmId);
        }
    }

    function searchFarm(value) {
        if (!isEmpty(value) && !isEmpty(farmList)) {
            setFilteredFarmList(farmList.filter(dataObj => stringUtils.includesNoCase(dataObj.value, value)));
        } else {
            setFilteredFarmList(farmList);
        }
    }

    return (
        <>
            <div className={classes.label}>
                <span>{t('cultivationPeriodsColumn.farm', 'Farm')}</span>
                {isRequired && <span className={classes.validationDot} />}
            </div>
            <div className={classes.dropdownWrapper}>
                <SimpleSelect
                    selected={farmId}
                    dataList={filteredFarmList}
                    onChange={changeFarm}
                    className={classes.dropdown}
                    search={value => searchFarm(value)}
                />

                {validation && validation.farmId &&
                    <span className={classes.errorMessage}>
                        {validation.farmId}
                    </span>
                }
            </div>
        </>
    );
}
