import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

import TableCollapseButton from './TableCollapseButton';
import TableGroupName from './TableGroupName';
import TableGroupCheckbox from './TableGroupCheckbox';

const useStyle = createUseStyles({
    groupNameWrapper: {
        height: 40,
        lineHeight: '40px',
        verticalAlign: 'middle',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
});

export default function TableGroupNameWithCollapser(props) {
    const { isCollapsed, groupName, groupItemCount, children, canUseGroupOperation, isBasePageTable, tableKey, onClick, classes } = props;
    const componentClasses = useStyle();

    return (
        <div className={clsx(classes.tableHeaderRow, componentClasses.groupNameWrapper)}>
            {canUseGroupOperation && <TableGroupCheckbox groupName={groupName} isBasePageTable={isBasePageTable} tableKey={tableKey} />}

            <TableGroupName groupName={groupName} groupItemCount={groupItemCount}>{groupName || children}</TableGroupName>

            <TableCollapseButton
                onClick={onClick}
                isCollapsed={isCollapsed}
                isBasePageTable={isBasePageTable}
            />
        </div>
    );
}
