import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, toLower, isNull, isArray } from 'lodash';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { tableUtils } from '@utils';
import { basePageActions, simpleTableActions } from '@states/actions';
import useTableDataHandler from './useTableDataHandler';
import useTableGroupedDataHandlerInitValues from './useTableGroupedDataHandlerInitValues';

export default function useTableGroupedDataHandler(props) {
    const { useAutoGrouping, useAutoSorting } = props;
    const { isBasePageTable, tableKey } = props; //simple table
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [groupedTableData, setGroupedTableData] = useState(null);
    const {
        selectedGroup,
        columns,
        globalColumnSortingTypes,
        isUpdateDomTable,
    } = useTableGroupedDataHandlerInitValues({ isBasePageTable, tableKey });
    const { sortedAndFilteredTableData } = useTableDataHandler({ isBasePageTable, tableKey });

    useEffect(() => {
        function getUnknownGroupName() {
            let unknownGroupName = '';
            if (!isEmpty(selectedGroup)) {
                unknownGroupName = toLower(selectedGroup.sumText);
            }
            return `${t('basePage.unknown', 'Unknown')} ${unknownGroupName}`;
        }
        if (!isEmpty(sortedAndFilteredTableData) && useAutoGrouping) {
            if (selectedGroup && selectedGroup.value) {
                //create groups by group name
                const groupedData = arrayUtils.groupingDataArray(sortedAndFilteredTableData, selectedGroup.value, getUnknownGroupName());
                //sort groups
                if (useAutoSorting) {
                    if (globalColumnSortingTypes) {
                        sortGroups(groupedData, columns, globalColumnSortingTypes, 'groupArray', 0);
                    } else {
                        arrayUtils.sortByParamAsc(groupedData, 'groupName'); // no sorting - default ABC order
                    }
                }
                setGroupedTableData(groupedData);
            } else if (selectedGroup && isNull(selectedGroup.value)) { //breakdown: no breakdown
                setGroupedTableData([{ groupId: 0, groupName: null, groupArray: sortedAndFilteredTableData }]);
            } else { //no grouping
                setGroupedTableData(sortedAndFilteredTableData);
            }
        } else if (isEmpty(sortedAndFilteredTableData) && !isNull(sortedAndFilteredTableData)) { //don't have data
            setGroupedTableData([]);
        }
    }, [sortedAndFilteredTableData, selectedGroup, useAutoGrouping, columns, useAutoSorting, t, globalColumnSortingTypes, isUpdateDomTable]);

    useEffect(() => {
        if (dispatch && !isNull(groupedTableData)) {
            if (isBasePageTable) { //basePage
                dispatch(basePageActions.setTableFilteredData(groupedTableData));
            } else { //simple table
                dispatch(simpleTableActions.setTableFilteredDatas(tableKey, groupedTableData));
            }
        }
    }, [isBasePageTable, groupedTableData, dispatch, tableKey]);

    //display table parts
    const displayNoDataCard = useCallback(() => isArray(groupedTableData) && isEmpty(groupedTableData) && !isNull(groupedTableData), [groupedTableData]);
    const displayDataGroups = useCallback(() => !isEmpty(groupedTableData) && isArray(groupedTableData), [groupedTableData]);

    function sortGroups(sortedDataArray, tableColumns, sortingTypes, key, itemIndex) {
        if (!isEmpty(sortedDataArray) && !isEmpty(sortingTypes) && tableColumns) {
            const [[columnId, sortType]] = Object.entries(sortingTypes); // get primary sort condition
            const { searchAndSortType } = tableColumns.find(column => column.columnId === columnId);
            tableUtils.sortDataSubArrayByType(sortedDataArray, searchAndSortType, sortType, columnId, key, itemIndex);
        }
    }

    return {
        groupedTableData,
        displayNoDataCard,
        displayDataGroups,
    };
}
