import React, { useCallback, useEffect, useState } from 'react';
import { isArray, isNull, isEmpty } from 'lodash';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import useTranslate from '@i18n/useTranslate';
import { SETTINGS } from 'shared/src/constants';
import { stringUtils } from 'shared/src/modules/stringUtils';
import { companyActions } from '@states/actions';
import renderModal from '@modals/modalNew/modalFunctions';
import CultivationPeriodsModal from '@customComponents/modals/cultivationPeriodsModal/CultivationPeriodsModal';
import Label from '@baseComponents/formParts/Label';
import Svg from '@baseComponents/Svg';
import Radio from '@baseComponents/controls/Radio';
import FormSelect from '@baseComponents/select/formSelect/FormSelect';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        marginTop: 15,
    },
    openModalButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: '100%',
        border: `1px solid ${theme.color.gray}`,
        borderRadius: 4,
        outline: 'none',
        backgroundColor: theme.color.white,
        cursor: 'pointer',
        '&:hover': {
            borderColor: theme.color.main,
            backgroundColor: theme.color.opacity.main,
            '& svg': {
                fill: theme.color.shadow_green,
            },
        },
    },
    addIcon: {
        fill: theme.color.raven,
        height: 24,
    },
    selectedValueText: {
        marginLeft: 10,
        marginRight: 10,
        color: theme.color.jet,
        fontWeight: 400,
        ...theme.overFlowText,
        width: '100%',
        textAlign: 'left',
        fontSize: 15,
    },
    infoPopover: {
        border: 'none',
        maxWidth: 'fit-content',
        zIndex: theme.zIndex.popover,
        fontSize: 12,
        padding: 10,
        '& .arrow::before': {
            borderRightColor: theme.color.white,
            borderWidth: 8,
            top: -12,
            left: -20,
        },
    },
    radioBar: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
        marginBottom: 10,
    },
    selectDropdown: {
        '& .dropdown-menu': {
            transform: 'translate(0px, 42px) !important',
            width: '100%',
        },
        '& .dropdown-item': {
            height: 40,
            padding: '10px 15px',
            ...theme.overFlowText,
            maxWidth: '100%',
        },
        '& #selectDropdownList': {
            maxWidth: 318,
        },
    },
    radioMargin: {
        marginLeft: 16,
    },
    selectorBar: {
        display: 'flex',
        alignItems: 'center',
    },
    deleteButton: {
        width: 30,
        height: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 2,
        cursor: 'pointer',
        '& svg': {
            fill: theme.color.jet,
            height: 18,
            width: 18,
        },
    }
}));

export default function PrevPeriodForm(props) {
    const { cultureList, cultivationPeriodId, periodYearId } = props;
    const dispatch = useDispatch();
    const { values, setFieldValue } = useFormikContext();
    const [selectedPeriods, setSelectedPeriods] = useState(values.previousPeriods);
    const [selectedCulture, setSelectedCulture] = useState();
    const [filteredCulture, setFilteredCulture] = useState(cultureList);
    const [isManualEdit, setIsManualEdit] = useState(!isNull(values.manualPrevCultureId));
    const [isNonAccentSearch] = useState(dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)));

    const { t, translater } = useTranslate();

    const classes = useStyles();


    useEffect(() => {
        if (!isManualEdit || !cultureList) {
            return;
        }

        const cultureObj = cultureList?.find(({ value, key }) => key === values.manualPrevCultureId);
        setSelectedCulture(cultureObj)

    }, [isManualEdit, values, cultureList]);

    useEffect(() => {
        setFilteredCulture(cultureList);
    }, [cultureList, setFilteredCulture]);

    const addSelectedPeriods = useCallback(periods => {
        const periodsFormated = isArray(periods) ? periods : [periods];
        setSelectedPeriods(periodsFormated);
        setFieldValue('previousPeriods', periodsFormated.map(period => period.id));
        setFieldValue('manualPrevCultureId', null)
        setSelectedCulture();
    }, [setFieldValue, setSelectedPeriods, setSelectedCulture]);

    const onCultureChange = useCallback(culture => {
        setFieldValue('manualPrevCultureId', Number(culture))
        setSelectedCulture(culture);
        setFieldValue('previousPeriods', [])
        setSelectedPeriods();
    }, [setFieldValue, setSelectedCulture, setSelectedPeriods])

    const onDeleteClicked = useCallback(() => {
        if (isManualEdit) {
            setSelectedCulture()
            setFieldValue('manualPrevCultureId', '')
        } else {
            setSelectedPeriods()
            setFieldValue('previousPeriods', [])
        }
    }, [setFieldValue, setSelectedCulture, setSelectedPeriods, isManualEdit])

    const getModalTitle = useCallback(() => {
        if (!selectedPeriods) {
            return null;
        }

        if (selectedPeriods.length === 1) {
            return selectedPeriods[0]?.name || selectedPeriods[0]?.periodName;
        }

        return `${selectedPeriods?.length} ${translater('prevPeriods.selectedPeriods', 'Periods selected')}`;
    }, [selectedPeriods]);

    function search(value, dataList, setList) {
        if (!isEmpty(value) && !isEmpty(dataList)) {
            setList(dataList.filter(dataObj => stringUtils.includesNoCase(dataObj.value, value, isNonAccentSearch)));
        } else {
            setList(dataList);
        }
    }

    return (
        <div className={classes.container}>
            <Label label={t('prevPeriods.label', 'Previous periods data')} />

            <div className={classes.radioBar}>
                <Radio text={t('prevPeriods.byPeriod', 'By period')} checked={!isManualEdit} onChange={() => setIsManualEdit(false)} />
                <Radio className={classes.radioMargin} text={t('prevPeriods.byCulture', 'By culture')} checked={isManualEdit} onChange={() => setIsManualEdit(true)} />
            </div>

            <div className={classes.selectorBar}>
                {!isManualEdit
                    ? <OverlayTrigger
                        placement="right"
                        trigger={['hover', 'focus']}
                        rootClose
                        // eslint-disable-next-line react/jsx-indent-props
                        overlay={
                            isArray(selectedPeriods) && selectedPeriods.length > 0
                                ? <Popover className={classes.infoPopover}>
                                    <b>{t('prevPeriods.periods', 'Periods')}</b>
                                    {selectedPeriods?.map(popoverEntry => (
                                        <div key={popoverEntry?.name || popoverEntry?.periodName}>
                                            {popoverEntry?.name || popoverEntry?.periodName} {popoverEntry?.cultureName && `- ${popoverEntry?.cultureName}`}
                                        </div>
                                    ))
                                    }
                                </Popover>
                                : <div />
                        }
                    >
                        <div
                            className={classes.openModalButtonWrapper}
                            role="button"
                            tabIndex={0}
                            onClick={() => {
                                renderModal(CultivationPeriodsModal, { isMultiselect: true, onChange: addSelectedPeriods, excludedIds: [cultivationPeriodId], maxPeriodYearId: periodYearId }, false);
                            }}
                            aria-hidden="true"
                        >
                            {isEmpty(selectedPeriods)
                                ? (
                                    <Svg
                                        iconId="icon-add"
                                        style={classes.addIcon}
                                    />
                                ) : (
                                    <div className={classes.selectedValueText}>
                                        {getModalTitle()}
                                    </div>
                                )
                            }
                        </div>
                    </OverlayTrigger>
                    : <FormSelect
                        dataList={filteredCulture}
                        selected={selectedCulture && selectedCulture?.key}
                        onChange={onCultureChange}
                        search={value => search(value, cultureList, setFilteredCulture)}
                        className={classes.selectDropdown}
                        isClearItem
                    />}
                    
                    {(!isEmpty(selectedPeriods) && !isManualEdit) ?
                    <div role="button" className={classes.deleteButton} onClick={onDeleteClicked} title={translater('default.delete', 'Delete')}>
                        <Svg iconId="icon-close" />
                    </div> : null}
            </div>
        </div>
    );
};
