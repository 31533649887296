import { routerHelper } from '@utils/router/RouterHelper';
import { CULTIVATION_PERIODS } from 'shared/src/constants';
import { sendServerRequest } from './baseService';

export const cultivationPeriodsService = {
    getCultivationPeriods,
    getCultivationPeriodById,
    updateCostRatios,
    getCultivationPeriodCostsById,
    getCultivationPeriodCosts,
    getCultivationPeriodsCosts,
    getCultivationPeriodsYields,
    createNewCultivationPeriod,
    getCultivationPeriodsProfitabilities,
    getCultivationPeriodSupport,
    getCultivationPeriodRegistryNumber,
    getCultivationPeriodCost,
    updateCultivationPeriod,
    getAllRegions,
    fixOrUnlockCultivationPeriod,
    getCultivationPeriodsToFilter,
    updateCosts,
    checkCloseAndOpenCultivationPeriods,
    closeAndOpenCultivationPeriod,
    deleteCultivationPeriod,
    getCosts,
    getFieldFeatures,
    getProductCost,
    setCultivationPeriodsCultureAndVariety,
    setCultivationPeriodsEndDate,
    //scouting
    getCultivationPeriodScoutings,
    getCultivationPeriodScoutingsViewData,
    getGrowthStageList,
    getCultivationPeriodsForScoutingRecord,
    getScoutingCategoryList,
    setScouting,
    checkCultivationPeriodCostExist,
    getScoutingLpisPlantProtectionData,
    deleteScouting,
    getScoutingBlocks,
    setApproveScoutings,
    setAnswerScoutings,
    //---
    getTaskList,
    setTaskData,
    deleteTaskData,
    setCultivationPeriodToLpisTable,
    getCultivationPeriodMaterialConsumption,
    setCultivationPeriodsNearestLocation,
    getCultivationPeriodsWorkOperations,
    checkCultivationPeriodDeleteConditions,
    getCultivationPeriodFiles,
    getCultivationPeriodsByGroupIds,
};

/**
 * Update cultivation period's operations cost ratios
 * @property {<int>} operationCostRatioId IsRequired - Operation's cost ratio ID eg.: 210
 * @property {<int>} operationEconomicUnitId IsRequired - The economic unit's id of the operation eg.: 22
 * @property {<int>} operationCostRatioValue IsRequired - The percentage of the cost ratio eg.: 70
 * -
 * -
 * You don't have to update both the operation's and product ratios, you can update only one's values.
 * Eg.: await cultivationPeriodsService.updateCostRatios({operationCostRatioId: 1, operationEconomicUnitId: 22, operationCostRatioValue: 50});
 * -
 * -
 * @property {<int>} productCostRatioId IsRequired - Product's cost ratio ID eg.: 210
 * @property {<int>} productEconomicUnitId IsRequired - The economic unit's id of the product eg.: 22
 * @property {<int>} productCostRatioValue IsRequired - The percentage of the cost ratio eg.: 70
 * -
 * -
 * Update selected rows
 * @property {List<object>} dataRows IsRequired - Object list formed by above lines
 * -
 * -
 * @return {bool} The update status being returned as true or false
 */

async function updateCostRatios(props) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/updateCostRatios', props);
    return response;
}

/**
 * Get costs for the specified cultivation period ids
 * @property {array<int>} companyIds IsRequired - Company ID list eg.: [1]
 * @property {array<int>} statusIds IsRequired - Period status id list eg.: [1]
 * @property {int} partnerTypeId IsRequired - Partner/Company filter type eg.: 3
 * 1 - PARTNER
 * 2 - COMPANY
 * 3 - ALL (default)
 * @property {int} costBetweenCompaniesFilter IsOptional - Filter for cost calculation eg.: 1
 * @property {int} productionYearId IsRequired - The id of the production year eg.: 13
 * @property {array<int>} cultivationPeriodIds - The ids of the cultivation periods eg.: [170, 173]
 * @property {bool} showExtraData IsOptional - Show extra data of the periods eg.: 1 or true
 * @property {bool} showOngoingOperations IsOptional - Filter ongoing operations eg.: 0 or false
 * @property {bool} showByPeriodGroupFilter IsOptional - Filter periods by groups if possible eg.: 1 or true
 *
 * @return {array} Cultivation period's cost for the specified cult. period id
 */

async function getCultivationPeriodCostsById(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/periodCostsById', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get uploaded images for the specified cultivation period ids
 * @property {array<int>} cultivationPeriodId IsRequired - Cultivation period ID list eg.: [1]
 * @return {array} Cultivation period's images from scouting and other sources
 */

async function getCultivationPeriodFiles(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/cultivationPeriodFiles', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get all cultivation periods datas
 * @property {array<int>} companyIds IsOptional - Company ID list eg.: [1]
 * @property {array<int>} partnerIds IsOptional - Partner ID list eg.: [1]
 * @property {int} productionYearId IsRequired - Production year id eg.: 1
 * @property {int} typeId IsRequired - Partner/Company filter type eg.: 3
 * 1 - PARTNER
 * 2 - COMPANY
 * 3 - ALL (default)
 * @property {int} statusId IsRequired - Active/Inactive filter type eg.: 3
 * 1 - ACTIVE
 * 0 - INACTIVE
 * 2 - ALL (default)
 * @property {int} showClosedPeriod IsOptional - Filter by closed period flag eg.: 1
 * @property {int} mapCompanyId IsOptional - Map company id to svg datas eg.: 1
 *
 * @return {array} Cultivation periods array
 */
async function getCultivationPeriods(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriodList', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get all cultivation periods with cost datas
 * @property {array<int>} companyIds IsOptional - Company ID list eg.: [1]
 * @property {int} productionYearId IsRequired - Production year id eg.: 1
 * @property {int} partnerTypeId IsRequired - Partner/Company filter type eg.: 3
 * 1 - PARTNER
 * 2 - COMPANY
 * 3 - ALL (default)
 * @property {array<int>} cultureIds IsOptional - Culture ID list to filtering eg.: [1]
 * @property {array<int>} partnerIds IsOptional - Partner ID list to filtering eg.: [1]
 * @property {array<int>} tableIds IsOptional - Cultivation table ID list to filtering eg.: [1]
 * @property {int} showByPeriodGroupFilter IsOptional - Show data by cultivation period groups - flag eg.: 1 or true
 * @property {int} showByPeriodWithCostsFilter IsOptional - Show data by cultivation periods with costs - flag eg.: 1 or true
 * @property {int} mapCompanyId IsOptional - Map company id to svg datas eg.: 1
 * @property {date} startDate IsOptional - Filter by startDate eg.: 1
 * @property {date} endDate IsOptional - Filter by endDate eg.: 1
 * @property {int} showClosedPeriod IsOptional - Filter by closed period flag eg.: 1
 *
 * @return {array} Cultivation periods costs array
 */
async function getCultivationPeriodsCosts(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/costs', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get all cultivation periods with cost datas
 * @property {array<int>} companyIds IsOptional - Company ID list eg.: [1]
 * @property {int} productionYearId IsRequired - Production year id eg.: 1
 * @property {int} partnerTypeId IsRequired - Partner/Company filter type eg.: 3
 * 1 - PARTNER
 * 2 - COMPANY
 * 3 - ALL (default)
 * @property {array<int>} cultureIds IsOptional - Culture ID list to filtering eg.: [1]
 * @property {array<int>} partnerIds IsOptional - Partner ID list to filtering eg.: [1]
 * @property {array<int>} tableIds IsOptional - Cultivation table ID list to filtering eg.: [1]
 * @property {int} hectareCostCalculationType IsOptional - Show data by sown or harvested area eg.: 1 or 2
 * 1 - sownArea (default)
 * 2 - harvestedArea
 * @property {int} mapCompanyId IsOptional - Map company id to svg datas eg.: 1
 * @property {date} startDate IsOptional - Filter by startDate eg.: 1
 * @property {date} endDate IsOptional - Filter by endDate eg.: 1
 *
 * @return {array} Cultivation periods costs array
 */
async function getCultivationPeriodsYields(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/yields', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCosts(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/getCosts', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCultivationPeriodById(periodId, cultivationPeriodId) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/periodById', { periodId, cultivationPeriodId });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCultivationPeriodCosts(partnerIds, cultureIds, periodYearId, cultivationPeriodId) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/periodCosts', { partnerIds, cultureIds, periodYearId, cultivationPeriodId });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function createNewCultivationPeriod(cultivationPeriod) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/newCultivationPeriod', { cultivationPeriod });
    return response;
}

async function getCultivationPeriodsProfitabilities(companyIds, periodId) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/profitabilities', { companyIds, periodId });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCultivationPeriodSupport(periodId) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/support', { periodId });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCultivationPeriodRegistryNumber(periodId) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/registryNumber', { periodId });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCultivationPeriodCost(partnerIds, periodYearId, startDate, endDate, cultureIds, periodId) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/cost', { partnerIds, periodYearId, startDate, endDate, cultureIds, periodId });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function updateCultivationPeriod(cultivationPeriod, periodId, supportList, registryNumberList, fieldFeatures, geomData, previousPeriods, manualPrevCultureId, isLpisCultureChangeNeeded) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/updateCultivationPeriod', { cultivationPeriod, periodId, supportList, registryNumberList, fieldFeatures, geomData, previousPeriods, manualPrevCultureId, isLpisCultureChangeNeeded });
    return response;
}

async function getAllRegions() {
    const response = await sendServerRequest('GET', '/api/cultivationPeriods/regions');
    return response;
}

async function fixOrUnlockCultivationPeriod({ periodIds, isFixing }) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/fixOrUnlockCultivationPeriod', { periodIds, isFixing });
    const response = await sendServerRequest('POST', url);
    return response;
}

async function getCultivationPeriodsToFilter(props) {
    const { companyIds, productionYear, partfieldType = CULTIVATION_PERIODS.PARTFIELD_TYPE.PERIOD } = props || {};
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/cultivationPeriodsToFilter', { companyIds, productionYear, partfieldType });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function updateCosts({ year, periodYear, startDate, endDate }) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/updateCosts', { year, periodYear, startDate, endDate });
    const response = await sendServerRequest('POST', url);
    return response;
}

async function checkCloseAndOpenCultivationPeriods(cultivationPeriodIds) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/checkCloseAndOpenCultivationPeriods', { cultivationPeriodIds });
    return response;
}

async function closeAndOpenCultivationPeriod({ cultivationPeriods }) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/closeAndOpenCultivationPeriod', { cultivationPeriods });
    return response;
}

async function deleteCultivationPeriod({ cultivationPeriodIds }) {
    const response = await sendServerRequest('DELETE', '/api/cultivationPeriods/deleteCultivationPeriod', { cultivationPeriodIds });
    return response;
}

async function getFieldFeatures() {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/fieldFeatures');
    const response = await sendServerRequest('GET', '/api/cultivationPeriods/fieldFeatures');
    return response;
}

async function checkCultivationPeriodCostExist(periodId) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/checkCultivationPeriodCostExist', { periodId });
    return response;
}

async function checkCultivationPeriodDeleteConditions(periodIds) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/checkCultivationPeriodDeleteConditions', { cultivationPeriodIds: periodIds });
    return response;
}

/**
 * Get product costs
 * @property {array<int>} cultivationPeriodIds IsRequired - Cultivation period ID list eg.: [1]
 * @property {array<int>} cultureIds IsOptional - Culture ID list eg.: [1]
 * @property {array<int>} partnerIds IsOptional - Partner/Company ID list eg.: [1]
 * @property {array<int>} tableIds IsOptional - Cultivation table ID list eg.: [1]
 * @property {date} startDate IsOptional - Workoperation start date eg.: 'YYYY-MM-DD'
 * @property {date} endDate IsOptional - Workoperation end date date eg.: 'YYYY-MM-DD'
 * @property {int} productionYearId IsOptional - Production year id eg.: 1
 * @property {int} partnerTypeId IsOptional - Partner/Company filter type eg.: 3
 * 1 - PARTNER
 * 2 - COMPANY
 * 3 - ALL (default)
 * @property {int} showFlag IsOptional - Filter by closed period flag eg.: 1
 * @property {boolean} showByPeriodGroupFilter IsOptional - Group filter type eg.: true
 *
 * @return {array} Product costs grouped by cultivation period id keys
 */
async function getProductCost(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/productCost', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Set periods end date
 * @property {array<int>} cultivationPeriodIds IsRequired - Cultivation period ID list eg.: [1]
 * @property {date} endDate isRequired - Workoperation end date date eg.: 'YYYY-MM-DD'
 */

async function setCultivationPeriodsEndDate(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/setCultivationPeriodsEndDate', props);
    const response = await sendServerRequest('POST', url, props);
    return response;
}

/**
 * Set periods cultures and varietys
 * @property {array<int>} cultivationPeriodIds IsRequired - Cultivation period ID list eg.: [1]
 * @property {<int>} cultureId isRequired - The id of the specified culture eg.: 9
 * @property {<int>} varietyId isOptional - The id of the specified culture variety eg.: 33
 * @property {<bool>} updateLpis isOptional - Update the Lpis table's culture and variety aswell eg.: false
 */

async function setCultivationPeriodsCultureAndVariety(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/setCultivationPeriodsCultureAndVariety', props);
    const response = await sendServerRequest('POST', url, props);
    return response;
}

/**
 * Get cultivation periods scoutings
 * @property {date} startDate isRequired - Scouting start date eg.: 'YYYY-MM-DD'
 * @property {date} endDate isRequired - Scouting end date date eg.: 'YYYY-MM-DD'
 * @property {array<int>} partnerIds isOptional - Partner ID list eg.: [1]
 * @property {array<int>} cultivationPeriodIds isOptional - Cultivation period ID list eg.: [1]
 * @property {array<int>} farmIds isOptional - Farm ID list eg.: [1]
 * @property {array<int>} categoryIds isOptional - Category ID list eg.: [1]
 * @property {array<int>} priorityIds isOptional - Priority ID list eg.: [1]
 * @property {array<int>} cultureIds isOptional - Culture ID list eg.: [1]
 * @property {array<int>} laborIds isOptional - Labor ID list eg.: [1]
 * @property {array<int>} showDeletedScoutings IsOptional - Show deleted scoutings - flag eg.: 1 or true
 */
async function getCultivationPeriodScoutings(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/scoutings', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get scouting datas
 * @property {int} scoutingId isRequired - Scouting ID eg.: 11
*/
async function getCultivationPeriodScoutingsViewData(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/scoutingsView', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getGrowthStageList(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/growthStages', props);
    const response = await sendServerRequest('GET', url);

    return response;
}

/**
 * Get all cultivation periods datas
 * @property {int} productionYearId IsRequired - Production year id eg.: 1
 * @property {int} typeId IsRequired - Partner/Company filter type eg.: 3
 * 1 - PARTNER
 * 2 - COMPANY
 * 3 - ALL (default)
 * @property {int} showClosedPeriod IsOptional - Filter by closed period flag eg.: 1
 * @property {int} mapCompanyId IsOptional - Map company id to svg datas eg.: 1
 * @property {int} cultureId IsOptional - Culture ID eg.: 1
 *
 * @return {array} Cultivation periods array
 */
async function getCultivationPeriodsForScoutingRecord(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/scoutingRecord', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get all scouting category data
  * @property {int} categoryId IsRequired - Filter by category eg.: 3
 * 1 - GENERAL
 * 2 - PEST
 * 3 - WEED
 * 3 - INFECTION
 *
 * @return {array} category list,
 */
async function getScoutingCategoryList(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/scoutingCategories', props);
    const response = await sendServerRequest('GET', url);

    return response;
}

async function setScouting(props) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/scoutings', props);

    return response;
}

async function getScoutingLpisPlantProtectionData(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/scoutingLpisPlantProtections', props);
    const response = await sendServerRequest('GET', url);

    return response;
}

async function deleteScouting(ids) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/scoutings', { ids });
    const response = await sendServerRequest('DELETE', url);

    return response;
}

async function getScoutingBlocks() {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/scoutingBlocks', {});
    const response = await sendServerRequest('GET', url);

    return response;
}

type ApproveScoutingPropsType = {
   id: number,
   isApproved: boolean, 
}[];

async function setApproveScoutings(props: ApproveScoutingPropsType) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/approveScoutings', props);

    return response;
}

type AnswerScoutingPropsType = {
   id: number,
   answer: string | number, 
}[];

async function setAnswerScoutings(props: AnswerScoutingPropsType) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/answerScoutings', props);

    return response;
}

//--------------------------

async function getTaskList(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/tasks', props);
    const response = await sendServerRequest('GET', url);

    return response;
}

async function setTaskData(props) {
    const response = await sendServerRequest('POST', '/api/cultivationPeriods/tasks', props);

    return response;
}

async function deleteTaskData(ids) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/tasks', { ids });
    const response = await sendServerRequest('DELETE', url);
    return response;
}

/**
 * Set periods end date
 * @property {array<int>} cultivationPeriodIds IsRequired - Cultivation period ID list eg.: [1]
 */
async function setCultivationPeriodToLpisTable(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/setCultivationPeriodToLpisTable', props);
    const response = await sendServerRequest('POST', url, props);
    return response;
}

/**
 * Get cultivation periods material consumtions data
 *
 * @property {array<int>} cultureIds IsOptional - Culture ID list to filtering eg.: [1]
 * @property {int} typeId IsRequired - Partner/Company filter type eg.: 3
 * 1 - PARTNER
 * 2 - COMPANY
 * 3 - ALL (default)
 * @property {array<int>} partnerIds IsOptional - Partner ID list eg.: [1]
 * @property {date} startDate IsOptional - Filter by startDate eg.: 1
 * @property {date} endDate IsOptional - Filter by endDate eg.: 1
 * @property {int} isShowByPeriodGroupFilterValidated IsOptional - Show data by cultivation period groups eg.: 1
 * @property {int} isShowPlannedWorkOperationsFilterValidated IsOptional - Filter data by planned work operations eg.: 1
 * @property {int} isShowOngoingWorkOperationsFilterValidated IsOptional - Filter data by ongoin work operations eg.: 1
 * @return {array} material consumption list,
 */
async function getCultivationPeriodMaterialConsumption(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/materialConsumption', props);
    const response = await sendServerRequest('GET', url);

    return response;
}
async function setCultivationPeriodsNearestLocation(props) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/nearestLocation', props);
    const response = await sendServerRequest('POST', url, props);
    return response;
}

/**
 * Get cultivation periods material consumtions data
 *
 * @property {array<int>} cultureIds IsOptional - Culture ID list to filtering eg.: [1]
 * @property {int} typeId IsRequired - Partner/Company filter type eg.: 3
 * 1 - PARTNER
 * 2 - COMPANY
 * 3 - ALL (default)
 * @property {array<int>} partnerIds IsOptional - Partner ID list eg.: [1]
 * @property {date} startDate IsOptional - Filter by startDate eg.: 1
 * @property {date} endDate IsOptional - Filter by endDate eg.: 1
 * @property {int} isShowByPeriodGroupFilterValidated IsOptional - Show data by cultivation period groups eg.: 1
 * @property {int} isShowPlannedWorkOperationsFilterValidated IsOptional - Filter data by planned work operations eg.: 1
 * @property {int} isShowOngoingWorkOperationsFilterValidated IsOptional - Filter data by ongoin work operations eg.: 1
 * @property {int} costBetweenCompanies IsOptional - Cost between companies type eg.: 1
 * 1 - CONTRACTED_OPERATION
 * 2 - WORK_OPERATION
 * @return {array} material consumption list,
 */
async function getCultivationPeriodsWorkOperations(props: any): Promise<any[]> {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/workOperations', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get cultivation periods by cultivation period group ids
 *
 * @property {array<int>} cultivationPeriodGroupIds IsRequired - Termesztési időszakok csoportjainak azonosítói
 * @return {array}
 */
async function getCultivationPeriodsByGroupIds(props: any): Promise<any[]> {
    const url = routerHelper.getUrlWithParameters('/api/cultivationPeriods/getCultivationPeriodsByGroupIds', props);
    const response = await sendServerRequest('GET', url);
    return response;
}
