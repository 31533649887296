import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { numberUtils } from 'shared/src/modules';
import { CustomMenuForward } from './CustomMenuForward';
import useMultiselectData from './useMultiselectData';

import Separator from './Separator';
import Checkbox from '../controls/Checkbox';

const useStyles = createUseStyles((theme: any) => ({
    dropdownMenu: {
        top: '100%',
        width: (props: any) => (!props.useAbsoluteMenu ? '100%' : 'auto'),
        left: 0,
        float: 'left',
        margin: 0,
        fontSize: '1rem',
        color: theme.color.jet,
        textAlign: 'left',
        listStyle: 'none',
        backgroundColor: theme.color.white,
        background: theme.color.white,
        borderRadius: 4,
        border: '1px solid rgba(227, 230, 232, 0.5)',
        boxShadow: theme.shadows.selectDropdown,
        padding: '5px 0px 5px 0px',
        zIndex: theme.zIndex.multiselect,

        maxHeight: 500,
        overflow: 'hidden',
        display: 'flex !important',
        flexDirection: 'column',
        paddingBottom: 0,

        '&.show': {
            display: 'block',
            opacity: '1 !important',
            transform: (props: any) => props.useAbsoluteMenu && 'translate(-9999px)',
        },

        '& .dropdown-item': {
            display: 'block',
            width: '100%',
            padding: '0.25rem 1.5rem',
            clear: 'both',
            fontWeight: 400,
            color: theme.color.jet,
            textAlign: 'inherit',
            whiteSpace: 'nowrap',
            backgroundColor: 'transparent',
            border: 0,
        },
    },
    multiselectItem: {
        margin: '5px 5px 5px 0px',
    },
    multiselectSubItem: {
        marginLeft: 15,
    },
    multiselectSubItemChild: {
        marginLeft: 30,
    },
    noData: {
        padding: 10,
        fontSize: 14,
    },
}));

export default function Menu(props) {
    const { show, hasSearchInput = false, isOpened, dataList, multiselect, hasMultiSelectHeader, useAbsoluteMenu, onChangeSelected, isNonAccentSearch } = props;
    const { t } = useTranslation();
    const classes = useStyles({ useAbsoluteMenu } as any);
    const { multiselectCheckedStates, multiselectDisabledStates, changeMultiselectItemState, selectAll, clearSelection } = useMultiselectData({ dataList, multiselect, onChangeSelected });

    function getMenuList() {
        return dataList?.map((menuItem, index) => {
            function onChangeCheckedState(state) {
                menuItem.isChecked = state;
                changeMultiselectItemState(`key_${menuItem.key}`, state);
            }

            if (menuItem.header) {
                return <Dropdown.Header key={index}>{menuItem.header}</Dropdown.Header>;
            }

            if (menuItem.isSeparator) {
                return <Separator key={index} role="separator" />;
            }

            if (multiselect) {
                return (<Checkbox
                    key={index}
                    text={menuItem.value}
                    size="small"
                    checked={multiselectCheckedStates[`key_${menuItem.key}`]}
                    disabled={multiselectDisabledStates[`key_${menuItem.key}`]}
                    onChange={onChangeCheckedState}
                    className={clsx(
                        classes.multiselectItem, //simple checkbox
                        ((menuItem.parentKey || menuItem.parentKey === 0) && classes.multiselectSubItem), //checkbox sub item
                        (dataList.find(parentItem => parentItem.key === menuItem.parentKey)?.parentKey && classes.multiselectSubItemChild) //checkbox sub item child
                    )}
                />);
            }

            if ((numberUtils.isNumber(menuItem.key) || isEmpty(menuItem.key)) && !isEmpty(menuItem.value)) {
                return (
                    <Dropdown.Item
                        key={index}
                        eventKey={menuItem.selectedValue || menuItem.key}
                        value={menuItem.value}
                        second_value={menuItem.secondValue}
                        third_value={menuItem.thirdValue}
                        disabled={menuItem.isDisabled}
                        className={clsx(menuItem.isDisabled && 'disabled', menuItem.isHidden && 'hidden')}
                    >
                        {menuItem.value && <span>{menuItem.value}</span>}
                        {menuItem.secondValue && <span>{menuItem.secondValue}</span>}
                        {menuItem.thirdValue && <span>{menuItem.thirdValue}</span>}
                    </Dropdown.Item>
                );
            }
            return null;
        });
    }

    return (
        <>
            {useAbsoluteMenu
                ? (
                    ReactDOM.createPortal(
                        <Dropdown.Menu
                            show={show}
                            className={classes.dropdownMenu}
                            as={CustomMenuForward}
                            hasSearchInput={hasSearchInput}
                            isOpened={isOpened}
                            hasMultiSelectHeader={multiselect && hasMultiSelectHeader}
                            selectAll={selectAll}
                            clearSelection={clearSelection}
                            isNonAccentSearch={isNonAccentSearch}
                        >
                            {!isEmpty(dataList)
                                ? getMenuList()
                                : <div className={classes.noData}>{t('default.noData', 'No data')}</div>
                            }
                        </Dropdown.Menu>,
                        document.getElementById('dynamicDropdownMenuWrapper')
                    )
                )
                : (
                    <Dropdown.Menu
                        show={show}
                        className={clsx(classes.dropdownMenu, 'dropdownMenu')}
                        as={CustomMenuForward}
                        hasSearchInput={hasSearchInput}
                        isOpened={isOpened}
                        hasMultiSelectHeader={multiselect && hasMultiSelectHeader}
                        selectAll={selectAll}
                        clearSelection={clearSelection}
                        isNonAccentSearch={isNonAccentSearch}
                    >
                        {!isEmpty(dataList)
                            ? getMenuList()
                            : <div className={classes.noData}>{t('default.noData', 'No data')}</div>
                        }
                    </Dropdown.Menu>
                )
            }
        </>
    );
}
