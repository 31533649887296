import React from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import { numberUtils } from 'shared/src/modules';
import Area from '@baseComponents/formatters/Area';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        height: '100%',
        fontSize: 15,
        fontWeight: 'normal',
    },
    itemContainer: {
        height: 40,
        padding: [0, 20],
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderBottomColor: theme.color.gray,
        '&:last-child ': {
            borderBottom: 'none',
        },
    },
    nameWrapper: {
        width: '100%',
        textAlign: 'left',
        display: 'grid',
    },
    name: {
        fontWeight: 500,
        ...theme.overFlowText,
    },
    areaWrapper: {
        marginLeft: 'auto',
    },
    area: {
        whiteSpace: 'nowrap',
        margin: 'auto',
    },
}));
export default function BasicFieldOverall(props) {
    const { basicFields } = props;
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {!isEmpty(basicFields) && basicFields.map(basicFieldObj => (
                <div key={basicFieldObj.id} className={classes.itemContainer}>
                    <div className={classes.nameWrapper}>
                        <span className={classes.name} title={basicFieldObj.name}>
                            {basicFieldObj.name}
                        </span>
                    </div>
                    {numberUtils.isNumber(basicFieldObj.categorizedArea)
                        ? (
                            <div className={classes.areaWrapper}>
                                <Area value={basicFieldObj.categorizedArea} style={classes.area} />
                            </div>
                        ) : null
                    }
                </div>
            ))}
        </div>
    );
}
