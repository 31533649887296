import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const soilAnalysisService = {
    getSoilAnalysisExcelInterface,
    getCultivationPeriodsSoilAnalysis,
    getSoilAnalysis,
    setSoilAnalysis,
    getSoilProductivityParameters,
    getSoilAnalysisByIds,
    getSoilAnalysisLaboratories,
    getSoilAnalysisAttachedCutlivationPeriods,
    deleteSoilAnalysis,
    deleteSoilAnalysisItemByPeriodId,
    inheritSoilAnalysis,
};

/**
 * Get soil analysis excel interface
 * @property {int} companyId isRequired - soil analysis interface company identifier eg.: 2
 *
 * @return {array} soil analysis excet interface array
 */
async function getSoilAnalysisExcelInterface(props) {
    const url = routerHelper.getUrlWithParameters('/api/soilAnalysis/excelInterface', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get soil analysis data
 * @property {int} productionYearId IsRequired - Production year id eg.: 1
 * @property {array<int>} cultureIds IsOptional - Culture ID list to filtering eg.: [1]
 * 1 - PARTNER
 * 2 - COMPANY
 * 3 - ALL (default)
 * @property {int} partnerTypeId IsRequired - Partner/Company filter type eg.: 3
 *
 * @return {array} Soil analysis array
 */
async function getCultivationPeriodsSoilAnalysis(props) {
    const url = routerHelper.getUrlWithParameters('/api/soilAnalysis/cultivationPeriods', props);
    const response = await sendServerRequest('GET', url);
    return response;
}
/**
 * Get soil analysis data
 * @property {date} startDate IsOptional - Filter by startDate eg.: 2022-09-11
 * @property {date} endDate IsOptional - Filter by endDate eg.: 2022-09-12
 *
 * @return {array} Soil analysis array
 */
async function getSoilAnalysis(props) {
    const url = routerHelper.getUrlWithParameters('/api/soilAnalysis', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function setSoilAnalysis(soilAnalysisData) {
    const response = await sendServerRequest('POST', '/api/soilAnalysis', soilAnalysisData);
    return response;
}

async function inheritSoilAnalysis(soilAnalysisData) {
    const response = await sendServerRequest('POST', '/api/soilAnalysis/inherit', soilAnalysisData);
    return response;
}

async function getSoilProductivityParameters() {
    const response = await sendServerRequest('GET', '/api/soilAnalysis/soilProductivityParameters');
    return response;
}
/**
 * Get soil analysis data by id
 * @property {int} soilAnalyisId IsOptional - soil analysis identifier eg.: 1
 * @property {int} cultivationPeriodId IsOptional - cultivation period identifier eg.: 2
 *
 * @return {array} Soil analysis array
 */
async function getSoilAnalysisByIds(props) {
    const url = routerHelper.getUrlWithParameters('/api/soilAnalysisData', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function deleteSoilAnalysis(id) {
    const response = await sendServerRequest('DELETE', `/api/soilAnalysis/${id}`);
    return response;
}

async function getSoilAnalysisLaboratories() {
    const url = routerHelper.getUrlWithParameters('/api/soilAnalysis/laboratories');
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get soil analysis attached cultivation periods
 * @property {int} cultivationPeriodId IsRequired - cultivation period identifier eg.: 2
 *
 * @return {array}
 */
async function getSoilAnalysisAttachedCutlivationPeriods(props) {
    const url = routerHelper.getUrlWithParameters('/api/soilAnalysis/attachedCultivationPeriods', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Delete soil analysis attached cultivation periods
 * @property {int} cultivationPeriodId IsRequired - cultivation period identifier eg.: 2
 * @property {int} soilAnalysisItemId IsRequired - soil analysis item identifier eg.: 2
 *
 * @return {array}
 */
async function deleteSoilAnalysisItemByPeriodId(props) {
    const response = await sendServerRequest('POST', '/api/soilAnalysis/deleteItemByPeriodId', props);
    return response;
}
