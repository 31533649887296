import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { INTERFACE_SETTINGS } from 'shared/src/constants';
import { isEmpty, isNil } from 'lodash';
import { userActions } from '@states/actions/userActions';
import { routerHelper } from '@utils/router/RouterHelper';
import { errorActions } from '@states/actions/errorActions';

import Select from '../../inputs/select/Select';

const useStyles = createUseStyles({
    marginLeft: {
        marginLeft: 15,
    },
});

export default function PeriodYearSelect(props) {
    const { periodYear = '', setPeriodYear, size, fixedSelectbox, saveFileName, className, hasMargin = true, maxPeriodYearId } = props;
    const location = useLocation().pathname;
    const [periods, setPeriods] = useState([]);
    const dispatch = useDispatch();
    const interfaceSettingList = useSelector((state: any) => state.user.user.settings.interfaceSettings);
    const periodYears = useSelector((state: any) => state.dataLists.productionYears);
    const classes = useStyles();

    useEffect(() => {
        let isCancelled = false;
        async function loadPeriods(yearId, periodList) {
            try {
                if (!isEmpty(periodList) && !isCancelled) {
                    const currentPeriodYear = interfaceSettingList[saveFileName] ? interfaceSettingList[saveFileName][INTERFACE_SETTINGS.PERIOD_YEAR] : 0;
                    const periodListToSelect = arrayUtils.getTextValueFromList(periodList, 'period', 'id');
                    setPeriods(periodListToSelect);
                    const savedPeriod = isEmpty(periodYear) && isEmpty(yearId) && currentPeriodYear && periodList.find(period => period.id === Number(currentPeriodYear.value));
                    const periodIndex = getPeriodIndex(periodList, savedPeriod, yearId);
                    setPeriodYear(periodIndex);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'BasePagePeriodYearSelect'));
            }
        }

        function getPeriodIndex(periodList, savedPeriod, yearId) {
            if (!isEmpty(periodYear)) {
                return periodYear;
            }
            if (!isEmpty(yearId)) {
                return yearId;
            }
            return savedPeriod ? savedPeriod.id : periodList[0].id;
        }

        if (dispatch && interfaceSettingList && setPeriodYear && periods && isEmpty(periods) && location && periodYears) {
            const yearId = routerHelper.getUrlParamValueBySearch(location.search, 'yearId') || null;
            const filteredPeriodYears = periodYears.filter(periodYear => periodYear.id <= maxPeriodYearId || isNil(maxPeriodYearId));

            loadPeriods(yearId, filteredPeriodYears);

            return () => {
                isCancelled = true;
            };
        }
    }, [dispatch, setPeriodYear, periods, location, periodYear, interfaceSettingList, saveFileName, periodYears, maxPeriodYearId]);

    async function changeSavedYearId(yearId) {
        if (saveFileName && yearId) {
            dispatch(userActions.setInterfaceSetting(INTERFACE_SETTINGS.PERIOD_YEAR, yearId, saveFileName));
        }
    }

    return (
        <div className={hasMargin ? classes.marginLeft : null}>
            <Select listData={periods} selected={periodYear} setSelected={setPeriodYear} onChange={changeSavedYearId} width="140" height={29} fixedSelectbox={fixedSelectbox} size={size} className={className} />
        </div>
    );
}
