import { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { theme } from '@styles/theme';

export default function useFormScroll(props) {
    const { formRef, scrollFadeRef } = props;

    useEffect(() => {
        function formScrollHandler() {
            if (formRef.current.scrollTop > 0) {
                formRef.current.style.borderTop = `1px solid ${theme.color.pearl}`;
            } else {
                formRef.current.style.borderTop = 'none';
            }

            if (formRef.current.scrollHeight - formRef.current.scrollTop === formRef.current.clientHeight) {
                scrollFadeRef.current.style.display = 'none';
            } else {
                scrollFadeRef.current.style.display = 'block';
            }
        }

        if (formRef && !isEmpty(formRef.current)) {
            formRef.current.addEventListener('scroll', formScrollHandler);
        }
    }, [formRef, scrollFadeRef]);
}
