import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { UnitInput } from '@baseComponents/inputs/inputField';
import { isFunction } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    quantityInputWrapper: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    quantityInput: {
        '& .input-group': {
            borderColor: theme.color.required,
        },
    },
}));

export default function SimpleInputCell(props) {
    const { rowData, cellId, value, className, onChange, onBlur, isRequired, maxLength, columnId, isDisabled, getIsDisabled } = props;
    const [formattedCellId] = useState(cellId ? `${cellId}_${rowData.id}` : null);
    const [inputValue, setInputValue] = useState(value);
    const classes = useStyles();

    const inputClass = useMemo(() => (isRequired && (!inputValue) ? classes.quantityInput : null), [classes.quantityInput, inputValue, isRequired]);

    function onChangeValue(currentValue) {
        setInputValue(currentValue || 0);
        if (isFunction(onChange)) {
            onChange(currentValue, rowData, columnId);
        }
    }

    function onBlurValue(_event, currentValue) {
        if (isFunction(onBlur)) {
            onBlur(currentValue, rowData, columnId);
        }
    }

    function checkIsDisabled() {
        if (isDisabled) {
            return isDisabled;
        }

        if (isFunction(getIsDisabled)) {
            return getIsDisabled(rowData);
        }

        return false;
    }

    return (
        <div className={clsx(classes.quantityInputWrapper, className)}>
            <UnitInput
                id={formattedCellId}
                type="text"
                defaultValue={inputValue}
                onChange={onChangeValue}
                onBlur={onBlurValue}
                className={inputClass}
                maxLength={maxLength}
                isDisabled={checkIsDisabled()}
            />
        </div>
    );
}
