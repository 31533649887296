/* eslint-disable no-restricted-syntax */
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CROP, SETTINGS, MAP, PARTNER } from 'shared/src/constants';
import { dateTimeUtils } from 'shared/src/modules';
import { isFunction, isEmpty, isNumber, head } from 'lodash';
import { companyActions } from '@states/actions/companyActions';
import { errorActions } from '@states/actions/errorActions';
import { alertActions } from '@states/actions/alertActions';
import { basePageActions } from '@states/actions';
import { cultivationPeriodsService, visiblefarmService } from '@services';
import { ConfirmDialog } from '@baseComponents/modals';
import renderModal from '@modals/modalNew/modalFunctions';
import { newCultivationPeriodInitialValues } from './newCultivationPeriodInitialValues';

export default function useNewCultivationPeriodSave(props) {
    const { cultivationPeriodValues, closeModal, setIsSaveBtnDisabled, setPeriodYearFilter, isEdit, isReloadDisabled, geometry, geomId, onAddNewPeriod } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const agentCompanyId = useSelector((state: any) => state.company.company.globalData.id);
    const [usingMap] = useState(dispatch(companyActions.getSetting(SETTINGS.USING_MAP)));

    function getSupportList(supports) {
        const supportList = [];
        if (!isEmpty(supports)) {
            supports.forEach(supportObj => {
                const support = {
                    supportId: supportObj.support.id,
                    area: supportObj.area || 0,
                };
                supportList.push(support);
            });
        }
        return supportList;
    }

    function getRegistryNumberList(registryNumbers) {
        const saveRegistryNumberList = [];
        if (!isEmpty(registryNumbers)) {
            registryNumbers.forEach(regNumObj => {
                const regNumRate = (+regNumObj.sizeOfTheArea !== 0) ? ((+regNumObj.area / +regNumObj.sizeOfTheArea) * 100) : 0;
                const registryNumber = {
                    registryNumberId: regNumObj.id,
                    rate: regNumRate || 0,
                };
                saveRegistryNumberList.push(registryNumber);
            });
        }
        return saveRegistryNumberList;
    }

    function getBasicFieldList(basicFields) {
        let saveBasicFields = [];
        if (!isEmpty(basicFields)) {
            saveBasicFields = basicFields.map(basicFieldObj => {
                const tablePeriodConnectionId = basicFieldObj.cultivationTablePeriods && head(basicFieldObj.cultivationTablePeriods).id;
                const reAttachedBasicField = !tablePeriodConnectionId && cultivationPeriodValues && cultivationPeriodValues.basicFields && cultivationPeriodValues.basicFields.find(basicField => basicField.id === basicFieldObj.id);
                const basicFieldPeriodConnectionId = reAttachedBasicField && reAttachedBasicField.cultivationTablePeriods ? head(reAttachedBasicField.cultivationTablePeriods).id : tablePeriodConnectionId;

                if (basicFieldPeriodConnectionId) {
                    return {
                        id: basicFieldPeriodConnectionId,
                        tableId: basicFieldObj.id,
                        area: basicFieldObj.categorizedArea,
                    };
                }
                return {
                    tableId: basicFieldObj.id,
                    area: basicFieldObj.categorizedArea,
                };
            });
        }
        return saveBasicFields;
    }

    async function setVisibleFarmPeriodData(savedPeriodId, isEditing) {
        if (isEditing) {
            await visiblefarmService.updatePeriod({
                agentId: agentCompanyId,
                av_id: savedPeriodId,
                periodType: 0,
            });
        } else {
            await visiblefarmService.createPeriod({
                agentId: agentCompanyId,
                av_id: savedPeriodId,
                periodType: 0,
            });
        }
    }

    function getDeletedBasicFields(basicFields) {
        let deletedBasicFields = [];
        if (!isEmpty(basicFields) && !isEmpty(cultivationPeriodValues.basicFields)) {
            const basicFieldsIds = basicFields.map(tableObj => tableObj.tableId);
            deletedBasicFields = cultivationPeriodValues.basicFields.filter(tableObj => !basicFieldsIds.includes(tableObj.id));
        }
        return deletedBasicFields.map(tableObj => tableObj.id);
    }

    function getChangedValues(periodValues) {
        const cultivationPeriodData = getCultivationPeriod(cultivationPeriodValues);
        const difference = Object.keys(periodValues).filter(key => periodValues[key] !== cultivationPeriodData[key] || key === 'ownerType');
        const editedObj = {};
        difference.forEach(key => {
            editedObj[key] = periodValues[key];
        });
        return editedObj;
    }

    function getCultivationPeriod(periodObj) {
        const cultivationPeriodSaveData = {
            id: periodObj.id,
            partnerId: periodObj.owner,
            name: periodObj.name,
            yearId: periodObj.period,
            startDate: dateTimeUtils.getDefaultDateFormat(periodObj.startDate),
            endDate: periodObj.endDate === '' ? null : dateTimeUtils.getDefaultDateFormat(periodObj.endDate),
            sownArea: periodObj.sownArea,
            cultureId: periodObj.crop.culture || 0,
            varietyId: periodObj.crop.variety === '' ? 0 : periodObj.crop.variety,
            cropTypeId: periodObj.crop.type === CROP.TYPE.MAIN_CROP || periodObj.crop.type === CROP.TYPE.PLANTATION ? CROP.TYPE.MAIN_CROP : CROP.TYPE.SECOND_CROP,
            reproductionDegreeId: periodObj.crop.reproductionRate === '' ? 0 : periodObj.crop.reproductionRate,
            isPlantation: !!(periodObj.crop.plantationType),
            plantingYear: periodObj.crop.year === '' ? null : periodObj.crop.year,
            lineCount: periodObj.crop.rowNumber === '' ? null : periodObj.crop.rowNumber,
            lineLength: periodObj.crop.lineLength === '' ? null : periodObj.crop.lineLength,
            lineSpacing: periodObj.crop.lineSpacing === '' ? null : periodObj.crop.lineSpacing,
            plantDistance: periodObj.crop.plantDistance === '' ? null : periodObj.crop.plantDistance,
            plantationTypeId: periodObj.crop.plantationType === '' ? 0 : periodObj.crop.plantationType,
            ownerType: periodObj.ownerType,
            region: periodObj.region,
            comment: periodObj.comment,
            comment2: periodObj.comment2,
            comment3: periodObj.comment3,
            farmId: periodObj.farmId,
            qualification: periodObj.qualification,
            isActive: periodObj.isActive,
            bankId: periodObj?.bankId,
            insurerId: periodObj?.insurerId,
            insuranceCountyNumber: periodObj?.insuranceCountyNumber,
            damageFileNumber: periodObj?.damageFileNumber,
            locationId: periodObj?.locationId,
            lpisFieldsNebihFinalized: periodObj?.lpisFieldsNebihFinalized ?? false
        };
        return cultivationPeriodSaveData;
    }

    function setMapYear(savedPeriodId, yearId) {
        if (savedPeriodId && yearId && Boolean(usingMap) && window.set_map_year) {
            window.set_map_year(MAP.POLYGON_TYPE.CULTIVATION_PERIODS, savedPeriodId, yearId); //TODO: php - php function
        }
    }

    function getFieldFeatures(fieldFeatures) {
        const checkedFieldFeatures = fieldFeatures?.filter(fieldFeature => fieldFeature.isChecked);
        return checkedFieldFeatures?.map(fieldFeature => fieldFeature.key);
    }

    function checkBasicFieldsBeforeSave(basicFieldList, sownArea, inputParams, callBack) {
        const sumBasicFieldArea = basicFieldList?.map(basicField => basicField?.area)?.reduce((prevArea, nextArea) => prevArea + nextArea) || 0;

        if (sumBasicFieldArea > sownArea) {
            renderModal(ConfirmDialog, {
                iconId: 'icon-help',
                isAcceptCancelDialog: true,
                acceptButtonText: t('default.yes', 'Yes'),
                cancelButtonText: t('default.no', 'No'),
                questionText: t('default.areYouSure', 'Are you sure?'),
                descriptionText: t('editCultivationPeriod.confirmDescriptionBasicFields', 'The categorized area of the cultivation period is more than the basic field area.'),
                confirmCallback: () => callBack(inputParams),
            }, false, () => setIsSaveBtnDisabled(false));
        } else {
            callBack(inputParams);
        }
    }

    async function checkCultureChangeForLpis(values, callBack) {
        if (isEmpty(cultivationPeriodValues)) {
            callBack({isLpisCultureChangeNeeded: false});
            return;
        }

        const changedValues : any = getChangedValues(getCultivationPeriod(values));
        const periodObject : any = getCultivationPeriod(cultivationPeriodValues);
        const modifiableLpisFieldFound = (periodObject?.lpisFieldsNebihFinalized ?? []).filter(element => !element).length > 0;

        if (isEdit && (changedValues.cultureId || changedValues.varietyId || changedValues.cropTypeId) && modifiableLpisFieldFound) {
            renderModal(ConfirmDialog, {
                iconId: 'icon-help',
                isAcceptCancelDialog: true,
                acceptButtonText: t('default.yes', 'Yes'),
                cancelButtonText: t('default.no', 'No'),
                questionText: t('editCultivationPeriod.lpisCultureChange', 'LPIS field culture change'),
                descriptionText: t('editCultivationPeriod.confirmLpisCultureChange', 'Do you want to change the culture of the associated LPIS fields as well?'),
                confirmCallback: () => callBack({isLpisCultureChangeNeeded: true}),
                onClickCancel: () => callBack({isLpisCultureChangeNeeded: false}),
                hideAfterCancel: true
            }, false, () => setIsSaveBtnDisabled(false));
        } else {
            callBack({isLpisCultureChangeNeeded: false});
        }
    }

    async function checkCultivationPeriodIsInactivable(isEditPeriod, isActive, periodId, callBack) {
        if (!isEditPeriod || isActive !== PARTNER.ACTIVE_STATUS.INACTIVE) {
            callBack();
            return;
        }

        const costExist = await cultivationPeriodsService.checkCultivationPeriodCostExist(periodId);
        if (costExist) {
            setIsSaveBtnDisabled(false);
            dispatch(alertActions.errorAlert(t('cultivationPeriods.periodWithCostWarning', 'Saving failed, cultivation period with cost is not inactivable')));
            return;
        }
        callBack();
    }

    async function save(value, action) {
        try {
            const supportList = getSupportList(value.support);
            const registryNumberList = getRegistryNumberList(value.selectedRegistryNumbers);
            const basicFields = getBasicFieldList(value.basicFields);
            const fieldFeatures = getFieldFeatures(value.fieldFeatures);
            const cultivationPeriod = getCultivationPeriod(value);
            const previousPeriods = value.previousPeriods || [];
            const manualPrevCultureId = isNumber(value.manualPrevCultureId) ? value.manualPrevCultureId : null;
            const yearId = cultivationPeriod.yearId;
            let isCultivationPeriodsSaved;
            let isEconomicUnitPeriodTable = false;
            let savedPeriodId = null;
            let isVFCallNeeded = false;

            checkCultivationPeriodIsInactivable(isEdit, cultivationPeriod.isActive, cultivationPeriod.id, async () => {
                checkCultureChangeForLpis(value, async (callbackParams) => {
                    checkBasicFieldsBeforeSave(basicFields, cultivationPeriod.sownArea, callbackParams, async (finalCallbackParams) => {
                        const { isLpisCultureChangeNeeded = false } = finalCallbackParams;

                        if (isEdit) {
                            const changedValues = getChangedValues(cultivationPeriod);
                            const deletedCultivationTableIds = getDeletedBasicFields(basicFields, isEdit);
    
                            const editCultivationPeriod = await cultivationPeriodsService.updateCultivationPeriod(
                                {
                                    ...changedValues,
                                    cultivationTables: basicFields,
                                    deletedCultivationTableIds,
                                },
                                cultivationPeriod.id,
                                supportList,
                                registryNumberList,
                                fieldFeatures,
                                {
                                    geomId,
                                    yearId,
                                    geometry,
                                    companyId: cultivationPeriod.partnerId,
                                    farmId: cultivationPeriod.farmId,
                                },
                                previousPeriods,
                                manualPrevCultureId,
                                isLpisCultureChangeNeeded,
                            );
                            isCultivationPeriodsSaved = editCultivationPeriod.isSuccess;
                            isEconomicUnitPeriodTable = editCultivationPeriod.isEconomicUnitPeriodTable;
                            isVFCallNeeded = editCultivationPeriod.isVFCallNeeded;
                            savedPeriodId = cultivationPeriod.id;
                        } else {
                            const periodSaveResult = await cultivationPeriodsService.createNewCultivationPeriod(
                                {
                                    ...cultivationPeriod,
                                    support: supportList,
                                    registryNumber: registryNumberList,
                                    cultivationTables: basicFields,
                                    fieldFeatures,
                                    geometry,
                                    companyId: cultivationPeriod.partnerId,
                                    previousPeriods,
                                    manualPrevCultureId,
                                },
                            );
                            isCultivationPeriodsSaved = periodSaveResult.isSuccess;
                            savedPeriodId = periodSaveResult.periodId;
                            isVFCallNeeded = periodSaveResult.isVFCallNeeded;
    
                            action.resetForm(newCultivationPeriodInitialValues);
                        }
                        //set Map year
                        //Régi térképet használ, egyelőre kikommenteztem
                        /*if (isEdit && isCultivationPeriodsSaved && savedPeriodId && yearId) {
                            setMapYear(savedPeriodId, yearId);
                        }*/
    
                        if (isVFCallNeeded) {
                            await setVisibleFarmPeriodData(savedPeriodId, isEdit);
                        }
    
                        if (isEconomicUnitPeriodTable) {
                            dispatch(alertActions.infoAlert(t('editCultivationPeriod.economicUnitPeriodTableCultureInfo', 'The cultivation period`s culture has been changed. Please check the cultivation period classification at Controlling economic unit ')));
                        }
    
                        if (isCultivationPeriodsSaved) {
                            if (isFunction(setPeriodYearFilter)) {
                                setPeriodYearFilter(Number(yearId));
                            }
    
                            if (!isReloadDisabled) {
                                dispatch(basePageActions.setReloadTable());
                            }
    
                            setIsSaveBtnDisabled(false);
                            dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved')));
                            closeModal();
                        } else {
                            setIsSaveBtnDisabled(false);
                            dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed')));
                        }
                    });
                });
            });
        } catch (error) {
            setIsSaveBtnDisabled(false);
            dispatch(errorActions.setError(error, 'New cultivation period modal (save).'));
        } finally {
            if (isFunction(onAddNewPeriod)) {
                onAddNewPeriod();
            }
        }
    }

    return {
        save,
        isEdit,
    };
}
