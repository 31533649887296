import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { translationActions } from '@states/actions/translationActions';

export function useTranslationSetter() {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(translationActions.setTranslateFuncion(t));
    }, [dispatch, t]);
}
