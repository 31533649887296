import { LANGUAGE, TRANSLATER_LANGUAGE_CODES } from 'shared/src/constants';
import LocalStorageBackend from 'i18next-localstorage-backend';
import Backend from 'i18next-fetch-backend';
//import { sendServerRequest } from '@services';

const i18NextOptions = {
    fallbackLng: [LANGUAGE.ENGLISH.toLowerCase()],
    preload: [
        LANGUAGE.ENGLISH.toLowerCase(),
        LANGUAGE.HUNGARIAN.toLowerCase(),
    ],
    keySeparator: '.',
    whitelist: TRANSLATER_LANGUAGE_CODES,
    debug: false,
    interpolation: {
        escapeValue: false,
    },
    saveMissing: true,
    saveMissingTo: 'fallback',
    updateMissing: false,
    backend: {
        backends: [
            LocalStorageBackend,
            Backend,
        ],
        backendOptions: [{
            expirationTime: 1 * 24 * 60 * 60 * 1000, // 1 day
            prefix: 'i18next_res_',
        }, {
            loadPath: '/api/translations/{{lng}}',
        }],
    },
    react: {
        useSuspense: false,
        wait: false,
    },
    missingKeyHandler: (lng, ns, key, value) => value,
    /*missingKeyHandler: (lng, ns, key, value) => {
        //sendServerRequest('POST', `/api/translations/${lng}/${key}/${value}`);
        return value;
    },*/
};
export default i18NextOptions;
