import React from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import useTranslate from '@i18n/useTranslate';
import Button from '@baseComponents/buttons/Button';
import RegistryNumbersModal from '@customComponents/modals/registryNumberModal/RegistryNumbersModal';
import renderModal from '@modals/modalNew/modalFunctions';
import RegistryNumberTable from './RegistryNumberTable';
import RegistryNumberHeader from './RegistryNumberHeader';
import RegistryNumberFooter from './RegistryNumberFooter';
import useRegistryNumberTableData from './useRegistryNumberTableData';

const useStyles = createUseStyles((theme: any) => ({
    registryNumberContainer: {
        display: 'flex',
        minWidth: 900,
        maxWidth: 1050,
        height: '100%',
        flexFlow: 'column',
        paddingTop: 25,
        position: 'relative',
        '& h2': {
            fontFamily: 'Rubik',
            display: 'inline-block',
            verticalAlign: 'top',
            margin: [0, 0, 25, 10],
        },
    },
    noDataContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
    noDataTitle: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 16,
        color: theme.color.stone,
        fontWeight: 500,
    },
    addNewContainer: {
        borderBottom: '1px solid',
        borderBottomColor: theme.color.pearl,
        display: 'flex',
        paddingBottom: 20,
        paddingTop: 25,
        margin: [0, 30],
    },
}));

export default function RegistryNumberData(props) {
    const { close } = props;
    const { t } = useTranslate();
    const { values } = useFormikContext();
    const classes = useStyles();
    const { onModalUpdate } = useRegistryNumberTableData();

    return (
        <>
            <div className={classes.registryNumberContainer}>
                <RegistryNumberHeader
                    close={close}
                />

                <div className={classes.addNewContainer}>
                    <Button
                        className={classes.addNewButton}
                        iconId="icon-add"
                        iconPosition="before"
                        onClick={() => renderModal(RegistryNumbersModal, { partnerId: values.owner, partnerType: values.ownerType, isMultiselect: true, onChange: onModalUpdate, selectedRegNums: values.selectedRegistryNumbers }, false)}
                    >
                        {t('registryNumberData.addButton', 'ADD REGISTRY NUMBER')}
                    </Button>
                </div>

                {!isEmpty(values.registryNumber)
                    ? (
                        <RegistryNumberTable />)
                    : (
                        <div className={classes.noDataContainer}>
                            <div className={classes.noDataTitle}>
                                {t('default.noData', 'No data')}
                            </div>
                        </div>)}

                <RegistryNumberFooter />
            </div>
        </>
    );
}
