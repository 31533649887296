import { useState, useCallback, useEffect } from 'react';
import { isEmpty, isObject, isNull, isNumber, isFunction } from 'lodash';
import { stringUtils } from 'shared/src/modules';

export default function useSelectEventHandler(props) {
    const { dataList, selected, customSelected, setSelected, onChange, search, disabled, onActive, onInactive } = props;
    const [isOpened, setIsOpened] = useState(false);
    const [selectedItem, setSelectedItem] = useState<any>(selected);

    //changed by other component
    useEffect(() => {
        if ((!isNull(selected) || !isNull(customSelected))) {
            const selectedKey = customSelected || selected;
            if (isObject(selected) && !isEmpty(selected)) {
                setSelectedItem(selected);
                return;
            }
            setSelectedItem(currentState => (selectedKey !== currentState ? selectedKey : currentState));
        }
    }, [selected, customSelected]);

    //on change set locally selected item
    const selectedItemObject = useCallback(() => {
        if (!isEmpty(dataList) && (selectedItem || isNull(selectedItem) || isNumber(selectedItem))) {
            if (isObject(selectedItem) && !isEmpty(selectedItem) && selectedItem?.key && selectedItem?.value) {
                return selectedItem;
            }

            return getSelectedItem(dataList, selectedItem);
        }
        return null;
    }, [dataList, selectedItem]);

    function getSelectedItem(dataArray: any, keyObject: any) {
        return dataArray.find(dataItem => {
            if (isObject(keyObject)) {
                return stringUtils.isSame(dataItem.key === (keyObject as any).key);
            }
            return stringUtils.isSame(dataItem.key, keyObject);
        });
    }

    function onToggle(openedState, event) {
        if (disabled) {
            event?.preventDefault();
            event?.stopPropagation();
        }
        if (!disabled) {
            setIsOpened(openedState);
            if (search) {
                search();
            }
            if (isFunction(onActive) && openedState) {
                onActive();
            }
            if (isFunction(onInactive) && !openedState) {
                onInactive();
            }
        }
    }

    function onChangeSelected(eventKey) {
        if (stringUtils.isSame(eventKey, selectedItem)) {
            return;
        }

        setSelectedItem(eventKey);

        if (setSelected) {
            setSelected(eventKey);
        }

        if (onChange) {
            const eventObject = dataList?.find(dataRow => stringUtils.isSame(eventKey, dataRow.key));
            onChange(eventKey, eventObject);
        }
    }

    return {
        selectedItem: selectedItemObject(),
        isOpened,
        onToggle,
        onChangeSelected,
    };
}
