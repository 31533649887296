import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { TABLE } from 'shared/src/constants';

import OptionSearchBar from '@baseComponents/tableNew/header/searcher/optionalSearch/OptionSearchBar';
import SearchBar from '@baseComponents/inputs/SearchBar';
import MultiselectSearcher from '@baseComponents/tableNew/header/searchTypes/MultiselectSearcher';
import RadioSearcher from '@baseComponents/tableNew/header/searchTypes/RadioSearcher';
import BooleanSearcher from '@baseComponents/tableNew/header/searchTypes/BooleanSearcher';
import useTableHeaderSearchInputHandler from './useTableHeaderSearchInputHandler';

const useStyles = createUseStyles({
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: 35,
        minWidth: props => props.minWidth - 10 || 80,
        marginRight: 5,
    },
    searchBar: {
        width: '100%',
        '& input': {
            fontSize: 12,
        },
    },
});

function TableHeaderSearchInput(props) {
    const { column, isBasePageTable, tableKey } = props;
    const { columnId, ignoreSearching, minWidth, isSearchSeparatelyLines, searchAndSortType, searchColumnId } = column; //column data
    const isEnabledSearching = useSelector((state: any) => state.basePage.table.isEnabledSearching);
    const isEnabledSearchingSimpleTable = useSelector((state: any) => state.simpleTable.table[tableKey]?.isEnabledSearching);
    const [searchValue, setSearchValue] = useState(null);
    const classes = useStyles({ minWidth });

    const { onChangeInputValue } = useTableHeaderSearchInputHandler({ columnId: searchColumnId || columnId, isBasePageTable, tableKey });

    function onClick(event) {
        event.stopPropagation();
    }

    //hide search input
    if (ignoreSearching || (!isEnabledSearching && isBasePageTable) || (!isEnabledSearchingSimpleTable && !isBasePageTable)) {
        return null;
    }

    //multiselect kereső
    if (searchAndSortType === TABLE.SEARCH_TYPE.MULTISELECT) {
        return (
            <MultiselectSearcher column={column} onChange={onChangeInputValue} />
        );
    }

    if (searchAndSortType === TABLE.SEARCH_TYPE.RADIOLIST) {
        return (
            <RadioSearcher column={column} onChange={onChangeInputValue} />
        );
    }

    if (searchAndSortType === TABLE.SEARCH_TYPE.BOOLEAN) {
        return (
            <BooleanSearcher column={column} onChange={onChangeInputValue} />
        );
    }

    //input kereső
    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div className={classes.searchContainer} onClick={onClick} role="button" tabIndex="0">
            {isSearchSeparatelyLines
                ? (
                    <OptionSearchBar
                        className={classes.searchBar}
                        height="32"
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        onChange={onChangeInputValue}
                        columnId={columnId}
                        firstOptionName={column.componentProps.firstLineIdName}
                        secondOptionName={column.componentProps.secondLineIdName}
                    />
                ) : (
                    <SearchBar className={classes.searchBar} height="32" searchValue={searchValue} setSearchValue={setSearchValue} onChange={onChangeInputValue} columnId={searchColumnId || columnId} />
                )}
        </div>
    );
}

export default React.memo(TableHeaderSearchInput);
