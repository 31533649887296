import React from 'react';
import clsx from 'clsx';
import './InputBorder.scss';

const InputBorder = props => {
    const { children, type = 'default', style } = props;

    return (
        <div className={clsx('InputBorder', type && `InputBorder-${type}`)} style={style}>
            {children}
        </div>
    );
};

export default React.memo(InputBorder);
