import { isFunction, isEmpty, values } from 'lodash';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import { theme as themes } from '@styles/theme';

export default function getAllColumns(t, translater, showedColumns) {
    const columns = {
        addIcon: {
            type: columnName.iconButton,
            columnId: 'icon-add',
            componentProps: {
                icon: 'icon-add',
                getCellTitle: () => translater('default.select', 'Select'),
                getBackgroundColor: () => themes.color.main,
                getIconColor: () => themes.color.white,
                onClick: (rowData, onAddButtonClicked) => {
                    if (isFunction(onAddButtonClicked)) {
                        onAddButtonClicked(rowData);
                    }
                },
            },
            isLeftFixed: true,
            width: 80,
            minWidth: 80,
            maxWidth: 80,
        },
        mapSvg: {
            type: columnName.mapSvg,
            componentProps: {
                mapId: 'mapSvg',
            },
        },
        periodName: {
            type: columnName.simpleText,
            columnId: 'name',
            columnName: t('cultivationPeriodTableFilter.name', 'Name'),
            isLeftFixed: true,
            width: 220,
        },
        companyName: {
            type: columnName.simpleText,
            columnId: 'companyName',
            columnName: t('cultivationPeriodTableFilter.company', 'Company'),
            minWidth: 220,
        },
        productionYear: {
            type: columnName.simpleText,
            columnId: 'productionYear',
            columnName: t('cultivationPeriodTableFilter.productionYear', 'Production year'),
            width: 200,
        },
        tySownCr: {
            type: columnName.simpleText,
            columnId: 'cropTypeName',
            columnName: t('cultivationPeriodTableFilter.tySownCr', 'Type of sown crop'),
            minWidth: 160,
        },
        culture: {
            type: columnName.simpleText,
            columnId: 'cultureName',
            columnName: t('cultivationPeriodTableFilter.culture', 'Culture'),
            minWidth: 180,
        },
        cultureVariety: {
            type: columnName.simpleText,
            columnId: 'cultureVarietyName',
            columnName: t('cultivationPeriodTableFilter.cultureVariety', 'Culture variety'),
            minWidth: 120,
        },
        sownArea: {
            type: columnName.area,
            columnId: 'sownArea',
            columnName: t('cultivationPeriodTableFilter.sownArea', 'Sown area'),
            minWidth: 120,
        },
        startDate: {
            type: columnName.date,
            columnId: 'startDate',
            columnName: t('cultivationPeriodTableFilter.start', 'Start'),
            minWidth: 100,
        },
        endDate: {
            type: columnName.date,
            columnId: 'endDate',
            columnName: t('cultivationPeriodTableFilter.end', 'End'),
            minWidth: 100,
        },
        currentCultureName: {
            type: columnName.simpleText,
            columnId: 'currentCultureName',
            columnName: t('cultivationPeriodTableFilter.previousCultureName', 'Previous culture'),
            minWidth: 180,
        },
        cultivationPeriodGroupName: {
            type: columnName.simpleText,
            columnId: 'cultivationPeriodGroupName',
            columnName: t('cultivationPeriodTableFilter.cultivationPeriodGroupName', 'Period group'),
            minWidth: 180,
        },
    };

    if (!isEmpty(showedColumns)) {
        const filteredColumns = [];
        const columnObj = Object.entries(columns);

        showedColumns.forEach(showedColName => {
            const column = columnObj.find(columnParts => columnParts[0].toString() === showedColName);
            if (column) {
                filteredColumns.push(column[1]);
            }
        });

        return filteredColumns;
    }

    return values(columns);
}
