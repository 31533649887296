import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { isNil } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import IrrigationPanelComponent from './IrrigationPanelComponent';
import MachinePanelComponent from './MachinePanelComponent';
import { MACHINE_PANEL_PROP } from './MachinePanelProperty';

function MachinePanelContainer() {
    const [store, controller] = useMapContext();
    const { isMachineModalOpen, layerId, machine } = store.selectedMachine;

    const [machineClickListeners, setMachineClickListeners] = useState<Record<number, google.maps.MapsEventListener[] | null> | null>();
    const machinePanelLocked = useRef(false);

    useEffect(() => {
        if (!machineClickListeners && store.layering.attributes) {
            return setMachineClickListeners(store.layering.attributes.reduce((obj, attribute) => {
                const listeners: google.maps.MapsEventListener[] = [];
                attribute.machineLayer.forEach(machineIdAndMarker => {
                    const mouseOverListener = machineIdAndMarker.marker.addListener('mouseover', ev => {
                        if (!machinePanelLocked.current) {
                            controller.openMachinePanel(attribute.layerId, machineIdAndMarker.deviceId, machineIdAndMarker.marker);
                        }
                    });

                    const mouseClickListener = machineIdAndMarker.marker.addListener('mousedown', ev => {
                        machinePanelLocked.current = true;
                        controller.openMachinePanel(attribute.layerId, machineIdAndMarker.deviceId, machineIdAndMarker.marker);
                    });

                    listeners.push(mouseOverListener, mouseClickListener);
                });
                return { ...obj, [attribute.id]: listeners };
            }, {}));
        }
        return () => {
            if (machineClickListeners) {
                Object.keys(machineClickListeners).forEach(clickListenerKey => {
                    machineClickListeners[clickListenerKey].forEach(l => google.maps.event.removeListener(l));
                });
                setMachineClickListeners(null);
            }
        };
    }, [controller, machineClickListeners, store.layering.attributes, machine]);

    const IsIrrigation = Number(machine?.mainTypeId ?? 0) === 8;

    if (!isMachineModalOpen || !machine) {
        return null;
    }

    if (IsIrrigation) {
        return (
            <IrrigationPanelComponent
                close={() => { controller.closeMachinePanel(); machinePanelLocked.current = false; }}
                machineData={machine}
            />

        );
    }

    return (
        <MachinePanelComponent
            close={() => { controller.closeMachinePanel(); machinePanelLocked.current = false; }}
            machineData={machine}
        />
    );
}

export default MachinePanelContainer;
