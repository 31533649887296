import { useHistory } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { errorActions, alertActions } from '@states/actions';
import { userService, sessionService } from '@services';
import { stringUtils } from 'shared/src/modules';
import { routerHelper } from '@utils/router/RouterHelper';
import { SYSTEM } from 'shared/src/constants';
import Button from '@baseComponents/buttons/Button';
import Input from '@baseComponents/inputs/Input';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isNull, isUndefined } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    changePassword: {
        display: 'flex',
    },
    modalHeader: {
        padding: '25px',
        paddingBottom: 12,
        display: 'block',
        borderBottom: 'none',
    },
    title: {
        padding: {
            top: 25,
            bottom: 30,
        },
        fontSize: 25,
        fontWeight: 'bold',
        textAlign: 'left',
    },
    form: {
        margin: {
            top: 10,
            right: '10%',
            left: '10%',
        },
    },
    notValid: {
        margin: {
            top: 10,
            right: '25%',
            left: '25%',
        },
        color: 'red',
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    leftPanel: {
        background: theme.color.white,
        height: '100vh',
        minHeight: '100vh',
        width: 640,
        minWidth: 640,
        float: 'left',
        position: 'relative',
    },
    rightPanel: {
        backgroundImage: (props: any) => {
            if (props.systemSubType === SYSTEM.SYSTEM_SUBTYPE.SAVINGS_BANK) {
                return 'url("/styles/img/takarek_login_bg.jpg")';
            }
            return 'url("/styles/img/login_bg.jpg")';
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        minHeight: '100vh',
        width: '100%',
    },
    card: {
        width: 360,
        marginTop: 100,
        margin: 'auto',
        border: 'none',
    },
    sendButtonContainer: {
        textAlign: 'center',
        marginTop: 40,
        marginBottom: 20,
    },
    sendButton: {
        width: '100%',
    },
    inputSpace: {
        marginBottom: 20,
        height: 50,
    },
}));

export default function ChangePassword() {
    const { t } = useTranslation();
    const agentName = sessionService.getAgentName();
    const location = useLocation().pathname;
    const history = useHistory();
    const dispatch = useDispatch();
    const systemSubType = useSelector((state: any) => state.system.systemInfo.subType);
    const classes = useStyles({ systemSubType });

    const { handleChange, handleBlur, handleSubmit, errors } = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .required(t('changePassword.newPasswordRequired', 'New password is required')),
            confirmPassword: Yup.string()
                .required(t('changePassword.confirmPasswordRequired', 'Confirmation of new password is required')).when(['newPassword'],
                    (newPassword, schema) => (((isUndefined(newPassword)) || (isNull(newPassword)) || (newPassword.length === 0))
                        ? schema : schema.oneOf([newPassword], t('changePassword.samePassword', 'The passwords are not equal')))),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: value => checkPasswords(value),
    });

    async function checkPasswords(event) {
        const newPassword = event.newPassword;
        const confirmPassword = event.confirmPassword;

        try {
            if (confirmPassword === newPassword) {
                const hashCode = routerHelper.getHashCode(location);
                const successPasswordEdit = await userService.setNewPasswordWitHash(newPassword, hashCode);
                if (successPasswordEdit) {
                    dispatch(alertActions.successAlert(t('changePassword.successPwChange', 'You successfully set your new password!')));
                    history.push(`/${agentName}`);
                } else {
                    dispatch(alertActions.errorAlert(t('changePassword.unSuccesPwChange', 'Unsuccessfully password change!')));
                }
            }
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }

    return (
        <div className={classes.changePassword}>
            <div className={classes.leftPanel}>
                <Card className={classes.card}>
                    <div className={classes.title}>{t('changePassword.changePassword', 'Change password') as any}</div>
                    <form onSubmit={handleSubmit}>
                        <Input
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="newPassword"
                            placeholder={stringUtils.capitalizeFirstLetterUpper(t('changePassword.newPassword', 'New password'))}
                            type="password"
                            invalid={!!errors.newPassword}
                            errorMessage={errors.newPassword}
                            className={classes.inputSpace}
                        />

                        <Input
                            required
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="confirmPassword"
                            placeholder={stringUtils.capitalizeFirstLetterUpper(t('changePassword.confirmPassword', 'Confirm password'))}
                            type="password"
                            invalid={!!errors.confirmPassword}
                            errorMessage={errors.confirmPassword}
                            className={classes.inputSpace}
                        />
                        <div className={classes.sendButtonContainer}>
                            <Button submit className={classes.sendButton}>
                                {t('default.send', 'Send')}
                            </Button>
                        </div>
                    </form>
                </Card>
            </div>
            <div className={classes.rightPanel} />
        </div>
    );
}
