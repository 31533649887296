import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { errorActions } from '@states/actions';
import { cultivationTableService } from '@services/cultivationTableService';

export default function useNewBasicFieldLoadData(props) {
    const { isEdit, editedFieldId, setValue, hideModal } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                setIsLoading(true);
                if (!isCancelled) {
                    const cultivationTable = await cultivationTableService.getCultivationTableById(editedFieldId);
                    Object.keys(cultivationTable).forEach((objectKey, idx) => setValue(objectKey, Object.values(cultivationTable)[idx]));
                    setIsLoading(false);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
                setIsLoading(false);
                hideModal();
            }
        }

        if (dispatch && editedFieldId && isEdit) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [dispatch, isEdit, setValue, location, editedFieldId, hideModal]);

    return {
        isLoading,
    };
}
