import Button from '@baseComponents/buttons/Button';
import Svg from '@baseComponents/Svg';
import BasicTag from '@baseComponents/tags/BasicTag';
import renderModal from '@modals/modalNew/modalFunctions';
import useTranslate from '@i18n/useTranslate';
import { MapFilters, ThemeType } from '@map/services/mapTypes';
import { isArray } from 'lodash';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import CulturesModal from '@customComponents/modals/culturesModal/CulturesModal';
import MapFilterBase from './MapFilterBase';
import MapFilterPartnerSelected from './MapFilterPartnerSelected';
import { MapFilterSchema } from './MapFilterSchema';
import MapFilterCultureSelected from './MapFilterCultureSelected';

type StlyeType = {
    buttonHovered: boolean
}

type Classes = 'container' | 'buttonContainer' | 'title' | 'openFilterTableButton' | 'addIcon' | 'filterTable' | 'addIcon' | 'tagOuterContainer'

const useStyles = createUseStyles<Classes, StlyeType, ThemeType>(theme => ({
    container: {
        width: 199,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    buttonContainer: {
        width: 199,
    },
    title: {
        marginBottom: 15,
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
    },
    openFilterTableButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: '100%',
        border: `2px dashed ${theme.color.gray}`,
        borderRadius: 6,
        outline: 'none',
        cursor: 'pointer',
        borderColor: props => props.buttonHovered && theme.color.main,
        backgroundColor: props => props.buttonHovered && theme.color.opacity.main,
    },
    addIcon: {
        fill: props => props.buttonHovered && theme.color.shadow_green,
        color: theme.color.raven,
        height: 24,
    },
    filterTable: {
        overflow: 'scroll',
    },
    tagOuterContainer: {
        height: 40,
        marginTop: 15,
        '& span': {
            ...theme.overFlowText,
        },
    },
}));
export default function MapFilterCulture(props) {
    const filterContext = useFormContext<MapFilterSchema>();

    const [buttonHovered, setButtonHovered] = useState(false);

    const { t } = useTranslate();

    const classes = useStyles({ buttonHovered });

    const openCultureModal = () => {
        const cultureModalProps: any = {
            isMultiselect: true,
            onChange: (selected: any) => {
                if (isArray(selected)) {
                    const cultureIds = selected.map(culture => culture.id);
                    filterContext.setValue('cultureIds', cultureIds);
                    filterContext.setValue('cultures', selected);
                } else {
                    filterContext.setValue('cultureIds', [selected.id]);
                    filterContext.setValue('cultures', [selected]);
                }
            },
            selectedRowIds: filterContext.getValues('cultureIds'),
        };
        // eslint-disable-next-line no-undefined
        renderModal(CulturesModal, cultureModalProps, false);
    };

    return (
        <MapFilterBase>
            <div className={classes.container}>
                <div className={classes.buttonContainer}>
                    <div className={classes.title}>
                        {t('filterTitle.cultureIds', 'Culture')}
                    </div>
                    <div
                        className={classes.openFilterTableButton}
                        onMouseOver={() => setButtonHovered(true)}
                        onMouseOut={() => setButtonHovered(false)}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onFocus={() => { }}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onBlur={() => { }}
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            openCultureModal();
                        }}
                        aria-hidden="true"
                    >
                        <Svg
                            iconId="icon-add"
                            style={classes.addIcon}
                        />
                    </div>

                    <div className={classes.tagOuterContainer}>
                        <MapFilterCultureSelected />
                    </div>
                </div>
            </div>
        </MapFilterBase>
    );
}
