import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { PRODUCT } from 'shared/src/constants';
import { isFunction } from 'lodash';

import Radio from '@baseComponents/controls/Radio';

const useStyles = createUseStyles({
    partnerTypeLabel: {
        marginTop: 0,
        marginBottom: 0,
        marginRight: 15,
        fontFamily: 'Rubik',
        fontWeight: 'normal',
    },
    partnerTypeMargin: {
        marginRight: 15,
        marginBottom: 0,
        fontSize: 13,
    },
});

export default function ProductActiveStateRadio(props) {
    const { activeState, changeProductActiveState } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    function onChange(event) {
        if (isFunction(changeProductActiveState)) {
            const type = +event.target.value;
            changeProductActiveState(type);
        }
    }

    return (
        <>
            <h4 className={classes.partnerTypeLabel}>{t('productTableFilter.status', 'Product state')}</h4>
            <Radio
                group="productsModalStatus"
                text={t('productTableFilter.activeState', 'Active')}
                value={PRODUCT.ACTIVE_STATUS.ACTIVE}
                checked={activeState === PRODUCT.ACTIVE_STATUS.ACTIVE}
                onChange={onChange}
                className={classes.partnerTypeMargin}
            />
            <Radio
                group="productsModalStatus"
                text={t('productTableFilter.inactiveState', 'Inactive')}
                value={PRODUCT.ACTIVE_STATUS.INACTIVE}
                checked={activeState === PRODUCT.ACTIVE_STATUS.INACTIVE}
                onChange={onChange}
                className={classes.partnerTypeMargin}
            />
            <Radio
                group="productsModalStatus"
                text={t('productTableFilter.activeAndInactiveState', 'Company and partner')}
                value={PRODUCT.ACTIVE_STATUS.ALL}
                checked={activeState === PRODUCT.ACTIVE_STATUS.ALL}
                onChange={onChange}
                className={classes.partnerTypeMargin}
            />
        </>
    );
}
