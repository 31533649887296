import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { isFunction } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import { errorActions, simpleTableActions, alertActions } from '@states/actions';
import { phpService } from '@services';
import { useNumberFormat } from '@common_modules/numberFormat';

import renderModal from '@modals/modalNew/modalFunctions';
import ConfirmDialog from '@baseComponents/modals/ConfirmDialog';
import CertificatesModal from '@customComponents/modals/certificatesModal/CertificatesModal';

export default function useMultiPartnerReception(props) {
    const { hideModal, reset, setValue, getValues, tableKey, companyRefresh } = props;
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const { t, translater } = useTranslate();
    const [isDisabledSaveButton, setIsDisabledSaveButton] = useState(false);
    const { numberFormatter } = useNumberFormat();

    const onSubmit = async (multiPartnerReceptionDatas, isSaveAndNew) => {
        setIsDisabledSaveButton(true);

        try {
            const releasedCertificateIds = [];
            const receptionCertificateIds = [];

            const phpResult = await phpService.postDataToPhp('/modules/react/inventory/set_multi_partner_reception.php', { datas: multiPartnerReceptionDatas });

            if (phpResult.error) {
                const errorMessage = phpResult.error_text || translater('default.savingFailed', 'Saving failed.');
                dispatch(alertActions.addAlert({ alertMessage: errorMessage, isHtmlCodeAlert: true }));
                setIsDisabledSaveButton(false);
                return;
            }

            phpResult?.beveteli_bizonylat_ids?.forEach(certificateObj => {
                receptionCertificateIds.push(certificateObj);
            });

            if (!phpResult.error) {
                renderModal(ConfirmDialog, {
                    iconId: 'icon-done',
                    isAcceptCancelDialog: true,
                    acceptButtonText: t('default.yes', 'Yes'),
                    cancelButtonText: t('default.no', 'No'),
                    questionText: t('default.successfullySaved', 'Successfully saved.'),
                    descriptionText: t('multiPartnerReceptionModal.checkCertificatesQuestion', 'Do you want to check the completed certificates?'),
                    confirmCallback: () => {
                        showCertificates(releasedCertificateIds, receptionCertificateIds);
                    },
                }, false, isSaveAndNew ? resetFormAndRefreshTable : onCloseModal);
            }
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        } finally {
            setIsDisabledSaveButton(false);
        }
    };

    function resetFormAndRefreshTable() {
        reset();
        companyRefresh();
    }

    async function showCertificates(releasedCertificateIds, receptionCertificateIds) {
        setTimeout(() => renderModal(CertificatesModal, {
            releasedCertificateIds, receptionCertificateIds,
        }, true), 100);
    }

    function onCloseModal() {
        if (isFunction(hideModal)) {
            hideModal();
        }
        if (isFunction(companyRefresh)) {
            companyRefresh();
        }
    }

    function updateProductUnits(productUnit) {
        if (!productUnit) {
            return;
        }

        const partners = getValues('partners');

        partners?.forEach(partner => {
            partner.productUnit = productUnit;
        });

        setValue('partners', partners);
    }

    //új partner hozzáadása a táblázathoz
    function addPartnersToTable(newPartners) {
        const partners = getValues('partners');
        const newRows = [];

        newPartners?.forEach(partner => {
            const partnerId = partner.id;
            const partnerName = partner.name;
            const partnerSettlement = partner.settlement;
            const partnerAddress = partner.address;
            const productUnit = getValues('productUnit');
            const quantity = 0;

            const newRow = { partner: partnerName, partnerId, quantity, partnerSettlement, partnerAddress, productUnit };
            dispatch(simpleTableActions.addRowToTableData(tableKey, newRow));
            newRows.push(newRow);
        });

        setValue('partners', [...partners || [], ...newRows]);
    }

    function onChangeQuantity(quantity, rowId) {
        const partners = getValues('partners');

        const tableData = dispatch(simpleTableActions.getTableDataList(tableKey));
        const updatedRow = tableData?.find(tableRow => tableRow.rowId === rowId);

        if (updatedRow) {
            updatedRow.quantity = quantity;
        }

        let totalQuantity = 0;
        partners?.forEach(partnerRow => {
            if (partnerRow.rowId === rowId) {
                partnerRow.quantity = (+quantity);
            }
            totalQuantity += (+partnerRow.quantity);
        });

        setValue('totalQuantity', totalQuantity);
    }

    //partner törlése a táblázatból
    function deletePartnerFromTable(rowId) {
        const partners = getValues('partners');
        const filteredPartners = partners?.filter(partner => partner.rowId !== rowId);
        setValue('partners', filteredPartners);
    }

    //mennyiségek összeadása
    function getProductQuantitySum() {
        const totalQuantity = getValues('totalQuantity');
        const formatQuantitySum = numberFormatter.getFormattedProductQuantity(totalQuantity);

        return formatQuantitySum;
    }

    return {
        onSubmit,
        isDisabledSaveButton,
        updateProductUnits,
        addPartnersToTable,
        deletePartnerFromTable,
        getProductQuantitySum,
        onChangeQuantity,
    };
}
