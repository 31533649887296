/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable object-shorthand */
/* eslint-disable no-undef */
/* eslint-disable id-blacklist */
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import useTranslate from '@i18n/useTranslate';
import { useDispatch } from 'react-redux';
import { simpleTableActions, alertActions } from '@states/actions';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import GlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/GlobalSearchInput';
import Button from '@baseComponents/buttons/Button';
import CloseButton from '@baseComponents/buttons/CloseButton';
import { LoadSpinner, useLoader } from '@baseComponents/loadSpinner';
import { ModalContentWrapper, ModalHeader, ModalBody, ModalFooter } from '@baseComponents/modals/modalNew';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import { uniqueId, isArray, isEmpty, isFunction } from 'lodash';
import { useErrorHandler } from '@utils';
import useFocusGlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/useFocusGlobalSearchInput';

import ProductActiveStateRadio from './ProductActiveStateRadio';
import useProductsModalLoadData from './useProductsModalLoadData';
import getAllColumns from './allColumns';

const useStyles = createUseStyles((theme: any) => ({
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    headerFunctionLine: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: theme.color.white,
        padding: '0 30px',
        marginBottom: 10,
    },
    productStateContainer: {
        display: 'inline-flex',
        marginLeft: 20,
    },
    footerButton: {
        width: 250,
    },
    closeButton: {
        width: 150,
    },
    addSelectedButton: {
        marginRight: 10,
    },
    tableWrapper: {
        display: 'flex',
        height: '100%',
        width: '100%',
    },
    table: {
        '& .tableHeaderRow': {
            marginRight: 20,
            paddingRight: 10,
            marginLeft: 4,
        },
        '& .List': {
            marginRight: 20,
            paddingRight: 10,
        },
        '& .table-row': {
            marginRight: 20,
            paddingRight: 10,
        },
        width: '100%',
    },
    createNewButton: {
        marginRight: 10,
    },
}));

const TABLE_KEY = uniqueId('productsModal');

export default function ProductsModal(props) {
    const {
        hideModal, onChange, productId, onSelectedValue, showActiveState = true, defaultActiveState, companyId, warehouseId, date, hasWeightShiftNumber, notPlantProtectedProducts,
        affectedByStockRecords, recordsInManagementUnit, isMultiselect = false, selectedRowIds, rowFormatFnc = obj => obj, inStockProductsOnly = true,
        typeIds, excludedIds, isNutrient, isPlantProtection, showPesticideProduct,
    } = props;
    const { hasCreateNewButton = true } = props;
    const { t, translater } = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [defaultColumns, setDefaultColumns] = useState<Array<any>>();
    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    const errorHandler = useErrorHandler();
    const { setLoader } = useLoader();
    const { tableData, activeState, changePartnerType, reloadTable } = useProductsModalLoadData({
        productId,
        defaultActiveState,
        companyId,
        warehouseId,
        date,
        hasWeightShiftNumber,
        notPlantProtectedProducts,
        affectedByStockRecords,
        recordsInManagementUnit,
        inStockProductsOnly,
        typeIds,
        excludedIds,
        isNutrient,
        isPlantProtection,
        showPesticideProduct,
    });

    useEffect(() => {
        if (productId && !isEmpty(tableData)) {
            const selectedProduct = tableData?.find(product => product.productId === productId);

            if (isFunction(onSelectedValue)) {
                onSelectedValue(selectedProduct.productName, selectedProduct.productId, rowFormatFnc(selectedProduct));
            }

            if (isFunction(onChange)) {
                onChange(rowFormatFnc(selectedProduct));
            }
        }
    }, [productId, tableData, onChange, onSelectedValue, rowFormatFnc]);

    //set columns
    useEffect(() => {
        const showedColumns = ['addIcon', 'name', 'isActiveIcon', 'productType', /*'productSubType',*/ 'itemNumber', 'unit', 'productComment', 'catalogNumber'];
        const filteredColumns = getAllColumns(t, translater, showedColumns);
        setDefaultColumns(filteredColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAddButtonClicked(selectedProduct) {
        if (isFunction(onSelectedValue)) {
            onSelectedValue(selectedProduct.productName, selectedProduct.productId);
        }

        if (isFunction(onChange)) {
            onChange(rowFormatFnc(selectedProduct));
        }

        if (isFunction(hideModal)) {
            hideModal();
        }
    }

    function onAddSelectedButtonClicked() {
        const selectedProductIds: Array<any> = dispatch(simpleTableActions.getSelectedRowIds(TABLE_KEY)) as any;
        const selectedProducts = tableData?.filter(rowData => selectedProductIds?.includes(rowData.id))?.map(product => rowFormatFnc(product));

        if (!isEmpty(selectedProducts)) {
            if (isFunction(onChange)) {
                onChange(selectedProducts);
            }

            if (isFunction(hideModal)) {
                hideModal();
            }
        } else {
            dispatch(alertActions.warningAlert(t('productTableFilter.noSelectedProduct', 'No selected Product')));
        }
    }

    //TODO: php function (modal)
    function onClickAddNew() {
        setLoader(true);

        try {
            setTimeout(() => {
                $.ajax({
                    url: '/modules/termek/view/termek_edit_modal.php',
                    data: {
                        tipus_id: 0,
                        nev: '',
                        allat_faj_id: 0,
                    },
                    async: false,
                    success: function (data) {
                        $(`<div id="termek-edit-modal" class="modal fade">${data}</div>`).modal().on('shown', function () {
                            setLoader(false);
                        }).on('hidden', function () {
                            $('.required_container_modal').remove();
                            $('#termek-edit-modal').modal('hide');
                            reloadTable();
                        });
                    },
                });
            }, 100);
        } catch (error) {
            errorHandler(error);
            setLoader(false);
        }
    }

    const [tableFunctions] = useState({
        onAddButtonClicked,
    });

    const [breakdown] = useState({
        value: null,
        text: 'No breakdown',
    });

    useFocusGlobalSearchInput(TABLE_KEY);

    return (
        <ModalContentWrapper>
            <ModalHeader>
                {t('productTableFilter.selectProduct', 'Select product')}
                <CloseButton onClick={hideModal} />
            </ModalHeader>
            <div className={classes.headerFunctionLine}>
                <GlobalSearchInput tableKey={TABLE_KEY} isSearchButton={false} isSearchIcon />
                {showActiveState && (
                    <div className={classes.productStateContainer}>
                        <ProductActiveStateRadio activeState={activeState} changeProductActiveState={changePartnerType} />
                    </div>
                )}
            </div>
            <ModalBody className={classes.modalBody}>
                <LoadSpinner value={tableData} isAutoDetect />
                <div className={classes.tableWrapper}>
                    <TableGroup
                        tableId={TABLE_KEY}
                        tableData={tableData}
                        tableColumns={columns}
                        isBasePageTable={false}
                        isCollapsible={false}
                        tableGroup={breakdown}
                        useRowSelection={isMultiselect}
                        selectedRowIds={selectedRowIds || []}
                        className={classes.table}
                        tableFunctions={tableFunctions}
                    />
                </div>
                <TableScrollerFooter tableKey={TABLE_KEY} isLoaded={isArray(tableData)} />
            </ModalBody>
            <ModalFooter>
                {hasCreateNewButton ? (
                    <Button
                        className={classes.createNewButton}
                        onClick={onClickAddNew}
                        iconId="icon-add"
                        iconPosition="before"
                    >
                        {t('default.createNew', 'Create new')}
                    </Button>
                ) : null}
                {isMultiselect ? (
                    <Button className={clsx(classes.footerButton, classes.addSelectedButton)} onClick={onAddSelectedButtonClicked}>
                        {t('default.addSelected', 'Add selected')}
                    </Button>
                ) : null}
                <Button type="secondary" className={classes.closeButton} onClick={hideModal}>
                    {t('default.close', 'Close')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
