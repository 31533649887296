export const FILES = {
    TYPE_ID: {
        IMAGE: 1,
        VOICE: 2,
        OTHER: 3,
    },
    SIZE_EXCHANGE: {
        MB: 1048576,
    },
    ROUND: {
        MB: 2,
    },
    INPUT_ACCEPT_TYPE: {
        EXCEL: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    },
    UNITS: {
        MB: "MB",
    },
    UPDATE_MAX_SIZE: 50,
    SAVING_SPACE: {
        FILES: '../uploads/files/',
        PROFILE_PICTURES: '../uploads/profilepictures/',
    },
}
