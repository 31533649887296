import { systemActions } from '../actions/systemActions';

const initialState = {
    systemInfo: {
        isMaintained: null,
        isTestSystem: null,
        subType: null,
        countryCode: null,
        langCode: null,
    },
    systemSettings: {
        planningModeAccess: null,
    },
    planningModeisActive: false,
};

export function system(state = initialState, action) {
    switch (action.type) {
        case systemActions.SET_SYSTEMINFO:
            return {
                ...state,
                systemInfo: action.payload.systemInfo,
            };
        case systemActions.REMOVE_SYSTEMINFO:
            return {
                ...state,
                systemInfo: null,
            };
        case systemActions.REMOVE_MAINTENANCE:
            return {
                ...state,
                systemInfo: {
                    ...state.systemInfo,
                    isMaintained: false,
                },
            };
        case systemActions.SET_SYSTEMSETTINGS:
            return {
                ...state,
                systemSettings: action.payload.systemSettings,
            };
        case systemActions.SET_PLANNING_MODE_ACTIVE:
            return {
                ...state,
                planningModeisActive: action.payload.planningModeisActive,
            };
        default:
            return state;
    }
}
