import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';

const useStyle = createUseStyles((theme: any) => ({
    groupName: {
        margin: 0,
        padding: 0,
        paddingLeft: 10,
        display: 'inline-block',
        color: theme.color.jet,
        fontSize: 18,
        fontWeight: 700,
        ...theme.overFlowText,
    },
    groupPiece: {
        margin: 0,
        padding: 0,
        paddingLeft: 5,
        display: 'inline-block',
        color: theme.color.jet,
        fontSize: 18,
        fontWeight: 700,
    },
}));

export default function TableGroupName(props) {
    const { groupName, groupItemCount } = props;
    const { t } = useTranslation();
    const classes = useStyle();

    function getGroupPiece() {
        return ` (${groupItemCount} ${t('default.pieceShort', 'pc')})`;
    }

    return (
        <>
            <h3 className={classes.groupName} title={groupName}>
                {groupName}
            </h3>
            {groupItemCount
                && (
                    <h3 className={classes.groupPiece}>
                        {getGroupPiece()}
                    </h3>
                )
            }
        </>
    );
}
