import { RIGHTS, SYSTEM } from '../constants';

export const WINERY = {
    MODULE_ID: 26,
    NAME: 'winery',
    CONTENTS: {
        WINE_ACCOMPANYING_DOCUMENTS: {
            CONTENT_URL_ID: { wineAccompanyingDocuments: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'wineAccompanyingDocuments',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.wineAccompanyingDocuments',
                TRANSLATOR_DEFAULT: 'Wine accompanying documents',
            },
            EXCLUDE_SYSTEM_MODULES: [
                SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
                SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
                SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
                SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
            ],
            RIGHTS: [RIGHTS.WINE_ACCOMPANYING_DOCUMENTS_INTERFACE],
        },
        WINE_ACCOMPANYING_DOCUMENTS_EDIT: {
            CONTENT_URL_ID: { wineAccompanyingDocumentsEdit: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'wineAccompanyingDocumentsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.wineAccompanyingDocumentsEdit',
                TRANSLATOR_DEFAULT: 'Wine accompanying documents edit',
            },
            EXCLUDE_SYSTEM_MODULES: [
                SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
                SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
                SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
                SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
            ],
            RIGHTS: [RIGHTS.WINE_ACCOMPANYING_DOCUMENTS_EDIT],
        },
        WINE_ACCOMPANYING_DOCUMENTS_BLOCKS: {
            CONTENT_URL_ID: { wineAccompanyingDocumentsBlocks: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'wineAccompanyingDocumentsBlocks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.wineAccompanyingDocumentsBlocks',
                TRANSLATOR_DEFAULT: 'Wine accompanying documents blocks',
            },
        },
    },
};
