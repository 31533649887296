import ModalRoot from '@modals/modalNew/ModalRoot';
import PageLoader from '@baseComponents/PageLoader';
import SiteHelmet from '@baseComponents/siteHelmet/SiteHelmet';
import Alert from '@baseComponents/alert/Alert';
import ErrorInfoPopup from '@baseComponents/alert/ErrorInfoPopup';
import { useWhyDidYouUpdate } from '@common_modules';
import MainPage from './MainPage';
import Login from './Login';
import NotAvailable from './errors/NotAvailable';
import ServerOverload from './errors/ServerOverload';
import ChangePassword from './ChangePassword';

import { usePhpEventHandler } from './screenLoader/usePhpEventHandler';
import { useRouteEventHandler } from './screenLoader/useRouteEventHandler';
import { useRouter } from './screenLoader/useRouter';
import { useDataListHandler } from './screenLoader/useDataListHandler';
import { useDataListPhpHandler } from './screenLoader/useDataListPhpHandler';
import { useTranslationSetter } from './screenLoader/useTranslationSetter';

export default function ScreenLoader() {
    const { isCheckedPhpSession, hasPhpSession } = usePhpEventHandler();
    const {
        isServerOverload,
        agentError,
        routeList,
        isInitialized,
        displayChangePasswordScreen,
        displayLoginScreen,
        displayMainPageScreen,
        displayNotAvailableScreen,
    } = useRouter({ isCheckedPhpSession, hasPhpSession });
    useRouteEventHandler();
    useDataListHandler();
    useDataListPhpHandler(); //php - PHP-react data list sync
    useTranslationSetter();

    useWhyDidYouUpdate('ScreenLoader', {
        isCheckedPhpSession,
        hasPhpSession,
        isServerOverload,
        agentError,
        routeList,
        isInitialized,
        displayChangePasswordScreen,
        displayLoginScreen,
        displayMainPageScreen,
        displayNotAvailableScreen,
    });

    if (isServerOverload) {
        return (<ServerOverload />);
    }

    if (!isInitialized()) {
        return null;
    }

    return (
        <>
            <ModalRoot />
            <SiteHelmet routeList={routeList} />
            {displayNotAvailableScreen() && <NotAvailable />}
            {displayLoginScreen() && <Login />}
            {displayMainPageScreen() && <MainPage routeList={routeList} />}
            {displayChangePasswordScreen() && <ChangePassword />}
            <Alert />
            <PageLoader />
            {!agentError && <ErrorInfoPopup />}
        </>
    );
}
