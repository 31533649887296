import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { errorActions } from '@states/actions';

export default function useErrorHandler() {
    const actualLocation = useLocation();
    const dispatch = useDispatch();

    const handleError = useCallback((error, location = actualLocation.pathname) => {
        dispatch(errorActions.setError(error, location));
    }, [dispatch, actualLocation]);

    return handleError;
}
