import { isEmpty, isUndefined } from 'lodash';

export function isAdministrator(user) {
    return Boolean(user.administrator);
}

export function isConsultant(user) {
    return Boolean(user.consultant);
}

export function isAgrovirResponsible(user) {
    return Boolean(user.agrovirResponsible);
}

export function isTranslater(user) {
    return Boolean(user.translater);
}

export function isSubscribedServiceAccess(user, packageGroupId) {
    const { userSubscriptionPackageGroupAccess } = user;

    if (!isEmpty(userSubscriptionPackageGroupAccess)) {
        const accessItem = userSubscriptionPackageGroupAccess.find(subscription => subscription.packageGroupId === packageGroupId);
        if (!isUndefined(accessItem)) {
            return Boolean(+accessItem.hasAccess);
        }
        return true;
    }
    return true;
}
