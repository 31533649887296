import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import useTranslate from '@i18n/useTranslate';
import { isFunction, isEmpty, isArray, uniqueId } from 'lodash';
import { simpleTableActions } from '@states/actions';
import { useAlert } from '@utils';

import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import Button from '@baseComponents/buttons/Button';
import CloseButton from '@baseComponents/buttons/CloseButton';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import GlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/GlobalSearchInput';
import { ModalContentWrapper, ModalHeader, ModalBody, ModalFooter } from '@baseComponents/modals/modalNew';

import useReleasingWarehouseLoadData from './useReleasingWarehouseLoadData';
import getAllColumns from './allColumns';

const useStyles = createUseStyles((theme: any) => ({
    modalWrapper: {
        width: (props: any) => (props.isOnlyFromStock ? 1100 : 900),
    },
    headerFunctionLine: {
        display: 'flex',
        alignItems: 'center',
        background: theme.color.white,
        height: 50,
        padding: '0 30px',
        marginBottom: 16,
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    tableWrapper: {
        display: 'contents',
        height: '100%',
        width: '100%',
    },
    addSelectedButton: {
        marginRight: 10,
    },
    footerButton: {
        width: 250,
    },
    closeButton: {
        width: 150,
    },
    table: {
        '& .tableHeaderRow': {
            marginRight: 20,
            paddingRight: 10,
            marginLeft: 4,
        },
        '& .List': {
            marginRight: 20,
            paddingRight: 10,
        },
        '& .table-row': {
            marginRight: 20,
            paddingRight: 10,
        },
        width: '100%',
    },
}));

const TABLE_KEY = uniqueId('releasingWarehouseModal');

function ReleasingWarehouseModal(props) {
    const { onChange, hideModal } = props;
    const { isMultiselect, selectedRowIds, onSelectedValue } = props;
    const { rowFormatFnc = obj => obj } = props;
    const { isEnabledSearching } = props; //default display
    const { partnerId, companyIds, typeIds, isRecordsInTreatmentUnit, isOnlyFromStock, productIds, date } = props;
    const { t, translater } = useTranslate();
    const classes = useStyles({ isOnlyFromStock });
    const dispatch = useDispatch();
    const [defaultColumns, setDefaultColumns] = useState();
    const [tableKey] = useState(TABLE_KEY);
    const [defaultGroup] = useState({
        value: null,
        text: 'No breakdown',
    });
    const { warningAlert } = useAlert();
    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });
    const { tableData } = useReleasingWarehouseLoadData({ partnerId, companyIds, typeIds, isRecordsInTreatmentUnit, isOnlyFromStock, productIds, date });

    //init columns
    useEffect(() => {
        const showedColumns = isOnlyFromStock
            ? ['addIcon', 'name', 'quantity', 'price', 'unit', 'partnerName']
            : ['addIcon', 'name', 'companyPartner', 'typeName'];
        const filteredColumns = getAllColumns(t, translater, showedColumns);
        setDefaultColumns(filteredColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAddButtonClicked(selectedWarehouse) {
        if (isFunction(onSelectedValue)) {
            onSelectedValue(selectedWarehouse.name, selectedWarehouse.id);
        }

        if (isFunction(onChange)) {
            onChange(rowFormatFnc(selectedWarehouse));
        }

        if (isFunction(hideModal)) {
            hideModal();
        }
    }

    function onAddSelectedButtonClicked() {
        const selectedRowId: any = dispatch(simpleTableActions.getSelectedRowIds(tableKey));
        const selectedWorkOperations = (tableData as any)?.filter(rowData => selectedRowId?.includes(rowData.id))?.map(warehouse => (
            rowFormatFnc(warehouse)
        ));

        if (!isEmpty(selectedWorkOperations)) {
            if (isFunction(onChange)) {
                onChange(selectedWorkOperations);
            }
            if (isFunction(hideModal)) {
                hideModal();
            }
        } else {
            warningAlert(t('releasingWarehouseTableFilter.noSelectedWarehouse', 'No warehouse were selected'));
        }
    }

    const [tableFunctions] = useState({
        onAddButtonClicked,
    });

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {isOnlyFromStock
                    ? t('releasingWarehouseTableFilter.selectReleasingWarehouse', 'Select releasing warehouse')
                    : t('releasingWarehouseTableFilter.selectWarehouse', 'Select warehouse')
                }
                <CloseButton onClick={hideModal} />
            </ModalHeader>
            <div className={classes.headerFunctionLine}>
                <GlobalSearchInput tableKey={tableKey} isSearchButton={false} isSearchIcon />
            </div>
            <ModalBody className={classes.modalBody}>
                <LoadSpinner value={tableData} isAutoDetect />
                <div className={classes.tableWrapper}>
                    <TableGroup
                        tableId={tableKey}
                        tableData={tableData}
                        tableColumns={columns}
                        isBasePageTable={false}
                        isCollapsible={false}
                        tableGroup={defaultGroup}
                        className={classes.table}
                        tableFunctions={tableFunctions}
                        useRowSelection={isMultiselect}
                        selectedRowIds={selectedRowIds || null}
                        isEnabledSearching={isEnabledSearching}
                    />
                </div>
                <TableScrollerFooter tableKey={tableKey} isLoaded={isArray(tableData)} />
            </ModalBody>
            <ModalFooter>
                {isMultiselect ? (
                    <Button className={clsx(classes.footerButton, classes.addSelectedButton)} onClick={onAddSelectedButtonClicked}>
                        {t('default.addSelected', 'Add selected')}
                    </Button>
                ) : null}
                <Button type="secondary" className={classes.closeButton} onClick={hideModal}>
                    {t('default.close', 'Close')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}

export default React.memo(ReleasingWarehouseModal);
