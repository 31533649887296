import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import '@styles/controls/radio.css';

const useStyles = createUseStyles({
    disabled: {
        opacity: 0.4,
        cursor: 'context-menu !important',
        '& input': {
            cursor: 'context-menu !important',
        },
    },
    radioButton: {
        fontSize: 13,
    },
});

export default function Radio(props) {
    const { size, text, group, value, checked, onChange, className, isDisabled } = props;
    const componentSize = size || '';
    const classes = useStyles();

    function getDisabledClassName() {
        return isDisabled && classes.disabled;
    }

    return (
        <label className={clsx(`radioContainer ${componentSize}`, classes.radioButton, className, getDisabledClassName())} disabled={isDisabled}>
            { text }
            <input type="radio" value={value} checked={checked} name={group} onChange={onChange} disabled={isDisabled} />
            <span className={`radioCheckmark ${componentSize}`} />
        </label>
    );
}
