import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { basePageActions, simpleTableActions } from '@states/actions';

//select actual row
export default function useBasePageRowSelection(props) {
    const { id, isBasePageTable, isSelectedRow, setIsSelectedRow, rowData, tableKey, onChangeSelect } = props;
    const selectedItemIds = useSelector((state: any) => state.basePage.table.selectedItemIds);
    const simpleTableSelectedItemIds = useSelector((state: any) => state.simpleTable.table[tableKey]?.selectedItemIds);

    const dispatch = useDispatch();

    //check basePage selected ids
    useEffect(() => {
        if (isBasePageTable) {
            setRowSelection(selectedItemIds, isSelectedRow, rowData, id, setIsSelectedRow);
        }
    }, [selectedItemIds, id, isBasePageTable, isSelectedRow, rowData, setIsSelectedRow]);

    //check simple table selected ids
    useEffect(() => {
        if (!isBasePageTable) {
            setRowSelection(simpleTableSelectedItemIds, isSelectedRow, rowData, id, setIsSelectedRow);
        }
    }, [simpleTableSelectedItemIds, id, isBasePageTable, isSelectedRow, rowData, setIsSelectedRow]);

    //selection handler from redux
    function setRowSelection(selectedItemIdList, isSelectedItem, rowDatas, selectedId, setSelectedRow) {
        if (selectedItemIdList) {
            if (selectedItemIdList.includes(selectedId) && isSelectedItem === false) {
                setSelectedRow(currentState => {
                    if (currentState === false) {
                        rowDatas.isSelectedRow = true;
                        return true;
                    }
                    return currentState;
                });
            } else if (!selectedItemIdList.includes(selectedId) && isSelectedItem === true) {
                setSelectedRow(currentState => {
                    if (currentState === true) {
                        rowDatas.isSelectedRow = false;
                        return false;
                    }
                    return currentState;
                });
            }
        } else if (!selectedItemIdList && isSelectedItem === true) {
            setSelectedRow(currentState => {
                if (currentState === true) {
                    rowDatas.isSelectedRow = false;
                    return false;
                }
                return currentState;
            });
        }
    }

    //Redux state change
    async function changeCheckedState() {
        if (isBasePageTable) { //base page
            if (!isSelectedRow) {
                dispatch(basePageActions.addSelectedIds(id));
            } else {
                dispatch(basePageActions.removeSelectedIds(id));
            }
        } else if (!isBasePageTable) { //simple table
            if (!isSelectedRow) {
                dispatch(simpleTableActions.addSelectedIds(tableKey, id));
            } else {
                dispatch(simpleTableActions.removeSelectedIds(tableKey, id));
            }
        }
        if (onChangeSelect) {
            onChangeSelect(isSelectedRow, rowData);
        }
    }

    return {
        changeCheckedState,
    };
}
