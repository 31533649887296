import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { Dropdown } from 'react-bootstrap';

const useStyles = createUseStyles((theme: any) => ({
    dropdownWrapper: {
        width: 50,
        '& .dropdown-toggle': {
            padding: 0,
            background: theme.color.white,
            border: '1px solid',
            borderColor: theme.color.gray,
            borderRadius: 4,
            boxShadow: '0 3px 5px 0 rgba(175,180,182,0.3)',
            '&:hover': {
                backgroundColor: `${theme.color.white} !important`,
                borderColor: `${theme.color.gray} !important`,
            },
            '&:active': {
                backgroundColor: `${theme.color.white} !important`,
                borderColor: `${theme.color.gray} !important`,
            },
            '&:focus': {
                backgroundColor: `${theme.color.white} !important`,
                borderColor: `${theme.color.gray} !important`,
            },
        },
        '& .btn-primary': {
            backgroundColor: `${theme.color.white} !important`,
            borderColor: `${theme.color.gray} !important`,
            '&:active': {
                backgroundColor: `${theme.color.white} !important`,
                borderColor: `${theme.color.gray} !important`,
            },
            '&:focus': {
                backgroundColor: `${theme.color.white} !important`,
                borderColor: `${theme.color.gray} !important`,
            },
        },
    },
}));

export default function DropdownWrapper(props) {
    const { children, className, dropDirection = 'down', title, onToggle } = props;
    const classes = useStyles();

    return (
        <Dropdown className={clsx(classes.dropdownWrapper, className)} drop={dropDirection} title={title} onToggle={onToggle}>
            {children}
        </Dropdown>
    );
}
