import { alertActions } from '../actions/alertActions';

const initialState = {
    alert: [],
};

export function alert(state = initialState, action) {
    switch (action.type) {
        case alertActions.ADD_ALERT:
            return {
                ...state,
                alert: [
                    ...state.alert,
                    { ...action.payload },
                ],
            };
        case alertActions.REMOVE_ALERT:
            return {
                ...state,
                alert: state.alert.filter(alertObj => alertObj.id !== action.payload.alertId),
            };
        default:
            return state;
    }
}
