import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { isFunction, isEmpty } from 'lodash';
import { stringUtils } from 'shared/src/modules';
import { SimpleSelect } from '@baseComponents/select';
import { useErrorHandler } from '@utils';

const useStyles = createUseStyles((theme: any) => ({
    simpleSelectCell: {
        height: (props: any) => (props.isMultigrid ? 34 : '100%'),
        //marginTop: (props: any) => (props.isMultigrid ? 0 : 8),
        margin: 0,
        display: 'flex !important',
        alignItems: 'center',
        '& .dropdown-toggle.btn.btn-primary': {
            borderColor: (props: any) => props.isRequired && !props.hasSelectedItem && 'red' && theme.color.required,
        },
        '& .dropdown-menu': {
            width: (props: any) => props.dropdownWidth > 0 && `${props.dropdownWidth}px !important`,
        },
        '& .dropdown-menu.show': {
            width: (props: any) => props.dropdownWidth > 0 && `${props.dropdownWidth}px !important`,
        },
        '& .show': {
            width: (props: any) => props.dropdownWidth > 0 && `${props.dropdownWidth}px !important`,
            display: 'flex !important',
        },
        '& button': {
            height: (props: any) => props.isMultigrid && '100%',
        },
    },
}));

export default function SimpleSelectCell(props) {
    const { updateValue, rowData = props?.cellValue, value = props?.cellValue?.id, columnId, onChangeSelected, isRequired, dropdownWidth, isNonAccentSearch, customSelectId, isDisabled } = props;
    const { getDataList, getSimpleDataList, ignoreUpdateValue, defaultSelectedItem, useAbsoluteMenu } = props;
    const [dataList, setDataList] = useState();
    const [filteredDataList, setFilteredDataList] = useState();
    const [selectedItem, setSelectedItem] = useState(value);
    const isInitializedDefault = useRef(true);
    const errorHandler = useErrorHandler();
    const classes = useStyles({ isRequired, hasSelectedItem: !!selectedItem, dropdownWidth, isMultigrid: props?.cellValue });

    //re-init selected item
    useEffect(() => {
        if (!defaultSelectedItem && !value) {
            setSelectedItem(currentState => {
                if (currentState?.id === props?.cellValue?.id || currentState === props?.cellValue?.id) {
                    return currentState;
                }
                return props?.cellValue;
            });
            isInitializedDefault.current = true;
            return;
        }
        if (isInitializedDefault?.current) {
            setSelectedItem(currentState => {
                if (currentState?.key?.toString() === props?.cellValue?.id?.toString() || currentState?.toString() === props?.cellValue?.id?.toString()) {
                    return currentState;
                }
                return defaultSelectedItem;
            });
        }
    }, [props?.cellValue, defaultSelectedItem, isInitializedDefault]);

    useEffect(() => {
        if (!defaultSelectedItem && value) {
            setSelectedItem(currentState => {
                if (currentState?.id === value?.id || currentState === value?.id || currentState?.key === value?.key || currentState === value?.key) {
                    return currentState;
                }
                return value;
            });
        }
    }, [value, defaultSelectedItem, isInitializedDefault]);

    //init datalist
    useEffect(() => {
        let isCancelled = false;
        async function loadData() {
            try {
                const selectDataArray = await getDataList(rowData);
                if (!isCancelled) {
                    setDataList(selectDataArray);
                    setFilteredDataList(selectDataArray);
                }
            } catch (error) {
                errorHandler(error);
            }
        }

        if (isFunction(getDataList)) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [rowData, getDataList, errorHandler]);

    //init datalist
    useEffect(() => {
        let isCancelled = false;
        async function loadData() {
            try {
                const selectDataArray = await getSimpleDataList();
                if (!isCancelled) {
                    setDataList(selectDataArray);
                    setFilteredDataList(selectDataArray);
                }
            } catch (error) {
                errorHandler(error);
            }
        }

        if (isFunction(getSimpleDataList)) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errorHandler]);

    //search input onChange
    function filterByValue(filterValue) {
        if (!isEmpty(filterValue) && !isEmpty(dataList)) {
            setFilteredDataList(dataList.filter(listItem => stringUtils.includesNoCase(listItem.value, filterValue, isNonAccentSearch)));
        } else {
            setFilteredDataList(dataList);
        }
    }

    //onChange selected item
    function onChangeSelectedItem(changedValue) {
        const selectedObject = dataList?.find(dataRow => stringUtils.isSame(dataRow.key, changedValue));
        setSelectedItem(changedValue);

        if (rowData) {
            rowData[columnId] = changedValue;
        }

        if (customSelectId && rowData) {
            rowData[customSelectId] = selectedObject.key;
        }

        if (isFunction(onChangeSelected)) {
            onChangeSelected(changedValue, rowData, columnId, selectedObject);
            return;
        }

        if (isFunction(updateValue) && !ignoreUpdateValue) {
            updateValue(selectedObject);
        }
    }

    return (
        <SimpleSelect
            dataList={filteredDataList}
            selected={selectedItem}
            customSelected={customSelectId ? rowData[customSelectId] : null}
            onChange={onChangeSelectedItem}
            className={classes.simpleSelectCell}
            search={filterByValue}
            disabled={isDisabled}
            defaultSelectedItem={defaultSelectedItem}
            useAbsoluteMenu={useAbsoluteMenu}
        />
    );
}
