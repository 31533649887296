import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { stringUtils } from 'shared/src/modules';
import { useTranslation } from 'react-i18next';
import { languageUtils } from 'shared/src/modules/languageUtils';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        height: (props: any) => (props.size ? 40 : 36),
        opacity: (props: any) => props.disabled && 0.4,
        padding: 3,
        borderRadius: '4px',
        border: `1px solid ${theme.color.gray}`,
        display: 'inline-flex',
        alignItems: 'center',
        backgroundColor: theme.color.white,
    },
    selectedButton: {
        width: (props: any) => props.fixedSize && '100%',
        height: (props: any) => (props.size === 'large' ? 34 : 28),
        padding: (props: any) => !props.fixedSize && (props.size === 'large' ? '0px 38px' : '0px 13px'),
        fontSize: (props: any) => (props.size === 'large' ? 15 : 12),
        lineHeight: (props: any) => (props.size === 'large' ? '15px' : '12px'),
        borderRadius: '2px',
        fontWeight: 500,
        cursor: 'pointer',
        color: [theme.color.white, '!important'],
        fill: theme.color.white,
        boxShadow: theme.shadows.primary.main,
        border: theme.border.main,
        backgroundColor: [theme.color.main, '!important'],
        outline: 'none',
        display: 'block',
        ...theme.overFlowText,
    },
    button: {
        width: (props: any) => props.fixedSize && '100%',
        height: (props: any) => (props.size === 'large' ? 34 : 28),
        cursor: 'pointer',
        backgroundColor: theme.color.white,
        border: 'none',
        padding: (props: any) => !props.fixedSize && (props.size === 'large' ? '0px 40px' : '0px 15px'),
        fontSize: (props: any) => (props.size === 'large' ? 15 : 12),
        lineHeight: (props: any) => (props.size === 'large' ? '15px' : '12px'),
        fontWeight: '500',
        color: theme.color.raven,
        outline: 'none',
        display: 'block',
        ...theme.overFlowText,
    },
    separater: {
        height: 20,
        width: 1,
        display: 'inline-block',
        borderLeft: '1px solid',
        borderLeftColor: theme.color.gray,

    },
    transparent: {
        display: 'inline-block',
        width: 1,
        height: 20,
        borderLeft: '1px solid',
        borderLeftColor: theme.color.white,
    },
    separaterContainer: {
        width: (props: any) => props.fixedSize && '100%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dropDown: {
        width: (props: any) => props.fixedSize && '100%',
        height: (props: any) => (props.size === 'large' ? 34 : 28),
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: theme.color.white,
        border: 'none',
        padding: (props: any) => !props.fixedSize && (props.size === 'large' ? '0px 40px' : '0px 15px'),
        fontSize: (props: any) => (props.size === 'large' ? 15 : 12),
        fontWeight: '500',
        color: theme.color.raven,
        outline: 'none',
        '&:hover': {
            backgroundColor: theme.color.white,
            border: 'none',
        },
        '&:focus': {
            backgroundColor: theme.color.white,
            border: 'none',
        },
        '&:active': {
            color: `${theme.color.raven} !important`,
            backgroundColor: `${theme.color.white} !important`,
            border: 'none',
        },
    },
    selectedDropDown: {
        width: (props: any) => props.fixedSize && '100%',
        height: (props: any) => (props.size === 'large' ? 34 : 28),
        padding: (props: any) => !props.fixedSize && (props.size === 'large' ? '0px 38px' : '0px 13px'),
        fontSize: (props: any) => (props.size === 'large' ? 15 : 12),
        borderRadius: '2px',
        fontWeight: 500,
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: [theme.color.white, '!important'],
        fill: theme.color.white,
        boxShadow: theme.shadows.primary.main,
        border: theme.border.main,
        backgroundColor: [theme.color.main, '!important'],
        outline: 'none',
        '& svg': {
            fill: theme.color.white,
        },
        '&:hover': {
            border: `1px solid ${theme.color.main} !important`,
        },
        '&:focus': {
            border: `1px solid ${theme.color.main} !important`,
        },
        '&:active': {
            border: `1px solid ${theme.color.main} !important`,
        },
    },
}));

export default function SegmentToogle(props) {
    const { items, selected, onChange, size, className, fixedSize, disabled } = props;
    const { t } = useTranslation();
    const classes = useStyles({ size, fixedSize, disabled } as any);

    function select(selectedItem) {
        if (items.length === 2) {
            const otherItem = items.find(toggleItem => !stringUtils.isSame(toggleItem.key, selectedItem.key));

            if (!stringUtils.isSame(otherItem.key, selected)) {
                onChange(otherItem.key);
                return;
            }
        }
        if (selectedItem.onClick) {
            selectedItem.onClick();
        }
        onChange(selectedItem.key);
    }

    function getDropdownStyle(key) {
        return stringUtils.isSame(selected, key) ? classes.selectedDropDown : classes.dropDown;
    }

    function getStyle(key) {
        return stringUtils.isSame(selected, key) ? classes.selectedButton : classes.button;
    }

    function hasItemSeparator(selectedItem) {
        return !(selectedItem === items[items.length - 1] || stringUtils.isSame(selectedItem.key, selected) || stringUtils.isSame(items[items.indexOf(selectedItem) + 1].key, selected));
    }

    if (!items) {
        return null;
    }

    return (
        <div className={clsx(classes.container, className, 'container')}>
            {items.map(toggleItem => (
                <div key={toggleItem.key} className={clsx(classes.separaterContainer, 'separaterContainer')}>
                    {toggleItem.component
                        ? (
                            <toggleItem.component onClick={() => select(toggleItem)} onSubmit={toggleItem.onSubmit} {...toggleItem.componentProps} style={getDropdownStyle(toggleItem.key)}>
                                {toggleItem.value}
                            </toggleItem.component>
                        )
                        : (
                            <button onClick={() => select(toggleItem)} disabled={disabled} type="button" title={languageUtils.getTitle(t, toggleItem.value)} className={clsx(getStyle(toggleItem.key), 'segmentButton')}>
                                {toggleItem.value}
                            </button>
                        )}
                    <div className={hasItemSeparator(toggleItem) ? classes.separater : classes.transparent} />
                </div>
            ))}
        </div>
    );
}
