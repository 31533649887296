import React from 'react';
import { isEmpty } from 'lodash';

import { arrayUtils } from 'shared/src/modules/arrayUtils';
import ListRowTemplate from './ListRowTemplate';
import TableBodyCell from '../body/TableBodyCell';

export default function ListDataRow(props) {
    const { rowData, leftFixedColumns, mainColumns, rightFixedColumns, handleMidScroll, classes } = props;
    const { columnWidths, leftSideWidth, rightSideWidth, isDataCard, onSelectRow, tableFunctions, rowStyle } = props;
    const { isBasePageTable, tableKey } = props; //simple table

    return (
        <ListRowTemplate
            classes={classes}
            rowStyle={rowStyle}
            rowData={rowData}
            leftSideWidth={leftSideWidth}
            handleMidScroll={handleMidScroll}
            rightSideWidth={rightSideWidth}
            isDataCard={isDataCard}
            onSelectRow={onSelectRow}
            isBasePageTable={isBasePageTable}
            tableKey={tableKey}
            left={
                !isEmpty(leftFixedColumns) && leftFixedColumns.map((lfColumn, idx) => (
                    <TableBodyCell
                        key={idx}
                        column={lfColumn}
                        rowData={rowData}
                        cellValue={arrayUtils.getArrayItemByKeyString(rowData, lfColumn.columnId)}
                        tableFunctions={tableFunctions}
                        isBasePageTable={isBasePageTable}
                    />
                ))
            }
            middle={
                !isEmpty(mainColumns) && mainColumns.map((mnColumn, idx) => (
                    <TableBodyCell
                        key={idx}
                        column={mnColumn}
                        rowData={rowData}
                        cellValue={arrayUtils.getArrayItemByKeyString(rowData, mnColumn.columnId)}
                        cellWidth={!isEmpty(columnWidths) ? columnWidths[idx] : null}
                        tableFunctions={tableFunctions}
                        isBasePageTable={isBasePageTable}
                    />
                ))
            }
            right={
                !isEmpty(rightFixedColumns) && rightFixedColumns.map((rColumn, idx) => (
                    <TableBodyCell
                        key={idx}
                        column={rColumn}
                        rowData={rowData}
                        cellValue={arrayUtils.getArrayItemByKeyString(rowData, rColumn.columnId)}
                        tableFunctions={tableFunctions}
                        isBasePageTable={isBasePageTable}
                        isRightFixed
                    />
                ))
            }
        />
    );
}
