/* eslint-disable id-match */
/* eslint-disable id-blacklist */
import { useEffect, useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles, useTheme } from 'react-jss';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { companyActions, basePageActions } from '@states/actions';
import { arrayUtils } from 'shared/src/modules';

import Checkbox from '../controls/Checkbox';
import Svg from '../Svg';

const useStyles = createUseStyles((theme: any) => ({
    dropdown: {
        fontSize: '15px',
        fontWeight: 'normal',
        lineHeight: '1.33',
        color: `${theme.color.jet} !important`,
    },
    dropdownToggle: {
        width: 300,
        height: 40,
        padding: 0,
        paddingLeft: 15,
        paddingTop: 4,
        textAlign: 'left',
        borderRadius: '4px',
        boxShadow: '0 2px 3px 0 rgba(175, 180, 182, 0.15)',
        border: `1px solid ${theme.color.gray} !important`,
        color: `${theme.color.jet} !important`,
        backgroundColor: `${theme.color.white} !important`,
        '&:focus': {
            border: `1px solid ${theme.color.main} !important`,
        },
        '& span': {
            width: 250,
            display: 'inline-block',
            ...theme.overFlowText,
        },
    },
    dropdownMenu: {
        width: 300,
        height: 'auto',
        maxHeight: 300,
        borderRadius: '4px',
        boxShadow: '0 5px 15px 1px rgba(121, 130, 134, 0.1), 0 10px 10px 1px rgba(69, 79, 84, 0.15), 0 5px 5px -2px rgba(69, 79, 84, 0.2)',
        border: ' 1px solid rgba(227, 230, 232, 0.5)',
        outline: 'none !important',
    },
    dropdownMenuFix: {
        paddingTop: '0px !important',
        width: 300,
        height: 'auto',
        maxHeight: 300,
    },
    dropdownMenuContent: {
        display: 'block !important',
        paddingTop: '0px',
        height: 'auto',
        width: '100%',
        overflowY: 'auto',
        maxHeight: 270,
    },
    dropdownItem: {
        display: 'block',
    },
    dropDownHr: {
        marginTop: 5,
        marginBottom: 5,
    },
    dropDownMenuControlLine: {
        borderBottom: '1px solid',
        borderBottomColor: theme.color.lilian,
        marginTop: -5,
        marginLeft: -1,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        color: theme.color.jet,
        padding: 2,
        lineHeight: 1.5,
        marginBottom: 3,
        width: 300,
        height: 25,
    },
    dropDownMenuControlLineCtrlBtn: {
        background: 'transparent',
        border: 'none',
        color: theme.color.black,
        fontSize: 12,
        '&:hover': {
            color: theme.color.main,
            textDecoration: 'underline',
        },
    },
    dropDownMenuControlLineCloseBtn: {
        cursor: 'pointer',
        fontSize: 20,
        float: 'right',
        lineHeight: 1,
        opacity: 0.5,
        textShadow: `0 1px 0 ${theme.color.white}`,
        background: 'transparent',
        border: 'none',
        fontWeight: 'bold',
        color: theme.color.stone,
        marginTop: '-3px',
    },
    dropDownItemcheckboxClass: {
        width: '100%',
        textAlign: 'left',
        '&:hover': {
            background: `${theme.color.main} !important`,
        },
    },
    icon: {
        height: 20,
        width: 20,
        float: 'right',
        marginRight: 10,
        marginTop: -2,
    },
    items: {
        padding: 5,
        '&:hover': {
            background: theme.color.main,
            color: theme.color.main,
        },
    },
}));

//TODO: php - temp company multiselect (php use this)
export default function CompanyMultiselect(props) {
    const { multiselectId } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [list, setList] = useState<any>([]);
    const [dropdownTitle, setDropdownTitle] = useState('');
    const companyList = useSelector((state: any) => state.company.company.companyList);
    const classes = useStyles({ theme });

    function checkListCheckedItemCount(companies, showedList, translater) {
        const tempList = showedList.length === 0 ? companies : showedList;
        const checkedItems = tempList.filter(item => item.checked === true);

        if (checkedItems.length === tempList.length) {
            setDropdownTitle(translater('default.consolidateView', 'Consolidated view'));
        } else if (checkedItems.length === 0) {
            setDropdownTitle(translater('default.noCompanySelected', 'No company selected'));
        } else {
            setDropdownTitle(`${checkedItems.length} ${translater('default.selectCount', 'Select company')}`);
        }

        return checkedItems.length;
    }

    useEffect(() => {
        if (companyList && dispatch) {
            companyList?.forEach(company => {
                company.checked = true;
            });
            arrayUtils.sortByParamAsc(companyList, 'name');
            setList(companyList);
            const checkedCompanies = companyList?.filter(company => company.checked);
            dispatch(companyActions.setActiveCompanyList(checkedCompanies));
        }
    }, [companyList, dispatch, t]);

    useEffect(() => {
        if (list && t) {
            checkListCheckedItemCount(companyList, list, t);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list, t, dispatch]);

    function onChange(itemId) {
        const selectedItem = list.filter(item => item.id === itemId);
        selectedItem[0].checked = !selectedItem[0].checked;
        checkListCheckedItemCount(companyList, list, t);
    }

    function setListCheckedstate(state) {
        const modifiedArr = list.map(item => {
            item.checked = state;
            return item;
        });
        setList(modifiedArr);
        checkListCheckedItemCount(companyList, list, t);
    }

    const toggle = () => {
        if (dropdownOpen) {
            const checkedCompanies = list?.filter(company => company.checked);
            dispatch(companyActions.setActiveCompanyList(checkedCompanies));
            dispatch(basePageActions.setReloadTable(true));
        }
        setDropdownOpen(prevState => !prevState);
    };

    function getActive() {
        const checkedStatusList = list.map(item => { if (item.checked) { return item.id; } return null; });
        return checkedStatusList;
    }

    (window as any).reloadCompanyList = () => {
        dispatch(companyActions.reloadCompanyList(location));
    };

    return (
        <Dropdown id={multiselectId} isOpen={dropdownOpen} toggle={toggle} datalist={getActive()} php_check_open={dropdownOpen.toString()}>
            {/*TODO: php - datalist and php_check_open variables are php functions... */}
            <DropdownToggle className={classes.dropdownToggle} caret>
                <span>{dropdownTitle}</span>
                {
                    dropdownOpen ? (
                        <Svg iconId="icon-arrow_drop_up" style={classes.icon} />
                    ) : (
                        <Svg iconId="icon-arrow_drop_down" style={classes.icon} />
                    )}
            </DropdownToggle>
            <DropdownMenu className={classes.dropdownMenu}>
                <div className={classes.dropdownMenuFix}>
                    <div className={classes.dropDownMenuControlLine}>
                        <button className={classes.dropDownMenuControlLineCtrlBtn} type="button" onClick={() => setListCheckedstate(true)}>{t('default.all', 'All')}</button>
                        <button className={classes.dropDownMenuControlLineCtrlBtn} type="button" onClick={() => setListCheckedstate(false)}>{t('default.nothing', 'Nothing')}</button>
                    </div>
                    <div className={classes.dropdownMenuContent}>
                        {
                            list.map((item, index) => {
                                //child items start
                                if ((list[index - 1] && list[index - 1].isChild === false) && item.isChild === true) {
                                    return (
                                        <div key={`option_${item.id}`} className={classes.items}>
                                            <hr className={classes.dropDownHr} />
                                            <Checkbox text={item.name} size="small" onChange={() => onChange(item.id)} checked={item.checked} ignoreFirstLetterFormatter />
                                        </div>
                                    );
                                }
                                //child items end
                                if ((list[index - 1] && list[index - 1].isChild === true) && item.isChild === false) {
                                    return (
                                        <div key={`option_${item.id}`} className={classes.items}>
                                            <hr className={classes.dropDownHr} />
                                            <Checkbox text={item.name} size="small" onChange={() => onChange(item.id)} checked={item.checked} ignoreFirstLetterFormatter />
                                        </div>
                                    );
                                }
                                //child item
                                if ((list[index - 1] && list[index - 1].isChild === true) && item.isChild === true) {
                                    return (
                                        <div key={`option_${item.id}`} className={classes.items}>
                                            <Checkbox text={item.name} size="small" onChange={() => onChange(item.id)} checked={item.checked} ignoreFirstLetterFormatter />
                                        </div>
                                    );
                                }
                                //main item
                                return (
                                    <div key={`option_${item.id}`} className={classes.items}>
                                        <Checkbox text={item.name} size="small" onChange={() => onChange(item.id)} checked={item.checked} ignoreFirstLetterFormatter />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </DropdownMenu>
        </Dropdown>
    );
}
