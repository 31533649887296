import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PARTNER, CROP } from 'shared/src/constants';
import { cultivationPeriodsService } from '@services';
import { languageUtils } from 'shared/src/modules';
import { errorActions } from '@states/actions';

export default function useLoadEditCultivationPeriodValues(props) {
    const { periodYearId, cultivationPeriodId, setLoadPeriodValues, setTitle, setFormInitialValues } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const [cultivationPeriodValues, setCultivationPeriodValues] = useState();
    const { TYPE } = CROP;
    useEffect(() => {
        let isCancelled = false;
        async function loadCultivationPeriodData() {
            try {
                if (cultivationPeriodId) {
                    const rawCultivationPeriodData = await cultivationPeriodsService.getCultivationPeriodById(
                        periodYearId,
                        cultivationPeriodId,
                    );
                    const {
                        id, isPlantation, cropTypeId, cropType, cultivationPeriodsCompany, name, startDate, endDate, periodYear, region, sownArea, culture,
                        varietyId, cultureVariety, reproductionDegreeId, plantationTypeId, plantingYear, lineCount, lineLength, lineSpacing, plantDistance, comment, comment2, comment3,
                        workoperationNumber, cultivationPeriodFarm, fieldFeatures, qualification, previousPeriods, manualPrevCultureId, inPeriodGroup, isActive,
                        bankId, insurerId, damageFileNumber, insuranceCountyNumber, locationId, lpisFieldsNebihFinalized
                    } = rawCultivationPeriodData || {};
                    let currentCropTypeId;
                    let currentCropTypeName;

                    if (isPlantation) {
                        currentCropTypeId = TYPE.PLANTATION;
                        currentCropTypeName = t('cropData.plantation', 'Plantation');
                    } else {
                        currentCropTypeId = cropTypeId;
                        currentCropTypeName = languageUtils.getLangCaptionFromObject(i18n.language, cropType);
                    }

                    const ownerType = cultivationPeriodsCompany.partner.typeId === PARTNER.TYPES.COMPANY || cultivationPeriodsCompany.partner.typeId === PARTNER.TYPES.DISCTRICT ? PARTNER.TYPES.COMPANY : PARTNER.TYPES.PARTNER;
                    const periodValues = {
                        id,
                        periodYear: periodYear.period,
                        ownerType,
                        owner: cultivationPeriodsCompany.partnerId,
                        name,
                        startDate,
                        endDate: endDate || '',
                        region: region || '',
                        sownArea: Number(sownArea),
                        cropType: currentCropTypeId,
                        crop: {
                            type: currentCropTypeId,
                            typeName: currentCropTypeName,
                            //crop
                            culture: culture ? culture.id : '',
                            cultureName: culture ? culture.name : '',
                            variety: varietyId || '',
                            varietyName: (cultureVariety && cultureVariety.name) || '',
                            reproductionRate: reproductionDegreeId || '',
                            reproductionRateName: '',
                            //plantation
                            plantationType: plantationTypeId || '',
                            year: plantingYear || '',
                            rowNumber: lineCount || '',
                            lineLength: lineLength || '',
                            lineSpacing: lineSpacing || '',
                            plantDistance: plantDistance || '',
                            inPeriodGroup,
                        },
                        support: '',
                        registryNumberList: '',
                        comment: comment || '',
                        comment2: comment2 || '',
                        comment3: comment3 || '',
                        farmId: cultivationPeriodFarm?.farmId,
                        basicFields: '',
                        editFieldFeatures: fieldFeatures,
                        qualification,
                        workoperationNumber,
                        previousPeriods,
                        manualPrevCultureId,
                        isActive,
                        bankId: bankId || '',
                        insurerId: insurerId || '',
                        insuranceCountyNumber: insuranceCountyNumber || '',
                        damageFileNumber: damageFileNumber || '',
                        locationId: locationId || '',
                        lpisFieldsNebihFinalized,
                    };

                    if (!isCancelled) {
                        setFormInitialValues(periodValues);
                        setCultivationPeriodValues(periodValues);
                        setTitle(name);
                    }
                }

                if (!isCancelled) {
                    setLoadPeriodValues(false);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (dispatch) {
            loadCultivationPeriodData();
        }

        return () => {
            isCancelled = true;
        };
    }, [TYPE.PLANTATION, dispatch, location, setLoadPeriodValues, t, periodYearId, cultivationPeriodId, setTitle, i18n.language, setFormInitialValues]);

    return {
        cultivationPeriodValues,
        setCultivationPeriodValues,
    };
}
