import { createUseStyles } from 'react-jss';
import { Form, Modal } from 'react-bootstrap';
import { isFunction } from 'lodash';
import clsx from 'clsx';
import Button from '@baseComponents/buttons/Button';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    modal: {
        minWidth: 500,
        '& .modal-content': {
            width: '500px',
            margin: 'auto',
        },
        '& .modal-header': {
            display: 'block',
            textAlign: 'center',
        },
        '& .modal-body': {
            display: 'block',
            textAlign: 'center',
        },
        '& .close': {
            top: 0,
        },
        '& .modal-dialog.modal-xl.modal-dialog-centered': {
            left: 0,
        },
    },
    iconProp: {
        height: 80,
        width: 80,
        backgroundColor: (props: any) => {
            switch (props.buttonType) {
                case 'destructive':
                    return theme.color.destructive;
                case 'primary':
                    return theme.color.main;
                default:
                    return theme.color.secondary;
            }
        },
        fill: (props: any) => {
            switch (props.buttonType) {
                case 'secondary':
                    return theme.color.black;
                default:
                    return theme.color.white;
            }
        },
        borderRadius: 50,
        padding: 15,
    },
    formRow: {
        display: 'block',
        textAlign: 'center',
    },
    questionText: {
        fontSize: '18px !important',
        fontWeight: 700,
        fontFamily: 'Rubik',
    },
    descriptionText: {
        color: theme.color.raven,
        fontSize: '14px !important',
        fontFamily: 'Rubik',
    },
    descriptionHtml: {
        color: theme.color.raven,
        fontSize: '14px !important',
        fontFamily: 'Rubik',
        maxHeight: 250,
        overflow: 'auto',
        scrollbarWidth: 'thin !important',
    },
    close: {
        height: 40,
        width: 40,
        borderRadius: 50,
        padding: 10,
        backgroundColor: theme.color.pearl,
        position: 'absolute',
        top: 15,
        right: 15,
    },
    closeButton: {
        border: 'none',
        padding: 0,
    },
}));

export default function BaseConfirmationModal(props) {
    const { buttonType, buttonText, questionText, descriptionText, iconId, handleConfirmClicked, hideModal, className, isHideModalAfterConfirm = true, onClickCancel, hideAfterCancel = false } = props;
    const { isAcceptCancelDialog, acceptButtonText, cancelButtonText, isHtmlDescription = false } = props;
    const classes = useStyles({ buttonType } as any);

    function close() {
        if (isFunction(hideModal)) {
            hideModal();
        }
    }

    function onConfirm() {
        if (isFunction(handleConfirmClicked)) {
            handleConfirmClicked();
        }
        if (isFunction(hideModal) && isHideModalAfterConfirm) {
            hideModal();
        }
    }

    function onCancel() {
        const functionToCall = onClickCancel || hideModal;
        functionToCall();

        if (hideAfterCancel) {
            hideModal();
        }
    }

    return (
        <div className={clsx(classes.modal, className)}>
            <Modal.Header>
                {iconId
                    && <Svg iconId={iconId} style={classes.iconProp} />
                }
                <button type="button" onClick={isFunction(onClickCancel) ? onCancel : close} className={classes.closeButton}>
                    <Svg iconId="icon-close" style={classes.close} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {questionText
                        && (
                            <Form.Row className={classes.formRow}>
                                <h2 className={classes.questionText}>{questionText}</h2>
                            </Form.Row>
                        )
                    }
                    {descriptionText
                        && (
                            <Form.Row className={classes.formRow}>
                                {!isHtmlDescription
                                    ? (
                                        <h4 className={classes.descriptionText}>{descriptionText}</h4>
                                    ) : (
                                        <div className={classes.descriptionHtml}>{descriptionText}</div>
                                    )
                                }
                            </Form.Row>
                        )
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {isAcceptCancelDialog
                    ? (
                        <>
                            <Button type={buttonType} onClick={onConfirm}>{acceptButtonText}</Button>
                            <Button type="secondary" onClick={onCancel}>{cancelButtonText}</Button>
                        </>
                    ) : (
                        <Button type={buttonType} onClick={onConfirm}>{buttonText}</Button>
                    )
                }
            </Modal.Footer>
        </div>
    );
}
