import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isFunction } from 'lodash';

import TableAggregateTitle from '../aggregate/TableAggregateTitle';
import TableAggregateCell from '../aggregate/TableAggregateCell';
import CustomAggragateCell from '../aggregate/CustomAggragateCell';

const useStyle = createUseStyles({
    footerCell: {
        textAlign: 'left',
        display: 'flex',
        overflow: 'hidden',
        padding: 0,
        width: props => props.width || '100%',
        minWidth: props => props.minWidth,
        maxWidth: props => props.maxWidth,
        height: '100%',
        verticalAlign: 'top',
        paddingLeft: 10,
        alignItems: 'center',
    },
});

export default function FooterRowCell(props) {
    const { cellWidth, filteredTableData, column, aggregateType } = props;
    const { aggregateType: hasColumnAggregate, customAggregateFunction, width, minWidth, maxWidth, columnId } = column;
    const classes = useStyle({ width: (cellWidth || width), minWidth: cellWidth || minWidth, maxWidth: cellWidth || maxWidth });

    return (
        <div className={clsx(classes.footerCell, 'footerCell', `footerCell_${columnId}`)}>
            {column.isTitle
                && <TableAggregateTitle aggregateType={aggregateType} />
            }
            {hasColumnAggregate && !column.isTitle
                && <TableAggregateCell tableData={filteredTableData} column={column} />
            }
            {isFunction(customAggregateFunction) && (
                <CustomAggragateCell tableData={filteredTableData} column={column} />
            )}
        </div>
    );
}
