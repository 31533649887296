import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { errorActions } from '@states/actions';

export default function useSoilAnalysisViewDataLoader(props) {
    const { cultivationPeriodValues } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //functions
    const [cultivationPeriodData, setCultivationPeriodData] = useState();
    //data lists
    const [supportList, setSupportList] = useState();
    const [registryNumberList, setRegistryNumberList] = useState();
    const [reproductionRateName, setReproductionRateName] = useState();
    const [basicFieldsList, setBasicFieldsList] = useState();

    useEffect(() => {
        let isCancelled = false;

        async function loadData(getDataFunction, setterFunction) {
            const dataList = await getDataFunction();

            if (!isCancelled) {
                setterFunction(dataList);
            }
        }

        async function getRegistryNumberList(getDataFunction, setterFunction) {
            let registryNumbers = await getDataFunction();
            if (!isEmpty(registryNumbers)) {
                registryNumbers = registryNumbers.map(regNumObj => ({ ...regNumObj, name: regNumObj.registryNumber.name }));
            }

            if (!isCancelled) {
                setterFunction(registryNumbers);
            }
        }

        function getcropTypeName() {
            return cultivationPeriodValues.cultivationPeriodData?.isPlantation
                ? t('cropData.plantation', 'Plantation')
                : cultivationPeriodValues.cultivationPeriodData.cropTypeName;
        }

        if (!isEmpty(cultivationPeriodValues)) {
            try {
                setCultivationPeriodData({
                    ...cultivationPeriodValues.cultivationPeriodData,
                    varietyName: cultivationPeriodValues.cultureVarietyName,
                    cropTypeName: getcropTypeName(),
                });
                loadData(cultivationPeriodValues.getReproductionRateName, setReproductionRateName);
                loadData(cultivationPeriodValues.getSupportList, setSupportList);
                loadData(cultivationPeriodValues.getBasicFields, setBasicFieldsList);
                getRegistryNumberList(cultivationPeriodValues.getRegistryNumberList, setRegistryNumberList);
            } catch (error) {
                dispatch(errorActions.setError(error, 'LoadPeriodViewData'));
            }

            return () => {
                isCancelled = true;
            };
        }
    }, [cultivationPeriodValues, t, dispatch]);

    return {
        cultivationPeriodData,
        reproductionRateName,
        supportList,
        basicFieldsList,
        registryNumberList,
    };
}
