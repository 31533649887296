import React from 'react';
import { createUseStyles } from 'react-jss';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { isEmpty, isFunction } from 'lodash';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { dateTimeUtils } from 'shared/src/modules';
import Button from '@baseComponents/buttons/Button';

const useStyles = createUseStyles((theme: any) => ({
    daysWithDataButtonWrapper: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
    },
    daysWithDataButton: {
        width: '100%',
        '& div': {
            padding: 0,
        },
        '& svg': {
            marginRight: 0,
            fill: theme.color.gray,
        },
    },
    popover: {
        padding: '15px 15px 20px',
        border: 'none',
        borderRadius: 6,
        backgroundColor: theme.color.white,
        zIndex: theme.zIndex.popover,
        boxShadow: theme.shadows.dropdown,
        '& .popover-body': {
            display: 'grid',
            gridTemplateColumns: 'repeat( auto-fit, minmax(40%, 1fr) )',
            gridGap: 10,
            minWidth: 200,
        },
        '& .arrow::before': {
            borderBottomColor: theme.color.white,
            borderWidth: 8,
            top: -20,
            left: -15,
        },
    },
}));

export default function DaysWithDataButtonCell(props) {
    const { value, rowData, getCellTitle, getIcon, icon, closeModal } = props;
    const { startDate, endDate } = useSelector((state: any) => state?.tableFilter?.filters?.dateRange) || {};
    const history = useHistory();
    const classes = useStyles();

    function ignoreRowSelection(event) {
        document.body.click();
        event.stopPropagation();
    }

    function onClickButton(dayData) {
        if (rowData.id || rowData.workOperationId) {
            const selectedId = rowData.id || rowData.workOperationId; // id in workoperationList and workOperationId in workoperationView
            if (closeModal) {
                closeModal();
            }
            //TODO: change php function
            history.push(`fixAuxiliaryOperation?munkamuvelet_id=${selectedId}&datum=${dayData}&datum_tol=${dateTimeUtils.getDefaultDateFormat(startDate)}&datum_ig=${dateTimeUtils.getDefaultDateFormat(endDate)}&${dateTimeUtils.getTimestampNow()}`);
        }
    }

    if (!isFunction(getIcon) && isEmpty(icon)) {
        return null;
    }

    return (
        <div className={classes.daysWithDataButtonWrapper} onClick={ignoreRowSelection} onKeyDown={ignoreRowSelection} role="button" tabIndex="0">
            <OverlayTrigger
                trigger="click"
                placement="bottom"
                rootClose
                overlay={
                    <Popover className={classes.popover}>
                        <Popover.Content>
                            {!isEmpty(value)
                        && value.map((dayData, index) => (
                            <Button
                                key={index}
                                onClick={() => onClickButton(dayData)}
                                fill="150"
                                size="small"
                                type="secondary"
                            >
                                {dateTimeUtils.getDate(dayData)}
                            </Button>
                        ))
                            }
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button
                    className={classes.daysWithDataButton}
                    size="small"
                    type="secondary"
                    iconId={isFunction(getIcon) ? getIcon(rowData) : icon}
                    iconPosition="before"
                    title={isFunction(getCellTitle) ? getCellTitle(rowData) : null}
                    disabled={isEmpty(value)}
                />
            </OverlayTrigger>
        </div>
    );
}
