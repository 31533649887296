import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isEmpty, isFunction } from 'lodash';
import useTranslate from '@i18n/useTranslate';

import { NoDataCard } from '@baseComponents/tableNew';
import SearchBar from '@baseComponents/inputs/SearchBar';
import CloseButton from '@baseComponents/buttons/CloseButton';
import TextButton from '@baseComponents/buttons/TextButton';
import Button from '@baseComponents/buttons/Button';
import { LoadSpinner } from '@baseComponents/loadSpinner';

import SelectListContent from './SelectListContent';
import useSelectListPanel from './useSelectListPanel';

const useStyles = createUseStyles((theme: any) => ({
    panelWrapper: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
    },
    fade: {
        backgroundColor: `rgba(${theme.rgbColor.jet}, 0.8)`,
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        cursor: 'context-menu',
        zIndex: theme.zIndex.fade,
    },
    selectListWrapper: {
        display: 'flex',
        flexFlow: 'column',
        height: '93%',
        width: 360,
        minHeight: 600,
        borderRadius: 10,
        boxShadow: theme.shadows.selectDropdown,
        position: 'absolute',
        top: 25,
        left: 40,
        backgroundColor: theme.color.white,
        zIndex: theme.zIndex.modal,
        border: `1px solid ${theme.color.white}`,
    },
    selectListHeader: {
        padding: [10, 15],
    },
    createNewButton: {
        marginTop: 10,
    },
    selectListContent: {
        height: '100%',
        backgroundColor: theme.color.lilian,
        border: '1px solid',
        borderColor: theme.color.pearl,
        borderRight: 'none',
        borderLeft: 'none',
        borderBottomLeftRadius: 6,
        borderBottomRightRadius: 6,
        overflow: 'auto',
        ...theme.verticalScrollbar,
        paddingTop: 10,
        paddingBottom: 10,
    },
    selectListFooter: {
        height: 50,
        backgroundColor: theme.color.white,
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
    },
    title: {
        color: theme.color.jet,
        fontWeight: 500,
        ...theme.overFlowText,
    },
    searchBar: {
        marginTop: 10,
    },
}));

export default function SelectListPanel(props) {
    const { onClose, title, hasSearchBar, dataList, onSelect, isNonAccentSearch, className, onClickNewButton, hasLoadSpinner } = props;
    const { t, translater } = useTranslate();
    const classes = useStyles();

    const { filteredDataList, onSearch } = useSelectListPanel({ dataList, isNonAccentSearch });

    return (
        <div className={classes.panelWrapper}>
            <div className={classes.fade} />
            <div className={clsx(classes.selectListWrapper, className)}>
                {hasLoadSpinner && <LoadSpinner value={dataList} isAutoDetect />}
                <div className={clsx(classes.selectListHeader, 'selectListHeader')}>
                    {!!title && <div className={classes.title}>{title}</div>}
                    {hasSearchBar && (
                        <SearchBar
                            className={classes.searchBar}
                            onChange={onSearch}
                        />
                    )}
                    {isFunction(onClickNewButton) && (
                        <Button
                            className={classes.createNewButton}
                            onClick={onClickNewButton}
                            size="small"
                            iconId="icon-add"
                            iconPosition="before"
                            title={translater('default.createNew', 'Create new')}
                        >
                            {t('default.createNew', 'Create new')}
                        </Button>
                    )}
                    <CloseButton onClick={() => onClose(false)} />
                </div>
                <div className={clsx(classes.selectListContent, 'selectListContent')}>
                    {!isEmpty(filteredDataList)
                        ? <SelectListContent dataList={filteredDataList} onSelect={onSelect} onClose={() => onClose(false)} />
                        : <NoDataCard />
                    }
                </div>
                <div className={clsx(classes.selectListFooter, 'selectListFooter')}>
                    <TextButton onClick={() => onClose(false)}>
                        {t('default.cancel', 'Cancel')}
                    </TextButton>
                </div>
            </div>
        </div>
    );
}
