import { createUseStyles } from 'react-jss';
import Button from '@baseComponents/buttons/Button';
import { useEffect, useMemo, useRef, useState } from 'react';
import { LayerAttribute, ThemeType, TranslateType } from '@map/services/mapTypes';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import useTranslate from '@i18n/useTranslate';
import Svg from '@baseComponents/Svg';
import clsx from 'clsx';
import LayerRow from './LayerControlPanelLayerRow';
import useLayerPanelStyles from './styles';
import { isNil } from 'lodash';

type Classes = 'container' | 'buttonWrapper' | 'layerSelectionContainer' | 'headerWrapper' | 'closePanelButton' | 'panelWrapper' | 'panelHidden';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: [10, 0],
        // gap: 5,
    },
    layerSelectionContainer: {
        maxHeight: '32vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 7,
            position: 'absolute',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            borderRadius: 7,
            background: 'rgba(0, 0, 0, 0.4)',
            position: 'absolute',
        },
        scrollbarWidth: 'thin !important',
    },
    buttonWrapper: {
        display: 'flex',
        marginTop: 10,
        padding: [0, 15],
        // padding: '0 15px 15px',
    },
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 10,
        justifyContent: 'space-between',
    },
    closePanelButton: {
        width: 24,
        height: 24,
        cursor: 'pointer',
    },
    panelWrapper: {
        display: 'flex',
        flexFlow: 'column',
        transition: '0.25s ease all',
        maxHeight: 400,
        overflowY: 'hidden',
    },
    panelHidden: {
        maxHeight: 0,
        transition: '0.25s ease all',
    },
}));

type TProps = {
    layerAttributes: LayerAttribute[],
    isLayerSelectOpen: boolean;
    setIsAddPanelOpen: (status: boolean) => void;
    isDefaultLayerTypeMandatory: boolean;
    layerType: BuiltInLayerTypes;
    isMeasuring: boolean;
    withSelector?: boolean;
};

export default function ActiveLayerAttributesSection({
    layerAttributes,
    setIsAddPanelOpen,
    isLayerSelectOpen,
    isMeasuring,
    isDefaultLayerTypeMandatory,
    layerType,
    withSelector,
}: TProps) {
    const { t, translater }: TranslateType = useTranslate();

    const [store, controller] = useMapContext();
    const classes = useStyles();
    const commonClasses = useLayerPanelStyles();
    const [stateAttributeOrder, setStateAttributeOrder] = useState<number[]>([]);

    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(true);

    const draggingItem = useRef<number | null>(null);
    const dragOverItem = useRef<number | null>(null);

    useEffect(() => {
        setStateAttributeOrder([...store.layering.order]); controller.recalculateGlobalLabeling();
    }, [controller, store.layering.order]);

    const sortedLayerAttributes = useMemo(() => layerAttributes.sort((a, b) => {
        if (stateAttributeOrder.indexOf(a.id) === -1) {
            return 1;
        }
        if (stateAttributeOrder.indexOf(b.id) === -1) {
            return -1;
        }
        return stateAttributeOrder.indexOf(a.id) - stateAttributeOrder.indexOf(b.id);
    }), [layerAttributes, stateAttributeOrder]);

    const handleDragStart = (position: number) => {
        draggingItem.current = position;
    };
    const handleDragEnter = (position: number) => {
        dragOverItem.current = position;
        const layerOrderCopy = [...stateAttributeOrder];
        const fromIdx = layerOrderCopy.indexOf(draggingItem.current as number);
        const toIdx = layerOrderCopy.indexOf(dragOverItem.current);
        [layerOrderCopy[fromIdx], layerOrderCopy[toIdx]] = [layerOrderCopy[toIdx], layerOrderCopy[fromIdx]];

        setStateAttributeOrder(layerOrderCopy);
    };

    const handleDragEnd = async () => {
        await controller.setLayerAttributeOrder(stateAttributeOrder);
        dragOverItem.current = null;
        draggingItem.current = null;
    };

    const handleOnPanelClose = () => {
        setIsPanelOpen(p => !p);

        if (isPanelOpen) {
            setIsAddPanelOpen(false);
        }
    };

    return (
        <>
            <div className={classes.container}>
                <div className={classes.headerWrapper}>
                    <span className={commonClasses.itemTitle} style={{ paddingLeft: 10 }}>{t('map.layersText', 'Layers')}</span>

                    <Svg style={classes.closePanelButton} iconId={`icon-arrow_drop_${isPanelOpen ? 'up' : 'down'}`} onClick={handleOnPanelClose} />
                </div>

                <div className={clsx(classes.panelWrapper, !isPanelOpen && classes.panelHidden)}>
                    <div className={classes.layerSelectionContainer}>
                        {sortedLayerAttributes?.map(attribute => (
                            <LayerRow
                                key={attribute.id}
                                isFirstLayer={store.layering.order[0] === attribute.id}
                                layerAttribute={attribute}
                                onDragStart={() => handleDragStart(attribute.id)}
                                onDragEnter={() => handleDragEnter(attribute.id)}
                                onDragEnd={handleDragEnd}
                                isRemoveDisabled={withSelector || (attribute.isDefault && isDefaultLayerTypeMandatory && attribute.layerName === layerType)}
                            />
                        ))}
                    </div>
                    <div className={classes.buttonWrapper}>
                        <Button
                            type="secondary"
                            iconId="icon-add"
                            title={t('map.addLayerText', 'Add layer')}
                            iconPosition="before"
                            disabled={withSelector || isLayerSelectOpen || isMeasuring}
                            className={commonClasses.button}
                            onClick={() => setIsAddPanelOpen(true)}
                        >{t('map.addLayerText', 'Add layer')}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
