import { sendServerRequest } from './baseService';

export const masterDataService = {
    updateVersion,
};

async function updateVersion(props) {
    const response = await sendServerRequest('POST', '/api/masterdata/updateVersion', props);
    return response;
}
