export const SYSTEM = {
    MENU_DEFAULT_PAGE: 'home',
    DEFAULT: 1, //system id
    SYSTEM_SUBTYPE: {
        NONE: 0,
        SAVINGS_BANK: 1,
    },
    SYSTEM_MODULE_TYPE: {
        CROP_PRODUCTION: 1, //növénytermesztés
        VINERY_VINEYARD: 2, //borászat, szőlészet
        CATTLE_FARMING: 3, //szarvasmarha tenyésztés
        PIG_FARM: 4, //sertés tenyésztés
        RABBIT_FARM: 5, //nyúl tenyésztés
        POULTRY_FARM: 6, //baromfi tenyésztés
        FRUIT_PLANTATION: 7, //gyümölcs, ültetvény
        SEED_PRODUCTION: 8, //vetőmagelőállítás
    },
};
