import { sendServerRequest } from './baseService';

export const announcementService = {
    getAd,
    readAd,
};

async function getAd() {
    const response = await sendServerRequest('GET', '/api/announcement/news');
    return response;
}
async function readAd(adId) {
    const response = await sendServerRequest('POST', `/api/announcement/news/read/${adId}`);
    return response;
}
