import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { isFunction, isEmpty } from 'lodash';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    modalButtonWrapper: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
    },
    openModalButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: '100%',
        border: props => (!props.isDisabled ? `1px solid ${theme.color.gray}` : `1px solid ${theme.color.pearl}`),
        borderRadius: 4,
        outline: 'none',
        backgroundColor: theme.color.white,
        cursor: props => (!props.isDisabled ? 'pointer' : 'default'),
        '&:hover': {
            borderColor: props => !props.isDisabled && theme.color.main,
            backgroundColor: props => !props.isDisabled && theme.color.opacity.main,
            '& svg': {
                fill: props => !props.isDisabled && theme.color.shadow_green,
            },
        },
        background: props => props.isDisabled && theme.color.disabled,
    },
    addIcon: {
        fill: props => (!props.isDisabled ? theme.color.raven : theme.color.gray),
        height: 24,
    },
    selectedValueText: {
        display: 'flex',
        justifyContent: 'center',
        color: theme.color.jet,
        fontWeight: 500,
        ...theme.overFlowText,
        maxWidth: '32ch',
        width: '100%',
        textAlign: 'left',
        fontSize: 13,
    },
}));

export default function ModalButtonCell(props) {
    const { rowData, value, onClick, modalComponent, modalComponentProps } = props;

    const [selectedValue, setSelectedValue] = useState();
    const [isDisabled, setIsDisabled] = useState(false);
    const classes = useStyles({ isDisabled });

    function onClickOpenModal() {
        if (isFunction(onClick)) {
            onClick(rowData);
        }
    }

    function ignoreRowSelection(event) {
        event.stopPropagation();
    }

    return (
        <div className={classes.modalButtonWrapper} onClick={ignoreRowSelection} onKeyDown={ignoreRowSelection} role="button" tabIndex="0">
            <div
                className={classes.openModalButtonWrapper}
                role="button"
                tabIndex={0}
                onClick={onClickOpenModal}
                aria-hidden="true"
            >
                {isEmpty(value)
                    ? (
                        <Svg iconId="icon-add" style={classes.addIcon} />
                    ) : (
                        <div className={classes.selectedValueText}>
                            {selectedValue || value}
                        </div>
                    )
                }
            </div>
        </div>
    );
}
