import { useState, useEffect, useCallback } from 'react';
import { isEmpty } from 'lodash';

export default function useTableColumnOrderDataLoader(props) {
    const { columns } = props;
    const [columnsLayout, setColumnsLayout] = useState([]);

    //init columns layout
    useEffect(() => {
        if (!isEmpty(columns)) {
            setColumnsLayout(generateColumnsLayout(columns));
        }
    }, [columns]);

    //reset columns
    const onToggleOrder = useCallback(toggleState => {
        if (!toggleState && !isEmpty(columns)) {
            setColumnsLayout(generateColumnsLayout(columns));
        }
    }, [columns]);

    function generateColumnsLayout(columnList) {
        const generatedLayout = columnList?.map((column, index) => ({
            x: 1, //x-coord
            y: index, //y-coord
            w: 1, //width
            h: 1, //height
            i: index.toString(), //column.columnId, //custom index
            isBounded: true, //bounded dnd
            isResizable: false,
            columnName: column.columnName, //translated column name
        }));
        //arrayUtils.sortByParamNumberAsc(generatedLayout, 'index');
        return generatedLayout;
    }

    return {
        onToggleOrder,
        columnsLayout,
    };
}
