import { createUseStyles } from 'react-jss';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { ModalContentWrapper, ModalHeader, ModalBody } from '@baseComponents/modals/modalNew';

import useLoadCultivationPeriodMeteorologyModalData from './useLoadCultivationPeriodMeteorologyModalData';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import useTranslate from '@i18n/useTranslate';
import { FormRangeCalendar } from '@customComponents/datePickers';
import { useRef, useState } from 'react';
import { dateTimeUtils } from 'shared/src/modules';
import Svg from '@baseComponents/Svg';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { t } from 'i18next';
import BaseTooltip from '@baseComponents/tooltips/tooltip/BaseTooltip';
import { isEmpty } from 'lodash';
import NoData from '@baseComponents/chips/NoData';

const useStyles = createUseStyles((theme: any) => ({
    modalWrapper: {
        width: 1200,
        height: 700,
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        borderRadius: 6,
    },
    datePicker: {
        width: 250,
        margin: [20, 0],
    },
    filterWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    popover: {
        zIndex: theme.zIndex.popover,
        boxShadow: theme.shadows.dropdown,
        backgroundColor: theme.color.white,
        borderRadius: 6,
        border: 'none',
        '& .arrow::before': {
            borderRightColor: theme.color.white,
            borderWidth: 8,
            top: -10,
            left: -20,
        },
        '& .popover-body': {
            padding: 0,
        },
    },
    noData: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        marginBottom: 80,
    },
}));

export default function CultivationPeriodMeteorologyModal(props) {
    const { periodId } = props;
    const classes = useStyles();

    const chartRef = useRef(null);

    const { t, translater } = useTranslate();
    const [startDate, setStartDate] = useState<string>(dateTimeUtils.getDefaultDateFormat(dateTimeUtils.getLastHalfYear(dateTimeUtils.getMoment())) as string);
    const [endDate, setEndDate] = useState<string>(dateTimeUtils.getDefaultDateNow());
    const { highchartOptions, meteorologyData } = useLoadCultivationPeriodMeteorologyModalData({ periodId, startDate, endDate, t, translater, chartRef });

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {t('cultivationPeriodMeteorologyModal.title', 'Daily meteorology data for cultivation period')}
            </ModalHeader>
            <ModalBody className={classes.modalBody}>
                <LoadSpinner value={meteorologyData} isAutoDetect />
                <div className={classes.filterWrapper}>
                    <FormRangeCalendar
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        className={classes.datePicker}
                        placement="left"
                    />
                    <BaseTooltip 
                        show={true}
                        placement="bottom"
                        type="info"
                        message={t('cultivationPeriodMeteorologyModal.hint', 'The chart shows a multitude of interpolated meteorological data for the given cultivation period, looking back over the last six months.')}
                    >
                    <Svg iconId="icon-info" width={32} height={32}  />
                    </BaseTooltip>

                </div>
                {
                    isEmpty(meteorologyData) ?
                    (
                        <div className={classes.noData}>
                            <NoData />
                        </div>
                    )
                :
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={highchartOptions ?? { credits: { enabled: false }, title: { text: '' } }}
                        ref={chartRef}
                    />
                }
            </ModalBody>
        </ModalContentWrapper>
    );
}
