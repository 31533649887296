import React from 'react';
import { createUseStyles } from 'react-jss';
import Svg from '@baseComponents/Svg';
import TitleBadge from '@baseComponents/chips/TitleBadge';
import Area from '@baseComponents/formatters/Area';

const useStyles = createUseStyles((theme: any) => ({
    headerWrapper: {
        padding: [0, 30],
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        lineHeight: 1.5,
        color: theme.color.jet,
        ...theme.overFlowText,
    },
    companyWithAreaWrapper: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '1.25',
        color: theme.color.raven,
    },
    companyName: {
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '1.25',
        color: theme.color.raven,
    },
    area: {
        fontSize: 16,
        fontWeight: 'normal',
        lineHeight: '1.25',
        color: theme.color.raven,
        marginLeft: 10,
        paddingLeft: 10,
        borderLeft: '1px solid',
        borderLeftColor: theme.color.pearl,
    },
    titleWrapper: {
        marginTop: 10,
        minHeight: 30,
        maxHeight: 60,
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
    },
    isFixedIcon: {
        height: 20,
        width: 20,
        minWidth: 20,
        fill: theme.color.black,
        marginRight: 5,
    },
    areaWrapper: {
        height: 20,
        width: 'auto',
        '& .areaFormatter': {
            paddingLeft: 0,
        },
    },
}));

export default function PeriodViewTitleCard(props) {
    const { periodYear, name, companyName, sownArea, isFixed } = props;
    const classes = useStyles();

    return (
        <div className={classes.headerWrapper}>
            <TitleBadge>
                {periodYear}
            </TitleBadge>
            <div className={classes.titleWrapper}>
                <Svg iconId={isFixed ? 'icon-lock_locked' : 'icon-lock_unlocked'} style={classes.isFixedIcon} />
                <span className={classes.title} title={name}>
                    {name}
                </span>
            </div>
            <div className={classes.companyWithAreaWrapper}>
                <div className={classes.companyName}>
                    {companyName}
                </div>
                <div className={classes.areaWrapper}>
                    <Area value={sownArea} style={classes.area} />
                </div>
            </div>
        </div>
    );
}
