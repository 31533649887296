import useTranslate from '@i18n/useTranslate';
import { ThemeType } from '@map/services/mapTypes';
import { isNil, isNumber } from 'lodash';
import { dateTimeUtils } from 'shared/src/modules';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
// eslint-disable-next-line no-restricted-imports
import { MapSliderIntervalMode } from '../MapSliderTypes';
import { MapSliderLabelComponent } from './MapSliderLabelComponent';

type Classes = 'mapSliderLabelContainer' | 'mapSliderClosing' | 'sliderLabelContainer';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    mapSliderLabelContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'absolute',
        bottom: 20,
    },
    mapSliderClosing: {
        fontSize: '1.6rem',
        fontWeight: 400,
    },
    sliderLabelContainer: {
        position: 'absolute',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        color: 'white',
        userSelect: 'none',
        pointerEvents: 'none',
        zIndex: 300,
    },
}));

type TProps = {
    mapSliderMode: MapSliderIntervalMode,
    pickedDate: Date,
}

export const MapSliderLabelContainer = ({ mapSliderMode, pickedDate }: TProps) => {
    const classes = useStyles();

    const { translater } = useTranslate();

    const yearLabes = useMemo<string[]>(() => {
        const yearLabelTextKeys = [
            'jan',
            'feb',
            'mar',
            'apr',
            'may',
            'jun',
            'jul',
            'aug',
            'sep',
            'oct',
            'nov',
            'dec',
        ];

        return yearLabelTextKeys.map(labelKey => (translater<string>(`map.month${labelKey}`, labelKey)));
    }, [translater]);

    const sliderLabels = useMemo<string[]>(
        () => {
            let labels: string[] = [];

            const pickedDateMoment = dateTimeUtils.getDateObject(pickedDate);
            const days = pickedDateMoment.daysInMonth();

            switch (mapSliderMode) {
                case MapSliderIntervalMode.YEAR:
                    labels = [...yearLabes];
                    break;
                case MapSliderIntervalMode.MONTH:
                    for (let i = 1; i <= days; i++) {
                        labels.push(`${i}`);
                    }
                    break;
                default:

                    for (let i = 1; i <= 7; i++) {
                        const oneDay = pickedDateMoment.isoWeekday(i).get('date');
                        labels.push(`${oneDay}`);
                    }
                    break;
            }
            return labels;
        }, [mapSliderMode, pickedDate, yearLabes],
    );

    const labelHasBorder = useCallback((label: string, isLast) => {
        if (isLast) {
            return false;
        }

        if (mapSliderMode === MapSliderIntervalMode.YEAR) {
            return true;
        }

        if (mapSliderMode === MapSliderIntervalMode.WEAK) {
            return true;
        }

        const dayNumber = Number(label);
        if (isNumber(dayNumber)) {
            const pickedDateMoment = dateTimeUtils.getDateObject(pickedDate);
            const oneDay = pickedDateMoment.set('date', dayNumber);
            const weekDay = oneDay.isoWeekday();

            if (weekDay === 7) {
                return true;
            }
        }

        return false;
    }, [mapSliderMode, pickedDate]);

    const labelGrow = useCallback((index: number) => {
        if (mapSliderMode !== MapSliderIntervalMode.YEAR) {
            return 1;
        }
        const pickedDateMoment = dateTimeUtils.getDateObject(pickedDate);

        pickedDateMoment.set('M', index);

        return pickedDateMoment.daysInMonth();
    }, [mapSliderMode, pickedDate]);

    return (
        <div className={classes.sliderLabelContainer}>
            <>
                {
                    sliderLabels.map((label, index) => (
                        <MapSliderLabelComponent
                            key={`label${mapSliderMode}-${index}`}
                            hasBorder={labelHasBorder(label, index === sliderLabels.length - 1)}
                            label={label}
                            flexGrow={labelGrow(index)}
                        />
                    ))
                }
            </>
        </div>
    );
};
