import * as turf from '@turf/turf';

enum PolygonErrorCodes {
  NO_LPIS = 2,
  SELF_INTERSECT = 3,
  INVALID_TYPE = 4,
}

function trimChildPolygon(parent: turf.helpers.Polygon, child: turf.helpers.Polygon) {
    if (parent.type === 'Polygon' && child.type === 'Polygon') {
        const intersection = turf.intersect(child, parent);

        if (intersection) {
            return intersection;
        }
        return null;
    }
    // throw new Error(`Invalid input: both parent and child must be Polygons.${parent.type}_${child.type}`);
}

function inheritHoles(parent: turf.helpers.Polygon, child: turf.helpers.Polygon) {
    if (parent.type === 'Polygon' && child.type === 'Polygon') {
        if (parent.coordinates.length > 1) {
            const childWithHoles = [child.coordinates[0]];

            parent.coordinates.slice(1).forEach(hole => {
                const holePolygon = turf.polygon([hole]);
                if (turf.booleanContains(child, holePolygon)) {
                    childWithHoles.push(hole);
                }
            });

            return turf.polygon(childWithHoles).geometry;
        }
    }
    return child;
}

interface SKPolygonValidationProps {
  drawnFeature: turf.helpers.Feature;
  dataLayer: google.maps.Data;
  selectedLpisPolygon: google.maps.Data.Feature;
}
export async function validatePolygonForSK(props: SKPolygonValidationProps) {
    const { drawnFeature, selectedLpisPolygon, dataLayer } = props;
    const errors: PolygonErrorCodes[] = [];
    let containingLpis: turf.helpers.Polygon | null = null;
    const otherPolygons: turf.helpers.Polygon[] = [];

    const drawnPolygon = drawnFeature.geometry as turf.helpers.Polygon;

    if (drawnPolygon.type !== 'Polygon') {
        errors.push(PolygonErrorCodes.INVALID_TYPE);
    }

    await new Promise(resolve => {
        selectedLpisPolygon.toGeoJson((jsonFeature: any) => {
            containingLpis = jsonFeature.geometry;
            resolve(true);
        });
    });

    await new Promise(resolve => {
        dataLayer.forEach((feature: google.maps.Data.Feature) => {
            if (feature !== selectedLpisPolygon) {
                feature.toGeoJson((jsonFeature: any) => {
                    if (jsonFeature.geometry && jsonFeature.geometry.type === 'Polygon') {
                        otherPolygons.push(jsonFeature.geometry);
                    }
                });
            }
        });
        resolve(true);
    });

    const isSelfIntersect = turf.kinks(drawnPolygon).features.length > 0;
    if (isSelfIntersect) {
        errors.push(PolygonErrorCodes.SELF_INTERSECT);
    }

    if (!containingLpis) {
        return { errors };
    }

    const newPolygonWithHoles = inheritHoles(containingLpis, drawnPolygon);

    const newPolygon = trimChildPolygon(containingLpis, newPolygonWithHoles);

    return { errors, newPolygon };
}
