import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { PLANTATION, SYSTEM } from 'shared/src/constants';
import useTranslate from '@i18n/useTranslate';
import FormSelect from '@baseComponents/select/formSelect/FormSelect';
import Counter from '@baseComponents/inputs/Counter';
import Input from '@baseComponents/inputs/Input';
import { FormYearPicker } from '@customComponents/datePickers';
import CropForm from '@customComponents/culture/CropForm';

const useStyle = createUseStyles((theme: any) => ({
    selectSpace: {
        marginTop: 15,
    },
    row: {
        display: 'flex',
    },
    datePicker: {
        marginRight: 16,
    },
    distances: {
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fit, minmax(45%, 1fr) )',
        gridGap: 15,
        marginTop: 30,
    },
    selectDropdown: {
        marginTop: 15,
        '& .dropdown-menu': {
            transform: 'translate(0px, 42px) !important',
            width: '100%',
        },
        '& .dropdown-item': {
            height: 40,
            padding: '10px 15px',
            ...theme.overFlowText,
            maxWidth: '100%',
        },
        '& #selectDropdownList': {
            maxWidth: 318,
        },
    },
}));

export default function PlantationForm(props) {
    const { cultureList } = props;
    const { t } = useTranslate();
    const { values, setFieldValue, errors } = useFormikContext();
    const currentSystemModule = useSelector((state: any) => state.user.user.settings.activeSystemModule);
    const [plantationTypes] = useState(currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION ? [
        {
            value: t('plantationForm.grape', 'Grape'),
            key: PLANTATION.TYPE.GRAPE,
        },
        {
            value: t('plantationForm.fruit', 'Fruit'),
            key: PLANTATION.TYPE.FRUIT,
        },
        {
            value: t('plantationForm.forest', 'Forest'),
            key: PLANTATION.TYPE.FOREST,
        },
    ] : [
        {
            value: t('plantationForm.grape', 'Grape'),
            key: PLANTATION.TYPE.GRAPE,
        },
        {
            value: t('plantationForm.fruit', 'Fruit'),
            key: PLANTATION.TYPE.FRUIT,
        },
    ]);
    const classes = useStyle();

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        setFieldValue('crop', { ...values.crop, [name]: value });
    }

    return (
        <>
            <CropForm
                cultureList={cultureList}
            />
            <FormSelect
                dataList={plantationTypes}
                selected={values.crop.plantationType}
                onChange={value => setFieldValue('crop', { ...values.crop, plantationType: value })}
                className={classes.selectDropdown}
                label={t('plantationForm.plantationType', 'Plantation type')}
                invalid={!!(errors.crop && errors.crop.plantationType)}
                errorMessage={errors.crop && errors.crop.plantationType}
                required
            />
            <div className={clsx(classes.selectSpace, classes.row)}>
                <FormYearPicker
                    className={classes.datePicker}
                    date={values.crop.year}
                    setDate={value => setFieldValue('crop', { ...values.crop, year: value })}
                    label={t('plantationForm.installationYear', 'Installation year')}
                    invalid={!!(errors.crop && errors.crop.year)}
                    errorMessage={errors.crop && errors.crop.year}
                    required
                />
                <Counter
                    unit={t('plantationForm.pc', 'pc')}
                    value={values.crop.rowNumber}
                    setValue={value => setFieldValue('crop', { ...values.crop, rowNumber: value })}
                    label={t('plantationForm.rowNumbers', 'Row numbers')}
                />
            </div>
            <div className={classes.distances}>
                <Input
                    type="number"
                    name="lineLength"
                    label={t('plantationForm.generalLineHeight', 'General line length')}
                    value={values.crop.lineLength}
                    onChange={handleChange}
                />
                <Input
                    type="number"
                    name="lineSpacing"
                    label={t('plantationForm.rowSpacing', 'Row spacing')}
                    value={values.crop.lineSpacing}
                    onChange={handleChange}
                />
                <Input
                    type="number"
                    name="plantDistance"
                    label={t('plantationForm.distanceBetweenPlants', 'Distance between plants')}
                    value={values.crop.plantDistance}
                    onChange={handleChange}
                />
            </div>
        </>
    );
}
