export const GENERAL = {
    FILTER: {
        STATUS: {
            ALL: [0, 1],
            ACTIV: 1,
            INACTIV: 0,
            ACTIVE_AND_INACTIVE: 2,
            ACTIV_INACTIV: (1, 0),
        },
    },
};
