import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty, isNumber } from 'lodash';
import { useSelector } from 'react-redux';
import useTranslate from '@i18n/useTranslate';
import FormSelect from '@baseComponents/select/formSelect/FormSelect';
import AddNewPeriodYearModal from '@customComponents/modals/AddNewPeriodYearModal';

const useStyles = createUseStyles((theme: any) => ({
    periodYearSelects: {
        display: 'flex',
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
        marginTop: 15,
    },
    periodYearsDot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: (props: any) => (props.period ? theme.color.main : theme.color.destructive),
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    selectDropdown: {
        '& .dropdown-menu': {
            transform: 'translate(0px, 42px) !important',
        },
        '& .dropdown-item': {
            height: 40,
            padding: '10px 15px',
            ...theme.overFlowText,
            maxWidth: '100%',
        },
        '& #selectDropdownList': {
            maxWidth: 318,
        },
    },
}));
export default function PeriodYearSelector(props) {
    const { period, setPeriod, setDefaultPeriod, errorMessage } = props;
    const { t } = useTranslate();
    const periodYears = useSelector((state: any) => state.dataLists.productionYears);
    const [periodYearList, setPeriodYearList] = useState();
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [isSelectedPeriodYear, setIsSelectedPeriodYear] = useState(false);

    const classes = useStyles({ period });

    useEffect(() => {
        if (!isEmpty(periodYears) && isEmpty(periodYearList)) {
            const selectPeriodYearList = periodYears.map(periodYearObj => ({
                value: periodYearObj.period,
                key: periodYearObj.id,
                year: periodYearObj.year,
            }));
            setPeriodYearList(selectPeriodYearList);
            if (!isEmpty(selectPeriodYearList) && isNumber(period)) {
                const defaultYear = periodYears?.find(periodListObj => periodListObj.id === period);
                if (defaultYear) {
                    setDefaultPeriod(defaultYear.year);
                }
            }
        }
    }, [periodYears, period, setDefaultPeriod, periodYearList]);

    useEffect(() => {
        function setSelectedPeriodYear(periodList, periodId) {
            const selectedPeriod = !!periodId && periodList?.find(periodListObj => periodListObj.key === +periodId);
            if (isEmpty(selectedPeriod)) {
                setPeriod('');
            } else {
                setDefaultPeriod(selectedPeriod?.year);
            }

            setIsSelectedPeriodYear(true);
        }

        if (periodYearList && !isSelectedPeriodYear) {
            setSelectedPeriodYear(periodYearList, period);
        }
    }, [periodYearList, period, setPeriod, setDefaultPeriod, isSelectedPeriodYear, setIsSelectedPeriodYear]);

    function changeSelectedYear(selectedId, selectedYear) {
        if (!isEmpty(periodYears) && (!isEmpty(selectedId) || isNumber(selectedId))) {
            const defaultYear = periodYears?.find(periodListObj => periodListObj.id === +selectedId);
            if (defaultYear) {
                setDefaultPeriod(defaultYear.year);
            } else if (selectedYear) {
                setDefaultPeriod(selectedYear);
            }
            setPeriod(+selectedId);
        }
    }

    return (
        <>
            <div className={classes.label}>
                {t('newCultivationPeriod.productionYear', 'Production year')}
                <span className={classes.periodYearsDot} />
            </div>
            <div className={classes.periodYearSelects}>
                <FormSelect
                    dataList={periodYearList}
                    selected={period}
                    onChange={changeSelectedYear}
                    invalid={!!errorMessage}
                    errorMessage={errorMessage}
                    className={classes.selectDropdown}
                    type="add"
                    addNew={() => setShowAddNewModal(true)}
                />
                {showAddNewModal
                    && <AddNewPeriodYearModal
                        show={showAddNewModal}
                        handleCloseClicked={() => setShowAddNewModal(false)}
                        periodYearList={periodYearList}
                        changeSelectedYear={changeSelectedYear}
                        setPeriodYearList={setPeriodYearList}
                    />
                }
            </div>
        </>
    );
}
