/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { MODULES } from 'shared/src/routing';
import { isEmpty, isNull } from 'lodash';
import { errorActions } from '@states/actions/errorActions';
import { announcementService, phpService } from '@services';
import { phpHelper } from '@utils/php/PhpHelper';
import { routerHelper } from '@utils/router/RouterHelper';
import { setPageLoading } from '@baseComponents/PageLoader';

const useStyles = createUseStyles({
    modalBody: {
        padding: 0,
    },
    modal: {
        width: 800,
        height: 'auto',
        margin: 'auto',
        overflow: 'hidden !important',
    },
});

export default function AdModal() {
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    const history = useHistory();
    const [adModalShow, setAdModalShow] = useState(true);
    const onHide = () => setAdModalShow(false);
    const [adData, setAdData] = useState<any>(null);
    const [pageContent, setPageContent] = useState('');
    const classes = useStyles();

    useEffect(() => {
        let isCancelled = false;

        async function loadAd() {
            try {
                const adServiceData = await announcementService.getAd();
                if (!isCancelled) {
                    setAdData(adServiceData);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }

        if (location && isNull(adData)) {
            loadAd();
        }

        return () => { isCancelled = true; };
    }, [dispatch, location, adData]);

    useEffect(() => {
        async function loadContent() {
            try {
                const adModal: any = document.getElementById('ad-modal');

                if (isEmpty(adModal)) {
                    return;
                }

                // load php content
                const page = await phpService.getPhpAdModal(adData.ads.id);
                const extractscript = phpHelper.getScriptsFromContent(page);
                if (isNull(extractscript)) {
                    adModal.innerHTML = page;
                    setPageContent(page);
                    return;
                }

                //set content
                const purePageContent = phpHelper.getPureContent(page, extractscript);
                adModal.innerHTML = purePageContent;
                setPageContent(purePageContent);

                //catch close btn-s event
                document.getElementById('ad-modal')?.querySelectorAll("a[data-dismiss='modal']")?.forEach(node => {
                    node.addEventListener('click', event => {
                        event.preventDefault();
                        if (event) {
                            readAd(adData.ads.id);
                            onHide();
                        }
                    });
                });

                const adModalBtnImg = document.getElementById('btn_modal_img_package');
                if (adModalBtnImg) {
                    adModalBtnImg.addEventListener('click', event => {
                        event.preventDefault();
                        if (event) {
                            const subscriptionUrl = routerHelper.generateUrl(MODULES.GENERAL, MODULES.GENERAL.CONTENTS.SUBSCRIPTION);
                            history.push(subscriptionUrl);
                            readAd(adData.ads.id);
                            onHide();
                        }
                    });
                }

                const adModalWorksLinkBtn = document.getElementById('btn_admodal_works_link');
                if (adModalWorksLinkBtn) {
                    adModalWorksLinkBtn.addEventListener('click', event => {
                        event.preventDefault();
                        if (event) {
                            const subscriptionUrl = routerHelper.generateUrl(MODULES.GENERAL, MODULES.GENERAL.CONTENTS.SUBSCRIPTION_WORKS);
                            history.push(subscriptionUrl);

                            onHide();
                        }
                    });
                }

                document.getElementById('btn_admodal_next')?.addEventListener('click', event => {
                    event.preventDefault();
                    if (event) {
                        if (adData.ads.id === 1) {
                            const subscriptionUrl = routerHelper.generateUrl(MODULES.GENERAL, MODULES.GENERAL.CONTENTS.SUBSCRIPTION);
                            history.push(subscriptionUrl);
                        }
                        readAd(adData.ads.id);
                        onHide();
                    }
                });

                //clean and run php content file <script> tags
                phpHelper.cleanAndRunScripts(extractscript);
                setPageLoading(false);
            } catch (error) {
                console.log('AdModal: ', error);
                //dispatch(errorActions.setError(error, location));
            }
        }

        if (adData && adData.ads && adModalShow) {
            setPageLoading(true);
            loadContent();
        }
    }, [adData, adModalShow, dispatch, history]);

    useEffect(() => {
        // eslint-disable-next-line no-restricted-syntax
        if (pageContent !== '') {
            setPageLoading(false);
        }
    }, [pageContent, history]);

    function closeRequest(event) {
        if (!event) { //ignore outside click
            return;
        }

        readAd(adData.ads.id);
        onHide();
    }

    function readAd(adsId) {
        const readAdCheckbox: any = document.getElementById('chb_modal_ads');
        if (readAdCheckbox && readAdCheckbox.checked) {
            announcementService.readAd(adsId);
        }
    }

    if (!adData || !adData.ads) {
        return null;
    }

    return (
        <Modal size="xl" show={adModalShow} onHide={closeRequest} aria-labelledby="contained-modal-title-vcenter" centered className={classes.modal}>
            <Modal.Body className={classes.modalBody}>
                <div id="ad-modal" />
            </Modal.Body>
        </Modal>
    );
}
