import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import { errorActions, simpleTableActions } from '@states/actions';
import useTranslate from '@i18n/useTranslate';
import CultivationPeriodsModal from '@customComponents/modals/cultivationPeriodsModal/CultivationPeriodsModal';
import { NUMBERS } from 'shared/src/constants';
import { theme } from '@styles/theme';
import { soilAnalysisService } from '@services';
import { useFormContext } from 'react-hook-form';
import { clone, isEmpty } from 'lodash';

const cellFunctions = {
    updateRowInMemory: (rowData, columnId, updateValue, getValues) => {
        const rowId = rowData.rowId;
        const otherRow = getValues('soilAnalysisData')?.find(tableRow => tableRow.rowId === rowId);
        if (otherRow) {
            otherRow[columnId] = updateValue;
        }
        if (rowData) {
            rowData[columnId] = updateValue;
        }
    },

    updateRow: (rowId, columnId, updateValue, tableKey, dispatch, rowData, setValue) => {
        if (!tableKey) {
            return;
        }
        const tableData = dispatch(simpleTableActions.getTableDataList(tableKey));
        const clonedTableData = clone(tableData);
        const updatedRow = tableData?.find(tableRow => tableRow.rowId === rowId);
        const selectedRowIds = dispatch(simpleTableActions.getSelectedRowIds(tableKey)) ?? [];
        const otherRows = clonedTableData?.filter(tableRow => tableRow.id !== rowId && selectedRowIds?.includes(tableRow.rowId));

        const valueBeforeUpdate = rowData[columnId];

        if (updatedRow && columnId) {
            rowData[columnId] = updateValue;
            updatedRow[columnId] = updateValue;
        }

        if (updateValue === valueBeforeUpdate || isEmpty(selectedRowIds) || !selectedRowIds.includes(rowId)) {
            setValue('soilAnalysisData', clonedTableData);
            return;
        }

        otherRows.forEach(row => {
            row[columnId] = updateValue;
        });

        dispatch(simpleTableActions.setTableData(tableKey, clonedTableData));
        setValue('soilAnalysisData', clonedTableData);
    },
    deleteRow: (rowId, tableKey, rowIdName, dispatch, setValue) => {
        setValue('isLoading', true);
        const tableData = dispatch(simpleTableActions.getTableDataList(tableKey));
        const filteredTableData = tableData?.filter(rowData => rowData.rowId !== rowId);
        setValue('soilAnalysisData', filteredTableData);
        dispatch(simpleTableActions.removeRowFromTableDataById(tableKey, rowId, rowIdName));
    },
};

export default function useSoilAnalysisManualTable(props) {
    const { tableId } = props;
    const cultivationPeriodList = useSelector((state: any) => state.basePage.table.tableData);
    const [parameters, setParameters] = useState([]);
    const { setValue, getValues } = useFormContext();
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const defaultColumns = useMemo(() => {
        const soilAnalysisColumns = [
            {
                type: columnName.modalAddButton,
                columnId: 'cultivationPeriod',
                columnName: t('soilAnalysisImport.cultivationPeriod', 'Cultivation period'),
                isLeftFixed: true,
                width: 200,
                minWidth: 200,
                maxWidth: 200,
                componentProps: {
                    line1Id: 'name',
                    modalComponent: CultivationPeriodsModal,
                    updateValue: (value, _cellData, rowData) => {
                        cellFunctions.updateRow(rowData.rowId, 'cultivationPeriod', value, tableId, dispatch, rowData, setValue);
                        cellFunctions.updateRow(rowData.rowId, 'cultivationPeriodCompanyName', value?.companyName, tableId, dispatch, rowData, setValue);
                    },
                    modalComponentProps: {
                        isMultiselect: false,
                        rowFormatFnc: period => ({
                            id: period.id,
                            name: period.name,
                            companyName: period.companyName,
                        }),
                    },
                    isRequired: true,
                    getDataList: () => cultivationPeriodList?.map(cultivationPeriodData => ({ key: cultivationPeriodData.id, value: cultivationPeriodData.name })),
                },
            },
            {
                type: columnName.simpleText,
                columnName: t('soilAnalysisImport.company', 'Company name'),
                columnId: 'cultivationPeriodCompanyName',
                isLeftFixed: true,
                width: 200,
                minWidth: 200,
                maxWidth: 200,
            },
            {
                type: columnName.quantityInput,
                columnId: 'depth',
                columnName: t('soilAnalysisManualImport.depth', 'Depth'),
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                componentProps: {
                    isRequired: true,
                    quantityFormat: {
                        decimals: NUMBERS.DEFAULT.DECIMAL_NUMBERS,
                        decimalSeparator: NUMBERS.DEFAULT.DECIMAL_SEPARATOR,
                        thousandsSeparator: NUMBERS.DEFAULT.THOUSAND_SEPARATOR,
                        unit: t('default.centimeter', 'cm'),
                    },
                    onBlur: (value, rowData, columnId) => {
                        if (!isEmpty(dispatch(simpleTableActions.getSelectedRowIds(tableId)))) {
                            cellFunctions.updateRow(rowData.rowId, columnId, value, tableId, dispatch, rowData, setValue);
                        } else {
                            cellFunctions.updateRowInMemory(rowData, columnId, value, getValues);
                        }
                    },
                },
            },
            {
                type: columnName.simpleInput,
                columnId: 'code',
                columnName: t('soilAnalysisImport.code', 'Code'),
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                componentProps: {
                    onBlur: (value, rowData, columnId) => {
                        if (!isEmpty(dispatch(simpleTableActions.getSelectedRowIds(tableId)))) {
                            cellFunctions.updateRow(rowData.rowId, columnId, value, tableId, dispatch, rowData, setValue);
                        } else {
                            cellFunctions.updateRowInMemory(rowData, columnId, value, getValues);
                        }
                    },
                    isRequired: true,
                },
            },
            {
                type: columnName.iconSvg,
                componentProps: {
                    getIcon: () => 'icon-bin',
                    getIconColor: () => theme.color.icons.deleteIcon,
                    getIsDisabled: () => dispatch(simpleTableActions.getTableDataList(tableId))?.length <= 1,
                    onClick: rowData => {
                        cellFunctions.deleteRow(rowData.rowId, tableId, 'rowId', dispatch, setValue);
                    },
                },
                isRightFixed: true,
                width: 55,
                minWidth: 55,
                maxWidth: 55,
            },
        ];

        parameters?.forEach((column: any) => {
            if (column.sqlName === 'cultivationPeriodd') {
                return;
            }

            soilAnalysisColumns.push({
                type: columnName.quantityInput,
                columnId: column.sqlName,
                columnName: column.name,
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                componentProps: {
                    quantityFormat: {
                        decimals: NUMBERS.DEFAULT.DECIMAL_NUMBERS,
                        decimalSeparator: NUMBERS.DEFAULT.DECIMAL_SEPARATOR,
                        thousandsSeparator: NUMBERS.DEFAULT.THOUSAND_SEPARATOR,
                    },
                    onBlur: (value, rowData, columnId) => {
                        if (!isEmpty(dispatch(simpleTableActions.getSelectedRowIds(tableId)))) {
                            cellFunctions.updateRow(rowData.rowId, columnId, value, tableId, dispatch, rowData, setValue);
                        } else {
                            cellFunctions.updateRowInMemory(rowData, columnId, value, getValues);
                        }
                    },
                },
            });
        });

        return soilAnalysisColumns;
    }, [cultivationPeriodList, dispatch, parameters, setValue, t, tableId]);

    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const soilAnalysisParameters = await soilAnalysisService.getSoilProductivityParameters();
                if (!isCancelled) {
                    setParameters(soilAnalysisParameters);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'LoadSoilAnalysisEditData'));
            }
        }

        if (dispatch) {
            loadData();
            return () => {
                isCancelled = true;
            };
        }
    }, [dispatch]);

    return {
        columns,
    };
}
