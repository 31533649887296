import SimpleTextCell from './simpleTextCell/SimpleTextCell';
import SimpleInputCell from './simpleInputCell/SimpleInputCell';
import SelectRowCell from './selectRowCell/SelectRowCell';

import AvatarWithNameCell from './avatars/AvatarWithNameCell';
import AreaCell from './areaCell/AreaCell';
import CostCell from './costCell/CostCell';
import CostPerAreaCell from './costPerAreaCell/CostPerAreaCell';
import CostPerWeightCell from './costPerWeightCell/CostPerWeightCell';
import DateCell from './dateCell/DateCell';
import DateEditCell from './dateCell/DateEditCell';
import DateMultilineCell from './dateMultilineCell/DateMultilineCell';
import DateTimeCell from './dateTimeCell/DateTimeCell';
import DecimalCell from './decimalCell/DecimalCell';
import EditButtonCell from './editButtonCell/EditButtonCell';
import EmptyCell from './emptyCell/EmptyCell';
import IconButtonCell from './iconButtonCell/IconButtonCell';
import DaysWithDataButtonCell from './daysWithDataButtonCell/DaysWithDataButtonCell';
import WorkOperationInfoButtonCell from './workOperationInfoButtonCell/WorkOperationInfoButtonCell';
import MapCell from './mapCell/MapCell';
import MapWithMultilineCell from './mapWithMultilineCell/MapWithMultilineCell';
import MultilineCell from './multilineCell/MultilineCell';
import NdviButtonCell from './ndviButtonCell/NdviButtonCell';
import OtherFunctionsCell from './otherFunctionsCell/OtherFunctionsCell';
import WeatherButtonCell from './weatherButtonCell/WeatherButtonCell';
import WeightPerAreaCell from './weightPerAreaCell/WeightPerAreaCell';
import AvatarAndMachineCell from './avatarAndMachinesCell/AvatarAndMachinesCell';
import MachineAvatarCell from './avatars/MachineAvatarCell';
import WorkerAvatarCell from './avatars/WorkerAvatarCell';
import MultilineWithApprovalAndAreaCell from './multilineWithApprovalAndAreaCell/MultilineWithApprovalAndAreaCell';
import StatusColorCell from './statusColorCell/StatusColorCell';
import MultilineWithDateIntervalCell from './multilineWithDateIntervalCell/MultilineWithDateIntervalCell';
import MultilineWithUnitCell from './multilineCell/MultilineWithUnitCell';
import MultilineWithAreaPercentCell from './multilineCell/MultilineWithAreaPercentCell';
import StatusCell from './statusCell/StatusCell';
import DeleteCell from './deleteCell/DeleteCell';
import PesticideLinkCell from './pesticideLinkCell/PesticideLinkCell';
import CollapseCell from './collapseCell/CollapseCell';
import QuantityCell from './quantityCell/QuantityCell';
import PercentCell from './percentCell/PercentCell';
import DoubleTextCell from './doubleTextCell/DoubleTextCell';
import QuantityPerAreaCell from './quantityCell/QuantityPerAreaCell';
import IconSvgCell from './iconSvgCell/IconSvgCell';
import IconListCell from './iconListCell/IconListCell';
import ProductWithPesticideCell from './productWithPesticideCell/ProductWithPesticideCell';
import PopoverListButtonCell from './popoverListButtonCell/PopoverListButtonCell';

//button cells
import ModalAddButtonCell from './modalAddButtonCell/ModalAddButtonCell';
import SerialNumberEditButtonCell from './serialNumberEditButtonCell/SerialNumberEditButtonCell';
import ModalButtonCell from './modalButtonCell/ModalButtonCell';

//input cells
import CheckboxCell from './checkBoxCell/CheckboxCell';
import AreaInputCell from './inputCell/AreaInputCell';
import QuantityInputCell from './inputCell/QuantityInputCell';
import TextInputCell from './inputCell/TextInputCell';
import SimpleSelectCell from './simpleSelectCell/SimpleSelectCell';

//dropdowns
import IconDropdownCell from './dropdowns/iconDropdownCell/IconDropdownCell';
import MultiselectCell from './multiselectCell/MultiselectCell';

//buttons
import MultilineWithButtonCell from './buttons/multilineWithButtonCell/MultilineWithButtonCell';

export const cellTypes = {
    SimpleTextCell,
    SimpleInputCell,
    SelectRowCell,

    AvatarWithNameCell,
    AreaCell,
    CostCell,
    CostPerAreaCell,
    CostPerWeightCell,
    DateCell,
    DateEditCell,
    DateMultilineCell,
    DateTimeCell,
    DecimalCell,
    EditButtonCell,
    EmptyCell,
    IconButtonCell,
    DaysWithDataButtonCell,
    WorkOperationInfoButtonCell,
    IconSvgCell,
    IconListCell,
    MapCell,
    MapWithMultilineCell,
    MultilineCell,
    NdviButtonCell,
    ModalButtonCell,
    OtherFunctionsCell,
    WeatherButtonCell,
    WeightPerAreaCell,

    AvatarAndMachineCell,
    MachineAvatarCell,
    WorkerAvatarCell,
    MultilineWithApprovalAndAreaCell,
    StatusColorCell,
    MultilineWithDateIntervalCell,
    MultilineWithAreaPercentCell,
    MultilineWithUnitCell,
    StatusCell,
    DeleteCell,
    PesticideLinkCell,
    CollapseCell,
    QuantityCell,
    PercentCell,
    DoubleTextCell,
    QuantityPerAreaCell,

    //input cells
    CheckboxCell,
    AreaInputCell,
    QuantityInputCell,
    SimpleSelectCell,
    TextInputCell,

    //dropdowns
    IconDropdownCell,
    MultiselectCell,

    //button cells
    ModalAddButtonCell,
    SerialNumberEditButtonCell,
    MultilineWithButtonCell,

    ProductWithPesticideCell,
    PopoverListButtonCell,
};
