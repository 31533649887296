import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { languageUtils, arrayUtils } from 'shared/src/modules';

const useStyles = createUseStyles((theme: any) => ({
    multilineTitleWrapper: {
        width: '100%',
        height: '100%',
        display: 'inline-block',
        margin: 'auto',
    },
    firstLine: {
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineHeight: '16px',
        height: '50%',
        alignItems: 'flex-end',
    },
    firstLineText: {
        fontSize: 13,
        fontWeight: 500,
        color: theme.color.jet,
        display: 'block',
        ...theme.overFlowText,
    },
    secondLine: {
        display: 'flex',
        width: '100%',
        lineHeight: '16px',

        fontSize: 13,
        fontWeight: 'normal',
        color: theme.color.raven,
        ...theme.overFlowText,
        height: '50%',
    },
    secondLineText: {
        display: 'inline-block',
        fontSize: 13,
        fontWeight: 'normal',
        color: theme.color.raven,

        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        ...theme.overFlowText,
    },
}));

export default function MultilineCell(props) {
    const { value, rowData, secondLineId, hasLangCaption, firstLineStyle, secondLineStyle } = props;
    const { i18n } = useTranslation();
    const classes = useStyles();

    function getSecondLineText() {
        if (secondLineId && rowData) {
            let columnItem = arrayUtils.getArrayItemByKeyString(rowData, secondLineId);

            if (hasLangCaption) {
                //TODO: edit server side - yields
                const otherLanguage = arrayUtils.getArrayItemByKeyString(rowData, 'otherLanguage');

                if (otherLanguage && otherLanguage.name) {
                    columnItem = otherLanguage.name;
                } else {
                    columnItem = languageUtils.getLangCaptionFromObject(i18n.language, columnItem);
                }
            }

            return columnItem;
        }

        return null;
    }

    return (
        <div className={classes.multilineTitleWrapper}>
            <div className={classes.firstLine}>
                <span title={value} className={classes.firstLineText} style={firstLineStyle}>
                    {value}
                </span>
            </div>
            {secondLineId &&
                <div className={classes.secondLine}>
                    <span className={classes.secondLineText} title={getSecondLineText()} style={secondLineStyle}>
                        {getSecondLineText()}
                    </span>
                </div>
            }
        </div>
    );
}
