import { useMapContext } from '@map/services/mapContext';
import { useEffect, useState } from 'react';
import MapIconButton from '@map/components/MapIconButton';
import { MapStyleModifierState } from './MapStyleModifierState.enum';
import { BuiltInLayerTypes } from '@map/services/mapEnums';

export const MapStyleModifier = () => {
    const [store, controller] = useMapContext();
    const [
        mapStyleModifierState,
        SetMapStyleModifierState,
    ] = useState<MapStyleModifierState>(MapStyleModifierState.showCities);

    useEffect(() => {
        switch (mapStyleModifierState) {
            case MapStyleModifierState.showCities: {
                const style: google.maps.MapTypeStyle[] = [
                    {
                        featureType: 'all',
                        stylers: [{ visibility: 'off' }],
                    },

                    {
                        featureType: 'administrative.locality',
                        stylers: [{ visibility: 'on' }],
                    },
                ];
                controller.setGoogleMapStlyeOverride(style);
                break;
            }

            case MapStyleModifierState.hideCities: {
                const style: google.maps.MapTypeStyle[] = [
                    {
                        featureType: 'all',
                        stylers: [{ visibility: 'off' }],
                    },
                ];
                controller.setGoogleMapStlyeOverride(style);
                break;
            }
            default:
                break;
        }
    }, [mapStyleModifierState, controller]);

    const onShowCititesToggleClick = () => {
        if (mapStyleModifierState === MapStyleModifierState.hideCities) {
            SetMapStyleModifierState(MapStyleModifierState.showCities);
        } else {
            SetMapStyleModifierState(MapStyleModifierState.hideCities);
        }
    };

    return (
        <MapIconButton
            iconId={
                mapStyleModifierState === MapStyleModifierState.showCities
                    ? 'icon-visibility_show'
                    : 'icon-visibility_hide'
            }
            onClick={() => onShowCititesToggleClick()}
            isActive={mapStyleModifierState === MapStyleModifierState.showCities}
        />
    );
};
