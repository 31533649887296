import { sendServerRequest } from './baseService';

export const messageService = {
    getMessages,
    setMessage,
    getUnreadMeassagesCount,
    getTopMessages,
    setSystemMessagesRead,
    setMessageRead,
    deleteSystemMessage,
    deleteOutgoingMessage,
    deleteIncomingMessage,
};

async function getMessages(type) {
    const response = await sendServerRequest('GET', `/api/messages/type/${type}`);
    return response;
}

async function setMessage(to, subject, message) {
    const response = await sendServerRequest('POST', '/api/messages', {
        to, subject, message, priority: 0, email: 0,
    });
    return response;
}

async function getUnreadMeassagesCount() {
    const response = await sendServerRequest('GET', '/api/messages/count');
    return response;
}

async function getTopMessages(messageNumber) {
    const response = await sendServerRequest('GET', `/api/messages/top/${messageNumber}`);
    return response;
}

async function setSystemMessagesRead() {
    const response = await sendServerRequest('PATCH', '/api/messages/readSystem');
    return response;
}

async function deleteSystemMessage(messageId) {
    const response = await sendServerRequest('PATCH', `/api/messages/deleteSystemMessage/${messageId}`);
    return response;
}

async function setMessageRead(messageId) {
    const response = await sendServerRequest('PATCH', `/api/messages/read/${messageId}`);
    return response;
}

async function deleteOutgoingMessage(messageId) {
    const response = await sendServerRequest('PATCH', `/api/messages/deleteOutgoing/${messageId}`);
    return response;
}

async function deleteIncomingMessage(messageId) {
    const response = await sendServerRequest('PATCH', `/api/messages/deleteIncoming/${messageId}`);
    return response;
}
