import React, { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import useTranslate from '@i18n/useTranslate';
import Button from '@baseComponents/buttons/Button';
import { ModalBody, ModalHeader, ModalContentWrapper, ModalFooter } from '@baseComponents/modals/modalNew';
import { Nullable } from '@flowsCommon/services/baseTypes';
import FormGroup from '@customComponents/form/FormGroup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isFunction } from 'lodash';
import { useForm } from 'react-hook-form';
import { FormSelect } from '@baseComponents/select';

const useStyles = createUseStyles((theme: any) => ({
    modalWrapper: {
        width: 350,
        height: 'auto',
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    datePicker: {
        width: '100%',
        display: 'block',
        '& > *': {
            width: '100%',
        },
    },
    formGroup: {
        margin: [16, 0],
    },
    footerButton: {
        width: '100%',
    },
    modalFooter: {
        padding: [0, 30],
    },
}));

type TProps = {
    propertyDataList: Nullable<string[]>;
    tableDataProperties: Nullable<string[]>;
    hideModal?: () => void;
    onSubmit?: (pairedEntities) => void;
};

export default function LayerUploadKeyPairingModal(props: TProps) {
    const { propertyDataList, tableDataProperties, hideModal, onSubmit } = props;

    const classes = useStyles();
    const { t, translater } = useTranslate();

    const featurePropertySelectList = useMemo(() => propertyDataList?.map((property: any, idx) => ({
        value: property,
        key: idx + 1,
    })), [propertyDataList]);

    const entityPropertySelectList = useMemo(() => tableDataProperties?.map((property: any, idx) => ({
        value: property,
        key: idx + 1,
    }))?.filter(property => ['id', 'name'].includes(property.value)), [tableDataProperties]);

    const [validationSchema] = useState(
        yup.object().shape({
            featureProperty: yup.string()
                .typeError(translater('layerUploadKeyPairingModal.specifyFeatureProperties', 'Specifying the feature properties is required'))
                .required(translater('layerUploadKeyPairingModal.specifyFeatureProperties', 'Specifying the feature properties is required')),
            entityProperty: yup.string()
                .typeError(translater('layerUploadKeyPairingModal.specifyEntityProperties', 'Specifying the entity properties is required'))
                .required(translater('layerUploadKeyPairingModal.specifyEntityProperties', 'Specifying the entity properties is required')),
        }),
    );

    const [defaultValues] = useState({ featureProperty: null, entityProperty: null });

    const { handleSubmit, watch, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...defaultValues,
        },
        mode: 'onChange',
    });

    function onFormSubmit(formData) {
        if (isFunction(onSubmit)) {
            onSubmit({
                featureProperty: propertyDataList?.[+formData?.featureProperty - 1],
                entityProperty: tableDataProperties?.[+formData?.entityProperty - 1],
            });

            if (isFunction(hideModal)) {
                hideModal();
            }
        }
    }

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {t('map.autoMapping', 'Auto mapping')}
            </ModalHeader>

            <ModalBody className={classes.modalBody}>
                <FormGroup label={t('layerUploadKeyPairingModal.featurePropertiesLabel', 'Feature property')} required className={classes.formGroup} hasValue={watch('featureProperty')} errorMessage={errors?.featureProperty?.message}>
                    <FormSelect
                        dataList={featurePropertySelectList}
                        onChange={value => setValue('featureProperty', value)}
                        selected={watch('featureProperty')}
                    />
                </FormGroup>

                <FormGroup label={t('layerUploadKeyPairingModal.entityPropertiesLabel', 'Entity property')} required className={classes.formGroup} hasValue={watch('entityProperty')} errorMessage={errors?.entityProperty?.message}>
                    <FormSelect
                        dataList={entityPropertySelectList}
                        onChange={value => setValue('entityProperty', value)}
                        selected={watch('entityProperty')}
                    />
                </FormGroup>
            </ModalBody>

            <ModalFooter className={classes.modalFooter}>
                <Button className={classes.footerButton} onClick={handleSubmit(onFormSubmit)}>
                    {translater<string>('layerUploadKeyPairingModal.pairingLabel', 'Pairing')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
