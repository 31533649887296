import React, { useState, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { numberUtils } from 'shared/src/modules/numberUtils';
import clsx from 'clsx';
import { useNumberFormat } from '@common_modules';
import { UnitInput } from '@baseComponents/inputs/inputField';

const useStyle = createUseStyles({
    areaInput: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
    },
});

export default function AreaInputCell(props) {
    const { value, onChange, id, onChangeArea, tableKey, showDependOnCheck = true, isBasePageTable, className, columnId, rowData } = props;

    const selectedItemIds = useSelector((state: any) => (isBasePageTable ? state.basePage.table.selectedItemIds : state.simpleTable.table[tableKey]?.selectedItemIds));
    const { numberFormatter } = useNumberFormat();
    const decimalNumber = numberFormatter.getAreaFormats()?.decimals;
    const [area, setArea] = useState(numberUtils.decimalRound(value, decimalNumber) || '');
    const classes = useStyle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const areaFormat = useCallback(() => numberFormatter.getAreaFormats(), [numberFormatter.isInitialized]);

    const onBlur = useCallback(
        (event, changedValue) => {
            if (onChangeArea) {
                const numberValue = numberFormatter.getValueFromString(changedValue);
                if (numberValue !== value) {
                    onChangeArea(numberValue, id);
                }
            }
            if (onChange) {
                const numberValue = numberFormatter.getValueFromString(changedValue);
                setArea(numberValue);
                if (columnId) {
                    rowData[columnId] = numberValue;
                }
                if (onChange) {
                    onChange(event, numberValue);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    function getShowInput() {
        return !showDependOnCheck || selectedItemIds?.includes(id);
    }

    return (
        <div className={clsx(classes.areaInput, className)}>
            {getShowInput()
                && <UnitInput
                    type="number"
                    defaultValue={area}
                    onBlur={onBlur}
                    numberFormat={areaFormat}
                    min={0}
                />
            }
        </div>
    );
}
