import { isNil } from 'lodash';


export const queryBuilder = () => {
    const paramOrder = [];
    const params = {};
    const builder = {};

    const buildFn = () => {
        const query = new URLSearchParams();

        paramOrder.forEach(key => {
            const { value } = params[key];

            if (Array.isArray(value)) {
                value.forEach(val => query.append(key, val.toString()));
            } else if (!isNil(value)) {
                query.append(key, value);
            }
        });
        return query;
    };

    const withFn = (name, value) => {
        paramOrder.push(name);
        params[name] = {
            key: name,
            value,
        };

        const fieldDescription = {};

        fieldDescription.with = withFn;

        fieldDescription.build = buildFn;

        return fieldDescription;
    };

    builder.with = withFn;

    builder.build = buildFn;

    return builder;
};
