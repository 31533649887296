import Button from '@baseComponents/buttons/Button';
import Svg from '@baseComponents/Svg';
import BasicTag from '@baseComponents/tags/BasicTag';
import PartnersModal from '@customComponents/modals/partnersModal/PartnersModal';
import renderModal from '@modals/modalNew/modalFunctions';
import useTranslate from '@i18n/useTranslate';
import { MapFilters, ThemeType } from '@map/services/mapTypes';
import { isArray } from 'lodash';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import MapFilterBase from './MapFilterBase';
import MapFilterPartnerSelected from './MapFilterPartnerSelected';
import { MapFilterSchema } from './MapFilterSchema';

type StlyeType = {
    buttonHovered: boolean
}

type Classes = 'container' | 'buttonContainer' | 'title' | 'openFilterTableButton' | 'addIcon' | 'filterTable' | 'addIcon' | 'tagOuterContainer'

const useStyles = createUseStyles<Classes, StlyeType, ThemeType>(theme => ({
    container: {
        width: 199,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    buttonContainer: {
        width: 199,
    },
    title: {
        marginBottom: 15,
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
    },
    openFilterTableButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: '100%',
        border: `2px dashed ${theme.color.gray}`,
        borderRadius: 6,
        outline: 'none',
        cursor: 'pointer',
        borderColor: props => props.buttonHovered && theme.color.main,
        backgroundColor: props => props.buttonHovered && theme.color.opacity.main,
    },
    addIcon: {
        fill: props => props.buttonHovered && theme.color.shadow_green,
        color: theme.color.raven,
        height: 24,
    },
    filterTable: {
        overflow: 'scroll',
    },
    tagOuterContainer: {
        height: 40,
        marginTop: 15,
        '& span': {
            ...theme.overFlowText,
        },
    },
}));
export default function MapFilterPartner(props) {
    const filterContext = useFormContext<MapFilterSchema>();

    const [buttonHovered, setButtonHovered] = useState(false);

    const { t } = useTranslate();

    const classes = useStyles({ buttonHovered });

    const openPartnerModal = () => {
        const partnerModalProps: any = {
            isMultiselect: true,
            onChange: (selected: any) => {
                if (isArray(selected)) {
                    const companyIds = selected.map(company => company.id);
                    filterContext.setValue('companyIds', companyIds);
                    filterContext.setValue('companies', selected);
                } else {
                    filterContext.setValue('companyIds', [selected.id]);
                    filterContext.setValue('companies', [selected]);
                }
            },
            selectedRowIds: filterContext.getValues('companyIds'),
        };
        // eslint-disable-next-line no-undefined
        renderModal(PartnersModal, partnerModalProps, false);
    };

    return (
        <MapFilterBase>
            <div className={classes.container}>
                <div className={classes.buttonContainer}>
                    <div className={classes.title}>
                        {t('filterTitle.partnerIds', 'Partner')}
                    </div>
                    <div
                        className={classes.openFilterTableButton}
                        onMouseOver={() => setButtonHovered(true)}
                        onMouseOut={() => setButtonHovered(false)}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onFocus={() => { }}
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onBlur={() => { }}
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            openPartnerModal();
                        }}
                        aria-hidden="true"
                    >
                        <Svg
                            iconId="icon-add"
                            style={classes.addIcon}
                        />
                    </div>

                    <div className={classes.tagOuterContainer}>
                        <MapFilterPartnerSelected />
                    </div>
                </div>
            </div>
        </MapFilterBase>
    );
}
