import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { errorActions, userActions } from '@states/actions';
import { userService } from '@services';
import { createUseStyles } from 'react-jss';
import OutsideClickHandler from 'react-outside-click-handler';
import { isEmpty } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import TwoFactorInput from '@baseComponents/inputs/TwoFactorInput';
import Button from '@baseComponents/buttons/Button';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        position: 'absolute',
        left: 0,
        top: 0,
        padding: [0, 30],
        height: '100vh',
        width: 360,
        boxShadow: `inset 2px 0 0 0 ${theme.color.lilian}`,
        display: (props: any) => (props.showActivation2FA ? 'block' : 'none'),
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 1.43,
        color: theme.color.jet,
        background: theme.color.white,
        textAlign: 'center',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '1',
        color: theme.color.jet,
        marginTop: 20,
    },
    form: {
        height: 'calc(100vh - 100px)',
        overflow: 'hidden auto',
        scrollbarWidth: 'thin !important',
    },
    qrCodeContainer: {
        marginTop: 30,
    },
    qrCode: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 30,
        minHeight: 220,
        '& img': {
            borderRadius: 6,
        },
    },
    footer: {
        bottom: 0,
        position: 'fixed',
        paddingBottom: 25,
        width: 300,
        display: 'flex',
        justifyContent: 'space-around',
    },
    saveBtn: {
        minWidth: 130,
    },
}));

const Activation2FA = props => {
    const { showActivation2FA, setShowActivation2FA, setTwoFactorStatus } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const userId = useSelector((state: any) => state.user.user.id);
    const [loadQRCode, setLoadQRCode] = useState(false);
    const [qrCodeImgSrc, setQrCodeImgSrc] = useState('');
    const [verifiedCode, setVerifiedCode] = useState(false);
    const { t } = useTranslate();
    const classes = useStyles({ showActivation2FA } as any);

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const qrCodeData = await userService.getTwoFactorQRCode();

                if (!isCancelled) {
                    setQrCodeImgSrc(qrCodeData.imgSrc);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }

        if (showActivation2FA && !loadQRCode) {
            loadData();

            return () => {
                setLoadQRCode(true);
                isCancelled = true;
            };
        }
    }, [dispatch, location, showActivation2FA, loadQRCode]);

    const cancel = () => setShowActivation2FA(false);

    const save = async () => {
        try {
            const success = await userService.setTwoFactorStatus(true);
            if (success) {
                dispatch(userActions.updateUser());
                setTwoFactorStatus(true);
                setShowActivation2FA(false);
            }
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    };

    return (
        <OutsideClickHandler onOutsideClick={() => setShowActivation2FA(false)}>
            <div className={classes.container}>
                <div className={classes.form}>
                    <div className={classes.title}>{t('2fa.enableAuth', 'Enable two-factor authentication')}</div>
                    <div className={classes.qrCodeContainer}>
                        <div>
                            <p>{t('2fa.scanQRCode', 'Scan the QR code below with the authenticator app')}</p>
                        </div>
                        <div className={classes.qrCode}>
                            {!isEmpty(qrCodeImgSrc) && (
                                <img src={qrCodeImgSrc} alt="QR Code" width="220" height="220" />
                            )}
                        </div>
                    </div>
                    <TwoFactorInput
                        userId={userId}
                        verifiedCode={verifiedCode}
                        setVerifiedCode={setVerifiedCode}
                    />
                </div>
                <div className={classes.footer}>
                    <Button type="secondary" size="large" onClick={cancel}>
                        {t('default.cancel', 'CANCEL')}
                    </Button>
                    <Button className={classes.saveBtn} size="large" onClick={save} disabled={!verifiedCode}>
                        {t('2fa.turnOn', 'TURN ON')}
                    </Button>
                </div>
            </div>
        </OutsideClickHandler>
    );
};

export default Activation2FA;
