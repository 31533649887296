import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { FormDatePicker, FormTimePicker } from '@customComponents/datePickers';

const useStyles = createUseStyles({
    shortForm: {
        '& .btn': {
            width: '100%',
        },
    },
});

export default function DateTimePicker(props) {
    const { date, setDate, time, setTime, className } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={className}>
            <FormDatePicker
                className={classes.shortForm}
                date={date}
                setDate={setDate}
                label={t('meteorologyDataRecord.date', 'Date')}
                required
                showDate
            />
            <FormTimePicker
                label={t('meteorologyDataRecord.time', 'Time')}
                className={classes.shortForm}
                time={time}
                setTime={setTime}
            />
        </div>
    );
}
