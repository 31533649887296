import { Dropdown } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { languageUtils } from 'shared/src/modules/languageUtils';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    toggleButton: {
        display: 'flex',
        width: '100%',
        height: 40,
        border: '1px solid',
        borderRadius: 4,
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        opacity: (props: any) => (props.disabled ? 0.6 : 1),
        ...theme.disableOutline,
        '&:hover': {
            backgroundColor: theme.color.white,
            borderColor: theme.color.gray,
        },
        '&.btn-primary.dropdown-toggle': {
            borderColor: theme.color.gray,
            cursor: (props: any) => (props.disabled ? 'default' : 'pointer'),
            color: theme.color.jet,
            backgroundColor: theme.color.white,
            boxShadow: theme.shadows.secondary.normal,
            minWidth: 50,
            '&:active': {
                color: theme.color.jet,
                backgroundColor: theme.color.white,
                borderColor: (props: any) => (props.disabled ? theme.color.gray : theme.color.main),
            },
            '&:focus': {
                boxShadow: (props: any) => (props.disabled ? theme.shadows.secondary.normal : theme.shadows.primary.focus),
                borderColor: (props: any) => (props.disabled ? theme.color.gray : theme.color.main),
            },
        },
    },
    toggleLabel: {
        fontSize: 15,
        width: '100%',
        display: 'inline-block',
        verticalAlign: 'middle',
        textAlign: 'left',
        lineHeight: '28px',
        height: 28,
        ...theme.overFlowText,
        ...theme.disableSelectionColor,
    },
    toggleText: {
        fontWeight: 400,
        color: theme.color.jet,
    },
    togglePlaceholder: {
        alignItems: 'center',
        fontWeight: '300',
        color: theme.color.stone,
    },
    toggleIcon: {
        height: 24,
        width: 24,
        margin: 'auto',
        verticalAlign: 'middle',
        alignItems: 'center',
    },
    selectedIcon: {
        height: 24,
        width: 24,
        margin: 'auto',
        verticalAlign: 'middle',
        alignItems: 'center',
        fill: (props: any) => props.selectedIconFillColor,
    },
    iconWithTextWrapper: {
        width: '100%',
        overeflow: 'hidden',
        display: 'contents',
    },
    iconWithText: {
        marginLeft: 5,
        ...theme.overFlowText,
    },
    clearIcon: {
        height: 18,
        width: 18,
    },
    clearPadding: {
        paddingRight: 0,
    },
    clearIconButton: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        border: 'none',
        backgroundColor: theme.color.white,
        borderRadius: [0, 4, 4, 0],
        '&:hover': {
            backgroundColor: theme.color.lilian,
        },
    },
}));

export default function SelectToggleButton(props) {
    const { placeholder, selectedItem, isOpened, iconId, disabled, onlyDropdown, isClearItem, onChangeSelected } = props;
    const { isOnlyIconToggle, isIconWithTextToggle } = props;
    const { t } = useTranslation();
    const classes = useStyles({ disabled, selectedIconFillColor: selectedItem?.iconColor } as any);

    function clearSelectedItem(event) {
        event.stopPropagation();
        onChangeSelected();
    }

    return (
        <Dropdown.Toggle
            className={clsx(classes.toggleButton, isClearItem && classes.clearPadding, 'withBorder')}
            title={(isOnlyIconToggle || isIconWithTextToggle) && !isEmpty(selectedItem?.value) ? languageUtils.getTitle(t, selectedItem?.value) : ''}
            disabled={disabled}
        >
            {!onlyDropdown && !isOnlyIconToggle && !isIconWithTextToggle && (selectedItem
                ? (
                    <span className={clsx(classes.toggleLabel, classes.toggleText)} title={languageUtils.getTitle(t, selectedItem.value)}>
                        {languageUtils.getTitle(t, selectedItem.value)}
                    </span>
                ) : (
                    <span className={clsx(classes.toggleLabel, classes.togglePlaceholder)}>
                        {placeholder}
                    </span>
                )
            )}
            {/* Csak icon */}
            {isOnlyIconToggle && !isEmpty(selectedItem?.iconId) && (
                <Svg iconId={selectedItem.iconId} style={classes.selectedIcon} />
            )}
            {/* Icon szöveggel */}
            {isIconWithTextToggle && !isEmpty(selectedItem?.iconId) && (
                <div className={classes.iconWithTextWrapper}>
                    <Svg iconId={selectedItem.iconId} style={classes.selectedIcon} />
                    <span className={clsx(classes.toggleLabel, classes.toggleText, classes.iconWithText)}>
                        {selectedItem?.value}
                    </span>
                </div>
            )}

            {iconId && <Svg iconId={iconId} style={classes.toggleIcon} />}
            {!iconId && (isOpened
                ? <Svg iconId="icon-arrow_drop_up" style={classes.toggleIcon} />
                : <Svg iconId="icon-arrow_drop_down" style={classes.toggleIcon} />
            )}
            {isClearItem && selectedItem && !disabled && (
                <div onClick={clearSelectedItem} onKeyDown={() => onChangeSelected()} role="button" tabIndex={0} className={classes.clearIconButton} title={t('default.delete', 'Delete')}>
                    <Svg iconId="icon-close" style={classes.clearIcon} />
                </div>
            )
            }
        </Dropdown.Toggle>
    );
}
