/* eslint-disable max-lines-per-function */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import OutsideClickHandler from 'react-outside-click-handler';
import { messageService } from '@services';
import useTranslate from '@i18n/useTranslate';
import { dateTimeUtils } from 'shared/src/modules';

import Button from '@baseComponents/buttons/Button';
import MessageList from './MessageList';
import NewMessage from './NewMessage';
import MessageDetails from './MessageDetails';

const useStyles = createUseStyles((theme: any) => ({
    fade: {
        display: (props: any) => (props.showMessagesMenu || props.showNewMessage ? 'block' : 'none'),
        opacity: '0.8 !important',
        top: 0,
        left: (props: any) => (props.expanded ? 720 : 540),
        right: 0,
        bottom: '100% !important',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        backgroundColor: theme.color.jet,
    },
    messagesMenu: {
        position: 'absolute',
        left: (props: any) => (props.expanded ? 240 : 60),
        top: 0,
        height: '100vh',
        width: 480,
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 1.43,
        color: theme.color.jet,
        backgroundColor: theme.color.white,
        display: (props: any) => (props.showMessagesMenu ? 'flex' : 'none'),
        flexDirection: 'column',
        zIndex: theme.zIndex.sideNavBar,
    },
    openedMessageMenu: {
        position: 'absolute',
        left: (props: any) => (props.expanded ? 720 : 540),
        top: 0,
        height: '100vh',
        width: '640px',
        display: (props: any) => (props.showSelectedMessage || props.showNewMessage ? 'flex' : 'none'),
        flexDirection: 'column',
        backgroundColor: theme.color.white,
        overflow: 'hidden',
        overflowY: 'auto',
        paddingLeft: '60px',
        paddingRight: '60px',
        paddingTop: '25px',
        textAlign: 'left',
        ...theme.verticalScrollbar,
    },
    messageMenuTop: {
        display: 'flex',
        paddingTop: 15,
        paddingBottom: 21,
        justifyContent: 'space-between',
        border: `1px solid ${theme.color.pearl}`,
    },
    messageMenuBottom: {
        display: 'flex',
        overflowY: 'hidden',
        height: '100%',
    },
    menuBottomRight: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderLeft: `1px solid ${theme.color.pearl}`,
        borderRight: `1px solid ${theme.color.pearl}`,
    },
    menuTopTitle: {
        marginLeft: 30,
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: '40px',
        color: theme.color.jet,
    },
    newMessage: {
        marginRight: '30px',
    },
    menuBottomControlsContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '5px',
    },
    menuBottomControlsSelect: {
        border: 'none',
        height: '40px',
        width: 'auto',
        marginLeft: '30px!important',
        padding: '0!important',
    },
    menuBottomControlsArrow: {
        width: '25px',
        height: '25px',
    },
    newMessageInputMessage: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontFamily: 'Rubik',
        fontWeight: '500',
        color: theme.color.jet,
    },
    input: {
        fontSize: '15px',
        borderRadius: '4px',
        height: '40px',
        border: `1px solid ${theme.color.gray}`,
        fontFamily: 'Rubik',
        color: theme.color.jet,
        paddingLeft: '15px',
        outline: 'none',
    },
    textarea: {
        fontSize: '15px',
        borderRadius: '4px',
        height: '300px',
        border: `1px solid ${theme.color.gray}`,
        fontFamily: 'Rubik',
        color: theme.color.jet,
        outline: 'none',
        marginTop: '30px',
        marginBottom: '40px',
        padding: '15px',
    },
}));

export default function MessagesMenu(props) {
    const { showMessagesMenu, closeMessagesMenu, expanded, setUnreadMessagesNumber } = props;
    const [selectedMessage, setSelectedMessage] = useState<any>(null);
    const [showNewMessage, setShowNewMessage] = useState(false);
    const [showSelectedMessage, setShowSelectedMessage] = useState(false);
    const user = useSelector((state: any) => state.user.user);
    const classes = useStyles({ showMessagesMenu, showNewMessage, expanded, showSelectedMessage } as any);
    const { t } = useTranslate();
    const [users, setUsers] = useState({});
    const [body, setBody] = useState({});
    const [editorState, setEditorState] = useState('');
    const [initial, setInitial] = useState(true);
    const [changeFlag, setChangeFlag] = useState(false);

    const setSelection = (message, flag) => {
        setSelectedMessage(message);
        setShowSelectedMessage(flag);
        setShowNewMessage(false);
    };

    const handleSelectedMessageClose = () => {
        setShowSelectedMessage(false);
    };

    const handleNewMessageOpen = () => {
        setShowSelectedMessage(false);
        setShowNewMessage(true);

        setEditorState('');
        setBody({});
        setUsers({});
        setInitial(true);
    };

    const answerToOne = () => {
        setShowSelectedMessage(false);
        setShowNewMessage(true);

        const prevMessage = `<div style="padding-top:20px;">
                                <div>${selectedMessage.user.name} ${dateTimeUtils.getDateTime(selectedMessage.date)}:</div>
                                <div style="padding-left:20px;">${selectedMessage.message}</div>
        </div>`;

        setEditorState(prevMessage);

        setBody({
            subject: `RE: ${selectedMessage.subject}`,
            message: prevMessage,
            toUser: selectedMessage.userId,
        });

        setUsers({
            tags: [
                selectedMessage.user,
            ],
            suggestions: [],
        });

        setInitial(false);
    };

    const answerToMany = () => {
        setShowSelectedMessage(false);
        setShowNewMessage(true);

        const prevMessage = `<div style="padding-top:20px;">
                                <div>${selectedMessage.user.name} ${dateTimeUtils.getDateTime(selectedMessage.date)}:</div>
                                <div style="padding-left:20px;">${selectedMessage.message}</div>
        </div>`;

        setEditorState(prevMessage);

        setBody({
            subject: `RE: ${selectedMessage.subject}`,
            message: prevMessage,
            toUser: selectedMessage.userId,
        });

        setUsers({
            tags: [
                selectedMessage.user,
                ...selectedMessage.messageToUser.filter(mtu => mtu.user.id !== user.id).map(mtu => mtu.user),
            ],
            suggestions: [],
        });

        setInitial(false);
    };

    const handleDeleteOutgoingMessage = async () => {
        await messageService.deleteOutgoingMessage(selectedMessage.id);

        setChangeFlag(!changeFlag);
        setShowSelectedMessage(false);
    };

    const handleDeleteIncomingMessage = async () => {
        await messageService.deleteIncomingMessage(selectedMessage.id);

        setChangeFlag(!changeFlag);
        setShowSelectedMessage(false);
    };

    const handleDeleteSystemMessage = async () => {
        await messageService.deleteSystemMessage(selectedMessage.id);

        setChangeFlag(!changeFlag);
        setShowSelectedMessage(false);
    };

    const handleNewMessageClose = () => {
        setChangeFlag(!changeFlag);
        setShowNewMessage(false);
    };

    return (
        <>
            <div className={classes.fade} />
            <OutsideClickHandler onOutsideClick={closeMessagesMenu}>
                <div className={classes.messagesMenu}>
                    <div className={classes.messageMenuTop}>
                        <div className={classes.menuTopTitle}>{t('messages.messages', 'Messages')}</div>
                        <Button className={classes.newMessage} onClick={handleNewMessageOpen}>{t('messages.new_short', 'New message')}</Button>
                    </div>
                    <div className={classes.messageMenuBottom}>
                        <div className={classes.menuBottomRight}>
                            <MessageList setUnreadMessagesNumber={setUnreadMessagesNumber} user={user} selectedMessage={selectedMessage} setSelection={setSelection} changeFlag={changeFlag} />
                        </div>

                    </div>
                </div>
                <div className={classes.openedMessageMenu}>
                    { showNewMessage
                        && (<NewMessage onMessageClose={handleNewMessageClose} user={user} editorStateProp={editorState} bodyProp={body} usersProp={users} initialProp={initial} />)
                    }

                    { showSelectedMessage
                    // eslint-disable-next-line max-len
                    && (<MessageDetails selectedMessage={selectedMessage} user={user} handleSelectedMessageClose={handleSelectedMessageClose} answerToOne={answerToOne} answerToMany={answerToMany} handleDeleteOutgoingMessage={handleDeleteOutgoingMessage} handleDeleteIncomingMessage={handleDeleteIncomingMessage} handleDeleteSystemMessage={handleDeleteSystemMessage} />)
                    }
                </div>
            </OutsideClickHandler>
        </>
    );
}
