export const AKI = {
    MODULE_ID: 33,
    NAME: 'aki',
    CONTENTS: {
        TABLE: {
            CONTENT_URL_ID: { tables: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'table',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'AKI Táblák',
            },
        },
    },
};
