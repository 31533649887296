import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useFormikContext } from 'formik';
import { cultivationPeriodsService } from '@services';
import { errorActions } from '@states/actions';
import { isEmpty } from 'lodash';

export default function useLoadFieldFeaturesData() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
        let isCancelled = false;

        async function loadFieldFeatureList() {
            try {
                let fieldFeatureList = await cultivationPeriodsService.getFieldFeatures();
                if (!isCancelled) {
                    fieldFeatureList = fieldFeatureList?.map(fieldFeature => ({ key: fieldFeature.id, value: fieldFeature.name }));
                    setFieldValue('fieldFeatures', fieldFeatureList);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (dispatch && location && setFieldValue) {
            loadFieldFeatureList();
            return () => {
                isCancelled = true;
            };
        }
    }, [dispatch, location, setFieldValue]);

    useEffect(() => {
        if (!isEmpty(values.fieldFeatures) && !isEmpty(values.editFieldFeatures)) {
            const fieldFeatures = values.fieldFeatures.map(fieldFeature => {
                if (values.editFieldFeatures.includes(fieldFeature.key)) {
                    fieldFeature.isChecked = true;
                }
                return fieldFeature;
            });
            setFieldValue('fieldFeatures', fieldFeatures);
            setFieldValue('editFieldFeatures', '');
        }
    }, [setFieldValue, values.editFieldFeatures, values.fieldFeatures]);
}
