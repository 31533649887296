import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { numberUtils } from 'shared/src/modules';
import { get, isFunction, isNil } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        ...theme.overFlowText,
        color: theme.color.jet,
        display: 'block',
        height: '100%',
        lineHeight: '56px',
        fontSize: 13,
        fontWeight: 500,
        verticalAlign: 'middle',
        textAlign: 'right',
    },
}));

export default function DecimalCell(props) {
    const { rowData, value, style, unit, decimal, onClick, styleWithValue } = props;
    const classes = useStyles();

    function getValue() {
        if (numberUtils.isNumber(value) && !decimal) {
            return parseFloat(value).toString().replace('.', ',');
        }
        if (decimal) {
            return numberUtils.decimalRound(value, decimal);
        }
        return value;
    }

    function handleOnClick(e: React.MouseEvent) {
        if (!onClick || !isFunction(onClick)) {
            return;
        }

        e.stopPropagation();
        onClick(rowData);
    }

    const hasValue = !isNil(value) && value !== '';

    return (
        <div className={clsx(classes.baseStyle, hasValue && styleWithValue, style)} onClick={handleOnClick}>
            {getValue()} {!isNil(value) ? unit : null}
        </div>
    );
}
