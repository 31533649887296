import React from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import Svg from '../../../Svg';
import { dataListsActions } from '@states/actions/dataListsActions';
import useCollapseTable from './useCollapseTable';

const useStyles = createUseStyles({
    baseStyle: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'inherit',
        border: 'none',
    },
    icon: {
        height: 30,
        width: 30,
    },
});

export default function CollapseCell(props) {
    const { rowData, tableName } = props;
    const dispatch = useDispatch();

    const tableData = useSelector((state: any) => state.dataLists[tableName]);

    const { isCollapsedTable, setIsCollapsedTable } = useCollapseTable({ tableData, rowData });

    const classes = useStyles();

    function onClickCollapse() {
        const changedCollapse = tableData.map(tableObj => {
            if (tableObj.tableId === rowData.tableName) {
                return { ...tableObj, isCollapsed: !isCollapsedTable };
            }
            return tableObj;
        });
        dispatch(dataListsActions.changeItem(tableName, changedCollapse));
        setIsCollapsedTable(!isCollapsedTable);
    }

    return (
        <button type="button" className={classes.baseStyle} onClick={() => onClickCollapse()}>
            {isCollapsedTable ?
                <Svg iconId="icon-expand_more" style={classes.icon} /> :
                <Svg iconId="icon-expand_less" style={classes.icon} />}
        </button>
    );
}
