import React, { useRef, useState, useEffect, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isEmpty, isNumber } from 'lodash';
import clsx from 'clsx';
import { SETTINGS, PARTNER } from 'shared/src/constants';
import { useFormikContext } from 'formik';
import { dateTimeUtils } from 'shared/src/modules';
import { companyActions } from '@states/actions/companyActions';
import Input from '@baseComponents/inputs/Input';
import { UnitInput } from '@baseComponents/inputs/inputField';

import Textarea from '@baseComponents/inputs/Textarea';
import Button from '@baseComponents/buttons/Button';
import PeriodYearSelector from '@baseComponents/selector/PeriodYearSelector';
import FarmDropdown from '@customComponents/dropdowns/farmDropdown';
import OwnerSelector from '@baseComponents/selector/OwnerSelector';
import { DoubleDatePicker } from '@customComponents/datePickers';
import RegionInput from '@baseComponents/region/RegionInput';
import FieldFeaturesMultiSelect from '@customComponents/fieldFeatures/FieldFeaturesMultiSelect';
import { useNumberFormat, useCompanyPermission } from '@common_modules';
import ActiveInactiveRadio from '@baseComponents/controls/ActiveInactiveRadio';
import { LocationSelect } from '@customComponents/selects';
import { mapService } from '@services';
import { errorActions } from '@states/actions';
import useFormScroll from './useFormScroll';
import NewCultivationPeriodDataWindows from './NewCultivationPeriodDataWindows';
import useLoadCloseDialog from './useLoadCloseDialog';
import Qualification from './qualification/Qualification';

const useStyles = createUseStyles((theme: any) => ({
    form: {
        paddingLeft: 30,
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingRight: 30,
        ...theme.verticalScrollbar,
    },
    inputSpace: {
        marginTop: 15,
    },
    scrollFade: {
        bottom: 125,
        width: 320,
        ...theme.formButtonFade,
    },
    saveBtn: {
        width: 312,
        minHeight: 40,
        marginLeft: 30,
        marginTop: 25,
    },
    sownAreaInput: {
        width: 152,
    },
    commentTextArea: {
        marginTop: 15,
    },
    locationContainer: {
        display: 'flex',
    },
    refreshLocation: {
        marginLeft: 5,
        marginTop: 35,
    },
}));

export default function NewCultivationPeriodForm(props) {
    const { periodYearId, geometry, cultivationPeriodId, isSaveBtnDisabled, setFormIsDirty, isFadeOn, setIsFadeOn, isEdit, setCultivationPeriodValues, formInitialValues, setFormInitialValues, isQulificationRequired, isGeometryEdited, onPeriodYearChange } = props;

    const { values, submitForm, handleChange, errors, setFieldValue } = useFormikContext();
    const formRef = useRef();
    const scrollFadeRef = useRef();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { companyPermissions } = useCompanyPermission();

    const [settings] = useState(
        dispatch(companyActions.getSettings({
            usingFarm: SETTINGS.USING_FARM,
            isNonAccentSearch: SETTINGS.ACCENT_INSENSITIVE_SEARCH,
        })),
    );

    const { numberFormatter } = useNumberFormat();

    useFormScroll({ formRef, scrollFadeRef });

    //set default period value from cultivationPeriodManagement layout selected period
    useEffect(() => {
        if (isNumber(periodYearId)) {
            setFieldValue('period', periodYearId);
        }
    }, [periodYearId, setFieldValue]);

    useEffect(() => {
        let isCancelled = false;

        async function loadNearestLocation() {
            try {
                const location = await mapService.getCultivationPeriodNearestLocation(values.id);

                if (!isCancelled && !isEmpty(location)) {
                    setFieldValue('locationId', location?.locationId);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'NearestLocation'));
            }
        }

        if (!!values.id && !values.locationId) {
            loadNearestLocation();
        }
        return () => {
            isCancelled = true;
        };
    }, [dispatch, setFieldValue, values.id, values.locationId]);

    useLoadCloseDialog({ formInitialValues, geometry, periodYearId, isEdit, isGeometryEdited });

    const handleChangeSownArea = useCallback(
        (_event, area) => {
            setFieldValue('sownArea', area);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setFieldValue],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const areaFormat = useCallback(() => numberFormatter.getAreaFormats(), [numberFormatter.isInitialized]);

    function setStartDate(date) {
        setFieldValue('startDate', date);

        if (!isEmpty(values.basicFields)) {
            const selectedBasicFields = values.basicFields.filter(basicField => basicField.isFixed || !basicField.terminationDate
                || dateTimeUtils.getDateObject(date) < dateTimeUtils.getDateObject(basicField.terminationDate));
            setFieldValue('basicFields', selectedBasicFields);
        }
    }

    function setEndDate(date) {
        setFieldValue('endDate', date);
        if (isEmpty(date)) {
            setFieldValue('isActive', PARTNER.ACTIVE_STATUS.ACTIVE);
        }
    }

    return (
        <>
            <form className={classes.form} ref={formRef}>

                <PeriodYearSelector
                    period={values.period}
                    setPeriod={value => { setFieldValue('period', value); onPeriodYearChange(value); }}
                    setDefaultPeriod={value => setFieldValue('periodYear', value)}
                    errorMessage={errors?.period}
                />

                {settings?.usingFarm
                    ? (
                        <FarmDropdown
                            farmId={values.farmId}
                            setFarmId={value => setFieldValue('farmId', value)}
                            validation={errors}
                        />
                    ) : null
                }

                <OwnerSelector isNonAccentSearch={settings?.isNonAccentSearch} />

                <Input
                    label={t('newCultivationPeriod.name', 'Name')}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    className={classes.inputSpace}
                    invalid={!!errors.name}
                    errorMessage={errors.name}
                    required
                />

                <DoubleDatePicker
                    startDate={values.startDate}
                    setStartDate={value => setStartDate(value)}
                    endDate={values.endDate}
                    setEndDate={value => setEndDate(value)}
                    validation={errors}
                    scrollRef={formRef}
                />

                { !isEmpty(values.endDate)
                    && (
                        <ActiveInactiveRadio
                            title={t('cultivationPeriods.state', 'Status')}
                            defaultValue={values.isActive}
                            onChange={value => setFieldValue('isActive', value)}
                        />
                    )
                }

                <RegionInput />

                <UnitInput
                    type="number"
                    defaultValue={values.sownArea}
                    onBlur={handleChangeSownArea}
                    numberFormat={areaFormat()}
                    isInvalid={!!errors.sownArea}
                    errorMessage={errors.sownArea}
                    className={clsx(classes.inputSpace, classes.sownAreaInput)}
                    label={t('newCultivationPeriod.sownArea', 'Sown area')}
                    positiveOnly
                    isRequired
                    isLeftAlign
                />

                <NewCultivationPeriodDataWindows
                    isFadeOn={isFadeOn}
                    setIsFadeOn={setIsFadeOn}
                    isEdit={isEdit}
                    periodYearId={periodYearId}
                    cultivationPeriodId={cultivationPeriodId}
                    setCultivationPeriodValues={setCultivationPeriodValues}
                    setFormInitialValues={setFormInitialValues}
                />

                <div className={classes.locationContainer}>
                    <LocationSelect
                        className={classes.inputSpace}
                        location={values.locationId}
                        setLocation={value => setFieldValue('locationId', value)}
                    />
                    {!!values.id && (
                        <Button
                            type="secondary"
                            iconId="icon-again"
                            className={classes.refreshLocation}
                            onClick={() => setFieldValue('locationId', '')}
                        />)}
                </div>

                <FieldFeaturesMultiSelect
                    className={classes.inputSpace}
                />

                <Qualification
                    qualification={values.qualification}
                    setQualification={value => setFieldValue('qualification', value)}
                    errorMessage={errors.qualification}
                    required={isQulificationRequired}
                />

                <Textarea
                    label={t('newCultivationPeriod.comment', 'Comment')}
                    name="comment"
                    className={classes.commentTextArea}
                    value={values.comment}
                    handleChange={handleChange}
                />

                {companyPermissions.hasCultivationPeriodBankAndInsurer() ? (
                    <Textarea
                        label={t('cultivationPeriodsColumn.comment2', 'Comment II')}
                        name="comment2"
                        className={classes.commentTextArea}
                        value={values.comment2}
                        handleChange={handleChange}
                    />
                ) : null}

                {companyPermissions.hasCultivationPeriodBankAndInsurer() ? (
                    <Textarea
                        label={t('cultivationPeriodsColumn.comment3', 'Comment III')}
                        name="comment3"
                        className={classes.commentTextArea}
                        value={values.comment3}
                        handleChange={handleChange}
                    />
                ) : null}

                <div className={classes.scrollFade} ref={scrollFadeRef} />
            </form>

            <Button size="large" className={classes.saveBtn} onClick={() => { setFormIsDirty(true); submitForm(); }} disabled={isSaveBtnDisabled}>
                {t('default.save', 'Save')}
            </Button>

        </>
    );
}
