export const TABLE = {
    VIEW_TYPE: { //on multiline cells
        SIMPLE: 0, //cost, weight, area
        VALUE_PER_UNIT: 1, //cost/area, weight/cost
    },
    COLUMN_TYPE: {
        DEFAULT_TYPE: 0,
        FIRST_TYPE: 1,
        SECOND_TYPE: 2,
        THIRD_TYPE: 3,
        CUSTOM_TYPE: 4,
    },
    AGGREGATE: {
        SUM: 'sum',
        AVG: 'avg',
        MIN: 'min',
        MAX: 'max',
        COUNT: 'count',
        //custom aggregate
        SUM_SPECIFIC_COST: 'sumSpecificCost',
        SUM_CROP_YIELD: 'sumCropYield',
        SUM_VALUE_PER_HECTARE: 'sumValuePerHectare',
        SUM_MARGIN: 'sumMargin',
    },
    SORTING: {
        NONE: 0,
        ASC: 1,
        DESC: 2,
    },
    SEARCH_TYPE: {
        STRING: 0,
        NUMBER: 1,
        DATE: 2,
        MULTISELECT: 3,
        RADIOLIST: 4,
        BOOLEAN: 5,
    },
    DATA_TYPE: {
        BOOLEAN: 'boolean',
        DATE: 'date',
        DOSE: 'dose',
        TEXT: 'text',
        AREA: 'area',
        WEIGHT: 'weight',
        COST: 'cost',
        COST_PER_AREA: 'costPerArea',
        WEIGHT_PER_AREA: 'weightPerArea',
        COST_PER_WEIGHT: 'costPerWeight',
        LANG_CAPTION: 'langCaption',
        QUANTITY: 'quantity',
        QUANTITY_PER_AREA: 'quantityPerArea',
        PERCENTAGE: 'percentage',
        WORKOPERATION_PERFORMANCE: 'workoperation_performance',
        WORKING_HOURS_PERFORMANCE: 'working_hours_performance',        
    },
    ORDER_INDEX: {
        LEFT_FIXED: -1,
        RIGHT_FIXED: 999,
    },
    COLUMN_LINE: {
        BOTH_LINE: 1,
        FIRST_LINE: 2,
        SECOND_LINE: 3,
    },
};
