import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { alertActions } from '@states/actions';

export default function useAlert() {
    const dispatch = useDispatch();

    const infoAlert = useCallback((message: string | JSX.Element, title?: string | JSX.Element, alertMoreInfo?: any) => dispatch(alertActions.infoAlert(message, title, alertMoreInfo)), [dispatch]);
    const successAlert = useCallback((message: string | JSX.Element, title?: string | JSX.Element, alertMoreInfo?: any) => dispatch(alertActions.successAlert(message, title, alertMoreInfo)), [dispatch]);
    const errorAlert = useCallback((message: string | JSX.Element, title?: string | JSX.Element, alertMoreInfo?: any) => dispatch(alertActions.errorAlert(message, title, alertMoreInfo)), [dispatch]);
    const warningAlert = useCallback((message: string | JSX.Element, title?: string | JSX.Element, alertMoreInfo?: any) => dispatch(alertActions.warningAlert(message, title, alertMoreInfo)), [dispatch]);
    const addAlert = useCallback(({ alertMessage, isHtmlCodeAlert = true }) => dispatch(alertActions.addAlert({ alertMessage, isHtmlCodeAlert })), [dispatch]);

    return {
        infoAlert,
        successAlert,
        errorAlert,
        warningAlert,
        addAlert,
    };
}
