import React, { useState, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import Avatar from '@baseComponents/avatars/Avatar';
import MachineAvatar from '@baseComponents/avatars/MachineAvatar';
import AvatarContainer from './AvatarContainer';

const useStyles = createUseStyles((theme: any) => ({
    avatarContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%',
        marginRight: 25,
    },
    machineAvatar: {
        marginRight: 3,
        '& >img': {
            width: '100%',
        },
    },
    workerContainer: {
        display: 'flex',
        marginRight: 8,
    },
    machineContainer: {
        display: 'flex',
    },
    additionalMachines: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        border: 'solid 2px',
        height: 40,
        width: 40,
        fontSize: 12,
        color: theme.color.white,
        background: theme.color.raven,
    },
    additionalWorkers: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        border: 'solid 2px',
        height: 40,
        width: 40,
        fontSize: 12,
        color: theme.color.white,
        background: theme.color.raven,
        padding: 3,
        marginLeft: -8,
    },
    tooltip: {
        borderRadius: 6,
        boxShadow: `${theme.shadows.lightGrey} !important`,
        border: `1px solid ${theme.color.pearl}`,
    },
}));

export default function AvatarAndMachinesCell(props) {
    const { rowData } = props;
    const classes = useStyles();
    const divideData = useCallback(dataArr => ({ withAvatar: dataArr?.filter((_, i) => i < 2), rest: dataArr?.filter((_, i) => i > 1) }), []);
    const [workers] = useState(divideData(rowData?.resources?.workers));
    const [machines] = useState(divideData(rowData?.resources?.machines));
    const { id } = rowData;

    return (
        <div className={classes.avatarContainer}>
            <AvatarContainer
                containerClassName={classes.workerContainer}
                additionalClassName={classes.additionalWorkers}
                datalist={workers}
                Component={Avatar}
                rowId={id}
            />
            <AvatarContainer
                containerClassName={classes.machineContainer}
                avatarClassName={classes.machineAvatar}
                additionalClassName={classes.additionalMachines}
                datalist={machines}
                Component={MachineAvatar}
                rowId={id}
            />
        </div>
    );
}
