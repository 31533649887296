import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';
import { lowerCase } from 'lodash';
import { LANGUAGE, DEFAULT_SYSTEM_DATA } from 'shared/src/constants';
import { theme as themes } from '@styles/theme';
import { sessionService } from '@services';

const useStyles = createUseStyles((theme: any) => ({
    linkStyle: {
        marginLeft: 5,
        cursor: 'pointer',
        display: 'inline-block',
        lineHeight: '23px',
        verticalAlign: 'middle',
        color: theme.color.linkHoverColor,
    },
    openInNewTabLink: {
        ...theme.disableOutline,
        '&:hover': {
            color: theme.color.linkHoverColor,
            textDecoration: 'underline',
            textDecorationColor: theme.color.linkHoverColor,
        },
    },
}));

export default function Gdpr() {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [gdprUrl, setGdprUrl] = useState(DEFAULT_SYSTEM_DATA.GDPR.EN_URL);

    function openUrlInNewTab(url) {
        window.open(url, '_blank');
    }

    useEffect(() => {
        if (!i18n.language) {
            return;
        }
        switch (i18n.language) {
            case lowerCase(LANGUAGE.HUNGARIAN):
                setGdprUrl(DEFAULT_SYSTEM_DATA.GDPR.HU_URL);
                break;
            case lowerCase(LANGUAGE.ROMANIAN):
                setGdprUrl(DEFAULT_SYSTEM_DATA.GDPR.RO_URL);
                break;
            default:
                setGdprUrl(DEFAULT_SYSTEM_DATA.GDPR.EN_URL);
                break;
        }
    }, [i18n.language]);

    return (
        <CookieConsent
            buttonText={t('default.ok', 'OK')}
            cookieName={`${sessionService.getAgentName()}_gdpr`}
            style={{ background: themes.color.pearl, color: themes.color.raven }}
            buttonStyle={{ background: themes.color.main, color: themes.color.white, fontSize: '13px', fontWeight: 500 }}
            expires={DEFAULT_SYSTEM_DATA.GDPR.EXPIRE_DAYS}
            location="top"
        >
            {t('gdpr.text', 'This website uses cookies to ensure you get the best experience on our website.')}

            <div className={classes.linkStyle}>
                <div onClick={() => openUrlInNewTab(gdprUrl)} className={classes.openInNewTabLink} onKeyDown={() => openUrlInNewTab(gdprUrl)} role="button" tabIndex={0}>
                    {t('gdpr.learnMore', 'Learn more')}
                </div>
            </div>
        </CookieConsent>
    );
}
