import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isNumber, isArray, isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import { createUseStyles } from 'react-jss';
import { Modal, Row, Col } from 'react-bootstrap';
import useTranslate from '@i18n/useTranslate';
import { DEFAULT_SYSTEM_DATA, COUNTRY } from 'shared/src/constants';
import { dateTimeUtils } from 'shared/src/modules';
import { errorActions } from '@states/actions';
import { pesticideService } from '@services';

import Button from '@baseComponents/buttons/Button';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    modal: {
        '& .modal-content': {
            width: '600px',
            margin: 'auto',
        },
        '& .modal-dialog': {
            padding: 0,
        },
        '& .modal-header': {
            alignItems: 'center',
            padding: '15px 30px 15px 30px',
            margin: 0,
            '& h4': {
                fontSize: '22px !important',
                fontWeight: 700,
                fontFamily: 'Rubik',
            },
        },
        '& .modal-body': {
            display: 'block',
            padding: '30px 30px 30px 30px',
            backgroundColor: theme.color.lilian,
        },
        '& .close': {
            top: 0,
        },
        '& .modal-dialog.modal-xl.modal-dialog-centered': {
            left: 0,
        },
    },
    logo: {
        border: 0,
        width: 274,
        height: 300,
        backgroundImage: 'url("/styles/img/av_nevjegy.png")',
    },
    row: {
        flexWrap: 'nowrap',
    },
    contentRight: {
        width: 300,
    },
    version: {
        fontSize: 16,
        fontWeight: 600,
        marginBottom: 0,
    },
    date: {
        fontSize: 12,
        fontStyle: 'italic',
        color: theme.color.stone,
        marginBottom: 2,
        fontWeight: 'normal',
        lineHeight: 'normal',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
    updateDate: {
        fontSize: 12,
        fontStyle: 'italic',
        color: theme.color.stone,
        marginTop: 2,
        marginBottom: 2,
        fontWeight: 'normal',
        lineHeight: 'normal',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
    firm: {
        marginTop: 20,
        marginBottom: 2,
        fontSize: 13,
        lineHeight: 'normal',
        fontWeight: 600,
    },
    otherLabel: {
        fontSize: 13,
        display: 'block',
        '& i': {
            marginRight: 5,
            color: theme.color.main,
        },
        '& a': {
            color: '#4b4b4b',
        },
    },
    otherDiv: {
        marginBottom: 5,
        fontSize: 13,
        display: 'flex',
        '& i': {
            marginRight: 5,
            color: theme.color.main,
        },
    },
    close: {
        cursor: 'pointer',
        height: 40,
        width: 40,
        borderRadius: '50%',
        backgroundColor: theme.color.pearl,
        zIndex: theme.zIndex.expanded,
        outline: 'none',
        border: 'none',
    },
    toggle: {
        height: 24,
        width: 24,
    },
    pesticideDataWrapper: {
        position: 'relative',
        marginTop: 10,
    },
    testSystemTextContainer: {
        color: theme.color.test,
        fontSize: 13,
        fontWeight: 600,
        textTransform: 'lowercase',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
        marginTop: -4,
        marginBottom: 5,
    },
    svgStone: {
        height: 20,
        width: 20,
        fill: theme.color.stone,
        marginRight: 3,
    },
    svgGreen: {
        height: 20,
        width: 20,
        fill: theme.color.main,
        marginRight: 3,
    },
    svgWeb: {
        height: 18,
        width: 20,
        fill: theme.color.main,
        marginRight: 3,
    },
}));

export default function BusinessCardModal(props) {
    const { show, onHide, versionNumber, versionDate } = props;
    const { t } = useTranslate();
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    const [pesticideUpdateDate, setPesticideUpdateDate] = useState<any>();
    const isTestSystem = useSelector((state: any) => state.system.systemInfo.isTestSystem);
    const countryCode = useSelector((state: any) => state.system.systemInfo.countryCode);
    const pesticideConnectionId = useSelector((state: any) => state.company.company.globalData.pesticideConnectionId);
    const classes = useStyles();

    useEffect(() => {
        let isCancelled = false;

        async function loadPesticideUpdateDate() {
            try {
                const updateDate = await pesticideService.getUpdateDate({ pesticideConnectionId });
                if (!isCancelled) {
                    const dateObject = dateTimeUtils.getMomentObjectFromDateString(updateDate?.lastUpdate);
                    const formattedDate = dateTimeUtils.getDate(dateObject);
                    setPesticideUpdateDate(formattedDate);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }

        if (location && show && isNumber(pesticideConnectionId)) {
            if (+pesticideConnectionId > 0) {
                loadPesticideUpdateDate();
            } else {
                setPesticideUpdateDate('-');
            }
        }

        return () => { isCancelled = true; };
    }, [dispatch, location, show, pesticideConnectionId]);

    function getEmail() {
        switch (countryCode) {
            case COUNTRY.HUNGARY:
                return isArray(DEFAULT_SYSTEM_DATA.EMAIL.HU)
                    ? DEFAULT_SYSTEM_DATA.EMAIL.HU?.map((emailAdress, index) => (<div key={index}>{emailAdress}</div>))
                    : DEFAULT_SYSTEM_DATA.EMAIL.HU;
            case COUNTRY.ROMANIA:
                return isArray(DEFAULT_SYSTEM_DATA.EMAIL.RO)
                    ? DEFAULT_SYSTEM_DATA.EMAIL.RO?.map((emailAdress, index) => (<div key={index}>{emailAdress}</div>))
                    : DEFAULT_SYSTEM_DATA.EMAIL.RO;
            default:
                return isArray(DEFAULT_SYSTEM_DATA.EMAIL.DEFAULT)
                    ? DEFAULT_SYSTEM_DATA.EMAIL.DEFAULT?.map((emailAdress, index) => (<div key={index}>{emailAdress}</div>))
                    : DEFAULT_SYSTEM_DATA.EMAIL.DEFAULT;
        }
    }

    function getPhone() {
        switch (countryCode) {
            case COUNTRY.HUNGARY:
                return isArray(DEFAULT_SYSTEM_DATA.PHONE.HU)
                    ? DEFAULT_SYSTEM_DATA.PHONE.HU?.map((phoneNumber, index) => (<div key={index}>{phoneNumber}</div>))
                    : DEFAULT_SYSTEM_DATA.PHONE.HU;
            case COUNTRY.ROMANIA:
                return isArray(DEFAULT_SYSTEM_DATA.PHONE.RO)
                    ? DEFAULT_SYSTEM_DATA.PHONE.RO?.map((phoneNumber, index) => (<div key={index}>{phoneNumber}</div>))
                    : DEFAULT_SYSTEM_DATA.PHONE.RO;
            case COUNTRY.ARGENTINA:
                return isArray(DEFAULT_SYSTEM_DATA.PHONE.AR)
                    ? DEFAULT_SYSTEM_DATA.PHONE.AR?.map((phoneNumber, index) => (<div key={index}>{phoneNumber}</div>))
                    : DEFAULT_SYSTEM_DATA.PHONE.AR;
            default:
                return isArray(DEFAULT_SYSTEM_DATA.PHONE.DEFAULT)
                    ? DEFAULT_SYSTEM_DATA.PHONE.DEFAULT?.map((phoneNumber, index) => (<div key={index}>{phoneNumber}</div>))
                    : DEFAULT_SYSTEM_DATA.PHONE.DEFAULT;
        }
    }

    function getSiteLink() {
        switch (countryCode) {
            case COUNTRY.HUNGARY:
                return DEFAULT_SYSTEM_DATA.SITE.HU.LINK;
            case COUNTRY.ROMANIA:
                return DEFAULT_SYSTEM_DATA.SITE.RO.LINK;
            case COUNTRY.SLOVAKIA:
                return DEFAULT_SYSTEM_DATA.SITE.SK.LINK;
            case COUNTRY.ARGENTINA:
                return DEFAULT_SYSTEM_DATA.SITE.AR.LINK;
            default:
                return DEFAULT_SYSTEM_DATA.SITE.DEFAULT.LINK;
        }
    }

    function getSiteName() {
        switch (countryCode) {
            case COUNTRY.HUNGARY:
                return DEFAULT_SYSTEM_DATA.SITE.HU.NAME;
            case COUNTRY.ROMANIA:
                return DEFAULT_SYSTEM_DATA.SITE.RO.NAME;
            case COUNTRY.SLOVAKIA:
                return DEFAULT_SYSTEM_DATA.SITE.SK.NAME;
            case COUNTRY.ARGENTINA:
                return DEFAULT_SYSTEM_DATA.SITE.AR.NAME;
            default:
                return DEFAULT_SYSTEM_DATA.SITE.DEFAULT.NAME;
        }
    }

    return (
        <Modal show={show} onHide={onHide} aria-labelledby="contained-modal-title-vcenter" centered className={classes.modal}>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    <h4>{t('userMenuFooter.businessCard', 'Business card')}</h4>
                </Modal.Title>
                <button className={classes.close} type="button" onClick={onHide}>
                    <Svg iconId="icon-close" style={classes.toggle} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <Row className={classes.row}>
                    <Col>
                        <div className={classes.logo} />
                    </Col>
                    <Col className={classes.contentRight}>
                        <label className={classes.version}>
                            {versionNumber}
                        </label>
                        {isTestSystem && (
                            <div className={classes.testSystemTextContainer}>{t('login.testSystem', 'Test system')}</div>
                        )}
                        <div className={classes.updateDate}>
                            {t('businessCardModal.timeOfUpdate', 'time of update')}
                            {`: ${dateTimeUtils.getDate(versionDate)}`}
                        </div>
                        <div className={classes.pesticideDataWrapper}>
                            <LoadSpinner value={pesticideUpdateDate} isAutoDetect />
                            <div className={classes.date}>
                                <Svg iconId="icon-pp_datasheet" style={classes.svgStone} />
                                {t('businessCardModal.pesticideDb', 'Pesticides database')}
                            </div>
                            <div className={classes.date}>
                                {t('businessCardModal.timeOfUpdate', 'time of update')}
                                {!isEmpty(pesticideUpdateDate) ? `: ${pesticideUpdateDate}` : ': -'}
                            </div>
                        </div>
                        <label className={classes.firm}>
                            {t('businessCardModal.agrovirLtd', 'AgroVIR Ltd.')}
                        </label>
                        <label className={classes.otherLabel}>
                            {DEFAULT_SYSTEM_DATA.HEADQUARTER}
                        </label>
                        <div className={classes.otherDiv}>
                            <div>
                                <Svg iconId="icon-phone" style={classes.svgGreen} />
                            </div>
                            <div>
                                {getPhone()}
                            </div>
                        </div>
                        <div className={classes.otherDiv}>
                            <div>
                                <Svg iconId="icon-atSign" style={classes.svgGreen} />
                            </div>
                            <div>
                                {getEmail()}
                            </div>
                        </div>
                        <label className={classes.otherLabel}>
                            <a target="_blank" rel="noopener noreferrer" href={`${getSiteLink()}`}>
                                <Svg iconId="icon-web" style={classes.svgWeb} />
                                {getSiteName()}
                            </a>
                        </label>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button type="secondary" onClick={onHide}>
                    {t('default.close', 'Close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
