/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isEmpty, isFunction } from 'lodash';
import { MODULES } from 'shared/src/routing';

import { SETTINGS } from 'shared/src/constants';
import { stringUtils } from 'shared/src/modules';
import { routerHelper } from '@utils';
import { dataListsActions, errorActions, userActions, companyActions } from '@states/actions';
import {
    productionYearService,
    cultureService,
    farmService,
    userService,
    companyService,
    economicUnitService,
    machineService,
    employeeService,
    reproductionRateService,
    warehouseService,
    workOperationsService,
} from '@services';
import { phpDataListReloadEndpoints } from './phpDataListReloadEndpoints';

export function useDataListPhpHandler() {
    const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
    const isPlanningmodeActive = useSelector((state: any) => state.system.planningModeisActive);
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const [previousLocation, setPreviousLocation] = useState(null);
    const [removedDataListNames, setRemovedDataListNames] = useState<any>([]);

    function getCompanyIds() {
        return dispatch(companyActions.getActiveCompanyList());
    }

    //TODO: this list should be expanded with new list
    const [dataListDetails, setDataListDetails] = useState([
        {
            listName: 'productionYears',
            reloadEndpoints: phpDataListReloadEndpoints.periodYearEndpoints,
            requestFunction: productionYearService.getProductionYears,
        },
        {
            listName: 'cultures',
            reloadEndpoints: phpDataListReloadEndpoints.cultureEndpoints,
            requestFunction: cultureService.getCultures,
        },
        {
            listName: 'economicUnitList',
            reloadEndpoints: phpDataListReloadEndpoints.economicUnitsEndpoints,
            requestFunction: economicUnitService.getEconomicUnitList,
            requestParams: getCompanyIds,
        },
        {
            listName: 'machineList',
            reloadEndpoints: phpDataListReloadEndpoints.machinesEndpoints,
            requestFunction: machineService.getMachineList,
            requestParams: getCompanyIds,
        },
        {
            listName: 'employeeList',
            reloadEndpoints: phpDataListReloadEndpoints.employeesEndpoints,
            requestFunction: employeeService.getEmployees,
            requestParams: getCompanyIds,
        },
        {
            listName: 'reproductionRates',
            reloadEndpoints: phpDataListReloadEndpoints.reproductionRatesEndpoints,
            requestFunction: reproductionRateService.getReproductionRates,
        },
        {
            listName: 'warehouses',
            reloadEndpoints: phpDataListReloadEndpoints.warehousesEndpoints,
            requestFunction: warehouseService.getWarehouses,
        },
        {
            listName: 'farms',
            reloadEndpoints: phpDataListReloadEndpoints.farmEndpoints,
            requestFunction: farmService.getFarms,
        },
        {
            listName: 'qualificationParameters',
            reloadEndpoints: phpDataListReloadEndpoints.workoperationQualificationsEndpoints,
            requestFunction: workOperationsService.getWorkOperationQualificationParameters,
        },
        /*{
            reloadEndpoints: phpDataListReloadEndpoints.cultureVarietyEndpoints,
            listName: 'cultureVarieties',
            requestFunction: cultureService.getCultures,
        },*/
    ]);

    //user permission and company settings
    const [otherListsDetails] = useState([
        {
            reloadEndpoints: phpDataListReloadEndpoints.userRightsEndpoints,
            requestFunction: userService.getRights,
            setterFunction: userActions.setRights,
            isDeleted: false,
        },
        {
            reloadEndpoints: phpDataListReloadEndpoints.companySettingsEndpoints,
            requestFunction: companyService.getSettings,
            setterFunction: companyActions.setSettings,
            isDeleted: false,
        },
        {
            reloadEndpoints: phpDataListReloadEndpoints.userDatasEndpoints,
            requestFunction: userService.getCurrentUser,
            setterFunction: userActions.setUserData,
            isDeleted: false,
        },
        {
            reloadEndpoints: phpDataListReloadEndpoints.numberFormatSettingsEndpoints,
            requestFunction: companyService.getNumberFormatSettings,
            setterFunction: companyActions.setNumberFormatSettings,
            isDeleted: false,
        },
    ]);

    //check settings and add data list items
    useEffect(() => {
        if (isLoggedIn && dispatch) {
            const usingFarm = dispatch(companyActions.getSetting(SETTINGS.USING_FARM)) as any;
            if (usingFarm) {
                setDataListDetails(currentState => ([...currentState, {
                    listName: 'farms',
                    reloadEndpoints: phpDataListReloadEndpoints.farmEndpoints,
                    requestFunction: farmService.getFarms,
                }]));
            }
        }
    }, [dispatch, isLoggedIn]);

    //on leave php - remove list
    useEffect(() => {
        function removeDataList(dataListName: string) {
            if (isEmpty(removedDataListNames) || (removedDataListNames && !removedDataListNames?.includes(dataListName))) {
                dispatch(dataListsActions.removeDataList(dataListName));
                setPreviousLocation(currentState => (currentState !== location ? location : currentState));
                removedDataListNames.push(dataListName);
            }
        }

        //exceptions - php betöltésekor már reseteljük a listát
        if (location && dataListDetails && otherListsDetails && isLoggedIn) {
            const moduleContentName = routerHelper.getModuleContentNameByUrl(location);
            const productionYearsDataList: any = dataListDetails?.find(dataListObj => dataListObj.listName === 'productionYears');
            const moduleName = MODULES.CULTIVATION_PERIODS.NAME;
            const contentName = routerHelper.getContentName(MODULES.CULTIVATION_PERIODS.CONTENTS.DISASSEMBLY);

            if (moduleContentName === `${moduleName}/${contentName}`
                && !isEmpty(productionYearsDataList) && !!isPhpEditLocation(moduleContentName, productionYearsDataList.reloadEndpoints)
            ) {
                removeDataList(productionYearsDataList.listName);
            }
        }

        return () => {
            if (location && dataListDetails && otherListsDetails && isLoggedIn) {
                const moduleContentName = routerHelper.getModuleContentNameByUrl(location);

                dataListDetails.forEach(dataList => {
                    if (!isEmpty(dataList) && isPhpEditLocation(moduleContentName, dataList.reloadEndpoints)) {
                        removeDataList(dataList.listName);
                    }
                });

                otherListsDetails.forEach(dataList => {
                    if (isPhpEditLocation(moduleContentName, dataList.reloadEndpoints)) {
                        dataList.isDeleted = true;
                        setPreviousLocation(currentState => (currentState !== location ? location : currentState));
                    }
                });
            }
        };
    }, [location, dispatch, dataListDetails, otherListsDetails, isLoggedIn, removedDataListNames]);

    //if open a react page - load again required lists
    useEffect(() => {
        let isCancelled = false;

        function isPhpLocation(url) {
            const contentObject = routerHelper.getContentObjectByUrl(url);
            return contentObject && contentObject.COMPONENT_URL === 'contents/PhpContent';
        }

        async function reloadDataToRedux(dataList) {
            try {
                const dataListResult = await dataList.requestFunction(); //request data
                if (!isCancelled && dataListResult) {
                    dispatch(dataList.setterFunction(dataListResult)); //set data in redux
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'DataListPhpHandlerError'));
            }
        }

        //load data lists
        if (previousLocation && removedDataListNames && location && !isPhpLocation(location) && isLoggedIn) {
            if (!isEmpty(removedDataListNames)) {
                removedDataListNames.forEach(dataListName => {
                    loadReactDataListByName(dataListName);
                });
            }
        }

        //load other lists
        if (previousLocation && otherListsDetails && location && !isPhpLocation(location) && isLoggedIn) {
            otherListsDetails.forEach(dataList => {
                if (dataList.isDeleted) {
                    reloadDataToRedux(dataList);
                    dataList.isDeleted = false;
                }
            });

            setPreviousLocation(() => null);
            setRemovedDataListNames(() => []);
        }

        return () => {
            isCancelled = true;
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, previousLocation, removedDataListNames, isLoggedIn]);

    useEffect(() => {
        (window as any).loadReactDataListByName = loadReactDataListByName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //on logout reset lists
    useEffect(() => {
        if (!isLoggedIn) {
            setRemovedDataListNames([]);
            setPreviousLocation(null);
        }
    }, [isLoggedIn]);

    function isPhpEditLocation(pathname, endpointList) {
        if (isEmpty(endpointList) || !pathname) {
            return false;
        }
        return endpointList.find(endpoint => stringUtils.isSame(pathname, endpoint));
    }

    function loadReactDataListByName(dataListName) {
        async function loadDataList(endPointFunction, reduxSetter, listName, endPointParamsObj) {
            try {
                let requestParams = isFunction(endPointParamsObj) ? endPointParamsObj() : endPointParamsObj;

                //exceptions
                if (listName === 'partnerAndCompany') {
                    requestParams = { companyIds: requestParams };
                }

                if (listName === 'productionYears') {
                    requestParams = {};
                    if (isPlanningmodeActive) {
                        requestParams = { isPlanning: 1 };
                    }
                }

                const dataList = await endPointFunction(requestParams);
                dispatch(reduxSetter(dataList, listName));
            } catch (error) {
                dispatch(errorActions.setError(error, 'DataListPhpHandlerError-load react data'));
            }
        }

        const editetDataListObject = dataListDetails.find(dataList => dataList.listName === dataListName);
        if (editetDataListObject) {
            loadDataList(editetDataListObject.requestFunction, dataListsActions.addDataList, editetDataListObject.listName, editetDataListObject.requestParams);
        }
    }
}
