import { createUseStyles } from 'react-jss';
import { useNumberFormat } from '@common_modules/numberFormat';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        height: 18,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        color: theme.color.jet,
        display: 'block',
        width: '100%',
        textAlign: 'right',
    },
}));

export default function Quantity(props) {
    const { value, unit, style, cellId } = props;
    const classes = useStyles();
    const { numberFormatter } = useNumberFormat();

    return (
        <div id={cellId} className={clsx(classes.baseStyle, style)}>
            {numberFormatter.getFormattedProductQuantity(value) || 0} {unit}
        </div>
    );
}
