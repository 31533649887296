import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    errorContainer: {
        width: 500,
        margin: 'auto',
        textAlign: 'center',
    },
    avLogo: {
        width: 106,
        margin: '30px auto',
    },
    center: {
        padding: 100,
        background: '#FFFFFF',
        boxShadow: '0 2px 33px 0 rgba(0,0,0,0.15)',
    },
    logoContainer: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: 50,
        color: '#252525',
    },
    textContainer: {
        fontSize: 16,
        color: '#252525',
        marginTop: 10,
    },
});

export default function Error400() {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.errorContainer}>
            <div className={classes.avLogo}>
                <Image src="/styles/img/av_small_logo.png" alt="Logo" width="106" height="38" />
            </div>
            <div className={classes.center}>
                <div className={classes.logoContainer}>
                    <Image src="/styles/img/av_logo_symbol.png" alt="Logo" width="100" height="100" />
                    400
                </div>
                <div className={classes.textContainer}>
                    <span>{t('errorPage.notAvailable', 'The service is not available at this address.') as any}</span>
                </div>
            </div>
        </div>
    );
}
