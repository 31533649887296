import { RIGHTS, PLANNING as PLANNING_CONSTANTS } from '../constants';

export const PRODUCT = {
    MODULE_ID: 5,
    NAME: 'product',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'product',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.product',
                TRANSLATOR_DEFAULT: 'Product',
            },
        },
        PRICES: {
            CONTENT_URL_ID: { prices: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'prices',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.prices',
                TRANSLATOR_DEFAULT: 'Prices',
            },
            RIGHTS: [RIGHTS.PRODUCTS_PRICES_INTERFACE],
        },
        PRODUCTS: {
            CONTENT_URL_ID: { products: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'products',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.products',
                TRANSLATOR_DEFAULT: 'Products',
            },
            RIGHTS: [RIGHTS.PRODUCT_INTERFACE],
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.STOCK,
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        PRODUCTS_EDIT: {
            CONTENT_URL_ID: { productsEdit: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'productsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productsEdit',
                TRANSLATOR_DEFAULT: 'Products edit',
            },
            RIGHTS: [RIGHTS.ADDING_PRODUCT,RIGHTS.EDIT_PRODUCT],
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.STOCK,
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        UNIT_OF_MEASUREMENT: {
            CONTENT_URL_ID: { unitOfMeasurement: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'unitOfMeasurement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.unitOfMeasurement',
                TRANSLATOR_DEFAULT: 'Units of measurement',
            },
        },
        VAT_RATES: {
            CONTENT_URL_ID: { vatRates: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'vatRates',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.vatRates',
                TRANSLATOR_DEFAULT: 'Vat rates',
            },
        },
        PESTICIDES_DATABASE: {
            CONTENT_URL_ID: { pesticidesDatabase: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'pesticidesDatabase',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.pesticidesDatabase',
                TRANSLATOR_DEFAULT: 'Pesticides database',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        SUB_TYPES: {
            CONTENT_URL_ID: { subTypes: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'subTypes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.subTypes',
                TRANSLATOR_DEFAULT: 'Sub types',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        PESTICIDES: {
            CONTENT_URL_ID: { pesticides: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'pesticides',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.pesticides',
                TRANSLATOR_DEFAULT: 'Pesticides',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        SALES_PRICES: {
            CONTENT_URL_ID: { salesPrices: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'salesPrices',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.salesPrices',
                TRANSLATOR_DEFAULT: 'Sales prices',
            },
            RIGHTS: [RIGHTS.SALES_PRICES_INTERFACE],
        },
        DEFAULT_WAREHOUSE: {
            CONTENT_URL_ID: { defaultWarehouse: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'defaultWarehouse',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.defaultWarehouse',
                TRANSLATOR_DEFAULT: 'Default warehouse',
            },
            RIGHTS: [RIGHTS.DEFAULT_WAREHOUSE_INTERFACE],
        },
    },
};
