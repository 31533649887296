import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useFormikContext } from 'formik';
import { isEmpty, isNumber } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import Svg from '@baseComponents/Svg';
import { useSelector } from 'react-redux';
import SearchBar from '@baseComponents/inputs/SearchBar';

const useStyles = createUseStyles((theme: any) => ({
    header: {
        display: 'flex',
        paddingLeft: 30,
        ...theme.firstLetterUppercase,
    },
    expand: {
        display: 'inline-block',
        cursor: 'pointer',
        height: 30,
        width: 30,
        borderRadius: '50%',
        backgroundColor: theme.color.pearl,
        outline: 'none',
        marginBottom: 15,
    },
    toggle: {
        marginLeft: 3,
        marginTop: 3,
        height: 24,
        width: 24,
    },
    rightHeader: {
        marginLeft: 'auto',
        marginRight: 30,
        display: 'flex',
    },
    onlyChecked: {
        marginRight: 15,
    },
}));

export default function RegistryNumberHeader(props) {
    const { close } = props;
    const { t } = useTranslate();
    const { values, setValues } = useFormikContext();
    const [onlyChecked, setOnlyChecked] = useState();
    const [registryNumberSearch, setRegistryNumberSearch] = useState();
    const selectedItemIds = useSelector((state: any) => state.simpleTable.table.registryNumber?.selectedItemIds);
    const classes = useStyles();

    useEffect(() => {
        if (isNumber(values.ownerType)) {
            setOnlyChecked(false);
        }
    }, [values.owner, values.ownerType]);

    function searchOnRegistryNumbers(searchValue) {
        let filteredTableData = onlyChecked ? values.selectedRegistryNumbers : values.allRegistryNumbers;
        if (searchValue && !isEmpty(filteredTableData)) {
            filteredTableData = filteredTableData.filter(
                regNumberData => regNumberData.name?.toLowerCase().includes(searchValue)
                        || regNumberData.settlement?.postCode?.toLowerCase().includes(searchValue)
                        || regNumberData.settlement?.name?.toLowerCase().includes(searchValue)
                        || regNumberData.ak?.toLowerCase().includes(searchValue)
                        || regNumberData.ownerName?.toLowerCase().includes(searchValue),
            );
        }
        if (!onlyChecked && !isEmpty(values.selectedRegistryNumbers) && !isEmpty(filteredTableData)) {
            filteredTableData = filteredTableData.map(dataObj => {
                const selectedRow = values.selectedRegistryNumbers.find(regNumberData => dataObj.id === regNumberData.id);
                return selectedRow || dataObj;
            });
        }
        setValues({
            ...values,
            registryNumberIds: selectedItemIds || values.registryNumberIds,
            registryNumber: filteredTableData,
        });
    }

    return (
        <div className={classes.header}>
            <div className={classes.expand} onClick={close} onKeyDown={close} role="button" tabIndex={0}>
                <Svg iconId="icon-chevron_left" style={classes.toggle} />
            </div>
            <h2>
                {t('registryNumberData.registryNumber', 'Registry number')}
            </h2>
            <div className={classes.rightHeader}>
                <SearchBar
                    height="36"
                    onChange={searchOnRegistryNumbers}
                    currentSearchValue={registryNumberSearch}
                    setSearchValue={setRegistryNumberSearch}
                />
            </div>
        </div>
    );
}
