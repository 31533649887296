// eslint-disable-next-line no-restricted-imports
import { useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import MapIconButton from '../MapIconButton';

type Tprops = {
    onClick: (isVisible: boolean) => void
}

export default function MapSliderButton({ onClick }: Tprops) {
    const [isSliderVisible, setIsSliderVisible] = useState(false);

    const onButtonClick = () => {
        setIsSliderVisible(isVisible => {
            onClick(!isVisible);
            return (!isVisible);
        });
    };

    return (
        <MapIconButton iconId="icon-view_timeline" onClick={() => onButtonClick()} isActive={isSliderVisible} />
    );
}
