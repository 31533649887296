import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import useTranslate from '@i18n/useTranslate';
import FormDatePicker from '../formCalendars/FormDatePicker';

const useStyles = createUseStyles((theme: any) => ({
    inputSpace: {
        marginTop: 15,
    },
    datePicker: {
        display: 'flex',
    },
    dateSeparator: {
        width: 6,
        height: 40,
        borderBottom: '1px solid',
        borderColor: theme.color.gray,
        margin: '0px 5px',
        marginTop: props => props.disabled && -15,
    },
}));
export default function DoubleDatePicker(props) {
    const { startDate, setStartDate, endDate, setEndDate, validation, disabled, className, scrollRef, required = true } = props;
    const { t } = useTranslate();
    const classes = useStyles({ validation, disabled });

    return (
        <div className={clsx(classes.inputSpace, className)}>
            <div className={classes.datePicker}>
                <FormDatePicker
                    className={classes.start}
                    date={startDate}
                    setDate={setStartDate}
                    maxDate={endDate}
                    label={!disabled && t('datePicker.start', 'Start')}
                    invalid={validation && !!validation.startDate}
                    errorMessage={validation && validation.startDate}
                    required={(!disabled && required)}
                    disabled={disabled}
                    showDate
                    scrollRef={scrollRef}
                />
                <div className={classes.dateSeparator} />
                <FormDatePicker
                    className={classes.end}
                    date={endDate}
                    setDate={setEndDate}
                    minDate={startDate}
                    label={!disabled && t('datePicker.end', 'End')}
                    disabled={disabled}
                    showDate
                    scrollRef={scrollRef}
                />
            </div>
        </div>
    );
}
