import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { basePageActions } from '@states/actions/basePageActions';
import { simpleTableActions } from '@states/actions/simpleTableActions';

export default function useTableHeaderSearchInputHandler(props) {
    const { columnId, isBasePageTable, tableKey } = props;
    const isEnabledSearchingSimpleTable = useSelector((state: any) => state.simpleTable.table[tableKey]?.isEnabledSearching);
    const dispatch = useDispatch();

    //simple table remove global search value
    useEffect(() => {
        if (tableKey && !isEnabledSearchingSimpleTable && columnId && dispatch) {
            if (!isBasePageTable) {
                dispatch(simpleTableActions.removeTableGlobalColumnSearchValue(tableKey, columnId));
            }
        }
    }, [isEnabledSearchingSimpleTable, isBasePageTable, columnId, tableKey, dispatch]);

    function onChangeInputValue(searchValue) {
        if (columnId) {
            addColumnSearchValue(searchValue);
        }
    }

    function addColumnSearchValue(searchValue) {
        if (isBasePageTable) {
            dispatch(basePageActions.addTableGlobalColumnSearchValue(columnId, searchValue));
        } else if (tableKey) {
            dispatch(simpleTableActions.addTableGlobalColumnSearchValue(tableKey, columnId, searchValue));
        }
    }

    return {
        onChangeInputValue,
    };
}
