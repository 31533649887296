import { isFunction, isEmpty, values } from 'lodash';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import { theme as themes } from '@styles/theme';
import { UNITS } from 'shared/src/constants';

export default function getAllColumns(t, translater, showedColumns) {
    const columns = {
        addIcon: {
            type: columnName.iconButton,
            columnId: 'icon-add',
            componentProps: {
                icon: 'icon-add',
                getCellTitle: () => translater('default.select', 'Select'),
                getBackgroundColor: () => themes.color.main,
                getIconColor: () => themes.color.white,
                onClick: (rowData, onAddButtonClicked) => {
                    if (isFunction(onAddButtonClicked)) {
                        onAddButtonClicked(rowData);
                    }
                },
            },
            isLeftFixed: true,
            width: 80,
            minWidth: 80,
            maxWidth: 80,
        },
        name: {
            type: columnName.simpleText,
            columnId: 'name',
            columnName: t('registryNumberData.registryNumber', 'Registry number'),
            isLeftFixed: true,
            width: 250,
            // minWidth: 170,
            // maxWidth: 200,
        },
        postCode: {
            type: columnName.doubleText,
            columnId: 'settlement.postCode',
            columnName: t('registryNumberData.settlement', 'Place'),
            width: 200,
            minWidth: 160,
            maxWidth: 200,
            componentProps: {
                secondValueId: 'settlement.name',
                firstLineId: 'settlement.postCode',
                secondLineId: 'settlement.name',
            },
        },
        ownerName: {
            type: columnName.simpleText,
            columnId: 'ownerName',
            columnName: t('registryNumberData.owner', 'Owner'),
            width: 170,
            minWidth: 170,
        },
        sizeOfTheArea: {
            type: columnName.area,
            columnId: 'sizeOfTheArea',
            columnName: t('registryNumberData.size', 'Size'),
            width: 100,
        },
        ak: {
            type: columnName.decimal,
            columnId: 'ak',
            columnName: t('registryNumberData.value', 'Value'),
            width: 100,
            componentProps: {
                unit: translater('newCultivationPeriod.registryNumberHeaderTextAk', 'GCV'),
                decimal: UNITS.DECIMAL_NUMBER.REGISTRY_NUMBER_AREA,
            },
        },
    };

    if (!isEmpty(showedColumns)) {
        const filteredColumns = [];
        const columnObj = Object.entries(columns);

        showedColumns.forEach(showedColName => {
            const column = columnObj.find(columnParts => columnParts[0].toString() === showedColName);
            if (column) {
                filteredColumns.push(column[1]);
            }
        });

        return filteredColumns;
    }

    return values(columns);
}
