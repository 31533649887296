import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { isArray } from 'lodash';
import useSoilAnalysisViewTable from './useSoilAnalysisViewTable';

const useStyles = createUseStyles((theme: any) => ({
    table: {
        position: 'relative',
        height: '100%',
        padding: 30,
        backgroundColor: theme.color.lilian,
    },
    tableWrapper: {
        height: '100%',
        '& .table-row': {
            paddingRight: 0,
        },
        '& .tableHeaderRow': {
            marginRight: 0,
        },
        '& .List': {
            ...theme.verticalScrollbar,
        },
    },
    tableScrollerFooter: {
        paddingTop: 7,
        paddingLeft: 30,
        paddingRight: 30,
        background: theme.color.transparent,
    },
}));

export default function SoilAnalysisViewTable(props) {
    const { cultivationPeriodId } = props;
    const [isLoadTableData, setIsLoadTableData] = useState(true);
    const classes = useStyles();

    const [tableGroup] = useState(
        {
            value: null,
            text: 'No breakdown',
        },
    );

    const { columns, soilAnalysisTableData } = useSoilAnalysisViewTable({ cultivationPeriodId, setIsLoadTableData });

    return (
        <>
            <div className={classes.table}>
                <LoadSpinner isAutoDetect value={!isLoadTableData} />
                <TableGroup
                    tableId="SoilAnalysisViewTableData"
                    tableData={soilAnalysisTableData}
                    tableColumns={columns}
                    isBasePageTable={false}
                    isCollapsible={false}
                    tableGroup={tableGroup}
                    onListItemsRendered={() => setIsLoadTableData(false)}
                    className={classes.tableWrapper}
                />
            </div>
            <div className={classes.tableScrollerFooter}>
                <TableScrollerFooter tableKey="SoilAnalysisViewTableData" isLoaded={isArray(soilAnalysisTableData)} />
            </div>
        </>
    );
}
