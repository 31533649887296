import BasicTag from '@baseComponents/tags/BasicTag';
import useTranslate from '@i18n/useTranslate';
import { isFunction, isNil } from 'lodash';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { MapFilterSchema } from './MapFilterSchema';

type TProps = {
    filterSubmit?: (formData: MapFilterSchema) => void
}

export default function MapFilterCultureSelected({ filterSubmit }: TProps) {
    const filterContext = useFormContext<MapFilterSchema>();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const cultures = filterContext.watch('cultures') ?? [];
    const { translater } = useTranslate();

    const onRemovePartners = () => {
        filterContext.setValue('cultures', []);
        filterContext.setValue('cultureIds', []);

        if (isFunction(filterSubmit)) {
            filterContext.handleSubmit(filterSubmit)();
        }
    };

    const titlePrefix = useMemo(() => (isNil(filterSubmit) ? '' : `${translater('filterTitle.cultureIds')}: `), [filterSubmit]);

    const tagTitle = useMemo(() => {
        if (!isNil(cultures)) {
            if (cultures.length > 0) {
                if (cultures.length === 1) {
                    return (`${titlePrefix}${cultures?.[0].cultureName}`);
                }
                return (`${titlePrefix}${cultures.length} ${translater('filterTag.itemsSelected')}`);
            }
        }
    }, [cultures, translater, titlePrefix]);

    if (!isNil(tagTitle)) {
        return (
            <BasicTag
                onClose={onRemovePartners}
                title={tagTitle}
                tagId={1}
                filterName="filterName"
                fullWidth={!isFunction(filterSubmit)}
            />
        );
    }

    return null;
}
