import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { UNITS, DATE } from 'shared/src/constants';
import Svg from '@baseComponents/Svg';
import { dateTimeUtils } from 'shared/src/modules';

const useStyles = createUseStyles((theme: any) => ({
    weatherForecastContainer: {
        width: '100%',
    },
    cardHeader: {
        display: 'flex',
        width: '100%',
        paddingBottom: 10,
        borderBottom: '1px solid',
        borderBottomColor: theme.color.pearl,
    },
    time: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 1.25,
    },
    temperature: {
        display: 'block',
        color: theme.color.raven,
        fontSize: 14,
        lineHeight: 1.429,
    },
    bigWeatherIcon: {
        height: 30,
        width: 30,
        marginLeft: 'auto',
    },
    smallWeatherIcon: {
        height: 15,
        width: 15,
        marginRight: 5,
        fill: theme.color.raven,
    },
    weatherDataRow: {
        width: '100%',
        height: 26,
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderBottomColor: theme.color.pearl,
    },
    weatherData: {
        width: 75,
        fontSize: 12,
        lineHeight: 1.25,
    },
    separator: {
        borderRight: '1px solid',
        borderRightColor: theme.color.pearl,
    },
    day: {
        display: 'flex',
        color: theme.color.raven,
        lineHeight: 1.67,
        marginBottom: 10,
    },
    dayName: {
        fontWeight: 500,
        marginRight: 5,
        ...theme.firstLetterUppercase,
    },
    dateName: {
        ...theme.firstLetterUppercase,
    },
    wind: {
        transform: props => props.windDirection && `rotate(${props.windDirection}deg)`,
    },
    paddingLeft: {
        paddingLeft: 5,
    },
    weatherForecastDataContainer: {
        width: 150,
        minWidth: 100,
        maxWidth: 150,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default function WeatherForecastData(props) {
    const { weatherData, isDateHeader, breakdown } = props;
    const classes = useStyles({ isDateHeader, windDirection: weatherData?.windDirection });

    function getDateTime() {
        if (breakdown === DATE.BREAKDOWN.WEEK && weatherData?.date) {
            return `${dateTimeUtils.getDateMonthShortName(weatherData.date)} ${dateTimeUtils.getDateDay(weatherData.date)}.`;
        }
        return weatherData?.date && dateTimeUtils.getTime(weatherData.date);
    }

    return (
        <div className={classes.weatherForecastContainer}>
            <div className={classes.weatherForecastDataContainer}>
                {isDateHeader && breakdown === DATE.BREAKDOWN.DAY && (
                    <div className={classes.day}>
                        <div className={classes.dayName}>
                            {weatherData?.date && dateTimeUtils.getDateDayName(weatherData.date)}
                        </div>
                        <div className={classes.dateName}>
                            {weatherData?.date && dateTimeUtils.getDateMonthShortName(weatherData.date)} {weatherData?.date && dateTimeUtils.getDateDay(weatherData.date)}
                        </div>
                    </div>)}
                <div className={classes.cardHeader}>
                    <div className={classes.dateName}>
                        <span className={classes.time}>{getDateTime()}</span>
                        <span className={classes.temperature}>{weatherData?.temperature} {UNITS.DEFAULT_TEMPERATURE}</span>
                    </div>
                    <Svg iconId={`icon-${weatherData?.symbol}`} style={classes.bigWeatherIcon} />
                </div>
                <div className={classes.weatherDataRow}>
                    <div className={clsx(classes.weatherData, classes.separator)}>
                        <Svg iconId="icon-wind_direction" style={clsx(classes.smallWeatherIcon, classes.wind)} />
                        {weatherData?.windForce}  {UNITS.DEFAULT_SPEED}
                    </div>
                    <div className={clsx(classes.weatherData, classes.paddingLeft)}>
                        <Svg iconId="icon-rain" style={classes.smallWeatherIcon} />
                        {weatherData?.precipitation}  {UNITS.DEFAULT_PRECIPITATION}
                    </div>
                </div>
                <div className={classes.weatherDataRow}>
                    <div className={clsx(classes.weatherData, classes.separator)}>
                        <Svg iconId="icon-humidity" style={classes.smallWeatherIcon} />
                        {weatherData?.humidity} {UNITS.DEFAULT_HUMIDITY}
                    </div>
                    <div className={clsx(classes.weatherData, classes.paddingLeft)}>
                        <Svg iconId="icon-dew_point" style={classes.smallWeatherIcon} />
                        {weatherData?.dewpoint} {UNITS.DEFAULT_DEW_POINT}
                    </div>
                </div>
            </div>
        </div>
    );
}
