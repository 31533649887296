import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles({
    modalWrapper: {
        height: '100vh',
        width: '100vw',
        display: 'flex',
    },
});

export default function ModalContentWrapper(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.modalWrapper, className)}>
            {children}
        </div>
    );
}
