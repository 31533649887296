import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import SelectToggleButton from '@baseComponents/select/selectParts/SelectToggleButton';
import SelectDropDown from '@baseComponents/select/selectParts/SelectDropDown';
import useSelectEventHandler from '@baseComponents/select/selectParts/useSelectEventHandler';

export default function SimpleSelect(props) {
    const { useAbsoluteMenu, customSelected, dataList, selected, setSelected, onChange, placeholder = null, iconId, addNew, search, disabled, onlyDropdown, emptyOption, onActive, onInactive, alignRight } = props;
    const { isOnlyIconToggle, isIconWithTextToggle, isOpenedDropdown, isClearItem } = props;
    const { className, seletDropdownClassName } = props;
    const { t } = useTranslation();
    const [placeholderText] = useState(placeholder || `${t('default.pleaseSelect', 'Please select')}...`);

    const { selectedItem, isOpened, onChangeSelected, onToggle } = useSelectEventHandler({
        dataList,
        selected,
        customSelected,
        setSelected,
        onChange,
        search,
        disabled,
        onActive,
        onInactive,
    });

    return (
        <Dropdown onSelect={onChangeSelected} onToggle={onToggle} className={className} show={isOpened || isOpenedDropdown} disabled={disabled}>
            <SelectToggleButton
                placeholder={placeholderText}
                selectedItem={selectedItem}
                isOpened={isOpened}
                iconId={iconId}
                disabled={disabled}
                onlyDropdown={onlyDropdown}
                isOnlyIconToggle={isOnlyIconToggle}
                isIconWithTextToggle={isIconWithTextToggle}
                onChangeSelected={onChangeSelected}
                isClearItem={isClearItem}
            />
            <SelectDropDown
                className={seletDropdownClassName}
                dataList={dataList}
                selectedItem={selectedItem}
                addNew={addNew}
                search={search}
                isOpened={isOpened}
                emptyOption={emptyOption}
                alignRight={alignRight}
                useAbsoluteMenu={useAbsoluteMenu}
            />
        </Dropdown>
    );
}
