import { useEffect, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { basePageActions, simpleTableActions } from '@states/actions';
import useTableColumnWidthsInitValues from './useTableColumnWidthsInitValues';

export default function useTableColumnWidths(props) {
    const { listRowRef } = props;
    const { isBasePageTable, tableKey } = props; //simple table
    const dispatch = useDispatch();

    const {
        columns,
        customColumns,
        isEnabledSearching,
    } = useTableColumnWidthsInitValues({ tableKey, isBasePageTable });

    function calculateColumnWidths(disp, rowRef, isBaseTable, tableId) {
        if (rowRef) {
            const headerColumns = rowRef.getElementsByClassName('headerCell');
            const columnWidths = [];

            Object.values(headerColumns).forEach((column, index) => {
                columnWidths[index] = column.offsetWidth;
            });

            if (isBaseTable) { //basePage
                disp(basePageActions.setHeaderColumnWidths(columnWidths));
            } else { //simple table
                disp(simpleTableActions.setColumnWidths(tableId, columnWidths));
            }
        }
    }

    //detect column change
    useEffect(() => {
        if (columns && !isEmpty(listRowRef?.current) && dispatch) {
            setTimeout(() => {
                calculateColumnWidths(dispatch, listRowRef.current, isBasePageTable, tableKey);
            }, 50);
        }
    }, [isEnabledSearching, columns, listRowRef, dispatch, isBasePageTable, tableKey]);

    //detect custom column change
    useEffect(() => {
        if (customColumns && !isEmpty(listRowRef?.current) && dispatch) {
            setTimeout(() => {
                calculateColumnWidths(dispatch, listRowRef.current, isBasePageTable, tableKey);
            }, 50);
        }
    }, [isEnabledSearching, customColumns, listRowRef, dispatch, isBasePageTable, tableKey]);

    useLayoutEffect(() => {
        async function throttle() {
            //throttle(() => calculateColumnWidths(dispatch, listRowRef?.current), 1000, { trailing: false });
            calculateColumnWidths(dispatch, listRowRef?.current, isBasePageTable, tableKey);
        }

        if (!isEmpty(listRowRef?.current)) {
            window.addEventListener('resize', throttle);
        }

        return () => {
            window.removeEventListener('resize', throttle);
        };
    }, [listRowRef, dispatch, isBasePageTable, tableKey]);
}
