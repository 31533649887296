import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { isAgrovirResponsible, isTranslater } from 'shared/src/user/userSettings';
import { MODULES } from 'shared/src/routing';
import { LANGUAGE, COUNTRY, DEFAULT_SYSTEM_DATA } from 'shared/src/constants';
import { languageUtils } from 'shared/src/modules/languageUtils';
import { errorActions } from '@states/actions/errorActions';
import { routerHelper } from '@utils/router/RouterHelper';
import useTranslate from '@i18n/useTranslate';
import { sessionService, systemService } from '@services';

import UserMenuFooterSubMenu from './UserMenuFooterSubMenu';
import BusinessCardModal from '../userModals/BusinessCardModal';

const useStyles = createUseStyles((theme: any) => ({
    footerContainer: {
        width: '100%',
        backgroundColor: theme.color.lilian,
        bottom: 0,
        position: 'sticky',
        display: 'block',
    },
    contentContainers: {
        padding: {
            top: 30,
            left: 30,
        },
    },
    contentTitles: {
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        textTransform: 'uppercase',
    },
    footerContent: {
        margin: '0px 30px',
        padding: '25px 0px',
        borderTop: '1px solid',
        borderTopColor: theme.color.pearl,
        marginTop: 20,
    },
    version: {
        fontWeight: 500,
    },
    linkStyleButton: {
        marginLeft: 5,
        color: theme.color.jet,
        textDecoration: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        border: 'none',
        padding: 0,
        '&:hover': {
            color: theme.color.main,
            textDecoration: 'underline',
        },
    },
    contractDataLink: {
        float: 'right',
    },
}));

export default function UserMenuFooter(props) {
    const { closeUserMenu } = props;
    const user = useSelector((state: any) => state.user.user);
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const countryCode = useSelector((state: any) => state.system.systemInfo.countryCode);
    const companySettings = useSelector((state: any) => state.company.company.settings);
    const subscriptions = useSelector((state: any) => state.company.company.globalData.companySubscriptionPackage);
    const classes = useStyles();

    //version number
    const [versionNumber, setVersionNumber] = useState('');
    const [versionDate, setVersionDate] = useState('');

    useEffect(() => {
        let isCancelled = false;

        async function loadVersion() {
            try {
                const version = await systemService.getVersion();
                if (!isCancelled && !isEmpty(version)) {
                    setVersionNumber(version.version);
                    setVersionDate(version.date);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'UserMenuFooter'));
            }
        }

        if (isEmpty(versionNumber)) {
            loadVersion();
        }

        return () => { isCancelled = true; };
    }, [dispatch, versionNumber]);

    //Business card modal
    const [businessCardModalShow, setBusinessCardModalShow] = useState(false);
    const handleBusinessCardModalClosed = () => setBusinessCardModalShow(false);
    const showBusinessCardModal = () => setBusinessCardModalShow(true);

    //php - temp php load this modal
    //Contract data modal
    /*const [contractDataModalShow, setContractDataModalShow] = useState();
    const handleContractDataModalClosed = () => setContractDataModalShow(false);
    const showContractDataModal = () => setContractDataModalShow(true);*/

    function isVisibleContractMenu() {
        return countryCode === COUNTRY.HUNGARY && !isEmpty(subscriptions);
    }

    return (
        <div className={classes.footerContainer}>
            <div className={classes.contentContainers}>
                <span className={classes.contentTitles}>
                    {t('userMenuFooter.productSupport', 'Product support')}
                </span>

                <UserMenuFooterSubMenu icon="icon-help" text={t('userMenuFooter.agrovirKnowledge', 'AgroVIR knowledge')} redirectUrl={DEFAULT_SYSTEM_DATA.KNOWLEDGE_URL} openInNewTab />

                {//this interface is still under planning
                /*!isConsultant(user) &&
                    <UserMenuFooterSubMenu icon="icon-support" text={t('userMenuFooter.consultancy', 'Consultancy')} redirectUrl={routerHelper.generateUrl(MODULES., MODULES.)} closeUserMenu={closeUserMenu} />
                */
                }

                {isAgrovirResponsible(user) && (
                    <UserMenuFooterSubMenu icon="icon-report" text={t('userMenuFooter.reportDevelopmentRequest', 'Report a development request')} redirectUrl={routerHelper.generateUrl(MODULES.SUPPORT, MODULES.SUPPORT.CONTENTS.COMMENTS)} closeUserMenu={closeUserMenu} />
                )}

                {isTranslater(user) && !languageUtils.isSameLanguage(sessionService.getLangCode(), LANGUAGE.HUNGARIAN) && (
                    <UserMenuFooterSubMenu icon="icon-flag_active" text={t('pageTitle.generalTranslation', 'Translate')} redirectUrl={routerHelper.generateUrl(MODULES.GENERAL, MODULES.GENERAL.CONTENTS.TRANSLATION)} closeUserMenu={closeUserMenu} />
                )}
            </div>
            <div className={classes.footerContent}>
                <span className={classes.version}>{versionNumber}</span>

                <button type="button" className={classes.linkStyleButton} onClick={showBusinessCardModal}>
                    {t('userMenuFooter.businessCard', 'Business card')}
                </button>
                <BusinessCardModal versionNumber={versionNumber} versionDate={versionDate} show={businessCardModalShow} onHide={handleBusinessCardModalClosed} />

                {isVisibleContractMenu() && (
                    <div className={classes.contractDataLink}>
                        <button type="button" className={classes.linkStyleButton} onClick={(window as any).openSubscriptionModal}>
                            {t('userMenuFooter.contractData', 'Contract data')}
                        </button>
                        {/*<ContractDataModal show={contractDataModalShow} onHide={handleContractDataModalClosed} />*/} {/* php - temp php load this modal */}
                    </div>
                )}
            </div>
        </div>
    );
}
