import { RIGHTS } from '../constants';

export const SCALE = {
    MODULE_ID: 13,
    NAME: 'scale',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'scale',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.scale',
                TRANSLATOR_DEFAULT: 'Scale',
            },
        },
        WEIGHT_RECEIPTS: {
            CONTENT_URL_ID: { weightReceipts: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'weightReceipts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.weightReceipts',
                TRANSLATOR_DEFAULT: 'Weight receipts',
            },
            RIGHTS: [RIGHTS.WEIGHT_RECEIPTS_INTERFACE],
        },
        DRYING_DATA: {
            CONTENT_URL_ID: { dryingData: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'dryingData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.dryingData',
                TRANSLATOR_DEFAULT: 'Drying data',
            },
            RIGHTS: [RIGHTS.RECORD_DRYING_DATA],
        },
        WEIGHTING_EDIT: {
            CONTENT_URL_ID: { weighingEdit: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'weighingEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.weighingEdit',
                TRANSLATOR_DEFAULT: 'Weighing edit',
            },
            RIGHTS: [RIGHTS.CREATE_WEIGHTING],
        },
        INCOMPLETE_WEIGHINGS: {
            CONTENT_URL_ID: { incompleteWeighings: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'incompleteWeighings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.incompleteWeighings',
                TRANSLATOR_DEFAULT: 'Incomplete weighings',
            },
            RIGHTS: [RIGHTS.INCOMPLETE_WEIGHINGS_INTERFACE],
        },
        SCALE_HOUSES: {
            CONTENT_URL_ID: { scaleHouses: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'scaleHouses',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.scaleHouses',
                TRANSLATOR_DEFAULT: 'Scale houses',
            },
        },
        SCALES: {
            CONTENT_URL_ID: { scales: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'scales',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.scales',
                TRANSLATOR_DEFAULT: 'Scales',
            },
        },
        MAINTENANCE_OF_EXTERNAL_PLATE_NUMBER_LIST: {
            CONTENT_URL_ID: { maintenanceOfExternalPlateNumberList: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'maintenanceOfExternalPlateNumberList',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.maintenanceOfExternalPlateNumberList',
                TRANSLATOR_DEFAULT: 'Maintenance of external plate number list',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        SIMPLIFIED_WEIGHING: {
            CONTENT_URL_ID: { simplifiedWeighing: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'simplifiedWeighing',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.simplifiedWeighing',
                TRANSLATOR_DEFAULT: 'Simplified weighing',
            },
            RIGHTS: [RIGHTS.WEIGHT_RECEIPTS_INTERFACE],
        },
        SIMPLIFIED_WEIGHING_EDIT: {
            CONTENT_URL_ID: { simplifiedWeighingEdit: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'simplifiedWeighingEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.simplifiedWeighingEdit',
                TRANSLATOR_DEFAULT: 'Simplified weighing edit',
            },
            RIGHTS: [RIGHTS.CREATE_WEIGHTING],
        },
        CHECK_WEIGHING_RECEIPTS: {
            CONTENT_URL_ID: { checkWeighingReceipts: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'checkWeighingReceipts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.checkWeighingReceipts',
                TRANSLATOR_DEFAULT: 'Check weighing receipts',
            },
            RIGHTS: [RIGHTS.CHECK_WEIGHING_RECEIPTS_INTERFACE],
        },
        EXTERNAL_SCALE_SENDING_SETTINGS: {
            CONTENT_URL_ID: { externalScaleSendingSettings: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'externalScaleSendingSettings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.externalScaleSendingSettings',
                TRANSLATOR_DEFAULT: 'External scale sending settings',
            },
            RIGHTS: [RIGHTS.EXTERNAL_SCALE_SOFTWARE_CONNECTION],
        },
        EXTERNAL_SCALE_SENDING_CULTIVATION_PERIOD: {
            CONTENT_URL_ID: { externalScaleSendingCultivationPeriods: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'externalScaleSendingCultivationPeriods',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.externalScaleSendingCultivationPeriods',
                TRANSLATOR_DEFAULT: 'External scale sending cultivation periods',
            },
            RIGHTS: [RIGHTS.EXTERNAL_SCALE_SOFTWARE_CONNECTION],
        },
        EXTERNAL_SCALE_SENDING_PRODUCT: {
            CONTENT_URL_ID: { externalScaleSendingProducts: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'externalScaleSendingProducts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.externalScaleSendingProducts',
                TRANSLATOR_DEFAULT: 'External scale sending products',
            },
            RIGHTS: [RIGHTS.EXTERNAL_SCALE_SOFTWARE_CONNECTION],
        },
        EXTERNAL_SCALE_MEASURE_IMPORT: {
            CONTENT_URL_ID: { externalScaleMeasureImport: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'externalScaleMeasureImport',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.externalScaleMeasureImport',
                TRANSLATOR_DEFAULT: 'External scale measure import',
            },
            RIGHTS: [RIGHTS.EXTERNAL_SCALE_SOFTWARE_CONNECTION],
        }
    },
};