/* eslint-disable no-restricted-syntax */
/* eslint-disable no-magic-numbers */
import { isNull, isNumber as isNumberLodash, isUndefined, isEmpty } from 'lodash';
import { UNITS } from '../constants';

export const numberUtils = {
    getDecimalFormattedValue,
    decimalRound,
    getFormattedValue,
    isNumber,
    isFloat,
    isInteger,
    isBoolean,
    getAreaFormatWithDecimalPoint,
    getBoolean,
    isSame,
    isArrayIndex,
    Round,
    getNumberFormat,
    isPositiveNumber,
    sum,
    decimalToInt,
    isZero,
    truncateSurplusDecimals,
    truncateNumberToSafeMinMax,
    confineNumberToSafeMinMax,
    getNumberFromFormattedString,
    getDisplayedNumberFormat,
};

const MAX_SAFE_INTEGER_ONE_LESS_DIGIT = Math.trunc(Number.MAX_SAFE_INTEGER / 10);
const MIN_SAFE_INTEGER_ONE_LESS_DIGIT = Math.trunc(Number.MIN_SAFE_INTEGER / 10);

function getDecimalFormattedValue(value, fromDecimalPoint = '.', toDecimalPoint = ',') {
    if (!isNumber(value)) {
        return 0;
    }
    return value.toString().replace(fromDecimalPoint, toDecimalPoint);
}

function decimalRound(value, decimals) {
    if (!isNumber(value)) {
        return value;
    }

    if (Number(decimals) === 0) {
        return Math.round(value);
    }

    if (('' + value).includes("e")) {
        const arr = ('' + value).split('e');
        const sig = (+arr[1] + decimals > 0) ? '+' : '';
        return +(Math.round(`${+arr[0]}e${sig}${+arr[1] + decimals}`) + `e-${decimals}`);
    }
    return Number(`${Math.round(`${Number(value)}e${decimals}`)}e-${decimals}`);
}

function getFormattedValue(value, decimals, decimalPoint = ',', thousandsSeparator = ' ') {
    if (!value) {
        return 0;
    }

    let formattedValue = value;
    let decimalNumbers = 2;

    if (!isNull(decimals) && Number.isInteger(decimals)) {
        decimalNumbers = Number(decimals);
        formattedValue = formattedValue.toString().replace(',', '.');
        formattedValue = decimalRound(formattedValue, decimalNumbers);
    }

    if (isFloat(formattedValue)) {
        formattedValue = decimalRound(formattedValue, decimalNumbers);
        const formattedValueParts = formattedValue.toString().split('.');
        const formattedIntegerValue = String(formattedValueParts[0]).replace(/(.)(?=(\d{3})+$)/g, `$1${thousandsSeparator}`);

        return `${formattedIntegerValue}${decimalPoint}${formattedValueParts[1]}`;
    }

    return String(formattedValue).replace(/(.)(?=(\d{3})+$)/g, `$1${thousandsSeparator}`);
}

function isFloat(number) {
    if (!number) {
        return false;
    }
    return Number(number) % 1 !== 0;
}

function isInteger(number) {
    if (!number) {
        return false;
    }
    return Number(number) % 1 === 0;
}

function isNumber(number) {
    if (isNull(number)) {
        return false;
    }
    return !Number.isNaN(Number(number));
}

function isBoolean(value) {
    switch (value) {
        case true:
        case 'true':
        case 1:
        case '1':
        case 'on':
        case 'yes':
        case false:
        case 'false':
        case 0:
        case '0':
        case 'off':
        case 'no':
            return true;
        default:
            return false;
    }
}

function getAreaFormatWithDecimalPoint(value, decimalNumbers = 2) {
    const areaValue = decimalRound(value, decimalNumbers);
    return !isEmpty(areaValue) || isNumberLodash(areaValue) ? areaValue.toString().split('.').join(',') : areaValue;
}

function getBoolean(value, defaultValue = false) {
    switch (value) {
        case true:
        case 'true':
        case 'TRUE':
        case 1:
        case '1':
        case 'on':
        case 'yes':
            return true;
        default:
            return defaultValue;
    }
}

function isSame(number1, number2) {
    if (isNumber(number1) && isNumber(number2)) {
        return Number(number1) === Number(number2);
    }
    return false;
}

function isArrayIndex(number) {
    return isNumber(number) && Number(number) > -1;
}

function Round(value) {
    return parseFloat(value);
}

function getNumberFormat(value) {
    if (isNull(value) || isUndefined(value) || value === '' || value === 'undefined') {
        return null;
    }

    const numberValue = value.toString().replace(/,/g, '.');
    return +numberValue;
}

function isPositiveNumber(number) {
    if (isNumberLodash(number)) {
        return number >= 0;
    }
    return false;
}

function sum(number1, number2) {
    const numberValue1 = isNumber(number1) ? Number(number1) : 0;
    const numberValue2 = isNumber(number2) ? Number(number2) : 0;

    return numberValue1 + numberValue2;
}

function decimalToInt(value) {
    return Math.round(value);
}

function isZero(number) {
    if (!isNumber(number)) {
        return false;
    }

    return Number(number) === 0;
}

function truncateSurplusDecimals(value, decimals) {
    if (!isNumber(value) || !isNumber(decimals) || decimals < 0) {
        return value;
    }

    const valueParts = value.toString().split('.');
    if (valueParts.length === 1) {
        return value;
    }

    const decimalsCount = valueParts[1].length;
    if (decimalsCount <= decimals) {
        return value;
    }

    return Number(valueParts[0] + '.' + valueParts[1].slice(0, decimals));
}

function confineNumberToSafeMinMax(number, allowOneLessDigit = false) {
    if (!isNumber(number)) {
        return number;
    }
    if (allowOneLessDigit && (number < MIN_SAFE_INTEGER_ONE_LESS_DIGIT)) {
        return MIN_SAFE_INTEGER_ONE_LESS_DIGIT;
    }
    if (allowOneLessDigit && (number > MAX_SAFE_INTEGER_ONE_LESS_DIGIT)) {
        return MAX_SAFE_INTEGER_ONE_LESS_DIGIT;
    }
    if (number < Number.MIN_SAFE_INTEGER) {
        return Number.MIN_SAFE_INTEGER;
    }
    if (number > Number.MAX_SAFE_INTEGER) {
        return Number.MAX_SAFE_INTEGER;
    }
    return number;
}

// truncates to one less digit than safe min/max to avoid rounding errors
function truncateNumberToSafeMinMax(number) {
    if (!isNumber(number)) {
        return number;
    }
    if (number < MIN_SAFE_INTEGER_ONE_LESS_DIGIT || number > MAX_SAFE_INTEGER_ONE_LESS_DIGIT) {
        return Math.trunc(number / 10);
    }
    return number;
}

function getNumberFromFormattedString(numberString, isAllowedLastSeparatorCharacter = false) {
    if (isUndefined(numberString) || isNull(numberString)) {
        return null;
    }

    let formattedNumber = numberString.toString();
    formattedNumber = formattedNumber.replaceAll(',', '.');
    formattedNumber = formattedNumber.replaceAll(' ', '');
    formattedNumber = formattedNumber.replaceAll('.,', '.').replaceAll(',.', '.').replaceAll('..', '.').replaceAll(',,', '.').replaceAll('>', '').replaceAll('<', '');

    if (formattedNumber.charAt(formattedNumber.length - 1) === '.' && !isAllowedLastSeparatorCharacter) {
        formattedNumber = formattedNumber.slice(0, -1);
    } else if (formattedNumber.charAt(formattedNumber.length - 1) === '.' || (formattedNumber.charAt(formattedNumber.length - 1) === '0' && isNumber(formattedNumber) && isFloat(formattedNumber))) {
        return formattedNumber;
    }

    if (formattedNumber === '-') {
        return formattedNumber;
    }

    return Number(formattedNumber);
}

function getDisplayedNumberFormat(number, decimals = UNITS.DECIMAL_NUMBERS.DEFAULT, decimalSeparator = UNITS.DECIMAL_POINT.DEFAULT, thousandSeparator = UNITS.THOUSAND_SEPARATOR.DEFAULT) {
    if (isNull(number) || number === '') {
        return null;
    }

    if (!number) {
        return 0;
    }

    if (number === '-') {
        return number;
    }

    let formattedValue = number;
    let decimalNumbers = 2;

    if (!isNull(decimals) && Number.isInteger(decimals)) {
        decimalNumbers = Number(decimals);
        formattedValue = formattedValue.toString().replace(',', '.');
        formattedValue = decimalRound(formattedValue, decimalNumbers);
    }

    if (formattedValue?.toString()?.includes(':')) {
        return formattedValue;
    }

    if (isFloat(formattedValue)) {
        const formattedValueParts = formattedValue.toString().split('.');
        const formattedInteger = String(formattedValueParts[0]).replace(/(.)(?=(\d{3})+$)/g, `$1${thousandSeparator}`);

        return `${formattedInteger}${decimalSeparator}${formattedValueParts[1]}`;
    }

    return String(formattedValue).replace(/(.)(?=(\d{3})+$)/g, `$1${thousandSeparator}`);
}
