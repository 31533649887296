import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

export default function useCloseAndOpenCultivationPeriodValidation(props) {
    const { isGroupOperation } = props;
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        period: Yup.string()
            .required(t('default.productionYearRequired', 'Production year is required')),
        name: !isGroupOperation && Yup.string()
            .required(t('default.nameRequired', 'Name is required')),
        endDate: Yup.string()
            .required(t('default.dateRequired', 'Date is required')),
        startDate: Yup.string()
            .required(t('default.dateRequired', 'Date is required')),
        sownArea: !isGroupOperation && Yup.number()
            .positive(t('newCultivationPeriod.sownAreaRequired', 'Sown area is required'))
            .required(t('newCultivationPeriod.sownAreaRequired', 'Sown area is required')),
    });

    return validationSchema;
}
