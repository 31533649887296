import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { useTranslation } from 'react-i18next';
import { UNITS } from 'shared/src/constants';
import { Quantity } from '@baseComponents/formatters';

const useStyles = createUseStyles((theme: any) => ({
    quantityPerAreaCellWrapper: {
        height: '100%',
        width: '100%',
        fontSize: 12,
        fontWeight: 'normal',
        color: theme.color.raven,
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function QuantityPerAreaCell(props) {
    const { value, rowData, unitId } = props;
    const [unit, setUnit] = useState(null);
    const { t } = useTranslation();
    const classes = useStyles();

    //set unit
    useEffect(() => {
        if (unitId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, unitId);
            setUnit(columnItem);
        }
    }, [unitId, rowData]);

    return (
        <div className={classes.quantityPerAreaCellWrapper}>
            <Quantity value={value} unit={`${unit}/${t('default.areUnit', UNITS.DEFAULT_AREA)}`} />
        </div>
    );
}
