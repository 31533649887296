import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import {
    has, isArray, isEmpty, isString, isEqual, isFunction, isObject, uniq,
} from 'lodash';
import clsx from 'clsx';

import Svg from '@baseComponents/Svg';
import renderModal from '@modals/modalNew/modalFunctions';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        display: 'flex',
        height: 40,
    },
    openModalButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: (props: any) => (props.isHasIcon ? 'calc(100% - 25px)' : '100%'),
        border: `1px solid ${theme.color.gray}`,
        borderTopRightRadius: (props: any) => props.isHasIcon && 0,
        borderBottomRightRadius: (props: any) => props.isHasIcon && 0,
        borderRadius: 4,
        outline: 'none',
        backgroundColor: theme.color.white,
        cursor: (props: any) => (!props.isDisabled ? 'pointer' : 'default'),
        '&:hover': {
            borderColor: (props: any) => !props.isDisabled && theme.color.main,
            backgroundColor: (props: any) => !props.isDisabled && theme.color.opacity.main,
            '& svg': {
                fill: (props: any) => !props.isDisabled && theme.color.shadow_green,
            },
        },
        opacity: (props: any) => props.isDisabled && 0.6,
    },
    addIconWrapper: {
        width: '100%',
        textAlign: 'center',
        display: 'contents',
    },
    addIcon: {
        fill: (props: any) => (!props.isDisabled ? theme.color.raven : theme.color.gray),
        height: 24,
        width: 24,
    },
    addIconText: {
        display: 'inline-block',
        ...theme.overFlowText,
    },
    selectedValueText: {
        marginLeft: 10,
        marginRight: 10,
        color: theme.color.jet,
        fontWeight: 400,
        ...theme.overFlowText,
        width: '100%',
        textAlign: (props: any) => (props.isAlignRight ? 'right' : 'center'),
        fontSize: 15,
    },
    content: {
        padding: [2, 10],
        fontSize: 12,
        width: '100%',
    },
    line1: {
        fontWeight: 'bold',
        ...theme.overFlowText,
    },
    line2: {
        ...theme.overFlowText,
    },
    required: {
        border: '1px solid',
        borderColor: theme.color.required,
        fill: theme.color.required,
        '& svg': {
            fill: theme.color.required,
        },
    },
    deleteButton: {
        width: 25,
        height: 'auto',
        cursor: 'pointer',
        padding: 0,
        margin: 0,
        background: 'transparent',
        lineHeight: 1,
        ...theme.disableOutline,
        border: `1px solid ${theme.color.gray}`,
        borderRadius: 4,
        borderLeft: 'none',
        borderTopLeftRadius: (props: any) => props.isHasIcon && 0,
        borderBottomLeftRadius: (props: any) => props.isHasIcon && 0,

        '&:hover': {
            backgroundColor: (props: any) => !props.isDisabled && theme.rgbaColor.lilian,
            '& svg': {
                fill: (props: any) => !props.isDisabled && theme.color.jet,
            },
        },
    },
    deleteIcon: {
        height: 16,
        width: 16,
    },
}));

function OpenModalButton(props) {
    const {
        onClick, isDisabled = false, preventOnShow, modalComponent, modalComponentProps, onChange, currentSelectedValue,
        className, hasDeleteButton = false, isPhpModal = false, phpIdKey, phpValueKey, onDelete, modalCloseButton = false,
        defaultValue, isRequired, defaultText, isIgnoreOnSelectedValue,
        isAlignRight, //style
    } = props;
    const { id, name, cellData } = props; //select default
    const { line1Id, line2Id } = props;
    const { text } = modalComponentProps || {};
    const [selectedId, setSelectedId] = useState<any>();
    const [selectedValue, setSelectedValue] = useState<any>(defaultValue || name);
    const [phpSelectedValue, setPhpSelectedValue] = useState<any>();
    const classes = useStyles({ isDisabled: !modalComponent || isDisabled, isAlignRight, isHasIcon: (hasDeleteButton && !isEmpty(selectedValue)) } as any);

    const selectedLabelText = useMemo(() => text || defaultText || (isString(selectedValue) ? String(selectedValue ?? '') : null), [text, defaultText, selectedValue]);

    //reset datas
    useEffect(() => {
        if (!isEqual(currentSelectedValue, selectedId) && !id && !name) {
            setSelectedId(undefined);
            setSelectedValue(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSelectedValue]);

    //init displayed data
    useEffect(() => {
        if (!isEqual(id, selectedId)) {
            setSelectedId(id);
        }
        if (!isEqual(name, selectedValue)) {
            setSelectedValue(name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, name]);

    //set defaultValue
    useEffect(() => {
        if (defaultValue) {
            setSelectedValue(defaultValue);
        }
    }, [defaultValue]);

    //php-s modal
    useEffect(() => {
        if (phpSelectedValue && isPhpModal) {
            setSelectedId(has(phpSelectedValue, phpIdKey) ? phpSelectedValue[phpIdKey] : null);
            setSelectedValue(has(phpSelectedValue, phpValueKey) ? phpSelectedValue[phpValueKey] : null);

            if (isFunction(onChange)) {
                setPhpSelectedValue(undefined);
                onChange(phpSelectedValue);
            }
        }
    }, [phpSelectedValue, isPhpModal, phpIdKey, phpValueKey, onChange]);

    function onSelectedValue(selectValue, selectId) {
        if (isIgnoreOnSelectedValue) {
            return;
        }

        if (isFunction(onClick)) {
            onClick(selectId, selectValue);
        } else {
            setSelectedValue(selectValue);
            setSelectedId(selectId);
        }
    }

    function onDeleteClicked() {
        if (isFunction(onDelete)) {
            onDelete(resetDatas);
            return;
        }

        resetDatas();
    }

    function resetDatas() {
        setSelectedId(undefined);
        setSelectedValue(undefined);

        if (isFunction(onDelete)) {
            onDelete();
        }
    }

    function onClickOpenModal() {
        function showModal() {
            if (isDisabled || !modalComponent) {
                return;
            }

            //render modal
            if (!isPhpModal) {
                renderModal(modalComponent, { ...modalComponentProps, onChange, onSelectedValue, selectedRowIds: isArray(id) ? id : [id] }, modalCloseButton);
            } else if (isFunction(modalComponent)) { // phpFunctions.php's old modal function call
                modalComponent(setPhpSelectedValue);
            }
        }

        if (isFunction(preventOnShow) && !isDisabled) {
            preventOnShow(id, showModal, cellData);
            return;
        }
        showModal();
    }

    const getButtonText = useCallback(() => {
        if (!isEmpty(text)) {
            return text;
        }
        if (isString(selectedValue)) {
            return selectedValue;
        }

        if (isArray(selectedValue) && line1Id && line2Id) {
            const uniqLine1 = uniq(selectedValue.map(selectedItem => selectedItem[line1Id]));
            const uniqLine2 = uniq(selectedValue.map(selectedItem => selectedItem[line2Id]));
            const line1 = uniqLine1?.join(',') || '';
            const line2 = uniqLine2?.join(',') || '';

            return (
                <div className={classes.content}>
                    <div className={classes.line1} title={line1}>{line1}</div>
                    <div className={classes.line2} title={line2}>{line2}</div>
                </div>
            );
        }

        if (isObject(selectedValue) && (line1Id || line2Id)) {
            return (
                <div className={classes.content}>
                    {selectedValue[line1Id] && <div className={classes.line1} title={selectedValue[line1Id]}>{selectedValue[line1Id]}</div>}
                    {selectedValue[line2Id] && <div className={classes.line2} title={selectedValue[line2Id]}>{selectedValue[line2Id]}</div>}
                </div>
            );
        }
        return null;
    }, [classes.content, classes.line1, classes.line2, line1Id, line2Id, selectedValue, text]);

    const getButtonClassName = useCallback(() => clsx(classes.openModalButtonWrapper, className, 'withBorder', isRequired && isEmpty(selectedValue) && classes.required),
        [classes.openModalButtonWrapper, classes.required, className, isRequired, selectedValue]);

    return (
        <div className={clsx(classes.container, 'openModalButton')}>
            <div
                className={getButtonClassName()}
                role="button"
                tabIndex={0}
                title={selectedLabelText}
                onClick={onClickOpenModal}
                aria-hidden="true"
            >
                {(isEmpty(selectedValue))
                    ? (
                        <div className={classes.addIconWrapper}>
                            <Svg iconId="icon-add" style={classes.addIcon} />
                            {!isEmpty(text) ? <div className={classes.addIconText}>{text}</div> : null}
                        </div>
                    ) : (
                        <div className={classes.selectedValueText}>
                            {getButtonText()}
                        </div>
                    )
                }
            </div>
            {hasDeleteButton && !isEmpty(selectedValue) ? (
                <button type="button" className={classes.deleteButton} onClick={onDeleteClicked}>
                    <Svg iconId="icon-close" style={classes.deleteIcon} />
                </button>
            ) : null}
        </div>
    );
}

export default React.memo(OpenModalButton);
