import React from 'react';
import { ModalRightPanel, ModalTemplate } from '@baseComponents/modals';
import SoilAnalysisViewTable from './SoilAnalysisViewTable';
import SoilAnalysisViewLeft from './SoilAnalysisViewLeft';

export default function SoilAnalysisView(props) {
    const { hideModal, cultivationPeriodValues } = props;

    return (
        <ModalTemplate hideModal={hideModal}>
            <SoilAnalysisViewLeft
                hideModal={hideModal}
                cultivationPeriodValues={cultivationPeriodValues}
            />
            <ModalRightPanel>
                <SoilAnalysisViewTable
                    cultivationPeriodId={cultivationPeriodValues?.cultivationPeriodData?.id}
                />
            </ModalRightPanel>
        </ModalTemplate>
    );
}
