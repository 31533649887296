import renderModal from '@modals/modalNew/modalFunctions';
import { Nullable } from '@flowsCommon/services/baseTypes';
import LayerCreateController from '@map/services/layerCreate/layerCreateController';
import { isFunction, isNumber } from 'lodash';
import { TLayerCreateStore } from '@map/services/layerCreate/layerCreateStore';

type EntityPairingModalProps = {
    controller: LayerCreateController,
    modalComponent: any,
    modalComponentProps: any,
    rowData: any,
    onSelectChange?: (selectedData: any) => void,
    doAlert: () => void;
    pairableEntityIds: number[];
};

type RemoveEntityProps = {
    controller: LayerCreateController,
    rowData: any,
    isCustomLayer: boolean;
};

function openEntityPairingModal(props: EntityPairingModalProps) {
    const { rowData, controller, modalComponent, modalComponentProps = {}, onSelectChange, doAlert, pairableEntityIds } = props;

    function onModalSelect(selectedData) {
        if (!(pairableEntityIds || []).includes(selectedData?.id)) {
            doAlert();
            return;
        }

        controller.unpairFeature(rowData.id);
        controller.pairEntityIdToFeatureId(selectedData?.id, rowData.id as number);

        if (isFunction(onSelectChange)) {
            setTimeout(() => {
                onSelectChange(selectedData);
            }, 0);
        }
    }

    if (modalComponent) {
        renderModal(modalComponent?.default, { ...modalComponentProps, onChange: onModalSelect }, false);
    }
}

function removeEntityPairing(props: RemoveEntityProps) {
    const { rowData, controller, isCustomLayer } = props;

    if (isNumber(rowData?.id)) {
        if (!isCustomLayer) {
            controller.unpairFeature(rowData.id);
        } else {
            controller.setExcludedFeatureIds([rowData?.id]);
        }
    }
}

export const rowFunctions = {
    openEntityPairingModal,
    removeEntityPairing,
};
