import ModalTemplate from '@baseComponents/modals/ModalTemplate';
import MapScreenContainer from '@map/screens/Map/MapScreenContainer';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { ModalLeftPanel, ModalRightPanel } from '@baseComponents/modals';
import { MeteorologyModalLeft } from './MeteorologyMoodalLeft';

export const MeteorologyModal = (props: any) => {
    const { hideModal } = props;

    return (
        <ModalTemplate hideModal={hideModal}>
            <ModalLeftPanel>
                <MeteorologyModalLeft {...props} />
            </ModalLeftPanel>
            <ModalRightPanel>
                <MapScreenContainer
                    defaultMapFilter={{ productionYearId: -1 }}
                    layerType={BuiltInLayerTypes.METEOROLOGY}
                    withLayers
                    layerPanelOptions={{
                        withLayerPanel: false,
                    }}
                    withCurrencySwitch={false}
                    withMapSearch={false}
                />
            </ModalRightPanel>
        </ModalTemplate>
    );
};
