import React, { useEffect, useState } from 'react';
import { SimpleSelect } from '@baseComponents/select';
import { useDispatch } from 'react-redux';
import { errorActions, userActions } from '@states/actions';
import { RIGHTS } from 'shared/src/constants';
import { isEmpty, isFunction } from 'lodash';
import { stringUtils } from 'shared/src/modules';
import { cultureService } from '@services';
import { useLocation } from 'react-router';
import renderModal from '@modals/modalNew/modalFunctions';
import NewCultureVarietyModal from '@customComponents/modals/newCultureVarietyModal/NewCultureVarietyModal';

export default function CultureVarietyDropDown(props) {
    const { variety, onChange, className, culture, setCulture } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const [isAddNewVarietyPermissions] = useState(dispatch(userActions.hasEveryPermissions([RIGHTS.ADD_VARIETY])));
    const [varietyList, setVarietyList] = useState();
    const [filteredVarietyList, setFilteredVarietyList] = useState();

    useEffect(() => {
        let isCancelled = false;

        async function getCultureVariety(cultureId) {
            try {
                const varieties = await cultureService.getCultureVariety(cultureId);
                if (!isCancelled) {
                    const cultureVarietyList = varieties?.map(varietyObj => ({ value: varietyObj.name, key: varietyObj.id }));
                    setFilteredVarietyList(cultureVarietyList);
                    setVarietyList(cultureVarietyList);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }

        if (culture && dispatch && location) {
            getCultureVariety(culture);
            return () => {
                isCancelled = true;
            };
        }
    }, [dispatch, location, culture]);

    function search(value) {
        if (!isEmpty(value) && !isEmpty(varietyList)) {
            setFilteredVarietyList(varietyList.filter(dataObj => stringUtils.includesNoCase(dataObj.value, value)));
        } else {
            setFilteredVarietyList(varietyList);
        }
    }

    function addNewVariety() {
        document.body.click();
        renderModal(NewCultureVarietyModal, {
            onSuccess: newVariety => {
                setNewVariety({
                    key: newVariety.id,
                    culture: newVariety.cultureId,
                    value: newVariety.name,
                    isActive: +newVariety.isActive,
                });
            },
            defaultCultureId: culture,
        }, true);
    }

    function setNewVariety(newVariety) {
        if (!isEmpty(newVariety) && newVariety.isActive && +culture === newVariety.culture) {
            setVarietyList([...varietyList, newVariety]);
            setFilteredVarietyList(varietyObj => [...varietyObj, newVariety]);

            if (isFunction(setCulture)) {
                setCulture(newVariety.culture);
            }

            if (isFunction(onChange)) {
                onChange(newVariety.key);
            }
        }
    }

    return (
        <SimpleSelect
            selected={variety}
            dataList={filteredVarietyList}
            onChange={onChange}
            search={value => search(value)}
            addNew={isAddNewVarietyPermissions && addNewVariety}
            className={className}
        />
    );
}
