import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import AlertTemplate from './AlertTemplate';

const useStyles = createUseStyles((theme: any) => ({
    alertContainer: {
        position: 'fixed',
        left: '50%',
        bottom: 20,
        transform: 'translate(-50%)',
        zIndex: theme.zIndex.alert,
    },
}));

export default function Alert() {
    const alertData = useSelector((state: any) => state.alert.alert);
    const classes = useStyles();

    return (
        <div className={classes.alertContainer}>
            {!isEmpty(alertData) && alertData.map((alert, index) => (
                <AlertTemplate key={index} alertData={alert} />
            ))}
        </div>
    );
}
