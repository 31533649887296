import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    appLoadingSpinnerWrapper: {
        display: 'flex',
        zIndex: theme.zIndex.loadSpinner,
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: 'transparent',
    },
    appLoadingSpinnerIcon: {
        display: 'block',
        margin: 'auto',
        maxWidth: 55,
        maxHeight: 55,
        minWidth: 15,
        minHeight: 15,
        width: '10%',
    },
}));

export default function AppLoadingSpinner() {
    const classes = useStyles();

    return (
        <div className={classes.appLoadingSpinnerWrapper}>
            <img alt="" src="/styles/img/page_loading.gif" className={classes.appLoadingSpinnerIcon} />
        </div>
    );
}
