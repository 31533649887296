import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        minWidth: 100,
        display: 'inline-block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        margin: 'auto',
    },
    firstLine: {
        display: 'block',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: 1.125,

        fontSize: 13,
        fontWeight: 500,
        color: theme.color.jet,
    },
    secondLine: {
        display: 'block',
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        height: 18,
        lineHeight: 1.231,

        fontSize: 12,
        fontWeight: 400,
        color: theme.color.raven,
    },
    inlineBlock: {
        display: 'inline-block',
    },
}));

export default function TwinLineView({ line1, line2 }) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.firstLine}>
                {line1 && line1}
            </div>
            {line2
                && <div className={classes.secondLine}>{line2}</div>
            }
        </div>
    );
}
