import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import { userActions } from '@states/actions';
import { routerHelper } from '@utils/router/RouterHelper';

export default function useTableColumnOrderChangeHandler(props) {
    const { columns } = props;
    const dispatch = useDispatch();
    const [location] = useState(routerHelper.getModuleContentNameByUrl(useLocation().pathname));
    const [currentLayout, setCurrentLayout] = useState();

    function onLayoutChange(layout) {
        setCurrentLayout(layout);
    }

    function onClickSubmit() {
        if (!isEmpty(currentLayout) && !isEmpty(columns)) {
            const dataForSave = currentLayout.map(layout => ({
                columnId: columns[Number(layout.i)]?.columnId,
                index: layout.y,
            }));
            dispatch(userActions.setTableColumnOrder(location, dataForSave));
        }
        document.body.click();
    }

    return {
        onLayoutChange,
        onClickSubmit,
    };
}
