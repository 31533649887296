import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    insuranceData: {
        width: '100%',
        padding: 10,
        display: 'flex',
    },
    insuranceDataContainer: {
        marginLeft: 10,
        display: 'inline-grid',
        textAlign: 'left',
    },
    bank: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.33,
        textAlign: 'left',
        ...theme.overFlowText,
    },
    insurer: {
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.33,
        textAlign: 'left',
        ...theme.overFlowText,
        marginTop: 2,
    },
}));

export default function InsuranceOverall(props) {
    const { bankName, insurerName } = props;
    const classes = useStyles();

    function formatBankInsurerName(bank, insurer) {
        return `${bank}, ${insurer}`;
    }

    return (
        <div className={classes.insuranceData}>
            <div
                className={classes.insuranceDataContainer}
                title={formatBankInsurerName(bankName, insurerName)}
            >
                <div className={classes.bank}>{bankName}</div>
                <div className={classes.insurer}>{insurerName}</div>
            </div>
        </div>
    );
}
