/* eslint-disable no-restricted-imports */
import { useEffect, useState } from 'react';
import { useMapContext } from '../../services/mapContext';
import CursorCoordinatesComponent from './CursorCoordinatesComponent';

const CursorCoordinatesContainer = () => {
    const [store] = useMapContext();
    const [cursorCoordinates, setCursorCoordinates] = useState<google.maps.LatLngLiteral>();

    useEffect(() => {
        if (!store.googleRefs.map) {
            return;
        }

        const listener = store.googleRefs.map.addListener('mousemove', ev => {
            setCursorCoordinates({
                lat: ev.latLng.lat(),
                lng: ev.latLng.lng(),
            });
        });

        return () => {
            google.maps.event.removeListener(listener);
        };
    }, [store.googleRefs.map]);

    return (<CursorCoordinatesComponent
        coordinates={cursorCoordinates}
    />);
};

export default CursorCoordinatesContainer;
