/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import Avatar from '@baseComponents/avatars/Avatar';
import { isEmpty } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    avatarWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        width: 30,
        height: 30,
    },
    nameWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflow: 'hidden',
    },
    name: {
        ...theme.overFlowText,
        color: theme.color.jet,
        display: 'block',
        height: '100%',
        //lineHeight: '56px',
        fontSize: 14,
        fontWeight: 500,
        verticalAlign: 'middle',
        backgroundColor: 'transparent',
        paddingLeft: 5,
    },
    companyName: {
        ...theme.overFlowText,
        color: theme.color.raven,
        display: 'block',
        height: '100%',
        //lineHeight: '56px',
        fontSize: 13,
        fontWeight: 400,
        verticalAlign: 'middle',
        backgroundColor: 'transparent',
        paddingLeft: 5,
    },
}));

export default function WorkerAvatarCell(props) {
    const { rowData, nameId, companyNameId, isShowTitle, isShowName, imageUrlId } = props;
    const classes = useStyles();

    const getName = useCallback(() => {
        if (nameId) {
            return rowData?.[nameId];
        }
        return rowData?.name;
    }, [nameId, rowData]);

    const getCompanyName = useCallback(() => {
        if (companyNameId) {
            return rowData?.[companyNameId];
        }
        return rowData?.name;
    }, [companyNameId, rowData]);

    const getImageUrl = useCallback(() => {
        if (imageUrlId) {
            return rowData?.[imageUrlId];
        }
        return rowData?.imageUrl;
    }, [imageUrlId, rowData]);

    return (
        <div className={classes.avatarWrapper}>
            {(!isEmpty(getName()) || !isEmpty(getImageUrl())) && <Avatar imageUrl={getImageUrl()} name={getName()} className={classes.avatar} isShowTitle={isShowTitle} />}
            {isShowName && (
                <div className={classes.nameWrapper}>
                    <span className={classes.name}>{getName()}</span>
                    {!isEmpty(companyNameId) && <span className={classes.companyName}>{getCompanyName()}</span>}
                </div>
            )}
        </div>
    );
}
