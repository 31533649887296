import React, { useCallback, useMemo } from 'react';
import { SCOUTING } from 'shared/src/constants';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
    cardContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(25%, 1fr))',
        gridGap: 15,
    },
    card: {
        border: '1px solid',
        borderColor: theme.color.gray,
        borderRadius: 4,
        backgroundColor: theme.color.white,
        height: 56,
        boxShadow: theme.shadows.secondary.normal,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
        fontWeight: 'bold',
        '&:hover': {
            boxShadow: theme.shadows.secondary.hover,
            backgroundColor: theme.color.buttonHover.secondary,
        },
    },
    selectedLowPriority: {
        border: 'none',
        borderRadius: 4,
        backgroundColor: theme.color.main,
        boxShadow: theme.shadows.primary.main,
        color: theme.color.white,
        '& span': {
            backgroundColor: theme.color.white,
        },
        '&:hover': {
            backgroundColor: theme.color.main,
            boxShadow: theme.shadows.primary.main,
        },
    },
    selectedMiddlePriority: {
        border: 'none',
        borderRadius: 4,
        backgroundColor: theme.color.slow,
        boxShadow: theme.shadows.primary.main,
        color: theme.color.white,
        '& span': {
            backgroundColor: theme.color.white,
        },
        '&:hover': {
            backgroundColor: theme.color.slow,
            boxShadow: theme.shadows.primary.main,
        },
    },
    selectedHighPriority: {
        border: 'none',
        borderRadius: 4,
        backgroundColor: theme.color.destructive,
        boxShadow: theme.shadows.destructive.main,
        color: theme.color.white,
        '& span': {
            backgroundColor: theme.color.white,
        },
        '&:hover': {
            backgroundColor: theme.color.destructive,
            boxShadow: theme.shadows.destructive.main,
        },
    },
    priority: {
        width: '70%',
        height: 6,
        borderRadius: 6,
        marginBottom: 5,
    },
    lowPriority: {
        backgroundColor: theme.color.main,
        boxShadow: theme.shadows.primary.main,
    },
    middlePriority: {
        backgroundColor: theme.color.slow,
        boxShadow: theme.shadows.slow.normal,
    },
    highPriority: {
        backgroundColor: theme.color.destructive,
        boxShadow: theme.shadows.destructive.main,
    },
}));

export default function PrioritySelect(props) {
    const { selectedPriority, onChangePriority, className } = props;
    const classes = useStyles();
    const { t } = useTranslation();

    const priorityList = useMemo(() => ([
        {
            key: SCOUTING.PRIORITY.LOW,
            text: t('priority.low', 'Low'),
        },
        {
            key: SCOUTING.PRIORITY.MIDDLE,
            text: t('priority.middle', 'Middle'),
        },
        {
            key: SCOUTING.PRIORITY.HIGH,
            text: t('priority.high', 'High'),
        },
    ]), [t]);

    const getPriorityColorClassName = useCallback(priorityKey => {
        switch (priorityKey) {
            case SCOUTING.PRIORITY.LOW:
                return classes.lowPriority;
            case SCOUTING.PRIORITY.HIGH:
                return classes.highPriority;
            default:
                return classes.middlePriority;
        }
    }, [classes.highPriority, classes.lowPriority, classes.middlePriority]);

    const getSelectedPriorityClassName = useCallback(key => {
        let selectedClassName;
        switch (key) {
            case SCOUTING.PRIORITY.LOW:
                selectedClassName = classes.selectedLowPriority;
                break;
            case SCOUTING.PRIORITY.HIGH:
                selectedClassName = classes.selectedHighPriority;
                break;
            default:
                selectedClassName = classes.selectedMiddlePriority;
        }

        return selectedPriority === key && selectedClassName;
    }, [selectedPriority, classes.selectedLowPriority, classes.selectedHighPriority, classes.selectedMiddlePriority]);

    return (
        <div className={clsx(classes.cardContainer, className)}>
            {
                priorityList.map(priority => (
                    <button type="button" key={priority.key} className={clsx(classes.card, getSelectedPriorityClassName(priority.key))} onClick={() => onChangePriority(priority.key)}>
                        <span className={clsx(classes.priority, getPriorityColorClassName(priority.key))} />
                        {priority.text}
                    </button>
                ))
            }
        </div>
    );
}
