import { useState } from 'react';
import { isFunction } from 'lodash';
import * as yup from 'yup';
import useTranslate from '@i18n/useTranslate';
import { useDispatch } from 'react-redux';
import { companyActions, alertActions } from '@states/actions';
import { cultivationTableService } from '@services/cultivationTableService';
import { SETTINGS, CULTIVATION_PERIODS, ERRORS } from 'shared/src/constants';

export default function useNewBasicFieldData(props) {
    const { hideModal, onSuccess } = props;

    const dispatch = useDispatch();
    const { t } = useTranslate();
    const [settings] = useState(
        dispatch(companyActions.getSettings({
            usingFarm: SETTINGS.USING_FARM,
        })),
    );

    const [validationSchema] = useState(
        yup.object().shape({
            name: yup.string()
                .typeError(t('newBasicField.specifyName', 'Specify the name'))
                .required(t('newBasicField.specifyName', 'Specify the name')),
            cultivatedArea: yup.number()
                .min(0.001, t('newBasicField.specifyCultivatedArea', 'Specify the cultivated area'))
                .typeError(t('newBasicField.specifyCultivatedArea', 'Specify the cultivated area'))
                .required(t('newBasicField.specifyCultivatedArea', 'Specify the cultivated area')),
            creationDate: yup.string()
                .typeError(t('newBasicField.specifyCreationDate', 'Specify the creation date'))
                .required(t('newBasicField.specifyCreationDate', 'Specify the creation date')),
            partnerId: yup.number()
                .typeError(t('newBasicField.specifyPartner', 'Specify the company or partner'))
                .required(t('newBasicField.specifyPartner', 'Specify the company or partner')),
        }),
    );

    const [defaultValues, setDefaultValues] = useState({
        name: '',
        cultivatedArea: null,
        tableLocation: '',
        creationDate: null,
        terminationDate: null,
        isOwnTable: null,
        isDeleted: null,
        isActive: true,
        isNhaCategory: 1,
        settlementId: null,
        partnerId: null,
        partnerName: null,
        description: '',
        farmId: null,
    });

    const [nhaCategories] = useState([
        { key: CULTIVATION_PERIODS.NHA_CATEGORIES.NHA_CATEGORY_1, value: t('newBasicField.nhaCategory1', 'Category I.') },
        { key: CULTIVATION_PERIODS.NHA_CATEGORIES.NHA_CATEGORY_2, value: t('newBasicField.nhaCategory2', 'Category II.') },
        { key: CULTIVATION_PERIODS.NHA_CATEGORIES.NHA_CATEGORY_3, value: t('newBasicField.nhaCategory3', 'Category III.') },
        { key: CULTIVATION_PERIODS.NHA_CATEGORIES.NHA_CATEGORY_4, value: t('newBasicField.nhaCategory4', 'Category IV.') },
    ]);

    const onSubmit = async newBasicFieldData => {
        try {
            const saveResult = await cultivationTableService.setCultivationTable(newBasicFieldData);

            if (saveResult.isSuccessful) {
                dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved.')));
                if (isFunction(hideModal)) {
                    if (isFunction(onSuccess)) {
                        onSuccess({ ...newBasicFieldData, ...{ id: saveResult.id, defaultFreeArea: newBasicFieldData.cultivatedArea, freeArea: newBasicFieldData.cultivatedArea, categorizedArea: 0 } });
                    }
                    hideModal();
                }
            } else {
                dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed.')));
            }
        } catch (error) {
            if (error.statusCode === ERRORS.HTTP_STATUS_CODES.DUPLICATED_ENTRY) {
                dispatch(alertActions.errorAlert(t('default.duplicatedEntry', 'Saving failed, duplicated data.')));
            } else {
                dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed.')));
            }
        }
    };

    return {
        nhaCategories,
        settings,
        defaultValues,
        validationSchema,
        onSubmit,
        setDefaultValues,
    };
}
