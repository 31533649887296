/* eslint-disable no-restricted-imports */
import { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import useTableFixedFooter from './useTableFixedFooter';
import TableFooterCell from './TableFooterCell';
import ListRowTemplate from '../tableGroup/ListRowTemplate';
import useTableFixedColumn from '../tableParts/useTableFixedColumn';
import { useTableColumnFader } from '../tableParts/tableColumnFader';

const useStyles = createUseStyles((theme: any) => ({
    row: {
        display: (props: any) => !props.isVisibleFixedFooter && 'none',
        height: theme.basePage.bodyFixedFooterLineHeight,
        background: 'transparent',
        maxWidth: '100%',
    },
}));

export default function TableFixedFooter(props) {
    const { isVisibleFixedFooter = !props.isBasePageTable, isBasePageTable = true, tableKey, pageData } = props;
    const { useGroupOperation } = pageData || {};
    const listRowRef = useRef(null);
    const classes = useStyles({ isVisibleFixedFooter, isBasePageTable });

    const { aggregateType, columnWidths, filteredDatas } = useTableFixedFooter({ isBasePageTable, tableKey });

    const { displayTableColumnFaders } = useTableColumnFader();
    const { leftSideWidth, rightSideWidth, leftFixedColumns, rightFixedColumns, mainColumns } = useTableFixedColumn({
        isBasePageTable,
        canUseGroupOperation: useGroupOperation,
        tableKey,
    });

    //csoportosítás esetén flat lista készítése
    function getGroupDataFromFilteredList() {
        if (!isEmpty(filteredDatas)) {
            const filteredDataArray: any = [];
            Object.values(filteredDatas as any)?.forEach((filteredData: any) => {
                filteredData?.forEach(filteredDataItem => filteredDataArray.push(filteredDataItem));
            });
            return filteredDataArray;
        }

        return null;
    }

    //on scroll
    const handleMidScroll = event => {
        const { scrollLeft } = event.target;
        onMidScroll(scrollLeft);
    };

    //scroll sync táblázattal
    function onMidScroll(scrollLeft) {
        document.querySelectorAll(`.midCol${tableKey ?? ''}`).forEach(midColItem => {
            midColItem.scrollLeft = scrollLeft;
            displayTableColumnFaders(midColItem);
        });
    }

    //init - group operation - after show footer - scroll sync
    useEffect(() => {
        if (isVisibleFixedFooter) {
            const scrollerFooter = document.getElementById('basePageContentScrollerFooter');
            if (scrollerFooter) {
                onMidScroll(scrollerFooter.scrollLeft);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisibleFixedFooter]);

    return (
        <ListRowTemplate
            tableKey={tableKey}
            classes={classes}
            leftSideWidth={leftSideWidth}
            handleMidScroll={handleMidScroll}
            rightSideWidth={rightSideWidth}
            listRowRef={listRowRef}
            left={
                !isEmpty(leftFixedColumns) && (leftFixedColumns as any)?.map((lfColumn, idx) => (
                    <TableFooterCell
                        key={idx}
                        filteredTableData={getGroupDataFromFilteredList()}
                        column={lfColumn}
                        aggregateType={aggregateType}
                    />
                ))
            }
            middle={
                !isEmpty(mainColumns) && (mainColumns as any)?.map((column, idx) => (
                    <TableFooterCell
                        key={idx}
                        cellWidth={!isEmpty(columnWidths) ? columnWidths?.[idx] : null}
                        filteredTableData={getGroupDataFromFilteredList()}
                        column={column}
                        aggregateType={aggregateType}
                    />
                ))
            }
            right={
                !isEmpty(rightFixedColumns) && (rightFixedColumns as any)?.map((rColumn, idx) => (
                    <TableFooterCell
                        key={idx}
                        filteredTableData={getGroupDataFromFilteredList()}
                        column={rColumn}
                        aggregateType={aggregateType}
                    />
                ))
            }
        />
    );
}
