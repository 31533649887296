import { useState } from 'react';
import Menu from '@baseComponents/multiselect/Menu';
import MultiSelectToggleButton from '@baseComponents/multiselect/MultiSelectToggleButton';
import { Dropdown } from 'react-bootstrap';
import { isFunction } from 'lodash';

export default function MultiSelect(props) {
    const { dataList, hasSearchInput, hasMultiSelectHeader, className, onChange, onClose, useAbsoluteMenu, isNonAccentSearch, isDisabled } = props;
    const [isOpened, setIsOpened] = useState(false);

    function onToggle(state) {
        document.body.click();
        setIsOpened(state);

        if (isFunction(onClose) && !state) {
            const selectedItems = dataList?.filter(dataItem => dataItem.isChecked);
            const selectedIds = selectedItems?.map(dataItem => dataItem.key);

            if (isFunction(onClose)) {
                onClose(selectedIds);
            }
        }
    }

    function onChangeSelected(checkedKeys) {
        if (isFunction(onChange)) {
            if (isFunction(onChange)) {
                onChange(checkedKeys);
            }
        }
    }

    return (
        <Dropdown onToggle={state => onToggle(state)} className={className}>
            <MultiSelectToggleButton isOpened={isOpened} dataList={dataList} isDisabled={isDisabled} />
            <Menu
                isOpened={isOpened}
                dataList={dataList}
                multiselect
                hasSearchInput={hasSearchInput}
                hasMultiSelectHeader={hasMultiSelectHeader}
                useAbsoluteMenu={useAbsoluteMenu}
                onChangeSelected={onChangeSelected}
                isNonAccentSearch={isNonAccentSearch}
            />
        </Dropdown>
    );
}
