import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { SETTINGS } from 'shared/src/constants';
import { companyActions } from '@states/actions';
import { theme as themes } from '@styles/theme';

const useStyles = createUseStyles({
    poligonContainer: {
        height: 36,
        width: 36,
        minWidth: 36,
        lineHeight: '36px',
        margin: 'auto',
        display: 'inline-block',
        cursor: (props: any) => (props.mapSvg ? 'pointer' : 'default'),
        marginRight: 15,
        outline: 'none',
    },
});

export default function MapPolygon(mapProps) {
    const { mapSvg, onClick } = mapProps;
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [usingMap] = useState(dispatch(companyActions.getSetting(SETTINGS.USING_MAP)));
    const classes = useStyles({ mapSvg });

    if (!usingMap) {
        return null;
    }

    return (
        <div className={classes.poligonContainer} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0} title={mapSvg ? t('cultivationPeriods.viewPeriodOnMap', 'View period on map') : ''}>
            {mapSvg && (
                <svg viewBox={mapSvg.viewBox} height={36} width={36}>
                    <g>
                        <path
                            vectorEffect="non-scaling-stroke"
                            fill="rgba(34, 195, 115, 0.3)"
                            stroke={themes.color.main}
                            strokeWidth={1}
                            d={mapSvg.path}
                        />
                    </g>
                </svg>
            )}
        </div>
    );
}
