import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    aggregateCellWrapper: {
        padding: 0,
        margin: 0,
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        color: theme.color.jet,
        fontWeight: 'bold',
    },
}));

export default function TableAggregateCell(props) {
    const { tableData, column } = props;
    const { customAggregateFunction } = column; //column data

    const classes = useStyles();

    return (
        <h4 className={clsx(classes.aggregateCellWrapper)}>
            {customAggregateFunction(tableData)}
        </h4>
    );
}
