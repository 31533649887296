import React from 'react';
import { createUseStyles } from 'react-jss';
import { numberUtils } from 'shared/src/modules/numberUtils';
import Area from '../../baseComponents/formatters/Area';

const useStyles = createUseStyles((theme: any) => ({
    supportContainer: {
        width: '100%',
        height: '100%',
        fontSize: 15,
        fontWeight: 'normal',
    },
    supportItem: {
        height: 40,
        padding: [0, 20],
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderBottomColor: props => (!props.disabled ? theme.color.gray : theme.color.pearl),
        '&:last-child ': {
            borderBottom: 'none',
        },
    },
    supportName: {
        fontWeight: 500,
        marginRight: 'auto',
    },
    supportArea: {
        width: 'auto',
    },
}));

export default function SupportOverall(props) {
    const { support, disabled } = props;
    const classes = useStyles({ disabled });

    return (
        <div className={classes.supportContainer}>
            {support.map(subsidy => (
                <div key={subsidy.support.id} className={classes.supportItem}>
                    <span className={classes.supportName} title={subsidy.support.shortName}>
                        {subsidy.support.shortName}
                    </span>
                    {numberUtils.isNumber(subsidy.area) && <Area value={subsidy.area} style={classes.supportArea} />}
                </div>
            ))}
        </div>
    );
}
