import React from 'react';
import { createUseStyles } from 'react-jss';
import { TABLE } from 'shared/src/constants';
import clsx from 'clsx';

import TableHeaderSorterIcon from './TableHeaderSorterIcon';
import useTableHeaderSorterHandler from './useTableHeaderSorterHandler';

const useStyles = createUseStyles({
    iconContainer: {
        display: 'inline-block',
        height: 40,
        textAlign: 'center',
        verticalAlign: 'middle',
        '&:active': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
    },
});

export default function TableHeaderSorter(props) {
    const { column, isBasePageTable, showSortingIcon, tableKey, className } = props;
    const { columnId, isSortable } = column;
    const classes = useStyles();

    const { sortingType, onClickSorterIcon } = useTableHeaderSorterHandler({ columnId, isBasePageTable, tableKey });

    function onClick(event) {
        event.stopPropagation();
        onClickSorterIcon();
    }

    if (!isSortable || (!showSortingIcon && sortingType === TABLE.SORTING.NONE)) {
        return null;
    }

    return (
        <div className={clsx(classes.iconContainer, 'iconContainer')} onClick={onClick} aria-hidden="true" role="button" tabIndex="0">
            <TableHeaderSorterIcon sortingType={sortingType} className={className} />
        </div>
    );
}
