import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import useTranslate from '@i18n/useTranslate';
import { routerHelper } from '@utils/router/RouterHelper';
import { sessionService } from '@services';

import SideNavBarItemNewImage from './SideNavBarItemNewImage';
import SideNavBarItemOldImage from './SideNavBarItemOldImage';

const useStyles = createUseStyles((theme: any) => ({
    menuItem: {
        borderTop: (props: any) => props.isSeparatedMenu && `1px solid ${theme.color.lilian}`,
        marginTop: (props: any) => props.isSeparatedMenu && 10,
        display: 'flex',
        cursor: 'pointer',
        height: 44,
        outline: 'none',
        '&:hover': {
            background: (props: any) => (!props.isTestSystem ? theme.color.mainFaded : theme.color.testFaded),
            fill: (props: any) => (!props.isTestSystem ? theme.color.main : theme.color.test),
            color: (props: any) => (!props.isTestSystem ? theme.color.main : theme.color.test),
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
            color: (props: any) => (!props.isTestSystem ? theme.color.main : theme.color.test),
        },
    },
    selectedItem: {
        display: 'flex',
        cursor: 'pointer',
        height: 44,
        background: (props: any) => (!props.isTestSystem ? theme.color.mainFaded : theme.color.testFaded),
        color: (props: any) => (!props.isTestSystem ? theme.color.main : theme.color.test),
        fill: (props: any) => (!props.isTestSystem ? theme.color.main : theme.color.test),
        borderRight: '5px solid',
        borderRightColor: (props: any) => (!props.isTestSystem ? theme.color.main : theme.color.test),
        outline: 'none',
        '&:active': {
            outline: 'none',
        },
        '&:focus': {
            outline: 'none',
            textDecoration: 'none',
            color: (props: any) => (!props.isTestSystem ? theme.color.main : theme.color.test),
        },
        '&:hover': {
            textDecoration: 'none',
            color: (props: any) => (!props.isTestSystem ? theme.color.main : theme.color.test),
        },
        '& $menuTitle': {
            fontWeight: 500,
        },
    },
    menuTitle: {
        display: (props: any) => !props.expanded && (props.menuIcon || props.iconId) && 'none',
        transition: 'width .5s',
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '1.43',
        paddingTop: 13,
        marginLeft: (props: any) => (!props.expanded || props.menuIcon || props.iconId) && 10,
        ...theme.overFlowText,
        width: (props: any) => !props.menuIcon && !props.iconId && '100%',
        textAlign: (props: any) => !props.menuIcon && !props.iconId && 'center',
        maxWidth: 165,
    },
}));

export default function SideNavBarItem(props) {
    const { menuItem, expanded, routeList, agentName, setShowUserMenu, isTestSystem } = props;
    const { t } = useTranslate();
    const { i18n } = useTranslation();
    const history = useHistory();
    const planningModuleKey = menuItem.planningModuleKey;
    const menuKey = menuItem.eventKey;
    const menuIcon = menuItem.icon;
    const iconId = menuItem.iconId;
    const menuTitle = t(menuItem.translatorKey, menuItem.translatorDefault);
    const isNewMenu = menuItem.isNewMenu;
    const isSeparatedMenu = menuItem.isSeparatedMenu;
    const hasSubscriptionBackground = menuItem.hasSubscriptionBackground;
    const hasSeparatedBackground = menuItem.hasSeparatedBackground;
    const hasPreIcon = menuItem.hasPreIcon;
    const style = menuItem.style;
    const newTabUrl = menuItem.newTabUrl;

    const location = useLocation().pathname;
    const [isSelected, setIsSelected] = useState(false);
    const [isPlanningMode] = useState(sessionService.getPlanningMode());
    const classes = useStyles({ menuIcon, expanded, isSeparatedMenu, iconId, isTestSystem } as any);

    useEffect(() => {
        if (location && menuKey) {
            const currentContentObject = routerHelper.getContentObjectByUrl(location);

            if (isPlanningMode && planningModuleKey && currentContentObject && currentContentObject.PLANNING_MODULE) {
                setIsSelected(planningModuleKey === currentContentObject.PLANNING_MODULE);
            } else {
                setIsSelected(routerHelper.getCurrentModuleName(location) === menuKey);
            }
        }
    }, [location, menuKey, isPlanningMode, planningModuleKey]);

    function getUrl() {
        if (newTabUrl) {
            return newTabUrl;
        }

        const routePage = routeList.find(page => page.eventKey === menuKey);
        const redirectUrl = routePage ? routePage.path : null;

        if (redirectUrl) {
            return `/${agentName}/${redirectUrl}`;
        }
    }

    function menuClick(event) {
        event.preventDefault();

        if (newTabUrl) {
            window.open(newTabUrl, '_blank');
        } else if (event?.ctrlKey) {
            const url = getUrl();
            window.open(url, '_blank');
        } else {
            const routePage = routeList.find(page => page.eventKey === menuKey);
            if (routePage) {
                const navigateToPathname = routePage.path;

                if (location.pathname !== navigateToPathname) {
                    history.push(`/${agentName}/${navigateToPathname}`);
                }
            }
        }
        setShowUserMenu(false);
    }

    return (
        <a href={getUrl()} className={isSelected ? classes.selectedItem : classes.menuItem} title={i18n.t(menuItem.translatorKey, menuItem.translatorDefault)} onClick={event => menuClick(event)}>

            <SideNavBarItemOldImage menuIcon={menuIcon} hasPreIcon={hasPreIcon} hasSeparatedBackground={hasSeparatedBackground} hasSubscriptionBackground={hasSubscriptionBackground} style={style} expanded={expanded} isPlanningMode={isPlanningMode} />
            <SideNavBarItemNewImage iconId={iconId} menuTitle={i18n.t(menuItem.translatorKey, menuItem.translatorDefault)} expanded={expanded} hasSeparatedBackground={hasSeparatedBackground} isNewMenu={isNewMenu} />

            <div className={classes.menuTitle}>
                {menuTitle}
            </div>
        </a>
    );
}
