/* eslint-disable id-match */
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import OutsideClickHandler from 'react-outside-click-handler';
import { userActions } from '@states/actions/userActions';
import { errorActions } from '@states/actions/errorActions';
import { alertActions } from '@states/actions/alertActions';
import useTranslate from '@i18n/useTranslate';
import { userService } from '@services';
import Input from '../../baseComponents/inputs/Input';
import Button from '../../baseComponents/buttons/Button';

const useStyles = createUseStyles((theme: any) => ({
    changePw: {
        position: 'absolute',
        left: 0,
        top: 0,
        padding: [0, 30],
        height: '100vh',
        width: 360,
        boxShadow: `inset 2px 0 0 0 ${theme.color.lilian}`,
        display: (props: any) => (props.showChangePassword ? 'block' : 'none'),
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 1.43,
        color: theme.color.jet,
        background: theme.color.white,
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '1.67',
        color: theme.color.jet,
        marginTop: 20,
    },
    password: {
        marginTop: 30,
    },
    confirmPassword: {
        marginTop: 15,
    },
    footer: {
        bottom: 0,
        position: 'fixed',
        paddingBottom: 25,
        width: 300,
        display: 'flex',
        justifyContent: 'space-around',
    },
    saveBtn: {
        minWidth: 130,
    },
}));

export default function ChanegPassword(props) {
    const { showChangePassword, setShowChangePassword, expanded } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const tempPasswords = {
        current: '',
        new: '',
        confirm: '',
    };
    const [passwords, setPasswords] = useState({ ...tempPasswords });
    const [isValidPasswords, setValidPasswords] = useState<any>({
        current: true,
        new: true,
        confirm: true,
    });
    const [errorMessages, setErrorMessages] = useState<any>({});
    const classes = useStyles({ showChangePassword, expanded } as any);

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const change = passwords;
        change[name] = value;
        setPasswords({ ...passwords, [name]: value });
    }

    async function saveNewPassword() {
        try {
            setErrorMessages({
                current: !passwords.current.length ? t('changePassword.currentPasswordRequired', 'Current password field is required') : t('changePassword.incorrectPassword', 'Incorrect password'),
                new: !passwords.new.length && t('changePassword.newPasswordRequired', 'New password filed is required'),
                confirm: !passwords.confirm.length ? t('changePassword.confirmPasswordRequired', 'Confirm new password filed is required') : t('changePassword.passwordsNotEqual', 'Passwords are not equa,ls'),
            });

            setValidPasswords({
                current: passwords.current.length,
                new: passwords.new.length,
                confirm: passwords.confirm.length,
            });

            const hasSpecialCharacters = /[@#$%^&*()+={}[\]:;"'|<>?,\\/~`]/.test(passwords.new);
            if (hasSpecialCharacters) {
                setValidPasswords({ current: isValidPasswords.current, new: false, confirm: isValidPasswords.confirm });
                setErrorMessages({ current: errorMessages.current, new: t('changePassword.invalidCharacters', 'The password cannot contain the following special characters!'), confirm: errorMessages.confirm });
                return;
            }

            if (passwords.current.length && passwords.new.length && passwords.confirm.length) {
                const isValidPassword = await userService.isValidPassword(passwords.current);
                const isSamePassword = passwords.new === passwords.confirm;

                setValidPasswords({
                    current: isValidPassword,
                    new: true,
                    confirm: isSamePassword,
                });

                if (isValidPassword && isSamePassword) {
                    const successPwChange = await userService.setNewPassword(passwords.new);
                    if (successPwChange) {
                        dispatch(userActions.updateUser());
                        setShowChangePassword(false);
                        setPasswords({ ...tempPasswords });
                        dispatch(alertActions.successAlert(t('changePassword.successPwChange', 'You successfully set your new password!')));
                    } else {
                        dispatch(alertActions.errorAlert(t('changePassword.unSuccesPwChange', 'Unsuccessfully password change!')));
                    }
                }
            }
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }

    function cancel() {
        setPasswords({ ...tempPasswords });
        setValidPasswords({
            current: true,
            new: true,
            confirm: true,
        });
        setShowChangePassword(false);
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setShowChangePassword(false)}>
            <div className={classes.changePw}>
                <div className={classes.title}>{t('changePassword.changePassword', 'Change password')}</div>
                <div>
                    <Input
                        className={classes.password}
                        value={passwords.current}
                        onChange={handleChange}
                        name="current"
                        type="password"
                        invalid={!isValidPasswords.current}
                        errorMessage={errorMessages.current}
                        label={t('changePassword.currentPassword', 'Current password')}
                        required
                    />
                    <Input
                        className={classes.password}
                        value={passwords.new}
                        onChange={handleChange}
                        name="new"
                        type="password"
                        invalid={!isValidPasswords.new}
                        errorMessage={errorMessages.new}
                        label={t('changePassword.newPassword', 'New password')}
                        required
                    />
                    <Input
                        className={classes.confirmPassword}
                        value={passwords.confirm}
                        onChange={handleChange}
                        name="confirm"
                        type="password"
                        invalid={!isValidPasswords.confirm}
                        errorMessage={errorMessages.confirm}
                        label={t('changePassword.confirmPassword', 'Confirm password')}
                        required
                    />
                </div>
                <div className={classes.footer}>
                    <Button type="secondary" size="large" onClick={cancel}>
                        {t('default.cancel', 'cancel')}
                    </Button>
                    <Button className={classes.saveBtn} size="large" onClick={saveNewPassword}>
                        {t('default.save', 'Save')}
                    </Button>
                </div>
            </div>
        </OutsideClickHandler>
    );
}
