import { routerHelper } from '@utils/router/RouterHelper';
import { isNil } from 'lodash';
import { sendServerRequest } from './baseService';

export const productionYearService = {
    getProductionYears,
    setProductionYear,
};

/**
 * Get all period year datas
 * @property {int} isPlanning Optional - set 1 if isPlanning
 * @return {array} Production years array
 */
async function getProductionYears({ isPlanning }) {
    let params = {};

    if (!isNil(isPlanning)) {
        params = { isPlanning };
    }
    const url = routerHelper.getUrlWithParameters('/api/productionYear', params);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Create new period year
 * @property {int} productionYear IsRequired -  Production year eg.: 2021
 * @property {string} period IsRequired -  Period string eg.: 2020/2021
 *
 * @return {ind} New production year id
 */
async function setProductionYear(productionYear, period) {
    const response = await sendServerRequest('POST', '/api/productionYear', { productionYear, period });
    return response;
}
