import React from 'react';
import { createUseStyles } from 'react-jss';
import GridLayout from 'react-grid-layout';
import { isEmpty, upperFirst, toLower } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import clsx from 'clsx';
import { languageUtils } from 'shared/src/modules';
import Button from '../../../buttons/Button';
import Svg from '../../../Svg';
import useTableColumnOrderChangeHandler from './useTableColumnOrderChangeHandler';

const useStyles = createUseStyles((theme: any) => ({
    contentTitle: {
        marginLeft: 15,
    },
    footerWrapper: {
        display: 'flex',
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 15,
        marginBottom: 10,
    },
    submitButton: {
        margin: 'auto',
    },
    gridWrapper: {
        borderTop: `1px solid ${theme.color.pearl}`,
        borderBottom: `1px solid ${theme.color.pearl}`,
        width: '100%',
        height: 'min-content',
        maxHeight: 395,
        position: 'relative',
        overflow: 'hidden',
        overflowY: 'auto',
        ...theme.verticalScrollbar,
        '& .react-grid-layout': {
            width: 200,
            height: 'auto',
            '& .react-resizable': {
                ...theme.disableOutline,
            },
        },
    },

    columnOrderWrapper: {
        width: '100% !important',
        height: 20,
        display: 'flex',
        alignItems: 'center',
        cursor: 'grab',
    },
    orderIcon: {
        verticalAlign: 'middle',
        width: 20,
        minWidth: 20,
        height: 20,
        marginRight: 10,
        fill: theme.color.stone,
        display: 'block',
    },
    columnNameWrapper: {
        verticalAlign: 'middle',
        height: 20,
        display: 'inline-flex',
        width: '100%',
    },
    columnName: {
        verticalAlign: 'middle',
        height: 20,
        lineHeight: '20px',
        fill: theme.color.stone,
        width: 'auto',
        maxWidth: 'calc(100% - 40px)', //absolute value paddings
        fontSize: 12,
        fontWeight: 500,
        color: theme.color.white,
        background: theme.color.main,
        borderRadius: 4,
        margin: 0,
        padding: 0,
        paddingLeft: 5,
        paddingRight: 5,
        ...theme.overFlowText,
        ...theme.firstLetterUppercase,
        ...theme.disableSelectionColor,
    },
}));

export default function ColumnOrderContent(props) {
    const { columnsLayout, columns } = props;
    const { t, translater } = useTranslate();
    const classes = useStyles();

    const { onLayoutChange, onClickSubmit } = useTableColumnOrderChangeHandler({ columns });

    function onDragStart() {
        document.getElementById('gridWrapper').style.cursor = 'grabbing';
        const gridItemWrappers = document.getElementsByClassName('gridItemWrapper');
        if (!isEmpty(gridItemWrappers)) {
            Object.values(gridItemWrappers)?.forEach(gridItemWrapper => { gridItemWrapper.style.cursor = 'grabbing'; });
        }
    }

    function onDragStop() {
        document.getElementById('gridWrapper').style.cursor = 'grab';
        const gridItemWrappers = document.getElementsByClassName('gridItemWrapper');
        if (!isEmpty(gridItemWrappers)) {
            Object.values(gridItemWrappers)?.forEach(gridItemWrapper => { gridItemWrapper.style.cursor = 'grab'; });
        }
    }

    function getTitle(title) {
        return title && upperFirst(toLower(languageUtils.getTitle(translater, title)));
    }

    return (
        <>
            <h4 className={classes.contentTitle}>{t('baseTable.sortColumns', 'Sorting columns')}</h4>
            <div id="gridWrapper" className={classes.gridWrapper}>
                <GridLayout className="layout" layout={columnsLayout} cols={1} rowHeight={30} width={190} onLayoutChange={layout => onLayoutChange(layout)} onDragStart={onDragStart} onDragStop={onDragStop}>
                    {columnsLayout?.map((row, index) => (
                        <div key={index} role="button" tabIndex="0" className={clsx(classes.columnOrderWrapper, 'gridItemWrapper')} data-grid={{ x: row.x, y: row.y, w: row.w, h: row.h, i: row.i }} title={getTitle(row.columnName)}>
                            <Svg iconId="icon-drag_vertical" style={classes.orderIcon} />
                            <div className={classes.columnNameWrapper}>
                                <h5 className={classes.columnName}>
                                    {row.columnName}
                                </h5>
                            </div>
                        </div>
                    ))}
                </GridLayout>
            </div>
            <div className={classes.footerWrapper}>
                <Button type="primary" className={classes.submitButton} onClick={onClickSubmit}>
                    {t('default.apply', 'Apply')}
                </Button>
            </div>
        </>
    );
}
