import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { isFunction, isEmpty, clone } from 'lodash';
import { stringUtils } from 'shared/src/modules';
import { SimpleSelect } from '@baseComponents/select';

const useStyles = createUseStyles((theme: any) => ({
    simpleSelectCell: {
        height: (props: any) => (props.isMultigrid ? 34 : '100%'),
        marginTop: (props: any) => (props.isMultigrid ? 0 : 8),
        width: '100%',
        //marginRight: 5,
        '& .dropdown-toggle.btn.btn-primary': {
            borderColor: (props: any) => props.isRequired && !props.hasSelectedItem && 'red',
        },
        '& .dropdown-menu': {
            width: (props: any) => props.dropdownWidth > 0 && `${props.dropdownWidth}px !important`,
        },
        '& .dropdown-menu.show': {
            width: (props: any) => props.dropdownWidth > 0 && `${props.dropdownWidth}px !important`,
        },
        '& .show': {
            width: (props: any) => props.dropdownWidth > 0 && `${props.dropdownWidth}px !important`,
        },
        '& button': {
            height: (props: any) => props.isMultigrid && '100%',
            padding: 0,
            paddingLeft: 5,
        },
    },
    iconDropdownCell: {
        width: '100%',
        height: '100%',
        background: theme.color.transparent,
        '& .dropdown-menu.show': {
            top: '-2px !important',
        },
    },
}));

export default function IconDropdownCell(props) {
    const { updateValue, rowData = props?.cellValue, value = props?.cellValue?.id, getDataList, columnId, onChangeSelected, isRequired, dropdownWidth, isNonAccentSearch, customSelectId, isDisabled } = props;
    const { isDisabledSearch, isIconWithTextToggle, isOnlyIconToggle, isDropdownOnHover } = props;
    const { style } = props;
    const [dataList, setDataList] = useState();
    const [filteredDataList, setFilteredDataList] = useState();
    const [selectedItem, setSelectedItem] = useState(value);
    const [isOpenedDropdown, setIsOpenedDropdown] = useState(false);
    const classes = useStyles({ isRequired, hasSelectedItem: !!selectedItem, dropdownWidth, isMultigrid: props?.cellValue });

    //init datalist
    useEffect(() => {
        if (isFunction(getDataList)) {
            const selectDataArray = getDataList(rowData);
            setDataList(selectDataArray);
            setFilteredDataList(selectDataArray);
        }
    }, [rowData, getDataList]);

    //search input onChange
    function filterByValue(filterValue) {
        if (!isEmpty(filterValue) && !isEmpty(dataList)) {
            setFilteredDataList(dataList.filter(listItem => stringUtils.includesNoCase(listItem.value, filterValue, isNonAccentSearch)));
        } else {
            setFilteredDataList(dataList);
        }
    }

    //onChange selected item
    function onChangeSelectedItem(changedValue) {
        const selectedObject = dataList?.find(dataRow => stringUtils.isSame(dataRow.key, changedValue));
        const currentValue = clone(selectedItem);
        setSelectedItem(changedValue);
        if (rowData && columnId && rowData[columnId]) {
            rowData[columnId] = changedValue;
        }

        if (customSelectId) {
            rowData[customSelectId] = selectedObject.key;
        }

        if (isFunction(onChangeSelected)) {
            onChangeSelected(changedValue, rowData, columnId, selectedObject, currentValue);
        }

        if (isFunction(updateValue) && !isFunction(onChangeSelected)) {
            updateValue(selectedObject);
        }

        if (isDropdownOnHover) {
            setIsOpenedDropdown(false);
        }
    }

    function onMouseEnter() {
        if (isDropdownOnHover && !isDisabled) {
            setIsOpenedDropdown(true);
        }
    }

    function onMouseLeave() {
        if (isDropdownOnHover && !isDisabled) {
            setIsOpenedDropdown(false);
        }
    }

    return (
        <div className={classes.iconDropdownCell} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={style}>
            <SimpleSelect
                dataList={filteredDataList}
                selected={selectedItem}
                customSelected={customSelectId ? rowData[customSelectId] : null}
                onChange={onChangeSelectedItem}
                className={classes.simpleSelectCell}
                search={!isDisabledSearch ? filterByValue : null}
                disabled={isDisabled}
                isOnlyIconToggle={isIconWithTextToggle ? false : isOnlyIconToggle || true}
                isIconWithTextToggle={isIconWithTextToggle}
                isOpenedDropdown={isOpenedDropdown}
                useAbsoluteMenu
            />
        </div>
    );
}
