import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { languageUtils } from 'shared/src/modules';
import { CROP, GENERAL, SYSTEM } from 'shared/src/constants';
import { useFormikContext } from 'formik';
import { dataListsActions } from '@states/actions';

export default function useLoadCultivationData() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { values, setFieldValue } = useFormikContext();
    const currentSystemModule = useSelector((state: any) => state.user.user.settings.activeSystemModule);
    const cropTypes = useSelector((state: any) => state.dataLists.cropTypes);
    const cultures = useSelector((state: any) => state.dataLists.cultures);
    const [cropTypeList, setCropTypeList] = useState();
    const [cultureList, setCultureList] = useState();
    const [initCultureId, setInitCultureId] = useState(null);
    const [currentCropDatas, setCurrentCropDatas] = useState(null);

    useEffect(() => {
        if (values.id && values.crop.culture && !initCultureId) {
            setInitCultureId(values.crop.culture);
        }
    }, [values.id, values.crop.culture, initCultureId]);

    //load crop types
    useEffect(() => {
        if (!isEmpty(cropTypes) && dispatch && location && t) {
            const selectCropTypes = cropTypes.map(cropTypeObj => ({
                key: cropTypeObj.id,
                value: languageUtils.getLangCaptionFromObject(i18n.language, cropTypeObj),
                // value: cropTypeObj.name,
            }));
            if (currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD || currentSystemModule === SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION) {
                selectCropTypes.push({
                    key: CROP.TYPE.PLANTATION,
                    value: t('cropData.plantation', 'Plantation'),
                });
            }
            setCropTypeList(selectCropTypes);
        }
    }, [dispatch, location, t, i18n, currentSystemModule, cropTypes]);

    //load culture list
    useEffect(() => {
        if (!isEmpty(cultures) && dispatch) {
            const activeCultures = dispatch(dataListsActions.getCultureDataList(GENERAL.FILTER.STATUS.ACTIV, initCultureId));
            const selectCultures = activeCultures?.map(cultureObj => ({
                value: cultureObj.shortName,
                key: cultureObj.id,
            }));
            setCultureList(selectCultures);
        }
    }, [dispatch, cultures, initCultureId]);

    useEffect(() => {
        if (values.cropType && values.cropType !== values.crop.type && cropTypeList) {
            const crop = cropTypeList.find(cropTypeItem => cropTypeItem.key === values.cropType);

            if (crop.key === CROP.TYPE.PLANTATION) {
                const cropDataToLoad = currentCropDatas || values.crop;
                setFieldValue('crop', {
                    ...cropDataToLoad,
                    type: crop.key,
                    typeName: crop.value,
                });
            } else {
                if (values.crop.plantationType || values.crop.year || values.crop.rowNumber || values.crop.lineLength || values.crop.lineSpacing || values.crop.plantDistance) {
                    setCurrentCropDatas(values.crop);
                }
                setFieldValue('crop', {
                    ...values.crop,
                    type: crop.key,
                    typeName: crop.value,
                });
            }
        }
    }, [values.cropType, cropTypeList, values.crop, setFieldValue]);

    return {
        cropTypeList,
        cultureList,
    };
}
