import useTranslate from '@i18n/useTranslate';
import { MachineDataResponse, TranslateType } from '@map/services/mapTypes';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { MACHINE_PANEL_PROP } from './MachinePanelProperty';

type MachineDataProp = {
    text: string,
    value?: number
}

export const useMachinePanelService = (machineData: MachineDataResponse) => {
    const { translater }: TranslateType = useTranslate();

    const workingStatus = useMemo<MachineDataProp>(() => {
        const workingState = machineData.attributes.Values.find(id => id.property === MACHINE_PANEL_PROP.IRRIGATION_WORKING_STATE);

        let workingText = '';

        if (isNil(workingState)) {
            workingText = 'N/A';
            return { text: workingText };
        }
        const workingValue = workingState.value;
        switch (workingValue) {
            case 0:
                workingText = translater('map.machinePanelIrrigating', 'Irrigating');
                break;
            case 1:
                workingText = translater('map.machinePanelRunning', 'Running');
                break;
            case 2:
                workingText = translater('map.machinePanelStopped', 'Stopped');
                break;
            case 3:
                workingText = translater('map.machinePanelAlert', 'Alert');
                break;
            default:
                workingText = 'UNKNOWN';
        }

        return { text: workingText, value: workingValue };
    }, [machineData, translater]);

    const lasdDataRefresh = useMemo<MachineDataProp>(() => {
        const timeText = machineData.attributes.Time;
        const date = timeText.slice(0, timeText.indexOf('T'));
        const time = timeText.slice(timeText.indexOf('T') + 1, timeText.indexOf('T') + 6);

        return { text: `${date} ${time}` };
    }, [machineData]);

    const flow = useMemo<MachineDataProp>(() => {
        const machineFlow = machineData.attributes.Values.find(id => id.property === MACHINE_PANEL_PROP.IRRIGATION_FLOW);

        if (isNil(machineFlow)) {
            return { text: '-' };
        }

        const flowValue = (machineFlow.value * 0.0000036).toFixed(2);
        const formattedValue = +flowValue;

        return { text: '-', value: formattedValue };
    }, [machineData]);

    const pressure = useMemo(() => {
        const machinePresure = machineData.attributes.Values.find(id => id.property === MACHINE_PANEL_PROP.IRRIGATION_PRESSURE);

        if (isNil(machinePresure)) {
            return { text: '-' };
        }

        const presureValue = (machinePresure.value / 1000000).toFixed(2);
        const formattedValue = +presureValue;

        return { text: '-', value: formattedValue };
    }, [machineData]);

    const irrigationSpeed = useMemo(() => {
        const machineIrrigationSpeed = machineData.attributes.Values.find(id => id.property === MACHINE_PANEL_PROP.IRRIGATION_SPEED);

        if (isNil(machineIrrigationSpeed)) {
            return { text: '-' };
        }

        const irrigationSpeedValue = (machineIrrigationSpeed.value / 1000).toFixed(2);
        const formattedValue = +irrigationSpeedValue;
        const text = `${formattedValue} %`;

        return { text, value: formattedValue };
    }, [machineData]);

    const irrigatedWater = useMemo(() => {
        const machineirrigatedWater = machineData.attributes.Values.find(id => id.property === MACHINE_PANEL_PROP.IRRIGATION_IRRIGATED_WATER);

        if (isNil(machineirrigatedWater)) {
            return { text: '-' };
        }

        const irrigatedWaterValue = (machineirrigatedWater.value / 100000000).toFixed(2);
        const formattedValue = +irrigatedWaterValue;
        const text = `${formattedValue} mm`;

        return { text, value: formattedValue };
    }, [machineData]);

    const remainingRevolution = useMemo(() => {
        const machineRemainingRevolution = machineData.attributes.Values.find(id => id.property === MACHINE_PANEL_PROP.REMAINING_REVOLUTION);

        if (isNil(machineRemainingRevolution)) {
            return { hours: 0, minutes: 0 };
        }

        const seconds = +machineRemainingRevolution.value;
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.round((seconds % 3600) / 60);

        return { hours, minutes };
    }, [machineData]);

    return { workingStatus, lasdDataRefresh, flow, pressure, irrigationSpeed, irrigatedWater, remainingRevolution };
};
