import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { isFunction } from 'lodash';
import Button from '@baseComponents/buttons/Button';
import PopoverContent from './PopoverContent';

const useStyles = createUseStyles((theme: any) => ({
    popupButtonWrapper: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
    },
    popupButton: {
        width: '100%',
        '& div': {
            padding: 0,
        },
        '& svg': {
            display: 'inline-block',
            marginRight: 5,
            fill: theme.color.black,
        },
        '& .content': {
            display: 'flex',
        },
        '& .title': {
            display: 'block',
            textTransform: 'none',
            width: '100%',
            textAlign: 'left',
            ...theme.overFlowText,
        },
    },
    popover: {
        padding: 5,
        border: 'none',
        borderRadius: 6,
        backgroundColor: theme.color.white,
        zIndex: theme.zIndex.popover,
        boxShadow: theme.shadows.dropdown,
        minWidth: 250,
        '& .arrow::before': {
            borderWidth: 8,
            top: -5,
            left: -15,
        },
        '& .arrow': {
            borderStyle: 'none',
        },
    },
}));

export default function PopoverListButtonCell(props) {
    const { cellValue, cellData, getHeaderTitle, rowData, value, getCellTitle, cellTitle, getIcon, icon, columnNumber, popoverContentComponent: PopoverContentComponent } = props;
    const { className, style, popoverStyle, popoverContentStyle } = props;
    const { getValue, getIsDisabled } = props;
    const { headerList, getList } = props;
    const classes = useStyles();

    function ignoreRowSelection(event) {
        document.body.click();
        event.stopPropagation();
    }

    return (
        <div className={clsx(classes.popupButtonWrapper, className)} style={style} onClick={ignoreRowSelection} onKeyDown={ignoreRowSelection} role="button" tabIndex="0">
            <OverlayTrigger
                trigger="click"
                placement="auto"
                rootClose
                overlay={
                    <Popover className={classes.popover} style={popoverStyle}>
                        <Popover.Content style={popoverContentStyle}>
                            {PopoverContentComponent
                                ? (
                                    <PopoverContentComponent
                                        {...props}
                                        title={isFunction(getCellTitle) ? getCellTitle(rowData || cellData) : cellTitle}
                                        dataList={isFunction(getList) ? getList(rowData || cellValue) : null}
                                        headerList={headerList}
                                    />
                                ) : (
                                    <PopoverContent
                                        title={isFunction(getCellTitle) ? getCellTitle(rowData || cellData) : cellTitle}
                                        headerTitle={isFunction(getHeaderTitle) ? getHeaderTitle(rowData || cellValue) : null}
                                        dataList={isFunction(getList) ? getList(rowData || cellValue) : null}
                                        columnNumber={columnNumber}
                                    />
                                )
                            }
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button
                    className={classes.popupButton}
                    size="small"
                    type="secondary"
                    iconId={isFunction(getIcon) ? getIcon(rowData || cellData) : icon}
                    iconPosition="before"
                    title={isFunction(getCellTitle) ? getCellTitle(rowData || cellData) : cellTitle}
                    disabled={!isFunction(getList) || isFunction(getIsDisabled) ? getIsDisabled(rowData || cellData) : false}
                >
                    {isFunction(getValue) ? getValue(rowData) : (value || cellValue)}
                </Button>
            </OverlayTrigger>
        </div>
    );
}
