/* eslint-disable no-restricted-imports */
import { LoadSpinner } from '@baseComponents/loadSpinner';
import BackToCenterButton from '@map/components/BackToCenter';
import BackToGeom from '@map/components/BackToGeom';
import CursorCoordinates from '@map/components/CursorCoordinates';
import DrawControl from '@map/components/DrawControl';
import { FeatureSelector } from '@map/components/FeatureSelector/FeatureSelector';
import InfoPanel from '@map/components/InfoPanel';
import LayerUploadModal from '@map/components/LayerCreateModal/LayerUploadModal';
import LayerPanel from '@map/components/LayerPanel';
import MachinePanel from '@map/components/MachinePanel';
import MapCurrencySwitch from '@map/components/MapCurrencySwitch';
import LayerPanelToggle from '@map/components/LayerPanelToggle';
import MapHolder from '@map/components/MapHolder';
import MapStyleModifier from '@map/components/MapStyleModifier';
import MeasureTool from '@map/components/MeasureTool';
import { MeasureToolInfoComponent } from '@map/components/MeasureToolInfo/MeasureToolInfoComponent';
import ZoomControl from '@map/components/ZoomControl';
import { LayerCreateContextProvider } from '@map/services/layerCreate/layerCreateContext';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes, MapMode } from '@map/services/mapEnums';
import { ThemeType } from '@map/services/mapTypes';
import { Ref, useEffect, useRef, useState } from 'react';
import { isNil } from 'lodash';
import { createUseStyles } from 'react-jss';
import MapSlider from '@map/components/MapSlider';
import MapSliderButton from '@map/components/MapSliderButton';
import LayerExportModal from '@map/components/LayerCreateModal/LayerExportModal/LayerExportModal';
import MapFilter from '@map/components/MapFilter';
import MapMultiModeButton from '@map/components/MapMultiModeButton';
import ContextMenu from '@map/components/ContextMenu';
import SlovakReportsOverlay from '@map/components/SlovakReportsOverlay/SlovakReportsOverlay';
import { MapProps } from './MapScreenContainer';
import useTranslate from '@i18n/useTranslate';
import MapSearchComponent from '@map/components/MapSearch';

type Classes = 'container' | 'container' | 'mapWrapper' | 'bottomRightContainer' | 'bottomLeftContainer' | 'selectorContainer' | 'measureToolInfoContainer' | 'zoomControlContainer' | 'layerSelectorContainer' | 'currencySwitchContainer' | 'mapSliderContainer';

type StyleProps = {
    withMapFilter: boolean
}

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    mapWrapper: {
        position: 'relative',
        height: '100%',
        width: '100%',
    },
    bottomRightContainer: {
        zIndex: 100,
        position: 'absolute',
        bottom: 115,
        right: 13,
    },
    bottomLeftContainer: {
        zIndex: 100,
        position: 'absolute',
        bottom: 5,
        left: 85,
    },
    selectorContainer: {
        zIndex: 100,
        position: 'absolute',
        bottom: 20,
        left: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    measureToolInfoContainer: {
        zIndex: 100,
        position: 'absolute',
        bottom: 24,
        right: 80,
    },
    zoomControlContainer: {
        zIndex: 100,
        position: 'absolute',
        bottom: 39,
        right: 13,
    },
    layerSelectorContainer: {
        zIndex: 100,
        position: 'absolute',
        left: 20,
        bottom: 100,
    },
    mapSliderContainer: {
        zIndex: 100,
        position: 'absolute',
        marginLeft: 'auto',
        marginRight: 'auto',
        left: 0,
        right: 0,
        width: '80%',
        height: 60,
        bottom: 60,
    },
    currencySwitchContainer: {
        zIndex: 100,
        position: 'absolute',
        top: ({ withMapFilter }) => (withMapFilter ? 12 : 20),
        right: 13,
    },
}));

interface TProps extends MapProps {
    mapRef: Ref<HTMLDivElement>,
}

export default function Map({
    layerType = BuiltInLayerTypes.CULTIVATION_PERIOD,
    filteredLayerTypes = [],
    entityId,
    setSavedGeomFeature,
    setSavedGeomId,
    onDrawingSaveClick,
    onGeometrySelector,
    withDrawingSaveButton = true,
    setZoomEntityBBoxCallback,
    parentId,
    withDrawing,
    withMeasureTool,
    zoomEntityId,
    zoomEntityBBox,
    withBackToGeom,
    withCoordinates,
    withInfoPanel,
    withMachinePanel,
    withSelector,
    withMapSearch,
    withMapSlider,
    withMapStyleModifier,
    layerPanelOptions = { withLayerPanel: false, isFileUploadAllowed: true, isDefaultLayerTypeMandatory: false },
    withBackToCenterButton,
    selectorDefaultEntityIds,
    mapRef,
    initDrawnFeature,
    withCurrencySwitch,
    onMapCenterChanged,
    withMapMultiMode,
    withMapFilter,
    mapMode,
    isMapRoute = false,
    withSlovakOverlay = false,
    gsaaYear,
    gsaaKODKD,
    planningEditDataRef,
    onSavePlanningEditModal,
}: TProps) {
    const classes = useStyles({ withMapFilter: withMapFilter ?? false });
    const { translater } = useTranslate();
    const [isUploadWindowOpen, setIsUploadWindowOpen] = useState(false);
    const [isExportWindowOpen, setIsExportWindowOpen] = useState(false);

    const [store, controller] = useMapContext();

    const [isMeasuring, setIsMeasuring] = useState(false);
    const [isMapSliderEnabled, setIsMapSliderEnalbed] = useState(false);

    const { islayerPanelEnabledDefault = true } = layerPanelOptions;

    const [isLayerPanelEnabled, setIsLayerPanelEnabled] = useState(layerPanelOptions?.withLayerPanel && islayerPanelEnabledDefault);

    const mapIsHovered = useRef<boolean>(false);

    useEffect(() => {
        setIsMeasuring(store.tools.isMeasuring);
    }, [store.tools.isMeasuring]);

    const onChangeMapSliderEnabled = () => {
        setIsMapSliderEnalbed(enabled => {
            if (enabled) {
                controller.setMapSliderInterval(null);
            } else {
                controller.setMapSliderInterval(new Date());
            }
            return !enabled;
        });
    };

    const { withLayerPanel, isFileUploadAllowed, isDefaultLayerTypeMandatory } = layerPanelOptions ?? {};
    return (
        <SlovakReportsOverlay mapRef={store.googleRefs.map} isEnabled={withSlovakOverlay} gsaaYear={gsaaYear as number} gsaaKODKD={gsaaKODKD as string}>
        <div
            className={classes.container}
            onMouseEnter={() => { mapIsHovered.current = true; }}
            onMouseLeave={() => { mapIsHovered.current = false; }}
        >
            {withMapFilter ? <MapFilter /> : null}
            <div className={classes.mapWrapper}>
                <LoadSpinner value={!store.layering.loading} isAutoDetect />
                {withDrawing ? (
                    <DrawControl
                        withSlovakOverlay={withSlovakOverlay}
                        setSavedGeomId={setSavedGeomId}
                        setSavedGeomFeature={setSavedGeomFeature}
                        onDrawingSaveClick={onDrawingSaveClick}
                        withDrawingSaveButton={withDrawingSaveButton}
                        setZoomEntityBBoxCallback={setZoomEntityBBoxCallback}
                        layerType={layerType}
                        entityId={entityId}
                        parentId={parentId}
                        initDrawnFeature={initDrawnFeature}
                        zoomEntityBBox={zoomEntityBBox}
                    />
                ) : null}
                
                {withMapSearch ? <span title={translater('default.search', 'Search')}><MapSearchComponent /></span> : null}
                {(withMeasureTool || withBackToCenterButton || withMapStyleModifier || withBackToGeom || withLayerPanel || withMapMultiMode || withMapSlider) ? (
                    <div className={classes.bottomRightContainer}>
                        {(withLayerPanel && store.mapMode !== MapMode.PLANNING_EDIT) ? <span title={translater('map.buttonLayerSelectorVisibility', 'Layer selector')}><LayerPanelToggle isEnabled={isLayerPanelEnabled} onChange={isEnabled => setIsLayerPanelEnabled(isEnabled)} /></span> : null}
                        {withMapSlider ? <span title={translater('map.buttonTimeline', 'Timeline')}><MapSliderButton onClick={() => onChangeMapSliderEnabled()} /></span> : null}
                        {withBackToGeom ? <span title={translater('map.buttonBackToCenter', 'Back to center')}><BackToGeom zoomEntityBBox={zoomEntityBBox} /></span> : null}
                        {(!isNil(withMapMultiMode) && mapMode === MapMode.DEFAULT) ? <span title={translater('map.buttonSplitView', 'Split view')}><MapMultiModeButton {...withMapMultiMode} /></span> : null}
                        {withMapStyleModifier ? <span title={translater('map.buttonSettlements', 'Settlements')}><MapStyleModifier /></span> : null}
                        {(withMeasureTool && store.mapMode !== MapMode.PLANNING_EDIT) ? <span title={translater('map.buttonMeasure', 'Measure')}><MeasureTool /></span> : null}
                        {withBackToCenterButton ? <span title={translater('map.buttonBackToCenter', 'Back to center')}><BackToCenterButton zoomEntityId={zoomEntityId} /></span> : null}
                    </div>
                ) : null}

                {mapMode && [MapMode.PLANNING, MapMode.PLANNING_EDIT].includes(mapMode) ? (
                    <ContextMenu mapMode={mapMode} planningEditDataRef={planningEditDataRef} onSavePlanningEditModal={onSavePlanningEditModal} />
                ) : null}

    
                {withMeasureTool ? (
                    <div className={classes.measureToolInfoContainer}>
                        <MeasureToolInfoComponent />
                    </div>
                ) : null}
                {withCoordinates ? (
                    <div className={classes.bottomLeftContainer}>
                        <CursorCoordinates />
                    </div>
                ) : null}
                {withInfoPanel && !isMeasuring ? (
                    <InfoPanel isMapRoute={isMapRoute} />
                ) : null}
                {withMachinePanel && !isMeasuring ? (
                    <MachinePanel />
                ) : null}
                {isLayerPanelEnabled ? (
                    <>
                        <LayerPanel
                            isMeasuring={isMeasuring}
                            setIsUploadWindowOpen={setIsUploadWindowOpen}
                            setIsExportWindowOpen={setIsExportWindowOpen}
                            isFileUploadAllowed={isFileUploadAllowed || false}
                            isDefaultLayerTypeMandatory={!!isDefaultLayerTypeMandatory}
                            layerType={layerType}
                            filteredLayerTypes={filteredLayerTypes}
                        />
                        <LayerCreateContextProvider>
                            {isUploadWindowOpen && <LayerUploadModal setIsUploadWindowOpen={setIsUploadWindowOpen} />}
                            {isExportWindowOpen && <LayerExportModal setIsExportWindowOpen={setIsExportWindowOpen} />}
                        </LayerCreateContextProvider>
                    </>
                ) : null}

                <MapHolder ref={mapRef} />

                <div className={classes.zoomControlContainer} title={translater('map.buttonZoom', 'Zoom')}>
                    <ZoomControl onMapCenterChanged={onMapCenterChanged} mapIsHovered={mapIsHovered} />
                </div>
                {withSelector ? (
                    <div className={classes.selectorContainer}>
                        <FeatureSelector onGeometrySelector={onGeometrySelector} selectorDefaultEntityIds={selectorDefaultEntityIds} />
                    </div>) : null
                }
            </div>
            {isMapSliderEnabled ? <MapSlider /> : null}
            {(withCurrencySwitch && store.mapMode !== MapMode.PLANNING_EDIT) ? (
                <div className={classes.currencySwitchContainer}>
                    <MapCurrencySwitch />
                </div>
            ) : null}
        </div>
        </SlovakReportsOverlay>
    );
}
