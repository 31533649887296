import { SETTINGS, RIGHTS } from '../constants';

export const FARM = {
    MODULE_ID: 32,
    NAME: 'farm',
    CONTENTS: {
        FARM: {
            CONTENT_URL_ID: { farm: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'farm',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.farm',
                TRANSLATOR_DEFAULT: 'Farm',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.FARM_INTERFACE],
            SETTINGS: [
                { [SETTINGS.USING_FARM]: 1 },
                { [SETTINGS.USE_GLOBAL_LITE]: 0 }
            ],
        },
    },
};
