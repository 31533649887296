import { useEffect, useState, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { isArray } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import { useNumberFormat } from '@common_modules/numberFormat';
import { theme } from '@styles/theme';
import { UNITS, TABLE } from 'shared/src/constants';

import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import { Area } from '@baseComponents/formatters';

export default function useRegistryNumberTableData() {
    const { values, setFieldValue } = useFormikContext();
    const [changedArea, setChangedArea] = useState();
    const [deletedRow, setDeletedRow] = useState();

    const { t, translater } = useTranslate();
    const { numberFormatter, isInitialized } = useNumberFormat();

    const [defaultColumns, setDefaultColumns] = useState(null);

    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    const [tableGroup] = useState(
        {
            value: null,
            text: 'No breakdown',
        },
    );

    const onModalUpdate = useCallback(registryNumbers => {
        const oldSelectedValues = values.selectedRegistryNumbers || [];
        let regNums = isArray(registryNumbers) ? registryNumbers : [registryNumbers];

        // if (!isEmpty(oldSelectedValues)) {
        //     regNums = regNums?.filter(filteredRegNum => !oldSelectedValues?.find(selectedRegNum => selectedRegNum.id === filteredRegNum.id));
        // }

        setFieldValue('selectedRegistryNumbers', [...regNums]);
    }, [setFieldValue, values.selectedRegistryNumbers]);

    useEffect(() => {
        if (numberFormatter && isInitialized) {
            setDefaultColumns([
                {
                    type: columnName.simpleText,
                    columnId: 'name',
                    columnName: t('registryNumberData.registryNumber', 'Registry number'),
                    width: 150,
                },
                {
                    type: columnName.doubleText,
                    columnId: 'settlement.postCode',
                    columnName: t('registryNumberData.settlement', 'Place'),
                    width: 150,
                    componentProps: {
                        secondValueId: 'settlement.name',
                        firstLineId: 'settlement.postCode',
                        secondLineId: 'settlement.name',
                    },
                },
                {
                    type: columnName.simpleText,
                    columnId: 'ownerName',
                    columnName: t('registryNumberData.owner', 'Owner'),
                    width: 150,
                },
                {
                    type: columnName.area,
                    columnId: 'sizeOfTheArea',
                    aggregateType: null,
                    aggregateFormatter: null,
                    columnName: t('registryNumberData.size', 'Size'),
                    width: 90,
                },
                {
                    type: columnName.decimal,
                    columnId: 'ak',
                    columnName: t('registryNumberData.value', 'Value'),
                    width: 100,
                    componentProps: {
                        unit: translater('newCultivationPeriod.registryNumberHeaderTextAk', 'GCV'),
                        decimal: UNITS.DECIMAL_NUMBER.REGISTRY_NUMBER_AREA,
                    },
                },
                {
                    columnId: 'area',
                    type: columnName.quantityInput,
                    columnName: t('registryNumberData.catSize', 'Cat. size'),
                    width: 100,
                    dataType: TABLE.DATA_TYPE.AREA,
                    aggregateType: TABLE.AGGREGATE.SUM,
                    aggregateFormatter: Area,
                    componentProps: {
                        tableKey: 'registryNumber',
                        quantityFormat: numberFormatter.getAreaFormats(),
                        onBlur: (value, rowData) => setChangedArea({ id: rowData.id, value }),
                    },
                },
                {
                    type: columnName.iconSvg,
                    columnId: 'icon-bin',
                    componentProps: {
                        icon: 'icon-bin',
                        getCellTitle: () => t('default.delete', 'Delete'),
                        getIconColor: () => theme.color.icons.deleteIcon,
                        onClick: rowData => {
                            setDeletedRow(rowData.id);
                        },
                    },
                    isRightFixed: true,
                    width: 50,
                },
            ]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberFormatter, isInitialized]);

    useEffect(() => {
        if (changedArea) {
            const newSelectedRows = values?.selectedRegistryNumbers?.map(selectedRow => {
                if (selectedRow.id === changedArea.id) {
                    return {
                        ...selectedRow,
                        area: changedArea.value,
                    };
                }
                return selectedRow;
            });

            setFieldValue('selectedRegistryNumbers', newSelectedRows);
            setChangedArea();
        }
    }, [changedArea, values, setFieldValue]);

    useEffect(() => {
        if (deletedRow) {
            const newSelectedRows = values?.selectedRegistryNumbers?.filter(selectedRow => selectedRow.id !== deletedRow);
            setFieldValue('selectedRegistryNumbers', newSelectedRows);
            setDeletedRow();
        }
    }, [deletedRow, values, setFieldValue]);

    return {
        columns,
        tableGroup,
        onModalUpdate,
    };
}
