import React from 'react';
import { createUseStyles } from 'react-jss';
import { numberUtils } from 'shared/src/modules';

import PhpPlanningHeader from './phpContentParts/PhpPlanningHeader';
import PhpHeader from './phpContentParts/PhpHeader';
import usePhpContent from './phpContentParts/usePhpContent';
import usePhpHibridFunctions from './phpContentParts/usePhpHibridFunctions';

const useStyles = createUseStyles({
    mainContent: {
        height: props => (props.planningModeisActive ? 'calc(100% - 70px)' : 'calc(100% - 45px)'),
        width: '100%',
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'hidden',
        paddingTop: 5,
        paddingLeft: '20px',
        paddingRight: '20px',
        display: 'block',
        '& #setup-menu-content': {
            height: '100% !important',
        },
        '& .setup-menu-content-wrapper .slimScrollDiv': {
            height: 'calc(100% - 10px) !important',
        },
    },
    phpManualHmrButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        borderRadius: 4,
    },
});

export default function PhpContent() {
    const { isLoadedPage, isSettingsContentActiv, planningModeisActive } = usePhpContent();
    const classes = useStyles({ planningModeisActive });
    usePhpHibridFunctions();

    return (
        <>
            <PhpPlanningHeader />
            <PhpHeader isLoadedPage={isLoadedPage} isSettingsContentActiv={isSettingsContentActiv} />
            <div id="main_content" className={classes.mainContent} />
            {numberUtils.getBoolean(process.env?.REACT_APP_PHP_MANUAL_HMR) ? (
                <div className={classes.phpManualHmrButton}>
                    {/* eslint-disable-next-line no-console*/}
                    <button type="button" onClick={() => { window.reloadPhpContent(); console.log('Content reloading...'); }}>
                        Reload
                    </button>
                </div>
            ) : null}
        </>
    );
}
