import HttpStatus from 'http-status-codes';
import { errorLogService, sentryService } from '@services';
import { isObject } from 'lodash';
import { dateTimeUtils } from 'shared/src/modules';
import i18n from '@i18n/i18n';
import { simpleAction } from './baseActions';
// eslint-disable-next-line import/no-cycle
import { routerActions } from './routerActions';

const errorActions = {
    setError,
    removeError,
    SET_ERROR: 'ERROR_SET_ERROR',
    SET_ERROR_MESSAGE: 'ERROR_SET_ERROR_MESSAGE',
    REMOVE_ERROR: 'ERROR_REMOVE_ERROR',
    SET_ERROR_CODE: 'ERROR_SET_ERROR_CODE',
};

export { errorActions };

function setError(errorObject: any, location: any, errorCode?: any) {
    return async (dispatch, getState) => {
        sentryService.captureException(errorObject);

        const { user } = getState();
        const userId = user ? user.user.id : null;
        const logTime = dateTimeUtils.getDateTimeNow();
        const statusCode = isObject(errorObject) ? errorObject.statusCode : errorCode;
        const errorMessage = isObject(errorObject) ? errorObject.message : errorObject;
        const isServerError = isObject(errorObject) ? errorObject.isServerError : false;

        if (!isServerError) {
            // eslint-disable-next-line no-console
            console.error('Client error: ', {
                errorObject,
                location,
                isServerError,
            });
        }

        //version update - error handler
        if (/Loading chunk [\d]+ failed/.test(errorMessage)) {
            dispatch(simpleAction(errorActions.SET_ERROR, {
                errorMessage: i18n.t('default.missingChunkError', 'Expired session. Please refresh the page to continue.'),
                errorCode: HttpStatus.PRECONDITION_FAILED,
            } as any));
            return;
        }

        //don't create log from the logged server errors and unauthorized errors
        if (!isServerError) {
            errorLogService.setErrorLog(location, errorObject, userId, logTime, statusCode);
        }

        if (errorObject.statusCode === HttpStatus.UNAUTHORIZED) {
            user.userError = 'UNAUTHORIZED';
        }

        //set error message - exclude: not found page and database connection error
        if (errorObject.statusCode !== HttpStatus.BAD_GATEWAY
            && errorObject.statusCode !== HttpStatus.SERVICE_UNAVAILABLE
            && errorObject.statusCode !== HttpStatus.GATEWAY_TIMEOUT) {
            dispatch(simpleAction(errorActions.SET_ERROR, { errorMessage, statusCode } as any));
        }

        //database connection error - handler
        if (errorObject.statusCode === HttpStatus.SERVICE_UNAVAILABLE) {
            dispatch(routerActions.setServerOverload(true));
        }
    };
}

function removeError() {
    return simpleAction(errorActions.REMOVE_ERROR);
}
