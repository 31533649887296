import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isUndefined, isNull } from 'lodash';

import FooterRowCell from './FooterRowCell';
import ListRowTemplate from './ListRowTemplate';

export default function ListFooterRow(props) {
    const { rowData, leftFixedColumns, mainColumns, rightFixedColumns, handleMidScroll, classes } = props;
    const { columnWidths, leftSideWidth, rightSideWidth, isDataCard, onSelectRow, rowStyle } = props;
    const { groupName, aggregateType } = props;
    const { tableKey, isBasePageTable } = props; //simple table

    const filteredTableData = useSelector((state: any) => state.basePage.table.filteredDatas);
    const simpleTableFilteredTableData = useSelector((state: any) => state.simpleTable.table?.[tableKey]?.filteredDatas);
    const simpleTableGroupingBy = useSelector((state: any) => state.simpleTable.table?.[tableKey]?.groupingBy);

    function getSimpleTableDataList() {
        let tableDataList = null;

        simpleTableFilteredTableData?.forEach(tableData => {
            if (tableData?.groupName === groupName) {
                tableDataList = tableData?.groupArray;
            }
        });
        return tableDataList;
    }

    function getGroupDataFromFilteredList() {
        const tableGroupData = isBasePageTable ? filteredTableData?.[groupName] : getSimpleTableDataList();
        if (tableGroupData && !isUndefined(groupName) && !isNull(groupName)) {
            return tableGroupData;
        }

        if (!isBasePageTable && simpleTableGroupingBy && !isUndefined(groupName) && !isNull(groupName)) {
            return simpleTableFilteredTableData?.filter(row => row[simpleTableGroupingBy] === groupName);
        }

        return null;
    }

    return (
        <ListRowTemplate
            classes={classes}
            rowStyle={rowStyle}
            rowData={rowData}
            leftSideWidth={leftSideWidth}
            handleMidScroll={handleMidScroll}
            rightSideWidth={rightSideWidth}
            isDataCard={isDataCard}
            onSelectRow={onSelectRow}
            tableKey={tableKey}
            left={
                !isEmpty(leftFixedColumns) && leftFixedColumns.map((lfColumn, idx) => lfColumn.isTitle && (
                    <FooterRowCell
                        key={idx}
                        column={lfColumn}
                        cellWidth={!isEmpty(columnWidths) ? columnWidths[idx] : null}
                        filteredTableData={getGroupDataFromFilteredList()}
                        aggregateType={aggregateType}
                    />
                ))
            }
            middle={
                !isEmpty(mainColumns) && mainColumns.map((mnColumn, idx) => (
                    <FooterRowCell
                        key={idx}
                        column={mnColumn}
                        cellWidth={!isEmpty(columnWidths) ? columnWidths[idx] : null}
                        filteredTableData={getGroupDataFromFilteredList()}
                        aggregateType={aggregateType}
                    />
                ))
            }
            right={
                !isEmpty(rightFixedColumns) && rightFixedColumns.map((rColumn, idx) => (
                    <FooterRowCell
                        key={idx}
                        column={rColumn}
                        cellWidth={!isEmpty(columnWidths) ? columnWidths[idx] : null}
                        filteredTableData={getGroupDataFromFilteredList()}
                        aggregateType={aggregateType}
                    />
                ))
            }
        />
    );
}
