import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isFunction, isNumber } from 'lodash';

import FormSelect from '@baseComponents/select/formSelect/FormSelect';
import AddNewPeriodYearModal from '@customComponents/modals/AddNewPeriodYearModal';

export default function PeriodYearDropdown(props) {
    const { periodYearId, setPeriodYearId, setDefaultPeriod, hasAddNewModal = true, className, disabled = false, defaultPeriodYearId } = props;

    const periodYears = useSelector((state: any) => state.dataLists.productionYears);
    const [periodYearList, setPeriodYearList] = useState();
    const [selectedPeriodYearId, setSelectedPeriodYearId] = useState(null);
    const [showAddNewModal, setShowAddNewModal] = useState(false);

    useLayoutEffect(() => {
        if (!isEmpty(periodYears) && periodYearId) {
            setSelectedPeriodYearId(periodYearId);
        }
    }, [periodYearId, periodYears]);

    //init period years
    useEffect(() => {
        if (!isEmpty(periodYears) && isEmpty(periodYearList)) {
            const selectPeriodYearList = periodYears.map(periodYearObj => ({
                value: periodYearObj.period,
                key: +periodYearObj.id,
                year: periodYearObj.year,
            }));
            setPeriodYearList(selectPeriodYearList);

            //set last period from list as default value
            if (!isEmpty(selectPeriodYearList) && isFunction(setDefaultPeriod) && isNumber(periodYearId)) {
                const defaultYear = periodYears.find(periodListObj => +periodListObj.id === +periodYearId);
                setDefaultPeriod(defaultYear.year);
            }

            //default first element
            if (!isEmpty(selectPeriodYearList)) {
                if (defaultPeriodYearId) {
                    setSelectedPeriodYearId(defaultPeriodYearId);
                    setPeriodYearId(defaultPeriodYearId);
                } else {
                    setSelectedPeriodYearId(selectPeriodYearList[0]?.key);
                    setPeriodYearId(selectPeriodYearList[0]?.key);
                }
            }
        }
    }, [periodYears, periodYearId, setDefaultPeriod, periodYearList, setPeriodYearId, defaultPeriodYearId]);

    function changeSelectedYear(selectedId) {
        if (!isEmpty(periodYears) && (!isEmpty(selectedId) || isNumber(selectedId))) {
            const defaultYear = periodYears?.find(periodListObj => periodListObj.id === +selectedId);
            if (defaultYear && isFunction(setDefaultPeriod)) {
                setDefaultPeriod(defaultYear.year);
            }

            if (setPeriodYearId) {
                setPeriodYearId(+selectedId);
            }

            setSelectedPeriodYearId(+selectedId);
        }
    }

    return (
        <>
            <FormSelect
                dataList={periodYearList}
                selected={selectedPeriodYearId}
                onChange={changeSelectedYear}
                type="add"
                addNew={() => setShowAddNewModal(true)}
                className={className}
                disabled={disabled}
            />
            {(showAddNewModal && hasAddNewModal)
                ? (
                    <AddNewPeriodYearModal
                        show={showAddNewModal}
                        handleCloseClicked={() => setShowAddNewModal(false)}
                        periodYearList={periodYearList}
                        changeSelectedYear={changeSelectedYear}
                        setPeriodYearList={setPeriodYearList}
                    />
                ) : null
            }
        </>
    );
}
