import SearchBar from '@baseComponents/inputs/SearchBar';
import Button from '@baseComponents/buttons/Button';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { useOnClickOutside } from '@flowsCommon/services/hooks';
import useTranslate from '@i18n/useTranslate';
import { Layer, LayerAttribute, LayerType, ThemeType, TranslateType } from '@map/services/mapTypes';
import { getLayerAttributeCaptionString } from '@map/utils/mapCaptionUtils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { LayerWithAttributes } from './LayerControlPanel';
import { LayerFolderContainer } from './LayerFolder/LayerFolderContainer';
import LayerSelectPanelAttributeGroup from './LayerSelectPanelAttributeGroup';
import { LayerSelectPanelLayer } from './LayerFolder/LayerSelectPanelLayer';
import useLayerPanelStyles from './styles';
import { getLayerName } from './LayerFolder/LayerFolderUtils';

type Classes = 'container' | 'layersWrapper' | 'buttonsLayersWrapper' | 'paddedContainer' | 'closeButton' | 'searchBar';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        width: 300,
        height: 'min(700px, 80%)',
        maxHeight: '70vh',
        backgroundColor: theme.color.white,
        borderRadius: 6,
        boxShadow: theme.shadows.map,
        display: 'flex',
        flexDirection: 'column',
        zIndex: theme.zIndex.popover,
        // transform: 'translateX(10%)',
    },
    paddedContainer: {
        padding: 15,
        '& > *': {
            marginTop: 10,
        },
    },
    layersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        fontSize: 16,
        color: theme.color.jet,
        overflowX: 'initial',
        flexGrow: 1,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 7,
            position: 'absolute',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            borderRadius: 7,
            background: 'rgba(0, 0, 0, 0.4)',
            position: 'absolute',
        },
        scrollbarWidth: 'thin !important',
    },
    buttonsLayersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 12,
        display: 'block',
        boxSizing: 'border-box',
        width: 30,
        height: 30,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: theme.color.pearl,
        borderRadius: '100%',
        background: '-webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, #0B0D0F 46%, #0B0D0F 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, #0B0D0F 46%, #0B0D0F 56%,transparent 56%, transparent 100%)',
        backgroundColor: '#E3E6E8',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
    },
    searchBar: {
        maxWidth: '85%',
    },
}));

type TProps = {
    layersWithAttributes: LayerWithAttributes[];
    addAttributesToMap: (layerAttribute: LayerAttribute[]) => void;
    closePanel: (event?: MouseEvent | TouchEvent) => void;
    setIsUploadWindowOpen: (isOpen: boolean) => void;
    setIsExportWindowOpen: (isOpen: boolean) => void;
    isFileUploadAllowed: boolean;
};

export default function LayerSelectPanel({ layersWithAttributes, addAttributesToMap, closePanel, setIsUploadWindowOpen, isFileUploadAllowed, setIsExportWindowOpen }: TProps) {
    const classes = useStyles();
    const commonClasses = useLayerPanelStyles();
    const [ref, setRef] = useState<HTMLDivElement | null>(null);
    const [layerSearchValue, setLayerSearchValue] = useState('');
    const agrovirLayer = layersWithAttributes.find(layer => layer.id === -1);
    const customLayer = layersWithAttributes.find(layer => layer.id === 0);
    const planningLayer = layersWithAttributes.find(layer => layer.name === BuiltInLayerTypes.PLANNING);
    const { t, translater }: TranslateType = useTranslate();

    // useOnClickOutside(ref, closePanel);

    const [store, controller] = useMapContext();

    const onRefChange = useCallback(node => {
        setRef(node);
    }, []);

    const filteredAttributeList: LayerWithAttributes[] = useMemo(() => {
        if (!layerSearchValue) {
            return layersWithAttributes;
        }

        return layersWithAttributes.filter(layerWithAttrs => {
            if (getLayerName(layerWithAttrs, translater).toLowerCase().includes(layerSearchValue.toLowerCase()) && layerWithAttrs.type === LayerType.FEATURE) {
                return true;
            }

            return false;
        });
    }, [layerSearchValue, layersWithAttributes, translater]);
    return (
        <div className={classes.container} ref={onRefChange}>

            <span
                className={classes.closeButton}
                onClick={() => closePanel()}
                role="button"
                aria-label="Close"
                aria-hidden="true"
            />
            <div className={classes.paddedContainer}>
                <SearchBar
                    height={32}
                    className={classes.searchBar}
                    currentSearchValue={layerSearchValue}
                    onChange={setLayerSearchValue}
                />

            </div>
            <div className={classes.layersWrapper}>
                {layerSearchValue
                    ? (
                        <>
                            {
                                filteredAttributeList?.map(layer => <LayerSelectPanelLayer closePanel={closePanel} layer={layer} mapController={controller} key={layer.id} />)
                            }
                        </>
                    ) : (
                        <>
                            {agrovirLayer && <LayerFolderContainer closePanel={closePanel} layerSelectorSettings={store.layerSelector} mapController={controller} parentLayer={agrovirLayer} layersWithAttributes={filteredAttributeList} isOpenDefault />}
                            {customLayer && <LayerFolderContainer closePanel={closePanel} layerSelectorSettings={store.layerSelector} mapController={controller} parentLayer={customLayer} layersWithAttributes={filteredAttributeList} />}
                            {planningLayer && <LayerFolderContainer closePanel={closePanel} layerSelectorSettings={store.layerSelector} mapController={controller} parentLayer={planningLayer} layersWithAttributes={filteredAttributeList} isOpenDefault />}
                        </>
                    )}
            </div>
            <div className={classes.paddedContainer}>
                {isFileUploadAllowed
                    && (
                        <Button
                            type="secondary"
                            iconId="icon-upload_table"
                            iconPosition="before"
                            className={commonClasses.button}
                            onClick={() => setIsUploadWindowOpen(true)}
                        >{t('map.uploadFileText', 'Upload file')}
                        </Button>
                    )
                }

                <Button
                    type="secondary"
                    iconId="icon-download"
                    iconPosition="before"
                    className={commonClasses.button}
                    onClick={() => setIsExportWindowOpen(true)}
                >{t('map.exportLayerText', 'Export layer')}
                </Button>
            </div>
        </div>
    );
}
