import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useTableGroupedDataHandlerInitValues(props) {
    const { isBasePageTable, tableKey } = props;

    //basePage
    const selectedGroup = useSelector((state: any) => state.basePage.selectedGroup);
    const columns = useSelector((state: any) => state.basePage.table.columns);
    const globalColumnSortingTypes = useSelector((state: any) => state.basePage.table.globalColumnSortingTypes);

    //simple table
    const simpleTableSelectedGroup = useSelector((state: any) => state.simpleTable.table[tableKey]?.selectedGroup);
    const simpleTableColumns = useSelector((state: any) => state.simpleTable.table[tableKey]?.columns);
    const simpleTableGlobalColumnSortingTypes = useSelector((state: any) => state.simpleTable.table[tableKey]?.globalColumnSortingTypes);
    const isUpdateDomTable = useSelector((state: any) => state.simpleTable.table[tableKey]?.isUpdateDomTable);

    const [initValues, setInitValues] = useState({
        selectedGroup: null,
        columns: null,
        globalColumnSortingTypes: null,
        isUpdateDomTable: null,
    });

    //init basePage table data
    useEffect(() => {
        if (isBasePageTable) {
            setInitValues({
                selectedGroup,
                columns,
                globalColumnSortingTypes,
                isUpdateDomTable: null,
            });
        }
    }, [isBasePageTable, selectedGroup, columns, globalColumnSortingTypes]);

    //init simple table data
    useEffect(() => {
        if (!isBasePageTable) {
            setInitValues({
                selectedGroup: simpleTableSelectedGroup,
                columns: simpleTableColumns,
                globalColumnSortingTypes: simpleTableGlobalColumnSortingTypes,
                isUpdateDomTable,
            });
        }
    }, [isBasePageTable, simpleTableSelectedGroup, simpleTableColumns, simpleTableGlobalColumnSortingTypes, isUpdateDomTable]);

    return initValues;
}
