import { useSelector } from 'react-redux';

export default function ModalRoot() {
    const components = useSelector((state: any) => state.modal.components);

    return (
        <>
            {components?.map((Component, index) => (
                Component
                    ? <Component key={index} />
                    : null
            ))}
        </>
    );
}
