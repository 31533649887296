import { isEmpty, isFunction, isNaN, isNull } from 'lodash';
import { useCallback, useEffect } from 'react';
import { NUMBERS } from 'shared/src/constants';
import { numberUtils } from 'shared/src/modules';
import { formatNumber } from './utils';

export default function useInput(props) {
    const { inputRef, setReference, type, isInteger, defaultValue, value, numberFormat, min, max, positiveOnly } = props;
    const { onChange, onFocus, onBlur, onKeyDown } = props;
    const {
        decimals = NUMBERS.DEFAULT.DECIMAL_NUMBERS,
        decimalSeparator = NUMBERS.DEFAULT.DECIMAL_SEPARATOR,
        thousandsSeparator = NUMBERS.DEFAULT.THOUSAND_SEPARATOR,
    } = numberFormat || {}; //number input

    let currentValueToValidate: any = '';
    let onKeyDownState = '';
    let onKeyUpState = defaultValue || null;

    //ez fog megjelenni az inputban
    const displayFormattedValue = useCallback(
        originalValue => {
            let displayedValue = null;

            switch (type) {
                case 'number':
                    displayedValue = formatNumber(originalValue, { decimals, decimalSeparator, thousandsSeparator });
                    inputRef.current.value = displayedValue;
                    break;
                default:
                    return value;
            }
        },
        [decimalSeparator, decimals, inputRef, thousandsSeparator, type, value],
    );

    function displayValue(originalValue) {
        inputRef.current.value = originalValue;
    }

    //külső forrás módosítja az értéket
    useEffect(() => {
        if (isNumber(value)) {
            // eslint-disable-next-line no-restricted-syntax
            const formattedValue = value === '' ? '' : numberUtils.getNumberFromFormattedString(value, true);
            displayFormattedValue(formattedValue);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    //init/default érték beállítás - formatter módosul
    useEffect(() => {
        if (isNumber(defaultValue) && numberFormat) {
            // eslint-disable-next-line no-restricted-syntax
            const formattedValue = defaultValue === '' ? '' : numberUtils.getNumberFromFormattedString(defaultValue, true);
            displayFormattedValue(formattedValue);
        }
    }, [defaultValue, displayFormattedValue, inputRef, numberFormat]);

    //figyelmeztetés numberFormat props kötelező jellegéről
    /*
    useEffect(() => {
        if (process.env.NODE_ENV !== 'production' && type === 'number' && isEmpty(numberFormat)) {
            console.warn('Use number formatter!');
        }
    }, [numberFormat, type]);
    */

    //init input ref
    useEffect(() => {
        if (!isEmpty(inputRef?.current) && isFunction(setReference)) {
            setReference(inputRef);
        }
    }, [inputRef, setReference]);

    //formatted value to display
    function getValue(inputValue) {
        let formattedValue = inputValue;

        switch (type) {
            case 'number':
                formattedValue = numberUtils.getDisplayedNumberFormat(formattedValue, decimals, decimalSeparator, thousandsSeparator);
                break;
            default:
                return formattedValue;
        }

        return formattedValue;
    }

    function readFormattedInput(inputValue, inputDecimals, isAllowedLastSeparatorCharacter = false) {
        return numberUtils.truncateNumberToSafeMinMax(
            numberUtils.truncateSurplusDecimals(
                numberUtils.getNumberFromFormattedString(inputValue, isAllowedLastSeparatorCharacter),
                inputDecimals,
            ),
        );
    }

    //on change validation
    function validatedValue(inputValue) {
        if (type === 'number') {
            // eslint-disable-next-line no-restricted-syntax
            currentValueToValidate = inputValue === ''
                ? ''
                : readFormattedInput(inputValue, decimals, true);

            if (positiveOnly && +currentValueToValidate < 0) { //copy paste only positive exception handle
                currentValueToValidate = Math.abs(+currentValueToValidate);
            }

            if (isNumber(+min) && +currentValueToValidate < +min) { //check min value
                currentValueToValidate = +min;
            }

            if (isNumber(+max) && +currentValueToValidate > +max) { //check max value
                currentValueToValidate = +max;
            }

            currentValueToValidate = numberUtils.confineNumberToSafeMinMax(currentValueToValidate, true);

            //10.0 -> végéről levágtunk egy nullát a formázással
            if (inputValue?.toString()?.charAt(inputValue?.toString()?.length - 1) === '0' && inputValue?.toString()?.split(decimalSeparator)?.[1]?.length) {
                const numberParts = inputValue?.toString()?.split(decimalSeparator);
                let decimalPart = numberParts?.[1];

                if (decimalPart.length > decimals) {
                    decimalPart = decimalPart?.substring(0, decimals);
                }
                displayFormattedValue(`${numberParts?.[0]}${decimalSeparator}${decimalPart}`);
            } else {
                displayFormattedValue(currentValueToValidate);
            }

            return isNaN(+currentValueToValidate) ? 0 : +currentValueToValidate;
        }

        return inputValue;
    }

    //Event handlers
    //on change value - validate it
    function onChangeInput(event) {
        let inputValue = event?.target?.value;

        // eslint-disable-next-line no-restricted-syntax
        if (type === 'number' && (isNaN(onKeyUpState) || (inputValue === '' && isNull(onKeyUpState)))) { //ignore wrong format
            event.preventDefault();
            return;
        }

        const caretPosition = event?.target?.selectionStart;

        inputValue = validatedValue(inputValue);

        //formázás után vissza kell állítani a kurzor pozíciót
        if (caretPosition !== event?.target?.selectionStart) {
            event.target?.setSelectionRange(caretPosition + 1, caretPosition + 1);
        }

        if (isFunction(onChange)) {
            inputValue = readFormattedInput(inputValue, decimals);
            event.target.rawValue = inputValue;
            onChange(event, inputValue);
        }
    }

    function onFocusInput(event) {
        let inputValue = event?.target?.value;
        if (isFunction(onBlur) || isFunction(onFocus)) {
            if (type === 'number') {
                inputValue = readFormattedInput(inputValue, decimals);
                inputValue = numberUtils.isNumber(inputValue) ? +inputValue : inputValue;
                event.target.rawValue = inputValue;
            }
        }
        if (isFunction(onBlur)) {
            displayFormattedValue(inputValue);
        }
        if (isFunction(onFocus)) {
            onFocus(event, inputValue);
        }
    }

    function onBlurInput(event) {
        let inputValue = event?.target?.value;

        if (type === 'number') {
            inputValue = readFormattedInput(inputValue, decimals);
            inputValue = numberUtils.isNumber(inputValue) ? +inputValue : inputValue;
            event.target.rawValue = inputValue;
            displayFormattedValue(inputValue);
        }

        if (isFunction(onBlur)) {
            onBlur(event, inputValue);
        }
    }

    //on key press - integer number validation
    //44 - vessző
    //46 - pont
    //45 - minusz
    function onKeyPress(event) {
        if (type === 'number' && positiveOnly && event.which === 45) { //ignore minus if only positive
            return;
        }
        if (type === 'number' && +currentValueToValidate < 0 && event.which === 45) { //ignore duplicated minus
            event.preventDefault();
            return;
        }
        if (type === 'number' && (event.which <= 43 || event.which === 47 || event.which > 57)) { //number type - ignore string
            event.preventDefault();
            return;
        }
        if (type === 'number' && (isInteger && (event.which === 44 || event.which === 46))) { //ignore decimal separator and minus
            event.preventDefault();
            return;
        }
        if (type === 'number' && (event.which === 44 || event.which === 46) && numberUtils.isFloat(currentValueToValidate)) { //ignore duplicate decimal separator
            event.preventDefault();
            return;
        }

        if (type !== 'number') {
            return;
        }

        const theEvent = event || window.event;
        let key: any = null;

        if (event.which === 38 || event.which === 40) { //ignore arrows
            event.preventDefault();
            return;
        }

        if (theEvent.type === 'paste') {
            key = event.clipboardData.getData('text/plain');
        } else {
            key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
        }

        const regex = /[-0-9]|\.|,/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) {
                theEvent.preventDefault();
            }
        }
    }

    //ignore keyboard up/down arrows
    function onKeyDownInput(event) {
        if (isFunction(onKeyDown)) {
            let inputValue = event?.target?.value;
            inputValue = readFormattedInput(inputValue, decimals);
            event.target.rawValue = inputValue;
            onKeyDown(event, inputValue);
        }
        if (type === 'number' && (event.which === 38 || event.which === 40)) { //up-down arrow
            event.preventDefault();
        }
        if (type === 'number') {
            const originalValue = event.which === 109 ? '-' : event?.target?.value; //handle negative number
            onKeyDownState = originalValue;
        }
    }

    //110 - vessző
    //109 - minusz
    //173 - kötőjel
    //190 - pont
    //8 - törlés
    //13 - enter
    function onKeyUp(event) {
        if (type === 'number' && (event.which === 109 || event.which === 173 || event.which === 110 || event.which === 190) && !isNumber(event?.target?.value)) {
            onKeyUpState = event?.target?.valueAsNumber;
            displayValue(onKeyDownState);
            event.preventDefault();
        } else if (type === 'number' && event.which === 8) { //törlés
            onKeyUpState = '';
        // } else if (event.which === 13) { //enter
            // event?.target?.blur(); // should be called explicitly from an event handler passed to the input
        } else if (type === 'number') {
            onKeyUpState = null;
        }
    }

    //custom utils
    function isNumber(numberString) {
        let formattedNumber = (numberString || numberString === 0) ? numberString.toString().replace(',', '.').replaceAll(' ', '') : null;

        if (formattedNumber === '-') {
            formattedNumber = 0;
        }

        if (isNull(formattedNumber)) {
            return false;
        }

        if (formattedNumber.toString().charAt(formattedNumber.length - 1) === '.') {
            formattedNumber = `${formattedNumber}0`;
        }

        // eslint-disable-next-line no-restricted-globals
        return !isNaN(Number(formattedNumber));
    }

    return {
        onKeyUp,
        onKeyDownInput,
        onKeyPress,
        onChangeInput,
        onFocusInput,
        onBlurInput,
        getValue,
    };
}
