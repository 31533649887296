/* eslint-disable id-match */
import { lazy } from 'react';
import { MODULES, getApiPath } from 'shared/src/routing';
import { hasAccessToContent } from 'shared/src/permissions';
import { sessionService } from '@services';
import PhpContent from '@contents/PhpContent';
import { routerHelper } from './RouterHelper';

function getRouteList(t, userToRights, administrator, consultant, companySettings, currentSystemModule, isHiddenUser, countryCode) {
    const planningMode = sessionService.getPlanningMode();
    const planningData = sessionService.getPlanningData();
    let moduleList = [];

    if (planningMode === true) {
        moduleList = [
            MODULES.PLANNING,
            MODULES.SUPPORT,
            MODULES.TASK,
            MODULES.PRODUCT,
            //settings
            MODULES.SETTINGS,
            MODULES.ANALYSIS,
            MODULES.COMPANY,
            MODULES.CONTROLLING,
            MODULES.REPORTS,
            MODULES.WIDGET,
            MODULES.PARTNER,
            MODULES.GENERAL,
            MODULES.CULTIVATION_PERIODS,
            MODULES.TEST,
            MODULES.LAND_ADMINISTRATION,
            MODULES.OFFICIAL_REPORTS,
            MODULES.STOCK,
            MODULES.USER,
            MODULES.WAREHOUSE,
            MODULES.INVOICE,
            MODULES.SCALE,
            MODULES.CROP_MANAGEMENT,
            MODULES.EMPLOYMENT,
            MODULES.EQUIPMENT,
            MODULES.LABOR,
            MODULES.DATA_ANALYSIS,
            MODULES.MAP,
        ] as any;
    } else {
        moduleList = [
            MODULES.AKI,
            MODULES.ANALYSIS,
            MODULES.COMPANY,
            MODULES.CONTROLLING,
            MODULES.CROP_MANAGEMENT,
            MODULES.CULTIVATION_PERIODS,
            MODULES.DATA_ANALYSIS,
            MODULES.ECONOMIC_UNIT,
            MODULES.EMPLOYMENT,
            MODULES.EQUIPMENT,
            MODULES.FARM,
            MODULES.FINANCIAL_FLOWS,
            MODULES.GENERAL,
            MODULES.HANSEAGRO,
            MODULES.INVOICE,
            MODULES.LABOR,
            MODULES.LAND_ADMINISTRATION,
            MODULES.LIVESTOCK_RECORDS,
            MODULES.MAP,
            MODULES.OFFICIAL_REPORTS,
            MODULES.PARTNER,
            MODULES.PRODUCT,
            MODULES.PRODUCTION,
            MODULES.REPORTS,
            MODULES.SCALE,
            MODULES.SETTINGS,
            MODULES.STOCK,
            MODULES.SUPPORT,
            MODULES.TASK,
            MODULES.TEST,
            MODULES.USER,
            MODULES.WAREHOUSE,
            MODULES.WIDGET,
            MODULES.WINERY,
            MODULES.LIVESTOCK_DAILY_TASKS,
            MODULES.LIVESTOCK_FEEDING,
            MODULES.METEOROLOGY,
        ] as any;
    }

    function getComponent(componentUrl) {
        return lazy(() => import(`../../components/${componentUrl}.tsx`).catch(error => {
            // eslint-disable-next-line no-console
            console.error('Content dynamic import error:', error);
            return { default: () => null };
        }));
    }

    let moduleRouteList: Array<any> = [];
    moduleList.forEach((moduleObj: any) => {
        const contentList = Object.entries(moduleObj.CONTENTS).map((content: any) => {
            const hasAccess = hasAccessToContent(moduleObj, content[1], userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode, isHiddenUser, planningData);
            return ({
                title: routerHelper.getContentTitle(t, content[1]),
                eventKey: content[1].EVENT_KEY,
                path: getApiPath(moduleObj, content[1]),
                hasAccess,
                component:
                    content[1].COMPONENT_URL === 'contents/PhpContent'
                        ? PhpContent
                        : getComponent(content[1].COMPONENT_URL),
                //componentProps,
            });
        });

        if (contentList) {
            const tempList: any = [];
            contentList.forEach(contentItem => {
                if (contentItem) {
                    tempList.push(contentItem);
                }
            });
            moduleRouteList = [...moduleRouteList, ...tempList];
        }
    });

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return [...moduleRouteList, ...getHomePage(t)];
}

export { getRouteList };

function getHomePage(t) {
    return [{
        title: `${t('pageTitle.home', 'Home')}`,
        eventKey: 'home',
        component: PhpContent,
        path: '',
        hasAccess: true,
    }];
}
