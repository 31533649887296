import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { errorActions } from '@states/actions';
import { GetFieldMeteorologyResponse, IMeteorologyData, analysisService } from '@services';
import { isEmpty, isNil } from 'lodash';
import { dateTimeUtils, getLocales } from 'shared/src/modules';
import { theme } from '@styles/theme';
import * as Highcharts from 'highcharts';
import { locales } from 'moment';

type IProps = {
    periodId: number;
    startDate: string;
    endDate: string;
    t: (moduleAndKey: any, defaultText: any) => JSX.Element;
    translater: (moduleAndKey: any, defaultText: any) => string;
    chartRef: React.RefObject<any>;
};

export default function useLoadCultivationPeriodMeteorologyModalData(props: IProps) {
    const { periodId, startDate, endDate, t, translater, chartRef } = props;
    const dispatch = useDispatch();
    const [meteorologyData, setMeteorologyData] = useState<GetFieldMeteorologyResponse>();
    const [highchartOptions, setHighchartOptions] = useState<Highcharts.Options>({
        chart: {
            type: 'spline',
            height: 600,
            margin: [10, 10, 120, 60],
            backgroundColor: 'transparent',
        },
        title: {
            text: '',
        },
        tooltip: {
            backgroundColor: theme.color.white,
            borderColor: 'none',
            borderRadius: 4,
            style: {
                fontWeight: 'bold',
                padding: '0px 10px',
                zIndex: 2,
            },
            useHTML: true,
            formatter() {
                return `
                <div class="highchart-tooltips">
                    <div class="highchart-tooltip-center">${this.x}</div>
                    <div class="highchart-tooltip-currency highchart-tooltip-center">${this.y} °C</div>
                </div>
                `;
            },
        },
        xAxis: {
            type: 'datetime',
            lineWidth: 0,
            tickWidth: 0,
            labels: {
                enabled: true,
            },
            gridLineColor: 'transparent',
            categories: [],
        },
        series: [],
        yAxis: {
            labels: {
                format: '{value} °C',
            },
            title: {
                text: '',
            }
        },
        credits: {
            enabled: false,
        },
    });
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {

                setIsLoading(true);

                const cultvationPeriodsResponse = await analysisService.getFieldMeteorologyHistory({ fieldId: periodId });

                if (isCancelled) {
                    throw new Error('useLoadCultivationPeriodMeteorologyModalData is unmounted');
                }

                setMeteorologyData(cultvationPeriodsResponse);
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            } finally {
                setIsLoading(false);
            }
        }

        if (!isNil(dispatch) && periodId && startDate && endDate && !isLoading) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [dispatch,  setIsLoading, location, periodId]);

    useEffect(() => {
        if (isNil(meteorologyData) || isEmpty(meteorologyData) || isNil(startDate) || isNil(endDate) || isNil(chartRef.current)) {
            return;
        }

        const momentStartDate = dateTimeUtils.getDateObject(startDate) as moment.Moment;
        const momentEndDate = dateTimeUtils.getDateObject(endDate) as moment.Moment;

        if (!momentStartDate.isValid() || !momentEndDate.isValid()) {
            return;
        }

        const dates = 
            Object.keys(meteorologyData)
            .filter(date => {
                const dateMoment: moment.Moment = dateTimeUtils.getDateObject(date) as moment.Moment;
                return dateMoment.valueOf() >= momentStartDate.valueOf() && dateMoment.valueOf() <= momentEndDate.valueOf();
            })
            .map((date) => dateTimeUtils.getDate(date));


        const soilTemperatureData: number[] = [];
        const soilTemperatureSeries: Highcharts.SeriesOptionsType = {
            type: 'spline',
            name: translater('cultivationPeriodMeteorologyModal.soilTemp10cm', 'Soil temperature'),
            color: theme.color.go,
            zIndex: 1,
        };

        const minTemperatureData: number[] = [];
        const minTemperatureSeries: Highcharts.SeriesOptionsType = {
            type: 'spline',
            name: translater('cultivationPeriodMeteorologyModal.minTemp', 'Min temperature'),
            color: theme.color.subscriptionLightBlue,
        };

        const maxTemperatureData: number[] = [];
        const maxTemperatureSeries: Highcharts.SeriesOptionsType = {
            type: 'spline',
            name: translater('cultivationPeriodMeteorologyModal.maxTemp', 'Max temperature'),
            color: theme.color.destructive,
        };

        const meanTemperatureData: number[] = [];
        const meanTemperatureSeries: Highcharts.SeriesOptionsType = {
            type: 'spline',
            name: translater('cultivationPeriodMeteorologyModal.meanTemp', 'Mean temperature'),
            color: theme.color.status_warning,
        };

        Object.entries(meteorologyData).forEach(([date, metData]: [string, IMeteorologyData]) => {
            const dateMoment = dateTimeUtils.getDateObject(date) as moment.Moment;

            if (!dateMoment.isValid()) {
                return;
            }

            if (dateMoment.valueOf() < momentStartDate.valueOf() || dateMoment.valueOf() > momentEndDate.valueOf()) {
                return;
            }

            soilTemperatureData.push(metData.soilTemperature10cm);
            minTemperatureData.push(metData.minimumTemperature);
            maxTemperatureData.push(metData.maximumTemperature);
            meanTemperatureData.push(metData.meanTemperature);
        });


        soilTemperatureSeries['data'] = soilTemperatureData;
        minTemperatureSeries['data'] = minTemperatureData;
        maxTemperatureSeries['data'] = maxTemperatureData;
        meanTemperatureSeries['data'] = meanTemperatureData;
        
        const chart = chartRef.current.chart;

        if (!chart) {
            return;
        }

        chart.xAxis[0].update({
            categories: dates,
        });

        if (!isEmpty(chart.series)) {
            chart.series[0].update({ data: soilTemperatureData });
            chart.series[1].update({ data: minTemperatureData });
            chart.series[2].update({ data: maxTemperatureData });
            chart.series[3].update({ data: meanTemperatureData });
        } else {
            chart.addSeries(soilTemperatureSeries);
            chart.addSeries({ ...minTemperatureSeries, visible: false });
            chart.addSeries({ ...maxTemperatureSeries, visible: false });
            chart.addSeries(meanTemperatureSeries);
        }

    }, [meteorologyData, startDate, endDate, translater]);

    return {
        highchartOptions,
        meteorologyData,
    };
}
