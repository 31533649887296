/* eslint-disable no-param-reassign */
/* eslint-disable id-blacklist */
/* eslint-disable no-magic-numbers */
// eslint-disable-next-line object-curly-newline
import { isEmpty, isBoolean, isObject, isUndefined, isNull, isArray, isString, sortBy } from 'lodash';
import { dateTimeUtils } from './dateTimeUtils';
import { numberUtils } from './numberUtils';

export const arrayUtils = {
    sortAsc,
    sortByParamAsc,
    sortByParamDesc,
    sortDateByParamAsc,
    sortDateByParamDesc,
    sortDateByParamKeyAsc,
    sortDateByParamKeyDesc,
    sortDateAsc,
    sortDateDesc,
    sortBySubArrayItemParamAsc,
    sortBySubArrayItemParamDesc,
    sortByParamNumberAsc,
    sortByParamNumberDesc,
    sortBySubArrayParamNumberAsc,
    sortBySubArrayParamNumberDesc,
    sortByReferenceArray,
    sortBooleanParamAsc,
    sortBooleanParamDesc,
    groupByKey,
    groupByKeys,
    groupBySubKey,
    getTextValueFromList,
    getValuesByKey,
    getMinValueByKey,
    groupingDataArray,
    getSumByKey,
    getWeightSumByKeyString,
    getArrayGroupCount,
    getArrayItemByKeyString,
    getSumByKeyString,
    convertStringToIntArray,
    convertStringToArray,
    createGroupByGroupName,
    getAllSumValuesByKey,
    getNumberOfArrayItemByKey,
    getMinByKey,
    getMaxByKey,
    isSimpleArraySame,
    setObjArrayValueById,
    getAllValuesFromObject,
    multiSortByParamAsc,
    multiSortByParamDesc,
    groupBy,
    getSelectListFormat,
    sortDateBySubArrayParamAsc,
    sortDateBySubArrayParamDesc,
    sumArray,
    getMinByKeyString,
    getMaxByKeyString,
    getValuesByKeyString,
    allAreEqual,
    unserializePhpArray,
    compareArrays,
    isEquals,
    filterDuplicatesFromComplexArray,
    filterDuplicatesFromArray,
};

function filterDuplicatesFromComplexArray(array, key) {
    const seen = new Set();

    return array.filter(item => {
        const keyValue = item[key];
        if (seen.has(keyValue)) {
            return false;
        }

        seen.add(keyValue);
        return true;
    });
}

function filterDuplicatesFromArray(array) {
    return Array.from(new Set(array));
}

function sortAsc(sortableArray) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => sortComparer(a, b));
    }
}

function sortComparer(a, b) {
    if (!a && !b) {
        return -1;
    }
    if (!a && b) {
        return -1;
    }
    if (a && !b) {
        return 1;
    }
    return String(a).localeCompare(b);
}

function sortBooleanComparer(a, b) {
    const aParam = a && isBoolean(a) ? Number(a) : 0;
    const bParam = b && isBoolean(b) ? Number(b) : 0;

    return aParam - bParam;
}

function getNumberOfArrayItemByKey(array, key) {
    return getArrayItemByKeyString(array, key) || 0;
}

//string sorter
function sortByParamAsc(sortableArray, param) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => {
            let aParam = getArrayItemByKeyString(a, param);
            let bParam = getArrayItemByKeyString(b, param);

            if (isObject(aParam)) {
                if (!isEmpty(aParam.otherLanguage)) {
                    aParam = aParam.otherLanguage[0].name;
                } else {
                    aParam = aParam.name;
                }
            }

            if (isObject(bParam)) {
                if (!isEmpty(bParam.otherLanguage)) {
                    bParam = bParam.otherLanguage[0].name;
                } else {
                    bParam = bParam.name;
                }
            }

            return sortComparer(aParam, bParam);
        });
        return sortableArray;
    }
}

function sortByParamDesc(sortableArray, param) {
    if (!isEmpty(sortableArray)) {
        sortByParamAsc(sortableArray, param);
        sortableArray.reverse();
        return sortableArray;
    }
}

//string sorter - array of objects sorted by an object value of specific  subarray item
function sortBySubArrayItemParamAsc(sortableArray, param, key, itemIndex) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => {
            let aParam = getArrayItemByKeyString(a[key][itemIndex], param);
            let bParam = getArrayItemByKeyString(b[key][itemIndex], param);
            if (isObject(aParam)) {
                if (!isEmpty(aParam.otherLanguage)) {
                    aParam = aParam.otherLanguage[0].name;
                } else {
                    aParam = aParam.name;
                }
            }

            if (isObject(bParam)) {
                if (!isEmpty(bParam.otherLanguage)) {
                    bParam = bParam.otherLanguage[0].name;
                } else {
                    bParam = bParam.name;
                }
            }
            return sortComparer(aParam, bParam);
        });
    }
}

function sortBySubArrayItemParamDesc(sortableArray, param, key, itemIndex) {
    if (!isEmpty(sortableArray)) {
        sortBySubArrayItemParamAsc(sortableArray, param, key, itemIndex);
        sortableArray.reverse();
    }
}

//boolean sorter
function sortBooleanParamAsc(sortableArray, param) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => sortBooleanComparer(getArrayItemByKeyString(a, param), getArrayItemByKeyString(b, param)));
    }
}

function sortBooleanParamDesc(sortableArray, param) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => sortBooleanComparer(getArrayItemByKeyString(b, param), getArrayItemByKeyString(a, param)));
    }
}

//date sorter
function sortDateByParamAsc(sortableArray, param) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => ((dateTimeUtils.isAfterDate(getArrayItemByKeyString(a, param), getArrayItemByKeyString(b, param))) ? 1 : -1));
    }
}

function sortDateByParamDesc(sortableArray, param) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => ((dateTimeUtils.isAfterDate(getArrayItemByKeyString(b, param), getArrayItemByKeyString(a, param))) ? 1 : -1));
    }
}

function sortDateAsc(sortableArray) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => ((dateTimeUtils.isAfterDate(a, b)) ? 1 : -1));
    }
}

function sortDateDesc(sortableArray) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => ((dateTimeUtils.isAfterDate(b, a)) ? 1 : -1));
    }
}

//date sorter (nagy méretű adattömb esetén használható)
function sortDateByParamKeyAsc(sortableArray, param) {
    if (!isEmpty(sortableArray)) {
        //sortableArray.sort((a, b) => ((dateTimeUtils.isAfterDate(a[param], b[param])) ? 1 : -1));
        return sortableArray
            .map(dataRow => new Date(dataRow[param]))
            .sort((a, b) => a - b);
    }
}

function sortDateByParamKeyDesc(sortableArray, param) {
    if (!isEmpty(sortableArray)) {
        //sortableArray.sort((a, b) => ((dateTimeUtils.isAfterDate(b[param], a[param])) ? 1 : -1));
        return sortableArray
            .map(dataRow => new Date(dataRow[param]))
            .sort((a, b) => b - a);
    }
}

//date sorter
function sortDateBySubArrayParamAsc(sortableArray, param, key, itemIndex) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => ((dateTimeUtils.isAfterDate(getArrayItemByKeyString(a[key][itemIndex], param), getArrayItemByKeyString(b[key][itemIndex], param))) ? 1 : -1));
    }
}

function sortDateBySubArrayParamDesc(sortableArray, param, key, itemIndex) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => ((dateTimeUtils.isAfterDate(getArrayItemByKeyString(b[key][itemIndex], param), getArrayItemByKeyString(a[key][itemIndex], param))) ? 1 : -1));
    }
}

//number sorter
function sortByParamNumberAsc(sortableArray, param) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => (getNumberOfArrayItemByKey(a, param) - getNumberOfArrayItemByKey(b, param)));
    }
    return sortableArray;
}

function sortByParamNumberDesc(sortableArray, param) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) => (getNumberOfArrayItemByKey(b, param) - getNumberOfArrayItemByKey(a, param)));
    }
    return sortableArray;
}

//string sorter - array of objects sorted by an object number value of specific subarray item
function sortBySubArrayParamNumberAsc(sortableArray, param, key, itemIndex) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) =>  (getNumberOfArrayItemByKey(a[key][itemIndex], param) - getNumberOfArrayItemByKey(b[key][itemIndex], param)));
    }
}

function sortBySubArrayParamNumberDesc(sortableArray, param, key, itemIndex) {
    if (!isEmpty(sortableArray)) {
        sortableArray.sort((a, b) =>  (getNumberOfArrayItemByKey(b[key][itemIndex], param) - getNumberOfArrayItemByKey(a[key][itemIndex], param)));
    }
}

// Sorts an array based on the order of elements in another array.
// Limitation: the array used as reference must not be longer than the array currently sorted.
// Eaxmple:
// const originalArray = ["A", "C", "D", "E", "F", "G", "H"];
// const newOrder = ["A", "B", "F", "G", "E", "H"];
// const sortedArray = ["A", "C", "D", "F", "G", "E", "H"];
function sortByReferenceArray({
    arrayTosort,
    referenceArr,
    compareFunction = (a, b) => a === b,
    includesFunction = (a, b) => a.includes(b)
}) {
    const sortedArray = [];
    let referenceArrIndex = 0;

    if (!isArray(arrayTosort)) {
        return arrayTosort;
    }

    arrayTosort.forEach(element => {
        const isEqual = compareFunction(element, referenceArr[referenceArrIndex]);
        const isReferenceIncludes = includesFunction(referenceArr, element);
        const isArrayToSortIncludes = includesFunction(arrayTosort, referenceArr[referenceArrIndex]);

        const increaseToNextValidReferenceArrIndex = refIndex => {
            let nextIndex = refIndex + 1;
            while (
                !includesFunction(arrayTosort, referenceArr[nextIndex]) &&
                nextIndex < referenceArr.length - 1
            ) {
                nextIndex++;
            }
            return nextIndex;
        };

        if (!isArrayToSortIncludes) {
            referenceArrIndex = increaseToNextValidReferenceArrIndex(referenceArrIndex);
        }
        if (isEqual && isReferenceIncludes) {
            sortedArray.push(element);
            referenceArrIndex++;
        }
        if (!isEqual && !isReferenceIncludes) {
            sortedArray.push(element);
        }
        if (!isEqual && isReferenceIncludes) {
            sortedArray.push(referenceArr[referenceArrIndex]);
            referenceArrIndex++;
        }
    });
    return sortedArray;
}

function groupByKey(array, key) {
    const groupedObject = array.reduce((resultGroup, currentValue) => {
        const currentGroup = currentValue[key] || [];
        (resultGroup[currentGroup] = resultGroup[currentGroup] || []).push(currentValue);
        return resultGroup;
    }, {});

    const groupObjectArray = [];
    Object.entries(groupedObject).forEach(([groupKey, groupVal]) => {
        groupObjectArray.push({ groupName: groupKey, groupArray: groupVal });
    });

    return groupObjectArray;
}

function groupBySubKey(array, key, key2) {
    const groupedObject = array.reduce((resultGroup, currentValue) => {
        const currentGroup = currentValue[key] && currentValue[key][key2] ? currentValue[key][key2] : [];
        (resultGroup[currentGroup] = resultGroup[currentGroup] || []).push(currentValue);
        return resultGroup;
    }, {});

    const groupObjectArray = [];
    Object.entries(groupedObject).forEach(([groupKey, groupVal]) => {
        groupObjectArray.push({ groupName: groupKey, groupArray: groupVal });
    });

    return groupObjectArray;
}

function groupByKeyString(array, keyString, unknownGroupName) {
    const groupedObject = array.reduce((resultGroup, currentValue) => {
        const arrayItem = getArrayItemByKeyString(currentValue, keyString);
        const currentGroup = arrayItem || [];
        (resultGroup[currentGroup] = resultGroup[currentGroup] || []).push(currentValue);

        return resultGroup;
    }, {});

    const groupObjectArray = [];
    let groupId = 0;
    Object.entries(groupedObject).forEach(([groupKey, groupVal]) => {
        const isUnknownGroup = isEmpty(groupKey);
        const groupName = isUnknownGroup ? unknownGroupName || groupKey : groupKey;
        groupObjectArray.push({ groupId, groupName, groupArray: groupVal, isUnknownGroup });
        groupId += 1;
    });

    return groupObjectArray;
}

function groupingDataArray(dataList, groupingBy, unknownGroupName) {
    if (!groupingBy || isEmpty(dataList)) {
        return dataList;
    }

    return groupByKeyString(dataList, groupingBy, unknownGroupName);
}

function getTextValueFromList(array, text, value) {
    const textValueArray = array.map(arrayItem => ({ value: arrayItem[value], text: arrayItem[text] }));
    return textValueArray;
}

function getValuesByKey(array, key, valueKey) {
    const valuesArray = [];
    array.forEach(companyItem => {
        // eslint-disable-next-line no-prototype-builtins
        if (companyItem.hasOwnProperty(key)) {
            valuesArray.push(companyItem[valueKey]);
        }
    });
    return valuesArray;
}

function getMinValueByKey(array, key) {
    return array.reduce((res, obj) => ((obj[key] < res[key]) ? obj : res));
}

function getSumByKey(array, key, key2) {
    if (key && key2) {
        return array.reduce((sum, arrayItem) => sum + Number(arrayItem[key] ? arrayItem[key][key2] || 0 : 0), 0);
    }
    const keyParts = key.split('.');
    if (keyParts.length === 2) {
        const part1 = keyParts[0].toString();
        const part2 = keyParts[1].toString();
        return array.reduce((sum, arrayItem) => sum + Number(arrayItem[part1] ? arrayItem[part1][part2] || 0 : 0), 0);
    }
    return array.reduce((sum, arrayItem) => sum + Number(arrayItem[key] || 0), 0);
}

function getSumByKeyString(array, key) {
    if (!isEmpty(array) && key) {
        return array.reduce((sum, arrayItem) => sum + Number(getArrayItemByKeyString(arrayItem, key) || 0), 0);
    }
    return 0;
}

function getWeightSumByKeyString(array, key) {
    if (array && key) {
        return array.reduce((sum, arrayItem) => sum + (Number(getArrayItemByKeyString(arrayItem, key) || 0) * Number(arrayItem.weightShiftNumber || 1)), 0);
    }
    return 0;
}

function getArrayGroupCount(array, groupKey) {
    const groupArray = groupingDataArray(array, groupKey);
    return groupArray.length;
}

function getArrayItemByKeyString(array, keyString) {
    if (!keyString) {
        return null;
    }

    if (!isUndefined(keyString.indexOf)) {
        if (keyString.indexOf('.') >= 0) {
            const keyParts = keyString.split('.');
            if (keyParts.length > 1) {
                let currentItem = array;
                keyParts.forEach(key => {
                    if (!isNull(currentItem)) {
                        currentItem = currentItem[key] || null;
                    } else {
                        currentItem = null;
                    }
                });
                return currentItem;
            }
        }
    }

    if (array && isObject(array)) {
        const arrayObject = Object.entries(array).find(([key,]) => key === keyString);
        if (arrayObject) {
            return arrayObject[1];
        }
    }

    return array[keyString] || null;
}

function convertStringToIntArray(string, ignoreZeroValue = false) {
    if (isArray(string)) {
        return string;
    }

    if (!string || isUndefined(string) || string === 'undefined') {
        return null;
    }

    let splittedArray = [];
    if (ignoreZeroValue) {
        splittedArray = string.split(',').filter(stringItem => +stringItem);
    } else {
        splittedArray = string.split(',');
    }

    if (isEmpty(splittedArray)) {
        return splittedArray;
    }

    const intArray = splittedArray.map(stringItem => {
        const itemInNumber = Number(stringItem);
        return Number.isNaN(itemInNumber) ? stringItem : itemInNumber;
    });

    return intArray;
}

function convertStringToArray(string) {
    if (!string || isUndefined(string) || string === 'undefined') {
        return null;
    }

    return string.split(',');
}

function createGroupByGroupName(array, groupName) {
    return [{ groupName, groupArray: array }];
}

function getAllSumValuesByKey(array, key, keyValue) {
    let resultSum = 0;

    array.forEach(headItem => {
        if (headItem[key]) {
            headItem[key].forEach(dataItem => {
                if (dataItem[keyValue]) {
                    resultSum += Number(dataItem[keyValue]);
                }
            });
        }
    });

    return resultSum;
}

function getMinByKey(array, key) {
    const keyDataList = array.map(obj => obj[key]);
    return Math.min(...keyDataList);
}

function getMaxByKey(array, key) {
    if (!isArray(array)) {
        return 0;
    }
    const keyDataList = array.map(obj => obj[key]);
    const maxValue = !isEmpty(keyDataList) ? Math.max(...keyDataList) : 0;
    return maxValue;
}

function getMinByKeyString(array, keyString) {
    const keyDataList = array.map(obj => arrayUtils.getArrayItemByKeyString(obj, keyString));
    return Math.min(...keyDataList);
}

function getMaxByKeyString(array, keyString) {
    const keyDataList = array.map(obj => arrayUtils.getArrayItemByKeyString(obj, keyString));
    const maxValue = !isEmpty(keyDataList) ? Math.max(...keyDataList) : 0;
    return maxValue;
}

function isSimpleArraySame(array1, array2) {
    return Array.isArray(array1)
        && Array.isArray(array2)
        && array1.length === array2.length
        && array1.every((value, index) => value === array2[index]);
}

function setObjArrayValueById(array, idName, id, valueName, value) {
    const modifiedArray = array.map(dataObj => {
        if (dataObj[idName] === id) {
            return {
                ...dataObj,
                [valueName]: value,
            };
        }
        return dataObj;
    });
    return modifiedArray;
}

function getAllValuesFromObject(array, key) {
    if (!isEmpty(array)) {
        const flattenedDataList = Object.values(array).flat()
        return flattenedDataList.map(dataRow => dataRow[key]);
    }
    return null;
}

function multiSortByParamAsc(sortableArray, param1, param2) {
    if (!isEmpty(sortableArray)) {
        sortBy(sortableArray, [param1, param2], ['asc', 'asc']);
    }
}

function multiSortByParamDesc(sortableArray, param1, param2) {
    if (!isEmpty(sortableArray)) {
        sortBy(sortableArray, [param1, param2], ['desc', 'desc']);
    }
}

function groupBy(array, keyString) {
    const groupedObject = array.reduce((resultArray, currentValue) => {
        const arrayItem = arrayUtils.getArrayItemByKeyString(currentValue, keyString);
        const currentItem = arrayItem || [];
        (resultArray[currentItem] = resultArray[currentItem] || []).push(currentValue);

        return resultArray;
    }, {});

    return groupedObject;
}

function groupByKeys(array, keys) {
    function getValueByKeys(obj, keys) {
        return keys?.map(key => obj[key]).join('_');
    }

    const groupedObject = array.reduce((resultObject, currentValue) => {
        const groupKey = getValueByKeys(currentValue, keys);
        (resultObject[groupKey] = resultObject[groupKey] || []).push(currentValue);

        return resultObject;
    }, {});

    return groupedObject;
}

function getSelectListFormat(array, textId, valueId) {
    const selectArray = array.map(listObj => (
        {
            value: listObj[textId],
            key: listObj[valueId],
        }
    ));
    return selectArray;
}

function sumArray(array) {
    if (isEmpty(array)) {
        return 0;
    }

    return array.reduce((summedValue, currentValue) => numberUtils.sum(summedValue, currentValue), 0);
}

function getValuesByKeyString(array, keyString) {
    const selectedValues = [];

    if (isEmpty(array) || isEmpty(keyString)) {
        return selectedValues;
    }

    array.forEach(arrayObject => {
        const arrayItem = arrayUtils.getArrayItemByKeyString(arrayObject, keyString);
        if (arrayItem) {
            selectedValues.push(arrayItem);
        }
    });

    return selectedValues;
}

function allAreEqual(array, testFunctionForElement = null) {
    return testFunctionForElement
        ? !array.some((value, index, array) => testFunctionForElement(value, index, array))
        : !array.some((value, index, array) => value !== array[0]);
}

// eslint-disable-next-line max-lines-per-function
function unserializePhpArray(string) {
    try {
        if (!isString(string)) {
            return false;
        }
    
        return expectType(string, initCache())[0];
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
        return false;
    }

    function initCache () {
        const store = [];
        // cache only first element, second is length to jump ahead for the parser
        const cache = function cache (value) {
            store.push(value[0]);
            return value;
        }
      
        cache.get = index => {
            if (index >= store.length) {
                throw RangeError(`Can't resolve reference ${index + 1}`);
            }
      
            return store[index];
        }
      
        return cache;
    }
      
    function expectType (str, cache) {
        const types = /^(?:N(?=;)|[bidsSaOCrR](?=:)|[^:]+(?=:))/g;
        const type = (types.exec(str) || [])[0];
      
        if (!type) {
            throw SyntaxError('Invalid input: ' + str);
        }
      
        switch (type) {
            case 'N':
                return cache([null, 2]);
            case 'b':
                return cache(expectBool(str));
            case 'i':
                return cache(expectInt(str));
            case 'd':
                return cache(expectFloat(str));
            case 's':
                return cache(expectString(str));
            case 'S':
                return cache(expectEscapedString(str));
            case 'a':
                return expectArray(str, cache);
            case 'O':
                return expectObject(str, cache);
            case 'C':
                return expectClass(str, cache);
            case 'r':
            case 'R':
                return expectReference(str, cache);
            default:
                throw SyntaxError(`Invalid or unsupported data type: ${type}`);
        }
    }
      
    function expectBool (str) {
        const reBool = /^b:([01]);/;
        const [match, boolMatch] = reBool.exec(str) || [];
      
        if (!boolMatch) {
            throw SyntaxError('Invalid bool value, expected 0 or 1');
        }
      
        return [boolMatch === '1', match.length];
    }
      
    function expectInt (str) {
        const reInt = /^i:([+-]?\d+);/;
        const [match, intMatch] = reInt.exec(str) || [];
      
        if (!intMatch) {
            throw SyntaxError('Expected an integer value');
        }
      
        return [parseInt(intMatch, 10), match.length];
    }
      
    function expectFloat (str) {
        const reFloat = /^d:(NAN|-?INF|(?:\d+\.\d*|\d*\.\d+|\d+)(?:[eE][+-]\d+)?);/;
        const [match, floatMatch] = reFloat.exec(str) || [];
      
        if (!floatMatch) {
            throw SyntaxError('Expected a float value');
        }
      
        let floatValue;
      
        switch (floatMatch) {
            case 'NAN':
                floatValue = Number.NaN;
                break
            case '-INF':
                floatValue = Number.NEGATIVE_INFINITY;
                break
            case 'INF':
                floatValue = Number.POSITIVE_INFINITY;
                break
            default:
                floatValue = parseFloat(floatMatch);
                break
        }
      
        return [floatValue, match.length];
    }
      
    function readBytes (str, len, escapedString = false) {
        let bytes = 0;
        let out = '';
        let c = 0;
        const strLen = str.length;
        let wasHighSurrogate = false;
        let escapedChars = 0;
      
        while (bytes < len && c < strLen) {
            let chr = str.charAt(c);
            const code = chr.charCodeAt(0);
            const isHighSurrogate = code >= 0xd800 && code <= 0xdbff;
            const isLowSurrogate = code >= 0xdc00 && code <= 0xdfff;
      
            if (escapedString && chr === '\\') {
                chr = String.fromCharCode(parseInt(str.substr(c + 1, 2), 16));
                escapedChars++;
      
                // each escaped sequence is 3 characters. Go 2 chars ahead.
                // third character will be jumped over a few lines later
                c += 2;
            }
      
            c++;
      
            bytes += isHighSurrogate || (isLowSurrogate && wasHighSurrogate)
            // if high surrogate, count 2 bytes, as expectation is to be followed by low surrogate
            // if low surrogate preceded by high surrogate, add 2 bytes
                ? 2
                : code > 0x7ff
                // otherwise low surrogate falls into this part
                    ? 3
                    : code > 0x7f
                        ? 2
                        : 1;
      
            // if high surrogate is not followed by low surrogate, add 1 more byte
            bytes += wasHighSurrogate && !isLowSurrogate ? 1 : 0;
      
            out += chr;
            wasHighSurrogate = isHighSurrogate;
        }
      
        return [out, bytes, escapedChars];
    }
      
    function expectString(str) {
        // PHP strings consist of one-byte characters.
        // JS uses 2 bytes with possible surrogate pairs.
        // Serialized length of 2 is still 1 JS string character
        const reStrLength = /^s:(\d+):"/g; // also match the opening " char
        const [match, byteLenMatch] = reStrLength.exec(str) || [];
      
        if (!match) {
            throw SyntaxError('Expected a string value');
        }
      
        const len = parseInt(byteLenMatch, 10);
      
        str = str.substr(match.length);
      
        const [strMatch, bytes] = readBytes(str, len);
      
        if (bytes !== len) {
            throw SyntaxError(`Expected string of ${len} bytes, but got ${bytes}`);
        }
      
        str = str.substr(strMatch.length);
      
        // strict parsing, match closing "; chars
        if (!str.startsWith('";')) {
            throw SyntaxError('Expected ";');
        }
      
        return [strMatch, match.length + strMatch.length + 2]; // skip last ";
    }
      
    function expectEscapedString (str) {
        const reStrLength = /^S:(\d+):"/g // also match the opening " char
        const [match, strLenMatch] = reStrLength.exec(str) || [];
      
        if (!match) {
            throw SyntaxError('Expected an escaped string value');
        }
      
        const len = parseInt(strLenMatch, 10);
      
        str = str.substr(match.length);
      
        const [strMatch, bytes, escapedChars] = readBytes(str, len, true);
      
        if (bytes !== len) {
            throw SyntaxError(`Expected escaped string of ${len} bytes, but got ${bytes}`);
        }
      
        str = str.substr(strMatch.length + escapedChars * 2);
      
        // strict parsing, match closing "; chars
        if (!str.startsWith('";')) {
            throw SyntaxError('Expected ";');
        }
      
        return [strMatch, match.length + strMatch.length + 2]; // skip last ";
    }
      
    function expectKeyOrIndex (str) {
        try {
            return expectString(str);
        // eslint-disable-next-line no-empty
        } catch (error) {}
      
        try {
            return expectEscapedString(str)
        // eslint-disable-next-line no-empty
        } catch (error) {}
      
        try {
            return expectInt(str)
        } catch (error) {
            throw SyntaxError('Expected key or index');
        }
    }
      
    function expectObject (str, cache) {
        // O:<class name length>:"class name":<prop count>:{<props and values>}
        // O:8:"stdClass":2:{s:3:"foo";s:3:"bar";s:3:"bar";s:3:"baz";}
        const reObjectLiteral = /^O:(\d+):"([^"]+)":(\d+):\{/;
        const [objectLiteralBeginMatch, /* classNameLengthMatch */, className, propCountMatch] = reObjectLiteral.exec(str) || [];
      
        if (!objectLiteralBeginMatch) {
            throw SyntaxError('Invalid input');
        }
      
        if (className !== 'stdClass') {
            throw SyntaxError(`Unsupported object type: ${className}`);
        }
      
        let totalOffset = objectLiteralBeginMatch.length;
      
        const propCount = parseInt(propCountMatch, 10);
        const obj = {};
        cache([obj]);
      
        str = str.substr(totalOffset);
      
        for (let i = 0; i < propCount; i++) {
            const prop = expectKeyOrIndex(str);
            str = str.substr(prop[1]);
            totalOffset += prop[1];
      
            const value = expectType(str, cache);
            str = str.substr(value[1]);
            totalOffset += value[1];
      
            obj[prop[0]] = value[0];
        }
      
        // strict parsing, expect } after object literal
        if (str.charAt(0) !== '}') {
            throw SyntaxError('Expected }');
        }
      
        return [obj, totalOffset + 1]; // skip final }
    }
      
    function expectClass() {
        // can't be well supported, because requires calling eval (or similar)
        // in order to call serialized constructor name
        // which is unsafe
        // or assume that constructor is defined in global scope
        // but this is too much limiting
        throw Error('Not yet implemented');
    }
      
    function expectReference (str, cache) {
        const reRef = /^[rR]:([1-9]\d*);/;
        const [match, refIndex] = reRef.exec(str) || [];
      
        if (!match) {
            throw SyntaxError('Expected reference value');
        }
      
        return [cache.get(parseInt(refIndex, 10) - 1), match.length];
    }
      
    function expectArray (str, cache) {
        const reArrayLength = /^a:(\d+):{/;
        const [arrayLiteralBeginMatch, arrayLengthMatch] = reArrayLength.exec(str) || [];
      
        if (!arrayLengthMatch) {
            throw SyntaxError('Expected array length annotation');
        }
      
        str = str.substr(arrayLiteralBeginMatch.length);
      
        const array = expectArrayItems(str, parseInt(arrayLengthMatch, 10), cache);
      
        // strict parsing, expect closing } brace after array literal
        if (str.charAt(array[1]) !== '}') {
            throw SyntaxError('Expected }');
        }
      
        return [array[0], arrayLiteralBeginMatch.length + array[1] + 1]; // jump over }
    }
      
    function expectArrayItems (str, expectedItems = 0, cache) {
        let key;
        let hasStringKeys = false;
        let item;
        let totalOffset = 0;
        let items = [];
        cache([items]);
      
        for (let i = 0; i < expectedItems; i++) {
            key = expectKeyOrIndex(str);
      
            // this is for backward compatibility with previous implementation
            if (!hasStringKeys) {
                hasStringKeys = (typeof key[0] === 'string');
            }
      
            str = str.substr(key[1]);
            totalOffset += key[1];
      
            // references are resolved immediately, so if duplicate key overwrites previous array index
            // the old value is anyway resolved
            // fixme: but next time the same reference should point to the new value
            item = expectType(str, cache);
            str = str.substr(item[1]);
            totalOffset += item[1];
      
            items[key[0]] = item[0];
        }
      
        // this is for backward compatibility with previous implementation
        if (hasStringKeys) {
            items = Object.assign({}, items);
        }
      
        return [items, totalOffset];
    }
}

//return is equal
function compareArrays(array1, array2) {
    return JSON.stringify(array1) === JSON.stringify(array2);
}

function isEquals(firstArray, secondArray) {
    return isArray(firstArray)
        && isArray(secondArray)
        && firstArray.length === secondArray.length
        && firstArray.every(value => secondArray.find(secondValue => value === secondValue));
}
