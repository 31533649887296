import { SETTINGS } from './settings';

export const UNITS = {
    DECIMAL_NUMBER: {
        DEFAULT: 2,
        WEIGHT: 2,
        REGISTRY_NUMBER_AREA: 4,
        AREA: 2,
        LAND_ADMINISTRATION_AREA_HA: 2,
        LAND_ADMINISTRATION_AREA_AK: 4,
        WEATHER_DATA: 1,
        CURRENCY: 2,
        SPEED: 2,
        TEMPERATURE: 1,
        PERCENTAGE: 2,
        TIME: 2,
        QUANTITY: 4,
        PRODUCT_QUANTITY: 4,
        DOSE: 4,
        WORKOPERATION_PERFORMANCE: 3,
        MACHINE_PERFORMANCE: 3,
        WORKING_HOURS_PERFORMANCE: 3,
    },
    DECIMAL_POINT: {
        DEFAULT: ',',
        AREA: ',',
        LAND_ADMINISTRATION_AREA_HA: ',',
        LAND_ADMINISTRATION_AREA_AK: ',',
        CURRENCY: ',',
        WEIGHT: ',',
        SPEED: ',',
        TEMPERATURE: ',',
        PERCENTAGE: ',',
        TIME: ',',
        QUANTITY: ',',
        PRODUCT_QUANTITY: ',',
        DOSE: ',',
        WORKOPERATION_PERFORMANCE: ',',
        MACHINE_PERFORMANCE: ',',
        WORKING_HOURS_PERFORMANCE: ',',
    },
    THOUSAND_SEPARATOR: {
        DEFAULT: ' ',
        AREA: ' ',
        LAND_ADMINISTRATION_AREA_HA: ' ',
        LAND_ADMINISTRATION_AREA_AK: ' ',
        CURRENCY: ' ',
        WEIGHT: ' ',
        SPEED: ' ',
        TEMPERATURE: ' ',
        PERCENTAGE: ' ',
        TIME: ' ',
        QUANTITY: ' ',
        PRODUCT_QUANTITY: ' ',
        DOSE: ' ',
        WORKOPERATION_PERFORMANCE: ' ',
        MACHINE_PERFORMANCE: ' ',
        WORKING_HOURS_PERFORMANCE: ' ',
    },
    TYPES: {
        AREA: 'AREA',
        LAND_ADMINISTRATION_AREA_HA: 'LAND_ADMINISTRATION_AREA_HA',
        LAND_ADMINISTRATION_AREA_AK: 'LAND_ADMINISTRATION_AREA_AK',
        CURRENCY: 'CURRENCY',
        WEIGHT: 'WEIGHT',
        SPEED: 'SPEED',
        TEMPERATURE: 'TEMPERATURE',
        PERCENTAGE: 'PERCENTAGE',
        TIME: 'TIME',
        QUANTITY: 'QUANTITY',
        PRODUCT_QUANTITY: 'PRODUCT_QUANTITY',
        DOSE: 'DOSE',
        WORKOPERATION_PERFORMANCE: 'WORKOPERATION_PERFORMANCE',
        MACHINE_PERFORMANCE: 'MACHINE_PERFORMANCE',
        WORKING_HOURS_PERFORMANCE: 'WORKING_HOURS_PERFORMANCE',
        DEFAULT: 'DEFAULT',
    },
    TYPE_SETTINGS: {
        AREA: 'AREA',
        PRIMARY_CURRENCY_ID: SETTINGS.PRIMARY_CURRENCY,
        SECONDARY_CURRENCY_ID: SETTINGS.SECONDARY_CURRENCY,
        LAND_ADMINISTRATION_AREA_HA: SETTINGS.LAND_ADMINISTRATION_AREA_HA_DECIMAL,
        LAND_ADMINISTRATION_AREA_AK: SETTINGS.LAND_ADMINISTRATION_AREA_AK_DECIMAL,
        PRODUCT_QUANTITY: 'PRODUCT_QUANTITY',
        DOSE: 'DOSE',
        WORKOPERATION_PERFORMANCE: 'WORKOPERATION_PERFORMANCE',
        MACHINE_PERFORMANCE: 'MACHINE_PERFORMANCE',
        WORKING_HOURS_PERFORMANCE: 'WORKING_HOURS_PERFORMANCE',
    },
    UNIT_TEXT: {
        AREA: 'ha',
    },
    DEFAULT_WEIGHT: 'kg',
    DEFAULT_AREA: 'ha',
    DEFAULT_SPEED: 'km/h',
    DEFAULT_TEMPERATURE: '°C',
    DEFAULT_PRECIPITATION: 'mm',
    DEFAULT_HUMIDITY: '%',
    DEFAULT_DEW_POINT: '°C',
    DEFAULT_PRESSURE: 'Pa',
    SHORT_NAMES: {
        LITER: 'l',
        MILILITER: 'ml',
        KILOGRAM: 'kg',
        GRAM: 'g',
        MILILITER_PER_HECTARE: 'ml/ha',
        LITER_PER_HECTARE: 'l/ha',
        GRAM_PER_HECTARE: 'g/ha',
        KILOGRAM_PER_HECTARE: 'kg/ha',
        PERCENTAGE: '%',
    },
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 2,
        ALL: 3,
    },
};
