import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { isEmpty } from 'lodash';
import { stringUtils } from 'shared/src/modules';
import DropdownToggleButton from '@baseComponents/select/dropdownParts/DropdownToggleButton';
import ListDropDown from '@baseComponents/select/selectParts/ListDropDown';

const useStyles = createUseStyles((theme: any) => ({
    basicFieldSelect: {
        position: 'relative',
        marginTop: 30,
        '&.show>.btn-primary.dropdown-toggle': {
            backgroundColor: theme.color.opacity.main,
            border: 'none !important',
            boxShadow: 'none !important',
            color: theme.color.main,
        },
        '&.btn-primary:active': {
            backgroundColor: [theme.color.opacity.main, '!important'],
            border: 'none !important',
            color: [theme.color.main, '!important'],
        },
        '& .dropdown-menu.show': {
            left: [30, '!important'],
            padding: 5,
        },
        '& .dropdown-item': {
            height: 40,
            display: 'flex',
            alignItems: 'center',
            padding: '0px 15px',
        },
        '& .dropdown-item:hover': {
            backgroundColor: [theme.color.mainFaded, '!important'],
            border: 'none',
            color: [theme.color.main, '!important'],
        },
    },
    dropdownButton: {
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        textTransform: 'uppercase',
        boxShadow: 'none !important',
        '& svg': {
            height: 20,
            width: 20,
            fill: theme.color.main,
        },
        '& #toggleText': {
            width: 'auto',
            color: theme.color.main,
            fontSize: 13,
            lineHeight: 1.54,
            fontWeight: 'bold',
            textAlign: 'center',
        },
        '&:focus': {
            backgroundColor: [theme.color.opacity.main, '!important'],
            border: 'none !important',
            color: [theme.color.main, '!important'],
        },
        '&:hover': {
            backgroundColor: [theme.color.mainFaded, '!important'],
            border: 'none',
            color: [theme.color.main, '!important'],
        },
    },
}));

export default function SupportSelect(props) {
    const { showList, setShowList, supportList, supportData, setSupportData } = props;
    const [uniqueSupportList, setUniqueSupportList] = useState();
    const [filteredSupportList, setFilteredSupportList] = useState();
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        function getUniqueSupportList() {
            const selectedSupportIds = !isEmpty(supportData) && supportData.map(supportObj => supportObj.support.id);
            let mergeSupportList = supportList;
            if (selectedSupportIds && !isEmpty(supportList)) {
                mergeSupportList = supportList.filter(supportObj => !selectedSupportIds.includes(supportObj.id));
            }
            return mergeSupportList;
        }

        if (!isEmpty(supportList)) {
            const uniqueList = getUniqueSupportList();
            setUniqueSupportList(uniqueList);
            setFilteredSupportList(uniqueList);
        }
    }, [supportData, supportList]);

    function addSupport(subsidy) {
        if (supportData) {
            setSupportData([...supportData, { support: subsidy, area: null }]);
        } else {
            setSupportData([{ support: subsidy, area: null }]);
        }
        closeSupports();
    }

    function closeSupports() {
        setShowList(false);
    }

    function toggleDropdown(toggleState) {
        setShowList(toggleState);

        //reset filtered data list
        if (!toggleState) {
            setFilteredSupportList(uniqueSupportList);
        }
    }

    function searchSupport(value) {
        if (!isEmpty(value) && !isEmpty(uniqueSupportList)) {
            setFilteredSupportList(uniqueSupportList.filter(dataObj => stringUtils.includesNoCase(dataObj.shortName, value)));
        } else {
            setFilteredSupportList(uniqueSupportList);
        }
    }

    return (
        <Dropdown className={classes.basicFieldSelect} onToggle={() => toggleDropdown(!showList)}>
            <DropdownToggleButton text={t('default.addMore', 'Add more')} iconId="icon-add" className={classes.dropdownButton} />
            <ListDropDown
                dataList={filteredSupportList}
                onClick={addSupport}
                valueId="shortName"
                search={value => searchSupport(value)}
                isOpened={showList}
            />
        </Dropdown>
    );
}
