import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import '@styles/components/pageloader/pageloader.css';

const useStyles = createUseStyles((theme: any) => ({
    pageLoaderPanel: {
        position: 'absolute',
        height: '100px',
        width: '300px',
        top: '30%',
        left: '45%',
        textAlign: 'center',
        background: 'white',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 8px 0 rgba(0, 0, 0, 0.19)',
        borderRadius: 4,
        zIndex: theme.zIndex.pageLoader,
    },
    pageLoaderSpinner: {
        position: 'absolute',
        top: '65%',
        left: '55%',
        marginTop: '-50px',
        marginLeft: '-43px',
        color: 'black',
    },
    pageLoaderSpinnerText: {
        fontSize: 12,
        color: 'black',
        textTransform: 'uppercase',
    },
    page: {
        zIndex: theme.zIndex.pageLoader,
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'transparent',
        height: '100vh',
        width: '100vw',
        display: 'none',
    },
}));

export default function PageLoader() {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div id="pageLoader" className={classes.page}>
            <div className={classes.pageLoaderPanel}>
                <div className={classes.pageLoaderSpinner}>
                    <div>
                        <img alt="" src="/styles/img/page_loading.gif" />
                    </div>
                    <span className={classes.pageLoaderSpinnerText}>{`${t('default.loading', 'Loading')}...`}</span>
                </div>
            </div>
        </div>
    );
}

export function setPageLoading(loading) {
    const pageLoader = document.getElementById('pageLoader');
    if (pageLoader) {
        if (loading === true) {
            pageLoader.style.display = 'block';
        } else {
            pageLoader.style.display = 'none';
        }
    }
}
