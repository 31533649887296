import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { dateTimeUtils } from 'shared/src/modules';
import { isEmpty, isFunction } from 'lodash';
import clsx from 'clsx';
import useTranslate from '@i18n/useTranslate';
import Input from '@baseComponents/inputs/Input';
import { DATE } from 'shared/src/constants';

const useStyles = createUseStyles((theme: any) => ({
    inputContainer: {
        display: 'flex',
        marginTop: 10,
        '& input': {
            padding: 0,
        },
    },
    inputWidth: {
        width: 120,
    },
    separator: {
        width: 5,
        borderTop: '2px solid',
        borderTopColor: theme.color.gray,
        margin: [19, 5, 0, 5],
    },
    inputBorder: {
        borderRadius: 4,
        boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15)',
        border: `1px solid ${theme.color.main}`,
    },
}));

export default function FormRangeCalendarInputs(props) {
    const { date, setDate, endDate, startDate, inputStartDate, setInputStartDate, inputEndDate, setInputEndDate, setIsDisabled, isFirstPick, setFirstPick, endDateDaysFromStartDate } = props;
    const { t } = useTranslate();
    const [isValidStartDate, setValidStartDate] = useState(true);
    const [isValidEndDate, setValidEndDate] = useState(true);
    const [dateMask] = useState(dateTimeUtils.getDateMask());
    const classes = useStyles();

    useEffect(() => {
        if (setIsDisabled) {
            setIsDisabled(!(isValidEndDate && isValidStartDate));
        }
    }, [isValidStartDate, isValidEndDate, setIsDisabled]);

    function handleChangeStartInput(event) {
        const startDateValue = event.target.value;
        setInputStartDate(startDateValue);
        const momentDate = dateTimeUtils.getMomentObjectFromDateString(startDateValue);
        if (dateTimeUtils.isValidDate(startDateValue) && !isEmpty(endDate)
            && (dateTimeUtils.isAfterDate(endDate, momentDate) || dateTimeUtils.isEqual(endDate, momentDate))
            && dateTimeUtils.getDateYear(momentDate) > DATE.MIN_VALUE
        ) {
            if (!isValidEndDate && dateTimeUtils.isValidDate(inputEndDate)) {
                setValidEndDate(true);
                setDate({ ...date, end: dateTimeUtils.getDateObject(inputEndDate) });
            }
            setDate({ ...date, start: dateTimeUtils.getDateObject(momentDate) });
            setValidStartDate(true);
        } else if (startDateValue !== dateMask.replace(/\9/g, '_') && !isEmpty(startDateValue)) {
            setValidStartDate(false);
        }
    }

    function handleChangeEndInput(event) {
        const endDateValue = event.target.value;
        setInputEndDate(event.target.value);
        const momentDate = dateTimeUtils.getMomentObjectFromDateString(endDateValue);
        if (dateTimeUtils.isValidDate(endDateValue) && !isEmpty(startDate)
            && (dateTimeUtils.isAfterDate(momentDate, startDate) || dateTimeUtils.isEqual(startDate, momentDate))
            && dateTimeUtils.getDateYear(momentDate) > DATE.MIN_VALUE
        ) {
            if (!isValidStartDate && dateTimeUtils.isValidDate(inputStartDate)) {
                setValidStartDate(true);
                setDate({ ...date, start: dateTimeUtils.getDateObject(inputStartDate) });
            }
            setDate({ ...date, end: dateTimeUtils.getDateObject(momentDate) });
            setValidEndDate(true);
        } else if (endDateValue !== dateMask.replace(/\9/g, '_') && !isEmpty(endDateValue)) {
            setValidEndDate(false);
        }
    }

    function openDate(isStartDatePick) {
        if (isFunction(setFirstPick) && !endDateDaysFromStartDate) {
            setFirstPick(isStartDatePick);
        }
    }

    return (
        <div className={classes.inputContainer}>
            <Input
                className={clsx(classes.inputWidth, isFirstPick && classes.inputBorder)}
                value={inputStartDate}
                type="date"
                mask={dateMask}
                onChange={handleChangeStartInput}
                errorMessage={t('calendar.validDate', 'This date is not valid')}
                invalid={!isValidStartDate}
                onClick={() => openDate(true)}
            />
            <div className={classes.separator} />
            <Input
                className={clsx(classes.inputWidth, !isFirstPick && classes.inputBorder)}
                value={inputEndDate}
                type="date"
                mask={dateMask}
                onChange={handleChangeEndInput}
                errorMessage={t('calendar.validDate', 'This date is not valid')}
                invalid={!isValidEndDate}
                onClick={() => openDate(false)}
                disabled={!!endDateDaysFromStartDate}
            />
        </div>
    );
}
