import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import { isArray } from 'lodash';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { useSelector } from 'react-redux';
import GroupOperationButton from '@baseComponents/basePageNew/body/bodyFunctionLine/bodyFunctionLineLeftSide/groupOperationButton/GroupOperationButton';
import useSoilAnalysisTable from './useSoilAnalysisTable';

const useStyles = createUseStyles((theme: any) => ({
    table: {
        position: 'relative',
        height: '100%',
    },
    tableWrapper: {
        height: '100%',
        '& .table-row': {
            paddingRight: 0,
        },
        '& .List': {
            ...theme.verticalScrollbar,
        },
        '& .tableHeaderRow': {
            marginRight: 0,
        },
    },
    tableScrollerFooter: {
        paddingTop: 7,
        background: theme.color.transparent,
    },
}));

export default function SoilAnalysisTable(props) {
    const { tableData, tableColumns, tableId } = props;
    const selectedRowIds = useSelector((state: any) => state.simpleTable.table?.[tableId]?.selectedItemIds);
    const classes = useStyles();

    const [tableGroup] = useState(
        {
            value: null,
            text: 'No breakdown',
        },
    );

    const { columns } = useSoilAnalysisTable({ tableColumns, tableId });

    return (
        <>
            <div className={classes.table}>
                <LoadSpinner isAutoDetect value={columns} />
                <GroupOperationButton tableKey={tableId} />
                <TableGroup
                    useRowSelection
                    selectedRowIds={selectedRowIds}
                    tableId={tableId}
                    tableData={tableData}
                    tableColumns={columns}
                    isBasePageTable={false}
                    isCollapsible={false}
                    tableGroup={tableGroup}
                    className={classes.tableWrapper} 
                />
            </div>
            <div className={classes.tableScrollerFooter}>
                <TableScrollerFooter tableKey={tableId} isLoaded={isArray(tableData)} useRowSelection />
            </div>
        </>
    );
}
