import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import { errorActions, simpleTableActions } from '@states/actions';
import useTranslate from '@i18n/useTranslate';
import CultivationPeriodsModal from '@customComponents/modals/cultivationPeriodsModal/CultivationPeriodsModal';
import { NUMBERS } from 'shared/src/constants';
import { theme } from '@styles/theme';
import { soilAnalysisService } from '@services';
import { useFormContext } from 'react-hook-form';
import { clone, isEmpty } from 'lodash';

const cellFunctions = {
    updateRow: (rowId, columnId, updateValue, tableKey, dispatch, rowData, setValue) => {
        if (!tableKey) {
            return;
        }
        const tableData = dispatch(simpleTableActions.getTableDataList(tableKey));
        const clonedTableData = clone(tableData);
        const updatedRow = tableData?.find(tableRow => tableRow.rowId === rowId);
        const selectedRowIds = dispatch(simpleTableActions.getSelectedRowIds(tableKey)) ?? [];
        const otherRows = clonedTableData?.filter(tableRow => tableRow.id !== rowId && selectedRowIds?.includes(tableRow.rowId));

        const valueBeforeUpdate = rowData[columnId];

        if (updatedRow && columnId) {
            rowData[columnId] = updateValue;
            updatedRow[columnId] = updateValue;
        }

        if (updateValue === valueBeforeUpdate || isEmpty(selectedRowIds) || !selectedRowIds.includes(rowId)) {
            setValue('soilAnalysisData', clonedTableData);
            return;
        }

        otherRows.forEach(row => {
            row[columnId] = updateValue;
        });

        dispatch(simpleTableActions.setTableData(tableKey, clonedTableData));
        setValue('soilAnalysisData', clonedTableData);
    },
    deleteRow: (rowId, tableKey, rowIdName, dispatch, setValue) => {
        setValue('isLoading', true);
        const tableData = dispatch(simpleTableActions.getTableDataList(tableKey));
        const filteredTableData = tableData?.filter(rowData => rowData.rowId !== rowId);
        setValue('soilAnalysisData', filteredTableData);
        dispatch(simpleTableActions.removeRowFromTableDataById(tableKey, rowId, rowIdName));
        if (isEmpty(filteredTableData)) {
            setValue('isLoading', false);
        }
    },
};

export default function useAnalysisDataTable(props) {
    const { tableId, soilAnalysisId } = props;
    const cultivationPeriodList = useSelector((state: any) => state.basePage.table.tableData);
    const simpleTableData = useSelector((state: any) => state.simpleTable.table?.[tableId]?.tableData);
    const [soilAnalysisTableData, setSoilAnalysisTableData] = useState([]);
    const [parameters, setParameters] = useState([]);
    const dispatch = useDispatch();
    const { t, translater } = useTranslate();
    const { setValue } = useFormContext();

    const defaultColumns = useMemo(() => {
        const soilAnalysisColumns = [
            {
                type: columnName.modalAddButton,
                columnId: 'cultivationPeriod',
                columnName: t('soilAnalysisImport.cultivationPeriod', 'Cultivation period'),
                isLeftFixed: true,
                width: 200,
                minWidth: 200,
                maxWidth: 200,
                componentProps: {
                    line1Id: 'name',
                    line2Id: 'companyName',
                    modalComponent: CultivationPeriodsModal,
                    defaultText: translater('default.empty', 'Empty'),
                    updateValue: (value, _cellData, rowData) => {
                        cellFunctions.updateRow(rowData.rowId, 'cultivationPeriod', value, tableId, dispatch, rowData, setValue);
                    },
                    modalComponentProps: {
                        isMultiselect: true,
                        rowFormatFnc: period => ({
                            id: period.id,
                            name: period.name,
                            companyName: period.companyName,
                        }),
                    },
                    isRequired: true,
                    getDataList: () => cultivationPeriodList?.map(cultivationPeriodData => ({ key: cultivationPeriodData.id, value: cultivationPeriodData.name })),
                },
            },
            {
                type: columnName.quantityInput,
                columnId: 'depth',
                columnName: t('soilAnalysisExcelImport.depth', 'Depth'),
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                componentProps: {
                    quantityFormat: {
                        decimals: NUMBERS.DEFAULT.DECIMAL_NUMBERS,
                        decimalSeparator: NUMBERS.DEFAULT.DECIMAL_SEPARATOR,
                        thousandsSeparator: NUMBERS.DEFAULT.THOUSAND_SEPARATOR,
                        unit: t('default.centimeter', 'cm'),
                    },
                    onBlur: (value, rowData, columnId) => {
                        cellFunctions.updateRow(rowData.rowId, columnId, value, tableId, dispatch, rowData, setValue);
                    },
                    isRequired: true,
                },
            },
            {
                type: columnName.simpleInput,
                columnId: 'code',
                columnName: t('soilAnalysisImport.code', 'Code'),
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                componentProps: {
                    onBlur: (value, rowData, columnId) => {
                        cellFunctions.updateRow(rowData.rowId, columnId, value, tableId, dispatch, rowData, setValue);
                    },
                    isRequired: true,
                },
            },
            {
                type: columnName.iconButton,
                isRightFixed: true,
                componentProps: {
                    type: 'destructive',
                    getCellTitle: () => t('default.delete', 'Delete'),
                    onClick: rowData => {
                        cellFunctions.deleteRow(rowData.rowId, tableId, 'rowId', dispatch, setValue);
                    },
                    getIcon: () => 'icon-bin',
                    getIconColor: () => theme.color.destructive,
                },
                width: 65,
                minWidth: 65,
                maxWidth: 65,
            },
        ];

        parameters?.forEach(column => {
            soilAnalysisColumns.push({
                type: columnName.quantityInput,
                columnId: column.sqlName,
                columnName: column.name,
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                componentProps: {
                    quantityFormat: {
                        decimals: NUMBERS.DEFAULT.DECIMAL_NUMBERS,
                        decimalSeparator: NUMBERS.DEFAULT.DECIMAL_SEPARATOR,
                        thousandsSeparator: NUMBERS.DEFAULT.THOUSAND_SEPARATOR,
                    },
                    onBlur: (value, rowData, columnId) => {
                        cellFunctions.updateRow(rowData.rowId, columnId, value, tableId, dispatch, rowData, setValue);
                    },
                },
            });
        });

        return soilAnalysisColumns;
    }, [cultivationPeriodList, dispatch, parameters, setValue, t, tableId]);

    useEffect(() => {
        setSoilAnalysisTableData(simpleTableData);
    }, [simpleTableData, setSoilAnalysisTableData]);

    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const soilAnalysisParameters = await soilAnalysisService.getSoilProductivityParameters();
                const soilAnalysisData = await soilAnalysisService.getSoilAnalysisByIds({ soilAnalysisId });
                if (!isCancelled) {
                    const soilAnalysisList = soilAnalysisData?.map((analysisData, index) => ({
                        ...analysisData,
                        rowId: index + 1,
                        id: index + 1,
                    }));
                    setParameters(soilAnalysisParameters);
                    setSoilAnalysisTableData(soilAnalysisList);
                    setValue('soilAnalysisData', soilAnalysisList);
                    setValue('rowId', soilAnalysisData?.length || 0);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'LoadSoilAnalysisEditData'));
            }
        }

        if (dispatch && soilAnalysisId) {
            loadData();
            return () => {
                isCancelled = true;
            };
        }
    }, [dispatch, setValue, soilAnalysisId]);

    return {
        soilAnalysisTableData,
        columns,
    };
}
