/* eslint-disable id-blacklist */
/* eslint-disable no-undef */
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { isAdministrator } from 'shared/src/user/userSettings';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { userActions } from '@states/actions/userActions';
import { errorActions } from '@states/actions/errorActions';
import useTranslate from '@i18n/useTranslate';
import { sessionService, phpService } from '@services';

import Button from '@baseComponents/buttons/Button';
import TitleBadge from '@baseComponents/chips/TitleBadge';
import Avatar from '@baseComponents/avatars/Avatar';

const useStyles = createUseStyles((theme: any) => ({
    userMenuHeader: {
        padding: '20px 30px',
        borderBottom: '1px solid',
        boxShadow: `inset 2px 0 0 0 ${theme.color.lilian}`,
        borderBottomColor: theme.color.pearl,
        backgroundColor: theme.color.white,
        display: 'block',
    },
    headerButtons: {
        marginTop: 25,
        display: 'flex',
        justifyContent: 'space-between',
    },
    headerTop: {
        display: 'flow-root',
    },
    profilePicture: {
        marginTop: 20,
        marginRight: 15,
        float: 'right',
        background: 'transparent',
        '& img': {
            width: 70,
            height: 70,
            borderRadius: '50%',
        },
    },
    userInformations: {
        display: 'inline-block',
    },
    userName: {
        display: 'block',
        width: 200,
        wordWrap: 'break-word',
        fontSize: 18,
        fontWeight: 500,
        lineHeight: 1.67,
    },
    exitButton: {
        width: 105,
        '& .content': {
            paddingLeft: 5,
            paddingRight: 5,
            display: 'flex',
            justifyContent: 'center',
        },
        '& .title': {
            display: 'block',
            ...theme.overFlowText,
        },
        '& svg': {
            minWidth: 15,
        },
    },
    editButton: {
        width: 175,
        '& .content': {
            paddingLeft: 5,
            paddingRight: 5,
        },
        '& .title': {
            display: 'block',
            ...theme.overFlowText,
        },
    },
}));

export default function UserMenuHeader(props) {
    const { closeUserMenu, setShowEditProfile } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const agentName = sessionService.getAgentName();
    const user = useSelector((state: any) => state.user.user);
    const userId = useSelector((state: any) => state.user.user.id);
    const profilePictureUrl = useSelector((state: any) => state.user.user.profilePictureUrl);
    const classes = useStyles();

    function openEditProfile() {
        setShowEditProfile(true);
        closeUserMenu();
    }

    //Log out
    async function logout() {
        try {
            await phpService.logout();
            dispatch(userActions.logout());
            history.push(`/${agentName}`);
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }

    //php function
    function changeProfilePics() {
        if ($) {
            if (!$('#profilkep-csere-modal').length) {
                $.ajax({
                    type: 'POST',
                    url: '/modules/altalanos/view/profilkep_csere_modal.php',
                    data: {
                        tmp_pid: 0,
                        module: 'user',
                        picture_id: userId,
                    },
                    async: false,
                    success: data => {
                        $(`<div id="profilkep-csere-modal" class="modal fade">${data}</div>`).modal({
                            backdrop: 'static',
                        }).on('hidden', () => {
                            // eslint-disable-next-line camelcase
                            if (saved_profilepic) {
                                const profilePicture = `/uploads/profilepictures/${agentName}/user/${userId}_medium_profilepic.jpg?${new Date().getTime()}`;
                                dispatch(userActions.setUserProfilePictureUrl(profilePicture));
                            // eslint-disable-next-line camelcase
                            } else if (deleted_profilepic) {
                                dispatch(userActions.setUserProfilePictureUrl('/styles/img/ures-profil.png'));
                            }
                            $('#profilkep-csere-modal').remove();
                        });
                    },
                });
            }
        }
    }

    return (
        <div className={classes.userMenuHeader}>
            <div className={classes.headerTop}>
                <div className={classes.userInformations}>
                    <span className={classes.userName}>{user.name}</span>
                    {isAdministrator(user) && (
                        <TitleBadge>
                            {t('userMenu.systemAdministrator', 'System administrator')}
                        </TitleBadge>
                    )}
                    {/*TODO:split to an own component when have solition
                        isAgrovirResponsible(user) && (
                        <TitleBadge>
                            {t('userMenu.agrovirResponsible', 'Agrovir responsible')}
                        </TitleBadge>
                    )*/}
                </div>
                <Avatar name={user.name} className={classes.profilePicture} imageUrl={profilePictureUrl} onClick={changeProfilePics} image />
            </div>
            <div className={classes.headerButtons}>
                <Button className={classes.editButton} size="small" type="secondary" onClick={openEditProfile} title={t('userMenu.editProfile', 'Edit profile')}>
                    {t('userMenu.editProfile', 'Edit profile')}
                </Button>
                <Button className={classes.exitButton} size="small" type="destructive" iconId="icon-arrow_forward" iconPosition="before" onClick={logout} title={t('userMenu.logout', 'Logout')}>
                    {t('userMenu.logout', 'Logout')}
                </Button>
            </div>
        </div>
    );
}
