const styleList = [
    '/plugins/bootstrap/css/bootstrap.css',
    '/plugins/font-awesome-4.6.3/css/font-awesome.min.css',
    '/plugins/weather-icons-master/css/weather-icons.min.css',
    '/plugins/weather-icons-master/css/weather-icons-wind.min.css',
    '/styles/ui/jquery-ui-1.10.2.min.css',
    '/styles/ui/jquery-ui-timepicker-addon.css',
    '/plugins/slickgrid/slick.grid.css',
    '/plugins/slickgrid/controls/slick.pager.css',
    '/plugins/slickgrid/controls/slick.summaryfooter.css',
    '/plugins/slickgrid/controls/slick.columnpicker.css',
    '/plugins/multiselect/jquery.multiselect.css',
    '/plugins/multiselect/jquery.multiselect.filter.css',
    '/plugins/fullcalendar/fullcalendar/fullcalendar.css',
    '/plugins/jquery.qtip/jquery.qtip.css',
    '/plugins/ajaxloader/ajaxloader.css',
    '/plugins/bootstrap-datepicker/css/bootstrap-datepicker.min.css',
    '/plugins/bootstrap-daterangepicker/css/daterangepicker.css',
    '/plugins/datatable/dataTables.bootstrap.min.css',
    '/plugins/pnotify/jquery.pnotify.default.css',
    '/plugins/jquery-tokeninput/css/token-input-bootstrappy.css',
    '/plugins/bootstrap-switch-master/bootstrap-switch.min.css',
    '/plugins/fullcalendar_3.2.0/fullcalendar.css',
    '/plugins/cropperjs/cropper.css',
    '/plugins/bootstrap/css/bootstrap-datetimepicker.css',
    '/styles/base.css',
    '/styles/style.css',
];

const generateKey = () => `?${new Date().getTime()}`;

export async function loadStyles() {
    const key = generateKey();
    styleList.forEach(href => {
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = `${href}${key}`;
        document.getElementsByTagName('head')[0].appendChild(link);
    });
}
