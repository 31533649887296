import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles({
    modalContainer: {
        position: 'relative',
        display: 'flex',
        height: 'calc(100vh - 80px)',
        width: 'calc(100vw - 120px)',
        minWidth: 1400,
    },
});

export default function ModalTemplate(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.modalContainer, className)}>
            {children}
        </div>
    );
}
