import { ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';

type Classes = 'icon' | 'button' | 'sectionTitle' | 'itemTitle' | 'fillRed' | 'fillOpacity';

const useLayerPanelStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    icon: {
        width: 24,
        height: 24,
        fill: theme.color.raven,
        cursor: 'pointer',
    },
    button: {
        width: '100%',
    },
    sectionTitle: {
        fontSize: 11,
        fontWeight: 500,
        textTransform: 'uppercase',
        lineHeight: '15px',
        color: theme.color.jet,
    },
    itemTitle: {
        fontSize: 16,
        lineHeight: '20px',
        color: theme.color.raven,
    },
    fillRed: {
        fill: theme.color.destructive,
    },
    fillOpacity: {
        fillOpacity: 0.5,
    }
}));

export default useLayerPanelStyles;
