import { Children } from 'react';
import ReactDOM from 'react-dom';
import { LayerFolderEdit } from './LayerFolderEdit';

type Tprops = {
    children: JSX.Element,
}

export default function LayerFolderModal({ children }: Tprops) {
    return ReactDOM.createPortal(
        <>
            <div className="modal-backdrop show" />
            {children}
        </>,
        document.getElementById('portal-root') as Element,
    );
}
