import { createUseStyles } from 'react-jss';
import { isArray, isEmpty, isFunction, isObject } from 'lodash';

import Button from '@baseComponents/buttons/Button';

const useStyles = createUseStyles((theme: any) => ({
    groupName: {
        paddingLeft: 10,
        paddingRight: 10,
        height: 25,
        color: theme.color.jet,
        fontSize: 11,
        fontWeight: 400,
        alignItems: 'center',
    },
    dataRow: {
        background: theme.color.white,
        paddingLeft: 10,
        paddingRight: 10,
        color: theme.color.jet,
        fontSize: 16,
        //height: 50,
        //lineHeight: '50px',
        fontWeight: 500,
        '&:hover': {
            backgroundColor: theme.color.opacity.main,
        },
        '& button': {
            margin: 'auto',
            marginLeft: 0,
            marginRight: 0,
        },
        display: 'flex',
        flexDirection: 'row',
        height: 48,
    },
    rowValueWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: 36,
        margin: 'auto',
        marginLeft: 0,
        marginRight: 10,
        overflow: 'hidden',
    },
    rowTitle: {
        width: '100%',
        //height: props => (props.hasSecondLine ? '60%' : '100%'),
        height: 'auto',
        margin: 'auto',
        marginLeft: 0,
        paddingLeft: 10,
        fontFamily: 'Rubik',
        ...theme.overFlowText,
    },
    secondRow: {
        width: '100%',
        height: '55%',
        paddingLeft: 10,
        fontSize: 12,
        fontFamily: 'Rubik',
        fontWeight: 'normal',
        color: theme.color.stone,
        ...theme.overFlowText,
    },
}));

export default function SelectListContent(props) {
    const { dataList, onSelect, onClose } = props;
    const classes = useStyles(/*{ hasSecondLine: !isEmpty(dataList?.find(dataRow => !isEmpty(dataRow.value2))) }*/);

    function onSelectRow(dataRow) {
        if (isFunction(onSelect)) {
            onSelect(dataRow.key, dataRow);
        }
        if (isFunction(onClose)) {
            onClose();
        }
    }

    const SelectList = selectListProps => {
        const { dataArray } = selectListProps;

        if (!isArray(dataArray)) {
            return null;
        }

        return dataArray?.map(dataRow => (
            <div key={dataRow.key} className={classes.dataRow}>
                <Button size="small" type="primary" iconId="icon-add" onClick={() => onSelectRow(dataRow)} />
                <div className={classes.rowValueWrapper}>
                    <div className={classes.rowTitle} title={dataRow.value}>
                        {dataRow.value}
                    </div>
                    {!isEmpty(dataRow.value2) && (
                        <div className={classes.secondRow}>
                            {dataRow.value2}
                        </div>
                    )}
                </div>
            </div>
        ));
    };

    if (isEmpty(dataList)) {
        return null;
    }

    //is object
    if (isObject(dataList) && !isArray(dataList)) {
        return Object.entries(dataList)?.map(([groupName, dataArray]) => (
            <>
                <div className={classes.groupName} key={groupName}>
                    {groupName}
                </div>
                <SelectList dataArray={dataArray} />
            </>
        ));
    }

    //is array
    return (
        <SelectList dataArray={dataList} />
    );
}
