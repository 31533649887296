import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    modalHeader: {
        height: 30,
        marginLeft: 30,
        marginTop: 20,
        marginBottom: 20,
        fontSize: 22,
        fontWeight: 'bold',
        color: theme.color.jet,
        background: theme.color.white,
    },
}));

export default function ModalHeader(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.modalHeader, className)}>
            {children}
        </div>
    );
}
