import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import { stringUtils } from 'shared/src/modules/stringUtils';
import { languageUtils } from 'shared/src/modules/languageUtils';
import { theme as themes } from '@styles/theme';
import SearchBar from '../SearchBar';
import TextButton from '../../buttons/TextButton';

const useStyles = createUseStyles((theme: any) => ({
    dropdownBox: {
        position: (props: any) => (props.fixedSelectbox ? 'fixed' : 'absolute'),
        width: (props: any) => (props.fixedSelectbox ? props.boxWidth : '100%'),
        zIndex: 100,
        display: (props: any) => (props.showItems ? 'block' : 'none'),
        background: theme.color.white,
        borderRadius: '4px',
        border: 'solid 1px rgba(227, 230, 232, 0.5)',
        boxShadow: '0 5px 15px 1px rgba(121, 130, 134, 0.1), 0 10px 10px 1px rgba(69, 79, 84, 0.15), 0 5px 5px -2px rgba(69, 79, 84, 0.2)',
        height: (props: any) => {
            if (props.dataListLength) {
                return props.dataListLength > 400 ? 400 : props.dataListLength;
            }
            return theme.subMenu.buttonHeight;
        },
    },
    dataItem: {
        height: theme.subMenu.buttonHeight,
        fontSize: '15px',
        fontWeight: 'normal',
        padding: '10px 15px',
        color: theme.color.jet,
        background: theme.color.white,
        textAlign: 'left',
        outline: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            cursor: 'pointer',
            background: theme.color.opacity.main,
            color: theme.color.main,
        },
        ...theme.disableSelectionColor,
    },
    selectedItem: {
        height: theme.subMenu.buttonHeight,
        padding: '10px 15px',
        fontSize: '15px',
        fontWeight: 500,
        color: theme.color.main,
        background: theme.color.opacity.main,
        textAlign: 'left',
        outline: 'none',
        '&:hover': {
            cursor: 'default',
        },
        ...theme.overFlowText,
        ...theme.disableSelectionColor,
    },
    searchBar: {
        padding: 5,
    },
    itemList: {
        height: 'auto',
        maxHeight: 400,
        overflow: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 6,
            position: 'right',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            borderRadius: 7,
            background: 'rgba(0, 0, 0, 0.4)',
        },
    },
}));

export default function SelectBox(props) {
    const { listData, selected, setSelected, showItems, setShowItems, type, width, fixedSelectbox, onChange, addOnClick } = props;
    const { t } = useTranslation();
    const boxWidth = fixedSelectbox ? Number(width) : '100%';
    const [isMouseInBox, setIsMouseInBox] = useState(false);
    const [searchValue, setSearchValue] = useState();
    const [filteredList, setFilteredList] = useState<any>();
    const classes = useStyles({ showItems, boxWidth, fixedSelectbox, dataListLength: (filteredList?.length || 0) * (themes.subMenu.buttonHeight + 2) } as any);

    useEffect(() => {
        if (listData && !isEmpty(listData)) {
            setFilteredList(listData);
        }
    }, [listData]);

    function selectItem(selectedItem) {
        if (onChange) {
            onChange(selectedItem.value);
        }
        setSelected(selectedItem.value);
        setShowItems(false);
    }

    function getItemClassName(selectedItem) {
        const selectedItemFromList = listData ? listData.find(dataItem => dataItem.value === selected) : null;
        return selected && selectedItemFromList && selectedItemFromList.text === selectedItem.text ? classes.selectedItem : classes.dataItem;
    }

    function search(value) {
        if (!isEmpty(value)) {
            setFilteredList(listData.filter(dataItem => stringUtils.includesNoCase(dataItem.text, value)));
        } else {
            setFilteredList(listData);
        }
    }

    useEffect(() => {
        function scrollFn() {
            setShowItems(false);
        }
        if (showItems && !isMouseInBox && fixedSelectbox) {
            window.addEventListener('scroll', scrollFn);
        }
    }, [fixedSelectbox, showItems, setShowItems, isMouseInBox]);

    return (
        <div id="selectScroll" className={classes.dropdownBox} onMouseEnter={() => { setIsMouseInBox(true); }} onMouseLeave={() => { setIsMouseInBox(false); }}>
            { (type === 'search' || type === 'searchPlusAdd') && (
                <div className={classes.searchBar}>
                    <SearchBar
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        onChange={search}
                    />
                </div>
            )}
            <div className={classes.itemList}>
                {filteredList && filteredList.map((dataItem, index) => (
                    <div title={languageUtils.getTitle(t, dataItem.text)} key={index} className={getItemClassName(dataItem)} onClick={() => selectItem(dataItem)} onKeyDown={() => selectItem(dataItem)} role="button" tabIndex={0}>
                        {dataItem.text}
                    </div>
                ))}
            </div>
            { (type === 'add' || type === 'searchPlusAdd') && (
                <TextButton iconId="icon-add" iconPosition="before" onClick={addOnClick}>
                    {t('default.addNewItem', 'Add new item')}
                </TextButton>
            )}
        </div>
    );
}
