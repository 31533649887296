import { Money } from '@baseComponents/formatters';
import { BuiltInAttributeTypes, isAttributeWithCost } from '@map/services/mapEnums';
import { InfoPanelLayerAttribute, LayerAttribute, ThemeType } from '@map/services/mapTypes';
import { currencyFormatter, isFloat, labelNumberFormatter } from '@map/utils/numberUtils';
import { isEmpty, isNil, isNumber } from 'lodash';
import { createUseStyles } from 'react-jss';
import { dateTimeUtils } from 'shared/src/modules';

type Classes = 'gridContainer' | 'gridBox' | 'gridBoxLabel' | 'gridBoxValue' | 'money';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'auto auto',
        gridRow: 'auto auto',
        gridColumnGap: 20,
        gridRowGap: 10,
        margin: '0 20px',
    },
    gridBox: {
        color: '#454F54',
        textAlign: 'left',
    },
    gridBoxLabel: {
        fontSize: 12,
        fontWeight: 400,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '16ch',
    },
    money: {
        textAlign: 'left',
    },
    gridBoxValue: {
        fontSize: 13,
        fontWeight: 500,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '17ch',
    },
}));

export function PropertyGrid({ children }) {
    const classes = useStyles();

    return (
        <div className={classes.gridContainer}>
            {children}
        </div>
    );
}

type TProps = {
    label: string | JSX.Element;
    value?: number | string | null;
    attribute: InfoPanelLayerAttribute;
    suffix?: string;
}

export function PropertyItem({ label, value, suffix, attribute }: TProps) {
    const classes = useStyles();
    const getDisplayValue = () => {
        if (attribute.attributeKey === BuiltInAttributeTypes.DATE_TIME) {
            return dateTimeUtils.getDateTime(value);
        }

        let text = value;
        if (typeof (text) !== 'number' && (isEmpty(text) || isNil(text))) {
            text = '-';
        }
        if (Number.isFinite(Number(text))) {
            if (attribute.defaultAttributeKey === BuiltInAttributeTypes.YIELD) {
                return `${Number(text || 0).toFixed(2)} ${suffix}`;
            }

            if (isAttributeWithCost(attribute.defaultAttributeKey)) {
                return Money({ value: text, unitPriceDecimals: false, className: classes.money });
            }

            text = labelNumberFormatter(text, suffix);
        }

        text = text && `${text}${suffix ? ` ${suffix}` : ''}`;
        return text;
    };

    return (
        <div className={classes.gridBox}>
            <div className={classes.gridBoxLabel}>
                {label}
            </div>
            <div className={classes.gridBoxValue}>
                {getDisplayValue()}
            </div>
        </div>
    );
}
