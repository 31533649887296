import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useFormikContext } from 'formik';
import useTranslate from '@i18n/useTranslate';
import Svg from '../../baseComponents/Svg';
import SupportCard from './SupportCard';
import SupportSelect from './SupportSelect';

const useStyles = createUseStyles((theme: any) => ({
    supportContainer: {
        width: 380,
        height: '100%',
        padding: '40px 30px',
        '& h2': {
            fontFamily: 'Rubik',
            marginBottom: 10,
            ...theme.firstLetterUppercase,
        },
    },
    expand: {
        cursor: 'pointer',
        height: 30,
        width: 30,
        borderRadius: '50%',
        backgroundColor: theme.color.pearl,
        outline: 'none',
        marginBottom: 15,
    },
    toggle: {
        marginLeft: 3,
        marginTop: 3,
        height: 24,
        width: 24,
    },
    card: {
        marginTop: 15,
    },
    cardContainer: {
        maxHeight: '80%',
        overflow: 'hidden',
        overflowY: 'auto',
        ...theme.verticalScrollbar,
    },
}));

export default function SupportData(props) {
    const { close } = props;
    const { t } = useTranslate();
    const [showSupports, setShowSupports] = useState(false);
    const { values, setFieldValue } = useFormikContext();
    const classes = useStyles();

    function deleteItem(subsidy) {
        const filteredSupportList = values.support.filter(supportObj => supportObj.support.id !== subsidy.support.id);
        setFieldValue('support', filteredSupportList);
    }

    function setValue(subsidy, value, name) {
        const subsidyList = [...values.support];
        const subsidyIndex = subsidyList.findIndex(subsidyObj => subsidyObj.support.id === subsidy.support.id);
        subsidyList[subsidyIndex] = { ...subsidyList[subsidyIndex], [name]: value };
        setFieldValue('support', subsidyList);
    }

    return (
        <div className={classes.supportContainer}>
            <div className={classes.expand} onClick={close} onKeyDown={close} role="button" tabIndex="0">
                <Svg iconId="icon-chevron_left" style={classes.toggle} />
            </div>
            <h2>
                {t('supportData.subsidy', 'Subsidy')}
            </h2>
            <div className={classes.cardContainer}>
                {values.support && values.support.map(supportObj => (
                    <SupportCard
                        supportList={values.support}
                        key={supportObj.support.id}
                        support={supportObj}
                        setValue={(value, name) => setValue(supportObj, value, name)}
                        deleteItem={() => deleteItem(supportObj)}
                        className={classes.card}
                    />
                ))}
            </div>
            <SupportSelect
                showList={showSupports}
                setShowList={setShowSupports}
                supportList={values.supportList}
                setSupportData={value => setFieldValue('support', value)}
                supportData={values.support}
            />
        </div>
    );
}
