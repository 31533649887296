import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';

i18n.use(ChainedBackend)
    .use(LanguageDetector)
    .use(initReactI18next);

export default i18n;
