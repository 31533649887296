import { sendServerRequest } from './baseService';

export const cropService = {
    getCropTypes,
};

/**
 * Get all crop types datas
 * @return {array} Crop types array
 */
async function getCropTypes() {
    const response = await sendServerRequest('GET', '/api/cropTypes');
    return response;
}
