import React from 'react';
import { createUseStyles } from 'react-jss';
import { routerHelper } from '@utils';
import { MODULES } from 'shared/src/routing';

import Svg from '@baseComponents/Svg';
import { isEmpty, isObject } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    //pesticide
    pesticideButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: theme.color.transparent,
        border: 'none',
        cursor: (props: any) => (props.isPesticideProduct ? 'pointer' : 'default'),
    },
    pesticideLink: {
        height: 36,
        width: 36,
        border: '1px solid',
        borderColor: theme.color.pearl,
        backgroundColor: theme.color.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        '&:hover': {
            borderColor: (props: any) => (props.isPesticideProduct ? theme.color.gray : theme.color.pearl),
            boxShadow: (props: any) => (props.isPesticideProduct ? theme.shadows.secondary.hover : 'none'),
        },
        cursor: (props: any) => (props.isPesticideProduct ? 'pointer' : 'default'),
    },
    pesticideIcon: {
        height: 21.6,
        width: 21.6,
        opacity: (props: any) => (props.isPesticideProduct ? '100%' : '15%'),
        cursor: (props: any) => (props.isPesticideProduct ? 'pointer' : 'default'),
    },
    //wrapper
    iconWithMultilineCellContainer: {
        height: '100%',
        width: '100%',
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
    },
    //two line
    twoLineContainer: {
        minWidth: 180,
        textAlign: 'left',
        height: '100%',
        width: '100%',
        margin: 'auto',
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        opacity: (props: any) => props.isDisabled && 0.5,
    },
    firstLine: {
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '18px',

        fontSize: 15,
        fontWeight: 500,
        color: theme.color.jet,
    },
    secondLine: {
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '16px',

        fontSize: 13,
        fontWeight: 'normal',
        color: theme.color.raven,

        display: 'flex',
        flexDirection: 'row',
    },
    inlineBlock: {
        display: 'inline-block',
    },
    //two column
    separator: {
        marginLeft: 5,
        marginRight: 5,
        borderRight: `1px solid ${theme.color.raven}`,
        display: 'inline-block',
        height: 12,
    },
    firstColumn: {
        display: 'inline-block',
        ...theme.overFlowText,
    },
    secondColumn: {
        minWidth: 60,
        display: 'inline-block',
        ...theme.overFlowText,
    },
}));

export default function ProductWithPesticideCell(props) {
    const { cellData, pesticideConnectionId, isDisabled } = props || {};
    const { name, pesticideId, typeName, itemNumber } = cellData || {};
    const classes = useStyles({ isPesticideProduct: pesticideId > 0, isDisabled });

    function onClickPesticideButton() {
        if (+pesticideId > 0) {
            const pesticideUrl = routerHelper.generateUrl(MODULES.PRODUCT, MODULES.PRODUCT.CONTENTS.PESTICIDES, { id: pesticideId });
            window.open(pesticideUrl, '_blank');
        }
    }

    if (isObject(name)) {
        return null;
    }

    return (
        <div className={classes.iconWithMultilineCellContainer}>
            {(pesticideConnectionId && !isEmpty(name)) ? (
                <div className={classes.inlineBlock}>
                    <button className={classes.pesticideButton} type="button" onClick={onClickPesticideButton}>
                        <div className={classes.pesticideLink}>
                            <Svg iconId="icon-pp_datasheet" style={classes.pesticideIcon} />
                        </div>
                    </button>
                </div>
            ) : null}
            <div className={classes.twoLineContainer}>
                <div className={classes.firstLine} title={name}>
                    {name}
                </div>
                <div className={classes.secondLine}>
                    {!isEmpty(typeName) && (
                        <div className={classes.firstColumn} title={typeName}>
                            {typeName}
                        </div>
                    )}
                    {!isEmpty(typeName) && !isEmpty(itemNumber) && (
                        <div className={classes.separator} />
                    )}
                    {!isEmpty(itemNumber) && (
                        <div className={classes.secondColumn} title={itemNumber}>
                            {itemNumber}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
