import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { isUndefined, isNull, isEmpty } from 'lodash';
import clsx from 'clsx';

import HeaderRowCell from './HeaderRowCell';
import ListRowTemplate from './ListRowTemplate';

const useStyle = createUseStyles({
    listHeaderRow: {
        borderBottomRightRadius: (props: any) => props.isCollapsed && '6px',
        borderBottomLeftRadius: (props: any) => props.isCollapsed && '6px',
    },
});

export default function ListHeaderRow(props) {
    const { groupName, groupItemCount, canUseGroupOperation, isCollapsible, isCollapsed, isBasePageTable, onToggleCollapsable } = props; //header collapse
    const { rowData, leftFixedColumns, mainColumns, rightFixedColumns, handleMidScroll, classes } = props; //body
    const { aggregateType } = props; //footer
    const { columnWidths, leftSideWidth, rightSideWidth, isDataCard, onSelectRow, rowStyle } = props; //style
    const { tableKey } = props; //simple table
    const customClasses = useStyle({ isCollapsed });

    const filteredTableData = useSelector((state: any) => state.basePage.table.filteredDatas);
    const simpleTableFilteredTableData = useSelector((state: any) => state.simpleTable.table?.[tableKey]?.filteredDatas);

    function getGroupDataFromFilteredList() {
        const tableGroupData = isBasePageTable ? filteredTableData?.[groupName] : simpleTableFilteredTableData?.[groupName];
        if (tableGroupData && !isUndefined(groupName) && !isNull(groupName)) {
            return tableGroupData;
        }
        return null;
    }

    return (
        <ListRowTemplate
            classes={classes}
            rowStyle={clsx(rowStyle, customClasses.listHeaderRow)}
            rowData={rowData}
            leftSideWidth={leftSideWidth}
            handleMidScroll={handleMidScroll}
            rightSideWidth={rightSideWidth}
            isDataCard={isDataCard}
            onSelectRow={onSelectRow}
            tableKey={tableKey}
            left={
                !isEmpty(leftFixedColumns) && leftFixedColumns.map((lfColumn, idx) => lfColumn.isTitle && (
                    <HeaderRowCell
                        key={idx}
                        column={lfColumn}
                        filteredTableData={getGroupDataFromFilteredList()}
                        groupName={groupName}
                        groupItemCount={groupItemCount}
                        canUseGroupOperation={canUseGroupOperation}
                        isCollapsible={isCollapsible}
                        isCollapsed={isCollapsed}
                        isBasePageTable={isBasePageTable}
                        onToggleCollapsable={onToggleCollapsable}
                        isDataCard={isDataCard}
                        tableKey={tableKey}
                    />
                ))
            }
            middle={
                !isEmpty(mainColumns) && mainColumns.map((mnColumn, idx) => (
                    <HeaderRowCell
                        key={idx}
                        column={mnColumn}
                        cellWidth={!isEmpty(columnWidths) ? columnWidths[idx] : null}
                        filteredTableData={getGroupDataFromFilteredList()}
                        aggregateType={aggregateType}
                        isCollapsible={isCollapsible}
                        isCollapsed={isCollapsed}
                        isDataCard={isDataCard}
                    />
                ))
            }
            right={
                !isEmpty(rightFixedColumns) && rightFixedColumns.map((rColumn, idx) => (
                    <HeaderRowCell
                        key={idx}
                        column={rColumn}
                        filteredTableData={getGroupDataFromFilteredList()}
                        aggregateType={aggregateType}
                        isCollapsible={isCollapsible}
                        isCollapsed={isCollapsed}
                        isDataCard={isDataCard}
                    />
                ))
            }
        />
    );
}
