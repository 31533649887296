import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    leftContainer: {
        height: 'auto',
        width: 50,
        borderBottomLeftRadius: 7.2,
        borderTopLeftRadius: 7.2,
        backgroundColor: (props: any) => {
            switch (props.type) {
                case 'info':
                    return theme.color.lilian;
                case 'success':
                    return theme.color.main;
                case 'error':
                    return theme.color.stop;
                default:
                    return theme.color.slow;
            }
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        height: 28,
        width: 28,
        fill: (props: any) => (props.type === 'info' ? theme.color.jet : theme.color.white),
    },
    rightContainer: {
        padding: [12, 18],
        display: 'flex',
        width: '100%',
        height: 'auto',
        alignItems: 'center',
        textAlign: 'left',
    },
    title: {
        display: 'block',
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 1.25,
        marginBottom: 5,
    },
    more: {
        display: 'block',
        fontSize: 12,
        fontWeight: 500,
        marginTop: 5,
        color: theme.color.main,
    },
}));

export default function TooltipBody(props) {
    const { type, title, message, moreInfo } = props;
    const { t } = useTranslation();
    const classes = useStyles({ type } as any);

    function getTypeSvg() {
        switch (type) {
            case 'info':
                return 'icon-info';
            case 'success':
                return 'icon-done';
            default:
                return 'icon-flag_warn';
        }
    }

    return (
        <>
            <div className={classes.leftContainer}>
                <Svg iconId={getTypeSvg()} style={classes.icon} />
            </div>
            <div className={classes.rightContainer}>
                <div>
                    {title && <span className={classes.title}>{title}</span>}
                    {message}
                    {moreInfo && <Link to={moreInfo} className={classes.more}>{t('alert.learnMore', 'Learn More')}</Link>}
                </div>
            </div>
        </>
    );
}
