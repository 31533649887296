import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector, useDispatch } from 'react-redux';
import { basePageActions } from '@states/actions/basePageActions';

import { simpleTableActions } from '@states/actions';
import Checkbox from '../../../../../controls/Checkbox';

const useStyles = createUseStyles({
    inlineItem: {
        display: 'inline-block',
        verticalAlign: 'middle',
    },
    groupCheckbox: {
        marginRight: '15px',
    },
});

export default function GroupOperationButton(props) {
    const { tableKey } = props;

    const simpleTable = useSelector((state: any) => state?.simpleTable?.table?.[tableKey]);
    const isAllSimpleTableRowSelected = useMemo(() => (simpleTable?.selectedItemIds?.length === simpleTable?.tableData?.length), [simpleTable]);

    const isAllTableGroupSelected = useSelector((state: any) => state.basePage.isAllTableGroupSelected);
    const dispatch = useDispatch();
    const classes = useStyles();

    function selectAllItem() {
        if (!tableKey) {
            dispatch(basePageActions.setIsAllTableGroupSelected());
        } else if (isAllSimpleTableRowSelected) {
            dispatch(simpleTableActions.setSelectedIds(tableKey, []));
        } else {
            dispatch(simpleTableActions.setSelectedIds(tableKey, simpleTable?.tableData?.map(simpleTableData => simpleTableData?.id)));
        }
    }
    return (
        <div className={classes.inlineItem}>
            <Checkbox
                className={classes.groupCheckbox}
                size="small"
                checked={tableKey ? isAllSimpleTableRowSelected : isAllTableGroupSelected}
                onChange={selectAllItem}
            />
        </div>
    );
}
