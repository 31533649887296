import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const partnerService = {
    getPartnersAndCompanies,
};

//const { companyIds, typeId, showSettlement, isWagework, isLandOwner, isProfessionalManager, isCultivationPeriodCompany } = props;
async function getPartnersAndCompanies(props) {
    const url = routerHelper.getUrlWithParameters('/api/partner/partnersAndCompanies', props);
    const response = await sendServerRequest('GET', url);
    return response;
}
