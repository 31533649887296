import React from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import { useFormContext } from 'react-hook-form';
import PeriodYearSelector from '@baseComponents/selector/PeriodYearSelector';
import Checkbox from '@baseComponents/controls/Checkbox';
import { UnitInput } from '@baseComponents/inputs/inputField';
import Input from '@baseComponents/inputs/Input';
import { dateTimeUtils } from 'shared/src/modules';
import { useNumberFormat } from '@common_modules';
import StartAndEndOfPeriod from './StartAndEndOfPeriod';
import CultureAndVarietySelect from './CultureAndVarietySelect';
import CropTypeSelect from './CropTypeSelect';

const useStyles = createUseStyles((theme: any) => ({
    content: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
        padding: [0, 30],
        ...theme.verticalScrollbar,
    },
    inputSpace: {
        marginTop: 15,
    },
}));

export default function CloseAndOpenCultivationPeriod(props) {
    const { formRef, isGroupOperation } = props;
    const { t } = useTranslate();
    const { watch, formState, clearErrors, getValues, setValue } = useFormContext();
    const { errors } = formState;

    const classes = useStyles();

    const { numberFormatter } = useNumberFormat();

    function setPeriod(value) {
        setValue('period', value);
        if (value && errors?.period?.message) {
            clearErrors('period');
        }
    }

    function setSownArea(value) {
        const area = numberFormatter.getValueFromString(value) || 0;
        setValue('sownArea', area);
        if (area && errors?.sownArea?.message) {
            clearErrors('sownArea');
        }
    }

    function setDate(date, dateId) {
        setValue(dateId, date);
        if (errors[dateId]?.message) {
            clearErrors(dateId);
        }
        if (dateId === 'endDate') {
            const start = dateTimeUtils.getDefaultDateFormat(dateTimeUtils.getMomentObjectFromDateString(dateTimeUtils.dateAddDays(date, 1)));
            setValue('startDate', start);
            clearErrors('startDate');
        }
    }

    function onChangeName(event) {
        const name = event.target.value;
        setValue('name', name);
        if (errors.name?.message) {
            clearErrors('name');
        }
    }

    return (
        <div className={classes.content}>
            {!isGroupOperation
                && (
                    <Input
                        label={t('closeOpenCultivationPeriod.name', 'Name')}
                        value={watch('name')}
                        onChange={event => onChangeName(event)}
                        invalid={!!errors?.name?.message}
                        errorMessage={errors?.name?.message}
                        required
                    />)}
            <PeriodYearSelector
                period={getValues('period')}
                setPeriod={value => setPeriod(value)}
                setDefaultPeriod={value => setValue('periodYear', value)}
                errorMessage={errors?.period?.message}
            />
            <StartAndEndOfPeriod
                endDate={watch('endDate')}
                setEndDate={value => setDate(value, 'endDate')}
                formRef={formRef}
                startDate={watch('startDate')}
                setStartDate={value => setDate(value, 'startDate')}
                startDateError={errors?.startDate?.message}
                endDateError={errors?.endDate?.message}
            />
            <Checkbox
                className={classes.inputSpace}
                text={t('closeOpenCultivationPeriod.previousCultureVariety', 'Add culture, variety of the previous year')}
                checked={watch('isPreviousCultureVariety')}
                onChange={state => setValue('isPreviousCultureVariety', state)}
            />
            <Checkbox
                className={classes.inputSpace}
                text={t('closeOpenCultivationPeriod.previousComment', 'Add comment of the previous year')}
                checked={watch('isPreviousComment')}
                onChange={state => setValue('isPreviousComment', state)}
            />
            <Checkbox
                className={classes.inputSpace}
                text={t('closeOpenCultivationPeriod.previousFieldFeatures', 'Add field features of the previous year')}
                checked={watch('isPreviousFieldFeatures')}
                onChange={state => setValue('isPreviousFieldFeatures', state)}
            />

            {!getValues('isPreviousCultureVariety')
                && (
                    <CultureAndVarietySelect
                        culture={watch('culture')}
                        setCulture={value => setValue('culture', value)}
                        variety={watch('variety')}
                        setVariety={value => setValue('variety', value)}
                    />)}

            {!isGroupOperation
                && (
                    <UnitInput
                        type="number"
                        defaultValue={getValues('sownArea')}
                        onBlur={(_event, area) => setSownArea(area)}
                        numberFormat={numberFormatter.getAreaFormats()}
                        isInvalid={!!errors?.sownArea?.message}
                        errorMessage={errors?.sownArea?.message}
                        className={classes.inputSpace}
                        label={t('closeOpenCultivationPeriod.sownArea', 'Sown area')}
                        isRequired
                        positiveOnly
                        isLeftAlign
                    />
                )
            }

            <CropTypeSelect
                cropType={watch('cropType')}
                setCropType={value => setValue('cropType', value)}
                className={classes.inputSpace}
            />
        </div>
    );
}
