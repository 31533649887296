import useTranslate from '@i18n/useTranslate';
import MapController from '@map/services/mapController';
import { BuiltInLayerTypes, MapMode } from '@map/services/mapEnums';
import { TMapStore } from '@map/services/mapStore';
import { getDataForLayer } from '@map/utils/mapUtils';
import { gisService } from '@services/gisService';
import { alertActions } from '@states/actions';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    PlanningCultivationPeriod,
    PlanningCultivationTechnology,
    PlanningCulture,
    PlanningCultureReprocutionScale,
    PlanningCultureType,
} from './planningEditModalTypes';
import { PlanningEditModalSubmitSchema, SetPlanningCultivationPeriodsDto } from './PlanningModalSchema';

export type TProps = {
    entityIds: number[],
    planId: number,
    productionYearId: number,
    mapStore: TMapStore,
    mapController: MapController,
    cultureId?: number,
    planningEditData?: PlanningCultivationPeriod[],
}

export const usePlanningEditModal = ({ entityIds, planId, productionYearId, cultureId, mapStore, mapController, planningEditData } : TProps) => {
    // eslint-disable-next-line no-return-await, arrow-body-style

    const [plannings, setPlannings] = useState<Array<PlanningCultivationPeriod>>([]);
    const [planningCultureType, setPlanningCultureType] = useState<Array<PlanningCultureType>>([]);
    const [planningCulture, setPlanningCulture] = useState<Array<PlanningCulture>>([]);
    const [planningCultureReproductionRate, setPlanningCultureReproductionRate] = useState<Array<PlanningCultureReprocutionScale>>([]);
    const [planningCultivationTechnology, setPlanningCultivationTechnology] = useState<Array<PlanningCultivationTechnology>>([]);

    const dispatch = useDispatch();
    const { t } = useTranslate();

    const { mapMode } = mapStore || { mapMode: MapMode.DEFAULT };

    useEffect(() => {
        const fetchPlannings = async () => {
            if (planningEditData) {
                setPlannings(planningEditData ?? []);
                return;
            }
            const fetchResult:Array<PlanningCultivationPeriod> = await getDataForLayer({ entityIds, planId, layerName: BuiltInLayerTypes.PLANNING, productionYearId });
            setPlannings(fetchResult);
        };

        const fetchPlanningsCulture = async () => {
            const response = await fetch('/modules/altalanos/control/get_kultura_combo.php', { method: 'GET' });
            if (response.status === 200) {
                const responseParsed = await response.json() as Array<PlanningCulture>;
                setPlanningCulture(responseParsed);
            }
        };

        const fetchPlanningCultureReproductionScale = async () => {
            const response = await fetch('/modules/tabla/control/get_szaporitasi_fok_combo.php', { method: 'GET' });
            if (response.status === 200) {
                const responseParsed = await response.json() as Array<PlanningCulture>;
                setPlanningCultureReproductionRate(responseParsed);
            }
        };

        fetchPlannings();
        fetchPlanningsCulture();
        if (mapMode !== MapMode.PLANNING_EDIT) {
            fetchPlanningCultureReproductionScale();
        }
    }, [entityIds, planId, productionYearId, planningEditData, mapMode]);

    useEffect(() => {
        if (isEmpty(plannings) || isNil(cultureId)) {
            return;
        }

        const companyIds = new Set(plannings.map(plan => plan.companyId));
        const farmIds = new Set(plannings.map(plan => plan.farmId));

        const fetchPlanningCultivationTechnology = async () => {
            const body = new FormData();
            body.append('ev_id', productionYearId.toString());
            companyIds.forEach(companyId => {
                body.append('ceg_id', companyId.toString());
            });
            body.append('kultura_id', cultureId?.toString());
            farmIds.forEach(farmId => {
                body.append('gazdasag_id', farmId.toString());
            });
            const response = await fetch('/modules/tervezes/control/get_termesztestechnologia_lista_modal.php', { method: 'POST', body });
            if (response.status === 200) {
                const responseParsed = await response.json() as Array<PlanningCultivationTechnology>;
                setPlanningCultivationTechnology(responseParsed);
            }
        };
        fetchPlanningCultivationTechnology();
    }, [cultureId, plannings, productionYearId]);

    useEffect(() => {
        if (isNil(cultureId)) {
            setPlanningCultureType([]);
            return;
        }

        const fetchPlanningsCultureType = async () => {
            const response = await fetch(`/modules/altalanos/control/get_kultura_fajta_combo.php?kultura_id=${cultureId}`, { method: 'GET' });
            if (response.status === 200) {
                const responseParsed = await response.json() as Array<PlanningCultureType>;
                setPlanningCultureType(responseParsed);
            }
        };

        if (mapMode !== MapMode.PLANNING_EDIT) {
            fetchPlanningsCultureType();
        }
    }, [cultureId, mapMode]);

    const savePlannings = async (planningsToSave: PlanningEditModalSubmitSchema[]) => {
        const planningDto: SetPlanningCultivationPeriodsDto = {
            planningData: planningsToSave,
        };

        try {
            const updateData = await gisService.setPlanningCultivationPeriods(planningDto);
            const layer = mapStore.layering.layers?.find(l => l.planId === planId);
            if (!isNil(layer)) {
                await mapController.updateLayerGeometryData(layer, updateData);
                dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved')));
            }
        } catch (error) {
            dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed')));
        }
    };

    return { plannings, planningCulture, planningCultureType, planningCultureReproductionRate, planningCultivationTechnology, savePlannings };
};
