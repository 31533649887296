import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import Svg from '@baseComponents/Svg';
import { isFunction } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    button: {
        height: 40,
        width: '100%',
        backgroundColor: 'transparent',
        border: 'none',
        borderTop: '1px solid #e3e6e8',
        borderRadius: 0,
        cursor: 'pointer',
        outline: 'none',
        color: (props: any) => (props.type === 'destructive' ? theme.color.destructive : theme.color.main),
        fill: (props: any) => (props.type === 'destructive' ? theme.color.destructive : theme.color.main),
        '&:hover': {
            cursor: 'pointer',
            background: (props: any) => (props.type === 'destructive' ? theme.color.destructiveFaded : theme.color.opacity.main),
        },
        '&:focus': {
            background: (props: any) => (props.type === 'destructive' ? theme.color.destructiveFaded : theme.color.opacity.main),
        },
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
    },
    title: {
        textTransform: (props: any) => !props.lowercase && 'uppercase',
        fontSize: 13,
        lineHeight: 1.54,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    after: {
        marginLeft: 7,
        width: 20,
        height: 20,
        fill: (props: any) => (props.type === 'destructive' ? theme.color.destructive : theme.color.main),
    },
    before: {
        width: 20,
        height: 20,
        marginRight: 7,
        fill: (props: any) => (props.type === 'destructive' ? theme.color.destructive : theme.color.main),
    },
    icon: {
        width: 20,
        height: 20,
        fill: (props: any) => (props.type === 'destructive' ? theme.color.destructive : theme.color.main),
    },
}));

export default function TextButton(props) {
    const { type, iconId, iconPosition, onClick, children, className, lowercase, href } = props;
    const title = children;
    const classes = useStyles({ type, lowercase });

    function onClickButton(event) {
        if (href && event?.ctrlKey) {
            event.preventDefault();
            window.open(href, '_blank');
            return;
        }

        if (isFunction(onClick)) {
            onClick();
        }
    }

    return (
        <button type="button" className={clsx(classes.button, className)} onClick={onClickButton}>
            <div className={classes.content}>
                { iconId && iconPosition === 'before' && <Svg iconId={iconId} style={classes.before} />}
                <div className={classes.title}>{title}</div>
                { !iconPosition && iconId && <Svg iconId={iconId} style={classes.icon} />}
                { iconId && iconPosition === 'after' && <Svg iconId={iconId} style={classes.after} />}
            </div>
        </button>
    );
}
