import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { SETTINGS } from 'shared/src/constants';
import useTranslate from '@i18n/useTranslate';
import { stringUtils } from 'shared/src/modules/stringUtils';
import { companyActions } from '@states/actions';
import FormSelect from '@baseComponents/select/formSelect/FormSelect';
import Svg from '@baseComponents/Svg';
import Input from '@baseComponents/inputs/Input';

const useStyle = createUseStyles((theme: any) => ({
    selectDropdown: {
        marginTop: 15,
        '& .dropdown-menu': {
            transform: 'translate(0px, 42px) !important',
            width: '100%',
        },
        '& .dropdown-item': {
            height: 40,
            padding: '10px 15px',
            ...theme.overFlowText,
            maxWidth: '100%',
        },
        '& #selectDropdownList': {
            maxWidth: 318,
        },
    },
    insuranceContainer: {
        width: 380,
        height: '100%',
        padding: '40px 30px',
        zIndex: theme.zIndex.modalWindow,
        overflow: 'auto',
        ...theme.verticalScrollbar,
        '& h2': {
            fontFamily: 'Rubik',
            ...theme.firstLetterUppercase,
        },
    },
    expand: {
        cursor: 'pointer',
        height: 30,
        width: 30,
        borderRadius: '50%',
        backgroundColor: theme.color.pearl,
        outline: 'none',
        marginBottom: 15,
    },
    toggle: {
        marginLeft: 3,
        marginTop: 3,
        height: 24,
        width: 24,
    },
    inputSpace: {
        marginTop: 15,
    },
}));

export default function InsuranceData(props) {
    const { close, partnerList, selectBank, selectInsurer } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();

    const { values, handleChange } = useFormikContext();
    const [filteredBankList, setFilteredBankList] = useState();
    const [filteredInsurerList, setFilteredInsurerList] = useState();
    const [isNonAccentSearch] = useState(
        dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)),
    );

    const classes = useStyle();

    useEffect(() => {
        if (partnerList) {
            setFilteredBankList(partnerList);
            setFilteredInsurerList(partnerList);
        }
    }, [partnerList, setFilteredBankList, setFilteredInsurerList]);

    function search(value, dataList, setList) {
        if (!isEmpty(value) && !isEmpty(dataList)) {
            setList(
                dataList.filter(dataObj => stringUtils.includesNoCase(dataObj.value, value, isNonAccentSearch)),
            );
        } else {
            setList(dataList);
        }
    }

    return (
        <div className={classes.insuranceContainer}>
            <div
                className={classes.expand}
                onClick={close}
                onKeyDown={close}
                role="button"
                tabIndex={0}
            >
                <Svg iconId="icon-chevron_left" style={classes.toggle} />
            </div>
            <h2>{t('cultivationPeriodsColumn.bank', 'Bank')}</h2>
            <>
                <FormSelect
                    dataList={filteredBankList}
                    selected={values.bankId}
                    onChange={selectBank}
                    label={t('cultivationPeriodsColumn.bank', 'Bank')}
                    search={value => search(value, partnerList, setFilteredBankList)}
                    className={classes.selectDropdown}
                    isClearItem
                />
                <FormSelect
                    dataList={filteredInsurerList}
                    selected={values.insurerId}
                    onChange={selectInsurer}
                    label={t('cultivationPeriodsColumn.insurer', 'Insurer')}
                    search={value => search(value, partnerList, setFilteredInsurerList)}
                    className={classes.selectDropdown}
                    isClearItem
                />
                <Input
                    label={t(
                        'cultivationPeriodsColumn.insuranceCountyNumber',
                        'Insurance country number',
                    )}
                    name="insuranceCountyNumber"
                    value={values.insuranceCountyNumber}
                    className={classes.inputSpace}
                    onChange={handleChange}
                />
                <Input
                    label={t(
                        'cultivationPeriodsColumn.damageFileNumber',
                        'Damage file number',
                    )}
                    name="damageFileNumber"
                    value={values.damageFileNumber}
                    className={classes.inputSpace}
                    onChange={handleChange}
                />
            </>
        </div>
    );
}
