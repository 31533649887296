import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const cultivationTableService = {
    setCultivationTable,
    getCultivationTables,
    getCultivationTableToPeriod,
    getCultivationTableById,
};

/**
 * Create / Update cultivation table
 * @property {int} id IsOptional - Table ID eg.: 13
 * If it is a creation then id should be null, otherwise the specified id will be updated
 * @property {string} name - Name of the table eg.: My lil table
 * @property {bool} isOwnTable IsOptional - Table relates to user's company eg.: true
 * @property {float} cultivatedArea - Site of cultivated area eg.: 10.5
 * @property {string} tableLocation - IsOptional Location of the field eg.: Budapest
 * @property {date} creationDate - Creation's date eg.: 2022-01-01
 * @property {date} terminationDate - IsOptional Termination's date eg.: 2022-01-01
 * @property {int} partnerId - The field owner partner/company ID eg.: 1312
 * @property {bool} isActive - Active status of the table eg.: true
 * @property {int} isNhaCategory - NHA category ID eg.: 3
 * @property {int} settlementId - IsOptional The id of the settlement where the table is located eg.: 321
 * NHA CATEGORIES ARE IN A RANGE FROM 1 to 4.
 * @property {string} description - IsOptional Description of the table eg.: Very big field
 * @property {int} farmId - IsOptional Related farm's id eg: 13
 *
 * @return {bool} Result status
 */

async function setCultivationTable(props) {
    const response = await sendServerRequest('POST', '/api/updateCultivationTable', props);
    return response;
}

async function getCultivationTableById(cultivationTableId) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationTableById', { cultivationTableId });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCultivationTables(periodYearId, companyOrPartnerId) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationTables', { periodYearId, companyOrPartnerId });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCultivationTableToPeriod(cultivationPeriodId, periodYearId) {
    const url = routerHelper.getUrlWithParameters('/api/cultivationTableToPeriod', { cultivationPeriodId, periodYearId });
    const response = await sendServerRequest('GET', url);
    return response;
}
