/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useMemo, useState } from 'react';
import { LayerCreateController } from './layerCreateController';
import { initStore, TLayerCreateStore } from './layerCreateStore';
import { LayerCreateStoreService } from './layerCreateStoreService';

export type TLayerCreateContext = Readonly<[store: TLayerCreateStore, controller: LayerCreateController]>;

const LayerCreateContext = createContext<TLayerCreateContext>([initStore, new LayerCreateController(initStore, new LayerCreateStoreService(initStore, () => {}))]);

type TProps = {
    children: React.ReactNode
};

export const LayerCreateContextProvider = ({ children }: TProps) => {
    const [store, setStore] = useState(initStore);

    const [storeService, controller] = useMemo<[LayerCreateStoreService, LayerCreateController]>(() => {
        const layerStoreService = new LayerCreateStoreService(initStore, () => {});
        const layerController = new LayerCreateController(initStore, layerStoreService);

        return [layerStoreService, layerController];
    }, []);

    storeService.bind(store, setStore);
    controller.bind(store, storeService);

    return (
        <LayerCreateContext.Provider value={[store, controller]}>
            {children}
        </LayerCreateContext.Provider>
    );
};

export const useLayerCreateContext = () => useContext(LayerCreateContext);
