import { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { isFunction, isEmpty } from 'lodash';
import clsx from 'clsx';
import Button from '@baseComponents/buttons/Button';
import useTranslate from '@i18n/useTranslate';
import OutsideClickHandler from 'react-outside-click-handler';

const useStyles = createUseStyles((theme: any) => ({
    workOperationInfoButtonCellWrapper: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
    },
    workOperationInfoButtonCell: {
        width: '100%',
        '& div': {
            padding: 0,
        },
        '& svg': {
            marginRight: 0,
            fill: theme.color.jet,
        },
    },
    popover: {
        minWidth: 755,
        maxWidth: 755,
        height: 370,
        padding: '15px 15px 20px',
        border: 'none',
        borderRadius: 6,
        backgroundColor: theme.color.white,
        zIndex: theme.zIndex.popover,
        boxShadow: theme.shadows.dropdown,
        '& #munkamuvelet_qtip': {
            '& label': {
                color: theme.color.jet,
                ...theme.overFlowText,
                display: 'block',
            },
            '& li.active a': {
                color: theme.color.main,
                borderColor: theme.color.main,
                background: theme.color.opacity.main,
            },
        },
    },
    popoverCloseButtonContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 15,
    },
}));

export default function WorkOperationInfoButtonCell(props) {
    const { rowData, getCellTitle, getIcon, icon } = props;
    const [popoverContent, setPopoverContent] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslate();
    const classes = useStyles();
    const workOperationPopoverClassName = 'workOperationInfoPopover';
    const popoverButtonRef = useRef<any>(null);

    function ignoreRowSelection(event) {
        document.body.click();
        event.stopPropagation();
    }

    function updateEventHandlers() {
        $('#munkamuvelet_qtip .nav-tabs>li>a').unbind('click').bind('click', function (event) {
            event.preventDefault();

            const tabClass = $(this).attr('class');
            const tabContentClass = `#munkamuvelet_qtip .tab-content .${tabClass}`;

            $('#munkamuvelet_qtip .nav-tabs>li').removeClass('active');
            $(this).parent('li').addClass('active');

            $(`${tabContentClass}`).addClass('active');
            $(`${tabContentClass}`).siblings().removeClass('active');
        });
    }

    function handlePopoverToggle(workOperationTypeId, id, buttonElement) {
        if (isOpen) {
            setIsOpen(false);
        } else {
            popoverButtonRef.current = buttonElement;

            (window as any).getWorkOperationInfoPhpContent(workOperationTypeId, id, function(data) {
                if (data) {
                    setPopoverContent(data);
                    setIsOpen(true);
                    updateEventHandlers();
                }
            });
        }
    }

    function handlePopoverClose() {
        setIsOpen(false);
    }

    function handleOutsideClick(event) {
        if (!isEmpty(popoverButtonRef.current) && (popoverButtonRef.current === event.target || popoverButtonRef.current.contains(event.target) || event.target.contains(popoverButtonRef.current))) {
            return;
        }

        setIsOpen(false);
    }

    if (!isFunction(getIcon) && isEmpty(icon)) {
        return null;
    }

    return (
        <div className={classes.workOperationInfoButtonCellWrapper} onClick={ignoreRowSelection} onKeyDown={ignoreRowSelection} role="button" tabIndex={0}>
            <OverlayTrigger
                trigger="click"
                placement="bottom"
                flip
                rootClose={false}
                show={isOpen}
                overlay={
                    <Popover className={clsx(classes.popover, workOperationPopoverClassName)}>
                        <Popover.Content>
                            {popoverContent && (
                                <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                                    <div dangerouslySetInnerHTML={{ __html: popoverContent }} />
                                    <div className={classes.popoverCloseButtonContainer}>
                                        <Button type="secondary" size="small" onClick={handlePopoverClose}>
                                            {t('default.close', 'Close')}
                                        </Button>
                                    </div>
                                </OutsideClickHandler>
                            )}
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button
                    className={classes.workOperationInfoButtonCell}
                    size="small"
                    type="secondary"
                    iconId={isFunction(getIcon) ? getIcon(rowData) : icon}
                    iconPosition="before"
                    title={isFunction(getCellTitle) ? getCellTitle(rowData) : null}
                    onClick={event => handlePopoverToggle(rowData.workOperationTypeId ?? 1, rowData.workOperationId, event.target)}
                    disabled={!+rowData?.workOperationId}
                />
            </OverlayTrigger>
        </div>
    );
}
