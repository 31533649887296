import { PLANNING as PLANNING_CONSTANTS } from '../constants';

export const PLANNING = {
    MODULE_ID: 22,
    NAME: 'planning',
    SHOW_ONLY_IN_PANNING_MODE: true,
    CONTENTS: {
        //crop rotation module
        CROP_ROTATION_INDEX: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { cropRotationIndex: 41 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: `cropRotation${PLANNING_CONSTANTS.ROUTE_KEY_EXTENSION}`,
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.cropRotation',
                TRANSLATOR_DEFAULT: 'Crop rotation',
            },
            IS_MENU_PAGE: true,
        },
        PRODUCTION_YEARS: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { productionYears: 42 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'productionYears',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productionYears',
                TRANSLATOR_DEFAULT: 'Production years',
            },
        },
        CULTURES: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { cultures: 43 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultures',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultures',
                TRANSLATOR_DEFAULT: 'Cultures',
            },
        },
        CULTIVATION_PERIODS_MANAGEMENT: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { cultivationPeriodsManagement: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsManagement',
                TRANSLATOR_DEFAULT: 'Cultivation periods management',
            },
        },
        CULTIVATON_PERIOD_EDIT: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { cultivationPeriodEdit: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodEdits',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.createPlanCultivationPeriod',
                TRANSLATOR_DEFAULT: 'Create cultivation period',
            },
            EDIT_TITLE: {
                TRANSLATOR_KEY: 'pageTitle.editPlanCultivationPeriod',
                TRANSLATOR_DEFAULT: 'Edit cultivation period',
            },
        },
        CULTIVATION_PERIODS_GROUPS: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { cultivationPeriodGroupManagement: 65 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodGroupManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.createPlanCultivationPeriodGroupManagement',
                TRANSLATOR_DEFAULT: 'Cultivation period group management',
            },
        },
        CULTIVATION_PERIODS_GROUPS_EDIT: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { cultivationPeriodGroupEdit: 66 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodGroupEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.createPlanCultivationPeriodGroup',
                TRANSLATOR_DEFAULT: 'Create cultivation period group',
            },
            EDIT_TITLE: {
                TRANSLATOR_KEY: 'pageTitle.editPlanCultivationPeriodGroup',
                TRANSLATOR_DEFAULT: 'Edit cultivation period group',
            },
        },
        YIELDS_OF_THE_CULTIVATION_PERIODS: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { yieldsOfTheCultivationPeriods: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'yieldsOfTheCultivationPeriods',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.yieldsOfTheCultivationPeriods',
                TRANSLATOR_DEFAULT: 'Yields of the cultivation periods',
            },
        },
        YIELD_EDIT: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { yieldEdit: 35 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'yieldEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.yieldEdit',
                TRANSLATOR_DEFAULT: 'Yield edit',
            },
        },
        TIMELINE: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { timeline: 45 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'timeline',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.timeline',
                TRANSLATOR_DEFAULT: 'Timeline',
            },
        },
        INCOMES: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { planCultivationPeriodsIncomes: 47 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'planCultivationPeriodsIncomes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsIncomes',
                TRANSLATOR_DEFAULT: 'Cultivation periods incomes',
            },
        },  
        INCOMES_EDIT: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { planCultivationPeriodsIncomesEdit: 48 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'planCultivationPeriodsIncomesEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsIncomes',
                TRANSLATOR_DEFAULT: 'Cultivation periods incomes',
            },
        },
        OTHER_COSTS_OF_CULTIVATION_PERIODS: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { otherCostsOfCultivationPeriods: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'otherCostsOfCultivationPeriods',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.otherCostsOfCultivationPeriods',
                TRANSLATOR_DEFAULT: 'Other costs of cultivation periods',
            },
        },
        ADDING_COST_EDIT: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { addingCostEdit: 36 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'addingCostEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.addingCostEdit',
                TRANSLATOR_DEFAULT: 'Adding cost edit',
            },
        },
        
        CHARGE_LAND_LEASE_FEE_TO_THE_PERIOD: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { chargeLandLeaseFeeToThePeriod: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'chargeLandLeaseFeeToThePeriod',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.chargeLandLeaseFeeToThePeriod',
                TRANSLATOR_DEFAULT: 'Charge land lease fee to the periods',
            },
        },
        CHARGE_DRYING_FEES_ON_THE_PERIOD: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CROP_ROTATION,
            CONTENT_URL_ID: { chargeDryingFeesOnThePeriod: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'chargeDryingFeesOnThePeriod',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.chargeDryingFeesOnThePeriod',
                TRANSLATOR_DEFAULT: 'Charge drying fees on the periods',
            },
        }, 

        //price module
        PRICE_INDEX: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.PRICE,
            CONTENT_URL_ID: { priceIndex: 46 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: `price${PLANNING_CONSTANTS.ROUTE_KEY_EXTENSION}`,
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.feesAndClearingPrices',
                TRANSLATOR_DEFAULT: 'Fees, clearing prices',
            },
            IS_MENU_PAGE: true,
        },
        LABOR_CLEARING_PRICE: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.PRICE,
            CONTENT_URL_ID: { laborClearingPrice: 39 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'laborClearingPrice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.laborClearingPrice',
                TRANSLATOR_DEFAULT: 'Labor clearing price',
            },
        },
        POSITION_CLEARING_PRICE: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.PRICE,
            CONTENT_URL_ID: { positionClearingPrice: 54 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'positionClearingPrice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.positionClearingPrice',
                TRANSLATOR_DEFAULT: 'Position clearing price',
            },
        },
        MACHINE_CLEARING_PRICE: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.PRICE,
            CONTENT_URL_ID: { machineClearingPrice: 38 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineClearingPrice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineClearingPrices',
                TRANSLATOR_DEFAULT: 'Machine clearing price',
            },
        },
        OPERATIONAL_ACTIVITY_CLEARING_PRICE: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.PRICE,
            CONTENT_URL_ID: { operationalActityClearingPrice: 55 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'operationalActityClearingPrice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.operationalActityClearingPrice',
                TRANSLATOR_DEFAULT: 'Operational actity clearing price',
            },
        },
        PRODUCT_PRICES: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.PRICE,
            CONTENT_URL_ID: { productClearingPrices: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'productClearingPrices',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productPrices',
                TRANSLATOR_DEFAULT: 'Input material prices',
            },
        },
        CROP_PRICES: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.PRICE,
            CONTENT_URL_ID: { cropPrices: 44 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cropPrices',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cropSalesPrices',
                TRANSLATOR_DEFAULT: 'Crop sales prices',
            },
        },       
        CROP_MANAGEMENT_FEES: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.PRICE,
            CONTENT_URL_ID: { cropManagementFees: 27 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cropManagementFees',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cropManagementFees',
                TRANSLATOR_DEFAULT: 'Crop management fees',
            },
        },
        CONTRACTED_OPERATION_CLEARING_PRICE: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.PRICE,
            CONTENT_URL_ID: { contractedOperationClearingPrice: 49 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'contractedOperationClearingPrice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.contractedOperationClearingPrice',
                TRANSLATOR_DEFAULT: 'Contracted opeartion clearing price',
            },
        },
        WORK_OPERATIONS_CLEARING_PRICE: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.PRICE,
            CONTENT_URL_ID: { workOperationsClearingPrice: 51 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workOperationsClearingPrice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.WorkOperationsClearingPrice',
                TRANSLATOR_DEFAULT: 'Work operations clearing price',
            },
        },

        //technologies module
        TECHNOLOGIES_INDEX: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { productionTechnologyIndex: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: `productionTechnology${PLANNING_CONSTANTS.ROUTE_KEY_EXTENSION}`,
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.productionTechnology',
                TRANSLATOR_DEFAULT: 'Cultivation technology',
            },
            IS_MENU_PAGE: true,
        },
        PRODUCTION_TECHNOLOGIES: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { productionTechnologies: 25 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'productionTechnologies',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productionTechnologies',
                TRANSLATOR_DEFAULT: 'Production technologies',
            },
        },
        CREATE_PRODUCTION_TECHNOLOGY: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { createProductionTechnology: 37 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'createProductionTechnology',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.createProductionTechnology',
                TRANSLATOR_DEFAULT: 'Create production technology',
            },
            EDIT_TITLE: {
                TRANSLATOR_KEY: 'pageTitle.editProductionTechnology',
                TRANSLATOR_DEFAULT: 'Edit production technology',
            },
        },
        WORK_OPERATIONS: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { workOperations: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workOperations',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workOperations',
                TRANSLATOR_DEFAULT: 'Work operations',
            },
        },
        CREATE_WORK_OPERATION: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { createWorkOperation: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'createWorkOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.createPlanWorkOperation',
                TRANSLATOR_DEFAULT: 'Create work operation',
            },
            EDIT_TITLE: {
                TRANSLATOR_KEY: 'pageTitle.editPlanWorkOperation',
                TRANSLATOR_DEFAULT: 'Edit work operation',
            },
        },
        COST_OF_CULTIVATION_PERIODS: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { costOfCultivationPeriods: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'costOfCultivationPeriods',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costOfCultivationPeriod',
                TRANSLATOR_DEFAULT: 'Cost of cultivation periods',
            },
        },
        COST_OF_CULTIVATION_PERIOD: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { costOfCultivationPeriod: 33 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'costOfCultivationPeriod',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costOfCultivationPeriod',
                TRANSLATOR_DEFAULT: 'Cost of cultivation period',
            },
        },
        PRODUCT_COST_OF_CULTIVATION_PERIOD: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { productCostOfCultivationPeriod: 34 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'productCostOfCultivationPeriod',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productCostOfCultivationPeriod',
                TRANSLATOR_DEFAULT: 'Product cost of cultivation period',
            },
        },
        MATERIAL_CONSUMPTION_IN_THE_GROWING_PERIOD: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { materialConsumptionInTheGrowingPeriod: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'materialConsumptionInTheGrowingPeriod',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.materialConsumptionInTheGrowingPeriod',
                TRANSLATOR_DEFAULT: 'Material consumption in the growing periods',
            },
        },        
        WORK_OPERATIONS_INT_THE_CULTIVATION_PERIODS: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { workOperationsInTheCultivationPeriods: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workOperationsInTheCultivationPeriods',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workOperationsInTheCultivationPeriods',
                TRANSLATOR_DEFAULT: 'Work operations in the cultivation periods',
            },
        },
        MATERIAL_CONSUMPTION_BY_TECHNOLOGY: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { materialConsumptionByTechnology: 56 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'materialConsumptionByTechnology',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.materialConsumptionByTechnology',
                TRANSLATOR_DEFAULT: 'Material consumption by technology',
            },
        },
        MATERIAL_CONSUMPTION: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.TECHNOLOGIES,
            CONTENT_URL_ID: { materialConsumption: 61 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'materialConsumption',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.materialConsumption',
                TRANSLATOR_DEFAULT: 'Material consumption',
            },
        },

        //stock module
        STOCK_INDEX: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.STOCK,
            CONTENT_URL_ID: { stockIndex: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: `stock${PLANNING_CONSTANTS.ROUTE_KEY_EXTENSION}`,
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.stock',
                TRANSLATOR_DEFAULT: 'Stock',
            },
            IS_MENU_PAGE: true,
        },
        PRODUCTS: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.STOCK,
            CONTENT_URL_ID: { products: 40 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'products',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.products',
                TRANSLATOR_DEFAULT: 'Products',
            },
        },

        //equipment index
        EQUIPMENT_INDEX: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.EQUIPMENT,
            CONTENT_URL_ID: { equipmentIndex: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: `equipment${PLANNING_CONSTANTS.ROUTE_KEY_EXTENSION}`,
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.equipment',
                TRANSLATOR_DEFAULT: 'Equipment',
            },
            IS_MENU_PAGE: true,
        },
        MACHINES: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.EQUIPMENT,
            CONTENT_URL_ID: { machines: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machines',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machines',
                TRANSLATOR_DEFAULT: 'Machines',
            },
        },
        MACHINE_EDIT: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.EQUIPMENT,
            CONTENT_URL_ID: { machineEdit: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineEdit',
                TRANSLATOR_DEFAULT: 'Machine edit',
            },
        },
        MACHINE_COST: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.EQUIPMENT,
            CONTENT_URL_ID: { machineCost: 63 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineCost',
                TRANSLATOR_DEFAULT: 'Machine cost',
            },
        },
        OPERATIONAL_ACTIVITY_COST: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.EQUIPMENT,
            CONTENT_URL_ID: { operationalActivityCost: 64 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'operationalActivityCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.operationalActivityCost',
                TRANSLATOR_DEFAULT: 'Operational activity cost',
            },
        },
        OPERATIONAL_ACTIVITY: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.EQUIPMENT,
            CONTENT_URL_ID: { operationalActivity: 52 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'operationalActivity',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.operationalActivity',
                TRANSLATOR_DEFAULT: 'Operational activity',
            },
        },
        MACHINE_ENGINE_HOURS_DEMAND: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.EQUIPMENT,
            CONTENT_URL_ID: { machineEngineHoursDemand: 62 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineEngineHoursDemand',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.engineHoursNeed',
                TRANSLATOR_DEFAULT: 'Engine hours demand',
            },
        },
        TASK_ENGINE_HOURS_DEMAND: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.EQUIPMENT,
            CONTENT_URL_ID: { taskEngineHoursDemand: 60 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'taskEngineHoursDemand',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.taskEngineHoursDemand',
                TRANSLATOR_DEFAULT: 'Task engine hours demand',
            },
        },

        //labour module
        LABOR_INDEX: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.LABOR,
            CONTENT_URL_ID: { laborIndex: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: `labor${PLANNING_CONSTANTS.ROUTE_KEY_EXTENSION}`,
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.labor',
                TRANSLATOR_DEFAULT: 'Labor',
            },
            IS_MENU_PAGE: true,
        },
        LABOR: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.LABOR,
            CONTENT_URL_ID: { labor: 21 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'labor',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.labor',
                TRANSLATOR_DEFAULT: 'Labor',
            },
        },
        POSITION: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.LABOR,
            CONTENT_URL_ID: { position: 53 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'position',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.position',
                TRANSLATOR_DEFAULT: 'Position',
            },
        },

        //controlling module
        CONTROLLING_INDEX: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CONTROLLING,
            CONTENT_URL_ID: { controllingIndex: 58 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: `controlling${PLANNING_CONSTANTS.ROUTE_KEY_EXTENSION}`,
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.controlling',
                TRANSLATOR_DEFAULT: 'Controlling',
            },
            IS_MENU_PAGE: true,
        },
        CATEGORIZATION_OF_CROP_PRODUCTION_TYPE: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.CONTROLLING,
            CONTENT_URL_ID: { categorizationOfCropProductionType: 59 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'categorizationOfCropProductionType',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.categorizationOfCropProductionType',
                TRANSLATOR_DEFAULT: 'Categorization of crop production type',
            },
        },

        //reports module
        REPORTS_INDEX: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.REPORTS,
            CONTENT_URL_ID: { reportsIndex: 23 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: `reports${PLANNING_CONSTANTS.ROUTE_KEY_EXTENSION}`,
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.reports',
                TRANSLATOR_DEFAULT: 'Reports',
            },
            IS_MENU_PAGE: true,
        },
        MATERIAL_NEED: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.REPORTS,
            CONTENT_URL_ID: { materialNeed: 28 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'materialNeed',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.materialNeed',
                TRANSLATOR_DEFAULT: 'Material need',
            },
        },
        GAS_OIL_NEED: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.REPORTS,
            CONTENT_URL_ID: { gasOilNeed: 29 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'gasOilNeed',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.gasOilNeed',
                TRANSLATOR_DEFAULT: 'Gas oil need',
            },
        },
        REQUIRED_WORKING_HOURS: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.REPORTS,
            CONTENT_URL_ID: { requiredWorkingHours: 30 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'requiredWorkingHours',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.requiredWorkingHours',
                TRANSLATOR_DEFAULT: 'Required working hours',
            },
        },
        MACHINE_HOURS_NEED: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.REPORTS,
            CONTENT_URL_ID: { machineHoursNeed: 31 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineHoursNeed',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineHoursNeed',
                TRANSLATOR_DEFAULT: 'Machine hours need',
            },
        },
        MACHINE_COST_PLAN_FACT: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.REPORTS,
            CONTENT_URL_ID: { machineCostPlanFact: 32 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineCostPlanFact',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineCostPlanFact',
                TRANSLATOR_DEFAULT: 'Machine cost plan fact',
            },
        },    
        ENGINE_HOURS_DEMAND: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.REPORTS,
            CONTENT_URL_ID: { engineHoursDemand: 50 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'engineHoursDemand',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.engineHoursNeed',
                TRANSLATOR_DEFAULT: 'Engine hours demand',
            },

        },
        CROP_ROTATION: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.REPORTS,
            CONTENT_URL_ID: { cropRotation: 57 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cropRotation',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.cropRotation',
                TRANSLATOR_DEFAULT: 'Crop rotation',
            },
        },
        MAP_INDEX: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.MAP,
            CONTENT_URL_ID: { mapIndex: 1 },
            COMPONENT_URL: 'contents/map/index',
            EVENT_KEY: `map${PLANNING_CONSTANTS.ROUTE_KEY_EXTENSION}`,
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.map',
                TRANSLATOR_DEFAULT: 'Map',
            },
            IS_MENU_PAGE: true,
        },
        SERVICE_NEED: {
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.REPORTS,
            CONTENT_URL_ID: { serviceNeed: 67 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'serviceNeed',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.serviceNeed',
                TRANSLATOR_DEFAULT: 'Service need',
            },
        },
    },
};
