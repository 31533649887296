import { useMapContext } from '@map/services/mapContext';
import useTranslate from '@i18n/useTranslate';
import { legendEntity, ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';
import { useEffect, useState } from 'react';
import { isEmpty, isEqual } from 'lodash';
import Svg from '@baseComponents/Svg';
import clsx from 'clsx';
import useLayerPanelStyles from './styles';

type Classes = 'container' | 'legendList' | 'paddedContainer'
    | 'headerMain' | 'headerName' | 'legendRow' | 'legendRange' | 'legendColorBox'
    | 'headerWrapper' | 'closePanelButton' | 'panelWrapper' | 'panelHidden' | 'rowWrapper' | 'visibleButton' | 'selectRow';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        // width: 280,
        height: 'min(300px, 40%)',
        maxHeight: '35vh',
        padding: [10, 15],
        paddingRight: 0,
        backgroundColor: theme.color.white,
        borderRadius: 6,
        boxShadow: theme.shadows.map,
        display: 'flex',
        flexDirection: 'column',
    },
    paddedContainer: {
        padding: 15,
    },
    legendList: {
        display: 'flex',
        flexDirection: 'column',
        fontSize: 14,
        color: theme.color.jet,
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
            width: 7,
            position: 'absolute',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            borderRadius: 7,
            background: 'rgba(0, 0, 0, 0.4)',
            position: 'absolute',
        },
        scrollbarWidth: 'thin !important',
        marginTop: 10,
    },
    headerMain: {
    },
    headerName: {
        fontSize: 16,
        // fontWeight: 700,
        color: theme.color.jet,
        textAlign: 'left',
        width: '100%',
    },
    legendRow: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        margin: [5, 0],

        '& > *:first-child': {
            marginRight: 10,
        },
    },
    legendColorBox: {
        width: 40,
        height: 20,
        fontSize: 10,
        fontWeight: 300,
        justifySelf: 'center',
        borderRadius: 4,
    },
    legendRange: {
        fontSize: 12,
        fontWeight: 300,
        verticalAlign: 'middle',
    },

    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 10,
        justifyContent: 'space-between',
    },
    closePanelButton: {
        width: 24,
        height: 24,
        cursor: 'pointer',
    },
    rowWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    visibleButton: {
        width: 24,
        height: 24,
        cursor: 'pointer',
        marginRight: 10,
    },
    panelWrapper: {
        display: 'flex',
        flexFlow: 'column',
        transition: '0.25s ease all',
        maxHeight: 400,
        overflowY: 'hidden',
    },
    panelHidden: {
        maxHeight: 0,
        transition: '0.25s ease all',
    },
    selectRow: {
        display: 'flex',
        justifyContent: 'flex-end',
        // justifyContent: 'space-between',
        width: '100%',
        paddingRight: 10,

        '& > *': {
            margin: [0, 4],
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            }
        }
    }
}));

export default function LegendPanel() {
    const [store, controller] = useMapContext();
    const { t, translater } = useTranslate();
    const classes = useStyles();
    const commonClasses = useLayerPanelStyles();

    const [isPanelOpen, setIsPanelOpen] = useState<boolean>(true);
    const [legendItems, setLegendItems] = useState<legendEntity[]>([]);


    useEffect(() => {
        if (!isEmpty(store.layering.order)) {
            const id = store.layering.order?.[0];
            const layerAttribute = store.layering.attributes?.find(attribute => attribute.id === id);

            if (layerAttribute) {
                setLegendItems(layerAttribute.legendList.map(l => ({ ...l, isVisible: true })));
            }
        }
    }, [store.layering.order, store.layering.layers, store.layering.attributes]);

    const handleOnPanelClose = () => {
        setIsPanelOpen(p => !p);
    };

    const handleVisibilityChange = (props: { legendItem?: legendEntity, isSelectAll?: boolean, isSelectNone?: boolean }) => {
        const { legendItem, isSelectAll, isSelectNone } = props;
        const id = store.layering.order?.[0];
        const layerAttribute = store.layering.attributes?.find(attribute => attribute.id === id);

        if (!layerAttribute) { return; };
        
        const opacity = (layerAttribute.opacity ?? 0) / 100;
        const visibleMarkers: google.maps.Marker[] = [];

        const newLegendItems = [...legendItems];
        newLegendItems.forEach(clegendItem => {
            if (legendItem) {
                if (isEqual(legendItem, clegendItem)) {
                    clegendItem.isVisible = !clegendItem.isVisible;
                }
            }

            if (isSelectAll) {
                clegendItem.isVisible = true;
            } else if (isSelectNone) {
                clegendItem.isVisible = false;
            }
        });

        newLegendItems.forEach(legend => {
            layerAttribute?.dataLayer.forEach(feature => {
                const legendId = feature.getProperty('legendId');

                if (legendId === legend.id) {
                    if (!legend.isVisible) {
                        layerAttribute.dataLayer.overrideStyle(feature, { fillOpacity: 0, strokeOpacity: 0 });
                    } else {
                        layerAttribute.dataLayer.overrideStyle(feature, { fillOpacity: opacity, strokeOpacity: 0.8 });
                    }

                    layerAttribute.labelLayer.forEach(marker => {
                        const geomId = marker.get('geomId');
                    
                        if (geomId === feature.getProperty('geomId')) {
                            marker.setOpacity(legend.isVisible ? 1 : 0);

                            if (legend.isVisible) {
                                visibleMarkers.push(marker);
                            }
                        }
                    })
                }
            });
        });

        if (store.layering.globalLabelLayerCluster) {
            store.layering.globalLabelLayerCluster.clearMarkers();
            store.layering.globalLabelLayerCluster.addMarkers(visibleMarkers);
            store.layering.globalLabelLayerCluster.render();
        }

        setLegendItems(newLegendItems);
    };

    return (
        <div className={classes.container}>
            <div className={classes.headerWrapper}>
                <span className={commonClasses.itemTitle}>{translater<string>('map.legendText', 'Legend')}</span>

                <Svg style={classes.closePanelButton} iconId={`icon-arrow_drop_${isPanelOpen ? 'up' : 'down'}`} onClick={handleOnPanelClose} />
            </div>

            <div className={clsx(classes.panelWrapper, !isPanelOpen && classes.panelHidden)}>

                <div className={classes.selectRow}>
                    <span onClick={() => handleVisibilityChange({ isSelectAll: true })}>{t('default.all', 'All')}</span>
                    <span onClick={() => handleVisibilityChange({ isSelectNone: true })}>{t('default.nothing', 'None')}</span>
                </div>

                <div className={classes.legendList}>
                    {
                        legendItems?.map((legendItem, index) => (
                            <div className={classes.rowWrapper} key={`legenditem_${index}`}>
                                    <div className={classes.legendRow}>
                                        <div className={classes.legendColorBox} style={{ background: legendItem.fillColor }} />
                                        <div className={classes.legendRange}> {legendItem.range} </div>
                                    </div>
                                    <Svg onClick={() => handleVisibilityChange({ legendItem })} style={classes.visibleButton} iconId={legendItem.isVisible ? 'icon-visibility_show' : 'icon-visibility_hide'} />
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}
