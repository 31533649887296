import { useRef, useState, useCallback, useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { isFunction, pick, uniqueId } from 'lodash';
import Button from '@baseComponents/buttons/Button';
import UpdatingPopover from './UpdatingPopover';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        position: 'relative',
        display: 'inline-flex',
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    popover: {
        zIndex: theme.zIndex.popover,
        boxShadow: theme.shadows.dropdown,
        backgroundColor: theme.color.white,
        borderRadius: 6,
        border: 'none',
        // padding: 20,
        minWidth: 300,
        '& .popover-body': {
            padding: 0,
        },
        '& input': {
            fontSize: 14,
        },
        '& .dropdown-item': {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 15px',
            minWidth: 250,
            fontSize: '15px',
            fontWeight: 'normal',
            lineHeight: '1.33',
            color: theme.color.jet,
            outline: 'none',
            '&:hover': {
                backgroundColor: theme.color.opacity.main,
                color: theme.color.main,
                cursor: 'pointer',
                '& > menuItemIcon': {
                    fill: theme.color.main,
                },
            },
            '& .disabled': {
                opacity: 1,
                backgroundColor: theme.color.white,
                color: theme.color.gray,
                '&:hover': {
                    backgroundColor: theme.color.white,
                    cursor: 'default',
                    color: theme.color.gray,
                },
            },
            '&:first-child': {
                '&:hover': {
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                },
            },
            '&:last-child': {
                '&:hover': {
                    borderBottomLeftRadius: 6,
                    borderBottomRightRadius: 6,
                },
            },
        },
    },
    mainButton: {
        marginRight: -1,
    },
    splitButton: {
        marginLeft: -1,

        '&>div': {
            padding: 0,
        },
    },
}));

const SplitButton = props => {
    const { className, renderDropdownContent, onToggle } = props;
    const { label, iconId, iconPosition, onClick, disabled = false } = props;
    const { splitLabel, splitIconId, splitDisabled = false } = props;
    const { tooltipDataEvent, tooltipDataFor } = props;
    const { children, ignoreDropdownIcon, title } = props;
    const commonButtonProps = pick(props, ['type', 'invalid', 'size', 'iconColor', 'fill', 'ignoreOtherClick', 'disabledPointerEvents', 'disabledOnClick']);

    const splitButtonRef = useRef<any>(null);
    const popOverRef = useRef();
    const [, setShowDropdown] = useState(false);
    const classes = useStyles();

    const splitButtonUUID = useMemo(() => uniqueId('splitButton'), []);

    const closeDropdown = useCallback(() => {
        setShowDropdown(false);
        const split = document.querySelector(`#${splitButtonUUID} .split`);
        // hack for popover so the close prop method works if called from it
        (split as any)?.click();
    }, [splitButtonUUID]);

    const update = () => (() => (popOverRef.current as any)?.popper?.scheduleUpdate())();
    const splitButtonElement = splitButtonRef.current;

    const togglePopOver = useCallback(() => setShowDropdown(current => !current), []);

    const closeAndResetDropdown = useCallback(() => {
        closeDropdown();
        if (isFunction(onToggle)) {
            onToggle();
        }
    }, [closeDropdown, onToggle]);

    const openAndResetDropdown = useCallback(() => {
        if (isFunction(onToggle)) {
            onToggle();
        }
        togglePopOver();
    }, [onToggle, togglePopOver]);

    return (
        <div className={clsx(classes.container, className)} id={splitButtonUUID} ref={splitButtonRef} title={title}>
            {
                (!!label || !!iconId)
                && (
                    <Button
                        disabled={disabled}
                        iconId={iconId}
                        iconPosition={iconPosition}
                        onClick={onClick}
                        className={classes.mainButton}
                        tooltipDataEvent={tooltipDataEvent}
                        tooltipDataFor={tooltipDataFor}
                        // disabledOnClick={disabledOnClick}  // should the split be disabled when this prop is true?
                        {...commonButtonProps}
                    >
                        {label}
                    </Button>
                )
            }
            <OverlayTrigger
                trigger="click"
                rootClose
                placement="bottom"
                overlay={
                    <UpdatingPopover className={classes.popover} forwardedRef={popOverRef}>
                        <Popover.Content>
                            {
                                isFunction(renderDropdownContent)
                                && renderDropdownContent({ close: closeAndResetDropdown, splitButtonElement, update })
                            }
                        </Popover.Content>
                    </UpdatingPopover>
                }
            >
                <Button
                    iconId={!splitIconId && !splitLabel && !ignoreDropdownIcon ? 'icon-arrow_drop_down' : splitIconId}
                    disabled={splitDisabled}
                    onClick={openAndResetDropdown}
                    className={clsx(classes.splitButton, 'split')}
                    {...commonButtonProps}
                >
                    {splitLabel || children}
                </Button>
            </OverlayTrigger>
        </div>
    );
};
export default SplitButton;
