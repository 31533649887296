import React from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty, isNumber } from 'lodash';
import { useFormikContext } from 'formik';
import useTranslate from '@i18n/useTranslate';
import SupportOverall from './SupportOverall';
import SupportData from './SupportData';
import DataInput from '../../baseComponents/inputs/DataInput';
import useLoadSupportData from './useLoadSupportData';
import { LoadSpinner } from '../../baseComponents/loadSpinner';

const useStyles = createUseStyles({
    supportContainer: {
        position: 'relative',
    },
    marginTop: {
        marginTop: 20,
    },
});

export default function AddSupport(props) {
    const { supportExpanded, closeSupport, toggle, isEdit, loadedSupportList } = props;
    const { t } = useTranslate();
    const { values } = useFormikContext();
    const classes = useStyles();

    useLoadSupportData({ loadedSupportList });

    function toggleSupport(state) {
        const windowContent = {
            component: SupportData,
            props: {
                close: closeSupport,
            },
        };
        toggle(state && (!isEmpty(values.periodYear) || isNumber(values.periodYear)) && windowContent);
    }

    return (
        <div className={classes.supportContainer}>
            { isEdit && <LoadSpinner value={loadedSupportList} isAutoDetect />}
            <DataInput
                title={t('newCultivationPeriod.support', 'Support')}
                label={t('newCultivationPeriod.support', 'Support')}
                className={classes.marginTop}
                onClick={() => toggleSupport(!supportExpanded)}
            >
                {!isEmpty(values.support) && (
                    <SupportOverall support={values.support} />
                )}
            </DataInput>
        </div>
    );
}
