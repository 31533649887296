import React from 'react';
import { createUseStyles } from 'react-jss';
import Radio from '@baseComponents/controls/Radio';
import { range } from 'lodash';
import Label from '@baseComponents/formParts/Label';
import useTranslate from '@i18n/useTranslate';
import { CULTIVATION_PERIODS } from 'shared/src/constants';
import QualificationInfo from './QualificationInfo';

const useStyles = createUseStyles((theme: any) => ({
    // label: {
    //     marginTop: 15,
    //     display: 'inline-block',
    // },
    qualificationContainer: {
        display: 'flex',
        marginTop: 40,
    },
    qualification: {
        margin: 'auto',
        paddingLeft: '0px !important',
        '& .radioCheckmark': {
            top: -25,
            left: -5,
        },
    },
    label: {
        marginTop: 15,
        display: 'flex',
        alignItems: 'flex-start',
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: (props: any) => (props.qualification ? theme.color.main : theme.color.destructive),
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        color: theme.color.destructive,
    },
}));

export default function Qualification(props) {
    const { qualification, setQualification, errorMessage, required } = props;
    const { t } = useTranslate();
    const classes = useStyles({ qualification });

    function onChangeQualification(event) {
        const value = +event.target.value;
        setQualification(value);
    }

    return (
        <>
            <div className={classes.label}>
                <Label label={t('cultivationPeriod.cultivationPeriodQualification', 'Qualification of the cultivation period')} />
                {!!required && <span className={classes.dot} />}
                <QualificationInfo />
            </div>

            <div className={classes.qualificationContainer}>
                {range(CULTIVATION_PERIODS.QUALIFICATION.VERY_BAD, CULTIVATION_PERIODS.QUALIFICATION.TOP).map(index => (
                    <Radio
                        key={index}
                        group="qualification"
                        text={index}
                        value={index}
                        checked={qualification === index}
                        onChange={onChangeQualification}
                        className={classes.qualification}
                    />
                ))}
            </div>
            <div className={classes.errorMessage}>
                {errorMessage}
            </div>
        </>
    );
}
