import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    textareaContainer: {
        marginTop: 40,
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    textarea: {
        width: '100%',
        height: 60,
        borderRadius: 4,
        border: '1px solid',
        borderColor: theme.color.gray,
        resize: 'none',
        padding: 5,
        outline: 'none',
        fontSize: 15,
        fontWeight: 300,
        boxShadow: 'none',
        '&:focus': {
            boxShadow: theme.shadows.primary.focus,
            border: `1px solid ${theme.color.main}`,
        },
    },
}));

export default function Textarea(props) {
    const { label, value, defaultValue, handleChange, onBlur, name, className, isDisabled } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.textareaContainer, 'textareaContainer', className)}>
            {!isEmpty(label) && (
                <div className={classes.label}>
                    {label}
                </div>
            )}
            <textarea
                name={name}
                className={classes.textarea}
                value={value}
                onChange={handleChange}
                disabled={isDisabled}
                onBlur={onBlur}
                defaultValue={defaultValue}
            />
        </div>
    );
}
