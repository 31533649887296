/* eslint-disable react-hooks/exhaustive-deps */
import { SimpleSelect } from '@baseComponents/select';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import { ThemeType } from '@map/services/mapTypes';
import { get, isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { INTERFACE_FILENAME } from 'shared/src/constants';
import MapFilterBase from './MapFilterBase';
import { MapFilterSchema, MAPFILTER_KEY } from './MapFilterSchema';

type Classes = 'datePeriodFilterContainer' | 'title' | 'titleContainer' | 'dateRangefilter' | 'dateRangeSelectContainer';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    datePeriodFilterContainer: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        // height: props => !props.isTagView && 170,
        overflow: 'visible',
        height: '100%',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .title': {
            maxWidth: 140,
            marginBottom: 15,
            fontSize: 14,
            fontWeight: 500,
            color: theme.color.raven,
            letterSpacing: 0,
            lineHeight: '20px',
        },
    },
    title: {
        marginBottom: 15,
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
    },
    dateRangefilter: {
        width: '100%',
    },
    dateRangeSelectContainer: {
        paddingTop: 15,
        '& .select': {
            width: 199,
        },
    },
}));

type TProps = {
    filterSubmit: (formData: MapFilterSchema) => void
}

export default function MapFilterProdYear({ filterSubmit }: TProps) {
    const periodYears = useSelector((state: any) => state.dataLists.productionYears);
    const classes = useStyles();
    const [dataList, setDataList] = useState(periodYears?.map(period => ({ key: period.id, value: period.period })));

    const filterContext = useFormContext<MapFilterSchema>();

    const { translater } = useTranslate();

    const [store] = useMapContext();
    const interfaceSettings = useSelector((state: any) => state.user.user.settings.interfaceSettings) ?? null;

    useEffect(() => {
        if (isEmpty(periodYears)) {
            return;
        }

        if (isNil(store.mapId)) {
            return;
        }

        const years = periodYears?.map(year => ({ key: year.id, value: year.period }));
        const sortedYears = periodYears?.sort((yearA, yearB) => yearB.year - yearA.year);
        const defYear = sortedYears?.[0];

        if (years) {
            setDataList(years);
        }
        if (defYear) {
            setDataList(years);
            filterContext.setValue('productionYearId', defYear.id);
        }

        const interfaceFileName = `${INTERFACE_FILENAME.MAP}_${store.mapId}_FILTER_${window.location.pathname}`;
        const interfaceSettingsValue = interfaceSettings?.[interfaceFileName]?.mapSettings?.value ?? null;

        if (!isEmpty(interfaceSettingsValue) && !isNil(interfaceSettingsValue)) {
            const parsedMapSettings: MapFilterSchema = JSON.parse(interfaceSettingsValue);

            const settingKeys = Object.keys(parsedMapSettings);
            settingKeys.forEach(settingKey => {
                const filterKey = settingKey as MAPFILTER_KEY;
                filterContext.setValue(filterKey, get(parsedMapSettings, filterKey));
            });
        }

        if (store.mapId !== 1) {
            const interfaceFileNameMap1 = `${INTERFACE_FILENAME.MAP}_1_FILTER_${window.location.pathname}`;
            const interfaceSettingsValueMap1 = interfaceSettings?.[interfaceFileNameMap1]?.mapSettings?.value ?? null;

            if (!isEmpty(interfaceSettingsValue) && !isNil(interfaceSettingsValue)) {
                const parsedMapSettings: MapFilterSchema = JSON.parse(interfaceSettingsValueMap1);
                const prodYearMap1 = parsedMapSettings.productionYearId;
                if (!isNil(prodYearMap1)) {
                    filterContext.setValue('productionYearId', prodYearMap1);
                }
            }
        }

        filterContext.handleSubmit(filterSubmit)();
    }, [periodYears, store.mapId]);

    const updateInterface = useCallback(yearId => {
        if (!yearId || Number(yearId) === 0) {
            return;
        }

        filterContext.setValue('productionYearId', Number(yearId));
    }, []);

    return (
        <MapFilterBase>
            <div className={classes.datePeriodFilterContainer}>
                <div className={classes.titleContainer}>
                    <div className={classes.title}>
                        {translater<string>('map.filterProductionYear', 'Production Year')}
                    </div>
                </div>
                <Controller
                    control={filterContext.control}
                    name="productionYearId"
                    render={field => (
                        <SimpleSelect
                            iconId="icon-calendar"
                            alignRight
                            className={classes.dateRangefilter}
                            selected={field.field.value}
                            dataList={dataList}
                            onChange={updateInterface}
                        />
                    )}
                />
            </div>
        </MapFilterBase>

    );
}
