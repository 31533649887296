import { useMapContext } from '@map/services/mapContext';
import { isNil } from 'lodash';
import { useCallback } from 'react';
import BackToGeomComponent from './BackToGeomComponent';

type Tprops = {
    zoomEntityBBox?: google.maps.LatLngBounds,
}

export default function BackToGeomContainer({ zoomEntityBBox }: Tprops) {
    const [store] = useMapContext();

    const onBackToGeomClick = useCallback(() => {
        if (!isNil(zoomEntityBBox)) {
            store.googleRefs.map?.fitBounds(zoomEntityBBox);
        }
    }, [store.googleRefs.map, zoomEntityBBox]);

    return (
        <BackToGeomComponent onClick={onBackToGeomClick} />
    );
}
