import { useEffect } from 'react';

import { createUseStyles } from 'react-jss';

const useStyle = createUseStyles((theme: any) => ({
    mouseDraggable: {
        position: 'relative',
        overflowX: 'auto',
        overflowY: 'hidden',
        width: '100%',
        whiteSpace: 'nowrap',
        transition: 'all 0.2s',
        transform: 'scaleY(1)',
        willChange: 'transform',
        userSelect: 'none',
        cursor: 'pointer',

        MsOverflowStyle: 'none', // hide scrollbar - IE and Edge
        scrollbarWidth: 'none', // hide scrollbar - Firefox

        '&::-webkit-scrollbar': { // hide scrollbar - Chrome, Safari and Opera
            display: 'none',
        },

        '&.active': {
            cursor: 'grabbing',
        },
    },
    horizontalScrollBar: {
        ...theme.horizontalScrollbar,
    },
}));

export default function useHorizontalMouseDrag({ draggableRef, mouseLeaveWrapperRef, displayScrollBar, onSelectRow }) {
    const classes = useStyle();

    useEffect(() => {
        if (!draggableRef?.current || !mouseLeaveWrapperRef.current) { return; }

        const slider = draggableRef?.current;
        const wrapper = mouseLeaveWrapperRef?.current;
        let isDown = false;
        let startX;
        let scrollLeft;

        const handleMouseDown = event => {
            if (event.target.tagName === 'INPUT') {
                return;
            }

            if (!displayScrollBar && onSelectRow) { //header input handler
                event.preventDefault();
            }
            isDown = true;
            slider.classList.add('active');
            startX = event.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        };

        const handleMouseleave = () => {
            isDown = false;
            slider.classList.remove('active');
        };

        const handleMouseup = event => {
            isDown = false;
            slider.classList.remove('active');

            //ignore onSelectRow function
            const x = event.pageX - slider.offsetLeft;
            const walk = (x - startX) * 1;

            if (walk) {
                event.preventDefault();
                event.stopPropagation();
            }
        };

        const handleMousemove = event => {
            if (!isDown) { return; }
            event.preventDefault();
            // TODO: use requestAnimationFrame
            const x = event.pageX - slider.offsetLeft;
            const walk = (x - startX) * 1;
            slider.scrollLeft = scrollLeft - walk;
        };

        wrapper.addEventListener('mouseup', handleMouseup);
        slider.addEventListener('click', handleMouseup);
        wrapper.addEventListener('mouseleave', handleMouseleave);
        slider.addEventListener('mousedown', handleMouseDown);
        slider.addEventListener('mousemove', handleMousemove);

        return () => {
            slider.removeEventListener('mousedown', handleMouseDown);
            slider.removeEventListener('mousemove', handleMousemove);
            wrapper.removeEventListener('mouseleave', handleMouseleave);
            wrapper.removeEventListener('mouseup', handleMouseup);
            slider.removeEventListener('click', handleMouseup);
        };
    }, [draggableRef, mouseLeaveWrapperRef, displayScrollBar]);

    return {
        classes,
    };
}
