export const SCOUTING = {
    CATEGORY: {
        GENERAL: 1,
        PEST: 2,
        WEED: 3,
        INFECTION: 4,
    },
    PRIORITY: {
        NO: 1,
        LOW: 2,
        MIDDLE: 3,
        HIGH: 4,
    },
    TASK_STATUS: {
        NOT_CHECKED: 1,
        CHECKED: 2,
        COMPLETED: 3,
    },
    SCOUTING_RECORD_TAB: {
        CULTIVATION_PERIODS: 1,
        CATEGORIES: 2,
        FILES: 3,
        MATERIALS: 4,
    },
    TASK_STATUS: {
        NOT_CHECKED: 1,
        CHECKED: 2,
        UNBOUND: 3,
    },
};
