import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { DropdownWrapper, EmptyDropdownToggleButton, DropdownMenu } from '../../../select';
import Svg from '../../../Svg';
import ColumnOrderContent from './ColumnOrderContent';
import useTableColumnOrderDataLoader from './useTableColumnOrderDataLoader';

const useStyles = createUseStyles((theme: any) => ({
    dropdownWrapper: {
        position: 'absolute',
        right: 80,
        top: 5,
    },
    dropdownButton: {
        '&.btn-primary.dropdown-toggle': {
            minWidth: 36,
            maxWidth: 36,
            width: 36,
            height: 36,
            borderRadius: '50%',
            border: `1px solid ${theme.color.gray}`,
        },
        '& .btn-primary': {
            border: '1px solid',
        },
    },
    orderIcon: {
        verticalAlign: 'middle',
        width: 20,
        height: 20,
        fill: theme.color.jet,
        display: 'block',
        margin: 'auto',
    },
    dropdownMenu: {
        maxHeight: 500,
        overflow: 'hidden',
        width: 300,
        height: 'min-content',
    },
}));

export default function TableColumnOrder(props) {
    const { columns } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const { onToggleOrder, columnsLayout } = useTableColumnOrderDataLoader({ columns });

    return (
        <DropdownWrapper className={classes.dropdownWrapper} dropDirection="left" title={t('baseTable.sortColumns', 'Sorting columns')} onToggle={onToggleOrder}>
            <EmptyDropdownToggleButton className={classes.dropdownButton}>
                <Svg iconId="icon-drag_drop" style={classes.orderIcon} />
            </EmptyDropdownToggleButton>

            <DropdownMenu className={classes.dropdownMenu}>
                <ColumnOrderContent columnsLayout={columnsLayout} columns={columns} />
            </DropdownMenu>
        </DropdownWrapper>
    );
}
