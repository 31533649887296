import { createLabel } from '@map/utils/mapLabelUtils';
import { clone, isNumber } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { rowFunctions } from '@map/components/LayerCreateModal/LayerUploadModalPair/LayerUploadModalPairFeatureListTable/rowFunctions';
import { TMapStore } from '@map/services/mapStore';
import { LayerUploadModalPairMapProps, TMarkers } from './LayerUploadModalPairMap';

export default function useLayerUploadModalPairData(props: LayerUploadModalPairMapProps) {
    const { store, controller, currentModalComponent, pairableEntityIds, currentModalComponentProps, doAlert } = props;
    const { selectedLayerId } = store.upload;

    const [markers, setMarkers] = useState<TMarkers>({});
    const [isMounted, setIsMounted] = useState(false);

    const [mapStore, setMapStore] = useState<TMapStore>();

    const excludedEntityIdsRef = useRef([]);
    const pairableEntitiesRef = useRef(store.upload.pairableEntities);
    const markersRef = useRef(markers);
    const isMountedRef = useRef(isMounted);
    const [refresh, setRefresh] = useState(-1);

    useEffect(() => {
        isMountedRef.current = isMounted;
    }, [isMounted]);

    // useEffect(() => {
    //     excludedEntityIdsRef.current = excludedEntityIds;
    // }, [excludedEntityIds]);

    useEffect(() => {
        markersRef.current = markers;
    }, [markers]);

    useEffect(() => {
        pairableEntitiesRef.current = store.upload.pairableEntities;
    }, [store.upload.pairableEntities]);

    useEffect(() => {
        const map = mapStore?.googleRefs.map;

        if (!map) {
            return;
        }

        const pairedEntities = pairableEntitiesRef.current?.filter(entity => isNumber(entity.entityId));
        const currentMarkers = clone(markersRef.current);

        map?.data?.forEach(feature => {
            const featureId = feature.getId();

            map?.data.revertStyle(feature);

            if (!isNumber(featureId)) {
                return;
            }

            if (currentMarkers?.[featureId]) {
                currentMarkers[featureId]?.setMap(null);
            }

            const bounds = new google.maps.LatLngBounds();

            const pairedEntity = pairedEntities?.find(entity => entity?.featureId === featureId);

            if (!pairedEntity) {
                return;
            }

            feature?.getGeometry()?.forEachLatLng(latLng => {
                bounds.extend(latLng);
            });

            const properties: any = pairedEntity?.properties;
            const cultureColorCode = properties?.cultureColorCode;

            if (cultureColorCode) {
                map.data.overrideStyle(feature, { fillColor: cultureColorCode, zIndex: 99999, strokeColor: cultureColorCode });
            }

            if (!currentMarkers?.[featureId] || !currentMarkers?.[featureId]?.getMap()) {
                const marker = createLabel(bounds.getCenter(), pairedEntity?.properties?.name, map);
                currentMarkers[featureId] = marker;
            }
        });
        setMarkers(currentMarkers);
    }, [mapStore?.layering.attributes, mapStore?.googleRefs, refresh, pairableEntityIds]);

    function handleFeatureClick(event, map: google.maps.Map): google.maps.MapsEventListener {
        const feature = event?.feature as google.maps.Data.Feature;
        const featureId = feature?.getId();
        const currentMarkers = clone(markersRef.current);

        function onSelectChange(selectedData) {
            // const bounds = new google.maps.LatLngBounds();

            // feature.getGeometry()?.forEachLatLng(latLng => bounds.extend(latLng));

            // const cultureColorCode = selectedData?.cultureColorCode;

            // if (cultureColorCode) {
            //     map?.data.overrideStyle(feature, { fillColor: cultureColorCode });
            //     feature.setProperty('fillColor', cultureColorCode);
            // }

            // const marker = createLabel(bounds.getCenter(), selectedData?.name, map);

            // if (isNumber(featureId) && currentMarkers) {
            //     currentMarkers[featureId] = marker;
            //     setMarkers(currentMarkers);
            // }
        }

        if (feature) {
            if (!isNumber(featureId)) {
                return event;
            }

            const pairedEntity = pairableEntitiesRef.current?.find(entity => entity.featureId === featureId);

            if (!pairedEntity) {
                rowFunctions.openEntityPairingModal({
                    controller,
                    rowData: { id: featureId },
                    modalComponent: currentModalComponent,
                    pairableEntityIds,
                    modalComponentProps: { ...(currentModalComponentProps || {}), excludedIds: [] },
                    doAlert,
                    onSelectChange,
                });
            } else {
                controller.unpairFeature(featureId);

                if (currentMarkers && isNumber(featureId)) {
                    map?.data.revertStyle(feature);

                    currentMarkers[featureId]?.setMap(null);
                    setMarkers(currentMarkers);
                }
                setRefresh(p => p + 1);
            }
        }
        return event;
    }

    const dataEventListeners = useMemo(() => [
        {
            eventName: 'click',
            eventHandler: handleFeatureClick,
        },
    ], []);

    return {
        dataEventListeners,
        setMapStore,
    };
}
