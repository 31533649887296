import React, { useState, useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNil, isEmpty } from 'lodash';
import { Formik } from 'formik';
import { SETTINGS } from 'shared/src/constants';
import { arrayUtils } from 'shared/src/modules';
import { companyActions } from '@states/actions/companyActions';
import renderModal from '@modals/modalNew/modalFunctions';
import ConfirmDialog from '@baseComponents/modals/ConfirmDialog';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { alertActions } from '@states/actions';
import Map from '@map/screens/Map';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import NewCultivationPeriodForm from './NewCultivationPeriodForm';
import useNewCultivationPeriodSave from './useNewCultivationPeriodSave';
import useNewCultivationPeriodValidation from './useNewCultivationPeriodValidation';
import { newCultivationPeriodInitialValues } from './newCultivationPeriodInitialValues';
import useLoadEditCultivationPeriodValues from './useLoadCultivationPeriodValues';

const useStyles = createUseStyles((theme: any) => ({
    periodsModal: {
        height: 'calc(100vh - 80px)',
        width: 'calc(100vw - 120px)',
        display: 'flex',
        position: 'relative',
        overflow: 'auto hidden',
    },
    periodsModalLeft: {
        width: 380,
        height: '100%',
        padding: [25, 0],
        display: 'flex',
        flexFlow: 'column',
    },
    periodsModalRight: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.color.map.background,
        borderBottomRightRadius: 12,
        position: 'relative',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        lineHeight: 'normal !important',
        color: theme.color.jet,
        ...theme.overFlowText,
        boxShadow: theme.shadows.header,
        borderBottom: `1px solid ${theme.color.lilian}`,
        paddingLeft: 30,
        paddingBottom: 22,
    },
    fade: {
        zIndex: theme.zIndex.fade,
        display: 'block',
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        opacity: 0.8,
        outline: 'none',
        backgroundColor: theme.color.jet,
        borderBottomRightRadius: 12,
    },
}));

export default function NewCultivationPeriod(props) {
    const { t } = useTranslation();
    const { hideModal, cultivationPeriodId, setPeriodYearFilter, isReloadDisabled, onAddNewPeriod } = props;
    const periodYearId = useSelector((state: any) => state.basePage.currentFilters?.periodYearFilter || state.tableFilter.currentFilters?.productionYearId || 0);
    const cropTypes = useSelector((state: any) => state.dataLists.cropTypes);
    const [isEdit] = useState(!!cultivationPeriodId);
    const [loadPeriodValues, setLoadPeriodValues] = useState(true);
    const [title, setTitle] = useState(t('cultivationPeriods.newCultivationPeriod', 'New cultivation period'));
    const [formInitialValues, setFormInitialValues] = useState({ ...newCultivationPeriodInitialValues });
    const { cultivationPeriodValues, setCultivationPeriodValues } = useLoadEditCultivationPeriodValues({ periodYearId, cultivationPeriodId, setLoadPeriodValues, setTitle, setFormInitialValues });
    const dispatch = useDispatch();
    const [usingMap] = useState(dispatch(companyActions.getSetting(SETTINGS.USING_MAP)));
    const [geometry, setGeometry] = useState(false);
    const [isGeometryEdited, setIsGeometryEdited] = useState(false);
    const [geomId, setGeomId] = useState(false);
    const [isFadeOn, setIsFadeOn] = useState(false);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);
    const [isQulificationRequired] = useState(dispatch(companyActions.getSetting(SETTINGS.REQUIRED_PERIOD_QUALIFICATION)));
    const { validationSchema } = useNewCultivationPeriodValidation({ isQulificationRequired });
    const { save } = useNewCultivationPeriodSave({ cultivationPeriodValues, closeModal: hideModal, setIsSaveBtnDisabled, setPeriodYearFilter, cultivationPeriodId, isEdit, isReloadDisabled, geometry, geomId, isGeometryEdited, onAddNewPeriod });
    const classes = useStyles();

    useEffect(() => {
        if (!isEmpty(cropTypes) && !isEmpty(newCultivationPeriodInitialValues)) {
            const cropType = cropTypes.find(cropTypeObj => cropTypeObj.id === newCultivationPeriodInitialValues.cropType);
            if (cropType) {
                newCultivationPeriodInitialValues.crop.typeName = cropType.name;
            }
        }
    }, [cropTypes]);

    function closeFade() {
        setIsFadeOn(false);
    }

    const [mapPeriodYear, setMapPeriodYear] = useState(Number(periodYearId));

    const geomInitialized = useRef(false);

    function featureUpdated(feature) {
        if (!isNil(feature) && !isNil(feature.geometry)) {
            if (isNil(feature.id)) {
                setIsGeometryEdited(true);
            } else if (geomInitialized.current === true) {
                setIsGeometryEdited(true);
            } else {
                geomInitialized.current = true;
            }
            setGeometry(feature.geometry);
        } else {
            setGeometry(null);
        }
    }

    const panelSelectorFilter = {
        attributeFilter: {
            whiteList: [
                {
                    layerId: BuiltInLayerTypes.CULTIVATION_PERIOD,
                    attributeKey: 'name',
                },
                {
                    layerId: BuiltInLayerTypes.CULTIVATION_PERIOD,
                    attributeKey: 'culture',
                },
                {
                    layerId: BuiltInLayerTypes.CULTIVATION_PERIOD,
                    attributeKey: 'type',
                },
                {
                    layerId: BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP,
                    attributeKey: 'name',
                },
                {
                    layerId: BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP,
                    attributeKey: 'culture',
                },
                {
                    layerId: BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP,
                    attributeKey: 'type',
                },
            ],
        },
        layerFilter: {
            blackList: [BuiltInLayerTypes.MACHINES],
        },
    };

    return (
        <div className={classes.periodsModal}>
            {cultivationPeriodId && <LoadSpinner everyValues={[cultivationPeriodValues, !loadPeriodValues]} isAutoDetect />}
            {(cultivationPeriodValues || !loadPeriodValues)
                && (
                    <Formik
                        initialValues={formInitialValues}
                        onSubmit={(value, action) => {
                            const supportSumArea = arrayUtils.getSumByKeyString(value.support, 'area');
                            const isBasicFieldAreaEmpty = value.basicFields.find(basicField => !basicField.categorizedArea);
                            if (isBasicFieldAreaEmpty) {
                                dispatch(alertActions.warningAlert(t('cultivationPeriods.categorizedAreaSize', 'The basic field size of the categorized have to be higher than 0!')));
                            } else if (!isEmpty(value.support) && Number(value.sownArea) < supportSumArea) {
                                setIsSaveBtnDisabled(true);
                                renderModal(ConfirmDialog, {
                                    descriptionText: t('editCultivationPeriod.subsidiesHectareExceedPeriodHectare', 'The area of the subsidies exceeds the area of the cultivation period.'),
                                    iconId: 'icon-undefined',
                                    isAcceptCancelDialog: true,
                                    confirmCallback: () => save(value, action),
                                }, false, () => setIsSaveBtnDisabled(false));
                            } else {
                                setIsSaveBtnDisabled(true);
                                save(value, action);
                            }
                        }}
                        validationSchema={validationSchema}
                        validateOnChange={formIsDirty}
                    >
                        <>
                            <div className={classes.periodsModalLeft}>
                                <div className={classes.title}>
                                    {title}
                                </div>

                                <NewCultivationPeriodForm
                                    periodYearId={mapPeriodYear}
                                    cultivationPeriodId={cultivationPeriodId}
                                    isSaveBtnDisabled={isSaveBtnDisabled}
                                    setFormIsDirty={setFormIsDirty}
                                    setIsFadeOn={setIsFadeOn}
                                    isFadeOn={isFadeOn}
                                    isEdit={isEdit}
                                    setCultivationPeriodValues={setCultivationPeriodValues}
                                    formInitialValues={formInitialValues}
                                    setFormInitialValues={setFormInitialValues}
                                    geometry={geometry}
                                    isQulificationRequired={isQulificationRequired}
                                    isGeometryEdited={isGeometryEdited}
                                    onPeriodYearChange={setMapPeriodYear}
                                />

                            </div>

                            <div className={classes.periodsModalRight}>
                                {isFadeOn && <div className={classes.fade} onClick={closeFade} aria-hidden="true" role="button" tabIndex={0} />}
                                {usingMap && (
                                    <Map
                                        withBackToCenterButton={false}
                                        withMapStyleModifier
                                        withInfoPanel
                                        withMachinePanel
                                        withDrawing
                                        withLayerPanel
                                        withMeasureTool={false}
                                        withCurrencySwitch={false}
                                        withMapSearch={false}
                                        defaultMapFilter={{
                                            productionYearId: mapPeriodYear,
                                        }}
                                        entityId={cultivationPeriodValues?.id}
                                        parentId={cultivationPeriodValues?.parentId}
                                        setSavedGeomFeature={featureUpdated}
                                        setSavedGeomId={setGeomId}
                                        layerPanelOptions={{
                                            withLayerPanel: true,
                                        }}
                                        layerSelectorSettings={panelSelectorFilter}
                                    />
                                )}
                            </div>
                        </>
                    </Formik>
                )
            }
        </div>
    );
}
