import { routerHelper } from '@utils';
import { sendServerRequest } from './baseService';

async function getAggregates() {
    const response = await sendServerRequest('GET', '/api/aggregates');
    return response;
}

async function getWindDirections() {
    const response = await sendServerRequest('GET', '/api/windDirections');
    return response;
}

async function getCloudTypes() {
    const response = await sendServerRequest('GET', '/api/cloudTypes');
    return response;
}

async function getCloudCoverages() {
    const response = await sendServerRequest('GET', '/api/cloudCoverages');
    return response;
}

async function getLocations() {
    const response = await sendServerRequest('GET', '/api/locations');
    return response;
}

async function getWindForces() {
    const response = await sendServerRequest('GET', '/api/windForces');
    return response;
}

async function setMeteorology(meteorology) {
    const response = await sendServerRequest('POST', '/api/meteorology', meteorology);
    return response;
}

async function deleteMeteorology(meteorologyId) {
    const response = await sendServerRequest('DELETE', `/api/meteorology/${meteorologyId}`);
    return response;
}

/**
 * Get all meteorology data
 * @property {date} startDate IsRequired - Filter by startDate eg.: 1
 * @property {date} endDate IsRequired - Filter by endDate eg.: 1
 * @property {int} locationId IOptional - Filter by locationId eg.: 1
 *
 * @return {array} meteorology data array
 */
async function getMeteorologyList(props) {
    const url = routerHelper.getUrlWithParameters('/api/meteorology/list', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getTemperatureUnits() {
    const response = await sendServerRequest('GET', '/api/temperatureUnits');
    return response;
}

async function getAtmosphericPressureUnits() {
    const response = await sendServerRequest('GET', '/api/atmosphericPressureUnits');
    return response;
}

export const meteorologyService = {
    getAggregates,
    getWindDirections,
    getCloudTypes,
    getCloudCoverages,
    getLocations,
    getWindForces,
    setMeteorology,
    deleteMeteorology,
    getMeteorologyList,
    getTemperatureUnits,
    getAtmosphericPressureUnits,
};
