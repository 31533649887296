import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { TABLE } from 'shared/src/constants';
import useLoadMultiLineViewType from '@customComponents/multilineView/useLoadMultiLineViewType';
import { Weight, WeightPerArea } from '@baseComponents/formatters';

const useStyles = createUseStyles((theme: any) => ({
    weightPerAreaCellWrapper: {
        height: '100%',
        width: '100%',
        fontSize: 12,
        fontWeight: 'normal',
        color: theme.color.raven,
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function WeightPerAreaCell(props) {
    const { value, rowData, secondLineId, unitId, tableKey } = props;
    const [unit, setUnit] = useState(null);
    const classes = useStyles();

    const multilineViewType = useLoadMultiLineViewType({ tableKey });

    //set unit
    useEffect(() => {
        if (unitId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, unitId);
            setUnit(columnItem);
        }
    }, [unitId, rowData]);

    function getWeightPerArea() {
        if (secondLineId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, secondLineId);
            return columnItem || 0;
        }
        return 0;
    }

    return (
        <div className={classes.weightPerAreaCellWrapper}>
            {(multilineViewType === TABLE.VIEW_TYPE.SIMPLE || !secondLineId)
                && <Weight value={value} unit={unit} />
            }
            {(multilineViewType === TABLE.VIEW_TYPE.VALUE_PER_UNIT && secondLineId)
                && <WeightPerArea value={getWeightPerArea()} unit={unit} />
            }
        </div>
    );
}
