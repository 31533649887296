import { createUseStyles } from 'react-jss';
import Label from '@baseComponents/formParts/Label';
import { useLoadSoilAnalysisImportData } from '@contents/cultivationPeriods/soilAnalysis/soilAnalysisImport/useLoadSoilAnalysisImportData';
import { UnitInput } from '@baseComponents/inputs/inputField';
import { FormDatePicker } from '@customComponents/datePickers';
import { ModalBody } from 'react-bootstrap';
import useTranslate from '@i18n/useTranslate';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { simpleTableActions } from '@states/actions';
import DataInput from '@baseComponents/inputs/DataInput';
import { SimpleSelect } from '@baseComponents/select';
import Checkbox from '@baseComponents/controls/Checkbox';
import { useNumberFormat } from '@common_modules/numberFormat';
import AnalysisDataEditTable from './AnalysisDataEditTable';

const useStyles = createUseStyles((theme: any) => ({
    listView: {
        width: '100%',
        minWidth: 1020,
        height: 'calc(100vh - 205px)',
        position: 'relative',
        backgroundColor: theme.color.lilian,
        borderBottomRightRadius: 12,
        display: 'flex',
        flexFlow: 'column',
        overflowY: 'hidden',
        padding: 30,
    },
    bodyHeaderContainer: {
        marginBottom: 15,
        display: 'flex',
        alignItems: 'start',
    },
    label: {
        fontSize: 12,
        marginRight: 12,
    },
    inputContainers: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 200,
        '& > *:last-child': {
            marginRight: 20,
        },
    },
    registryNumberImput: {
        width: 200,
    },
    companySelect: {
        width: 200,
    },
    addNewRow: {
        marginTop: 5,
        height: 56,
        '& button:hover': {
            color: theme.color.main,
            '& svg': {
                fill: theme.color.main,
            },
        },
    },
    colRow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 12,
            width: '100%',
        },
    },
    isExtendedExaminationInput: {
        marginBottom: 0,
        marginTop: 10,
        '& span': {
            marginLeft: 0,
            textTransform: 'uppercase',
            fontSize: 12,
            fontWeight: 500,
        },
    },
}));

export default function AnalysisDataEditBody(props) {
    const { tableId } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { watch, setValue, getValues } = useFormContext();

    const { numberFormatter } = useNumberFormat();
    const { laboratories } = useLoadSoilAnalysisImportData();

    function onClickAddRow() {
        setValue('isLoading', true);
        const tableData = getValues('soilAnalysisData') || [];
        const rowId = getValues('rowId') + 1 || 1;
        setValue('soilAnalysisData', [...(tableData || []), { rowId, id: rowId }]);
        setValue('rowId', rowId);
        dispatch(simpleTableActions.addRowToTableData(tableId, { rowId, id: rowId }));
    }

    return (
        <ModalBody className={classes.listView}>
            <div className={classes.bodyHeaderContainer}>
                <div className={classes.colRow}>
                    <div className={classes.inputContainers}>
                        <Label
                            label={t('soilAnalysisImport.samplingDate', 'Sampling date')}
                            className={classes.label}
                            required
                            isValue={watch('date')}
                        />
                        <FormDatePicker
                            date={getValues('date')}
                            setDate={value => setValue('date', value)}
                            label=""
                        />
                    </div>
                    <div className={classes.inputContainers}>
                        <Label
                            label={t('soilAnalysisImport.validityDate', 'Validity date')}
                            className={classes.label}
                            required
                            isValue={watch('validityDate')}
                        />
                        <FormDatePicker
                            date={getValues('validityDate')}
                            setDate={value => setValue('validityDate', value)}
                            label=""
                        />
                    </div>
                </div>
                <div className={classes.colRow}>
                    <div className={classes.inputContainers}>
                        <Label
                            label={t('soilAnalysisImport.registryNumber', 'Registry number')}
                            className={classes.label}
                            required
                            isValue={watch('registryNumber')}
                        />
                        <UnitInput
                            type="text"
                            className={classes.registryNumberImput}
                            defaultValue={getValues('registryNumber')}
                            onBlur={(_event, value) => setValue('registryNumber', value)}
                        />
                    </div>
                    <div className={classes.inputContainers}>
                        <Label
                            label={t('soilAnalysisImport.samplingArea', 'Size of sampling area')}
                            className={classes.label}
                            required
                            isValue={watch('samplingArea')}
                        />
                        <UnitInput
                            type="number"
                            numberFormat={numberFormatter.getAreaFormats()}
                            className={classes.registryNumberImput}
                            defaultValue={getValues('samplingArea')}
                            onBlur={(_event, value) => setValue('samplingArea', value)}
                        />
                    </div>
                </div>
                <div className={classes.colRow}>
                    <div className={classes.inputContainers}>
                        <Label
                            label={t('soilAnalysisImport.laboratory', 'Accredited laboratory')}
                            className={classes.label}
                            required
                            isValue={watch('laboratoryId')}
                        />
                        <SimpleSelect
                            dataList={laboratories}
                            selected={watch('laboratoryId')}
                            className={classes.registryNumberImput}
                            onChange={(laboratoryId: number) => {
                                setValue('laboratoryId', +laboratoryId);
                            }}
                        />
                    </div>

                    <div className={classes.isExtendedExaminationInput}>
                        <Checkbox
                            text={t('soilAnalysisImport.isExtendedExamination', 'Extended examination')}
                            checked={watch('isExtendedExamination')}
                            onChange={value => setValue('isExtendedExamination', value)}
                        />
                    </div>
                </div>
            </div>
            <AnalysisDataEditTable
                tableId={tableId}
            />
            {!!watch('isManual')
                && (
                    <DataInput
                        type="dashed"
                        title={t('default.addNewRow', 'Add new row')}
                        className={classes.addNewRow}
                        onClick={onClickAddRow}
                    />)}
        </ModalBody>
    );
}
