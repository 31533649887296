import { soilAnalysisService } from '@services/soilAnalysisService';
import { errorActions } from '@states/actions';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

export const useLoadSoilAnalysisImportData = () => {
    const [laboratories, setLaboratories] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const laboratoriesList = await soilAnalysisService.getSoilAnalysisLaboratories();
                if (!isCancelled) {
                    const laboratoriesDataList = laboratoriesList.map(laboratory => ({
                        key: laboratory.id,
                        value: laboratory.name,
                    }));
                    setLaboratories(laboratoriesDataList);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'LoadSoilAnalysisEditData'));
            }
        }

        loadData();
        return () => {
            isCancelled = true;
        };
    }, [dispatch]);

    return {
        laboratories,
    };
};
