import React from 'react';
import useTranslate from '@i18n/useTranslate';
import BaseConfirmationModal from '@baseComponents/modals/BaseConfirmationModal';
import renderModal from '@modals/modalNew/modalFunctions';
import SoilAnalysisImport from './SoilAnalysisImport';

export default function NewSoilAnalysis(props) {
    const { hideModal, cultivationPeriodId } = props;
    const { t } = useTranslate();

    function soilAnalisisDataImport(isExcelImport) {
        hideModal();
        renderModal(SoilAnalysisImport, { isExcelImport, cultivationPeriodId });
    }

    return (
        <BaseConfirmationModal
            questionText={t('soilAnalysisImport.loadData', 'Load data')}
            descriptionText={t('soilAnalysisImport.loadDataQuestion', 'What kind of form do you want to upload the data?')}
            isAcceptCancelDialog
            buttonType="primary"
            acceptButtonText={t('soilAnalysisImport.loadFromExcel', 'Load from excel')}
            cancelButtonText={t('soilAnalysisImport.loadByManual', 'Load by manual')}
            iconId="icon-help"
            isHideModalAfterConfirm={false}
            handleConfirmClicked={() => soilAnalisisDataImport(true)}
            onClickCancel={() => soilAnalisisDataImport(false)}
            hideModal={hideModal}
        />
    );
}
