import useTranslate from '@i18n/useTranslate';
import BaseConfirmationModal from './BaseConfirmationModal';

export default function DeleteConfirmationModal(props) {
    const { t } = useTranslate();
    const {
        show, handleDeleteClicked, hideModal, onClickCancel, isHideModalAfterConfirm,
        buttonText = t('default.delete', 'Delete'),
        cancelButtonText = t('default.cancel', 'Cancel'),
        descriptionText = t('default.losedata', 'Saved data get lost.'),
        questionText = t('default.questiondelete', 'Are you sure you delete data?'),
        buttonType = 'destructive',
        iconId = 'icon-bin',
        className,
    } = props;

    return (
        <BaseConfirmationModal
            show={show}
            isAcceptCancelDialog
            buttonType={buttonType}
            acceptButtonText={buttonText}
            cancelButtonText={cancelButtonText}
            questionText={questionText}
            descriptionText={descriptionText}
            iconId={iconId}
            handleConfirmClicked={handleDeleteClicked}
            hideModal={hideModal}
            className={className}
            onClickCancel={onClickCancel}
            isHideModalAfterConfirm={isHideModalAfterConfirm}
        />
    );
}
