import { isObject } from 'lodash';
import { ERRORS } from 'shared/src/constants';
import { sendServerRequest } from './baseService';

export const errorLogService = {
    setErrorLog,
};

async function setErrorLog(location, errorMessage, userId, logTime, statusCode) {
    const errorObject = {
        message: isObject(errorMessage) ? (errorMessage as any).message : errorMessage,
        stack: isObject(errorMessage) ? (errorMessage as any).stack : errorMessage,
    };
    const response = await sendServerRequest('POST', '/api/errorLog', { location, errorMessage: errorObject, userId, logTime, statusCode, errorSide: ERRORS.SIDE.CLIENT });
    return response;
}
