import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import SVG from '@styles/svg/icons.svg';

const useStyles = createUseStyles((theme: any) => ({
    defaultSvg: {
        fill: theme.color.jet,
    },
}));

export default function Svg(props) {
    const { id, iconId, style, styles, viewBox, onClick, height, width } = props;
    const classes = useStyles();

    return (
        <svg id={id} className={clsx(classes.defaultSvg, style)} viewBox={viewBox} onClick={onClick} height={height} width={width} style={styles}>
            <use xlinkHref={`${SVG}#${iconId}`} />
        </svg>
    );
}
