import Svg from '@baseComponents/Svg';
import useTranslate from '@i18n/useTranslate';
import { LayerSelectPanelLayer } from '@map/components/LayerPanel/LayerFolder/LayerSelectPanelLayer';
import MapController from '@map/services/mapController';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { Layer, LayerSelectorSettings, LayerType, ThemeType } from '@map/services/mapTypes';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { LayerWithAttributes } from '../LayerControlPanel';
import { LayerFolderDelete } from './LayerFolderDelete';
import { LayerFolderEdit } from './LayerFolderEdit';
import { LayerFolderEditMode } from './LayerFolderEdit.enum';
import LayerFolderModal from './LayerFolderModal';
import { LayerFolderMoreOptions } from './LayerFolderMoreOptions';
import { getLayerName } from './LayerFolderUtils';

type Classes = 'container' | 'folderHeader' | 'folderHeaderTitle' | 'folderHeaderButton' | 'folderContainer' | 'selectableContainer' | 'moreContainerWrapper' | 'folderBody' | 'folderHeaderTitle' | 'arrowIcon' | 'moreIcon' | 'moreContainer';

type StyleProps = {
    isOpen: boolean,
}

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '100%',
    },
    folderHeader: {
        maxWidth: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 40,
        padding: [5, 5, 5, 0],
    },
    folderContainer: {

    },
    selectableContainer: {

    },
    folderHeaderTitle: {
        margin: 0,
        color: theme.color.jet,
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 500,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    folderHeaderButton: {
        alignItems: 'center',
        fontSize: 18,
        marginBottom: 0,
        border: 'none',
        background: 'transparent',
        display: 'flex',
        justifyContent: 'flex-start',
        verticalAlign: 'bottom',
        overflow: 'hidden',
    },
    buttonWrapper: {
        display: 'flex',
        padding: '0 15px 15px',
    },
    folderBody: {
        paddingLeft: 20,
    },
    arrowIcon: {
        height: 20,
        width: 20,
        marginRight: 2,
        verticalAlign: 'middle',
    },
    moreIcon: {
        width: 24,
        height: 24,
        fill: theme.color.raven,
        justifySelf: 'flex-end',
    },
    moreContainerWrapper: {
        position: 'relative',
    },
    moreContainer: {
        position: 'fixed',
    },
}));

type TProps = {
    parentLayer: Layer,
    isOpenDefault?: boolean,
    layersWithAttributes: LayerWithAttributes[],
    mapController: MapController
    index?: number,
    layerSelectorSettings: LayerSelectorSettings,
    closePanel: (event?: MouseEvent | TouchEvent) => void,
}

export function LayerFolderContainer({ closePanel, parentLayer, layersWithAttributes, isOpenDefault = false, mapController, index = 1, layerSelectorSettings }: TProps) {
    const { translater } = useTranslate();
    const [folderLayers, setFolderLayers] = useState<Layer[]>([]);
    const [selectablaLayers, setSelectableLayers] = useState<Layer[]>([]);
    const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault);
    const [editPanelMode, setEditPanelMode] = useState<LayerFolderEditMode>(LayerFolderEditMode.NONE);
    const [isContianerHovered, setIsContainerHovered] = useState(false);

    const [isFolderTop, setIsFolderTop] = useState(true);

    const classes = useStyles({ isOpen });

    //Agrovir folder specific
    const orderAgrovirLayer = (selectable: LayerWithAttributes[]) => {
        setIsFolderTop(false);

        const layerOrder = [
            BuiltInLayerTypes.CULTIVATION_PERIOD,
            BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP,
            BuiltInLayerTypes.REGISTRY_NUMBER,
            BuiltInLayerTypes.LPIS_FIELD,
            BuiltInLayerTypes.LOT,
            BuiltInLayerTypes.MACHINES,
            BuiltInLayerTypes.IRRIGATION,
            BuiltInLayerTypes.OTHER,
            BuiltInLayerTypes.METEOROLOGY,
            BuiltInLayerTypes.SCOUTINGS,
        ];

        const layerOrderStore: LayerWithAttributes[] = [];

        layerOrder.forEach(order => {
            const findLayer = selectable.find(layer => layer.name === order);
            if (!isNil(findLayer)) {
                layerOrderStore.push(findLayer);
            }
        });

        return layerOrderStore;
    };

    useEffect(() => {
        const folders = layersWithAttributes.filter(layer => layer.type === LayerType.FOLDER && layer.parentId === parentLayer.id && [-1, 0].includes(layer.id) === false);
        const selectable = layersWithAttributes.filter(layer => layer.type !== LayerType.FOLDER && layer.parentId === parentLayer.id && layer.id !== 0 && [-1, 0].includes(layer.id) === false);

        let filteredSelectable = selectable.filter(layer => !layerSelectorSettings.layerFilter.blackList?.includes(layer.name));

        if (parentLayer.id === -1) {
            filteredSelectable = orderAgrovirLayer(filteredSelectable);
        }

        setFolderLayers(folders);
        setSelectableLayers(filteredSelectable);
    }, [layerSelectorSettings, layersWithAttributes, parentLayer.id]);

    return (
        <div className={classes.container}>
            <div
                className={classes.folderHeader}
                onMouseEnter={() => setIsContainerHovered(true)}
                onMouseLeave={() => setIsContainerHovered(false)}
            >
                <button type="button" onClick={() => { setIsOpen(!isOpen); }} className={classes.folderHeaderButton}>
                    <div>
                        <Svg
                            iconId={isOpen ? 'icon-expand_less' : 'icon-expand_more'}
                            height={24}
                            width={24}
                            style={classes.arrowIcon}
                        />
                    </div>
                    <p className={classes.folderHeaderTitle}>
                        {getLayerName(parentLayer, translater)}
                    </p>
                </button>
                <div>
                    {
                        isContianerHovered && <LayerFolderMoreOptions layer={parentLayer} setEditPanelMode={setEditPanelMode} />
                    }
                </div>
            </div>
            {isOpen && (
                <div className={classes.folderBody}>
                    {isFolderTop && (
                        <div className={classes.folderContainer}>
                            {folderLayers.map(folder => <LayerFolderContainer closePanel={closePanel} layerSelectorSettings={layerSelectorSettings} index={index + 1} mapController={mapController} key={folder.id} parentLayer={folder} layersWithAttributes={layersWithAttributes} />)}
                        </div>
                    )}
                    <div className={classes.selectableContainer}>
                        {selectablaLayers.map(selectable => <LayerSelectPanelLayer closePanel={closePanel} mapController={mapController} key={selectable.id} layer={selectable} />)}
                    </div>
                    {!isFolderTop && (
                        <div className={classes.folderContainer}>
                            {folderLayers.map(folder => <LayerFolderContainer closePanel={closePanel} layerSelectorSettings={layerSelectorSettings} index={index + 1} mapController={mapController} key={folder.id} parentLayer={folder} layersWithAttributes={layersWithAttributes} />)}
                        </div>
                    )}
                </div>
            )}
            {(editPanelMode === LayerFolderEditMode.EDIT || editPanelMode === LayerFolderEditMode.CREATE) && (
                <LayerFolderModal>
                    <LayerFolderEdit
                        editPanelMode={editPanelMode}
                        layer={parentLayer}
                        mapController={mapController}
                        setEditPanelMode={setEditPanelMode}
                        index={index}
                    />
                </LayerFolderModal>
            )}
            {editPanelMode === LayerFolderEditMode.DELETE && (
                <LayerFolderModal>
                    <LayerFolderDelete
                        editPanelMode={editPanelMode}
                        layer={parentLayer}
                        mapController={mapController}
                        setEditPanelMode={setEditPanelMode}
                    />
                </LayerFolderModal>
            )}
        </div>
    );
}
