export const ECONOMIC_UNIT = {
    TYPE: {
        WORKSHOP: 1,
        ANIMAL_HUSBANDRY: 2,
        CROP_PRODUCTION: 3,
        SERVICE: 4,
        AUXILIARY_OPERATION: 5,
        GENERAL: 6,
        COST_PROFIT_CENTER: 7,
        WINE: 8,
        WINE_CELLAR: 9,
        PROCESSING_PLANT: 10,
        FRUIT_PREPARATIONS: 11,
        SEED_PLANT: 12,
        REPRODUCTIVE_MATERIAL: 13,
    },
    CLASSIFICATION_TYPE: {
        CLASSIFIED: 1,
        NOT_CLASSIFIED: 0
    },
    WORK_OPERATION_LIST_TERMINATION_DATE: '2100-12-31',
};
