import sassColors from '@styles/sass/variables/_colors.module.scss';

const headerFilterTopHeight = 170;
const headerFilterBottomHeight = 100;

export type ThemeType = typeof theme;

export const theme = {
    color: {
        //almost blacks
        // black: '#0b0d0f',
        black: sassColors?.color_black,
        jet: sassColors?.color_gray_jet, //#21282C
        raven: sassColors?.color_gray_raven, //#454f54
        stone: sassColors?.color_gray_stone, //#798286
        gray: sassColors?.color_gray_gray,
        pearl: sassColors?.color_gray_pearl, //#e3e6e8
        lilian: sassColors?.color_gray_lilian,
        white: sassColors?.color_white,
        disabled: 'RGBA(240, 243, 244, 0.5)',
        //systemwide
        main: sassColors?.color_green_main_theme, //#22c373
        test: '#ff9600',
        status: '#ff9500',
        destructive: sassColors?.color_red_destructive,
        status_success: sassColors?.$color_green_main_theme,
        status_info: sassColors?.color_status_info,
        status_warning: sassColors?.color_status_warning,
        status_error_non_critical: sassColors?.color_status_error_non_critical,
        status_error: sassColors?.color_red_destructive,
        status_accepted: 'rgba(38, 217, 68, 0.2)',
        status_rejected: 'rgba(255, 85, 51, 0.2)',
        status_partially_completed: 'rgb(255, 204, 51, 0.2)',
        status_completed: 'rgba(38, 217, 68, 0.5)',
        status_closed: 'rgba(255, 85, 51, 0.5)',
        status_deleted: 'rgba(255, 85, 51, 0.5)',
        statusColor: {
            default: sassColors?.color_status_default,
            none: 'transparent',
            success: sassColors?.color_status_success,
            info: sassColors?.color_status_info,
            warning: sassColors?.color_status_warning,
            error_non_critical: sassColors?.color_status_error_non_critical,
            error: sassColors?.color_status_error,
        },
        filter: '#9a6700',
        shadow_green: 'rgb(34, 195, 115)',
        water: '#8cd9d9',
        //flag
        stop: '#ff5533',
        slow: '#ffcc33',
        go: '#26d944',
        opacity: {
            main: '#22c3731A',
            jet: '#21282c1A',
            stop: '#ff55331A',
            slow: '#ffcc331A',
            go: '#26d9441A',
        },
        operations: {
            table: '#a3e085',
            economic_unit: '#f7c96e',
            service: '#99bbff',
            wage_work: '#ffb2b2',
        },
        workOperationStatus: {
            ongoing: '#4fb5ff',
            planned: '#ff964d',
            template: '#ff964d',
            finished: '#22c373',
        },
        tableRowHover: '#e8f9f1',
        //button
        buttonHover: {
            secondary: '#f8f9fa',
            destructive: '#ffe6ee',
            default: '#38c981',
        },
        //other
        transparent: 'transparent',
        beta: '#a60df2',
        lightCyan: '#99bbff',
        mainFaded: '#22C37333',
        testFaded: '#ff960033',
        grayFaded: '#afb4b680',
        destructiveFaded: '#ff00551a',
        statusFaded: '#38c981',
        statusInfoFaded: 'rgb(66, 183, 223, 0.5)',
        statusPlannedFaded: '#FCB27E',
        subscriptionLightBlue: '#0ac2ad',
        subscriptionBlue: '#079182',
        separatedMenuColor: '#F5DEB3',
        separatedMenuHoverColor: '#AA8559',
        linkHoverColor: '#00914c',
        yellow: '#FFFF00',
        lilac: '#A60DF2',
        fog: '#F7F8F9',
        map: {
            background: '#e5e3df',
        },
        icons: {
            pdfIcon: sassColors?.color_red_destructive,
            deleteIcon: sassColors?.color_red_destructive,
        },
        required: '#FF0055',
    },
    rgbColor: {
        jet: '33, 40, 44',
        main: '34, 195, 115',
        destructive: '219, 0, 73',
    },
    rgbaColor: {
        lilian: 'rgba(240, 243, 244, 0.5)',
    },
    palette: {
        primary: {
            main: sassColors?.$color_green_main_theme,
            light: '#38c981',
            hover: '#e9f9f1',
        },
        destructive: {
            main: sassColors?.color_red_destructive,
            hover: '#ff00551a',
        },
        white: '#ffffff',
        //systemwide
        main: sassColors?.$color_green_main_theme,
        status: '#ff9500',
        filter: '#9a6700',
        water: '#8cd9d9',
        //flag
        stop: '#ff5533',
        slow: '#ffcc33',
        go: '#26d944',
    },
    border: {
        main: 'solid 2px rgba(255, 255, 255, 0.3)',
    },
    shadows: {
        none: 'none',
        dropdown: '0 6px 22px 4px rgba(33, 40, 44, 0.1), 0 12px 18px 2px rgba(33, 40, 44, 0.15), 0 4px 8px -4px rgba(33, 40, 44, 0.2)',
        header: '0 4px 4px 3px rgba(33, 40, 44, 0.02)',
        footer: '0 -4px 18px 3px rgba(33, 40, 44, 0.08)',
        alert: '0 10px 50px 8px rgba(33, 40, 44, 0.15), 0 30px 40px 3px rgba(33, 40, 44, 0.2), 0 15px 15px -7px rgba(33, 40, 44, 0.3)',
        filter: '0 5px 0 0 rgba(33, 40, 44, 0.15), inset 2px 2px 0 0  rgba(240, 243, 244)',
        map: '00px 4px 8px -4px rgba(33, 40, 44, 0.2), 0px 12px 18px 2px rgba(33, 40, 44, 0.15), 0px 6px 22px 4px rgba(33, 40, 44, 0.1)',
        primary: {
            main: '0 3px 5px 0 rgba(34, 195, 115, 0.3)',
            hover: '0 5px 10px 0 rgba(34, 195, 115, 0.5)',
            focus: '0 3px 5px 0 rgba(34, 195, 115, 0.15)',
        },
        secondary: {
            normal: '0 3px 5px 0 rgba(175, 180, 182, 0.3)',
            hover: '0 5px 10px 0 rgba(175, 180, 182, 0.5)',
            input: '0 2px 3px 0 rgba(175, 180, 182, 0.15)',
        },
        destructive: {
            normal: '0 3px 5px 0 rgba(255, 0, 85, 0.15)',
            main: '0 3px 5px 0 rgba(255, 0, 85, 0.3)',
            hover: '0 5px 10px 0 rgba(255, 0, 85, 0.2)',
        },
        slow: {
            normal: '0 3px 5px 0 rgb(255, 204, 51, 0.3)',
        },
        lightGrey: '0 2px 4px 0 rgba(33, 40, 44, 0.15)',
        status: {
            slow: '0 0 0 3px rgba(255, 204, 51, 0.2)',
            stop: '0 0 0 3px rgba(255, 0, 85, 0.2)',
            go: '0 0 0 3px rgba(38, 217, 68, 0.2)',
        },
    },
    column: {
        container: {
            display: 'inline-flex',
        },
        simpleInlineFlexColumn: {
            borderRight: '1px solid #f0f3f4',
            display: 'block',
            //width: 260,
            width: 'auto',
            paddingLeft: 15,
            paddingRight: 15,
            '& h4': {
                marginTop: 0,
                marginBottom: 15,
            },
        },
        simpleInlineFlexChildColumnLeftBorder: {
            borderLeft: '1px solid #f0f3f4',
            display: 'inline-block',
            width: 150,
            paddingLeft: 30,
            paddingRight: 30,
        },
        simpleInlineFlexChildColumn: {
            display: 'inline-block',
            width: 120,
            paddingRight: 30,
        },
        simpleInlineColumn: {
            borderLeft: '1px solid #f0f3f4',
            display: 'inline-block',
            width: 260,
            paddingLeft: 30,
            paddingRight: 30,
            '& h2': {
                marginTop: 0,
                marginBottom: 15,
            },
        },
        simpleInlineColumnLeftBorder: {
            borderLeft: '1px solid #f0f3f4',
            display: 'inline-block',
            width: 260,
            paddingLeft: 30,
            paddingRight: 30,
            '& h2': {
                marginTop: 0,
                marginBottom: 15,
            },
            '& h4': {
                marginTop: 0,
                textTransform: 'lowercase',
                '&:first-letter': {
                    textTransform: 'uppercase',
                },
            },
        },
    },
    overFlowText: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    headerFilter: {
        filter: {
            minHeight: 0,
            maxHeight: `calc(100vh - ${(headerFilterTopHeight + headerFilterBottomHeight)}px)`,
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: 6,
                position: 'absolute', //right
            },
            '&:hover::-webkit-scrollbar-thumb': {
                borderRadius: 7,
                background: 'rgba(0, 0, 0, 0.4)',
                position: 'absolute',
            },
            scrollbarWidth: 'thin !important',
        },
        filterTop: {
            flex: 1,
            width: '100%',
            paddingBottom: 30,
            borderBottom: '1px solid #e3e6e8',
            display: 'inline-flex',
            maxHeight: 225,
        },
        filterBottom: {
            flex: 0,
            display: 'inline-flex',
            minHeight: 0,
            '& h4': {
                marginBottom: 5,
                display: 'inline-block',
            },
        },
    },
    firstLetterUppercase: {
        textTransform: 'lowercase',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
        '& :first-letter': {
            textTransform: 'uppercase',
        },
    },
    disableSelectionColor: {
        webkitTouchCallout: 'none', /* iOS Safari */
        webkitUserSelect: 'none', /* Safari */
        khtmlUserSelect: 'none', /* Konqueror HTML */
        mozUserSelect: 'none', /* Old versions of Firefox */
        msUserSelect: 'none', /* Internet Explorer/Edge */
        userSelect: 'none', /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    },
    disableOutline: {
        outline: 'none',
        '&:focus': {
            outline: 'none',
        },
        '&:active': {
            outline: 'none',
        },
    },
    boxTringle: {
        up: {
            content: '""',
            width: 0,
            height: 0,
            borderLeft: '20px solid transparent',
            borderRight: '20px solid transparent',
            borderBottom: '10px solid #ffffff',
            position: 'absolute',
            left: 'calc(50% - 10px)',
            top: '-10px',
        },
    },
    contentMinWidth: 1200,
    zIndex: {
        sidebar: 2,
        tableColumnFader: 2,
        filterContentFader: 5,
        tableRowProgressBar: 3,
        menuDropDown: 6,
        bodyMapView: 4,
        fade: 9,
        modalClose: 12,
        modalWindow: 203,
        listDropdown: 1000,
        tableCellPopover: 1000,
        sideNavBar: 1001,
        expanded: 1002,
        selectDropdown: 1003,
        close: 2000,
        loadSpinner: 10000,
        pageLoader: 10001,
        modal: 10002,
        popover: 15000,
        multiselect: 15001,
        alert: 99999,
    },
    subMenu: {
        buttonHeight: 40,
    },
    table: {
        //old table
        pagerWidth: 50,
        otherFunctionsWidth: 60,
        leftMargin: 20,

        //new table
        tableRowHeight: 56,
        dataCardRowHeight: 80,
        progressBarHeight: 6,
        tableHeaderRowHeight: 46,
        rowPaddingRight: 60,
        tableRowFaderHeight: 57, //tableRowHeight + 2px border
        leftPagerWidth: 21,
        rightPagerWidth: 51,
        minWidth: 1040,
        selectionColumnWidth: 40,
        horizontalScrollbar: {
            '&::-webkit-scrollbar': {
                position: 'absolute',
                width: 4,
                height: 4,
                background: 'rgba(69, 79, 84, 0.2)',
                opacity: 0.2,
                borderRadius: 2.5,
            },
            '&::-webkit-scrollbar-track': {
                width: 4,
                height: 4,
                background: 'rgba(69, 79, 84, 0.2)',
                opacity: 0.2,
                borderRadius: 2.5,
            },
            '&::-webkit-scrollbar-thumb': {
                position: 'absolute',
                height: 4,
                width: 674,
                opacity: 0.4,
                borderRadius: 2.5,
                background: 'rgba(69, 79, 84, 0.2)',
            },
            '&::-webkit-scrollbar-thumb:hover': {
                borderRadius: 7,
                background: 'rgba(11, 13, 15, 0.4)',
                position: 'absolute',
            },
            scrollbarHeight: 'thin !important',
            scrollbarWidth: 'thin !important',
        },
        column: {
            smallColumn: {
                minWidth: 100,
                maxWidth: 140,
            },
            mediumColumn: {
                minWidth: 140,
                maxWidth: 160,
            },
            bigColumn: {
                minWidth: 160,
                maxWidth: 210,
            },
            autoColumn: {
                minWidth: 100,
                width: 'auto',
                maxWidth: 300,
            },
        },
    },
    basePage: {
        headerHeight: 110,
        headerTitleLineHeight: 40,
        headerFunctionLineHeight: 42, // + 2px separator
        bodyFunctionLineHeight: 50,
        bodyFixedFooterLineHeight: 45,
        footerHeight: 70,
    },
    verticalScrollbar: {
        '&::-webkit-scrollbar': {
            width: 6,
            position: 'absolute', //right
        },
        '&:hover::-webkit-scrollbar-thumb': {
            borderRadius: 7,
            background: 'rgba(0, 0, 0, 0.4)',
            position: 'absolute',
        },
        scrollbarWidth: 'thin !important',
    },
    yearPicker: {
        '& .yearPicker': {
            width: 'auto',
            '& .react-datepicker__navigation': {
                width: 30,
                height: 30,
            },
            '& .react-datepicker-year-header': {
                marginTop: '0',
                color: sassColors?.color_gray_jet,
                fontWeight: 500,
                fontSize: 15,
                textAlign: 'center',
                paddingTop: '8px',
                position: 'relative',
            },
            '& .react-datepicker__navigation--next': {
                right: 0,
                outline: 'none',
            },
            '& .react-datepicker__navigation--previous': {
                right: 0,
                outline: 'none',
            },
            '& .react-datepicker__year-container': {
                marginTop: 20,
                marginRight: -25,
                display: 'flex',
                flexWrap: 'wrap',
                textAlign: 'center',
            },
            '& .react-datepicker__year-container-text': {
                display: 'inline-flex',
                justifyContent: 'center',
                cursor: 'pointer',
                alignItems: 'center',
                width: 77,
                height: 46,
                border: [1, 'solid', '#afb4b6'],
                borderRadius: 4,
                marginRight: 17,
                marginTop: 15,
                color: sassColors?.color_gray_jet,
                fontSize: 14,
                fontWeight: 500,
            },
            '& .selectedYear': {
                color: '#22c373',
                boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15)',
                backgroundColor: '#22C37333',
                border: 'solid 1px',
                borderColor: '#22c373',
            },
        },
    },
    horizontalScrollbar: {
        '&::-webkit-scrollbar': {
            position: 'absolute',
            width: 4,
            height: 4,
            background: 'rgba(69, 79, 84, 0.2)',
            opacity: 0.2,
            borderRadius: 2.5,
        },
        '&::-webkit-scrollbar-track': {
            width: 4,
            height: 4,
            background: 'rgba(69, 79, 84, 0.2)',
            opacity: 0.2,
            borderRadius: 2.5,
        },
        '&::-webkit-scrollbar-thumb': {
            position: 'absolute',
            height: 4,
            width: 674,
            opacity: 0.4,
            borderRadius: 2.5,
            background: 'rgba(69, 79, 84, 0.2)',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            borderRadius: 7,
            background: 'rgba(11, 13, 15, 0.4)',
            position: 'absolute',
        },
        scrollbarHeight: 'thin !important',
        scrollbarWidth: 'thin !important',
    },
    map: {
        polygonStyle: {
            strokeOpacity: 1,
            fillOpacity: 0.5,
        },
        drawPolygonStyle: {
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            fillColor: '#FF0000',
            fillOpacity: 0.15,
            editable: true,
            zIndex: 2,
        },
        markerStyle: {
            padding: '2px 2px 2px 2px',
            fontFamily: 'Montserrat',
            textAlign: 'center',
            fontWeight: 'bold',
            fontSize: 12,
            color: 'white',
            textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
        },
    },
    operationData: {
        heights: {
            header: 100,
            daySelector: 70,
            tabSelector: 60,
            footer: 86,
            modalMargin: 160,
        },
        paddingX: 30,
        columnWidths: {
            default: {
                min: 75,
                max: 250,
            },
        },
    },
    formatBootstrapButton: {
        secondary: {
            '& .btn-primary:hover': {
                boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15) !important',
                borderColor: '#22c373 !important',
                backgroundColor: '#ffffff !important',
                color: `${sassColors?.color_gray_jet} !important`,
            },
            '& .btn-primary:not(:disabled):not(.disabled):active': {
                boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15) !important',
                borderColor: '#22c373 !important',
                backgroundColor: '#ffffff !important',
                color: `${sassColors?.color_gray_jet} !important`,
            },
            '& .btn-primary:focus': {
                boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15) !important',
                borderColor: '#22c373 !important',
                backgroundColor: '#ffffff !important',
                color: `${sassColors?.color_gray_jet} !important`,
            },
            '& .btn-primary:disabled': {
                boxShadow: '0 2px 3px 0 rgba(175, 180, 182, 0.15) !important',
                borderColor: '#afb4b6 !important',
                backgroundColor: '#ffffff !important',
                color: `${sassColors?.color_gray_jet} !important`,
            },
        },
    },
    formButtonFade: {
        position: 'fixed',
        height: 40,
        backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff)',
    },
};
