import { ALERT } from 'shared/src/constants';
import { dateTimeUtils } from 'shared/src/modules';
import { simpleAction } from './baseActions';

const alertActions = {
    addAlert,
    removeAlert,
    successAlert,
    errorAlert,
    warningAlert,
    infoAlert,
    ADD_ALERT: 'ADD_ALERT',
    REMOVE_ALERT: 'REMOVE_ALERT',
};

export {
    alertActions,
    addAlert,
};

function addAlert(props) {
    const { alertType = ALERT.TYPE.ERROR, alertMessage, alertTitle, alertMoreInfo, isHtmlCodeAlert } = props || {};
    return async dispatch => {
        const alertId = dateTimeUtils.getTimestampNow();
        dispatch(simpleAction(alertActions.ADD_ALERT, { id: alertId, type: alertType, message: alertMessage, title: alertTitle, moreInfo: alertMoreInfo, isHtmlCodeAlert }));
    };
}

function removeAlert(alertId) {
    return async dispatch => {
        dispatch(simpleAction(alertActions.REMOVE_ALERT, { alertId }));
    };
}

function successAlert(alertMessage: any, alertTitle?: any, alertMoreInfo?: any) {
    return async dispatch => {
        dispatch(addAlert({ alertType: ALERT.TYPE.SUCCESS, alertMessage, alertTitle, alertMoreInfo }));
    };
}

function errorAlert(alertMessage: any, alertTitle?: any, alertMoreInfo?: any) {
    return async dispatch => {
        dispatch(addAlert({ alertType: ALERT.TYPE.ERROR, alertMessage, alertTitle, alertMoreInfo }));
    };
}

function warningAlert(alertMessage: any, alertTitle?: any, alertMoreInfo?: any) {
    return async dispatch => {
        dispatch(addAlert({ alertType: ALERT.TYPE.WARNING, alertMessage, alertTitle, alertMoreInfo }));
    };
}

function infoAlert(alertMessage: any, alertTitle?: any, alertMoreInfo?: any) {
    return async dispatch => {
        dispatch(addAlert({ alertType: ALERT.TYPE.INFO, alertMessage, alertTitle, alertMoreInfo }));
    };
}
