/* eslint-disable no-restricted-imports */
import { useEffect, useMemo, useState } from 'react';
import MeasureTool from 'measuretool-googlemaps-v3';
import turfCentroid from '@turf/centroid';
import { polygon as turfPolygon } from '@turf/helpers';
import { useMapContext } from '../../services/mapContext';
import { clearMVCArray, createLabel } from '../../utils/mapLabelUtils';
import MeasureToolComponent from './MeasureToolComponent';

const MeasureToolContainer = () => {
    const [store, controller] = useMapContext();

    const [isMeasuring, setIsMeasuring] = useState<boolean>(false);
    const [measureIndicators, setMeasureIndicators] = useState<google.maps.MVCArray>();

    const measureTool = useMemo(() => {
        if (!store.googleRefs.map) {
            return;
        }
        return new MeasureTool(store.googleRefs.map, {
            contextMenu: false,
            showSegmentLength: true,
            tooltip: false,
            unit: MeasureTool.UnitTypeId.METRIC,
        });
    }, [store.googleRefs.map]);

    useEffect(() => {
        if (isMeasuring) {
            const attributes = store.layering.attributes;
            attributes?.forEach(attr => {
                attr.dataLayer.setStyle({ clickable: false });
            });
            measureTool?.start();
        } else {
            const attributes = store.layering.attributes;
            attributes?.forEach(attr => {
                attr.dataLayer.setStyle({ clickable: true });
            });
            measureTool?.end();
        }
        controller.setMapIsMeasuring(isMeasuring);
    }, [isMeasuring, measureTool]);

    useEffect(() => {
        if (!measureTool) {
            return;
        }

        const activeMeasureIndicators = measureIndicators ?? new google.maps.MVCArray();
        if (!measureIndicators) {
            setMeasureIndicators(activeMeasureIndicators);
        }

        measureTool.addListener('measure_change', event => {
            if (activeMeasureIndicators.get('map')) {
                clearMVCArray(activeMeasureIndicators);
            }
            if (event.result.area > 0) {
                const text = `${Number(event.result.area / 10000).toFixed(2)} ha`;

                let centroid;
                try { // bug prevention, sometimes above if passes even though the measured lines are not closed
                    centroid = turfCentroid(turfPolygon([event.result.points.map(p => [p.lat, p.lng])])).geometry.coordinates;
                } catch (er) {
                    return;
                }

                const label = createLabel({ lat: centroid[0], lng: centroid[1] }, text);
                const polygon = new google.maps.Polygon({
                    paths: event.result.points,
                    fillColor: '#22c373',
                    fillOpacity: 0.5,
                    strokeOpacity: 0,
                    zIndex: 100000000,
                });
                label.bindTo('map', activeMeasureIndicators);
                polygon.bindTo('map', activeMeasureIndicators);
                activeMeasureIndicators.push(label);
                activeMeasureIndicators.push(polygon);
                activeMeasureIndicators.set('map', store.googleRefs.map);

                //removes total area/distance text
                const textEl = document.querySelector('.node-text');
                if (textEl && textEl.children[textEl.children.length - 1]) {
                    textEl.children[textEl.children.length - 1].setAttribute('visibility', 'hidden');
                }
            }
            controller.setMapMeasureInfo(event.result.area, event.result.length)

        });

        measureTool.addListener('measure_end', () => {
            if (!activeMeasureIndicators) { return; }
            clearMVCArray(activeMeasureIndicators);
        });

        return () => {
            measureTool.removeListener('measure_change');
            measureTool.removeListener('measure_end');
        };
    }, [measureIndicators, store.googleRefs.map, measureTool]);

    if (!measureTool) {
        return null;
    }

    return (<MeasureToolComponent
        isMeasuring={isMeasuring}
        toggleMeasure={() => (isMeasuring ? setIsMeasuring(false) : setIsMeasuring(true))}
    />);
};

export default MeasureToolContainer;
