import React from 'react';
import { createUseStyles } from 'react-jss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import clsx from 'clsx';
import TooltipBody from './TooltipBody';

const useStyles = createUseStyles((theme: any) => ({
    tooltipContainer: {
        width: 'auto',
    },
    tooltip: {
        '& .tooltip-inner': {
            display: 'flex',
            boxShadow: theme.shadows.alert,
            minWidth: 300,
            maxWidth: 600,
            minHeight: 60,
            marginBottom: 0,
            fontSize: 13,
            borderRadius: 7.2,
            border: 'none !important',
            padding: 0,
            opacity: 1,
            backgroundColor: theme.color.white,
            color: theme.color.jet,
        },
        '& .arrow::before': {
            borderBottomColor: (props: any) => props.placement === 'bottom' && theme.color.white,
            borderLeftColor: (props: any) => props.placement === 'left' && theme.color.white,
            borderRightColor: (props: any) => props.placement === 'right' && theme.color.white,
            borderTopColor: (props: any) => props.placement === 'top' && theme.color.white,
        },
        '&.show': {
            opacity: 1,
        },
    },
}));

function BaseTooltip(props) {
    const { children, placement, type, title, message, moreInfo, show = false, className } = props;
    const classes = useStyles({ type, placement });

    const renderTooltip = prop => (
        <Tooltip id={`tooltip-${placement}`} {...prop} className={classes.tooltip}>
            <TooltipBody
                type={type}
                title={title}
                message={message}
                moreInfo={moreInfo}
            />
        </Tooltip>
    );

    if (!show) {
        return (
            <div className={clsx(classes.tooltipContainer, className)}>
                {children}
            </div>
        );
    }

    return (
        <OverlayTrigger
            placement={placement}
            delay={{ show: 150, hide: 400 }}
            trigger={['hover', 'click', 'focus']}
            overlay={renderTooltip}
        >
            <div className={clsx(classes.tooltipContainer, className)}>
                {children}
            </div>
        </OverlayTrigger>
    );
}

export default React.memo(BaseTooltip);
