import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import useTranslate from '@i18n/useTranslate';
import { companyActions, errorActions } from '@states/actions';
import { employeeService } from '@services';
import { isEmpty } from 'lodash';

export default function useWorkersModalLoadData(props) {
    const { selectedWorkers, excludedIds, TABLE_KEY } = props;
    const { translater } = useTranslate();
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState<Array<any>>();
    const location = useLocation();

    const [seasonalText] = useState(
        [
            translater('employeeTableFilter.seasonal', 'Seasonal'),
            translater('employeeTableFilter.permanent', 'Permanent'),
        ],
    );

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const params = {
                    companyIds: dispatch(companyActions.getActiveCompanyList()),
                };
                const employeeListResponse = await employeeService.getEmployees(params);
                const employeesFormatted = employeeListResponse?.map(employee => ({
                    ...employee,
                    seasonalType: seasonalText[+employee.isSeasonalEmployee],
                }));

                let selectedemployeesFirst: Array<any> = [];
                if (selectedWorkers) { //line1 = már kiválasztott gépek ...
                    employeesFormatted?.forEach(employee => {
                        if (selectedWorkers.find(lineItem => lineItem.id === employee.id)) {
                            selectedemployeesFirst.unshift(employee);
                        } else {
                            selectedemployeesFirst.push(employee);
                        }
                    });
                } else {
                    selectedemployeesFirst = employeesFormatted;
                }

                if (!isEmpty(excludedIds)) {
                    selectedemployeesFirst = selectedemployeesFirst.filter(employee => !excludedIds.includes(employee.id));
                }

                if (!isCancelled) {
                    setTableData(selectedemployeesFirst);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }

        loadData();

        return () => {
            isCancelled = true;
        };
    }, [dispatch, location, seasonalText, TABLE_KEY, selectedWorkers, excludedIds]);

    return {
        tableData,
    };
}
