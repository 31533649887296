import { SETTINGS, SUBSCRIPTION, PLANNING as PLANNING_CONSTANTS } from '../constants';

export const MAP = {
    MODULE_ID: 18,
    NAME: 'map',
    SETTINGS: [
        SETTINGS.USING_MAP,
    ],
    SYSTEM_PERMISSIONS: {
        CONSULTANT: false,
    },
    SUBSCRIPTION: true,
    SUBSCRIPTION_FUNCTIONS: [
        SUBSCRIPTION.NAME.CURRENT_WEATHER,
        SUBSCRIPTION.NAME.WEATHER_HISTORY,
        SUBSCRIPTION.NAME.WEATHER_FORECAST,
    ],
    SHOW_IN_PANNING_MODE: true,
    COLOR_SUBSCRIPTION: true,
    CONTENTS: {
        MAP: {
            CONTENT_URL_ID: { map: 1 },
            COMPONENT_URL: 'contents/map/index',
            EVENT_KEY: 'map',
            IGNORE_TITLE_LINE: true,
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.map',
                TRANSLATOR_DEFAULT: 'Map',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.MAP,
            PLANNING_SETTINGS: {
                DEPTH_ID: 1,
            },
        },
    },
};
