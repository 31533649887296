export const MAP = {
    TYPE: {
        HYBRID: 'hybrid',
    },
    POLYGON_TYPE: {
        CULTIVATION_PERIODS: 2,
        LPIS_TABLE: 3,
        CULTIVATION_PERIOD_GROUP: 7,
    },
    NDVI_STATUS: {
        NONE: -1,
        IN_PROGRESS: 0,
    },
    POLYGON_MARKER: {
        PROPERTY: {
            NAME: 'nev',
            CENTROID: 'centroid',
        },
        START_ZOOM: 13,
        MAX_ZOOM: 15,
    },
    GOOGLE_MAP_LIBS: {
        PLACES: 'places',
        DRAWING: 'drawing',
        GEOMETRY: 'geometry',
    },
    CALC_UNITS: {
        M2_TO_HA: 10000,
    },
    VALUE_FORMATS: {
        DRAWN_POLYGON_AREA_DECIMALS: 2,
    }
};
