/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable no-restricted-imports */
import { Nullable } from '@flowsCommon/services/baseTypes';
import { Loader } from '@googlemaps/js-api-loader';
import useTranslate from '@i18n/useTranslate';
import useFocusCenter from '@map/components/BackToCenter/useFocusCenter';
import { useMapContext } from '@map/services/mapContext';
import MapController from '@map/services/mapController';
import { BuiltInLayerTypes, MapMode } from '@map/services/mapEnums';
import { TMapStore } from '@map/services/mapStore';
import {
    LayerAttribute, LayerGeometriesWithBboxDto, LayerSelectorSettings, LayersDto, MapFilters, MapMultiModeProps, SecondaryLayer,
} from '@map/services/mapTypes';
import { sessionService } from '@services/sessionService';
import { partnerService } from '@services/partnerService';
import { registryNumberService } from '@services/registryNumberService';
import { companyActions, tableFilterActions } from '@states/actions';
import { Feature, Geometry, GeoJsonProperties } from 'geojson';
import {
    isEmpty, isEqual, isFunction, isNil, isNull, isNumber, lte,
} from 'lodash';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SETTINGS, INTERFACE_FILENAME } from 'shared/src/constants';
import { PlanningCultivationPeriod } from '@map/components/ContextMenu/modals/PlanningEditModal/planningEditModalTypes';
import MapScreenComponent from './MapScreenComponent';

export type MapEventListeners = {
    eventName: string;
    eventHandler: (event: any, map: google.maps.Map) => google.maps.MapsEventListener;
}

type LayerPanel = {
    withLayerPanel?: boolean;
    islayerPanelEnabledDefault?: boolean;
    isFileUploadAllowed?: boolean;
    isDefaultLayerTypeMandatory?: boolean;
}

export type MapProps = {
    setMapContext?: any,
    withDrawing?: boolean;
    withMeasureTool?: boolean;
    withCoordinates?: boolean;
    withInfoPanel?: boolean;
    withMachinePanel?: boolean;
    withLayers?: boolean;
    withSelector?: boolean;
    withBackToCenterButton?: boolean;
    withMapStyleModifier?: boolean;
    withMapSlider?: boolean;
    withMapSearch?: boolean;
    zoomEntityBBox?: google.maps.LatLngBounds,
    isMapRoute?: boolean;

    //selector related
    selectorDefaultEntityIds?: number[],
    onGeometrySelector?: (selectedEntityIds: number[]) => void;

    //mapModal reladted
    zoomEntityId?: number,
    withBackToGeom?: boolean,

    //filter layerSelector
    layerSelectorSettings?: LayerSelectorSettings,

    defaultMapFilter?: Partial<MapFilters>,
    layerType?: BuiltInLayerTypes;
    filteredLayerTypes?: BuiltInLayerTypes[];
    productionYearId?: number;
    entityId?: number;
    filterEntityIds?: number[];
    visibleEntityIds?: number[];
    excludedEntityIds?: number[];
    customFeatures?: Feature[];
    customFeatureEventListeners?: MapEventListeners[];

    setMapStore?: (mapStore: TMapStore) => void,

    initDrawnFeature?: Feature<Geometry, GeoJsonProperties>;
    //drawing related
    setSavedGeomFeature?: (feature: Feature<Geometry, GeoJsonProperties> | null) => void,
    setSavedGeomId?: (id: number) => void,
    onDrawingSaveClick?: () => void;
    parentId?: number;
    withDrawingSaveButton?: boolean;
    setZoomEntityBBoxCallback?: (bbox: google.maps.LatLngBounds) => void,

    layerPanelOptions?: LayerPanel;
    withCurrencySwitch?: boolean;

    onMapInit?: (map) => void;
    onMapCenterChanged?: (map: google.maps.Map) => void;

    withMapMultiMode?: MapMultiModeProps;
    withAutoCenterOnStart?: boolean;

    mapId?: number,
    withMapFilter?: boolean,

    mapMode?: MapMode,
    withSlovakOverlay?: boolean,
    gsaaYear?: number,
    gsaaKODKD?: string,
    secondaryLayerTypes?: SecondaryLayer[],
    defaultAttributesInitalizedCallback?: () => void;
    planningEditData?: PlanningCultivationPeriod[];
    planningEditDataRef?: RefObject<PlanningCultivationPeriod[] | undefined>;
    onSavePlanningEditModal?: (editedData: PlanningCultivationPeriod[]) => void;

    fetchers?: {
        layerFetcher: () => Promise<LayersDto>;
        geomFetcher: () => Promise<LayerGeometriesWithBboxDto>;
    }
}

const googleLoader = (apiKey: string) => new Loader({
    apiKey,
    // apiKey: 'AIzaSyC1tJ8SfjDZwZXcU7jfAXbk84sfTzl1jaY',
    version: 'weekly',
    libraries: ['places', 'geometry', 'drawing'],
});

// eslint-disable-next-line max-lines-per-function
/*eslint max-lines-per-function: ["error", 800]*/
export default function MapScreenContainer({
    setMapContext,
    withDrawing = false,
    withMeasureTool = true,
    withCoordinates = true,
    withInfoPanel = false,
    withMachinePanel = false,
    withSelector = false,
    layerPanelOptions = { withLayerPanel: false, isFileUploadAllowed: true, isDefaultLayerTypeMandatory: false },
    withLayers = true,
    withBackToCenterButton = true,
    withMapStyleModifier = true,
    withMapSearch = true,
    withMapSlider = false,
    isMapRoute = true,
    //selector related, defaultMapFilter.productionYearId must be -1
    selectorDefaultEntityIds,
    onGeometrySelector,

    //mapModal related,
    zoomEntityId,
    withBackToGeom = false,
    //filter layerSelector
    layerSelectorSettings,

    layerType = BuiltInLayerTypes.CULTIVATION_PERIOD,
    filteredLayerTypes = [],
    productionYearId,
    entityId,
    filterEntityIds,
    excludedEntityIds,
    visibleEntityIds,
    defaultMapFilter,
    initDrawnFeature,
    customFeatures,
    customFeatureEventListeners,
    setMapStore,
    //drawing related
    setSavedGeomFeature,
    setSavedGeomId,
    onDrawingSaveClick,
    withDrawingSaveButton = true,
    setZoomEntityBBoxCallback,

    parentId,
    withCurrencySwitch = true,

    onMapInit,
    onMapCenterChanged,

    withMapMultiMode,
    withAutoCenterOnStart = true,
    mapId = 1,
    withMapFilter = false,

    mapMode = MapMode.DEFAULT,
    withSlovakOverlay = false,
    gsaaYear,
    gsaaKODKD,
    secondaryLayerTypes = [],

    fetchers,
    planningEditData,
    onSavePlanningEditModal,
    defaultAttributesInitalizedCallback,
}: MapProps) {
    const [store, controller] = useMapContext();

    const dispatch = useDispatch();
    const domainName = useMemo(() => sessionService.getAgentName(), []);
    const countryCode = useSelector((state: any) => state.system.systemInfo.countryCode);

    const userId = useSelector((state: any) => state.user.user.id);
    const mapApiKey = useSelector((state: any) => state.company.company.map.googleMapApiKey);
    const currencies = useSelector((state: any) => state.company.company.currencies);
    const cultures = useSelector((state: any) => state.dataLists.cultures);
    const workOperationMainGroups = useSelector((state: any) => state.dataLists.workOperationMainGroups);
    const interfaceSettings = useSelector((state: any) => state.user.user.settings.interfaceSettings) ?? null;
    const { t } = useTranslate();

    const [temperatureUnitId] = useState(dispatch(companyActions.getSetting(SETTINGS.METEOROLOGY_TEMPERATURE_UNIT)));
    const [pressureUnitId] = useState(dispatch(companyActions.getSetting(SETTINGS.METEOROLOGY_ATMOSPHERIC_PRESSURE_UNIT)));
    const atmosphericPressureUnits = useSelector((state: any) => state.dataLists.atmosphericPressureUnits);
    const temperatureUnits = useSelector((state: any) => state.dataLists.temperatureUnits);

    const meteorologyUnits = useMemo(() => [
        temperatureUnits?.find(unitData => unitData.id === temperatureUnitId)?.name,
        atmosphericPressureUnits?.find(unitData => unitData.id === pressureUnitId)?.name,
    ], [temperatureUnitId, pressureUnitId]);

    const [isDeafaultLayersFetched, setIsDeafaultLayersFetched] = useState(false);
    const [isDefaultAttributeInitalized, setIsDefaultAttributeInitalized] = useState<boolean>(false);
    const [isZoomInitalized, setIsZoomInitalized] = useState<boolean>(false);

    const mapRef = useRef<HTMLDivElement>(null);

    const [zoomEntityBBox, setZoomEntityBBox] = useState<google.maps.LatLngBounds>();
    const visibleEntitiyIdsRef = useRef<number[]>([]);
    const [secondaryLayersLoaded, setSecondaryLayersLoaded] = useState(false);

    const [currentPlanningData, setCurrentPlanningData] = useState<PlanningCultivationPeriod[] | undefined>();
    const planningEditDataRef = useRef<PlanningCultivationPeriod[] | undefined>();

    const geomDataFetcher = async features => ({
        ok: true,
        data: {
            layerGeoms: [
                {
                    id: 1,
                    name: 'planningEdit',
                    geoms: {
                        type: 'FeatureCollection',
                        features,
                    },
                },
            ],
        },
    });

    const onPlanningEditSave = useCallback(async (editedData: PlanningCultivationPeriod[]) => {
        if (!editedData || !onSavePlanningEditModal || !planningEditDataRef.current) {
            return;
        }

        let newPlanningData: PlanningCultivationPeriod[] = [];

        planningEditDataRef.current.forEach(planningData => {
            if (editedData.some(editedPlanning => editedPlanning.id === planningData.id)) {
                return;
            }

            newPlanningData.push(planningData);
        });

        newPlanningData = [...newPlanningData, ...editedData];

        const planningEditLayerAttributes = store.layering.attributes?.filter(attribute => attribute.layerName === 'planningEdit');

        const features: any[] = [];
        planningEditLayerAttributes?.forEach(attribute => {
            attribute.dataLayer.forEach(feature => {
                const planningData = newPlanningData.find(planningEntityData => planningEntityData.entityId === feature.getId());

                if (planningData) {
                    feature.setProperty('cultureName', planningData?.cultureName);
                    feature.setProperty('culture', planningData?.cultureName);
                    feature.setProperty('cultureId', planningData?.cultureId);
                    feature.setProperty('technologyName', planningData?.technologyName);
                    feature.setProperty('productionTechnologyName', planningData?.technologyName);
                }

                feature.toGeoJson((f: any) => {
                    if (!features.some(featureFilter => featureFilter.id === f.id)) {
                        features.push(f);
                    }
                });
            });

            if (store.fetcher.layerFetcher) {
                controller.setFetchers(store.fetcher.layerFetcher, () => geomDataFetcher(features) as any);
            }
        });

        await controller.reloadLayers();
        // planningEditLayerAttributes?.forEach(attribute => {
        //     controller.relabelLayerAttributeLabels(attribute);
        //     controller.recalculateGlobalLabeling();
        // });

        planningEditDataRef.current = newPlanningData;
        onSavePlanningEditModal(newPlanningData);
    }, [planningEditDataRef.current, store.layering.attributes, controller]);

    useEffect(() => {
        if (!planningEditDataRef.current) {
            planningEditDataRef.current = planningEditData;
        }
    }, [planningEditData]);

    const focusCenter = useFocusCenter(store);
    useEffect(() => {
        const mapSliderInit = async () => {
            if (store.mapSlider.isEnabled) {
                const selectorSetting: LayerSelectorSettings = {
                    attributeFilter: {
                        whiteList: [],
                    },
                    layerFilter: {
                        blackList: null,
                    },
                };

                const activeLayerAttributes = store.layering.attributes?.filter(attr => attr.isActive);

                if (!isNil(selectorSetting?.attributeFilter.whiteList)) {
                    const attributesToRemove: LayerAttribute[] = [];
                    activeLayerAttributes?.forEach(attr => {
                        const layerWhitelists = selectorSetting.attributeFilter.whiteList?.filter(filter => filter.layerName === attr.layerName);
                        if (!isEmpty(layerWhitelists)) {
                            const attrWhiteList = layerWhitelists?.find(filter => filter.attributeKey === attr.attributeKey);
                            if (isNil(attrWhiteList)) {
                                attributesToRemove.push(attr);
                            }
                        }
                    });
                    if (!isNil(attributesToRemove)) {
                        await controller.removeLayerAttributesFromMap(attributesToRemove);
                    }
                }
                controller.setLayerSelectorSettings(selectorSetting);
            } else {
                controller.setLayerSelectorSettings(layerSelectorSettings);
            }
            controller.reloadLayers();
        };
        mapSliderInit();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layerSelectorSettings, controller, store.mapSlider.isEnabled]);

    useEffect(() => {
        controller.setMapId(mapId);
        controller.setMapMode(mapMode);
        if (fetchers?.layerFetcher) {
            controller.setFetchers(fetchers?.layerFetcher, fetchers?.geomFetcher);
        }
    }, []);

    useEffect(() => {
        if (!setMapContext) {
            return;
        }

        if (store && controller) {
            const { setStore, setController } = setMapContext;
            setStore(store);
            setController(controller);
        }
    }, [store, controller, setMapContext]);

    useEffect(() => {
        let isCancelled = false;

        const init = async () => {
            const google = await googleLoader(mapApiKey).load();

            if (!isCancelled) {
                controller.setGoogleRef(google);
            }
        };

        if (mapApiKey) {
            init();
        }

        return () => {
            isCancelled = true;
        };
    }, [controller, mapApiKey]);

    useEffect(() => {
        const primaryCurrencyId = dispatch(companyActions.getSetting(SETTINGS.PRIMARY_CURRENCY));
        const currencyName = currencies?.find(currency => currency.id === primaryCurrencyId)?.shortName;
        controller.setCommonData(currencyName, cultures, workOperationMainGroups, meteorologyUnits);
    }, [dispatch, currencies, controller, cultures, workOperationMainGroups, meteorologyUnits]);

    useEffect(() => {
        if (!domainName || !userId || store.gisAuth.isAvailable) {
            return;
        }

        controller.getGisToken(userId, domainName, countryCode ?? 'HU');
    }, [controller, domainName, userId, store.gisAuth.isAvailable, countryCode]);

    useEffect(() => {
        if (!store.googleRefs.google || store.googleRefs.map || !store.gisAuth.isAvailable) { return; }

        controller.getMapDefaultCenter();
    }, [store.googleRefs.google, store.googleRefs.map, controller, store.gisAuth.isAvailable]);

    useEffect(() => {
        const map = store.googleRefs.map;
        if (!isNil(visibleEntityIds) && !isNil(visibleEntitiyIdsRef.current) && map && (!isEqual(visibleEntityIds, visibleEntitiyIdsRef.current) || isEmpty(visibleEntityIds))) {
            store.layering.attributes?.filter(attribute => attribute.isActive && attribute.layerName === layerType)
                .forEach(attribute => {
                    attribute.dataLayer.forEach(feature => {
                        const featureEntityId = +(feature.getProperty('entityId') || 0);
                        if (!visibleEntityIds.includes(featureEntityId)) {
                            attribute.dataLayer.overrideStyle(feature, { visible: false });
                            visibleEntitiyIdsRef.current.push(featureEntityId);
                        } else if (visibleEntitiyIdsRef.current.includes(featureEntityId)) {
                            attribute.dataLayer.overrideStyle(feature, { visible: true });
                            visibleEntitiyIdsRef.current = visibleEntitiyIdsRef.current.filter(filterF => filterF !== featureEntityId);
                        }
                        // }
                    });
                    attribute.labelLayer.forEach((marker, index) => {
                        if (marker) {
                            const labelEntityId = marker.get('entityId');
                            if (!visibleEntityIds.includes(labelEntityId)) {
                                marker.setVisible(false);
                            } else {
                                marker.setVisible(true);
                            }
                        }
                    });
                });

            focusCenter();
        }
    }, [visibleEntityIds, store.googleRefs.map, focusCenter]);

    useEffect(() => {
        if (store.googleRefs.map || !store.googleRefs.google || !mapRef.current || !store.center.centerData) { return; }

        const { coordinates, zoom } = store.center.centerData;

        const mapSettings: google.maps.MapOptions = {
            streetViewControl: false,
            fullscreenControl: false,
            scaleControl: true,
            mapTypeId: google.maps.MapTypeId.HYBRID,
            mapTypeControl: false,
            zoomControl: false,
            rotateControl: false,
            tilt: 0,
            minZoom: store.zoomControl.minLevel,
            maxZoom: store.zoomControl.maxLevel,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
            },
            center: {
                lat: coordinates.lat,
                lng: coordinates.lng,
            },
            zoom,
            // ...overrideMapOptions,
        };

        const map = new google.maps.Map(
            mapRef.current, mapSettings,
        );

        if (isFunction(onMapInit)) {
            onMapInit(map);
        }

        controller.setMapRef(map);
    }, [controller, store.center, store.googleRefs, store.zoomControl, onMapInit]);

    //overried map styles
    useEffect(() => {
        if (!isNil(store.googleRefs)) {
            if (store.googleMapSettingsOverride.styles.length !== 0) {
                store.googleRefs.map?.setOptions({
                    styles: store.googleMapSettingsOverride.styles,
                });
            }
        }
    }, [store.googleRefs, store.googleMapSettingsOverride]);

    //component params and URL params merged, with URL param taking priority
    const [paramLayerName, paramEntityId, paramProductionYearId] = useMemo(() => {
        const searchParams = new URLSearchParams(window.location.search);

        if (!isNil(defaultMapFilter)) {
            if (!isNil(defaultMapFilter.productionYearId)) {
                searchParams.set('mapYearId', defaultMapFilter.productionYearId.toString());
            }
        }

        const filter = ['mapLayerName', 'mapEntityId', 'mapYearId'].map(paramKey => {
            const param = searchParams.get(paramKey);
            if (param) {
                return Number(param);
            }
            if (paramKey === 'mapLayerName' && layerType) {
                return layerType?.valueOf();
            }
            if (paramKey === 'mapYearId' && productionYearId) {
                return productionYearId;
            }
            if (paramKey === 'mapEntityId' && entityId) {
                return entityId;
            }
            // eslint-disable-next-line no-undefined
            return undefined;
        });

        return filter;
    }, [entityId, layerType, productionYearId, defaultMapFilter]);

    useEffect(() => {
        const addedMapEventListeners: google.maps.MapsEventListener[] = [];

        if (!customFeatures || !store.googleRefs.map || isEmpty(customFeatures)) {
            return;
        }

        customFeatures?.forEach(customFeature => {
            try {
                store.googleRefs?.map?.data.addGeoJson(customFeature);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        });

        if (!isEmpty(customFeatureEventListeners)) {
            customFeatureEventListeners?.forEach(eventListener => {
                const addedMapDataEventListener = store.googleRefs.map?.data?.addListener(eventListener.eventName, (event: any) => eventListener.eventHandler(event, store.googleRefs.map as google.maps.Map));
                if (addedMapDataEventListener) {
                    addedMapEventListeners.push(addedMapDataEventListener);
                }
            });
        }

        const bounds = new google.maps.LatLngBounds();
        store.googleRefs.map.data.forEach(feature => {
            feature.getGeometry()?.forEachLatLng(latLng => bounds.extend(latLng));
            store.googleRefs?.map?.data.overrideStyle(feature, { zIndex: 9999999 });
        });

        setTimeout(() => {
            store.googleRefs.map?.fitBounds(bounds);
        }, 0);

        return () => {
            addedMapEventListeners?.forEach(listener => listener.remove());
        };
    }, [customFeatureEventListeners, customFeatures, store.googleRefs.map]);

    useEffect(() => {
        if (setMapStore) {
            setMapStore(store);
        }
    }, [store, setMapStore]);

    const baseFilterIsSetRef = useRef(false);

    //init productionYear and set filters
    useEffect(() => {
        if (baseFilterIsSetRef.current === true && (withSelector || filterEntityIds || excludedEntityIds)) {
            return;
        }

        if (store.filters.productionYearId !== -1 || !layerType) {
            return;
        }

        if (isNil(paramProductionYearId) && isEmpty(store.filterInit)) {
            return;
        }

        const filters = {
            ...store.filters,
            ...store.filterInit,
            ...defaultMapFilter,
        };

        if (!isNil(paramProductionYearId)) {
            filters.productionYearId = Number(paramProductionYearId);
        }

        if ((excludedEntityIds || filterEntityIds) && withLayers && layerType) {
            filters.entity = {
                layerName: layerType.valueOf(),
                ids: [...(filterEntityIds || [])],
                excludedIds: [...(excludedEntityIds || [])],
            };
        }

        baseFilterIsSetRef.current = true;
        controller.setMapFilters(filters);
    }, [controller, dispatch, paramProductionYearId, filterEntityIds, layerType, withLayers, withSelector, excludedEntityIds, store.filterInit]);

    useEffect(() => {
        if (!store.gisAuth.isAvailable || !store.googleRefs.google || store.layering.layers || isNil(layerType) || isNil(store.mapMode) || isDeafaultLayersFetched || !baseFilterIsSetRef.current) { return; }

        controller.getEveryLayerAndAttribute(isMapRoute);
        setIsDeafaultLayersFetched(true);
    }, [controller, layerType, store.gisAuth.isAvailable, store.googleRefs.google, store.layering.layers, isMapRoute, baseFilterIsSetRef.current]);



    //filters ready, add layers to map
    useEffect(() => {
        async function setPlanningLabeling() {
            if (!store || !store.layering.attributes) {
                return;
            }
            const attr = { ...store.layering.attributes[0] };
            await controller.setLayerAttributeLabeling(attr, true);
            controller.relabelLayerAttributeLabels(attr);
        }

        if (isNil(store.mapId)) {
            return;
        }
        if (!isDefaultAttributeInitalized && store.layering.attributes && store.layering.layers && store.googleRefs.map && withLayers) {
            if (store.filters.productionYearId !== -1 || withSelector || filterEntityIds || excludedEntityIds) {
                const interfaceFileName = `${INTERFACE_FILENAME.MAP}_${store.mapId}_${window.location.pathname}`;

                const interfaceSettingsValue = interfaceSettings?.[interfaceFileName]?.mapSettings?.value ?? null;

                let defaultAttributes: LayerAttribute[] = [];

                if (!isEmpty(interfaceSettingsValue) && !isNil(interfaceSettingsValue)) {
                    const parsedMapSettings = JSON.parse(interfaceSettingsValue);
                    const activeLayerAttributes = parsedMapSettings.filter(attr => attr.isActive).map(attr => attr.id);
                    defaultAttributes = activeLayerAttributes?.map(activeAttributeId => store.layering.attributes?.find(attribute => attribute.id === activeAttributeId));
                }

                defaultAttributes = defaultAttributes?.filter(attribute => !isNil(attribute));

                if (isEmpty(defaultAttributes) || isNil(defaultAttributes)) {
                    defaultAttributes = store.layering.attributes.filter(attribute => attribute.layerName === paramLayerName && attribute.isDefault);
                }

                if (mapMode === MapMode.PLANNING) {
                    const selectedPlanId = sessionService.getPlanningData()?.selectedPlanId;
                    if (!isNil(selectedPlanId)) {
                        const planLayer = store.layering.layers.find(l => l.planId === selectedPlanId);
                        if (!isNil(planLayer)) {
                            const planAttribute = store.layering.attributes.find(attr => attr.layerId === planLayer.id && attr.isDefault);
                            if (!isNil(planAttribute)) {
                                defaultAttributes = [planAttribute];
                            }
                        }
                    }
                }

                if (mapMode === MapMode.PLANNING_EDIT) {
                    const planAttribute = store.layering.attributes?.[0];
                    if (!isNil(planAttribute)) {
                        defaultAttributes = [planAttribute];
                    }
                }

                controller.addLayerAttributesToMap(defaultAttributes, layerPanelOptions.withLayerPanel);

                if (mapMode === MapMode.PLANNING_EDIT) {
                    setPlanningLabeling();
                }
                if (!isNil(defaultAttributesInitalizedCallback) && isFunction(defaultAttributesInitalizedCallback)) {
                    defaultAttributesInitalizedCallback();
                }
                setIsDefaultAttributeInitalized(true);
            }
        }
    }, [
        controller,
        isDefaultAttributeInitalized,
        paramLayerName,
        store.filters.productionYearId,
        store.googleRefs.map,
        store.layering.attributes,
        store.layering.layers,
        layerPanelOptions.withLayerPanel,
        withLayers,
        withSelector,
        filterEntityIds,
        excludedEntityIds,
        interfaceSettings,
        store.mapId,
    ]);

    //reload layers if productionYear changed
    useEffect(() => {
        if (isDefaultAttributeInitalized && paramProductionYearId && store.filters.productionYearId !== Number(paramProductionYearId)) {
            const filters = {
                ...store.filters,
                productionYearId: Number(paramProductionYearId),
            };

            controller.setMapFiltersAndReloadGeometries(filters);
        }
    }, [controller, dispatch, isDefaultAttributeInitalized, paramProductionYearId, store.filters]);

    useEffect(() => {
        let isCancelled = false;

        if (secondaryLayersLoaded) {
            return;
        }

        if (!isDefaultAttributeInitalized && !isEmpty(secondaryLayerTypes)) {
            return;
        }

        const loadSecondaryLayers = async () => {
            for (const secondaryLayer of secondaryLayerTypes) {
                if (isCancelled) {
                    return;
                }
                const defaultAttributeOfSecondaryLayer = store.layering.attributes?.find(attr => attr.layerName === secondaryLayer.layerName && attr.isDefault);
                if (!isNil(defaultAttributeOfSecondaryLayer)) {
                    const secondaryFilters: MapFilters = {
                        ...store.filters,
                        productionYearId: secondaryLayer.productionYear,
                        entity: {
                            layerName: secondaryLayer.layerName,
                            ids: secondaryLayer.filter.entityIds,
                            excludedIds: [],
                        },
                    };
                    // eslint-disable-next-line no-await-in-loop
                    await controller.addLayerAttributesToMap([defaultAttributeOfSecondaryLayer], false, secondaryFilters);
                }

                if (secondaryLayerTypes.indexOf(secondaryLayer) === secondaryLayerTypes.length - 1) {
                    setSecondaryLayersLoaded(true);
                }
            }
        };

        loadSecondaryLayers();

        return () => {
            isCancelled = true;
        };
    }, [isDefaultAttributeInitalized]);

    //hide entities if filteredEntityIds changed
    useEffect(() => {
        if (store.layering.loading || (!filterEntityIds && !excludedEntityIds) || isNil(layerType) || (isEqual(store.filters.entity?.ids, filterEntityIds || []) && isEqual(store.filters.entity?.excludedIds, excludedEntityIds || []))) {
            return;
        }

        controller.setMapFilters({
            ...store.filters,
            entity: {
                layerName: layerType.valueOf(),
                ids: [...(filterEntityIds || [])],
                excludedIds: [...(excludedEntityIds || [])],
            },
        });

        if (!isEmpty(filterEntityIds) && filterEntityIds) {
            store.layering.attributes?.filter(attribute => attribute.isActive && attribute.layerName === layerType.valueOf())
                .forEach(attribute => {
                    attribute.dataLayer.forEach(feature => {
                        if (!filterEntityIds.includes(feature.getProperty('entityId'))) {
                            attribute.dataLayer.remove(feature);
                        }
                    });
                    attribute.labelLayer.forEach((marker, index) => {
                        if (marker && !filterEntityIds.includes(marker.get('entityId'))) {
                            attribute.labelLayer.removeAt(index);
                            marker.unbindAll();
                            marker.setMap(null);
                        }
                    });
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, filterEntityIds, layerType, store.layering.attributes, store.layering.loading, store.filters.entity, excludedEntityIds]);

    //zoom once when bbox is available
    useEffect(() => {
        if (!withAutoCenterOnStart) {
            return;
        }
        const bbox = store.center.bbox;

        if (!bbox || bbox.length === 0 || !store.googleRefs.map || !store.layering.layers) {
            return;
        }

        const bounds = new google.maps.LatLngBounds();
        if (paramEntityId && paramLayerName) {
            const activeAttribute = store.layering.attributes?.find(attribute => attribute.layerName === paramLayerName && attribute.isDefault);

            activeAttribute?.dataLayer.forEach(feature => {
                if (Number(feature.getProperty('entityId')) === paramEntityId) {
                    feature.getGeometry()?.forEachLatLng(latLng => bounds.extend(latLng));
                }
            });
        }
        if (bbox && bounds.isEmpty() && isNumber(bbox?.[0])) {
            bounds.extend({
                lat: bbox[1],
                lng: bbox[0],
            });
            bounds.extend({
                lat: bbox[3],
                lng: bbox[2],
            });
        }
        if (!bounds.isEmpty() && !isZoomInitalized && withDrawingSaveButton) {
            if (bounds.getNorthEast().lat() === 1 && bounds.getNorthEast().lng() === 180 && bounds.getSouthWest().lat() === -1 && bounds.getSouthWest().lng() === 180) {
                if (!isNil(store.center.centerData) && !isNil(store.center.centerData?.coordinates)) {
                    store.googleRefs.map.setCenter(store.center.centerData.coordinates);
                }
            } else {
                store.googleRefs.map.fitBounds(bounds);
                setZoomEntityBBox(bounds);
            }
        }
        setIsZoomInitalized(true);
    }, [store.center.bbox, isZoomInitalized, store.googleRefs.map, store.layering.layers, paramEntityId, paramLayerName, store.layering.attributes, store.filters, withDrawingSaveButton]);

    return (
        <MapScreenComponent
            withDrawing={withDrawing}
            withMeasureTool={withMeasureTool}
            withCoordinates={withCoordinates}
            withBackToCenterButton={withBackToCenterButton}
            withInfoPanel={withInfoPanel}
            withMachinePanel={withMachinePanel}
            layerPanelOptions={layerPanelOptions}
            withLayers={withLayers}
            withMapSlider={withMapSlider}
            withSelector={withSelector}
            withMapStyleModifier={withMapStyleModifier}
            selectorDefaultEntityIds={selectorDefaultEntityIds}
            layerType={layerType}
            zoomEntityId={zoomEntityId}
            zoomEntityBBox={zoomEntityBBox}
            withBackToGeom={withBackToGeom}
            filteredLayerTypes={filteredLayerTypes}
            productionYearId={productionYearId}
            entityId={entityId}
            filterEntityIds={filterEntityIds}
            excludedEntityIds={excludedEntityIds}
            initDrawnFeature={initDrawnFeature}
            setSavedGeomFeature={setSavedGeomFeature}
            setSavedGeomId={setSavedGeomId}
            onDrawingSaveClick={onDrawingSaveClick}
            onGeometrySelector={onGeometrySelector}
            withDrawingSaveButton={withDrawingSaveButton}
            parentId={parentId}
            mapRef={mapRef}
            withSlovakOverlay={withSlovakOverlay}
            setZoomEntityBBoxCallback={setZoomEntityBBox}
            withCurrencySwitch={withCurrencySwitch}
            onMapCenterChanged={onMapCenterChanged}
            withMapMultiMode={withMapMultiMode}
            withMapFilter={withMapFilter}
            mapMode={mapMode}
            isMapRoute={isMapRoute}
            planningEditDataRef={planningEditDataRef}
            onSavePlanningEditModal={onPlanningEditSave}
            withMapSearch={withMapSearch}
            gsaaYear={gsaaYear}
            gsaaKODKD={gsaaKODKD}
        />
    );
}
