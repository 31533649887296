import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Label from '@baseComponents/formParts/Label';
import MultiSelect from '@baseComponents/multiselect/MultiSelect';
import { companyActions } from '@states/actions/companyActions';
import { useFormikContext } from 'formik';
import { SETTINGS } from 'shared/src/constants';
import { modalActions } from '@states/actions';
import useTranslate from '@i18n/useTranslate';
import useLoadFieldFeaturesData from './useLoadFieldFeaturesData';

export default function FieldFeaturesMultiSelect(props) {
    const { className } = props;
    const { t } = useTranslate();
    const { values } = useFormikContext();
    const dispatch = useDispatch();
    const [isNonAccentSearch] = useState(dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)));

    useLoadFieldFeaturesData();

    return (
        <>
            <Label label={t('fieldFeature.feature', 'Feature')} className={className} />
            <MultiSelect
                dataList={values.fieldFeatures}
                hasSearchInput
                hasMultiSelectHeader
                isNonAccentSearch={isNonAccentSearch}
                onChange={() => dispatch(modalActions.setPreventCloseDialog(true))}
            />
        </>
    );
}
