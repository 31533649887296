import { SETTINGS } from '../constants';

export const GENERAL = {
    MODULE_ID: 20,
    NAME: 'general',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalIndex',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'AGROVIR',
            },
        },
        CULTURES: {
            CONTENT_URL_ID: { cultures: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalCultures',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.generalCultures',
                TRANSLATOR_DEFAULT: 'Cultures',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        VARIETIES: {
            CONTENT_URL_ID: { varieties: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalVarieties',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.generalVarieties',
                TRANSLATOR_DEFAULT: 'Varieties',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        CURRENCIED: {
            CONTENT_URL_ID: { currencies: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalCurrencies',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.generalCurrencies',
                TRANSLATOR_DEFAULT: 'Currencies',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        TYPES_OF_CROP: {
            CONTENT_URL_ID: { typesOfCrop: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalTypesOfCrop',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.generalTypesOfCrop',
                TRANSLATOR_DEFAULT: 'Types of crop',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        FARMS: {
            CONTENT_URL_ID: { farms: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalFarms',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.generalFarms',
                TRANSLATOR_DEFAULT: 'Farms',
            },
            SETTINGS: [
                { [SETTINGS.USE_GLOBAL_LITE]: 0 }
            ],
        },
        CURRENCY: {
            CONTENT_URL_ID: { currency: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalCurrency',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.generalCurrency',
                TRANSLATOR_DEFAULT: 'Exchange rates',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        TRANSLATION: {
            CONTENT_URL_ID: { translation: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalTranslation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.generalTranslation',
                TRANSLATOR_DEFAULT: 'Translation',
            },
        },
        LANG_CONFIG: {
            CONTENT_URL_ID: { langConfig: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalLangConfig',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.generalLangConfig',
                TRANSLATOR_DEFAULT: 'System language',
            },
        },
        SURFACE_CUSTOMIZATION: {
            CONTENT_URL_ID: { surfaceCustomization: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalSurfaceCustomization',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.generalSurfaceCustomization',
                TRANSLATOR_DEFAULT: 'Surface customization',
            },
        },
        SUBSCRIPTION: {
            CONTENT_URL_ID: { subscription: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'subscription',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.subscription',
                TRANSLATOR_DEFAULT: 'Előfizethető szolgáltatások',
            },
        },
        SUBSCRIPTION_WORKS: {
            CONTENT_URL_ID: { subscriptionWorks: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'subscriptionWorks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.subscriptionWorks',
                TRANSLATOR_DEFAULT: 'AgroVIR WORKS előfizetés kezelése',
            },
        },
        SUBSCRIPTION_CONNECT: {
            CONTENT_URL_ID: { subscriptionConnect: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'subscriptionConnect',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.subscriptionConnect',
                TRANSLATOR_DEFAULT: 'AgroVIR CONNECT előfizetés kezelése',
            },
        },
        SUBSCRIPTION_DATA_ANALYSIS: {
            CONTENT_URL_ID: { subscriptionDataAnalysis: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'subscriptionDataAnalysis',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.subscriptionDataAnalysis',
                TRANSLATOR_DEFAULT: 'AgroVIR ADVANCER szolgáltatás',
            },
        },
        METEOROLOGY: {
            CONTENT_URL_ID: {
                meteorology: 17,
            },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'meteorology',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.meteorology',
                TRANSLATOR_DEFAULT: 'Meteorology',
            },
            SHOW_IN_PANNING_MODE: true,
            SETTINGS: [
                { [SETTINGS.USE_GLOBAL_LITE]: 0 }
            ],
        },
    },
};
