import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    dot: {
        borderRadius: '50%',
        height: 10,
        width: 10,
        border: props => {
            switch (props.status) {
                case 'slow':
                    return theme.border.status.slow;
                case 'stop':
                    return theme.border.status.stop;
                default:
                    return theme.border.status.go;
            }
        },
        backgroundColor: props => {
            switch (props.status) {
                case 'slow':
                    return theme.color.slow;
                case 'stop':
                    return theme.color.stop;
                default:
                    return theme.color.go;
            }
        },
        boxShadow: props => {
            switch (props.status) {
                case 'slow':
                    return theme.shadows.status.slow;
                case 'stop':
                    return theme.shadows.status.stop;
                default:
                    return theme.shadows.status.go;
            }
        },
    },
}));

export default function StatusCell(props) {
    //const { value, rowData } = props;
    const status = 'slow';
    const classes = useStyles({ status });

    return (
        <div className={classes.baseStyle}>
            <span className={classes.dot} />
        </div>

    );
}
