/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import EditProfile from './EditProfile';
import UserMenuFooter from './UserMenuFooter';

import UserMenuHeader from './UserMenuHeader';
import UserMenuContent from './UserMenuContent';

const useStyles = createUseStyles((theme: any) => ({
    fade: {
        display: (props: any) => (props.showUserMenu || props.showEditProfile ? 'block' : 'none'),
        opacity: '0.8 !important',
        top: 0,
        left: (props: any) => (props.expanded ? 600 : 420),
        right: 0,
        bottom: '100% !important',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        backgroundColor: theme.color.jet,
    },
    userMenu: {
        position: 'absolute',
        left: (props: any) => (props.expanded ? 240 : 60),
        top: 0,
        height: '100vh',
        width: 360,
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 1.43,
        color: theme.color.jet,
        backgroundColor: theme.color.lilian,
        display: (props: any) => (props.showUserMenu ? 'flex' : 'none'),
        flexDirection: 'column',
        zIndex: theme.zIndex.sideNavBar,
    },
}));

export default function UserMenu(props) {
    const { showUserMenu, openUserMenu, closeUserMenu, expanded, defaultInfoLoaded } = props;
    const [showEditProfile, setShowEditProfile] = useState(false);
    const classes = useStyles({ showUserMenu, showEditProfile, expanded });

    function closeEditProfile() {
        setShowEditProfile(false);
        openUserMenu();
    }

    return (
        <>
            <div className={classes.fade} onClick={closeUserMenu} role="button" />
            <div className={classes.userMenu}>
                <UserMenuHeader setShowEditProfile={setShowEditProfile} closeUserMenu={closeUserMenu} />
                <UserMenuContent defaultInfoLoaded={defaultInfoLoaded} closeUserMenu={closeUserMenu} />
                <UserMenuFooter closeUserMenu={closeUserMenu} />
            </div>
            {showEditProfile && <EditProfile showEditProfile={showEditProfile} setShowEditProfile={setShowEditProfile} closeEditProfile={closeEditProfile} expanded={expanded} />}
        </>
    );
}
