import { Nullable } from '@flowsCommon/services/baseTypes';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { Layer } from '@map/services/mapTypes';
import { getLayerCaptionString } from '@map/utils/mapCaptionUtils';
import { getIsCustomLayer } from '@map/utils/mapUtils';
import * as apis from '@map/services/mapApis';
import FileSaver from 'file-saver';
import { useCallback, useMemo, useState } from 'react';
import { SETTINGS } from 'shared/src/constants';
import { dateTimeUtils } from 'shared/src/modules';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNil } from 'lodash';
import { alertActions, companyActions } from '@states/actions';

export default function useLayerExportModalData() {
    const [mapStore, mapController] = useMapContext();
    const { t, translater } = useTranslate();
    const dispatch = useDispatch();
    const [layerName, setLayerName] = useState<Nullable<string>>();
    const [layerId, setLayerId] = useState(-1);
    const [productionYearId, setProductionYearId] = useState(-1);
    const [startDate, setStartDate] = useState<Nullable<string>>();
    const [endDate, setEndDate] = useState<Nullable<string>>();
    const [usingFarm] = useState(dispatch(companyActions.getSetting(SETTINGS.USING_FARM)));

    const periodList = useSelector((state: any) => state.dataLists.productionYears);
    const activeCompanyList = useSelector((state: any) => state.company.activeCompanyList);
    const activeFarmList = useSelector((state: any) => state.dataLists.farms);

    const periodDataList = periodList?.map((period: any) => ({ key: period?.id, value: period?.period }));
    const activeCompanyIds = activeCompanyList?.map((company: any) => company?.id) ?? [];
    const activeCompanyNames = activeCompanyList?.map((company: any) => company?.name)?.join(',') ?? null;

    const activeFarmIds = activeFarmList?.map((farm: any) => farm?.id) ?? [];
    const activeFarmNames = activeFarmList?.map((farm: any) => farm?.name)?.join(',') ?? null;

    const [companyIds, setCompanyIds] = useState<number[]>(activeCompanyIds);
    const [companyNames, setCompanyNames] = useState<Nullable<string>>(activeCompanyNames);

    const [farmIds, setFarmIds] = useState<number[]>([] ?? activeFarmIds);
    const [farmNames, setFarmNames] = useState<Nullable<string>>('' ?? activeFarmNames);

    const [showClosedPeriods, setShowClosedPeriods] = useState<boolean>(false);

    const isFarmDisabled = useMemo(() => {
        if (!usingFarm) {
            return true;
        }

        const layer = mapController.getLayerById(layerId);
        if (!isNil(layer)) {
            return ![
                BuiltInLayerTypes.CULTIVATION_PERIOD,
                BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP,
                BuiltInLayerTypes.LOT,
            ].includes(layer.name as BuiltInLayerTypes);
        }
        return true;
    }, [layerId, mapController, usingFarm]);

    function getLayerProps(layer: Layer) {
        return {
            layerName: getLayerCaptionString(translater, layer),
            id: layer.id,
            type: layer.type,
            isCustomLayer: getIsCustomLayer(layer),
            parentName: mapStore.layering.layers?.find(parentLayer => parentLayer.id === layer.parentId)?.name || '',
        };
    }

    const selectableLayers = mapStore.layering.layers
        ?.map(layer => getLayerProps(layer))
        ?.filter(layer => layer?.type === 'FEATURE' && layer.layerName !== 'Unnamed layer' && layer.layerName !== BuiltInLayerTypes.MACHINES);

    const isCustomLayer = mapController.getLayerIsCustomLayer(layerId);

    const isProductionYearDisabled = useMemo(() => {
        const layer = mapController.getLayerById(layerId);
        if (!isNil(layer)) {
            return ![
                BuiltInLayerTypes.CULTIVATION_PERIOD,
                BuiltInLayerTypes.LPIS_FIELD,
                BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP,
            ].includes(layer.name as BuiltInLayerTypes);
        }
        return true;
    }, [layerId, mapController]);

    const onDownload = useCallback(async () => {
        try {
            const reqBody: any = {
                companyIds: companyIds?.join(','),
                farmIds: (!isFarmDisabled && farmIds) ? farmIds?.join(',') : null,
                layerId,
            };
            if (+productionYearId > 0) {
                const prodYearName = periodList?.find((period: any) => +period.id === +productionYearId)?.period;

                reqBody.productionYearId = productionYearId;
                reqBody.productionYearName = prodYearName;

                if (showClosedPeriods) {
                    reqBody.includeClosedCultivationPeriods = true;
                }
            }

            const res = await apis.getShapefileOfGeometires(reqBody);
            const currentTime = dateTimeUtils.getLongDateFormat();
            const fileName = `${dateTimeUtils.getCustomDateFormat(currentTime)}_${layerName}.zip`;

            if (res.ok) {
                if (res.data.size > 0) {
                    dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved')));
                    FileSaver.saveAs(res.data, fileName);
                } else {
                    dispatch(alertActions.warningAlert(t('map.exportLayerTextError', 'No geometries were found')));
                }
            } else {
                dispatch(alertActions.errorAlert(t('default.failedSave', 'Failed to save')));
            }
        } catch (error) {
            console.error(error);
        }
    }, [companyIds, isFarmDisabled, farmIds, layerId, productionYearId, layerName, periodList, showClosedPeriods, dispatch, t]);

    return {
        layerName,
        layerId,
        selectableLayers,
        isCustomLayer,
        productionYearId,
        startDate,
        endDate,
        isProductionYearDisabled,
        isDownloadDisabled: !(layerId > -1 && (isProductionYearDisabled || productionYearId > 0) && !isEmpty(companyIds)),
        companyIds,
        companyNames,
        farmIds,
        farmNames,
        showClosedPeriods,
        periodDataList,
        isFarmDisabled,
        usingFarm,
        setLayerName,
        setLayerId,
        setProductionYearId,
        setStartDate,
        setEndDate,
        setCompanyIds,
        setCompanyNames,
        setFarmIds,
        setFarmNames,
        setShowClosedPeriods,
        onDownload,
    };
}
