import { useMemo } from 'react';
import { clone, isEmpty, values } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import { simpleTableActions } from '@states/actions';
import useTranslate from '@i18n/useTranslate';
import CultivationPeriodsModal from '@customComponents/modals/cultivationPeriodsModal/CultivationPeriodsModal';
import { useFormContext } from 'react-hook-form';
import { NUMBERS } from 'shared/src/constants';

const cellFunctions = {
    updateRowInMemory: (rowData, columnId, updateValue, getValues) => {
        const rowId = rowData.rowId;
        const otherRow = getValues('soilAnalysisData')?.find(tableRow => tableRow.rowId === rowId);
        if (otherRow) {
            otherRow[columnId] = updateValue;
        }
        if (rowData) {
            rowData[columnId] = updateValue;
        }
    },

    updateRow: (rowId, columnId, updateValue, tableKey, dispatch, rowData, setValue) => {
        if (!tableKey) {
            return;
        }
        const tableData = dispatch(simpleTableActions.getTableDataList(tableKey));
        const clonedTableData = clone(tableData);
        const updatedRow = tableData?.find(tableRow => tableRow.rowId === rowId);
        const selectedRowIds = dispatch(simpleTableActions.getSelectedRowIds(tableKey)) ?? [];
        const otherRows = clonedTableData?.filter(tableRow => tableRow.id !== rowId && selectedRowIds?.includes(tableRow.rowId));
        const valueBeforeUpdate = rowData[columnId];

        if (updatedRow && columnId) {
            rowData[columnId] = updateValue;
            updatedRow[columnId] = updateValue;
        }

        if (updateValue === valueBeforeUpdate || isEmpty(selectedRowIds) || !selectedRowIds.includes(rowId)) {
            setValue('soilAnalysisData', clonedTableData);
            return;
        }

        otherRows.forEach(row => {
            row[columnId] = updateValue;
        });

        dispatch(simpleTableActions.setTableData(tableKey, clonedTableData));
        setValue('soilAnalysisData', clonedTableData);
    },
};

export default function useSoilAnalysisTable(props) {
    const { tableColumns, tableId } = props;
    const cultivationPeriodList = useSelector((state: any) => state.basePage.table.tableData);
    const { t } = useTranslate();
    const { setValue, getValues } = useFormContext();
    const dispatch = useDispatch();

    const defaultColumns = useMemo(() => {
        const soilAnalysisColumns = [
            {
                type: columnName.modalAddButton,
                columnId: 'cultivationPeriod',
                columnName: t('soilAnalysisImport.cultivationPeriod', 'Cultivation period'),
                isLeftFixed: true,
                width: 200,
                minWidth: 200,
                maxWidth: 200,
                componentProps: {
                    line1Id: 'name',
                    modalComponent: CultivationPeriodsModal,
                    updateValue: (value, _cellData, rowData) => {
                        cellFunctions.updateRow(rowData.rowId, 'cultivationPeriod', value, tableId, dispatch, rowData, setValue);
                        cellFunctions.updateRow(rowData.rowId, 'cultivationPeriodCompanyName', value?.companyName, tableId, dispatch, rowData, setValue);
                    },
                    modalComponentProps: {
                        isMultiselect: false,
                        rowFormatFnc: period => ({
                            id: period.id,
                            name: period.name,
                            companyName: period.companyName,
                        }),
                    },
                    isRequired: true,
                    getDataList: () => cultivationPeriodList?.map(cultivationPeriod => ({ key: cultivationPeriod.id, value: cultivationPeriod.name })),
                },
            },
            {
                type: columnName.simpleText,
                columnName: t('soilAnalysisImport.company', 'Company name'),
                columnId: 'cultivationPeriodCompanyName',
                isLeftFixed: true,
                width: 200,
                minWidth: 200,
                maxWidth: 200,
            },
            {
                type: columnName.quantityInput,
                columnId: 'depth',
                columnName: t('soilAnalysisExcelImport.depth', 'Depth'),
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                componentProps: {
                    quantityFormat: {
                        decimals: NUMBERS.DEFAULT.DECIMAL_NUMBERS,
                        decimalSeparator: NUMBERS.DEFAULT.DECIMAL_SEPARATOR,
                        thousandsSeparator: NUMBERS.DEFAULT.THOUSAND_SEPARATOR,
                        unit: t('default.centimeter', 'cm'),
                    },
                    onBlur: (value, rowData, columnId) => {
                        if (!isEmpty(dispatch(simpleTableActions.getSelectedRowIds(tableId)))) {
                            cellFunctions.updateRow(rowData.rowId, columnId, value, tableId, dispatch, rowData, setValue);
                        } else {
                            cellFunctions.updateRowInMemory(rowData, columnId, value, getValues);
                        }
                    },
                },
            },
        ];

        if (!isEmpty(tableColumns)) {
            values(tableColumns)?.forEach(column => {
                if (['depth', 'cultivationPeriod'].includes(column.sqlName)) {
                    return;
                }

                soilAnalysisColumns.push({
                    type: columnName.simpleText,
                    columnId: column.sqlName,
                    columnName: column.name,
                    width: 100,
                    minWidth: 100,
                    maxWidth: 100,
                });
            });
        }

        return soilAnalysisColumns;
    }, [cultivationPeriodList, dispatch, setValue, t, tableColumns, tableId]);

    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    return {
        columns,
    };
}
