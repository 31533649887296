import { SimpleSelect } from '@baseComponents/select';
import { ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';
import Map from '@map/screens/Map';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { BuiltInLayerTypes } from '@map/services/mapEnums';

type Classes = 'container' | 'selectorContainer' | 'select';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        position: 'relative',
    },
    selectorContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        width: 150,
        background: 'transparent !important',
        display: 'flex',
        justifyContent: 'right',
        zIndex: 1000,
        padding: [30, 10, 4, 4],
    },
    select: {
        width: 150,
    },
}));

export type MapWidgetProdYearSelectorItem = {
    key: string,
    value: string,
}

export function MapWidgetComponent() {
    const periodYears = useSelector((state: any) => state.dataLists.productionYears);
    const [selectorYears, setSelectorYears] = useState<MapWidgetProdYearSelectorItem[]>();
    const [defaultYear, setDefaultYear] = useState();
    const [selectedYear, setSelectedYear] = useState();

    useEffect(() => {
        if (isEmpty(periodYears)) {
            return;
        }

        const years = periodYears?.map(year => ({ key: year.id, value: year.period }));
        const sortedYears = periodYears?.sort((yearA, yearB) => yearB.year - yearA.year);
        const defYear = sortedYears?.[0]?.id;

        if (years) {
            setSelectorYears(years);
        }
        if (defYear) {
            setDefaultYear(defYear);
            setSelectedYear(defYear);
        }
    }, [periodYears]);

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.selectorContainer}>
                <SimpleSelect
                    dataList={selectorYears}
                    selected={defaultYear}
                    setSelected={(id: any) => { setSelectedYear(id); }}
                    className={classes.select}
                    iconId="icon-calendar"
                />
            </div>
            {
                selectedYear && <Map
                    withInfoPanel
                    layerPanelOptions={{
                        withLayerPanel: true,
                        islayerPanelEnabledDefault: false,
                    }}
                    defaultMapFilter={{ productionYearId: selectedYear }}
                    layerType={BuiltInLayerTypes.CULTIVATION_PERIOD}
                    withMeasureTool={false}
                    withCurrencySwitch={false}
                    withMapSearch={false}
                />
            }
        </div>);
}
