import { Nullable } from '@flowsCommon/services/baseTypes';
import Map from '@map/screens/Map';
import LayerCreateController from '@map/services/layerCreate/layerCreateController';
import { TLayerCreateStore } from '@map/services/layerCreate/layerCreateStore';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import useLayerUploadModalPairData from './useLayerUploadModalPairMapData';

export type LayerUploadModalPairMapProps = {
    store: TLayerCreateStore;
    controller: LayerCreateController;
    currentModalComponent: Nullable<JSX.Element>;
    currentModalComponentProps?: any;
    isCustomLayerUpload: boolean;
    doAlert: () => void;
    pairableEntityIds: number[];
}

export type TMarkers = {
    [key: string | number]: Nullable<google.maps.Marker>;
}

export default function LayerUploadModalPairMap(props: LayerUploadModalPairMapProps) {
    const { store, isCustomLayerUpload, doAlert } = props;
    const { dataEventListeners, setMapStore } = useLayerUploadModalPairData(props);
    const { productionYearId, selectedLayerId } = store.upload;

    const [mapContext] = useMapContext();

    return (
        <Map
            withMapStyleModifier
            withMeasureTool={false}
            withCurrencySwitch={false}
            withMapSearch={false}
            customFeatureEventListeners={!isCustomLayerUpload ? dataEventListeners : undefined}
            customFeatures={store.upload.featureCollection?.features}
            setMapStore={setMapStore}
            withLayers
            withDrawingSaveButton={false}
            layerType={BuiltInLayerTypes.EMPTY}
            excludedEntityIds={store?.upload?.pairableEntities?.map(pariableEntity => pariableEntity.entityId) ?? []}
            // filteredLayerTypes={selectedLayerId ? [+selectedLayerId] : []}
            layerPanelOptions={{ withLayerPanel: true, isFileUploadAllowed: false, isDefaultLayerTypeMandatory: false }}
            productionYearId={productionYearId || mapContext.filters.productionYearId}
        />
    );
}
