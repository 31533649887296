import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useTableColumnWidthsInitValues(props) {
    const { isBasePageTable, tableKey } = props;

    const columns = useSelector((state: any) => state.basePage.table.columns);
    const customColumns = useSelector((state: any) => state.basePage.table.customColumns);
    const isEnabledSearching = useSelector((state: any) => state.basePage.table.isEnabledSearching);

    const simpleTableColumns = useSelector((state: any) => state.simpleTable.table[tableKey]?.columns);
    const simpleTableCustomColumns = useSelector((state: any) => state.simpleTable.table[tableKey]?.customColumns);
    const simpleTableIsEnabledSearching = useSelector((state: any) => state.simpleTable.table[tableKey]?.isEnabledSearching);

    const [initValues, setInitValues] = useState({
        columns: null,
        customColumns: null,
        isEnabledSearching: null,
    });

    //init basePage table data
    useEffect(() => {
        if (isBasePageTable) {
            setInitValues({
                columns,
                customColumns,
                isEnabledSearching,
            });
        }
    }, [isBasePageTable, columns, customColumns, isEnabledSearching]);

    //init simple table data
    useEffect(() => {
        if (!isBasePageTable) {
            setInitValues({
                columns: simpleTableColumns,
                customColumns: simpleTableCustomColumns,
                isEnabledSearching: simpleTableIsEnabledSearching,
            });
        }
    }, [isBasePageTable, simpleTableColumns, simpleTableCustomColumns, simpleTableIsEnabledSearching]);

    return initValues;
}
