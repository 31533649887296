export enum MapEvents {
    BoundsChanged = 'bounds_changed',
    Idle = 'idle',
    TilesLoaded = 'tilesloaded',
}

export enum MapMode {
    DEFAULT = 0,
    PLANNING = 1,
    PLANNING_EDIT = 2,
}

export enum BuiltInLayerIds {
    EMPTY = 0,
    LOT = 1,
    CULTIVATION_PERIOD = 2,
    LPIS_FIELD = 3,
    REGISTRY_NUMBER = 4,
    PREMISE = 5,
    STABLE = 6,
    CULTIVATION_PERIOD_GROUP = 7,
}

export enum BuiltInLayerTypes {
    EMPTY = '0',
    AGROVIR = 'agrovir',
    LOT = 'lot',
    CULTIVATION_PERIOD = 'cultivationPeriod',
    LPIS_FIELD = 'lpisField',
    REGISTRY_NUMBER = 'registryNumber',
    PREMISE = 'premise',
    STABLE = 'stable',
    CULTIVATION_PERIOD_GROUP = 'cultivationPeriodGroup',
    MACHINES = 'machine',
    OTHER = 'other',
    METEOROLOGY = 'meteorology',
    IRRIGATION = 'irrigation',
    PLANNING = 'planning',
    SCOUTINGS = 'scoutings',
    RECEIVED_SCOUTINGS = 'receivedScoutings',
    SENT_TASK = 'sentTasks',
}

export enum BuiltInAttributeTypes {
    LAST_WORK_OPERATION_MAIN_GROUP = 'lastWorkOperationMainGroup',
    COMPLETED_AREA_PERCENTAGE = 'completedAreaPercentage',
    TOTAL_COST_SERVICE_PER_HECTARE = 'totalCostServicePerHectare',
    DIRECT_COST_PER_HECTARE = 'directCostPerHectare',
    PRODUCT_COST_PER_HECTARE = 'productCostPerHectare',
    MACHINE_COST_PER_HECTARE = 'machineCostPerHectare',
    WAGE_COST_PER_HECTARE = 'wageCostPerHectare',
    OPERATION_COST_PER_HECTARE = 'operationCostPerHectare',
    TOTAL_COST_PER_HECTARE = 'totalCostPerHectare',
    YIELD = 'yield',
    TEMPERATURE = 'temperature',
    ATMOSPHERIC_PRESSURE = 'atmosphericPressure',
    SOIL_TEMPERATURE = 'soilTemperature',
    DATE_TIME = 'dateTime',
}

export function isAttributeWithCost(attributeKey: string) {
    const costAttributes = [
        BuiltInAttributeTypes.TOTAL_COST_SERVICE_PER_HECTARE,
        BuiltInAttributeTypes.DIRECT_COST_PER_HECTARE,
        BuiltInAttributeTypes.DIRECT_COST_PER_HECTARE,
        BuiltInAttributeTypes.PRODUCT_COST_PER_HECTARE,
        BuiltInAttributeTypes.MACHINE_COST_PER_HECTARE,
        BuiltInAttributeTypes.WAGE_COST_PER_HECTARE,
        BuiltInAttributeTypes.OPERATION_COST_PER_HECTARE,
        BuiltInAttributeTypes.TOTAL_COST_PER_HECTARE,
        BuiltInAttributeTypes.YIELD,
    ];

    return costAttributes.includes(attributeKey as BuiltInAttributeTypes);
}

const cultureLayerAttributes = ['culture', 'mainPlantCulture', 'secondaryPlantCulture'];
const customLayerAttributes = [...cultureLayerAttributes, 'lastWorkOperationMainGroup', 'companyName'];

const layerAttributesWithDefaultHiddenLabeling = [...cultureLayerAttributes, 'companyName'];

export type CultureLayerAttributes = typeof cultureLayerAttributes[number];

export type CustomLayerAttributes = typeof cultureLayerAttributes[number];

export function isLayerAttributeLabelingHiddenDefault(attributeKey: unknown): attributeKey is CustomLayerAttributes {
    return typeof attributeKey === 'string' && layerAttributesWithDefaultHiddenLabeling.includes(attributeKey);
}

export function isCustomLayerAttribute(attributeKey: unknown): attributeKey is CustomLayerAttributes {
    return typeof attributeKey === 'string' && customLayerAttributes.includes(attributeKey);
}

export function isCultureLayerAttribute(attributeKey: unknown): attributeKey is CultureLayerAttributes {
    return typeof attributeKey === 'string' && cultureLayerAttributes.includes(attributeKey);
}

export function getCustomLayerPropertyId(attributeKey: string) {
    return {
        culture: 'cultureId',
        lastWorkOperationMainGroup: 'lastWorkOperationMainGroupId',
        companyName: 'companyId',
    }[attributeKey] ?? '';
}
