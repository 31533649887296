import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LANGUAGE } from 'shared/src/constants';
import { languageUtils } from 'shared/src/modules/languageUtils';
import renderModal from '@modals/modalNew/modalFunctions';
import TranslationModal from './TranslationModal/TranslationModal';

function Translation(props) {
    const { moduleAndKey, defaultText } = props;
    const isTranslater = useSelector((state: any) => state.user.user.translater);
    const { t, i18n } = useTranslation();
    const [translation, setTranslation] = useState();
    const [currentLanguage] = useState(i18n.language);

    useEffect(() => {
        setTranslation(t(moduleAndKey, defaultText));
    }, [moduleAndKey, defaultText, t]);

    function onContextMenu(event) {
        if (isTranslater && !languageUtils.isSameLanguage(currentLanguage, LANGUAGE.HUNGARIAN)) {
            event.preventDefault();
            renderModal(TranslationModal, {
                setTranslation,
                moduleAndKey,
                defaultText,
            }, false);
        }
    }

    return (
        <span onContextMenu={onContextMenu}>
            {translation}
        </span>
    );
}

export default React.memo(Translation);
