import { isFunction, isEmpty, values } from 'lodash';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import { theme as themes } from '@styles/theme';

export default function getAllColumns(t, translater, showedColumns) {
    const columns = {
        addIcon: {
            type: columnName.iconButton,
            columnId: 'icon-add',
            componentProps: {
                icon: 'icon-add',
                getCellTitle: () => translater('default.select', 'Select'),
                getBackgroundColor: () => themes.color.main,
                getIconColor: () => themes.color.white,
                onClick: (rowData, onAddButtonClicked) => {
                    if (isFunction(onAddButtonClicked)) {
                        onAddButtonClicked(rowData);
                    }
                },
            },
            isLeftFixed: true,
            //isFirstColumn: true,
            width: 80,
            minWidth: 80,
            maxWidth: 80,
        },
        employeeAvatar: {
            type: columnName.avatarWorker,
            columnId: 'imageUrl',
            isLeftFixed: true,
            width: 60,
        },
        employeeName: {
            type: columnName.simpleText,
            columnId: 'name',
            columnName: t('employeeTableFilter.name', 'Name'),
            minWidth: 220,
        },
        companyName: {
            type: columnName.simpleText,
            columnId: 'companyName',
            columnName: t('employeeTableFilter.company', 'company'),
            minWidth: 220,
        },
        employeePosition: {
            type: columnName.simpleText,
            columnId: 'position',
            columnName: t('employeeTableFilter.position', 'Position'),
            minWidth: 220,
        },
        seasonalType: {
            type: columnName.simpleText,
            columnId: 'seasonalType',
            columnName: t('employeeTableFilter.isSeasonalEmployee', 'Seasonal employee'),
            minWidth: 150,
        },
    };

    if (!isEmpty(showedColumns)) {
        const filteredColumns = [];
        const columnObj = Object.entries(columns);

        showedColumns.forEach(showedColName => {
            const column = columnObj.find(columnParts => columnParts[0].toString() === showedColName);
            if (column) {
                filteredColumns.push(column[1]);
            }
        });

        return filteredColumns;
    }

    return values(columns);
}
