import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '@i18n/useTranslate';
import { isFunction } from 'lodash';
import { RIGHTS } from 'shared/src/constants';
import { numberUtils } from 'shared/src/modules';
import { sessionService } from '@services';
import { systemActions, userActions } from '@states/actions';
import Toogle from '@baseComponents/controls/Toogle';
import PlanningModeSelectedPlan from './PlanningModeSelectedPlan';

const useStyles = createUseStyles({
    contentTitles: {
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        textTransform: 'uppercase',
    },
    planningSwitch: {
        width: 200,
        display: 'block',
        paddingRight: 30,
        marginTop: 5,
        '& .container': {
            width: '100%',
        },
        '& .separaterContainer': {
            width: '50%',
        },
        '& .segmentButton': {
            width: '100%',
        },
    },
    planningContentWrapper: {
        marginBottom: 20,
    },
});

export default function PlanningMode(props) {
    const { setIsPlanningMode, closeUserMenu } = props;
    const { t, translater } = useTranslate();
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();

    const planningModeAccess = useSelector((state: any) => state.system.systemSettings.planningModeAccess);
    const [isEnabledPlanning, setIsEnabledPlanning] = useState(sessionService.getPlanningMode() && sessionService.getPlanningData());
    const [hasPlanningInterfaceAccessRight] = useState(
        dispatch(userActions.getRight(RIGHTS.PLANNING_INTERFACE)),
    );

    function onChangePlanningStatus(value) {
        const planningBool = numberUtils.getBoolean(value);
        setIsEnabledPlanning(planningBool);

        if (!planningBool) { //on disable - remove current plan
            removeSession();
            setIsPlanningMode(planningBool);

            if (isFunction((window as any).reloadPhpContent)) {
                (window as any).reloadPhpContent();
            }
        }
    }

    async function onSelectPlan(hasSelected) {
        setIsEnabledPlanning(hasSelected); //change local state
        setIsPlanningMode(hasSelected); //show-hide active module panel in user menu

        //save session
        if (hasSelected) {
            closeUserMenu();
            sessionService.setPlanningMode(hasSelected);
            dispatch(systemActions.setPlanningModeActive(true));
            //update php session
            /*
            if (isFunction(window.reactRefreshPhpPlanningSession)) {
                window.reactRefreshPhpPlanningSession(Number(planningMode), planningData);
            }
            */
        } else { //delete session
            removeSession();
        }
    }

    async function removeSession() {
        //update php session
        if (isFunction((window as any).reactRefreshPhpPlanningSession)) {
            (window as any).reactRefreshPhpPlanningSession(0, {});
        }

        sessionService.removePlanningData();
        sessionService.removePlanningMode();
        dispatch(systemActions.setPlanningModeActive(false));

        const agentName = sessionService.getAgentName();
        history.push(`/${agentName}`);
    }

    function onClose(hasSelectedData) {
        setIsEnabledPlanning(hasSelectedData);
    }

    if (!planningModeAccess || !hasPlanningInterfaceAccessRight) {
        return null;
    }

    return (
        <>
            <div className={classes.planningContentWrapper}>
                <span className={classes.contentTitles}>
                    {t('userMenu.planningModul', 'Planning modul')}
                </span>
                <div className={classes.planningSwitch}>
                    <Toogle
                        onStateTitle={translater('userMenu.disablePlanningMode', 'Disable')}
                        offStateTitle={translater('userMenu.enablePlanningMode', 'Enable')}
                        isEnabled={isEnabledPlanning}
                        onChange={onChangePlanningStatus}
                    />
                </div>
                {isEnabledPlanning ? <PlanningModeSelectedPlan onSelectPlan={onSelectPlan} onClose={onClose} onChangePlanningStatus={onChangePlanningStatus} closeUserMenu={closeUserMenu} /> : null}
            </div>
        </>
    );
}
