import { createUseStyles } from 'react-jss';
import { useNumberFormat } from '@common_modules/numberFormat';

interface PercentCellProps {
    value: number | string;
}

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        color: theme.color.jet,
        textAlign: 'right',
    },
}));

export default function PercentCell(props: PercentCellProps) {
    const { value } = props;
    const { numberFormatter } = useNumberFormat();
    const classes = useStyles();

    return (
        <div className={classes.baseStyle}>
            {Number(value) >= 100
                ? numberFormatter.getFormattedPercentage(100, true)
                : numberFormatter.getFormattedPercentage(value || 0, true)} %
        </div>
    );
}
