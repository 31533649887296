/* eslint-disable max-lines-per-function */
import { isObject } from 'lodash';
import { TABLE } from 'shared/src/constants';
import { theme } from '@styles/theme';
import { cellTypes } from '@baseComponents/tableNew/cellTypes';
import { customCellTypes } from '@customComponents/cellTypes';
import { Area, Money, Weight, Quantity } from '@baseComponents/formatters';

export const columnName = {
    //map
    mapSvg: 'mapSvg',
    mapWithMultiline: 'mapWithMultiline',
    polygon: 'polygon',

    //text
    multiline: 'multiline',
    simpleText: 'simpleText',

    //area
    area: 'area',

    //cost
    cost: 'cost',
    costPerArea: 'costPerArea',
    costPerWeight: 'costPerWeight',

    //weight
    weightPerArea: 'weightPerArea',

    //quantity
    quantity: 'quantity',
    quantityPerArea: 'quantityPerArea',

    //percent
    percent: 'percent',

    //date
    date: 'date',
    dateEdit: 'dateEdit',
    dateTime: 'dateTime',
    dateMultiline: 'dateMultiline',

    //decimal
    decimal: 'decimal',

    //dose
    dose: 'dose',

    //other functions
    otherFunctions: 'otherFunctions',

    //buttons
    weatherButton: 'weatherButton',
    ndviButton: 'ndviButton',
    editButton: 'editButton',
    iconButton: 'iconButton',
    daysWithDataButton: 'daysWithDataButton',
    multilineWithButton: 'multilineWithButton',
    modalAddButton: 'modalAddButton',
    modalButton: 'modalButton',
    workOperationInfoButton: 'workOperationInfoButton',

    //inputs
    simpleInput: 'simpleInput',
    checkBox: 'checkBox',
    areaInput: 'areaInput',
    doseInput: 'doseInput',
    dosePerAreaInput: 'dosePerAreaInput',
    quantityInput: 'quantityInput',
    simpleSelect: 'simpleSelect',

    //icons
    iconSvg: 'iconSvg',
    iconList: 'iconList',

    title: 'title',
    collapsingWithOtherFunction: 'collapsingWithOtherFunction',
    collapsing: 'collapsing',
    selectRow: 'selectRow',
    statusColorCell: 'statusColorCell',
    multilineWithArea: 'multilineWithArea',
    multilineWithDateInterval: 'multilineWithDateInterval',
    multilineWithAreaPercent: 'multilineWithAreaPercent',
    multilineWithUnit: 'multilineWithUnit',
    avatarAndMachines: 'avatarAndMachines',
    avatarMachines: 'avatarMachines',
    avatarWorker: 'avatarWorker',
    avatarWithName: 'avatarWithName',
    headerBody: 'headerBody',
    status: 'status',
    multilineWithApprovalAndArea: 'multilineWithApprovalAndArea',
    doubleText: 'doubleText',

    popoverListButton: 'popoverListButton',

    //custom
    productUsageWarehouseSelector: 'productUsageWarehouseSelector',
    pesticideLink: 'pesticideLink',
};

export function getColumns(columns) {
    const defaultColumns: any = getDefaultColumns();
    const columnList: any = [];

    columns?.forEach((column: any) => {
        if (!isObject(column)) {
            columnList.push(defaultColumns[column]);
        } else {
            columnList.push({ ...defaultColumns[column.type], ...column });
        }
    });

    return columnList;
}

export function getGroupOperationColumn(tableKey) {
    return {
        type: columnName.selectRow,
        id: 'isSelectedRow',
        columnName: '',
        isSelectRowColumn: true,
        width: theme.table.selectionColumnWidth,
        minWidth: theme.table.selectionColumnWidth,
        maxWidth: theme.table.selectionColumnWidth,
        ignoreSearching: true,
        componentProps: {
            tableKey,
        },
        component: cellTypes.SelectRowCell,
    };
}

function getDefaultColumns() {
    return {
        //map
        [columnName.mapSvg]: {
            columnId: 'mapSvg',
            isLeftFixed: true,
            width: 56,
            minWidth: 56,
            maxWidth: 56,
            isSortable: false,
            isIgnoredInExcel: true,
            ignoreSearching: true,
            component: cellTypes.MapCell,
        },
        [columnName.mapWithMultiline]: {
            columnId: 'name',
            isLeftFixed: true,
            isTitle: true,
            width: 300,
            minWidth: 300,
            maxWidth: 300,
            isSortable: true,
            searchAndSortType: TABLE.SEARCH_TYPE.STRING,
            dataType: TABLE.DATA_TYPE.TEXT,
            searchInSecondLine: true,
            component: cellTypes.MapWithMultilineCell,
        },
        [columnName.polygon]: {
            isSortable: false,
            columnName: '',
            minWidth: 36,
            maxWidth: 60,
            ignoreSearching: true,
            component: cellTypes.MapCell,
        },

        //text
        [columnName.simpleText]: {
            isSortable: true,
            dataType: TABLE.DATA_TYPE.TEXT,
            //...theme.table.column.mediumColumn,
            ...theme.table.column.autoColumn,
            component: cellTypes.SimpleTextCell,
            searchAndSortType: TABLE.SEARCH_TYPE.STRING,
        },
        [columnName.multiline]: {
            columnId: 'name',
            width: 280,
            minWidth: 200,
            maxWidth: 280,
            isSortable: true,
            searchAndSortType: TABLE.SEARCH_TYPE.STRING,
            dataType: TABLE.DATA_TYPE.TEXT,
            searchInSecondLine: true,
            component: cellTypes.MultilineCell,
        },

        //simple area
        [columnName.area]: {
            columnId: 'area',
            isSortable: true,
            searchAndSortType: TABLE.SEARCH_TYPE.NUMBER,
            dataType: TABLE.DATA_TYPE.AREA,
            aggregateType: TABLE.AGGREGATE.SUM,
            aggregateFormatter: Area,
            ...theme.table.column.autoColumn,
            component: cellTypes.AreaCell,
        },

        //cost
        [columnName.cost]: {
            isSortable: true,
            aggregateType: TABLE.AGGREGATE.SUM,
            dataType: TABLE.DATA_TYPE.COST,
            ...theme.table.column.smallColumn,
            hasSecondaryCurrency: true,
            component: cellTypes.CostCell,
            unitPriceDecimals: false,
        },
        [columnName.costPerArea]: {
            columnId: 'area',
            isSortable: true,
            hasSecondViewType: true,
            searchAndSortType: TABLE.SEARCH_TYPE.NUMBER,
            dataType: TABLE.DATA_TYPE.COST_PER_AREA,
            aggregateType: TABLE.AGGREGATE.SUM,
            aggregateFormatter: Money,
            ...theme.table.column.mediumColumn,
            hasSecondaryCurrency: true,
            component: cellTypes.CostPerAreaCell,
            unitPriceDecimals: true,
        },
        [columnName.costPerWeight]: {
            isSortable: true,
            hasSecondViewType: true,
            searchAndSortType: TABLE.SEARCH_TYPE.NUMBER,
            dataType: TABLE.DATA_TYPE.COST_PER_WEIGHT,
            aggregateType: TABLE.AGGREGATE.SUM,
            aggregateFormatter: Money,
            ...theme.table.column.mediumColumn,
            hasSecondaryCurrency: true,
            component: cellTypes.CostPerWeightCell,
            unitPriceDecimals: true,
        },

        //weight
        [columnName.weightPerArea]: {
            isSortable: true,
            hasSecondViewType: true,
            searchAndSortType: TABLE.SEARCH_TYPE.NUMBER,
            dataType: TABLE.DATA_TYPE.WEIGHT_PER_AREA,
            aggregateType: TABLE.AGGREGATE.SUM,
            aggregateFormatter: Weight,
            ...theme.table.column.mediumColumn,
            component: cellTypes.WeightPerAreaCell,
        },

        //quantity
        [columnName.quantity]: {
            isSortable: true,
            hasSecondViewType: true,
            searchAndSortType: TABLE.SEARCH_TYPE.NUMBER,
            dataType: TABLE.DATA_TYPE.QUANTITY,
            aggregateType: TABLE.AGGREGATE.SUM,
            aggregateFormatter: Quantity,
            ...theme.table.column.mediumColumn,
            component: cellTypes.QuantityCell,
        },
        [columnName.quantityPerArea]: {
            isSortable: true,
            hasSecondViewType: true,
            searchAndSortType: TABLE.SEARCH_TYPE.NUMBER,
            dataType: TABLE.DATA_TYPE.QUANTITY_PER_AREA,
            aggregateType: TABLE.AGGREGATE.SUM,
            aggregateFormatter: Quantity,
            ...theme.table.column.mediumColumn,
            component: cellTypes.QuantityPerAreaCell,
        },

        //percent
        [columnName.percent]: {
            isSortable: true,
            hasSecondViewType: true,
            dataType: TABLE.DATA_TYPE.PERCENTAGE,
            ...theme.table.column.mediumColumn,
            component: cellTypes.PercentCell,
        },

        //dose
        [columnName.dose]: {
            isSortable: true,
            hasSecondViewType: true,
            searchAndSortType: TABLE.SEARCH_TYPE.NUMBER,
            dataType: TABLE.DATA_TYPE.DOSE,
            aggregateFormatter: Quantity,
            ...theme.table.column.mediumColumn,
            component: cellTypes.QuantityCell,
        },

        //date
        [columnName.date]: {
            isSortable: true,
            searchAndSortType: TABLE.SEARCH_TYPE.DATE,
            dataType: TABLE.DATA_TYPE.DATE,
            ...theme.table.column.autoColumn,
            component: cellTypes.DateCell,
        },
        [columnName.dateEdit]: {
            isSortable: true,
            searchAndSortType: TABLE.SEARCH_TYPE.DATE,
            dataType: TABLE.DATA_TYPE.DATE,
            ...theme.table.column.autoColumn,
            component: cellTypes.DateEditCell,
            width: 162,
            minWidth: 162,
            maxWidth: 162,
        },
        [columnName.dateTime]: {
            isSortable: true,
            searchAndSortType: TABLE.SEARCH_TYPE.DATE,
            dataType: TABLE.DATA_TYPE.DATE,
            ...theme.table.column.autoColumn,
            component: cellTypes.DateTimeCell,
        },
        [columnName.dateMultiline]: {
            isSortable: true,
            searchInSecondLine: true,
            searchAndSortType: TABLE.SEARCH_TYPE.DATE,
            dataType: TABLE.DATA_TYPE.DATE,
            ...theme.table.column.autoColumn,
            component: cellTypes.DateMultilineCell,
        },

        //decimal
        [columnName.decimal]: {
            isSortable: true,
            searchAndSortType: TABLE.SEARCH_TYPE.NUMBER,
            dataType: TABLE.DATA_TYPE.AREA,
            ...theme.table.column.autoColumn,
            component: cellTypes.DecimalCell,
        },

        //other function
        [columnName.otherFunctions]: {
            isIgnoredInExcel: true,
            ignoreSearching: true,
            isRightFixed: true,
            width: 70,
            maxWidth: 70,
            component: cellTypes.OtherFunctionsCell,
        },

        //buttons
        [columnName.weatherButton]: {
            isIgnoredInExcel: true,
            ignoreSearching: true,
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            component: cellTypes.WeatherButtonCell,
        },
        [columnName.ndviButton]: {
            isIgnoredInExcel: true,
            ignoreSearching: true,
            width: 80,
            minWidth: 80,
            maxWidth: 80,
            component: cellTypes.NdviButtonCell,
        },
        [columnName.editButton]: {
            isRightFixed: true,
            isIgnoredInExcel: true,
            ignoreSearching: true,
            width: 60,
            minWidth: 60,
            maxWidth: 60,
            component: cellTypes.EditButtonCell,
        },
        [columnName.iconButton]: {
            ignoreSearching: true,
            width: 60,
            minWidth: 60,
            maxWidth: 60,
            dataType: TABLE.DATA_TYPE.BOOLEAN,
            component: cellTypes.IconButtonCell,
        },
        [columnName.daysWithDataButton]: {
            ignoreSearching: true,
            width: 60,
            minWidth: 60,
            maxWidth: 60,
            component: cellTypes.DaysWithDataButtonCell,
        },
        [columnName.workOperationInfoButton]: {
            ignoreSearching: true,
            isSortable: false,
            isIgnoredInExcel: true,
            width: 60,
            minWidth: 60,
            maxWidth: 60,
            component: cellTypes.WorkOperationInfoButtonCell,
        },
        [columnName.multilineWithButton]: {
            minWidth: 250,
            component: cellTypes.MultilineWithButtonCell,
            searchInSecondLine: true,
        },

        [columnName.modalButton]: {
            ignoreSearching: true,
            width: 150,
            minWidth: 150,
            maxWidth: 150,
            component: cellTypes.ModalButtonCell,
        },

        //icons
        [columnName.iconSvg]: {
            ignoreSearching: true,
            isSortable: true,
            width: 60,
            minWidth: 60,
            maxWidth: 60,
            dataType: TABLE.DATA_TYPE.BOOLEAN,
            component: cellTypes.IconSvgCell,
        },

        //icon list
        [columnName.iconList]: {
            isSortable: false,
            width: 90,
            minWidth: 90,
            maxWidth: 90,
            dataType: TABLE.DATA_TYPE.BOOLEAN,
            component: cellTypes.IconListCell,
        },

        //inputs
        [columnName.simpleInput]: {
            isSortable: false,
            ...theme.table.column.mediumColumn,
            isIgnoredInExcel: true,
            component: cellTypes.SimpleInputCell,
        },
        [columnName.areaInput]: {
            dataType: TABLE.DATA_TYPE.AREA,
            isSortable: false,
            ...theme.table.column.mediumColumn,
            maxWidth: 150,
            ignoreSearching: true,
            component: cellTypes.AreaInputCell,
        },
        [columnName.checkBox]: {
            columnName: '',
            isSortable: false,
            ignoreSearching: true,
            width: 36,
            component: cellTypes.CheckboxCell,
        },
        [columnName.quantityInput]: {
            dataType: TABLE.DATA_TYPE.QUANTITY,
            isSortable: false,
            ignoreSearching: true,
            ...theme.table.column.mediumColumn,
            maxWidth: 150,
            component: cellTypes.QuantityInputCell,
        },
        [columnName.simpleSelect]: {
            isShowOverflow: true,
            isSortable: false,
            ignoreSearching: true,
            ...theme.table.column.mediumColumn,
            component: cellTypes.SimpleSelectCell,
        },

        //other
        [columnName.title]: {
            isTitle: true,
            isSortable: true,
            minWidth: 120,
            component: cellTypes.SimpleTextCell,
        },
        [columnName.statusColorCell]: {
            isSortable: false,
            width: 10,
            maxWidth: 10,
            isIgnoredInExcel: true,
            ignoreSearching: true,
            isFirstColumn: true,
            component: cellTypes.StatusColorCell,
        },
        [columnName.simpleText]: {
            isSortable: true,
            ...theme.table.column.mediumColumn,
            component: cellTypes.SimpleTextCell,
        },
        [columnName.collapsing]: {
            id: 'collapse',
            columnName: '',
            isCollapsingColumn: true,
            width: 60,
            isIgnoredInExcel: true,
            component: cellTypes.OtherFunctionsCell,
        },
        [columnName.collapsingWithOtherFunction]: {
            id: 'collapse',
            columnName: '',
            isCollapsingColumn: true,
            width: 60,
            isIgnoredInExcel: true,
        },
        [columnName.avatarAndMachines]: {
            isSortable: false,
            width: 300,
            isIgnoredInExcel: true,
            ignoreSearching: true,
            component: cellTypes.AvatarAndMachineCell,
        },
        [columnName.avatarMachines]: {
            isSortable: false,
            width: 50,
            isIgnoredInExcel: true,
            ignoreSearching: true,
            component: cellTypes.MachineAvatarCell,
        },
        [columnName.avatarWorker]: {
            isSortable: false,
            width: 50,
            isIgnoredInExcel: true,
            ignoreSearching: true,
            component: cellTypes.WorkerAvatarCell,
        },
        [columnName.avatarWithName]: {
            isSortable: false,
            width: 200,
            isIgnoredInExcel: true,
            component: cellTypes.AvatarWithNameCell,
        },
        [columnName.multilineWithApprovalAndArea]: {
            isTitle: true,
            isSortable: true,
            minWidth: 120,
            component: cellTypes.MultilineWithApprovalAndAreaCell,
        },
        [columnName.multilineWithDateInterval]: {
            isSortable: true,
            componentProps: {},
            minWidth: 120,
            component: cellTypes.MultilineWithDateIntervalCell,
        },
        [columnName.multilineWithAreaPercent]: {
            isSortable: true,
            componentProps: {},
            minWidth: 120,
            dataType: TABLE.DATA_TYPE.PERCENTAGE,
            secondLineDataType: TABLE.DATA_TYPE.AREA,
            component: cellTypes.MultilineWithAreaPercentCell,
        },
        [columnName.multilineWithUnit]: {
            isSortable: true,
            componentProps: {},
            minWidth: 120,
            dataType: TABLE.DATA_TYPE.WORKOPERATION_PERFORMANCE,
            secondLineDataType: TABLE.DATA_TYPE.WORKING_HOURS_PERFORMANCE,
            component: cellTypes.MultilineWithUnitCell,
        },
        [columnName.headerBody]: {
            isSortable: true,
            component: cellTypes.HeaderBodyCell,
        },
        [columnName.status]: {
            isSortable: false,
            columnName: '',
            width: 36,
            ignoreSearching: true,
            component: cellTypes.StatusCell,
        },
        [columnName.doubleText]: {
            isSortable: true,
            dataType: TABLE.DATA_TYPE.TEXT,
            ...theme.table.column.autoColumn,
            searchInSecondLine: true,
            component: cellTypes.DoubleTextCell,
        },
        [columnName.pesticideLink]: {
            ignoreSearching: true,
            isSortable: true,
            width: 60,
            minWidth: 60,
            maxWidth: 60,
            dataType: TABLE.DATA_TYPE.BOOLEAN,
            component: cellTypes.PesticideLinkCell,
        },
        [columnName.popoverListButton]: {
            ignoreSearching: true,
            isSortable: false,
            width: 100,
            minWidth: 100,
            maxWidth: 100,
            dataType: TABLE.DATA_TYPE.TEXT,
            component: cellTypes.PopoverListButtonCell,
        },
        [columnName.modalAddButton]: {
            isShowOverflow: true,
            isSortable: false,
            ignoreSearching: true,
            ...theme.table.column.bigColumn,
            component: cellTypes.ModalAddButtonCell,
        },
        [columnName.percent]: {
            isShowOverflow: true,
            isSortable: true,
            ignoreSearching: true,
            dataType: TABLE.DATA_TYPE.PERCENTAGE,
            searchAndSortType: TABLE.SEARCH_TYPE.NUMBER,
            ...theme.table.column.smallColumn,
            component: cellTypes.PercentCell,
        },
        //custom components
        [columnName.productUsageWarehouseSelector]: {
            ignoreSearching: true,
            width: 300,
            minWidth: 300,
            maxWidth: 300,
            component: customCellTypes.ProductUsageWarehouseSelectorCell,
        },
    };
}

/*
[columnName.allProps]: {
    index: 0,
    id: 'collapse',
    columnName: '',
    columnId = '',
    dataType: TABLE.DATA_TYPE.COST,
    isIgnoredInExcel: true, //TABLE DOWNLOAD IN TO EXCEL eg.: svg
    hasSecondViewType: true, //Total and by hectare view type column
    isTitle: true,
    isCollapsingColumn: true,
    isSortable: true,
    aggregateType: TABLE.AGGREGATE.SUM,
    aggregateFunction: null,
    aggregateFormatter: Area,
    component: cellTypes.SimpleTextCell,
    componentProps: {},
    width: 120,
    minWidth: 120,
    maxWidth: 120,
    ...theme.table.column.autoColumn,
    ...theme.table.column.smallColumn,
    ...theme.table.column.mediumColumn,
    ...theme.table.column.bigColumn,
    ignoreSearching,
    searchAndSortType: TABLE.SEARCH_TYPE.STRING,
    searchInSecondLine: true, //can search in the secondLineId
    searchEndpointFunction: service.xy, //call endpoint to dataList
    searchEndpointProps: { companyIds }, //endpoint props list
    searchDataList: [], //default dataList to search component
    hasLangCaption: true,
    hasSecondaryCurrency: true,
    isColumnSwitchButton: true, //table header - columnName as button
    onClick: onClick, //table header - column name button onclick function
    aggregateDivisor: '', //divisor column to "by hectare" view type
    isShowOverflow: true, //e.g.: dropdowns
},
*/
