import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import useTranslate from '@i18n/useTranslate';
import { uniqueId, isEmpty, isFunction, isArray } from 'lodash';
import { simpleTableActions, alertActions } from '@states/actions';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import Button from '@baseComponents/buttons/Button';
import CloseButton from '@baseComponents/buttons/CloseButton';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import GlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/GlobalSearchInput';
import { ModalContentWrapper, ModalHeader, ModalBody, ModalFooter } from '@baseComponents/modals/modalNew';

import useCulturesModalLoadData from './useCulturesModalLoadData';
import getAllColumns from './allColumns';

const useStyles = createUseStyles((theme: any) => ({
    modalWrapper: {
        width: 800,
    },
    headerFunctionLine: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: theme.color.white,
        padding: '0 30px',
        marginBottom: 10,
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    tableWrapper: {
        display: 'contents',
        height: '100%',
        width: '100%',
    },
    addSelectedButton: {
        marginRight: 10,
    },
    footerButton: {
        width: 250,
    },
    closeButton: {
        width: 150,
    },
    table: {
        '& .tableHeaderRow': {
            marginRight: 20,
            paddingRight: 10,
            marginLeft: 4,
        },
        '& .List': {
            marginRight: 20,
            paddingRight: 10,
        },
        '& .table-row': {
            marginRight: 20,
            paddingRight: 10,
        },
        width: '100%',
    },
}));

const TABLE_KEY = uniqueId('culturesModal');

export default function CulturesModal(props) {
    const { onChange, hideModal, isMultiselect, rowFormatFnc = obj => obj, onSelectedValue, selectedRowIds } = props;
    const { t, translater } = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [defaultColumns, setDefaultColumns] = useState();
    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    const { tableData } = useCulturesModalLoadData();

    useEffect(() => {
        const showedColumns = ['addIcon', 'cultureName', 'cultureCode'];

        const filteredColumns = getAllColumns(t, translater, showedColumns);
        setDefaultColumns(filteredColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAddButtonClicked(selectedService) {
        if (isFunction(onSelectedValue)) {
            onSelectedValue(rowFormatFnc(selectedService));
        }

        if (isFunction(onChange)) {
            onChange(rowFormatFnc(selectedService));
        }

        if (isFunction(hideModal)) {
            hideModal();
        }
    }

    function onAddSelectedButtonClicked() {
        const selectedServiceIds = dispatch(simpleTableActions.getSelectedRowIds(TABLE_KEY));
        const selectedServices = tableData?.filter(rowData => selectedServiceIds?.includes(rowData.id))?.map(Service => (
            rowFormatFnc(Service)
        ));

        if (!isEmpty(selectedServices)) {
            if (isFunction(onChange)) { onChange(selectedServices); }
            if (isFunction(hideModal)) { hideModal(); }
        } else {
            dispatch(alertActions.warningAlert(t('culturesTableFilter.noSelectedCultures', 'No cultures were selected')));
        }
    }

    const [tableFunctions] = useState({
        onAddButtonClicked,
    });

    const [breakdown] = useState({
        value: null,
        text: 'No breakdown',
    });

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {t('culturesTableFilter.selectCultures', 'Select cultures')}
                <CloseButton onClick={hideModal} />
            </ModalHeader>
            <div className={classes.headerFunctionLine}>
                <GlobalSearchInput tableKey={TABLE_KEY} isSearchButton={false} isSearchIcon />
            </div>
            <ModalBody className={classes.modalBody}>
                <LoadSpinner value={tableData} isAutoDetect />
                <div className={classes.tableWrapper}>
                    <TableGroup
                        tableId={TABLE_KEY}
                        tableData={tableData}
                        tableColumns={columns}
                        isBasePageTable={false}
                        isCollapsible={false}
                        tableGroup={breakdown}
                        className={classes.table}
                        tableFunctions={tableFunctions}
                        useRowSelection={isMultiselect}
                        selectedRowIds={selectedRowIds || null}
                    />
                </div>
                <TableScrollerFooter tableKey={TABLE_KEY} isLoaded={isArray(tableData)} />
            </ModalBody>
            <ModalFooter>
                {isMultiselect ? (
                    <Button className={clsx(classes.footerButton, classes.addSelectedButton)} onClick={onAddSelectedButtonClicked}>
                        {t('default.addSelected', 'Add selected')}
                    </Button>
                ) : null}
                <Button type="secondary" className={classes.closeButton} onClick={hideModal}>
                    {t('default.close', 'Close')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
