import { FeatureCollection } from 'geojson';

/* eslint-disable camelcase */
export type SKCultureCode = {
  OBJECTID: number;
  KOD: string;
  NAZOV: string;
  ID_KULTURA_LPIS: null;
  displayValue: string;
};

export type SKLpis = {
  OBJECTID: number;
  ROK: number;
  ID_KD: number;
  LOKALITA: number;
  ZKODKD: string;
  VYMERA: number;
  KULTURA: number;
  SPOSOBILOST: number;
  KODKD: string;
  NUMKD: number;
  POZNAMKA: string | null;
  POPIS: string | null;
  KDIEL: string;
  LOKALITA_ZKODKD: string;
  Shape__Area: number;
  Shape__Length: number;
  UBYTOK: string | null;
  UBYTOK_VEK: string | null;
};

export type SKPublishedPolygon = {
  ZKODKD: string;
  FARMAR_VYTVORIL: string;
  DATUM_VYTVORENIA: number;
  FARMAR_EDITOVAL: null | string;
  DATUM_EDITACIE: null | number;
  PPA_ID: null | string;
  PARCELA: string;
  VALID: number;
  ZDROJ: string;
  OBJECTID: number;
  NAZOV_SUBJEKTU: string;
  ICO: string;
  LOKALITA: string;
  KODKD: string;
  PCUV: number;
  VYMERA: number;
  ZDROJ_KOD: string;
  STAV: string;
};


export type SKInfoPanelTabData = {
  header: string;
  properties?: {[key: string]: any};
  isDraw?: boolean;
  onDrawClick?: () => void;
};

export const SKCultureCodeList: SKCultureCode[] = [
  {
    OBJECTID: 1,
    KOD: 'OP',
    NAZOV: 'Orná pôda',
    ID_KULTURA_LPIS: null,
    displayValue: 'Orná pôda - OP',
  },
  {
    OBJECTID: 2,
    KOD: 'CHM',
    NAZOV: 'Chmeľnica s vysadeným chmeľom',
    ID_KULTURA_LPIS: null,
    displayValue: 'Chmeľnica s vysadeným chmeľom - CHM',
  },
  {
    OBJECTID: 3,
    KOD: 'VIN',
    NAZOV: 'Vinohrad',
    ID_KULTURA_LPIS: null,
    displayValue: 'Vinohrad - VIN',
  },
  {
    OBJECTID: 4,
    KOD: 'SAD',
    NAZOV: 'Ovocný sad (vrátane drobného ovocia)',
    ID_KULTURA_LPIS: null,
    displayValue: 'Ovocný sad (vrátane drobného ovocia) - SAD',
  },
  {
    OBJECTID: 5,
    KOD: 'TTP',
    NAZOV: 'Trvalý trávnatý porast',
    ID_KULTURA_LPIS: null,
    displayValue: 'Trvalý trávnatý porast - TTP',
  },
  {
    OBJECTID: 6,
    KOD: 'TP',
    NAZOV: 'Trvalé plodiny',
    ID_KULTURA_LPIS: null,
    displayValue: 'Trvalé plodiny - TP',
  },
];

export interface GetSlovakExternalLayerResult {
  layerId: number;
  featureCollection: FeatureCollection;
}

type AVImageMapType = {
  name: string;
  map: google.maps.Map;
  getUrl: (bbox: string) => string;
  minZoom?: number;
  maxZoom?: number;
  options?: google.maps.ImageMapTypeOptions;
};

export function getBbox(map: google.maps.Map) {
  const bounds = map.getBounds();
  const ne = bounds?.getNorthEast();
  const sw = bounds?.getSouthWest();

  if (!ne || !sw || !map) {
    return;
  }

  return {
    xmin: sw.lng(),
    ymin: sw.lat(),
    xmax: ne.lng(),
    ymax: ne.lat(),
    spatialReference: {
      wkid: 4326,
      latestWkid: 4326,
    },
  };
}

export const getImageMapType = (props: AVImageMapType) =>
  new google.maps.ImageMapType({
    getTileUrl(coord, z) {
      const {map, getUrl} = props;
      const proj = map.getProjection();
      const zoomLevel = map.getZoom() ?? 0;
      if (!proj || zoomLevel < 13) {
        return null;
      }
      const zfactor = 2 ** z;
      const top = proj.fromPointToLatLng(new google.maps.Point((coord.x * 256) / zfactor, (coord.y * 256) / zfactor));
      const bot = proj.fromPointToLatLng(new google.maps.Point(((coord.x + 1) * 256) / zfactor, ((coord.y + 1) * 256) / zfactor));

      if (!top || !bot) {
        return null;
      }

      const bbox = `${top.lng()},${bot.lat()},${bot.lng()},${top.lat()}`;

      return getUrl(bbox);
    },
    opacity: 0.5,
    tileSize: new google.maps.Size(256, 256),
    name: props.name,
    ...(props?.options ?? {}),
  });
