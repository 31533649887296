import { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { uniqueId, isFunction, isEmpty, isNil } from 'lodash';
import { CULTIVATION_PERIODS } from 'shared';
import useTranslate from '@i18n/useTranslate';
import { simpleTableActions, alertActions } from '@states/actions';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import Button from '@baseComponents/buttons/Button';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { ModalContentWrapper, ModalHeader, ModalBody, ModalFooter } from '@baseComponents/modals/modalNew';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import SegmentToogle from '@baseComponents/controls/SegmentToogle';
import GlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/GlobalSearchInput';
import PeriodYearSelect from '@baseComponents/basePageNew/filters/PeriodYearSelect';
import CloseButton from '@baseComponents/buttons/CloseButton';
import useFocusGlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/useFocusGlobalSearchInput';
import useCultivationPeriodsModalLoadData from './useCultivationPeriodsModalLoadData';
import getAllColumns from './allColumns';

const useStyles = createUseStyles((theme: any) => ({
    headerFunctionLine: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: theme.color.white,
        padding: '0 30px',
        marginBottom: 10,
    },
    rightButtons: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 20,
    },
    select: {
        marginRight: 20,
        marginLeft: 0,
        minWidth: 180,
        '& .selectContainer': {
            height: 36,
        },
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    tableWrapper: {
        display: 'contents',
        height: '100%',
        width: '100%',
    },
    table: {
        '& .tableHeaderRow': {
            marginRight: 20,
            paddingRight: 10,
        },
        '& .List': {
            marginRight: 20,
            paddingRight: 10,
        },
        '& .table-row': {
            marginRight: 20,
            paddingRight: '10px !important',
        },
        width: '100%',
    },
    footerButton: {
        width: 250,
    },
    closeButton: {
        width: 150,
    },
    addSelectedButton: {
        marginRight: 10,
    },
}));

const TABLE_KEY = uniqueId('cultivationPeriods');

export default function CultivationPeriodsModal(props) {
    const {
        onChange, hideModal, partfieldType, isMultiselect, showRadioButtons = false, defaultProductionYear, onSelectedValue,
        selectedRowIds, rowFormatFnc = obj => obj, excludedIds, showPeriodYearSelect = true, cultureId, maxPeriodYearId,
        onClose,
    } = props;
    const partfieldTypeRef = useRef(partfieldType || 0);

    const { t, translater } = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [defaultColumns, setDefaultColumns] = useState<any>();
    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    const { tableData, tablePartfieldType, setTablePartfieldType, partfieldTypesList, periodYear, setPeriodYear, isLoading } = useCultivationPeriodsModalLoadData({ partfieldType, t, excludedIds, cultureId });

    useEffect(() => {
        if (defaultProductionYear) {
            setPeriodYear(defaultProductionYear);
        }
    }, [defaultProductionYear, setPeriodYear]);

    useEffect(() => {
        partfieldTypeRef.current = tablePartfieldType;
    }, [tablePartfieldType]);

    useEffect(() => {
        const showedColumns = [
            'addIcon',
            'mapSvg',
            'periodName',
            'companyName',
            'productionYear',
            'tySownCr',
            'culture',
            'cultureVariety',
            'sownArea',
            'startDate',
            'endDate',
            'currentCultureName',
            'cultivationPeriodGroupName',
        ];

        const filteredColumns = getAllColumns(t, translater, showedColumns);
        setDefaultColumns(filteredColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAddButtonClicked(selectedPeriod) {
        if (isFunction(onSelectedValue)) {
            onSelectedValue(rowFormatFnc(selectedPeriod, +partfieldTypeRef.current));
        }

        if (isFunction(onChange)) {
            onChange(rowFormatFnc(selectedPeriod, +partfieldTypeRef.current));
        }

        if (isFunction(hideModal)) {
            hideModal();
        }

        if (isFunction(onClose)) {
            onClose();
        }
    }

    function onAddSelectedButtonClicked() {
        const selectedPeriodIds: any = dispatch(simpleTableActions.getSelectedRowIds(TABLE_KEY));
        const selectedPeriods = tableData?.filter(rowData => selectedPeriodIds?.includes(rowData.id))?.map(period => (
            rowFormatFnc(period, +partfieldTypeRef.current)
        ));

        if (isFunction(onSelectedValue)) {
            onSelectedValue(selectedPeriods);
        }

        if (!isEmpty(selectedPeriods)) {
            if (isFunction(onChange)) { onChange(selectedPeriods); }
            if (isFunction(hideModal)) { hideModal(); }
            if (isFunction(onClose)) { onClose(); }
        } else {
            dispatch(alertActions.warningAlert(t('cultivationPeriodTableFilter.noSelectedPeriods', 'No periods were selected')));
        }
    }

    function handleClose() {
        if (isFunction(hideModal)) {
            hideModal();
        }

        if (isFunction(onClose)) {
            onClose();
        }
    }

    const [tableFunctions] = useState({
        onAddButtonClicked,
    });

    const [breakdown] = useState({
        value: null,
        text: 'No breakdown',
    });

    useFocusGlobalSearchInput(TABLE_KEY);

    return (
        <ModalContentWrapper>
            <ModalHeader>
                {tablePartfieldType === CULTIVATION_PERIODS.PARTFIELD_TYPE.PERIOD
                    ? t('cultivationPeriodTableFilter.selectCultivationPeriod', 'Select cultivation period')
                    : t('cultivationPeriodGroupsModal.modalHeader', 'Select cultivation period groups')
                }
                <CloseButton onClick={handleClose} />
            </ModalHeader>
            <div className={classes.headerFunctionLine}>
                {showPeriodYearSelect && (
                    <PeriodYearSelect
                        hasMargin={false}
                        periodYear={defaultProductionYear || periodYear}
                        setPeriodYear={setPeriodYear}
                        className={classes.select}
                        maxPeriodYearId={maxPeriodYearId}
                    />
                )}
                <GlobalSearchInput tableKey={TABLE_KEY} isSearchButton={false} isSearchIcon />
                <div className={classes.rightButtons}>
                    {!isNil(partfieldType) && showRadioButtons && (
                        <div>
                            <SegmentToogle
                                fixedSize
                                selected={tablePartfieldType}
                                items={partfieldTypesList}
                                onChange={key => {
                                    setTablePartfieldType(key);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <ModalBody className={classes.modalBody}>
                {
                    isLoading && <LoadSpinner />
                }
                <div className={classes.tableWrapper}>
                    <TableGroup
                        tableId={TABLE_KEY}
                        tableData={tableData}
                        tableColumns={columns}
                        isBasePageTable={false}
                        isCollapsible={false}
                        tableGroup={breakdown}
                        className={classes.table}
                        tableFunctions={tableFunctions}
                        selectedRowIds={selectedRowIds || null}
                        canUseGroupOperation={isMultiselect}
                        useRowSelection={isMultiselect}
                    />
                </div>
                <TableScrollerFooter tableKey={TABLE_KEY} isLoaded={!isEmpty(tableData)} />
            </ModalBody>
            <ModalFooter>
                {isMultiselect ? (
                    <Button className={clsx(classes.footerButton, classes.addSelectedButton)} onClick={onAddSelectedButtonClicked}>
                        {t('default.addSelected', 'Add selected')}
                    </Button>
                ) : null}
                <Button type="secondary" className={classes.closeButton} onClick={handleClose}>
                    {t('default.close', 'Close')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
