import { useState, useEffect, useCallback } from 'react';
import { isArray, isEmpty, isObject } from 'lodash';
import { stringUtils } from 'shared/src/modules';

export default function useSelectListPanel(props) {
    const { dataList, isNonAccentSearch } = props;
    const [filteredDataList, setFilteredDataList] = useState<any>();

    useEffect(() => {
        setFilteredDataList(dataList);
    }, [dataList]);

    const onSearch = useCallback(searchValue => {
        if (isEmpty(searchValue) || isEmpty(dataList)) {
            setFilteredDataList(dataList);
            return;
        }

        if (isArray(dataList)) {
            setFilteredDataList(dataList?.filter(dataRow => stringUtils.includesNoCase(dataRow.value, searchValue, isNonAccentSearch)));
        } else if (isObject(dataList)) {
            const newDataListObject = {};
            Object.entries(dataList).forEach(([group, dataArray]) => {
                const filteredGroupDatas = dataArray?.filter(dataRow => stringUtils.includesNoCase(dataRow.value, searchValue, isNonAccentSearch));
                if (!isEmpty(filteredGroupDatas)) {
                    newDataListObject[group] = filteredGroupDatas;
                }
            });
        }
    }, [dataList, isNonAccentSearch]);

    return {
        filteredDataList,
        onSearch,
    };
}
