import React from 'react';
import { createUseStyles } from 'react-jss';
import CropAvatar from '../../baseComponents/avatars/CropAvatar';

const useStyles = createUseStyles((theme: any) => ({
    cropData: {
        width: '100%',
        padding: 10,
        display: 'flex',
    },
    cropDataContainer: {
        marginLeft: 10,
        display: 'inline-grid',
        textAlign: 'left',
    },
    cropCulture: {
        fontSize: 15,
        fontWeight: 500,
        lineHeight: 1.33,
        textAlign: 'left',
        ...theme.overFlowText,
    },
    cropInfo: {
        fontSize: 13,
        lineHeight: 1.38,
        color: theme.color.stone,
        marginTop: 2,
    },
    cropType: {
        paddingRight: 10,
        borderRight: props => props.isReproductionRate && '1px solid',
        borderRightColor: theme.color.pearl,
        marginRight: 10,
    },
}));

export default function CropOverall(props) {
    const { cultureName, typeName, reproductionRateName, varietyName } = props;
    const classes = useStyles({ isReproductionRate: !!reproductionRateName });

    function formatCultureAndVarietyName(culture, variety) {
        if (variety) {
            return `${culture} (${variety})`;
        }
        return culture;
    }

    return (
        <div className={classes.cropData}>
            <CropAvatar iconId="icon-leafy" />
            <div className={classes.cropDataContainer} title={formatCultureAndVarietyName(cultureName, varietyName)}>
                <div className={classes.cropCulture}>
                    {formatCultureAndVarietyName(cultureName, varietyName)}
                </div>
                <div className={classes.cropInfo}>
                    <span className={classes.cropType}>
                        {typeName}
                    </span>
                    <span>
                        {reproductionRateName}
                    </span>
                </div>
            </div>
        </div>
    );
}
