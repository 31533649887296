import BaseStoreService from './BaseStoreService';

class BaseController<TStore, TStoreService extends BaseStoreService<TStore>> {
    protected store: TStore;

    protected storeService: TStoreService;

    constructor(store: TStore, storeService: TStoreService) {
        this.store = store;
        this.storeService = storeService;
    }

    public bind = (store: TStore, storeService: TStoreService) => {
        this.store = store;
        this.storeService = storeService;
    }
}

export default BaseController;
