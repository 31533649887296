import { RIGHTS, SYSTEM } from '../constants';

export const ECONOMIC_UNIT = {
    MODULE_ID: 10,
    NAME: 'economicUnit',
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'economicUnit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.economicUnit',
                TRANSLATOR_DEFAULT: 'Economic unit',
            },
        },
        GARAGES: {
            CONTENT_URL_ID: { garages: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'garages',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.garages',
                TRANSLATOR_DEFAULT: 'Garages',
            },
            RIGHTS: [RIGHTS.GARAGES_INTERFACE],
        },
        ECONOMIC_UNITS: {
            CONTENT_URL_ID: { economicUnits: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'economicUnits',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.economicUnits',
                TRANSLATOR_DEFAULT: 'Economic units',
            },
            RIGHTS: [RIGHTS.ECONOMIC_UNIT_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        INCOMES: {
            CONTENT_URL_ID: { incomes: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'incomes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.incomes',
                TRANSLATOR_DEFAULT: 'Incomes',
            },
            RIGHTS: [RIGHTS.ECONOMIC_UNITS_INCOMES_INTERFACE],
        },
        INCOME_EDIT: {
            CONTENT_URL_ID: { incomeEdit: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'incomeEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.incomeEdit',
                TRANSLATOR_DEFAULT: 'Income edit',
            },
        },
        CATEGORIZATION_OF_CROP_PRODUCTION_TYPE: {
            CONTENT_URL_ID: { categorizationOfCropProductionType: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'categorizationOfCropProductionType',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.categorizationOfCropProductionType',
                TRANSLATOR_DEFAULT: 'Categorization of crop production type',
            },
            RIGHTS: [RIGHTS.CATEGORIZATION_OF_CROP_PRODUCTION_TYPE_INTERFACE],
        },
        CLASSIFICTION_OF_COST_CENTER_INTO_AUXILIARY_OPERATION_TYPE: {
            CONTENT_URL_ID: { classificationOfCostCenterIntoAuxiliaryOperationType: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'classificationOfCostCenterIntoAuxiliaryOperationType',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.classificationOfCostCenterIntoAuxiliaryOperationType',
                TRANSLATOR_DEFAULT: 'Classification of cost center into auxiliary operation type',
            },
            RIGHTS: [RIGHTS.CLASSIFICTION_OF_COST_CENTER_INTO_AUXILIARY_OPERATION_TYPE_INTERFACE],
        },
        CLASSIFICATION_LIVE_STOCK_TYPE: {
            CONTENT_URL_ID: { classificationLivestockType: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'classificationLiveStockType',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.classificationLiveStockType',
                TRANSLATOR_DEFAULT: 'Classification live stock type',
            },
            EXCLUDE_SYSTEM_MODULES: [
                SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
                SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD,
                SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
                SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
            ],
            RIGHTS: [RIGHTS.CLASSIFICATION_LIVE_STOCK_TYPE_INTERFACE],
        },
        CLASSIFIC_SUMMARY_TYPE: {
            CONTENT_URL_ID: { classificationSummaryType: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'classificSummaryType',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.classificSummaryType',
                TRANSLATOR_DEFAULT: 'Classific summary type',
            },
        },
        ECONOMIC_UNIT_EDIT: {
            CONTENT_URL_ID: { economicUnitEdit: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'economicUnitEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.economicUnitEdit',
                TRANSLATOR_DEFAULT: 'Economic unit edit',
            },
        },
        COST: {
            CONTENT_URL_ID: { cost: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'contentCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.contentCost',
                TRANSLATOR_DEFAULT: 'Content cost',
            },
            HAS_CURRENCY_SWITCH: true,
        },
        WINE_FIRST_COST: {
            CONTENT_URL_ID: { wineFirstCost: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'wineFirstCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.wineFirstCost',
                TRANSLATOR_DEFAULT: 'Wine first cost',
            },
            EXCLUDE_SYSTEM_MODULES: [
                SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
                SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
                SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
                SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
            ],
            RIGHTS: [RIGHTS.ECONOMIC_UNIT_INTERFACE],
        },
        DETAILED_WINE_FIRST_COST: {
            CONTENT_URL_ID: { detailedWineFirstCost: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'detailedWineFirstCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.detailedWineFirstCost',
                TRANSLATOR_DEFAULT: 'Detailed wine first cost',
            },
        },
        CELLAR: {
            CONTENT_URL_ID: { cellar: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cellar',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cellar',
                TRANSLATOR_DEFAULT: 'Cellar',
            },
            EXCLUDE_SYSTEM_MODULES: [
                SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
                SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
                SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
                SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
            ],
            RIGHTS: [RIGHTS.ECONOMIC_UNIT_INTERFACE],
        },
        FRUIT_PREPARATION_FIRST_COST: {
            CONTENT_URL_ID: { fruitPreparationFirstCost: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'fruitPreparationFirstCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.fruitPreparationFirstCost',
                TRANSLATOR_DEFAULT: 'Fruit preparation first cost',
            },
            EXCLUDE_SYSTEM_MODULES: [
                SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
                SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD,
                SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
                SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
            ],
            RIGHTS: [RIGHTS.ECONOMIC_UNIT_INTERFACE],
        },
        PROCESSING_PLANT: {
            CONTENT_URL_ID: { processingPlant: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'processingPlant',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.processingPlant',
                TRANSLATOR_DEFAULT: 'Processing plant',
            },
            EXCLUDE_SYSTEM_MODULES: [
                SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
                SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD,
                SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
                SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
            ],
            RIGHTS: [RIGHTS.ECONOMIC_UNIT_INTERFACE],
        },
        DETAILED_FRUIT_PREPARATION_FIRST_COST: {
            CONTENT_URL_ID: { detailedFruitPreparationFirstCost: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'detailedFruitPreparationFirstCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.detailedFruitPreparationFirstCost',
                TRANSLATOR_DEFAULT: 'Detailed fruit preparation first cost',
            },
        },
        COST_EXCEL_IMPORT: {
            CONTENT_URL_ID: { costExcelImport: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'costExcelImport',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costExcelImport',
                TRANSLATOR_DEFAULT: 'Cost excel import',
            },
            RIGHTS: [RIGHTS.COST_EXCEL_IMPORT_INTERFACE],
        },
        FIRST_COST_OF_THE_REPRODUCTIVE_MATERIAL: {
            CONTENT_URL_ID: { firstCostOfTheReproductiveMaterial: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'firstCostOfTheReproductiveMaterial',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.firstCostOfTheReproductiveMaterial',
                TRANSLATOR_DEFAULT: 'First cost of the reproductive material',
            },
            EXCLUDE_SYSTEM_MODULES: [
                SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
                SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD,
                SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
                SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
            ],
            RIGHTS: [RIGHTS.ECONOMIC_UNIT_INTERFACE],
        },
        SEED_PLANT: {
            CONTENT_URL_ID: { seedPlant: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'seedPlant',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.seedPlant',
                TRANSLATOR_DEFAULT: 'Seed plant',
            },
            EXCLUDE_SYSTEM_MODULES: [
                SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
                SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD,
                SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
                SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
            ],
            RIGHTS: [RIGHTS.ECONOMIC_UNIT_INTERFACE],
        },
        DETAILED_FIRST_COST_OF_THE_REPRODUCTIVE_MATERIAL: {
            CONTENT_URL_ID: { detailedFirstCostOfTheReproductiveMaterial: 21 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'detailedFirstCostOfTheReproductiveMaterial',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.detailedFirstCostOfTheReproductiveMaterial',
                TRANSLATOR_DEFAULT: 'Detailed first cost of the reproductive material',
            },
        },
        COST_PROFIT_CENTRE: {
            CONTENT_URL_ID: { costProfitCentre: 22 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'costProfitCentre',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costProfitCentre',
                TRANSLATOR_DEFAULT: 'Cost profit centre',
            },
            RIGHTS: [RIGHTS.ECONOMIC_UNIT_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        COST_PROFIT_CENTRE_EDIT: {
            CONTENT_URL_ID: { costProfitCentreEdit: 23 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'costProfitCentreEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costProfitCentreEdit',
                TRANSLATOR_DEFAULT: 'Cost profit centre edit',
            },
            RIGHTS: [RIGHTS.ECONOMIC_UNIT_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        MODIFICATION_FIRSTCOST_WITH_COST_ALLOCATION: {
            CONTENT_URL_ID: { modificationFirstCostWithCostAllocation: 24 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'modificationFirstCostWithCostAllocation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.modificationFirstCostWithCostAllocation',
                TRANSLATOR_DEFAULT: 'Modification of first cost with cost allocation',
            },
            EXCLUDE_SYSTEM_MODULES: [
                SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
                SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
                SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
                SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
                SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
            ],
            RIGHTS: [RIGHTS.MODIFICATION_FIRSTCOST_WITH_COST_ALLOCATION],
        },
        MODIFICATION_FIRSTCOST_WITH_COST_ALLOCATION_EDIT: {
            CONTENT_URL_ID: { modificationFirstCostWithCostAllocationEdit: 25 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'modificationFirstCostWithCostAllocationEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.modificationFirstCostWithCostAllocationEdit',
                TRANSLATOR_DEFAULT: 'Edit the modification of first cost with cost allocation',
            },
            RIGHTS: [RIGHTS.MODIFICATION_FIRSTCOST_WITH_COST_ALLOCATION],
        },
    },
};
