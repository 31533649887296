import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import Svg from '@baseComponents/Svg';
import { Form } from 'react-bootstrap';
import ReactTags from 'react-tag-autocomplete';
import Button from '@baseComponents/buttons/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import useTranslate from '@i18n/useTranslate';
import { isEmpty, isUndefined, isArray } from 'lodash';
import { messageService, userService } from '@services';
import { useDispatch } from 'react-redux';
import { alertActions, errorActions } from '@states/actions';
import { useLocation } from 'react-router';

const useStyles = createUseStyles((theme: any) => ({
    newMessageContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    newMessageHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '25px',
        borderBottom: `1px solid ${theme.color.pearl}`,
    },
    newMessageTitle: {
        fontSize: '18px!important',
        fontWeight: '500',
        color: theme.color.jet,
        fontFamily: 'Rubik',
        marginTop: '0',
    },
    newMessageClose: {
        width: '40px',
        height: '40px',
        border: 'none',
        borderRadius: '50px',
        backgroundColor: theme.color.pearl,
    },
    menuBottomControlsSearch: {
        width: '20px',
        height: '20px',
    },
    newMessageInputTo: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '30px',
    },
    label: {
        fontFamily: 'Rubik',
        fontWeight: '500',
        color: theme.color.jet,
    },
    selectedTag: {
        marginTop: '10px',
        marginBottom: '10px',
        marginRight: '5px',
        border: `1px solid ${theme.color.gray}`,
        borderRadius: '4px',
        backgroundColor: theme.color.white,
    },
    suggestions: {
        backgroundColor: 'transparent',
        border: `1px solid ${theme.color.gray}`,
        padding: '0',
        margin: '0',
        marginTop: '5px',
    },
    statusError: {
        color: theme.color.status_error,
    },
    newMessageInputSubject: {
        display: 'flex',
        flexDirection: 'column',
    },
    input: {
        fontSize: '15px',
        borderRadius: '4px',
        height: '40px',
        border: `1px solid ${theme.color.gray}`,
        fontFamily: 'Rubik',
        color: theme.color.jet,
        paddingLeft: '15px',
        outline: 'none',
    },
    newMessageInputMessage: {
        display: 'flex',
        flexDirection: 'column',
    },
    sendMessage: {
        width: 'max-content',
        marginLeft: 'auto',
        marginRight: '0',
        marginTop: '30px',
        height: '40px',
    },
}));

export default function NewMessage(props) {
    const { onMessageClose, user, usersProp, bodyProp, editorStateProp, initialProp } = props;
    const classes = useStyles();
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const editorConfig = {
        toolbar: ['heading', 'numberedList', 'bulletedList', 'bold', 'italic'],
    };

    const [users, setUsers] = useState(usersProp);
    const [body, setBody] = useState(bodyProp);
    const [editorState, setEditorState] = useState(editorStateProp);
    const [initial, setInitial] = useState(initialProp);

    useEffect(() => {
        setUsers(usersProp);
        setBody(bodyProp);
        setEditorState(editorStateProp);
        setInitial(initialProp);
    }, [usersProp, bodyProp, editorStateProp, initialProp]);

    async function handleSubmit(event) {
        event.preventDefault();
        try {
            setInitial(false);

            let isValid = true;

            const userTags = users?.tags;
            if (isEmpty(userTags)) {
                isValid = false;
            }

            if (isEmpty(body?.subject)) {
                isValid = false;
            }

            if (isEmpty(editorState)) {
                isValid = false;
            }

            if (!isValid) {
                return;
            }

            const toUserIds = userTags.map(tagUser => tagUser.id);

            const messageResponse = await messageService.setMessage(toUserIds, body.subject, editorState);

            if (messageResponse) {
                setUsers({ tags: [], suggestions: [] });
                setBody({ subject: '', message: '' });
                setEditorState('');
                onMessageClose();
                dispatch(alertActions.successAlert(t('messages.messageSent', 'The message is successfully sent!')));
            }
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }

    async function handleInputChange(userNameFargment) {
        try {
            if (isEmpty(userNameFargment)) {
                return;
            }

            const rawUserList = await userService.getUsersByName(userNameFargment);
            if (!isArray(rawUserList)) {
                return;
            }

            const userList = rawUserList.filter(currentUser => {
                const sameUser = currentUser.id === user.id;

                if (sameUser) {
                    return false;
                }

                if (isEmpty(users.tags)) {
                    return true;
                }

                return users?.tags?.findIndex(currentTagValue => currentTagValue.id === currentUser.id) === -1;
            });
            const change: any = { tags: [], suggestions: [] };
            if (isUndefined(users.tags)) {
                change.tags = [];
            }
            if (!isUndefined(users.tags)) {
                change.tags = [...users.tags];
            }
            change.suggestions = userList;
            setUsers(change);
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }

    const handleDelete = deleteIndex => {
        const tags = users.tags.slice(0);
        tags.splice(deleteIndex, 1);
        const changeTags = { tags: [], suggestions: [] };
        changeTags.tags = tags;
        changeTags.suggestions = users.suggestions;
        setUsers(changeTags);

        const changeBody = body;
        changeBody.toUser = null;
        setBody(changeBody);
    };

    const handleAddition = tag => {
        const tags = [...users.tags, tag];
        const changeTags: any = { tags: [], suggestions: [] };
        changeTags.tags = tags;
        changeTags.suggestions = users.suggestions;
        setUsers(changeTags);

        const value = tag.id;
        const changeBody = body;
        changeBody.toUser = value;
        setBody(changeBody);
    };

    const changeHandler = async event => {
        const name = event.target.name;
        const value = event.target.value;

        const newBody = {};
        Object.assign(newBody, body);

        newBody[name] = value;

        setBody(newBody);
    };

    const handleEditorChange = (event, editor) => {
        setEditorState(editor.getData());
    };

    return (
        <Form className={classes.newMessageContainer} onSubmit={handleSubmit}>
            <div className={classes.newMessageHeading}>
                <h3 className={classes.newMessageTitle}>{t('messages.new_short', 'New message')}</h3>
                <button onClick={onMessageClose} className={classes.newMessageClose} type="button">
                    <Svg style={classes.menuBottomControlsSearch} iconId="icon-close" />
                </button>
            </div>
            <Form.Group className={classes.newMessageInputTo} controlId="messagesForm.toUser">
                <label className={classes.label} htmlFor="toUser">{t('messages.address', 'Address')}</label>
                <ReactTags
                    classNames={{
                        selectedTag: classes.selectedTag,
                        suggestions: classes.suggestions,
                    }}
                    id="toUser"
                    name="toUser"
                    autoresize={false}
                    placeholder=""
                    tags={users.tags}
                    suggestions={users.suggestions}
                    handleInputChange={handleInputChange}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                />
                <div className={classes.statusError}>{initial || !isEmpty(users?.tags) ? '' : t('messages.required', 'Required field')}</div>
            </Form.Group>
            <Form.Group className={classes.newMessageInputSubject} controlId="messagesForm.subject">
                <label className={classes.label} htmlFor="subject">{t('messages.subject', 'Subject')}</label>
                <input className={classes.input} id="subject" name="subject" type="text" value={body.subject || ''} onChange={changeHandler} />
                <div className={classes.statusError}>{initial || !isEmpty(body?.subject) ? '' : t('messages.required', 'Required field')}</div>
            </Form.Group>
            <Form.Group className={classes.newMessageInputMessage} controlId="messagesForm.message">
                <CKEditor config={editorConfig} data={editorState} editor={ClassicEditor} onChange={handleEditorChange} />
                <div className={classes.statusError}>{initial || !isEmpty(editorState) ? '' : t('messages.required', 'Required field')}</div>
            </Form.Group>
            <Button className={classes.sendMessage} iconId="icon-send" iconPosition="before" size="small" submit="true">{t('messages.send', 'Send')}</Button>
        </Form>
    );
}
