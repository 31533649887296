import { useSelector } from 'react-redux';
import { isEmpty, uniq } from 'lodash';
import { useMapContext } from '@map/services/mapContext';
import { visiblefarmService } from '@services/visiblefarmService';
import { BuiltInLayerTypes } from '@map/services/mapEnums';

export const useVisibleFarmService = () => {
    const [, mapController] = useMapContext();
    const agentId = useSelector((state: any) => state.company.company.globalData.id);

    const syncPolygons = async (geomRequestData: any[]) => {
        let periodIds : any = [];
        let periodGroupIds : any = [];

        try {
            if (!isEmpty(geomRequestData)) {
                geomRequestData.forEach((geom : any) => {
                    const layer = mapController.getLayerById(geom.layerId);

                    switch (layer?.name) {
                        case BuiltInLayerTypes.CULTIVATION_PERIOD:
                            periodIds.push(geom.entityId);
                            break;
                        case BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP:
                            periodGroupIds.push(geom.entityId);
                            break;
                        default:
                            break;
                    }
                });

                periodIds = uniq(periodIds);
                periodGroupIds = uniq(periodGroupIds);

                if (periodIds.length > 0) {
                    await visiblefarmService.updatePeriodBatch({
                        agentId: agentId,
                        periodType: 0,
                        ids: periodIds                        
                    });
                }

                if (periodGroupIds.length > 0) {
                    await visiblefarmService.updatePeriodBatch({
                        agentId: agentId,
                        periodType: 1,
                        ids: periodGroupIds                        
                    });
                }
            }
        } catch (error) {
            // ha ez meghal, a felületen ne látsszon
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;
        }
    };

    return [syncPolygons];
};
