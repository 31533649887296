/* eslint-disable id-blacklist */
import { Nullable } from '@flowsCommon/services/baseTypes';
import { theme } from '@styles/theme';
import { Feature, FeatureCollection, Geometry, Point } from 'geojson';
import { TFunction } from 'i18next';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { MapSliderIntervalMode } from '@map/components/MapSlider/MapSliderTypes';
import { MAP_FILTER_WORK_OPERATION_STATUS } from '@map/components/MapFilter/MapFilterSchema';
import { BuiltInLayerTypes } from './mapEnums';

//API

export enum ProcessGeomErrorCode {
  NONE = -1,
  FILE_NOT_FOUND = 1,
  PARSING_ERROR = 2,
  NO_POLYGON_DATA = 3,
  NO_CRS = 4,
  UNSUPPORTED_FILE_FORMAT = 5,
  INVALID_CRS = 6,
  FILE_TOO_LARGE = 7,
  INVALID_GEOMETRY_TYPE = 8,
  MORE_THAN_ONE_COMPRESSED_GEO_FILE = 9,
  TIMEOUT = 10,
  UNKNOWN_ERROR = 999,
}

export enum ProcessGeomWarningCode {
  NONE = -1,
  SOME_POLYGONS_NOT_VALID = 1,
}

export type GeoProcessResponse = {
  error?: ProcessGeomErrorCode;
  warning?: ProcessGeomWarningCode;
  data?: FeatureCollection;
};

export type ProcessGeometryDto = {
  featureCollection: FeatureCollection,
  error?: number,
  warning?: number
}

export type GetGeometriesForLayersProps = {
  layers: Layer[],
  customFilters?: MapFilters
  shouldFetchCosts?: boolean;
  layerAttributes: LayerAttribute[],
}

export type GeomToSvgDto = {
  id: number;
  geometry: Geometry;
}

export type GeomSvgDto = {
  id?: number;
  viewBox: string;
  path: string;
}

export type GeomToShpDto = {
  layerId: number;
  productionYearId?: number;
}

export type DefaultCenterDto = {
  coordinates: {
    lat: number,
    lng: number,
  },
  zoom: number,
}

export type LayersDto = {
  layers: LayerDto[],
}

export enum LayerType {
  FEATURE = 'FEATURE',
  FOLDER = 'FOLDER'
}

export type LayerDto = {
  id: number,
  name: string,
  readonly: boolean,
  isCustomLayer: boolean;
  type : LayerType,
  parentId : number,
  planId?: number,
  defaultFillColor: string,
  defaultStrokeColor: string,
  attributes: LayerAttributeDto[]
}

export type GetCustomLayerDataResponseDto = {
  companyIds: number[],
  farmIds?: number[],
  existingLayerAttributes?: string[],
  startDate?: string;
  endDate?: string;
}

export type LayerGeometriesWithBboxDto = {
  layerGeoms: LayerGeomsDto[],
};

export type LayerGeomsDto = {
  id: number,
  geoms: FeatureCollection,
  name: string,
  bbox: number[],
}

export type MachineValuesVfDto = {
  property: number,
  value: number
}

export type MachineDataAtributesVfDto = {
  ExternalID: string;
  Measure: number;
  Time: string;
  Position: Point;
  Values: MachineValuesVfDto[];
}

export type MachineDataResponse = {
  deviceID: number;
  deviceIDwithDomain: string;
  name: string;
  companyName: string;
  idNumber: string;
  profilePicture: string;
  mainTypeId: number;
  attributes: MachineDataAtributesVfDto;
};

export enum LayerAttributeColoringTypes {
  DISCRETE = 'DISCRETE',
  SCALE = 'SCALE',
  CUSTOM = 'CUSTOM'
}

export interface LayerAttributeDto {
  id: number,
  displayName?: string,
  attributeKey: string,
  coloringType: LayerAttributeColoringTypes,
  defaultFillColor: string,
  defaultStrokeColor: string,
  isDefault: boolean,
  suffix?: string,
  timelineProductionYearId?: number,
}

//API: Layer Data

export interface BaseDataResponse {
  entityId: number;
  centroid: {
    lat: number;
    lng: number;
  };
  area: number;
  perimeter: number;
  name: string;
}

export interface LotDataResponse extends BaseDataResponse {
  companyId: number;
  companyName: string;
  farmId?: number;
  farmName?: string;
}

export interface CultivationPeriodDataResponse extends BaseDataResponse {
  companyId: number;
  companyName: string;
  cropTypeId: number;
  cropTypeName: string;
  cultureId: number;
  cultureName: string;
  cultureColorCode: string;
  directCost: number;
  directCostPerHectare: number;
  directCostPerHectareSC: number;
  directCostSC: number;
  directCostService: number;
  directCostServicePerHectare: number;
  directCostServicePerHectareSC: number;
  directCostServiceSC: number;
  farmId: number | string;
  farmName: string;
  grossQuantity: string;
  grossYields: number;
  id: number;
  income: string;
  machineCost: number;
  machineCostPerHectare: number;
  machineCostPerHectareSC: number;
  machineCostSC: number;
  machineCostService: number;
  machineCostServicePerHectare: number;
  machineCostServicePerHectareSC: number;
  machineCostServiceSC: number;
  nitrogenKgHa: number;
  operationCost: number;
  operationCostPerHectare: number;
  operationCostPerHectareSC: number;
  operationCostSC: number;
  operationCostService: number;
  operationCostServicePerHectare: number;
  operationCostServicePerHectareSC: number;
  operationCostServiceSC: number;
  periodId: number;
  plusCost: number;
  plusCostPerHectare: number;
  plusCostPerHectareSC: number;
  plusCostSC: number;
  prodouctCost: number;
  productCostPerHectare: number;
  productCostPerHectareSC: number;
  productCostSC: number;
  productionYear: string;
  productionYearId: number;
  quantity: string;
  quantityKg: string;
  sownArea: string;
  totalCost: number;
  totalCostPerHectare: number;
  totalCostPerHectareSC: number;
  totalCostSC: number;
  totalCostService: number;
  totalCostServicePerHectare: number;
  totalCostServicePerHectareSC: number;
  totalCostServiceSC: number;
  wageCost: number;
  wageCostPerHectare: number;
  wageCostSC: number;
  wageWorkCost: number;
  wageWorkCostPerHectare: number;
  wageWorkCostSC: number;
  yield: number | string;
  yields: number;
}

export interface LpisFieldDataResponse extends BaseDataResponse {
  blockIdentifier: string;
  companyId: number;
  companyName: string;
  fieldIdentifier?: number;
  mainPlantCultureId: number;
  mainPlantCultureName: string;
  nitrogenKgHa?: number;
  productionYear: string;
  productionYearId: number;
  secondaryPlantCultureId?: number;
  secondaryPlantCultureName?: string;
}

export interface RegistryNumberDataResponse extends BaseDataResponse {
  ak: string;
  companyIds: string;
  companyNames: string[];
  cultivationType: string;
  titleDeed: string;
}

export interface PremiseDataResponse extends BaseDataResponse {
  companyId: string;
  companyName: string;
}

export interface StableDataResponse extends BaseDataResponse {
  capacity: number;
  companyId: string;
  companyName: string;
}

export interface CultivationPeriodGroupDataResponse extends CultivationPeriodDataResponse {
  companyIds: number[];
  companyNames: string[];
  cropTypeNames: string[];
  periodGroupId: number;
  perioudGroupName: string;
  periodIds: number[];
  startDates: string[];
  tableNameList: string[];
}

export interface MachineData {
  vfCustomerId?: number;
  deviceId?: number;
  vfDeviceId: number;
  name?: string;
  companyName?: string;
  identificationNumber?: string; //alvázszám
  prifilePicture: string;
  time: string;
  position: Point;
  values: MachineValuesVfDto[];
}

//UI

export type MapFilters = {
  showClosedPeriods: boolean;
  productionYearId: number,
  statusId: number,
  farmIds: number[],
  companyIds: number[],
  cultureIds: number[],
  entity: Nullable<{
    layerName: string;
    ids: number[];
    excludedIds: number[];
  }>,
  cropType: number;
  workOperationMainGroupIds: number[];
  workOperationStatus: MAP_FILTER_WORK_OPERATION_STATUS,
}
export interface Layer {
  id: number,
  name: string,
  type : LayerType,
  readonly : boolean,
  parentId : number,
  defaultFillColor: string,
  defaultStrokeColor: string,
  fetchedGeometries: Nullable<FeatureCollection>,
  geometries: Nullable<FeatureCollection>,
  isCustomLayer?: boolean;
  machines: Nullable<MachineDataResponse[]>,
  bbox?: number[];
  costFetched: boolean;
  planId?: number;
  isPlanningOpen?: boolean;
  adittionalGeometryData?: AddittionalGeometryData;
  additionalAttributes?: InfoPanelLayerAttribute[];
}

export interface PlanningLayerData {
  id: number;
  isPlanningOpen: boolean;
}

export type machineIDAndMarker = {
  deviceId: number,
  marker: google.maps.Marker,
};

export enum LayerAttributeStatus {
  INACTIVE = 0,
  ACTIVE = 1,
  PENDING = 2,
}
export interface LayerAttribute extends LayerAttributeDto {
  layerId: number,
  layerName: string,
  isActive: LayerAttributeStatus,
  isLabelingEnabled: boolean,
  dataLayer: google.maps.Data,
  defaultAttributeKey: string,
  labelLayer: google.maps.MVCArray<google.maps.Marker>,
  machineLayer: google.maps.MVCArray<machineIDAndMarker>,
  markerCluster: MarkerClusterer,
  legendList:legendEntity[],
  opacity?: number;
}
//MISC

export type legendEntity = {
  id: number;
  fillColor: string,
  range: string
  isVisible?: boolean;
};

export type ThemeType = typeof theme;

export type TranslateType = {
  t: (moduleAndKey: string, defaultText: string) => JSX.Element,
  translater: TFunction
}

export type GeomEntity = {
  id?: number,
  geom?: Geometry
};

export type GeomSaveEntity = {
    companyIds?: number[],
    layerId: Nullable<number>,
    entityId: number,
    productionYearId?: number,
    farmIds?: Nullable<number[]>,
    geometry?: Geometry,
    startDate?: string,
    endDate?: string,
    customLayerProperties?: object,
}

export type PostGeomRequestDto = {
  layerAttributeWithTypes?: object,
  isCustomLayer: boolean,
  customLayerId?: Nullable<number>,
  geomRequestData: GeomSaveEntity[],
}

export type MultiGeometrySelector = {
  yearId : number,
  dataLayer: Nullable<google.maps.Data>,
  selectedFeatures : Nullable<Feature[]>,
  entityIds : Nullable<number[]>
}

export type SelectedLayer = {
  layerId : number,
  attributeId : number,
  isLabelingEnabled: boolean,
  dataLayer: google.maps.Data,
  labelLayer: google.maps.MVCArray<google.maps.Marker>,
}

//manage layers
export interface CreateLayerDto {
  type: LayerType,
  name : string,
  defaultFillColor: string,
  defaultStrokeColor: string,
  parentId: number,
  domainId: number,
}

export interface UpdateLayerDto extends CreateLayerDto {
  id : number
}

export interface GetLayerDto extends CreateLayerDto {
  id : number,
  attributes: LayerAttributeDto[]
}

export interface GeomDto {
  id: number;
  createdAt: string;
  updatedAt: string;
  geom: GeomGeometry;
  area: number;
  farms?: GeomFarm[];
  productionYearId: number;
  entityId: number;
  companies: GeomCompany[];
}

export interface GeomCompany {
  companyId: number;
}

export interface GeomFarm {
  farmId: number;
}

export interface GeomGeometry {
  type: string;
  coordinates: number[][][];
}

export interface LayerSelectorAttributeFilter {
  layerName: string,
  attributeKey: string,
}

export interface LayerSelectorSettings {
    attributeFilter: {
      whiteList?: Nullable<LayerSelectorAttributeFilter[]>,
      blackList?: Nullable<LayerSelectorAttributeFilter[]>,
    }
    layerFilter: {
      blackList?: Nullable<Array<string>>
    }
}

export interface MapMultiModeProps {
  isMultiModeEnabled: boolean,
  setIsMultiModeEnabled: (enabled: boolean) => void
}

export interface AddittionalGeometryData {
  geomData: any,
}

export type MapSliderStore = {
  isEnabled: boolean,
        attributesToSkipInterval: BuiltInLayerTypes[],
        attributesToUseYearFilter: BuiltInLayerTypes[],
        startDate?: Date,
        endDate?: Date,
        intervalMode: MapSliderIntervalMode
}

// SLovakian

export type SKBoundingBox = {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
  spatialReference: {
    wkid: number;
    latestWkid: number;
  }
};
export interface InfoPanelLayerAttribute {
  id: number;
  suffix?: string;
  attributeKey: string;
  defaultAttributeKey: string;
  displayName?: string;
}

export interface SecondaryLayer {
  layerName: BuiltInLayerTypes,
  productionYear: number,
  filter: {
    entityIds: number[]
  }
}
