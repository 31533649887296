export const DEFAULT_SYSTEM_DATA = {
    SITE_DATA: {
        DESCRIPTION: 'AgroVIR - More Than Farm Management',
        TITLE: 'AgroVIR',
        CHARSET: 'utf-8',
    },
    KNOWLEDGE_URL: 'https://help.agrovir.hu',
    NEW_INTERFACES_URL: 'https://help.agrovir.hu/termesztesi_idoszak',
    ADVANCER_URL: 'https://help.agrovir.hu/advancer',
    GDPR: {
        EXPIRE_DAYS: 150,
        HU_URL: 'https://www.agrovir.eu/adatvedelem/adatvedelmi_nyilatkozat.html',
        RO_URL: 'https://www.agrovir.eu/securitate/politica-de-confidentialitate.html',
        EN_URL: 'https://www.agrovir.eu/privacy/privacy-policy.html',
    },
    HEADQUARTER: '2040 Budaörs, Kinizsi utca 30.',
    SITE: {
        HU: {
            LINK: 'https://www.agrovir.com/HU',
            NAME: 'www.agrovir.hu'
        },
        RO: {
            LINK: 'https://www.agrovir.com/RO',
            NAME: 'www.agrovir.ro'
        },
        SK: {
            LINK: 'https://www.agrovir.com/SK',
            NAME: 'www.agrovir.sk'
        },
        AR: {
            LINK: 'https://www.agrovir.com/AR',
            NAME: 'www.agrovir.com.ar'
        },
        DEFAULT: {
            LINK: 'https://www.agrovir.com',
            NAME: 'www.agrovir.com'
        },
    },
    EMAIL: {
        HU: [
            'support@agrovir.hu' 
        ],
        RO: [
            'support@agrovir.eu'
        ],
        DEFAULT: [
            'support@agrovir.eu'
        ],
    },
    PHONE: {
        HU: [
            '+ 36 30 089 2390',
            '+ 36 30 322 5674'
        ],
        RO: [
            '+ 36 30 340 3882',
            '+ 36 30 120 3270'
        ],
        AR: [
            '+ 54 9 11 2256 5976', 
            '+ 36 30 089 2390'
        ],
        DEFAULT: [
            '+ 36 30 089 2390'
        ],
    },
    PHONE_NUMBERS: [
        '+36',
        '+40',
        '+555',
    ],
};
