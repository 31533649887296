import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import { LoadSpinner } from '../../../../baseComponents/loadSpinner';
import BasicFieldOverall from '../../../../customComponents/basicFields/BasicFieldOverall';
import SupportOverall from '../../../../customComponents/support/SupportOverall';
import RegistryNumberOverall from '../../../../customComponents/registryNumber/RegistryNumberOverall';
import FileInput from '../../../../baseComponents/inputs/DataInput';

const useStyles = createUseStyles({
    marginTop: {
        marginTop: 15,
    },
    overallCardWrapper: {
        minHeight: 42,
        width: '100%',
        position: 'relative',
        display: 'block',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        outline: 'none',
    },
});

export default function PeriodViewOverallCard(props) {
    const { supportList, registryNumberList, basicFieldsList } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.overallCardWrapper, classes.marginTop)}>
            <LoadSpinner everyValues={[supportList, registryNumberList, basicFieldsList]} isAutoDetect />

            {!isEmpty(basicFieldsList) && (
                <FileInput className={classes.marginTop} disabled>
                    <BasicFieldOverall basicFields={basicFieldsList} />
                </FileInput>
            )}

            {!isEmpty(supportList) && (
                <FileInput className={classes.marginTop} disabled>
                    <SupportOverall support={supportList} disabled />
                </FileInput>
            )}

            {!isEmpty(registryNumberList) && (
                <FileInput className={classes.marginTop} disabled>
                    <RegistryNumberOverall registryNumber={registryNumberList} disabled />
                </FileInput>
            )}
        </div>
    );
}
