import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { isBoolean, isEmpty } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import { routerHelper } from '@utils/router/RouterHelper';
import { phpHelper } from '@utils/php/PhpHelper';
import { sessionService } from '@services';
import { MODULES } from 'shared/src/routing';
import { RIGHTS } from 'shared/src/constants';
import { userActions } from '@states/actions';
import HeaderBackButton from '@baseComponents/basePageNew/header/headerTitleLine/HeaderBackButton';
import CurrencySwitch from '@baseComponents/basePageNew/body/bodyFunctionLine/bodyFunctionLineRightSide/currencySwitch/CurrencySwitch';
import HeaderTitle from '@baseComponents/basePageNew/header/headerTitleLine/HeaderTitle';

const useStyles = createUseStyles({
    editStartPage: {
        display: 'inline-block',
        float: 'right',
        paddingTop: 5,
        paddingRight: 22,
        verticalAlign: 'middle',
        lineHeight: '40px',
    },
    currencySwitch: {
        display: 'inline-block',
        float: 'right',
        paddingTop: 5,
        paddingRight: '15px !important',
    },
    backAndTitle: {
        display: 'inline-flex',
        float: 'left',
        alignItems: 'center',
        paddingTop: 5,
        paddingLeft: 30,
        '& button': {
            marginLeft: -7,
        },
        verticalAlign: 'middle',
    },
    headerDiv: {
        height: 45,
    },
});

export default function PhpHeader(props) {
    const { isLoadedPage, isSettingsContentActiv } = props;
    const { t } = useTranslate();
    const location = useLocation().pathname;
    const classes = useStyles();
    const dispatch = useDispatch();

    const isPlanningMode = useSelector((state: any) => state.system.planningModeisActive);
    const [agentName] = useState(sessionService.getAgentName());
    const [actualContentName, setActualContentName] = useState<any>(null);
    const [actualContentObject, setActualContentObject] = useState<any>(null);
    const [backButtonRedirectUrl, setBackButtonRedirectUrl] = useState<any>(null);
    const [userRights] = useState<any>(
        dispatch(userActions.getRights({
            editStartPageSettingsRight: RIGHTS.ACCESSING_START_PAGE_SETTINGS_INTERFACE,
        })),
    );

    //init page title
    useEffect(() => {
        if (location) {
            const contentName = routerHelper.getCurrentContentName(location);
            const contentObject = routerHelper.getContentObjectByUrl(location);

            setActualContentName(contentName);
            setActualContentObject(contentObject);
        }
    }, [location]);

    //init page back button
    useEffect(() => {
        if (isBoolean(isLoadedPage) && !isEmpty(agentName)) {
            setBackButtonRedirectUrl(phpHelper.getPhpBackButtonUrl(agentName, isPlanningMode));
        }
    }, [isLoadedPage, agentName, isPlanningMode]);

    const isVisibleHeaderLine = useCallback(() => (routerHelper.isHomePage(location))
            || ((actualContentObject && !actualContentObject.IGNORE_TITLE_LINE) && (actualContentName !== 'index' || isSettingsContentActiv)
            && ((actualContentObject && !actualContentObject.IS_MENU_PAGE) || (!actualContentObject && !actualContentName))), [actualContentName, actualContentObject, isSettingsContentActiv, location]);

    //php-main content magasság módosítás (landing page-en eltérő magasság szükséges)
    useEffect(() => {
        const mainContent = document.getElementById('main_content');
        if (mainContent) {
            if (isVisibleHeaderLine()) {
                if (isPlanningMode) {
                    mainContent.style.height = 'calc(100% - 70px)';
                } else {
                    mainContent.style.height = 'calc(100% - 45px)';
                }
            } else {
                mainContent.style.height = 'calc(100% - 15px)';
            }
        }
    }, [isPlanningMode, isVisibleHeaderLine]);

    if (!isVisibleHeaderLine()) {
        return null;
    }

    return (
        <div id="main_header" className={classes.headerDiv}>
            <div className={classes.backAndTitle}>
                {!isSettingsContentActiv && !isEmpty(actualContentName) && (backButtonRedirectUrl ? <HeaderBackButton url={backButtonRedirectUrl} /> : null) }
                <HeaderTitle isSettingsContentActiv={isSettingsContentActiv} />
            </div>

            {!isPlanningMode && (
                <div className={classes.currencySwitch}>
                    <CurrencySwitch />
                </div>
            )}

            {routerHelper.isHomePage(location) && !isPlanningMode && Boolean(userRights.editStartPageSettingsRight) && (
                <div className={classes.editStartPage}>
                    <Link to={routerHelper.generateUrl(MODULES.WIDGET, MODULES.WIDGET.CONTENTS.WIDGET_USER, 'from_url=main_page')}>
                        {t('pageTitle.homePageCustomization', 'Home page customization')}
                    </Link>
                </div>
            )}
        </div>
    );
}
