import { useState, useEffect, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { isFunction } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import ModalRightTabs from './ModalRightTabs';
import ModalRightContents from './ModalRightContents';

const useStyles = createUseStyles((theme: any) => ({
    modalRight: {
        display: 'flex',
        flexFlow: 'column',
        width: '100%',
        height: '100%',
        minWidth: 1020,
        overflowY: 'hidden',
    },
    rightContent: {
        position: 'relative',
        display: 'flex',
        backgroundColor: theme.color.lilian,
        borderBottomRightRadius: 12,
        height: '92%',
        width: '100%',
        flexShrink: 1,
    },
    continueText: {
        fontSize: 12,
        fontWeight: 500,
        color: theme.color.jet,
        width: '100%',
        height: '100%',
        display: 'flex',

        '& span': {
            margin: 'auto',
        },
    },
    tabWrapper: {
        '& .tabWrapper': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
}));

export default function ModalRightWithTabs(props) {
    const { rightContent, isOpenRightSide, tabNumber, selectedTabKey, hasTabInitLabel, tabInitLabel, onChangeTab, children, showAddIcon = true } = props;
    const { errorTabKey, errorMessage } = props; //erorr handler
    const { t } = useTranslate();
    const [selectedHeaderTitleKey, setSelectedHeaderTitleKey] = useState();
    const classes = useStyles();

    useEffect(() => {
        if (isOpenRightSide && selectedTabKey) {
            setSelectedHeaderTitleKey(selectedTabKey);
        }
    }, [isOpenRightSide, selectedTabKey]);

    //error handler
    useEffect(() => {
        if (errorTabKey) {
            setSelectedHeaderTitleKey(errorTabKey);
        }
    }, [errorTabKey]);

    const onChangeRightTab = useCallback(tabKey => {
        if (isFunction(onChangeTab)) {
            onChangeTab(tabKey);
        }
        setSelectedHeaderTitleKey(tabKey);
    }, [onChangeTab]);

    return (
        <div className={classes.modalRight}>
            <ModalRightTabs
                headerTitles={rightContent}
                selectedTabKey={selectedHeaderTitleKey}
                errorTabKey={errorTabKey}
                onChangeRightTab={onChangeRightTab}
                tabNumber={tabNumber}
                disabled={!isOpenRightSide}
                showAddIcon={showAddIcon}
            />
            <div className={classes.rightContent}>
                {!!hasTabInitLabel && !selectedHeaderTitleKey && (
                    <div className={classes.continueText}>
                        {tabInitLabel || t('default.requiredFields', 'Please fill the marked fields!')}
                    </div>
                )}
                {/*isOpenRightSide && selectedHeaderTitleKey && getRightContent()*/}
                {isOpenRightSide && selectedHeaderTitleKey ? (
                    <ModalRightContents
                        selectedHeaderTitleKey={selectedHeaderTitleKey}
                        rightContent={rightContent}
                        errorMessage={errorMessage}
                        errorTabKey={errorTabKey}
                    />
                ) : null }
                {children}
            </div>
        </div>
    );
}
