import { useState } from 'react';
import { numberUtils } from 'shared/src/modules';
import { CROP } from 'shared/src/constants';

export default function useLoadCloseAndOpenCultivationPeriodDefaultValue(props) {
    const { cultivationPeriod, isGroupOperation } = props;
    const [cultivationPeriodDefaultValue] = useState({
        name: isGroupOperation ? '' : cultivationPeriod?.name,
        period: '',
        periodYear: '',
        endDate: '',
        startDate: '',
        isPreviousCultureVariety: false,
        isPreviousComment: false,
        isPreviousFieldFeatures: false,
        culture: 0,
        variety: 0,
        sownArea: isGroupOperation ? '' : numberUtils.getNumberFormat(cultivationPeriod?.sownArea),
        cropType: cultivationPeriod?.cropType || CROP.TYPE.MAIN_CROP,
    });

    return cultivationPeriodDefaultValue;
}
