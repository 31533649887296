import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { Dropdown } from 'react-bootstrap';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { languageUtils } from 'shared/src/modules/languageUtils';
import { useTranslation } from 'react-i18next';
import { stringUtils } from 'shared/src/modules';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    menuItem: {
        fontSize: 15,
        fontWeight: 'normal',
        lineHeight: '20px',
        padding: '1.00rem 1.5rem',
        height: theme.subMenu.buttonHeight,
        color: theme.color.jet,
        background: theme.color.white,
        textAlign: 'left',
        ...theme.overFlowText,
        ...theme.disableSelectionColor,
        ...theme.disableOutline,
        '&:hover': {
            cursor: 'pointer',
            background: theme.color.opacity.main,
            color: theme.color.main,
            textDecoration: 'none',
        },
        '&:active': {
            color: theme.color.jet,
        },
        display: 'block',
        paddingLeft: (props: any) => props.iconId && 5,
        paddingRight: (props: any) => props.iconId && 5,
    },
    selectedMenuItem: {
        color: theme.color.main,
        fontWeight: 500,
        background: theme.color.opacity.main,
        textAlign: 'left',
        outline: 'none',
        ...theme.overFlowText,
        ...theme.disableSelectionColor,
        '&:hover': {
            cursor: 'default',
            color: `${theme.color.main} !important`,
            background: theme.color.opacity.main,
        },
        '&:active': {
            color: `${theme.color.main} !important`,
        },
    },
    secondValue: {
        color: theme.color.stone,
        fontSize: 13,
        marginLeft: 5,
    },
    icon: {
        height: 24,
        width: 24,
        margin: 'auto',
        marginRight: 5,
        verticalAlign: 'middle',
        alignItems: 'center',
        fill: (props: any) => props.iconColor,
    },
}));

export default function DropDownItem(props) {
    const { dataKey, dataValue, onClick, selectedItem, secondValue, iconId, iconColor, isDisabled } = props;
    const { t } = useTranslation();
    const classes = useStyles({ iconColor, iconId } as any);

    const getStyle = useCallback(() => {
        if (selectedItem && stringUtils.isSame(dataKey, selectedItem.key)) {
            return clsx(classes.menuItem, classes.selectedMenuItem, 'selectItem');
        }
        return clsx(classes.menuItem, 'selectItem');
    }, [selectedItem, dataKey, classes]);

    function onClickDropitem() {
        if ((!selectedItem || !stringUtils.isSame(dataKey, selectedItem.key)) && onClick) {
            onClick();
        }
    }

    return (
        <Dropdown.Item eventKey={dataKey} className={getStyle()} onClick={onClickDropitem} title={languageUtils.getTitle(t, dataValue)} disabled={isDisabled}>
            {!isEmpty(iconId) && <Svg iconId={iconId} style={classes.icon} />}
            {dataValue}
            {secondValue
                && (
                    <span className={classes.secondValue}>
                        ({secondValue})
                    </span>
                )}
        </Dropdown.Item>
    );
}
