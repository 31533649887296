import { GENERAL } from 'shared/src/constants';
import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

/**
 * Get all machine datas
 * @property {array<int>} companyIds IsOptional - Company ID list eg.: [1]
 * @property {int} machineStatus IsOptional - Machine status eg.: 1
 * 0 - INACTIVE
 * 1 - ACTIVE
 * 2 - ALL (default)
 *
 * @return {array} Machine array
 */
async function getMachineList(companyIds, machineStatus) {
    const url = routerHelper.getUrlWithParameters('/api/machine', { companyIds, machineStatus });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getMachineConnectionList(machineConnectionStatus = GENERAL.FILTER.STATUS.ACTIV) {
    const url = routerHelper.getUrlWithParameters('/api/machine/machineConnection', { machineConnectionStatus });
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Visszaadja a gép főtípusokat
 * @returns dataList
 */
async function getMachineMainTypeList() {
    const url = routerHelper.getUrlWithParameters('/api/machine/machineMainType', { });
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get partner machine
 * @property {int} partnerId IsOptional - ID of the partner eg.: 1
 * @property {boolean} isSelfPropelled IsOptional - Machine is self propelled eg.: true

 * @return {array} Partner machine array
 */
async function getPartnerMachines(props) {
    const url = routerHelper.getUrlWithParameters('/api/machine/partnerMachines', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get production lines
 * @property {int} statusId IsOptional - ID of the status eg.: 1
 * 1: isActive
 * 2: inactive
 * 3: all
 * default: 1 - active
 * @return {array} Production lines array
 */
async function getProductionLines(props) {
    const url = routerHelper.getUrlWithParameters('/api/machine/productionLines', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get cultivation periods material consumtions data
 * @property {date} startDate IsOptional - Filter by startDate eg.: 1
 * @property {date} endDate IsOptional - Filter by endDate eg.: 1
 * @property {int} isShowOpen IsOptional - Show data if status open
 * @property {int} isShowClosed IsOptional - Show data if status closed
 * @property {int} isShowNeedsRepair IsOptional - Filter data by category
 * @property {int} isShowMaintenance IsOptional - Filter data by category
 * @property {int} isShowNotRequireRepair IsOptional - Filter data by category
 * @return {array} material consumption list,
 */
async function getMachineAnnouncement(props: any): Promise<any[]> {
    const url = routerHelper.getUrlWithParameters('/api/machine/machineAnnouncement', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function setAnnouncement(props) {
    const response = await sendServerRequest('POST', '/api/machine/setAnnouncement', props);

    return response;
}

async function deleteAnnouncement(ids) {
    const url = routerHelper.getUrlWithParameters('/api/machine/deleteAnnouncement', { ids });
    const response = await sendServerRequest('DELETE', url);

    return response;
}

async function updateAnnouncementStatus(props) {
    const response = await sendServerRequest('POST', '/api/machine/updateAnnouncementStatus', props);

    return response;
}

export const machineService = {
    getMachineConnectionList,
    getMachineList,
    getMachineMainTypeList,
    getPartnerMachines,
    getProductionLines,
    getMachineAnnouncement,
    setAnnouncement,
    deleteAnnouncement,
    updateAnnouncementStatus,
};
