/* eslint-disable no-restricted-imports */
/* eslint-disable no-alert */
import BaseController from '@flowsCommon/services/BaseController';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { queryBuilder } from '@flowsCommon/services/queryBuilder';
import * as apis from '@map/services/mapApis';
import { getDataForLayer } from '@map/utils/mapUtils';
import { Feature } from 'geojson';
import {
    cloneDeep,
    flattenDeep,
    isArray,
    isEmpty,
    isEqual,
    isNil,
} from 'lodash';
import { GeomSaveEntity, GeomToSvgDto, Layer, PostGeomRequestDto, ProcessGeomErrorCode } from '../mapTypes';
import { initStore, TLayerCreateStore } from './layerCreateStore';
import { LayerCreateStoreService } from './layerCreateStoreService';
import { PairableEntity } from './layerCreateTypes';
import { BuiltInLayerIds } from '../mapEnums';

export class LayerCreateController extends BaseController<TLayerCreateStore, LayerCreateStoreService> {
    uploadLayerFile = async (file: File, onFileUploadError) => {
        const formData = new FormData();
        formData.append('file', file);
        const res = await apis.processGeometryFile(formData);

        const { error, warning, featureCollection } = res.data;

        if (res.status === 413) {
            onFileUploadError(ProcessGeomErrorCode.FILE_TOO_LARGE, warning);
            return;
        }

        onFileUploadError(error, warning);

        if (!res.ok) {
            return false;
        }

        this.storeService.setUploadedFile(file.name, featureCollection);

        return true;
    }

    getPairableEntitiesForLayer = async (layer: Layer, productionYearId: number, isCustomLayer: boolean) => {
        let productionYearIdValid = productionYearId;

        if (!isCustomLayer && ![BuiltInLayerIds.CULTIVATION_PERIOD, BuiltInLayerIds.CULTIVATION_PERIOD_GROUP, BuiltInLayerIds.LPIS_FIELD].includes(layer.id)) {
            productionYearIdValid = undefined;
        }

        const entityDatas = await getDataForLayer({ layerName: layer.name, productionYearId: productionYearIdValid, showClosedPeriods: true }) ?? [];

        const query = queryBuilder()
            .with('layerId', layer.id)
            .with('productionYearId', productionYearIdValid)
            .build();

        const excludedIdsResult = await apis.getExcludedEntityIds(query);

        const geometryQuery = queryBuilder()
            .with('productionYear', productionYearIdValid)
            .with('layers', layer.id)
            .build();

        const existingLayerGeomsResponse = await apis.fetchGeometriesForLayers(geometryQuery);

        if (!existingLayerGeomsResponse.ok) {
            alert('Error while getting exsiting layer geoms');
        }

        if (!excludedIdsResult.ok) {
            alert('Error while getting pairable entities');
        }

        const existingLayerGeoms = existingLayerGeomsResponse.data;
        let existingFeatures: Feature[] = [];

        if (existingLayerGeoms.layerGeoms) {
            existingLayerGeoms.layerGeoms.forEach(layerGeom => {
                if (layerGeom?.geoms?.features) {
                    existingFeatures = layerGeom?.geoms?.features;
                    this.storeService.setExistingLayerGeoms(layerGeom?.geoms?.features);
                }
            });
        }

        const excludedIds = excludedIdsResult.data || [];
        const pairableEntities: PairableEntity[] = entityDatas.map(entityData => ({
            entityId: entityData.entityId,
            featureId: null,
            properties: entityData,
        // }));
        }))?.filter(entity => (!isEmpty(excludedIds) ? !excludedIds?.includes(entity.entityId) : true));

        const features = this.store.upload.featureCollection?.features;

        if (isCustomLayer && features) {
            const newFeatureCollection = cloneDeep(this.store.upload.featureCollection);
            const featureIds = this.store.upload.existingLayerFeatures?.map(feature => feature.id as number) || [];

            let customLayerPropertyDatas: any[] = [];

            try {
                customLayerPropertyDatas = await this.getGeomAttributeData(featureIds);
                if (customLayerPropertyDatas) {
                    this.storeService.setCustomLayerFeatureData(customLayerPropertyDatas);
                }
            } catch (error) {
                console.log(error);
            }

            if (newFeatureCollection?.features) {
                const filteredExistingFeatures = existingFeatures?.filter(existingFeature => !features.some(newFeature => isEqual(newFeature.geometry, existingFeature.geometry)));
                // newFeatureCollection.features = [...features, ...(filteredExistingFeatures as Feature[])];
                newFeatureCollection.features = [...features];

                newFeatureCollection?.features?.forEach(feature => {
                    if (feature?.id) {
                        const featureData = customLayerPropertyDatas?.find(customLayerGeom => customLayerGeom?.geomId === feature?.id);
                        // if (featureData && featureData?.geomId && feature.properties) {
                        // }
                    }
                });

                this.storeService.setFeatureCollection(newFeatureCollection);
            }
        }

        if (!isCustomLayer) {
            existingFeatures?.forEach(existingFeature => {
                const existingFeatureGeom: any = existingFeature.geometry as unknown as any[];
                const existingFeaturProperties: any = existingFeature?.properties;
                const entityId = existingFeaturProperties.entityId as number;

                const existingToEqual: any[] = flattenDeep(existingFeatureGeom?.coordinates)?.map(self => Number(self)?.toFixed(6));
                if (features) {
                    const feature = features?.find(newFeature => {
                        const newFeautreGeom: any = newFeature.geometry as unknown as any[];
                        const newFeatureToEqual = flattenDeep(newFeautreGeom?.coordinates)?.map(self => Number(self)?.toFixed(6));
                        const equal = isEqual(existingToEqual, newFeatureToEqual);
                        return equal;
                    });

                    if (feature && !isNil(feature?.id)) {
                        pairableEntities.push({
                            entityId,
                            featureId: feature.id as number,
                            properties: entityDatas?.find(entityData => (entityData?.id === entityId || entityData?.entityId === entityId)),
                        });
                    }
                }
            });
        }

        // this.storeService.setExcludedEntities(excludedIds);
        this.storeService.setPairableEntities(layer.id, pairableEntities);
    }

    getGeomAttributeData = async (featureIds: number[]): Promise<any[]> => {
        const query = queryBuilder()
            .with('geomIds', featureIds?.join(','))
            .build();

        const res = await apis.getGeomAttributeData(query);

        if (!res.ok) {
            alert('Error');
            return [];
        }

        return res.data;
    }

    resetUploadStore = () => {
        this.storeService.setUploadStore(initStore.upload);
    }

    saveGeometries = async (geomsToBeSaved: PostGeomRequestDto) => {
        const res = await apis.saveGeometries(geomsToBeSaved);
        if (!res.ok) {
            alert('Unexpected error while saving geometries');
        }
        return res.ok;
    }

    updateGeometry = async (id: number, geomsToBeSaved: GeomSaveEntity) => {
        const res = await apis.updateGeometry(id, geomsToBeSaved);
        if (!res.ok) {
            alert('Unexpected error while saving geometries');
        }
        return res.ok;
    }

    pairEntityIdToFeatureId = (entityId: number, featureId: number) => {
        this.storeService.setPairableEntityFeatureId(entityId, featureId);
    }

    pairEntityIdsToFeautreIds = (entityFeatureConnections: object) => {
        this.storeService.pairEntityIdsToFeautreIds(entityFeatureConnections);
    }

    unpairFeature = (featureId: number) => {
        this.storeService.unpairFeatureId(featureId);
    }

    setExcludedEntityIds = (entityIds: number[]) => {
        this.storeService.setExcludedEntities(entityIds);
    }

    setExcludedFeatureIds = (featureIds: number[]) => {
        this.storeService.setExcludedFeatures(featureIds);
    }

    setProductionYearId = (productionYearId: Nullable<number>) => {
        this.storeService.setProductionYearId(productionYearId);
    }

    setCustomLayerPermissionIds = (companyIds?: number[], farmIds?: number[]) => {
        this.storeService.setCustomLayerPermissionIds(companyIds, farmIds);
    }

    setCustomLayerDates = (startDate: string, endDate: string) => {
        this.storeService.setCustomLayerDates(startDate, endDate);
    }

    getFeatureSvgs = async (geomToSvgData: GeomToSvgDto[]) => {
        const res = await apis.getSvgFromGeometry(geomToSvgData);
        if (!res.ok) {
            alert('Unexpected error while getting svgs for geometires');
        }
        return res.data;
    }

    findGeometryByEntityId = async (entitiyId: number, layerId: number, prodYearId?: number) => {
        let geometryQuery;
        if (!isNil(prodYearId)) {
            geometryQuery = queryBuilder()
                .with('productionYearId', prodYearId)
                .with('layerId', layerId)
                .with('entityId', entitiyId)
                .build();
        } else {
            geometryQuery = queryBuilder()
                .with('layerId', layerId)
                .with('entityId', entitiyId)
                .build();
        }

        const res = await apis.findGeometryByEntityId(geometryQuery);

        if (res.ok) {
            return res.data;
        }

        // alert('Unexpected error while finding Geometry');
        return null;
    }

    getGeometryCompanyIds = async (entityId: number, layerId: number | string) => {
        const headers = {
            'Cache-Control': 'no-store',
            'Content-Type': 'application/json',
        } as any;
        const permissionRequest = await fetch(`/modules/terkep/control/get_companyids_by_layer_and_entity.php?layer_id=${layerId}&entity_id=${entityId}`, { headers });

        if (permissionRequest.ok) {
            try {
                const readData = await permissionRequest.text();
                const parsed = JSON.parse(readData);
                const retval: any = {};

                if (isArray(parsed?.companyIds) && !isEmpty(parsed?.companyIds)) {
                    retval.companyIds = await parsed?.companyIds.map(id => Number(id)) as number[];
                }

                if (isArray(parsed?.farmIds) && !isEmpty(parsed.farmIds)) {
                    retval.farmIds = parsed?.farmIds.map(id => Number(id)) as number[];
                }

                if (retval.companyIds || retval.farmIds) {
                    return retval;
                }

                return null;
            } catch {
                return null;
            }
        }
        return null;
    }

    setIsCustomLayerUpload = (isCustomLayerUpload: boolean) => {
        this.storeService.setIsCustomLayerUpload(isCustomLayerUpload);
    }

    setExistingLayerAttributes = (existingLayerAttributes: object) => {
        this.storeService.setExistingLayerAttributes(existingLayerAttributes);
    }

    deleteGeometry = async (geomId: number) => {
        await apis.deleteGeometry(geomId);
    }
}

export default LayerCreateController;
