export const METEOROLOGY = {
    WIND_FORCE_EXCHANGE: 3.6,
    DATA_RECORD_TYPE_ID: {
        BASE_DATA: 1,
        DETAILED_DATA: 2,
    },
    UNIT: {
        CELSIUS: '°C',
    },
    MEASURE_TYPE: {
        MANUAL: 1,
        MACHINE: 2,
    }
};

