import Button from '@baseComponents/buttons/Button';
import Radio from '@baseComponents/controls/Radio';
import { UnitInput } from '@baseComponents/inputs/inputField';
import InputLabel from '@baseComponents/inputs/inputField/InputLabel';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { ModalTemplate } from '@baseComponents/modals';
import { FormSelect } from '@baseComponents/select';
import { DoubleDatePicker } from '@customComponents/datePickers';
import { useNumberFormat } from '@common_modules';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import useTranslate from '@i18n/useTranslate';
import { ThemeType } from '@map/services/mapTypes';
import { isEmpty, isFunction, isNil, omitBy } from 'lodash';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { CROP } from 'shared/src/constants';
import { MapMode } from '@map/services/mapEnums';
import { PlanningEditModalMode } from './planningEditModalTypes';
import { PlanningEditModalSchema, planningEditModalSchema } from './PlanningModalSchema';
import { usePlanningEditModal } from './usePlanningEditModal';

type Classes = 'modalContainer' | 'modalHeader' | 'modalBody' | 'modalInputWrapper' | 'saveButton' | 'cropTypeContainer' | 'datePicker';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    modalContainer: {
        minWidth: 500,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        background: '#F0F3F4',
        borderRadius: 10,
        position: 'relative',
        height: 'auto',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: 22,
        fontWeight: 700,
        textTransform: 'uppercase',
        color: theme.color.jet,
        padding: [5, 30],
        minHeight: 60,
        borderWidth: 0,
        background: theme.color.white,
        verticalAlign: 'center',
        width: '100%',
    },
    modalBody: {
        padding: [0, 30],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
    },
    modalInputWrapper: {
        marginTop: 10,
    },
    container: {
        width: 300,
    },
    saveButton: {
        marginTop: 15,
        marginBottom: 10,
        width: '100%',
        height: 50,
    },
    cropTypeContainer: {
        width: '100%',
    },
    datePicker: {
        '& .popover': {
            zIndex: 9999999999,
        },
    },
}));

export function PlanningEditModal(props: any) {
    const classes = useStyles();

    const { onSavePlanningEditModal } = props;
    const { hideModal, entityIds, planId, productionYearId, mapStore, mapController, planningEditData } = props;

    const { mapMode } = mapStore || { mapMode: MapMode.DEFAULT };

    const planningForm = useForm<PlanningEditModalSchema>({ resolver: yupResolver(planningEditModalSchema) });

    const [planningEditMode, setPlanningEditMode] = useState<Nullable<PlanningEditModalMode>>(null);
    const [formInitialized, setFormInitialized] = useState(false);
    const selectedCulture = planningForm.watch('cultureId');

    const { plannings, planningCulture, planningCultureType, planningCultureReproductionRate, planningCultivationTechnology, savePlannings } = usePlanningEditModal({ entityIds, planId, productionYearId, cultureId: selectedCulture, mapStore, mapController, planningEditData });
    const { translater, t } = useTranslate();

    function getCultureIdByTechnologyId(technologyId: number) {
        return Number(planningCultivationTechnology.find(technology => Number(technology.id) === +technologyId)?.kultura_id);
    }

    const submitPlanningForm = async (formData: PlanningEditModalSchema) => {
        const { schemaMode, ...planningData } = omitBy(formData, isNil);

        const startDate = planningData?.startDate?.split('T')?.[0];
        const endDate = planningData?.endDate?.split('T')?.[0];

        const planningSubmitData: Partial<PlanningEditModalSchema[]> | any = plannings.map(planning => ({
            ...planning,
            ...planningData,
            entityId: planning.id,
            startDate,
            endDate,
        }));

        if (isFunction(onSavePlanningEditModal)) {
            planningSubmitData.forEach(editedPlanningData => {
                editedPlanningData.id = editedPlanningData.entityId;
                editedPlanningData.cultureName = planningCulture.find(culture => +(culture.id ?? 0) === editedPlanningData.cultureId)?.nev;
                editedPlanningData.culture = editedPlanningData?.cultureName;
                editedPlanningData.technologyName = planningCultivationTechnology.find(technology => +(technology.id ?? 0) === editedPlanningData.technologyId)?.nev;
                if (!editedPlanningData.technologyName || editedPlanningData.technologyName === '-') {
                    editedPlanningData.technologyName = '';
                }
                editedPlanningData.productionTechnologyName = editedPlanningData.technologyName;
            });

            onSavePlanningEditModal(planningSubmitData);
        } else {
            await savePlannings(planningSubmitData);
        }

        hideModal();
    };

    const cultureSelectList = useMemo(() => planningCulture.map(c => ({ key: +c.id, value: c.nev })), [planningCulture]);
    const cultureTypeSelectList = useMemo(() => planningCultureType.map(c => ({ key: +c.id, value: c.nev })), [planningCultureType]);
    const cultureReproductionScaleSelectList = useMemo(() => planningCultureReproductionRate.map(c => ({ key: +c.id, value: c.nev })), [planningCultureReproductionRate]);
    const cultureTechnologySelectList = useMemo(() => planningCultivationTechnology.map(c => ({ key: +c.id, value: c.nev })), [planningCultivationTechnology]);

    const headerNames = useMemo(() => plannings.map(p => p.name).join(', '), [plannings]);

    const { numberFormatter } = useNumberFormat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const areaFormat = useCallback(() => numberFormatter.getAreaFormats(), [numberFormatter.isInitialized]);

    useEffect(() => {
        if (formInitialized) {
            return;
        }

        if (!isEmpty(plannings)) {
            if (plannings.length === 1) {
                const [firstPlanning] = plannings;
                if (!isNil(firstPlanning.cultureId)) {
                    planningForm.setValue('cultureId', firstPlanning.cultureId);
                }
                if (!isNil(firstPlanning.cropTypeId)) {
                    planningForm.setValue('cropTypeId', firstPlanning.cropTypeId);
                }
                if (!isNil(firstPlanning?.reproductionRateId)) {
                    planningForm.setValue('reproductionRateId', firstPlanning.reproductionRateId);
                }
                if (!isNil(firstPlanning?.cultureVarietyId)) {
                    planningForm.setValue('cultureVarietyId', firstPlanning.cultureVarietyId);
                }
                if (!isNil(firstPlanning?.startDate)) {
                    planningForm.setValue('startDate', firstPlanning.startDate);
                }
                if (!isNil(firstPlanning?.endDate)) {
                    planningForm.setValue('endDate', firstPlanning.endDate);
                }
                if (!isNil(firstPlanning?.entityArea)) {
                    planningForm.setValue('entityArea', +firstPlanning.entityArea);
                }
                if (!isNil(firstPlanning?.technologyId)) {
                    planningForm.setValue('technologyId', +firstPlanning.technologyId);
                }
                if (!isNil(firstPlanning?.cropTypeId)) {
                    planningForm.setValue('cropTypeId', +firstPlanning.cropTypeId);
                }
                setPlanningEditMode(PlanningEditModalMode.SINGLE);
                planningForm.setValue('schemaMode', PlanningEditModalMode.SINGLE);
            } else {
                setPlanningEditMode(PlanningEditModalMode.MULTI);
                planningForm.setValue('schemaMode', PlanningEditModalMode.MULTI);
            }
            setFormInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plannings, formInitialized]);

    const onChangeCulture = (cultureId: any) => {
        planningForm.setValue('cultureId', +cultureId);
        const defaultTechnology = planningCultivationTechnology.find(t => t.nev === '-');
        if (!isNil(defaultTechnology)) {
            const currentTechnoloyId = planningForm.getValues('technologyId');
            if (!isNil(currentTechnoloyId)) {
                planningForm.setValue('technologyId', +defaultTechnology.id);
            }
            // eslint-disable-next-line no-undefined
            planningForm.setValue('cultureVarietyId', undefined);
        }
    };

    if (isNil(planningEditMode)) {
        return (
            <ModalTemplate hideModal={hideModal} className={classes.modalContainer}>
                <LoadSpinner />
            </ModalTemplate>
        );
    }

    return (
        <ModalTemplate hideModal={hideModal} className={classes.modalContainer}>
            <FormProvider {...planningForm}>
                <div className={classes.modalHeader}>
                    {headerNames}
                </div>

                <div className={classes.modalBody}>
                    <DoubleDatePicker
                        className={classes.datePicker}
                        required={false}
                        startDate={planningForm.watch('startDate')}
                        setStartDate={value => planningForm.setValue('startDate', value)}
                        endDate={planningForm.watch('endDate')}
                        setEndDate={value => planningForm.setValue('endDate', value)}
                    />
                    {planningEditMode === PlanningEditModalMode.SINGLE && (
                        <div className={classes.modalInputWrapper}>
                            <Controller
                                control={planningForm.control}
                                name="entityArea"
                                render={({ field }) => (
                                    <UnitInput
                                        type="number"
                                        value={field.value}
                                        onBlur={(value: number | undefined) => planningForm.setValue('entityArea', value)}
                                        isRequired
                                        isLeftAlign
                                        id="entityArea"
                                        label={translater('map.planningEditModalArea', 'Area')}
                                        isInvalid={!!planningForm.formState.errors.entityArea}
                                        errorMessage={translater('newCultivationPeriod.sownAreaRequired', 'Sown area is required')}
                                        numberFormat={areaFormat()}
                                    />
                                )}
                            />
                        </div>
                    )}
                    {planningEditMode === PlanningEditModalMode.SINGLE && mapMode !== MapMode.PLANNING_EDIT && (
                        <div className={classes.modalInputWrapper}>
                            <Controller
                                control={planningForm.control}
                                name="cropTypeId"
                                render={({ field }) => (
                                    <div className={classes.cropTypeContainer}>
                                        <InputLabel label={translater('map.layerPlanningCropTypeName', 'Crop Type')} />
                                        <Radio
                                            text={translater('map.filterCropMain', 'Main')}
                                            checked={field.value === CROP.TYPE.MAIN_CROP}
                                            group="stategrp"
                                            value={CROP.TYPE.MAIN_CROP}
                                            onChange={() => { planningForm.setValue('cropTypeId', CROP.TYPE.MAIN_CROP); }}
                                        />
                                        <Radio
                                            text={translater('map.filterCropSecondary', 'Secondary')}
                                            checked={field.value === CROP.TYPE.SECOND_CROP}
                                            group="stategrp"
                                            value={CROP.TYPE.SECOND_CROP}
                                            onChange={() => { planningForm.setValue('cropTypeId', CROP.TYPE.SECOND_CROP); }}
                                        />
                                    </div>
                                )}
                            />
                        </div>
                    )}
                    <div className={classes.modalInputWrapper}>
                        <Controller
                            control={planningForm.control}
                            name="cultureId"
                            render={({ field }) => (
                                <FormSelect
                                    label={translater('map.planningEditModalCulture', 'Culture')}
                                    dataList={cultureSelectList}
                                    selected={field.value}
                                    onChange={(val: any) => { onChangeCulture(val); }}
                                />
                            )}
                        />
                    </div>
                    <div className={classes.modalInputWrapper}>
                        <Controller
                            control={planningForm.control}
                            name="technologyId"
                            render={({ field }) => (
                                <FormSelect
                                    label={translater('map.planningEditModalTechnology', 'Technology')}
                                    dataList={cultureTechnologySelectList}
                                    selected={field.value}
                                    onChange={(val: any) => {
                                        planningForm.setValue('technologyId', +val);

                                        if (val) {
                                            planningForm.setValue('cultureId', getCultureIdByTechnologyId(+val));
                                        }
                                    }}
                                />
                            )}
                        />
                    </div>
                    {mapMode !== MapMode.PLANNING_EDIT && (
                    <div className={classes.modalInputWrapper}>
                        <Controller
                            control={planningForm.control}
                            name="cultureVarietyId"
                            render={({ field }) => (
                                <FormSelect
                                    label={translater('map.planningEditModalCultureVariant', 'Type')}
                                    dataList={cultureTypeSelectList}
                                    selected={field.value}
                                    onChange={(val: any) => { planningForm.setValue('cultureVarietyId', +val); }}
                                />
                            )}
                        />
                    </div>
                    )}
                    {planningEditMode === PlanningEditModalMode.SINGLE && mapMode !== MapMode.PLANNING_EDIT && (
                        <div className={classes.modalInputWrapper}>
                            <Controller
                                control={planningForm.control}
                                name="reproductionRateId"
                                render={({ field }) => (
                                    <FormSelect
                                        label={translater('map.planningEditModalReproduction', 'Reproduction Degree')}
                                        dataList={cultureReproductionScaleSelectList}
                                        selected={field.value}
                                        onChange={(val: any) => { planningForm.setValue('reproductionRateId', +val); }}
                                    />
                                )}
                            />
                        </div>
                    )}
                    <div className={classes.modalInputWrapper}>
                        <Button className={classes.saveButton} onClick={() => planningForm.handleSubmit(submitPlanningForm)()}>
                            {mapMode !== MapMode.PLANNING_EDIT ? t('map.planningEditModalSave', 'Save') : t('default.send', 'Send')}
                        </Button>
                    </div>
                </div>
            </FormProvider>
        </ModalTemplate>);
}
