import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useForm } from 'react-hook-form';

import useTranslate from '@i18n/useTranslate';
import Button from '@baseComponents/buttons/Button';
import { ModalTemplate, ModalLeftPanel, ModalLeftPanelBody, ModalLeftPanelFooter, ModalRightPanel, OpenModalButton } from '@baseComponents/modals';
import { useNumberFormat } from '@common_modules/numberFormat';

import renderModal from '@modals/modalNew/modalFunctions';
import PartnersModal from '@customComponents/modals/partnersModal/PartnersModal';
import ProductsModal from '@customComponents/modals/productsModal/ProductsModal';
import ReceivingWarehouseDropdown from '@customComponents/dropdowns/receivingWarehouseDropdown/ReceivingWarehouseDropdown';
import FormGroup from '@customComponents/form/FormGroup';
import CompanyDropdown from '@customComponents/dropdowns/companyDropdown/CompanyDropdown';
import FormDatePicker from '@customComponents/datePickers/formCalendars/FormDatePicker';

import { yupResolver } from '@hookform/resolvers/yup';

import useFormValidation from './useFormValidation';
import useMultiPartnerReception from './useMultiPartnerReception';

import PartnerTable from './partnerTable/PartnerTable';

const useStyles = createUseStyles((theme: any) => ({
    partnerTableWrapper: {
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: 20,
        paddingRight: 20,
        background: theme.color.lilian,
        height: '100%',
        width: '100%',
    },
    row: {
        '&:after': {
            content: '""',
            display: 'table',
            clear: 'both',
        },
        height: 80,
        minHeight: 80,
        boxShadow: theme.shadows.header,
        background: theme.color.lilian,
        borderBottom: `1px solid ${theme.color.pearl}`,
        paddingTop: 17,
        paddingBottom: 17,
        paddingLeft: 20,
        paddingRight: 20,
    },
    column: {
        float: 'left',
        width: '40%',
        padding: 10,
    },
    addButtonColumn: {
        float: 'left',
        width: '20%',
        padding: 10,
        textAlign: 'center',
    },
    addButton: {
        marginTop: 15,
    },
    footerButton: {
        minWidth: 160,
        width: 160,
        marginLeft: 20,
    },
    saveOtherFunctionButton: {
        margin: 'auto',
    },
    rightSideSumFooter: {
        width: '100%',
        height: 66,
        minHeight: 66,
        padding: 20,
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: 'center',
        background: theme.color.white,
        boxShadow: theme.shadows.footer,
        display: 'block',
    },
    partnerColumn: {
        display: 'inline-block',
        width: 230,
    },
    totalCaption: {
        display: 'block',
        fontWeight: 500,
        fontSize: 14,
        color: theme.color.black,
    },
    partnerCount: {
        fontSize: 13,
        color: theme.color.raven,
    },
    quantityColumn: {
        display: 'inline-block',
        marginLeft: 470,
        width: 230,
        fontSize: 12,
        color: theme.color.black,
        fontWeight: 500,
    },
}));

const TABLE_KEY = 'multiPartnerReceptionModalTable';

export default function MultiPartnerReceptionModal(props) {
    const { hideModal, companyRefresh } = props;
    const { t } = useTranslate();
    const classes = useStyles();

    const { numberFormatter, isInitialized } = useNumberFormat();
    const {
        validationSchema,
        defaultValues,
        warehouseTypeIds,
    } = useFormValidation();

    const [quantityFormats, setQuantityFormats] = useState();

    useEffect(() => {
        if (isInitialized && numberFormatter) {
            const quantity = numberFormatter.getProductQuantityFormats();
            setQuantityFormats(quantity);
        }
    }, [isInitialized, numberFormatter]);

    const { reset, handleSubmit, watch, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...defaultValues,
        },
        mode: 'onChange',
    });

    const {
        onSubmit,
        isDisabledSaveButton,
        updateProductUnits,
        addPartnersToTable,
        onChangeQuantity,
        deletePartnerFromTable,
        getProductQuantitySum,
    } = useMultiPartnerReception({ hideModal, reset, setValue, getValues, tableKey: TABLE_KEY, companyRefresh });

    return (
        <ModalTemplate hideModal={hideModal}>
            <ModalLeftPanel title={t('multiPartnerReceptionModal.multiPartnerReception', 'Multi partner reception')}>
                <ModalLeftPanelBody>

                    <FormGroup label={t('multiPartnerReceptionModal.company', 'Company')} required hasValue={watch('companyId')} errorMessage={errors?.companyId?.message}>
                        <CompanyDropdown
                            companyId={getValues('companyId')}
                            setCompanyId={value => {
                                setValue('companyId', value);
                                setValue('warehouseId', null);
                                setValue('productId', null);
                            }}
                        />
                    </FormGroup>

                    <FormGroup label={t('multiPartnerReceptionModal.warehouse', 'Warehouse')} required hasValue={watch('warehouseId')} errorMessage={errors?.warehouseId?.message}>
                        <ReceivingWarehouseDropdown
                            warehouseId={getValues('warehouseId')}
                            companyIds={watch('companyId')}
                            setWarehouseId={(value, name) => {
                                setValue('warehouseId', value);
                                setValue('warehouseName', name);
                            }}
                            typeIds={warehouseTypeIds}
                        />
                    </FormGroup>

                    <FormGroup label={t('multiPartnerReceptionModal.product', 'Product')} required hasValue={watch('productId')} errorMessage={errors?.productId?.message}>
                        <OpenModalButton
                            currentSelectedValue={watch('productId')}
                            isDisabled={!!watch(['companyId'])?.some(value => !value)}
                            modalComponent={ProductsModal}
                            onChange={productObj => {
                                setValue('productId', productObj.productId);
                                setValue('productName', productObj.productName);
                                setValue('productUnit', productObj?.unitShortName);
                                updateProductUnits(productObj?.unitShortName);
                            }}
                            modalComponentProps={{
                                showActiveState: false,
                                defaultActiveState: 2,
                                companyId: watch('companyId'),
                                affectedByStockRecords: 0,
                            }}
                        />
                    </FormGroup>

                    <FormGroup label={t('multiPartnerReceptionModal.receivingDate', 'Receiving date')} required hasValue={watch('date')} errorMessage={errors?.date?.message}>
                        <FormDatePicker date={getValues('date')} setDate={value => setValue('date', value)} />
                    </FormGroup>
                </ModalLeftPanelBody>
                <ModalLeftPanelFooter>
                    <Button size="large" className={classes.footerButton} onClick={handleSubmit(onSubmit)} disabled={isDisabledSaveButton}>
                        {t('default.save', 'Save')}
                    </Button>
                    <Button size="large" className={classes.footerButton} onClick={handleSubmit(formData => onSubmit(formData, true))} disabled={isDisabledSaveButton} type="secondary">
                        {t('default.saveAndNew', 'Save and new')}
                    </Button>
                </ModalLeftPanelFooter>
            </ModalLeftPanel>

            <ModalRightPanel>
                <div className={classes.row}>
                    <Button
                        iconId="icon-add"
                        iconPosition="before"
                        onClick={() => renderModal(PartnersModal, {
                            isMultiselect: true,
                            isShowSelect: false,
                            showPartnerType: false,
                            excludedIds: watch('partners')?.map(partner => partner.partnerId),
                            onChange: partners => {
                                addPartnersToTable(partners);
                            },
                        }, false)}
                    >
                        {t('default.addNewPartner', 'Add new partner')}
                    </Button>
                </div>

                <div className={classes.partnerTableWrapper}>
                    {quantityFormats
                        ? (
                            <PartnerTable
                                tableKey={TABLE_KEY}
                                partners={watch('partners')}
                                deletePartnerFromTable={deletePartnerFromTable}
                                errorMessage={errors?.partners?.message}
                                quantityFormats={quantityFormats}
                                onChangeQuantity={onChangeQuantity}
                            />
                        ) : null}
                </div>
                <div className={classes.rightSideSumFooter}>
                    <div className={classes.partnerColumn}>
                        <div className={classes.totalCaption}>
                            {t('default.total', 'Total')}:
                        </div>
                        <div className={classes.partnerCount}>
                            {`${watch('partners')?.length}`} {t('multiPartnerReceptionModal.partnerSum', 'partner')}
                        </div>
                    </div>
                    <div className={classes.quantityColumn}>
                        {watch('totalQuantity') ? `${getProductQuantitySum()} ` : 0}{watch('productUnit')}
                    </div>
                </div>
            </ModalRightPanel>
        </ModalTemplate>
    );
}
