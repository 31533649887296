import React, { useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FormProvider, useForm } from 'react-hook-form';
import useTranslate from '@i18n/useTranslate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, head } from 'lodash';
import { alertActions, basePageActions, errorActions } from '@states/actions';
import { cultivationPeriodsService, visiblefarmService } from '@services';
import renderModal from '@modals/modalNew/modalFunctions';
import ConfirmDialog from '@baseComponents/modals/ConfirmDialog';
import Button from '@baseComponents/buttons/Button';
import CloseAndOpenCultivationPeriodBody from './CloseAndOpenCultivationPeriodBody';
import useLoadCloseAndOpenCultivationPeriodValue from './useLoadCloseAndOpenCultivationPeriodDefaultValue';
import useCloseAndOpenCultivationPeriodValidation from './useCloseAndOpenCultivationPeriodValidation';

const useStyles = createUseStyles((theme: any) => ({
    closeAndOpenCultivationPeriodContainer: {
        height: 'calc(100vh - 80px)',
        width: 380,
        padding: [25, 0],
        display: 'flex',
        flexFlow: 'column',
    },
    title: {
        padding: [0, 30, 25, 30],
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 1.5,
        color: theme.color.jet,
        ...theme.overFlowText,
    },
    button: {
        marginLeft: 10,
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 25,
    },
}));

export default function CloseAndOpenCultivationPeriod(props) {
    const { hideModal, cultivationPeriods } = props;

    const { t, translater } = useTranslate();
    const formRef = useRef();
    const dispatch = useDispatch();
    const [isGroupOperation] = useState(cultivationPeriods?.length > 1);
    const [isSaveDisabled, setIsSaveDisabled] = useState(false);
    const agentCompanyId = useSelector((state: any) => state.company.company.globalData.id);
    const cultivationPeriodDefaultValue = useLoadCloseAndOpenCultivationPeriodValue({ cultivationPeriod: head(cultivationPeriods), isGroupOperation });
    const validationSchema = useCloseAndOpenCultivationPeriodValidation({ isGroupOperation });
    const methods = useForm({
        defaultValues: cultivationPeriodDefaultValue,
        resolver: yupResolver(validationSchema),
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });
    const classes = useStyles();

    async function onSave(selectedCultivationPeriods) {
        const [closeAndOpenCultivationPeriod, vfOldIds, vfNewIds] = await cultivationPeriodsService.closeAndOpenCultivationPeriod({ cultivationPeriods: selectedCultivationPeriods });

        if (!isEmpty(closeAndOpenCultivationPeriod)) {
            await setVisibleFarmPeriodData(vfNewIds, vfOldIds);
        }

        if (closeAndOpenCultivationPeriod) {
            dispatch(basePageActions.setReloadTable());
            setIsSaveDisabled(false);
            dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved')));
            hideModal();
        } else {
            setIsSaveDisabled(false);
            dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed')));
        }
    }

    async function onSubmit(cultivationPeriodData) {
        try {
            setIsSaveDisabled(true);
            const selectedCultivationPeriods = [];
            const selectedCultivationPeriodsIds = [];
            cultivationPeriods?.forEach(cultivationPeriod => {
                const selectedCultivationPeriodData = {
                    ...cultivationPeriodData,
                    id: cultivationPeriod.id,
                    name: isGroupOperation ? cultivationPeriod.name : cultivationPeriodData.name,
                    sownArea: isGroupOperation ? cultivationPeriod.sownArea : cultivationPeriodData.sownArea,
                };
                selectedCultivationPeriods.push(selectedCultivationPeriodData);
                selectedCultivationPeriodsIds.push(cultivationPeriod.id);
            });

            const checkPeriodData = await cultivationPeriodsService.checkCloseAndOpenCultivationPeriods(selectedCultivationPeriodsIds);

            if (!isEmpty(checkPeriodData)) {
                const questionText = t('closeOpenCultivationPeriod.closeOpenCultivationPeriod', 'Close/Open cultivation period');
                const descriptionQuestionText = checkPeriodData.length > 1
                    ? translater('closeOpenCultivationPeriod.questions', 'Are you sure you want to close these transfer requests?')
                    : translater('closeOpenCultivationPeriod.question', 'Are you sure you want to close this transfer request?');

                const textData: any = [];
                textData.push(descriptionQuestionText);
                if (checkPeriodData.length > 1) {
                    textData.push(checkPeriodData.join(', '));
                }

                const descriptionText = textData.map((text, index) => <p key={index}>{text}</p>);

                renderModal(ConfirmDialog, {
                    iconId: 'icon-help',
                    isAcceptCancelDialog: true,
                    isHtmlDescription: true,
                    questionText,
                    descriptionText,
                    acceptButtonText: t('default.yes', 'Yes'),
                    confirmCallback: async () => {
                        await onSave(selectedCultivationPeriods);
                    },
                }, false, () => setIsSaveDisabled(false));
            } else {
                await onSave(selectedCultivationPeriods);
            }
        } catch (error) {
            setIsSaveDisabled(false);
            dispatch(errorActions.setError(error, 'Fix or unlock cultivation period'));
        }
    }

    async function setVisibleFarmPeriodData(newPeriodIds, updatePeriodIds) {
        const newPeriodRequests = newPeriodIds.map(async period => visiblefarmService.createPeriod({
            agentId: agentCompanyId,
            av_id: period,
            periodType: 0,
        }));
        await Promise.all(newPeriodRequests);

        const updatePeriodRequests = updatePeriodIds.map(async period => visiblefarmService.updatePeriod({
            agentId: agentCompanyId,
            av_id: period,
            periodType: 0,
        }));
        await Promise.all(updatePeriodRequests);
    }

    return (
        <FormProvider {...methods}>
            <form className={classes.closeAndOpenCultivationPeriodContainer} ref={formRef}>
                <div className={classes.title}>
                    {t('closeOpenCultivationPeriod.closeOpenCultivationPeriod', 'Close/Open cultivation period')}
                </div>
                <CloseAndOpenCultivationPeriodBody
                    formRef={formRef}
                    isGroupOperation={isGroupOperation}
                />
                <div className={classes.footer}>
                    <Button
                        onClick={methods.handleSubmit(onSubmit)}
                        disabled={isSaveDisabled}
                    >
                        {t('default.save', 'Save')}
                    </Button>
                    <Button type="secondary" onClick={() => hideModal()} className={classes.button}>
                        {t('default.cancel', 'Cancel')}
                    </Button>
                </div>
            </form>
        </FormProvider>

    );
}
