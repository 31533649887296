/* eslint-disable react-hooks/exhaustive-deps */
import Radio from '@baseComponents/controls/Radio';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import { ThemeType } from '@map/services/mapTypes';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import MapFilterBase from './MapFilterBase';
import { MapFilterSchema, MAP_FILTER_STATUS, MAP_FILTER_WORK_OPERATION_STATUS } from './MapFilterSchema';

type Classes = 'container' | 'title';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    title: {
        marginBottom: 15,
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
    },
}));
export default function MapFilterWorkOperationMainGroupState(props) {
    const classes = useStyles();
    const filterContext = useFormContext<MapFilterSchema>();

    function changeTypeFilter(event: any) {
        const value = event.target.value;
        filterContext.setValue('workOperationStatus', Number(value));
    }

    const { translater, t } = useTranslate();

    const statusId = filterContext.watch('workOperationStatus') ?? 1;

    return (
        <MapFilterBase>
            <div className={classes.container}>
                <div className={classes.title}>{t('map.filterWorkOpertaionState', 'Work Operation Status')}</div>
                <form>
                    <Radio text={translater('workOperationStatus.ongoingStatus', 'In progress')} checked={statusId === MAP_FILTER_WORK_OPERATION_STATUS.IN_PROGRESS} group="stategrp" value={MAP_FILTER_WORK_OPERATION_STATUS.IN_PROGRESS} onChange={changeTypeFilter} />
                    <Radio text={translater('workOperationStatus.finishedStatus', 'FINISHED')} checked={statusId === MAP_FILTER_WORK_OPERATION_STATUS.FINISHED} group="stategrp" value={MAP_FILTER_WORK_OPERATION_STATUS.FINISHED} onChange={changeTypeFilter} />
                    <Radio text={translater('map.filterWorkOpertaionStateInBoth', 'Both')} checked={statusId === MAP_FILTER_WORK_OPERATION_STATUS.IN_PROGRESS_AND_DONE} group="stategrp" value={MAP_FILTER_WORK_OPERATION_STATUS.IN_PROGRESS_AND_DONE} onChange={changeTypeFilter} />
                </form>
            </div>
        </MapFilterBase>
    );
}
