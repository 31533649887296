import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isEmpty } from 'lodash';
import { errorActions } from '@states/actions';
import { partnerService } from '@services';
import { PARTNER } from 'shared/src/constants';

export default function usePartnersModalLoadData(props) {
    const { defaultPartnerType, excludedIds } = props || {};
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const [tableData, setTableData] = useState();
    const [partnerTypeId, setPartnerTypeId] = useState(defaultPartnerType || PARTNER.TYPES.PARTNER_AND_COMPANY);
    const companyIds = useSelector((state: any) => state.company.activeCompanyIds);

    //bizonylat id alapján betöltés
    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const partners = await partnerService.getPartnersAndCompanies({
                    companyIds,
                    typeId: partnerTypeId,
                    showSettlement: true,
                });

                if (!isCancelled) {
                    const filteredPartners = !isEmpty(excludedIds) ? partners?.filter(partner => !excludedIds.includes(partner.id)) : partners;
                    setTableData(filteredPartners);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (!isEmpty(companyIds) && partnerTypeId && location && dispatch) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [companyIds, location, partnerTypeId, excludedIds, dispatch]);

    return {
        tableData,
        partnerTypeId,
        changePartnerType: setPartnerTypeId,
    };
}
