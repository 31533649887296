import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const supportService = {
    getSupportsByYear,
};

async function getSupportsByYear(year) {
    const url = routerHelper.getUrlWithParameters('/api/supports', { year });
    const response = await sendServerRequest('GET', url);
    return response;
}
