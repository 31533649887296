import {Nullable} from '@flowsCommon/services/baseTypes';
import {useSlovakReportContext} from '@map/services/slovakianReports/slovakReportContext';
import {SlovakLayer} from '@map/services/slovakianReports/slovakReportTypes';
import {slovakExternalLayers} from '@map/services/slovakianReports/slovakReportUtils';
import {useState} from 'react';
import {SK_LAYERS_ARRAY} from '../slovakReportsLayers';

const slovakLayerDataList = SK_LAYERS_ARRAY.map((skLayer) => ({
  key: skLayer.id,
  value: skLayer.name,
  isChecked: false,
}));

type Props = {
  map: Nullable<google.maps.Map>;
};

export default function useSlovakLayersData(props: Props) {
  const {map} = props;
  const [store, controller] = useSlovakReportContext();
  const [dataList, setDataList] = useState<number[]>([]);

  function onLayersChange(layerIds: number[]) {
    if (map) {
      map.overlayMapTypes.clear();
    }

    const updatedList = [...dataList];
    layerIds.forEach((layerId) => {
      const index = updatedList.indexOf(layerId);
      if (index !== -1) {
        updatedList.splice(index, 1);
      } else {
        updatedList.push(layerId);
      }
    });

    setDataList(updatedList);
    controller.setActiveLayers(updatedList);
  }

  return {
    dataList,
    onLayersChange,
  };
}
