import Button from '@baseComponents/buttons/Button';
import { SimpleSelect } from '@baseComponents/select';
import PeriodYearDropdown from '@customComponents/dropdowns/periodYearDropdown/PeriodYearDropdown';
import Svg from '@baseComponents/Svg';
import useTranslate from '@i18n/useTranslate';
import { useLayerCreateContext } from '@map/services/layerCreate/layerCreateContext';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerIds, BuiltInLayerTypes } from '@map/services/mapEnums';
import { Layer, ThemeType, TranslateType } from '@map/services/mapTypes';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { getLayerCaptionString } from '@map/utils/mapCaptionUtils';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { FormRangeCalendar } from '@customComponents/datePickers';
import { dateTimeUtils } from 'shared/src/modules';
import { OpenModalButton } from '@baseComponents/modals';
import FarmModal from '@customComponents/modals/farmModal/FarmModal';
import PartnersModal from '@customComponents/modals/partnersModal/PartnersModal';
import { isArray, isEmpty, isNil, isNull } from 'lodash';
import { getCustomLayerAttributes } from '@map/services/mapApis';
import { useDispatch, useSelector } from 'react-redux';
import FormGroup from '@customComponents/form/FormGroup';
import { companyActions } from '@states/actions';
import { SETTINGS } from 'shared/src/constants';
import { getIsCustomLayer } from '@map/utils/mapUtils';
import { LayerModalBottomBar, LayerModalContainer, LayerModalContent, LayerModalHeader } from './LayerModal.styles';
import { LayerUploadSteps } from './modalSteps.enum';
import { ModalStepProps } from './types';
import LayerSelectorModal from '../LayerSelectorModal/LayerSelectorModal';

type Classes = 'container' | 'gridContainer' | 'tableRow' | 'tableCell' | 'withLeftBorderRadius' | 'withRightBorderRadius'
    | 'buttonRowContainer' | 'gridTitle' | 'doneIcon' | 'errorIcon' | 'fileName' | 'warningText' | 'browseText' | 'tableRowPart'
    | 'tableCellWrapper' | 'fileNameRow' | 'formGroup';

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    container: {
        minHeight: 350,
        width: '100%',
    },
    gridContainer: {
        display: 'flex',
        tableLayout: 'fixed',
        flexWrap: 'wrap',
        width: '100%',
    },
    tableRow: {
        display: 'flex',
        width: '100%',
        backgroundColor: props => (props?.isError ? 'rgba(255, 0, 85, 0.1)' : 'transparent'),
        border: props => (props?.isError ? '1px solid rgba(255, 0, 85, 0.2)' : 'none'),
    },
    tableCell: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        justifyContent: 'flex-start',
        '& > *': {
            width: '100%',
        },
    },
    withLeftBorderRadius: {
        borderRadius: '4px 0 0 4px',
    },
    withRightBorderRadius: {
        borderRadius: '0 4px 4px 0',
    },
    buttonRowContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    gridTitle: {
        color: theme.color.stone,
        fontWeight: 500,
        fontSize: 12,
        marginBottom: 5,
    },
    doneIcon: {
        fill: props => (props?.isInactive ? theme.color.gray : theme.color.main),
    },
    errorIcon: {
        fill: theme.color.destructive,
    },
    fileName: {
        fontWeight: 500,
        fontSize: 16,
        flexGrow: 1,
        marginLeft: 5,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '20ch',
    },
    warningText: {
        fontSize: 14,
        fontWeight: 400,
        color: theme.color.destructive,
    },
    browseText: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.color.main,
        textDecorationLine: 'underline',
    },
    tableRowPart: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        width: '50%',
    },
    tableCellWrapper: {
        display: 'flex',
        flexFlow: 'column',
        padding: [10, 8],
        minHeight: 90,
    },
    fileNameRow: {
        display: 'flex',
    },
    formGroup: {
        display: 'flex',
        flexFlow: 'column',
        height: '100%',
        margin: 0,
    },
    marginRight: {
        marginRight: 16,
    },
}));

type FileProps = {
    isError: boolean,
    name: string,
    layerId: number,
    setLayerId: (layerId: number) => void,
    setSelectedProductionYearId: (productionYearId: number) => void;
    isProductionYearDisabled: boolean;
    selectedProductionYear?: number,
    isCustomLayer: boolean;
    startDate?: string;
    endDate?: string;
    setStartDate: (startDate: string) => void,
    setEndDate: (startDate: string) => void,
    farmIds?: number[];
    companyIds?: number[];
    setFarmIds: (farmIds?: number[]) => void,
    setCompanyIds: (companyIds?: number[]) => void,
    setExistingLayerAttributes: (layerAttributes: object) => void,
}

type TProps = {
    file: FileProps,
    layerSelectDataList: { layerName: string, parentName: string, id: number, isCustomLayer: boolean }[]
    usingFarm: boolean;
}

type StyleProps = {
    isError?: boolean,
    isInactive?: boolean,
}

function FileRow(props: TProps) {
    const { file, layerSelectDataList, usingFarm } = props;
    const { t, translater }: TranslateType = useTranslate();

    const { isCustomLayer, setCompanyIds, setFarmIds, layerId, setStartDate, setEndDate, setExistingLayerAttributes } = file;

    const classes = useStyles({ isError: file.isError, isInactive: false });

    const [layerName, setLayerName] = useState<string | undefined | null>();
    const [farmNames, setFarmNames] = useState<string | undefined | null>();
    const [companyNames, setCompanyNames] = useState<string | undefined | null>();

    const activeCompanyList = useSelector((state: any) => state.company.activeCompanyList);
    const activeFarmList = useSelector((state: any) => state.dataLists.farms);

    useEffect(() => {
        if (!isCustomLayer) {
            file.setCompanyIds();
            file.setFarmIds();
            setLayerName(layerSelectDataList?.find(layerData => layerData.id === file.layerId)?.layerName);
            setFarmNames(null);
            setCompanyNames(null);
        }
    }, [file, isCustomLayer]);

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const customLayerAttributes = await getCustomLayerAttributes(layerId);
                const customLayerAttributesData = customLayerAttributes.data;
                if (!isCancelled) {
                    const { companyIds, farmId, existingLayerAttributes, startDate, endDate } = customLayerAttributesData;

                    if (!isNull(existingLayerAttributes)) {
                        setCompanyIds(companyIds);
                        setFarmIds([farmId as number]);

                        if (!isEmpty(companyIds)) {
                            const fetchedCompanyNames = activeCompanyList?.filter(companyData => companyIds?.includes(companyData?.id))?.map(companyData => companyData?.name)?.join(',');
                            setCompanyNames(fetchedCompanyNames);
                        }

                        if (!isNil(farmId)) {
                            const fetchedFarmNames = activeFarmList?.find(farmData => farmData?.id === farmId)?.name;
                            setFarmNames(fetchedFarmNames);
                        }

                        if (!isNil(startDate) && !isNil(endDate) && !isNil(existingLayerAttributes)) {
                            setStartDate(startDate);
                            setEndDate(endDate);
                            setExistingLayerAttributes(existingLayerAttributes);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
        if (layerId && isCustomLayer) {
            loadData();
        }
        return () => {
            isCancelled = true;
        };
    }, [setStartDate, setEndDate, setCompanyIds, setFarmIds, setExistingLayerAttributes, layerId, isCustomLayer, activeCompanyList, activeFarmList]);

    return (
        <div className={classes.tableRow}>

            <div className={classes.tableRowPart}>

                <div className={classes.tableCellWrapper}>
                    <div className={classes.tableCell}>
                        <FormGroup className={classes.formGroup} label={t('map.fileName', 'Filename')}>
                            <div className={classes.fileNameRow}>
                                <div className={clsx(classes.withLeftBorderRadius)}>
                                    {file.isError && <Svg iconId="icon-flag_warn" height={24} width={24} style={classes.errorIcon} />}
                                    {!file.isError && <Svg iconId="icon-done_circle" height={24} width={24} style={classes.doneIcon} />}
                                </div>
                                <div className={clsx(classes.fileName)}>
                                    {file.name}
                                    {file.isError && (
                                        <div>
                                            <span className={classes.warningText}>{t('map.missingDBFFile', 'Missing .dbf file!')}</span>
                                            <span className={classes.browseText}>{t('map.browseText', 'Browse')}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </FormGroup>
                    </div>
                </div>

                <div className={classes.tableCellWrapper}>
                    <div className={classes.tableCell}>
                        <FormGroup
                            className={classes.formGroup}
                            label={isCustomLayer ? t('map.validity', 'Validity') : t('map.productionYear', 'Production Year')}
                            required={!file.isProductionYearDisabled || isCustomLayer}
                            hasValue={isCustomLayer ? (file.startDate && file.endDate) : file.selectedProductionYear}
                        >
                            {!isCustomLayer ? <PeriodYearDropdown
                                setPeriodYearId={file.setSelectedProductionYearId}
                                disabled={file.isProductionYearDisabled}
                                defaultPeriodYearId={file.selectedProductionYear}
                            /> : <FormRangeCalendar
                                startDate={file.startDate}
                                endDate={file.endDate}
                                setStartDate={file.setStartDate}
                                setEndDate={file.setEndDate}
                            />}
                        </FormGroup>
                    </div>
                </div>

                {!usingFarm ? null : <div className={classes.tableCellWrapper}>
                    <div className={classes.tableCell}>
                        <FormGroup className={classes.formGroup} label={t('map.farm', 'Farm')}>
                            <OpenModalButton
                                isDisabled={!isCustomLayer}
                                hasDeleteButton
                                currentSelectedValue={file.farmIds}
                                modalComponentProps={{
                                    isMultiselect: false,
                                    ignoreDefaultSelection: true,
                                }}
                                modalComponent={FarmModal}
                                id={file.farmIds}
                                name={farmNames}
                                onChange={farms => {
                                    const selectedFarms = isArray(farms) ? farms : [farms];
                                    const farmIds = selectedFarms?.map(farm => farm?.id);

                                    if (!isEmpty(farmIds)) {
                                        const farmNameLabel = selectedFarms?.map(farm => farm?.farmName)?.join(',');
                                        setFarmNames(farmNameLabel);
                                        file.setFarmIds(farmIds);
                                    }
                                }}
                                onDelete={() => {
                                    file.setFarmIds([]);
                                    setFarmNames(null);
                                }}
                            />
                        </FormGroup>
                    </div>
                </div>
                }
            </div>

            <div className={classes.tableRowPart}>
                <div className={classes.tableCellWrapper}>
                    <div className={classes.tableCell}>
                        <FormGroup className={classes.formGroup} label={t('map.layerType', 'Layer Type')} required hasValue={file.layerId > 0}>
                            <OpenModalButton
                                text={layerName}
                                currentSelectedValue={file.layerId}
                                hasDeleteButton
                                modalComponentProps={{
                                    isMultiselect: false,
                                    tableData: layerSelectDataList,
                                }}
                                modalComponent={LayerSelectorModal}
                                id={file.layerId}
                                name={layerName}
                                onChange={layer => {
                                    const layerNameTemp = layer?.layerName;
                                    setLayerName(layerNameTemp);
                                    file.setLayerId(layer?.id);
                                }}
                                onDelete={() => {
                                    file.setLayerId(-1);
                                    setLayerName(null);
                                }}
                            />
                        </FormGroup>
                    </div>
                </div>

                <div className={classes.tableCellWrapper}>
                    <div className={classes.tableCell}>
                        <FormGroup className={classes.formGroup} label={t('map.layerLpisFieldCompanyName', 'Company')} required={isCustomLayer} hasValue={!isEmpty(file.companyIds)}>
                            <OpenModalButton
                                isDisabled={!isCustomLayer}
                                hasDeleteButton
                                modalComponentProps={{
                                    isMultiselect: true,
                                    ignoreDefaultSelection: true,
                                    hasDeleteButton: true,
                                    defaultPartnerType: 2,
                                }}
                                modalComponent={PartnersModal}
                                id={file.companyIds}
                                name={companyNames}
                                onChange={companies => {
                                    const selectedCompanies = isArray(companies) ? companies : [companies];
                                    const companyIds = selectedCompanies?.map(company => company?.id);

                                    if (!isEmpty(companyIds)) {
                                        const companyNameLabel = selectedCompanies?.map(company => company?.name)?.join(',');
                                        setCompanyNames(companyNameLabel);
                                        file.setCompanyIds(companyIds);
                                    }
                                }}
                                onDelete={() => {
                                    file.setCompanyIds([]);
                                    setCompanyNames(null);
                                }}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function LayerUploadModalFiles({ setStep, close }: ModalStepProps) {
    const { t, translater }: TranslateType = useTranslate();
    const [store, controller] = useLayerCreateContext();
    const [mapStore, mapController] = useMapContext();
    const classes = useStyles();

    const dispatch = useDispatch();
    const [usingFarm] = useState(dispatch(companyActions.getSetting(SETTINGS.USING_FARM)));

    const [selectedProductionYearId, setSelectedProductionYearId] = useState<Nullable<number>>();

    const cultivationPeriodLayer = mapController.getLayerByName(BuiltInLayerTypes.CULTIVATION_PERIOD);

    const [selectedLayerId, setSelectedLayerId] = useState(store.upload.selectedLayerId ?? cultivationPeriodLayer?.id ?? -1);

    const [startDate, setStartDate] = useState<string | undefined>(dateTimeUtils.getDefaultDateNow());
    const [endDate, setEndDate] = useState<string | undefined>(dateTimeUtils.getDefaultDateNow());

    const [farmIds, setFarmIds] = useState<number[] | undefined>();
    const [companyIds, setCompanyIds] = useState<number[] | undefined>();

    const [existingLayerAttributes, setExistingLayerAttributes] = useState<object | undefined>({});

    const isCustomLayer = useMemo(() => mapController.getLayerIsCustomLayer(selectedLayerId), [mapController, selectedLayerId]);
    const [isGSAA] = useState(dispatch(companyActions.getSetting(SETTINGS.GSAA_IS_ENABLED)));

    const isNextButtonDisabled = useMemo(() => {
        if (!isCustomLayer) {
            return false;
        }

        return (isNil(startDate) || isNil(endDate) || isEmpty(companyIds));
    }, [isCustomLayer, startDate, endDate, companyIds]);

    const isProductionYearDisabled = useMemo(() => {
        const layer = mapController.getLayerById(selectedLayerId);
        if (!isNil(layer)) {
            return ![
                BuiltInLayerTypes.CULTIVATION_PERIOD,
                BuiltInLayerTypes.LPIS_FIELD,
                BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP,
            ].includes(layer.name as BuiltInLayerTypes);
        }
        return true;
    }, [selectedLayerId, mapController]);

    function getLayerProps(layer: Layer) {
        return {
            layerName: getLayerCaptionString(translater, layer),
            id: layer.id,
            type: layer.type,
            isCustomLayer: getIsCustomLayer(layer),
            parentName: mapStore.layering.layers?.find(parentLayer => parentLayer.id === layer.parentId)?.name || '',
        };
    }

    const excludedLayerNames = ['machine', 'irrigation', 'meteorology', 'receivedScoutings', 'sentTasks'];
    const selectableLayers = useMemo(() => mapStore.layering.layers
        ?.filter(layer => layer?.type === 'FEATURE' && !excludedLayerNames.includes(layer.name))
        ?.map(layer => getLayerProps(layer))
        ?.filter(layer => layer.layerName !== 'Unnamed layer')
        ?.filter(layer => layer.id !== BuiltInLayerIds.LPIS_FIELD || !isGSAA),
    [mapStore.layering.layers, translater, isGSAA]);

    /*
    const columnDefinitions = [
        { id: 1, title: '', size: 30 },
        { id: 2, title: t('map.fileName', 'Filename'), size: 250 },
        { id: 3, title: t('map.dataType', 'Datatype'), size: 250 },
        { id: 4, title: t('map.productionYear', 'Production Year'), size: 250 },
        // { id: 4, title: 'Regisztrálás dátuma', size: 140 },
        // { id: 5, title: '', size: 30 },
    ];*/

    const proceedToNextStep = async () => {
        controller.setIsCustomLayerUpload(isCustomLayer);
        const selectedLayer = mapController.getLayerById(selectedLayerId);

        if (isNil(selectedLayer)) {
            return;
        }

        await controller.getPairableEntitiesForLayer(selectedLayer, selectedProductionYearId || mapStore.filters.productionYearId, isCustomLayer); // mapStore.filters.productionYearId

        if (isCustomLayer && startDate && endDate && existingLayerAttributes) {
            controller.setCustomLayerPermissionIds(companyIds, farmIds);
            controller.setCustomLayerDates(startDate, endDate);
            controller.setExistingLayerAttributes(existingLayerAttributes);
        }

        controller.setProductionYearId(selectedProductionYearId || null);
        setStep(LayerUploadSteps.PAIR);
    };

    const onBackToBrowse = () => {
        setStep(LayerUploadSteps.BROWSE);
        controller.resetUploadStore();
    };

    return (
        <LayerModalContainer width={600}>
            <LayerModalHeader
                title={translater<string>('map.uploadLayerText', 'Upload layer')}
                close={close}
            />
            <LayerModalContent>
                <div className={classes.container}>
                    <div className={classes.gridContainer}>
                        {/* {columnDefinitions.map(col => (
                            <div
                                key={col.id}
                                className={clsx(classes.gridTitle, classes.tableCell)}
                                style={{ width: col.size }}
                            >
                                {col.title}
                            </div>))} */}
                        {store.upload.fileName && selectableLayers && <FileRow
                            usingFarm={usingFarm}
                            file={{
                                isCustomLayer,
                                isError: false,
                                name: store.upload.fileName,
                                layerId: selectedLayerId,
                                setLayerId: setSelectedLayerId,
                                setSelectedProductionYearId,
                                isProductionYearDisabled,
                                selectedProductionYear: mapStore.filters.productionYearId,
                                startDate,
                                endDate,
                                setStartDate,
                                setEndDate,
                                setFarmIds,
                                setCompanyIds,
                                farmIds,
                                companyIds,
                                setExistingLayerAttributes,
                            }}
                            layerSelectDataList={selectableLayers}
                        />}
                    </div>
                </div>
            </LayerModalContent>
            <LayerModalBottomBar justifyContent="center">
                <Button
                    title={t('map.backText', 'Back')}
                    type="secondary"
                    iconId="icon-arrow_back"
                    iconPosition="before"
                    className={classes.marginRight}
                    onClick={() => onBackToBrowse()}
                >
                    {t('default.back', 'Back')}
                </Button>
                <Button
                    disabled={isNextButtonDisabled}
                    title={t('map.continueToAttach', 'Continue to attach')}
                    iconId="icon-arrow_forward"
                    iconPosition="after"
                    onClick={() => proceedToNextStep()}
                >
                    {isCustomLayer ? t('map.nextText', 'Back') : t('map.poligonPairing', 'Poligon pairing')}
                </Button>
            </LayerModalBottomBar>
        </LayerModalContainer>
    );
}
