/* eslint-disable react/button-has-type */
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { languageUtils } from 'shared/src/modules/languageUtils';
import Svg from '@baseComponents/Svg';
import CostumReactTooltip from '@baseComponents/tooltips/CostumReactTooltip';

const useStyles = createUseStyles((theme: any) => ({
    button: {
        display: 'inline-block',
        verticalAlign: 'top',
        cursor: (props: any) => (props.disabled ? 'default' : 'pointer'),
        width: (props: any) => props.fill && '100%',
        borderRadius: 4,
        height: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 36;
                case 'large':
                    return 46;
                default:
                    return 40;
            }
        },
        fill: (props: any) => {
            switch (props.type) {
                case 'secondary':
                    return theme.color.jet;
                case 'destructive':
                    return theme.color.destructive;
                default:
                    return theme.color.white;
            }
        },
        boxShadow: (props: any) => {
            switch (props.type) {
                case 'secondary':
                    return theme.shadows.secondary.normal;
                case 'destructive':
                    return theme.shadows.destructive.normal;
                default:
                    return theme.shadows.primary.main;
            }
        },
        border: (props: any) => ((props.type === 'secondary' || props.type === 'destructive') ? 'solid 1px' : 'solid 2px'),
        borderColor: (props: any) => {
            switch (props.type) {
                case 'secondary':
                    return theme.color.gray;
                case 'destructive':
                    return theme.color.destructive;
                default:
                    return 'rgba(255, 255, 255, 0.3)';
            }
        },
        backgroundColor: (props: any) => ((props.type === 'secondary' || props.type === 'destructive') ? theme.color.white : theme.color.main),
        color: (props: any) => {
            switch (props.type) {
                case 'secondary':
                    return theme.color.jet;
                case 'destructive':
                    return theme.color.destructive;
                default:
                    return theme.color.white;
            }
        },
        '&:hover': {
            background: (props: any) => {
                switch (props.type) {
                    case 'secondary':
                        return theme.color.buttonHover.secondary;
                    case 'destructive':
                        return theme.color.buttonHover.destructive;
                    default:
                        return theme.color.buttonHover.default;
                }
            },
            border: (props: any) => ((props.type === 'secondary' || props.type === 'destructive') ? 'solid 1px' : 'solid 2px'),
            borderColor: (props: any) => {
                switch (props.type) {
                    case 'secondary':
                        return theme.color.gray;
                    case 'destructive':
                        return theme.color.destructive;
                    default:
                        return 'rgba(255, 255, 255, 0.15)';
                }
            },
            boxShadow: (props: any) => {
                switch (props.type) {
                    case 'secondary':
                        return theme.shadows.secondary.hover;
                    case 'destructive':
                        return theme.shadows.destructive.hover;
                    default:
                        return theme.shadows.primary.hover;
                }
            },
        },
        outline: 'none',
    },
    buttonDisabled: {
        opacity: 0.5,
        borderColor: `${theme.color.gray} !important`,
        pointerEvents: (props: any) => {
            if (props.disabledPointerEvents) {
                return props.disabledPointerEvents;
            }
        },
    },
    content: {
        height: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 20;
                case 'large':
                    return 26;
                default:
                    return 20;
            }
        },
        padding: (props: any) => {
            switch (props.size) {
                case 'small':
                    return '0px 13px';
                case 'large':
                    return '0px 23px';
                default:
                    return '0px 18px';
            }
        },
        lineHeight: 'normal',
        whiteSpace: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 'nowrap';
                case 'large':
                    return '';
                default:
                    return '';
            }
        },
    },
    title: {
        display: 'inline-block',
        textTransform: 'uppercase',
        fontSize: (props: any) => (props.size === 'large' ? 14 : 13),
        lineHeight: (props: any) => (props.size === 'large' ? 1.86 : 1.54),
        fontWeight: (props: any) => ((props.type === 'secondary' || props.type === 'destructive') ? 500 : 'bold'),
        ...theme.disableSelectionColor,
    },
    after: {
        verticalAlign: 'middle',
        width: (props: any) => (props.size === 'large' ? 26 : 20),
        height: (props: any) => (props.size === 'large' ? 26 : 20),
        marginLeft: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 7;
                case 'large':
                    return 18;
                default:
                    return 12;
            }
        },
        fill: (props: any) => {
            switch (props.type) {
                case 'secondary':
                    return theme.color.jet;
                case 'destructive':
                    return theme.color.destructive;
                default:
                    return theme.color.white;
            }
        },
    },
    before: {
        verticalAlign: 'middle',
        width: (props: any) => (props.size === 'large' ? 26 : 20),
        height: (props: any) => (props.size === 'large' ? 26 : 20),
        marginRight: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 7;
                case 'large':
                    return 18;
                default:
                    return 12;
            }
        },
        fill: (props: any) => {
            switch (props.type) {
                case 'secondary':
                    return theme.color.jet;
                case 'destructive':
                    return theme.color.destructive;
                default:
                    return theme.color.white;
            }
        },
    },
    icon: {
        verticalAlign: 'middle',
        width: (props: any) => (props.size === 'large' ? 26 : 20),
        height: (props: any) => (props.size === 'large' ? 26 : 20),
        fill: (props: any) => {
            if (props.iconColor) {
                return props.iconColor;
            }
            switch (props.type) {
                case 'secondary':
                    return theme.color.jet;
                case 'destructive':
                    return theme.color.destructive;
                default:
                    return theme.color.white;
            }
        },
    },
}));

export default function Button(props) {
    const {
        type, title, size, iconId, iconPosition, iconColor, onClick, submit, children, ignoreOtherClick,
        className, fill, disabled, disabledPointerEvents = 'none', disabledOnClick = undefined, tooltipDataEvent, tooltipDataFor,
        reference,
    } = props;
    const label = children;
    const { t } = useTranslation();
    const buttonType = submit ? 'submit' : 'button';
    const classes = useStyles({ disabled, type, size, iconId, iconColor, fill, disabledPointerEvents, onClick } as any);

    function onClickButton(event) {
        if (ignoreOtherClick) {
            event.stopPropagation();
        }

        if (disabled && disabledOnClick) {
            disabledOnClick();
        } else if (!disabled && onClick) {
            onClick(event);
        }
    }

    return (
        <>
            <button
                type={buttonType}
                title={languageUtils.getTitle(t, title)}
                data-tip={languageUtils.getTitle(t, title)}
                data-for={tooltipDataFor || ''}
                data-event={tooltipDataEvent || ''}
                className={clsx(classes.button, className, disabled && classes.buttonDisabled)}
                onClick={onClickButton}
                ref={reference}
            >
                <div className={clsx(classes.content, 'content')}>
                    {iconId && iconPosition === 'before' && <Svg iconId={iconId} style={classes.before} />}
                    {label && <div className={clsx(classes.title, 'title')}>{label}</div>}
                    {!iconPosition && iconId && <Svg iconId={iconId} style={classes.icon} />}
                    {iconId && iconPosition === 'after' && <Svg iconId={iconId} style={classes.after} />}
                </div>
            </button>
            {!tooltipDataFor && (<CostumReactTooltip />)}
        </>
    );
}
