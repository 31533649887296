import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    windowContainer: {
        display: (props: any) => (props.expanded ? 'flex' : 'none'),
        position: 'absolute',
        top: 0,
        width: 'auto',
        height: '100%',
        maxWidth: '65%',
        overflow: 'auto',
        left: 380,
        zIndex: theme.zIndex.modalWindow,
        backgroundColor: theme.color.white,
        borderLeft: '2px solid',
        borderColor: theme.color.pearl,
        borderRadius: '0 10px 10px 0',
        ...theme.verticalScrollbar,
    },
}));

export default function ModalWindow(props) {
    const { windowContent } = props;
    const classes = useStyles({ expanded: windowContent } as any);

    return (
        windowContent && (
            <div className={classes.windowContainer}>
                <windowContent.component {...windowContent.props} />
            </div>
        )
    );
}
