import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

import Label from '@baseComponents/formParts/Label';
import ErrorMessage from '@baseComponents/formParts/ErrorMessage';

const useStyles = createUseStyles((theme: any) => ({
    formGroupWrapper: {
        display: 'block',
        position: 'relative',
        width: '100%',
        marginBottom: 20,
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        // color: theme.color.destructive,
    },
    errorMessageAbsolute: {
        position: 'absolute',
        backgroundColor: theme.color.white,
        width: '100%',
        padding: 8,
        zIndex: theme.zIndex.popover,
        borderRadius: 4,
        transform: 'translateY(15%)',
        boxShadow: theme.shadows.dropdown,
        '&:before': {
            display: 'block',
            position: 'absolute',
            content: '""',
            width: 0,
            height: 0,
            borderLeft: '20px solid transparent',
            borderRight: '20px solid transparent',
            borderBottom: '20px solid white',
            top: 0,
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    },
    error: {
        borderColor: (props: any) => props.invalid && !props.hasValue && [theme.color.destructive, '!important'],
        '& span': {
            color: (props: any) => props.invalid && !props.hasValue && theme.color.destructive,
        },
    },
}));

function FormGroup(props) {
    const { children, label, required, className } = props;
    const { hasValue, errorMessage, isErrorMessageAbsolute } = props; //validation
    const classes = useStyles({ invalid: !isEmpty(errorMessage), hasValue });

    return (
        <div className={clsx(classes.formGroupWrapper, className)}>
            {label && <Label label={label} required={required} isValue={hasValue} />}
            {children}
            <ErrorMessage invalid={!isEmpty(errorMessage) && !hasValue} errorMessage={errorMessage} className={clsx(classes.errorMessage, isErrorMessageAbsolute && classes.errorMessageAbsolute)} iconId={isErrorMessageAbsolute && 'icon-flag_warn'} />
        </div>
    );
}

export default React.memo(FormGroup);
