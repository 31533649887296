import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    popoverArrow: {
        minWidth: 100,
        left: '-4px !important',
        '&.popover .arrow': {
            position: 'absolute',
            display: 'block',
            width: '1rem',
            height: '0.5rem',
            margin: '0 0.3rem',
        },
        '&.popover .arrow::before, .popover .arrow::after': {
            position: 'absolute',
            display: 'block',
            content: '""',
            borderColor: 'transparent',
            borderStyle: 'solid',
        },
        '&.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"]': {
            marginTop: '0.5rem',
        },
        '&.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow': {
            top: 'calc(-0.5rem - 12px)',
        },
        '&.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before': {
            top: 0,
            borderWidth: '0 0.5rem 0.5rem 0.5rem',
            borderBottomColor: 'rgba(0, 0, 0, 0.25)',
        },
        '&.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after': {
            top: 1,
            borderWidth: '0 0.5rem 0.5rem 0.5rem',
            borderBottomColor: theme.color.white,
        },
        '&.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before': {
            position: 'absolute',
            top: 0,
            left: '50%',
            display: 'block',
            width: '1rem',
            content: '""',
            borderBottom: '1px solid #f7f7f7',
        },
    },
    searchOption: {
        height: theme.subMenu.buttonHeight,
        width: '100%',
        border: 'none',
        backgroundColor: theme.color.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 12,
        cursor: 'pointer',
        ...theme.firstLetterUppercase,
    },
    selectedSearchOption: {
        color: theme.color.main,
        background: theme.color.opacity.main,
        outline: 'none',
        ...theme.overFlowText,
        ...theme.disableSelectionColor,
    },
    arrowIcon: {
        height: 25,
        width: 25,
    },
    arrowButton: {
        width: 16,
        height: 'auto',
        cursor: 'pointer',
        padding: 0,
        border: 'none',
        background: 'transparent',
        lineHeight: 1,
        ...theme.disableOutline,
    },
}));

export default function OptionalSearchPopover(props) {
    const { search, searchId, searchIds, setSearchId } = props;
    const [isOpenOptions, setIsOpenOptions] = useState(false);
    const classes = useStyles();

    function getSelectedSearchOptionClass(id) {
        return searchId === id && classes.selectedSearchOption;
    }

    function onToggle() {
        setIsOpenOptions(!isOpenOptions);
    }

    function onClickOption(optionKey) {
        search(optionKey);
        setSearchId(optionKey);
        setIsOpenOptions(false);
    }

    const menuPopover = (
        <Popover className={classes.popoverArrow}>
            <Popover.Content>
                {searchIds.map(searchOption => (
                    <div className={clsx(classes.searchOption, getSelectedSearchOptionClass(searchOption.key))} key={searchOption.key} onClick={() => onClickOption(searchOption.key)} onKeyDown={() => onClickOption(searchOption.key)} role="button" tabIndex="0">
                        {searchOption.value}
                    </div>
                ))}
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            show={isOpenOptions}
            trigger="click"
            placement="bottom"
            overlay={menuPopover}
            rootCloseEvent="mousedown"
            rootClose
            onToggle={onToggle}
        >
            <button type="button" className={classes.arrowButton}>
                {isOpenOptions
                    ? <Svg iconId="icon-arrow_drop_up" style={classes.arrowIcon} />
                    : <Svg iconId="icon-arrow_drop_down" style={classes.arrowIcon} />}
            </button>
        </OverlayTrigger>
    );
}
