export const COUNTRY = {
    ARGENTINA: 'AR',
    BULGARIA: 'BG',
    GERMANY: 'DE',
    HUNGARY: 'HU',
    ROMANIA: 'RO',
    RUSSIA: 'RU',
    SLOVAKIA: 'SK',
    UKRAINE: 'UA',
    AZERBAIJAN: 'AZ',
    SERBIA: 'RS',
};
