import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    modalLeftPanelFooterWrapper: {
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 66,
        height: 66,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxShadow: theme.shadows.footer,
        zIndex: 1,
    },
}));

export default function ModalLeftPanelFooter(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.modalLeftPanelFooterWrapper, className)}>
            {children}
        </div>
    );
}
