import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const gisService = {
    getWorkoperationStatusData,
    getLots,
    getCultivationPeriods,
    getCultivationPeriodGroups,
    getPlanningCultivationPeriods,
    getLpisFields,
    getRegistryNumbers,
    getPremises,
    getStables,
    getMeteorology,
    getMapCompanyPermissions, //TODO: ideiglenes végpont adat migrációhoz
    setPlanningCultivationPeriods,
    getPlanningLayersData,
    getScoutingTasks,
    getScoutings,
};

/**
 * Get cultivation table arrays
 * @property {array<int>} entityIds - IsOptional - Lot id list eg.: [1]
 * @property {array<int>} companyIds - IsOptional - Company list eg.: [1,2,3]
 *
 * @return {array} Cultivation table array
*/
async function getLots(props) {
    const response = await sendServerRequest('POST', '/api/gis/lots', props);
    return response;
}

/**
 * Get cultivation period arrays
 * @property {array<int>} entityIds - IsOptional - Lot id list eg.: [1]
 * @property {array<int>} companyIds - IsOptional - Company list eg.: [1,2,3]
 * @property {int} productionYearId - IsOptional - Production year id eg.: 16
 *
 * @return {array} Cultivation period array
*/
async function getCultivationPeriods(props) {
    const response = await sendServerRequest('POST', '/api/gis/cultivationPeriods', props);
    return response;
}

/**
 * Get cultivation period arrays
 * @property {array<int>} entityIds - IsOptional - Lot id list eg.: [1]
 * @property {array<int>} companyIds - IsOptional - Company list eg.: [1,2,3]
 * @property {int} productionYearId - IsOptional - Production year id eg.: 16
 *
 * @return {array} Cultivation period array
*/
async function getPlanningCultivationPeriods(props) {
    const response = await sendServerRequest('POST', '/api/gis/planningCultivationPeriods', props);
    return response;
}

/**
 * Get cultivation period group arrays
 * @property {array<int>} entityIds - IsOptional - Lot id list eg.: [1]
 * @property {array<int>} companyIds - IsOptional - Company list eg.: [1,2,3]
 * @property {int} productionYearId - IsOptional - Production year id eg.: 16
 *
 * @return {array} Cultivation period group array
*/
async function getCultivationPeriodGroups(props) {
    const response = await sendServerRequest('POST', '/api/gis/cultivationPeriodGroups', props);
    return response;
}

/**
 * Get lpis field arrays
 * @property {array<int>} entityIds - IsOptional - Lot id list eg.: [1]
 * @property {array<int>} companyIds - IsOptional - Company list eg.: [1,2,3]
 * @property {int} productionYearId - IsOptional - Production year id eg.: 16
 *
 * @return {array} Lpis field array
*/
async function getLpisFields(props) {
    const response = await sendServerRequest('POST', '/api/gis/lpisFields', props);
    return response;
}

/**
 * Get Registry number arrays
 * @property {array<int>} entityIds - IsOptional - Lot id list eg.: [1]
 * @property {array<int>} companyIds - IsOptional - Company list eg.: [1,2,3]
 *
 * @return {array} Registry number array
*/
async function getRegistryNumbers(props) {
    const response = await sendServerRequest('POST', '/api/gis/registryNumbers', props);
    return response;
}

/**
 * Get premise arrays
 * @property {array<int>} entityIds - IsOptional - Lot id list eg.: [1]
 * @property {array<int>} companyIds - IsOptional - Company list eg.: [1,2,3]
 *
 * @return {array} Premise array
*/
async function getPremises(props) {
    const response = await sendServerRequest('POST', '/api/gis/premises', props);
    return response;
}

/**
 * Get stable arrays
 * @property {array<int>} entityIds - IsOptional - Lot id list eg.: [1]
 * @property {array<int>} companyIds - IsOptional - Company list eg.: [1,2,3]
 *
 * @return {array} Stable array
*/
async function getStables(props) {
    const response = await sendServerRequest('POST', '/api/gis/stables', props);
    return response;
}

/**
 * Get Meteorology arrays
 * @property {string} startDate - Required - Lot id list eg.: "2020-03-01"
 * @property {string} endDate - Required - Company list eg.: "2020-03-20"
 * @property {string} locationId - Required - locationIds concat with comma eg.: "1,2,3"
 *
 * @return {array} Meteorology array
*/
async function getMeteorology(props) {
    const url = routerHelper.getUrlWithParameters('/api/meteorology/list', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

//TODO: ideiglenes végpont adat migrációhoz
async function getMapCompanyPermissions(body) {
    const response = await sendServerRequest('POST', '/api/gis/mapCompanyPermissions', body);
    return response;
}

/**
 * Get work operation status for cultivation periods on a specific date
 * @property {array<int>} startDate
 * @property {array<int>} endDate
 *
 * @return {array} Registry number array
*/
async function getWorkoperationStatusData(props) {
    const response = await sendServerRequest('POST', '/api/gis/workoperationStatus', props);
    return response;
}

async function setPlanningCultivationPeriods(body) {
    const response = await sendServerRequest('POST', '/api/gis/setPlanningCultivationPeriods', body);
    return response;
}

async function getPlanningLayersData(planIds) {
    const body = new FormData();
    body.append('planIds', planIds);

    const response = await fetch('/modules/tervezes/control/get_terv_zart.php', { method: 'POST', body });
    if (response.status === 200) {
        const responseParsed = await response.json();
        return responseParsed;
    }
    return [];
}

/**
 * Get sent tasks
 * @property {array<int>} entityIds - IsOptional - Lot id list eg.: [1]
 * @property {array<int>} companyIds - IsOptional - Company list eg.: [1,2,3]
 *
 * @return {array} Registry number array
*/
async function getScoutingTasks(props) {
    const response = await sendServerRequest('POST', '/api/gis/scoutingTasks', props);
    return response;
}

/**
 * Get scoutings
 * @property {array<int>} entityIds - IsOptional - Lot id list eg.: [1]
 * @property {array<int>} companyIds - IsOptional - Company list eg.: [1,2,3]
 *
 * @return {array} Registry number array
*/
async function getScoutings(props) {
    const response = await sendServerRequest('POST', '/api/gis/scoutings', props);
    return response;
}
