/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import Svg from '../../../Svg';

const useStyles = createUseStyles((theme: any) => ({
    iconSvgWrapper: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
    },
    iconSvg: {
        width: 22,
        height: 22,
        fill: theme.color.jet,
    },
}));

export default function IconListCell(props) {
    const { rowData, columnId } = props;
    const classes = useStyles();
    const [iconList, setIconList] = useState();

    useEffect(() => {
        if (rowData && columnId) {
            const iconListData = arrayUtils.getArrayItemByKeyString(rowData, columnId) || [];
            setIconList(iconListData);
        }
    }, [rowData, columnId]);

    if (isEmpty(columnId)) {
        return null;
    }

    return (
        <div className={classes.iconSvgWrapper} role="button" tabIndex={0}>
            {iconList?.map((iconItem, index) => (
                <div key={index} title={iconItem.iconTitle}>
                    <Svg
                        key={index}
                        iconId={iconItem.iconId}
                        style={classes.iconSvg}
                    />
                </div>
            ))}
        </div>
    );
}
