export const TASK = {
    MODULE_ID: 27,
    NAME: 'task',
    CONTENTS: {
        RELEASED_TASKS: {
            CONTENT_URL_ID: { releasedTasks: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'releasedTasks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.releasedTasks',
                TRANSLATOR_DEFAULT: 'Released tasks',
            },
        },
    },
};
