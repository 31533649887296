import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { isArray } from 'lodash';

import useTranslate from '@i18n/useTranslate';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import Button from '@baseComponents/buttons/Button';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { ModalContentWrapper, ModalHeader, ModalBody, ModalFooter } from '@baseComponents/modals/modalNew';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';

import useCertificatesModalLoadData from './useCertificatesModalLoadData';
import getAllColumns from './allColumns';

const useStyles = createUseStyles((theme: any) => ({
    modalWrapper: {
        width: 1200,
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    tableWrapper: {
        display: 'flex',
        height: '100%',
        width: '100%',
    },
    table: {
        '& .table-row>[role=button]': {
            height: theme.table.tableRowHeight,
        },
        '& .tableHeaderRow': {
            marginRight: 0,
        },
        '& .List': {
            paddingRight: 0,
        },
        '& .table-row': {
            paddingRight: 0,
        },
        width: '100%',
    },
    footerButton: {
        width: 150,
    },
}));

const TABLE_KEY = 'certificatesModal';

export default function CertificatesModal(props) {
    const { hideModal, requestIds, releasedCertificateIds, receptionCertificateIds, showDeleteColumn = false } = props;
    const { t, translater } = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [defaultColumns, setDefaultColumns] = useState();
    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    const { tableData, reloadTable } = useCertificatesModalLoadData({ requestIds, releasedCertificateIds, receptionCertificateIds });

    useEffect(() => {
        const showedColumns = ['downloadIcon', 'type', 'certificateDate', 'certificateNumber', 'companyName', 'partnerName', 'warehouseName'];

        if (showDeleteColumn) {
            showedColumns.push('deleteButton');
        }

        const filteredColumns = getAllColumns(t, translater, showedColumns, showDeleteColumn, reloadTable, dispatch, requestIds);
        setDefaultColumns(filteredColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showDeleteColumn]);

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {t('certificatesModal.completedCertificates', 'Completed certificates')}
            </ModalHeader>
            <ModalBody className={classes.modalBody}>
                <LoadSpinner value={tableData} isAutoDetect />
                <div className={classes.tableWrapper}>
                    <TableGroup
                        tableId={TABLE_KEY}
                        tableData={tableData}
                        tableColumns={columns}
                        isBasePageTable={false}
                        isCollapsible={false}
                        tableGroup={{
                            value: null,
                            text: 'No breakdown',
                        }}
                        useRowSelection={false}
                        className={classes.table}
                    />
                </div>
                <TableScrollerFooter tableKey={TABLE_KEY} isLoaded={isArray(tableData)} />
            </ModalBody>
            <ModalFooter>
                <Button type="secondary" className={classes.footerButton} onClick={hideModal}>
                    {t('default.close', 'Close')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
