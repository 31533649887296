import { useCallback, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { messageService } from '@services';
import { errorActions } from '@states/actions/errorActions';
import { SETTINGS } from 'shared';
import { companyActions } from '@states/actions';
import Notification from '@baseComponents/chips/Notification';
import Svg from '@baseComponents/Svg';
import useIsWindowActive from '@common_modules/useIsWindowActive/useIsWindowActive';
import MessagesMenu from '../messages/MessagesMenu';

const useStyles = createUseStyles((theme: any) => ({
    btnIcon: {
        height: 20,
        width: 20,
        fill: theme.color.raven,
    },
    notification: {
        marginTop: (props: any) => (props.expanded ? -40 : -30),
        marginLeft: (props: any) => (props.expanded ? 60 : 30),
    },
}));

export default function MessagesCard(props) {
    const { className, expanded, setShowUserMenu } = props;
    const dispatch = useDispatch();
    const location = useLocation();

    const [unreadMessagesNumber, setUnreadMessagesNumber] = useState(null);
    const [showMessagesMenu, setShowMessagesMenu] = useState(false);
    const [updateTick, setUpdateTick] = useState(0);
    const [updateInterval, setUpdateInterval] = useState<any>();

    const isWindowActive = useIsWindowActive();

    const handleUpdateTick = useCallback(() => {
        setUpdateTick(p => (p + 1 >= Number.MAX_SAFE_INTEGER ? 0 : p + 1));
    }, [setUpdateTick]);

    const classes = useStyles({ expanded });

    function handleOnClick() {
        setShowMessagesMenu(!showMessagesMenu);
        setShowUserMenu(false);
    }

    useEffect(() => {
        let isCancelled = false;
        const messageUpdateInterval = dispatch(companyActions.getSetting(SETTINGS.MESSAGE_UPDATE_INTERVAL)) as any;

        async function loadUnreadMessagesNumber() {
            try {
                const messages = await messageService.getUnreadMeassagesCount();
                if (!isCancelled) {
                    setUnreadMessagesNumber(messages?.messageCount || 0);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }

        if (location && (isWindowActive || messageUpdateInterval === 0)) {
            loadUnreadMessagesNumber();
        }

        if (updateTick === 0) {
            if (messageUpdateInterval > 0 && !updateInterval) {
                const interval = setInterval(() => {
                    handleUpdateTick();
                }, messageUpdateInterval);

                setUpdateInterval(interval);
            }
        }

        return () => {
            isCancelled = true;
            // clearInterval(updateInterval);
        };
    }, [dispatch, handleUpdateTick, isWindowActive, location, unreadMessagesNumber, updateInterval, updateTick]);

    return (
        <>
            <button type="button" className={className} onClick={handleOnClick}>
                <Svg iconId="icon-message" style={classes.btnIcon} />
                {(unreadMessagesNumber && unreadMessagesNumber > 0) ? <Notification count={unreadMessagesNumber} className={classes.notification} /> : null}
            </button>

            {showMessagesMenu && (
                <MessagesMenu
                    showMessagesMenu={showMessagesMenu}
                    closeMessagesMenu={() => setShowMessagesMenu(false)}
                    expanded={expanded}
                    unreadMessagesNumber={unreadMessagesNumber}
                    setUnreadMessagesNumber={setUnreadMessagesNumber}
                />
            )}
        </>
    );
}
