import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '@states/actions';
import useTranslate from '@i18n/useTranslate';
import { isEmpty, isNil } from 'lodash';
import { INTERFACE_FILENAME, INTERFACE_SETTINGS } from 'shared/src/constants';

import Slider from '@baseComponents/controls/Slider';
import Svg from '@baseComponents/Svg';
import { SimpleSelectOverFlow } from '@baseComponents/select';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInAttributeTypes, BuiltInLayerTypes, isCustomLayerAttribute, isLayerAttributeLabelingHiddenDefault } from '@map/services/mapEnums';
import { LayerAttribute, LayerAttributeStatus, ThemeType, TranslateType } from '@map/services/mapTypes';
import { getLayerAttributeCaptionString } from '@map/utils/mapCaptionUtils';

import { MapWidgetProdYearSelectorItem } from '@map/widgets/MapWidget/MapWidgetComponent';
import { Spinner } from 'react-bootstrap';
import { LayerControlPanelLayerRowOptions } from './LayerControlPanelLayerRowOptions';
import { getLayerName } from './LayerFolder/LayerFolderUtils';
import useLayerPanelStyles from './styles';

type Classes = 'container' | 'rowWrapper' | 'rowLeftWrapper' | 'actionsWrapper' | 'dragWrapper' | 'opacityWrapper' | 'opacityBar' | 'slider' | 'bottomRow' |
    'warningText' | 'warningFill' | 'leftArrow'
    | 'titleWrapper' | 'itemTitle' | 'itemTitleShort' | 'itemSubtitle' | 'disabled' | 'attrSelect' | 'selectorContainer' | 'visibleButton' | 'binButton' | 'actionsCenterWraper' | 'yearSelector';

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    container: {
        position: 'relative',
    },
    rowWrapper: {
        display: 'flex',
        width: '100%',
        opacity: props => (props.isVisible ? 1 : 0.5),
        borderLeft: props => `5px solid ${props.color}`,
        backgroundColor: theme.color.white,
        height: props => (props.hasProductionYearFilter ? 96 : 64),
        alignItems: 'center',
        gap: 10,
        padding: '0 10px',
        justifyContent: 'space-between',
    },
    rowLeftWrapper: {
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        width: '100%',
    },
    actionsWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    dragWrapper: {
        cursor: 'grab',
        opacity: props => (props.isVisible ? 1 : 0),
    },
    opacityWrapper: {
        display: 'flex',
        // paddingLeft: 4,
        // minWidth: '7ch',
        maxWidth: 60,
        height: 30,
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.color.lilian,
        borderRadius: 4,
        color: theme.color.raven,
        fontSize: 14,
        margin: 'auto 0',
        cursor: 'pointer',
    },
    opacityBar: {
        display: 'flex',
        width: '100%',
        height: 20,
        // background: theme.color.lilian,
        alignItems: 'center',
        padding: [0, 8],
        '& *': {
            transform: 'translateY(-55%)',
        },
    },
    slider: {
        width: '100%',
    },
    warningText: {
        color: theme.color.destructive,
    },
    warningFill: {
        fill: theme.color.destructive,
    },
    leftArrow: {
        width: 0,
        height: 0,
        borderTop: '10px solid transparent',
        borderBottom: '10px solid transparent',
        borderRight: `10px solid ${theme.color.white}`,
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 2,
    },
    itemTitle: {
        maxWidth: '24ch',
        color: theme.color.jet,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 14,
        fontWeight: 500,
        margin: 0,
    },
    itemTitleShort: {
        maxWidth: '16ch',
        color: theme.color.jet,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        fontSize: 14,
        fontWeight: 500,
        margin: 0,
    },
    bottomRow: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    itemSubtitle: {
        fontSize: 12,
        color: theme.color.raven,
        fontWeight: 500,
        lineHeight: '12px',
    },
    disabled: {
        color: theme.color.raven,
        pointerEvents: 'none',
        opacity: 0.3,
    },
    attrSelect: {
        '& .btn-primary.dropdown-toggle': {
            height: 30,
            maxHeight: 30,
            width: 140,
        },
    },
    selectorContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        aligntItems: 'flex-end',
        width: '100%',
        position: 'relative',
    },
    visibleButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 4,
        borderColor: theme.color.gray,
        cursor: 'pointer',
        minWidth: 'calc(7ch + 10px)',
        maxWidth: 60,
        height: 30,
    },
    binButton: {
        cursor: 'pointer',
        marginLeft: 4,

        '& > svg': {
            fill: theme.color.destructive,
        },
    },
    actionsCenterWraper: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'end',
    },
    yearSelector: {
        '& .btn-primary.dropdown-toggle': {
            height: 30,
            maxHeight: 30,
            width: 140,
        },
    },
}));

type TProps = {
    layerAttribute: LayerAttribute;
    isFirstLayer: boolean,
    onDragStart: (ev) => void;
    onDragEnter: (ev) => void;
    onDragEnd: (ev) => Promise<void>;
    isRemoveDisabled: boolean;
};

type StyleProps = {
    color: string,
    isVisible: boolean,
    hasProductionYearFilter: boolean,
}

type LayerSelectorItem = {
    key: number,
    value: string
}

enum LabelVisibilityMode {
    AUTO = 'AUTO',
    HIDDEN = 'HIDDEN',
    VISIBLE = 'VISIBLE',
}

export default function LayerRow({
    layerAttribute,
    isFirstLayer,
    onDragStart,
    onDragEnter,
    onDragEnd,
    isRemoveDisabled,
}: TProps) {
    const [store, controller] = useMapContext();
    const { t, translater }: TranslateType = useTranslate();
    const dispatch = useDispatch();
    const [opacity, setOpacity] = useState<number>(50);
    const [hasOpacity, setHasOpacity] = useState<boolean>(true);

    const [isLabelingVisible, setIsLabelingVisible] = useState(false);

    const [layerSelectorItems, setLayerSelectorItems] = useState<LayerSelectorItem[]>([]);

    const labelVisibilityMode = useRef(LabelVisibilityMode.AUTO);

    const classes = useStyles({ color: layerAttribute.defaultFillColor, isVisible: hasOpacity, hasProductionYearFilter: !!layerAttribute.timelineProductionYearId });
    const commonClasses = useLayerPanelStyles();

    const [labelModeInitialized, setLabelModeInitialized] = useState(false);

    const periodYears = useSelector((state: any) => state.dataLists.productionYears);
    const [selectedYear, setSelectedYear] = useState(layerAttribute.timelineProductionYearId);

    const isOpacityBarVisible = layerAttribute.attributeKey !== BuiltInAttributeTypes.LAST_WORK_OPERATION_MAIN_GROUP;

    const isPlanningLayer = controller.isPlanningLayer(layerAttribute.layerId);

    const selectorYears = useMemo<MapWidgetProdYearSelectorItem[]>(() => {
        if (isEmpty(periodYears)) {
            return [];
        }

        return periodYears?.map(year => ({ key: year.id, value: year.period }));
    }, [periodYears]);

    const saveInterfaceSettings = () => {
        const [fileName, stringifiedInterfaceSettings] = controller.getInterfaceSettings();
        dispatch(userActions.setInterfaceSetting(INTERFACE_SETTINGS.MAP_SETTINGS, stringifiedInterfaceSettings, fileName));
    };

    const isPending = layerAttribute.isActive === LayerAttributeStatus.PENDING;

    useEffect(() => {
        if (labelModeInitialized) {
            return;
        }
        if ((isLayerAttributeLabelingHiddenDefault(layerAttribute.attributeKey) && layerAttribute.layerName === BuiltInLayerTypes.CULTIVATION_PERIOD)) {
            labelVisibilityMode.current = LabelVisibilityMode.HIDDEN;
        }

        if ((layerAttribute.layerName === BuiltInLayerTypes.MACHINES || layerAttribute.layerName === BuiltInLayerTypes.IRRIGATION) && layerAttribute.isLabelingEnabled === false) {
            controller.setLayerAttributeMachines(layerAttribute, true);
            labelVisibilityMode.current = LabelVisibilityMode.VISIBLE;
        }
        setLabelModeInitialized(true);
    }, [controller, layerAttribute, labelModeInitialized]);


    useEffect(() => {
        controller.setLayerAttributeOpacity(layerAttribute, opacity ?? 0);
    }, [opacity])

    useEffect(() => {
        if (!hasOpacity) {
            return;
        }

        if (isFirstLayer) {
            setOpacity(80);
            if (!(isLayerAttributeLabelingHiddenDefault(layerAttribute.attributeKey) && layerAttribute.layerName === BuiltInLayerTypes.CULTIVATION_PERIOD)) {
                controller.setLayerAttributeLabeling(layerAttribute, true);
                if (labelVisibilityMode.current === LabelVisibilityMode.AUTO) {
                    setIsLabelingVisible(true);
                }
            } else {
                labelVisibilityMode.current = LabelVisibilityMode.HIDDEN;
            }
        } else {
            setOpacity(50);
            if (labelVisibilityMode.current === LabelVisibilityMode.AUTO) {
                controller.setLayerAttributeLabeling(layerAttribute, false);

                setIsLabelingVisible(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirstLayer]);

    const layer = store.layering.layers?.find(storeLayer => storeLayer.id === layerAttribute.layerId);
    const isAttributeSelectorHidden = ['machine', 'irrigation', 'receivedScoutings', 'sentTasks'].includes(layer?.name as string);

    const setLabelVisibilityPermament = useCallback((visible: boolean) => {
        if (layerAttribute.layerName === BuiltInLayerTypes.MACHINES || layerAttribute.layerName === BuiltInLayerTypes.IRRIGATION) {
            controller.setLayerAttributeMachines(layerAttribute, visible);
        }

        if (visible) {
            labelVisibilityMode.current = LabelVisibilityMode.VISIBLE;
        } else {
            labelVisibilityMode.current = LabelVisibilityMode.HIDDEN;
        }

        controller.setLayerAttributeLabeling(layerAttribute, visible);
        setIsLabelingVisible(visible);
    }, [layerAttribute, controller]);

    useEffect(() => {
        const layerId = layerAttribute.layerId;

        let layerAttibutes = store.layering.attributes?.filter(attribute => attribute.layerId === layerId);

        const layerAttributesFilterWhiteList = store.layerSelector.attributeFilter.whiteList?.filter(filter => filter.layerName === layerAttribute.layerName);

        if (!isNil(layerAttributesFilterWhiteList) && !isEmpty(layerAttributesFilterWhiteList)) {
            const whiteListKeys = layerAttributesFilterWhiteList.map(filter => filter.attributeKey);
            layerAttibutes = layerAttibutes?.filter(attr => whiteListKeys.includes(attr.defaultAttributeKey));
        }

        if (layerAttibutes) {
            let captionLayer = layer;
            if (isPlanningLayer) {
                captionLayer = store.layering.layers?.find(l => l.name === BuiltInLayerTypes.PLANNING);
            }

            const attrIndex = layerAttibutes.findIndex(attr => attr.id === layerAttribute.id);

            const layerSelectors: LayerSelectorItem[] = layerAttibutes?.filter(attribute => attribute.isActive === LayerAttributeStatus.INACTIVE).map(attribute => ({ key: attribute.id, value: getLayerAttributeCaptionString(translater, captionLayer, attribute) }));

            layerSelectors.splice(attrIndex, 0, { key: layerAttribute.id, value: getLayerAttributeCaptionString(translater, captionLayer, layerAttribute) });
            // layerSelectors.push({ key: layerAttribute.id, value: getLayerAttributeCaptionString(translater, captionLayer, layerAttribute) });
            setLayerSelectorItems(layerSelectors);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layer, layerAttribute, store.layering.attributes, store.layering.order, translater, store.layerSelector]);

    useEffect(() => {
        setIsLabelingVisible(layerAttribute.isLabelingEnabled);
    }, [controller, layerAttribute]);

    useEffect(() => {
        layerAttribute.dataLayer.forEach(feature => {
            const newStyle: google.maps.Data.StyleOptions = { fillOpacity: opacity / 100, strokeOpacity: 0.8 };

            if (layerAttribute.attributeKey === BuiltInAttributeTypes.LAST_WORK_OPERATION_MAIN_GROUP && opacity !== 0) {
                newStyle.fillOpacity = feature.getProperty('defaultFillOpacity');
            }

            const layerNames = [BuiltInLayerTypes.RECEIVED_SCOUTINGS, BuiltInLayerTypes.SENT_TASK, BuiltInLayerTypes.METEOROLOGY] as string[];
            if (opacity === 0) {
                newStyle.strokeOpacity = 0;
                if (layerNames.includes(layerAttribute.layerName)) {
                    layerAttribute.dataLayer.overrideStyle(feature, { opacity: 0 });
                }
            } else if (layerNames.includes(layerAttribute.layerName)) {
                layerAttribute.dataLayer.overrideStyle(feature, { opacity: 1 });
            }

            layerAttribute.dataLayer.overrideStyle(feature, newStyle);
        });
    }, [layerAttribute, opacity, store.layering.order]);

    const handleOpacityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newOpacity = Number(event.target.value);
        setOpacity(newOpacity);

        if (newOpacity > 0 && hasOpacity === false) {
            setHasOpacity(true);
        }
    };

    const setAsHidden = () => {
        controller.setLayerAttributeLabeling(layerAttribute, false);
        setIsLabelingVisible(false);
        setHasOpacity(false);
        setOpacity(0);
    };
    const setAsVisible = () => {
        setHasOpacity(true);
        setOpacity(50);
    };

    const setAsHiddenIfTransparent = () => {
        if (opacity === 0) {
            setAsHidden();
        }
    };

    const replaceAttribute = async (id: number) => {
        const addAttribute = store.layering.attributes?.find(x => x.id === id);
        if (addAttribute) {
            controller.setAutoZoom(false);
            await controller.removeLayerAttributesFromMap([layerAttribute]);
            await controller.setLayerAttributeLabeling(layerAttribute, false);
            await controller.addLayerAttributesToMap([addAttribute]);
            controller.setAutoZoom(true);
            saveInterfaceSettings();
        }
    };

    const onChangeAttributeTimeLineProductionYearId = async (id: number) => {
        setSelectedYear(id);
        await controller.setAttributeTimeLineProductionYearId(layerAttribute, id);
        if (!isNil(store.mapSlider.startDate)) {
            controller.reloadAttributesByInterval(store.mapSlider.startDate);
        }
    };

    return (
        <div className={classes.container}>
            <div
                className={classes.rowWrapper}
            >
                <div className={classes.rowLeftWrapper}>
                    <div
                        draggable
                        onDragStart={onDragStart}
                        onDragEnter={onDragEnter}
                        onDragEnd={async ev => {
                            await onDragEnd(ev);
                            saveInterfaceSettings();
                        }}
                        onDragOver={ev => ev.preventDefault()}
                        className={classes.dragWrapper}
                    >
                        <Svg iconId="icon-drag_vertical" style={commonClasses.icon} />
                    </div>
                    <div className={classes.titleWrapper}>
                        {!isAttributeSelectorHidden ? <p className={classes.itemTitle} title={getLayerName(layer, translater)}>
                            {getLayerName(layer, translater)}
                        </p> : null}
                        <div className={classes.bottomRow}>
                            <div className={classes.selectorContainer}>
                                {isAttributeSelectorHidden ? (<p className={classes.itemTitleShort} title={getLayerName(layer, translater)}>
                                    {getLayerName(layer, translater)}
                                                              </p>) : (<SimpleSelectOverFlow
                                    dataList={layerSelectorItems}
                                    selected={layerAttribute.id}
                                    setSelected={(id: any) => replaceAttribute(Number(id))}
                                    className={classes.attrSelect}
                                    disabled={layerSelectorItems.length === 1 || isPending}
                                />)}
                            </div>
                            <div className={classes.actionsCenterWraper}>
                                <div className={classes.actionsWrapper}>
                                    {isPending
                                        ? <Spinner style={{ width: 30, height: 30 }} variant="secondary" animation="border" />
                                        : (
                                            <>
                                                <LayerControlPanelLayerRowOptions
                                                    mapController={controller}
                                                    layerAttribute={layerAttribute}
                                                    isRemoveDisabled={isRemoveDisabled}
                                                    hasOpacity={hasOpacity}
                                                    isLabelingVisible={isLabelingVisible}
                                                    setAsHidden={setAsHidden}
                                                    setAsVisible={setAsVisible}
                                                    setIsLabelingVisible={setLabelVisibilityPermament}
                                                />
                                                <div
                                                    role="button"
                                                    aria-hidden="true"
                                                    onClick={async () => {
                                                        if (!isRemoveDisabled) {
                                                            await controller.removeLayerAttributesFromMap([layerAttribute]);
                                                            await controller.setLayerAttributeLabeling(layerAttribute, false);
                                                            saveInterfaceSettings();
                                                            setIsLabelingVisible(false);
                                                        }
                                                        document.body.click();
                                                    }}
                                                    className={classes.binButton}
                                                >
                                                    <Svg iconId="icon-bin" width={24} height={24} />
                                                </div>
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                        {layerAttribute.timelineProductionYearId && (
                            <div>
                                <SimpleSelectOverFlow
                                    dataList={selectorYears}
                                    selected={selectedYear}
                                    setSelected={(id: any) => { onChangeAttributeTimeLineProductionYearId(Number(id)); }}
                                    className={classes.yearSelector}
                                    iconId="icon-calendar"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {isOpacityBarVisible && (
                <div className={classes.opacityBar}>
                    <Slider
                        value={opacity}
                        min={0}
                        max={100}
                        className={classes.slider}
                        onChange={handleOpacityChange}
                        onBlur={setAsHiddenIfTransparent}
                    />
                </div>
            )}
        </div>
    );
}
