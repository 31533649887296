/* eslint-disable max-lines-per-function */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import { SYSTEM } from 'shared/src/constants';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { errorActions } from '@states/actions/errorActions';
import { alertActions } from '@states/actions/alertActions';
import Input from '@baseComponents/inputs/Input';
import Button from '@baseComponents/buttons/Button';
import TwoFactorInput from '@baseComponents/inputs/TwoFactorInput';
import { userActions } from '@states/actions/userActions';
import { setPageLoading } from '@baseComponents/PageLoader';
import { phpHelper } from '@utils/php/PhpHelper';
import { dateTimeUtils } from 'shared/src/modules';
import { systemService, sessionService, phpService, companyService } from '@services';
import { useCompanyPermission } from '@common_modules/permission';

import Svg from '@baseComponents/Svg';
import ForgetPassword from '../components/login/ForgetPassword';
import ForgetTwoFactorToken from '../components/login/ForgetTwoFactorToken';
import SelectLanguage from '../components/login/SelectLanguage';
import Gdpr from '../components/login/gdpr/Gdpr';

const useStyles = createUseStyles((theme: any) => ({
    loginContainer: {
        display: 'flex',
    },
    form: {
        marginTop: 100,
    },
    formContainer: {
        background: theme.color.white,
        height: '100vh',
        minHeight: '100vh',
        width: 640,
        minWidth: 640,
        float: 'left',
        position: 'relative',
    },
    imgContainer: {
        backgroundImage: (props: any) => {
            if (props.systemSubType === SYSTEM.SYSTEM_SUBTYPE.SAVINGS_BANK) {
                return 'url("/styles/img/takarek_login_bg.jpg")';
            }
            return 'url("/styles/img/login_bg.jpg")';
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        minHeight: '100vh',
        width: '100%',
    },
    loginForm: {
        width: 360,
        marginTop: 100,
        margin: 'auto',
        border: 'none',
    },
    logoContainer: {
        width: '100%',
        margin: 'auto',
        position: 'relative',
    },
    logo: {
        backgroundImage: (props: any) => {
            if (props.isTestSystem) {
                return 'url("/styles/img/av_small_logo_test.png")';
            }
            return 'url("/styles/img/av_small_logo.png")';
        },
        margin: 'auto',
        display: 'block',
        width: 106,
        height: 38,
        float: 'middle',
    },
    systemCountryCode: {
        position: 'absolute',
        top: 0,
        left: 238,
        color: theme.color.raven,
        fontSize: 10,
    },
    loginButtonContainer: {
        textAlign: 'center',
        marginTop: 40,
        marginBottom: 20,
    },
    loginButton: {
        width: '100%',
    },
    twoFactorContainer: {
        fontSize: '16px',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: [50, 30, 0, 30],
        textAlign: 'center',
    },
    twoFactorTitle: {
        marginTop: 0,
        marginBottom: 30,
        fontWeight: 700,
        fontSize: '24px !important',
        fontFamily: 'Rubik',
        lineHeight: '1.2 !important',
    },
    twoFactorFooterContainer: {
        paddingTop: 10,
        paddingBottom: 15,
        borderTop: `1px solid ${theme.color.pearl}`,
        display: 'flex',
        justifyContent: 'center',
    },
    footerContainer: {
        position: 'absolute',
        bottom: 0,
        width: 360,
    },
    langContainer: {
        paddingTop: 10,
        paddingBottom: 15,
        borderTop: `1px solid ${theme.color.pearl}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    linkButton: {
        color: theme.color.main,
        fontSize: 14,
        textDecoration: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        border: 'none',
        padding: 0,
        '&:hover': {
            color: theme.color.main,
            textDecoration: 'underline',
        },
    },
    alert: {
        float: 'right',
    },
    inputSpace: {
        marginBottom: 20,
        height: 50,
    },
    testSystemTextContainer: {
        position: 'absolute',
        bottom: -8,
        left: 148,
        color: theme.color.raven,
        fontSize: 10,
        textTransform: 'uppercase',
        overflow: 'hidden',
        maxWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    testLineTop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: 10,
        background: 'rgb(255,150,0)',
    },
    testLineBottom: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 10,
        background: 'rgb(255,150,0)',
    },
    /*
    infoImgContainer: {
        backgroundImage: 'url("/styles/img/login_egn_bg.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        minHeight: '100vh',
        width: '100%',
        position: 'relative',
    },
    infoContainer: {
        width: 510,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        marginTop: 50,
        marginLeft: 50,
        background: 'rgba(57,76,70,0.85)',
        borderRadius: 10,
    },
    infoTitle: {
        color: '#ccc176',
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 150,
    },
    infoSubTitle: {
        color: '#ccc176',
        marginBottom: 50,
        fontSize: 30,
        textAlign: 'center',
    },
    infoText: {
        color: theme.color.white,
        fontSize: 20,
        textAlign: 'center',
    },
    infoFooterText: {
        color: '#ccc176',
        fontSize: 26,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 50,
    },
    infoColorText: {
        color: theme.color.main,
    },*/
    /*
    infoImgContainer: {
        backgroundImage: 'url("/styles/img/login_agrovirtus_bg.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        minHeight: '100vh',
        width: '100%',
        position: 'relative',
    },
    infoContainer: {
        width: 510,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        marginTop: 50,
        marginLeft: 50,
        background: 'rgba(47,57,77,0.85)',
        borderRadius: 10,
    },
    infoTitle: {
        color: '#05DAA5',
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
        marginTop: 150,
    },
    infoSubTitle: {
        color: '#05DAA5',
        marginBottom: 50,
        fontSize: 30,
        textAlign: 'center',
    },
    infoText: {
        color: theme.color.white,
        fontSize: 20,
        textAlign: 'center',
    },
    infoFooterText: {
        color: theme.color.white,
        textAlign: 'center',
        marginTop: 50,
        fontSize: 16,
    },
    infoColorText: {
        color: '#05DAA5',
        '&:hover': {
            color: '#05DAA5',
        },
        '&:focus': {
            color: '#05DAA5',
        },
    },
    agrovirtusLogo: {
        width: 150,
        marginTop: 25,
    },*/
    /*
    worksImgContainer: {
        backgroundImage: 'url("/styles/img/login_works_bg.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        minHeight: '100vh',
        width: '100%',
        position: 'relative',
    },
    worksContainer: {
        width: 510,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        marginTop: 50,
        marginLeft: 50,
        background: 'rgba(36, 123, 80, 0.85)',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },
    worksBody: {
        padding: 30,
        height: 'calc(100% - 50px)',
        overflow: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin !important',
    },
    worksFooter: {
        position: 'fixed',
        bottom: 0,
        width: 510,
        color: theme.color.white,
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 20,
    },
    worksLogoTitle: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 15,
    },
    worksTitle: {
        color: 'rgb(11, 19, 7)',
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: 1,
    },
    worksSubTitle: {
        color: 'rgb(20, 34, 12)',
        marginBottom: 50,
        fontSize: 24,
        textAlign: 'center',
        lineHeight: 1,
    },
    worksText: {
        color: theme.color.white,
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20,
    },
    worksSubText: {
        color: theme.color.white,
        fontSize: 18,
        textAlign: 'center',
    },
    worksSmallSubText: {
        color: theme.color.white,
        fontSize: 16,
        textAlign: 'center',
        marginTop: 30,
    },
    worksBodyFooter: {
        display: 'flex',
        justifyContent: 'space-around',
        margin: [15, 30, 0, 30],
    },
    worksStoreLogo: {
        height: 50,
    },
    worksAppLogo: {
        height: 60,
        width: 60,
        borderRadius: 8,
    },
    */
    dataArrivalImgContainer: {
        backgroundImage: 'url("/styles/img/login_data_arrival_bg.jpg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        minHeight: '100vh',
        width: '100%',
        position: 'relative',
    },
    dataArrivalContainer: {
        width: 510,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        marginTop: 50,
        marginLeft: 50,
        background: 'rgba(88, 130, 126, 0.8)',
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },
    dataArrivalBody: {
        padding: 30,
        height: 'calc(100% - 90px)',
        overflow: 'hidden',
        overflowY: 'auto',
        scrollbarWidth: 'thin !important',
    },
    dataArrivalLogoTitle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
    },
    dataArrivalTitle: {
        color: 'rgb(11, 19, 7)',
        fontSize: 26,
        fontWeight: 'bold',
        textAlign: 'center',
        lineHeight: 1,
    },
    dataArrivalSubTitle: {
        color: 'rgb(20, 34, 12)',
        marginBottom: 50,
        fontSize: 24,
        textAlign: 'center',
        lineHeight: 1,
    },
    dataArrivalText: {
        color: theme.color.white,
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20,
    },
    dataArrivalSubText: {
        color: theme.color.white,
        fontSize: 18,
        textAlign: 'center',
    },
    dataArrivalSmallSubText: {
        color: theme.color.white,
        fontSize: 16,
        textAlign: 'center',
        marginTop: 30,
    },
    dataArrivalWorksLogo: {
        height: 60,
        width: 60,
        borderRadius: 8,
    },
    dataArrivalConnectLogoContainer: {
        height: 60,
        width: 60,
        background: '#946e3f',
        borderRadius: 8,
        padding: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dataArrivalConnectLogo: {
        height: 45,
        width: 45,
    },
    dataArrivalAdd: {
        width: 25,
        height: 25,
        fill: theme.color.white,
    },
    dataArrivalTabletContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    dataArrivalTabletImg: {
        width: 400,
    },
    dataArrivalFooter: {
        position: 'fixed',
        bottom: 0,
        width: 510,
        color: theme.color.white,
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 20,
    },
    dataArrivalWorksColor: {
        backgroundColor: theme.color.main,
        padding: [2, 4],
    },
    dataArrivalConnectColor: {
        backgroundColor: '#946e3f',
        padding: [2, 4],
    },
}));

const initialValues = {
    username: '',
    password: '',
};

export default function Login() {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const countryCode = useSelector((state: any) => state.system.systemInfo.countryCode);
    const systemLangCode = useSelector((state: any) => state.system.systemInfo.langCode);
    const sessionLangCode = sessionService.getLangCode();
    const [langCode, setLangCode] = useState(sessionLangCode || systemLangCode);
    const isTestSystem = useSelector((state: any) => state.system.systemInfo.isTestSystem);
    const systemSubType = useSelector((state: any) => state.system.systemInfo.subType);
    const { companyPermissions } = useCompanyPermission();
    const [isForgottenPassword, setForgottenPassword] = useState(false);
    const [twoFactor, setTwoFactor] = useState({ isEnabled: false, userId: 0 });
    const [verifiedCode, setVerifiedCode] = useState(false);
    const [isForgottenTwoFactorToken, setForgottenTwoFactorToken] = useState(false);
    const agentName = sessionService.getAgentName();
    const [loginData, setLoginData] = useState<any>({
        grant_type: 'password',
        username: '',
        password: '',
        agent: agentName,
    });
    const [langs, setLangs] = useState([]);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const isActiveDarkMode = sessionService.getDarkMode();
    const classes = useStyles({ systemSubType, isTestSystem } as any);

    const { values, handleChange, handleSubmit, errors } = useFormik({
        initialValues: { ...initialValues },
        validationSchema: Yup.object({
            username: Yup.string()
                .required(t('login.userNameRequired', 'Username is required')),
            password: Yup.string()
                .required(t('login.passwordRequired', 'Password is required')),
        }),
        validateOnChange: formIsDirty,
        onSubmit: (value, action) => login(value, action),
    });

    async function setLogin(isMaintained) {
        try {
            const responseMessage = await phpService.login(loginData);
            const loginResult: any = await dispatch(userActions.login(isMaintained, loginData));
            if (loginResult) {
                if (responseMessage.error) {
                    dispatch(errorActions.setError(responseMessage.error, location));
                }
            } else {
                if(!isMaintained){
                    dispatch(alertActions.errorAlert(t('login.failedLogin', 'Failed login')));
                }
                else{
                    dispatch(alertActions.infoAlert(t('router.maintenance', 'The AgroVIR is updating. Please try again later.')));
                }
            }
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }

    async function i18nChangeLanguage() {
        await i18n.changeLanguage(langCode.toLowerCase());
    }

    async function login() {
        try {
            setPageLoading(true);

            dateTimeUtils.setMomentLocale(sessionService.getLangCode());

            let isTwoFactorAuth = false;
            const company = await companyService.getCompanyByAgent(agentName);
            const isMaintained = Boolean(company.isMaintained);
            if(!isMaintained){
                const twoFactorAuth: any = await dispatch(userActions.getTwoFactorAuth(loginData.username, loginData.password, loginData.agent)) as any;
                setTwoFactor({ isEnabled: twoFactorAuth.isEnabled, userId: twoFactorAuth.userId });
                isTwoFactorAuth = twoFactorAuth.isEnabled; 
            }

            if(!isTwoFactorAuth){
                await setLogin(isMaintained);
            }
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        } finally {
            setPageLoading(false);
        }
    }

    function cancel() {
        setTwoFactor({ isEnabled: false, userId: 0 });
    }

    useEffect(() => {
        const fetchData = async () => {
            if (verifiedCode) {
                try {
                    setLogin(false);
                } catch (error) {
                    dispatch(errorActions.setError(error, location));
                }
            }
        };

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [verifiedCode]);

    //dark mode
    useEffect(() => {
        const docBody = document.body;
        if (docBody) {
            if (isActiveDarkMode) {
                docBody.classList.add('dark-mode');
            } else {
                docBody.classList.remove('dark-mode');
            }
        }
    }, [isActiveDarkMode]);

    useEffect(() => {
        setLoginData(currentLoginData => ({ ...currentLoginData, username: values.username, password: values.password }));
    }, [values]);

    useEffect(() => {
        async function loadActiveSystemLangs() {
            const langList = await systemService.getActiveSystemLangs();
            setLangs(langList);
        }

        if (langs && isEmpty(langs)) {
            loadActiveSystemLangs();
        }
    }, [langs]);

    useEffect(() => {
        if (isEmpty(langCode)) {
            return;
        }
        i18nChangeLanguage();
        sessionService.setLangCode(langCode.toLowerCase());
        dateTimeUtils.setMomentLocale(langCode.toLowerCase());
        setLoginData(currentData => ({ ...currentData, langCode: langCode.toUpperCase() }));
        phpHelper.changeLanguage(langCode.toLowerCase());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [langCode]);

    return (
        <>
            <div className={classes.loginContainer}>
                <div className={classes.formContainer}>
                    <div className={classes.loginForm}>

                        {!isForgottenPassword && !isForgottenTwoFactorToken && (
                            <div className={classes.logoContainer}>
                                <div className={classes.logo} />
                                <div className={classes.systemCountryCode}>{countryCode}</div>
                                {isTestSystem && (
                                    <div className={classes.testSystemTextContainer}>{t('login.testSystem', 'Test system') as any}</div>
                                )}
                            </div>
                        )}

                        {isTestSystem && (
                            <div className={classes.testLineTop} />
                        )}

                        {!isForgottenPassword && !isForgottenTwoFactorToken && !twoFactor.isEnabled && (
                            <>
                                <form
                                    className={classes.form}
                                    autoComplete="on"
                                    onSubmit={event => {
                                        setFormIsDirty(true);
                                        handleSubmit(event);
                                    }}
                                >

                                    <Input
                                        value={values.username}
                                        onChange={handleChange}
                                        name="username"
                                        placeholder={t('login.userNamePlaceholder', 'Username')}
                                        className={classes.inputSpace}
                                        required
                                        invalid={!!errors.username}
                                        errorMessage={errors.username}
                                        autoComplete="username"
                                        tabindex="0"
                                        autoFocus
                                    />

                                    <Input
                                        value={values.password}
                                        type="password"
                                        onChange={handleChange}
                                        name="password"
                                        placeholder={t('login.passwordPlaceholder', 'Password')}
                                        className={classes.inputSpace}
                                        required
                                        invalid={!!errors.password}
                                        errorMessage={errors.password}
                                        autoComplete="currentPassword"
                                        tabindex="1"
                                    />

                                    <div className={classes.loginButtonContainer}>
                                        <Button submit className={classes.loginButton}>
                                            {t('login.login', 'Log in')}
                                        </Button>
                                    </div>

                                    <div className={classes.footerContainer}>
                                        <div className={classes.langContainer}>
                                            <SelectLanguage
                                                langList={langs}
                                                langCode={langCode}
                                                setLangCode={setLangCode}
                                            />
                                            <button type="button" className={classes.linkButton} onClick={() => setForgottenPassword(true)}>
                                                {t('login.forgotPassword', 'Forgot password?') as any}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </>
                        )}

                        {!isForgottenPassword && !isForgottenTwoFactorToken && twoFactor.isEnabled && (
                            <>
                                <div className={classes.twoFactorContainer}>
                                    <h2 className={classes.twoFactorTitle}>
                                        {t('login.approval', 'Approval of login request') as any}
                                    </h2>
                                    <TwoFactorInput
                                        userId={twoFactor.userId}
                                        verifiedCode={verifiedCode}
                                        setVerifiedCode={setVerifiedCode}
                                    />
                                    <div className={classes.loginButtonContainer}>
                                        <Button className={classes.loginButton} type="secondary" onClick={cancel}>
                                            {t('default.cancel', 'Cancel')}
                                        </Button>
                                    </div>
                                </div>
                                <div className={classes.footerContainer}>
                                    <div className={classes.twoFactorFooterContainer}>
                                        <button type="button" className={classes.linkButton} onClick={() => setForgottenTwoFactorToken(true)}>
                                            {t('login.forgetTwoFactorToken', 'Are you unable to use the app?') as any}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}

                        {isForgottenPassword
                            && <ForgetPassword onBack={() => setForgottenPassword(false)} countryCode={countryCode} />
                        }

                        {isForgottenTwoFactorToken && (
                            <ForgetTwoFactorToken
                                onBack={() => {
                                    setForgottenTwoFactorToken(false);
                                    setTwoFactor({ isEnabled: false, userId: 0 });
                                }}
                                countryCode={countryCode}
                            />
                        )}

                        {isTestSystem && (
                            <div className={classes.testLineBottom} />
                        )}
                    </div>
                </div>
                {(
                    (!companyPermissions.hasLoginAdPermission()) || (systemSubType === SYSTEM.SYSTEM_SUBTYPE.SAVINGS_BANK)
                ) && (
                    <div className={classes.imgContainer} />
                )}
                {(
                    (companyPermissions.hasLoginAdPermission()) && (systemSubType !== SYSTEM.SYSTEM_SUBTYPE.SAVINGS_BANK)
                ) && (                    
                    <div className={classes.dataArrivalImgContainer}>
                        <div className={classes.dataArrivalContainer}>
                            <div className={classes.dataArrivalBody}>
                                <div className={classes.dataArrivalLogoTitle}>
                                    <div>
                                        <img src="/styles/img/subscription/package_20.png" alt="Works" className={classes.dataArrivalWorksLogo} />
                                    </div>
                                    <div>
                                        <Svg iconId="icon-add" style={classes.dataArrivalAdd} />
                                    </div>
                                    <div className={classes.dataArrivalConnectLogoContainer}>
                                        <img src="/styles/img/subscription/package_connect_white.png" alt="Connect" className={classes.dataArrivalConnectLogo} />
                                    </div>
                                </div>
                                <div className={classes.dataArrivalTitle}>
                                    ÚJ ADAT ÉRKEZTETŐ
                                </div>
                                <div className={classes.dataArrivalSubTitle}>
                                    FELÜLET
                                </div>
                                <div className={classes.dataArrivalText}>
                                    Megérkezett az érkeztető új verziója!
                                </div>
                                <div className={classes.dataArrivalSubText}>
                                    Egy helyen elérhetők
                                </div>
                                <div className={classes.dataArrivalSubText}>
                                    a különböző forrásból érkező adatok.
                                </div>
                                <div className={classes.dataArrivalSubText}>
                                    Megújult kezelőfelület a könnyebb átláthatóságért.
                                </div>
                                <div className={classes.dataArrivalTabletContainer} >
                                    <img src="/styles/img/data_arrival_tablet.png" alt="" className={classes.dataArrivalTabletImg} />
                                </div>
                            </div>
                            <div className={classes.dataArrivalFooter}>
                                Az új funkció a "Foglalkoztatás" menüponton belül érhető el.<br />Használatához <span className={classes.dataArrivalWorksColor}><b>WORKS</b></span> vagy <span className={classes.dataArrivalConnectColor}><b>CONNECT</b></span><br />előfizetés szükséges.
                            </div>
                        </div>
                    </div>
                    /*
                    <div className={classes.worksImgContainer}>
                        <div className={classes.worksContainer}>
                            <div className={classes.worksBody}>
                                <div className={classes.worksLogoTitle}>
                                    <img src="/styles/img/subscription/package_20.png" alt="" className={classes.worksAppLogo} />
                                </div>
                                <div className={classes.worksTitle}>
                                    WORKS
                                </div>
                                <div className={classes.worksSubTitle}>
                                    ALKALMAZÁS
                                </div>
                                <div className={classes.worksText}>
                                    Megújult a Works alkalmazásunk!
                                </div>
                                <div className={classes.worksSubText}>
                                    Stabilabb működés, bővebb funkcionalitás.
                                </div>
                                <div className={classes.worksSubText}>
                                    Az új alkalmazást már teljeskörűen
                                </div>
                                <div className={classes.worksSubText}>
                                    az AgroVIR Kft. fejleszti.
                                </div>
                                <div className={classes.worksSmallSubText}>
                                    Válaszd ki az okostelefonoddal kompatibilis szoftverbolt linkjét, ahonnan letöltheted az új alkalmazást.
                                </div>
                                <div className={classes.worksBodyFooter}>
                                    <div>
                                        <a href="https://play.google.com/store/apps/details?id=hu.agrovir.works" target="_blank"><img src="/styles/img/google_play.png" alt="" className={classes.worksStoreLogo} /></a>
                                    </div>
                                    <div>
                                        <a href="https://apps.apple.com/hu/app/agrovir-works/id6470185968?l=hu" target="_blank"><img src="/styles/img/app_store.png" alt="" className={classes.worksStoreLogo} /></a>
                                    </div>
                                </div>
                                <div className={classes.worksBodyFooter}>
                                    <div>
                                        <img src="/styles/img/google_play_qrcode.jpg" alt="" />
                                    </div>
                                    <div>
                                        <img src="/styles/img/app_store_qrcode.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.worksFooter}>
                                Az alkalmazás használatához AgroVIR WORKS előfizetés szükséges.
                            </div>
                        </div>
                    </div>
                    */
                /*
                    <div className={classes.infoImgContainer}>
                        <div className={classes.infoContainer}>
                            <div className={classes.infoTitle}>
                                E-GAZDÁLKODÁSI
                            </div>
                            <div className={classes.infoSubTitle}>
                                NAPLÓ
                            </div>
                            <div className={classes.infoText}>
                                Közvetlen NÉBIH kapcsolat,
                            </div>
                            <div className={classes.infoText}>
                                egyszerű adatszinkronizálás,
                            </div>
                            <div className={classes.infoText}>
                                konszolidált feladás,
                            </div>
                            <div className={classes.infoText}>
                                kevesebb adminisztrációs feladat,
                            </div>
                            <div className={classes.infoText}>
                                kényelmes használat,
                            </div>
                            <div className={classes.infoFooterText}>
                                GYORS, HATÉKONY, AGROVIR<span className={classes.infoColorText}>.</span>
                            </div>
                        </div>
                    </div>*/
                /*<div className={classes.infoImgContainer}>
                        <div className={classes.infoContainer}>
                            <div className={classes.infoTitle}>
                                AGROVIRTUS
                            </div>
                            <div className={classes.infoSubTitle}>
                                AZ EGYETEMI AGRÁRVERSENY
                            </div>
                            <div className={classes.infoText}>
                                Csatlakozz
                            </div>
                            <div className={classes.infoText}>
                                az AgroVIRTUS verseny támogatói köréhez!
                            </div>
                            <div className={classes.infoFooterText}>
                                Bővebb információt az alábbi linken találsz:
                                <div>
                                    <a href="https://www.agrovir.com/HU/tamogatas.html" target="_blank" className={classes.infoColorText}>Tájékoztató oldal megnyitása</a>
                                </div>
                                <div>
                                    <img src="/styles/img/agrovirtus_logo.svg" alt="" className={classes.agrovirtusLogo} />
                                </div>
                            </div>
                        </div>
                    </div>*/
                )}
            </div>
            <Gdpr />
        </>
    );
}
