import { Nullable } from '@flowsCommon/services/baseTypes';
import { isFunction, isNil } from 'lodash';
import { dateTimeUtils } from 'shared/src/modules';
import { useCallback, useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { MapSliderCache, MapSliderDatePicker, MapSliderIntervalMode } from '../MapSliderTypes';

type TProps = {
    calcDateHasDate?: (startDate: Date) => boolean,
    mapSliderMode: MapSliderIntervalMode,
    withDataViewer: boolean,
}

export const useMapSliderCache = ({ calcDateHasDate, mapSliderMode, withDataViewer } : TProps) => {
    const sliderCache = useRef<Nullable<MapSliderCache>>();

    const readMapSliderCache = useCallback((datePicker: MapSliderDatePicker[]) => {
        if (!isNil(sliderCache.current)) {
            const cacheMinMoment = dateTimeUtils.getDateObject(sliderCache.current.min);
            const cacheMaxMoment = dateTimeUtils.getDateObject(sliderCache.current.max);

            const currentMinMoment = dateTimeUtils.getDateObject(datePicker?.[0].date);
            const currentMaxMoment = dateTimeUtils.getDateObject(datePicker?.[datePicker.length - 1].date);

            if (cacheMinMoment.isSame(currentMinMoment) && cacheMaxMoment.isSame(currentMaxMoment)) {
                return sliderCache.current.datePicker;
            }
        }
        if (withDataViewer && isFunction(calcDateHasDate)) {
            const calcDatePicker = datePicker.map(picker => ({
                ...picker,
                hasValue: calcDateHasDate(picker.date),
            }));
            sliderCache.current = {
                min: datePicker?.[0].date,
                max: datePicker?.[datePicker.length - 1].date,
                mode: mapSliderMode,
                datePicker: calcDatePicker,
            };
            return calcDatePicker;
        }

        return datePicker;
    }, [calcDateHasDate, mapSliderMode, withDataViewer]);

    const invalidateMapSiderCache = () => {
        sliderCache.current = null;
    };

    return { readMapSliderCache, invalidateMapSiderCache };
};
