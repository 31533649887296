import { workOperationsManagementActions } from '@states/actions/workOperationsManagementActions';

const defaultNewMaterialMultiGridState = {
    hydrationData: {
        cells: {},
        rows: {},
        auxiliary: {},
    },
    isMultiGridLoaded: false,
};

const initialState = {
    newMaterialMultiGrid: defaultNewMaterialMultiGridState,
};

export function workOperationsManagement(state = initialState, action) {
    switch (action.type) {
        case workOperationsManagementActions.SET_WORKOPERATIONSMANAGEMENT_FILTERS:
            return {
                ...state,
            };
        case workOperationsManagementActions.SET_NEWMATERIALMULTIGRID_HYDRATIONDATA: {
            const { hydrationData } = action.payload;
            return {
                ...state,
                newMaterialMultiGrid: {
                    ...state.newMaterialMultiGrid,
                    hydrationData,
                },
            };
        }
        // TODO: rename - resetting not just hydrationdata
        case workOperationsManagementActions.RESET_NEWMATERIALMULTIGRID_HYDRATIONDATA: {
            return {
                ...state,
                newMaterialMultiGrid: defaultNewMaterialMultiGridState,
            };
        }
        case workOperationsManagementActions.SET_NEWMATERIALMULTIGRID_ISMULTIGRIDLOADED: {
            return {
                ...state,
                newMaterialMultiGrid: {
                    ...state.newMaterialMultiGrid,
                    isMultiGridLoaded: action.payload.isMultiGridLoaded,
                },
            };
        }
        default:
            return state;
    }
}
