import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, clone, isFunction } from 'lodash';
import { SETTINGS } from 'shared/src/constants';

import { errorActions, companyActions } from '@states/actions';
import MultiSelect from '@baseComponents/multiselect/MultiSelect';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    multiSelectSearch: {
        '& .dropdown-toggle': {
            height: 32,
            margin: '2px 0px',
            marginRight: 0,
            paddingRight: 0,

            '& span': {
                fontSize: 12,
                fontWeight: 300,
            },
            '& svg': {
                width: 42,
                height: 28,
                paddingTop: 2,
            },
        },
    },
});

export default function MultiselectSearcher(props) {
    const { column, onChange } = props;
    const { searchDataList, searchEndpointFunction, searchEndpointProps, hasSearchInput = true, hasMultiSelectHeader = true } = column || {};
    const dispatch = useDispatch();
    const [dataList, setDataList] = useState();
    const [isNonAccentSearch] = useState(dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)));

    const classes = useStyles();

    //init default list
    useEffect(() => {
        if (!isEmpty(searchDataList)) {
            setDataList(clone(searchDataList));
        }
    }, [searchDataList]);

    //init default list
    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const endpointProps = !isEmpty(searchEndpointProps) ? searchEndpointProps : {};
                let endpointResult = await searchEndpointFunction(endpointProps);

                if (!isCancelled) {
                    endpointResult = endpointResult.map(resultData => ({ key: resultData.id, value: resultData.name }));
                    setDataList(endpointResult);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'MultiSelectSearcherEndPointCall'));
            }
        }

        if (isFunction(searchEndpointFunction)) {
            loadData();
        }
        return () => {
            isCancelled = true;
        };
    }, [searchEndpointFunction, searchEndpointProps, dispatch]);

    //on changed value - start filtering
    function onChangeMultiSelect(selectedIds) {
        const ids = selectedIds;

        if (isEmpty(ids)) {
            onChange(null);
        }

        onChange(ids);
    }

    function onClick(event) {
        event.stopPropagation();
    }

    return (
        <div onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
            <MultiSelect
                isNonAccentSearch={isNonAccentSearch}
                dataList={dataList}
                hasSearchInput={hasSearchInput}
                hasMultiSelectHeader={hasMultiSelectHeader}
                className={classes.multiSelectSearch}
                onChange={onChangeMultiSelect}
                useAbsoluteMenu
            />
        </div>
    );
}
