import React, { useEffect, useState, forwardRef } from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import { isEmpty } from 'lodash';
import { stringUtils } from 'shared/src/modules';
import MultiSelectHeader from './MultiSelectHeader';
import SearchBar from '../inputs/SearchBar';

const useStyles = createUseStyles((theme: any) => ({
    customMenuSearchInput: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 5,
        marginBottom: 5,
        boxSizing: 'border-box',
        width: 'auto',
    },
    dropdownListWrapper: {
        margin: 0,
        padding: 0,
        '& .dropdown-header': {
            fontSize: '1rem',
            marginBottom: '0.5rem',
            fontWeight: 600,
            paddingLeft: 5,
            paddingRight: 5,
        },
        '& .dropdown-item': {
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingTop: '0.25rem',
            paddingBottom: '0.25rem',
            boxSizing: 'border-box',
            display: 'flex',
            height: '2rem',
            lineHeight: '2rem',
            alignItems: 'center',
            textDecoration: 'none',
            fontWeight: 400,

            '&.hidden': {
                display: 'none',
            },
            '&.disabled': {
                display: 'hidden',
                cursor: 'default',
                color: theme.color.gray,
            },

            '&:hover:not(.disabled)': {
                background: `rgba(${theme.color.main}, 15%)`,
                cursor: 'pointer',
            },
        },

        overflow: 'hidden',
        overflowY: 'auto',
        ...theme.verticalScrollbar,
    },
    noData: {
        padding: 10,
        fontSize: 14,
    },
}));

export const CustomMenuForward = forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy, hasSearchInput, isOpened, hasMultiSelectHeader, selectAll, clearSelection, isNonAccentSearch }, ref) => {
        const [searchValue, setSearchValue] = useState('');
        const { t } = useTranslate();
        const classes = useStyles();

        //reset search input
        useEffect(() => {
            if (!isOpened) {
                setSearchValue('');
            }
        }, [isOpened]);

        function getFilteredChild(child) {
            const mainValue = child.props?.text?.toLowerCase();
            const secondValue = child.props?.second_value?.toLowerCase();
            const thirdValue = child.props?.third_value?.toLowerCase();

            return !searchValue/* || child.props.role === 'heading' || child.props.role === 'separator'*/
                || ((stringUtils.includesNoCase(mainValue, searchValue, isNonAccentSearch) || stringUtils.includesNoCase(secondValue, searchValue, isNonAccentSearch) || stringUtils.includesNoCase(thirdValue, searchValue, isNonAccentSearch)));
        }

        function getFilteredChildren() {
            const filteredChildren = React.Children.toArray(children)?.filter(child => getFilteredChild(child));

            if (isEmpty(filteredChildren)) {
                return (<div className={classes.noData}>{t('default.noData', 'No data')}</div>);
            }

            return filteredChildren;
        }

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                {hasSearchInput
                    && <SearchBar
                        currentSearchValue={searchValue}
                        className={classes.customMenuSearchInput}
                        onChange={searchInputValue => setSearchValue(searchInputValue)}
                        height={34}
                    />}

                {hasMultiSelectHeader
                    && <MultiSelectHeader
                        selectAll={selectAll}
                        clearSelection={clearSelection}
                    />}

                <ul className={classes.dropdownListWrapper}>
                    {getFilteredChildren()}
                </ul>
            </div>
        );
    },
);
