/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef } from 'react';
import { Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { isEmpty, isFunction } from 'lodash';
import clsx from 'clsx';
import { languageUtils } from 'shared/src/modules/languageUtils';
import Svg from '@baseComponents/Svg';
import Button from '@baseComponents/buttons/Button';

const useStyles = createUseStyles((theme: any) => ({
    otherFunctionCellWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        cursor: 'default',
    },
    popover: {
        border: 'none',
        maxWidth: 'fit-content',
        '& .popover-body': {
            padding: 0,
        },
        '& .arrow::before': {
            borderColor: 'transparent',
            borderLeftColor: props => (props.placement === 'left' ? theme.color.white : 'transparent'),
            borderRightColor: props => (props.placement !== 'left' ? theme.color.white : 'transparent'),
            borderWidth: 8,
            top: -10,
            left: props => (props.placement === 'left' ? 4 : -20),
        },
        '& .arrow': {
            borderLeftColor: theme.color.red,
        },
        zIndex: theme.zIndex.popover,
    },
    dropdownMenuWrapper: {
        borderRadius: 6,
        padding: 0,
    },
    dropdownMenuItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        minWidth: 250,
        fontSize: '15px',
        fontWeight: 'normal',
        lineHeight: '1.33',
        color: theme.color.jet,
        outline: 'none',
        '&:hover': {
            backgroundColor: theme.color.opacity.main,
            color: theme.color.main,
            cursor: 'pointer',
            '& $menuItemIcon': {
                fill: theme.color.main,
            },
        },
        '&.disabled': {
            opacity: 1,
            backgroundColor: theme.color.white,
            color: theme.color.gray,
            '&:hover': {
                backgroundColor: theme.color.white,
                cursor: 'default',
                color: theme.color.gray,
            },
        },
        '&:first-child': {
            '&:hover': {
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
            },
        },
        '&:last-child': {
            '&:hover': {
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
            },
        },
    },
    dropdownMenuItemDestructive: {
        color: theme.color.destructive,
        backgroundColor: theme.color.white,
        outline: 'none',
        '&:hover': {
            color: theme.color.destructive,
            backgroundColor: theme.color.destructiveFaded,
            cursor: 'pointer',
            '& $menuItemIcon': {
                fill: theme.color.destructive,
            },
        },
    },
    menuItemIcon: {
        width: 20,
        height: 20,
        marginRight: 10,
    },
    disabledIcon: {
        opacity: 0.5,
        fill: [theme.color.jet, '!important'],
    },
    destructiveIcon: {
        fill: theme.color.destructive,
    },
    otherFunctionButton: {
        width: 50,
        maxWidth: (props: any) => props.maxWidth ?? 'initial',
        maxHeight: (props: any) => props.maxHeight ?? 'initial',
        '& div': {
            padding: 0,
        },
        '& svg': {
            marginRight: 0,
        },
    },
}));

export default function OtherFunctionsCell(props) {
    const { itemList, rowData, placement = 'left', maxWidth, maxHeight } = props;
    const { t } = useTranslation();
    const dropdownCellRef = useRef();
    const classes = useStyles({ placement, maxWidth, maxHeight });

    useEffect(() => {
        if (dropdownCellRef && !isEmpty(dropdownCellRef.current)) {
            dropdownCellRef.current.parentElement.style.overflow = 'visible';
            dropdownCellRef.current.parentElement.style.paddingLeft = 0;
        }
    }, [dropdownCellRef]);
    //init
    useEffect(() => {
        if (rowData && itemList) {
            rowData.otherFunctionList = itemList;
        }
    }, [rowData, itemList]);

    function getClassName(menuItem) {
        if (menuItem.type && menuItem.type === 'destructive') {
            return clsx(classes.dropdownMenuItem, classes.dropdownMenuItemDestructive);
        }
        return classes.dropdownMenuItem;
    }

    function onClickMenuItem(event, menuItem) {
        document.body.click();
        event.stopPropagation();
        if (menuItem && (!isFunction(menuItem.isDisabled) || !menuItem.isDisabled(rowData)) && menuItem.onClick) {
            menuItem.onClick(rowData, event);
        }
    }

    function checkIsDisabled(menuItem) {
        if (menuItem.isDisabled) {
            return menuItem.isDisabled(rowData);
        }
        return false;
    }

    function checkIsDestructive(menuItem) {
        return menuItem.type && menuItem.type === 'destructive';
    }

    function ignoreRowSelection(event) {
        document.body.click();
        event.stopPropagation();
    }

    return (
        <div className={classes.otherFunctionCellWrapper} ref={dropdownCellRef} onClick={ignoreRowSelection} role="button" tabIndex="0">
            <OverlayTrigger
                trigger="click"
                placement={placement ?? 'left'}
                rootClose
                overlay={
                    <Popover className={classes.popover} arrowProps={{ style: { background: 'red' } }}>
                        <Popover.Content>
                            <div className={classes.dropdownMenuWrapper}>
                                {!isEmpty(itemList) && itemList.map((menuItem, index) => (
                                    <Dropdown.Item
                                        key={index}
                                        eventKey={index}
                                        className={getClassName(menuItem)}
                                        disabled={checkIsDisabled(menuItem)}
                                        onClick={event => onClickMenuItem(event, menuItem)}
                                        title={languageUtils.getTitle(t, menuItem.title)}
                                    >
                                        {menuItem.iconId
                                            && <Svg iconId={menuItem.iconId} style={clsx(classes.menuItemIcon, checkIsDestructive(menuItem) && classes.destructiveIcon, checkIsDisabled(menuItem) && classes.disabledIcon)} />
                                        }
                                        {menuItem.title}
                                    </Dropdown.Item>
                                ))}
                            </div>
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button
                    className={classes.otherFunctionButton}
                    size="small"
                    type="secondary"
                    iconId="icon-more"
                    title={t('default.functions', 'Functions')}
                />
            </OverlayTrigger>
        </div>
    );
}
