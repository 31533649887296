/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { Dropdown } from 'react-bootstrap';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { isEmpty, isArray } from 'lodash';
import { theme as themes } from '@styles/theme';
import SearchDropDown from './SearchDropDown';
import AddNewButton from './AddNewButton';

import DropDownItem from './DropDownItem';

const useStyles = createUseStyles((theme: any) => ({
    absoluteDropdown: {
        top: '100%',
        width: 320,
        margin: 0,
        fontSize: '1rem',
        color: theme.color.jet,
        textAlign: 'left',
        listStyle: 'none',
        backgroundColor: theme.color.white,
        background: theme.color.white,
        borderRadius: 4,
        border: '1px solid rgba(227, 230, 232, 0.5)',
        boxShadow: theme.shadows.selectDropdown,
        padding: '5px 0px 5px 0px',
        zIndex: theme.zIndex.multiselect,

        maxHeight: 500,
        overflow: 'hidden',
        display: 'flex !important',
        //display: 'none',
        flexDirection: 'column',
        paddingBottom: 0,

        '&.show': {
            display: 'block',
            opacity: '1 !important',
            transform: 'translate(-9999px)',
        },
    },
    selectDropdown: {
        padding: 0,
        zIndex: theme.zIndex.multiselect,
        background: theme.color.white,
        borderRadius: 4,
        border: '1px solid rgba(227, 230, 232, 0.5)',
        boxShadow: theme.shadows.selectDropdown,
        maxHeight: 350,
        width: (props: any) => (!props.useAbsoluteMenu ? '100%' : 320),
        overflow: 'hidden',
        display: 'flex !important',
        flexFlow: 'column',
    },
    dropdownList: {
        minHeight: 'inherit',
        height: (props: any) => {
            if (props.dataListLength) {
                const searchHeight = props.hasSearch ? theme.subMenu.buttonHeight + 10 : 0;
                const addNewHeight = props.hasAddNew ? theme.subMenu.buttonHeight : 0;
                const height = 350 - searchHeight - addNewHeight;
                return props.dataListLength > 350 ? height : props.dataListLength;
            }
            return theme.subMenu.buttonHeight;
        },
        zIndex: theme.zIndex.multiselect,
    },
    scrollBar: {
        ...theme.verticalScrollbar,
    },
}));

export default function SelectDropDown(props) {
    const { useAbsoluteMenu, dataList, selectedItem, addNew, search, isOpened, emptyOption = null, alignRight = false, className } = props;
    const [currentDataList, setCurrentDataList] = useState<any>([]);
    const classes = useStyles({
        dataListLength: (((dataList?.length || 0) * themes.subMenu.buttonHeight + (!isEmpty(emptyOption) ? themes.subMenu.buttonHeight : 0))),
        hasSearch: !!search,
        hasAddNew: !!addNew,
        useAbsoluteMenu,
    } as any);

    useEffect(() => {
        if (emptyOption && isArray(dataList)) {
            setCurrentDataList([emptyOption, ...dataList]);
        } else {
            setCurrentDataList(dataList);
        }
    }, [emptyOption, dataList]);

    return (
        <>
            {useAbsoluteMenu
                ? (
                    ReactDOM.createPortal(
                        <div onClick={event => event.stopPropagation()} onKeyDown={event => event.stopPropagation()} role="button" tabIndex={0}>
                            <Dropdown.Menu className={clsx(classes.absoluteDropdown, className)} alignRight={alignRight}>
                                {search && <SearchDropDown search={search} isOpened={isOpened} />}

                                {!isEmpty(currentDataList)
                                    && (
                                        <div className={classes.dropdownList} id="selectDropdownList">
                                            <AutoSizer>
                                                {({ height, width }) => (
                                                    <List
                                                        height={height}
                                                        width={width}
                                                        itemCount={currentDataList.length}
                                                        itemSize={themes.subMenu.buttonHeight}
                                                        className={classes.scrollBar}
                                                    >
                                                        {({ index, style }) => (
                                                            <div key={index} style={style}>
                                                                <DropDownItem
                                                                    key={index}
                                                                    dataKey={currentDataList[index].key}
                                                                    dataValue={currentDataList[index].value}
                                                                    iconId={currentDataList[index]?.iconId}
                                                                    iconColor={currentDataList[index]?.iconColor}
                                                                    isDisabled={currentDataList[index]?.isDisabled || false}
                                                                    selectedItem={selectedItem}
                                                                />
                                                            </div>
                                                        )}
                                                    </List>
                                                )}
                                            </AutoSizer>
                                        </div>
                                    )}
                                {addNew && <AddNewButton addNew={addNew} />}
                            </Dropdown.Menu>
                        </div>,
                        document.getElementById('dynamicDropdownMenuWrapper'),
                    )
                ) : (
                    <Dropdown.Menu className={clsx(classes.selectDropdown, className)} alignRight={alignRight}>
                        {search && <SearchDropDown search={search} isOpened={isOpened} />}

                        {!isEmpty(currentDataList)
                            && (
                                <div className={classes.dropdownList} id="selectDropdownList">
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                height={height}
                                                width={width}
                                                itemCount={currentDataList.length}
                                                itemSize={themes.subMenu.buttonHeight}
                                                className={classes.scrollBar}
                                            >
                                                {({ index, style }) => (
                                                    <div key={index} style={style}>
                                                        <DropDownItem
                                                            key={index}
                                                            dataKey={currentDataList[index].key}
                                                            dataValue={currentDataList[index].value}
                                                            iconId={currentDataList[index]?.iconId}
                                                            iconColor={currentDataList[index]?.iconColor}
                                                            selectedItem={selectedItem}
                                                        />
                                                    </div>
                                                )}
                                            </List>
                                        )}
                                    </AutoSizer>
                                </div>
                            )}
                        {addNew && <AddNewButton addNew={addNew} />}
                    </Dropdown.Menu>
                )
            }
        </>
    );
}
