import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const productService = {
    getProducts,
    getProductTypeList,
    getProductsNotFromStock,
    getProductUnits,
};

/**
 * Get all products
 * @property {List<int>} companyIds IsOptional - Company ids array fe.: [1]
 * @property {int} productStatus IsOptional - Product status fe.: 1
 * @property {boolean} isNutrient
 * @property {boolean} isPlantProtection
 * @property {boolean} showPesticideProduct
 * INACTIVE: 0
 * ACTIVE: 1
 * ALL: 2
 * Default: 1
 * @property {List<int>} typeIds IsOptional - Type ids array fe.: [1]
 * @return {array} Products array
 */
async function getProducts(props) {
    const url = routerHelper.getUrlWithParameters('/api/products', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get all product types datas
 * @return {array} Product types array
 */
async function getProductTypeList() {
    const response = await sendServerRequest('GET', '/api/productType');
    return response;
}

async function getProductsNotFromStock(props) {
    //companyIds, typeIds, pricingMode, isActiveAndInactive, isRecordsInTreatmentUnit, treatmentUnitIds, status
    const url = routerHelper.getUrlWithParameters('/api/productsNotFromStock', props || {});
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get all product units
 * @property {int} statusId IsOptional - Unit status
 * 1 = active
 * 2 = inactive
 * 3 = all
 * default: 1
 * @return {array} Product units array
 */
async function getProductUnits(props) {
    const response = await sendServerRequest('GET', routerHelper.getUrlWithParameters('/api/productUnits', props));
    return response;
}
