/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import InputMask from 'react-input-mask';
import { isEmpty, isUndefined, isNumber, isNull, isFunction } from 'lodash';
import { numberUtils } from 'shared/src/modules';
import { formUtils } from '@utils/form/formUtils';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    disableOutline: {
        ...theme.disableOutline,
    },
    container: {
        display: 'flex',
        height: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 34;
                case 'large':
                    return 44;
                default:
                    return 40;
            }
        },
        backgroundColor: theme.color.white,
        border: 'solid 1px',
        borderColor: (props: any) => (props.invalid ? theme.color.destructive : theme.color.gray),
        borderRadius: 4,
        padding: '0px 15px',
        boxShadow: (props: any) => (props.invalid ? '0 3px 5px 0 rgba(255, 0, 85, 0.15)' : '0 2px 3px 0 rgba(175, 180, 182, 0.15)'),
        '&:focus-within': {
            boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15)',
            border: `1px solid ${theme.color.main}`,
        },
        opacity: (props: any) => props.disabled && 0.4,
    },
    input: {
        width: '100%',
        fontSize: 15,
        fontWeight: 300,
        marginTop: 1,
        padding: 0,
        paddingRight: (props: any) => (props.type === 'date' ? 0 : 15),
        color: (props: any) => (props.invalid ? [theme.color.destructive, '!important'] : theme.color.jet),
        height: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 30;
                case 'large':
                    return 40;
                default:
                    return 36;
            }
        },
        border: 'none',
        outline: 'none',
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            MozAppearance: 'none',
            margin: 0,
        },
        MozAppearance: 'textfield',
        backgroundColor: (props: any) => props.disabled && theme.color.white,
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        color: theme.color.destructive,
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: (props: any) => (props.value ? theme.color.main : theme.color.destructive),
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    unit: {
        color: theme.color.stone,
        alignItem: 'center',
        margin: 'auto',
        fontSize: 15,
        fontWeight: 300,
        lineHeight: 1.33,
        marginLeft: -10,
    },
    seePassword: {
        backgroundColor: theme.color.white,
        border: 'none',
        float: 'right',
    },
    icon: {
        width: 24,
        height: 24,
        objectFit: 'contain',
        fill: theme.color.stone,
    },
    datepicker: {
        float: 'right',
        alignItem: 'center',
        margin: 'auto',
    },
}));

function Input(props, ref) {
    const {
        placeholder, type, value, name, size, unit, errorMessage, label, required, className, onChange, invalid, mask, autoComplete = 'off',
        disabled, onBlur, onKeyDown, onClick, autoFocus, tabindex, onFocus, maxLength,
    } = props;
    const inputRef = useRef<any>();
    const [currentType, setCurrentType] = useState(type);
    const placeHolder = type === 'number' ? 0 : placeholder;
    const classes = useStyles({ type, size, unit, value, invalid, disabled } as any);

    useEffect(() => {
        if (autoFocus && !isEmpty(inputRef.current)) {
            inputRef.current.focus();
        }
    }, [autoFocus, inputRef]);

    function showPassword() {
        if (currentType === 'password') {
            setCurrentType('text');
        } else {
            setCurrentType('password');
        }
    }

    function disableScroll() {
        if (inputRef.current) {
            inputRef.current.blur();
        }
    }

    function getInputValue() {
        if ((!isUndefined(value) && !isNull(value)) && (value || (type === 'number' && numberUtils.isNumber(value)))) {
            return value;
        }
        return '';
    }

    function onChangeValue(event) {
        if (isFunction(onChange)) {
            if (currentType === 'number') {
                const inputValue = Number(event.target.value);
                if (inputValue >= 0) {
                    onChange(event);
                }
            } else {
                onChange(event);
            }
        }
    }

    return (
        <div className={clsx(className, classes.disableOutline)} onClick={onClick} role="button" onFocus={() => { if (isNumber(+tabindex)) { inputRef?.current?.focus(); } }}>
            {label && (
                <div className={classes.label}>
                    {label}
                    {required && <span className={classes.dot} />}
                </div>
            )}
            <div className={classes.container}>
                {type === 'date' ? (
                    <InputMask
                        className={classes.input}
                        mask={mask}
                        maskChar="_"
                        name={name}
                        placeholder={placeHolder}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                    />
                ) : (
                    <input
                        name={name}
                        className={classes.input}
                        type={currentType}
                        placeholder={placeHolder}
                        value={value && getInputValue()}
                        onChange={onChangeValue}
                        autoComplete={autoComplete}
                        onKeyPress={event => currentType === 'number' && formUtils.preventNonNumericalInput(event)}
                        ref={ref || inputRef}
                        onWheel={disableScroll}
                        onBlur={onBlur}
                        disabled={disabled}
                        onKeyDown={onKeyDown}
                        onFocus={onFocus}
                        tabIndex={tabindex}
                        maxLength={maxLength}
                    />
                )}
                {(type === 'number' || type === 'decimal') && unit && (
                    <div className={classes.unit}>
                        {unit}
                    </div>
                )}
                {type === 'password' && (
                    <button type="button" className={classes.seePassword} onClick={showPassword}>
                        {currentType === 'password'
                            ? <Svg style={classes.icon} iconId="icon-visibility_show" />
                            : <Svg style={classes.icon} iconId="icon-visibility_hide" />}
                    </button>
                )}
            </div>
            {invalid && (size === 'large' || isUndefined(size)) && errorMessage && (
                <div className={classes.errorMessage}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
}

export default React.forwardRef(Input);
