/* eslint-disable id-match */
import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import OutsideClickHandler from 'react-outside-click-handler';
import { validationUtils } from 'shared/src/modules/validationUtils';
import useTranslate from '@i18n/useTranslate';
import { errorActions } from '@states/actions/errorActions';
import { alertActions } from '@states/actions/alertActions';
import { userActions } from '@states/actions/userActions';
import { userService } from '@services';
import Button from '@baseComponents/buttons/Button';
import Input from '@baseComponents/inputs/Input';
import DarkMode from '../darkMode/DarkMode';
import ChangePassword from './ChangePassword';
import Activation2FA from './Activation2FA';

const useStyles = createUseStyles((theme: any) => ({
    editProfile: {
        padding: [0, 30],
        position: 'absolute',
        left: (props: any) => (props.expanded ? 240 : 60),
        height: '100vh',
        top: 0,
        width: 360,
        boxShadow: `inset 2px 0 0 0 ${theme.color.lilian}`,
        display: (props: any) => (props.showEditProfile ? 'block' : 'none'),
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 1.43,
        color: theme.color.jet,
        background: theme.color.white,
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '1.67',
        color: theme.color.jet,
        paddingTop: 20,
        marginBottom: 30,
    },
    pictureBtns: {
        marginBottom: 70,
    },
    upload: {
        float: 'left',
        cursor: 'pointer',
    },
    uploadBtn: {
        position: 'absolute',
    },
    deleteBtn: {
        float: 'right',
    },
    form: {
        height: 'calc(100vh - 235px)',
        overflow: 'hidden auto',
        scrollbarWidth: 'thin !important',
    },
    inputs: {
        marginTop: 15,
    },
    changePassword: {
        marginTop: 25,
        height: 'auto',
        '& div': {
            height: 'auto',
            padding: [3, 0],
            whiteSpace: 'wrap',
        },
    },
    activation2FA: {
        marginTop: 10,
        height: 'auto',
        '& div': {
            height: 'auto',
            padding: [3, 0],
            whiteSpace: 'wrap',
        },
    },
    btnGroup: {
        paddingTop: 25,
        paddingBottom: 25,
        width: 300,
        display: (props: any) => (props.showChangePassword || props.showActivation2FA ? 'none' : 'flex'),
        borderBottom: 'solid 1px',
        borderBottomColor: theme.color.pearl,
    },
    saveBtn: {
        width: 300,
    },
    footer: {
        bottom: 0,
        position: 'fixed',
        paddingBottom: 25,
        width: 300,
        display: (props: any) => (props.showChangePassword || props.showActivation2FA ? 'none' : 'flex'),
        justifyContent: 'center',
    },
    profilPicture: {
        height: [260, '!important'],
        width: [300, '!important'],
        borderRadius: '4px',
        border: 'solid 1px',
        borderColor: theme.color.pearl,
        backgroundColor: theme.color.lilian,
        marginBottom: 15,
    },
    sliderContainer: {
        position: 'absolute',
        height: 30,
        width: 160,
        padding: [13, 15],
        background: theme.color.white,
        borderRadius: 15,
        marginTop: -65,
        marginLeft: 70,
    },
    slider: {
        width: 130,
    },
    fileUpload: {
        display: 'none !important',
    },
}));

export default function EditProfile(props) {
    const { showEditProfile, setShowEditProfile, closeEditProfile, expanded } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const user = useSelector((state: any) => state.user.user);
    const [twoFactorStatus, setTwoFactorStatus] = useState(Boolean(user?.twoFactorEnabled) || false);
    const [userData, setUserData] = useState<any>({});
    const [isValidData, setValidData] = useState<any>({});
    const isActiveDarkMode = user.darkMode;
    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showActivation2FA, setShowActivation2FA] = useState(false);
    const classes = useStyles({ showEditProfile, expanded, showChangePassword, showActivation2FA } as any);

    function setTempData(userObj) {
        const tempUser = {
            fullName: userObj.name,
            userName: userObj.userName,
            email: userObj.email,
        };
        const tempValidate = {
            fullName: true,
            userName: true,
            email: true,
        };

        setValidData({ ...tempValidate });
        setUserData({ ...tempUser });
    }

    useEffect(() => {
        if (user) {
            setTempData(user);
        }
    }, [user]);

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const change = userData;
        change[name] = value;
        setUserData({ ...userData, [name]: value });
    }

    function cancel() {
        setTempData(user);
        closeEditProfile();
    }

    //TODO: finish profile picture maker and upload
    //Picture upload
    /*const fileInput = useRef(null);

    const handleClick = () => {
        fileInput.current.click();
    };

    //slider
    const [sliderValue, setSliderValue] = useState(15);

    function handleSliderChange(event) {
        setSliderValue(event.target.value);
    }*/

    //Change password modal
    async function handleSubmit(event) {
        event.preventDefault();
        try {
            setValidData({
                fullName: userData.fullName.length > 0,
                userName: userData.userName.length > 0,
                email: validationUtils.isValidEmail(userData.email),
            });
            if (userData.fullName.length > 0 && userData.userName.length > 0 && validationUtils.isValidEmail(userData.email)) {
                const successUpdate = await userService.setUserDatas(userData.fullName, userData.userName, userData.email);
                if (successUpdate) {
                    dispatch(alertActions.successAlert(t('personalDataModal.successDataChange', 'You successfully set your new user data!')));
                    dispatch(userActions.updateUser());
                    closeEditProfile();
                } else {
                    dispatch(alertActions.errorAlert(t('personalDataModal.unSuccesDataChange', 'Unsuccessfully user data change!')));
                }
            }
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }

    async function setTwoFactorDisabled() {
        try {
            const success = await userService.setTwoFactorStatus(false);
            if (success) {
                dispatch(userActions.updateUser());
                setTwoFactorStatus(false);
            }
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    }

    return (
        <OutsideClickHandler onOutsideClick={() => setShowEditProfile(false)}>
            <form className={classes.editProfile} onSubmit={handleSubmit}>
                <h1>{t('editProfile.editProfileData', 'Edit profile')}</h1>
                {/*TODO:  finish profile picture editor
                <AvatarEditor
                    className={editProfileClasses.profilPicture}
                    image="/styles/img/login_bg.jpg"
                    width={150}
                    height={150}
                    border={[20, 20]}
                    color={[255, 255, 255, 0.25]} // RGBA
                    scale={sliderValue / 10}
                    rotate={0}
                    borderRadius={1}

                />
                <div className={editProfileClasses.sliderContainer}>
                    <Slider
                        value={sliderValue}
                        min={10}
                        max={20}
                        className={editProfileClasses.slider}
                        onChange={handleSliderChange}
                    />
                </div>
                <div className={editProfileClasses.pictureBtns}>
                    <span className={editProfileClasses.upload}>
                        <Button className={editProfileClasses.uploadBtn} type="secondary" size="small" iconId="icon-add" iconPosition="before" onClick={handleClick}>
                            {t('editProfile.uploadOther', 'Upload other')}
                        </Button>
                        <input ref={fileInput} className={editProfileClasses.fileUpload} type="file" name="myImage" accept="image/x-png,image/gif,image/jpeg" />
                    </span>
                    <Button className={editProfileClasses.deleteBtn} type="destructive" size="small" iconId="icon-bin" iconPosition="before">
                        {t('editProfile.delete', 'Delete')}
                </Button>
                </div>*/}
                <DarkMode isActiveDarkMode={isActiveDarkMode} />
                <div className={classes.form}>
                    <Input
                        className={classes.inputs}
                        type="text"
                        label={t('editProfile.fullName', 'Full name')}
                        name="fullName"
                        value={userData.fullName}
                        onChange={handleChange}
                        invalid={!isValidData.fullName}
                        errorMessage={t('editProfile.fullNameRequired', 'Full name is required')}
                        required
                    />
                    <Input
                        className={classes.inputs}
                        type="text"
                        label={t('editProfile.userName', 'User name')}
                        name="userName"
                        value={userData.userName}
                        onChange={handleChange}
                        invalid={!isValidData.userName}
                        errorMessage={t('editProfile.userNameRequired', 'User name is required')}
                        required
                    />
                    <Input
                        className={classes.inputs}
                        type="text"
                        label={t('editProfile.emailAddress', 'Email address')}
                        name="email"
                        value={userData.email}
                        onChange={handleChange}
                        invalid={!isValidData.email}
                        errorMessage={t('editProfile.invalidEmail', 'Invalid email address')}
                        required
                    />
                    <div className={classes.btnGroup}>
                        <Button className={classes.saveBtn} size="small" submit>
                            {t('default.save', 'Save')}
                        </Button>
                    </div>
                    <Button className={classes.changePassword} type="secondary" size="small" iconId="icon-arrow_forward" iconPosition="after" onClick={() => setShowChangePassword(true)} fill>
                        {t('editProfile.changePassword', 'Change password')}
                    </Button>
                    <Button
                        className={classes.activation2FA}
                        type={twoFactorStatus ? 'destructive' : 'secondary'}
                        size="small"
                        onClick={() => {
                            if (twoFactorStatus) {
                                setTwoFactorDisabled();
                            } else {
                                setShowActivation2FA(true);
                            }
                        }}
                        iconId={twoFactorStatus ? null : 'icon-arrow_forward'}
                        iconPosition={twoFactorStatus ? null : 'after'}
                        fill
                    >
                        {twoFactorStatus
                            ? t('2fa.disableAuth', 'Disable Two-Factor Authentication')
                            : t('2fa.enableAuth', 'Enable Two-Factor Authentication')
                        }
                    </Button>
                </div>
                <div className={classes.footer}>
                    <Button type="secondary" size="large" onClick={cancel}>
                        {t('default.close', 'Close')}
                    </Button>
                </div>
                <ChangePassword
                    showChangePassword={showChangePassword}
                    setShowChangePassword={setShowChangePassword}
                    expaneded={expanded}
                />
                <Activation2FA
                    showActivation2FA={showActivation2FA}
                    setShowActivation2FA={setShowActivation2FA}
                    setTwoFactorStatus={setTwoFactorStatus}
                    expaneded={expanded}
                />
            </form>
        </OutsideClickHandler>
    );
}
