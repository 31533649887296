import React, { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    tabWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
    },
    tabContent: {
        width: '100%',
        height: '100%',
        display: 'block',
        overflow: 'hidden',
    },
    tabContentWithError: {
        //marginTop: -20,
    },
    errorText: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        fontSize: 12,
        fontWeight: 500,
        color: theme.color.statusColor.error,
    },
    hide: {
        display: 'none',
    },
}));

function ModalRightContents(props) {
    const { selectedHeaderTitleKey, rightContent, errorMessage, errorTabKey } = props;
    const classes = useStyles();

    const getIsVisibleClassName = useCallback(key => key !== selectedHeaderTitleKey && classes.hide, [classes.hide, selectedHeaderTitleKey]);

    return (
        <>
            {rightContent?.map((content, index) => (
                <div className={clsx(classes.tabWrapper, 'tabWrapper')} key={index} style={{ display: content.key === selectedHeaderTitleKey ? 'block' : 'none' }}>
                    {!isEmpty(errorMessage) && errorTabKey === content.key ? (
                        <div className={classes.errorText}>
                            {errorMessage}
                        </div>
                    ) : null}
                    <div className={clsx(classes.tabContent, getIsVisibleClassName(content.key), !isEmpty(errorMessage) && classes.tabContentWithError)}>
                        <content.component {...content.props} />
                    </div>
                </div>
            ))}
        </>
    );
}

export default React.memo(ModalRightContents);
