/* eslint-disable no-nested-ternary */
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    input: {
        minHeight: (props: any) => !props.children && 60,
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: (props: any) => (props.type === 'dashed' ? 6 : 4),
        border: (props: any) => (props.type === 'dashed' ? '1px dashed' : '1px solid'),
        backgroundColor: (props: any) => (props.type === 'dashed' ? 'transparent' : theme.color.white),
        borderColor: (props: any) => (props.invalid ? theme.color.destructive : !props.disabled ? theme.color.gray : theme.color.pearl),
        color: theme.color.jet,
        fill: theme.color.jet,
        outline: 'none',
        padding: 0,
        '&:active': {
            boxShadow: (props: any) => (!props.disabled && theme.shadows.primary.focus),
            borderColor: (props: any) => (!props.disabled && theme.color.main),
        },
        '&:hover': {
            boxShadow: (props: any) => (!props.disabled && theme.shadows.primary.focus),
            borderColor: (props: any) => (!props.disabled && theme.color.main),
            color: (props: any) => (!props.disabled && theme.color.main),
            '& svg': {
                fill: (props: any) => (!props.disabled && theme.color.main),
            },
            '& [class^="baseStyle"]': {
                color: (props: any) => (!props.disabled && theme.color.main),
            },
        },
    },
    title: {
        textTransform: 'uppercase',
        fontSize: 13,
        lineHeight: 1.54,
        fontWeight: 'bold',
        textAlign: 'center',
        marginLeft: 10,
    },
    icon: {
        width: 20,
        height: 20,
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        color: theme.color.destructive,
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: (props: any) => (props.children ? theme.color.main : theme.color.destructive),
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
}));

export default function DataInput(props) {
    const { type, title, label, children, className, onClick, required, invalid, errorMessage, disabled } = props;
    const classes = useStyles({ type, children, invalid, disabled });

    return (
        <div className={clsx('dataInputWrapper', className)}>
            {!!label && (
                <div className={classes.label}>
                    {label}
                    {required && <span className={classes.dot} />}
                </div>
            )}

            <button className={classes.input} onClick={onClick} type="button" disabled={disabled}>
                {children || (
                    <>
                        <Svg iconId="icon-add" style={classes.icon} />
                        <div className={clsx(classes.title, 'title')}>{title}</div>
                    </>
                )}
            </button>

            {invalid && errorMessage
                ? (
                    <div className={classes.errorMessage}>
                        {errorMessage}
                    </div>
                ) : null}
        </div>
    );
}
