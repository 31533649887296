import { isNil, size } from 'lodash';
import { modalActions } from '../actions/modalActions';

const initialState = {
    components: [],
    hasPreventCloseDialog: false,
};

export function modal(state = initialState, action) {
    switch (action.type) {
        case modalActions.MODAL_ADD_NEW_MODAL_ITEM: {
            const { component } = action.payload;
            return {
                ...state,
                components: [...state.components, component],
            };
        }
        case modalActions.MODAL_REMOVE_MODAL_ITEM: {
            const { component } = action.payload;
            const newComponentList = isNil(component) ? (state?.components?.filter((_, index) => index !== size(state?.components) - 1)) : 
                (state?.components?.filter(stateComponent => stateComponent !== component));
            return {
                ...state,
                components: newComponentList,
            };
        }
        case modalActions.SET_PREVENT_CLOSE_DIALOG: {
            const { hasPreventCloseDialog } = action.payload;
            return {
                ...state,
                hasPreventCloseDialog,
            };
        }
        default:
            return state;
    }
}
