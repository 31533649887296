import { useEffect } from 'react';

export default function useCalendarScroll(props) {
    const { scrollRef, closeCalendar, isCalendarOn } = props;

    useEffect(() => {
        function removeScrollEvent() {
            closeCalendar();
            scrollRef.current.removeEventListener('scroll',
                removeScrollEvent,
                false);
        }
        if (scrollRef && isCalendarOn) {
            scrollRef.current.addEventListener('scroll',
                removeScrollEvent,
                false);
        }
    }, [scrollRef, closeCalendar, isCalendarOn]);
}
