import Button from '@baseComponents/buttons/Button';
import Svg from '@baseComponents/Svg';
import FarmModal from '@customComponents/modals/farmModal/FarmModal';
import PartnersModal from '@customComponents/modals/partnersModal/PartnersModal';
import renderModal from '@modals/modalNew/modalFunctions';
import { ThemeType } from '@map/services/mapTypes';
import { Children, useState } from 'react';
import { createUseStyles } from 'react-jss';

type TProps = {
    children: JSX.Element;
}

type Classes = 'container' | 'innerContainer' | 'rightBorder';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        display: 'block',
        borderStyle: 'solid',
        borderColor: theme.color.grayFaded,
        borderWidth: 0,
        borderBottomWidth: 1,
        height: 137,
        marginBottom: 7,
    },
    innerContainer: {
        width: 199,
        height: 127,
        margin: [0, 30, 0, 30],
        display: 'flex',
        alignItems: 'center',
    },
    rightBorder: {
        borderStyle: 'solid',
        borderColor: theme.color.grayFaded,
        borderWidth: 0,
        borderRightWidth: 1,
        marginBottom: 5,
    },
}));
export default function MapFilterBase({ children }: TProps) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.rightBorder}>
                <div className={classes.innerContainer}>
                    {children}
                </div>
            </div>
        </div>
    );
}
