import React from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';

import { TABLE } from 'shared/src/constants';

const useStyle = createUseStyles((theme: any) => ({
    columnTitle: {
        padding: 0,
        margin: 0,
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
        fontSize: 16,
        fontWeight: 500,
        color: theme.color.jet,
        ...theme.overFlowText,
    },
    rowCount: {
        paddingLeft: 5,
        paddingRight: 5,
    },
}));

export default function TableAggregateTitle(props) {
    const { aggregateType, tableRowCount } = props;
    const { t } = useTranslate();
    const classes = useStyle();

    function getAggregateText() {
        switch (aggregateType) {
            case TABLE.AGGREGATE.SUM:
                return t('default.total', 'Total');
            case TABLE.AGGREGATE.AVG:
                return t('default.avarge', 'Avarge');
            default:
                return '';
        }
    }

    return (
        <h4 className={classes.columnTitle}>
            {getAggregateText()}
            {tableRowCount
                && (
                    <>
                        <span className={classes.rowCount}> ({tableRowCount} {t('default.pieceShort', 'pc')})</span>
                    </>
                )
            }
        </h4>
    );
}
