import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { PARTNER } from 'shared/src/constants';
import { errorActions } from '@states/actions/errorActions';
import { partnerService } from '@services';

export default function useLoadInsuranceData() {
    const dispatch = useDispatch();
    const location = useLocation();
    const companyIds = useSelector((state: any) => state.company.activeCompanyIds);
    const [partnerList, setPartnerList] = useState();

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const partners = await partnerService.getPartnersAndCompanies({
                    companyIds,
                    typeId: PARTNER.TYPES.PARTNER,
                });

                if (!isCancelled) {
                    const partnersComp = partners?.map(partnerObj => ({ value: partnerObj.name, key: partnerObj.id }));
                    setPartnerList(partnersComp);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'InsuranceSelector'));
            }
        }

        if (companyIds && location) {
            loadData();
        }
        return () => {
            isCancelled = true;
        };
    }, [companyIds, dispatch, location]);

    return {
        partnerList,
    };
}
