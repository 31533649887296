/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { isEmpty, isUndefined, isFunction } from 'lodash';

export default function useMultiselectData(props) {
    const { dataList, multiselect, onChangeSelected } = props;
    const [multiselectCheckedStates, setMultiselectCheckedStates] = useState([]);
    const [multiselectDisabledStates, setMultiselectDisabledStates] = useState([]);

    //multiselect set data list
    useEffect(() => {
        if (multiselect && !isEmpty(dataList)) {
            const checkedStates = [];
            const disabledStates = [];
            dataList.forEach(dataRow => {
                const isChecked = isUndefined(dataRow.isChecked) ? false : dataRow.isChecked;
                checkedStates[`key_${dataRow.key}`] = isChecked;

                const isDisabled = isUndefined(dataRow.isDisabled) ? false : dataRow.isDisabled;
                disabledStates[`key_${dataRow.key}`] = isDisabled;
            });
            setMultiselectCheckedStates(checkedStates);
            setMultiselectDisabledStates(disabledStates);
        }
    }, [multiselect, dataList]);

    //on click checkbox - onChange
    function changeMultiselectItemState(itemKey, state) {
        const checkedStates = [];

        //parent checkbox -> select/deselect sub items
        let parentKeyToChecking;
        let parentKeyToUnChecking;
        const childDatas: any = [];
        const changedItemObj = dataList?.find(rowData => `key_${rowData.key}` === itemKey);
        const parentKey = changedItemObj.key;

        if (changedItemObj.isParent && !changedItemObj.parentKey) { //simple parent
            const subMenuParentKeys: any = [];

            dataList?.forEach(dataRow => {
                if (dataRow.parentKey === parentKey) {
                    if (dataRow.isParent) { //submenu
                        subMenuParentKeys.push(dataRow.key);
                    }
                    childDatas.push(dataRow);
                }
            });

            dataList?.forEach(dataRow => {
                if (subMenuParentKeys.includes(dataRow.parentKey)) {
                    childDatas.push(dataRow);
                }
            });
        } else if (changedItemObj.isParent && (changedItemObj.parentKey || changedItemObj.parentKey === 0)) { //sub item and parent
            dataList?.forEach(dataRow => {
                if (dataRow.parentKey === parentKey) {
                    childDatas.push(dataRow);
                }
            });
        } else if ((state && changedItemObj.parentKey) || changedItemObj.parentKey === 0) { //simple item checked -> check parent
            parentKeyToChecking = changedItemObj.parentKey;
        } else if ((!state && changedItemObj.parentKey) || changedItemObj.parentKey === 0) { //simple item unchecked -> uncheck parent
            let hasCheckedSubChild = false;

            dataList?.forEach(dataRow => {
                if (dataRow.parentKey === changedItemObj.parentKey && dataRow.isChecked) {
                    hasCheckedSubChild = true;
                }
            });

            if (!hasCheckedSubChild) {
                parentKeyToUnChecking = changedItemObj.parentKey;
            }
        }

        dataList?.forEach(dataRow => {
            const isChecked = isUndefined(dataRow.isChecked) ? false : dataRow.isChecked; //set default checked state
            //const isCheckedKey = `key_${dataRow.key}` === itemKey ? state : isChecked; //set new checked state

            const isCheckedKey = (`key_${dataRow.key}` === itemKey || dataRow.key === parentKeyToChecking || dataRow.key === parentKeyToUnChecking) //set new checked state
                ? state
                : (childDatas?.find(childData => childData.key === dataRow.key)
                    ? state
                    : isChecked);

            //manual set isChecked flag
            if (`key_${dataRow.key}` !== itemKey && childDatas?.find(childData => childData.key === dataRow.key)) {
                dataRow.isChecked = state;
            }
            if (dataRow.key === parentKeyToChecking) {
                dataRow.isChecked = state;
            }
            if (!state && dataRow.key === parentKeyToUnChecking) {
                dataRow.isChecked = state;
            }

            checkedStates[`key_${dataRow.key}`] = isCheckedKey; //save checked state
        });
        setMultiselectCheckedStates(checkedStates);
        onChange();
    }

    //select all checkbox
    function selectAll() {
        if (multiselect && !isEmpty(dataList)) {
            const checkedStates = [];
            dataList.forEach(dataRow => {
                if (!dataRow.isDisabled) {
                    dataRow.isChecked = true;
                    checkedStates[`key_${dataRow.key}`] = true;
                }
            });
            setMultiselectCheckedStates(checkedStates);
            onChange();
        }
    }

    //deselect all checkbox
    function clearSelection() {
        if (multiselect && !isEmpty(dataList)) {
            const checkedStates = [];
            dataList.forEach(dataRow => {
                if (!dataRow.isDisabled) {
                    dataRow.isChecked = false;
                    checkedStates[`key_${dataRow.key}`] = false;
                }
            });
            setMultiselectCheckedStates(checkedStates);
            onChange();
        }
    }

    function onChange() {
        if (isFunction(onChangeSelected)) {
            const selectedItems: any = [];
            if (!isEmpty(dataList)) {
                dataList.forEach(rowData => {
                    if (rowData.isChecked) {
                        selectedItems.push(rowData.key);
                    }
                });
            }
            onChangeSelected(selectedItems);
        }
    }

    return {
        multiselectCheckedStates,
        multiselectDisabledStates,
        changeMultiselectItemState,
        selectAll,
        clearSelection,
    };
}
