import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { isNumber, isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { registryNumberService } from '@services';
import { errorActions } from '@states/actions/errorActions';

export default function useLoadRegistryNumber(props) {
    const { loadedRegistryNumberList, registryNumberExpanded, setFormInitialValues } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const [registryNumberList, setRegistryNumberList] = useState();

    const { values, setFieldValue } = useFormikContext();

    //load all registryNumbers
    useEffect(() => {
        let isCancelled = false;
        async function loadAllRegistryNumber() {
            try {
                const registryNumbers = await registryNumberService.getRegistryNumbers(values.owner, values.ownerType);
                if (!isCancelled) {
                    arrayUtils.sortByParamAsc(registryNumbers, 'name');
                    setFieldValue('allRegistryNumbers', registryNumbers);
                    setFieldValue('registryNumber', registryNumbers);
                    setRegistryNumberList(registryNumbers);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (dispatch && location && isNumber(values.owner) && isNumber(values.ownerType) && loadedRegistryNumberList) {
            loadAllRegistryNumber();
            return () => {
                isCancelled = true;
            };
        }
        if (!isNumber(values.owner) && isNumber(values.ownerType)) {
            setFieldValue('registryNumber', '');
        }
    }, [dispatch, location, values.owner, values.ownerType, setFieldValue, loadedRegistryNumberList, setFormInitialValues]);

    useEffect(() => {
        if (!isEmpty(values.registryNumberList) && !isEmpty(registryNumberList) && loadedRegistryNumberList) {
            const selectedRegistryNumbers = [];
            registryNumberList.forEach(registryNumberObj => {
                const checkRegistryNumber = values.registryNumberList.find(regNumObj => regNumObj.registryNumberId === registryNumberObj.id);
                if (checkRegistryNumber) {
                    selectedRegistryNumbers.push({
                        ...registryNumberObj,
                        area: checkRegistryNumber.area,
                    });
                }
            });
            setFieldValue('registryNumberList', '');
            setFormInitialValues(initialValues => ({ ...initialValues, selectedRegistryNumbers }));
            setFieldValue('selectedRegistryNumbers', selectedRegistryNumbers);
        }
    }, [setFieldValue, registryNumberList, values.registryNumberList, loadedRegistryNumberList, setFormInitialValues]);

    useEffect(() => {
        if (isNumber(values.ownerType)) {
            setFieldValue('registryNumber', '');
            setFieldValue('registryNumberIds', '');
            setFieldValue('selectedRegistryNumbers', '');
        }
    }, [values.owner, values.ownerType, setFieldValue]);

    useEffect(() => {
        if (!registryNumberExpanded && !isEmpty(values.selectedRegistryNumbers) && !isEmpty(registryNumberList)) {
            const registryNumbers = [];
            registryNumberList.forEach(regNumObj => {
                const selectedRegNum = values.selectedRegistryNumbers.find(selectedObj => selectedObj.id === regNumObj.id);
                registryNumbers.push(selectedRegNum || regNumObj);
            });
            const regNumIds = values.selectedRegistryNumbers.map(selectedObj => selectedObj.id);
            setFieldValue('registryNumberIds', regNumIds);
            setFieldValue('registryNumber', registryNumbers);
            // 5
        } else if (!registryNumberExpanded && isEmpty(values.selectedRegistryNumbers) && !isEmpty(registryNumberList)) {
            setFieldValue('registryNumberIds', '');
            // 3
        }
    }, [registryNumberExpanded, registryNumberList, setFieldValue, values.selectedRegistryNumbers]);
}
