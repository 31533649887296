import { useEffect } from 'react';
import { isEmpty } from 'lodash';

import { dateTimeUtils } from 'shared/src/modules';
import ServerOverload from '@screens/errors/ServerOverload';
import { sessionService } from '@services';
import { LANGUAGE } from 'shared/src/constants';

import useTranslater from './useTranslater';

export default function TranslaterProvider(props) {
    const { children } = props;
    const { hasTranslaterError, i18n } = useTranslater();

    //set default lang
    useEffect(() => {
        if (!isEmpty(i18n) && isEmpty((i18n as any).errorMessage) && i18n.isInitialized && !hasTranslaterError) {
            const langCode = sessionService.getLangCode();
            if (langCode) {
                i18n.changeLanguage(langCode.toLowerCase());
            }
        }
    }, [hasTranslaterError, i18n, i18n.isInitialized]);

    //set default datetime format by lang
    useEffect(() => {
        let langCode = sessionService.getLangCode();
        if (!langCode) {
            langCode = LANGUAGE.ENGLISH;
        }

        dateTimeUtils.setMomentLocale(langCode);
    }, []);

    if (!i18n.isInitialized) {
        return null;
    }

    if (hasTranslaterError) {
        return (<ServerOverload isTranslaterError />);
    }

    return (
        <>
            {children}
        </>
    );
}
