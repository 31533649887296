import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useFormikContext } from 'formik';
import { cultivationPeriodsService, cultivationTableService } from '@services';
import { errorActions } from '@states/actions/errorActions';

export default function useLoadEditCultivationPeriodData(props) {
    const { periodYearId, cultivationPeriodId, setCultivationPeriodValues, setFormInitialValues } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const [loadSupportList, setLoadSupportList] = useState(true);
    const [loadBasicFieldsList, setLoadBasicFieldsList] = useState(true);
    const [loadRegistryNumberList, setLoadRegistryNumberList] = useState(true);
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        let isCancelled = false;

        async function loadCultivationPeriodData() {
            try {
                const [supportList, basicFieldList, registryNumberList] = await Promise.all([
                    cultivationPeriodsService.getCultivationPeriodSupport(cultivationPeriodId),
                    cultivationTableService.getCultivationTableToPeriod(cultivationPeriodId, periodYearId),
                    cultivationPeriodsService.getCultivationPeriodRegistryNumber(cultivationPeriodId),
                ]);

                if (!isCancelled) {
                    setFormInitialValues(initialValues => ({
                        ...initialValues,
                        support: supportList,
                        registryNumberList,
                        basicFields: basicFieldList?.map(basicField => ({ ...basicField, isFixed: true })),
                    }));
                    setFieldValue('support', supportList);
                    setLoadSupportList(false);
                    setFieldValue('registryNumberList', registryNumberList);
                    setLoadRegistryNumberList(false);
                    setFieldValue('basicFields', basicFieldList?.map(basicField => ({ ...basicField, isFixed: true })));
                    setLoadBasicFieldsList(false);
                    setFieldValue('isEditDataLoaded', true);
                    setCultivationPeriodValues(currentCultivationPeriods => ({
                        ...currentCultivationPeriods,
                        support: supportList,
                        registryNumberList,
                        basicFields: basicFieldList,
                    }));
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (dispatch && location && cultivationPeriodId && periodYearId && loadBasicFieldsList && loadRegistryNumberList && loadSupportList) {
            loadCultivationPeriodData();
        }

        return () => {
            isCancelled = true;
        };
    }, [dispatch, location, periodYearId, setFieldValue, loadBasicFieldsList, loadRegistryNumberList, loadSupportList, cultivationPeriodId, setCultivationPeriodValues, setFormInitialValues]);

    return {
        loadSupportList,
        loadBasicFieldsList,
        loadRegistryNumberList,
    };
}
