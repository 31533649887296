import React from 'react';
import { createUseStyles } from 'react-jss';
import { WORKOPERATION } from 'shared/src/constants';
import { useNumberFormat } from '@common_modules/numberFormat';
import { Area } from '@baseComponents/formatters';

const useStyles = createUseStyles((theme: any) => ({
    multilineTitleWrapper: {
        width: '100%',
        height: '100%',
        display: 'inline-block',
        margin: 'auto',
        textAlign: 'right',
    },
    firstLine: {
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineHeight: '16px',
        height: '50%',
        alignItems: 'flex-end',
    },
    firstLineText: {
        fontSize: 13,
        fontWeight: 500,
        color: theme.color.jet,
        display: 'block',
        ...theme.overFlowText,
        '& div': {
            fontSize: 13,
        },
    },
    secondLine: {
        display: 'flex',
        width: '100%',
        lineHeight: '16px',
        fontSize: 13,
        fontWeight: 'normal',
        color: theme.color.raven,
        ...theme.overFlowText,
        height: '50%',
    },
    secondLineText: {
        width: '100%',
        display: 'inline-block',
        fontSize: 13,
        fontWeight: 'normal',
        color: props => (props.value >= 100 ? theme.color.operations.table : theme.color.status),
        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        ...theme.overFlowText,
    },
    secondLineId: {
        width: '100%',
    },
}));

export default function MultilineWithAreaPercentCell(props) {
    const { value, rowData, secondLineId } = props;
    const { numberFormatter } = useNumberFormat();
    const classes = useStyles({ value });

    return (
        <div className={classes.multilineTitleWrapper}>
            {rowData.typeId === WORKOPERATION.TYPE_ID.TABLE_WORK
                ? (
                    <>
                        <div className={classes.firstLine}>
                            <span className={classes.secondLineId}>
                                <Area value={rowData[secondLineId] || 0} />
                            </span>
                        </div>
                        {secondLineId
                            ? (
                                <div className={classes.secondLine}>
                                    <span className={classes.secondLineText}>
                                        {Number(value) >= 100 ? numberFormatter.getFormattedPercentage(100, true) : numberFormatter.getFormattedPercentage(value, true)} %
                                    </span>
                                </div>
                            ) : null
                        }
                    </>
                ) : null
            }
        </div>
    );
}
