/* eslint-disable dot-notation */
import { useEffect, useMemo, useState } from 'react';
import useTranslate from '@i18n/useTranslate';
import { GeomSaveEntity } from '@map/services/mapTypes';
import { Feature } from 'geojson';
import {
    cloneDeep,
    isArray, isEmpty, isNil, isNumber, isString, uniqueId,
} from 'lodash';
import { useMapContext } from '@map/services/mapContext';
import { TLayerCreateStore } from '@map/services/layerCreate/layerCreateStore';
import LayerCreateController from '@map/services/layerCreate/layerCreateController';
import { useVisibleFarmService } from '@map/services/mapVisibleFarmService';
import { masterDataService } from '@services/masterDataService';
import area from '@turf/area';
import { useDispatch } from 'react-redux';
import { simpleTableActions } from '@states/actions';
import { AttributePairs } from './LayerUploadCustomLayerAttributesModal/LayerUploadCustomLayerAttributesModal';
import { BuiltInLayerIds, BuiltInLayerTypes } from '@map/services/mapEnums';

const idColumnName = uniqueId('id');

type TProps = {
    close: () => void;
    store: TLayerCreateStore;
    controller: LayerCreateController;
    tableKey: string;
    setIsSaving: (isSaving: boolean) => void;
}

export function useLayerUploadModalPairData(props: TProps) {
    const { close, store, controller, tableKey, setIsSaving } = props;
    const [tableData, setTableData] = useState<unknown>();
    const [mapStore, mapController] = useMapContext();
    const [syncPolygons] = useVisibleFarmService();
    const dispatch = useDispatch();

    const { t, translater } = useTranslate();

    const propertyDataList: string[] = useMemo<string[]>(() => {
        const set = new Set<string>();
        store.upload.featureCollection?.features?.forEach(feature => {
            if (feature.properties) {
                Object.keys(feature.properties as object).forEach(key => {
                    if (!['entityId', 'area', 'centroid', 'perimeter', 'mapSvg'].includes(key)) {
                        set.add(key);
                    }
                });
            }
        });

        return Array.from(set) || [];
    }, [store.upload.featureCollection]);

    const excludedEntityIds = useMemo(() => {
        const currentlyPairedIds = store.upload.pairableEntities?.filter(entity => isNumber(entity.featureId))?.map(entity => entity.entityId) || [];
        const alreadyPairedIds = [];// store.upload.excludedEntityIds || [];
        return [...currentlyPairedIds, ...alreadyPairedIds];
    }, [store.upload.pairableEntities]);

    useEffect(() => {
        let isCancelled = false;

        const features = store.upload.featureCollection?.features;
        async function getFeaturesTableData() {
            const geomToSvg = features?.map(feature => ({ id: feature.id, geometry: feature.geometry }));
            // const svgData: GeomSvgDto[] = await controller.getFeatureSvgs(geomToSvg as GeomToSvgDto[]);

            if (!isCancelled) {
                const tableFeatureData = features?.map((feature: Feature) => {
                    const pairedEntityName = store.upload.pairableEntities?.find(entity => entity?.featureId === feature.id);
                    // const svgFeatureData = svgData?.find(svg => svg?.id === feature?.id);
                    const customFeatureData = store.upload.customLayerFeatureData?.find(customLayerData => customLayerData?.geomId === feature?.id);

                    const isExcluded = isNumber(feature?.id) ? store.upload.excludedFeatureIds?.includes(+feature.id) : true;
                    const tableDataObj = {
                        ...(customFeatureData || {}),
                        ...feature.properties,
                        id: feature.id,
                        pairedEntityName: pairedEntityName?.properties?.name,
                        isExcluded,
                        // mapSvg: {
                        //     viewBox: svgFeatureData?.viewBox,
                        //     path: svgFeatureData?.path,
                        // },
                    } as any;

                    if (feature?.properties?.id) {
                        tableDataObj[idColumnName] = feature.properties.id;
                    }

                    tableDataObj.calculatedArea = area(feature) / 10000;

                    return tableDataObj;
                });

                setTableData(tableFeatureData);
            }
        }

        if (isArray(features) && !isEmpty(features)) {
            getFeaturesTableData();
        }

        return () => {
            isCancelled = true;
        };
    }, [store.upload.featureCollection?.features, store.upload.pairableEntities, controller, store.upload.customLayerFeatureData, store.upload.excludedFeatureIds]);

    const onSave = async (isCustomLayerUpload: boolean, layerAttributeWithTypes?: object, layerAttributePairing?: AttributePairs[]) => {
        try {
            setIsSaving(true);
            const { upload } = store;

            const { startDate, endDate } = upload;

            let productionYearId = !isCustomLayerUpload ? (store.upload.productionYearId || null) : null;
            const entitiesToSave = isCustomLayerUpload ? store.upload.featureCollection?.features : store.upload.pairableEntities;

            const { customLayerFeatureData } = store.upload;

            if (!store.upload.isCustomLayerUpload && ![BuiltInLayerIds.CULTIVATION_PERIOD, BuiltInLayerIds.CULTIVATION_PERIOD_GROUP, BuiltInLayerIds.LPIS_FIELD].includes(store.upload.selectedLayerId as number)) {
                productionYearId = null;
            }

            let geomsToBeSaved: GeomSaveEntity[] | any = entitiesToSave?.map(pairEntity => {
                let { properties }: any = cloneDeep(pairEntity);
                if (properties && layerAttributePairing) {
                    layerAttributePairing?.forEach(layerAttributePair => {
                        if (!isNil(layerAttributePair.pairAttributeName) && !layerAttributePair?.isDeleted) {
                            properties[layerAttributePair.pairAttributeName] = properties[layerAttributePair.newAttributeName];
                        }

                        if (layerAttributePair?.isDeleted || (!isEmpty(layerAttributePair.pairAttributeName) && (layerAttributePair.pairAttributeName !== layerAttributePair.newAttributeName))) {
                            delete properties[layerAttributePair.newAttributeName];
                        }
                    });
                }

                if (isCustomLayerUpload) {
                    const existingCustomLayerData = customLayerFeatureData?.find(featureData => featureData?.geomId === pairEntity?.id);
                    if (existingCustomLayerData) {
                        properties = { ...properties, ...existingCustomLayerData };
                    }
                }

                let companyIdQueryParam: number[] = [];
                let farmIdQueryParam: number[] | null = null;

                const companyId = properties?.companyId;
                const companyIds = properties?.companyIds;

                const farmId = properties?.farmId;
                const farmIds = properties?.farmIds;

                if (!isCustomLayerUpload) {
                    if (!farmIds && Number(farmId ?? 0) > 0) {
                        farmIdQueryParam = [+farmId];
                    } else if (farmIds && isString(farmIds) && farmIds.includes(',')) {
                        farmIdQueryParam = farmIds?.split(',')?.map(curFarmId => +curFarmId);
                    } else if (isArray(farmIds)) {
                        farmIdQueryParam = farmIds;
                    }
                } else {
                    companyIdQueryParam = [...(store.upload.customLayerPermissions?.companyIds || [])];
                    farmIdQueryParam = store?.upload?.customLayerPermissions?.farmIds ?? null;
                }

                if (isNumber(companyId) && !companyIds) {
                    companyIdQueryParam = [companyId];
                } else if (companyIds && isString(companyIds) && companyIds.includes(',')) {
                    companyIdQueryParam = companyIds?.split(',')?.map(companyIdToNumb => +companyIdToNumb);
                } else if (isArray(companyIds)) {
                    companyIdQueryParam = companyIds;
                }

                const geometry = !isCustomLayerUpload ? store.upload.featureCollection?.features.find(feature => feature.id === pairEntity.featureId)?.geometry : pairEntity?.geometry;

                return {
                    layerId: store.upload.selectedLayerId,
                    companyIds: companyIdQueryParam,
                    farmIds: farmIdQueryParam,
                    entityId: pairEntity.entityId || null,
                    productionYearId, //pairEntity?.properties['productionYearId'],
                    geometry,
                    customLayerProperties: isCustomLayerUpload ? properties : null,
                    startDate,
                    endDate,
                    isExcluded: store.upload.excludedFeatureIds?.includes(pairEntity?.id),
                };
            }) ?? [];

            if (!isEmpty(store.upload.excludedFeatureIds)) {
                geomsToBeSaved = geomsToBeSaved?.filter(geom => !geom.isExcluded);
            }

            if (geomsToBeSaved.length > 0) {
                const layerAttributesWithTypes = { ...cloneDeep(layerAttributeWithTypes || {}), ...(store.upload.existingLayerAttributes || {}) };

                if (!isNil(layerAttributePairing)) {
                    layerAttributePairing?.forEach(layerAttributePair => {
                        if (!layerAttributePair.isDeleted && layerAttributePair.pairAttributeName) {
                            layerAttributesWithTypes[layerAttributePair.pairAttributeName] = layerAttributePair.attributeType;
                        }

                        if (layerAttributePair.isDeleted || (!isEmpty(layerAttributePair.pairAttributeName) && (layerAttributePair.pairAttributeName !== layerAttributePair.newAttributeName))) {
                            delete layerAttributesWithTypes[layerAttributePair.newAttributeName];
                        }
                    });
                }
                const customLayerId = (isCustomLayerUpload && store.upload.selectedLayerId) ? +store.upload.selectedLayerId : null;

                const isSaveOk = await controller.saveGeometries({
                    layerAttributeWithTypes: layerAttributesWithTypes,
                    isCustomLayer: isCustomLayerUpload,
                    customLayerId,
                    geomRequestData: geomsToBeSaved,
                });
                const savedLayer = mapStore.layering.layers?.find(layer => layer.id === Number(store.upload.selectedLayerId));
                if (isSaveOk) {
                    if (savedLayer) {
                        await mapController.reloadLayers([savedLayer]);
                        await mapController.loadLayerUnloadedAttributes(savedLayer);
                        await mapController.addLayerToMap([savedLayer]);
                    }

                    if (isCustomLayerUpload && mapStore?.layering?.attributes) {
                        // await mapController.getEveryLayerAndAttribute();
                        // let defaultAttributes: LayerAttribute[] = [];
                        // defaultAttributes = mapStore?.layering?.attributes.filter(attribute => +attribute.layerId === 2 && attribute.isDefault);

                        // mapController.addLayerAttributesToMap(defaultAttributes, false);

                        // await mapController.addLayerAttributesToMap([availableAttr[0]]);
                    }

                    //masterData version update
                    switch (Number(store.upload.selectedLayerId)) {
                        case BuiltInLayerIds.CULTIVATION_PERIOD:
                        case BuiltInLayerIds.CULTIVATION_PERIOD_GROUP:
                            await masterDataService.updateVersion({ masterdata: 'partfield' });
                            break;
                        case BuiltInLayerIds.LPIS_FIELD:
                            await masterDataService.updateVersion({ masterdata: 'lpis_field' });
                            break;
                        default:
                            break;
                    }

                    //VF szinkron
                    await syncPolygons(geomsToBeSaved as any);

                    close();
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsSaving(false);
        }
    };

    function handleFeatureKeyPairing(pairingKeys) {
        const { featureProperty, entityProperty } = pairingKeys;
        const { features }: any = store.upload.featureCollection;
        const pairableEntities = store.upload.pairableEntities;

        if (!featureProperty || !entityProperty || !features) {
            return;
        }

        const entityFeaturePairMap = {};

        features?.forEach((feature: Feature) => {
            const featurePropertyValue: any = feature.properties?.[featureProperty];
            const pairEntity = pairableEntities?.find(pairableEntity => pairableEntity?.properties?.[entityProperty] === featurePropertyValue);

            if (pairEntity && isNumber(feature?.id)) {
                entityFeaturePairMap[pairEntity.entityId] = feature?.id;
            }
        });

        if (!isEmpty(entityFeaturePairMap)) {
            controller.pairEntityIdsToFeautreIds(entityFeaturePairMap);
            setTimeout(() => {
                dispatch(simpleTableActions.addTableGlobalColumnSortingType(tableKey, 'pairedEntityName', 1));
            }, 0);
        }
    }

    return {
        tableData,
        excludedEntityIds,
        onSave,
        propertyDataList,
        idColumnName,
        handleFeatureKeyPairing,
    };
}
