import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        ...theme.overFlowText,
        color: theme.color.jet,
        display: 'block',
        height: '100%',
        lineHeight: '56px',
        fontSize: 13,
        fontWeight: 500,
        verticalAlign: 'middle',
    },
}));

export default function DoubleTextCell(props) {
    const { value, style, rowData, secondValueId } = props;
    const classes = useStyles();
    const [textValue] = useState(getValue());

    function getValue() {
        const secondValue = arrayUtils.getArrayItemByKeyString(rowData, secondValueId);
        return value && secondValue && `${value} ${secondValue}`;
    }

    return (
        <div className={clsx(classes.baseStyle, style)} title={textValue}>
            {textValue}
        </div>
    );
}
