export const ANALYSIS = {
    MODULE_ID: 29,
    NAME: 'analysis',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'analysis',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Agrovir',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        MACHINES: {
            CONTENT_URL_ID: { machines: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'analysisMachines',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.analysisMachines',
                TRANSLATOR_DEFAULT: 'Machines',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        SPECIES: {
            CONTENT_URL_ID: { species: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'analysisSpecies',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.analysisSpecies',
                TRANSLATOR_DEFAULT: 'Species',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        SEED: {
            CONTENT_URL_ID: { seed: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'analysisSeed',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.analysisSeed',
                TRANSLATOR_DEFAULT: 'Seed',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        OPERATION_GROUP: {
            CONTENT_URL_ID: { operationGroup: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'analysisOperationGroup',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.analysisOperationGroup',
                TRANSLATOR_DEFAULT: 'Operation group',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        INSECTICIDE: {
            CONTENT_URL_ID: { insecticide: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'analysisInsecticide',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.analysisInsecticide',
                TRANSLATOR_DEFAULT: 'Insecticide',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        NUTRIENT: {
            CONTENT_URL_ID: { nutrient: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'analysisNutrient',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.analysisNutrient',
                TRANSLATOR_DEFAULT: 'Nutrient',
            },
            SHOW_IN_PANNING_MODE: true,
        },        
    },
};
