import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    tagContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxSizing: 'border-box',
        width: props => props.fullWidth && '100%',
        minHeight: props => (props.fullWidth && 30),
        height: props => (!props.fullWidth && 30),
        padding: '5px 10px',
        border: props => (props.fullWidth ? 'none' : `1px solid ${theme.color.filter}`),
        borderRadius: 4,
        backgroundColor: props => (props.fullWidth ? theme.color.lilian : 'rgba(154,103,0,0.2)'),
        color: props => (props.fullWidth ? theme.color.stone : theme.color.filter),
        fontSize: 13,
        fontWeight: 500,
        letterSpacing: 0,
        cursor: 'default',
    },
    closeIcon: {
        width: 15,
        height: 15,
        marginLeft: 12,
        fill: (props: any) => (props.fullWidth ? theme.color.stone : theme.color.filter),
    },
    closeButton: {
        cursor: 'pointer',
    },
}));

export default function BasicTag({ title, tagId, filterName, onClose, fullWidth }) {
    const classes = useStyles({ fullWidth } as any);
    const [trimmedTitle, setTrimmedTitle] = useState('');
    useEffect(() => {
        setTrimmedTitle(title.length > 33 ? `${title.substring(0, 30)}...` : title);
    }, [title]);

    return (
        <div className={classes.tagContainer}>
            <span>{trimmedTitle}</span>
            <div
                className={classes.closeButton}
                role="button"
                onClick={() => onClose(filterName, tagId)}
                aria-hidden="true"
            >
                <Svg iconId="icon-close" style={classes.closeIcon} />
            </div>
        </div>
    );
}

BasicTag.propTypes = {
    title: PropTypes.string.isRequired,
    filterName: PropTypes.string.isRequired,
    tagId: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    onClose: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
};

BasicTag.defaultProps = {
    tagId: null,
    fullWidth: null,
};
