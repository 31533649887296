export const VALIDATION = {
    ERRORS: {
        MISSING_VALIDATION: {
            ERROR_CODE: 'missingValidation',
            ERROR_MESSAGE: {
                KEY: 'validation.missingValidation',
                DEFAULT: 'Missing validation.',
            },
        },
        MISSING_VALUES: {
            ERROR_CODE: 'missingValues',
            ERROR_MESSAGE: {
                KEY: 'validation.markedFieldsMandatory',
                DEFAULT: 'The marked fields are mandatory.',
            },
        },
        INVALID_VALUES: {
            ERROR_CODE: 'invalidValues',
            ERROR_MESSAGE: {
                KEY: 'validation.invalidValues',
                DEFAULT: 'The marked fields contain invalid values.',
            },
        },        
        MISSING_VALUE: {
            ERROR_CODE: 'missingValue',
            ERROR_MESSAGE: {
                KEY: 'validation.missingValue',
                DEFAULT: 'This value is mandatory.',
            },
        },
        VALUE_IS_NEGATIVE: {
            ERROR_CODE: 'valueIsNegative',
            ERROR_MESSAGE: {
                KEY: 'validation.valueIsNegative',
                DEFAULT: 'This value cannot be negative.',
            },
        },
        VALUE_IS_NOT_HIGHER_THAN_ZERO: {
            ERROR_CODE: 'valueIsNotHigherThanZero',
            ERROR_MESSAGE: {
                KEY: 'validation.valueIsNotHigherThanZero',
                DEFAULT: 'This value must be higher than zero.',
            },
        },
        INVALID_TIME: {
            ERROR_CODE: 'invalidTime',
            ERROR_MESSAGE: {
                KEY: 'validation.invalidTime',
                DEFAULT: 'Invalid time value.',
            },
        },
    },
};
