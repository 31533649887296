import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import useTranslate from '@i18n/useTranslate';
import { uniqueId, isArray, isFunction, isEmpty } from 'lodash';
import { simpleTableActions, alertActions } from '@states/actions';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import Button from '@baseComponents/buttons/Button';
import CloseButton from '@baseComponents/buttons/CloseButton';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import GlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/GlobalSearchInput';
import { ModalContentWrapper, ModalHeader, ModalBody, ModalFooter } from '@baseComponents/modals/modalNew';

import getAllColumns from './allColumns';
import LayerTypeRadio from './LayerTypeRadio';

const useStyles = createUseStyles((theme: any) => ({
    modalWrapper: {
        width: 650,
    },
    headerFunctionLine: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: theme.color.white,
        padding: '0 30px',
        marginBottom: 10,
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    tableWrapper: {
        display: 'contents',
        height: '100%',
        width: '100%',
    },
    addSelectedButton: {
        marginRight: 20,
    },
    footerButton: {
        width: 150,
    },
    radioContainer: {
        display: 'flex',
    },
    table: {
        '& .tableHeaderRow': {
            marginRight: 20,
            paddingRight: 10,
            marginLeft: 4,
        },
        '& .List': {
            marginRight: 20,
            paddingRight: 10,
        },
        '& .table-row': {
            marginRight: 20,
            paddingRight: 10,
        },
        width: '100%',
    },
}));

const TABLE_KEY = uniqueId('layerSelectorModal');

export const LAYER_TYPES = {
    AGROVIR: 1,
    CUSTOM: 2,
    ALL: 3,
};

export default function LayerSelectorModal(props) {
    const { onChange, hideModal, isMultiselect, rowFormatFnc = obj => obj, onSelectedValue, selectedRowIds, tableData, showLayerType = true, allowedLayerTypes = LAYER_TYPES.ALL } = props;
    const { t, translater } = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [defaultColumns, setDefaultColumns] = useState();
    const [layerTypeId, setLayerTypeId] = useState(allowedLayerTypes);

    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    const filteredTableData = useMemo(() => (((layerTypeId === LAYER_TYPES.ALL)) ? tableData : (tableData as any[])?.filter(rowData => rowData?.isCustomLayer === (layerTypeId === LAYER_TYPES.CUSTOM))), [layerTypeId, showLayerType, tableData]);

    useEffect(() => {
        const showedColumns = ['addIcon', 'layerName', 'parentName'];

        const filteredColumns = getAllColumns(t, translater, showedColumns);
        setDefaultColumns(filteredColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAddButtonClicked(selectedLayer) {
        if (isFunction(onSelectedValue)) {
            onSelectedValue(rowFormatFnc(selectedLayer), selectedLayer?.id);
        }

        if (isFunction(onChange)) {
            onChange(rowFormatFnc(selectedLayer), selectedLayer?.id);
        }

        if (isFunction(hideModal)) {
            hideModal();
        }
    }

    function onAddSelectedButtonClicked() {
        const selectedLayerIds = dispatch(simpleTableActions.getSelectedRowIds(TABLE_KEY)) as unknown as any[];
        const selectedLayers = (tableData ?? [])
            .filter((rowData: any) => selectedLayerIds?.includes(rowData.id))
            .map(rowFormatFnc);

        if (!isEmpty(selectedLayers)) {
            if (isFunction(onChange)) { onChange(selectedLayers); }
            if (isFunction(hideModal)) { hideModal(); }
        } else {
            dispatch(alertActions.warningAlert(t('layerSelectorModal.noSelectedLayers', 'No layers were selected')));
        }
    }

    const [tableFunctions] = useState({
        onAddButtonClicked,
    });

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {t('layerSelectorModal.selectLayers', 'Select layers')}
                <CloseButton onClick={hideModal} />
            </ModalHeader>
            <div className={classes.headerFunctionLine}>
                {showLayerType ? <LayerTypeRadio layerTypeId={layerTypeId} setLayerTypeId={setLayerTypeId} /> : null}
                <GlobalSearchInput tableKey={TABLE_KEY} isSearchButton={false} isSearchIcon />
            </div>
            <ModalBody className={classes.modalBody}>
                <LoadSpinner value={tableData} isAutoDetect />
                <div className={classes.tableWrapper}>
                    <TableGroup
                        tableId={TABLE_KEY}
                        tableData={filteredTableData}
                        tableColumns={columns}
                        isBasePageTable={false}
                        isCollapsible={false}
                        tableGroup={{
                            value: null,
                            text: 'No breakdown',
                        }}
                        className={classes.table}
                        tableFunctions={tableFunctions}
                        useRowSelection={isMultiselect}
                        selectedRowIds={selectedRowIds || null}
                    />
                </div>
                <TableScrollerFooter tableKey={TABLE_KEY} isLoaded={isArray(tableData)} />
            </ModalBody>
            <ModalFooter>
                {isMultiselect ? (
                    <Button className={clsx(classes.footerButton, classes.addSelectedButton)} onClick={onAddSelectedButtonClicked}>
                        {t('default.addSelected', 'Add selected')}
                    </Button>
                ) : null}
                <Button type="secondary" className={classes.footerButton} onClick={hideModal}>
                    {t('default.close', 'Close')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
