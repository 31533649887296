import React from 'react';
import { createUseStyles } from 'react-jss';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { MapPolygon } from '../../../formatters';

const useStyles = createUseStyles((theme: any) => ({
    mapWithMultilineCellContainer: {
        height: '100%',
        width: '100%',
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
    },

    twoLineContainer: {
        minWidth: 180,
        textAlign: 'left',
        height: '100%',
        width: '100%',
        margin: 'auto',
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    firstLine: {
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '18px',

        fontSize: 15,
        fontWeight: 500,
        color: theme.color.jet,
    },
    secondLine: {
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'inline-block',
        lineHeight: '16px',

        fontSize: 13,
        fontWeight: 'normal',
        color: theme.color.raven,
    },
    inlineBlock: {
        display: 'inline-block',
    },
}));

export default function MapWithMultilineCell(props) {
    const { rowData, value, firstLineId, secondLineId, mapSvgId, onClick } = props;
    const classes = useStyles();

    function getMapSvg() {
        if (mapSvgId && rowData) {
            return arrayUtils.getArrayItemByKeyString(rowData, mapSvgId);
        }
        return null;
    }

    function getFirstLineText() {
        if (value) {
            return value;
        }

        if (firstLineId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, firstLineId);
            return columnItem;
        }

        return null;
    }

    function getSecondLineText() {
        if (secondLineId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, secondLineId);
            return columnItem;
        }

        return null;
    }

    function onClickMap(event) {
        if (onClick) {
            onClick(rowData, event);
        }
    }

    return (
        <div className={classes.mapWithMultilineCellContainer}>
            <div className={classes.inlineBlock}>
                <MapPolygon mapSvg={getMapSvg()} onClick={onClickMap} />
            </div>
            <div className={classes.twoLineContainer}>
                <div className={classes.firstLine}>
                    {getFirstLineText()}
                </div>
                <div className={classes.secondLine}>
                    {getSecondLineText()}
                </div>
            </div>
        </div>
    );
}
