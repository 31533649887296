import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { isEmpty, isFunction, isUndefined } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import { sessionService } from '@services';

import SelectPlanModal from '@modals/selectPlanModal/SelectPlanModal';
import Button from '@baseComponents/buttons/Button';

const useStyles = createUseStyles((theme: any) => ({
    planningModeWrapper: {
        marginTop: 15,
    },
    contentTitles: {
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        textTransform: 'uppercase',
        display: 'block',
    },
    selectPlanBtn: {
        height: 41,
        width: 300,
        marginTop: 5,
        '& .title': {
            textTransform: 'uppercase',
            display: 'block',
            ...theme.overFlowText,
        },
    },
    selectedPlanTextWrapper: {
        width: '100%',
        paddingRight: 30,
        display: 'inline-flex',
    },
    selectedPlanText: {
        fontWeight: 500,
        color: theme.color.shadow_green,
        lineHeight: '30px',
        width: '100%',
        ...theme.overFlowText,
    },
}));

export default function PlanningModeSelectedPlan(props) {
    const { onSelectPlan, closeUserMenu, onClose, onChangePlanningStatus } = props;
    const { t } = useTranslate();
    const history = useHistory();
    const classes = useStyles();
    const plannningData = sessionService.getPlanningData();
    const [showSelectPlanModal, setShowSelectPlanModal] = useState(isEmpty(plannningData));

    function onCloseModal(hasSelectedPlan) {
        if (!isUndefined(hasSelectedPlan) || !hasSelectedPlan) {
            setShowSelectPlanModal(false); //hide modal
        }

        if (isUndefined(hasSelectedPlan)) { //cancel modal
            if ((window as any).isDeletedSelectedPlan) { //check deleted plan is the current selected
                delete (window as any).isDeletedSelectedPlan;

                if (isFunction(onChangePlanningStatus)) {
                    onChangePlanningStatus(false);
                }
                if (isFunction(onClose)) {
                    onClose(false);
                }
                closeUserMenu();
                return;
            }
            if (isFunction(onClose)) {
                onClose(!isEmpty(plannningData));
            }
            return;
        }

        if (hasSelectedPlan) {
            onSelectPlan(true); //hide active module list
        } else {
            onSelectPlan(false); //show active module list
        }
    }

    useEffect(() => {
        if (showSelectPlanModal && history) {
            const agentName = sessionService.getAgentName();
            history.push(`/${agentName}`);
        }
    }, [showSelectPlanModal, history]);

    return (
        <div className={classes.planningModeWrapper}>
            <span className={classes.contentTitles}>
                {t('userMenu.selectedPlan', 'Selected plan')}
            </span>
            {!isEmpty(plannningData) ? (
                <div className={classes.selectedPlanTextWrapper}>
                    <span className={classes.selectedPlanText} title={plannningData.selectedPlanName}>
                        {plannningData.selectedPlanName}
                    </span>
                </div>) : null
            }
            <Button className={classes.selectPlanBtn} type="secondary" onClick={() => setShowSelectPlanModal(!showSelectPlanModal)}>
                {t('userMenu.selectPlan', 'Select plan')}
            </Button>
            <SelectPlanModal show={showSelectPlanModal} closeModal={onCloseModal} />
        </div>
    );
}
