export const IMAGE = {
    PATH: '/styles/img',
    ITEM_PATH: {
        MACHINE: '/machine-profil-',
    },
    SIZE: {
        SMALL: 'small',
        MEDIUM: 'medium',
        FULL: 'full',
        BIG: 'big',
    },
    EXTENSION: {
        PNG: 'png',
        JPG: 'jpg',
    },
};
