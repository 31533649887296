import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import { dateTimeUtils } from 'shared/src/modules';
import { CalendarWithInput } from '@baseComponents/datepickers';
import Svg from '@baseComponents/Svg';
import ErrorMessage from '@baseComponents/formParts/ErrorMessage';
import Label from '@baseComponents/formParts/Label';
import useCalendarScroll from './useCalendarScroll';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        position: 'relative',
        display: 'inline-block',
        ...theme.formatBootstrapButton.secondary,
    },
    dateBtn: {
        width: 152,
        height: 40,
        borderRadius: 4,
        boxShadow: (props: any) => (props.invalid && !props.date ? theme.shadows.destructive.normal : theme.shadows.secondary.input),
        border: 'solid 1px',
        borderColor: (props: any) => (props.invalid && !props.date ? theme.color.destructive : theme.color.gray),
        backgroundColor: theme.color.white,
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 15px',
        fontFamily: 'Rubik',
        fontSize: 15,
        fontWeight: 300,
        color: theme.color.jet,
        '&:disabled': {
            color: (props: any) => props.showDate && theme.color.jet,
        },
    },
    icon: {
        width: 24,
        height: 24,
        marginLeft: 'auto',
        fill: theme.color.stone,
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: (props: any) => (props.date ? theme.color.main : theme.color.destructive),
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    popover: {
        zIndex: 9999999, //theme.zIndex.popover,
        boxShadow: theme.shadows.dropdown,
        backgroundColor: theme.color.white,
        borderRadius: 6,
        border: 'none',
        '& .arrow::before': {
            borderRightColor: theme.color.white,
            borderWidth: 8,
            top: -10,
            left: -20,
        },
        '& .popover-body': {
            padding: 0,
        },
    },
    label: {
        textAlign: 'left',
    },
}));

export default function FormDatePicker(props) {
    const { placement = 'right', date, setDate, label, required, className, errorMessage, invalid, minDate, maxDate, showDate, disabled = false, scrollRef, excludeDates } = props;
    const classes = useStyles({ date, invalid, showDate } as any);
    const [isCalendarOn, setIsCalendarOn] = useState(false);

    useCalendarScroll({ scrollRef, closeCalendar, isCalendarOn });

    function closeCalendar() {
        document.body.click();
    }

    return (
        <div className={clsx(classes.container, className)}>
            {label && <Label label={label} required={required} isValue={date} className={classes.label} />}
            <OverlayTrigger
                trigger="click"
                placement={placement}
                rootClose
                onToggle={() => setIsCalendarOn(!isCalendarOn)}
                overlay={
                    <Popover className={classes.popover}>
                        <Popover.Content>
                            <CalendarWithInput
                                date={dateTimeUtils.isValidDate(date) && dateTimeUtils.getDateObject(date)}
                                setDate={setDate}
                                minDate={minDate && dateTimeUtils.getDateObject(minDate)}
                                maxDate={maxDate && dateTimeUtils.getDateObject(maxDate)}
                                close={closeCalendar}
                                required={required}
                                excludeDates={excludeDates}
                            />
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button disabled={disabled} type="button" className={classes.dateBtn}>
                    {dateTimeUtils.isValidDate(date) && dateTimeUtils.getDate(date)}
                    <Svg style={classes.icon} iconId="icon-calendar" />
                </Button>
            </OverlayTrigger>
            <ErrorMessage invalid={invalid && !date} errorMessage={errorMessage} />
        </div>
    );
}
