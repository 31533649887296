import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import { theme as themes } from '@styles/theme';
import TableHeaderCell from './TableHeaderCell';
import useTableColumnWidths from './useTableColumnWidths';
import TableColumnOrder from './columnOrder/TableColumnOrder';
import ListRowTemplate from '../tableGroup/ListRowTemplate';

const useStyles = createUseStyles({
    row: {
        marginRight: 80,
        paddingBottom: 5,
    },
});

function TableHeaderRow(props) {
    const { leftSideWidth, rightSideWidth, leftFixedColumns, rightFixedColumns, mainColumns, handleMidScroll, isBasePageTable, useColumnSorter, useColumnSearcher } = props;
    const { tableKey } = props; //simple table
    const listRowRef = useRef(null);
    const classes = useStyles();

    useTableColumnWidths({ listRowRef, isBasePageTable, tableKey });

    return (
        <>
            <ListRowTemplate
                classes={classes}
                leftSideWidth={leftSideWidth}
                handleMidScroll={handleMidScroll}
                rightSideWidth={rightSideWidth}
                isHeaderRow
                listRowRef={listRowRef}
                faderHeight={themes.table.tableHeaderRowHeight}
                isBasePageTable={isBasePageTable}
                tableKey={tableKey}
                left={
                    !isEmpty(leftFixedColumns) && leftFixedColumns.map((lfColumn, idx) => (
                        <TableHeaderCell
                            key={idx}
                            column={lfColumn}
                            isBasePageTable={isBasePageTable}
                            tableKey={tableKey}
                            useColumnSorter={useColumnSorter}
                            useColumnSearcher={useColumnSearcher}
                            listRowRef={listRowRef}
                        />
                    ))
                }
                middle={
                    !isEmpty(mainColumns) && mainColumns.map((mnColumn, idx) => (
                        <TableHeaderCell
                            key={idx}
                            column={mnColumn}
                            isBasePageTable={isBasePageTable}
                            tableKey={tableKey}
                            useColumnSorter={useColumnSorter}
                            useColumnSearcher={useColumnSearcher}
                            listRowRef={listRowRef}
                            isMiddleColumnCell
                        />
                    ))
                }
                right={
                    !isEmpty(rightFixedColumns) && rightFixedColumns.map((rColumn, idx) => (
                        <TableHeaderCell
                            key={idx}
                            column={rColumn}
                            isBasePageTable={isBasePageTable}
                            useColumnSorter={useColumnSorter}
                            useColumnSearcher={useColumnSearcher}
                            tableKey={tableKey}
                            listRowRef={listRowRef}
                        />
                    ))
                }
            />
            {isBasePageTable && <TableColumnOrder columns={mainColumns} />}
        </>
    );
}

export default React.memo(TableHeaderRow);
