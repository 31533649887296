/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import { getAPIEndpoint } from 'shared/src/routing';
import { isFunction, isNull, isEmpty, isUndefined } from 'lodash';
import { errorActions } from '@states/actions/errorActions';
import { sessionService } from '@services';
import { routerHelper } from '@utils/router/RouterHelper';
import { phpHelper } from '@utils/php/PhpHelper';
import { setPageLoading } from '@baseComponents/PageLoader';

const useStyles = createUseStyles({
    modalContent: {
        overflowX: 'hidden !important',
        overflowY: 'hidden !important',
        '& .modal-content': {
            minWidth: 1200,
            height: 735,
        },
        '& .modal-dialog-centered': {
            maxWidth: '100%',
        },
    },
    modalHeader: {
        padding: '25px',
        paddingBottom: 12,
        display: 'block',
        borderBottom: 'none',
    },
    title: {
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    modalBody: {
        padding: 0,
    },
    bodyHeader: {
        fontSize: 16,
        textAlign: 'center',
    },
    modalFooter: {
        padding: 25,
        textAlign: 'center',
        display: 'block',
    },
});

export default function SelectPlanModal(props) {
    const { show, closeModal } = props;
    const location = useLocation().pathname;
    const history = useHistory();
    const dispatch = useDispatch();
    const [agentName] = useState(sessionService.getAgentName());
    const [pageContent] = useState('');
    const [isFirstLoad, setIsFirstLoad] = useState(true);
    const planningData = sessionService.getPlanningData();
    const classes = useStyles();

    function modalClose(planningDataObj, callHide) {
        const selectedPlanId = Number((window as any).selected_terv_id);
        const isSelectedPlan = Boolean((window as any).selected_terv);
        const selectedPlanIsClosed = Boolean((window as any).selected_terv_lezart);
        const selecteedPlanDepthId = +(window as any)?.selected_terv_melyseg_id || 1;
        const selectedPlanName = (window as any).selected_terv_nev ? (window as any).selected_terv_nev.toString() : '';

        if (selectedPlanId > 0 && isSelectedPlan) {
            sessionService.setPlanningData({ selectedPlanId, selectedPlanName, selectedPlanIsClosed, selecteedPlanDepthId });

            if (isFunction((window as any).reloadPhpContent)) {
                (window as any).reloadPhpContent();
            }

            callHide(true);
        } else if (!planningDataObj) {
            callHide(false);
        } else {
            callHide();
        }
    }

    useEffect(() => {
        async function loadContent() {
            try {
                if (!document.getElementById('selectPlanModalContent')) {
                    return;
                }
                // load php content
                $.ajax({
                    type: 'POST',
                    url: '/modules/tervezes/view/terv_lista_modal.php',
                    dataType: 'text',
                    success: tervListaModalContent => {
                        //set dom
                        try {
                            $('#selectPlanModalContent').html(tervListaModalContent);

                            if (isFunction((window as any).create_tervezes_grid_modal)) {
                                (window as any).create_tervezes_grid_modal();
                            }

                            transformPhp();
                            setPageLoading(false);
                        } catch (error) {
                            // eslint-disable-next-line no-console
                            console.error(error);
                        }
                    },
                    error: (__, error) => {
                        // eslint-disable-next-line no-console
                        console.log('Hibrid php ajax loader error: ', error);
                    },
                });

                transformPhp();
                setPageLoading(false);
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }

        function transformPhp() {
            function disablePhpHrefs() {
                const planModalElement = document.getElementById('selectPlanModalContent');
                if (planModalElement) {
                    planModalElement.querySelectorAll("a[href^='#']")?.forEach(node => {
                        node.addEventListener('click', event => {
                            const href = phpHelper.getContentHref(event.target);
                            if (href) {
                                event.preventDefault();
                                phpChangeLocation(href);
                            }
                        });
                    });
                }
            }

            function modalDismissEventHandler() {
                const planModalElement = document.getElementById('selectPlanModalContent');
                if (planModalElement) {
                    planModalElement.querySelectorAll("a[data-dismiss='modal']")?.forEach(node => {
                        node.addEventListener('click', event => {
                            event.preventDefault();
                            if (event) {
                                modalClose(planningData, closeModal);
                            }
                        });
                    });
                }
            }

            function phpChangeLocation(phpRedirectUrl) {
                if (!isNull(phpRedirectUrl)) {
                    const hash = routerHelper.getHashFromLocation(phpRedirectUrl);
                    if (!isNull(hash)) {
                        const redirectUrl = getAPIEndpoint(hash, agentName);
                        if (redirectUrl) {
                            history.push(redirectUrl);
                        }
                    }
                }
            }

            disablePhpHrefs();
            modalDismissEventHandler();

            //searchbar format
            const searchBar = document.getElementById('search_terv');
            if (searchBar) {
                const searchBarRoot = searchBar?.parentElement?.parentElement?.parentElement;
                if (searchBarRoot) {
                    searchBarRoot.style.marginRight = '20px';
                    searchBarRoot.style.marginBottom = '0px';
                }
            }
            setPageLoading(false);
        }

        if ($ && show && agentName && history && location && closeModal && ((isFirstLoad && !planningData) || (!isFirstLoad && planningData) || (isFirstLoad && planningData))) {
            setPageLoading(true);
            loadContent();
            setIsFirstLoad(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$, show, agentName, history, dispatch]);

    useEffect(() => {
        if (!isEmpty(pageContent)) {
            phpHelper.changeSearchBarFormat();
            setPageLoading(false);
        }
    }, [pageContent, history]);

    (window as any).reactCloseRequest = () => {
        modalClose(planningData, closeModal);
    };

    function closeRequest(event) {
        if (isNull(event) || isUndefined(event)) { //ignore outside click
            return;
        }
        modalClose(planningData, closeModal);
    }

    return (
        <Modal id="selectPlanModal" show={show} onHide={closeRequest} aria-labelledby="contained-modal-title-vcenter" centered className={classes.modalContent} backdrop="static" keyboard={false} restoreFocus={false} enforceFocus={false}>
            <Modal.Body className={classes.modalBody}>
                <div id="selectPlanModalContent" />
            </Modal.Body>
        </Modal>
    );
}
