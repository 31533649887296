import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { isUndefined, isEmpty } from 'lodash';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { useFormikContext } from 'formik';
import { PARTNER } from 'shared/src/constants';
import useTranslate from '@i18n/useTranslate';
import { useSelector, useDispatch } from 'react-redux';
import { stringUtils } from 'shared/src/modules/stringUtils';
import FormSelect from '@baseComponents/select/formSelect/FormSelect';
import SimpleSelect from '@baseComponents/select/simpleSelect/SimpleSelect';

import { errorActions } from '@states/actions/errorActions';
import { partnerService } from '@services';

const useStyles = createUseStyles((theme: any) => ({
    ownerSelects: {
        display: 'flex',
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
        marginTop: 15,
    },
    ownersDot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: (props: any) => (props.owner ? theme.color.main : theme.color.destructive),
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    select: {
        '& .dropdown-menu': {
            transform: 'translate(0px, 42px) !important',
        },
        '& .dropdown-item': {
            height: 40,
            padding: '10px 15px',
            ...theme.overFlowText,
        },
    },
    ownerSelect: {
        '& .dropdown-toggle': {
            minWidth: 198,
            width: 198,
        },
    },
    ownerType: {
        marginRight: 10,
        '& .dropdown-menu, .dropdown-toggle': {
            minWidth: 104,
            width: 104,
        },
    },
}));
export default function OwnerSelector(props) {
    const { isNonAccentSearch = false } = props;

    const { t } = useTranslate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [defaultOwnerList, setDefaultOwnerList] = useState<any>();
    const [ownerList, setOwnerList] = useState<any>();
    const [filteredOwnerList, setFilteredOwnerList] = useState<any>();
    const companyIds = useSelector((state: any) => state.company.activeCompanyIds);
    const { values, errors, setFieldValue } = useFormikContext();

    const [partnerOrCompanyList] = useState([
        {
            value: t('ownerSelect.company', 'Company'),
            key: PARTNER.TYPES.COMPANY,
        },
        {
            value: t('ownerSelect.partner', 'Partner'),
            key: PARTNER.TYPES.PARTNER,
        },
    ]);

    const classes = useStyles({ owner: (values as any).owner });

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const partnerAndCompany = await partnerService.getPartnersAndCompanies({ companyIds, hasFieldRights: true });

                if (!isCancelled) {
                    setDefaultOwnerList(partnerAndCompany);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'OwnerSelector'));
            }
        }

        if (companyIds && location) {
            loadData();
        }
        return () => {
            isCancelled = true;
        };
    }, [companyIds, dispatch, location]);

    useEffect(() => {
        if ((values as any).ownerType && !isUndefined(defaultOwnerList)) {
            let companiesAndPartners = defaultOwnerList?.filter(companyOrPartner => {
                switch (+companyOrPartner?.typeId) {
                    case PARTNER.TYPES.PARTNER: //partner
                        return +companyOrPartner?.typeId === +(values as any)?.ownerType && companyOrPartner?.isWagework;
                    case PARTNER.TYPES.COMPANY: //cég
                        return +companyOrPartner?.typeId === +(values as any)?.ownerType;
                    case PARTNER.TYPES.DISCTRICT: //kerület
                        return +(values as any)?.ownerType === PARTNER.TYPES.COMPANY;
                    default:
                        return +companyOrPartner?.typeId === +(values as any)?.ownerType;
                }
            });
            companiesAndPartners = companiesAndPartners?.map(companyObj => ({ value: companyObj.name, key: companyObj.id, typeId: companyObj.typeId }));

            setOwnerList(companiesAndPartners);
            setFilteredOwnerList(companiesAndPartners);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(values as any).ownerType, defaultOwnerList]);

    function searchOwner(value) {
        if (!isEmpty(value) && !isEmpty(ownerList)) {
            setFilteredOwnerList(ownerList.filter(ownerObj => stringUtils.includesNoCase(ownerObj.value, value, isNonAccentSearch)));
        } else {
            setFilteredOwnerList(ownerList);
        }
    }

    function setOwnerType(value) {
        setFieldValue('ownerType', +value);
        setFieldValue('owner', '');
    }

    return (
        <>
            <div className={classes.label}>
                {t('ownerSelect.categorizeCompanyPartner', 'Categorize company and partner')}
                <span className={classes.ownersDot} />
            </div>
            <div className={classes.ownerSelects}>
                <SimpleSelect
                    dataList={partnerOrCompanyList}
                    selected={(values as any).ownerType}
                    onChange={value => setOwnerType(value)}
                    className={clsx(classes.ownerType, classes.select)}
                    disabled={!!(values as any).workoperationNumber}
                />
                <FormSelect
                    dataList={filteredOwnerList}
                    selected={(values as any).owner}
                    onChange={value => setFieldValue('owner', +value)}
                    search={searchOwner}
                    className={clsx(classes.select, classes.ownerSelect)}
                    invalid={!!(errors as any).owner}
                    errorMessage={(errors as any).owner}
                    disabled={!!(values as any).workoperationNumber}
                />
            </div>
        </>
    );
}
