import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    dropdown: {
        display: (props: any) => (props.show ? 'block' : 'none'),
        position: 'absolute',
        borderRadius: 6,
        boxShadow: theme.shadows.dropdown,
        backgroundColor: theme.color.white,
        bottom: 'auto',
        top: 70,
        left: '50%',
        transform: 'translateX(-50%)',
        outline: 'none',
        zIndex: theme.zIndex.listDropdown,
        '&::after': {
            display: (props: any) => (props.isAfter ? 'block' : 'none'),
            content: '""',
            position: 'absolute',
            left: '50%',
            bottom: '100%',
            marginLeft: '-10px',
            borderWidth: '8px',
            borderStyle: 'solid',
            borderColor: `transparent transparent ${theme.color.white} transparent`,
        },
    },
}));

export default function Dropdown(props) {
    const { show, children, className, isAfter, coords } = props;
    const classes = useStyles({ show, isAfter });

    return (
        <div className={clsx(classes.dropdown, className)} style={{ ...coords }}>
            {children}
        </div>
    );
}
