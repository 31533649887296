import { RIGHTS } from '../constants';

export const FINANCIAL_FLOWS = {
    MODULE_ID: 36,
    NAME: 'financialFlows',
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'financialFlows',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.invoiceTransfer',
                TRANSLATOR_DEFAULT: 'Invoice transfer',
            },
        },
        TRANSFER: {
            CONTENT_URL_ID: { transfer: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'transfer',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.transfer',
                TRANSLATOR_DEFAULT: 'Transfer',
            },
            RIGHTS: [RIGHTS.TRANSFER_INTERFACE_ACCESS_EDIT],
        },
        TRANSFER_EDIT: {
            CONTENT_URL_ID: { transferEdit: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'transferEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.transferEdit',
                TRANSLATOR_DEFAULT: 'Transfer edit',
            },
            RIGHTS: [RIGHTS.TRANSFER_INTERFACE_ACCESS_EDIT],
        },
        CREDITIGS: {
            CONTENT_URL_ID: { creditings: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'creditings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.creditings',
                TRANSLATOR_DEFAULT: 'Creditings',
            },
            RIGHTS: [RIGHTS.CREDITINGS_INTERFACE_ACCESS_EDDIT],
        },
        CREDITING_EDIT: {
            CONTENT_URL_ID: { creditingEdit: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'creditingEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.creditingEdit',
                TRANSLATOR_DEFAULT: 'Crediting edit',
            },
            RIGHTS: [RIGHTS.CREDITINGS_INTERFACE_ACCESS_EDDIT],
        },
    },
};
