import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { NoDataCard } from '@baseComponents/tableNew';

const useStyles = createUseStyles((theme: any) => ({
    contentWrapper: {

    },
    title: {
        fontSize: 14,
        fontWeight: 500,
        //borderBottom: `1px solid ${theme.color.gray}`,
        marginBottom: 10,
    },
    content: {

    },
    dataRow: {
        display: 'flex',
        flexDireection: 'row',
        borderBottom: `1px solid ${theme.color.lilian}`,
    },
    firstColumn: {
        fontWeight: 400,
    },
    column: {
        fontSize: 12,
        marginRight: 10,
        width: props => (props.columnWidth ? `${props.columnWidth}%` : 'auto'),
    },
}));

export default function PopoverContentCell(props) {
    const { title, dataList, headerTitle, columnNumber } = props;
    const [columns] = useState(columnNumber || Object.values(dataList?.[0])?.length);
    const classes = useStyles({ columnWidth: 100 / columns });

    return (
        <div className={classes.contentWrapper}>
            <div className={classes.title}>
                {title}
            </div>
            <div className={classes.content}>
                {!isEmpty(headerTitle) && (
                    <div className={classes.dataRow}>
                        {headerTitle?.map((dataRow, index) => (
                            <div key={index} className={classes.column}>{dataRow}</div>
                        ))}
                    </div>
                )}
                {isEmpty(dataList)
                    ? <NoDataCard />
                    : dataList?.map((dataRow, index) => (
                        <div key={index} className={classes.dataRow}>
                            {!isEmpty(dataRow.value1) && <div className={clsx(classes.column, classes.firstColumn)}>{dataRow.value1}</div>}
                            {(!isEmpty(dataRow.value2) || columnNumber > 1) && <div className={classes.column}>{dataRow.value2}</div>}
                            {(!isEmpty(dataRow.value3) || columnNumber > 2) && <div className={classes.column}>{dataRow.value3}</div>}
                        </div>
                    ))
                }
            </div>
        </div>
    );
}
