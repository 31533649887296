import { ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';

type Classes = 'container' | 'titleText' | 'dataText';

const useStyles = createUseStyles<Classes, unknown, ThemeType>({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    titleText: {
        width: 80,
        fontSize: 14,
    },
    dataText: {
        fontSize: 14,
    },
});

type TProps = {
    dataText: string,
    titleText: string
}

export const MeasureToolInfoRow = ({ dataText, titleText }: TProps) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <span className={classes.titleText}>{`${titleText}:`}</span>
            <span className={classes.dataText}>{dataText}</span>
        </div>
    );
};
