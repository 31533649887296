import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import Svg from '../../Svg';

const useStyles = createUseStyles((theme: any) => ({
    toggleButton: {
        display: 'flex',
        width: '100%',
        height: 40,
        lineHeight: '40px',
        border: '1px solid',
        borderRadius: 4,
        verticalAlign: 'middle',
        ...theme.disableOutline,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.color.white,
            borderColor: theme.color.gray,
        },
        '&.btn-primary.dropdown-toggle': {
            borderColor: theme.color.gray,
            color: theme.color.jet,
            backgroundColor: theme.color.white,
            boxShadow: theme.shadows.secondary.normal,
            '&:active': {
                color: theme.color.jet,
                backgroundColor: theme.color.white,
                borderColor: theme.color.main,
            },
            '&:focus': {
                boxShadow: theme.shadows.primary.focus,
                borderColor: theme.color.main,
            },
        },
    },
    toggleText: {
        fontSize: 15,
        height: '100%',
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        fontWeight: 400,
        color: theme.color.jet,
        ...theme.overFlowText,
        ...theme.disableSelectionColor,
    },
    iconContainer: {
        marginRight: 15,
    },
    toggleIcon: {
        height: 24,
        width: 24,
        margin: 'auto',
        verticalAlign: 'middle',
    },
}));

export default function DropdownToggleButton(props) {
    const { text, iconId, isBeforeIcon = true, children, className } = props;
    const classes = useStyles();

    return (
        <Dropdown.Toggle className={clsx(classes.toggleButton, className)}>
            {iconId && isBeforeIcon && (
                <span id="iconContainer" className={classes.iconContainer}>
                    <Svg iconId={iconId} style={classes.toggleIcon} />
                </span>
            )}

            <span id="toggleText" className={classes.toggleText}>
                {text || children}
            </span>

            {iconId && !isBeforeIcon && <Svg iconId={iconId} style={classes.toggleIcon} />}
        </Dropdown.Toggle>
    );
}
