import { RIGHTS, COUNTRY, SETTINGS } from '../constants';

export const CROP_MANAGEMENT = {
    MODULE_ID: 7,
    NAME: 'cropManagement',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cropManagement',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.cropManagement',
                TRANSLATOR_DEFAULT: 'Crop management',
            },
        },
        STORAGE_ACCOUNT: {
            CONTENT_URL_ID: { storageAccount: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'storageAccount',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.storageAccount',
                TRANSLATOR_DEFAULT: 'Storage account',
            },
            RIGHTS: [RIGHTS.STORAGE_ACCOUNT_INTERFACE],
        },
        STORAGE_ACCOUNT_EDIT: {
            CONTENT_URL_ID: { storageAccountEdit: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'storageAccountEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.storageAccountEdit',
                TRANSLATOR_DEFAULT: 'Storage account edit',
            },
            RIGHTS: [RIGHTS.CREATE_STORAGE_ACCOUNT],
        },
        ACCOUNTING_SYSTEM_OF_CROP_DRYING: {
            CONTENT_URL_ID: { accountingSystemOfCropDrying: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'accountingSystemOfCropDrying',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.accountingSystemOfCropDrying',
                TRANSLATOR_DEFAULT: 'Accounting system of crop drying',
            },
            RIGHTS: [RIGHTS.ACCOUNTING_SYSTEM_OF_CROP_DRYING_INTERFACE],
        },
        ACCOUNTING_SYSTEM_OF_CROP_DRYING_EDIT: {
            CONTENT_URL_ID: { accountingSystemOfCropDryingEdit: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'accountingSystemOfCropDryingEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.accountingSystemOfCropDryingEdit',
                TRANSLATOR_DEFAULT: 'Accounting system of crop drying edit',
            },
            RIGHTS: [RIGHTS.CREATE_ACCOUNTING_SYSTEM_OF_CROP_DRYING],
        },
        TEMPLATES_FOR_ACCOUNTING_OF_CROP_DRYING: {
            CONTENT_URL_ID: { templatesForAccountingOfCropDrying: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'templatesForAccountingOfCropDrying',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.templatesForAccountingOfCropDrying',
                TRANSLATOR_DEFAULT: 'Templates for accounting of crop drying',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.CROP_DRYING_TEMPLATE_INTERFACE], //settinsMenu
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        STORGAE_ACCOUNTING_TEMPLATES: {
            CONTENT_URL_ID: { storageAccountingTemplates: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'storageAccountingTemplates',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.storageAccountingTemplates',
                TRANSLATOR_DEFAULT: 'Storage accounting templates',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.STORAGE_ACCOUNTING_TEMPLATE_INTERFACE], //settinsMenu
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        CROP_DRYING_FEE: {
            CONTENT_URL_ID: { cropDryingFee: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cropDryingFee',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cropDryingFee',
                TRANSLATOR_DEFAULT: 'Crop drying fee',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.CROP_DRYING_FEES_INTERFACE], //settinsMenu
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        DEFAULT_DRYING_DATA: {
            CONTENT_URL_ID: { defaultDryingData: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'defaultDryingData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.defaultDryingData',
                TRANSLATOR_DEFAULT: 'Default drying data',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.DEFAULT_DRYING_DATA_INTERFACE], //settinsMenu
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        AGRICULTURAL_PRODUCTION_CONTRACTS: {
            CONTENT_URL_ID: { agriculturalProductionContracts: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'agriculturalProductionContracts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cropSalesContracts',
                TRANSLATOR_DEFAULT: 'Crop sales contracts',
            },
            RIGHTS: [RIGHTS.CROP_SALES_CONTRACTS_INTERFACE_ACCESS_EDIT],
        },
        AGRICULTURAL_PRODUCTION_CONTRACTS_EDIT: {
            CONTENT_URL_ID: { agriculturalProductionContractsEdit: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'agriculturalProductionContractsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cropsSalesContractsEdit',
                TRANSLATOR_DEFAULT: 'New crop sales contract',
            },
            RIGHTS: [RIGHTS.CROP_SALES_CONTRACTS_INTERFACE_ACCESS_EDIT],
        },
        CROP_SALES_DOCUMENT_TEMPLATES: {
            CONTENT_URL_ID: { cropSalesDocumentTemplates: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cropSalesDocumentTemplates',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cropSalesDocumentTemplates',
                TRANSLATOR_DEFAULT: 'Crop sales document templates',
            },
            RIGHTS: [RIGHTS.ACCESS_CROP_SALES_CONTRACT_TEMPLATES_INTERFACE],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        WORK_OPERATION_BLOCK: {
            CONTENT_URL_ID: { contractSets: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'contractSets',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.contractSets',
                TRANSLATOR_DEFAULT: 'Contract sets',
            },
            RIGHTS: [
                RIGHTS.CROP_SALES_CONTRACTS_INTERFACE_ACCESS_EDIT,
                RIGHTS.ACCESS_EDIT_CROP_PURCHASE_CONTRACT_INTERFACE,
            ],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        CROP_SALES_CONTRACT_TEMPLATES: {
            CONTENT_URL_ID: { cropPurchaseDocumentTemplate: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cropPurchaseDocumentTemplate',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cropPurchaseDocumentTemplate',
                TRANSLATOR_DEFAULT: 'Crop purchase document template',
            },
            RIGHTS: [RIGHTS.ACCESS_CROP_PURCHASE_CONTRACT_TEMPLATES_INTERFACE],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        CROP_PURCHASE_CONTRACTS: {
            CONTENT_URL_ID: { cropPurchaseContracts: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cropPurchaseContracts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cropPurchaseContracts',
                TRANSLATOR_DEFAULT: 'Crop purchase contracts',
            },
            RIGHTS: [RIGHTS.ACCESS_EDIT_CROP_PURCHASE_CONTRACT_INTERFACE],
        },
        CROP_PURCHASE_CONTRACTS_EDIT: {
            CONTENT_URL_ID: { defaultDryingDataEdit: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'defaultDryingDataEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.defaultDryingDataEdit',
                TRANSLATOR_DEFAULT: 'Crop purchase contracts',
            },
            RIGHTS: [RIGHTS.ACCESS_EDIT_CROP_PURCHASE_CONTRACT_INTERFACE],
        },
        SUSTAINABILITY_STATEMENTS: {
            CONTENT_URL_ID: { sustainabilityStatements: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'sustainabilityStatements',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Fenntarthatósági nyilatkozatok',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.ACCESSING_EDIT_SUSTAINABILITY_SURFACE_INTERFACE],
            SETTINGS: [SETTINGS.SUSTAINABILITY_STATEMENTS],
        },
        SUSTAINABILITY_STATEMENTS_EDIT: {
            CONTENT_URL_ID: { sustainabilityStatementsEdit: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'sustainabilityStatementsEdit',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Fenntarthatósági nyilatkozat szerkesztése',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.ACCESSING_EDIT_SUSTAINABILITY_SURFACE_INTERFACE],
        },
        DECLARATION: {
            CONTENT_URL_ID: { declaration: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'declaration',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Szándéknyilatkozatok',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.ACCESSING_EDIT_DECLARATION_SURFACE_INTERFACE],
            SETTINGS: [SETTINGS.DECLARATION],
        },
        DECLARATION_EDIT: {
            CONTENT_URL_ID: { declarationEdit: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'declarationEdit',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Új szándéknyilatkozat',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.ACCESSING_EDIT_DECLARATION_SURFACE_INTERFACE],
            SETTINGS: [SETTINGS.DECLARATION],
        },
        STOCK_DISTRIBUTION: {
            CONTENT_URL_ID: { stockDistribution: 21 },
            COMPONENT_URL: 'contents/cropManagement/stockDistribution/StockDistribution',
            EVENT_KEY: 'stockDistribution',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockDistribution',
                TRANSLATOR_DEFAULT: 'Stock distribution',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.ACCESSING_STOCK_DISTRIBUTION_INTERFACE],
            SETTINGS: [SETTINGS.STOCK_DISTRIBUTION],
        },
    },
};
