import Button from '@baseComponents/buttons/Button';
import { SimpleSelectOverFlow } from '@baseComponents/select';
import { FormDatePicker } from '@customComponents/datePickers';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInAttributeTypes, BuiltInLayerTypes } from '@map/services/mapEnums';
import { Layer, ThemeType } from '@map/services/mapTypes';
import { isNil } from 'lodash';
import { dateTimeUtils } from 'shared/src/modules';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import ReactSlider from 'react-slider';
import { MapSliderContainer, MapSliderContainerRef } from './components/MapSliderContainer';
import { MapSliderIntervalMode } from './MapSliderTypes';

type Classes = 'container' | 'playButton' | 'sliderModeSelector';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    container: {
        width: '100%',
        height: 50,
        display: 'flex',
        flexDirection: 'row',
        background: '#454F54',
        alignItems: 'center',
        overflowX: 'hidden',
        overflowY: 'hidden',
        padding: [0, 5],
    },
    sliderModeSelector: {
        margin: [0, 5],
        width: 130,
        color: theme.color.white,
        display: 'relative',
        '& .dropdown-menu': {
            position: 'relative !important',
        },
    },
    playButton: {
        width: 40,
        height: 40,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: [0, 5],
        background: theme.color.jet,
        boxShadow: 'none',
        borderColor: theme.color.black,
        borderWidth: 1,
        '&:hover': {
            background: '#fb9404',
            boxShadow: 'none',
        },
    },
}));

export function MapSlider() {
    const classes = useStyles();
    const [store, controller] = useMapContext();
    const [pickedDate, setPickedDate] = useState<Date>(dateTimeUtils.getMomentObjectWithDate().startOf('day').toDate());
    const mapSliderContainerRef = useRef<MapSliderContainerRef>(null);
    const [mapSliderMode, setMapSliderModer] = useState(MapSliderIntervalMode.YEAR);

    const { t, translater } = useTranslate();

    const modeSelectorData = [
        {
            key: 1,
            value: translater('map.sliderYear', 'Year'),
        },
        {
            key: 2,
            value: translater('map.sliderMonth', 'Month'),
        },
        {
            key: 3,
            value: translater('map.sliderDay', 'Day'),
        },
    ];

    const onChangeMapSliderMode = (mode: MapSliderIntervalMode) => {
        setMapSliderModer(mode);
        controller.setMapSliderIntervalMode((mode as MapSliderIntervalMode));
    };

    const onMapSliderChange = async (pickedStartDate: Date, pickedEndDate?: Date) => {
        setPickedDate(pickedStartDate);
        controller.reloadAttributesByInterval(pickedStartDate, pickedEndDate);
    };

    const movePickedDate = (distance: number) => {
        mapSliderContainerRef.current?.moveDay(distance);
    };

    const movePickedDateToday = useCallback(() => {
        const today = dateTimeUtils.getMomentObjectWithDate().startOf('day').toDate();
        setPickedDate(today);
        controller.reloadAttributesByInterval(today);
    }, [controller]);

    useEffect(() => {
        movePickedDateToday();
    }, [movePickedDateToday]);

    const calculateDateHasDate = (date: Date): boolean => controller.anyActiveAttributehasDataOnInterval(date);

    useEffect(() => {
        mapSliderContainerRef.current?.invalidateCache();
    }, [store.layering.order]);

    const minMaxDateHasChanged = async (min: Date, max: Date, mode: MapSliderIntervalMode) => {
        const layersToLoad: Layer[] = [];

        const cultivationPeriodLayer = store.layering?.layers?.find(layer => layer.name === BuiltInLayerTypes.CULTIVATION_PERIOD);
        const meteorologyLayer = store.layering?.layers?.find(layer => layer.name === BuiltInLayerTypes.METEOROLOGY);
        const completedAreaPercentageAttributre = store.layering.attributes?.find(attr => attr.layerName === BuiltInLayerTypes.CULTIVATION_PERIOD && attr.attributeKey === BuiltInAttributeTypes.LAST_WORK_OPERATION_MAIN_GROUP);
        const hasMeterologyAttr = store.layering.attributes?.some((attr => attr.layerName === BuiltInLayerTypes.METEOROLOGY && attr.isActive));

        if (!isNil(completedAreaPercentageAttributre) && !isNil(completedAreaPercentageAttributre.isActive)) {
            if (completedAreaPercentageAttributre.isActive) {
                if (!isNil(cultivationPeriodLayer)) {
                    layersToLoad.push(cultivationPeriodLayer);
                }
            }
        }

        if (!isNil(meteorologyLayer) && hasMeterologyAttr) {
            layersToLoad.push(meteorologyLayer);
        }

        if (layersToLoad.length > 0) {
            await controller.getAdditionalDataForLayer(layersToLoad);
            if (!isNil(store.mapSlider) && !isNil(store.mapSlider.startDate) && !isNil(store.mapSlider.endDate)) {
                controller.reloadAttributesByInterval(store.mapSlider.startDate, store.mapSlider.endDate);
            }
        }
    };

    return (
        <div className={classes.container}>
            <SimpleSelectOverFlow
                dataList={modeSelectorData}
                selected={mapSliderMode}
                placement="top-start"
                className={classes.sliderModeSelector}
                setSelected={(id: number) => onChangeMapSliderMode(Number(id))}
            />
            {/* <Button iconId="icon-play" className={classes.playButton} onClick={() => { mapSliderContainerRef.current?.invalidateCache(); }} /> */}
            <MapSliderContainer
                ref={mapSliderContainerRef}
                pickedDate={pickedDate}
                mapSliderMode={Number(mapSliderMode)}
                onChange={onMapSliderChange}
                withDataViewer
                calcDateHasDate={date => calculateDateHasDate(date)}
                minMaxDateHasChanged={minMaxDateHasChanged}
            />
            <Button className={classes.playButton} onClick={movePickedDateToday}>Ma</Button>
            <FormDatePicker
                date={pickedDate}
                invalid={false}
                errorMessage="hiba"
                placement="top"
                showDate
                setDate={date => {
                    const newPickeDate = dateTimeUtils.getMomentObjectWithDate().startOf('day').toDate();
                    setPickedDate(newPickeDate);
                    controller.reloadAttributesByInterval(newPickeDate);
                }}
            />
            <Button iconId="icon-arrow_back" className={classes.playButton} onClick={() => movePickedDate(-1)} />
            <Button iconId="icon-arrow_forward" className={classes.playButton} onClick={() => movePickedDate(1)} />
        </div>

    );
}
