import React from 'react';
import { createUseStyles } from 'react-jss';
import { isUndefined } from 'lodash';

import { LoadSpinner } from '@baseComponents/loadSpinner';
import { SimpleSelect } from '@baseComponents/select';
import useReceivingWarehouse from '@customComponents/dropdowns/receivingWarehouseDropdown/useReceivingWarehouse';

const useStyles = createUseStyles({
    receivingWarehouseDropdownWrapper: {
        display: 'block',
        position: 'relative',
    },
});

export default function ReceivingWarehouseDropdown(props) {
    const { warehouseId, setWarehouseId, className } = props;
    const { companyIds, typeIds } = props; //filter props
    const classes = useStyles();

    const { selectedWarehouseId, filteredWarehouseList, changeWarehouse, searchWarehouse } = useReceivingWarehouse({
        warehouseId,
        setWarehouseId,
        //filters
        companyIds,
        typeIds,
    });

    return (
        <div className={classes.receivingWarehouseDropdownWrapper}>
            <LoadSpinner everyValues={[companyIds, filteredWarehouseList]} orValue={isUndefined(companyIds)} isAutoDetect />
            <SimpleSelect
                selected={selectedWarehouseId}
                dataList={filteredWarehouseList}
                onChange={changeWarehouse}
                search={searchWarehouse}
                disabled={isUndefined(companyIds)}
                className={className}
            />
        </div>
    );
}
