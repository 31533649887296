import { RIGHTS } from '../constants';

export const REPORTS = {
    MODULE_ID: 16,
    NAME: 'reports',
    RIGHTS: [
        RIGHTS.REPORT_ACCESS_CONTRACTED_OPERATION_GROUP,
        RIGHTS.REPORT_ACCESS_STOCK_GROUP,
        RIGHTS.REPORT_ACCESS_OFFICIAL_DOCUMENTS_GROUP,
        RIGHTS.REPORT_ACCESS_ECONOMIC_UNIT_GROUP,
        RIGHTS.REPORT_ACCESS_LABOR_GROUP,
        RIGHTS.REPORT_ACCESS_LAND_ADMINISTRATION_GROUP,
        RIGHTS.REPORT_ACCESS_EQUIPMENT_GROUP,
        RIGHTS.REPORT_ACCESS_SCALE_GROUP,
        RIGHTS.REPORT_ACCESS_FIELD_RECORDS_GROUP,
        RIGHTS.REPORT_ACCESS_LIVESTOCK_BREEDING_GROUP,
        RIGHTS.REPORT_ACCESS_EMPLOYMENT,
    ],
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'reports',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.reports',
                TRANSLATOR_DEFAULT: 'Reports',
            },
            RIGHTS: [
                RIGHTS.REPORT_ACCESS_LIVESTOCK_BREEDING_GROUP,
                RIGHTS.REPORT_ACCESS_CONTRACTED_OPERATION_GROUP,
                RIGHTS.REPORT_ACCESS_EQUIPMENT_GROUP,
                RIGHTS.REPORT_ACCESS_EMPLOYMENT,
                RIGHTS.REPORT_ACCESS_LAND_ADMINISTRATION_GROUP,
                RIGHTS.REPORT_ACCESS_ECONOMIC_UNIT_GROUP,
                RIGHTS.REPORT_ACCESS_OFFICIAL_DOCUMENTS_GROUP,
                RIGHTS.REPORT_ACCESS_STOCK_GROUP,
                RIGHTS.REPORT_ACCESS_SCALE_GROUP,
                RIGHTS.REPORT_ACCESS_LABOR_GROUP,
                RIGHTS.REPORT_ACCESS_FIELD_RECORDS_GROUP,
            ],
        },
        REPORTS: {
            CONTENT_URL_ID: { reports: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'report',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.reports',
                TRANSLATOR_DEFAULT: 'Reports',
            },
        },
        SETTINGS: {
            CONTENT_URL_ID: { settings: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'reportSettings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.reportSettings',
                TRANSLATOR_DEFAULT: 'Report settings',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        GENERATED_DOCUMENTS: {
            CONTENT_URL_ID: { generatedDocuments: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generatedDocuments',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.generatedDocuments',
                TRANSLATOR_DEFAULT: 'Generated documents',
            },
        },
        FAVORITE_REPORTS: {
            CONTENT_URL_ID: { favoriteReports: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'favoriteReports',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.favoriteReports',
                TRANSLATOR_DEFAULT: 'Favorite reports',
            },
        },
    },
};
