import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isArray } from 'lodash';

export default function useTableFixedFooter(props) {
    const { isBasePageTable, tableKey } = props;

    const [initValues, setInitValues] = useState({
        aggregateType: null,
        columnWidths: null,
        filteredDatas: null,
    });

    const aggregateType = useSelector((state: any) => state.basePage.table.aggregateType);
    const columnWidths = useSelector((state: any) => state.basePage.table.headerColumnWidths);
    const filteredDatas = useSelector((state: any) => state.basePage.table.filteredDatas);

    const simpleTableAggregateType = useSelector((state: any) => state.simpleTable?.table[tableKey]?.aggregateType);
    const simpleTableColumnWidths = useSelector((state: any) => state.simpleTable?.table[tableKey]?.columnWidths);
    const simpleTableFilteredDatas = useSelector((state: any) => state.simpleTable?.table[tableKey]?.filteredDatas);

    //init basePage table data
    useEffect(() => {
        if (isBasePageTable) {
            setInitValues({
                aggregateType,
                columnWidths,
                filteredDatas,
            });
        }
    }, [isBasePageTable, aggregateType, columnWidths, filteredDatas]);

    //init simple table data
    useEffect(() => {
        if (!isBasePageTable) {
            const dataArray: any = {};

            if (isArray(simpleTableFilteredDatas)) {
                simpleTableFilteredDatas?.forEach(dataGroup => {
                    dataArray[dataGroup.groupName] = dataGroup.groupArray;
                });
            }

            setInitValues({
                aggregateType: simpleTableAggregateType,
                columnWidths: simpleTableColumnWidths,
                filteredDatas: dataArray,
            });
        }
    }, [isBasePageTable, simpleTableAggregateType, simpleTableColumnWidths, simpleTableFilteredDatas]);

    return initValues;
}
