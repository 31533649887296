import React from 'react';
import { createUseStyles } from 'react-jss';
import { toLower, upperFirst } from 'lodash';
import { useTranslation } from 'react-i18next';
import { languageUtils } from 'shared/src/modules';
import Svg from '../../Svg';

const useStyles = createUseStyles((theme: any) => ({
    columnNameButton: {
        borderRadius: 12,
        height: 24,
        width: 'auto',
        //maxWidth: '100%',
        //display: 'inline-block',
        //margin: 0,
        //marginRight: props => props.isSortable && 10,
        border: 'none',
        backgroundColor: theme.color.pearl,
        padding: 0,
        '&:hover': {
            border: 'none',
        },
        '&:focus': {
            outline: 'none',
        },
        lineHeight: '40px !important',
        maxWidth: 'min-content',
        color: theme.color.stone,
        fontSize: 12,
        fontWeight: 500,
        display: 'block',
        margin: 'auto',
        marginLeft: 0,
        marginRight: 0,
        ...theme.firstLetterUppercase,
        ...theme.overFlowText,
    },
    content: {
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        height: '100%',
        alignItems: 'center',
    },
    title: {
        display: 'inline-block',
        height: 20,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        color: theme.color.stone,
        textAlign: 'center',
        ...theme.firstLetterUppercase,
        ...theme.overFlowText,
    },
    icon: {
        minWidth: 12,
        verticalAlign: 'middle',
        display: 'inline-block',
        width: 12,
        height: 12,
        objectFit: 'contain',
        fill: theme.color.stone,
        margin: 'auto',
        marginLeft: 5,
    },
}));

export default function ColumnNameButton(props) {
    const { text, onClick, isSortable } = props;
    const { t } = useTranslation();
    const classes = useStyles({ isSortable } as any);

    function buttonOnClick(event) {
        event.preventDefault();
        event.stopPropagation();

        if (onClick) {
            onClick();
        }
    }

    function getTitle(title) {
        return upperFirst(toLower(languageUtils.getTitle(t, title)));
    }

    return (
        <button type="button" title={getTitle(text)} className={classes.columnNameButton} onClick={buttonOnClick}>
            <div className={classes.content}>
                <div className={classes.title}>{text}</div>
                <Svg iconId="icon-add" style={classes.icon} />
            </div>
        </button>
    );
}
