import Button from '@baseComponents/buttons/Button';
import Input from '@baseComponents/inputs/Input';
import MultiSelect from '@baseComponents/multiselect/MultiSelect';
import { SimpleSelect } from '@baseComponents/select';
import { yupResolver } from '@hookform/resolvers/yup';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import { ThemeType } from '@map/services/mapTypes';
import clsx from 'clsx';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { ModalFooter, ModalHeader } from '@baseComponents/modals/modalNew';
import { ModalLeftPanelBody } from '@baseComponents/modals';
import { stringUtils } from 'shared/src/modules/stringUtils';
import { MeteorolgyFormType, meteorologySchema } from './MeteorolgySchema';
import { useMeteorolgyService } from './useMetetorolyService';
import { Coordinates } from './Coordinates';

type Classes = 'label' | 'labelDot' | 'labelContainer' | 'labelDotError' |
    'locationContainer' | 'locationValueContainer' | 'saveButtonContainer' | 'topMargin';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    label: {
        height: 15,
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    labelDot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: theme.color.main,
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    labelDotError: {
        backgroundColor: theme.color.status_error,
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: 'fit-content',
    },
    locationContainer: {
        display: 'flex',
    },
    locationValueContainer: {
        flex: 1,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 3,
        margin: [0, 6, 0, 0],
        borderColor: theme.color.gray,
        boxShadow: theme.shadows.secondary.normal,
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 5,
    },
    saveButtonContainer: {
        padding: [0, 30],
    },
    topMargin: {
        marginTop: 10,
    },
}));

type SelectData = {
    key: string,
    value: string,
    isChecked?: boolean,
    isDisabled?: boolean
}

export const MeteorologyModalLeft = (props: any) => {
    const { hideModal, entityId, onSuccess, onFailure, settlementId, locationName, deviceReference } = props;

    const classes = useStyles();

    const [store] = useMapContext();

    const { t, translater } = useTranslate();

    const marker = useRef<google.maps.Marker>();
    const circle = useRef<google.maps.Circle>();

    const listeners = useRef<google.maps.MapsEventListener[]>([]);

    const { cities, defaultComapnies, saveCity, preGeom } = useMeteorolgyService({ entityId, initCompanyIds: [], onSuccess, onFailure });

    const [companiesSelectData, setCompaniesSelectData] = useState<SelectData[]>([]);
    const [citiesSelectData, setCitiesSelectData] = useState<SelectData[]>([]);
    const [filteredCitiesSelectData, setFilteredCitiesSelectData] = useState<SelectData[]>([]);

    const activeCompanyList = useSelector((state: any) => state.company.activeCompanyList);

    const { control, getValues, setValue, formState: { errors }, trigger, handleSubmit } = useForm<MeteorolgyFormType>({
        resolver: yupResolver(meteorologySchema),
    });

    const onChangeLocationCoors = (lat?: number, lng?: number) => {
        setValue('location', { lat, lng });
        trigger('location');
    };

    const onChangeSelectedCompanyIds = (newValues: string[]) => {
        setValue('companyIds', newValues.map(Number));
        trigger('companyIds');
        setCompaniesSelectData(companiesData => companiesData.map(c => {
            if (newValues.includes(c.key)) {
                return {
                    ...c,
                    isChecked: true,
                };
            }
            return {
                ...c,
                isChecked: false,
            };
        }));
    };

    const searchCities = (searchValue: string) => {
        if (!isEmpty(searchValue) && !isEmpty(citiesSelectData)) {
            setFilteredCitiesSelectData(citiesSelectData.filter(dataObj => stringUtils.includesNoCase(dataObj.value, searchValue)));
        } else {
            setFilteredCitiesSelectData(citiesSelectData);
        }
    };

    useEffect(() => {
        if (isEmpty(cities)) {
            return;
        }

        const mappedCities = cities.map(c => ({ key: c.id, value: c.telepules }));
        trigger('cityId');
        trigger('companyIds');
        trigger('location');
        setCitiesSelectData(mappedCities);
        setFilteredCitiesSelectData(mappedCities);
    }, [cities, trigger]);

    useEffect(() => {
        setValue('id', Number(entityId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId]);

    useEffect(() => {
        if (!isNil(settlementId)) {
            setValue('cityId', Number(settlementId));
            trigger('cityId');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settlementId]);

    useEffect(() => {
        if (!isNil(locationName)) {
            setValue('name', locationName);
            trigger('name');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationName]);

    useEffect(() => {
        if (!isNil(deviceReference)) {
            setValue('deviceReference', deviceReference);
            trigger('deviceReference');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceReference]);

    useEffect(() => {
        const defaultComapnyIds = defaultComapnies.map(defcompany => Number(defcompany.ceg_id));
        const defaultCompanyDisabledFlags = defaultComapnies.map(defcompany => Number(defcompany.connect_szerzodo_ceg));

        const mappedCompanies = activeCompanyList.map(c => {
            const defaultCompanyIndex = defaultComapnyIds.indexOf(c.id);
            const isDefaultCompany = defaultCompanyIndex !== -1;
            const isDisabled = defaultCompanyIndex !== -1 ? Boolean(defaultCompanyDisabledFlags[defaultCompanyIndex]) : false;
            return {
                key: c.id,
                value: c.name,
                isChecked: isDefaultCompany,
                isDisabled,
            };
        });
        onChangeSelectedCompanyIds([...defaultComapnies.map(decompany => decompany.ceg_id)]);
        setCompaniesSelectData(mappedCompanies);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCompanyList, defaultComapnies]);

    useEffect(() => {
        if (isNil(store.googleRefs.map)) {
            return;
        }

        listeners.current.forEach(l => {
            l.remove();
        });

        listeners.current = [];

        if (isNil(marker.current)) {
            marker.current = new google.maps.Marker({
                zIndex: 2000,
                clickable: false,
                draggable: true,
                icon: {
                    url: `${window.location.origin}/styles/img/orange-marker.png`,
                    scaledSize: new window.google.maps.Size(40, 40),
                },
            });
            marker.current.setMap(store.googleRefs.map);
        }

        const preLocation = getValues('location');
        let position: google.maps.LatLng;
        if (!isNil(preLocation?.lat) && !isNil(preLocation?.lng)) {
            position = new google.maps.LatLng({ lat: preLocation.lat, lng: preLocation.lng });
            marker.current.setPosition(position);
            store.googleRefs.map.setCenter(position);
            store.googleRefs.map.setZoom(13);
        }

        listeners.current.push(marker.current.addListener('dragend', (ev: any) => {
            const lat = ev.latLng.lat();
            const lng = ev.latLng.lng();
            // circle.current?.setCenter({ lat, lng });
            onChangeLocationCoors(lat, lng);
        }));

        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            listeners.current.forEach(l => {
                l.remove();
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.googleRefs.map]);

    useEffect(() => {
        if (!isNil(preGeom)) {
            if (preGeom.geom.type === 'Point') {
                const coordinates: number[] = (preGeom.geom.coordinates) as any[];
                const lng = coordinates?.[0];
                const lat = coordinates?.[1];
                if (!isNil(lat) && !isNil(lng)) {
                    marker.current?.setPosition({ lat, lng });
                    setValue('location', { lat, lng });
                    trigger('location');
                }
            }
        }
    }, [locationName, preGeom, setValue, trigger]);

    const findCityLocation = (city: string) => {
        const geocode = new google.maps.Geocoder();
        geocode.geocode({ address: `${city}` }, status => {
            if (!isNil(status) && !isEmpty(status)) {
                const statusData = status?.[0];
                if (!isNil(statusData)) {
                    if (!isNil(statusData.geometry.bounds)) {
                        store.googleRefs.map?.fitBounds(statusData.geometry.viewport);
                    }
                    marker.current?.setPosition(statusData.geometry.location);
                    onChangeLocationCoors(statusData.geometry.location.lat(), statusData.geometry.location.lng());
                    circle.current?.setCenter(statusData.geometry.location);
                }

                const p1 = statusData.geometry.bounds?.getSouthWest();
                const p2 = statusData.geometry.bounds?.getNorthEast();

                if (!isNil(p1) && !isNil(p2)) {
                    const distance = google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
                    circle.current?.setRadius(distance / 8);
                }
            }
        });
    };

    const onChangeSelectedCity = (key: string) => {
        const cityValue = citiesSelectData.find(c => c.key === key);
        if (!isNil(cityValue)) {
            setValue('name', cityValue.value);
            setValue('cityId', Number(cityValue.key));
            findCityLocation(cityValue.value);
        }
    };

    const onSubmitForm = async (city: MeteorolgyFormType) => {
        await saveCity(city);
        hideModal();
    };

    return (
        <>
            <ModalHeader>
                {t('meteorology.location', 'Location')}
            </ModalHeader>
            <ModalLeftPanelBody>
                <Controller
                    control={control}
                    name="cityId"
                    render={({ field }) => (
                        <>
                            <div className={classes.labelContainer}>
                                <p className={classes.label}>{t('meteorology.settlement', 'Location')}</p>
                                <div className={clsx(classes.labelDot, { [classes.labelDotError]: !!errors.cityId })} />
                            </div>
                            <SimpleSelect
                                dataList={filteredCitiesSelectData}
                                selected={field.value}
                                onChange={(selected: string) => { onChangeSelectedCity(selected); field.onBlur(); trigger('cityId'); }}
                                search={searchCities}
                                required
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                        <Input
                            value={field.value}
                            onChange={(ev: any) => { field.onChange(ev); }}
                            onBlur={() => { field.onBlur(); }}
                            placeholder={translater('meteorology.name', 'Name')}
                            required
                            id="name"
                            name="name"
                            type="text"
                            label={translater('meteorology.name', 'Name')}
                            className={classes.topMargin}
                            invalid={!!errors.name}
                            errorMessage={errors.name?.message}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="companyIds"
                    render={() => (
                        <>
                            <div className={clsx(classes.labelContainer, classes.topMargin)}>
                                <p className={classes.label}>{t('meteorology.companies', 'Companies')}</p>
                                <div className={clsx(classes.labelDot, { [classes.labelDotError]: !!errors.companyIds })} />
                            </div>
                            <MultiSelect
                                search
                                dataList={companiesSelectData}
                                hasSearchInput
                                hasMultiSelectHeader
                                onChange={(val: string[]) => { onChangeSelectedCompanyIds(val); }}
                            />
                        </>
                    )}
                />
                <Controller
                    control={control}
                    name="location"
                    render={({ field }) => (
                        <Coordinates
                            longitude={field.value?.lng}
                            latitude={field.value?.lat}
                            onChangeLocationCoors={onChangeLocationCoors}
                            isInvalid={!!errors.location}
                            marker={marker}
                        />
                    )}
                />
                <Controller
                    control={control}
                    name="deviceReference"
                    render={({ field }) => (
                        <Input
                            value={field.value}
                            onChange={(ev: any) => { field.onChange(ev); }}
                            onBlur={() => { field.onBlur(); }}
                            placeholder={translater('meteorology.deviceReference', 'Device reference')}
                            id="deviceReference"
                            name="deviceReference"
                            type="text"
                            label={translater('meteorology.deviceReference', 'Device reference')}
                            className={classes.topMargin}
                        />
                    )}
                />
            </ModalLeftPanelBody>
            <ModalFooter className={classes.saveButtonContainer}>
                <Button fill onClick={handleSubmit(onSubmitForm)}>{translater('default.save', 'Save')}</Button>
            </ModalFooter>
        </>
    );
};
