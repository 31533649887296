import { ThemeType } from '@map/services/mapTypes';
import { theme } from '@styles/theme';
import { isNil } from 'lodash';
import { createUseStyles } from 'react-jss';
// eslint-disable-next-line no-restricted-imports
import { MapSliderOptions } from '../MapSliderTypes';

type Classes = | 'container';

type StyleProps = {
    hasBorder: boolean,
    flexGrow: number,
}

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        position: 'absolute',
        zIndex: 200,
        bottom: 4,
    },
}));

type TProps = {
    mapSliderOptions?: MapSliderOptions;
}

export const MapSliderDataContainer = ({ mapSliderOptions }: TProps) => {
    const classes = useStyles();

    if (isNil(mapSliderOptions)) {
        return null;
    }

    return (
        <div className={classes.container}>
            {mapSliderOptions.datePicker.map((picker, i) => (
                <div key={i} style={{ flex: 1, height: 5, background: picker?.hasValue === true ? theme.color.main : theme.color.gray }} />
            ))}
        </div>
    );
};
