import React from 'react';
import { createUseStyles } from 'react-jss';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { dateTimeUtils } from 'shared/src/modules/dateTimeUtils';

import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        display: 'inline-flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        minWidth: 100,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    firstLine: {
        display: 'block',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        height: 16,
        lineHeight: '16px',
        fontSize: 13,
        fontWeight: 500,
        color: theme.color.jet,
        textTransform: 'lowercase',
    },
    secondLine: {
        display: 'block',
        width: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        height: 18,
        lineHeight: '18px',
        fontSize: 13,
        fontWeight: 'normal',
        color: theme.color.raven,
    },
    inlineBlock: {
        display: 'inline-block',
    },
    icon: {
        height: 15,
        width: 15,
        fill: theme.color.jet,
    },
}));

export default function MultilineWithDateIntervalCell(props) {
    const { rowData, firstLineId, date1Id, date2Id, hasColumnName, columnName, columnId } = props;
    const classes = useStyles();

    function getFirstLineText() {
        if (hasColumnName && columnName && !firstLineId) {
            return columnName;
        }

        const columnIdString = firstLineId || columnId;
        const columnItem = arrayUtils.getArrayItemByKeyString(rowData, columnIdString);

        return columnItem;
    }

    function getDate1LineText() {
        if (date1Id) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, date1Id);
            const dateObject = dateTimeUtils.getMomentObjectFromDateString(dateTimeUtils.getDate(columnItem));
            return columnItem ? dateTimeUtils.getDateMonthDay(dateObject) : null;
        }
        return null;
    }

    function getDate2LineText() {
        if (date2Id) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, date2Id);
            const dateObject = dateTimeUtils.getMomentObjectFromDateString(dateTimeUtils.getDate(columnItem));
            return columnItem ? dateTimeUtils.getDateMonthDay(dateObject) : null;
        }
        return null;
    }

    return (
        <div className={classes.container}>
            <div className={classes.firstLine}>
                {getDate1LineText()}
                {getDate1LineText() !== getDate2LineText()
                && (
                    <>
                        <Svg iconId="icon-from_l-r" style={classes.icon} />
                        {getDate2LineText()}
                    </>
                )
                }
            </div>
            <div className={classes.secondLine}>
                {getFirstLineText()}
            </div>
        </div>
    );
}
