import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import useTranslate from '@i18n/useTranslate';
import Toogle from '@baseComponents/controls/Toogle';
import Svg from '@baseComponents/Svg';
import { numberUtils } from 'shared/src/modules';
import { userService } from '@services/userService';
import { userActions } from '@states/actions';

const useStyles = createUseStyles((theme: any) => ({
    darkModeContentWrapper: {
        marginTop: 20,
        marginBottom: 5,
        paddingBottom: 15,
        borderBottom: 'solid 1px',
        borderBottomColor: theme.color.pearl,
    },
    contentTitles: {
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        textTransform: 'uppercase',
    },
    darkModeContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 5,
    },
    darkModeSwitch: {
        display: 'block',
        paddingRight: 30,
        '& .container': {
            width: '100%',
        },
        '& .separaterContainer': {
            width: '50%',
        },
        '& .segmentButton': {
            width: '100%',
        },
        '& label': {
            marginBottom: 0,
        },
    },
    darkModeIcon: {
        height: 25,
        width: 25,
    },
}));

export default function DarkMode(props) {
    const { isActiveDarkMode } = props;
    const { t, translater } = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [isEnabledDarkMode, setIsEnabledDarkMode] = useState(numberUtils.getBoolean(isActiveDarkMode));

    async function onChangeDarkMode(value) {
        const darkMode = numberUtils.getBoolean(value);
        const successUpdate = await userService.setUserDarkMode(darkMode);
        if (successUpdate) {
            setIsEnabledDarkMode(darkMode);
            dispatch(userActions.updateUser());
        }
    }

    return (
        <>
            <div className={classes.darkModeContentWrapper}>
                <span className={classes.contentTitles}>
                    {t('editProfile.darkMode', 'Dark mode')}
                </span>
                <div className={classes.darkModeContainer}>
                    <div className={classes.darkModeSwitch}>
                        <Toogle
                            onStateTitle={translater('userMenu.disablePlanningMode', 'Disable')}
                            offStateTitle={translater('userMenu.enablePlanningMode', 'Enable')}
                            isEnabled={isEnabledDarkMode}
                            onChange={onChangeDarkMode}
                        />
                    </div>
                    <div>
                        <Svg iconId={!isEnabledDarkMode ? 'icon-sun' : 'icon-moon'} style={classes.darkModeIcon} />
                    </div>
                </div>
            </div>
        </>
    );
}
