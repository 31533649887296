/* eslint-disable id-blacklist */
import { routerHelper } from '@utils/index';
import { sendServerRequest } from './baseService';
import { sessionService } from './sessionService';

const userService = {
    getUsersByName,
    getCurrentUser,
    getTwoFactorAuth,
    login,
    sendPasswordReminder,
    setNewPasswordWitHash,
    isValidDate,
    setActiveModule,
    setUserDatas,
    setUserDarkMode,
    isValidPassword,
    setNewPassword,
    setInterfaceSetting,
    getRights,
    setTableColumnOrder,
    getUserList,
    getTwoFactorQRCode,
    isValidTwoFactorAuthCode,
    setTwoFactorStatus,
    sendTwoFactorTokenReminder,
    getReadUserQuestion,
    setReadUserQuestion,
    getEgnAccess,
};

export { userService };

async function getUsersByName(nameFragment) {
    const response = await sendServerRequest('GET', `/api/user/users?nameFragment=${nameFragment}`);
    return response;
}

async function getCurrentUser() {
    const response = await sendServerRequest('GET', '/api/user/loggedIn');
    return response;
}

async function getTwoFactorAuth(userName, password, agentName) {
    const response = await sendServerRequest('POST', `/api/user/twoFactorAuth/${userName}/${password}/${agentName}`);
    return response;
}

async function login(props) {
    const apiResponse = await sendServerRequest('POST', '/api/oauth2/token', props);
    const userToken = apiResponse.access_token;
    return userToken;
}

async function sendPasswordReminder(userName, pageUrl, messageSubject, messageBody) {
    const agentName = sessionService.getAgentName();
    const body = { userName, connectionName: agentName, pageUrl, messageSubject, messageBody };
    const response = await sendServerRequest('POST', '/api/user/passwordReminder', body);
    return response;
}

async function isValidDate(agentName, hashCode) {
    const response = await sendServerRequest('GET', `/api/user/passwordConfirm/${hashCode}/${agentName}`);
    return response;
}

async function setNewPasswordWitHash(password, hash) {
    const agentName = sessionService.getAgentName();
    const response = await sendServerRequest('PATCH', '/api/user/setNewPassword', {
        password, hash, agentName,
    });
    return response;
}

async function setActiveModule(moduleId) {
    const response = await sendServerRequest('PATCH', `/api/user/activeSystemModule/${moduleId}`);
    return response;
}

async function setUserDatas(fullName, userName, email) {
    const response = await sendServerRequest('PATCH', '/api/user/setUserDatas', {
        fullName, userName, email,
    });
    return response;
}

async function setUserDarkMode(darkMode) {
    const response = await sendServerRequest('PATCH', '/api/user/setUserDarkMode', {
        darkMode,
    });
    return response;
}

async function isValidPassword(password) {
    const response = await sendServerRequest('GET', `/api/user/correctPassword/${password}`);
    return response;
}

async function setNewPassword(password) {
    const response = await sendServerRequest('PATCH', '/api/user/newPassword', {
        password,
    });
    return response;
}

async function setInterfaceSetting(settingName, settingValue, fileName) {
    const response = await sendServerRequest('POST', '/api/user/interfaceSetting', {
        settingName,
        settingValue,
        fileName,
    });
    return response;
}

async function getRights() {
    const response = await sendServerRequest('GET', '/api/user/rights');
    return response;
}

async function setTableColumnOrder(location, tableColumns) {
    const response = await sendServerRequest('POST', '/api/user/tableColumnOrder', { location, tableColumns });
    return response;
}

async function getUserList() {
    const response = await sendServerRequest('GET', '/api/user/userList');
    return response;
}

async function getTwoFactorQRCode() {
    const agentName = sessionService.getAgentName();
    const response = await sendServerRequest('GET', `/api/user/twoFactorQRCode/${agentName}`);
    return response;
}

async function isValidTwoFactorAuthCode(userId, userToken) {
    const connectionName = sessionService.getAgentName();
    const response = await sendServerRequest('POST', '/api/user/isValidTwoFactorAuthCode', { connectionName, userId, userToken });
    return response;
}

async function setTwoFactorStatus(status) {
    const response = await sendServerRequest('PATCH', '/api/user/twoFactorStatus', {
        status,
    });
    return response;
}

async function sendTwoFactorTokenReminder(userName, messageSubject, messageBody) {
    const connectionName = sessionService.getAgentName();
    const body = { userName, connectionName, messageSubject, messageBody };
    const response = await sendServerRequest('POST', '/api/user/twoFactorTokenReminder', body);
    return response;
}

async function getReadUserQuestion(questionKey) {
    const response = await sendServerRequest('GET', `/api/user/readUserQuestion/${questionKey}`);
    return response;
}

async function setReadUserQuestion(questionKey) {
    const response = await sendServerRequest('POST', '/api/user/setReadUserQuestion', { questionKey });
    return response;
}

/**
 * @param productionYearId - Termelési év id-k
 * @param companyIds - Cég id-k
 */
async function getEgnAccess(props) {
    const url = routerHelper.getUrlWithParameters('/api/user/egnAccess', props);
    const response = await sendServerRequest('GET', url);
    return response;
}
