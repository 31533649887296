import React, { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { isFunction, isEmpty } from 'lodash';

const useStyles = createUseStyles({
    leftSideColorContainer: {
        display: 'block',
        height: (props: any) => (props.progressValue ? 'calc(100% + 6px)' : '100%'), //progressBar: 6px + 2px border
    },
    leftSideColor: {
        width: 10,
        height: '100%',
        objectFit: 'contain',
        backgroundColor: (props: any) => props.currentColor,
        margin: 'auto',
    },
});

export default function StatusColorCell(props) {
    const { rowData, getCurrentColorFunction, getTitle } = props;
    const { progressValue } = rowData;
    const colorCellRef = useRef<any>(null);
    const currentColor = isFunction(getCurrentColorFunction) ? getCurrentColorFunction(rowData) : 'pink';
    const title = isFunction(getTitle) ? getTitle(rowData) : undefined;
    const classes = useStyles({ currentColor, progressValue });

    useEffect(() => {
        if (colorCellRef && !isEmpty(colorCellRef.current)) {
            colorCellRef.current.parentElement.style.overflow = 'visible';
            colorCellRef.current.parentElement.style.padding = 0;
        }
    }, [colorCellRef]);

    return (
        <div className={classes.leftSideColorContainer} ref={colorCellRef} title={title}>
            <div className={classes.leftSideColor} />
        </div>
    );
}
