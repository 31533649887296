/* eslint-disable operator-linebreak */
import { useCallback, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty, isNil } from 'lodash';
import clsx from 'clsx';
import useTranslate from '@i18n/useTranslate';
import { WAREHOUSE } from 'shared/src/constants';

import Button from '@baseComponents/buttons/Button';
import Radio from '@baseComponents/controls/Radio';
import { OpenModalButton } from '@baseComponents/modals';
import PartnersModal from '@customComponents/modals/partnersModal/PartnersModal';
import WorkersModal from '@customComponents/modals/workersModal/WorkersModal';
import ReleasingWarehouseModal from '@customComponents/modals/releasingWarehouseModal/ReleasingWarehouseModal';
import CompanyDropdown from '@customComponents/dropdowns/companyDropdown/CompanyDropdown';
import FormGroup from '@customComponents/form/FormGroup';

const useStyles = createUseStyles((theme: any) => ({
    popupWrapper: {
        //minWidth: 700,
        minWidth: 350,
        height: 'auto',
        minHeight: 350,
        maxHeight: 650,
        display: 'flex',
        flexDirection: 'column',
    },
    body: {
        height: '100%',
        overflow: 'hidden',
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
    },
    footer: {
        height: 50,
        minHeight: 50,
        padding: 10,
        paddingTop: 7,
        borderTop: `1px solid ${theme.color.pearl}`,
        display: 'block',
        alignItems: 'center',
        textAlign: 'center',
    },
    cancelButton: {
        width: 150,
    },
    addButton: {
        marginRight: 10,
        width: 200,
    },
    columnWrapper: {
        marginRight: 25,
    },
    selectWrapper: {
        width: '100%',
    },
    extraWidth: {
        width: 280,
    },
}));

export default function WarehouseSelectorPopup(props) {
    const { rowData, closeModal, isOnlyNewUsage = false, onSubmit, isReadOnly } = props;
    const { t } = useTranslate();
    const classes = useStyles();

    //const [releasingWarehouseTypeIds] = useState([WAREHOUSE.TYPE_ID.OWN, WAREHOUSE.TYPE_ID.FOREIGN, WAREHOUSE.TYPE_ID.OWN_AND_FOREIGN]);
    const [usageType, setUsageType] = useState<any>(WAREHOUSE.USAGE_TYPE.NEW);
    const [partner, setPartner] = useState<any>({ id: rowData?.partnerId, name: rowData?.partnerName });
    const [releaseType, setReleaseType] = useState<any>(!isNil(rowData?.releaseTypeId) ? rowData?.releaseTypeId : WAREHOUSE.RELEASE_TYPE.FROM_OWN);
    const [stockRecorder, setStockRecorder] = useState<any>({ id: rowData?.stockRecorderId, name: rowData?.stockRecorderName });
    const [company, setCompany] = useState<any>({ id: rowData?.companyId, name: rowData?.companyName });
    const [releaseWarehouse, setReleaseWarehouse] = useState<any>({ id: rowData?.releaseWarehouseId, name: rowData?.releaseWarehouseName });
    const [receivingWarehouse, setReceivingWarehouse] = useState<any>({ id: rowData?.receivingWarehouseId, name: rowData?.receivingWarehouseName });

    function onClickButton() {
        if (closeModal) {
            closeModal();
        }
    }

    function onClickSubmit() {
        onSubmit({ usageType, partner, releaseType, stockRecorder, company, releaseWarehouse, receivingWarehouse });
    }

    function onChangeUsageType(event) {
        const value = +event?.target?.value || 0;
        setUsageType(value);

        //visszavételezés
        if (value === WAREHOUSE.USAGE_TYPE.WITHDRAW) {
            setReleaseWarehouse(null);
        } else { //új felhasználás
            setReceivingWarehouse(null);
        }
    }

    //kiadás típus módosítása
    function onChangeReleaseType(event) {
        const value = +event?.target?.value || 0;
        setReleaseType(value);

        if (value === WAREHOUSE.RELEASE_TYPE.FROM_OWN) { //saját raktár
            setPartner(null);
        } else { //partner, külső vagy nem készletről
            setCompany(null);
            setReleaseWarehouse(null);
            setReceivingWarehouse(null);
        }
    }

    //partner módosítás
    function onChangePartner(partnerObj) {
        setPartner({ id: partnerObj?.id, name: partnerObj?.name });

        //reset warehouse
        if (releaseWarehouse?.companyId !== partnerObj.id) {
            setReleaseWarehouse(null);
        }
    }

    //készlet felvevő módosítás
    function onChangeStockRecorder(employeeObj) {
        setStockRecorder({ id: employeeObj?.id, name: employeeObj?.name });
    }

    //cég módosítása
    function onChangeCompany(companyObj) {
        setCompany({ id: companyObj?.key, name: companyObj?.value });

        //reset warehouse
        if (releaseWarehouse?.companyId !== companyObj.key) {
            setReleaseWarehouse(null);
        }
    }

    //kiadó raktár módosítása
    function onChangeReleaseWarehouse(warehouseObj) {
        setReleaseWarehouse(warehouseObj);
    }

    const checkIfDataIsInvalid = useCallback(() => {
        switch (releaseType) {
            case WAREHOUSE.RELEASE_TYPE.FROM_OWN:
                return (
                    isEmpty(company) || !company?.id
                    || isEmpty(releaseWarehouse) || !releaseWarehouse?.id
                );
            case WAREHOUSE.RELEASE_TYPE.FROM_PARTNER:
                return (
                    isEmpty(company) || !company?.id
                    || isEmpty(partner) || !partner?.id
                    || isEmpty(releaseWarehouse) || !releaseWarehouse?.id
                );
            case WAREHOUSE.RELEASE_TYPE.FROM_EXTERNAL_PARTNER:
                return (
                    isEmpty(partner) || !partner?.id
                );
            default: return false;
        }
    }, [company, partner, releaseType, releaseWarehouse]);

    const isSaveDisabled = useMemo(() => checkIfDataIsInvalid(), [checkIfDataIsInvalid]);

    return (
        <div className={classes.popupWrapper}>
            <div className={classes.body}>
                {/* Usage type */}
                <div className={classes.columnWrapper}>
                    <FormGroup label={t('warehouseSelectorPopup.type', 'Type')} required hasValue={usageType}>
                        <Radio
                            group="productUsageType"
                            text={t('warehouseSelectorPopup.newUsage', 'New usage')}
                            value={WAREHOUSE.USAGE_TYPE.NEW}
                            checked={usageType === WAREHOUSE.USAGE_TYPE.NEW}
                            onChange={onChangeUsageType}
                            isDisabled={isOnlyNewUsage || isReadOnly}
                        />
                        <Radio
                            group="productUsageType"
                            text={t('warehouseSelectorPopup.withdrawUsage', 'Withdraw usage')}
                            value={WAREHOUSE.USAGE_TYPE.WITHDRAW}
                            checked={usageType === WAREHOUSE.USAGE_TYPE.WITHDRAW}
                            onChange={onChangeUsageType}
                            isDisabled={isOnlyNewUsage || isReadOnly}
                        />
                    </FormGroup>
                </div>

                {/* Type of Release */}
                {usageType === WAREHOUSE.USAGE_TYPE.NEW && (
                    <div className={classes.columnWrapper}>
                        <FormGroup label={t('warehouseSelectorPopup.typeOfRelease', 'Type of release')} required hasValue={releaseType}>
                            <Radio
                                group="productUsageReleaseType"
                                text={t('warehouseSelectorPopup.fromOwnWarehouse', 'From own warehouse')}
                                value={WAREHOUSE.RELEASE_TYPE.FROM_OWN}
                                checked={releaseType === WAREHOUSE.RELEASE_TYPE.FROM_OWN}
                                onChange={onChangeReleaseType}
                                isDisabled={isReadOnly}
                            />
                            <Radio
                                group="productUsageReleaseType"
                                text={t('warehouseSelectorPopup.partnerStock', 'Partner stock from warehouse')}
                                value={WAREHOUSE.RELEASE_TYPE.FROM_PARTNER}
                                checked={releaseType === WAREHOUSE.RELEASE_TYPE.FROM_PARTNER}
                                onChange={onChangeReleaseType}
                                isDisabled={isReadOnly}
                            />
                            <Radio
                                group="productUsageReleaseType"
                                text={t('warehouseSelectorPopup.externalPartner', 'From external partner')}
                                value={WAREHOUSE.RELEASE_TYPE.FROM_EXTERNAL_PARTNER}
                                checked={releaseType === WAREHOUSE.RELEASE_TYPE.FROM_EXTERNAL_PARTNER}
                                onChange={onChangeReleaseType}
                                isDisabled={isReadOnly || rowData.isRecordsInTreatmentUnit}
                            />
                            <Radio
                                group="productUsageReleaseType"
                                text={t('warehouseSelectorPopup.notFromStock', 'Not from stock')}
                                value={WAREHOUSE.RELEASE_TYPE.NOT_FROM_STOCK}
                                checked={releaseType === WAREHOUSE.RELEASE_TYPE.NOT_FROM_STOCK}
                                onChange={onChangeReleaseType}
                                isDisabled={isReadOnly || rowData.isRecordsInTreatmentUnit}
                            />
                        </FormGroup>
                    </div>
                )}

                {/* Releasing company/partner */}
                {usageType === WAREHOUSE.USAGE_TYPE.NEW && (
                    <div className={clsx(classes.columnWrapper, classes.extraWidth)}>
                        {/* Partner (partner or external partner) */}
                        {(releaseType === WAREHOUSE.RELEASE_TYPE.FROM_PARTNER || releaseType === WAREHOUSE.RELEASE_TYPE.FROM_EXTERNAL_PARTNER) &&
                            <div className={classes.selectWrapper}>
                                <FormGroup label={t('warehouseSelectorPopup.partner', 'Partner')} required hasValue={partner?.id}>
                                    <OpenModalButton
                                        currentSelectedValue={partner?.id}
                                        modalComponent={PartnersModal}
                                        id={partner?.id}
                                        name={partner?.name}
                                        onChange={partnerObj => {
                                            onChangePartner(partnerObj);
                                        }}
                                        modalComponentProps={{
                                            isMultiselect: false,
                                            showPartnerType: false,
                                        }}
                                    />
                                </FormGroup>
                            </div>
                        }
                        {/* From stock */}
                        {(releaseType === WAREHOUSE.RELEASE_TYPE.FROM_OWN || releaseType === WAREHOUSE.RELEASE_TYPE.FROM_PARTNER) &&
                            <div className={classes.selectWrapper}>
                                {(releaseType === WAREHOUSE.RELEASE_TYPE.FROM_OWN || releaseType === WAREHOUSE.RELEASE_TYPE.FROM_PARTNER) && (
                                    <FormGroup label={t('warehouseSelectorPopup.releasingCompany', 'Releasing company')} required hasValue={company?.id}>
                                        <CompanyDropdown
                                            useAbsoluteMenu
                                            companyId={company?.id}
                                            onChange={companyObj => {
                                                onChangeCompany(companyObj);
                                            }}
                                            isDisabled={isReadOnly}
                                        />
                                    </FormGroup>
                                )}

                                <FormGroup label={t('warehouseSelectorPopup.releasingWarehouse', 'Releasing warehouse')} required hasValue={releaseWarehouse?.id}>
                                    <OpenModalButton
                                        modalComponent={ReleasingWarehouseModal}
                                        currentSelectedValue={releaseWarehouse?.name}
                                        id={releaseWarehouse?.id}
                                        name={releaseWarehouse?.name}
                                        onChange={warehouse => {
                                            onChangeReleaseWarehouse(warehouse);
                                        }}
                                        modalComponentProps={{
                                            isMultiselect: false,
                                            isRecordsInTreatmentUnit: Boolean(rowData.isRecordsInTreatmentUnit),
                                            companyIds: [company?.id],
                                            partnerId: releaseType === WAREHOUSE.RELEASE_TYPE.FROM_PARTNER ? partner?.id : 0,
                                            isOnlyFromStock: true,
                                            productIds: [rowData.productId],
                                            date: rowData.date,
                                            rowFormatFnc: value => ({
                                                id: value.id,
                                                name: value.name,
                                                companyId: value.companyId,
                                                stockPrice: value.stockPrice,
                                            }),
                                        }}
                                        isDisabled={isReadOnly || !(+company?.id || +partner?.id)}
                                    />
                                </FormGroup>
                            </div>
                        }

                        {/* Stock recorder */}
                        <div className={classes.selectWrapper}>
                            <FormGroup label={t('warehouseSelectorPopup.stockRecorder', 'Stock recorder')}>
                                <OpenModalButton
                                    currentSelectedValue={stockRecorder?.id}
                                    modalComponent={WorkersModal}
                                    id={stockRecorder?.id}
                                    name={stockRecorder?.name}
                                    onChange={stockRecorderObj => {
                                        onChangeStockRecorder(stockRecorderObj);
                                    }}
                                    modalComponentProps={{ isMultiselect: false }}
                                    isDisabled={isReadOnly}
                                />
                            </FormGroup>
                        </div>
                    </div>
                )}
            </div>
            <div className={classes.footer}>
                <Button
                    onClick={onClickSubmit}
                    size="small"
                    type="primary"
                    className={classes.addButton}
                    disabled={isSaveDisabled || isReadOnly}
                >
                    {t('default.save', 'Save')}
                </Button>
                <Button
                    onClick={() => { onClickButton(); }}
                    size="small"
                    type="secondary"
                    className={classes.cancelButton}
                >
                    {t('default.cancel', 'Cancel')}
                </Button>
            </div>
        </div>
    );
}
