import { routerActions } from '../actions/routerActions';

const initialState = {
    currentPageKey: null,
    isServerOverload: false,
    isChangePasswordScreen: false,
};

export function router(state = initialState, action) {
    switch (action.type) {
        case routerActions.SET_CURRENT_PAGE_KEY:
            return {
                ...state,
                currentPageKey: action.payload.currentPageKey,
            };
        case routerActions.REMOVE_SERVEROVERLOAD:
            return {
                ...state,
                isServerOverload: false,
            };
        case routerActions.SET_SERVEROVERLOAD:
            return {
                ...state,
                isServerOverload: action.payload.isServerOverload,
            };
        case routerActions.SET_CHANGE_PASSWORD_SCREEN:
            return {
                ...state,
                isChangePasswordScreen: action.payload.isChangePasswordScreen,
            };
        default:
            return state;
    }
}
