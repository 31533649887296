import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const pesticideService = {
    getUpdateDate,
    getProductPesticideInfoByCultures,
    getProductPesticideUsageInfo,
};

/**
 * Get last update date
 * @property {int} pesticideConnectionId IsRequired/IsOptional -  Pesticide db connection id eg.: 1 (if not specified - the backend will queries)
 *
 * @return {date} Update date
 */
async function getUpdateDate(props) {
    const url = routerHelper.getUrlWithParameters('/api/pesticide/updateDate', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get product pesticide info
 * @property {int} pesticideConnectionId IsRequired/IsOptional -  Pesticide db connection id eg.: 1 (if not specified - the backend will queries)
 * @property {array<int>} cultureIds IsRequired - Culture id list eg.: [1]
 * @property {int} pesticideProductId IsRequired (not required if has productId) -  Product pesticide id eg.: 1
 * @property {int} productId IsRequired (not required if has pesticideProductId) -  Pesticide id eg.: 1
 *
 * @return {object} Product - Pesticide info
 */
async function getProductPesticideInfoByCultures(props) {
    const url = routerHelper.getUrlWithParameters('/api/pesticide/productPesticideInfoByCultures', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get product pesticide usage info/product
 * @property {int} pesticideConnectionId IsRequired/IsOptional -  Pesticide db connection id eg.: 1 (if not specified - the backend will queries)
 * @property {array<int>} cultureIds IsRequired - Culture id list eg.: [1]
 * @property {array<int>} pesticideProductIds IsRequired (not required if has productIds) -  Product pesticide ids eg.: [1]
 * @property {array<int>} productIds IsRequired (not required if has pesticideProductIds) -  Product ids eg.: [1]
 *
 * @return {object} Product - Pesticide usage info
 */
async function getProductPesticideUsageInfo(props) {
    const url = routerHelper.getUrlWithParameters('/api/pesticide/productPesticideUsageInfo', props);
    const response = await sendServerRequest('GET', url);
    return response;
}
