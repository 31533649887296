import { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { basePageActions, simpleTableActions } from '@states/actions';
import { isEmpty, isUndefined, isArray } from 'lodash';

export default function useTableConfig(props) {
    const { isEnabledSearching, isBasePageTable, useRowSelection, aggregateType, tableId } = props;
    const { tableData, tableColumns, tableGroup, selectedRowIds } = props; //simple table
    const [tableKey] = useState(!isBasePageTable ? (tableId || `simpleTable_${new Date().getTime()}`) : ''); //simple table - generated unique key
    const dispatch = useDispatch();
    const initState = useRef({ isInitialized: false });

    //init - remove simple table
    useEffect(() => {
        if (!isBasePageTable && !isEmpty(tableColumns) && !isUndefined(tableData) && !isUndefined(tableKey) && dispatch) {
            dispatch(simpleTableActions.initSimpleTable(tableKey, tableColumns, tableData, tableGroup));
        }

        return () => {
            dispatch(simpleTableActions.resetSimpleTable(tableKey));
        };
    }, [isBasePageTable, tableKey, tableData, tableColumns, tableGroup, dispatch]);

    //init - set table aggregate type
    useEffect(() => {
        if (aggregateType && dispatch) { //basePage
            if (isBasePageTable) {
                dispatch(basePageActions.setTableAggregateType(aggregateType));
            } else {
                dispatch(simpleTableActions.setAggregateType(tableKey, aggregateType));
            }
        }
    }, [isBasePageTable, aggregateType, tableKey, dispatch]);

    //init - set selected rows
    useEffect(() => {
        if (!isBasePageTable && !isEmpty(selectedRowIds) && isArray(selectedRowIds) && dispatch && !isUndefined(tableData) && !isEmpty(tableColumns)) {
            dispatch(simpleTableActions.setSelectedIds(tableKey, selectedRowIds));
        }
    }, [isBasePageTable, selectedRowIds, tableKey, dispatch, tableData, tableColumns]);

    //init - has default column search inputs
    useEffect(() => {
        if (!isBasePageTable && !isEmpty(tableColumns) && !isUndefined(tableData) && !isUndefined(tableKey) && dispatch && isEnabledSearching && !initState?.current?.isInitialized) {
            dispatch(simpleTableActions.setTableEnabledSearching(tableKey));
            initState.current.isInitialized = true;
        }
    }, [isBasePageTable, isEnabledSearching, tableKey, tableColumns, dispatch, tableData]);

    //get flag from config
    const canUseGroupOperation = useCallback(() => {
        if (useRowSelection) {
            return true;
        }
        return false;
    }, [useRowSelection]);

    return {
        canUseGroupOperation: canUseGroupOperation(),
        tableKey,
    };
}
