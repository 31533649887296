import { isEmpty, isObject } from 'lodash';

export const languageUtils = {
    isSameLanguage,
    getLangCaptionFromDataItem,
    getLangCaptionFromObject,
    getCaptionModule,
    getCaptionKey,
    getTitle,
};

function isSameLanguage(languageCode1, languageCode2) {
    if (!languageCode1 || !languageCode2) {
        return false;
    }

    return languageCode1.toUpperCase() === languageCode2.toUpperCase();
}

function getLangCaptionFromDataItem(languageCode, tableColumn, dataItem) {
    let caption = dataItem;

    if (tableColumn.componentProps && tableColumn.componentProps.hasLangCaption) {
        caption = getLangCaptionFromObject(languageCode, dataItem);
    }

    return caption;
}

function getLangCaptionFromObject(languageCode, dataItem) {
    if (!dataItem) {
        return null;
    }

    if (!isEmpty(dataItem.otherLanguage)) {
        const columnValueLangObject = dataItem.otherLanguage.find(langObject => isSameLanguage(langObject.langCode, languageCode));

        if (!isEmpty(columnValueLangObject) && (columnValueLangObject.name || columnValueLangObject.shortName)) {
            return columnValueLangObject.name || columnValueLangObject.shortName;
        }
    }
    
    return dataItem.name || dataItem.shortName;
}

function getCaptionModule(moduleAndKey) {
    return moduleAndKey.split('.')[0] || null;
}

function getCaptionKey(moduleAndKey) {
    return moduleAndKey.split('.')[1] || null;
}

function getTitle(t, title) {
    if (isObject(title) && title.props) {
        return t(title.props.moduleAndKey, title.props.defaultText);
    }
    return title;
}
