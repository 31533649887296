import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import { DEFAULT_SYSTEM_DATA } from 'shared/src/constants';
import { routerHelper } from '@utils/router/RouterHelper';

export default function useSiteHelmetTitle(props) {
    const { routeList } = props;
    const location = useLocation();
    const locationParam = useLocation().search;
    const { t } = useTranslation();
    const [pageTitle, setPageTitle] = useState(DEFAULT_SYSTEM_DATA.SITE_DATA.TITLE);

    //set dynamic page title
    useEffect(() => {
        if (location && routeList && t) {
            const actualPage = routerHelper.getCurrentRouteItem(location, routeList);
            if (actualPage) {
                const title = routerHelper.getContentTitleByUrl(t, location.pathname, locationParam);
                if (title) {
                    setPageTitle(title);
                } else {
                    setPageTitle(actualPage.title);
                }
            } else {
                setPageTitle(DEFAULT_SYSTEM_DATA.SITE_DATA.TITLE);
            }
        }
    }, [location, locationParam, routeList, t]);

    return {
        pageTitle,
    };
}
