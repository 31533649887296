import { createUseStyles } from 'react-jss';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    svgIcon: {
        height: 30,
        width: 30,
        fill: (props: any) => {
            if (props.isNewMenu) {
                return theme.color.beta;
            }
            if (props.hasSeparatedBackground) {
                return theme.color.status;
            }
            return theme.color.black;
        },
    },
    svgIconContainer: {
        lineHeight: '44px',
        marginLeft: (props: any) => (!props.expanded ? 15 : 20),
    },
}));

export default function SideNavBarItemNewImage(props) {
    const { iconId, menuTitle, expanded, hasSeparatedBackground, isNewMenu } = props;
    const classes = useStyles({ expanded, hasSeparatedBackground, isNewMenu } as any);

    return (
        <>
            {iconId && (
                <div title={menuTitle} className={classes.svgIconContainer}>
                    <Svg iconId={iconId} style={classes.svgIcon} />
                </div>
            )}
        </>
    );
}
