import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    progressBarContainer: {
        bottom: 1,
        position: 'absolute',
        width: '100%',
        zIndex: theme.zIndex.tableRowProgressBar,
    },
    progress: {
        backgroundColor: 'rgb(227, 230, 232)',
        borderRadius: '0 0 6px 6px',
        position: 'relative',
        marginLeft: (props: any) => (props.isOpened ? 40 : 10),
        height: 6,
        display: 'flex',
    },
    progressDone: {
        background: (props: any) => (props.done >= 100 ? theme.color.operations.table : theme.color.status),
        height: '100%',
        width: 0,
        opacity: 0,
        transition: '1s ease 0.3s',
    },
}));

export default function DataListItemProgressBar(props) {
    const { done, isOpened, className } = props;
    const classes = useStyles({ isOpened, done } as any);
    const [style, setStyle] = useState<any>();

    useEffect(() => {
        const timer = setTimeout(() => {
            const newStyle = {
                opacity: 1,
                width: `${done}%`,
            };

            setStyle(newStyle);
        }, 200);
        return () => clearTimeout(timer);
    }, [done]);

    return (
        <div className={clsx(classes.progressBarContainer, className)}>
            <div className={classes.progress}>
                <div className={classes.progressDone} style={style} />
            </div>
        </div>
    );
}
