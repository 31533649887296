/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useMemo, useState } from 'react';
import { SlovakReportController } from './slovakReportController';
import { initSlovakReportStore, TSlovakReportStore } from './slovakReportStore';
import SlovakReportStoreService from './slovakReportStoreService';

export type TSlovakReportContext = Readonly<[store: TSlovakReportStore, controller: SlovakReportController]>;

const SlovakReportContext = createContext<TSlovakReportContext>([initSlovakReportStore, new SlovakReportController(initSlovakReportStore, new SlovakReportStoreService(initSlovakReportStore, () => { }))]);

type TProps = {
    gsaaYear: number,
    gsaaKODKD: string,
    children: React.ReactNode
};

export const SlovakReportContextProvider = ({ children, gsaaYear, gsaaKODKD }: TProps) => {
    const [store, setStore] = useState({ ...initSlovakReportStore, gsaaYear, gsaaKODKD });

    const [storeService, controller] = useMemo<[SlovakReportStoreService, SlovakReportController]>(() => {
        const slovakReportStoreService = new SlovakReportStoreService(initSlovakReportStore, () => {});
        const slovakReportController = new SlovakReportController(initSlovakReportStore, slovakReportStoreService);

        return [slovakReportStoreService, slovakReportController];
    }, []);

    storeService.bind(store, setStore);
    controller.bind(store, storeService);

    return (
        <SlovakReportContext.Provider value={[store, controller]}>
            {children}
        </SlovakReportContext.Provider>
    );
};

export const useSlovakReportContext = () => useContext(SlovakReportContext);
