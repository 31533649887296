/* eslint-disable id-blacklist */
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { arrayUtils, dateTimeUtils } from 'shared/src/modules';
import { theme } from '@styles/theme';
import SVG from '@styles/svg/icons.svg';

const defaultOptions = {
    chart: {
        type: 'areaspline',
        height: 120,
        margin: [10, 10, 10, 50],
        backgroundColor: 'transparent',
    },
    title: {
        text: '',
    },
    xAxis: {
        type: 'datetime',
        lineWidth: 0,
        tickWidth: 0,
        labels: {
            enabled: false,
        },
        gridLineColor: 'transparent',
    },
    yAxis: {
        gridLineWidth: 0,
        height: 80,
        gridLineColor: 'transparent',
        labels: {
            enabled: false,
        },
    },
    tooltip: {
        backgroundColor: theme.color.white,
        borderColor: 'none',
        borderRadius: 4,
        style: {
            fontWeight: 'bold',
            padding: '0px 10px',
            zIndex: 2,
        },
        useHTML: true,
        formatter() {
            return `
            <div class="highchart-tooltips">
                <div class="highchart-tooltip-center">${dateTimeUtils.getDateTime(this.x)}</div>
                <div class="highchart-tooltip-currency highchart-tooltip-center">${this.y} ${this.series.name}</div>
            </div>
            `;
        },
    },
    plotOptions: {
        line: {
            dataLabels: {
                enabled: true,
            },
            enableMouseTracking: false,
        },
    },
    legend: {
        enabled: false,
    },
    series: [{
        lineWidth: 3,
        color: theme.color.go,
        marker: {
            enabled: false,
        },
        threshold: null,
    }],
    credits: {
        enabled: false,
    },
};

export default function WeatherChart(props) {
    const { className, color, iconId, type, weatherData, dateFormatter, unit, isShowZero = false } = props;
    const [options, setOptions] = useState();

    useEffect(() => {
        function getIconYAxisPosition() {
            if (arrayUtils.getMaxByKey(weatherData, 'data') === arrayUtils.getMinByKey(weatherData, 'data')) {
                return 20;
            }
            return -5;
        }

        if (Highcharts && weatherData && color && iconId && type && dateFormatter && unit) {
            const yAxis = defaultOptions.yAxis;
            const series = defaultOptions.series;
            const chart = defaultOptions.chart;
            const dataList: Array<any> = [];

            weatherData.forEach(chartData => {
                dataList.push(
                    [dateFormatter(chartData.date), chartData.data],
                );
            });

            const plotLines: Array<any> = [];
            if (isShowZero && arrayUtils.getMaxByKey(weatherData, 'data') > 0 && arrayUtils.getMinByKey(weatherData, 'data') < 0) {
                plotLines.push({
                    value: 0,
                    color: theme.color.water,
                    width: 2,
                    label: {
                        text: `0 ${unit}`,
                        style: {
                            fontSize: 10,
                            color: theme.color.water,
                        },
                        x: 5,
                        y: -5,
                    },
                });
            }

            setOptions({
                ...defaultOptions,
                chart: {
                    ...chart,
                    type,
                },
                yAxis: {
                    ...yAxis,
                    startOnTick: false,
                    endOnTick: false,
                    title: {
                        text:
                            `<svg style="height: 25px !important; width: 25px !important; fill: ${color};">
                                <use xlink:href="${SVG}#${iconId}" />
                            </svg>`,
                        x: -15,
                        y: getIconYAxisPosition(),
                        rotation: 0,
                        useHTML: true,
                    },
                    plotLines: [
                        ...plotLines,
                        {
                            value: arrayUtils.getMinByKey(weatherData, 'data'),
                            color: theme.color.water,
                            dashStyle: 'dash',
                            width: 1,
                            zIndex: 3,
                            label: {
                                text: `${arrayUtils.getMinByKey(weatherData, 'data')} ${unit}`,
                                style: {
                                    fontSize: 10,
                                    color: theme.color.raven,
                                },
                                x: -45,
                                y: 2,
                            },
                        }, {
                            value: arrayUtils.getMaxByKey(weatherData, 'data'),
                            color: theme.color.water,
                            dashStyle: 'dash',
                            width: 1,
                            label: {
                                text: `${arrayUtils.getMaxByKey(weatherData, 'data')} ${unit}`,
                                style: {
                                    fontSize: 10,
                                    color: theme.color.raven,
                                },
                                x: -45,
                                y: 2,
                            },
                        }],
                },
                series: [{
                    ...series[0],
                    name: unit,
                    color,
                    data: dataList,
                    fillColor: {
                        linearGradient: {
                            x1: 0, //from left to right
                            y1: 0, //from bottom to down
                            x2: 0, //from right to left
                            y2: 1, //from bottom to top
                        },
                        stops: [
                            [0, Highcharts.color(color).setOpacity(0.25).get('rgba')],
                            [1, Highcharts.color(color).setOpacity(0).get('rgba')],
                        ],
                    },
                }],
            });
        }
    }, [color, iconId, weatherData, type, dateFormatter, unit, isShowZero]);

    if (isEmpty(options)) {
        return null;
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            className={className}
        />
    );
}
