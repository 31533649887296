import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import useTranslate from '@i18n/useTranslate';
import { SETTINGS } from 'shared/src/constants';
import DataInput from '@baseComponents/inputs/DataInput';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { companyActions } from '@states/actions/companyActions';
import BasicFieldOverall from './BasicFieldOverall';
import BasicFieldsData from './BasicFieldsData';
import useLoadBasicFieldData from './useLoadBasicFieldData';

const useStyles = createUseStyles({
    basicFieldContainer: {
        position: 'relative',
    },
    basicFields: {
        marginTop: 40,
        '& button': {
            opacity: (props: any) => props.isBasicFieldCanAdd && 0.4,
        },
    },
});

export default function AddBasicField(props) {
    const { closeBasicFields, toggle, basicFieldsExpanded, loadedBasicFieldsList, isEdit } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const tableSetting = dispatch(companyActions.getSetting(SETTINGS.TABLE_AND_PERIOD_DIFFERENT_COMPANY));
    const { values, errors } = useFormikContext();
    const classes = useStyles({ isBasicFieldCanAdd: !getBasicFieldCanAdd() } as any);

    useLoadBasicFieldData({ loadedBasicFieldsList });

    function getBasicFieldCanAdd() {
        const isBasicFieldAdd = tableSetting || (!tableSetting && values.owner);
        if (!isBasicFieldAdd && basicFieldsExpanded) {
            closeBasicFields();
        }
        return isBasicFieldAdd;
    }

    function toggleBasicFields(state) {
        const windowContent = {
            component: BasicFieldsData,
            props: {
                close: closeBasicFields,
            },
        };
        toggle(state && windowContent);
    }

    return (
        <div className={classes.basicFieldContainer}>
            { isEdit && <LoadSpinner value={loadedBasicFieldsList} isAutoDetect />}
            <DataInput
                title={t('basicFields.basicFields', 'BASIC FIELDS')}
                label={t('basicFields.basicFields', 'BASIC FIELDS')}
                className={classes.basicFields}
                onClick={() => toggleBasicFields(!basicFieldsExpanded)}
                disabled={!getBasicFieldCanAdd()}
                invalid={!!errors.basicFields}
                errorMessage={errors.basicFields}
                required
            >
                {!isEmpty(values.basicFields) && <BasicFieldOverall basicFields={values.basicFields} />}
            </DataInput>
        </div>
    );
}
