import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Modal } from 'react-bootstrap';
import { LANGUAGE } from 'shared/src/constants';
import { languageUtils } from 'shared/src/modules/languageUtils';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '@baseComponents/buttons/Button';
import Svg from '@baseComponents/Svg';
import Input from '@baseComponents/inputs/Input';
import { errorActions } from '@states/actions/errorActions';
import { alertActions } from '@states/actions/alertActions';
import { translationService } from '@services';

const useStyles = createUseStyles({
    title: {
        margin: 0,
    },
    close: {
        height: 40,
        width: 40,
        borderRadius: '50%',
        border: 'none',
    },
    icon: {
        height: 24,
        width: 24,
    },
    flag: {
        width: 22,
        height: 16,
        marginRight: 10,
        border: 'none',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        cursor: 'inherit',
    },
    langContainer: {
        padding: '10px 0px',
    },
    valueSpace: {
        marginTop: 15,
        marginLeft: 35,
        '& h4': {
            margin: 0,
            fontFamily: 'Rubik',
            marginLeft: 15,
        },
    },
});

export default function TranslationModal(props) {
    const { moduleAndKey, hideModal, setTranslation, defaultText } = props;
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [currentLanguage] = useState(i18n.language);
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(false);
    const classes = useStyles();

    const { values, handleChange, handleSubmit, errors } = useFormik({
        initialValues: {
            translateValue: getCaption(),
        },
        validationSchema: Yup.object({
            translateValue: Yup.string()
                .required(t('translationModal.emptyField', 'Empty field')),
        }),
        validateOnChange: formIsDirty,
        onSubmit: value => saveTranslation(value),
    });

    async function saveTranslation(value) {
        try {
            setIsSaveBtnDisabled(true);
            const captionModule = languageUtils.getCaptionModule(moduleAndKey);
            const captionKey = languageUtils.getCaptionKey(moduleAndKey);
            let isSaveTranslation = false;
            if (captionModule && captionKey) {
                isSaveTranslation = await translationService.setLangTranslation(captionModule, captionKey, currentLanguage, value.translateValue);
            }
            if (isSaveTranslation) {
                setIsSaveBtnDisabled(false);
                dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved')));
                i18n.addResource(i18n.language, LANGUAGE.NAMESPACE.DEFAULT, moduleAndKey, value.translateValue);
                setTranslation(value.translateValue);
                hideModal();
            } else {
                setIsSaveBtnDisabled(false);
                dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed')));
            }
        } catch (error) {
            setIsSaveBtnDisabled(false);
            dispatch(errorActions.setError(error, 'Translation modal (save).'));
        }
    }

    function getCaption() {
        return t(moduleAndKey) === t(moduleAndKey, { lng: LANGUAGE.HUNGARIAN.toLowerCase() }) ? null : t(moduleAndKey);
    }

    return (
        <>
            <Modal.Header>
                <h2 className={classes.title}>{t('translationModal.translation', 'Translation') as any}</h2>
                <button type="button" onClick={hideModal} className={classes.close}>
                    <Svg iconId="icon-close" style={classes.icon} />
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className={classes.langContainer}>
                    <img className={classes.flag} src="/styles/img/flags/HU.png" alt="" />
                    {LANGUAGE.HUNGARIAN}:
                    <div className={classes.valueSpace}>
                        <h4>{t(moduleAndKey, defaultText, { lng: LANGUAGE.HUNGARIAN.toLowerCase() }) as any}</h4>
                    </div>
                </div>
                {!languageUtils.isSameLanguage(currentLanguage, LANGUAGE.ENGLISH) && t(moduleAndKey, { lng: LANGUAGE.ENGLISH.toLowerCase() }) !== t(moduleAndKey, { lng: LANGUAGE.HUNGARIAN.toLowerCase() }) &&
                    <div className={classes.langContainer}>
                        <img className={classes.flag} src="/styles/img/flags/EN.png" alt="" />
                        {LANGUAGE.ENGLISH}:
                        <div className={classes.valueSpace}>
                            <h4>{t(moduleAndKey, defaultText, { lng: LANGUAGE.ENGLISH.toLowerCase() }) as any}</h4>
                        </div>
                    </div>}
                <div className={classes.langContainer}>
                    <img className={classes.flag} src={`/styles/img/flags/${currentLanguage.toUpperCase()}.png`} alt="" />
                    {currentLanguage.toUpperCase()}:
                    <Input
                        name="translateValue"
                        value={values.translateValue}
                        onChange={handleChange}
                        className={classes.valueSpace}
                        invalid={!!errors.translateValue}
                        errorMessage={errors.translateValue}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => { setFormIsDirty(true); handleSubmit(); }} disabled={isSaveBtnDisabled}>{t('default.save', 'Save')}</Button>
                <Button type="secondary" onClick={hideModal}>{t('default.cancel', 'Cancel')}</Button>
            </Modal.Footer>
        </>
    );
}
