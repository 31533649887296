import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import Svg from '../../baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    productSupportContent: {
        marginTop: 20,
        ...theme.disableOutline,
    },
    subMenuIcon: {
        height: 24,
        width: 24,
        fill: theme.color.stone,
    },
    linkyStyle: {
        marginLeft: 5,
        cursor: 'pointer',
        display: 'inline-block',
        lineHeight: '24px',
        verticalAlign: 'middle',
    },
    openInNewTabLink: {
        ...theme.disableOutline,
        '&:hover': {
            color: theme.color.linkHoverColor,
            textDecoration: 'underline',
            textDecorationColor: theme.color.linkHoverColor,
        },
    },
}));

export default function UserMenuFooterSubMenu(props) {
    const { closeUserMenu, text, icon, redirectUrl, openInNewTab } = props;
    const classes = useStyles();

    function openUrlInNewTab(url) {
        window.open(url, '_blank');
    }

    return (
        <div className={classes.productSupportContent} onClick={closeUserMenu} onKeyDown={closeUserMenu} role="button" tabIndex={0}>
            <Svg iconId={icon} style={classes.subMenuIcon} />
            <div className={classes.linkyStyle}>
                {openInNewTab ? (
                    <div
                        onClick={() => openUrlInNewTab(redirectUrl)}
                        className={classes.openInNewTabLink}
                        onKeyDown={() => openUrlInNewTab(redirectUrl)}
                        role="button"
                        tabIndex={0}
                    >
                        {text}
                    </div>
                ) : (
                    <Link to={redirectUrl}>{text}</Link>
                )}
            </div>
        </div>
    );
}
