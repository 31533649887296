import { string } from 'yup';

export interface PlanningCultivationPeriod {
  id: number;
  name: string;
  productionYearId: number;
  startDate?: any;
  endDate?: any;
  sownArea: string;
  companyId: number;
  companyName: string;
  productionYear: string;
  cultureId?: number;
  cultureName?: string;
  cultureColorCode?: string;
  cropTypeId: number;
  type?: any;
  entityId: number;
  cropTypeName: string;
  entityArea: string;
  isClosedPeriod: number;
  farmId: number;
  farmName: string;
  nitrogenKgHa: number;
  reproductionRateId?: number;
  cultureVarietyId?: number;
  yield?: any;
  productCostPerHectare: number;
  machineCostPerHectare: number;
  wageCostPerHectare: number;
  operationCostPerHectare: number;
  technologyId?: number;
  technologyName?: string;
  totalCostPerHectare: number;
}

export interface PlanningCulture {
    id: string,
    rovidnev: string,
    nev: string,
    // eslint-disable-next-line camelcase
    kultura_kod: string,
    // eslint-disable-next-line camelcase
    szin_kod: string,
}

export interface PlanningCultureReprocutionScale {
    id: string,
    nev: string,
}

export interface PlanningCultureType {
    aktiv: string,
    // eslint-disable-next-line camelcase
    av_torzs_adat: string,
    // eslint-disable-next-line camelcase
    fajta_torzs_id: string,
    id: string,
    // eslint-disable-next-line camelcase
    kultura_id: string,
    nev: string,
}

export interface PlanningProductionYear {
    id: string,
    nev: string,
}

export interface PlanningCultivationTechnology {
    ceg: string,
    gazdasag: string,
    id: string,
    kultura: string,
    // eslint-disable-next-line camelcase
    kultura_id: string,
    nev: string,
    // eslint-disable-next-line camelcase
    termeny_tipus: string,
    // eslint-disable-next-line camelcase
    termeny_tipus_id: string,
}

export enum PlanningEditModalMode {
    SINGLE,
    MULTI
}
