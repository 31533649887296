import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isNull, isEmpty } from 'lodash';
import OutsideClickHandler from 'react-outside-click-handler';
import Svg from '@baseComponents/Svg';
import SelectBox from './SelectBox';

const useStyles = createUseStyles((theme: any) => ({
    select: {
        width: (props: any) => (props.width ? Number(props.width) : '100%'),
        minWidth: (props: any) => (props.width ? Number(props.width) : 200),
        position: 'relative',
        '& div': {
            width: '100%',
        },
    },
    container: {
        '&:hover': {
            cursor: (props: any) => (props.disabled ? 'default' : 'pointer'),
        },
        outline: 'none',
        height: (props: any) => {
            if (props.height) {
                return props.height;
            }
            switch (props.size) {
                case 'small':
                    return 36;
                case 'large':
                    return 44;
                default:
                    return 40;
            }
        },
        width: '100%',
        borderRadius: '4px',
        display: 'flex',
        boxShadow: (props: any) => (props.invalid ? theme.shadows.destructive.normal : theme.shadows.secondary.normal),
        border: 'solid 1px',
        borderColor: (props: any) => {
            if (props.disabled) {
                return theme.color.gray;
            }
            return props.invalid ? theme.color.destructive : theme.color.gray;
        },
        backgroundColor: (props: any) => (props.disabled ? theme.rgbaColor.lilian : theme.color.white),
        '&:focus': {
            boxShadow: (props: any) => !props.disabled && theme.shadows.primary.focus,
            borderColor: (props: any) => !props.disabled && theme.color.main,
        },
    },
    dropdown: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 40,
    },
    dataItem: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 15px',
        paddinTop: 0,
        paddingBottom: 0,
        fontSize: '15px',
        fontWeight: '300',
        color: theme.color.jet,
        ...theme.overFlowText,
    },
    itemText: {
        display: 'inline-block',
        ...theme.overFlowText,
    },
    icon: {
        height: (props: any) => (props.size === 'large' ? 26 : 24),
        width: (props: any) => (props.size === 'large' ? 26 : 24),
        margin: 'auto',
        fill: (props: any) => (props.disabled ? theme.color.gray : theme.color.black),
    },
    placeholder: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 15px',
        fontSize: '15px',
        fontWeight: '300',
        ...theme.overFlowText,
        ...theme.disableSelectionColor,
        color: (props: any) => (props.invalid ? theme.color.destructive : theme.color.stone),
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: (props: any) => (props.selected ? theme.color.main : theme.color.destructive),
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        color: theme.color.destructive,
    },
}));

export default function Select(props) {
    const { listData, size, type, selected, setSelected, width, height, fixedSelectbox, iconId, onChange, className, label, required, invalid, errorMessage, addOnClick, disabled } = props;
    const { t } = useTranslation();
    const [showItems, setShowItems] = useState(false);
    const classes = useStyles({ size, showItems, selected, invalid, width, height, disabled } as any);

    const selectedItemObject = useCallback(() => {
        if (listData && ((selected || isNull(selected)))) {
            return listData.find(dataItem => dataItem.value === selected);
        }
        return null;
    }, [listData, selected]);

    function dropdown() {
        if (!disabled) {
            setShowItems(!showItems);
        }
    }

    function handleOutsideClick() {
        if (showItems) {
            setShowItems(false);
        }
    }

    return (
        <div className={clsx(classes.select, className)}>
            {label && (
                <div className={classes.label}>
                    {label}
                    {required && <span className={classes.dot} />}
                </div>
            )}
            <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                <div className={clsx(classes.container, 'selectContainer')} onClick={dropdown} onKeyDown={dropdown} role="button" tabIndex={0}>
                    {(selected || isNull(selected)) && !isEmpty(listData) && selectedItemObject() && selectedItemObject().text ? (
                        <div className={classes.dataItem}>
                            <span className={classes.itemText}>
                                {selectedItemObject() && selectedItemObject().text}
                            </span>
                        </div>
                    ) : (
                        <div className={classes.placeholder}>
                            {t('default.pleaseSelect', 'Please select')}...
                        </div>
                    )}
                    <div className={classes.dropdown}>
                        {iconId && <Svg iconId={iconId} style={classes.icon} />}
                        {!iconId && (showItems ? (
                            <Svg iconId="icon-arrow_drop_up" style={classes.icon} />
                        ) : (
                            <Svg iconId="icon-arrow_drop_down" style={classes.icon} />
                        ))}
                    </div>
                </div>
                <SelectBox
                    listData={listData}
                    onChange={onChange}
                    selected={selected}
                    setSelected={setSelected}
                    showItems={showItems}
                    setShowItems={setShowItems}
                    type={type}
                    width={width}
                    addOnClick={addOnClick}
                    fixedSelectbox={fixedSelectbox}
                />
            </OutsideClickHandler>
            { invalid && errorMessage && (
                <div className={classes.errorMessage}>
                    {errorMessage}
                </div>
            )}
        </div>
    );
}
