import MapIconButton from '../MapIconButton';

type TProps = {
    onClick: () => void
}

export default function BackToGeomComponent({ onClick }: TProps) {
    return (
        <MapIconButton iconId="icon-cursor" onClick={onClick} isActive={false} />
    );
}
