import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isNumber } from 'lodash';
import { MAP } from 'shared/src/constants';
import useTranslate from '@i18n/useTranslate';
import { alertActions } from '@states/actions/alertActions';
import { errorActions } from '@states/actions/errorActions';
import { companyActions } from '@states/actions/companyActions';
import { theme as themes } from '@styles/theme';
import { mapService } from '@services';

import Button from '@baseComponents/buttons/Button';

const useStyles = createUseStyles({
    ndviButtonWrapper: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
    },
});

export default function NdviButtonCell(props) {
    const { rowData, onClick, isDisabled, className } = props;
    const { mapSvg, ndviStatus = -1, yearId, id, name, startDate, endDate } = rowData;
    const mapCompanyId = useSelector((state: any) => state.company.company.globalData.mapCompanyId);
    const { i18n } = useTranslation();
    const { t } = useTranslate();
    const mapServerUrl = useSelector((state: any) => state.company.company.map.serverUrl);
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const [ndviState, setNdviState] = useState();
    const classes = useStyles();

    //init status
    useEffect(() => {
        if (isNumber(ndviStatus)) {
            setNdviState(ndviStatus);
        }
    }, [ndviStatus]);

    function changeNdviState(state) {
        setNdviState(state);
        rowData.ndviStatus = state;
    }

    function getTitle() {
        switch (+ndviState) {
            case MAP.NDVI_STATUS.NONE:
                return t('cultivationPeriods.requestNdvi', 'Request NDVI data');
            case MAP.NDVI_STATUS.IN_PROGRESS:
                return t('cultivationPeriods.generateNdvi', 'NDVI data generation is in progress...');
            default:
                return t('cultivationPeriods.openNdvi', 'Open NDVI data');
        }
    }

    function getIconColor() {
        if (isDisabled) {
            return themes.color.gray;
        }

        switch (+ndviState) {
            case MAP.NDVI_STATUS.NONE:
                return themes.color.gray;
            case MAP.NDVI_STATUS.IN_PROGRESS:
                return themes.color.water;
            default:
                return themes.color.main;
        }
    }

    async function onClickButton() {
        if (!isDisabled && onClick) {
            onClick();
        }

        if (!isDisabled) {
            if (+ndviState > MAP.NDVI_STATUS.IN_PROGRESS) {
                openNDVI();
            } else if (+ndviState !== MAP.NDVI_STATUS.IN_PROGRESS) {
                createNDVI();
            }
        }
    }

    function openNDVI() {
        const langCode = i18n.language.toString();

        window.open_iframe_terkep_muhold(mapServerUrl, mapCompanyId, MAP.POLYGON_TYPE.CULTIVATION_PERIODS, yearId, id, name, langCode, startDate, endDate);
    }

    async function createNDVI() {
        const maxNdviCount = dispatch(companyActions.getMaxNdviCount());
        const ndviStatusResponse = await mapService.getNdvi(yearId, mapCompanyId, id, MAP.POLYGON_TYPE.CULTIVATION_PERIODS, maxNdviCount);

        if (ndviStatusResponse && +ndviStatusResponse.status === MAP.NDVI_STATUS.IN_PROGRESS) {
            const maxParcel = Number(ndviStatusResponse.max_parcel);
            let activeParcel = Number(ndviStatusResponse.active_parcel);
            if (activeParcel > maxParcel) { //if you switch to a smaller package
                activeParcel = maxParcel;
            }
            const monthlyActivity = Number(ndviStatusResponse.monthly_activity);
            const avaibleParcel = maxParcel - activeParcel;
            let monthlyAvaibleParcel = avaibleParcel - monthlyActivity;
            if (monthlyAvaibleParcel < 0) {
                monthlyAvaibleParcel = 0;
            }

            if (avaibleParcel > 0) { //if NDVI can be activated
                if (monthlyAvaibleParcel > 0) {
                    dispatch(alertActions.infoAlert(`Ebben a hónapban a még lekérhető NDVI területek száma: ${monthlyAvaibleParcel} darab.`));
                } else {
                    dispatch(alertActions.infoAlert(`Az NDVI adatok nem lekérhetők, mert a lekérések száma elérte az előfizetett csomagban meghatározott darabszámot.<br>A következő hónapban a még lekérhető NDVI területek száma: ${avaibleParcel} darab.`));
                }
            } else {
                dispatch(alertActions.infoAlert('Az NDVI adatok nem lekérhetők, mert az aktív NDVI területek száma elérte az előfizetett csomagban meghatározott darabszámot.'));
            }
        } else {
            try {
                const createNdviResponse = await mapService.createNdvi(mapCompanyId, id, MAP.POLYGON_TYPE.CULTIVATION_PERIODS);

                if (!createNdviResponse) {
                    dispatch(alertActions.errorAlert(t('default.failedSave', 'Failed to save')));
                } else {
                    changeNdviState(MAP.NDVI_STATUS.IN_PROGRESS);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
    }

    if (!mapSvg) {
        return null;
    }

    return (
        <div className={classes.ndviButtonWrapper}>
            <Button
                ignoreOtherClick
                size="small"
                type="secondary"
                iconId="icon-satellite"
                iconColor={getIconColor()}
                onClick={onClickButton}
                title={getTitle()}
                className={className}
                disabled={isDisabled}
                disabledOnClick={null}
                disabledPointerEvents="auto"
                tooltipDataEvent={!isDisabled ? 'click' : null}
            />
        </div>
    );
}
