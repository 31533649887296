import { Image } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import useTranslate from '@i18n/useTranslate';

const useStyles = createUseStyles({
    errorContainer: {
        width: 500,
        margin: 'auto',
        textAlign: 'center',
    },
    avLogo: {
        width: 106,
        margin: '30px auto',
    },
    center: {
        padding: 100,
        background: '#FFFFFF',
        boxShadow: '0 2px 33px 0 rgba(0,0,0,0.15)',
    },
    logoContainer: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: 50,
        color: '#252525',
    },
    textContainer: {
        fontSize: 16,
        color: '#252525',
        marginTop: 10,
    },
    faLockIcon: {
        fontSize: 50,
    },
});

export default function NoPermission() {
    const { t } = useTranslate();
    const classes = useStyles();

    return (
        <div className={classes.errorContainer}>
            <div className={classes.avLogo}>
                <Image src="/styles/img/av_small_logo.png" alt="Logo" width="106" height="38" />
            </div>
            <div className={classes.center}>
                <div className={classes.logoContainer}>
                    <i className={clsx('fa fa-lock', classes.faLockIcon)} />
                </div>
                <div className={classes.textContainer}>
                    <span>{t('errorPage.noPermission', 'No authorization to view this page.')}</span>
                </div>
            </div>
        </div>
    );
}
