import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { DATE } from 'shared/src/constants';

import useTranslate from '@i18n/useTranslate';
import { errorActions } from '@states/actions/errorActions';
import { theme } from '@styles/theme';
import { mapService } from '@services';
import { setPageLoading } from '@baseComponents/PageLoader';
import { dateTimeUtils } from 'shared/src/modules';
import WeatherForecastData from './WeatherForecastData';
import WeatherForecastModalCharts from './WeatherForecastModalCharts';
import WeatherForecastModalHeader from './WeatherForecastModalHeader';

const useStyles = createUseStyles({
    weatherModalContainer: {
        display: 'flex',
        flexFlow: 'column',
        minHeight: 750,
        minWidth: 1400,
        borderRadius: 10,
        boxShadow: '0 10px 50px 8px rgba(33, 40, 44, 0.15), 0 30px 40px 3px rgba(33, 40, 44, 0.2), 0 15px 15px -7px rgba(33, 40, 44, 0.3)',
        backgroundColor: theme.color.white,
        cursor: 'context-menu',
    },
    contentContainer: {
        padding: 20,
        margin: (props: any) => !props.isWeatherData && 'auto',
    },
    weatherData: {
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fit, minmax(10%, 1fr) )',
        margin: [0, 80, 15, 60],
    },
    noData: {
        display: 'flex',
        justifyContent: 'center',
        fontSize: 16,
        color: theme.color.stone,
        fontWeight: 500,
    },
});

export default function WeatherModal(props) {
    const { hideModal, periodId, periodName, showPeriodModal, mapCompanyId } = props || {};
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const { t } = useTranslate();
    const [selectedBreak, setSelectedBreak] = useState(DATE.BREAKDOWN.DAY);
    const [weatherData, setWeatherData] = useState();

    const classes = useStyles({ isWeatherData: !isEmpty(weatherData) });

    useEffect(() => {
        let isCancelled = false;
        async function loadWeatherForecastData() {
            try {
                const weatherForecastData = await mapService.getWeatherForecast(periodId, mapCompanyId, selectedBreak);
                if (!isEmpty(weatherForecastData) && !isCancelled) {
                    setWeatherData(weatherForecastData);
                }
                setPageLoading(false);
            } catch (error) {
                setPageLoading(false);
                dispatch(errorActions.setError(error, location));
            }
        }
        if (periodId && dispatch && location && mapCompanyId && selectedBreak) {
            setPageLoading(true);
            loadWeatherForecastData();
            return () => {
                isCancelled = true;
            };
        }
    }, [periodId, dispatch, location, mapCompanyId, selectedBreak]);

    function getDateBreakdowns() {
        if (selectedBreak === DATE.BREAKDOWN.WEEK) {
            return weatherData.data;
        }
        const dateBreakdown = weatherData?.data.filter((__, index) => index % 3 === 0);
        return dateBreakdown.slice(0, 7);
    }

    function getIsOtherDay(date) {
        if (selectedBreak === DATE.BREAKDOWN.WEEK) {
            return true;
        }

        const firstDate = dateTimeUtils.getDateDay(weatherData.data[0].date);
        const dataOtherDay = getDateBreakdowns().filter(dataObj => dateTimeUtils.getDateDay(dataObj.date) !== firstDate);

        if (isEmpty(dataOtherDay)) {
            return false;
        }
        return date === dataOtherDay[0]?.date;
    }

    function showPeriodView() {
        hideModal();
        showPeriodModal();
    }

    return (
        <>
            <div className={classes.weatherModalContainer}>
                <WeatherForecastModalHeader
                    showPeriodView={showPeriodView}
                    periodName={periodName}
                    selectedBreak={selectedBreak}
                    setSelectedBreak={setSelectedBreak}
                    hideModal={hideModal}
                    setWeatherData={setWeatherData}
                />
                <div className={classes.contentContainer}>
                    <div className={classes.weatherData}>
                        {!isEmpty(weatherData) && !isEmpty(weatherData.data) && getDateBreakdowns().map((weatherForecastData, index) => (
                            <WeatherForecastData
                                key={index}
                                weatherData={weatherForecastData}
                                isDateHeader={getIsOtherDay(weatherForecastData.date)}
                                index={index}
                                breakdown={selectedBreak}
                            />
                        ))}
                    </div>
                    {!isEmpty(weatherData) && !isEmpty(weatherData.data)
                        ? (
                            <WeatherForecastModalCharts
                                weatherData={weatherData}
                                selectedBreak={selectedBreak}
                            />)
                        : (
                            <div className={classes.noData}>
                                {t('default.noData', 'No data to display')}
                            </div>)}
                </div>
            </div>
        </>

    );
}
