import reactGA from 'react-ga';
import { numberUtils } from 'shared/src/modules';

export const googleAnalyticsService = {
    init,
};

let googleAnalyticsInited = false;

function init() {
    const isEnabled = numberUtils.getBoolean(process.env?.REACT_APP_GA_ENABLED);
    if (isEnabled) {
        if (!googleAnalyticsInited) {
            const appCode: any = process.env?.REACT_APP_GA_CODE;
            reactGA.initialize(appCode);
            reactGA.pageview(window.location.pathname + window.location.search);

            googleAnalyticsInited = 'loaded' in reactGA.ga;
        }

        return googleAnalyticsInited;
    }

    return false;
}
