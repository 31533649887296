import Button from '@baseComponents/buttons/Button';
import TextButton from '@baseComponents/buttons/TextButton';
import Svg from '@baseComponents/Svg';
import { yupResolver } from '@hookform/resolvers/yup';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import { MapMode } from '@map/services/mapEnums';
import { MapFilters, ThemeType } from '@map/services/mapTypes';
import { companyActions, userActions } from '@states/actions';
import { get, isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { INTERFACE_SETTINGS, INTERFACE_FILENAME, SETTINGS } from 'shared/src/constants';
import MapFilterCropType from './MapFilterCropType';
import MapFilterFarm from './MapFilterFarm';
import MapFilterFarmSelected from './MapFilterFarmSelected';
import MapFilterPartner from './MapFilterPartner';
import MapFilterPartnerSelected from './MapFilterPartnerSelected';
import MapFilterProdYear from './MapFilterProdYear';
import MapFilterProdYearSelected from './MapFilterProdYearSelected';
import { MapFilterSchema, mapFilterSchema, MAPFILTER_KEY } from './MapFilterSchema';
import MapFilterState from './MapFilterState';
import MapFilterStatus from './MapFilterStatus';
import MapFilterWorkOperationMainGroup from './MapFilterWorkOperationMainGroup';
import MapFilterWorkOperationMainGroupSelected from './MapFilterWorkOperationMainGroupSelected';
import MapFilterWorkOperationMainGroupState from './MapFilterWorkOperationMainGroupState';
import MapFilterCultureSelected from './MapFilterCultureSelected';
import MapFilterCulture from './MapFilterCulture';

type StyleType = {
    isFilterVisible: boolean,
}

type Classes = 'container' | 'headerItem' | 'containerHeaderIcon' | 'containerHeaderTitleContainer' | 'filterContainer' | 'bottomContainer' | 'bottomLeftSide' | 'bottomRightSide' | 'cancelButton' | 'filterContainerFilters' | 'containerTitle' | 'containerHeader';

const useStyles = createUseStyles<Classes, StyleType, ThemeType>(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        background: theme.color.white,
        position: 'relative',
        '.headerWrapper[.*]': {
            display: 'none !important',
        },
    },
    containerHeaderTitleContainer: {
        all: 'unset',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    containerTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.jet,
        textTransform: 'uppercase',
    },
    containerHeader: {
        paddingLeft: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 60,
    },
    containerHeaderIcon: {
        width: 20,
        height: 20,
    },
    filterContainer: {
        position: 'absolute',
        top: 60,
        width: '100%',
        display: props => (props.isFilterVisible ? 'flex' : 'none'),
        flexDirection: 'column',
        zIndex: 120,
        background: theme.color.white,
    },
    filterContainerFilters: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        paddingTop: 15,
        paddingLeft: 30,
        paddingRight: 30,
    },
    bottomContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        padding: [15, 0],
    },
    bottomLeftSide: {
        margin: [0, 0, 0, 30],
        width: 160,
        '& button': {
            border: 'none !important',
            '&:hover': {
                borderRadius: 4,
            },
        },
    },
    bottomRightSide: {
        margin: [0, 60, 0, 0],
    },
    cancelButton: {
        marginRight: 20,
    },
    headerItem: {
        margin: [0, 3],
        '&:nth-child(2)': {
            marginLeft: 30,
        },
    },
}));

const MapFilterComponent = () => {
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const classes = useStyles({ isFilterVisible });

    const filterForm = useForm<MapFilterSchema>({ resolver: yupResolver(mapFilterSchema) });

    const dispatch = useDispatch();

    const [store, controller] = useMapContext();

    const { t } = useTranslate();

    const [usingFarm] = useState(dispatch(companyActions.getSetting(SETTINGS.USING_FARM)));

    const interfaceSettings = useSelector((state: any) => state.user.user.settings.interfaceSettings) ?? null;

    const closeFilterForm = () => {
        const interfaceFileName = `${INTERFACE_FILENAME.MAP}_${store.mapId}_FILTER_${window.location.pathname}`;
        const interfaceSettingsValue = interfaceSettings?.[interfaceFileName]?.mapSettings?.value ?? null;

        if (!isEmpty(interfaceSettingsValue) && !isNil(interfaceSettingsValue)) {
            const parsedMapSettings: MapFilterSchema = JSON.parse(interfaceSettingsValue);
            const settingKeys = Object.keys(parsedMapSettings);
            settingKeys.forEach(settingKey => {
                const filterKey = settingKey as MAPFILTER_KEY;
                filterForm.setValue(filterKey, get(parsedMapSettings, filterKey));
            });
        } else {
            const currentProductionYearId = filterForm.getValues('productionYearId');
            filterForm.reset();
            filterForm.setValue('productionYearId', currentProductionYearId);
        }
        setIsFilterVisible(false);
    };

    const onFilterVisibleClick = () => {
        if (isFilterVisible) {
            closeFilterForm();
        } else {
            setIsFilterVisible(visible => !visible);
        }
    };

    const filterSubmit = async (filterData: MapFilterSchema) => {
        const currentFilters = store.filters;

        const filters: MapFilters = {
            ...currentFilters,
            companyIds: filterData.companyIds,
            statusId: filterData.statusId,
            showClosedPeriods: filterData.showClosedPeriods,
            productionYearId: filterData.productionYearId,
            farmIds: filterData.farmIds,
            cultureIds: filterData.cultureIds,
            cropType: filterData.cropType,
            workOperationMainGroupIds: filterData.workOperationMainGroupIds,
            workOperationStatus: filterData.workOperationStatus,
        };

        //on init mapFilter, run once
        if (isEmpty(store.filterInit)) {
            controller.setFilterInit({
                companyIds: filterData.companyIds,
                statusId: filterData.statusId,
                productionYearId: filterData.productionYearId,
                showClosedPeriods: filterData.showClosedPeriods,
                farmIds: filterData.farmIds,
                cultureIds: filterData.cultureIds,
                cropType: filterData.cropType,
                workOperationMainGroupIds: filterData.workOperationMainGroupIds,
                workOperationStatus: filterData.workOperationStatus,
            });
        } else {
            setIsFilterVisible(false);
            const fileName = `${INTERFACE_FILENAME.MAP}_${store.mapId}_FILTER_${window.location.pathname}`;

            dispatch(userActions.setInterfaceSetting(INTERFACE_SETTINGS.MAP_SETTINGS, JSON.stringify(filterData), fileName));

            if (store.filters.productionYearId !== filters.productionYearId) {
                await controller.setMapFiltersAndReloadGeometries(filters);
            } else {
                const shouldFetchAdditionalData = (store.filters.workOperationStatus !== filterData.workOperationStatus
                    || ([...store.filters.workOperationMainGroupIds].sort().join(',') !== [...filterData.workOperationMainGroupIds].sort().join(',')));
                await controller.setMapFiltersAndReloadGeometriesWithoutFetch(filters, shouldFetchAdditionalData);
            }
        }
    };

    const onApplyFilter = () => {
        filterForm.handleSubmit(filterSubmit)();
    };

    const onFilterReset = () => {
        const currentProductionYearId = filterForm.getValues('productionYearId');
        filterForm.reset();
        filterForm.setValue('productionYearId', currentProductionYearId);

        filterForm.handleSubmit(filterSubmit)();
    };

    const isPlanningMode = store.mapMode ? [MapMode.PLANNING, MapMode.PLANNING_EDIT].includes(store.mapMode) : false;

    return (
        <FormProvider {...filterForm}>
            <div className={classes.container}>
                <div className={classes.containerHeader}>
                    <button className={classes.containerHeaderTitleContainer} onClick={onFilterVisibleClick} type="button">
                        <Svg iconId={isFilterVisible ? 'icon-expand_less' : 'icon-expand_more'} style={classes.containerHeaderIcon} />
                        <div className={classes.containerTitle}>
                            {t('basePage.filter', 'Filter')}
                        </div>
                    </button>
                    <div className={classes.headerItem}>
                        <MapFilterProdYearSelected filterSubmit={filterSubmit} />
                    </div>
                    <div className={classes.headerItem}>
                        <MapFilterPartnerSelected filterSubmit={filterSubmit} />
                    </div>
                    <div className={classes.headerItem}>
                        <MapFilterCultureSelected filterSubmit={filterSubmit} />
                    </div>
                    {usingFarm ? (
                        <div className={classes.headerItem}>
                            <MapFilterFarmSelected filterSubmit={filterSubmit} />
                        </div>
                    ) : null}
                    {!isPlanningMode ? (
                        <div className={classes.headerItem}>
                            <MapFilterWorkOperationMainGroupSelected filterSubmit={filterSubmit} />
                        </div>
                    ) : null}
                </div>
                <div className={classes.filterContainer}>
                    <div className={classes.filterContainerFilters}>
                        <MapFilterProdYear filterSubmit={filterSubmit} />
                        {!isPlanningMode ? (
                            <MapFilterStatus />
                        ) : null}
                        {!isPlanningMode ? (
                            <MapFilterState />
                        ) : null}
                        <MapFilterCropType />
                        <MapFilterPartner />
                        <MapFilterCulture />
                        {usingFarm ? <MapFilterFarm /> : null}
                        {!isPlanningMode ? (
                            <MapFilterWorkOperationMainGroupState />
                        ) : null}
                        {!isPlanningMode ? (
                            <MapFilterWorkOperationMainGroup />
                        ) : null}
                    </div>
                    <div className={classes.bottomContainer}>
                        <div className={classes.bottomLeftSide}>
                            <TextButton onClick={onFilterReset} type="destructive">{t('basePage.deleteFilter', 'Remove Filters')}</TextButton>
                        </div>
                        <div className={classes.bottomRightSide}>
                            <Button className={classes.cancelButton} type="secondary" onClick={closeFilterForm}>{t('default.cancel', 'Cancel')}</Button>
                            <Button onClick={onApplyFilter}>{t('basePage.applyFilter', 'Apply filters')}</Button>
                        </div>
                    </div>
                </div>
            </div>
        </FormProvider>
    );
};

export default MapFilterComponent;
