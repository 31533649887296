import { Nullable } from '@flowsCommon/services/baseTypes';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { BuiltInAttributeTypes, BuiltInLayerTypes, getCustomLayerPropertyId, isCultureLayerAttribute, isCustomLayerAttribute } from '@map/services/mapEnums';
import MapStoreService from '@map/services/mapStoreService';
import { Layer, LayerAttribute, LayerAttributeColoringTypes, legendEntity, MapSliderStore } from '@map/services/mapTypes';
import { isEmpty, isNil, get } from 'lodash';
import turf, { Point } from '@turf/helpers';
import { dateTimeUtils } from 'shared/src/modules';
import { currencyFormatter, labelNumberFormatter } from './numberUtils';
import styles from './styles.module.css';

function hexToRgb(hex: string) {
    if (hex.charAt(0) !== '#') {
      throw new Error('Invalid hex code: must start with a #');
    }
  
    hex = hex.slice(1);
  
    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;
  
    return { r, g, b };
}
    
export const createLabel = (
    centroid: google.maps.LatLngLiteral | google.maps.LatLng,
    labelText?: string,
    map?: Nullable<google.maps.Map>,
    colorInHex?: string,
    outlineColorInHex?: string,
): google.maps.Marker => {
    let labelColor = colorInHex || (labelText ? '#FFFFFF' : '#000000');
    let shadowColor = outlineColorInHex || (labelText ? '#000000' : '#FFFFFF');

    const { r: r0, g: g0, b: b0 } = hexToRgb(labelColor);
    const { r: r1, g: g1, b: b1 } = hexToRgb(shadowColor);

    const label = new google.maps.Marker({
        position: centroid,
        map,
        icon: {
            url: encodeURI(`${window.location.origin}/gis-api/geom/textToImage?text=${labelText ?? 'N/A'}&color=rgb(${r0},${g0},${b0})&shadowColor=rgb(${r1},${g1},${b1})`),
            anchor: new google.maps.Point(200, 0),
        },
        clickable: false,
        title: labelText,
        zIndex: 1000,
    });
    return label;
};

export const createClusterLabel = (
    centroid: google.maps.LatLngLiteral | google.maps.LatLng,
    labelText?: string,
    map?: Nullable<google.maps.Map>,
    bgColor?: string,
): google.maps.Marker => {
    const backgroundColor = bgColor ? bgColor.replace('#', '') : '2BAE6A';

    const label = new google.maps.Marker({
        visible: Number(labelText) > 0,
        position: centroid,
        map,
        icon: {
            url: `${window.location.origin}/gis-api/geom/textToCircleImage?text=${labelText}&bgColor=${backgroundColor}&color=ffffff`,
        },
        zIndex: 1000,
    });
    return label;
};

export const createMachineMarker = (
    centroid: google.maps.LatLngLiteral | google.maps.LatLng,
    labelText: string,
    icon: string,
    map?: Nullable<google.maps.Map>,
): google.maps.Marker => {
    const gIcon = isEmpty(icon)
        ? {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 10,
        } : {
            url: `${window.location.origin}${icon}#customMachineMarker`,
            scaledSize: new window.google.maps.Size(50, 50),
        };
    const label = new google.maps.Marker({
        position: centroid,
        map,
        label: {
            text: labelText ?? 'N/A',
            fontFamily: 'Rubik',
            fontSize: '0px',
            fontWeight: '700',
            color: labelText ? 'white' : 'gray',
            className: styles.labelText,
        },
        icon: gIcon,
        zIndex: 1000,
    });
    return label;
};

export const createLabelForLabelLayer = (
    feature: google.maps.Data.Feature,
    attributeKey = 'name',
    labelLayer: google.maps.MVCArray<google.maps.Marker>,
    markerCluster: MarkerClusterer,
    mapSlider: MapSliderStore,
    suffix?: string,
    layer?: Layer,
) => {
    let attributeValue = feature.getProperty(attributeKey);

    if (layer?.name === BuiltInLayerTypes.CULTIVATION_PERIOD && attributeKey === BuiltInAttributeTypes.LAST_WORK_OPERATION_MAIN_GROUP) {
        const entityId = feature.getProperty('entityId');
        if (!isNil(layer.adittionalGeometryData) && !isNil(entityId)) {
            const pickedDate = mapSlider.isEnabled ? dateTimeUtils.getDateObject(mapSlider.startDate)?.format('YYYY-MM-DD') : dateTimeUtils.getDefaultDateNow();
            const entityData = get(layer?.adittionalGeometryData.geomData, entityId);
            const dateValue = mapSlider.isEnabled ? get(entityData, pickedDate) : entityData;
            const completedAreaPercentage = dateValue?.completedAreaPercentage;
            attributeValue = completedAreaPercentage;
        }
    }

    const labelLocation = feature.getProperty('centroid');
    if (!labelLocation) { return null; }

    let text = attributeValue;

    if (layer?.isCustomLayer && attributeKey === 'custom_layer_name') {
        text = layer.name;
    } else {
        if (typeof (text) !== 'number' && (isEmpty(text) || isNil(text))) {
            // eslint-disable-next-line no-undefined
            text = undefined;
        }
        if (Number.isFinite(Number(text))) {
            text = labelNumberFormatter(text, suffix);
        }

        text = text && `${text}${suffix ? ` ${suffix}` : ''}`;
    }

    if (text) {
        const label = createLabel(labelLocation, text);

        //TODO: remove after testing marker cluster
        //label.bindTo('map', labelLayer);
        label.set('entityId', feature.getProperty('entityId'));
        label.set('geomId', feature.getProperty('geomId'));
        labelLayer.push(label);
        markerCluster.addMarker(label, true);
        return label;
    }

    return null;
};

export const clearMVCArray = (array: google.maps.MVCArray<google.maps.MVCObject>) => {
    array.set('map', null);
    array.forEach((el: google.maps.MVCObject) => el.unbindAll());
    array.clear();
};

const handleDiscreteColoring = (layerAttribute: LayerAttribute) => {
    layerAttribute.dataLayer.forEach(feature => {
        feature.setProperty('fillColor', layerAttribute.defaultFillColor);
        feature.setProperty('strokeColor', layerAttribute.defaultStrokeColor);
        layerAttribute.dataLayer.overrideStyle(feature, {
            fillColor: layerAttribute.defaultFillColor,
            strokeColor: layerAttribute.defaultStrokeColor,
        });
    });
};

// Assigns a color from a green(min)-red(max) scale
const getFillAndStrokeColorByValue = (minValue: number, maxValue: number, value: number, reversed?: boolean): [string, string] => {
    if (minValue === maxValue) {
        return ['hsl(120,100%,50%)', 'hsl(120,100%,25%)'];
    }

    if (reversed) {
        return [
            `hsl(${(((((value - minValue)) / (maxValue - minValue))) * 120)},100%,50%)`,
            `hsl(${(((((value - minValue)) / (maxValue - minValue))) * 120)},100%,25%)`,
        ];
    }

    return [`hsl(${((1 - (((value - minValue)) / (maxValue - minValue))) * 120)},100%,50%)`,
        `hsl(${((1 - (((value - minValue)) / (maxValue - minValue))) * 120)},100%,25%)`];
};

const getlegendItemForValue = (id: number, minValue: number, maxValue: number, value: number, suffix: string, reversed: boolean): legendEntity => {
    const [fC, sC] = getFillAndStrokeColorByValue(minValue, maxValue, value, reversed);
    return { id, fillColor: fC, range: `${currencyFormatter(value.toFixed(2).toString())} ${suffix}` };
};

const getlegendItemForRange = (id: number, minValue: number, maxValue: number, r1: number, r2: number, suffix:string, reversed: boolean): legendEntity => {
    const middle = (r1 + r2) / 2;
    const [fC, sC] = getFillAndStrokeColorByValue(minValue, maxValue, middle, reversed);
    return { id, fillColor: fC, range: `${currencyFormatter(r1.toFixed(2).toString())} - ${currencyFormatter(r2.toFixed(2).toString())} ${suffix}` };
};

const handleScaleColoring = (layerAttribute: LayerAttribute, storeService: MapStoreService) => {
    const allValues: number[] = [];
    layerAttribute.dataLayer.forEach(feature => {
        let attributeValue = feature.getProperty(layerAttribute.attributeKey) as number | string | null;
        if (!isNil(attributeValue)) {
            attributeValue = Number(attributeValue);

            if (!allValues.includes(attributeValue)) {
                allValues.push(attributeValue);
            }
        }
    });

    let minValue = allValues.length ? Math.min(...allValues) : 0;
    let maxValue = allValues.length ? Math.max(...allValues) : 0;

    if (layerAttribute.layerName === BuiltInLayerTypes.CULTIVATION_PERIOD && layerAttribute.attributeKey === BuiltInAttributeTypes.COMPLETED_AREA_PERCENTAGE) {
        minValue = 0;
        maxValue = 100;
    }

    let reversedColoring = (layerAttribute.layerName === BuiltInLayerTypes.CULTIVATION_PERIOD && layerAttribute.attributeKey === BuiltInAttributeTypes.COMPLETED_AREA_PERCENTAGE)
                         || (layerAttribute.attributeKey === 'yield' && layerAttribute.layerName === BuiltInLayerTypes.CULTIVATION_PERIOD);

    const numOfRanges = Math.min(7, allValues.length);
    if (allValues.length > 0) {
        const rangePerPart = ((maxValue - minValue) / numOfRanges);
        layerAttribute.dataLayer.forEach(feature => {
            let fillColor = '#000';
            let strokeColor = '#000';

            let attributeValue = feature.getProperty(layerAttribute.attributeKey);
            attributeValue = Number(attributeValue);

            if (Number.isFinite(attributeValue) && +attributeValue >= minValue && +attributeValue <= maxValue) {
                [fillColor, strokeColor] = getFillAndStrokeColorByValue(minValue, maxValue, attributeValue, reversedColoring);
                let legendId = (Math.floor((attributeValue - minValue) / rangePerPart) || 0) + 1;
                legendId = Math.max(1, Math.min(legendId, numOfRanges));
                feature.setProperty('legendId', legendId);
            } else {
                feature.setProperty('legendId', -1);

            }

            feature.setProperty('fillColor', fillColor);
            feature.setProperty('strokeColor', strokeColor);
            layerAttribute.dataLayer.overrideStyle(feature, { fillColor, strokeColor });
        });
    } else {
        layerAttribute.dataLayer.forEach(feature => {
            feature.setProperty('legendId', -1);
            feature.setProperty('fillColor', '#000');
            feature.setProperty('strokeColor', '#000');
            layerAttribute.dataLayer.overrideStyle(feature, { fillColor: '#000', strokeColor: '#000' });
        });
    }

    const lList: legendEntity[] = [];
    lList.push({ id: -1, fillColor: '#000', range: 'N/A' });

    if (minValue !== maxValue && allValues.length > 0) {
        const piece = (maxValue - minValue) / numOfRanges;
        for (let i = 0; i < numOfRanges; i++) {
            const rangeA = minValue + i * piece;
            const rangeB = minValue + (i + 1) * piece;
            lList.push(getlegendItemForRange(i + 1, minValue, maxValue, rangeA, rangeB, layerAttribute.suffix ?? '', reversedColoring));
        }
    } else if (allValues.length > 0) {
        lList.push(getlegendItemForValue(1, minValue, maxValue, minValue, layerAttribute.suffix ?? '', reversedColoring));
    }

    storeService.setLayerAttributeLegend(layerAttribute, lList);
};

const customForAllAttributes = [BuiltInLayerTypes.REGISTRY_NUMBER.valueOf()];

const handleCustomColoring = (layerAttribute: LayerAttribute, storeGlobal: any, storeService: MapStoreService, layer: Layer, mapSlider: MapSliderStore) => {
    const { cultures, workOperationMainGroups } = storeGlobal;
    if (isCustomLayerAttribute(layerAttribute.attributeKey) || customForAllAttributes.includes(layerAttribute.layerName)) {
        const isCultureAttribute = layerAttribute.attributeKey === 'culture';
        const lList: legendEntity[] = [];
        const listOfUsedData: number[] = [];
        const propertyIdToLabelId = {};
        
        lList.push({ id: -1, fillColor: '#000', range: 'N/A' });

        const dataList: any[] = [];

        if (isCultureAttribute) {
            dataList.push(...(cultures ?? []));
        }

        layerAttribute.dataLayer.forEach(feature => {
            const entityIdProperty = feature?.getProperty('entityId') ?? -1;

            if (cultures && workOperationMainGroups) {
                switch (layerAttribute.attributeKey) {
                    case 'lastWorkOperationMainGroup':
                        // eslint-disable-next-line no-case-declarations
                        const entityId = feature.getProperty('entityId');
                        if (!isNil(layer?.adittionalGeometryData) && !isNil(entityId)) {
                            const pickedDate = mapSlider.isEnabled ? dateTimeUtils.getDateObject(mapSlider.startDate)?.format('YYYY-MM-DD') : dateTimeUtils.getDefaultDateNow();
                            const entityData = get(layer?.adittionalGeometryData.geomData, entityId);
                            const dateValue = mapSlider.isEnabled ? get(entityData, pickedDate) : entityData;
                            const colorCode = dateValue?.lastWorkOperationMainGroupColorCode ?? '#000';
                            feature.setProperty('fillColor', colorCode);
                            feature.setProperty('strokeColor', colorCode);
                            const isApproved = !isNil(dateValue?.approvalDate);
                            if (isApproved) {
                                feature.setProperty('defaultFillOpacity', 0.8);
                                layerAttribute.dataLayer.overrideStyle(feature, { fillColor: colorCode, strokeColor: colorCode, fillOpacity: 0.8 });
                            } else {
                                feature.setProperty('defaultFillOpacity', 0.4);
                                layerAttribute.dataLayer.overrideStyle(feature, { fillColor: colorCode, strokeColor: colorCode, fillOpacity: 0.4 });
                            }
                            const lastWorkOperationMainGroupName = dateValue?.lastWorkOperationMainGroupName;
                            const lastWorkOperationMainGroupId = dateValue?.lastWorkOperationMainGroupId;
                            if (!isNil(lastWorkOperationMainGroupName) && !isNil(lastWorkOperationMainGroupId)) {
                                if (listOfUsedData.indexOf(lastWorkOperationMainGroupId) === -1) {
                                    listOfUsedData.push(lastWorkOperationMainGroupId);
                                    const dataName = lastWorkOperationMainGroupName;

                                    const legendId = lList.length + 1;
                                    propertyIdToLabelId[lastWorkOperationMainGroupId] = legendId;

                                    lList.push({ id: lList.length + 1, fillColor: colorCode, range: dataName });
                                }
                            }
                            feature.setProperty('legendId', propertyIdToLabelId?.[lastWorkOperationMainGroupId] || -1);
                        }
                        

                        // eslint-disable-next-line no-nested-ternary
                        lList.sort((a, b) => ((a.fillColor > b.fillColor) ? 1 : ((b.fillColor > a.fillColor) ? -1 : 0)));
                        return;
                    case 'companyName':
                        const companyColor = feature.getProperty('companyColorCode');
                        const companyName = feature.getProperty('companyName');
                        const companyId = feature.getProperty('companyId');

                        if (companyColor && !dataList.some(c => c?.id === companyId)) { 
                            dataList.push({ id: companyId, name: companyName, colorCode: companyColor });
                        }
                        break;
                    default:
                        break;
                }
            }

            let propertyIdName = getCustomLayerPropertyId(layerAttribute.attributeKey);

            if (layerAttribute.layerName === BuiltInLayerTypes.REGISTRY_NUMBER) {
                propertyIdName = 'entityId';

                const registryNumberColor = feature.getProperty('registryNumberColorCode');

                if (registryNumberColor && !dataList.some(c => c?.id === entityIdProperty)) { 
                    dataList.push({ id: entityIdProperty, colorCode: registryNumberColor });
                }
            }

            let propertyId = feature.getProperty(propertyIdName);
            if (typeof propertyId === 'string') {
                propertyId = Number(propertyId);
            }

            let color = layerAttribute.defaultFillColor ?? '#000';
            const dataColor = dataList?.find(c => c?.id === propertyId)?.colorCode;

            if (dataColor) {
                if (listOfUsedData.indexOf(propertyId) === -1) {
                    listOfUsedData.push(propertyId);

                    const dataName = dataList?.find(c => c?.id === propertyId)?.name;
                    const legendId = lList.length + 1;

                    propertyIdToLabelId[propertyId] = legendId;

                    lList.push({ id: legendId, fillColor: dataColor, range: dataName });
                }

                color = dataColor;
            }

            feature.setProperty('legendId', propertyIdToLabelId?.[propertyId] || -1);

            feature.setProperty('fillColor', color);
            feature.setProperty('strokeColor', color);
            layerAttribute.dataLayer.overrideStyle(feature, { fillColor: color, strokeColor: color });
        });
        if (!isEmpty(lList) && isCustomLayerAttribute(layerAttribute.attributeKey)) {
            storeService.setLayerAttributeLegend(layerAttribute, lList);
        }
    }
};

export const createLabelsAndColoring = (layerAttribute: LayerAttribute, storeGlobal: any, storeService: MapStoreService, mapSlider: MapSliderStore, layer: Layer) => {
    const labels = layerAttribute.labelLayer;
    const geometries = layerAttribute.dataLayer;
    const markerCluster = layerAttribute.markerCluster;

    clearMVCArray(layerAttribute.labelLayer);
    let attributeKey = layerAttribute.attributeKey;
    if (isCultureLayerAttribute(attributeKey)) {
        attributeKey = `${layerAttribute.attributeKey}Name`;
    }

    const layerAttributeToRender = { ...layerAttribute };

    geometries.forEach(feature => createLabelForLabelLayer(feature, attributeKey, labels, markerCluster, mapSlider, layerAttributeToRender.suffix, layer));

    switch (layerAttribute.coloringType) {
        case LayerAttributeColoringTypes.DISCRETE:
            handleDiscreteColoring(layerAttributeToRender);
            break;
        case LayerAttributeColoringTypes.SCALE:
            handleScaleColoring(layerAttributeToRender, storeService);
            break;
        case LayerAttributeColoringTypes.CUSTOM:
            handleCustomColoring(layerAttributeToRender, storeGlobal, storeService, layer, mapSlider);
            break;
        default:
            break;
    }

    if (layerAttributeToRender.layerName === BuiltInLayerTypes.REGISTRY_NUMBER) {
        handleCustomColoring(layerAttributeToRender, storeGlobal, storeService, layer, mapSlider);
    }
};

export const createMeteorolyLabel = (layerAttribute: LayerAttribute, parentLayer: Layer) => {
    const collection = parentLayer.geometries as turf.FeatureCollection<Point>;

    if (isEmpty(collection)) {
        return;
    }
    clearMVCArray(layerAttribute.labelLayer);

    const meteorolgyWithData = collection?.features.filter(f => !isNil(get(f.properties, layerAttribute.attributeKey)));

    const meteorolgyWithDataWithCoors = meteorolgyWithData.map(mData => ({ mData: { ...mData }, location: new google.maps.LatLng({ lat: mData.geometry.coordinates[1], lng: mData.geometry.coordinates[0] }) }));

    meteorolgyWithDataWithCoors.forEach(meteorology => {
        const labelSuffix = get(meteorology.mData.properties, `${layerAttribute.attributeKey}Unit`);
        const labelSuffixToShow = labelSuffix ? ` ${labelSuffix}` : '';
        const labelText = get(meteorology.mData.properties, layerAttribute.attributeKey) ?? '';
        const gisSuffix = (!isNil(layerAttribute.suffix) ? ` ${layerAttribute.suffix}` : '');
        // eslint-disable-next-line no-undefined
        const label = createLabel(meteorology.location, `${labelText}${labelSuffixToShow}${gisSuffix}`, undefined, new google.maps.Point(200, 0));
        layerAttribute.labelLayer.push(label);
    });
};
