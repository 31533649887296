import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyle = createUseStyles((theme: any) => ({
    fader: {
        display: 'none', //on visible: block
        position: 'absolute',
        left: props => props.leftPosition,
        top: 0,
        height: props => props.height || theme.table.tableRowFaderHeight,
        width: theme.table.leftPagerWidth,
        background: 'linear-gradient(270deg, rgba(240,243,244,0) 0%, #F0F3F4 100%)',
        zIndex: theme.zIndex.tableColumnFader,
        pointerEvents: 'none',
    },
}));

export default function TableColumnLeftFader(props) {
    const { leftPosition, height = null } = props;
    const { reference } = props; //TODO: current table fader
    const classes = useStyle({ leftPosition, height });

    return (
        <div className={clsx(classes.fader, 'leftFader')} ref={reference} />
    );
}
