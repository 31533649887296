import {
    delay, put, takeEvery, call, select, all,
} from 'redux-saga/effects';
import { objectUtils } from 'shared/src/modules';
import { reduxStore } from '@states/store';
import { basePageActions, userActions, tableFilterActions } from '@states/actions';
import { showErrorAlert, setApiParams, setInterfaceData } from './sagaUtils';

const getUserReducer = state => state.user;
const getTranslationReducer = state => state.translation;
const getTableFilterReducer = state => state.tableFilter;

export function* resetFilter({ payload: { closeFunction = null, actionType } }) {
    try {
        if (closeFunction) {
            yield delay(1000);
            closeFunction();
            yield put({
                type: actionType,
            });
        }
    } catch (error) {
        console.log('Filter Error: ', error); // eslint-disable-line
    }
}

export function* getFilterOptions({ payload: { params, dynamicParam, filterName } }) {
    const { args, setDataFromResponse, apiServiceFunction } = params;
    try {
        const argsList = Object.entries(args).map(arg => {
            if (!arg[1]) {
                return dynamicParam;
            }
            return arg[1];
        });

        const response = yield call(apiServiceFunction, ...argsList);
        const options = setDataFromResponse(response);
        yield put({
            type: tableFilterActions.SET_SINGLE_FILTER_OPTIONLIST,
            payload: {
                key: filterName,
                options,
            },
        });
        yield put({
            type: tableFilterActions.GET_FILTER_OPTIONS_SUCCEEDED,
            payload: {
                filterName,
            },
        });
        if (dynamicParam) {
            yield put({
                type: tableFilterActions.REQUEST_SAVE_SUBFILTER_INTERFACE_SETTING,
                payload: {
                    key: filterName,
                    value: dynamicParam,
                },
            });
        }
    } catch (error) {
        const translationReducer = yield select(getTranslationReducer);
        const { t } = translationReducer;
        yield put({
            type: tableFilterActions.GET_FILTER_OPTIONS_FAILED,
            payload: {
                filterName,
                errorMessage: error.message,
            },
        });
        yield put(showErrorAlert(t('error.default', 'An error occurred while performing the operation!'), error));
        console.log(`${filterName} filter error: `, error); // eslint-disable-line
    }
}

export function* setSubfiltersInterfaceSetting({ payload: { key, value } }) {
    const tableFilterReducer = yield select(getTableFilterReducer);
    const { downloadParams: { moduleName, subFilterInterfaceName } } = tableFilterReducer;
    const { user: { settings: { interfaceSettings } } } = yield select(getUserReducer);
    const subFilterInterfaceSettings = objectUtils.parseJSONToObject(interfaceSettings[moduleName]?.[subFilterInterfaceName]?.value);
    const values = JSON.stringify({
        ...subFilterInterfaceSettings,
        [key]: value,
    });
    reduxStore.dispatch(userActions.setInterfaceSetting(
        subFilterInterfaceName,
        values,
        moduleName,
    ));
}

export function* getTableData() {
    try {
        // GET FILTERS AND DOWNLOAD PARAMETERS
        const tableFilterReducer = yield select(getTableFilterReducer);
        const { filters, filterTypes } = tableFilterReducer || {};
        const { staticParams, apiServiceFunction, setDataFromResponse, moduleName, interfaceName, isSavingDisabled } = tableFilterReducer?.downloadParams || {};

        if (!apiServiceFunction) {
            return;
        }

        // GET RAW DATA
        const params = setApiParams(filters, staticParams, filterTypes);

        if (filters.dateRange && params.startDate) {
            filters.dateRange.startDate = params.startDate;
            filters.dateRange.endDate = params.endDate;
        }

        const rawResponse = yield call(apiServiceFunction, params);

        // SET TABLE DATA FROM RAW DATA
        const tableData = setDataFromResponse(rawResponse);

        // PUT DATA INTO STORE
        yield all([
            put({
                type: tableFilterActions.GET_TABLE_DATA_SUCCEEDED,
            }),
            put({
                type: basePageActions.SET_TABLE_DATA,
                payload: {
                    tableData,
                },
            }),
        ]);

        const interfaceData = setInterfaceData(filterTypes, filters);

        // SEND INTERFACE DATA
        if (!isSavingDisabled && interfaceData) {
            reduxStore.dispatch(userActions.setInterfaceSetting(
                interfaceName,
                interfaceData,
                moduleName,
            ));
        }
    } catch (error) {
        const translationReducer = yield select(getTranslationReducer);
        const { t } = translationReducer;
        yield put({
            type: tableFilterActions.GET_TABLE_DATA_FAILED,
            payload: {
                errorMessage: error.message,
            },
        });
        yield put(showErrorAlert(t('error.default', 'An error occurred while performing the operation!'), error));
        console.log('Workoperations Management Data Request Error: ', error); // eslint-disable-line
    }
}

export function* requestTableData() {
    try {
        yield put({
            type: tableFilterActions.GET_TABLE_DATA_REQUESTED,
        });
    } catch (error) {
        console.log(error); // eslint-disable-line
    }
}

export const tableFilterSagas = [
    takeEvery(tableFilterActions.RESET_TABLE_FILTER, resetFilter),
    takeEvery(tableFilterActions.GET_FILTER_OPTIONS_REQUESTED, getFilterOptions),
    takeEvery(tableFilterActions.REQUEST_SAVE_SUBFILTER_INTERFACE_SETTING, setSubfiltersInterfaceSetting),
    takeEvery(tableFilterActions.GET_TABLE_DATA_REQUESTED, getTableData),
    takeEvery(tableFilterActions.APPLY_TABLE_CURRENT_FILTER_DATA, requestTableData),
    takeEvery(tableFilterActions.DELETE_TABLE_FILTER_TAG, requestTableData),
];
