import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import { SETTINGS } from 'shared/src/constants';

import { companyActions } from '@states/actions';
import { createUseStyles } from 'react-jss';
import { SimpleSelect } from '@baseComponents/select';
import { numberUtils } from 'shared/src/modules';

const useStyles = createUseStyles({
    selectWrapper: {
        '& .dropdown-toggle': {
            height: 32,
            margin: '2px 0px',
            marginRight: 0,
            paddingRight: 0,
        },
    },
    seletDropdownClassName: {
        width: '80px !important',
        maxWidth: '80px !important',
        minWidth: '80px !important',
    },
});

export default function BooleanSearcher(props) {
    const { onChange } = props;
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const [dataList] = useState([
        { key: 'false', value: t('default.no', 'No') },
        { key: 'true', value: t('default.yes', 'Yes') },
    ]);
    const [isNonAccentSearch] = useState(dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)));

    const classes = useStyles();

    //on changed value - start filtering
    function onChangeSelect(selectedKey) {
        if (isEmpty(selectedKey)) {
            onChange(null);
            return;
        }

        onChange(numberUtils.getBoolean(selectedKey));
    }

    function onClick(event) {
        event.stopPropagation();
    }

    return (
        <div onClick={onClick} onKeyDown={onClick} role="button" tabIndex="0">
            <SimpleSelect
                placeholder={t('default.all', 'All')}
                className={classes.selectWrapper}
                seletDropdownClassName={classes.seletDropdownClassName}
                isNonAccentSearch={isNonAccentSearch}
                dataList={dataList}
                onChange={onChangeSelect}
                emptyOption={{ key: null, value: t('default.all', 'All') }}
                useAbsoluteMenu
            />
        </div>
    );
}
