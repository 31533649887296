import { createUseStyles } from 'react-jss';
import { uniqueId, isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import useRegistryNumberTableData from './useRegistryNumberTableData';
import { TABLE } from 'shared/src/constants';

type Classes = 'container' | 'tableWrapper' | 'tableFooterWrapper' | 'card' | 'cardContainer' | 'table';

const useStyles = createUseStyles<Classes>((theme: any) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingLeft: 30,
    },
    card: {
        marginTop: 15,
    },
    cardContainer: {
        height: '100%',
        marginLeft: 30,
    },
    tableWrapper: {
        position: 'relative',
        height: '100%',
    },
    tableFooterWrapper: {
        height: 68,
        marginRight: 30,
        background: theme.color.white,
        '& .footerWrapper': {
            boxShadow: 'none',
        },
    },
    table: {
        '& .tableHeaderRow': {
            marginRight: 20,
            paddingRight: 10,
        },
        '& .List': {
            marginRight: 20,
            paddingRight: 10,
            ...theme.verticalScrollbar,
        },
        '& .table-row': {
            marginRight: 20,
            paddingRight: '10px !important',
        },
        '& .table-row>[role=button]': {
            border: '1px solid',
            borderColor: theme.color.pearl,
            backgroundColor: theme.color.lilian,
            color: theme.color.raven,
            fontSize: 13,
            fontWeight: 'bold',
        },
        height: '100%',
        width: '100%',
        paddingRight: 30,
    },
}));

const TABLE_KEY = uniqueId('cultivationperiodRegistryNumbersTable');

export default function RegistryNumberTable() {
    const { values } = useFormikContext();
    const classes = useStyles();

    const { columns, tableGroup } = useRegistryNumberTableData();

    return (
        <div className={classes.cardContainer}>
            {!isEmpty(values.registryNumber)
                && (
                    <div className={classes.container}>
                    <div className={classes.tableWrapper}>
                    <TableGroup
                        tableId={TABLE_KEY}
                        tableData={values.selectedRegistryNumbers}
                        tableColumns={columns}
                        isBasePageTable={false}
                        isCollapsible={false}
                        tableGroup={tableGroup}
                        isDataCard
                        className={classes.table}
                        aggregateType={TABLE.AGGREGATE.SUM}
                    />
                    </div>
                    <div className={classes.tableFooterWrapper}>
                        <TableScrollerFooter tableKey={TABLE_KEY} isLoaded={values.selectedRegistryNumbers} isBasePageTable={false} isHasSumFooter />
                    </div>
                    </div>)}
        </div>
    );
}
