export const ACCOUNTING_SYSTEM = {
    TYPE: {
        DIRECT_ACCOUNTING: 0,
        MANAGEMENT_SYSTEM: 1,
        IBS: 2,
        WINMENTOR: 3,
        COBRA: 4,
        AX: 5,
        NAVISION: 6,
        FAP: 7,
        KRAFT: 8,
        OTHER: 9,
    },
};
