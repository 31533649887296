import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Svg from '../Svg';

const useStyles = createUseStyles((theme: any) => ({
    noItemContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
    },
    noItem: {
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        fontSize: 16,
        lineHeight: 2.5,
        color: theme.color.stone,
    },
    iconContainer: {
        height: 120,
        width: 120,
        backgroundColor: theme.color.pearl,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: [10, 'auto'],
    },
    icon: {
        height: 60,
        width: 60,
        fill: theme.color.white,
    },
}));

export default function NoData(props) {
    const { isAdd, className } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.noItemContainer}>
            <div className={clsx(classes.noItem, className)}>
                {isAdd && (
                    <div className={classes.iconContainer}>
                        <Svg style={classes.icon} iconId="icon-add" />
                    </div>)}
                {t('default.noData', 'No data')}
            </div>
        </div>
    );
}
