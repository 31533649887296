export const SUPPORT = {
    MODULE_ID: 28,
    NAME: 'support',
    CONTENTS: {
        COMMENTS: {
            CONTENT_URL_ID: { comments: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'comments',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.comments',
                TRANSLATOR_DEFAULT: 'Comments',
            },
        },
        ADD_NEW_COMMENT: {
            CONTENT_URL_ID: { addNewComment: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'addNewComment',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.addNewComment',
                TRANSLATOR_DEFAULT: 'Add new comment',
            },
        },
    },
};
