import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import DatePicker from 'react-datepicker';
import useTranslate from '@i18n/useTranslate';
import { dateTimeUtils } from 'shared/src/modules';
import { sessionService } from '@services';
import Button from '@baseComponents/buttons/Button';
import Input from '../../inputs/Input';
import useCalendarData from '../simpleCalendar/useCalendarData';

const useStyles = createUseStyles((theme: any) => ({
    calendarMainContainer: {
        display: 'flex',
    },
    calendarLeftContainer: {
        minWidth: 479,
    },
    calendar: {
        display: 'flex',
    },
    buttonContainer: {
        marginLeft: -40,
    },
    stepperLeft: {
        marginLeft: -455,
        marginTop: 6,
        position: 'relative',
        height: 20,
        width: 20,
        borderRadius: '50%',
        backgroundColor: theme.color.pearl,
        border: 'none',
        outline: 'none',
    },
    stepperRight: {
        marginLeft: -30,
        float: 'right',
        marginTop: 6,
        position: 'relative',
        height: 20,
        width: 20,
        borderRadius: '50%',
        border: 'none',
        backgroundColor: theme.color.pearl,
        outline: 'none',
    },
    stepperIcon: {
        height: 20,
        width: 20,
        marginLeft: -6,
        marginTop: -1,
    },
    calendarRightContainer: {
        width: 240,
    },
    selectContainer: {
        marginBottom: 20,
    },
    inputContainer: {
        display: 'flex',
    },
    startInput: {
        width: 115,
    },
    endInput: {
        width: 115,
        marginLeft: 10,
    },
    filterApplyButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 10,
    },
}));

export default function RangeCalendar(props) {
    const { startDate, setStartDate, endDate, setEndDate, inputStartDate, setInputStartDate, inputEndDate, setInputEndDate, children, onApplyClick } = props;
    const { t } = useTranslate();
    const [isValidStartDate, setValidStartDate] = useState(true);
    const [isValidEndDate, setValidEndDate] = useState(true);
    const [isFirstPick, setFirstPick] = useState(true);
    const [isFirstLook, setFirstLook] = useState(true);
    const langCode = sessionService.getLangCode();
    const classes = useStyles({ isFirstPick } as any);
    const { getDate, getDateObject, isValidDate, isAfterDate, getDateMask, getMomentObjectFromDateString } = dateTimeUtils;
    const dateMask = getDateMask();

    useCalendarData();

    useEffect(() => {
        setInputStartDate(getDate(startDate));
    }, [startDate, setInputStartDate, getDate]);

    function handleChangeDate(date) {
        if (isFirstPick) {
            setStartDate(getDateObject(getDate(date)));
            setInputStartDate(getDate(date));
            setFirstPick(!isFirstPick);
        } else {
            setEndDate(getDateObject(getDate(date)));
            setInputEndDate(getDate(date));
            setFirstPick(!isFirstPick);
        }
        setFirstLook(false);
    }

    function handleChangeStartInput(event) {
        const value = event.target.value;
        setInputStartDate(event.target.value);
        const momentDate = getMomentObjectFromDateString(event.target.value);

        if (isValidDate(value) && isAfterDate(endDate, momentDate)) {
            if (!isValidEndDate) {
                setValidEndDate(true);
                setEndDate(getDateObject(inputEndDate));
            }
            setStartDate(getDateObject(momentDate));
            setValidStartDate(true);
        } else {
            setValidStartDate(false);
        }
    }

    function handleChangeEndInput(event) {
        const value = event.target.value;
        setInputEndDate(event.target.value);
        const momentDate = getMomentObjectFromDateString(event.target.value);

        if (isValidDate(value) && isAfterDate(momentDate, startDate)) {
            if (!isValidStartDate) {
                setValidStartDate(true);
                setStartDate(getDateObject(inputStartDate));
            }
            setEndDate(getDateObject(momentDate));
            setValidEndDate(true);
        } else {
            setValidEndDate(false);
        }
    }

    function openDates() {
        return isFirstPick ? getInputDate(endDate) : getInputDate(startDate);
    }

    function getInputDate(date) {
        return date && isValidDate(date) && date.toDate();
    }

    return (
        <>
            <div className={classes.calendarMainContainer}>
                <div className={classes.calendarLeftContainer}>
                    <DatePicker
                        dateFormat="YYYY-MM-DD"
                        calendarClassName={classes.calendar}
                        selected={isFirstPick ? getInputDate(startDate) : getInputDate(endDate)}
                        startDate={getInputDate(startDate)}
                        endDate={getInputDate(endDate)}
                        minDate={!isFirstPick && getInputDate(startDate)}
                        maxDate={isFirstPick && getInputDate(endDate)}
                        openToDate={openDates()}
                        showPreviousMonths={isFirstLook}
                        onChange={date => handleChangeDate(date)}
                        monthsShown={2}
                        locale={langCode}
                        inline
                    />
                </div>
                {
                    (!onApplyClick && inputStartDate && inputEndDate)
                    && (
                        <div className={classes.calendarRightContainer}>
                            <div className={classes.selectContainer}>
                                {children}
                            </div>
                            <div className={classes.inputContainer}>
                                <Input
                                    className={classes.startInput}
                                    value={inputStartDate}
                                    type="date"
                                    mask={dateMask}
                                    onChange={handleChangeStartInput}
                                    errorMessage={t('calendar.validDate', 'This date is not valid')}
                                    invalid={!isValidStartDate}
                                />
                                <Input
                                    className={classes.endInput}
                                    value={inputEndDate}
                                    type="date"
                                    mask={dateMask}
                                    onChange={handleChangeEndInput}
                                    errorMessage={t('calendar.validDate', 'This date is not valid')}
                                    invalid={!isValidEndDate}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
            {

                onApplyClick
                && (
                    <div className={classes.filterApplyButton}>
                        <Button type="primary" onClick={onApplyClick}>
                            {t('basePage.applyFilter', 'Apply filter')}
                        </Button>
                    </div>
                )
            }
        </>
    );
}
