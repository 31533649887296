import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import useCurrency from '@utils/currency/useCurrency';
import SegmentToogle from '@baseComponents/controls/SegmentToogle';
import clsx from 'clsx';

const useStyles = createUseStyles({
    currencySwitchWrapper: {
        display: 'inline-block',
        height: 36,
        verticalAlign: 'top',
        marginLeft: 10,
    },
});

export default function CurrencySwitch(props) {
    const { className, manualEnabled = false } = props;
    const { t } = useTranslation();
    const primaryCurrency = useSelector((state: any) => state.company.company.currency.primaryCurrency);
    const secondaryCurrency = useSelector((state: any) => state.company.company.currency.secondaryCurrency);
    const currentCurrency = useSelector((state: any) => state.company.company.currency.currentCurrency);
    const classes = useStyles();

    const { isVisibleSwitch, currencyOnChange, isCheckedSwitch } = useCurrency({ manualEnabled });

    function getToggleItems() {
        if (!primaryCurrency || !secondaryCurrency) {
            return null;
        }

        return [
            { key: primaryCurrency.id, value: primaryCurrency.text },
            { key: secondaryCurrency.id, value: secondaryCurrency.text },
        ];
    }

    if (!isVisibleSwitch) {
        return null;
    }

    return (
        <div className={clsx(classes.currencySwitchWrapper, className)} id="currencySwitch" title={t('currencySwitch.change', 'Currency conversion')} is_checked={isCheckedSwitch.toString()}> {/*TODO: php - is_checked - php use this*/}
            <SegmentToogle items={getToggleItems()} selected={currentCurrency.id} onChange={currencyOnChange} />
        </div>
    );
}
