import React, { useState, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { TABLE } from 'shared/src/constants';

import SelectAllCell from '@baseComponents/tableNew/cellTypes/selectAllCell/SelectAllCell';
import ColumnName from './ColumnName';
import ColumnNameButton from './ColumnNameButton';
import TableHeaderColumnDropDown from './dropdown/TableHeaderColumnDropDown';
import TableHeaderSorter from './sorter/TableHeaderSorter';
import TableHeaderSearchInput from './searcher/TableHeaderSearchInput';
import TableHeaderEmptyCell from './TableHeaderEmptyCell';

const useStyles = createUseStyles((theme: any) => ({
    headerCell: {
        cursor: props => (props.isSortable ? 'pointer' : 'default'),
        width: props => props.width || 'auto',
        minWidth: props => props.minWidth,
        maxWidth: props => props.maxWidth,
        height: 'auto',
        padding: 0,
        paddingLeft: 10,
        verticalAlign: 'top',
        textAlign: 'left',
        overflow: 'hidden',
        ...theme.disableOutline,
    },
    columnNameContainer: {
        display: 'block',
        width: '100%',
        minWidth: 40,
    },
    columnNameWithSorter: {
        width: '100%',
        minWidth: '100%',
        lineHeight: '40px',
        height: 40,
        display: 'flex',
        alignItems: 'center',
    },
    sorterWrapper: {
        marginLeft: 5,
        display: 'inline-block',
    },
    popoverArrow: {
        '&.popover .arrow': {
            position: 'absolute',
            display: 'block',
            width: '1rem',
            height: '0.5rem',
            margin: '0 0.3rem',
        },
        '&.popover .arrow::before, .popover .arrow::after': {
            position: 'absolute',
            display: 'block',
            content: '""',
            borderColor: 'transparent',
            borderStyle: 'solid',
        },
        '&.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"]': {
            marginTop: '0.5rem',
        },
        '&.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow': {
            top: 'calc(-0.5rem - 12px)',
        },
        '&.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before': {
            top: 0,
            borderWidth: '0 0.5rem 0.5rem 0.5rem',
            borderBottomColor: 'rgba(0, 0, 0, 0.25)',
        },
        '&.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after': {
            top: 1,
            borderWidth: '0 0.5rem 0.5rem 0.5rem',
            borderBottomColor: theme.color.white,
        },
        '&.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before': {
            position: 'absolute',
            top: 0,
            left: '50%',
            display: 'block',
            width: '1rem',
            marginLeft: '-0.5rem',
            content: '""',
            borderBottom: '1px solid #f7f7f7',
        },
    },
}));

export default function TableHeaderCell(props) {
    const { column, isMiddleColumnCell, useColumnSorter, useColumnSearcher, listRowRef } = props;
    const { columnName, isSelectRowColumn, isSortable, minWidth, maxWidth, width, ignoreSearching, isColumnSwitchButton, onClick, dataType } = column; //column data
    const { isBasePageTable, tableKey } = props; //simple table
    const [showSortingIcon, setShowSortingIcon] = useState(false);
    const [isVisibleDropdown, setIsVisibleDropdown] = useState(false);
    const headerCellRef = useRef(null);

    const classes = useStyles({ isSortable, width, minWidth, maxWidth, showSortingIcon, isRightAlign: isRightAlign() });

    function onClickCell(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    function hasDropdownMenu() {
        return !ignoreSearching && isSortable && !isColumnSwitchButton && useColumnSorter && useColumnSearcher;
    }

    /*if (!columnName && isSelectRowColumn) {
        return (<SelectAllCell tableKey={tableKey} isBasePageTable={isBasePageTable} />);
    }*/

    if (!columnName || isSelectRowColumn) {
        return (<TableHeaderEmptyCell column={column} isBasePageTable={isBasePageTable} isMiddleColumnCell={isMiddleColumnCell} />);
    }

    function hideDropdown() {
        setIsVisibleDropdown(false);
    }

    function onToggle() {
        setIsVisibleDropdown(currentState => !currentState);
    }

    function isRightAlign() {
        return dataType === TABLE.DATA_TYPE.AREA
            || dataType === TABLE.DATA_TYPE.WEIGHT
            || dataType === TABLE.DATA_TYPE.COST
            || dataType === TABLE.DATA_TYPE.COST_PER_AREA
            || dataType === TABLE.DATA_TYPE.WEIGHT_PER_AREA
            || dataType === TABLE.DATA_TYPE.COST_PER_WEIGHT
            || dataType === TABLE.DATA_TYPE.QUANTITY
            || dataType === TABLE.DATA_TYPE.QUANTITY_PER_AREA
            || dataType === TABLE.DATA_TYPE.PERCENTAGE
            || dataType === TABLE.DATA_TYPE.WORKOPERATION_PERFORMANCE
            || dataType === TABLE.DATA_TYPE.WORKING_HOURS_PERFORMANCE;
    }

    return (
        <>
            <OverlayTrigger
                show={isVisibleDropdown}
                trigger="click"
                placement="bottom"
                overlay={hasDropdownMenu
                    ? (
                        <Popover className={classes.popoverArrow}>
                            <Popover.Content>
                                <TableHeaderColumnDropDown
                                    setShowColumnDropDown={hideDropdown}
                                    column={column}
                                    tableKey={tableKey}
                                    isBasePageTable={isBasePageTable}
                                />
                            </Popover.Content>
                        </Popover>
                    ) : null}
                container={listRowRef.current}
                rootCloseEvent="mousedown"
                rootClose
                onToggle={onToggle}
            >
                <div
                    className={clsx(classes.headerCell, (isMiddleColumnCell && 'headerCell'))}
                    onMouseEnter={() => setShowSortingIcon(true)}
                    onMouseLeave={() => setShowSortingIcon(false)}
                    ref={headerCellRef}
                    onClick={onClickCell}
                    aria-hidden="true"
                    role="button"
                    tabIndex="0"
                >
                    <div className={classes.columnNameContainer}>
                        <div className={classes.columnNameWithSorter}>

                            {isColumnSwitchButton && onClick
                                ? <ColumnNameButton text={columnName} onClick={onClick} isSortable={isSortable} />
                                : <ColumnName text={columnName} isSortable={isSortable} isRightAlign={isRightAlign()} />
                            }

                            {useColumnSorter
                                && (
                                <div className={classes.sorterWrapper}>
                                    <TableHeaderSorter
                                        column={column}
                                        showSortingIcon={showSortingIcon}
                                        tableKey={tableKey}
                                        isBasePageTable={isBasePageTable}
                                    />
                                </div>
                                )
                            }
                        </div>
                        {useColumnSearcher
                            && (
                                <TableHeaderSearchInput
                                    column={column}
                                    tableKey={tableKey}
                                    isBasePageTable={isBasePageTable}
                                />
                            )
                        }
                    </div>
                </div>
            </OverlayTrigger>
        </>
    );
}
