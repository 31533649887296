import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import { isEmpty } from 'lodash';
import { languageUtils } from 'shared/src/modules';

const useStyles = createUseStyles((theme: any) => ({
    modalLeft: {
        width: 380,
        minWidth: 380,
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        maxHeight: '100%',
    },
    modalTitle: {
        fontFamily: 'Rubik',
        width: '100%',
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 25,
        paddingBottom: 20,
        fontWeight: 'bold',
        minHeight: 80,
        maxHeight: 80,
        height: 80,
        display: 'block',
        boxShadow: theme.shadows.header,
        borderBottom: `1px solid ${theme.color.lilian}`,
        lineHeight: 'normal !important',
        position: 'relative',
        ...theme.overFlowText,
    },
}));

export default function ModalLeftPanel(props) {
    const { title, children, className, titleClassName } = props;
    const { translater } = useTranslate();
    const classes = useStyles();

    return (
        <div className={clsx(classes.modalLeft, className)}>
            {!isEmpty(title) && (
                <h1 className={clsx(classes.modalTitle, titleClassName)} title={languageUtils.getTitle(translater, title)}>
                    {title}
                </h1>
            )}
            {children}
        </div>
    );
}
