import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '@i18n/useTranslate';
import { basePageActions } from '@states/actions/basePageActions';
import { simpleTableActions } from '@states/actions/simpleTableActions';

import Svg from '@baseComponents/Svg';

export default function DropDownSearcher(props) {
    const { isBasePageTable, setShow, tableKey, className } = props;
    const isEnabledSearching = useSelector((state: any) => state.basePage.table.isEnabledSearching);
    const isEnabledSearchingSimpleTable = useSelector((state: any) => state.simpleTable.table[tableKey]?.isEnabledSearching);
    const { t } = useTranslate();
    const dispatch = useDispatch();

    function changeSearchingVisibilityState(event) {
        event.stopPropagation();

        if (isBasePageTable) {
            dispatch(basePageActions.setTableEnabledSearching());
        } else if (tableKey) {
            dispatch(simpleTableActions.setTableEnabledSearching(tableKey));
        }

        if (setShow) {
            setShow(false);
        }
    }

    function isEnabledTableSearching() {
        if (isBasePageTable) {
            return isEnabledSearching;
        }
        return isEnabledSearchingSimpleTable;
    }

    return (
        <div className={className.itemButton} role="button" tabIndex="0" onClick={changeSearchingVisibilityState} onKeyDown={changeSearchingVisibilityState}>
            <Svg iconId="icon-search" style={className.itemButtonBeforIcon} />
            <div className={className.itemButtonText}>
                {isEnabledTableSearching() ? t('tableDropDown.cancelSearch', 'Cancel search') : t('tableDropDown.searchInColumn', 'Search in the column')}
            </div>
        </div>
    );
}
