import { MODULES, getApiPath } from 'shared/src/routing';

const periodYearEndpoints = [
    getApiPath(MODULES.OFFICIAL_REPORTS, MODULES.OFFICIAL_REPORTS.CONTENTS.LPIS_FIELD_EDIT),
    getApiPath(MODULES.TEST, MODULES.TEST.CONTENTS.TEST_EDIT),
    getApiPath(MODULES.CULTIVATION_PERIODS, MODULES.CULTIVATION_PERIODS.CONTENTS.CULTIVATION_PERIOD_EDIT),
    getApiPath(MODULES.CULTIVATION_PERIODS, MODULES.CULTIVATION_PERIODS.CONTENTS.DISASSEMBLY),
    getApiPath(MODULES.CULTIVATION_PERIODS, MODULES.CULTIVATION_PERIODS.CONTENTS.CONTRACTION),
];

const cultureEndpoints = [
    getApiPath(MODULES.GENERAL, MODULES.GENERAL.CONTENTS.CULTURES),
];

const cultureVarietyEndpoints = [
    getApiPath(MODULES.GENERAL, MODULES.GENERAL.CONTENTS.VARIETIES),
    getApiPath(MODULES.CULTIVATION_PERIODS, MODULES.CULTIVATION_PERIODS.CONTENTS.CULTIVATION_PERIOD_EDIT),
    getApiPath(MODULES.TEST, MODULES.TEST.CONTENTS.TEST_EDIT),
    getApiPath(MODULES.OFFICIAL_REPORTS, MODULES.OFFICIAL_REPORTS.CONTENTS.LPIS_FIELD_EDIT),
    getApiPath(MODULES.PLANNING, MODULES.PLANNING.CONTENTS.CULTIVATON_PERIOD_EDIT),
];

const farmEndpoints = [
    getApiPath(MODULES.FARM, MODULES.FARM.CONTENTS.FARM),
];

const userRightsEndpoints = [
    getApiPath(MODULES.USER, MODULES.USER.CONTENTS.GROUP_RIGHTS),
];

const companySettingsEndpoints = [
    getApiPath(MODULES.SETTINGS, MODULES.SETTINGS.CONTENTS.FIELD_RECORDS),
];

const userDatasEndpoints = [
    getApiPath(MODULES.USER, MODULES.USER.CONTENTS.USERS),
];

const warehousesEndpoints = [
    getApiPath(MODULES.USER, MODULES.USER.CONTENTS.USERS),
    getApiPath(MODULES.WAREHOUSE, MODULES.WAREHOUSE.CONTENTS.WAREHOUSES),
    getApiPath(MODULES.EQUIPMENT, MODULES.EQUIPMENT.CONTENTS.MACHINE_EDIT),
];

const reproductionRatesEndpoints = [
    getApiPath(MODULES.CULTIVATION_PERIODS, MODULES.CULTIVATION_PERIODS.CONTENTS.CULTIVATION_PERIOD_EDIT),
];

const economicUnitsEndpoints = [
    getApiPath(MODULES.ECONOMIC_UNIT, MODULES.ECONOMIC_UNIT.CONTENTS.ECONOMIC_UNIT_EDIT),
];

const machinesEndpoints = [
    getApiPath(MODULES.EQUIPMENT, MODULES.EQUIPMENT.CONTENTS.MACHINE_EDIT),
];

const employeesEndpoints = [
    getApiPath(MODULES.LABOR, MODULES.LABOR.CONTENTS.LABOR_EDIT),
    getApiPath(MODULES.LABOR, MODULES.LABOR.CONTENTS.SIMPLIFIED_EMPLOYEMENT_EDIT),
];

const numberFormatSettingsEndpoints = [
    getApiPath(MODULES.SETTINGS, MODULES.SETTINGS.CONTENTS.ADMIN_SETTINGS),
];

const workoperationQualificationsEndpoints = [
    getApiPath(MODULES.SETTINGS, MODULES.SETTINGS.CONTENTS.WORKOPERATION_QUALIFICATION),
];

export const phpDataListReloadEndpoints = {
    periodYearEndpoints,
    cultureEndpoints,
    cultureVarietyEndpoints,
    farmEndpoints,

    userRightsEndpoints,
    companySettingsEndpoints,
    userDatasEndpoints,
    warehousesEndpoints,
    reproductionRatesEndpoints,
    economicUnitsEndpoints,
    machinesEndpoints,
    employeesEndpoints,

    numberFormatSettingsEndpoints,

    workoperationQualificationsEndpoints,
};
