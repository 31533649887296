import { useEffect } from 'react';
import { setDefaultLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

export default function useCalendarData() {
    const { i18n } = useTranslation();

    useEffect(() => {
        if (i18n.language) {
            setDefaultLocale(i18n.language);
        }
    }, [i18n.language]);
}
