/* eslint-disable react-hooks/exhaustive-deps */
import { debounce, isEmpty, isFunction, isNil } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SKInfoPanelTabData } from '@map/components/SlovakReportsOverlay/slovakReportsUtils';
import { useSlovakReportContext } from '@map/services/slovakianReports/slovakReportContext';
import { getExternalLayers } from './getExternalLayers/getExternalLayers';
import getLpisLayer, { updateLpisLayer } from './getLpisLayer/getLpisLayer';
import getPublishedPolygons from './getPublishedPolygons/getPublishedPolygons';
import { SK_PUBLISHED_POLYGONS_LAYER } from '../slovakReportsLayers';
import { InfoPanelTab } from '../SlovakInfoPanel/SlovakInfoPanel';

function removeEventListeners(listeners: google.maps.MapsEventListener[]) {
    listeners?.forEach(listener => {
        google.maps.event.removeListener(listener);
    });
}

export default function useSlovakWFS(map: google.maps.Map | null, activeLayers: number[]) {
    const [store, controller] = useSlovakReportContext();
    const clickListener = useRef<google.maps.MapsEventListener[]>([]);
    const boundsEventListener = useRef<google.maps.MapsEventListener>();
    const markers = useRef<google.maps.Marker[]>([]);

    const [rendered, setRendered] = useState(0);
    const [isInitLpisSelected, setIsInitLpisSelected] = useState(false);

    const setInfoPanelState = useCallback(
        (isOpen: boolean, posX: number, posY: number, tabType: InfoPanelTab, tabData?: SKInfoPanelTabData[], onClose?: () => void) => {
            controller.setInfoPanel(isOpen, posX, posY, tabType, tabData, onClose);
        },
        [controller],
    );

    const cleanMarkers = useCallback(() => {
        markers.current.forEach(marker => {
            marker.setMap(null);
        });
    }, []);

    function removeListeners() {
        if (clickListener.current) {
            removeEventListeners(clickListener.current);
        }

        if (boundsEventListener.current) {
            removeEventListeners([boundsEventListener.current]);
        }
    }

    function cleanDataLayers() {
        store?.lpisDataLayer?.forEach(feature => {
            if (store.lpisDataLayer) {
                store.lpisDataLayer.remove(feature);
            }
        });

        store?.dataLayer?.forEach(feature => {
            store?.dataLayer?.remove(feature);
        });

        store?.publishedPolygonsDataLayer?.forEach(feature => {
            store?.publishedPolygonsDataLayer?.remove(feature);
        });
    }

    useEffect(() => {
        if (store.isDrawMode && store.selectedLpisPolygon) {
            markers.current.forEach((marker: any) => {
                if (marker.title !== store.selectedLpisPolygon?.getProperty('ZKODKD')) {
                    marker.setMap(null);
                }
            });
        }
    }, [store.isDrawMode, store.selectedLpisPolygon, markers.current]);

    useEffect(() => {
        if (store.lpisDataLayer && map) {
            updateLpisLayer({
                setDrawMode: controller.setDrawMode,
                gsaaYear: store.gsaaYear,
                gsaaKODKD: !isInitLpisSelected ? store.gsaaKODKD : null,
                map,
                dataLayer: store.lpisDataLayer,
                markers,
                selectedLpisPolygon: store.selectedLpisPolygon,
                infoPanelCallback: setInfoPanelState,
                setSelectedLpisPolygon: controller.setSelectedLpisPolygon,
            });
        }
    }, [store.lpisDataLayer, store.selectedLpisPolygon, map, setInfoPanelState, controller.setSelectedLpisPolygon, rendered, isInitLpisSelected, store.gsaaKODKD, store.gsaaYear]);

    useEffect(() => {
        const { selectedLpisPolygon, lpisDataLayer, dataLayer, publishedPolygonsDataLayer, gsaaYear } = store;
        const { setSelectedLpisPolygon } = controller;

        async function update() {
            if (!map) {
                return;
            }
            if ((map.getZoom() ?? 0) < 14) {
                cleanDataLayers();
                cleanMarkers();

                controller.setSelectedLpisPolygon(null);
                controller.setInfoPanel(false, 0, 0, InfoPanelTab.NONE);

                return;
            }

            cleanDataLayers();

            if (!isEmpty(clickListener.current)) {
                removeEventListeners(clickListener.current);
            }

            if (dataLayer) {
                await getExternalLayers({ gsaaYear, map, markers, dataLayer, activeLayers });
            }

            if (lpisDataLayer) {
                await getLpisLayer({
                    gsaaYear,
                    map,
                    dataLayer: lpisDataLayer,
                    selectedLpisPolygon,
                    infoPanelCallback: setInfoPanelState,
                    markers,
                    setSelectedLpisPolygon,
                });
                setRendered(Date.now());
            }

            const isPublishedPolygonsLayerActive = activeLayers.includes(SK_PUBLISHED_POLYGONS_LAYER.id);
            if (publishedPolygonsDataLayer && isPublishedPolygonsLayerActive && lpisDataLayer) {
                const publishedPolygonEventListener = await getPublishedPolygons({
                    gsaaYear,
                    map,
                    lpisDataLayer,
                    dataLayer: publishedPolygonsDataLayer,
                    infoPanelCallback: setInfoPanelState,
                    markers,
                });

                if (!isNil(publishedPolygonEventListener)) {
                    clickListener.current.push(publishedPolygonEventListener);
                }
            }
        }

        if (map && dataLayer && !store.isDrawMode) {
            removeListeners();

            update();

            const debounceListener = map.addListener(
                'bounds_changed',
                debounce(async () => {
                    await update();
                }, 300),
            );

            boundsEventListener.current = debounceListener;
        }

        return () => {
            removeListeners();
        };
    }, [map, activeLayers, setInfoPanelState, setRendered, store.dataLayer, store.publishedPolygonsDataLayer, store.lpisDataLayer, store.isDrawMode]);
}
