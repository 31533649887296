import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import Svg from '@baseComponents/Svg';
import TimeCalendar from '@baseComponents/datepickers/simpleCalendar/TimeCalendar';
import Label from '@baseComponents/formParts/Label';
import { dateTimeUtils } from 'shared/src/modules';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        position: 'relative',
        display: 'inline-block',
        ...theme.formatBootstrapButton.secondary,
    },
    dateBtn: {
        width: '100%',
        height: 40,
        borderRadius: 4,
        boxShadow: theme.shadows.secondary.input,
        border: 'solid 1px',
        borderColor: theme.color.gray,
        backgroundColor: theme.color.white,
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 15px',
        fontFamily: 'Rubik',
        fontSize: 15,
        fontWeight: 300,
        color: theme.color.jet,
        '&:disabled': {
            color: theme.color.jet,
        },
    },
    borderInvalid: {
        borderColor: theme.color.destructive,
    },
    icon: {
        width: 24,
        height: 24,
        marginLeft: 'auto',
        fill: theme.color.stone,
    },
    popover: {
        zIndex: theme.zIndex.popover,
        boxShadow: theme.shadows.dropdown,
        backgroundColor: theme.color.white,
        borderRadius: 6,
        border: 'none',
        padding: 20,
        '& .arrow::before': {
            borderRightColor: theme.color.white,
            borderWidth: 8,
            top: -10,
            left: -20,
        },
        '& .popover-body': {
            padding: 0,
        },
        '& input': {
            fontSize: 14,
        },
    },
}));

export default function FormTimePicker(props) {
    const { time, setTime, className, label, required, invalid } = props;
    const [isOnCalendar, setOnCalendar] = useState(false);
    const classes = useStyles();

    function closeCalendar() {
        document.body.click();
    }

    return (
        <div className={clsx(classes.container, className)}>
            {label && <Label label={label} required={required} />}
            <OverlayTrigger
                trigger="click"
                rootClose
                onToggle={() => setOnCalendar(!isOnCalendar)}
                placement="right"
                overlay={
                    <Popover className={classes.popover}>
                        <Popover.Content>
                            <TimeCalendar
                                setDefaultTime={setTime}
                                defaultTime={time || dateTimeUtils.getTimeNow()}
                                close={() => closeCalendar()}
                            />
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button type="button" className={clsx(classes.dateBtn, invalid && classes.borderInvalid)}>
                    {time}
                    <Svg style={classes.icon} iconId="icon-clock" />
                </Button>
            </OverlayTrigger>
        </div>
    );
}
