import React from 'react';
import { isEmpty } from 'lodash';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { UNITS } from 'shared/src/constants';
import { useNumberFormat } from '@common_modules';

const useStyles = createUseStyles((theme: any) => ({
    ancestorChildCardWrapper: {
        borderRadius: 4,
        border: `1px solid ${theme.color.pearl}`,
        padding: 20,
        marginBottom: 15,
    },
    ancestorChildCardTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
        marginBottom: 5,
    },
    ancestorChild: {
        whiteSpace: 'pre-line',
        marginBottom: 5,
        fontSize: 13,
    },
}));

export default function PeriodViewAncestorChildCard(props) {
    const { cardData, isChild } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const { numberFormatter } = useNumberFormat();

    function getDataItem(dataItem) {
        let content = '';

        content += `${dataItem.name}`;
        content += '\n';
        content += `(${dataItem.productionYear}`;

        if (!isEmpty(dataItem?.cultureName)) {
            content += ` - ${dataItem.cultureName}`;
        }

        content += `, ${numberFormatter.getFormattedArea(dataItem.area)} ${t('default.areUnit', UNITS.DEFAULT_AREA)})`;

        return content;
    }

    return (
        <div className={classes.ancestorChildCardWrapper}>
            <div className={classes.ancestorChildCardTitle}>
                {!isChild ? t('cultivationPeriodView.ancestorCultivationPeriod', 'Ancestor cultivation periods') : t('cultivationPeriodView.childCultivationPeriod', 'Child cultivation periods')}
            </div>
            <div>
                {cardData.map((dataItem, index) => (
                    <div key={index} className={classes.ancestorChild}>
                        {getDataItem(dataItem)}
                    </div>
                ))}
            </div>
        </div>
    );
}
