import { createUseStyles } from 'react-jss';
import { Dropdown } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

import DropDownItem from './DropDownItem';
import AddNewButton from './AddNewButton';
import SearchDropDown from './SearchDropDown';

const useStyles = createUseStyles((theme: any) => ({
    selectDropdown: {
        padding: 0,
        width: '100%',
        zIndex: theme.zIndex.selectDropdown,
        background: theme.color.white,
        borderRadius: 4,
        border: '1px solid rgba(227, 230, 232, 0.5)',
        boxShadow: theme.shadows.selectDropdown,
    },
    dropdownList: {
        height: (props: any) => {
            if (props.dataList) {
                return props.dataList.length * 40 > 250 ? 250 : props.dataList.length * 40;
            }
            return 40;
        },
    },
    scrollBar: {
        ...theme.verticalScrollbar,
    },
}));

export default function ListDropDown(props) {
    const { dataList, onClick, search, addNew, valueId, secondValueId, isOpened } = props;
    const classes = useStyles({ dataList } as any);

    function onClickItem(dataItem) {
        if (onClick) {
            onClick(dataItem);
        }
    }

    return (
        <Dropdown.Menu className={classes.selectDropdown}>
            {search
                && <SearchDropDown search={search} isOpened={isOpened} />}
            {!isEmpty(dataList)
                && (
                    <div className={classes.dropdownList}>
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    height={height}
                                    width={width}
                                    itemCount={dataList.length}
                                    itemSize={40}
                                    className={classes.scrollBar}
                                >
                                    {({ index, style }) => (
                                        <div key={index} style={style}>
                                            <DropDownItem key={index} dataValue={dataList[index][valueId]} secondValue={dataList[index][secondValueId]} onClick={() => onClickItem(dataList[index])} />
                                        </div>
                                    )}
                                </List>
                            )}
                        </AutoSizer>
                    </div>
                )}
            {addNew
                && <AddNewButton addNew={addNew} />}
        </Dropdown.Menu>
    );
}
