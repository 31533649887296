import React from 'react';
import { createUseStyles } from 'react-jss';

import { Input } from '@baseComponents/inputs/inputField';

const useStyles = createUseStyles({
    textInputCell: {
        width: '100%',
        height: 32,
        '& .input': {
            height: 32,
            maxHeight: 32,
            padding: 5,
            border: props => props.hasBorder && 'none',
        },
    },
});

export default function TextInputCell(props) {
    const { cellValue, updateValue, hasBorder, isDisabled } = props;
    const classes = useStyles({ hasBorder });

    function onBlur(event, value) {
        if (updateValue) {
            updateValue({ event, value });
        }
    }

    return (
        <div className={classes.textInputCell}>
            <Input defaultValue={cellValue} onBlur={onBlur} isDisabled={isDisabled} />
        </div>
    );
}
