import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SYSTEM } from 'shared/src/constants';
import { sessionService } from '@services';

const useStyles = createUseStyles((theme: any) => ({
    sideNavLogoSavingBank: {
        height: 40,
        width: 40,
        display: 'inline-block',
        background: 'transparent',
        verticalAlign: 'middle',
        cursor: 'pointer',
        float: 'left',
        marginLeft: 20,
        border: 'none !important',
    },
    sideNavLogoSavingBankIcon: {
        display: 'inline-block',
        width: 40,
        height: 40,
        marginTop: 13,
        verticalAlign: 'middle',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        border: 'none !important',
        background: 'transparent',
        backgroundImage: 'url("/styles/img/av_takarek_logo_slidebar.gif")',
    },
    sideNavLogo: {
        position: 'relative',
        height: 36,
        width: 212,
        display: 'inline-block',
        background: 'transparent !important',
        verticalAlign: 'middle',
        cursor: 'pointer',
        float: 'left',
        marginLeft: 14,
        marginTop: 25,
        border: 'none',
    },
    sideNavLogoSmallIcon: {
        display: 'inline-block',
        width: 26,
        height: 36,
        backgroundImage: props => {
            if (props.isTestSystem) {
                return 'url("/styles/img/av_logo_slidebar_left_test.png")';
            }
            return 'url("/styles/img/av_logo_slidebar_left.png")';
        },
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        background: 'transparent',
        border: 'none',
    },
    sideNavLogoAvIcon: {
        display: 'inline-block',
        width: 77,
        height: 36,
        marginLeft: -2,
        backgroundImage: (props: any) => {
            if (props.isTestSystem) {
                return 'url("/styles/img/av_logo_slidebar_right_test.png")';
            }
            return 'url("/styles/img/av_logo_slidebar_right.png")';
        },
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        background: 'transparent',
        border: 'none',
    },
    sideNavExpandedLogo: {
        display: 'inline-block',
        height: 36,
        width: 26,
        marginRight: 5,
        marginLeft: 18,
        marginTop: 25,
        marginBottom: 19,
        backgroundImage: (props: any) => {
            if (props.isTestSystem) {
                return 'url("/styles/img/av_logo_slidebar_left_test.png")';
            }
            return 'url("/styles/img/av_logo_slidebar_left.png")';
        },
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        background: 'transparent',
        border: 'none',
    },

    testSystemTextContainer: {
        position: 'absolute',
        bottom: 7,
        left: 88,
        color: theme.color.raven,
        fontSize: 10,
        textTransform: 'uppercase',
        cursor: 'default',
        overflow: 'hidden',
        maxWidth: 125,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },

    systemCountryCode: {
        position: 'absolute',
        top: 0,
        left: 161,
        color: theme.color.raven,
        fontSize: 10,
    },
}));

export default function SideBarLogo(props) {

    const { expanded, setShowUserMenu, isTestSystem } = props;
    const { t } = useTranslation();
    const countryCode = useSelector((state: any) => state.system.systemInfo.countryCode);
    const systemSubType = useSelector((state: any) => state.system.systemInfo.subType);
    const agentName = sessionService.getAgentName();
    const history = useHistory();
    const classes = useStyles({ isTestSystem } as any);

    function navToHome() {
        setShowUserMenu(false);
        history.push(`/${agentName}`);
    }

    if (!expanded) {
        return (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button type="button" className={classes.sideNavExpandedLogo} onClick={navToHome} />
        );
    }
    if (systemSubType === SYSTEM.SYSTEM_SUBTYPE.SAVINGS_BANK) {
        return (
            <button type="button" className={classes.sideNavLogoSavingBank} onClick={navToHome}>
                <i alt="" src="" className={classes.sideNavLogoSavingBankIcon} />
            </button>
        );
    }

    return (
        <>
        <button type="button" className={classes.sideNavLogo} onClick={navToHome}>
            <i className={classes.sideNavLogoSmallIcon} />
            <i className={classes.sideNavLogoAvIcon} />
            <span className={classes.systemCountryCode}>{countryCode}</span>
        </button>
        {isTestSystem && (
            <div className={classes.testSystemTextContainer}>{t('login.testSystem', 'Test system') as any}</div>
        )}
        </>
    );
}
