/* eslint-disable no-magic-numbers */
/* eslint-disable id-blacklist */
import { isUndefined, isEmpty } from 'lodash';

import { AKI } from '../contents/aki';
import { ANALYSIS } from '../contents/analysis';
import { COMPANY } from '../contents/company';
import { CONTROLLING } from '../contents/controlling';
import { CROP_MANAGEMENT } from '../contents/cropManagement';
import { CULTIVATION_PERIODS } from '../contents/cultivationPeriods';
import { DATA_ANALYSIS } from '../contents/dataAnalysis';
import { ECONOMIC_UNIT } from '../contents/economicUnit';
import { EMPLOYMENT } from '../contents/employment';
import { EQUIPMENT } from '../contents/equipment';
import { FARM } from '../contents/farm';
import { FINANCIAL_FLOWS } from '../contents/financialFlows';
import { GENERAL } from '../contents/general';
import { HANSEAGRO } from '../contents/hanseagro';
import { INVOICE } from '../contents/invoice';
import { LABOR } from '../contents/labor';
import { LAND_ADMINISTRATION } from '../contents/landAdministration';
import { LIVESTOCK_RECORDS } from '../contents/livestockRecords';
import { MAP } from '../contents/map';
import { OFFICIAL_REPORTS } from '../contents/officialReports';
import { PARTNER } from '../contents/partner';
import { PLANNING } from '../contents/planning';
import { PRODUCT } from '../contents/product';
import { PRODUCTION } from '../contents/production';
import { REPORTS } from '../contents/reports';
import { SCALE } from '../contents/scale';
import { SETTINGS } from '../contents/settings';
import { STOCK } from '../contents/stock';
import { SUPPORT } from '../contents/support';
import { TASK } from '../contents/task';
import { TEST } from '../contents/test';
import { USER } from '../contents/user';
import { WAREHOUSE } from '../contents/warehouse';
import { WIDGET } from '../contents/widget';
import { WINERY } from '../contents/winery';
import { LIVESTOCK_DAILY_TASKS } from '../contents/livestockDailyTasks';
import { LIVESTOCK_FEEDING } from '../contents/livestockFeeding';
import { METEOROLOGY } from '../contents/meteorology';
//import { TEKA } from '../contents/teka';

export const MODULES = {
    AKI,
    ANALYSIS,
    COMPANY,
    CONTROLLING,
    CROP_MANAGEMENT,
    CULTIVATION_PERIODS,
    DATA_ANALYSIS,
    ECONOMIC_UNIT,
    EMPLOYMENT,
    EQUIPMENT,
    FARM,
    FINANCIAL_FLOWS,
    GENERAL,
    HANSEAGRO,
    INVOICE,
    LABOR,
    LAND_ADMINISTRATION,
    LIVESTOCK_RECORDS,
    MAP,
    OFFICIAL_REPORTS,
    PARTNER,
    PLANNING,
    PRODUCT,
    PRODUCTION,
    REPORTS,
    SCALE,
    SETTINGS,
    STOCK,
    SUPPORT,
    TASK,
    TEST,
    USER,
    WAREHOUSE,
    WIDGET,
    WINERY,
    LIVESTOCK_DAILY_TASKS,
    LIVESTOCK_FEEDING,
    METEOROLOGY,
    //TEKA,
};

// New Client side
export function getApiPath(module, contentConst) {
    const content = contentConst.CONTENT_URL_ID;
    if (isUndefined(content)) {
        return '';
    }
    const moduleName = module.NAME;
    const contentName = Object.keys(content)[0].toString();

    /*let urlParams = '';
    if (contentConst.URL_PARAMS) {
        urlParams = `${contentConst.URL_PARAMS}`
    }*/

    return `${moduleName}/${contentName}`;
    //return `${moduleName}/${contentName}${urlParams}`;
}

export function getContentName(moduleContent) {
    const content = moduleContent.CONTENT_URL_ID;
    if (isUndefined(content)) {
        return '';
    }
    return Object.keys(content)[0];
}

// PHP side
export function getAPIEndpoint(currentUrl, agentName, isPlanningMode) {
    try {
        if (isEmpty(currentUrl)) {
            return null;
        }

        //TODO: php - new tab - convert from php url
        let url = currentUrl;
        if (currentUrl.charAt(0) === '#') {
            url = currentUrl.substr(1);
        }

        const urlRedirectParts = url.split('?');
        const urlRedirectModuleParts = urlRedirectParts[0].split('/');
        let params = urlRedirectParts.length > 1 ? `?${urlRedirectParts[1]}` : '';
        params += urlRedirectParts.length > 2 ? `?${urlRedirectParts[2]}` : '';
        const moduleId = parseInt(urlRedirectModuleParts[0], 10);
        const contentId = parseInt(urlRedirectModuleParts[1], 10);

        const moduleObj = Object.values(MODULES).find(module => module.MODULE_ID === moduleId);
        const contentObj = Object.entries(moduleObj.CONTENTS).find(route => Object.entries(route[1]).find(routeObj => (routeObj[0] === 'CONTENT_URL_ID' && Object.values(routeObj[1]).includes(contentId))));
        const module = moduleObj.NAME;
        const content = Object.keys(contentObj[1].CONTENT_URL_ID)[0].toString();

        if (isPlanningMode) {
            const isSettingsContent = params && params.includes('setupcontent');
            const contentCanUsePlanning = contentObj[1].CAN_USE_PLANNING || isSettingsContent;
            let moduleName = MODULES.PLANNING.NAME;

            //get custom module
            if (contentCanUsePlanning) {
                moduleName = moduleObj.NAME;
            }

            //if (!contentObj[1].SHOW_IN_PANNING_MODE) { return `/${agentName}`; }

            return `/${agentName}/${moduleName}/${content}${params}`;
        }

        return `/${agentName}/${module}/${content}${params}`;
    } catch {
        return null;
    }
}

export function getPHPEndpoint(currentUrl, isPlanningMode) {
    try {
        if (isEmpty(currentUrl)) {
            return null;
        }

        const urlRedirectParts = currentUrl.split('?');
        const urlRedirectModuleParts = urlRedirectParts[0].split('/');

        let paramString = urlRedirectParts.length > 1 ? `${urlRedirectParts[1]}` : null;
        paramString += urlRedirectParts.length > 2 ? `?${urlRedirectParts[2]}` : '';

        const moduleName = urlRedirectModuleParts[2].toString();
        const contentName = urlRedirectModuleParts[3].toString();

        const moduleObj = Object.values(MODULES).find(module => module.NAME === moduleName);
        const contentObj = Object.entries(moduleObj.CONTENTS).find(content => !isUndefined(content[1].CONTENT_URL_ID[contentName]));

        let module = moduleObj.MODULE_ID;
        const content = contentObj[1].CONTENT_URL_ID[contentName];

        if (isPlanningMode && contentObj[1].MODULE_ID) {
            module = contentObj[1].MODULE_ID;
        }

        return { data: [module, content], parameters: getParametersObject(paramString) };
    } catch {
        return null;
    }
}

function getParametersObject(parameterString) {
    const parameters = new URLSearchParams(parameterString);
    const searchParams = {};

    parameters.forEach((value, key) => {
        searchParams[key] = value;
    });

    return searchParams;
}
