import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { isEmpty, isFunction } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import Svg from '@baseComponents/Svg';
import { Button } from '@baseComponents/buttons';

const useStyles = createUseStyles((theme: any) => ({
    div: {
        margin: '16px 8px',
        marginLeft: 20,

        '& > h3': {
            opacity: 0.7,
            marginTop: 8,
        },
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        '& > h2': {
            margin: '0 8px',
        },
    },
    iconDiv: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 6,
        background: theme.color.status_error,
        width: 32,
        height: 32,
        padding: 5,
    },
    icon: {
        fill: 'white',
    },
    refresh: {
        marginBottom: 10,
    },
    details: {
        paddingLeft: 10,
        fontSize: 12,
    },
}));

export default function DefaultErrorMessage(props) {
    const { error, errorInfo, shouldLogError, showDetails, refreshPage } = props;
    const history = useHistory();
    const { t } = useTranslate();
    const classes = useStyles();

    function onClickRefreshPage() {
        history.goBack();

        if (isFunction(refreshPage)) {
            refreshPage();
        }

        history.goForward();
    }

    return (
        <div className={classes.div}>
            <Button type="secondary" className={classes.refresh} iconId="icon-again" iconPosition="before" onClick={onClickRefreshPage}>
                {t('default.refreshPage', 'Refresh page')}
            </Button>
            <section className={classes.title}>
                <div className={classes.iconDiv}><Svg iconId="icon-flag_warn" style={classes.icon} /></div>
                <h2>{t('errorBoundary.defaultErrorMsg', 'Something went wrong!')}</h2>
            </section>

            <h3>{shouldLogError ? t('errorBoundary.defaultErrorLogged', 'The problem has been forwarded to our team.') : null}</h3>
            <details className={classes.details} style={{ whiteSpace: 'pre-wrap', display: showDetails ? 'default' : 'none' }}>
                {!isEmpty(error) && error.toString()}
                <br /><br />Stack:{errorInfo.componentStack}
            </details>
        </div>
    );
}
