import { isFunction, isEmpty, values } from 'lodash';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import { theme as themes } from '@styles/theme';

export default function getAllColumns(t, translater, showedColumns) {
    const columns = {
        addIcon: {
            type: columnName.iconButton,
            columnId: 'icon-add',
            componentProps: {
                icon: 'icon-add',
                getCellTitle: () => translater('default.select', 'Select'),
                getBackgroundColor: () => themes.color.main,
                getIconColor: () => themes.color.white,
                onClick: (rowData, onAddButtonClicked) => {
                    if (isFunction(onAddButtonClicked)) {
                        onAddButtonClicked(rowData);
                    }
                },
            },
            isLeftFixed: true,
            //isFirstColumn: true,
            width: 80,
            minWidth: 80,
            maxWidth: 80,
        },
        farmName: {
            type: columnName.simpleText,
            columnId: 'farmName',
            columnName: t('farmTableFilter.name', 'Name'),
            width: 300,
            marginLeft: 50,
            isLeftFixed: true,

        },
    };

    if (!isEmpty(showedColumns)) {
        const filteredColumns = [];
        const columnObj = Object.entries(columns);

        showedColumns.forEach(showedColName => {
            const column = columnObj.find(columnParts => columnParts[0].toString() === showedColName);
            if (column) {
                filteredColumns.push(column[1]);
            }
        });

        return filteredColumns;
    }

    return values(columns);
}
