import { useState, useEffect } from 'react';

export default function useCollapseTable(props) {
    const { tableData, rowData } = props;

    const [isCollapsedTable, setIsCollapsedTable] = useState(false);

    const table = tableData && tableData.find(tableObj => tableObj.tableId === rowData.tableName);
    const isCollapsed = table && table.isCollapsed;

    useEffect(() => {
        setIsCollapsedTable(isCollapsed);
    }, [isCollapsed]);

    return {
        isCollapsedTable,
        setIsCollapsedTable,
    };
}
