import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { isNil, isFunction } from 'lodash';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import useTranslate from '@i18n/useTranslate';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGroup from '@customComponents/form/FormGroup';
import Button from '@baseComponents/buttons/Button';
import Checkbox from '@baseComponents/controls/Checkbox';
import CultureDropdown from '@customComponents/dropdowns/cultureDropdown/CultureDropdown';
import CultureVarietyDropDown from '@customComponents/dropdowns/cultureDropdown/CultureVarietyDropDown';
import { ConfirmDialog } from '@baseComponents/modals';
import renderModal from '@modals/modalNew/modalFunctions';
import { ModalBody, ModalHeader, ModalContentWrapper, ModalFooter } from '@baseComponents/modals/modalNew';
import { cultivationPeriodsService } from '@services';
import { alertActions, tableFilterActions } from '@states/actions';

const useStyles = createUseStyles((theme: any) => ({
    modalWrapper: {
        width: 400,
        height: 'auto',
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        padding: [12, 30],
    },
    cultureDropDown: {
        maxHeight: 200,
    },
    varietyCheckBox: {
        '& > span': {
            marginLeft: 0,
        },
    },
    formGroup: {
        marginTop: 8,
        marginBottom: 8,
    },
    footerButton: {
        width: '100%',
    },
    modalFooter: {
        padding: [0, 30],
    },
}));

export default function CultureAndVarietyEditModal(props) {
    const { hideModal, seletedPeriodIdList, hasCulture, cultureId, onSuccess } = props;
    const classes = useStyles();
    const { t } = useTranslate();
    const dispatch = useDispatch();

    const [hasVariety, setHasVariety] = useState(false);

    const [defaultValues] = useState({
        cultureId,
        varietyId: null,
        updateLpis: false,
    });

    const [validationSchema] = useState(
        yup.object().shape({
            cultureId: yup.number()
                .typeError(t('cultureAndVarietyEditModal.specifyCulture', 'Specify the culture'))
                .required(t('cultureAndVarietyEditModal.specifyCulture', 'Specify the culture')),
        }),
    );

    const { handleSubmit, watch, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...defaultValues,
        },
        mode: 'onChange',
    });

    async function updateCultureAndVariety(formData) {
        try {
            const updateResult = await cultivationPeriodsService.setCultivationPeriodsCultureAndVariety({
                cultivationPeriodIds: seletedPeriodIdList,
                ...formData,
            });

            if (updateResult) {
                dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved.')));
                if (isFunction(hideModal)) {
                    hideModal();
                }

                if (!isNil(onSuccess) && isFunction(onSuccess)) {
                    onSuccess();
                } else {
                    dispatch(tableFilterActions.getTableData());
                }
            } else {
                dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed.')));
            }
        } catch (error) {
            dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed.')));
        }
    }

    async function onSubmit(formData) {
        try {
            if (hasCulture) {
                renderModal(ConfirmDialog, {
                    iconId: 'icon-done',
                    isAcceptCancelDialog: true,
                    acceptButtonText: t('default.yes', 'Yes'),
                    cancelButtonText: t('default.no', 'No'),
                    questionText: t('cultureAndVarietyEditModal.confirmQuestion', 'Are you sure?'),
                    descriptionText: t('cultureAndVarietyEditModal.confirmDescription', 'The selected periods already has culture, variety.'),
                    isHideModalAfterConfirm: false,
                    confirmCallback: () => updateCultureAndVariety(formData),
                }, false);
            } else {
                updateCultureAndVariety(formData);
            }
        } catch (error) {
            dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed.')));
        }
    }

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {t('cultureAndVarietyEditModal.modalTitle', 'Add Culture and Variety')}
            </ModalHeader>

            <ModalBody className={classes.modalBody}>
                <FormGroup label={t('cultureAndVarietyEditModal.culture', 'Culture')} required className={classes.formGroup} hasValue={getValues('cultureId')} errorMessage={errors?.cultureId?.message}>
                    <CultureDropdown className={classes.cultureDropDown} cultureId={watch('cultureId')} setCultureId={cultureId => setValue('cultureId', cultureId)} />
                </FormGroup>

                <FormGroup className={classes.formGroup}>
                    <Checkbox className={classes.varietyCheckBox} text={t('cultureAndVarietyEditModal.varietyCheckBox', 'Add Variety')} size="small" checked={hasVariety} setChecked={state => setHasVariety(state)} />
                </FormGroup>
                {
                    !hasVariety
                        ? null
                        : (
                            <FormGroup label={t('cultureAndVarietyEditModal.cultureVariety', 'Culture Variety')} className={classes.formGroup}>
                                <CultureVarietyDropDown className={classes.cultureDropDown} culture={getValues('cultureId')} onChange={varietyId => setValue('varietyId', varietyId)} />
                            </FormGroup>
                        )
                }
                <FormGroup className={classes.formGroup}>
                    <Checkbox ignoreFirstLetterFormatter className={classes.varietyCheckBox} text={t('cultureAndVarietyEditModal.migrateToLpis', 'Migrate data to Lpis')} size="small" checked={watch('updateLpis')} setChecked={state => setValue('updateLpis', !!state)} />
                </FormGroup>
            </ModalBody>

            <ModalFooter className={classes.modalFooter}>
                <Button className={classes.footerButton} onClick={handleSubmit(onSubmit)}>
                    {t('default.save', 'Save')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
