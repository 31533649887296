import { hotjar } from 'react-hotjar';
import { numberUtils } from 'shared/src/modules';

export const hotjarService = {
    init,
    identifyUser,
};

let hotjarInited = false;

function init() {
    const isEnabled = numberUtils.getBoolean(process.env?.REACT_APP_HOTJAR_ENABLED);
    if (isEnabled) {
        if (!hotjarInited) {
            const hotjarHjId: any = process.env?.REACT_APP_HOTJAR_HJID;
            const hotjarHjSv: any = process.env?.REACT_APP_HOTJAR_HJSV;
            hotjar.initialize(hotjarHjId, hotjarHjSv);
            hotjarInited = hotjar.initialized();
        }
        return hotjarInited;
    }

    return false;
}

function identifyUser(userId, props) {
    if (init()) {
        hotjar.identify(userId, props);
    }
}
