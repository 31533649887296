import { isFunction, isEmpty, values } from 'lodash';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import { theme as themes } from '@styles/theme';

export default function getAllColumns(t, translater, showedColumns) {
    const columns = {
        addIcon: {
            type: columnName.iconButton,
            columnId: 'icon-add',
            componentProps: {
                icon: 'icon-add',
                getCellTitle: () => translater('default.select', 'Select'),
                getBackgroundColor: () => themes.color.main,
                getIconColor: () => themes.color.white,
                onClick: (rowData, onAddButtonClicked) => {
                    if (isFunction(onAddButtonClicked)) {
                        onAddButtonClicked(rowData);
                    }
                },
            },
            isLeftFixed: true,
            width: 80,
            minWidth: 80,
            maxWidth: 80,
        },
        name: {
            type: columnName.simpleText,
            columnId: 'productName',
            columnName: t('productTableFilter.name', 'Name'),
            // isLeftFixed: true,
            width: 250,
            // minWidth: 250,
            // maxWidth: 250,
            isLeftFixed: true,
        },
        isActiveIcon: {
            type: columnName.iconSvg,
            columnId: 'productIsActive',
            columnName: t('productTableFilter.status', 'Status'),
            componentProps: {
                getIcon: rowData => (+rowData.productIsActive ? 'icon-done' : ''),
                getIconColor: () => themes.color.main,
                onClick: (rowData, onAddButtonClicked) => {
                    if (isFunction(onAddButtonClicked)) {
                        onAddButtonClicked(rowData);
                    }
                },
            },
            minWidth: 100,
        },
        productType: {
            type: columnName.simpleText,
            columnId: 'productType',
            columnName: t('productTableFilter.productType', 'Product type'),
            minWidth: 180,
        },
        itemNumber: {
            type: columnName.simpleText,
            columnId: 'productItemNumber',
            columnName: t('productTableFilter.itemNumber', 'Item number'),
        },
        unit: {
            type: columnName.simpleText,
            columnId: 'unitShortName',
            columnName: t('productTableFilter.unit', 'Unit'),
        },
        productComment: {
            type: columnName.simpleText,
            columnId: 'productComment',
            columnName: t('productTableFilter.productComment', 'Product comment'),
        },
        catalogNumber: {
            type: columnName.simpleText,
            columnId: 'productCatalogNumber',
            columnName: t('productTableFilter.catalogNumber', 'Catalog number'),
        },
    };

    if (!isEmpty(showedColumns)) {
        const filteredColumns: Array<any> = [];
        const columnObj = Object.entries(columns);

        showedColumns.forEach(showedColName => {
            const column = columnObj.find(columnParts => columnParts[0].toString() === showedColName);
            if (column) {
                filteredColumns.push(column[1]);
            }
        });

        return filteredColumns;
    }

    return values(columns);
}
