import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import Svg from '../Svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: 40,
        height: 40,
        borderRadius: 4,
        border: `2px solid ${theme.color.pearl}`,
        backgroundColor: theme.color.lilian,
    },
    icon: {
        width: 38,
        height: 38,
        borderRadius: 4,
        fill: theme.color.jet,
    },
}));

export default function CroptAvatar(props) {
    const { iconId, className } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.container, className)}>
            <Svg iconId={iconId} style={classes.icon} />
        </div>
    );
}
