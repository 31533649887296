import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { SYSTEM } from 'shared/src/constants';
import { isEmpty } from 'lodash';
import { sessionService } from '@services';

import { LoadSpinner } from '@baseComponents/loadSpinner';
import SideNavBarHeader from './sideNavBarParts/SideNavBarHeader';
import SideNavBarFooter from './sideNavBarParts/SideNavBarFooter';
import SideNavBarItem from './sideNavBarParts/SideNavBarItem';
import DQSLink from './sideNavBarParts/DQSLink';
import UserMenu from './userMenu/UserMenu';

const useStyles = createUseStyles((theme: any) => ({
    sideNavBarWrapper: {
        height: '100vh',
        position: 'relative',
        width: (props: any) => (props.expanded ? 240 : 60),
        minWidth: (props: any) => (props.expanded ? 240 : 60),
        float: 'left',
        background: (props: any) => {
            if (props.systemSubType === SYSTEM.SYSTEM_SUBTYPE.SAVINGS_BANK) {
                return 'rgb(0,154,223) !important';
            }
            return theme.color.white;
        },
        zIndex: theme.zIndex.sideNavBar,
    },
    sideBar: {
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    sideBarNavItems: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 7,
            position: 'absolute',
        },
        '&:hover::-webkit-scrollbar-thumb': {
            borderRadius: 7,
            background: 'rgba(0, 0, 0, 0.4)',
            position: 'absolute',
        },
        scrollbarWidth: 'thin !important',
    },
}));

export default function SideNavBar(props) {
    const { expanded, setExpanded, routeList, sideMenuList, defaultInfoLoaded } = props;
    const systemSubType = useSelector((state: any) => state.system.systemInfo.subType);
    const isTestSystem = useSelector((state: any) => state.system.systemInfo.isTestSystem);
    const [agentName] = useState(sessionService.getAgentName());
    const [showUserMenu, setShowUserMenu] = useState(false);
    const classes = useStyles({ expanded, showUserMenu, systemSubType });

    return (
        <div className={classes.sideNavBarWrapper}>
            <LoadSpinner value={!isEmpty(sideMenuList)} isAutoDetect />

            <div className={classes.sideBar}>
                <SideNavBarHeader expanded={expanded} setExpanded={setExpanded} setShowUserMenu={setShowUserMenu} isTestSystem={isTestSystem} />

                <div className={classes.sideBarNavItems}>
                    {sideMenuList.map(menuItem => (menuItem.visible && menuItem.eventKey
                        ? (
                            <SideNavBarItem
                                key={menuItem.eventKey}
                                expanded={expanded}
                                menuItem={menuItem}
                                routeList={routeList}
                                agentName={agentName}
                                setExpanded={setExpanded}
                                setShowUserMenu={setShowUserMenu}
                                isTestSystem={isTestSystem}
                            />)
                        : null
                    ))}
                </div>

                <DQSLink expanded={expanded} />

                <SideNavBarFooter expanded={expanded} setShowUserMenu={setShowUserMenu} />
            </div>

            <UserMenu
                showUserMenu={showUserMenu}
                openUserMenu={() => setShowUserMenu(true)}
                closeUserMenu={() => setShowUserMenu(false)}
                expanded={expanded}
                defaultInfoLoaded={defaultInfoLoaded}
            />

        </div>
    );
}
