import { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import DatePicker, { setDefaultLocale } from 'react-datepicker';
import { isFunction, isEmpty } from 'lodash';
import { sessionService } from '@services';
import { dateTimeUtils } from 'shared/src/modules';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        display: 'flex',
    },
    calendarContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    calendar: {
        display: 'flex',
        '& .react-datepicker__navigation--next': {
            right: 0,
            outline: 'none',
        },
        '& .react-datepicker__navigation--previous': {
            left: 0,
            outline: 'none',
        },
        '& .react-datepicker__day--selected': {
            width: '28px',
            borderRadius: 5,
            backgroundColor: theme.color.main,
            fontWeight: 'bold',
            color: theme.color.white,
        },
        '& .calendar': {
            marginBottom: 10,
        },
        '& .react-datepicker__header__dropdown': {
            display: 'inline-flex',
            paddingBottom: 5,
            marginLeft: 5,
            fontSize: 12,
            '& .react-datepicker__year-dropdown-container, .react-datepicker__month-dropdown-container': {
                paddingLeft: 5,
                paddingRight: 5,
            },
            '& select': {
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                appearance: 'none',
                paddingTop: '5px !important',
                paddingBottom: '5px !important',
                paddingLeft: '5px !important',
                outline: 'none',
                background: 'revert !important',
                boxShadow: theme.shadows.secondary.normal,
                border: 'solid 1px',
                borderColor: theme.color.gray,
                borderRadius: 4,
                height: 32,
            },
        },
    },
}));

export default function RangeDatePicker(props) {
    const { startDate, setStartDate, endDate, setEndDate, setInputStartDate, setInputEndDate, isFirstPick, setFirstPick, endDateDaysFromStartDate } = props;
    const langCode = sessionService.getLangCode();

    const classes = useStyles({ isFirstPick } as any);

    useEffect(() => {
        if (langCode) {
            setDefaultLocale(langCode);
        }
    }, [langCode]);

    function handleChangeDate(date) {
        if (isFirstPick) {
            setStartDate(dateTimeUtils.getDateObject(date));
            setInputStartDate(dateTimeUtils.getDate(date));
        } else {
            setEndDate(dateTimeUtils.getDateObject(date));
            setInputEndDate(dateTimeUtils.getDate(date));
        }
        if (isFunction(setFirstPick) && !endDateDaysFromStartDate) {
            setFirstPick(!isFirstPick);
        }
    }

    function getValidDate(date) {
        return !isEmpty(date) && dateTimeUtils.isValidDate(date) && date.toDate();
    }

    function getOpenDate() {
        return isFirstPick ? getValidDate(startDate) : getValidDate(endDate);
    }

    return (
        <div className={classes.container}>
            <div className={classes.calendarContainer}>
                <DatePicker
                    calendarClassName={classes.calendar}
                    selected={isFirstPick ? getValidDate(startDate) : getValidDate(endDate)}
                    startDate={getValidDate(startDate)}
                    endDate={getValidDate(endDate)}
                    minDate={endDateDaysFromStartDate ? null : (!isFirstPick && getValidDate(startDate))}
                    maxDate={endDateDaysFromStartDate ? null : (isFirstPick && getValidDate(endDate))}
                    onChange={date => handleChangeDate(date)}
                    openToDate={getOpenDate()}
                    locale={langCode}
                    showMonthDropdown
                    showYearDropdown
                    scrollableYearDropdown
                    dropdownMode="select"
                    selectsRange
                    inline
                />
            </div>
        </div>
    );
}
