import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { lte } from 'lodash';

import { numberUtils } from 'shared/src/modules/numberUtils';
import { useNumberFormat } from '@common_modules';

import Svg from '@baseComponents/Svg';
import { UnitInput } from '@baseComponents/inputs/inputField';

import Area from '@baseComponents/formatters/Area';

const useStyles = createUseStyles((theme: any) => ({
    basicFieldCard: {
        width: '100%',
        height: 60,
        borderRadius: 4,
        border: '1px solid',
        borderColor: theme.color.pearl,
        backgroundColor: theme.color.lilian,
        display: 'flex',
        marginTop: 15,
    },
    cardLeft: {
        display: 'flex',
    },
    deleteBtn: {
        minWidth: 60,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        backgroundColor: theme.color.lilian,
        borderLeft: '1px solid',
        borderLeftColor: theme.color.pearl,
        outline: 'none',
        marginLeft: 5,
        padding: 0,
    },
    icon: {
        height: 24,
        width: 24,
        fill: theme.color.destructive,
    },
    input: {
        width: 110,
    },
    basicFieldData: {
        fontSize: 13,
        lineHeight: '18px',
        marginLeft: 5,
        color: theme.color.stone,
    },
    areaInput: {
        paddingLeft: 5,
        paddingRight: 5,
        width: 120,
        display: 'flex',
        alignItems: 'center',
    },
    area: {
        paddingLeft: 5,
        paddingRight: 5,
        width: 100,
        display: 'flex',
        alignItems: 'center',
    },
    name: {
        paddingLeft: 5,
        paddingRight: 5,
        margin: 0,
        width: 202,
        display: 'flex',
        alignItems: 'center',
        '& h3': {
            margin: 0,
            ...theme.overFlowText,
        },
    },
}));

export default function BasicFieldCard(props) {
    const { basicField, removeCard, setBasicField, isDelete } = props;
    const { name, cultivatedArea, categorizedArea, freeArea, defaultFreeArea } = basicField;

    const classes = useStyles();

    const { numberFormatter } = useNumberFormat();

    const handleChangeArea = useCallback(
        (_event, currentValue) => {
            if (numberUtils.isPositiveNumber(currentValue)) {
                const countFreeArea = +defaultFreeArea - currentValue;
                const changedBasicField = {
                    ...basicField,
                    categorizedArea: currentValue,
                    freeArea: lte(countFreeArea, 0) ? 0 : countFreeArea || 0,
                };
                setBasicField(changedBasicField);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [numberFormatter, basicField, setBasicField],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const areaFormat = useCallback(() => numberFormatter.getAreaFormats(), [numberFormatter.isInitialized]);

    return (
        <div className={classes.basicFieldCard}>
            <div className={classes.cardLeft}>
                <div className={classes.name}>
                    <h3 title={name}>
                        {name}
                    </h3>
                </div>
                <div className={classes.area}>
                    <Area value={cultivatedArea} />
                </div>
                <div className={classes.areaInput}>
                    <UnitInput
                        type="number"
                        defaultValue={categorizedArea}
                        onBlur={handleChangeArea}
                        numberFormat={areaFormat()}
                        className={classes.input}
                        positiveOnly
                    />
                </div>
                <div className={classes.area}>
                    <Area value={freeArea} />
                </div>
            </div>
            {isDelete &&
                <button type="button" className={classes.deleteBtn} onClick={() => removeCard(basicField.id)}>
                    <Svg iconId="icon-bin" style={classes.icon} />
                </button>}
        </div>
    );
}
