import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SETTINGS, ECONOMIC_UNIT, WORKOPERATION } from 'shared/src/constants';
import { companyActions } from '@states/actions/companyActions';
import { isEmpty } from 'lodash';

import { dataListsActions, errorActions } from '@states/actions';
import {
    productionYearService,
    cultureService,
    cropService,
    reproductionRateService,
    farmService,
    economicUnitService,
    gnService,
    machineService,
    employeeService,
    treatmentUnitService,
    productService,
    warehouseService,
    workOperationsService,
    cultivationPeriodsService,
    meteorologyService,
} from '@services';

export function useDataListHandler() {
    const dispatch = useDispatch();
    const companyIds = useSelector((state: any) => state.company.activeCompanyIds);
    const isLoggedIn = useSelector((state: any) => state.user.isLoggedIn);
    const isPlanningmodeActive = useSelector((state: any) => state.system.planningModeisActive);
    const dataListInitialized = useRef(false);

    //fix lists
    useEffect(() => {
        let isCancelled = false;

        async function loadDataList(endPointFunction, reduxSetter, listName, endPointParamsObj?: any) {
            try {
                let dataList = [];
                if (!isCancelled) {
                    dataList = await endPointFunction(endPointParamsObj);
                }

                if (!isCancelled) {
                    dispatch(reduxSetter(dataList, listName));
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'DataListHandlerError'));
            }
        }

        if (dispatch && isLoggedIn) {
            let productionYearsParams = {};
            if (isPlanningmodeActive) {
                productionYearsParams = { isPlanning: 1 };
            }

            loadDataList(productionYearService.getProductionYears, dataListsActions.addDataList, 'productionYears', productionYearsParams);

            if (!dataListInitialized.current) {
                loadDataList(cultureService.getCultures, dataListsActions.addDataList, 'cultures');
                loadDataList(cropService.getCropTypes, dataListsActions.addDataList, 'cropTypes');
                loadDataList(reproductionRateService.getReproductionRates, dataListsActions.addDataList, 'reproductionRates');
                loadDataList(gnService.getGnInformationObligedEventList, dataListsActions.addDataList, 'gnInformationObligedEventList');
                loadDataList(productService.getProductTypeList, dataListsActions.addDataList, 'productTypes');
                loadDataList(treatmentUnitService.getTreatmentUnitTypes, dataListsActions.addDataList, 'treatmentUnitTypes');
                loadDataList(warehouseService.getWarehouses, dataListsActions.addDataList, 'warehouses');
                loadDataList(meteorologyService.getTemperatureUnits, dataListsActions.addDataList, 'temperatureUnits');
                loadDataList(meteorologyService.getAtmosphericPressureUnits, dataListsActions.addDataList, 'atmosphericPressureUnits');
                loadDataList(cultivationPeriodsService.getGrowthStageList, dataListsActions.addDataList, 'growthStages');

                if (dispatch(companyActions.getSetting(SETTINGS.WORKOPERATION_QUALIFICATION))) {
                    loadDataList(workOperationsService.getWorkOperationQualificationParameters, dataListsActions.addDataList, 'qualificationParameters');
                }

                if (dispatch(companyActions.getSetting(SETTINGS.USING_FARM))) {
                    loadDataList(farmService.getFarms, dataListsActions.addDataList, 'farms');
                }

                dataListInitialized.current = true;
            }

            return () => {
                isCancelled = true;
            };
        }
        dataListInitialized.current = false;
    }, [dispatch, isLoggedIn, isPlanningmodeActive]);

    //load by filter
    useEffect(() => {
        let isCancelled = false;

        async function loadDataList(endPointFunction, reduxSetter, listName, endPointParamsObj) {
            try {
                let dataList = [];
                if (!isCancelled) {
                    dataList = await endPointFunction(endPointParamsObj);
                }
                if (!isCancelled) {
                    dispatch(reduxSetter(dataList, listName));
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'DataListHandlerError'));
            }
        }

        if (!isEmpty(companyIds)) {
            loadDataList(economicUnitService.getEconomicUnitList, dataListsActions.addDataList, 'economicUnitList', { companyIds, economicUnitTypeIds: ECONOMIC_UNIT.TYPE.SERVICE });
            loadDataList(machineService.getMachineList, dataListsActions.addDataList, 'machineList', companyIds);
            loadDataList(employeeService.getEmployees, dataListsActions.addDataList, 'employeeList', { companyIds });
            loadDataList(workOperationsService.getAllWorkShiftWithTime, dataListsActions.addDataList, 'workShiftList', companyIds);
            loadDataList(workOperationsService.getWorkOperationMaingroup, dataListsActions.addDataList, 'workOperationMainGroups', { workoperationTypeIds: Object.values(WORKOPERATION.TYPE_ID) });

            return () => {
                isCancelled = true;
            };
        }
    }, [companyIds, dispatch]);
}
