import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isEmpty, isUndefined } from 'lodash';
import { errorActions } from '@states/actions';
import { stockService, productService } from '@services';
import { PRODUCT } from 'shared/src/constants';

export default function useProductsModalLoadData(props) {
    const {
        defaultActiveState, companyId, warehouseId = null, date = null, hasWeightShiftNumber = false, notPlantProtectedProducts = false, showPesticideProduct,
        affectedByStockRecords, recordsInManagementUnit, inStockProductsOnly, typeIds, excludedIds, isNutrient, isPlantProtection,
    } = props || {};
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const [tableData, setTableData] = useState<Array<any>>();
    const [isReloadTable, setIsReloadTable] = useState(false);
    const [activeState, setActiveState] = useState(defaultActiveState || PRODUCT.ACTIVE_STATUS.ACTIVE);
    const companyIds = useSelector((state: any) => state.company.activeCompanyIds);

    //készleten lévő termékek betöltése
    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const products = inStockProductsOnly
                    ? await stockService.getProductsFromStock({
                        companyIds: companyId || companyIds,
                        warehouseId,
                        date,
                        hasWeightShiftNumber,
                        notPlantProtectedProducts,
                        ...(!isUndefined(affectedByStockRecords) ? { affectedByStockRecords } : {}),
                        ...(!isUndefined(recordsInManagementUnit) ? { recordsInManagementUnit } : {}),
                        productStatus: activeState,
                        isNutrient,
                        isPlantProtection,
                        showPesticideProduct,
                    })
                    : await productService.getProducts({ companyIds: companyId || companyIds, productStatus: activeState, typeIds, isNutrient, isPlantProtection, showPesticideProduct });

                const productsFormated = inStockProductsOnly
                    ? products
                    : products.map(product => ({
                        id: product?.id,
                        productId: product?.id,
                        productName: product?.name,
                        productIsActive: product?.isActive,
                        productType: product?.productType,
                        productItemNumber: product?.itemNumber,
                        unitId: product?.unitId,
                        unitShortName: product?.unitShortName,
                        productCatalogNumber: product?.catalogNumber,
                        productComment: product?.comment,
                        pesticideProductId: product?.pesticideProductId,
                        recordingInTreatmentUnit: product?.recordingInTreatmentUnit,
                    }));

                if (!isCancelled) {
                    if (!isEmpty(excludedIds)) {
                        setTableData(productsFormated?.filter(product => !excludedIds?.includes(product.id)));
                    } else {
                        setTableData(productsFormated);
                    }
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (!isEmpty(companyIds) && location) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [
        isReloadTable,
        companyIds,
        location,
        activeState,
        warehouseId,
        date,
        companyId,
        hasWeightShiftNumber,
        notPlantProtectedProducts,
        dispatch,
        affectedByStockRecords,
        recordsInManagementUnit,
        inStockProductsOnly,
        typeIds,
        excludedIds,
        isNutrient,
        isPlantProtection,
        showPesticideProduct,
    ]);

    function reloadTable() {
        setIsReloadTable(currentState => !currentState);
    }

    return {
        tableData,
        activeState,
        changePartnerType: setActiveState,
        reloadTable,
    };
}
