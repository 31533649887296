export const WORKOPERATION = {
    STATUS_ID: {
        TEMPLATE: 1,
        PLANNED: 2,
        ONGOING: 3,
        FINISHED: 4,
    },
    TYPE_ID: {
        TABLE_WORK: 1,
        ECONOMIC_UNIT_WORK: 2,
        SERVICE_WORK: 3,
        WAGE_WORK: 4,
        PRODUCTION_GROUP: 5,
        WINE_TREATMENT: 6,
        FRUIT_PROCESSING: 7,
        SEED_PRODUCTION: 8,
    },
    PERFORMANCE: {
        NO_WORKOPERATION_PERFORMANCE: 1,
        NO_PERFORMED_AREA: 2,
        NO_WORK_HOURS: 3,
    },
    MACHINE_CONNECTION: {
        NO_LABOUR: 1,
        NO_EQUIPMENT: 2,
    },
    OTHER: {
        NO_TRANSFER_REQUEST: 1,
    },
    PREVIOUS_YEARS_FOR_MENU: 1,
    MAX_SHOWED_DATA_IN_MENU: 10,
    ADD: {
        TITLES: {
            PERIOD: 1,
            MATERIAL: 2,
            RESOURCES: 3,
            PARTNER: 4,
            MACHINE: 5,
            ECONOMIC_UNIT: 6,
            SERVICE: 7,
        },
    },
    SERVICE_TYPE: {
        MACHINE_SERVICE: 1,
        ECONOMIC_UNIT_SERVICE: 2,
    },
    RECORDING_RELEVANT_DATA: {
        WITH: 1,
        WITHOUT: 2,
    },
    PERIOD: {
        NONE: 0,
        PERIODS: 1,
        PERIOD_GROUPS: 2,
    },
    STOCK: {
        FROM_STOCK: 1,
        NOT_FROM_STOCK: 2,
    },
    RECORDING_IN_TREATMENT_UNIT_RELATION: 0,
    ECONOMIC_UNIT_RELATION: 0,
    PRODUCT_STATUS: 1,
    PARTNER_ID: 0,

    //machines
    OPEN_SELF_PROPELLED_MACHINE: 1,
    NUMBER_OF_MACHINES: 2,
    MAX_NUMBER_OF_MACHINES: {
        SERVICE_WORK: 0,
        OTHER_WORK_OPERATIONS: 4,
    },
    MACHINE_TYPES: {
        SELF_PROPELLED_MACHINE: 'selfPropelledMachine',
        FIRST_MACHINE: 'firstMachine',
        SECOND_MACHINE: 'secondMachine',
        THIRD_MACHINE: 'thirdMachine',
    },
    SELF_PROPELLED_MACHINE: 1,
    NOT_SELF_PROPELLED_MACHINE: 0,
    MACHINE_CONNECTION_POPOVER_INDEX: 1,
    MODAL_TABS_NUMBER: 3,
    VIEW_DATA_TABS: {
        PERIOD: 1,
        MATERIAL: 2,
        RESOURCES: 3,
        ECONOMIC_UNIT: 4,
        PARTNER: 5,
        SERVICE: 6,
    },
    FLEET_MOBILE_DATA_SOURCE_TYPE: {  // id-ból jön maradnia kell
        NONE: 0,
        CONNECT: 1,
        WORKS: 2,
    },
    FLEET_MOBILE_DATA_SOURCE_NAME: {  // id-ból jön maradnia kell
        CONNECT: 'Connect',
        WORKS: 'Works',
    },
    WORKSHOP: {
        OWN: 1,
        PARTNER: 2,
        PARTNER_AND_OWN: 3,
    },
    FLEET_MOBILE_DATA_GROUPING_OPTIONS: {
        DUMMY_GROUP: 'dummyGroup',
        MACHINE_AND_WORKOPERATION_GROUP: 'machineAndWorkoperationGroup',
        WORKER_AND_WORKOPERATION_GROUP: 'workerAndWorkoperationGroup',
    },
    FLEET_MOBILE_DATA_MATERIAL_GROUPING_OPTIONS: {
        NONE: 'none',
        BY_MATERIALS: 'groupByProduct',
        BY_MATERIALS_AND_WORKOPERATIONTYPE: 'groupByMaterialAworkoperattiontype',
    },
    FLEET_MOBILE_DATA_TYPE: {
        DATA: 1,
        MOVEMENT: 2,
        PREPARATIONS: 3,
        REST: 4,
        // DATA: 'DATA',
        // MOVEMENT: 'MOVEMENT',
        // PREPARATIONS: 'PREPARATIONS',
        // REST: 'REST',
    },
    FLEET_MOBILE_DATA_VIEW_TYPE: {
        OPERATIONS: 'operations',
        OPERATIONS_AND_MATERIALS: 'operations-and_materials',
        MATERIALS: 'materials',
    },
    GRIDBUTTON_TYPES: {
        WORKOPERATION_BUTTON: 1,
        MACHINE_BUTTON: 2,
        ADD_BUTTON: 3,
        NO_BUTTON: 4,
        WORKMACHINE_BUTTON: 5,
        WORKER_BUTTON: 6,
        DETAILED_DATA_BUTTON: 7,
    },
    ERRORS: {
        MISSING_ITEM: {
            ERROR_CODE: 'missingItem',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingItem',
                DEFAULT: 'The field is mandatory.',
            },
        },
        MISSING_COST_BEARER: {
            ERROR_CODE: 'missingWorkoperation',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingCostBearer',
                DEFAULT: 'The cost bearer field is mandatory.',
            },
        },
        MISSING_WORKOPERATION: {
            ERROR_CODE: 'missingWorkoperation',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingWorkoperation',
                DEFAULT: 'The workOperation field is mandatory.',
            },
        },
        MISSING_MACHINE: {
            ERROR_CODE: 'missingMachine',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingMachine',
                DEFAULT: 'The machine field is mandatory.',
            },
        },
        MISSING_WORKER: {
            ERROR_CODE: 'missingWorker',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingWorker',
                DEFAULT: 'The worker field is mandatory.',
            },
        },
        MISSING_PERFORMANCE: {
            ERROR_CODE: 'missingPerformance',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingPerformance',
                DEFAULT: 'The performance field is mandatory.',
            },
        },
        MISSING_PAID_PERFORMANCE: {
            ERROR_CODE: 'missingPaidPerformance',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingPaidPerformance',
                DEFAULT: 'The paid performance field is mandatory.',
            },
        },
        MISSING_UNIT_PRICE: {
            ERROR_CODE: 'missingUnitPrice',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingUnitPrice',
                DEFAULT: 'The unit price field is mandatory.',
            },
        },
        MISSING_TOTAL_NET_FEE: {
            ERROR_CODE: 'missingTotalNetFee',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingTotalNetFee',
                DEFAULT: 'The total net fee field is mandatory.',
            },
        },
        MISSING_TREATED_AREA: {
            ERROR_CODE: 'missingPerformance',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingTreatedArea',
                DEFAULT: 'The treated area field is mandatory.',
            },
        },
        MISSING_QUANTITY: {
            ERROR_CODE: 'missingQuantity',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingQuantity',
                DEFAULT: 'The quantity field is mandatory.',
            },
        },
        MISSING_WAREHOUSE: {
            ERROR_CODE: 'missingWarehouse',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingWarehouse',
                DEFAULT: 'The warehouse field is mandatory.',
            },
        },
        MISSING_DOSE: {
            ERROR_CODE: 'missingDose',
            ERROR_MESSAGE: {
                KEY: 'workoperation.missingDose',
                DEFAULT: 'The dose field is mandatory.',
            },
        },
    },
    APPROVAL_DATE: {
        LAST_DAILY_DATA: 1,
        WORK_OPERATION_END_DATE: 2,
        TODAY: 3,
    },
    CALENDAR: {
        GROUP_BY: {
            CULTURE: 1,
            MACHINE: 2,
            WORKER: 3,
        },
        GROUP_BY_PROPERTY: {
            COST_CENTER: 'costCenter',
            MACHINE_AVATARS: 'machineAvatars',
            WORKER_AVATARS: 'workerAvatars',
            NO_CULTURE: 'withoutCulture',
        }
    },
    SUBJECT_OF_WORK_TYPE: {
        PERIOD: 'PERIOD',
        PERIOD_GROUP: 'PERIOD_GROUP',
        ECONOMIC_UNIT: 'ECONOMIC_UNIT',
        PARTNER: 'PARTNER',
        SERVICE: 'SERVICE',
    },
    ACTIVITY_DATA_GROUPING_TYPE: {
        BY_WORK_OPERATION: 'BY_WORK_OPERATION',
        BY_WORKER: 'BY_WORKER',
    },
    OPERATION_DATA_DATASOURCE_TYPE: {
        DATA: 'DATA',
        MOVEMENT: 'MOVEMENT',
        PREPARATIONS: 'PREPARATIONS',
        REST: 'REST',
    },
    OPERATION_DATA_DATA_GROUPING_TYPE: {
        BY_WORK_OPERATION: 'BY_WORK_OPERATION',
        BY_WORKER: 'BY_WORKER',
    },
    OPERATION_DATA_GRID_GROUPING_TYPE: {
        BY_MACHINE_CONNECTION: 'machineConnection',
    },
    OPERATION_DATA_MATERIALS_GRID_GROUPING_TYPE: {
        BY_PRODUCT: 'product',
        BY_USAGE_AND_PRODUCT: 'usage_and_product',
    },
    OPERATION_DATA_MATERIALS_GRID_DISTRIBUTE_BY: {
        AREA: 'DISTRIBUTE_BY_AREA',
        PLANNED_QUANTITY: 'DISTRIBUTE_BY_PLANNED_QUANTITY',
    },
    OPERATION_DATA_MATERIALS_GRID_TARGET_OF_CHANGE: {
        DOSE: 'TARGET_OF_CHANGE_DOSE',
        AREA: 'TARGET_OF_CHANGE_AREA',
    },
    DAILY_DATA_COMPANY_DIFFERENCE_TYPE: {
        POWERMACHINE_PERIOD: 1, //eltérő erőgép cég és időszak cég
        WORKER_PERIOD: 2, //eltérő munkaerő cég és időszak cég
        POWERMACHINE_PERIOD_AND_WORKER_PERIOD: 3, //eltérő erőgép és időszak cég és eltérő munkaerő cég és időszak cég
    },
    SERVING_WORK_OPERATION: {
        SERVING: 1,
        NO_SERVING: 0,
    },
    QUALIFICATION_TYPE : {
        OFF: 0,
        ON: 1,
    },
    QUALIFICATION_NORM : {
        OFF: 0,
        ON: 1,
    },
    QUALIFICATION_FILTER: {
        ALL: 0,
        QUALIFIED: 1,
        UNQUALIFIED: 2,
    },
    WORKS_WORKOPERATION_TYPE: {
        TABLE: 1,
        ECONOMIC_UNIT: 2,
        SERVICE: 3,
        WAGE: 4,
        UTILIZED: 5, //igénybevett szolgáltatás
        PRODUCTION_GROUP: 6, //termelési csoportra végzett munka
    },
    SERVICE_REASON: {
        TECHNICAL_PREPARATION: 2,
        WARRANTY_SERVICE: 4,
        TECHNICAL_REVIEW_DIAGNOSTICION: 6,
        PREVENTIVE_MAINTENANCE : 12,
        MANDATORY_SERVICE: 15,
    },
};
