import React from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty, isFunction } from 'lodash';

import Button from '@baseComponents/buttons/Button';

const useStyles = createUseStyles((theme: any) => ({
    cellWrapper: {
        height: '100%',
        width: '100%',
        minHeight: 56,
        display: 'flex',
    },
    textWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    twoLineContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    firstLine: {
        width: '100%',
        height: '50%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: '36px',

        fontSize: 15,
        fontWeight: 500,
        color: theme.color.jet,
    },
    secondLine: {
        width: '100%',
        height: '50%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        display: 'inline-block',
        lineHeight: '18px',

        fontSize: 13,
        fontWeight: 'normal',
        color: theme.color.raven,
    },
    buttonWrapper: {
        display: 'inline-block',
        margin: 'auto',
        marginLeft: 0,
        marginRight: 5,
    },
    button: {
        width: 50,
    },
}));

export default function MultilineWithButtonCell(props) {
    const { rowData = props.cellData, value = props.cellValue, onClick } = props;
    const { key, value: line1, value2: line2 } = value || {}; //values
    const { iconId, title, isDisabled, getIsDisabled } = props; //icon
    const classes = useStyles();

    function onClickButton(event) {
        event.stopPropagation();
        if (onClick) {
            onClick(key, rowData);
        }
    }

    if (isEmpty(value)) {
        return null;
    }

    return (
        <div className={classes.cellWrapper}>
            <div className={classes.buttonWrapper}>
                {!isEmpty(iconId) && (
                    <Button
                        className={classes.button}
                        size="small"
                        type="secondary"
                        iconId={iconId}
                        iconPosition="before"
                        title={title}
                        onClick={onClickButton}
                        disabled={(!isFunction(onClick) && !isDisabled) || (isFunction(getIsDisabled) ? getIsDisabled(rowData) : false)}
                    />
                )}
            </div>
            <div className={classes.textWrapper}>
                <div className={classes.twoLineContainer}>
                    <div className={classes.firstLine}>
                        {line1}
                    </div>
                    <div className={classes.secondLine}>
                        {line2}
                    </div>
                </div>
            </div>
        </div>
    );
}
