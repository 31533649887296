import React from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { OverlayTrigger } from 'react-bootstrap';

const useStyles = createUseStyles((theme: any) => ({
    tooltip: {
        borderRadius: 6,
        boxShadow: `${theme.shadows.lightGrey} !important`,
        border: `1px solid ${theme.color.pearl}`,
        background: theme.color.white,
        padding: 20,
        fontSize: 14,
        margin: 0,
    },
    tooltipItemContainer: {
        display: 'flex',
        marginBottom: 5,
        listStyleType: 'none',
        alignItems: 'center',
    },
    nameContainer: {
        marginLeft: 10,
    },
}));

const AvatarContainer = (props) => {
    const { containerClassName, avatarClassName, additionalClassName, datalist, Component, rowId } = props || {};
    const classes = useStyles();

    return (
        <div className={containerClassName}>
            {datalist?.withAvatar?.map(({ name, id, imageUrl }, index) => (
                id ? (
                    <OverlayTrigger
                        placement="left"
                        key={`${rowId}-${id}-${index}`}
                        overlay={({ placement, scheduleUpdate, arrowProps, outOfBoundaries, show, ...props }) => (
                            <div id="button-tooltip" className={classes.tooltip} {...props} show={show.toString()}>
                                {name}
                            </div>
                        )}
                    >
                        <div>
                            <Component name={name} className={avatarClassName} image={imageUrl} machineId={id} imageUrl={imageUrl} />
                        </div>
                    </OverlayTrigger>
                ) : null
            ))}
            {datalist?.rest?.length > 0 && (
                <div className={additionalClassName}>
                    <OverlayTrigger
                        placement="left"
                        overlay={({ placement, scheduleUpdate, arrowProps, outOfBoundaries, show, ...props }) => (
                            <ul id="button-tooltip" className={classes.tooltip} {...props} show={show.toString()}>
                                {datalist?.rest?.map(({ name, id, imageUrl }) => (
                                    <li className={classes.tooltipItemContainer} key={`${rowId}${id}`}>
                                        <Component name={name} className={avatarClassName} image={imageUrl} machineId={id} imageUrl={imageUrl} />
                                        <span className={classes.nameContainer}>{name}</span>
                                    </li>))}
                            </ul>
                        )}
                    >
                        <div>
                            + {datalist.rest.length}
                        </div>
                    </OverlayTrigger>
                </div>
            )}
        </div>
    );
};

export default AvatarContainer;

AvatarContainer.propTypes = {
    containerClassName: PropTypes.string.isRequired,
    avatarClassName: PropTypes.string,
    additionalClassName: PropTypes.string,
    datalist: PropTypes.shape({
        withAvatar: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
        rest: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        })),
    }).isRequired,
    Component: PropTypes.elementType.isRequired,
    rowId: PropTypes.number.isRequired,
};

AvatarContainer.defaultProps = {
    avatarClassName: null,
    additionalClassName: null,
};
