import useTranslate from '@i18n/useTranslate';
import { MachineDataResponse, ThemeType, TranslateType } from '@map/services/mapTypes';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { createUseStyles } from 'react-jss';
import { camelCase, isNil } from 'lodash';
import MachinePanelHeader from './MachinPanleHeader';
import 'react-circular-progressbar/dist/styles.css';

type Classes = 'main' | 'title' | 'subtitle' | 'content' | 'gadget' | 'gadgetText' | 'titleText' | 'operationHoursText' | 'progressText' | 'progressChildrenText' | 'mainHeader' | 'closeButton' | 'footer' | 'footerText';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    mainHeader: {
        width: 360,
        height: 'auto',
        right: 61,
        marginTop: 25,
        position: 'absolute',
        zIndex: 3,
        backgroundColor: 'white',
        fontFamily: 'Rubik',
        borderRadius: 5,
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 12,
        display: 'block',
        boxSizing: 'border-box',
        width: 30,
        height: 30,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: theme.color.pearl,
        borderRadius: '100%',
        background: '-webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, #0B0D0F 46%, #0B0D0F 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, #0B0D0F 46%, #0B0D0F 56%,transparent 56%, transparent 100%)',
        backgroundColor: theme.color.pearl,
        transition: 'all 0.3s ease',
        cursor: 'pointer',
    },
    main: {
        height: 'auto',
        backgroundColor: theme.color.lilian,
        paddingTop: 15,
        borderRadius: '0 0 5px 5px',
        borderTop: '1px solid',
        borderTopColor: theme.color.pearl,
    },
    title: {
        height: 20,
        display: 'flex',
        justifyContent: 'space-between',
    },
    subtitle: {
        marginLeft: 15,
        marginRight: 15,
    },
    content: {
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 10,
        display: 'flex',
    },
    gadget: {
        width: 100,
        margin: '0px 5px 0px 5px',
        textAlign: 'center',
    },
    gadgetText: {
        fontSize: 12,
        color: theme.color.raven,
    },
    titleText: {
        fontSize: 14,
        color: theme.color.jet,
        fontWeight: 500,
    },
    timeText: {
        fontSize: 12,
        color: theme.color.jet,
        fontWeight: 'normal',
    },
    operationHoursText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
        fontSize: 14,
        fontWeight: 500,
    },
    progressText: {
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 2,
    },
    progressChildrenText: {
        fontSize: 12,
        fontWeight: 'normal',
        color: theme.color.raven,
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingLeft: 2,
    },
    footerText: {
        fontSize: 10,
        textAlign: 'left',
        color: theme.color.raven,
    },
}));

const progressBarStyleInput = {
    textSize: '16px',
    textColor: '#21282C',
    pathColor: '#FF9500',
};

type TProps = {
    close: () => void,
    machineData: MachineDataResponse;
}

function MachinePanelComponent({
    close,
    machineData,
}: TProps) {
    const { t, translater }: TranslateType = useTranslate();
    const classes = useStyles();
    // 0.01%
    const fuel = machineData.attributes.Values.find(id => id.property === 100491);
    const fuelText = isNil(fuel) ? '-' : (fuel?.value / 100).toString();
    const fuelNum = isNil(fuel) ? 0 : fuel?.value / 100;
    //m/s * 1000
    const speed = machineData.attributes.Values.find(id => id.property === 1859);
    const speedText = isNil(speed) ? '-' : ((speed?.value / 1000) * 3.6).toFixed(1);
    const speedNum = isNil(speed) ? 0 : ((speed?.value / 1000) * 3.6);
    //0.05hour / bit
    const hoo = machineData.attributes.Values.find(id => id.property === 247);
    const hooText = isNil(hoo) ? '-' : (hoo?.value * 0.05).toFixed(1);

    const timeText = machineData.attributes.Time;
    const date = timeText.slice(0, timeText.indexOf('T'));
    const time = timeText.slice(timeText.indexOf('T') + 1, timeText.indexOf('T') + 6);

    return (
        <div className={classes.mainHeader}>
            <span
                className={classes.closeButton}
                onClick={() => close()}
                onKeyDown={() => close()}
                role="button"
                aria-label="Close"
                aria-hidden="true"
            />
            <MachinePanelHeader name={machineData.name} companyName={machineData.companyName} idNum={machineData.idNumber} iconUrl={machineData.profilePicture} />
            <div className={classes.main}>
                <div className={classes.title}>
                    <div className={classes.subtitle}>
                        <span className={classes.titleText}>{ t('map.machinePanelTrackData', 'Track Data') }</span>
                    </div>
                </div>
                <div className={classes.content}>
                    <div className={classes.gadget}>
                        <CircularProgressbarWithChildren value={fuelNum} maxValue={100} strokeWidth={6} styles={buildStyles(progressBarStyleInput)}>
                            <div className={classes.progressText}>
                                <span>{fuelText}</span>
                            </div>
                            <div className={classes.progressChildrenText}>
                                <span>%</span>
                            </div>
                        </CircularProgressbarWithChildren>
                        <span className={classes.gadgetText}>{ t('map.machinePanelFuel', 'Fuel') }</span>
                    </div>
                    <div className={classes.gadget}>
                        <CircularProgressbarWithChildren value={speedNum} maxValue={100} strokeWidth={6} styles={buildStyles(progressBarStyleInput)}>
                            <div className={classes.progressText}>
                                <span>{speedText}</span>
                            </div>
                            <div className={classes.progressChildrenText}>
                                <span>km/h</span>
                            </div>
                        </CircularProgressbarWithChildren>
                        <span className={classes.gadgetText}>{ t('map.machinePanelSpeed', 'Speed') } </span>
                    </div>
                    <div className={classes.gadget}>
                        <div className={classes.operationHoursText}>{hooText}</div>
                        <span className={classes.gadgetText}>{ t('map.machinePanelOperationHours', 'Operating hours') }</span>
                    </div>
                </div>
                <div className={classes.footer}>
                    <span className={classes.footerText}>{`${translater('map.machinPanelUpdateTime', 'Last data refresh')}: ${date} ${time}`}</span>
                </div>
            </div>
        </div>
    );
}

export default MachinePanelComponent;
