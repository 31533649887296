import React from 'react';
import { createUseStyles } from 'react-jss';
import { FormDatePicker } from '@customComponents/datePickers';
import useTranslate from '@i18n/useTranslate';

const useStyles = createUseStyles({
    date: {
        display: 'block',
        marginTop: 15,
        '& button': {
            width: '100%',
        },
    },
});

export default function StartAndEndOfPeriod(props) {
    const { endDate, setEndDate, formRef, startDate, setStartDate, startDateError, endDateError } = props;
    const { t } = useTranslate();
    const classes = useStyles();

    return (
        <>
            <FormDatePicker
                className={classes.date}
                date={endDate}
                setDate={setEndDate}
                label={t('closeOpenCultivationPeriod.endOfPeriod', 'End of period')}
                scrollRef={formRef}
                invalid={!!endDateError}
                errorMessage={endDateError}
                showDate
                required
            />
            <FormDatePicker
                className={classes.date}
                date={startDate}
                setDate={setStartDate}
                label={t('closeOpenCultivationPeriod.startOfNewPeriod', 'Start of new period')}
                scrollRef={formRef}
                invalid={!!startDateError}
                errorMessage={startDateError}
                showDate
                required
            />
        </>
    );
}
