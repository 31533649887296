import { ResponseWrapper } from './baseTypes';

type RequestMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

/* eslint-disable id-blacklist */
const createConfig = (method: RequestMethod, body: any) => {
    const formBody = body instanceof FormData;
    const headers = {
        'Cache-Control': 'no-store',
    } as any;

    if (!formBody) {
        headers['Content-Type'] = 'application/json';
    }

    if (sessionStorage.getItem('gisAuthToken')) {
        headers.Authorization = `Bearer ${sessionStorage.getItem('gisAuthToken')}`;
    }

    let configBody: FormData | string | null = null;
    if (body) {
        configBody = formBody ? body : JSON.stringify(body);
    }

    return {
        body: configBody,
        headers,
        method,
    };
};

const callApi = async <T> (method: RequestMethod, url: string, bodyOrQuery?: any, asBlob?: boolean): Promise<ResponseWrapper<T> | Record<string, never>> => {
    let fullUrl = url;
    let body = bodyOrQuery;

    if (method === 'GET') {
        fullUrl += bodyOrQuery ? `?${bodyOrQuery.toString()}` : '';
        body = null;
    }
    try {
        const res = await fetch(fullUrl, createConfig(method, body));
        if (res) {
            let data = {};
            if (!asBlob) {
                const text = await res.text();
                try {
                    data = JSON.parse(text);
                } catch (e) {
                    data = text;
                }
            } else {
                data = await res.blob();
            }
            return {
                ok: res.ok,
                status: res.status,
                headers: res.headers ?? {},
                data: data as T,
            };
        }
    } catch (error) {
        return {};
    }
    return {};
};

export default callApi;
