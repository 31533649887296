/* eslint-disable max-lines-per-function */
import { checkMenuIsVisible, hasPermission } from '../permissions';
import { MODULES } from '../routing/index';
import { PLANNING, RIGHTS } from '../constants';
//import { SETTINGS, PLANNING, DEFAULT_SYSTEM_DATA } from '../constants';
//import { companySettingsModule } from '../company/companySettings';

export function getSideMenuList(userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, permissionList, isHiddenUser, countryCode) {
    //const useGlobalLite = companySettingsModule.getSettingValue(SETTINGS.USE_GLOBAL_LITE, companySettings);

    //TODO: php - temp permission checker function
    function hasPhpPermission(menuId) {
        return permissionList ? permissionList[menuId] : false;
    }

    function getEventKey(contentObject) {
        if (!contentObject) {
            return null;
        }

        if (planningMode) {
            const planningContent = Object.entries(MODULES.PLANNING.CONTENTS).find(([, content]) => content.EVENT_KEY === `${contentObject.EVENT_KEY}${PLANNING.ROUTE_KEY_EXTENSION}`);
            return planningContent ? planningContent[1].EVENT_KEY : null;
        }

        return contentObject.EVENT_KEY;
    }

    function checkPremiumServicesMenuIsVisible(){
        return !consultant && hasPermission(RIGHTS.SUBSCRIPTION_SERVICE_INTERFACE, userToRights, isHiddenUser);
    }

    let routeList = [];

    //simple route list
    if (!planningMode) {
        routeList = [
            {
                eventKey: getEventKey(MODULES.LIVESTOCK_RECORDS.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.livestockRecords',
                translatorDefault: 'Livestock records',
                icon: '/styles/img/menu/stockmanagement',
                visible: checkMenuIsVisible(MODULES.LIVESTOCK_RECORDS, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_allattenyesztes'),
            },
            {
                eventKey: getEventKey(MODULES.LIVESTOCK_DAILY_TASKS.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.livestockDailyTasks',
                translatorDefault: 'Daily tasks',
                icon: '/styles/img/menu/dailytasks',
                visible: checkMenuIsVisible(MODULES.LIVESTOCK_DAILY_TASKS, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_allattenyesztes_napi_feladatok'),
            },
            {
                eventKey: getEventKey(MODULES.LIVESTOCK_FEEDING.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.livestockFeeding',
                translatorDefault: 'Feeding',
                icon: '/styles/img/menu/feeding',
                visible: checkMenuIsVisible(MODULES.LIVESTOCK_FEEDING, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_allattenyesztes_takarmanyozas'),
            },
            {
                eventKey: getEventKey(MODULES.CULTIVATION_PERIODS.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.periods',
                translatorDefault: 'Cultivation periods',
                icon: '/styles/img/menu/termesztesi-idoszakok',
                visible: (checkMenuIsVisible(MODULES.CULTIVATION_PERIODS, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_termesztesi_idoszak')) || consultant,
            },
            {
                eventKey: getEventKey(MODULES.TEST.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.test',
                translatorDefault: 'Test',
                icon: '/styles/img/menu/kiserlet',
                visible: checkMenuIsVisible(MODULES.TEST, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_kiserlet'),
            },
            {
                eventKey: getEventKey(MODULES.LAND_ADMINISTRATION.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.landAdministration',
                translatorDefault: 'Land administration',
                icon: '/styles/img/menu/foldugy',
                visible: checkMenuIsVisible(MODULES.LAND_ADMINISTRATION, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_foldugy'),
            },
            {
                eventKey: getEventKey(MODULES.OFFICIAL_REPORTS.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.officialNotifications',
                translatorDefault: 'Official notifications',
                icon: '/styles/img/menu/hivatalos-bejelentesek',
                visible: checkMenuIsVisible(MODULES.OFFICIAL_REPORTS, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_hivatalos_bejelentesek'),
            },
            {
                eventKey: getEventKey(MODULES.FINANCIAL_FLOWS.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.invoiceTransfer',
                translatorDefault: 'Invoice-Transfer',
                icon: '/styles/img/menu/penzmozgasok',
                visible: checkMenuIsVisible(MODULES.FINANCIAL_FLOWS, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_penzmozgasok'),
            },
            {
                eventKey: getEventKey(MODULES.STOCK.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.stockInventory',
                translatorDefault: 'Stock-Inventory',
                icon: '/styles/img/menu/keszlet',
                visible: checkMenuIsVisible(MODULES.STOCK, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_keszlet'),
            },
            {
                eventKey: getEventKey(MODULES.SCALE.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.scale',
                translatorDefault: 'Scale',
                icon: '/styles/img/menu/merleg',
                visible: checkMenuIsVisible(MODULES.SCALE, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_merleg'),
            },
            {
                eventKey: getEventKey(MODULES.CROP_MANAGEMENT.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.cropManagement',
                translatorDefault: 'Crop management',
                icon: '/styles/img/menu/termenykezeles',
                visible: checkMenuIsVisible(MODULES.CROP_MANAGEMENT, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_termenykezeles'),
            },
            {
                eventKey: getEventKey(MODULES.PRODUCTION.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.production',
                translatorDefault: 'Production',
                icon: '/styles/img/menu/production',
                visible: checkMenuIsVisible(MODULES.PRODUCTION, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode) && hasPhpPermission('menu_gyartas'),
            },
            {
                eventKey: getEventKey(MODULES.EMPLOYMENT.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.employment',
                translatorDefault: 'Employment',
                icon: '/styles/img/menu/foglalkoztatas',
                visible: checkMenuIsVisible(MODULES.EMPLOYMENT, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_foglalkoztatas'),
            },
            {
                eventKey: getEventKey(MODULES.EQUIPMENT.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.equipment',
                translatorDefault: 'Equipment',
                icon: '/styles/img/menu/eszkozok',
                visible: checkMenuIsVisible(MODULES.EQUIPMENT, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_eszkozok'),
            },
            {
                eventKey: getEventKey(MODULES.LABOR.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.labor',
                translatorDefault: 'Labour',
                icon: '/styles/img/menu/munkaero',
                visible: checkMenuIsVisible(MODULES.LABOR, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_munkaero'),
            },
            {
                eventKey: getEventKey(MODULES.CONTROLLING.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.controlling',
                translatorDefault: 'Controlling',
                icon: '/styles/img/menu/kontrolling',
                visible: checkMenuIsVisible(MODULES.CONTROLLING, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_kontrolling'),
            },
            {
                eventKey: getEventKey(MODULES.REPORTS.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.reports',
                translatorDefault: 'Reports',
                icon: '/styles/img/menu/kimutatasok',
                visible: checkMenuIsVisible(MODULES.REPORTS, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_kimutatas'),
            },
            {
                eventKey: getEventKey(MODULES.DATA_ANALYSIS.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.dataAnalysis',
                translatorDefault: 'Farm analysis',
                icon: '/styles/img/menu/adatelemzes',
                visible: checkMenuIsVisible(MODULES.DATA_ANALYSIS, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_adatelemzes'),
            },
            {
                eventKey: getEventKey(MODULES.MAP.CONTENTS.MAP),
                translatorKey: 'sideMenuTitle.map',
                translatorDefault: 'Map',
                icon: '/styles/img/menu/terkep',
                visible: checkMenuIsVisible(MODULES.MAP, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_terkep'),
            },
            {
                eventKey: 'subscription',
                translatorKey: 'sideMenuTitle.newSolutions',
                translatorDefault: 'Premium services',
                visible: checkPremiumServicesMenuIsVisible(),
                isSeparatedMenu: true,
                iconId: 'icon-new',
                hasSeparatedBackground: true,
            },
        /*  {
                eventKey: 'newInterfaces',
                translatorKey: 'sideMenuTitle.newInterfaces',
                translatorDefault: 'New interfaces',
                iconId: 'icon-new',
                visible: !useGlobalLite, 
                isNewMenu: true,
                newTabUrl: DEFAULT_SYSTEM_DATA.NEW_INTERFACES_URL,
            }, */
        ];
    } else { //planning mode route list
        routeList = [
            {
                planningModuleKey: PLANNING.MODULE.CROP_ROTATION,
                eventKey: MODULES.PLANNING.CONTENTS.CROP_ROTATION_INDEX.EVENT_KEY,
                translatorKey: 'sideMenuTitle.cropRotation',
                translatorDefault: 'Crop rotation',
                icon: '/styles/img/menu/crop_rotation',
                visible: checkMenuIsVisible(MODULES.PLANNING, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_vetesforgo'),
            },
            {
                planningModuleKey: PLANNING.MODULE.TECHNOLOGIES,
                eventKey: MODULES.PLANNING.CONTENTS.TECHNOLOGIES_INDEX.EVENT_KEY,
                translatorKey: 'sideMenuTitle.productionTechnology',
                translatorDefault: 'Cultivation technology',
                icon: '/styles/img/menu/cultivation_technology',
                visible: checkMenuIsVisible(MODULES.PLANNING, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_technologia'),
            },
            {
                planningModuleKey: PLANNING.MODULE.STOCK,
                eventKey: getEventKey(MODULES.STOCK.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.stockInventory',
                translatorDefault: 'Stock-Inventory',
                icon: '/styles/img/menu/keszlet',
                visible: checkMenuIsVisible(MODULES.STOCK, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_keszlet'),
            },
            {
                planningModuleKey: PLANNING.MODULE.EQUIPMENT,
                eventKey: getEventKey(MODULES.EQUIPMENT.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.equipment',
                translatorDefault: 'Equipment',
                icon: '/styles/img/menu/eszkozok',
                visible: checkMenuIsVisible(MODULES.EQUIPMENT, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_eszkozok'),
            },
            {
                planningModuleKey: PLANNING.MODULE.LABOR,
                eventKey: getEventKey(MODULES.LABOR.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.labor',
                translatorDefault: 'Labour',
                icon: '/styles/img/menu/munkaero',
                visible: checkMenuIsVisible(MODULES.LABOR, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_munkaero'),
            },
            {
                planningModuleKey: PLANNING.MODULE.CONTROLLING,
                eventKey: getEventKey(MODULES.CONTROLLING.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.controlling',
                translatorDefault: 'Controlling',
                icon: '/styles/img/menu/kontrolling',
                visible: checkMenuIsVisible(MODULES.CONTROLLING, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_kontrolling'),
            },
            {
                planningModuleKey: PLANNING.MODULE.PRICE,
                eventKey: MODULES.PLANNING.CONTENTS.PRICE_INDEX.EVENT_KEY,
                translatorKey: 'sideMenuTitle.feesAndClearingPrices',
                translatorDefault: 'Fees, clearing prices',
                icon: '/styles/img/menu/clearing_price',
                visible: checkMenuIsVisible(MODULES.PLANNING, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_dijak'),
            },
            {
                planningModuleKey: PLANNING.MODULE.REPORTS,
                eventKey: getEventKey(MODULES.REPORTS.CONTENTS.INDEX),
                translatorKey: 'sideMenuTitle.reports',
                translatorDefault: 'Reports',
                icon: '/styles/img/menu/kimutatasok',
                visible: checkMenuIsVisible(MODULES.REPORTS, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_kimutatas'),
            },
            {
                planningModuleKey: PLANNING.MODULE.MAP,
                eventKey: getEventKey(MODULES.MAP.CONTENTS.MAP),
                translatorKey: 'sideMenuTitle.map',
                translatorDefault: 'Map',
                icon: '/styles/img/menu/terkep',
                visible: checkMenuIsVisible(MODULES.MAP, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode) && hasPhpPermission('menu_terkep'),
            },   
        ];
    }

    return routeList;
}
