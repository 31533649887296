import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useFormContext } from 'react-hook-form';
import useTranslate from '@i18n/useTranslate';
import Label from '@baseComponents/formParts/Label';
import CompanyDropdown from '@customComponents/dropdowns/companyDropdown/CompanyDropdown';
import { UnitInput } from '@baseComponents/inputs/inputField';
import { FormDatePicker } from '@customComponents/datePickers';
import { useDispatch, useSelector } from 'react-redux';
import { simpleTableActions } from '@states/actions';
import clsx from 'clsx';
import DataInput from '@baseComponents/inputs/DataInput';
import { useNumberFormat } from '@common_modules/numberFormat';
import Checkbox from '@baseComponents/controls/Checkbox';
import { SimpleSelect } from '@baseComponents/select';
import SoilAnalysisManualTable from './manualImport/SoilAnalysisManualTable';
import SoilAnalysisExcelImport from './excelImport/SoilAnalysisExcelImport';
import { useLoadSoilAnalysisImportData } from './useLoadSoilAnalysisImportData';
import { isNil } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    bodyContainer: {
        width: '100%',
        minWidth: 1020,
        height: 'calc(100vh - 205px)',
        position: 'relative',
        backgroundColor: theme.color.lilian,
        borderBottomRightRadius: 12,
        display: 'flex',
        flexFlow: 'column',
        overflowY: 'hidden',
        padding: 30,
    },
    bodyHeaderContainer: {
        marginBottom: 15,
        display: 'flex',
        alignItems: 'start',
    },
    bodyHeaderElements: {
        // marginRight: 20,
    },
    label: {
        fontSize: 12,
        marginRight: 12,
        marginBottom: 0,
    },
    inputContainers: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: 200,
        '& > *:last-child': {
            marginRight: 20,
        },
    },
    registryNumberInput: {
        width: 200,
    },
    companySelect: {
        width: 200,
    },
    addNewRow: {
        height: 56,
        marginTop: 5,
        '& button:hover': {
            color: theme.color.main,
            '& svg': {
                fill: theme.color.main,
            },
        },
    },
    colRow: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 12,
            width: '100%',
        },
    },
    isExtendedExaminationInput: {
        marginBottom: 0,
        marginTop: 10,
        '& span': {
            marginLeft: 0,
            textTransform: 'uppercase',
            fontSize: 12,
            fontWeight: 500,
        },
    },
}));

export const SOIL_ANALYSIS_TABLE_ID = 'soilAnalysisTable';

export default function SoilAnalysisImportBody(props) {
    const { isExcelImport, cultivationPeriodId } = props;
    const cultivationPeriodList = useSelector((state: any) => state.basePage.table.tableData);

    const { t } = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { watch, setValue, getValues } = useFormContext();

    const { laboratories } = useLoadSoilAnalysisImportData();

    const { numberFormatter } = useNumberFormat();

    function onChangeCompany(companyId) {
        setValue('companyId', companyId);
    }

    function onClickAddRow() {
        const cultivationPeriod = cultivationPeriodList.find(period => period.id === cultivationPeriodId);
        setValue('isLoading', true);
        const tableData = getValues('soilAnalysisData') || [];
        const rowId = getValues('rowId') + 1;
        setValue('rowId', rowId);

        const newRow = {
            rowId,
            id: rowId,
        };

        if (!isNil(cultivationPeriod)) {
            newRow.cultivationPeriod = { id: cultivationPeriod.id, name: cultivationPeriod.name, companyName: cultivationPeriod.companyName };
            newRow.cultivationPeriodCompanyName = cultivationPeriod.companyName;
        }
        setValue('soilAnalysisData', [...tableData, newRow]);

        dispatch(simpleTableActions.addRowToTableData(SOIL_ANALYSIS_TABLE_ID, newRow));
    }

    return (
        <div className={classes.bodyContainer}>
            <div className={classes.bodyHeaderContainer}>
                <div className={classes.colRow}>
                    <div className={classes.inputContainers}>
                        <Label
                            label={t('soilAnalysisImport.samplingDate', 'Sampling date')}
                            className={classes.label}
                            required
                            isValue={watch('date')}
                        />
                        <FormDatePicker
                            className={classes.bodyHeaderElements}
                            date={getValues('date')}
                            setDate={value => setValue('date', value)}
                            label=""
                        />
                    </div>
                    <div className={classes.inputContainers}>
                        <Label
                            label={t('soilAnalysisImport.validityDate', 'Validity date')}
                            className={classes.label}
                            required
                            isValue={watch('validityDate')}
                        />
                        <FormDatePicker
                            className={classes.bodyHeaderElements}
                            date={getValues('validityDate')}
                            setDate={value => setValue('validityDate', value)}
                            label=""
                        />
                    </div>
                </div>
                <div className={classes.colRow}>
                    <div className={classes.inputContainers}>
                        <Label
                            label={t('soilAnalysisImport.registryNumber', 'Registry number')}
                            className={classes.label}
                            required
                            isValue={watch('registryNumber')}
                        />
                        <UnitInput
                            type="text"
                            className={clsx(classes.bodyHeaderElements, classes.registryNumberInut)}
                            defaultValue={getValues('registryNumber')}
                            onBlur={(_event, value) => setValue('registryNumber', value)}
                        />
                    </div>
                    <div className={classes.inputContainers}>
                        <Label
                            label={t('soilAnalysisImport.samplingArea', 'Size of sampling area')}
                            className={classes.label}
                            required
                            isValue={watch('samplingArea')}
                        />
                        <UnitInput
                            type="number"
                            numberFormat={numberFormatter.getAreaFormats()}
                            className={clsx(classes.bodyHeaderElements, classes.registryNumberInput)}
                            defaultValue={getValues('samplingArea')}
                            onBlur={(_event, value) => setValue('samplingArea', value)}
                        />
                    </div>
                </div>
                <div className={classes.colRow}>
                    <div className={classes.inputContainers}>
                        <Label
                            label={t('soilAnalysisImport.laboratory', 'Accredited laboratory')}
                            className={classes.label}
                            required
                            isValue={watch('laboratoryId')}
                        />
                        <SimpleSelect
                            dataList={laboratories}
                            className={clsx(classes.bodyHeaderElements, classes.registryNumberInput)}
                            onChange={(laboratoryId: number) => {
                                setValue('laboratoryId', +laboratoryId);
                            }}
                        />
                    </div>

                    <div className={classes.isExtendedExaminationInput}>
                        <Checkbox
                            text={t('soilAnalysisImport.isExtendedExamination', 'Extended examination')}
                            checked={watch('isExtendedExamination')}
                            onChange={value => setValue('isExtendedExamination', value)}
                        />
                    </div>
                </div>

                {!!isExcelImport
                        && (
                            <div className={classes.colRow}>
                                <div className={classes.inputContainers}>
                                    <Label
                                        label={t('soilAnalysisImport.interfaceCompany', 'Interface company')}
                                        className={classes.label}
                                    />
                                    <CompanyDropdown
                                        companyId={watch('companyId')}
                                        setCompanyId={onChangeCompany}
                                        className={clsx(classes.bodyHeaderElements, classes.companySelect)}
                                    />
                                </div>
                            </div>
                        )}
            </div>
            {
                isExcelImport
                    ? (
                        <SoilAnalysisExcelImport
                            cultivationPeriodId={cultivationPeriodId}
                            tableId={SOIL_ANALYSIS_TABLE_ID}
                        />
                    )
                    : (
                        <SoilAnalysisManualTable
                            cultivationPeriodId={cultivationPeriodId}
                            tableId={SOIL_ANALYSIS_TABLE_ID}
                        />
                    )
            }
            {!isExcelImport
                && (
                    <DataInput
                        type="dashed"
                        title={t('default.addNewRow', 'Add new row')}
                        className={classes.addNewRow}
                        onClick={onClickAddRow}
                    />
                )}
        </div>
    );
}
