import useTranslate from '@i18n/useTranslate';
import clsx from 'clsx';
import { MachineDataResponse, ThemeType, TranslateType } from '@map/services/mapTypes';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { createUseStyles } from 'react-jss';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import MachinePanelHeader from './MachinPanleHeader';
import 'react-circular-progressbar/dist/styles.css';
import { useMachinePanelService } from './MachinePanelService';

type Classes = 'main' | 'title' | 'subtitle' | 'content' | 'gadget' | 'gadgetText'
    | 'titleText' | 'operationHoursText' | 'progressText' |
    'progressChildrenText' | 'mainHeader' | 'closeButton' | 'progressContainer' |
    'progressChildLine' | 'gadgetTextContainer' | 'gatgetTextLine' | 'footer' | 'footerText' | 'warningText' | 'boldText' | 'okText' | 'gadgetInner';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    mainHeader: {
        width: 360,
        height: 'auto',
        right: 61,
        marginTop: 25,
        position: 'absolute',
        zIndex: 3,
        backgroundColor: 'white',
        fontFamily: 'Rubik',
        borderRadius: 5,
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 12,
        display: 'block',
        boxSizing: 'border-box',
        width: 30,
        height: 30,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: theme.color.pearl,
        borderRadius: '100%',
        background: '-webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, #0B0D0F 46%, #0B0D0F 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, #0B0D0F 46%, #0B0D0F 56%,transparent 56%, transparent 100%)',
        backgroundColor: theme.color.pearl,
        transition: 'all 0.3s ease',
        cursor: 'pointer',
    },
    main: {
        backgroundColor: theme.color.lilian,
        paddingTop: 15,
        borderRadius: '0 0 5px 5px',
        borderTop: '1px solid',
        borderTopColor: theme.color.pearl,
    },
    title: {
        height: 20,
        display: 'flex',
        justifyContent: 'space-between',
    },
    subtitle: {
        marginLeft: 15,
        marginRight: 15,
    },
    content: {
        margin: [8, 0],
        display: 'flex',
        justifyContent: 'space-evenly',
    },
    gadget: {
        width: 100,
        margin: 5,
        textAlign: 'center',
    },
    gadgetText: {
        fontSize: 12,
        color: theme.color.raven,
    },
    titleText: {
        fontSize: 14,
        color: theme.color.jet,
        fontWeight: 500,
    },
    timeText: {
        fontSize: 12,
        color: theme.color.jet,
        fontWeight: 'normal',
    },
    operationHoursText: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
        fontSize: 14,
        fontWeight: 500,
    },
    progressText: {
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 2,
    },
    progressChildrenText: {
        fontSize: 12,
        fontWeight: 'normal',
        color: theme.color.raven,
    },
    progressContainer: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    progressChildLine: {
        width: 70,
        margin: [2, 0],
        borderColor: theme.color.raven,
    },
    gadgetTextContainer: {
        width: 100,
        height: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    gatgetTextLine: {
        width: 40,
        margin: [-3, 0, -2, 0],
        borderColor: theme.color.raven,
    },
    footer: {
        display: 'flex',
        justifyContent: 'flex-start',
        width: '100%',
        paddingLeft: 2,
    },
    footerText: {
        fontSize: 10,
        textAlign: 'left',
        color: theme.color.raven,
    },
    warningText: {
        color: theme.color.status_error,
    },
    okText: {
        color: theme.color.main,
    },
    boldText: {
        fontWeight: 'bold',
    },
    gadgetInner: {
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
}));

const progressBarStyleInput = {
    textSize: '16px',
    textColor: '#21282C',
    pathColor: '#FF9500',
};

type TProps = {
    close: () => void,
    machineData: MachineDataResponse;
}

function IrrigationPanelComponent({
    close,
    machineData,
}: TProps) {
    const classes = useStyles();

    const { t, translater }: TranslateType = useTranslate();

    const { workingStatus, lasdDataRefresh, flow, pressure, irrigationSpeed, irrigatedWater, remainingRevolution } = useMachinePanelService(machineData);

    return (
        <div className={classes.mainHeader}>
            <span
                className={classes.closeButton}
                onClick={() => close()}
                onKeyDown={() => close()}
                role="button"
                aria-label="Close"
                aria-hidden="true"
            />
            <MachinePanelHeader name={machineData.name} companyName={machineData.companyName} idNum={machineData.idNumber} iconUrl={machineData.profilePicture} />
            <div className={classes.main}>
                <div className={classes.title}>
                    <div className={classes.subtitle}>
                        <span className={classes.titleText}>{t('map.machinePanelStatus', 'Status')}</span>
                    </div>
                    <div className={classes.subtitle}>
                        <span className={clsx([classes.gadgetText, classes.boldText], { [classes.warningText]: ![0, 1].includes(workingStatus.value ?? 0) }, { [classes.okText]: [0, 1].includes(workingStatus.value ?? -1) })}>{workingStatus.text}</span>
                    </div>
                </div>
                <div className={classes.title}>
                    <div className={classes.subtitle}>
                        <span className={classes.gadgetText}>{t('map.machinePanelRemainingRevolution', 'Remaining revolution')}</span>
                    </div>
                    <div className={classes.subtitle}>
                        <span className={classes.gadgetText}>{remainingRevolution.hours === 0 && remainingRevolution.minutes === 0 ? '-' : `${remainingRevolution.hours} h, ${remainingRevolution.minutes} m`}</span>
                    </div>
                </div>
                <div className={classes.content}>
                    <div className={classes.gadget}>
                        <CircularProgressbarWithChildren value={irrigationSpeed?.value ? irrigationSpeed.value : 0} maxValue={100} strokeWidth={6} styles={buildStyles(progressBarStyleInput)}>
                            <div className={classes.progressContainer}>
                                <div className={classes.progressText}>
                                    <span>{irrigationSpeed.text}</span>
                                </div>
                                <hr className={classes.progressChildLine} />
                                <div className={classes.progressChildrenText}>
                                    <span>{irrigatedWater.text}</span>
                                </div>
                            </div>
                        </CircularProgressbarWithChildren>
                        <div className={classes.gadgetTextContainer}>
                            <span className={classes.gadgetText}>{t('map.machinePanelSpeed', 'Speed')}</span>
                            <hr className={classes.gatgetTextLine} />
                            <span className={classes.gadgetText}>{t('map.machinePanelDepth', 'Depth')}</span>
                        </div>
                    </div>
                    <div className={classes.gadget}>
                        {/* <CircularProgressbarWithChildren value={0} maxValue={100} strokeWidth={6} styles={buildStyles(progressBarStyleInput)}> */}
                        <div className={classes.gadgetInner}>
                            <div className={classes.progressText}>
                                <span>{pressure?.value ? pressure.value : pressure.text}</span>
                            </div>
                            <div className={classes.progressChildrenText}>
                                <span>bar</span>
                            </div>
                        </div>
                        {/* </CircularProgressbarWithChildren> */}
                        <div className={classes.gadgetTextContainer}>
                            <span className={classes.gadgetText}>{t('map.machinePanelPressure', 'Pressure')} </span>
                        </div>
                    </div>
                    <div className={classes.gadget}>
                        {/* <CircularProgressbarWithChildren value={0} maxValue={100} strokeWidth={6} styles={buildStyles(progressBarStyleInput)}> */}
                        <div className={classes.gadgetInner}>

                            <div className={classes.progressText}>
                                <span>{flow.value ? flow.value : flow.text}</span>
                            </div>
                            <div className={classes.progressChildrenText}>
                                <span>m<sup>3</sup>/h</span>
                            </div>
                        </div>
                        {/* </CircularProgressbarWithChildren> */}
                        <div className={classes.gadgetTextContainer}>
                            <span className={classes.gadgetText}>{t('map.machinePanelFlow', 'Flow')}</span>
                        </div>
                    </div>
                </div>
                <div className={classes.footer}>
                    <span className={classes.footerText}>{`${translater('map.machinPanelUpdateTime', 'Last data refresh')}: ${lasdDataRefresh.text}`}</span>
                </div>
            </div>
        </div>
    );
}

export default IrrigationPanelComponent;
