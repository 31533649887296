/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { errorActions } from '@states/actions';
import { createUseStyles } from 'react-jss';
import { userService } from '@services';
import useTranslate from '@i18n/useTranslate';
import Input from '@baseComponents/inputs/Input';

const useStyles = createUseStyles({
    inputContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    input: {
        width: 42,
        padding: [0, 0],
        '& div': {
            padding: [0, 10],
        },
        '& input': {
            padding: 0,
            textAlign: 'center',
        },
    },
    messageContainer: {
        color: (props: any) => (props.verifiedCode ? 'green' : 'red'),
        marginTop: 5,
        minHeight: 25,
        '& p': {
            margin: 0,
        },
    },
});

const TwoFactorInput = (props: any) => {
    const { userId, verifiedCode, setVerifiedCode } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const [userToken, setUserToken] = useState('');
    const [validationSuccess, setValidationSuccess] = useState(false);
    const { t } = useTranslate();
    const classes = useStyles({ verifiedCode });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputRefs = Array.from({ length: 6 }, () => useRef<any>(null));

    useEffect(() => {
        if (userToken.length === 6) {
            isValidTwoFactorAuthCode();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userToken]);

    const isValidTwoFactorAuthCode = async () => {
        try {
            setValidationSuccess(false);
            const verifyTwoFactorAuthCode = await userService.isValidTwoFactorAuthCode(userId, userToken);
            setVerifiedCode(verifyTwoFactorAuthCode);
            setValidationSuccess(true);
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    };

    const handleInputChange = (index, value) => {
        setUserToken(prev => {
            const isLastChar = value.length === 1 && value === prev.charAt(index);
            const updatedToken = prev.split('');
            updatedToken[index] = isLastChar ? '' : value.charAt(value.length - 1);
            return updatedToken.join('');
        });

        if (value !== '' && index > 0 && value === userToken.charAt(index)) {
            inputRefs[index - 1].current.focus();
        } else if (value !== '' && index < 5) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleKeyDown = (index, event) => {
        if (event.key === 'Backspace' && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    return (
        <>
            <div>
                <p>{t('2fa.addCode', 'Please provide the six-digit code generated by the application')}</p>
            </div>
            <div className={classes.inputContainer}>
                {Array.from({ length: 6 }, (_, index) => (
                    <Input
                        key={index}
                        className={classes.input}
                        value={userToken[index] || ''}
                        onChange={event => handleInputChange(index, event.target.value)}
                        onKeyDown={event => handleKeyDown(index, event)}
                        onFocus={event => event.target.select()}
                        name={`verifyCode${index}`}
                        //type="number"
                        label=""
                        ref={inputRefs[index]}
                        maxLength="1"
                    />
                ))}
            </div>
            <div className={classes.messageContainer}>
                {userToken && userToken.length === 6 && validationSuccess && (
                    <p>{verifiedCode ? t('2fa.verificationSuccess', 'The verification was successful!') : t('2fa.verificationFail', 'The provided code is incorrect!')}</p>
                )}
            </div>
        </>
    );
};

export default TwoFactorInput;
