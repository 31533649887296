
export const METEOROLOGY = {
    MODULE_ID: 39,
    NAME: 'meteorology',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: {
                index: 1,
            },
            COMPONENT_URL: 'contents/meteorology/menu/Meteorology',
            EVENT_KEY: 'meteorologyIndex',
            TITLE: {
                TRANSLATOR_KEY: 'meteorologyIndex.meteorology',
                TRANSLATOR_DEFAULT: 'Meteorology',
            },
        },
        METEOROLOGICAL_DATA: {
            CONTENT_URL_ID: {
                meteorologicalData: 2,
            },
            COMPONENT_URL: 'contents/meteorology/meteorologyManagement/MeteorologyManagement',
            EVENT_KEY: 'meteorologicalData',
            TITLE: {
                TRANSLATOR_KEY: 'meteorologyIndex.meteorologyData',
                TRANSLATOR_DEFAULT: 'Meteorology data',
            },
        },
    },
};
