import { ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';

type Classes = 'container';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 10px',
        width: 124,
        height: 20,
        backgroundColor: theme.color.jet,
        borderRadius: 4,
        gap: 5,
        color: theme.color.white,
        fontSize: 12,
    },
}));

type TProps = {
    coordinates?: google.maps.LatLngLiteral
}

export default function CursorCoordinatesComponent({ coordinates }: TProps) {
    const classes = useStyles();

    if (!coordinates) {
        return null;
    }

    return (
        <div className={classes.container}>
            <span>{coordinates.lat.toFixed(5)}</span>
            <span>{coordinates.lng.toFixed(5)}</span>
        </div>
    );
}
