/* eslint-disable no-magic-numbers */
import moment from 'moment';
import 'moment/min/locales';
import { stringUtils } from './stringUtils';
import { DATE } from '../constants';
import {
    isNull, isUndefined, lowerCase, isEmpty, isFinite, isString, isNumber, isObject 
} from 'lodash';

moment.locale('hu');
moment.suppressDeprecationWarnings = true;

export const dateTimeUtils = {
    DateType: moment.Moment,
    setMomentLocale,
    getLocale: moment.locales,
    getDateList,

    //utils function
    getDateMask,
    isValidDate,
    isAfterDate,
    isZeroDuration,
    isEqual,
    getDateDifferenceInDay,
    timeAddMinutes,
    getTimeDifferenceInMinutes,
    getFormattedDurationFromHours,
    getFormattedDurationFromSeconds,
    getDurationAsHoursFromFormattedTime,
    getDurationAsSecondsFromFormattedTime,
    getTimeInMinutes,
    getFirstDayOfYear,
    getFirstDayOfMonth,
    getLastDayOfYear,
    getLastWeek,
    getLastMonth,
    getLastTwoWeeks,
    getLastTwoWeeksObject,
    getLastQuarterYear,
    getLastHalfYear,
    getActualMonth,
    getNextMonth,
    getMonthAndDay,
    convertTimeToHoursDecimal,
    convertTimeToHoursDecimalIfNeeded,
    convertTimeToSecondsDecimal,
    convertTimeToSecondsDecimalIfNeeded,
    calculateFromSecondsOrParseTimeToHoursDecimalIfNeeded,
    convertSecondsToHours,
    convertHoursToSeconds,
    calculateWorkshiftTimesFromStartAndEndTimes,
    getAllDaysOfMonthWithLabels,
    getFirstDateOfMonth,
    getLastDateOfMonth,
    getFirstAndLastDatesOfMonth,
    isWeekDay,
    isWeekendDay,

    //formats
    getMomentObjectFromDateString,
    getMoment,
    getDateObject,
    getDateObjectFromHourMin,
    getDate,
    getDateTime,
    getDateTimeFormat,
    getDefaultDateFormat,
    getJSDate,
    getDefaultDateTimeFormat,
    dateAddYears,
    dateAddMonths,
    dateAddWeeks,
    dateAddDays,
    getDateYear,
    getDateDay,
    getDateDayName,
    getDateMonthShortName,
    getDateMonthDay,
    getDateObjectByValues,
    getTime,
    getTimeFormat,
    getDateFormat,
    getUTCFormat,
    getDateTimestamp,

    //date now
    getDateObjectNow,
    getDateTimeObjectNow,
    getDateNow,
    getDefaultDateNow,
    getDefaultDateTimeNow,
    dateNowAddDays,
    getTimestampNow,
    getDateTimeNow,
    getTimeNow,
    getDateYearNow,
    getDateTimeNowAddDays,

    //egyedi formátum, amelyeket a térkép használ
    getLongDateFormat,
    getCustomDateFormat,
    getMomentObjectWithDate,
};

function getLangCodeFormat(lang) {
    let langCode = lowerCase(lang);

    if (stringUtils.isSame(langCode, 'ar')) {
        langCode = 'es';
    }

    return langCode;
}

function setMomentLocale(lang) {
    const langCode = getLangCodeFormat(lang);
    moment.locale(langCode);
}

function getDateList(start, end, periodsSelectList) {
    return [
        {
            value: getDefaultDateFormat(getLastTwoWeeks(end)),
            text: periodsSelectList.TWO_WEEKS,
        },
        {
            value: getDefaultDateFormat(getActualMonth()),
            text: periodsSelectList.ACTUAL_MONTH,
        },
        {
            value: getDefaultDateFormat(start),
            text: periodsSelectList.CURRENT_CULTIVATION_PERIOD,
        },
        {
            value: getDefaultDateFormat(getLastWeek(end)),
            text: periodsSelectList.A_WEEK,
        },
        {
            value: getDefaultDateFormat(getLastMonth(end)),
            text: periodsSelectList.A_MONTH,
        },
        {
            value: getDefaultDateFormat(getLastQuarterYear(end)),
            text: periodsSelectList.A_QUARTER_YEAR,
        },
        {
            value: getDefaultDateFormat(getLastHalfYear(end)),
            text: periodsSelectList.A_HALF_YEAR,
        },
    ];
}

//utils functions
function isDefaultDate(date) {
    if (date && typeof date === "string") {
        const dateParts = date.split('-');
        if (dateParts.length === 3) {
            return dateParts[0].length === 4 && dateParts[1].length === 2;
        }
    }
    return false;
}


function getDateMask() {
    return getDateNow().replace(new RegExp('[0-9]', 'g'), '9');
}

function isValidDate(date) {
    if (isDefaultDate(date)) {
        const dateObject = moment(date, 'YYYY-MM-DD');
        return dateObject.isValid();
    }
    if (date && typeof date === "string") {
        return date.includes('_') ? false : getMomentObjectFromDateString(date).isValid();
    }

    return (typeof date === "object" && moment(date).isValid());
}

function isAfterDate(date, date2) { //date > date2 => return true
    const dateFormatted = getDateObject(date);
    const afterDateFormatted = getDateObject(date2);

    if (!dateFormatted) {
        return false;
    } else if (!afterDateFormatted) {
        return true;
    }

    return dateFormatted.valueOf() > afterDateFormatted.valueOf();
}

function isZeroDuration(date) {
    const duration = moment.duration(date);
    if (!moment.isDuration(duration)) {
        return true;
    }
    return duration.asSeconds() === 0;
}

function isEqual(date1, date2) {
    if (!date1 || !date2) {
        return false;
    }

    const date1Formatted = moment(date1).format('YYYY-MM-DD');
    const date2Formatted = moment(date2).format('YYYY-MM-DD');

    return date1Formatted.valueOf() === date2Formatted.valueOf();
}

function getDateDifferenceInDay(date1, date2, isAbsDays) {
    const startDate = getDateObject(date1);
    const endDate = getDateObject(date2);

    if (!startDate || !endDate) {
        return null;
    }

    const days = moment.duration(endDate.diff(startDate)).asDays(); //endDate.diff(startDate, 'days')
    return isAbsDays
        ? Math.abs(parseInt(days, 10))
        : parseInt(days, 10);
}

function timeAddMinutes(time, minutes) {
    const timeFormatted = moment(time, 'LTS'); //h:m:s

    if (!timeFormatted || !minutes) {
        return null;
    }

    return timeFormatted.add(minutes, 'minutes');
}

function getTimeDifferenceInMinutes(time1, time2) {
    const time1Formatted = moment(time1, 'LTS'); //h:m:s
    const time2Formatted = moment(time2, 'LTS'); //h:m:s

    if (!time1Formatted || !time2Formatted) {
        return null;
    }

    const duration = moment.duration(time2Formatted.diff(time1Formatted));
    return duration.asMinutes();
}

function getFormattedDurationFromHours(hours) {  //8.35 => '08:21'
    return moment.utc(moment.duration(hours, 'h').asMilliseconds()).format('HH:mm');
}

function getFormattedDurationFromSeconds(seconds) {  //30600 => '08:21'
    return moment.utc(moment.duration(seconds, 's').asMilliseconds()).format('HH:mm');
}

function getDurationAsHoursFromFormattedTime(timeString) {  //'08:21' => 8.35
    return typeof timeString === 'string' && timeString.includes(':') ? moment.duration(timeString).asHours() : timeString;
}

function getDurationAsSecondsFromFormattedTime(timeString) {  //'08:21' => 30060
    return typeof timeString === 'string' && timeString.includes(':') ? moment.duration(timeString).asSeconds() : timeString;
}

function getTimeInMinutes(time) {
    const timeFormatted = moment(time, 'LTS');  //h:m:s

    return timeFormatted.asMinutes();
}

function getFirstDayOfYear(year) {
    return moment().year(parseInt(year, 10)).month(DATE.FIRST_MONTH_OF_YEAR).date(DATE.FIRST_DAY_OF_YEAR);
}

function getFirstDayOfMonth(date) {
    if (date) {
        const dateObject = dateTimeUtils.getDateObject(date);
        const year = dateObject.year();
        const monthIndex = dateObject.month();
        const month = monthIndex + 1;
    
        return moment(`${year}-${month}-01`);
    }

    return moment().startOf('month');
}

function getLastDayOfYear(year) {
    if (year === moment().year()) {
        return moment();
    } else {
        return moment().year(parseInt(year, 10)).month(DATE.LAST_MONTH_OF_YEAR).date(DATE.LAST_DAY_OF_YEAR);
    }
}

function getLastWeek(date) {
    const lastWeek = moment().year(date.year()).month(date.month()).date(date.date() - DATE.A_WEEK);
    return lastWeek;
}

function getLastMonth(date) {
    const lastMonth = moment().year(date.year()).month(date.month() - DATE.A_MONTH).date(date.date());
    return lastMonth;
}

function getLastTwoWeeks(date) {
    const lastTwoWeeks = moment().year(date.year()).month(date.month()).date(date.date() - DATE.TWO_WEEKS);
    return lastTwoWeeks;
}

function getLastTwoWeeksObject(date) {
    return moment().year(date.year()).month(date.month()).date(date.date() - DATE.TWO_WEEKS);
}

function getLastQuarterYear(date) {
    const lastQuarterYear = moment().year(date.year()).month(date.month() - DATE.A_QUARTER_YEAR).date(date.date());
    return lastQuarterYear;
}

function getLastHalfYear(date) {
    const lastHalfYear = moment().year(date.year()).month(date.month() - DATE.A_HALF_YEAR).date(date.date());
    return lastHalfYear;
}

function getActualMonth() {
    return moment().startOf('month');
}

function getNextMonth(date) {
    const nextMonth = moment().year(date.year()).month(date.month() + DATE.A_MONTH).date(date.date());
    return getDateFormat(nextMonth);
}

function convertTimeToHoursDecimal(time) {
    return moment.duration(time).asHours();
}

function convertTimeToHoursDecimalIfNeeded(time) {
    if (typeof time === "string" && time.includes(':')) {
        return convertTimeToHoursDecimal(time);
    }
    const hours = +time;
    if (isFinite(hours)) {
        return hours;
    }
    // eslint-disable-next-line no-console
    console.log('failed to parse time as Hours');
}

function convertTimeToSecondsDecimal(time) {
    return moment.duration(time, 's').asSeconds();
}

function convertTimeToSecondsDecimalIfNeeded(time) {
    if (isString(time) && time.includes(':')) {
        return convertTimeToSecondsDecimal(time);
    }
    const seconds = +time;
    if (isFinite(seconds)) {
        return seconds;
    }
    // eslint-disable-next-line no-console
    console.log('failed to parse time as seconds');
}

function convertSecondsToHours(seconds) {
    if (isNumber(seconds)) {
        return seconds / 3600
    }
    return null;
}

function convertHoursToSeconds(hours) {
    if (isNumber(hours)) {
        return hours * 3600
    }
    return null;
}

function calculateFromSecondsOrParseTimeToHoursDecimalIfNeeded(valueToParse) {
    if (isString(valueToParse) && valueToParse.includes(':')) {
        return convertTimeToHoursDecimal(valueToParse);
    }
    if (isNumber(valueToParse)) {
        return convertSecondsToHours(valueToParse)
    }
    return valueToParse;
}

//const workShifts = useSelector((state: any) => state?.dataLists?.workShiftList);
// returns array of seconds
function calculateWorkshiftTimesFromStartAndEndTimes(workShifts, companyId, startTime, endTime) {
    const startParsed = getDurationAsSecondsFromFormattedTime(startTime);
    const endParsed = getDurationAsSecondsFromFormattedTime(endTime);

    const breakDownTimeInterval = (start, end) => {
        const isStartedOnPrevDay = start > end;
        const prevDay = isStartedOnPrevDay
            ? { start, end: 86400 } // 24 * 60 * 60
            : null;
        const currentDay = isStartedOnPrevDay
            ? { start: 0, end }
            : { start, end };
        return isStartedOnPrevDay
            ? [prevDay, currentDay]
            : [currentDay];
    };

    const workIntervalParts = breakDownTimeInterval(startParsed, endParsed);
    const workShiftIntervalsValues = !isEmpty(workShifts) ? workShifts.map(
        shift => {
            const workShiftTime = shift.workShiftTime.find(
                wTime => +wTime.companyId === +companyId,
            );
            const { startDate, endDate } = isObject(workShiftTime)
                ? shift.workShiftTime.find(
                    wTime => +wTime.companyId === +companyId,
                )
                : {};
            return {
                shiftStart: startDate,
                shiftEnd: endDate === '00:00:00' || endDate === '00:00'
                    ? '23:59:59'
                    : endDate,
            };
        },
    ) : [];

    const workShiftIntervalsParsed = !isEmpty(workShiftIntervalsValues) ? workShiftIntervalsValues.map(
        wInterval => ({
            wsStart: moment.duration(wInterval.shiftStart).asSeconds(),
            wsEnd: moment.duration(wInterval.shiftEnd).asSeconds(),
        }),
    ) : [];

    const shiftIntervalsParts = !isEmpty(workShiftIntervalsValues) ? workShiftIntervalsParsed.map(
        wInterval => {
            const { wsStart, wsEnd } = wInterval;
            return (breakDownTimeInterval(wsStart, wsEnd));
        },
    ) : [];

    const getIntersection = (a, b) => {
        //get the range with the smaller starting point (min) and greater start (max)
        const min = (a.start < b.start ? a : b);
        const max = (min === a ? b : a);
        //min ends before max starts -> no intersection
        if (min.end < max.start) {
            return null; //the ranges don't intersect
        }
        return [max.start, (min.end < max.end ? min.end : max.end)];
    };

    const calcHoursForShiftPart = (shiftPart, workTimeParts, shiftPartCount) => {
        const hoursForShiftPart = workTimeParts.reduce(
            (acc, curr, idx) => {
                if (idx === 1 && shiftPartCount === 1) {
                    return acc;
                }
                const { start, end } = curr;
                const intersection = getIntersection({ start, end }, shiftPart);
                const hours = intersection ? intersection[1] - intersection[0] : 0;
                return acc + hours;
            },
            0,
        );
        return hoursForShiftPart;
    };

    const calculatedHoursForWorkhifts = shiftIntervalsParts.map(
        shiftInterval => shiftInterval.reduce(
            (intervalAcc, shiftPart) => intervalAcc + calcHoursForShiftPart(shiftPart, workIntervalParts, shiftInterval.length),
            0,
        ),
    );

    return calculatedHoursForWorkhifts;
}

function isWeekDay(date) {
    return getDateObject(date).isoWeekday() < 6;
}

function isWeekendDay(date) {
    return getDateObject(date).isoWeekday() > 5;
}

function getAllDaysOfMonthWithLabels(date, langCode) {
    dateTimeUtils.setMomentLocale(langCode);
    const dateObject = getDateObject(date);
    const numberOfDaysInMonth = dateObject.daysInMonth();
    const year = dateObject.year();
    const monthIndex = dateObject.month();
    const month = monthIndex + 1;
    const allDaysInMonthWithLabels = [...Array(numberOfDaysInMonth)].map(
        (_day, dayIndex) => {
            const day = dayIndex + 1;
            const currDate = moment(`${year}-${month}-${day}`);
            return {
                date: currDate,
                dateString: getDefaultDateFormat(currDate),
                label: currDate.format('dd'),
                day: currDate.format('DD'),
                isWeekDay: isWeekDay(currDate),
            };
        }
    );
    return allDaysInMonthWithLabels;
}

function getFirstDateOfMonth(date) {
    const dateObject = dateTimeUtils.getDateObject(date);
    const year = dateObject.year();
    const monthIndex = dateObject.month();
    const month = monthIndex + 1;
    return moment(`${year}-${month}-01`);
}

function getLastDateOfMonth(date) {
    if (date) {
        const dateObject = dateTimeUtils.getDateObject(date);
        const numberOfDaysInMonth = dateObject.daysInMonth();
        const year = dateObject.year();
        const monthIndex = dateObject.month();
        const month = monthIndex + 1;
    
        return moment(`${year}-${month}-${numberOfDaysInMonth}`);
    }
    
    return moment().endOf('month');
}

function getFirstAndLastDatesOfMonth(date) {
    return [
        getFirstDateOfMonth(date),
        getLastDateOfMonth(date),
    ];
}

//date formats
function getMomentObjectFromDateString(date) {
    return moment(date, 'L');
}

function getDateFormat(date) { //local format moment object
    if (!isValidDate(date)) {
        return null;
    }

    return typeof date === "object"
        ? (date._isAMomentObject ? date.format('L') : moment(date).format('L'))
        : null;
}

function getMoment() {
    return moment();
}

function getDateObject(date) {
    try {
        if (typeof date === "object") {
            if (moment.isMoment(date)) {
                return date;
            }

            return moment(date);
        }

        if (!isValidDate(date)) {
            return null;
        }

        return moment(date);
    } catch {
        return null;
    }
}

function getDateObjectFromHourMin(hourMinFormat) {
    return moment(hourMinFormat, 'HHmm');
}

function getDate(date) {
    if (!isValidDate(date)) {
        if (!date) {
            return '';
        }
        if (date && date === '-') {
            return '-';
        }
        return null;
    }

    let formattedDate = getDateObject(date);
    return getDateFormat(formattedDate);
}

function getDateTime(date) {

    if (typeof date === "number") {
        return moment(date).format('LLL');
    }

    if (!date) {
        return '';
    }
    if (date && date === '-') {
        return '-';
    }

    return getDateObject(date).format('LLL');
}

function getDateTimeFormat(date) {
    if (typeof date === "number") {
        return moment(date).format('L H:mm:ss');
    }

    if (!date) {
        return '';
    }
    if (date && date === '-') {
        return '-';
    }

    return getDateObject(date).format('L H:mm:ss');
}

function getDefaultDateFormat(date) {
    if (isUndefined(date) || isNull(date)) {
        return null;
    }

    const formattedDate = getDateObject(date);
    if (formattedDate) {
        return formattedDate.format('YYYY-MM-DD');
    }

    return formattedDate;
}

function getJSDate(date) {
    return moment(date).toDate()
}

function getDefaultDateTimeFormat(date, isEndOfDay) {
    if (!date) {
        return null;
    }

    const formattedDate = getDateObject(date);

    if (formattedDate) {
        if (isEndOfDay) {
            return formattedDate.format('YYYY-MM-DD 23:59:59');
        }
        return formattedDate.format('YYYY-MM-DD H:mm:ss');
    }

    return formattedDate;
}

function dateAddYears(date, years) {
    const formattedDate = getDateObject(date);

    if (formattedDate) {
        return getDateFormat(formattedDate.add(years, 'years'));
    }

    return formattedDate;
}

function dateAddMonths(date, months) {
    const formattedDate = getDateObject(date);

    if (formattedDate) {
        return getDateFormat(formattedDate.add(months, 'months'));
    }

    return formattedDate;
}

function dateAddWeeks(date, weeks) {
    const formattedDate = getDateObject(date);

    if (formattedDate) {
        return getDateFormat(formattedDate.add(weeks, 'weeks'));
    }

    return formattedDate;
}

function dateAddDays(date, days) {
    const formattedDate = getDateObject(date);

    if (formattedDate) {
        return getDateFormat(formattedDate.add(days, 'days'));
    }

    return formattedDate;
}

function getDateYear(date) {
    const formattedDate = getDateObject(date);

    if (formattedDate) {
        return formattedDate.format('YYYY');
    }

    return formattedDate;
}

function getDateDay(date) {
    return getDateObject(date).format('D');
}

function getDateDayName(date) {
    return getDateObject(date).format('dddd');
}

function getDateMonthShortName(date) {
    return getDateObject(date).format('MMM');
}

function getDateMonthDay(date) {
    return getDateObject(date).format('MMM D.');
}

function getDateObjectByValues(year, month, day) {
    return getDateObject(`${year}-${month}-${day}`);
}

function getTime(date) {
    const dateObject = getDateObject(date);
    return dateObject.format('H:mm');
}

function getTimeFormat(time) {
    const timeFormatted = moment(time, 'LTS');

    return timeFormatted.format('H:mm:ss');
}

function getMonthAndDay(date) {
    return getDateObject(date).format('MMM D.');
}

function getUTCFormat(date) {
    if (!isValidDate(date)) {
        return '';
    }

    return getDateObject(date).utc().valueOf();
}

//get date now
function getDateObjectNow() {
    const date = moment();
    return moment(date, 'L');
}

function getDateTimeObjectNow() {
    const date = moment();
    return moment(date, 'L H:mm:ss');
}

function getDateNow() {
    const date = getDateObjectNow();
    return getDateFormat(date);
}

function getDateTimeNow() {
    return getDateObjectNow().format('L H:mm:ss');
}

function getTimeNow() {
    return getDateObjectNow().format('HH:mm');
}

function getDefaultDateNow() {
    return getDateObjectNow().format('YYYY-MM-DD');
}

function getDefaultDateTimeNow() {
    return getDateObjectNow().format('YYYY-MM-DD H:mm:ss');
}

function dateNowAddDays(days) {
    const date = getDateObjectNow().add(days, 'days');
    return getDateFormat(date);
}

function getTimestampNow() {
    return getDateObjectNow().format('x');
}

function getDateYearNow() {
    return getDateObjectNow().format('YYYY');
}

function getDateTimeNowAddDays(days) {
    return getDateObjectNow().add(days, 'days').format('L H:mm:ss');
}

//egyedi formátum, amelyeket a térkép használ
function getLongDateFormat() {
    moment().localeData().longDateFormat('L')
}

function getCustomDateFormat(date) {
    return moment().format(date);
}

function getMomentObjectWithDate() {
    return moment(new Date());
}

function getDateTimestamp(date) {
    if (!date) {
        return null;
    }

    return date.format('x');
}
