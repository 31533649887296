import { isEmpty, isArray } from 'lodash';
import { getAPIEndpoint } from 'shared/src/routing';
import { sessionService } from '@services';
import { phpHelper } from '../php/PhpHelper';
import { routerHelper } from '../router/RouterHelper';

export const domHelper = {
    getComponentByKey,
    getComponentByName,
    getComponentByNameFromObject,
    getComponentsExceptByName,
    isButtonNode,
    getNodeParentByDepth,
    selectItemByClassName,
    signItemWithClassName,
    updateTooltipCoords,
    handleLinksCtrlClick,
};

function getComponentByKey(components, key) {
    let selectedComponent = components;
    if (!components) {
        return null;
    }
    if (Array.isArray(components)) {
        selectedComponent = components.find(component => component.key === key);
    } else {
        selectedComponent = components.key === key ? components : null;
    }
    return selectedComponent;
}

function getComponentByName(components, name) {
    let selectedComponent = null;
    if (!components) {
        return null;
    }

    if (Array.isArray(components)) {
        selectedComponent = components.find(component => component && component.type && component.type.displayName === name);
    } else if (components.type) {
        selectedComponent = components.type && components.type.displayName === name ? components : null;
    }

    return selectedComponent;
}

function getComponentByNameFromObject(components, name) {
    let contentFunctionbarChildren = null;

    if (Array.isArray(components)) {
        components.forEach(componentElement => {
            if (!componentElement || ((!componentElement[0] || !componentElement[0].type) && !componentElement.type)) {
                return contentFunctionbarChildren;
            }
            if (!Array.isArray(componentElement) && componentElement.type.displayName === name) {
                contentFunctionbarChildren = componentElement;
            }
        });
    }

    return contentFunctionbarChildren;
}

function getComponentsExceptByName(components, exceptName) {
    function sameExceptNames(componentName, exceptNames) {
        if (!isEmpty(exceptNames)) {
            if (isArray(exceptNames)) {
                return exceptNames.find(name => name === componentName);
            }
            return componentName === exceptNames;
        }
    }

    const contentChildrens: any = [];
    if (!components || isEmpty(exceptName)) {
        return contentChildrens;
    }

    if (Array.isArray(components)) {
        components.forEach(componentElement => {
            if (Array.isArray(componentElement) || (componentElement && componentElement.type && !sameExceptNames(componentElement.type.displayName, exceptName))) {
                contentChildrens.push(componentElement);
            }
        });
    } else if (components.type && !sameExceptNames(components.type.displayName, exceptName)) {
        contentChildrens.push(components);
    }

    return contentChildrens;
}

function isButtonNode(node, isHandleInput) {
    return node && node.localName && (node.localName === 'a' || node.localName === 'button' || node.getAttribute('role') === 'button') && (isHandleInput ? node.localName === 'input' : true);
}

function getNodeParentByDepth(node, maxDepth, nodeCheckerFunction) {
    if (maxDepth > 0 && node.parentElement && (!nodeCheckerFunction || (nodeCheckerFunction && !nodeCheckerFunction(node)))) {
        return getNodeParentByDepth(node.parentElement, maxDepth - 1, nodeCheckerFunction);
    }

    return node;
}

function selectItemByClassName(setSelectedItem, nodeItem, event, className) {
    setSelectedItem(nodeItem);
    const selectedNodeItem = document.getElementsByClassName(className);
    while (selectedNodeItem.length) {
        selectedNodeItem[0].classList.remove(className);
    }
    event.target.className += ` ${className}`;
}

function signItemWithClassName(className, selectedItem, selectedClassName) {
    const nodeItemList = document.getElementsByClassName(className);
    if (!nodeItemList) {
        return;
    }
    for (const nodeItem of nodeItemList) {
        if (parseInt((nodeItem as any).innerText, 10) === selectedItem) {
            nodeItem.className += selectedClassName;
        }
    }
}

function updateTooltipCoords(button, setCoordinate) {
    const rect = button && button.getBoundingClientRect();
    setCoordinate({
        left: rect && rect.x + rect.width / 2, // add half the width of the button for centering
        top: rect && rect.y + window.scrollY, // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    });
}

function handleLinksCtrlClick(event) {
    const wasMiddleClick = event.button === 1;
    const wasModifiedLeftClick = event.button === 0 && (event.metaKey || event.ctrlKey || event.shiftKey);

    const maxNodeDepth = 3;
    const actualNode = event.target;
    if (!actualNode) {
        return;
    }

    const parentNode = domHelper.getNodeParentByDepth(actualNode, maxNodeDepth, () => isButtonNode(actualNode, null));
    const hrefUrl = parentNode ? parentNode.href?.baseVal || parentNode.href : null;

    if (hrefUrl && (wasMiddleClick || wasModifiedLeftClick)) {
        event.stopImmediatePropagation();
        const agentName = sessionService.getAgentName();

        //if href is a full php url
        if (phpHelper.checkHrefIsFullUrl(hrefUrl)) {
            const hash = routerHelper.getHashFromLocation(hrefUrl);
            if (!hash || phpHelper.checkHrefIsTabControl(hrefUrl)) {
                return;
            }
            const redirectUrl = getAPIEndpoint(hash, agentName);

            if (redirectUrl) {
                window.open(redirectUrl, '_blank');
            }
        } else if (phpHelper.checkHrefStartHashmark(hrefUrl)) { //if href is simple # url
            if (phpHelper.checkHrefIsTabControl(hrefUrl)) { //if it is tab controller
                return;
            }
            const redirectUrl = getAPIEndpoint(hrefUrl, agentName);
            if (redirectUrl) {
                window.open(redirectUrl, '_blank');
            }
        } else {
            window.open(hrefUrl, '_blank');
        }
    }
}
