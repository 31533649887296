import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const reproductionRateService = {
    getReproductionRates,
    getReproductionRateById,
};

/**
 * Get all reproduction rates datas
 * @return {array} Reproduction rates array
 */
async function getReproductionRates() {
    const response = await sendServerRequest('GET', '/api/reproductionRates');
    return response;
}

/**
 * Get one reproduction rate datas by id
 * @property {int} reproductionRateId IsRequired - Reproduction rate ID eg.: 1
 *
 * @return {object} Reproduction rates
 */
async function getReproductionRateById(reproductionRateId) {
    const url = routerHelper.getUrlWithParameters('/api/reproductionRate', { reproductionRateId });
    const response = await sendServerRequest('GET', url);
    return response;
}
