import { latLngToPixel } from '@map/utils/mapUtils';
import { isEmpty, isNil } from 'lodash';
import { SKInfoPanelTabData, SKLpis, SKPublishedPolygon, getBbox } from '@map/components/SlovakReportsOverlay/slovakReportsUtils';
import { slovakExternalServices } from '@map/services/slovakianReports/slovakReportExternalApis';
import { InfoPanelCallback, InfoPanelTab } from '../../SlovakInfoPanel/SlovakInfoPanel';
import { generateLpisTabData } from '../getLpisLayer/getLpisLayer';

const selectedStyle = { strokeColor: '#FFFFFF', strokeWeight: 3, strokeOpacity: 1, zIndex: 1002 };
const nonSelectedStyle = { fillOpacity: 0, strokeColor: '#00FF00', strokeWeight: 2, strokeOpacity: 1, zIndex: 1002 };

type GetPublishedPolygonsLayerProps = {
  map: google.maps.Map;
  dataLayer: google.maps.Data;
  lpisDataLayer: google.maps.Data;
  markers: React.MutableRefObject<google.maps.Marker[]>;
  infoPanelCallback: InfoPanelCallback;
  gsaaYear: number;
};

export default async function getPublishedPolygons(props: GetPublishedPolygonsLayerProps) {
    const { map, dataLayer, lpisDataLayer, gsaaYear, infoPanelCallback } = props;
    const bbox = getBbox(map);
    const featureCollection = await slovakExternalServices.getPublishedPolygonsFromBBox(gsaaYear, encodeURIComponent(JSON.stringify(bbox)));

    if (isEmpty(featureCollection) || isNil(featureCollection.features)) {
        return null;
    }

    dataLayer.addGeoJson(featureCollection);

    dataLayer.forEach(feature => {
        const centroid: google.maps.LatLng = feature.getProperty('centroid');

        dataLayer.overrideStyle(feature, nonSelectedStyle);
    });

    const clickListener = dataLayer.addListener('click', (event: google.maps.Data.MouseEvent) => {
        const clickedFeature = event.feature;

        const pixelCoordinates = latLngToPixel(map, event.latLng);

        const geomProperties: Partial<SKPublishedPolygon> = {};
        clickedFeature.forEachProperty((value, property) => {
            geomProperties[property] = value;
        });

        if (pixelCoordinates) {
            const tabData: SKInfoPanelTabData[] = [];

            const lpis: Partial<SKLpis> = {};
            lpisDataLayer.forEach(feature => {
                const lpisKODKD = feature.getProperty('ZKODKD');
                if (lpisKODKD === geomProperties.ZKODKD) {
                    feature.forEachProperty((value, property) => {
                        lpis[property] = value;
                    });
                }
            });

            tabData.push({
                header: `Zverejnené HU ${geomProperties.KODKD}`,
                properties: {
                    'Poradové číslo': geomProperties.PCUV,
                    Parcela: geomProperties.PARCELA,
                    'Výmera HU [ha]': geomProperties.VYMERA,
                    Lokalita: geomProperties.LOKALITA,
                    'Skrátený kód dielu': geomProperties.ZKODKD,
                    'Kód dielu': geomProperties.KODKD,
                    'Názov subjektu': geomProperties.NAZOV_SUBJEKTU,
                },
            });

            tabData.push(generateLpisTabData(lpis as SKLpis, gsaaYear));

            const onClose = () => {
                dataLayer.forEach(feature => {
                    dataLayer.overrideStyle(feature, nonSelectedStyle);
                });
            };

            infoPanelCallback(true, pixelCoordinates?.x, pixelCoordinates?.y, InfoPanelTab.PUBLISHED_POLYGON, tabData, onClose);
        }

        dataLayer.forEach(feature => {
            dataLayer.overrideStyle(feature, nonSelectedStyle);
        });

        dataLayer.overrideStyle(clickedFeature, selectedStyle);
    });

    return clickListener;
}
