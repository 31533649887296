import { createUseStyles } from 'react-jss';
import { isFunction } from 'lodash';

import MultiSelect from '@baseComponents/multiselect/MultiSelect';

const useStyles = createUseStyles({
    multiselectWrapper: {
        '& .dropdown-toggle.btn.btn-primary': {
            height: 32,
            border: (props: any) => props.hasBorder && 'none',
        },
    },
});

export default function MultiselectCell(props) {
    const { dataList, onChange, onClose, isNonAccentSearch, isDisabled } = props;
    const { hasBorder, updateValue } = props;
    const classes = useStyles({ hasBorder });

    function onChangeSelectedValues(values) {
        if (isFunction(onChange)) {
            onChange(values);
        }
    }

    function onCloseMultiselect(values) {
        if (isFunction(onClose)) {
            onClose(values);
            return;
        }

        if (isFunction(updateValue)) {
            updateValue(values);
        }
    }

    return (
        <div className={classes.multiselectWrapper}>
            <MultiSelect
                isDisabled={isDisabled}
                isNonAccentSearch={isNonAccentSearch}
                dataList={dataList}
                hasSearchInput
                hasMultiSelectHeader
                onChange={(values: any) => {
                    onChangeSelectedValues(values);
                }}
                onClose={(values: any) => {
                    onCloseMultiselect(values);
                }}
            />
        </div>
    );
}
