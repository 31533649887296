import Svg from '@baseComponents/Svg';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundColor: theme.color.white,
        border: 'none',
    },
    pesticideLink: {
        height: 36,
        width: 36,
        border: '1px solid',
        borderColor: theme.color.pearl,
        backgroundColor: theme.color.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        '&:hover': {
            borderColor: (props: any) => (props.isPesticideProduct ? theme.color.gray : theme.color.pearl),
            boxShadow: (props: any) => (props.isPesticideProduct ? theme.shadows.secondary.hover : 'none'),
        },
    },
    pesticideIcon: {
        height: 21.6,
        width: 21.6,
        opacity: (props: any) => (props.isPesticideProduct ? '100%' : '15%'),
        cursor: (props: any) => (props.isPesticideProduct ? 'pointer' : 'default'),
    },
}));

export default function PesticideLinkCell(props) {
    const { value } = props;
    const classes = useStyles({ isPesticideProduct: !!+value });

    function onClickPesticideButton() {
        const pesticideId = value || 0;
        if (+pesticideId > 0 && (window as any).openNovenyvedoszerModal) {
            (window as any).openNovenyvedoszerModal(pesticideId);
        }
    }

    return (
        <button className={classes.baseStyle} type="button" onClick={onClickPesticideButton}>
            <div className={classes.pesticideLink}>
                <Svg iconId="icon-pp_datasheet" style={classes.pesticideIcon} />
            </div>
        </button>
    );
}
