import FarmModal from '@customComponents/modals/farmModal/FarmModal';
import PartnersModal from '@customComponents/modals/partnersModal/PartnersModal';
import useTranslate from '@i18n/useTranslate';
import { tableFilterActions } from '@states/actions';
import { routerHelper } from '@utils/router/RouterHelper';
import { useAlert } from '@utils/index';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PARTNER, FILTERS, INTERFACE_FILENAME, INTERFACE_SETTINGS } from 'shared/src/constants';
import { MODULES } from 'shared/src/routing';
import { ProcessGeomErrorCode, ProcessGeomWarningCode } from './mapTypes';

/* eslint-disable max-lines-per-function*/

type TProps = {
    onResetFiltersFn: (resetYearId: number) => void;
    onTagSubmit: (filters?: any) => void;
}

export const useMapGetOnFileUploadError = () => {
    const { t } = useTranslate();
    const { errorAlert, warningAlert } = useAlert();
    const onFileUploadError = (error: ProcessGeomErrorCode, warning: ProcessGeomWarningCode) => {
        if (error) {
            let errorMessage;
            let defaultEnglishMessage;
            switch (error) {
                case ProcessGeomErrorCode.FILE_NOT_FOUND:
                    errorMessage = 'fileNotFound';
                    defaultEnglishMessage = 'File not found';
                    break;
                case ProcessGeomErrorCode.PARSING_ERROR:
                    errorMessage = 'parsingError';
                    defaultEnglishMessage = 'Parsing error';
                    break;
                case ProcessGeomErrorCode.UNKNOWN_ERROR:
                    errorMessage = 'unknownError';
                    defaultEnglishMessage = 'Unknown error';
                    break;
                case ProcessGeomErrorCode.NO_POLYGON_DATA:
                    errorMessage = 'noPolygonData';
                    defaultEnglishMessage = 'No polygon data';
                    break;
                case ProcessGeomErrorCode.NO_CRS:
                    errorMessage = 'noCRS';
                    defaultEnglishMessage = 'No CRS';
                    break;
                case ProcessGeomErrorCode.UNSUPPORTED_FILE_FORMAT:
                    errorMessage = 'unsupportedFileFormat';
                    defaultEnglishMessage = 'Unsupported file format';
                    break;
                case ProcessGeomErrorCode.INVALID_CRS:
                    errorMessage = 'invalidCRS';
                    defaultEnglishMessage = 'Invalid CRS';
                    break;
                case ProcessGeomErrorCode.FILE_TOO_LARGE:
                    errorMessage = 'fileTooLarge';
                    defaultEnglishMessage = 'File too large';
                    break;
                case ProcessGeomErrorCode.INVALID_GEOMETRY_TYPE:
                    errorMessage = 'invalidGeometryType';
                    defaultEnglishMessage = 'Invalid geometry type';
                    break;
                case ProcessGeomErrorCode.MORE_THAN_ONE_COMPRESSED_GEO_FILE:
                    errorMessage = 'moreThanOneCompressedGeoFile';
                    defaultEnglishMessage = 'More than one compressed geo file';
                    break;
                case ProcessGeomErrorCode.TIMEOUT:
                    errorMessage = 'timeout';
                    defaultEnglishMessage = 'Timeout';
                    break;
                default:
                    errorMessage = 'unknownError';
                    defaultEnglishMessage = 'Unknown error';
                    break;
            }

            errorAlert(t(`geoProcess.${errorMessage}`, defaultEnglishMessage));
        }
        if (warning && warning !== ProcessGeomWarningCode.NONE) {
            let warningMessage;
            let defaultEnglishMessage;

            switch (warning) {
                case ProcessGeomWarningCode.SOME_POLYGONS_NOT_VALID:
                    warningMessage = 'somePolygonsNotValid';
                    defaultEnglishMessage = 'Some polygons are not valid and has been removed';
                    break;
                default:
                    break;
            }

            if (warningMessage) {
                warningAlert(t(`geoProcess.${warningMessage}`, defaultEnglishMessage));
            }
        }
    };

    return onFileUploadError;
};

export const useMapFilters = ({ onResetFiltersFn, onTagSubmit } : TProps) => {
    const { t, translater } = useTranslate();
    const dispatch = useDispatch();
    const { MAP_FILTERS } = INTERFACE_SETTINGS;
    const [moduleName] = useState(routerHelper.getBaseUrl(MODULES.MAP, MODULES.MAP.CONTENTS.MAP));
    const prevProductionYear = useSelector((state: any) => state.tableFilter.currentFilters.prevProductionYear);
    const periodYears = useSelector((state: any) => state.dataLists.productionYears);
    const interfaceProductionYear = useSelector((state: any) => state.user.user.settings.interfaceSettings[INTERFACE_FILENAME.CULTIVATION_PERIODS_MANAGEMENT]?.periodYear?.value);

    // const interfaceProductionYear = useSelector((state: any) => state.user.user.settings.interfaceSettings[INTERFACE_FILENAME.MAP]?.periodYear?.value);
    const [currentProdYear] = useState(interfaceProductionYear || periodYears?.[0]?.id || 0);

    const [showClosedPeriodIds] = useState(Object.values({
        showClosedPeriods: { label: t('cultivationPeriods.showClosedPeriod', 'Show closed periods'), id: 1 },
    }));

    const [statusPeriods] = useState({
        active: { label: t('productTableFilter.activeState', 'Active'), id: PARTNER.ACTIVE_STATUS.ACTIVE, text: translater('productTableFilter.activeState', 'Active') },
        inactive: { label: t('productTableFilter.inactiveState', 'Inactive'), id: PARTNER.ACTIVE_STATUS.INACTIVE, text: translater('productTableFilter.inactiveState', 'Inactive') },
        activeAndInactive: { label: t('productTableFilter.activeAndInactiveState', 'Active and Inactive'), id: PARTNER.ACTIVE_STATUS.ALL, text: translater('productTableFilter.activeAndInactiveState', 'Active and Inactive') },
    });

    const [statusIds] = useState(Object.values(statusPeriods));

    const {
        TYPES: { PRODUCTIONYEARFILTER, FILTERTABLE, CHECKBOXLIST, RADIOLIST },
        DEFAULTTRANSLATIONLABELS,
    } = FILTERS;

    function onResetFilters(closeFn) {
        const resetYearId = (!Array.isArray(prevProductionYear) && prevProductionYear) || currentProdYear;
        // dispatch(userActions.setInterfaceSetting(INTERFACE_SETTINGS.PERIOD_YEAR, resetYearId, INTERFACE_FILENAME.MAP));
        dispatch(tableFilterActions.resetCurrentFilters(
            null,
            null,
            {
                productionYearId: resetYearId,
                showClosedPeriods: true,
            },
        ));
        closeFn();
        onResetFiltersFn(resetYearId);
    }

    const [filterProps] = useState({
        moduleName,
        isSavingDisabled: true,
        interfaceName: MAP_FILTERS,
        filters: {
            productionYearId: {
                componentType: PRODUCTIONYEARFILTER,
                onTagSubmit,
                // saveInterfaceSetting: true,
                componentProps: {
                    filterName: 'productionYearId',
                    title: t('filterTitle.productionYear', DEFAULTTRANSLATIONLABELS.productionYear),
                },
                defaultFilterOptions: currentProdYear,
            },
            showClosedPeriods: {
                onTagSubmit,
                componentType: CHECKBOXLIST,
                isTagDisabled: true,
                componentProps: {
                    filterName: 'showClosedPeriods',
                    title: t('filter.status', 'Status'),
                    isSelectAllDisabled: true,
                    checklistData: showClosedPeriodIds,
                },
                defaultFilterOptions: showClosedPeriodIds,
            },
            statusId: {
                componentType: RADIOLIST,
                isTagDisabled: true,
                onTagSubmit,
                tagTitle: t('cultivationPeriods.state', 'Status'),
                componentProps: {
                    title: t('cultivationPeriods.state', 'Status'),
                    filterName: 'statusId',
                    dataList: statusIds,
                },
                defaultFilterOptions: statusPeriods.active.id,
            },
            partnerIds: {
                componentType: FILTERTABLE,
                onTagSubmit,
                componentProps: {
                    title: t('filterTitle.partnerIds', DEFAULTTRANSLATIONLABELS.partnerIds),
                    filterName: 'partnerIds',
                    nameKeyToTag: 'name',
                    ModalComponent: PartnersModal,
                    modalComponentProps: {
                        rowFormatFnc: partner => ({
                            id: partner.id,
                            name: partner.name,
                            label: partner.name,
                        }),
                    },
                },
            },
            farmIds: {
                componentType: FILTERTABLE,
                onTagSubmit,
                componentProps: {
                    title: t('filterTitle.farmIds', DEFAULTTRANSLATIONLABELS.farmIds),
                    filterName: 'farmIds',
                    nameKeyToTag: 'name',
                    ModalComponent: FarmModal,
                    modalComponentProps: {
                        rowFormatFnc: farm => ({
                            id: farm.id,
                            name: farm.farmName,
                            label: farm.farmName,
                        }),
                    },
                },
            },
            // cultureIds: {
            //     componentType: FILTERTABLE,
            //     componentProps: {
            //         title: t('filterTitle.cultureIds', DEFAULTTRANSLATIONLABELS.cultureIds),
            //         filterName: 'cultureIds',
            //         nameKeyToTag: 'name',
            //         ModalComponent: CulturesModal,
            //         modalComponentProps: {
            //             rowFormatFnc: culture => ({
            //                 id: culture.id,
            //                 name: culture.cultureName,
            //                 label: culture.,
            //             }),
            //         },
            //     },
            // },
        },
    });
    return { filterProps, onResetFilters };
};
