import ReactTooltip from 'react-tooltip';

export default function CostumReactTooltip(props) {
    const { place = 'top' } = props;

    return (
        <ReactTooltip
            globalEventOff="click"
            place={place}
            effect="solid"
            isCapture
        />
    );
}
