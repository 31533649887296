import { isEmpty, isBoolean } from 'lodash';
import { stringUtils } from 'shared/src/modules';
import { routerHelper } from '@utils/router/RouterHelper';
import { sessionService, companyService } from '@services';
import { simpleAction } from './baseActions';
// eslint-disable-next-line import/no-cycle
import { userActions } from './userActions';

const routerActions = {
    routeChanged,
    checkAgentByRoute,
    setCurrentPageKey,
    setServerOverload,
    removeServerOverload,
    setChangePasswordScreen,
    SET_AGENT: 'AGENT_SET_AGENT',
    SET_AGENT_ERROR: 'AGENT_SET_AGENT_ERROR',
    SET_SYSTEMINFO: 'ROUTER_SET_SYSTEMINFO',
    SET_MAINTENANCE: 'ROUTER_SET_MAINTENANCE',
    REMOVE_MAINTENANCE: 'ROUTER_REMOVE_MAINTENANCE',
    SET_SYSTEMSETTINGS: 'ROUTER_SET_SYSTEMSETTINGS',
    SET_CURRENT_PAGE_KEY: 'ROUTER_SET_CURRENT_PAGE_KEY',
    SET_SERVEROVERLOAD: 'ROUTER_SET_SERVEROVERLOAD',
    REMOVE_SERVEROVERLOAD: 'ROUTER_REMOVE_SERVEROVERLOAD',
    SET_CHANGE_PASSWORD_SCREEN: 'ROUTER_SET_CHANGE_PASSWORD_SCREEN',
};

export { routerActions };

function routeChanged(location) {
    return async dispatch => {
        try {
            const agentName = routerHelper.getAgentName(location);
            const agent = agentName ? await companyService.getAgent(agentName) : null;

            if (!agent) {
                dispatch(simpleAction(userActions.SET_AGENT_ERROR, { error: { message: 'Agent not found!' } }));
                return false;
            }

            sessionService.setAgentName(agentName);
            dispatch(simpleAction(userActions.SET_AGENT, { agent: agent.agentName }));
            return true;
        } catch (error) {
            dispatch(simpleAction(routerActions.SET_SERVEROVERLOAD, { isServerOverload: true }));
            return false;
        }
    };
}

function checkAgentByRoute(location) {
    return (dispatch, getState) => {
        const agentName = routerHelper.getAgentName(location);
        const agentNameFromSession = sessionService.getAgentName(agentName);
        const { user } = getState();
        const agentError = user.agentError;

        //check has agent in session
        if (!agentError && ((agentNameFromSession && !stringUtils.isSame(agentName, agentNameFromSession)) || isEmpty(agentNameFromSession))) {
            sessionService.setAgentName(agentName);
            dispatch(simpleAction(userActions.SET_AGENT, { agent: agentName }));
        }
    };
}

function setCurrentPageKey(pageKey) {
    return simpleAction(routerActions.SET_CURRENT_PAGE_KEY, { currentPageKey: pageKey });
}

function setServerOverload(isServerOverload) {
    return simpleAction(routerActions.SET_SERVEROVERLOAD, { isServerOverload });
}

function removeServerOverload() {
    return simpleAction(routerActions.REMOVE_SERVEROVERLOAD);
}

function setChangePasswordScreen(isChangePasswordScreen) {
    return (dispatch, getState) => {
        const { router } = getState();
        const screenState = isBoolean(isChangePasswordScreen) ? isChangePasswordScreen : !router.isChangePasswordScreen;
        dispatch(simpleAction(routerActions.SET_CHANGE_PASSWORD_SCREEN, { isChangePasswordScreen: screenState }));
    };
}
