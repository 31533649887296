import { isFunction, isEmpty, isObject, values } from 'lodash';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import { theme as themes } from '@styles/theme';
import { CERTIFICATES } from 'shared/src/constants';
import { alertActions, errorActions } from '@states/actions';
import { phpService, inventoryTransferRequestService } from '@services';

import renderModal from '@modals/modalNew/modalFunctions';
import ConfirmDialog from '@baseComponents/modals/ConfirmDialog';

export default function getAllColumns(t, translater, showedColumns, showDelete, reloadTable, dispatch, requestId) {
    const columns = {
        downloadIcon: {
            type: columnName.iconButton,
            columnId: 'downloadIcon',
            componentProps: {
                icon: 'icon-file_pdf',
                getTitle: () => translater('default.downloadInPdf', 'Download in pdf format'),
                getIconColor: () => themes.color.icons.pdfIcon,
                onClick: rowData => {
                    if (Number(rowData.type) === CERTIFICATES.TYPE_ID.RECEIPT && isFunction(window.beveteli_bizonylat_nyomtatas)) {
                        window.beveteli_bizonylat_nyomtatas(rowData.id);
                    } else if (Number(rowData.type) === CERTIFICATES.TYPE_ID.RELEASED && isFunction(window.kiadasi_bizonylat_nyomtatas)) {
                        window.kiadasi_bizonylat_nyomtatas(rowData.id);
                    }
                },
            },
            isLeftFixed: true,
            isFirstColumn: true,
            width: 65,
            minWidth: 65,
            maxWidth: 65,
        },
        type: {
            type: columnName.iconSvg,
            columnId: 'type',
            componentProps: {
                getIcon: rowData => (Number(rowData.type) === CERTIFICATES.TYPE_ID.RECEIPT
                    ? 'icon-file_cert-reception'
                    : 'icon-file_cert-release'),
                getIconColor: rowData => (Number(rowData.type) === CERTIFICATES.TYPE_ID.RECEIPT
                    ? themes.color.go
                    : themes.color.stop),
                getTitle: rowData => (Number(rowData.type) === CERTIFICATES.TYPE_ID.RECEIPT
                    ? translater('certificatesModal.reception', 'Reception')
                    : translater('certificatesModal.release', 'Release')),
            },
            isLeftFixed: true,
            width: 55,
            minWidth: 55,
            maxWidth: 55,
        },
        certificateDate: {
            type: columnName.date,
            columnId: 'certificateDate',
            columnName: t('certificatesModal.dateOfCertificate', 'Date of certificate'),
            minWidth: 120,
        },
        certificateNumber: {
            type: columnName.simpleText,
            columnId: 'certificateNumber',
            columnName: t('certificatesModal.certificateNr', 'Certificate nr.'),
            minWidth: 210,
        },
        companyName: {
            type: columnName.simpleText,
            columnId: 'companyName',
            columnName: t('certificatesModal.company', 'Company'),
            minWidth: 180,
        },
        partnerName: {
            type: columnName.simpleText,
            columnId: 'partnerName',
            columnName: t('certificatesModal.partner', 'Partner'),
            minWidth: 180,
        },
        warehouseName: {
            type: columnName.simpleText,
            columnId: 'warehouseName',
            columnName: t('certificatesModal.warehouse', 'Warehouse'),
            minWidth: 180,
        },
    };

    if (showDelete) {
        columns.deleteButton = {
            type: columnName.iconButton,
            columnId: 'deleteIcon',
            componentProps: {
                icon: 'icon-bin',
                type: 'destructive',
                getTitle: () => translater('certificatesModal.storno', 'Storno'),
                getIconColor: () => themes.color.destructive,
                onClick: rowData => {
                    renderModal(ConfirmDialog, {
                        isAcceptCancelDialog: true,
                        questionText: translater('certificatesModal.cancelCertificateItems', 'Cancel certificate items'),
                        descriptionText: translater('certificatesModal.stornoQuestion', 'The reception and release certificates of the stock transfer will be invalidated. Are you sure to cancel the certificates?'),
                        acceptButtonText: t('default.yes', 'Yes'),
                        iconId: 'icon-help',
                        confirmCallback: async () => {
                            try {
                                const phpResult = await phpService.postDataToPhp('/modules/react/certificates/delete_certificates.php', { transferId: rowData.transferId });
                                let hasError = phpResult.error;

                                if (!hasError && requestId) {
                                    const successInsert = await inventoryTransferRequestService.setInventoryRequestCertificateStorno({ requestId });
                                    hasError = !isObject(successInsert);
                                }

                                if (hasError) {
                                    dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed')));
                                } else {
                                    dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved')));
                                    reloadTable();
                                }
                            } catch (error) {
                                dispatch(errorActions.setError(error, 'certificateModal_AllColumns'));
                            }
                        },
                    }, false);
                },
            },
            isRightFixed: true,
            width: 65,
            minWidth: 65,
            maxWidth: 65,
        };
    }

    if (!isEmpty(showedColumns)) {
        const filteredColumns = [];
        const columnObj = Object.entries(columns);

        showedColumns.forEach(showedColName => {
            const column = columnObj.find(columnParts => columnParts[0].toString() === showedColName);
            if (column) {
                filteredColumns.push(column[1]);
            }
        });

        return filteredColumns;
    }

    return values(columns);
}
