import { Layer } from '@map/services/mapTypes';
import { getLayerCaptionString } from '@map/utils/mapCaptionUtils';
import { TFunction } from 'i18next';
import { isNil } from 'lodash';

export function getLayerName(layer?: Layer, translater?: TFunction) {
    if (!isNil(layer) && !isNil(translater)) {
        if (layer.readonly) {
            const name = getLayerCaptionString(translater, layer);
            if (name !== 'Unnamed layer') {
                return name;
            }
            if (name.length === 0) {
                return layer.name;
            }
            return layer.name;
        }
        if (layer.name.indexOf('TText') > 0) {
            const name = translater<string>(`map.${layer.name}`, layer.name);
            if (name.length === 0) {
                return layer.name;
            }
            return name;
        }
        return layer.name;
    }
    return 'Undefined';
}
