import BaseStoreService from '@flowsCommon/services/BaseStoreService';
import {SKInfoPanelTabData} from '@map/components/SlovakReportsOverlay/slovakReportsUtils';
import {TSlovakReportStore} from './slovakReportStore';
import { InfoPanelTab } from '@map/components/SlovakReportsOverlay/SlovakInfoPanel/SlovakInfoPanel';

export class SlovakReportStoreService extends BaseStoreService<TSlovakReportStore> {
  setAPIToken = (token: string) => {
    this.setStore((draftStore) => {
      draftStore.auth.token = token;
    });
  };

  initDataLayers = (map: google.maps.Map, dataLayer: google.maps.Data, lpisDataLayer: google.maps.Data, publishedPolygonsDataLayer: google.maps.Data) => {
    this.setStore((draftStore) => {
      draftStore.map = map;
      draftStore.lpisDataLayer = lpisDataLayer;
      draftStore.dataLayer = dataLayer;
      draftStore.publishedPolygonsDataLayer = publishedPolygonsDataLayer;
      draftStore.isInitalized = true;
    });
  };

  setActiveLayers = (layers: number[]) => {
    this.setStore((draftStore) => {
      draftStore.layering.layers = layers;
    });
  };

  setInfoPanel(isOpen: boolean, posX: number, posY: number, tabType: InfoPanelTab, infoPanelData?: SKInfoPanelTabData[], onClose?: () => void) {
    this.setStore((draftStore) => {
      draftStore.infoPanel.isOpen = isOpen;
      draftStore.infoPanel.posX = posX;
      draftStore.infoPanel.posY = posY;
      draftStore.infoPanel.tabType = tabType;
      draftStore.infoPanel.tabData = infoPanelData ?? [];
      draftStore.infoPanel.onClose = onClose;
    });
  };

  setSelectedLpisPolygon = (feature: google.maps.Data.Feature | null) => {
    this.setStore((draftStore) => {
      draftStore.selectedLpisPolygon = feature;
    });
  };

  setDrawMode = (drawMode: boolean) => {
    this.setStore((draftStore) => {
      draftStore.isDrawMode = drawMode;

      if (drawMode) {
        draftStore.infoPanel.isOpen = false;
        draftStore.infoPanel.tabData = [];
      }
    });
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export default SlovakReportStoreService;
