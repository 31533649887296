/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { routerHelper } from '@utils';
import useTranslate from '@i18n/useTranslate';
import { phpService, sessionService } from '@services';
import { userActions, alertActions } from '@states/actions';
import { PAGE_DATA } from 'shared/src/constants';

import { ConfirmDialog } from '@baseComponents/modals';
import renderModal from '@modals/modalNew/modalFunctions';

export default function usePhpReactSessionHandler(props) {
    const { phpJavascriptLoaded } = props;
    const location = useLocation().pathname;
    const { t } = useTranslate();
    const dispatch = useDispatch();

    //react - php session keep alive
    const [sessionTimer, setSessionTimer] = useState<any>();
    const [phpSessionRefreshTime] = useState(PAGE_DATA.SESSION_REFRESH_TIME_IN_SEC * 1000); //30min

    //php idle timer
    const [sessionTimeoutTimer, setSessionTimeoutTimer] = useState<any>(); //timer
    const [phpSessionTimeout] = useState(PAGE_DATA.SESSION_TIMEOUT_IN_SEC * 1000); //4h

    useEffect(() => {
        if (phpJavascriptLoaded && location) {
            const isPhpPage = isPhpLocation(location);

            if (!sessionTimer && !isPhpPage && sessionService.checkAuthToken()) { //has't timer + react page + logged in -> start timer
                checkPhpSession().then(isLoggedIn => {
                    if (isLoggedIn) { //start timer
                        const timer = setInterval(() => {
                            checkPhpSession(); //refresh php session
                        }, phpSessionRefreshTime);
                        setSessionTimer(timer);
                    }
                });
                stopTimeout();
            } else if (sessionTimer && isPhpPage) { //has timer + php page -> stop timer
                clearInterval(sessionTimer);
                setSessionTimer(null);
            }

            //session checker
            if (!sessionTimeoutTimer && isPhpPage) { //start php timeout - is php page
                startTimeout();
            } else if (sessionTimeoutTimer && !isPhpPage) { //stop timer - is react page
                stopTimeout();
            } else if (sessionTimeoutTimer && isPhpPage) { //refresh timer - php navigation
                stopTimeout();
                startTimeout();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phpJavascriptLoaded, location]);

    function startTimeout() {
        function showDialogBox() {
            renderModal(ConfirmDialog, {
                iconId: 'icon-clock',
                buttonType: 'secondary',
                buttonText: t('default.ok', 'Ok'),
                questionText: t('default.sessionAboutExpire', 'The session is about to expire.'),
                descriptionText: t('default.clickOkExtendExpire', 'Click to the OK button for extend the session.'),
            }, false, () => {
                //check session - then logout or reset timer
                checkPhpSession().then(isLoggedIn => {
                    if (isLoggedIn) {
                        startTimeout(); //start again the timer - user activity (onClose)
                        checkPhpSession(); //refresh php session
                    } else {
                        dispatch(userActions.logout());
                        dispatch(alertActions.infoAlert(t('default.sessionExpired', 'Session expired. Please log in again.')));
                    }
                });
            });
        }

        const timer = setTimeout(() => {
            showDialogBox();
        }, phpSessionTimeout);

        setSessionTimeoutTimer(timer);
    }

    function stopTimeout() {
        clearTimeout(sessionTimeoutTimer);
        setSessionTimeoutTimer(null);
    }

    function isPhpLocation(url) {
        const contentObject = routerHelper.getContentObjectByUrl(url);
        return contentObject && contentObject.COMPONENT_URL === 'contents/PhpContent';
    }

    async function checkPhpSession() {
        const response = await phpService.checkPhpSession();
        if (!response || Boolean(response) === false) {
            clearInterval(sessionTimer);
            setSessionTimer(null);
            userActions.logout();
            return false;
        }
        return true;
    }

    return {
    };
}
