import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { TABLE } from 'shared/src/constants';

import TableHeaderSorter from '../../sorter/TableHeaderSorter';
import Svg from '../../../../Svg';

const useStyles = createUseStyles((theme: any) => ({
    itemContainer: {
        height: 20,
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        '& .iconContainer': {
            height: 20,
            lineHeight: '20px',
        },
    },
    itemButtonBeforIcon: {
        verticalAlign: 'middle',
        width: 20,
        height: 20,
        marginRight: 10,
        fill: theme.color.stone,
        display: 'block',
    },
    itemTextContainer: {
        verticalAlign: 'middle',
        height: 20,
        display: 'inline-flex',
        width: '100%',
    },
    itemText: {
        verticalAlign: 'middle',
        height: 20,
        lineHeight: '20px',
        fill: theme.color.stone,
        width: 'auto',
        fontSize: 12,
        fontWeight: 500,
        color: theme.color.white,
        background: theme.color.main,
        borderRadius: 4,
        margin: 0,
        padding: 5,
        paddingTop: 0,
        paddingBottom: 0,
    },
    itemSorterIcon: {
        verticalAlign: 'middle',
        width: 16,
        height: 16,
        minWidth: 16,
        fill: theme.color.white,
        display: 'inline-block',
        background: theme.color.main,
        borderRadius: 70,
        float: 'right',
        cursor: 'pointer',
    },
}));

export default function DropDownSorterListItem(props) {
    const { columnId, isBasePageTable, tableKey } = props;
    const columns = useSelector((state: any) => state.basePage.table.columns);
    const globalColumnSortingTypes = useSelector((state: any) => state.basePage.table.globalColumnSortingTypes);
    const globalColumnSortingTypesSimpleTable = useSelector((state: any) => (tableKey ? state.simpleTable.table[tableKey].globalColumnSortingTypes : null));
    const [column, setColumn] = useState(null);
    const [sortingType, setSortingType] = useState(null);
    const classes = useStyles();

    //get sorted column
    useEffect(() => {
        if (!isEmpty(columns) && columnId) {
            const columnById = columns.find(columnItem => columnItem.columnId === columnId);
            setColumn(columnById);
        }
    }, [columns, columnId]);

    //get sorting type - base page table
    useEffect(() => {
        if (!isEmpty(globalColumnSortingTypes) && columnId && isBasePageTable) {
            const sorterColumn = globalColumnSortingTypes[columnId];
            setSortingType(sorterColumn);
        }
    }, [globalColumnSortingTypes, columnId, isBasePageTable]);

    //get sorting type - simple table
    useEffect(() => {
        if (!isEmpty(globalColumnSortingTypesSimpleTable) && columnId) {
            const sorterColumn = globalColumnSortingTypesSimpleTable[columnId];
            setSortingType(sorterColumn);
        }
    }, [globalColumnSortingTypesSimpleTable, columnId]);

    if (isEmpty(column) || !sortingType || sortingType === TABLE.SORTING.NONE) {
        return null;
    }

    return (
        <div className={classes.itemContainer}>
            <Svg iconId="icon-drag_vertical" style={classes.itemButtonBeforIcon} />
            <div className={classes.itemTextContainer}>
                <h5 className={classes.itemText}>{column.columnName}</h5>
            </div>
            <TableHeaderSorter
                tableKey={tableKey}
                column={column}
                isBasePageTable={isBasePageTable}
                showSortingIcon
                className={classes.itemButtonBeforIcon}
            />
        </div>
    );
}
