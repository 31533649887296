import { createUseStyles } from 'react-jss';
import { Dropdown } from 'react-bootstrap';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    menuItemSeparator: {
        height: 0,
        margin: '0.5rem 0',
        overflow: 'hidden',
        borderTop: `1px solid ${theme.color.whiteGray}`,
    },
}));

export default function Separator(props) {
    const { className } = props;
    const classes = useStyles();

    return (
        <Dropdown.Divider className={clsx(classes.menuItemSeparator, className)} />
    );
}
