import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { uniqueId, isFunction, isEmpty, isArray } from 'lodash';

import { simpleTableActions } from '@states/actions';
import useTranslate from '@i18n/useTranslate';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import GlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/GlobalSearchInput';
import Button from '@baseComponents/buttons/Button';
import CloseButton from '@baseComponents/buttons/CloseButton';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { ModalContentWrapper, ModalHeader, ModalBody, ModalFooter } from '@baseComponents/modals/modalNew';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import useFocusGlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/useFocusGlobalSearchInput';
import { useAlert } from '@utils/index';

import PartnerTypeRadio from './PartnerTypeRadio';
import usePartnersModalLoadData from './usePartnersModalLoadData';
import getAllColumns from './allColumns';

const useStyles = createUseStyles((theme: any) => ({
    modalWrapper: {
        width: 1200,
    },
    headerFunctionLine: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: theme.color.white,
        padding: '0 30px',
        marginBottom: 10,
    },
    partnerTypeContainer: {
        display: 'inline-flex',
        marginLeftt: 20,
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    footerButton: {
        width: 250,
    },
    closeButton: {
        width: 150,
    },
    addSelectedButton: {
        marginRight: 10,
    },
    functionLine: {
        display: 'inline-flex',
        alignItems: 'center',
        height: 40,
    },
    tableWrapper: {
        display: 'contents',
        height: '100%',
        width: '100%',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    table: {
        '& .tableHeaderRow': {
            marginRight: 20,
            paddingRight: 10,
            marginLeft: 4,
        },
        '& .List': {
            marginRight: 20,
            paddingRight: 10,
        },
        '& .table-row': {
            marginRight: 20,
            paddingRight: 10,
        },
        width: '100%',
    },
}));

const TABLE_KEY = uniqueId('partnersModal');

export default function PartnersModal(props) {
    const { hideModal, onChange, isMultiselect = false, rowFormatFnc = obj => obj, isShowSelect = true, onSelectedValue, showPartnerType = true, defaultPartnerType, excludedIds, selectedRowIds } = props;
    const { t, translater } = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { warningAlert } = useAlert();
    const [defaultColumns, setDefaultColumns] = useState();
    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    const { tableData, partnerTypeId, changePartnerType } = usePartnersModalLoadData({ defaultPartnerType, excludedIds });

    //set columns
    useEffect(() => {
        const showedColumns = ['addIcon', 'name', 'settlement', 'address', 'taxSign', 'taxNumber'];
        const filteredColumns: any = getAllColumns(t, translater, showedColumns);
        setDefaultColumns(filteredColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAddButtonClicked(selectedPartner) {
        if (!isMultiselect) { //1 konkrét partnert választottunk
            if (isShowSelect && isFunction(onSelectedValue)) {
                onSelectedValue(selectedPartner.name, selectedPartner.id);
            }

            if (isFunction(onChange)) {
                onChange(rowFormatFnc(selectedPartner));
            }
        } else { //multiselect - több partner választó miatt tömbként adjuk vissza
            if (isShowSelect && isFunction(onSelectedValue)) {
                // onSelectedValue([rowFormatFnc(selectedPartner)]);
            }

            if (isFunction(onChange)) {
                onChange([rowFormatFnc(selectedPartner)]);
            }
        }

        if (isFunction(hideModal)) {
            hideModal();
        }
    }

    function onAddSelectedButtonClicked() {
        const selectedPartnerIds: any = dispatch(simpleTableActions.getSelectedRowIds(TABLE_KEY));
        const selectedPartners = (tableData as any)?.filter(rowData => selectedPartnerIds?.includes(rowData.id))?.map(partner => rowFormatFnc(partner));

        if (!isEmpty(selectedPartners)) {
            if (isFunction(onChange)) {
                onChange(selectedPartners);
            }

            if (isFunction(hideModal)) {
                hideModal();
            }
        } else {
            warningAlert(t('partnerTableFilter.noSelectedPartner', 'No selected partner'));
        }
    }

    const [tableFunctions] = useState({
        onAddButtonClicked,
    });

    const [breakdown] = useState({
        value: null,
        text: 'No breakdown',
    });

    useFocusGlobalSearchInput(TABLE_KEY);

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                <div className={classes.modalHeader}>
                    {t('partnerTableFilter.selectPartner', 'Select partner')}
                    <CloseButton onClick={hideModal} />
                </div>
            </ModalHeader>
            <div className={classes.headerFunctionLine}>
                <div>
                    <GlobalSearchInput tableKey={TABLE_KEY} isSearchButton={false} isSearchIcon />
                </div>
                {showPartnerType && <div className={classes.partnerTypeContainer}><PartnerTypeRadio partnerTypeId={partnerTypeId} changePartnerType={changePartnerType} /></div>}
            </div>
            <ModalBody className={classes.modalBody}>
                <LoadSpinner value={tableData} isAutoDetect />
                <div className={classes.tableWrapper}>
                    <TableGroup
                        tableId={TABLE_KEY}
                        tableData={tableData}
                        tableColumns={columns}
                        isBasePageTable={false}
                        isCollapsible={false}
                        tableGroup={breakdown}
                        useRowSelection={isMultiselect}
                        className={classes.table}
                        tableFunctions={tableFunctions}
                        selectedRowIds={selectedRowIds || null}
                    />
                </div>
                <TableScrollerFooter tableKey={TABLE_KEY} isLoaded={isArray(tableData)} />
            </ModalBody>
            <ModalFooter>
                {isMultiselect ? (
                    <Button className={clsx(classes.footerButton, classes.addSelectedButton)} onClick={onAddSelectedButtonClicked}>
                        {t('default.addSelected', 'Add selected')}
                    </Button>
                ) : null}
                <Button type="secondary" className={classes.closeButton} onClick={hideModal}>
                    {t('default.close', 'Close')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
