import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import OutsideClickHandler from 'react-outside-click-handler';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { stringUtils } from 'shared/src/modules/stringUtils';
import useTranslate from '@i18n/useTranslate';
import { errorActions } from '@states/actions/errorActions';
import { cultivationPeriodsService } from '@services';
import Input from '../inputs/Input';
import Dropdown from '../dropdowns/Dropdown';

const useStyle = createUseStyles((theme: any) => ({
    regionContainer: {
        position: 'relative',
    },
    inputSpace: {
        marginTop: 15,
    },
    regionDropdown: {
        borderRadius: 4,
        top: 60,
        width: '100%',
        maxHeight: '205px',
        overflow: 'hidden',
        overflowY: 'auto',
        '&:hover': {
            cursor: 'pointer',
        },
        ...theme.verticalScrollbar,
    },
    regionElement: {
        fontSize: '15px',
        fontWeight: 'normal',
        padding: '10px 15px',
        color: theme.color.jet,
        background: theme.color.white,
        textAlign: 'left',
        outline: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '&:hover': {
            color: 'rgba(34, 195, 115)',
            background: 'rgba(34, 195, 115, 0.1)',
        },
    },
}));

export default function RegionInput() {
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [regionList, setRegionList] = useState([]);
    const [filteredRegionList, setFilteredRegionList] = useState<any>();
    const [showRegionList, setShowRegionList] = useState(false);
    const { values, handleChange, setFieldValue } = useFormikContext();
    const classes = useStyle();

    useEffect(() => {
        let isCancelled = false;
        async function loadAllRegion() {
            try {
                const regions = await cultivationPeriodsService.getAllRegions();
                const filteredRegions = regions.filter(regionObj => !isEmpty(regionObj.region));
                if (!isEmpty(filteredRegions) && !isCancelled) {
                    setRegionList(filteredRegions);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }

        if (isEmpty(regionList) && location) {
            loadAllRegion();
            return () => {
                isCancelled = true;
            };
        }
    }, [regionList, dispatch, location]);

    function handleFocusRegion(event) {
        const regionName = event.target.value;
        handleChange(event);
        const focusRegions = regionName ? regionList.filter((regionObj: any) => stringUtils.includesNoCase(regionObj.region, regionName)) : [];
        setShowRegionList(!isEmpty(focusRegions));
        if (!isEmpty(focusRegions)) {
            setFilteredRegionList(focusRegions);
        }
    }

    function setRegion(regionName) {
        setFieldValue('region', regionName);
        setShowRegionList(false);
    }

    return (
        <div className={classes.regionContainer}>
            <Input
                value={(values as any)?.region}
                onChange={event => handleFocusRegion(event)}
                name="region"
                label={t('newCultivationPeriod.region', 'Region')}
                className={classes.inputSpace}
            />
            <OutsideClickHandler onOutsideClick={() => setShowRegionList(false)}>
                <Dropdown show={showRegionList} close={() => setShowRegionList(false)} className={classes.regionDropdown}>
                    {filteredRegionList && filteredRegionList.map(regionObj => (
                        <div key={regionObj.region} className={classes.regionElement} onClick={() => setRegion(regionObj.region)} onKeyDown={() => setRegion(regionObj.region)} role="button" tabIndex="0">
                            {regionObj.region}
                        </div>
                    ))}
                </Dropdown>
            </OutsideClickHandler>
        </div>
    );
}
