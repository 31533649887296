import { RIGHTS, SYSTEM, SUBSCRIPTION, SETTINGS } from '../constants';

export const CULTIVATION_PERIODS = {
    MODULE_ID: 1,
    NAME: 'cultivationPeriods',
    RIGHTS: [],
    SETTINGS: [],
    EXCLUDE_COUNTRIES: [],
    INCLUDE_COUNTRIES: [],
    SYSTEM_PERMISSIONS: {
        //ADMINISTRATOR: false,
        //CONSULTANT: false,
    },
    EXCLUDE_SYSTEM_MODULES: [
        SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
        SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
        SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
        SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
    ],
    SUBSCRIPTION: true,
    SUBSCRIPTION_FUNCTIONS: [
        SUBSCRIPTION.NAME.CURRENT_WEATHER,
        SUBSCRIPTION.NAME.WEATHER_HISTORY,
        SUBSCRIPTION.NAME.WEATHER_FORECAST,
        SUBSCRIPTION.NAME.NDVI_5,
        SUBSCRIPTION.NAME.NDVI_15,
        SUBSCRIPTION.NAME.NDVI_FULL,
    ],
    COLOR_SUBSCRIPTION: true,
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/cultivationPeriods/menu/CultivationPeriods',
            EVENT_KEY: 'cultivationPeriods',
            SETTINGS_URL: 'setupcontent=1&menuindex=1',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.periods',
                TRANSLATOR_DEFAULT: 'Cultivation periods',
            },
        },
        CULTIVATION_PERIODS_MANAGEMENT: {
            CONTENT_URL_ID: { cultivationPeriodsManagement: 2 },
            COMPONENT_URL: 'contents/cultivationPeriods/cultivationPeriodsManagement/CultivationPeriodsManagement',
            EVENT_KEY: 'cultivationPeriodsManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsManagement',
                TRANSLATOR_DEFAULT: 'Cultivation periods management',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE],
            CREATE_NEW_RIGHTS: [RIGHTS.ADDING_CULTIVATION_PERIODS],
            EDIT_RIGHTS: [RIGHTS.ADDING_CULTIVATION_PERIODS],
        },
        COSTS_CHARGED_ON_THE_CULTIVATION_PERIOD: {
            CONTENT_URL_ID: { costsChargedOnTheCultivationPeriod: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'costChargedOnTheCultivationPeriod',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costChargedOnTheCultivationPeriod',
                TRANSLATOR_DEFAULT: 'Cost charged on the cultivaton period',
            },
        },
        YIELDS: {
            CONTENT_URL_ID: { yields: 4 },
            COMPONENT_URL: 'contents/cultivationPeriods/cultivationPeriodsYields/CultivationPeriodsYields',
            EVENT_KEY: 'cultivationPeriodsYields',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsYields',
                TRANSLATOR_DEFAULT: 'Cultivation periods yields',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        MATERIAL_CONSUPTION: {
            CONTENT_URL_ID: { materialConsumption: 5 },
            COMPONENT_URL: 'contents/cultivationPeriods/materialConsumption/MaterialConsumptionManagement',
            EVENT_KEY: 'cultivationPeriodsMaterialConsuption',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsMaterialConsuption',
                TRANSLATOR_DEFAULT: 'Cultivation periods material consuption',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        WORK_OPERATIONS: {
            CONTENT_URL_ID: { workOperations: 6 },
            COMPONENT_URL: 'contents/cultivationPeriods/workOperations/WorkOperationsManagement',
            //COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsWorkOperations',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsWorkOperations',
                TRANSLATOR_DEFAULT: 'Cultivation periods work operations',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        LAND_LEASE_FEE: {
            CONTENT_URL_ID: { landLeaseFee: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsLandLeaseFee',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsLandLeaseFee',
                TRANSLATOR_DEFAULT: 'Cultivation periods land lease fee',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        DRYING_FEE: {
            CONTENT_URL_ID: { dryingFee: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsDryingFee',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsDryingFee',
                TRANSLATOR_DEFAULT: 'Cultivation periods drying fee',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE],
        },
        OTHER_COSTS: {
            CONTENT_URL_ID: { otherCosts: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsOtherCosts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsOtherCosts',
                TRANSLATOR_DEFAULT: 'Cultivation periods other costs',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE],
            HAS_CURRENCY_SWITCH: true,

        },
        OTHER_COSTS_EDIT: {
            CONTENT_URL_ID: { otherCoastEdit: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsOtherCostsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsOtherCostsEdit',
                TRANSLATOR_DEFAULT: 'Cultivation periods other costs edit',
            },
        },
        INCOMES: {
            CONTENT_URL_ID: { incomes: 11 },
            RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE],
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsIncomes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsIncomes',
                TRANSLATOR_DEFAULT: 'Cultivation periods incomes',
            },
            HAS_CURRENCY_SWITCH: true,
        },
        CULTIVATION_PERIOD_EDIT: {
            CONTENT_URL_ID: { cultivationPeriodEdit: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodEdits',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodEdits',
                TRANSLATOR_DEFAULT: 'New cultivation period',
            },
            EDIT_TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodEdits',
                TRANSLATOR_DEFAULT: 'Cultivation periods edits',
            },
            RIGHTS: [RIGHTS.ADDING_CULTIVATION_PERIODS],
        },
        CULTURE: {
            CONTENT_URL_ID: { culture: 13 },
            //COMPONENT_URL: 'contents/PhpContent',
            COMPONENT_URL: 'contents/cultivationPeriods/cultivationPeriodsExpenses/CultivationPeriodsExpenses',
            EVENT_KEY: 'cultivationPeriodsCulture',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsCulture',
                TRANSLATOR_DEFAULT: 'Cultivation periods culture',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIOD_COST_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        COST: {
            CONTENT_URL_ID: { cost: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.periodsCost',
                TRANSLATOR_DEFAULT: 'Cultivation periods cost',
            },
            HAS_CURRENCY_SWITCH: true,
        },
        WORK_OPERATIONAL_CATEGORY_EDIT: {
            CONTENT_URL_ID: { workOperationalCategoryEdit: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workOperationalCategoryEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsYieldsWorkOperationalCategoryEdit',
                TRANSLATOR_DEFAULT: 'Cultivationo periods yield work operation category edit',
            },
            HAS_CURRENCY_SWITCH: true,
        },
        PRODUCT_COST: {
            CONTENT_URL_ID: { productCost: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsProductCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costOfCultivationPeriods',
                TRANSLATOR_DEFAULT: 'Cultivation periods product cost',
            },
            HAS_CURRENCY_SWITCH: true,
        },
        OTHER_COST_TYPES: {
            CONTENT_URL_ID: { cultivationPeriodsOtherCostTypes: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsOtherCostTypes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsOtherCostTypes',
                TRANSLATOR_DEFAULT: 'Cultivation periods other cost types',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.OTHER_COST_TYPE_INTERFACE], //settinsMenu
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        GROUPS: {
            CONTENT_URL_ID: { groups: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsGroups',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsGroups',
                TRANSLATOR_DEFAULT: 'Cultivation periods groups',
            },
            URL_PARAMS: '?setupcontent=1',
            RIGHTS: [RIGHTS.CULTIVATION_TABLES_ACCESS_INTERFACE],
            SETTING_MENU_RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE], //settinsMenu
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        CONSULTANCY: {
            CONTENT_URL_ID: { consultancy: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsConsultancy',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsConsultancy',
                TRANSLATOR_DEFAULT: 'Cultivation periods consultancy',
            },
            RIGHTS: [RIGHTS.CONSULTANCY_INTERFACE],
            SETTINGS: [
                SETTINGS.PERIOD_CONSULTING,
            ],
        },
        SUBSIDE: {
            CONTENT_URL_ID: { subsides: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsSubside',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsSubside',
                TRANSLATOR_DEFAULT: 'Cultivation periods subside',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.SUBSIDE_INTERFACE], //settinsMenu
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        CULTIVATION_TABLES: {
            CONTENT_URL_ID: { cultivationTables: 21 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationTables',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationTables',
                TRANSLATOR_DEFAULT: 'Cultivatioin tables',
            },
            RIGHTS: [RIGHTS.CULTIVATION_TABLES_ACCESS_INTERFACE],
            URL_PARAMS: '?setupcontent=1',
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        LAND_LEASING_FEE_COST_EDIT: {
            CONTENT_URL_ID: { landLeasingFeeCostEdit: 22 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landleasingFeeCostEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.landleasingFeeCostEdit',
                TRANSLATOR_DEFAULT: 'Landleasing Fee cost edit',
            },
        },
        DISASSEMBLY: {
            CONTENT_URL_ID: { disassembly: 23 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsDisassembly',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsDisassembly',
                TRANSLATOR_DEFAULT: 'Cultivation periods disassembly',
            },
        },
        CONTRACTION: {
            CONTENT_URL_ID: { contraction: 24 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cultivationPeriodsContraction',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsContraction',
                TRANSLATOR_DEFAULT: 'Cultivationo periods contraction',
            },
        },
        OTHER_REVENUE_EDIT: {
            CONTENT_URL_ID: { otherRevenueEdit: 25 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'otherRevenueEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.otherRevenueEdit',
                TRANSLATOR_DEFAULT: 'Edit other revenue',
            },
        },
        OTHER_REVENUE_TYPES: {
            CONTENT_URL_ID: { otherRevenueTypes: 26 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'otherRevenueTypes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.otherRevenueTypes',
                TRANSLATOR_DEFAULT: 'Other types of revenue',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.OTHER_REVENUE_TYPE_EDIT_INTERFACE], //settinsMenu
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        SCOUTINGS: {
            CONTENT_URL_ID: { scoutings: 27 },
            COMPONENT_URL: 'contents/cultivationPeriods/cultivationPeriodsScoutings/CultivationPeriodsScoutings',
            EVENT_KEY: 'cultivationPeriodsScoutings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsScoutings',
                TRANSLATOR_DEFAULT: 'Scoutings',
            },
            RIGHTS: [RIGHTS.SCOUTING_ACCESS_INTERFACE],
        },
        YIELD_DETAILS: {
            CONTENT_URL_ID: { yieldDetails: 28 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'yieldDetails',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.yieldItemDetails',
                TRANSLATOR_DEFAULT: 'Yield details',
            },
        },
        SOIL_ANALYSIS: {
            CONTENT_URL_ID: {
                soilAnalysis: 29,
            },
            COMPONENT_URL: 'contents/cultivationPeriods/soilAnalysis/SoilAnalysis',
            EVENT_KEY: 'soilAnalysis',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.soilAnalysis',
                TRANSLATOR_DEFAULT: 'Soil analysis',
            },
            SETTINGS: [
                { [SETTINGS.USE_GLOBAL_LITE]: 0 }
            ],
        },
        ANALYSIS_DATA_SERVICE: {
            CONTENT_URL_ID: {
                analysisDataService: 30,
            },
            COMPONENT_URL: 'contents/cultivationPeriods/analysisDataService/AnalysisDataService',
            EVENT_KEY: 'analysisDataService',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.analysisDataService',
                TRANSLATOR_DEFAULT: 'Analysis data service',
            },
        },
        SCOUTING_BLOCKS: {
            CONTENT_URL_ID: {
                scoutingBlocks: 31,
            },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'scoutingBlocks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.scoutingBlock',
                TRANSLATOR_DEFAULT: 'Scuting blocks',
            },
        },
        TASKS_MANAGEMENT: {
            CONTENT_URL_ID: {
                tasksManagement: 32,
            },
            COMPONENT_URL: 'contents/cultivationPeriods/tasks/TasksManagement',
            EVENT_KEY: 'tasksManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.tasksManagement',
                TRANSLATOR_DEFAULT: 'Tasks management',
            },
            RIGHTS: [RIGHTS.SCOUTING_TASK],
        },

        //---------------------------------------------------------------
        //PHP - old php contents - refactored -
        PHP_CULTIVATION_PERIODS_MANAGEMENT: {
            CONTENT_URL_ID: { phpCultivationPeriodsManagement: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'phpCultivationPeriodsManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsManagement',
                TRANSLATOR_DEFAULT: 'Cultivation periods management',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE],
        },
        PHP_YIELDS: {
            CONTENT_URL_ID: { phpYields: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'phpYields',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsYields',
                TRANSLATOR_DEFAULT: 'Cultivation periods yields',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIODS_ACCESS_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        PHP_CULTURE: {
            CONTENT_URL_ID: { phpCulture: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'phpCulture',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsCulture',
                TRANSLATOR_DEFAULT: 'Cultivation periods culture',
            },
            RIGHTS: [RIGHTS.CULTIVATION_PERIOD_COST_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
    },
};
