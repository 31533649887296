import { combineReducers } from 'redux';

import { alert } from './alertReducer';
import { basePage } from './basePageReducer';
import { company } from './companyReducer';
import { contentDom } from './contentDomReducer';
import { dataLists } from './dataListsReducer';
import { error } from './errorReducer';
import { router } from './routerReducer';
import { simpleTable } from './simpleTableReducer';
import { system } from './systemReducer';
import { user } from './userReducer';
import { modal } from './modalReducer';
import { tableFilter } from './tableFilterReducer';
import { translation } from './translationReducer';
import { inventoryTransferRequest } from './inventoryTransferRequestReducer';
import { workOperationsManagement } from './workOperationsManagementReducer';

const rootReducer = combineReducers({
    alert,
    basePage,
    company,
    contentDom,
    dataLists,
    error,
    router,
    simpleTable,
    system,
    user,
    modal,
    tableFilter,
    translation,
    inventoryTransferRequest,
    workOperationsManagement,
});

export default rootReducer;
