import { queryBuilder } from '@flowsCommon/services/queryBuilder';
import { sendServerRequest } from '@services/baseService';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { WORKOPERATION } from 'shared/src/constants';

export type WorkOperationDto = {
    id: number;
    name: string;
    isActive: number;
    colorCode: string;
    typeId: number;
}

export const useWorkOperationSelectModalService = () => {
    const workOperationTypeIds = Object.values(WORKOPERATION.TYPE_ID);

    const [workOperations, setWorkOperations] = useState<WorkOperationDto[]>([]);

    useEffect(() => {
        const getWorkOperationMainGroups = async () => {
            const queryParams = queryBuilder()
                .with('workoperationTypeIds', workOperationTypeIds.join(','))
                .with('mainGroupStatus', 1)
                .build();

            const response: WorkOperationDto[] = await sendServerRequest('GET', `/api/workOperations/workOperationsMaingroup?${queryParams.toString()}`);
            const unique: WorkOperationDto[] = [];

            response.forEach(resItem => {
                const findUniq = unique.find(uniq => uniq.id === resItem.id);
                if (isNil(findUniq)) {
                    unique.push(resItem);
                }
            });

            setWorkOperations(unique);
        };

        getWorkOperationMainGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [workOperations];
};
