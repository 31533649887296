import { useEffect, Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import { reduxStore } from '@states/store';
import { sentryService, hotjarService, googleAnalyticsService } from '@services';

import { loadStyles } from '@utils/php/PhpTagLoader';
import { theme } from '@styles/theme';
import '@styles/index.css';
import '@styles/components/bootstrap/bootstrap.css';

import AppLoadingSpinner from '@baseComponents/loadSpinner/AppLoadingSpinner';
import ScreenLoader from '@screens/ScreenLoader';
import TranslaterProvider from '@i18n/TranslaterProvider';

export default function App() {
    sentryService.initSentry();
    hotjarService.init();
    googleAnalyticsService.init();

    //php css-ek betöltése (hmr css szétesés miatt itt kell betöltenünk)
    useEffect(() => {
        if (!(window as any)?.isLoadedPhpDefaultStyles) {
            (window as any).isLoadedPhpDefaultStyles = true;
            loadStyles();
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <TranslaterProvider>
                <Suspense fallback={<AppLoadingSpinner />}>
                    <Provider store={reduxStore as any}>
                        <BrowserRouter>
                            <ScreenLoader />
                        </BrowserRouter>
                    </Provider>
                </Suspense>
            </TranslaterProvider>
        </ThemeProvider>
    );
}
