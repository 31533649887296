/* eslint-disable no-restricted-imports */
import MapIconButton from '../MapIconButton';

type TProps = {
    isMeasuring: boolean,
    toggleMeasure: () => void,
}

const MeasureToolComponent = ({ isMeasuring, toggleMeasure }: TProps) => <MapIconButton iconId={isMeasuring ? 'icon-trash' : 'icon-ruler'} onClick={() => toggleMeasure()} isActive={isMeasuring} />;

export default MeasureToolComponent;
