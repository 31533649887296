import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes, isCultureLayerAttribute } from '@map/services/mapEnums';
import { LayerAttribute, LayerAttributeStatus } from '@map/services/mapTypes';
import { getDataForLayer, selectedFeatureStyle, selectedFeatureStylePlanning, selectedFeatureStylePlanningWithInfoPanel } from '@map/utils/mapUtils';
import { isNil, isNumber } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import InfoPanelComponent from './InfoPanelComponent';

interface LastClickGeomety {
    attribute: LayerAttribute,
    feature: any;
}

type TProps = {
    isMapRoute: boolean
}

function InfoPanelContainer({ isMapRoute }: TProps) {
    const [store, controller] = useMapContext();
    const { isInfoModalOpen, layerId, geom } = store.selectedGeometry;

    const [selectedGeom, setSelectedGeom] = useState<any>(null);

    const [isLoadingData, setIsloadingData] = useState(false);

    const isBuiltInLayer = controller.getIsLayerBuiltInLayer(layerId);
    const selectedLayer = controller.getLayerById(layerId);

    useEffect(() => {
        setSelectedGeom(store.selectedGeometry.geom);
        const loadGeomData = async () => {
            if (store.mapSlider.isEnabled && isBuiltInLayer && !selectedLayer?.costFetched && selectedLayer?.name !== BuiltInLayerTypes.METEOROLOGY) {
                const entityId = store.selectedGeometry.geom?.properties?.id;
                if (!isNil(entityId)) {
                    setIsloadingData(true);
                    const layer = controller.getLayerById(layerId);
                    if (isNil(layer)) {
                        return;
                    }
                    const fetchData = await getDataForLayer({ layerName: layer.name, entityIds: [entityId], shouldFetchCosts: true, showClosedPeriods: true });
                    const geomData = fetchData.find(d => d.id === entityId);
                    if (!isNil(geomData)) {
                        setSelectedGeom(preSelectedGeom => ({
                            ...preSelectedGeom,
                            properties: {
                                ...store.selectedGeometry.geom,
                                ...(preSelectedGeom?.properties),
                                ...geomData,
                            },
                        }));
                    }
                    setIsloadingData(false);
                }
            }
        };
        loadGeomData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layerId, store.mapSlider.isEnabled, store.selectedGeometry.geom]);
    const additionalAttributes = (selectedLayer?.additionalAttributes ?? []).filter(adAttr => !isNil(selectedGeom?.properties) && Object.keys(selectedGeom?.properties).some(property => property === adAttr?.attributeKey));
    const attributes = (store.layering.attributes ?? []).filter(attribute => attribute.layerId === layerId && selectedGeom?.properties && Object.keys(selectedGeom.properties).some(property => property === attribute.attributeKey));
    const cultureAttributes = (store.layering.attributes ?? []).filter(attribute => attribute.layerId === layerId && isCultureLayerAttribute(attribute.attributeKey));

    const [geometryClickListeners, setGeometryClickListeners] = useState<Record<number, google.maps.MapsEventListener | null> | null>();
    const [geometryDoubleClickListeners, setGeometryDoubleClickListeners] = useState<Record<number, google.maps.MapsEventListener | null> | null>();

    const lastClickGeometry = useRef<LastClickGeomety | null>(null);
    const [geomId, setGeomId] = useState(0);

    const files = selectedGeom?.properties?.files;

    const restoreLastClickGeometryColor = () => {
        if (!isNil(lastClickGeometry.current)) {
            const fillColor = lastClickGeometry.current.feature?.getProperty('fillColor');
            const strokeColor = lastClickGeometry.current.feature?.getProperty('strokeColor');

            const isSelected = lastClickGeometry.current.feature?.getProperty('isSelected') ?? false;

            if (isSelected) {
                lastClickGeometry.current.attribute.dataLayer.overrideStyle(lastClickGeometry.current.feature, selectedFeatureStylePlanning);
            } else {
                lastClickGeometry.current.attribute.dataLayer.overrideStyle(lastClickGeometry.current.feature, {
                    fillColor,
                    strokeColor,
                });
            }

            lastClickGeometry.current.feature?.setProperty('isInfoPanelOpen', false);
        }
    };

    useEffect(() => {
        if (!geometryClickListeners && store.layering.attributes) {
            setGeometryClickListeners(store.layering.attributes.reduce((obj, attribute) => {
                if (attribute.isActive !== LayerAttributeStatus.ACTIVE) {
                    return { ...obj };
                }
                const listener = attribute.dataLayer.addListener('click', ev => {
                    controller.openGeometryInfoPanel(attribute.layerId, ev.feature);

                    restoreLastClickGeometryColor();

                    const lastClickGeom: LastClickGeomety = {
                        attribute,
                        feature: ev.feature,
                    };

                    const gId = ev.feature.getProperty('geomId');
                    setGeomId(gId);

                    lastClickGeometry.current = lastClickGeom;

                    const isSelected = lastClickGeometry.current.feature?.getProperty('isSelected') ?? false;

                    if (isSelected) {
                        attribute.dataLayer.overrideStyle(ev.feature, selectedFeatureStylePlanningWithInfoPanel);
                    } else {
                        attribute.dataLayer.overrideStyle(ev.feature, selectedFeatureStyle);
                    }

                    lastClickGeometry.current.feature?.setProperty('isInfoPanelOpen', true);
                });
                return { ...obj, [attribute.id]: listener };
            }, {}));
            setGeometryDoubleClickListeners(store.layering.attributes.reduce((obj, attribute) => {
                const listener = attribute.dataLayer.addListener('dblclick', ev => {
                    const idx = store.layering.order.indexOf(attribute.id);
                    const layerOrderCopy = [...store.layering.order];
                    [layerOrderCopy[0], layerOrderCopy[idx]] = [layerOrderCopy[idx], layerOrderCopy[0]];
                    controller.setLayerAttributeOrder(layerOrderCopy);
                });
                return { ...obj, [attribute.id]: listener };
            }, {}));
        }
        return () => {
            if (geometryClickListeners) {
                Object.keys(geometryClickListeners).forEach(clickListenerKey => google.maps.event.removeListener(geometryClickListeners[clickListenerKey]));
                setGeometryClickListeners(null);
            }
            if (geometryDoubleClickListeners) {
                Object.keys(geometryDoubleClickListeners).forEach(clickListenerKey => google.maps.event.removeListener(geometryDoubleClickListeners[clickListenerKey]));
                setGeometryDoubleClickListeners(null);
            }
        };
    }, [controller, geometryClickListeners, geometryDoubleClickListeners, store.layering.attributes, store.layering.order]);

    const closeGeometryInfoPanel = () => {
        restoreLastClickGeometryColor();
        controller.closeGeometryInfoPanel();
    };

    if (selectedLayer?.name === BuiltInLayerTypes.METEOROLOGY && !geom?.properties?.locationId) {
        return null;
    }

    if (!isInfoModalOpen || !geom) {
        return null;
    }

    return (
        <InfoPanelComponent
            close={closeGeometryInfoPanel}
            attributes={attributes}
            cultureAttributes={cultureAttributes}
            geom={selectedGeom}
            geomId={geomId}
            selectedLayer={selectedLayer}
            isLoadingData={isLoadingData}
            additionalAttributes={additionalAttributes}
            files={files}
            isMapRoute={isMapRoute}
        />
    );
}

export default InfoPanelContainer;
