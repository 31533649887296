import BaseStoreService from '@flowsCommon/services/BaseStoreService';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { Feature, FeatureCollection } from 'geojson';
import { cloneDeep, isNil, isNumber } from 'lodash';
import { BuiltInLayerTypes } from '../mapEnums';
import { TLayerCreateStore } from './layerCreateStore';
import { PairableEntity } from './layerCreateTypes';

export class LayerCreateStoreService extends BaseStoreService<TLayerCreateStore> {
    setUploadedFile = (fileName: string, featureCollection: FeatureCollection) => {
        this.setStore(draftStore => {
            draftStore.upload.fileName = fileName;
            draftStore.upload.featureCollection = featureCollection;
        });
    };

    setExistingLayerAttributes = (existingLayerAttributes: object) => {
        this.setStore(draftStore => {
            draftStore.upload.existingLayerAttributes = existingLayerAttributes;
        });
    }

    setFeatureCollection = (featureCollection: FeatureCollection) => {
        this.setStore(draftStore => {
            draftStore.upload.featureCollection = featureCollection;
        });
    }

    setExcludedEntities = (excludedEntityIds: number[]) => {
        this.setStore(draftStore => {
            draftStore.upload.excludedEntityIds = excludedEntityIds;
        });
    }

    setExcludedFeatures = (excludedFeatureIds: number[]) => {
        this.setStore(draftStore => {
            const prevExcludedIds = [...(draftStore.upload.excludedFeatureIds || [])];
            const newExcludedIds: number[] = [];

            excludedFeatureIds?.forEach(excludedId => {
                if (prevExcludedIds.includes(excludedId)) {
                    const idx = prevExcludedIds.indexOf(excludedId);
                    prevExcludedIds.splice(idx, 1);
                } else {
                    prevExcludedIds.push(excludedId);
                }
            });

            draftStore.upload.excludedFeatureIds = prevExcludedIds;
        });
    }

    setProductionYearId = (productionYearId: Nullable<number>) => {
        this.setStore(draftStore => {
            draftStore.upload.productionYearId = productionYearId;
        });
    }

    setPairableEntities = (layerId: number, pairableEntities: PairableEntity[]) => {
        this.setStore(draftStore => {
            draftStore.upload.selectedLayerId = layerId;
            draftStore.upload.pairableEntities = pairableEntities;
        });
    }

    setExistingLayerGeoms = (existingLayerFeatures: Feature[]) => {
        this.setStore(draftStore => {
            draftStore.upload.existingLayerFeatures = existingLayerFeatures;
        });
    }

    setCustomLayerFeatureData = (customLayerFeatureData: any[]) => {
        this.setStore(draftStore => {
            draftStore.upload.customLayerFeatureData = customLayerFeatureData;
        });
    }

    setUploadStore = store => {
        this.setStore(draftStore => {
            draftStore.upload = store;
        });
    }

    setPairableEntityFeatureId = (entityId: number, featureId: number) => {
        this.setStore(draftStore => {
            const entity = draftStore.upload.pairableEntities?.find(pairEntity => pairEntity.entityId === entityId);
            if (entity) {
                entity.featureId = featureId;
            }
        });
    }

    pairEntityIdsToFeautreIds = (entityFeatureConnections: object) => {
        this.setStore(draftStore => {
            const newPairableEntities = cloneDeep(draftStore.upload.pairableEntities);

            newPairableEntities?.forEach(pairEntity => {
                const entityId = pairEntity?.entityId;

                if (!isNil(entityId) && !isNil(entityFeatureConnections?.[entityId])) {
                    const pairFeatureId = entityFeatureConnections?.[entityId];

                    if (isNumber(pairFeatureId)) {
                        pairEntity.featureId = pairFeatureId;
                    }
                }
            });

            draftStore.upload.pairableEntities = newPairableEntities;
        });
    }

    unpairFeatureId = (featureId: number) => {
        this.setStore(draftStore => {
            const entity = draftStore.upload.pairableEntities?.find(pairEntity => pairEntity.featureId === featureId);
            if (entity) {
                entity.featureId = null;
            }
        });
    }

    setIsCustomLayerUpload = (isCustomLayerUpload: boolean) => {
        this.setStore(draftStore => {
            draftStore.upload.isCustomLayerUpload = isCustomLayerUpload;
        });
    }

    setCustomLayerPermissionIds = (companyIds?: number[], farmIds?: number[]) => {
        this.setStore(draftStore => {
            draftStore.upload.customLayerPermissions = {
                companyIds,
                farmIds,
            };
        });
    }

    setCustomLayerDates = (startDate: string, endDate: string) => {
        this.setStore(draftStore => {
            draftStore.upload.startDate = startDate;
            draftStore.upload.endDate = endDate;
        });
    }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export default LayerCreateStoreService;
