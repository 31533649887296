export const DATE = {
    A_WEEK: 7,
    TWO_WEEKS: 14,
    A_MONTH_IN_DAYS: 30,
    A_MONTH: 1,
    A_QUARTER_YEAR: 3,
    A_HALF_YEAR: 6,
    FIRST_DAY_OF_YEAR: 1,
    LAST_DAY_OF_YEAR: 31,
    FIRST_MONTH_OF_YEAR: 0,
    LAST_MONTH_OF_YEAR: 11,
    MONTHS_SHOW: 2,
    BREAKDOWN: {
        DAY: '24h',
        WEEK: '7d',
    },
    MAX_LENGTH: 4,
    MIN_VALUE: 1899,
    HOURS: {
        MIN: 0,
        MAX: 24,
    },
    SECONDS: {
        MIN: 0,
        MAX: 86400,
    },
    RECURRENCE_TYPE: {
        DAILY: 1,
        WEEKLY: 2,
        MONTHLY: 3,
        YEARLY: 4,
    },
    DAY_KEY: {
        MONDAY: 1,
        TUESDAY: 2,
        WEDNESDAY: 3,
        THURSDAY: 4,
        FRIDAY: 5,
        SATURDAY: 6,
        SUNDAY: 0,
    },
};
