import { useRef } from 'react';
import clsx from 'clsx';
import useInput from './useInput';
import './Input.scss';

export default function Input(props) {
    const { type = 'text', maxLength, placeholder, label, value, defaultValue, /*isValid, isInvalid,*/ isDisabled, className, setReference, isLeftAlign } = props;
    const { onChange, onFocus, onBlur, onKeyDown } = props;
    const { noBuilInBorders, noBuilInBackgorund } = props;
    const { positiveOnly, isInteger, min, max, numberFormat } = props; //formatter

    const inputRef = useRef<any>(null);

    const {
        getValue,
        onKeyUp,
        onKeyDownInput,
        onKeyPress,
        onChangeInput,
        onFocusInput,
        onBlurInput,
    } = useInput({ inputRef, setReference, type, onChange, onFocus, onBlur, onKeyDown, isInteger, min, max, defaultValue, numberFormat, value, positiveOnly });

    return (
        <input
            min={min}
            max={max}
            type="text"
            className={clsx(
                'input',
                'Input',
                ((type === 'text') && 'Input--simpleText'),
                ((type !== 'number' || isLeftAlign) && 'Input--leftAlign'),
                (isDisabled && 'Input--disabled'),
                (!(isDisabled || noBuilInBorders) && 'Input--withBorders'),
                (noBuilInBorders && 'Input--withOutBorders'),
                (noBuilInBackgorund && 'Input--withOutBackground'),
                className,
                'form-control',
            )}
            maxLength={maxLength}
            placeholder={placeholder}
            aria-label={label}
            aria-describedby={label}
            //isValid={isValid}
            //isInvalid={isInvalid}
            disabled={isDisabled}
            defaultValue={getValue(defaultValue)}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDownInput}
            onChange={onChangeInput}
            onFocus={onFocusInput}
            onBlur={onBlurInput}
            ref={inputRef}
            onKeyUp={onKeyUp}
        />
    );
}
