import TranslaterProvider from '@i18n/TranslaterProvider';
import { reduxStore } from '@states/store';
import { theme } from '@styles/theme';
import { ThemeProvider } from 'react-jss';
import { Provider } from 'react-redux';
import { MapWidgetComponent } from './MapWidgetComponent';

export function MapWidgetContainer() {
    return (
        <ThemeProvider theme={theme}>
            <TranslaterProvider>
                <Provider store={reduxStore}>
                    <MapWidgetComponent />
                </Provider>
            </TranslaterProvider>
        </ThemeProvider>);
}
