import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import { useDispatch } from 'react-redux';
import { alertActions, basePageActions, errorActions } from '@states/actions';
import { soilAnalysisService } from '@services';
import DeleteConfirmationModal from '@baseComponents/modals/DeleteConfirmationModal';

const useStyles = createUseStyles({
    deleteSoilAnalysis: {
        width: 500,
    },
});

export default function DeleteSoilAnalysisModal(props) {
    const { rowData, hideModal, onAfterHideModal, deleteByItemId = false, cultivationPeriodId } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();

    async function deleteSoilAnalysis() {
        try {
            const isSoilAnalysisDeleted = !deleteByItemId ? await soilAnalysisService.deleteSoilAnalysis(rowData.id) : await soilAnalysisService.deleteSoilAnalysisItemByPeriodId({
                cultivationPeriodId,
                soilAnalysisItemId: rowData.id,
            });

            if (isSoilAnalysisDeleted) {
                dispatch(alertActions.successAlert(t('deleteSoilAnalysis.deleteSoilAnalysisSuccesfully', 'Soil analysis successfully deleted')));
                dispatch(basePageActions.setReloadTable());
                hideModal();
                if (onAfterHideModal && isFunction(onAfterHideModal)) {
                    onAfterHideModal();
                }
            } else {
                dispatch(alertActions.errorAlert(t('deleteSoilAnalysis.deleteSoilAnalysisFailed', 'Soil analysis delete is failed')));
            }
        } catch (error) {
            dispatch(errorActions.setError(error, 'Delete cultivation period'));
        }
    }

    return (
        <DeleteConfirmationModal
            questionText={t('deleteSoilAnalysis.deleteSoilAnalysisData', 'Delete soil analysis data')}
            descriptionText={t('deleteSoilAnalysis.deleteSoilAnalysisSurely', 'Are you sure to delete the cultivation period?')}
            className={classes.deleteSoilAnalysis}
            handleDeleteClicked={deleteSoilAnalysis}
            hideModal={hideModal}
        />
    );
}
