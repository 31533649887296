import React from 'react';
import { createUseStyles } from 'react-jss';

import TableAggregateCell from '../aggregate/TableAggregateCell';
import TableGroupNameWithCollapser from '../tableParts/tableGroupNameWithCollapser/TableGroupNameWithCollapser';

const useStyle = createUseStyles({
    headerCell: {
        textAlign: 'left',
        display: 'flex',
        overflow: 'hidden',
        padding: 0,
        width: props => props.width || '100%',
        minWidth: props => props.minWidth,
        maxWidth: props => props.maxWidth,
        height: '100%',
        verticalAlign: 'top',
        paddingLeft: props => !props.isTitle && 10,
        alignItems: 'center',
        minHeight: 40,
    },
    aggregateValue: {
        height: 40,
    },
    aggregateFormatter: {
        lineHeight: '40px !important',
    },
});

export default function HeaderRowCell(props) {
    const { isDataCard, groupName, groupItemCount, canUseGroupOperation, isCollapsible, isCollapsed, isBasePageTable, onToggleCollapsable, cellWidth, filteredTableData, column } = props;
    const { aggregateType: hasColumnAggregate, width, minWidth, maxWidth, isTitle } = column;
    const { tableKey } = props; //simple table
    const classes = useStyle({ width: (cellWidth || width), minWidth: cellWidth || minWidth, maxWidth: cellWidth || maxWidth, isTitle, isBasePageTable });

    function isVisibleAggregateCell() {
        return (!isTitle && isCollapsed && hasColumnAggregate && !isDataCard) //table view
        || (!isTitle && hasColumnAggregate && isDataCard); //datacard view
    }

    return (
        <div className={classes.headerCell}>
            {isTitle
                && <TableGroupNameWithCollapser
                    classes={classes}
                    groupName={groupName}
                    groupItemCount={groupItemCount}
                    canUseGroupOperation={canUseGroupOperation}
                    isCollapsible={isCollapsible}
                    isCollapsed={isCollapsed}
                    isBasePageTable={isBasePageTable}
                    tableKey={tableKey}
                    onClick={onToggleCollapsable}
                />
            }
            {isVisibleAggregateCell()
                && <TableAggregateCell tableData={filteredTableData} column={column} style={classes} />
            }
        </div>
    );
}
