import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const employeeService = {
    getEmployees,
};

/**
 * Get all employees datas
 * @property {array<int>} companyIds IsOptional - Company ID list eg.: [1]
 * @property {int} employeesStatus IsOptional - Employees status eg.: 1
 * 0 - INACTIVE
 * 1 - ACTIVE
 * 2 - ALL (default)
 * @property {int} isStockRecorder IsOptional - Filter by stock recorder flag eg.: 1
 * @property {int} isSeasonalEmployee IsOptional - Filter by seasonal employee flag eg.: 1
 *
 * @return {array} Employees array
 */
async function getEmployees(props) {
    const url = routerHelper.getUrlWithParameters('/api/employees', props);
    const response = await sendServerRequest('GET', url);
    return response;
}
