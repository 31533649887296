import React from 'react';
import { isFunction } from 'lodash';
import DefaultErrorMessage from './DefaultErrorMessage';

interface ErrorBoundaryProps {
    logError?: (errorInfo: any) => void,
    ErrorDisplay?: any,
    onError?: any,
    children: any,
}

interface ErrorBoundaryState {
    error: any;
    errorInfo: any;
    shouldLogError: boolean;
    showDetails: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            errorInfo: null,
            shouldLogError: true, //(this.props.logError && isFunction(this.props.logError)) || false,
            showDetails: true,
        };

        //bindoljuk a függvényeket a megfelelő this kontextusra
        this.refreshPage = this.refreshPage.bind(this);
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo,
        });
        // eslint-disable-next-line no-console
        console.error({ error, errorInfo });

        if (this.state.shouldLogError && this.props.logError) {
            this.props.logError(errorInfo);
        }

        const { onError } = this.props;
        if (onError && isFunction(onError)) {
            onError({ ...this.state });
        }
    }

    refreshPage() {
        this.setState({
            error: null,
            errorInfo: null,
        });
    }

    render() {
        if (this.state.errorInfo) {
            return this.props.ErrorDisplay
                ? <this.props.ErrorDisplay {...this.state} {...this.props} />
                : <DefaultErrorMessage {...this.state} {...this.props} refreshPage={this.refreshPage} />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
