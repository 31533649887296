export const WIDGET = {
    MODULE_ID: 25,
    NAME: 'widget',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        WIDGET_USER: {
            CONTENT_URL_ID: { widgetUser: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'widget',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.home',
                TRANSLATOR_DEFAULT: 'Home',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
    },
};
