/* eslint-disable react-hooks/exhaustive-deps */
import SearchBar from '@baseComponents/inputs/SearchBar';
import { useMapContext } from '@map/services/mapContext';
import { ThemeType } from '@map/services/mapTypes';
import { companyActions } from '@states/actions';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { SETTINGS } from 'shared/src/constants';
import { stringUtils } from 'shared/src/modules';

type Classes = 'container' | 'searchContainer' | 'search' | 'searchVisible';

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        width: 34,
        height: 34,
        backgroundColor: (props: StyleProps) => (props.isActive ? theme.color.main : theme.color.white),
        boxShadow: theme.shadows.map,
        borderRadius: 6,
        margin: '8px 0',
        cursor: 'pointer',
        zIndex: 100,
    },
    searchContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        right: 13,
        top: 22,
    },
    search: {
        transition: '0.25s ease all',
        zIndex: 1,
        left: 0,
        '& svg': {
            fill: theme.color.jet,
        },
    },
    searchVisible: {
        left: -240,
        transition: '0.25s ease all',
    },
}));

type StyleProps = {
    isActive: boolean,
}

type Tprops = {
    onMapCenterChanged?: (map: google.maps.Map) => void;
    mapIsHovered: React.RefObject<boolean>;
}

export const MapSearchComponent = () => {
    const [isActive, setIsActive] = useState(false);
    const [searchValue, setSearchValue] = useState<string | null>('');
    const [store, controller] = useMapContext();

    const dispatch = useDispatch();

    const [isNonAccentSearch] = useState(dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)));
    
    const classes = useStyles({ isActive });

    function changeIsActive() {
        if (isActive) {
            setIsActive(false);
            setSearchValue('');
        } else {
            setIsActive(true);
        }
    }

    const onSearchChange = (value: string) => {
        const id = store.layering.order?.[0];
        const layerAttribute = store.layering.attributes?.find(attribute => attribute.id === id);

        if (!layerAttribute) { return; };

        const opacity = (layerAttribute.opacity ?? 0) / 100;
        const visibleMarkers: google.maps.Marker[] = [];

        let hasFeature: boolean = false;
        const bounds = new google.maps.LatLngBounds();

        layerAttribute.labelLayer.forEach(marker => {
            const title = marker.getTitle();
            const geomId = marker.get('geomId');

            const isVisible = isEmpty(value) || stringUtils.includesNoCase(title, value, isNonAccentSearch)
            const feature = layerAttribute.dataLayer.getFeatureById(geomId);

            if (!geomId || !feature) { return; };

            if (isVisible) {
                hasFeature = true;
                visibleMarkers.push(marker);
                feature.getGeometry()?.forEachLatLng(latLng => bounds.extend(latLng));
                layerAttribute.dataLayer.overrideStyle(feature, { fillOpacity: opacity, strokeOpacity: 0.8 });
            } else {
                layerAttribute.dataLayer.overrideStyle(feature, { fillOpacity: 0, strokeOpacity: 0 });
            }

            marker.setOpacity(isVisible ? 1 : 0);
        });

        
        if (store.layering.globalLabelLayerCluster) {
            store.layering.globalLabelLayerCluster.clearMarkers();
            store.layering.globalLabelLayerCluster.addMarkers(visibleMarkers);
            store.layering.globalLabelLayerCluster.render();
        }
    
        if (hasFeature) {
            store.googleRefs.map?.fitBounds(bounds);
        }
    };

    return (
        <div className={classes.searchContainer}>
            <SearchBar currentSearchValue={searchValue} onChange={onSearchChange} setSearchValue={setSearchValue} className={clsx(classes.search, isActive ? classes.searchVisible : null )} height={34} isCollapsible isCollapsed />
        </div>
    );
};
