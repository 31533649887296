import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { useFormContext } from 'react-hook-form';
import { isArray, isEmpty, isNil } from 'lodash';
import GroupOperationButton from '@baseComponents/basePageNew/body/bodyFunctionLine/bodyFunctionLineLeftSide/groupOperationButton/GroupOperationButton';
import useSoilAnalysisManualTable from './useSoilAnalysisManualTable';

const useStyles = createUseStyles((theme: any) => ({
    table: {
        position: 'relative',
        height: '100%',
    },
    tableWrapper: {
        height: '100%',
        '& .table-row': {
            paddingRight: 0,
        },
        '& .List': {
            ...theme.verticalScrollbar,
        },
        '& .bodyRow': {
            height: 56,
        },
        '& .tableHeaderRow': {
            marginRight: 0,
        },
    },
    tableScrollerFooter: {
        paddingTop: 7,
        background: theme.color.transparent,
    },
}));

export default function SoilAnalysisManualTable(props) {
    const { tableId, cultivationPeriodId } = props;
    const cultivationPeriodList = useSelector((state: any) => state.basePage.table.tableData || []);
    const simpleTableData = useSelector((state: any) => state.simpleTable.table?.[tableId]?.tableData);
    const selectedRowIds = useSelector((state: any) => state.simpleTable.table?.[tableId]?.selectedItemIds);

    const classes = useStyles();
    const { watch, setValue } = useFormContext();

    const [tableGroup] = useState(
        {
            value: null,
            text: 'No breakdown',
        },
    );

    const tableData = useMemo(() => {
        if (isEmpty(watch('soilAnalysisData'))) {
            const cultivationPeriod = cultivationPeriodList.find(period => period.id === cultivationPeriodId);

            const newRow: any = {
                rowId: 1,
                id: 1,
            };
            if (!isNil(cultivationPeriod)) {
                newRow.cultivationPeriod = { id: cultivationPeriod.id, name: cultivationPeriod.name, companyName: cultivationPeriod.companyName };
                newRow.cultivationPeriodCompanyName = cultivationPeriod.companyName;
            }

            setValue('soilAnalysisData', [newRow]);
            setValue('rowId', 1);
            return [newRow];
        }

        return watch('soilAnalysisData');
    }, [watch, setValue, simpleTableData, cultivationPeriodId, cultivationPeriodList]);

    const { columns } = useSoilAnalysisManualTable({ tableId });

    return (
        <>
            <div className={classes.table}>
                <LoadSpinner value={!watch('isLoading')} />
                <GroupOperationButton tableKey={tableId} />
                <TableGroup
                    useRowSelection
                    tableId={tableId}
                    tableData={tableData}
                    tableColumns={columns}
                    isBasePageTable={false}
                    isCollapsible={false}
                    tableGroup={tableGroup}
                    className={classes.tableWrapper}
                    selectedRowIds={selectedRowIds}
                    onListItemsRendered={() => setValue('isLoading', false)}
                />
            </div>
            <div className={classes.tableScrollerFooter}>
                <TableScrollerFooter tableKey={tableId} isLoaded={isArray(tableData)} useRowSelection />
            </div>
        </>
    );
}
