import React, { useEffect, useState } from 'react';
import { isEmpty, isNil } from 'lodash';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { alertActions, basePageActions, errorActions } from '@states/actions';
import { useDispatch, useSelector } from 'react-redux';
import { cultivationPeriodsService } from '@services';
import DeleteConfirmationModal from '@baseComponents/modals/DeleteConfirmationModal';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { visiblefarmService } from '@services/visiblefarmService';

const useStyles = createUseStyles({
    deleteCultivationPeriod: {
        width: 500,
        opacity: props => (props.isHideNeeded ? '0' : '1'),
    },
});

export default function DeleteCultivationPeriodModal(props) {
    const { cultivationPeriodIds, hideModal } = props;
    const [warnings, setWarnings] = useState(null);
    const agentCompanyId = useSelector((state: any) => state.company.company.globalData.id);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles({isHideNeeded: isNil(warnings)});

    async function deleteCultivationPeriod() {
        try {
            const [isCultivationPeriodDeleted, vfIds] = await cultivationPeriodsService.deleteCultivationPeriod({ cultivationPeriodIds });

            if (isCultivationPeriodDeleted) {
                if (!isEmpty(vfIds)) {
                    const periodRequests = vfIds.map(async periodId => visiblefarmService.deletePeriod({
                        agentId: agentCompanyId,
                        av_id: periodId,
                        periodType: 0,
                    }));
                    await Promise.all(periodRequests);
                }

                dispatch(alertActions.successAlert(t('deleteCultivationPeriod.cultivationPeriodDeleted', 'Cultivation period successfully deleted')));
                dispatch(basePageActions.setReloadTable());
                hideModal();
            } else {
                dispatch(alertActions.errorAlert(t('deleteCultivationPeriod.cultivationPeriodDeleteFailed', 'Cultivation period delete is failed')));
            }
        } catch (error) {
            dispatch(errorActions.setError(error, 'Delete cultivation period'));
        }
    }

    function getDeleteTitle() {
        if (cultivationPeriodIds.length > 1) {
            return t('deleteCultivationPeriod.deleteCultivationPeriods', 'Delete cultivation periods');
        }
        return t('deleteCultivationPeriod.deleteCultivationPeriod', 'Delete cultivation period');
    }

    function getDeleteQuestion() {
        if (isEmpty(warnings)){
            if (cultivationPeriodIds.length > 1) {
                return t('deleteCultivationPeriod.sureDeleteCultivationPeriods', 'Are you sure to delete the selected cultivation periods?');
            }
            return t('deleteCultivationPeriod.sureDeleteCultivationPeriod', 'Are you sure to delete the cultivation period?');
        }
        else {
            const modalTokens : any = [];
            const modalDependencyTokens : any = [];

            if (cultivationPeriodIds.length > 1) {
                modalTokens.push(t('deleteCultivationPeriod.sureDeleteCultivationPeriodsDependantData', 'Are you sure to delete the selected cultivation periods? They have the following dependencies:'));
            }
            else {
                modalTokens.push(t('deleteCultivationPeriod.sureDeleteCultivationPeriodDependantData', 'Are you sure to delete the cultivation period? It has the following dependencies:'));
            }
            
            if (warnings?.['hasPeriodChargedCost']){
                modalDependencyTokens.push(t('deleteCultivationPeriod.chargedCost', 'charged cost'));
            }

            if (warnings?.['hasPeriodOtherIncome']){
                modalDependencyTokens.push(t('deleteCultivationPeriod.otherIncome', 'other income'));
            }

            if (warnings?.['hasPeriodReclassifiedCost']){
                modalDependencyTokens.push(t('deleteCultivationPeriod.reclassifiedCost', 'reclassified cost'));
            }

            modalTokens.push( modalDependencyTokens.join(", ") );

            return modalTokens.join(" ");
        }
    }

    useEffect(() => {
        async function checkDeleteConditions() {
            const deletionMap = await cultivationPeriodsService.checkCultivationPeriodDeleteConditions(cultivationPeriodIds);

            let hasPeriodChargedCost = false;
            let hasPeriodOtherIncome = false;
            let hasPeriodReclassifiedCost = false;

            Object.keys(deletionMap).forEach(periodId => {
                if (deletionMap[periodId]['chargedCost']) {
                    hasPeriodChargedCost = true;
                }

                if (deletionMap[periodId]['otherIncome']) {
                    hasPeriodOtherIncome = true;
                }

                if (deletionMap[periodId]['reclassifiedCost']) {
                    hasPeriodReclassifiedCost = true;
                }
            });

            setWarnings({
                ...(hasPeriodChargedCost ? {hasPeriodChargedCost: true} : {}),
                ...(hasPeriodOtherIncome ? {hasPeriodOtherIncome: true} : {}),
                ...(hasPeriodReclassifiedCost ? {hasPeriodReclassifiedCost: true} : {}),
            });
        }

        checkDeleteConditions();
    }, []);

    return (
        <div>
            <LoadSpinner isAutoDetect value={!isNil(warnings)} />
            <DeleteConfirmationModal
                questionText={getDeleteTitle()}
                descriptionText={getDeleteQuestion()}
                className={classes.deleteCultivationPeriod}
                handleDeleteClicked={deleteCultivationPeriod}
                hideModal={hideModal}
            />
        </div>
    );
}
