import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    dot: {
        position: 'absolute',
        borderRadius: '50%',
        height: (props: any) => (props.count ? 15 : 4),
        minWidth: (props: any) => (props.count ? 15 : 4),
        boxShadow: '0 0 5px 0 rgba(255, 0, 85, 0.5)',
        border: 'solid 3px rgba(255, 0, 85, 0.1)',
        backgroundColor: theme.color.destructive,
        fontSize: '12px',
        fontWeight: 'bold',
        letterSpacing: '-0.5px',
        textAlign: 'center',
        color: theme.color.white,
        boxSizing: 'initial',
    },
}));

export default function Notification(props) {
    const { className, count } = props;
    const classes = useStyles({ count });

    return (
        <span className={clsx(classes.dot, className)}>
            {count}
        </span>
    );
}
