import { isUndefined } from 'lodash';

function simpleAction(type, payload?: any) {
    const payloadObj = isUndefined(payload) ? null : payload;

    return {
        type,
        payload: payloadObj,
    };
}

export { simpleAction };
