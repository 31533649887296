import { routerHelper } from '@utils/router/RouterHelper';
import { dateTimeUtils, objectUtils } from 'shared/src/modules';
import { isEmpty } from 'lodash';
import { sendServerRequest } from './baseService';

export const inventoryTransferRequestService = {
    getInventoryTransferRequestList,
    updateInventoryTransferRequestsStatus,
    getProductTransfersByRequestIds,
    setInventoryRequestCertificateStorno,
};

/**
 * Get inventory transfer request list
 * @property {list<int>} companyIds IsRequired - Company ID list eg.: [1]
 * @property {boolean} isShowClosedStatus IsOptional - Show inventory transfer request closed status flag eg.: 1
 * @property {boolean} isShowRejectedStatus IsOptional - Show inventory transfer request rejected status flag eg.: 1
 * @property {date} startDate IsOptional - Filter inventory transfer request by date eg.: 2021-10-01
 * @property {date} endDate IsOptional - Filter inventory transfer request by date eg.: 2021-10-31
 *
 * @return {list} Inventory transfer request list
 */
async function getInventoryTransferRequestList(props) {
    const url = routerHelper.getUrlWithParameters('/api/inventoryTransferRequest', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get inventory transfer request products to start product release or start product reposession (inventory transfer request edit modal)
 * @property {list<int>} requestIds IsRequired - Inventory transfer request ID list eg.: [1]
 * @property {boolean} isReturnTransfer IsRequired - Inventory transfer request is release or reposession flag eg.: 1
 * 0 - releasing (kiadás)
 * 1 - reposession (visszavételezés)
 *
 * @return {list} Inventory transfer request products list
 */
async function getProductTransfersByRequestIds(props) {
    const url = routerHelper.getUrlWithParameters('/api/inventoryTransferRequest/productTransfers', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Set inventory transfer request status
 * @property {list<int>} requestId IsRequired - Inventory transfer request ID or ID list eg.: [1]
 * @property {int} statusId IsRequired - Inventory transfer request status ID eg.: 1
 * @property {dateTime} dateTime IsOptional - Inventory transfer request date eg.: '2021-10-19 14:37:00'
 * @property {object} requestIdsWithStatusObj IsOptional - Inventory transfer request id with status object
 *
 * @return {boolean} Is success save flag
 */
async function updateInventoryTransferRequestsStatus(props) {
    const { dateTime = dateTimeUtils.getDefaultDateTimeNow(), requestIdsWithStatus } = props;

    let requestIdsWithStatusJson: any = null;
    if (!isEmpty(requestIdsWithStatus)) {
        requestIdsWithStatusJson = objectUtils.parseObjectToJson(requestIdsWithStatus);
    }

    const url = routerHelper.getUrlWithParameters('/api/inventoryTransferRequest/updateStatus', { ...props, dateTime, requestIdsWithStatus: requestIdsWithStatusJson });
    const response = await sendServerRequest('PATCH', url);
    return response;
}

/**
 * Storno inventory transfer request certificate
 * @property {int} requestId IsRequired - Inventory transfer request ID eg.: [1]
 *
 * @return {boolean} Is success save flag
 */
async function setInventoryRequestCertificateStorno(props) {
    const url = routerHelper.getUrlWithParameters('/api/inventoryTransferRequest/inventoryRequestCertificateStorno', props);
    const response = await sendServerRequest('PATCH', url);
    return response;
}
