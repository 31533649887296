import callApi from '@flowsCommon/services/ApiHandler';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry } from 'geojson';
import { CreateLayerDto, GeomDto, GeomToShpDto, GetCustomLayerDataResponseDto, GetLayerDto, LayerDto, PostGeomRequestDto, ProcessGeometryDto, UpdateLayerDto } from '@map/services/mapTypes';
import {
    DefaultCenterDto, GeomEntity, GeomSaveEntity, GeomSvgDto, GeomToSvgDto, LayerGeometriesWithBboxDto, LayersDto, MachineDataResponse,
} from './mapTypes';

export const fetchMockGisToken = async (body: any) => callApi<{accessToken: string}>('POST', '/gis-api/map/login', body);
export const fetchLayers = async (query: any) => callApi<LayersDto>('GET', '/gis-api/layers', query);
export const fetchMapDefaultCenter = async () => callApi<DefaultCenterDto>('GET', '/gis-api/map/center');
export const fetchGeometriesForLayers = async (query: any) => callApi<LayerGeometriesWithBboxDto>('GET', '/gis-api/layer/geometries', query);
export const uploadLayerFile = async (formData: any) => callApi<any>('POST', '/gis-api/layer/upload', formData);
export const processGeometryFile = async (formData: any) => callApi<ProcessGeometryDto>('POST', '/gis-api/geom/process-file', formData);
export const fetchGeometry = async (query: any) => callApi<Feature>('GET', '/gis-api/geom', query);
export const diffGeometries = async (body: any) => callApi<Geometry>('PUT', '/gis-api/geom/difference', body);
export const unionGeometries = async (body: any) => callApi<Geometry>('PUT', '/gis-api/geom/union', body);
export const saveGeometries = async (body: PostGeomRequestDto) => callApi<GeomEntity[]>('POST', '/gis-api/geom', body);
export const updateGeometry = async (id : number, body: GeomSaveEntity) => callApi<GeomEntity[]>('PUT', `/gis-api/geom/${id}`, body);
export const deleteGeometry = async (id : number) => callApi<GeomEntity[]>('DELETE', `/gis-api/geom/${id}`);
export const getGeomertyByEntityIdLayerId = async (query : any) => callApi<GeomEntity[]>('POST', '/gis-api/geom', query);
export const getShapefileOfGeometry = async ({ body, geomId }: {body: {properties: GeoJsonProperties}, geomId: number}) => callApi<Blob>('PUT', `/gis-api/geom/${geomId}/shapefile`, body, true);
export const getShapefileOfGeometires = async (body: any) => callApi<Blob>('POST', '/gis-api/geom/geom-to-shp', body, true);
export const getSvgFromGeometry = async (body: GeomToSvgDto[]) => callApi<GeomSvgDto[]>('POST', '/gis-api/geom/geom-to-svg', body);
export const getExcludedEntityIds = async (query: any) => callApi<number[]>('GET', '/gis-api/geom/excluded-entity-ids', query);
export const fetchMachines = async () => callApi<MachineDataResponse[]>('GET', '/gis-api/layer/machines');
export const getLayer = async (layerId: number) => callApi<GetLayerDto>('GET', `/gis-api/layer/data/${layerId}`);
export const createLayer = async (body: CreateLayerDto) => callApi<any>('POST', '/gis-api/layer/data', body);
export const updateLayer = async (layerId, body: UpdateLayerDto) => callApi<any>('PUT', `/gis-api/layer/data/${layerId}`, body);
export const removeLayer = async (layerId : number) => callApi<any>('DELETE', `/gis-api/layer/data/${layerId}`);
export const findGeometryByEntityId = async (query: any) => callApi<GeomDto>('GET', '/gis-api/geom/findOne', query);
export const getGeomAttributeData = async (query: any) => callApi<any[]>('GET', '/gis-api/geom/attribute-data', query);
export const getCustomLayerAttributes = async (layerId: number) => callApi<GetCustomLayerDataResponseDto>('GET', `/gis-api/layer/custom-layer-data?layerId=${layerId}`, '');
