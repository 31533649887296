import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { RIGHTS, SETTINGS } from 'shared/src/constants';
import useTranslate from '@i18n/useTranslate';
import { stringUtils } from 'shared/src/modules/stringUtils';
import { dataListsActions, userActions, companyActions } from '@states/actions';
import FormSelect from '@baseComponents/select/formSelect/FormSelect';
import renderModal from '@modals/modalNew/modalFunctions';
import NewCultureVarietyModal from '@customComponents/modals/newCultureVarietyModal/NewCultureVarietyModal';

const useStyle = createUseStyles((theme: any) => ({
    selectDropdown: {
        marginTop: 15,
        '& .dropdown-menu': {
            transform: 'translate(0px, 42px) !important',
            width: '100%',
        },
        '& .dropdown-item': {
            height: 40,
            padding: '10px 15px',
            ...theme.overFlowText,
            maxWidth: '100%',
        },
        '& #selectDropdownList': {
            maxWidth: 318,
        },
    },
}));

export default function CropForm(props) {
    const { cultureList } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const reproductionRates = useSelector((state: any) => state.dataLists.reproductionRates);
    const [isAddNewVarietyPermissions] = useState(dispatch(userActions.hasEveryPermissions([RIGHTS.ADD_VARIETY])));
    const { values, setFieldValue } = useFormikContext();
    const [filteredVarietyList, setFilteredVarietyList] = useState(values.varietyList);
    const [filteredCulture, setFilteredCulture] = useState(cultureList);
    const [isNonAccentSearch] = useState(dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)));

    const classes = useStyle();

    useEffect(() => {
        setFilteredCulture(cultureList);
    }, [cultureList, setFilteredCulture]);

    function addNewVariety() {
        renderModal(NewCultureVarietyModal, {
            onSuccess: newVariety => {
                setNewVariety({
                    key: newVariety.id,
                    culture: newVariety.cultureId,
                    value: newVariety.name,
                    isActive: +newVariety.isActive,
                });
            },
            defaultCultureId: values.crop.culture,
        }, true);
    }

    function addNewReproductionRate() {
        window.addNewReproductionRate(setNewReproductionRate);
    }

    function setNewReproductionRate(newReproductionRate) {
        if (!isEmpty(newReproductionRate)) {
            dispatch(dataListsActions.addDataList([...reproductionRates, {
                name: newReproductionRate.value,
                id: newReproductionRate.key,
            }], 'reproductionRates'));
            setFieldValue('reproductionRateList', [...values.reproductionRateList, newReproductionRate]);
            setFieldValue('crop', { ...values.crop, reproductionRate: newReproductionRate.key, reproductionRateName: newReproductionRate.value });
        }
    }

    function setNewVariety(newVariety) {
        if (!isEmpty(newVariety) && newVariety.isActive && +values.crop.culture === newVariety.culture) {
            setFieldValue('varietyList', [...values.varietyList, newVariety]);
            setFilteredVarietyList(varietyObj => [...varietyObj, newVariety]);
            setFieldValue('crop', { ...values.crop, variety: newVariety.key, varietyName: newVariety.value });
        }
    }

    function search(value, dataList, setList) {
        if (!isEmpty(value) && !isEmpty(dataList)) {
            setList(dataList.filter(dataObj => stringUtils.includesNoCase(dataObj.value, value, isNonAccentSearch)));
        } else {
            setList(dataList);
        }
    }

    function selectCulture(value) {
        if (+value > 0) {
            const cultureName = cultureList?.find(cultureObj => cultureObj.key === +value)?.value;
            setFieldValue('crop', { ...values.crop, culture: value, cultureName, variety: '', varietyName: '' });
        } else {
            setFieldValue('crop', { ...values.crop, culture: value, cultureName: '', variety: '', varietyName: '' });
        }
    }

    function selectVariety(value) {
        if (+value > 0){
            const varietyName = values?.varietyList?.find(variety => variety.key === +value)?.value;
            setFieldValue('crop', { ...values.crop, variety: value, varietyName });
        } else {
            setFieldValue('crop', { ...values.crop, variety: '', varietyName: '' });
        }
    }

    function selectReproductionRate(value) {
        const reproductionRateName = values?.reproductionRateList?.find(reproductionRateObj => reproductionRateObj.key === +value)?.value;
        setFieldValue('crop', { ...values.crop, reproductionRate: value, reproductionRateName });
    }

    return (
        <>
            <FormSelect
                dataList={filteredCulture}
                selected={values.crop && values.crop.culture}
                onChange={selectCulture}
                label={t('cropForm.culture', 'Culture')}
                search={value => search(value, cultureList, setFilteredCulture)}
                className={classes.selectDropdown}
                disabled={values.crop.inPeriodGroup}
                isClearItem
            />
            <FormSelect
                dataList={filteredVarietyList}
                selected={values.crop.variety}
                onChange={selectVariety}
                search={value => search(value, values.varietyList, setFilteredVarietyList)}
                addNew={isAddNewVarietyPermissions && addNewVariety}
                label={t('cropForm.variety', 'Variety')}
                className={classes.selectDropdown}
                isClearItem
            />
            <FormSelect
                dataList={values.reproductionRateList}
                selected={values.crop && values.crop.reproductionRate}
                onChange={selectReproductionRate}
                addNew={addNewReproductionRate}
                label={t('cropForm.reproductionRate', 'Reproduction rate')}
                className={classes.selectDropdown}
                isClearItem
            />
        </>
    );
}
