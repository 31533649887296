import { useLayerCreateContext } from '@map/services/layerCreate/layerCreateContext';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import LayerUploadModalAttach from './LayerUploadModalAttach';
import LayerUploadModalBrowse from './LayerUploadModalBrowse';
import LayerUploadModalFiles from './LayerUploadModalFiles';
import LayerUploadModalPair from './LayerUploadModalPair/LayerUploadModalPair';
import { LayerUploadSteps } from './modalSteps.enum';
import { ModalStepProps } from './types';

export const LayerUploadComponents = {
    [LayerUploadSteps.BROWSE]: (props: ModalStepProps) => <LayerUploadModalBrowse {...props} />,
    [LayerUploadSteps.FILES]: (props: ModalStepProps) => <LayerUploadModalFiles {...props} />,
    [LayerUploadSteps.ATTACH]: (props: ModalStepProps) => <LayerUploadModalAttach {...props} />,
    [LayerUploadSteps.PAIR]: (props: ModalStepProps) => <LayerUploadModalPair {...props} />,
};

type TProps = {
    setIsUploadWindowOpen: (isOpen: boolean) => void,
}

export default function LayerUploadModal({ setIsUploadWindowOpen }: TProps) {
    const [store, controller] = useLayerCreateContext();
    const [step, setStep] = useState<LayerUploadSteps>(LayerUploadSteps.BROWSE);

    const onClose = () => {
        controller.resetUploadStore();
        setIsUploadWindowOpen(false);
    };

    return ReactDOM.createPortal(
        <>
            <div className="modal-backdrop show" />
            {LayerUploadComponents[step]({
                setStep,
                close: onClose,
            })}
        </>,
        document.getElementById('portal-root') as Element,
    );
}
