/* eslint-disable react-hooks/exhaustive-deps */
import Button from '@baseComponents/buttons/Button';
import { useMapContext } from '@map/services/mapContext';
import { ThemeType } from '@map/services/mapTypes';
import clsx from 'clsx';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';

type Classes = 'container' | 'buttonText' | 'line' | 'disabledButtonText' | 'buttonZoomIn' | 'button';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        // height: 81,
        // background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        // overflow: 'hidden',
    },
    line: {
        height: 0,
        width: '70%',
        borderColor: '#eee',
        margin: 0,
    },
    buttonText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        userSelect: 'none',
        fontSize: 30,
        margin: 0,
        fontWeight: 900,
        padding: 0,
        height: 18,
    },
    disabledButtonText: {
        color: theme.color.grayFaded,
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 4,
        maxWidth: 34,
        maxHeight: 34,
    },
    buttonZoomIn: {
        marginBottom: 8,
    },
}));

type Tprops = {
    onMapCenterChanged?: (map: google.maps.Map) => void;
    mapIsHovered: React.RefObject<boolean>;
}

export const ZoomControlComponent = ({ onMapCenterChanged, mapIsHovered }: Tprops) => {
    const classes = useStyles();

    const [store, controller] = useMapContext();
    const [zoomLevel, setZoomLevel] = useState(7);
    const [isZoomListenerActive, setisZoomListenerActive] = useState(false);

    const maxZoomLevel = store.zoomControl.maxLevel;
    const minZoomLevel = store.zoomControl.minLevel;

    const mapIsDragged = useRef<boolean>(false);

    const mapListeners = useRef<google.maps.MapsEventListener[]>([]);

    const onZoomIn = () => {
        setZoomLevel(preZoomLevel => {
            if (preZoomLevel === maxZoomLevel) {
                return zoomLevel;
            }
            const nextZoomLevel = zoomLevel + 1;
            store.googleRefs.map?.setZoom(nextZoomLevel);
            store.googleRefs.map?.getZoom();
            return nextZoomLevel;
        });
        if (store.googleRefs.map && onMapCenterChanged) {
            onMapCenterChanged(store.googleRefs.map);
        }
    };

    const onZoomOut = () => {
        setZoomLevel(preZoomLevel => {
            if (preZoomLevel === minZoomLevel) {
                return zoomLevel;
            }
            const nextZoomLevel = zoomLevel - 1;
            store.googleRefs.map?.setZoom(nextZoomLevel);
            return nextZoomLevel;
        });
        if (store.googleRefs.map && onMapCenterChanged) {
            onMapCenterChanged(store.googleRefs.map);
        }
    };

    useEffect(() => {
        controller.setZoomLevel(zoomLevel);
    }, [zoomLevel, controller]);

    useEffect(() => {
        if (!store.googleRefs.map || isZoomListenerActive === true) {
            return;
        }
        //initial zoomlevel
        const mapZoomLevel = store.googleRefs.map.getZoom();
        if (!isNil(mapZoomLevel)) {
            setZoomLevel(mapZoomLevel);
        }

        //zoom listener
        const zoomListener = store.googleRefs.map.addListener('zoom_changed', () => {
            try {
                const mapListenerZoomeLevel = store.googleRefs.map?.getZoom();
                if (!isNil(mapListenerZoomeLevel)) {
                    setZoomLevel(mapListenerZoomeLevel);
                }
                if (store.googleRefs.map && onMapCenterChanged && mapIsHovered.current) {
                    onMapCenterChanged(store.googleRefs.map);
                }
            } catch { }
        });

        const dragStartListener = store.googleRefs.map.addListener('dragstart', () => {
            mapIsDragged.current = true;
        });
        const dragEndListener = store.googleRefs.map.addListener('dragend', () => {
            mapIsDragged.current = false;
        });
        const centerChangedListener = store.googleRefs.map.addListener('center_changed', () => {
            if (mapIsDragged.current === true || mapIsHovered.current === true) {
                if (store.googleRefs.map && onMapCenterChanged) {
                    onMapCenterChanged(store.googleRefs.map);
                }
            }
        });

        mapListeners.current.push(zoomListener);
        mapListeners.current.push(dragStartListener);
        mapListeners.current.push(dragEndListener);
        mapListeners.current.push(centerChangedListener);
        mapListeners.current.push(zoomListener);

        setisZoomListenerActive(true);
    }, [store.googleRefs.map, setisZoomListenerActive, isZoomListenerActive, onMapCenterChanged]);

    useEffect(() => {
        mapListeners.current.forEach(listener => {
            google.maps.event.removeListener(listener);
        });
    }, []);

    return (
        <div className={classes.container}>
            <Button onClick={onZoomIn} iconPosition="after" type="secondary" disabled={zoomLevel === maxZoomLevel} className={clsx(classes.button, classes.buttonZoomIn)}>
                <p className={clsx({ [classes.buttonText]: true, [classes.disabledButtonText]: zoomLevel === maxZoomLevel })}>+</p>
            </Button>

            <Button onClick={onZoomOut} type="secondary" disabled={zoomLevel === minZoomLevel} className={classes.button}>
                <p className={clsx({ [classes.buttonText]: true, [classes.disabledButtonText]: zoomLevel === minZoomLevel })}>-</p>
            </Button>
        </div>);
};
