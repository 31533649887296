import { createUseStyles } from 'react-jss';
import { InputGroup } from 'react-bootstrap';
import Svg from '@baseComponents/Svg';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    inputPretend: {
        fontFamily: 'rubik',
        cursor: (props: any) => (props.onClick ? 'pointer' : 'default'),
        display: 'flex',
        marginRight: -1,
        background: theme.color.white,
        '& .input-group-text': {
            display: 'flex',
            alignItems: 'center',
            padding: '.3rem .75rem',
            marginBottom: 0,
            fontSize: 15,
            fontWeight: 300,
            lineHeight: 1.5,
            color: theme.color.darkGray,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            background: theme.color.white,
            border: `1px solid ${theme.color.midLightGray}`,
            borderRadius: '.25rem',

            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        '&:hover': {
            border: (props: any) => props.onClick && `1px solid ${theme.color.darkGray}`,
            boxShadow: (props: any) => props.onClick && `0 2px 3px 0 ${theme.color.gray}`,
        },
    },
}));

export default function InputPretend(props) {
    const { text, children, icon, className, title, onClick } = props;
    const classes = useStyles({ onClick });

    return (
        <InputGroup.Prepend className={clsx('inputPretend', classes.inputPretend, className)} title={title} onClick={onClick}>
            <InputGroup.Text>
                {icon
                    ? <Svg iconId={icon} height="1rem" width="1rem" />
                    : children || text
                }
            </InputGroup.Text>
        </InputGroup.Prepend>
    );
}
