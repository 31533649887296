import React, { useCallback, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useForm } from 'react-hook-form';
import useTranslate from '@i18n/useTranslate';
import { yupResolver } from '@hookform/resolvers/yup';
import FormGroup from '@customComponents/form/FormGroup';
import Input from '@baseComponents/inputs/Input';
import Textarea from '@baseComponents/inputs/Textarea';
import Radio from '@baseComponents/controls/Radio';
import Button from '@baseComponents/buttons/Button';
import { SimpleSelect } from '@baseComponents/select';
import { UnitInput } from '@baseComponents/inputs/inputField';
import FormDatePicker from '@customComponents/datePickers/formCalendars/FormDatePicker';
import FarmDropdown from '@customComponents/dropdowns/farmDropdown';
import { ModalBody, ModalHeader, ModalContentWrapper, ModalFooter } from '@baseComponents/modals/modalNew';
import { OpenModalButton } from '@baseComponents/modals';
import PartnersModal from '@customComponents/modals/partnersModal/PartnersModal';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { useNumberFormat } from '@common_modules/numberFormat';
import { PARTNER } from 'shared/src/constants';
import useNewBasicFieldData from './useNewBasicFieldData';
import useNewBasicFieldLoadData from './useNewBasicFieldLoadData';

const useStyles = createUseStyles({
    modalWrapper: {
        width: 750,
        height: 'auto',
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
    },
    modalContainer: {
        display: 'flex',
    },
    modalSide: {
        width: '100%',
        flex: '1',
        margin: 16,
    },
    flexRow: {
        display: 'flex',
        minHeight: 30,
        '& > *': {
            marginBottom: 0,
        },
        '& > *:not(:first-child)': {
            marginLeft: 16,
        },
    },
    datePicker: {
        width: '100%',
        display: 'block',
        '& > *': {
            width: '100%',
        },
    },
    textArea: {
        marginTop: 0,
    },
    footerButton: {
        width: 220,
    },
    emptySpace: {
        height: 50,
        marginBottom: 20,
    },
    textAreaContainer: {
        padding: [0, 16],
    },
});

export default function NewBasicField(props) {
    const { hideModal, isEdit = false, editedFieldId, onSuccess } = props;

    const classes = useStyles();
    const { t } = useTranslate();

    const { numberFormatter } = useNumberFormat();
    const areaFormat = useCallback(() => numberFormatter.getAreaFormats(), [numberFormatter]);

    const { settings, defaultValues, validationSchema, nhaCategories, onSubmit } = useNewBasicFieldData({ hideModal, onSuccess });

    const { handleSubmit, watch, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...defaultValues,
        },
        mode: 'onChange',
    });

    const { isLoading } = useNewBasicFieldLoadData({ setValue, isEdit, editedFieldId, hideModal });

    /*useEffect(() => {
        if (isInitialized && numberFormatter) {
            const quantity = numberFormatter.getProductQuantityFormats();
            setQuantityFormats(quantity);
        }
    }, [isInitialized, numberFormatter]);*/

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {!isEdit ? t('newBasicField.headerNew', 'New basic field') : t('newBasicField.headerEdit', 'Edit basic field')}
            </ModalHeader>

            <ModalBody className={classes.modalBody}>
                {isEdit && isLoading && <LoadSpinner />}
                <div className={classes.modalContainer}>
                    <div className={classes.modalSide}>
                        <FormGroup label={t('newBasicField.status', 'Status')}>
                            <div className={classes.flexRow}>
                                <Radio text={t('newBasicField.active', 'Active')} checked={!!watch('isActive') === true} onChange={() => setValue('isActive', true)} />
                                <Radio text={t('newBasicField.inactive', 'Inactive')} checked={!!watch('isActive') === false} onChange={() => setValue('isActive', false)} />
                            </div>
                        </FormGroup>

                        {settings?.usingFarm ? (
                            <FormGroup>
                                <FarmDropdown farmId={getValues('farmId')} setFarmId={value => setValue('farmId', value)} />
                            </FormGroup>
                        ) : null
                        }

                        <FormGroup label={t('newBasicField.companyOrPartner', 'Company / Partner')} required errorMessage={errors?.partnerId?.message} hasValue={watch('partnerId')}>
                            <OpenModalButton
                                currentSelectedValue={watch('partnerId')}
                                modalComponent={PartnersModal}
                                modalComponentProps={{ isMultiselect: false, defaultPartnerType: PARTNER.TYPES.COMPANY }}
                                id={watch('partnerId')}
                                name={watch('partnerName')}
                                onChange={partner => {
                                    setValue('isOwnTable', partner?.typeId !== PARTNER.TYPES.PARTNER);
                                    setValue('partnerId', partner?.id);
                                    setValue('partnerName', partner?.name);
                                }}
                            />
                        </FormGroup>

                        <FormGroup label={t('newBasicField.name', 'Name')} required hasValue={watch('name')} errorMessage={errors?.name?.message}>
                            <Input value={watch('name')} onChange={event => setValue('name', event?.target?.value)} />
                        </FormGroup>

                        <FormGroup label={t('newBasicField.cultivatedArea', 'Cultivated Area')} required hasValue={watch('cultivatedArea')} errorMessage={errors?.cultivatedArea?.message}>
                            <UnitInput
                                value={getValues('cultivatedArea')}
                                onBlur={(_event, value) => setValue('cultivatedArea', value)}
                                numberFormat={areaFormat()}
                                isLeftAlign
                            />
                        </FormGroup>

                    </div>

                    <div className={classes.modalSide}>

                        <div className={classes.emptySpace} />

                        <FormGroup label={t('newBasicField.creationDate', 'Creation Date')} required hasValue={watch('creationDate')} errorMessage={errors?.creationDate?.message}>
                            <FormDatePicker className={classes.datePicker} date={getValues('creationDate')} setDate={value => setValue('creationDate', value)} />
                        </FormGroup>

                        <FormGroup label={t('newBasicField.terminationDate', 'Termination Date')} hasValue={watch('terminationDate')}>
                            <FormDatePicker className={classes.datePicker} date={getValues('terminationDate')} setDate={value => setValue('terminationDate', value)} />
                        </FormGroup>

                        <FormGroup label={t('newBasicField.nhaCategoryLabel', 'NHA Area Category')}>
                            <SimpleSelect selected={getValues('isNhaCategory')} dataList={nhaCategories} onChange={value => setValue('isNhaCategory', +value)} />
                        </FormGroup>

                        <FormGroup label={t('newBasicField.tableLocation', 'Table Location')}>
                            <OpenModalButton
                                hasDeleteButton
                                isPhpModal
                                phpIdKey="id"
                                phpValueKey="telepules"
                                modalComponent={window.settlementModal}
                                id={watch('settlementId')}
                                name={watch('tableLocation')}
                                onChange={settlement => {
                                    setValue('tableLocation', settlement?.telepules);
                                    setValue('settlementId', +settlement?.id);
                                }}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className={classes.textAreaContainer}>
                    <FormGroup label={t('newBasicField.description', 'Description')}>
                        <Textarea value={watch('description')} handleChange={event => setValue('description', event?.target?.value)} className={classes.textArea} />
                    </FormGroup>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button size="large" className={classes.footerButton} onClick={handleSubmit(onSubmit)}>
                    {!isEdit ? t('newBasicField.modalAddButton', 'ADD NEW FIELD') : t('newBasicField.modalSaveButton', 'SAVE CURRENT FIELD')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
