export interface MapSliderMinMax {
    endDate: Date,
    startDate: Date,
}

export interface MapSliderInterval {
    intervals: MapSliderMinMax[];
    endDateText?: string,
    startDateText?: string,
    currentIndex?: number;
    currentDateText?: string;
}

export interface MapSliderState {
    index: number;
    value: number;
    valueNow: number;
}

export enum MapSliderIntervalMode {
    YEAR = 1,
    MONTH = 2,
    WEAK = 3,
}

export type MapSliderDatePicker = {
    date: Date,
    hasValue?: boolean,
}

export type MapSliderOptions = {
    min: number,
    max: number,
    datePicker: MapSliderDatePicker[],
    defaultValue: number,
}

export type MapSliderCache = {
    mode: MapSliderIntervalMode
    min: Date,
    max: Date,
    datePicker: MapSliderDatePicker[],
}
