import { Nullable } from '@flowsCommon/services/baseTypes';
import { Feature, FeatureCollection } from 'geojson';
import { BuiltInLayerTypes } from '../mapEnums';
import { PairableEntity } from './layerCreateTypes';

export type TLayerCreateStore = {
    common: {
        isGeomRefreshNeeded: boolean,
    }
    upload: {
        loading: boolean,
        fileName: string,
        featureCollection: Nullable<FeatureCollection>,
        pairableEntities: Nullable<PairableEntity[]>,
        selectedLayerId: Nullable<number>,
        isCustomLayerUpload: boolean;
        excludedEntityIds: Nullable<number[]>,
        excludedFeatureIds: Nullable<number[]>,
        productionYearId: Nullable<number>,
        existingLayerFeatures: Nullable<Feature[]>,
        startDate?: Nullable<string>;
        endDate?: Nullable<string>;
        existingLayerAttributes?: Nullable<object>;
        customLayerFeatureData?: Nullable<any[]>;
        customLayerPermissions?: {
            farmIds?: Nullable<number[]>,
            companyIds?: Nullable<number[]>,
        },
    }
};

export const initStore: TLayerCreateStore = {
    common: {
        isGeomRefreshNeeded: false,
    },
    upload: {
        loading: false,
        fileName: '',
        featureCollection: null,
        pairableEntities: null,
        selectedLayerId: null,
        excludedEntityIds: null,
        excludedFeatureIds: null,
        productionYearId: null,
        existingLayerFeatures: null,
        isCustomLayerUpload: false,
    },
};
