import { tableFilterActions } from '../actions/tableFilterActions';
import { deleteProperty, setLoadingStates, setSingleLoadingState } from '../utils';

const initialState = {
    downloadParams: null,
    loadingStates: {
        isLoading: false,
        errorMessage: '',
        hasLoadingError: false,
    },
    subFilterLoadingStates: null,
    filterTypes: null,
    filterOptions: null,
    outerFilterConditions: null,
    currentFilters: {
        dateRange: {
            startDate: null,
            endDate: null,
        },
    },
    filters: {
        dateRange: {
            startDate: null,
            endDate: null,
        },
    },
};

export function tableFilter(state = initialState, action) {
    switch (action.type) {
        case tableFilterActions.SET_STATIC_PARAMETER_TO_FILTER_DATA: {
            const { staticParams } = action.payload;
            return {
                ...state,
                downloadParams: {
                    ...state.downloadParams || {},
                    staticParams,
                },
            };
        }
        case tableFilterActions.SET_TABLE_CURRENT_FILTER_DATA: {
            const { filterValue, filterName } = action.payload;
            return {
                ...state,
                currentFilters: {
                    ...state.currentFilters,
                    [filterName]: filterValue,
                },
            };
        }
        case tableFilterActions.INIT_TABLE_FILTER_DATA: {
            const { filters, downloadParams, initialOptionData: { filterOptions, subFilterLoadingStates, filterTypes } } = action.payload;
            return {
                ...state,
                subFilterLoadingStates,
                filterOptions,
                filterTypes,
                currentFilters: filters,
                filters,
                downloadParams,
            };
        }
        case tableFilterActions.RESET_TABLE_FILTER: {
            const { fixedFilterStates } = action.payload;
            const resetFilters = (fixedStates, currentState) => {
                const resetState = {
                    dateRange: state.currentFilters.dateRange,
                    ...fixedStates,
                };
                Object.keys(currentState).forEach(filter => {
                    if (!Object.prototype.hasOwnProperty.call(fixedStates, filter) && filter !== 'dateRange') {
                        resetState[filter] = [];
                    }
                });
                return resetState;
            };
            const newFilters = resetFilters(fixedFilterStates, state.currentFilters);
            return {
                ...state,
                currentFilters: {
                    ...newFilters,
                },
                filters: {
                    ...newFilters,
                },
            };
        }
        case tableFilterActions.SET_BASE_FILTERS: {
            const filters = action.payload;
            return {
                ...state,
                filters,
            };
        }
        case tableFilterActions.APPLY_TABLE_CURRENT_FILTER_DATA: {
            return {
                ...state,
                filters: state.currentFilters,
            };
        }
        case tableFilterActions.SET_TABLE_CURRENT_FILTER_DATA_FROM_SAVED_FILTER:
            return {
                ...state,
                currentFilters: state.filters,
            };
        case tableFilterActions.DELETE_TABLE_FILTER_TAG: {
            const { filterCategory } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [filterCategory]: [],
                },
                currentFilters: {
                    ...state.currentFilters,
                    [filterCategory]: [],
                },
            };
        }
        case tableFilterActions.DELETE_TABLE_FILTER_MODAL_SELECTION: {
            const { filterCategory } = action.payload;
            const newCurrentFilters = deleteProperty(filterCategory, state.currentFilters);
            return {
                ...state,
                currentFilters: newCurrentFilters,
            };
        }
        case tableFilterActions.SET_SINGLE_FILTER_OPTIONLIST: {
            const { key, options } = action.payload;
            return {
                ...state,
                filterOptions: {
                    ...state.filterOptions || {},
                    [key]: options,
                },
            };
        }
        case tableFilterActions.GET_FILTER_OPTIONS_REQUESTED: {
            const { filterName } = action.payload;
            return {
                ...state,
                subFilterLoadingStates: setLoadingStates(filterName, state.subFilterLoadingStates, true),
            };
        }
        case tableFilterActions.GET_FILTER_OPTIONS_FAILED: {
            const { errorMessage, filterName } = action.payload;
            return {
                ...state,
                subFilterLoadingStates: setLoadingStates(filterName, state.subFilterLoadingStates, false, true, errorMessage),
            };
        }
        case tableFilterActions.GET_FILTER_OPTIONS_SUCCEEDED: {
            const { filterName } = action.payload;
            return {
                ...state,
                subFilterLoadingStates: setLoadingStates(filterName, state.subFilterLoadingStates, false, false, ''),
            };
        }
        case tableFilterActions.SET_OUTER_FILTER_CONDITIONS: {
            const { outerFilterConditions } = action.payload;
            return {
                ...state,
                outerFilterConditions,
            };
        }
        case tableFilterActions.GET_TABLE_DATA_REQUESTED:
            return {
                ...state,
                loadingStates: setSingleLoadingState(state.loadingStates, true),
            };
        case tableFilterActions.GET_TABLE_DATA_SUCCEEDED: {
            return {
                ...state,
                loadingStates: setSingleLoadingState(state.loadingStates, false, false, ''),
            };
        }
        case tableFilterActions.GET_TABLE_DATA_FAILED: {
            const { errorMessage } = action.payload;
            return {
                ...state,
                loadingStates: setSingleLoadingState(state.loadingStates, false, true, errorMessage),
            };
        }
        case tableFilterActions.DELETE_ALL_FILTERS:
            return initialState;
        default:
            return state;
    }
}
