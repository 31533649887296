import renderModal from '@modals/modalNew/modalFunctions';
import { useMapContext } from '@map/services/mapContext';
import { MapMode } from '@map/services/mapEnums';
import { LayerAttribute } from '@map/services/mapTypes';
import { selectedFeatureStyle, selectedFeatureStylePlanning, selectedFeatureStylePlanningWithInfoPanel } from '@map/utils/mapUtils';
import { theme } from '@styles/theme';
import { isEmpty, isNil, isNumber } from 'lodash';
import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'react-jss';
// eslint-disable-next-line no-restricted-imports
import PlanningEditModal from '@map/components/ContextMenu/modals/PlanningEditModal';
import { reduxStore } from '@states/store';
import { Provider } from 'react-redux';
import { ContextMenuPlanning } from './ContextMenuPlanning';
import { PlanningCultivationPeriod } from './modals/PlanningEditModal/planningEditModalTypes';

type TProps = {
    mapMode: MapMode;
    planningEditDataRef?: RefObject<PlanningCultivationPeriod[] | undefined>;
    onSavePlanningEditModal?: (editedData: PlanningCultivationPeriod[]) => void;
}

export const ContextMenuContainer = ({ mapMode, planningEditDataRef, onSavePlanningEditModal }: TProps) => {
    const [store, controller] = useMapContext();

    const [geometryClickListeners, setGeometryClickListeners] = useState<Record<number, google.maps.MapsEventListener | null> | null>();
    const selectedLayerAttributeId = useRef<number>();

    const selectedFeatureIds = useRef<number[]>([]);

    const selectFeature = (layerAttribute: LayerAttribute, feature: any) => {
        const entityId = Number(feature.getProperty('entityId'));

        const isInfoPanelOpen = feature?.getProperty('isInfoPanelOpen') ?? false;

        if (!isNil(entityId)) {
            selectedFeatureIds.current = [...selectedFeatureIds.current, entityId];
            if (isInfoPanelOpen) {
                layerAttribute.dataLayer.overrideStyle(feature, selectedFeatureStylePlanningWithInfoPanel);
            } else {
                layerAttribute.dataLayer.overrideStyle(feature, selectedFeatureStylePlanning);
            }
            feature.setProperty('isSelected', true);
        }
    };

    const unSelectFeature = (layerAttribute: LayerAttribute, feature: any) => {
        const entityId = Number(feature.getProperty('entityId'));

        feature.setProperty('isSelected', false);
        const removeAtIndex = selectedFeatureIds.current.indexOf(entityId);
        if (removeAtIndex !== -1) {
            selectedFeatureIds.current.splice(removeAtIndex, 1);
        }

        const isInfoPanelOpen = feature?.getProperty('isInfoPanelOpen') ?? false;

        if (isInfoPanelOpen) {
            layerAttribute.dataLayer.overrideStyle(feature, selectedFeatureStyle);
        } else {
            const fillColor = feature.getProperty('fillColor');
            const strokeColor = feature.getProperty('strokeColor');
            layerAttribute.dataLayer.overrideStyle(feature, {
                fillColor,
                strokeColor,
            });
        }
    };

    const onCloseEditModal = useCallback(() => {
        if (!isNil(selectedLayerAttributeId.current)) {
            const activeAttribute = store.layering?.attributes?.find(attr => attr.id === selectedLayerAttributeId.current);
            activeAttribute?.dataLayer.forEach(feature => {
                const entityId = Number(feature.getProperty('entityId'));
                if (selectedFeatureIds.current.includes(entityId)) {
                    unSelectFeature(activeAttribute, feature);
                }
            });
        }
    }, [store.layering?.attributes]);

    const editFeatures = useCallback(() => {
        const planAttribute = store.layering.attributes?.find(attr => attr.id === selectedLayerAttributeId.current);

        if (!isNil(planAttribute)) {
            const planLayer = store.layering.layers?.find(layer => planAttribute.layerId === layer.id);
            const planId = planLayer?.planId;

            if (!isNil(planId)) {
                const entityIds = [...selectedFeatureIds.current];
                const planningEditProps: any = {
                    entityIds,
                    planId,
                    productionYearId: store.filters.productionYearId,
                    mapStore: store,
                    mapController: controller,
                };

                if (mapMode === MapMode.PLANNING_EDIT) {
                    planningEditProps.onSavePlanningEditModal = onSavePlanningEditModal;
                    planningEditProps.planningEditData = planningEditDataRef?.current?.filter(planningEditDataFiltered => entityIds.includes(planningEditDataFiltered.id));
                }
                // eslint-disable-next-line no-undefined
                renderModal(PlanningEditModal, planningEditProps, true, null, true);
                onCloseEditModal();
            }
        }
    }, [controller, onCloseEditModal, store, onSavePlanningEditModal, planningEditDataRef, mapMode]);

    useEffect(() => {
        const currentSelectedLayerAttributeId = selectedLayerAttributeId.current;
        const firstLayerAttributeId = store.layering.order?.[0];
        const firstLayerAttribute = store.layering.attributes?.find(attr => attr.id === firstLayerAttributeId);

        if (!geometryClickListeners && store.layering.attributes && !isNil(firstLayerAttribute)) {
            if (isNil(firstLayerAttributeId)) {
                return;
            }

            if (!isNil(currentSelectedLayerAttributeId) && currentSelectedLayerAttributeId === firstLayerAttributeId) {
                return;
            }

            selectedLayerAttributeId.current = firstLayerAttributeId;

            if (!isNil(firstLayerAttribute)) {
                const planLayer = store.layering.layers?.find(layer => firstLayerAttribute.layerId === layer.id);
                const leftClickListener = mapMode === MapMode.PLANNING_EDIT ? firstLayerAttribute.dataLayer.addListener('click', ev => {
                    const isFeatureSelected = ev.feature.getProperty('isSelected') ?? false;

                    if (!isFeatureSelected) {
                        selectFeature(firstLayerAttribute, ev.feature);
                    }
                    editFeatures();
                }) : null;

                const listener = firstLayerAttribute.dataLayer.addListener('contextmenu', ev => {
                    const entityId = Number(ev.feature.getProperty('entityId'));

                    if (mapMode !== MapMode.PLANNING_EDIT && (isNil(planLayer?.isPlanningOpen) || planLayer?.isPlanningOpen === false)) {
                        return;
                    }

                    if (!isNil(entityId) && isNumber(entityId)) {
                        const isFeatureSelected = ev.feature.getProperty('isSelected') ?? false;
                        const isMenuHidden = isEmpty(selectedFeatureIds.current);

                        if (isFeatureSelected) {
                            unSelectFeature(firstLayerAttribute, ev.feature);
                        } else {
                            selectFeature(firstLayerAttribute, ev.feature);
                        }

                        if (!isNil(store.googleRefs.map && isMenuHidden)) {
                            const existingNode = document.getElementById('contextMenuContainer');

                            if (existingNode) {
                                existingNode.remove();
                                ReactDOM.unmountComponentAtNode(existingNode);
                            }

                            const contextMenu = document.createElement('div');
                            contextMenu.id = 'contextMenuContainer';

                            ReactDOM.render(
                                <ThemeProvider theme={theme}>
                                    <Provider store={reduxStore}>
                                        <ContextMenuPlanning
                                            selectedFeatureIds={selectedFeatureIds.current}
                                            onClose={() => {
                                                contextMenu.remove();
                                                ReactDOM.unmountComponentAtNode(contextMenu);
                                            }}
                                            editFeatures={() => editFeatures()}
                                            unSelectAllFeature={() => { onCloseEditModal(); selectedFeatureIds.current = []; contextMenu.remove(); }}
                                        />
                                    </Provider>
                                </ThemeProvider>,
                                contextMenu,
                            );
                            store.googleRefs.map?.getDiv().appendChild(contextMenu);
                        }
                    }
                });

                const listeners = [listener];
                if (leftClickListener) {
                    listeners.push(leftClickListener);
                }
                setGeometryClickListeners(listeners);
            }
        }
        return () => {
            if (geometryClickListeners) {
                if (!isNil(currentSelectedLayerAttributeId) && currentSelectedLayerAttributeId === firstLayerAttributeId) {
                    return;
                }

                if (!isNil(firstLayerAttribute)) {
                    firstLayerAttribute.dataLayer.forEach(layerFeature => {
                        const fillColor = layerFeature.getProperty('fillColor');
                        const strokeColor = layerFeature.getProperty('strokeColor');
                        firstLayerAttribute.dataLayer.overrideStyle(layerFeature, {
                            fillColor,
                            strokeColor,
                        });
                        layerFeature.setProperty('isSelected', false);
                    });
                }

                selectedFeatureIds.current = [];
                // eslint-disable-next-line no-undefined
                selectedLayerAttributeId.current = undefined;

                Object.keys(geometryClickListeners).forEach(clickListenerKey => google.maps.event.removeListener(geometryClickListeners[clickListenerKey]));
                setGeometryClickListeners(null);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controller, editFeatures, geometryClickListeners, store.googleRefs.map, store.layering.attributes, store.layering.order]);

    return null;
};
