import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { arrayUtils, numberUtils } from 'shared/src/modules';
import { useTranslation } from 'react-i18next';
import { TABLE, UNITS } from 'shared/src/constants';
import { Quantity } from '@baseComponents/formatters';

const useStyles = createUseStyles((theme: any) => ({
    quantityPerAreaCellWrapper: {
        height: '100%',
        width: '100%',
        fontSize: 12,
        fontWeight: 'normal',
        color: theme.color.raven,
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function QuantityCell(props) {
    const { value, rowData, secondLineId, unitId, cellId, defaultUnit } = props;
    const [formattedCellId] = useState(cellId ? `${cellId}_${rowData.id}` : null);
    const multilineViewType = useSelector((state: any) => state.basePage.table.multilineViewType);
    const [unit, setUnit] = useState(defaultUnit || null);
    const { t } = useTranslation();
    const classes = useStyles();

    //set unit
    useEffect(() => {
        if (unitId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, unitId);
            setUnit(columnItem);
        }
    }, [unitId, rowData]);

    function getQuantityPerArea() {
        if (secondLineId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, secondLineId);
            return columnItem || 0;
        }
        return 0;
    }

    if (!numberUtils.isNumber(value)) {
        return null;
    }

    return (
        <div className={classes.quantityPerAreaCellWrapper}>
            {(multilineViewType === TABLE.VIEW_TYPE.SIMPLE || !secondLineId)
                && <Quantity value={value} unit={unit} cellId={formattedCellId} />
            }
            {multilineViewType === TABLE.VIEW_TYPE.VALUE_PER_UNIT && secondLineId
                && <Quantity value={getQuantityPerArea()} unit={`${unit}/${t('default.areUnit', UNITS.DEFAULT_AREA)}`} cellId={formattedCellId} />
            }
        </div>
    );
}
