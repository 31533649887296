import CurrencySwitch from '@baseComponents/basePageNew/body/bodyFunctionLine/bodyFunctionLineRightSide/currencySwitch/CurrencySwitch';
import { useMapContext } from '@map/services/mapContext';
import { isNil } from 'lodash';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

export const MapCurrencySwitch = () => {
    const primaryCurrency = useSelector((state: any) => state.company.company.currency.primaryCurrency);
    const secondaryCurrency = useSelector((state: any) => state.company.company.currency.secondaryCurrency);
    const currentCurrency = useSelector((state: any) => state.company.company.currency.currentCurrency);

    const [store, controller] = useMapContext();
    const currency = useRef();

    useEffect(() => {
        const setLabelling = async () => {
            if (isNil(store.googleRefs.google)) {
                return;
            }

            const layerAttributeKeys = [
                'totalCostServicePerHectare',
                'directCostPerHectare',
                'productCostPerHectare',
                'machineCostPerHectare',
                'wageCostPerHectare',
                'operationCostPerHectare',
                'totalCostPerHectare',
            ];

            const isPrimaryCurrency = primaryCurrency.id === currentCurrency.id;
            await controller.setPrimaryCurrencySelected(isPrimaryCurrency, primaryCurrency.text, secondaryCurrency.text);

            const costAttrs = store.layering.attributes?.filter(attr => layerAttributeKeys.includes(attr.defaultAttributeKey));

            if (currency.current === currentCurrency.id) {
                return;
            }

            if (!isNil(costAttrs)) {
                currency.current = currentCurrency.id;
                for (const attr of costAttrs) {
                    if (isPrimaryCurrency) {
                        // eslint-disable-next-line no-await-in-loop
                        await controller.changeLayerAttributeKeyAndSuffix(attr, attr.defaultAttributeKey, `${currentCurrency.text}/ha`);
                        controller.relabelLayerAttributeLabels(attr);
                    } else {
                        // eslint-disable-next-line no-await-in-loop
                        await controller.changeLayerAttributeKeyAndSuffix(attr, `${attr.defaultAttributeKey}SC`, `${currentCurrency.text}/ha`);
                        controller.relabelLayerAttributeLabels(attr);
                    }
                }
            }
            controller.recalculateGlobalLabeling();
        };
        setLabelling();
    }, [primaryCurrency, secondaryCurrency, currentCurrency, controller, store.layering.attributes, store.googleRefs.google]);

    return <CurrencySwitch manualEnabled />;
};
