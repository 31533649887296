/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { createUseStyles } from 'react-jss';
import Avatar from '@baseComponents/avatars/Avatar';

const useStyles = createUseStyles((theme: any) => ({
    avatarWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        width: 30,
        height: 30,
    },
    name: {
        ...theme.overFlowText,
        color: theme.color.jet,
        display: 'block',
        height: '100%',
        lineHeight: '56px',
        fontSize: 13,
        fontWeight: 500,
        verticalAlign: 'middle',
        backgroundColor: 'transparent',
        paddingLeft: 5,
    },
}));

export default function AvatarWithNameCell(props) {
    const { rowData, nameId, isShowTitle = true, isShowName = true } = props;
    const classes = useStyles();

    function getName() {
        if (nameId) {
            return rowData?.[nameId];
        }
        return rowData?.name;
    }

    return (
        <div className={classes.avatarWrapper}>
            <Avatar imageUrl={rowData?.imageUrl} name={getName()} className={classes.avatar} isShowTitle={isShowTitle} />
            {isShowName && (
                <span className={classes.name}>{getName()}</span>
            )}
        </div>
    );
}
