import { isFunction } from 'lodash';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { dateTimeUtils } from 'shared/src/modules';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        ...theme.overFlowText,
        color: (props: any) => props.color || theme.color.jet,
        display: 'block',
        height: '100%',
        lineHeight: '56px',
        fontSize: 12,
        fontWeight: 500,
        verticalAlign: 'middle',
    },
}));

export default function DateCell(props) {
    const { value, rowData, getColor } = props;
    const classes = useStyles({
        color: isFunction(getColor) ? getColor(rowData) : null,
    } as any);

    function getFormattedDate() {
        return dateTimeUtils.isValidDate(value) ? dateTimeUtils.getDate(value) : null;
    }

    return (
        <div className={classes.baseStyle}>
            {getFormattedDate()}
        </div>
    );
}
