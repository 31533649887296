import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const cultureService = {
    getCultures,
    getCultureVariety,
};

/**
 * Get all cultures datas
 * @return {array} Cultures array
 */
async function getCultures() {
    const response = await sendServerRequest('GET', '/api/cultures');
    return response;
}

/**
 * Get all variety of cultures datas
 * @property {int} productionYear IsRequired - Culture ID eg.: 1
 *
 * @return {array} Culture varieties array
 */
async function getCultureVariety(cultureId) {
    const url = routerHelper.getUrlWithParameters('/api/cultureVariety', { cultureId });
    const response = await sendServerRequest('GET', url);
    return response;
}
