import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import Svg from '../../../Svg';

const useStyle = createUseStyles({
    collapseButton: {
        margin: 0,
        marginLeft: 5,
        display: 'inline-block',
        border: 'none',
        background: 'transparent',
    },
    icon: {
        height: 25,
        width: 25,
        float: 'right',
    },
});

export default function TableCollapseButton(props) {
    const { isCollapsed, onClick, className } = props;
    const classes = useStyle();

    return (
        <button type="button" className={clsx(classes.collapseButton, className)} onClick={onClick}>
            {isCollapsed
                ? (<Svg iconId="icon-expand_more" style={classes.icon} />)
                : (<Svg iconId="icon-expand_less" style={classes.icon} />)}
        </button>
    );
}
