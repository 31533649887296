import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { errorActions } from '@states/actions';
import { registryNumberService } from '@services';

export default function useRegistryNumbersModalData(props) {
    const { partnerId, partnerType, selectedRegNums, excludedIds } = props;

    const dispatch = useDispatch();
    const [tableData, setTableData] = useState();
    const location = useLocation();

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const regNumsResponse = await registryNumberService.getRegistryNumbers(partnerId, partnerType);

                let selectedRegNumsFirst = [];

                if (selectedRegNums) {
                    const alreadyAddedRegNums = [];

                    regNumsResponse?.forEach(registryNumber => {
                        const selectedRegNum = selectedRegNums.find(addedRegNum => addedRegNum.id === registryNumber.id);

                        if (selectedRegNum) {
                            alreadyAddedRegNums.push(selectedRegNum);
                        } else {
                            selectedRegNumsFirst.push(registryNumber);
                        }
                    });

                    selectedRegNumsFirst = [...alreadyAddedRegNums, ...selectedRegNumsFirst];
                }

                let regNumTableData = selectedRegNums ? selectedRegNumsFirst : regNumsResponse;
                regNumTableData = regNumTableData?.filter(regNum => (!isEmpty(excludedIds) ? !excludedIds?.includes(regNum?.id) : true));

                if (!isCancelled) {
                    setTableData(regNumTableData);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (dispatch) {
            loadData();
        } else {
            setTableData([]);
        }

        return () => {
            isCancelled = true;
        };
    }, [dispatch, location, partnerId, partnerType, selectedRegNums, excludedIds]);

    return {
        tableData,
    };
}
