/* eslint-disable jsx-a11y/click-events-have-key-events */
import { createUseStyles } from 'react-jss';
import Avatar from '@baseComponents/avatars/Avatar';

const useStyles = createUseStyles({
    avatarWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    avatar: {
        width: 30,
        height: 30,
        backgroundColor: 'transparent',
    },
});

export default function MachineAvatarCell(props) {
    const { rowData } = props;
    const classes = useStyles();

    return (
        <div className={classes.avatarWrapper}>
            <Avatar imageUrl={rowData?.imageUrl} name={rowData?.name} className={classes.avatar} />
        </div>
    );
}
