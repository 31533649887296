import { ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';
import CultureIcon from './CultureIcon';

type TProps = {
    name: string,
    label: string | JSX.Element,
    cultureId: number | string,
}

type Classes = 'cultureBlock' | 'cultText' | 'cultName' | 'cultInfo';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    cultureBlock: {
        marginLeft: 20,
        marginBottom: 10,
    },
    cultText: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginLeft: 50,
        height: 44,
        textAlign: 'left',
    },
    cultName: {
        fontSize: 15,
        fontWeight: 500,
        color: '#21282C',
    },
    cultInfo: {
        fontSize: 13,
        fontWeight: 400,
        color: '#798286',
    },
}));

export default function CultureBlock({ name, label, cultureId }: TProps) {
    const classes = useStyles();

    if (!name || !cultureId) { return null; }

    return (
        <div className={classes.cultureBlock}>
            <CultureIcon />
            <div className={classes.cultText}>
                <div className={classes.cultName}>{name}</div>
                <div className={classes.cultInfo}>{label}</div>
            </div>
        </div>
    );
}
