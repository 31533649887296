import HttpStatus from 'http-status-codes';
import { simpleAction } from './baseActions';
// eslint-disable-next-line import/no-cycle
import { errorActions } from './errorActions';
// eslint-disable-next-line import/no-cycle
import { routerActions } from './routerActions';
import { systemService, sessionService, companyService } from '@services';

const systemActions = {
    getSystemInfo,
    removeMaintenance,
    setPlanningModeActive,
    SET_SYSTEMINFO: 'SYSTEM_SET_SYSTEMINFO',
    SET_MAINTENANCE: 'SYSTEM_SET_MAINTENANCE',
    REMOVE_MAINTENANCE: 'SYSTEM_REMOVE_MAINTENANCE',
    SET_SYSTEMSETTINGS: 'SYSTEM_SET_SYSTEMSETTINGS',
    SET_PLANNING_MODE_ACTIVE: 'SYSTEM_SET_PLANNING_MODE_ACTIVE',
};

export { systemActions };

function getSystemInfo() {
    return async dispatch => {
        try {
            const agentName = sessionService.getAgentName();

            const [companyResponse, systemSettingsResponse] = await Promise.all([
                companyService.getCompanyByAgent(agentName),
                systemService.getSystemSettings(),
            ]);

            const systemInfo = {
                isMaintained: Boolean(companyResponse.isMaintained),
                isTestSystem: Boolean(companyResponse.isTestSystem),
                subType: 0,
                countryCode: companyResponse.countryCode,
                langCode: companyResponse.langCode,
            };
            dispatch(simpleAction(systemActions.SET_SYSTEMINFO, { systemInfo }));

            //system settings
            dispatch(simpleAction(systemActions.SET_SYSTEMSETTINGS, { systemSettings: systemSettingsResponse }));

            return systemInfo;
        } catch (error) {
            if (error.statusCode === HttpStatus.SERVICE_UNAVAILABLE) {
                dispatch(routerActions.setServerOverload(true));
            } else {
                dispatch(errorActions.setError(error, 'systemActions', HttpStatus.INTERNAL_SERVER_ERROR));
            }

            return false;
        }
    };
}

function setPlanningModeActive(planningModeisActive) {
    return simpleAction(systemActions.SET_PLANNING_MODE_ACTIVE, { planningModeisActive });
}

function removeMaintenance() {
    return simpleAction(systemActions.REMOVE_MAINTENANCE);
}
