import { isObject, isUndefined } from 'lodash';
import { companySettingsModule } from '../company/companySettings';

export function hasPermission(right, userRightList, isHiddenUser) {
    return !!userRightList.find(rightItem => rightItem.rightId === right && rightItem.hasRight) || isHiddenUser;
}

export function hasEveryPermissions(rightList, userRightList, isHiddenUser) {
    const hasPermissionInList = rightList.every(right => {
        if (userRightList.find(rightItem => rightItem.rightId === right && rightItem.hasRight) || isHiddenUser) {
            return true;
        }
        return false;
    });
    return hasPermissionInList;
}

export function hasSomePermissions(rightList, userRightList, isHiddenUser) {
    const hasPermissionInList = rightList.some(right => {
        if (userRightList.find(rightItem => rightItem.rightId === right && rightItem.hasRight) || isHiddenUser) {
            return true;
        }
        return false;
    });
    return hasPermissionInList;
}

export function hasEverySystemSettings(settingList, companySettingsList) {
    const hasSettings = settingList.every(setting => {
        if (companySettingsModule.checkSettingValues(setting, companySettingsList)) {
            return true;
        }
        return false;
    });
    return hasSettings;
}

export function checkMenuIsVisible(module, userToRights, administrator, consultant, companySettings, activeSystemModules, planningMode, countryCode, isHiddenUser) {
    // if the menu don't has planning mode
    if ((planningMode === true && (module && !module.SHOW_IN_PANNING_MODE && !module.SHOW_ONLY_IN_PANNING_MODE)) ||
        (module && module.SHOW_IN_PANNING_MODE === false && module.SHOW_ONLY_IN_PANNING_MODE === false)) {
        return false;
    }

    // if the menu show only in planning mode
    if (planningMode === false && (module && module.SHOW_ONLY_IN_PANNING_MODE && module.SHOW_ONLY_IN_PANNING_MODE === true)) {
        return false;
    }

    // if the menu has rights
    if (module.RIGHTS && module.RIGHTS.length) {
        if (!hasSomePermissions(module.RIGHTS, userToRights, isHiddenUser)) {
            return false;
        }
    }

    // if the menu has settings
    if (module.SETTINGS && module.SETTINGS.length) {
        if (!hasEverySystemSettings(module.SETTINGS, companySettings)) {
            return false;
        }
    }

    // if the menu has an administrator setup and the logged in user is not an administrator
    if (module.SYSTEM_PERMISSIONS && !isUndefined(module.SYSTEM_PERMISSIONS.ADMINISTRATOR)) {
        if (module.SYSTEM_PERMISSIONS.ADMINISTRATOR === true && module.SYSTEM_PERMISSIONS.ADMINISTRATOR !== Boolean(administrator)) {
            return false;
        }
    }

    // if the menu has an consultant setup and the logged in user is not an consultant
    if (module.SYSTEM_PERMISSIONS && !isUndefined(module.SYSTEM_PERMISSIONS.CONSULTANT)) {
        if (module.SYSTEM_PERMISSIONS.CONSULTANT === false && Boolean(consultant)) {
            return false;
        }
    }

    // check exclude system module type
    if (module.EXCLUDE_SYSTEM_MODULES && module.EXCLUDE_SYSTEM_MODULES.includes(activeSystemModules)) {
        return false;
    }

    // check exclude system country
    if (module.EXCLUDE_COUNTRIES && module.EXCLUDE_COUNTRIES.includes(countryCode)) {
        return false;
    }

    // check include system country
    if (module.INCLUDE_COUNTRIES && module.INCLUDE_COUNTRIES.length > 0 && !module.INCLUDE_COUNTRIES.includes(countryCode)) {
        return false;
    }

    return true;
}

export function hasAccessToContent(module, content, userToRights, administrator, consultant, companySettings, activeSystemModules, planningMode, countryCode, isHiddenUser, planningData) {
    if (!checkMenuIsVisible(module, userToRights, administrator, consultant, companySettings, activeSystemModules, planningMode, countryCode, isHiddenUser)) {
        return false;
    }

    if (content.RIGHTS && content.RIGHTS.length && !isHiddenUser) {
        if (!hasSomePermissions(content.RIGHTS, userToRights)) {
            return false;
        }
    }

    // if the content has settings
    if (content.SETTINGS && content.SETTINGS.length) {
        if (!hasEverySystemSettings(content.SETTINGS, companySettings)) {
            return false;
        }
    }

    // if the content has an administrator setup and the logged in user is not an administrator
    if (content.SYSTEM_PERMISSIONS && !isUndefined(content.SYSTEM_PERMISSIONS.ADMINISTRATOR)) {
        if (content.SYSTEM_PERMISSIONS.ADMINISTRATOR === true && content.SYSTEM_PERMISSIONS.ADMINISTRATOR !== Boolean(administrator)) {
            return false;
        }
    }

    // if the content has an consultant setup and the logged in user is not an consultant
    if (content.SYSTEM_PERMISSIONS && !isUndefined(content.SYSTEM_PERMISSIONS.CONSULTANT)) {
        if (content.SYSTEM_PERMISSIONS.CONSULTANT === true && content.SYSTEM_PERMISSIONS.CONSULTANT !== Boolean(consultant)) {
            return false;
        }
    }

    // check exclude system country
    if (content.EXCLUDE_COUNTRIES && content.EXCLUDE_COUNTRIES.includes(countryCode)) {
        return false;
    }

    // check include system country
    if (content.INCLUDE_COUNTRIES && content.INCLUDE_COUNTRIES.length > 0 && !content.INCLUDE_COUNTRIES.includes(countryCode)) {
        return false;
    }

    //check planning settings
    if (planningMode === true && content.PLANNING_SETTINGS && isObject(content.PLANNING_SETTINGS)) {
        if (content.PLANNING_SETTINGS.DEPTH_ID !== ((planningData && planningData.selecteedPlanDepthId) || 1)) {
            return false;
        }
    }

    return true;
}

export function createRightsList(rights, customList, isHiddenUser) {
    const rightList = {};
    customList.forEach(right => {
        rightList[right.name] = hasPermission(right.right, rights, isHiddenUser);
    });
    return rightList;
}
