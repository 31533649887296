import { createUseStyles } from 'react-jss';
import { InputGroup } from 'react-bootstrap';
import Svg from '@baseComponents/Svg';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    inputAppend: {
        fontFamily: 'rubik',
        cursor: (props: any) => (props.onClick ? 'pointer' : 'default'),
        display: 'flex',
        marginLeft: -1,
        background: theme.color.white,
        '& .input-group-text': {
            display: 'flex',
            alignItems: 'center',
            padding: '.3rem .75rem',
            marginBottom: 0,
            fontSize: 15,
            fontWeight: 300,
            lineHeight: 1.5,
            color: theme.color.darkGray,
            textAlign: 'center',
            whiteSpace: 'nowrap',
            background: theme.color.white,
            border: `1px solid ${theme.color.midLightGray}`,
            borderRadius: '.25rem',

            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        '&:hover': {
            border: props => props.onClick && `1px solid ${theme.color.darkGray}`,
            boxShadow: props => props.onClick && `0 2px 3px 0 ${theme.color.gray}`,
        },
    },
}));

export default function InputAppend(props) {
    const { children, text, icon, className, title, onClick } = props;
    const classes = useStyles({ onClick });

    return (
        <InputGroup.Append className={clsx('inputAppend', classes.inputAppend, className)} title={title} onClick={onClick}>
            <InputGroup.Text>
                {icon
                    ? <Svg iconId={icon} height="1rem" width="1rem" />
                    : children || text
                }
            </InputGroup.Text>
        </InputGroup.Append>
    );
}
