/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';

import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isFunction, isUndefined } from 'lodash';

import { TableColumnLeftFader, TableColumnRightFader } from '@baseComponents/tableNew/tableParts/tableColumnFader';
import useHorizontalMouseDrag from './useHorizontalMouseDrag';
import DataListItemProgressBar from './DataListItemProgressBar';

const useStyle = createUseStyles((theme: any) => ({
    row: {
        display: 'flex',
        ...theme.disableOutline,
        position: 'relative',
        '&:hover': {
            background: (props: any) => isFunction(props.onSelectRow) && theme.color.tableRowHover,
        },
    },
    tableLeft: {
        display: 'flex',
        width: (props: any) => props.leftSideWidth,
        minWidth: (props: any) => props.leftSideWidth,
    },
    tableMiddle: {
        display: 'flex',
        position: 'relative',
        paddingRight: 10,
    },
    tableRight: {
        display: 'flex',
        justifyContent: 'end',
        width: (props: any) => props.rightSideWidth,
        minWidth: (props: any) => props.rightSideWidth,
        paddingRight: 10,
    },
}));

export default function ListRowTemplate(props) {
    const { rowData, left, middle, right, handleMidScroll, classes, isHeaderRow, listRowRef } = props;
    const { leftSideWidth, rightSideWidth, isDataCard, onSelectRow, rowStyle } = props;
    const { faderHeight } = props;
    const { tableKey = '' } = props; //simple table

    const componentClasses = useStyle({ leftSideWidth, rightSideWidth, isDataCard, onSelectRow } as any);
    const midColRef = useRef(null);
    const rowRef = useRef(null);

    const { classes: dragClasses } = useHorizontalMouseDrag({ draggableRef: midColRef, mouseLeaveWrapperRef: isUndefined(listRowRef) ? rowRef : listRowRef, displayScrollBar: isHeaderRow, onSelectRow });

    function onClickRow(event) {
        if (onSelectRow) {
            if (event.target.tagName !== 'INPUT') {
                onSelectRow(rowData);
                event.stopPropagation();
            }
        }
    }

    return (
        <div className={clsx(classes.row, rowStyle, componentClasses.row, isHeaderRow && 'tableHeaderRow')} ref={isUndefined(listRowRef) ? rowRef : listRowRef} onClick={onClickRow} role={isDataCard ? 'button' : ''} tabIndex="0">
            <TableColumnLeftFader leftPosition={leftSideWidth} height={faderHeight} />
            <TableColumnRightFader rightPosition={rightSideWidth} height={faderHeight} />

            <div className={componentClasses.tableLeft}>
                {left}
            </div>

            <div
                className={clsx(
                    componentClasses.tableMiddle,
                    dragClasses.mouseDraggable,
                    !isHeaderRow ? 'bodyRow' : 'headerRow',
                    `midCol${tableKey}`
                )}
                ref={midColRef}
                onScroll={handleMidScroll}
            >
                {middle}
            </div>

            <div className={componentClasses.tableRight}>
                {right}
            </div>
            {rowData?.hasProgressBar ? <DataListItemProgressBar done={rowData.progressValue} /> : null}
        </div>
    );
}
