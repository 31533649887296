import { createUseStyles } from 'react-jss';
import { isEmpty, last, indexOf, times } from 'lodash';
import clsx from 'clsx';
import { languageUtils } from 'shared/src/modules/languageUtils';
import Svg from '@baseComponents/Svg';
import useTranslate from '@i18n/useTranslate';
import BaseTooltip from '@baseComponents/tooltips/tooltip/BaseTooltip';

const useStyles = createUseStyles((theme: any) => ({
    rightHeader: {
        zIndex: 9,
        display: 'grid',
        height: '8%',
        width: '100%',
        gridTemplateColumns: 'repeat( auto-fit, minmax(20%, 1fr) )',
        backgroundColor: theme.color.white,
        flexShrink: 0,
    },
    separator: {
        width: 1,
        backgroundColor: theme.color.pearl,
        height: 50,
        margin: ['auto', 0],
    },
    titleContainer: {
        display: 'flex',
        border: 'none',
        backgroundColor: theme.color.white,
        padding: 0,
        fontSize: 18,
        lineHeight: 1.67,
        fontWeight: 500,
        '&:hover:not([disabled])': {
            backgroundColor: theme.color.lilian,
        },
        '&:disabled': {
            opacity: 0.4,
        },
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
    },
    selectedTitle: {
        backgroundColor: theme.color.opacity.main,
        display: 'flex',
        border: 'none',
        padding: 0,
        color: theme.color.main,
        fontSize: 18,
        lineHeight: 1.67,
        fontWeight: 500,
        borderBottom: '5px solid',
        borderBottomColor: theme.color.main,
    },
    icon: {
        height: 24,
        width: 24,
        marginRight: 5,
    },
    itemText: {
        fontSize: 14,
        lineHeight: 1.429,
        fontWeight: 400,
        textTransform: 'lowercase',
        color: theme.color.raven,
    },
    label: {
        display: 'inline-block',
    },
    skeletonWrapper: {
        display: 'flex',
    },
    tabSkeleton: {
        height: '70%',
        width: '90%',
        margin: 'auto',
        display: 'block',
        backgroundColor: theme.color.lilian,
    },
    errorClasses: {
        borderRadius: 4,
        border: `1px solid ${theme.color.statusColor.error}`,
    },
    firstLineWrapper: {
        display: 'inline-block',
    },
}));

export default function ModalRightTabs(props) {
    const { selectedTabKey, errorTabKey, onChangeRightTab, headerTitles, disabled, tabNumber, showAddIcon } = props;
    const { translater } = useTranslate();
    const classes = useStyles();

    function getClassName(id) {
        const errorClasses = (id === errorTabKey ? classes.errorClasses : null);

        return selectedTabKey === id
            ? clsx(classes.selectedTitle, errorClasses)
            : clsx(classes.titleContainer, errorClasses);
    }

    function hasSeparator(title) {
        return headerTitles && (!(title === last(headerTitles) || title.key === selectedTabKey || headerTitles[indexOf(headerTitles, title) + 1].key === selectedTabKey));
    }

    if (disabled && tabNumber) {
        return (
            <div className={classes.rightHeader}>
                {times(tabNumber, (index: number) => (
                    <div key={index} className={classes.skeletonWrapper}>
                        <div className={classes.tabSkeleton} />
                        <div className={classes.separator} />
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className={classes.rightHeader}>
            {headerTitles?.map((title, index) => (
                <button disabled={title.isDisabled} key={index} type="button" className={getClassName(title.key)} onClick={() => onChangeRightTab(title.key)}>
                    <BaseTooltip
                        placement={title.tooltipPlacement}
                        type={title.tooltipType}
                        message={title.tooltipMessage}
                        show={title.isDisabled}
                        className={classes.title}
                    >
                        <div className={classes.firstLineWrapper}>
                            {title.key !== selectedTabKey && showAddIcon && isEmpty(title.value) && <Svg iconId="icon-add" style={classes.icon} />}

                            <div className={classes.label}>
                                {title.text}
                            </div>
                        </div>

                        {title.key !== selectedTabKey && (
                            <div className={classes.itemText}>
                                {!!title.value && `${title.value} ${languageUtils.getTitle(translater, title.text)}`}
                            </div>
                        )}
                    </BaseTooltip>
                    {hasSeparator(title) && <div className={classes.separator} />}
                </button>
            ))}
        </div>
    );
}
