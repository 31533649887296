import { isFunction, isEmpty, values } from 'lodash';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import { theme as themes } from '@styles/theme';

export default function getAllColumns(t, translater, showedColumns) {
    const columns = {
        addIcon: {
            type: columnName.iconButton,
            columnId: 'icon-add',
            componentProps: {
                icon: 'icon-add',
                getCellTitle: () => translater('default.select', 'Select'),
                getBackgroundColor: () => themes.color.main,
                getIconColor: () => themes.color.white,
                onClick: (rowData, onAddButtonClicked) => {
                    if (isFunction(onAddButtonClicked)) {
                        onAddButtonClicked(rowData);
                    }
                },
            },
            isLeftFixed: true,
            width: 80,
            minWidth: 80,
            maxWidth: 80,
        },
        name: {
            type: columnName.simpleText,
            columnId: 'name',
            columnName: t('basicFieldsModal.name', 'Name'),
            isLeftFixed: true,
            width: 230,
        },
        companyName: {
            type: columnName.simpleText,
            columnId: 'companyName',
            columnName: t('basicFieldsModal.company', 'Company'),
            minWidth: 180,
        },
        cultivatedArea: {
            type: columnName.area,
            columnId: 'cultivatedArea',
            columnName: t('basicFieldsModal.cultivatedArea', 'Cultivated Area'),
            minWidth: 120,
        },
        creationDate: {
            type: columnName.date,
            columnId: 'creationDate',
            columnName: t('basicFieldsModal.creationDate', 'Creation Date'),
            minWidth: 150,
        },
        terminationDate: {
            type: columnName.date,
            columnId: 'terminationDate',
            columnName: t('basicFieldsModal.terminationDate', 'Termination Date'),
            minWidth: 150,
        },
    };

    if (!isEmpty(showedColumns)) {
        const filteredColumns = [];
        const columnObj = Object.entries(columns);

        showedColumns.forEach(showedColName => {
            const column = columnObj.find(columnParts => columnParts[0].toString() === showedColName);
            if (column) {
                filteredColumns.push(column[1]);
            }
        });

        return filteredColumns;
    }

    return values(columns);
}
