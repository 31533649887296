import { simpleAction } from './baseActions';

const tableFilterActions = {
    setOuterFilterConditions,
    setTableFilter,
    resetCurrentFilters,
    initTableFilter,
    setBaseFilters,
    applyTableFilter,
    setCurrentFilterFromSavedFilter,
    deleteTableFilterTag,
    deleteTableFilterModalSelection,
    deleteAllFilters,
    getFilterOptions,
    getTableData,
    setStaticParameters,
    GET_TABLE_DATA_REQUESTED: 'TABLE_DATA_GET_TABLE_DATA_REQUESTED',
    GET_TABLE_DATA_SUCCEEDED: 'TABLE_DATA_GET_TABLE_DATA_SUCCEEDED',
    GET_TABLE_DATA_FAILED: 'TABLE_DATA_GET_TABLE_DATA_FAILED',
    SET_INITIAL_LOADING_STATES: 'TABLEFILTER_SET_INITIAL_LOADING_STATES',
    RESET_TABLE_FILTER: 'TABLEFILTER_RESET_TABLE_FILTER',
    INIT_TABLE_FILTER_DATA: 'TABLEFILTER_INIT_TABLE_FILTER_DATA',
    SET_BASE_FILTERS: 'TABLEFILTER_SET_BASE_FILTERS',
    APPLY_TABLE_CURRENT_FILTER_DATA: 'TABLEFILTER_APPLY_TABLE_CURRENT_FILTER_DATA',
    SET_TABLE_CURRENT_FILTER_DATA_FROM_SAVED_FILTER: 'TABLEFILTER_SET_TABLE_CURRENT_FILTER_DATA_FROM_SAVED_FILTER',
    SET_TABLE_CURRENT_FILTER_DATA: 'TABLEFILTER_SET_TABLE_CURRENT_FILTER_DATA',
    DELETE_TABLE_FILTER_TAG: 'TABLEFILTER_DELETE_TABLE_FILTER_TAG',
    DELETE_TABLE_FILTER_MODAL_SELECTION: 'TABLEFILTER_DELETE_TABLE_FILTER_MODAL_SELECTION',
    SET_SINGLE_FILTER_OPTIONLIST: 'TABLEFILTER_SET_SINGLE_FILTER_OPTIONLIST',
    DELETE_ALL_FILTERS: 'TABLEFILTER_DELETE_ALL_FILTERS',
    GET_FILTER_OPTIONS_REQUESTED: 'FILTERS_GET_FILTER_OPTIONS_REQUESTED',
    GET_FILTER_OPTIONS_SUCCEEDED: 'FILTERS_GET_FILTER_OPTIONS_SUCCEEDED',
    REQUEST_SAVE_SUBFILTER_INTERFACE_SETTING: 'FILTERS_REQUEST_SAVE_SUBFILTER_INTERFACE_SETTING',
    SET_OUTER_FILTER_CONDITIONS: 'FILTERS_SET_OUTER_FILTER_CONDITIONS',
    SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
    INIT_TABLE_CURRENT_FILTER_DATA: 'INIT_TABLE_CURRENT_FILTER_DATA',
    SET_STATIC_PARAMETER_TO_FILTER_DATA: 'SET_STATIC_PARAMETER_TO_FILTER_DATA',
};

export { tableFilterActions };

function setStaticParameters(staticParams) {
    return simpleAction(tableFilterActions.SET_STATIC_PARAMETER_TO_FILTER_DATA, { staticParams });
}

function setTableFilter(filterName, filterValue) {
    return simpleAction(tableFilterActions.SET_TABLE_CURRENT_FILTER_DATA, { filterName, filterValue });
}

function initTableFilter(filters, initialOptionData, downloadParams) {
    return simpleAction(tableFilterActions.INIT_TABLE_FILTER_DATA, { filters, initialOptionData, downloadParams });
}

function resetCurrentFilters(closeFunction, actionType, fixedFilterStates) {
    return simpleAction(tableFilterActions.RESET_TABLE_FILTER, { closeFunction, actionType, fixedFilterStates });
}

function setBaseFilters(filters) {
    return simpleAction(tableFilterActions.SET_BASE_FILTERS, filters);
}

function applyTableFilter() {
    return simpleAction(tableFilterActions.APPLY_TABLE_CURRENT_FILTER_DATA);
}

function setCurrentFilterFromSavedFilter() {
    return simpleAction(tableFilterActions.SET_TABLE_CURRENT_FILTER_DATA_FROM_SAVED_FILTER);
}

function deleteTableFilterTag(filterCategory) {
    return simpleAction(tableFilterActions.DELETE_TABLE_FILTER_TAG, { filterCategory });
}

function deleteTableFilterModalSelection(filterCategory, tagId) {
    return simpleAction(tableFilterActions.DELETE_TABLE_FILTER_MODAL_SELECTION, { filterCategory, tagId });
}

function deleteAllFilters() {
    return simpleAction(tableFilterActions.DELETE_ALL_FILTERS);
}

function getFilterOptions(params, filterName, dynamicParam) {
    return simpleAction(tableFilterActions.GET_FILTER_OPTIONS_REQUESTED, { params, dynamicParam, filterName });
}

function setOuterFilterConditions(outerFilterConditions) {
    return simpleAction(tableFilterActions.SET_OUTER_FILTER_CONDITIONS, { outerFilterConditions });
}

function getTableData() {
    return simpleAction(tableFilterActions.GET_TABLE_DATA_REQUESTED);
}
