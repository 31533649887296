import Button from '@baseComponents/buttons/Button';
import Svg from '@baseComponents/Svg';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import MapController from '@map/services/mapController';
import { Layer, LayerAttribute, LayerAttributeStatus, ThemeType } from '@map/services/mapTypes';
import { INTERFACE_FILENAME, INTERFACE_SETTINGS } from 'shared/src/constants';
import { isEmpty, isNil } from 'lodash';
import { useDispatch } from 'react-redux';
import { userActions } from '@states/actions';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { LayerFolderDelete } from './LayerFolderDelete';
import { LayerFolderEdit } from './LayerFolderEdit';
import { LayerFolderEditMode } from './LayerFolderEdit.enum';
import LayerFolderModal from './LayerFolderModal';
import { LayerFolderMoreOptions } from './LayerFolderMoreOptions';
import { getLayerName } from './LayerFolderUtils';

type Classes = 'layerRow' | 'attributeRow' | 'title' | 'arrowSvg' | 'moreIcon' | 'moreContainer' | 'actionContainer' | 'addButton';

type StyleProps = {
    isActive: boolean,
}

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    layerRow: {
        padding: [5, 0],
        display: 'flex',
        gap: 3,
        width: '100%',
        color: props => (props.isActive ? 'inherit' : theme.color.gray),
    },
    attributeRow: {
        padding: [5, 5, 5, 5],
        // paddingLeft: 30,
        // paddingRight: 15,
        hover: {
            background: theme.color.tableRowHover,
        },
        height: 36,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    title: {
        paddingLeft: 5,
        verticalAlign: 'center',
        marginBottom: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        color: theme.color.jet,
        fontSize: 14,
        fontWeight: 500,
    },
    arrowSvg: {
        fill: props => (props.isActive ? 'inherit' : theme.color.gray),
    },
    moreIcon: {
        width: 24,
        height: 24,
        fill: theme.color.raven,
        justifySelf: 'flex-end',
    },
    moreContainer: {
        position: 'fixed',
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'center',
        width: 'fit-content',
        alignItems: 'center',
    },
    addButton: {
        width: 50,
        display: 'block',
        '& div': {
            padding: 0,
        },
        '& svg': {
            marginRight: 0,
        },
    },
}));

type TProps = {
    layer: Layer,
    closePanel: (event?: MouseEvent | TouchEvent) => void,
    mapController: MapController
}

export function LayerSelectPanelLayer({ closePanel, layer, mapController }: TProps) {
    const { translater } = useTranslate();

    const [store, controller] = useMapContext();
    const [isActive, setIsActive] = useState(false);
    const [editPanelMode, setEditPanelMode] = useState<LayerFolderEditMode>(LayerFolderEditMode.NONE);
    const [isContianerHovered, setIsContainerHovered] = useState(false);

    const classes = useStyles({ isActive });

    const [availableAttributes, setAvailableAttributes] = useState<LayerAttribute[]>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!store.layering) {
            return;
        }

        const layerAttributesFilterWhiteList = store.layerSelector.attributeFilter.whiteList?.filter(filter => filter.layerName === layer.name);
        let availableAttr = store.layering.attributes?.filter(attribute => attribute.isActive === LayerAttributeStatus.INACTIVE && attribute.layerId === layer.id).sort((a, b) => Number(b.isDefault) - Number(a.isDefault));

        if (!isNil(layerAttributesFilterWhiteList) && !isEmpty(layerAttributesFilterWhiteList)) {
            const whiteListKeys = layerAttributesFilterWhiteList.map(filter => filter.attributeKey);
            availableAttr = availableAttr?.filter(attr => whiteListKeys.includes(attr.attributeKey));
        }

        if (availableAttr) {
            setAvailableAttributes(availableAttr);
            setIsActive(availableAttr.length !== 0);
        }
    }, [layer.id, store.layering, store.layering.attributes, store.layerSelector.attributeFilter]);

    const addAvailableAttributeToMap = async () => {
        if (availableAttributes.length > 0) {
            await controller.addLayerAttributesToMap([availableAttributes[0]]);

            const [fileName, interfaceSettings] = controller.getInterfaceSettings();
            dispatch(userActions.setInterfaceSetting(INTERFACE_SETTINGS.MAP_SETTINGS, interfaceSettings, fileName));

            closePanel();
        }
    };

    return (
        <div>
            <div
                className={classes.attributeRow}
                onMouseEnter={() => setIsContainerHovered(true)}
                onMouseLeave={() => setIsContainerHovered(false)}
            >
                <p className={classes.title}>
                    {getLayerName(layer, translater)}
                </p>
                {isContianerHovered && (
                    <div className={classes.actionContainer}>
                        <div>
                            <Button
                                className={classes.addButton}
                                size="small"
                                type="secondary"
                                iconId="icon-add"
                                title="Hozzáadás"
                                onClick={addAvailableAttributeToMap}
                                disabled={availableAttributes.length === 0}
                            />
                        </div>
                        {layer.readonly === false && (
                            <LayerFolderMoreOptions layer={layer} setEditPanelMode={setEditPanelMode} />
                        )}
                    </div>
                )}
            </div>
            {(editPanelMode === LayerFolderEditMode.EDIT || editPanelMode === LayerFolderEditMode.CREATE) && (
                <LayerFolderModal>
                    <LayerFolderEdit
                        editPanelMode={editPanelMode}
                        layer={layer}
                        mapController={mapController}
                        setEditPanelMode={setEditPanelMode}
                        index={0}
                    />
                </LayerFolderModal>
            )}
            {editPanelMode === LayerFolderEditMode.DELETE && (
                <LayerFolderModal>
                    <LayerFolderDelete
                        editPanelMode={editPanelMode}
                        layer={layer}
                        mapController={mapController}
                        setEditPanelMode={setEditPanelMode}
                    />
                </LayerFolderModal>
            )}
        </div>
    );
}
