import { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { dateTimeUtils } from 'shared/src/modules';
import { domHelper } from '@utils/dom/domHelper';

export default function YearPicker(props) {
    const { selectedYear, setSelectedYear, date } = props;

    useEffect(() => {
        domHelper.signItemWithClassName('react-datepicker__year-container-text', selectedYear, ' selectedYear');
    }, [selectedYear]);

    return (
        <DatePicker
            calendarClassName="yearPicker"
            selected={selectedYear}
            onChange={(year, event) => domHelper.selectItemByClassName(setSelectedYear, year.getFullYear(), event, 'selectedYear')}
            onYearChange={() => domHelper.signItemWithClassName('react-datepicker__year-container-text', selectedYear, ' selectedYear')}
            dateFormat="yyyy"
            showYearPicker
            openToDate={dateTimeUtils.getFirstDayOfYear(date).toDate()}
            inline
        />
    );
}
