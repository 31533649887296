import { Nullable } from '@flowsCommon/services/baseTypes';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { Feature } from 'geojson';
import { CROP } from 'shared/src/constants';
import { MapSliderIntervalMode } from '@map/components/MapSlider/MapSliderTypes';
import { MAP_FILTER_STATUS, MAP_FILTER_WORK_OPERATION_STATUS } from '@map/components/MapFilter/MapFilterSchema';
import { BuiltInLayerTypes, MapMode } from './mapEnums';
import { MapClusterRenderer } from './MapClusterRenderer';
import {
    Layer,
    DefaultCenterDto,
    MapFilters,
    LayerAttribute,
    MultiGeometrySelector,
    SelectedLayer,
    MachineDataResponse,
    LayerSelectorAttributeFilter,
    LayerSelectorSettings,
    MapSliderStore,
    LayersDto,
    LayerGeometriesWithBboxDto,
} from './mapTypes';

export type TMapStore = {
    mapId?: number,
    mapMode?: MapMode,
    filters: MapFilters,
    filterInit: Partial<MapFilters>,
    global: {
        primaryCurrency: string;
        cultures: any[],
        workOperationMainGroups: any[],
        meteorologyUnits: any[],
    }
    googleRefs: {
        google: any,
        map: Nullable<google.maps.Map>,
        measureTool: null | any,
        drawingManager: Nullable<google.maps.drawing.DrawingManager>,
    }
    googleMapSettingsOverride: {
        styles: google.maps.MapTypeStyle[];
    };
    drawing: {
        loading: boolean,
        dataLayer: Nullable<google.maps.Data>,
        drawnFeature: Nullable<Feature>
        periodId: Nullable<number>,
        parentId: Nullable<number>,
    },
    center: {
        centerData: Nullable<DefaultCenterDto>,
        bbox: Nullable<number[]>
    },
    layering: {
        loading: boolean,
        layers: Nullable<Layer[]>,
        attributes: Nullable<LayerAttribute[]>,
        order: number[],
        autoZoom: boolean,
        selector: SelectedLayer[]
        globalLabelLayer?: google.maps.MVCArray<google.maps.Marker>
        globalLabelLayerCluster?: MarkerClusterer
    },
    gisAuth: {
        isAvailable: boolean,
    },
    selectedGeometry: {
        isInfoModalOpen: boolean,
        layerId: number,
        geom: Nullable<Feature>
    },
    selectedMachine: {
        isMachineModalOpen: boolean,
        layerId: number,
        machine: Nullable<MachineDataResponse>
    },
    multiSelectedGeometries: Array<MultiGeometrySelector>,
    tools: {
        isMeasuring : boolean,
        measureArea? : number,
        measureLenght? : number,
    },
    zoomControl : {
        minLevel : number,
        maxLevel: number,
        currentLevel: number,
    },
    mapSlider: MapSliderStore,
    layerSelector: LayerSelectorSettings,
    currency: {
        isPrimarySelected: boolean,
        primaryCurrency?: string,
        secondaryCurrancy?: string
    },
    fetcher: {
        layerFetcher?: () => Promise<LayersDto>,
        geomFetcher?: () => Promise<LayerGeometriesWithBboxDto>,
    }
};

export const initStore: TMapStore = {
    filters: {
        productionYearId: -1,
        statusId: MAP_FILTER_STATUS.ACTIVE,
        farmIds: [],
        companyIds: [],
        cultureIds: [],
        entity: null,
        showClosedPeriods: true,
        cropType: CROP.TYPE.PLANTATION,
        workOperationMainGroupIds: [],
        workOperationStatus: MAP_FILTER_WORK_OPERATION_STATUS.IN_PROGRESS,
    },
    filterInit: {},
    googleRefs: {
        google: null,
        map: null,
        measureTool: null,
        drawingManager: null,
    },
    googleMapSettingsOverride: {
        styles: [],
    },
    drawing: {
        loading: false,
        dataLayer: null,
        drawnFeature: null,
        parentId: null,
        periodId: null,
    },
    center: {
        centerData: null,
        bbox: null,
    },
    layering: {
        loading: true,
        layers: null,
        attributes: null,
        order: [],
        autoZoom: true,
        selector: [],
    },
    gisAuth: {
        isAvailable: false,
    },
    selectedGeometry: {
        isInfoModalOpen: false,
        layerId: -1,
        geom: null,
    },
    selectedMachine: {
        isMachineModalOpen: false,
        layerId: -1,
        machine: null,
    },
    global: {
        primaryCurrency: '',
        cultures: [],
        workOperationMainGroups: [],
        meteorologyUnits: [],
    },
    multiSelectedGeometries: [],
    tools: {
        isMeasuring: false,
    },
    zoomControl: {
        minLevel: 0,
        maxLevel: 19,
        currentLevel: 19,
    },
    layerSelector: {
        layerFilter: {
            blackList: null,
        },
        attributeFilter: {
            whiteList: null,
        },
    },
    currency: {
        isPrimarySelected: true,
    },
    mapSlider: {
        isEnabled: false,
        attributesToUseYearFilter: [
            BuiltInLayerTypes.LPIS_FIELD,
        ],
        attributesToSkipInterval: [
            BuiltInLayerTypes.LOT,
            BuiltInLayerTypes.REGISTRY_NUMBER,
            BuiltInLayerTypes.STABLE,
            BuiltInLayerTypes.PREMISE,
        ],
        intervalMode: MapSliderIntervalMode.YEAR,
    },
    fetcher: {},
};
