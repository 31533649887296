import React from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import ModalLeftPanel from '@baseComponents/modals/modalParts/ModalLeftPanel';
import FileInput from '@baseComponents/inputs/DataInput';
import { DoubleDatePicker } from '@customComponents/datePickers';
import CropOverall from '@customComponents/culture/CropOverall';
import PeriodViewTitleCard from '@contents/cultivationPeriods/cultivationPeriodsManagement/periodViews/PeriodViewTitleCard';
import PeriodViewOverallCard from '@contents/cultivationPeriods/cultivationPeriodsManagement/periodViews/PeriodViewOverallCard';
import PeriodViewCommentCard from '@contents/cultivationPeriods/cultivationPeriodsManagement/periodViews/PeriodViewCommentCard';
import { ModalFooter } from '@baseComponents/modals/modalNew';
import Button from '@baseComponents/buttons/Button';
import renderModal from '@modals/modalNew/modalFunctions';
import useSoilAnalysisViewDataLoader from './useSoilAnalysisViewDataLoader';
import NewSoilAnalysis from '../soilAnalysisImport/NewSoilAnalysis';
import { useSelector } from 'react-redux';

const useStyles = createUseStyles((theme: any) => ({
    modalLeftContainer: {
        paddingTop: 25,
    },
    periodViewContainer: {
        marginTop: 15,
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: [0, 30],
        ...theme.verticalScrollbar,
    },
    viewContainerWidth: {
        width: 320,
    },
    marginTop: {
        marginTop: 15,
    },
}));

export default function SoilAnalysisViewLeft(props) {
    const { hideModal, cultivationPeriodValues } = props;
    const { t } = useTranslate();
    const classes = useStyles();

    const {
        cultivationPeriodData,
        reproductionRateName,
        supportList,
        basicFieldsList,
        registryNumberList,
    } = useSoilAnalysisViewDataLoader({ cultivationPeriodValues });

    function onClick() {
        hideModal();
        renderModal(NewSoilAnalysis, { cultivationPeriodId: cultivationPeriodData?.id }, false);
    }

    return (
        <ModalLeftPanel className={classes.modalLeftContainer}>
            <PeriodViewTitleCard
                periodYear={cultivationPeriodData?.productionYear}
                name={cultivationPeriodData?.name}
                companyName={cultivationPeriodData?.companyName}
                sownArea={cultivationPeriodData?.sownArea || 0}
                isFixed={Number(cultivationPeriodData?.isFixed)}
            />
            <div className={classes.periodViewContainer}>
                <div className={classes.viewContainerWidth}>
                    <DoubleDatePicker
                        disabled
                        startDate={cultivationPeriodData?.startDate}
                        endDate={cultivationPeriodData?.endDate}
                    />

                    <FileInput className={classes.marginTop} disabled>
                        <CropOverall
                            cultureName={cultivationPeriodData?.cultureName}
                            typeName={cultivationPeriodData?.cropTypeName}
                            reproductionRateName={reproductionRateName}
                            varietyName={cultivationPeriodData?.cultureVarietyName}
                        />
                    </FileInput>

                    <PeriodViewOverallCard supportList={supportList} basicFieldsList={basicFieldsList} registryNumberList={registryNumberList} />

                    <PeriodViewCommentCard comment={cultivationPeriodData?.comment} />
                </div>
            </div>
            <ModalFooter>
                <Button iconId="icon-add" iconPosition="before" onClick={onClick}>
                    {t('soilAnalysisView.addNewSoilAnalysis', 'Add new soil analysis')}
                </Button>
            </ModalFooter>
        </ModalLeftPanel>
    );
}
