import { RIGHTS, COUNTRY, SETTINGS, PLANNING as PLANNING_CONSTANTS } from '../constants';

export const LABOR = {
    MODULE_ID: 11,
    NAME: 'labor',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'labor',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.labor',
                TRANSLATOR_DEFAULT: 'Labour',
            },
        },
        LABOR: {
            CONTENT_URL_ID: { labor: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'labors',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.labor',
                TRANSLATOR_DEFAULT: 'Labor',
            },
            RIGHTS: [RIGHTS.LABOR_INTERFACE],
        },
        WORKING_TIME_RECORD: {
            CONTENT_URL_ID: { workingTimeRecord: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workingTimeRecord',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Munkaidő nyilvántartás',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.WORKING_TIME_RECORD_INTERFACE],
            SETTINGS: [SETTINGS.WORKING_TIME_RECORDS],
        },
        ATTENDANCE_SHEET: {
            CONTENT_URL_ID: { attendanceSheet: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'attendanceSheet',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.attendanceSheet',
                TRANSLATOR_DEFAULT: 'Attendance sheet',
            },
            RIGHTS: [RIGHTS.ATTENDANCE_SHEET_INTERFACE],
        },
        SIMPLIFIED_EMPLOYMENT: {
            CONTENT_URL_ID: { simplifiedEmployment: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'simplifiedEmployment',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.simplifiedEmployment',
                TRANSLATOR_DEFAULT: 'Simplified employment',
            },
            EXCLUDE_COUNTRIES: [COUNTRY.ARGENTINA],
            RIGHTS: [RIGHTS.SIMPLIFIED_EMPLOYMENT_INTERFACE],
        },
        MONTHLY_WAGE_EXCEL_IMPORT: {
            CONTENT_URL_ID: { monthlyWageExcelImport: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'monthlyWageExcelImport',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.monthlyWageExcelImport',
                TRANSLATOR_DEFAULT: 'Monthly wage excel import',
            },
            RIGHTS: [RIGHTS.MONTHLY_WAGE_EXCEL_INTERFACE_ACCESS_EDIT],
            HAS_CURRENCY_SWITCH: true,
        },
        LABOR_EDIT: {
            CONTENT_URL_ID: { laborEdit: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'laborEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.laborEdit',
                TRANSLATOR_DEFAULT: 'Labor edit',
            },
            RIGHTS: [RIGHTS.ADDING_LABOR],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.LABOR,
            PLANNING_SETTINGS: {
                DEPTH_ID: 1,
            },
        },
        MONTHLY_WAHE_COST: {
            CONTENT_URL_ID: { monthlyWageCost: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'monthlyWageCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.monthlyWageCost',
                TRANSLATOR_DEFAULT: 'Monthly wage cost',
            },
            HAS_CURRENCY_SWITCH: true,
        },
        SIMPLIFIED_EMPLOYEMENT_EDIT: {
            CONTENT_URL_ID: { simplifiedEmploymentEdit: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'simplifiedEmploymentEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.simplifiedEmploymentEdit',
                TRANSLATOR_DEFAULT: 'Simplified employment edit',
            },
            RIGHTS: [RIGHTS.SIMPLIFIED_EMPLOYMENT_INTERFACE],
        },
        SET_DAY: {
            CONTENT_URL_ID: { setDay: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'setDay',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.setWeekendHoliday',
                TRANSLATOR_DEFAULT: 'Set weekend holiday',
            },
            EXCLUDE_COUNTRIES: [COUNTRY.ARGENTINA],
            RIGHTS: [RIGHTS.SIMPLIFIED_EMPLOYMENT_INTERFACE],
        },
        CHECKING_WORKING_TIME_RECORD: {
            CONTENT_URL_ID: { checkingWorkingTimeRecord: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'checkingWorkingTimeRecord',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.checkingWorkingTimeRecord',
                TRANSLATOR_DEFAULT: 'Checking working time record',
            },
        },
        DEFAULT_WORKING_TIME: {
            CONTENT_URL_ID: { defaultWorkingTime: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'defaultWorkingTime',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.defaultWorkingTime',
                TRANSLATOR_DEFAULT: 'Default working time',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        TYPE_OF_WORKING_HOURS: {
            CONTENT_URL_ID: { typeOfWorkingHours: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'typeOfWorkingHours',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.typeOfWorkingHours',
                TRANSLATOR_DEFAULT: 'Type of working hours',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        SET_WEEKEND_HOLIDAY: {
            CONTENT_URL_ID: { setWeekendHoliday: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'setWeekendHoliday',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.setWeekendHoliday',
                TRANSLATOR_DEFAULT: 'Set weekend holiday',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        ALLOWANCE_SYSTEM: {
            CONTENT_URL_ID: { allowanceSystem: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'allowanceSystem',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.allowanceSystem',
                TRANSLATOR_DEFAULT: 'Allowance system',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        POSITIONS: {
            CONTENT_URL_ID: { positions: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'positions',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.positions',
                TRANSLATOR_DEFAULT: 'Positions',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        WORKING_TIME_RECORD_HOLIDAY_TYPE: {
            CONTENT_URL_ID: { workingTimeRecordHolidayType: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workingTimeRecordHolidayType',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Szabadság típusok munkaidő nyilvántartás típushoz sorolása',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        LOST_OF_LABOR: {
            CONTENT_URL_ID: { listsOfLabor: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'listsOfLabor',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.listsOfLabor',
                TRANSLATOR_DEFAULT: 'Lists of labor',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        LIST_OF_LABOR_EDIT: {
            CONTENT_URL_ID: { listsOfLaborEdit: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'listsOfLaborEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.listsOfLaborEdit',
                TRANSLATOR_DEFAULT: 'Lists of labor edit',
            },
        },
        LABOR_IS_A_SIMPLE_PUBLIC_BURDEN: {
            CONTENT_URL_ID: { laborIsASimplePublicBurdendit: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'laborIsASimplePublicBurden',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.laborIsASimplePublicBurden',
                TRANSLATOR_DEFAULT: 'Labor is a simple public burden',
            },
        },
    },
};
