import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isNumber } from 'lodash';
import { useFormikContext } from 'formik';
import { useLocation } from 'react-router';
import { SETTINGS } from 'shared/src/constants';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { companyActions } from '@states/actions/companyActions';
import { errorActions } from '@states/actions/errorActions';
import { cultivationTableService } from '@services';
import { dateTimeUtils } from 'shared/src/modules';

export default function useLoadBasicFieldData(props) {
    const { loadedBasicFieldsList } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const tableSetting = dispatch(companyActions.getSetting(SETTINGS.TABLE_AND_PERIOD_DIFFERENT_COMPANY));
    const { values, setFieldValue } = useFormikContext();
    const [tableCompany, setTableCompany] = useState(values.owner);

    useEffect(() => {
        let isCancelled = false;

        async function loadBasicFieldList() {
            try {
                let cultivationTables = await cultivationTableService.getCultivationTables(values.period, tableCompany);
                cultivationTables = cultivationTables.map(cultivationTableObj => ({
                    ...cultivationTableObj,
                    companyName: arrayUtils.getArrayItemByKeyString(cultivationTableObj, 'cultivationTableCompany.partner.name'),
                }));
                if (!isCancelled) {
                    setFieldValue('baseBasicFieldList', cultivationTables);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }

        if (dispatch && location && isNumber(values.period) && loadedBasicFieldsList) {
            loadBasicFieldList();
            return () => {
                isCancelled = true;
            };
        }
    }, [dispatch, location, tableCompany, values.period, setFieldValue, loadedBasicFieldsList]);

    useEffect(() => {
        if (values.startDate) {
            const filterFieldList = values.baseBasicFieldList?.filter(basicField => !basicField.terminationDate || dateTimeUtils.getDateObject(values.startDate) < dateTimeUtils.getDateObject(basicField.terminationDate));
            setFieldValue('basicFieldList', filterFieldList);
        } else {
            setFieldValue('basicFieldList', values.baseBasicFieldList);
        }
    }, [values.startDate, setFieldValue, values.baseBasicFieldList]);

    useEffect(() => {
        if (tableCompany !== values.owner && !tableSetting) {
            setTableCompany(values.owner);
            setFieldValue('basicFields', '');
        }
    }, [values.owner, tableSetting, tableCompany, setFieldValue]);
}
