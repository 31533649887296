import { createUseStyles } from 'react-jss';
import DatePicker from 'react-datepicker';
import { sessionService } from '@services';

const useStyles = createUseStyles({
    calendarContainer: {
        position: 'relative',
        fontSize: '1.5em',

        '& .monthCalendar': {
            display: 'flex',
        },

        '& .react-datepicker__navigation--next': {
            right: 0,
        },

        '& .react-datepicker__month-wrapper': {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
        },

        '& .react-datepicker__month-text': {
            padding: 5,
        },
    },
});

export default function MonthCalendar(props) {
    const { ...restProps } = props;
    const classes = useStyles();
    const langCode = sessionService.getLangCode();

    return (
        <div className={classes.calendarContainer}>
            <DatePicker
                calendarClassName="monthCalendar"
                locale={langCode}
                inline
                showMonthYearPicker
                {...restProps}
            />
        </div>
    );
}

// selected={getDate(date)}
// onChange={dateItem => handleChangeDate(dateItem)}
// minDate={getDate(minDate)}
// maxDate={getDate(maxDate)}
// openToDate={getDate(getOpenDate())}
// showMonthDropdown
// showYearDropdown
// scrollableYearDropdown
// dropdownMode="select"
// inline
// filterDate={filterDate}
// excludeDates={excludeDates}

// selected={startDate}
// selected={'01/2022'}
// onChange={(date) => console.log({ date })}
// dateFormat="MM/yyyy"
