import Svg from '@baseComponents/Svg';
import { cultivationPeriodsService } from '@services/cultivationPeriodsService';
import { errorActions } from '@states/actions';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { dateTimeUtils } from 'shared/src/modules';
import { useTranslation } from 'react-i18next';

type CultivationPeriodImagesModalLoadDataProps = {
    cultivationPeriodId: number[];
    nonImageClass: string;
    thumbnailClass: string;
};

type ImageProps = {
    original: string;
    thumbnail: string;
} & any;

type ImageRendererProps = {
    className: string;
    fileUrl: string;
    fileName: string;
    fileDate?: string;
    isScouting?: boolean;
};

const isImageFormat = (fileName: string) => {
    const fileNameLower = fileName.toLowerCase();
    const imageFormats = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
    const format = fileNameLower.split('.').pop() as string;
    return imageFormats.includes(format);
};

const ImageRenderer = (props: ImageRendererProps) => {
    const { className, fileUrl, fileName, fileDate, isScouting } = props;
    const { t } = useTranslation();
    const isImg = isImageFormat(fileUrl);

    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = fileName;
        link.click();
        link.remove();
    };

    if (!isImg) {
        return (
            <div className={className}>
                <section>
                    <Svg iconId="icon-file_default" width={300} height={300} />

                    <section>
                        <span title={isScouting ? t('cultivationPeriodsImagesModal.scoutingAttachedFile', 'File attached for scouting') : t('cultivationPeriodsImagesModal.cultivationPeriodAttachedFile', 'File attached for cultivaltion period')}>
                            <Svg iconId={isScouting ? 'icon-visibility_show' : 'icon-leafy'} width={32} height={32} />
                        </span>
                        <a title={fileName} href={fileUrl} target='_blank'>{fileName} ({dateTimeUtils.getDate(fileDate)})</a>

                        <span style={{ cursor: 'pointer' }} onClick={() => downloadFile()} title={t('default.download', 'Download')} >
                            <Svg iconId="icon-download" width={32} height={32} />
                        </span>
                    </section>
                </section>
            </div>
        );
    }

    return (
        <div className={className}>
            <div>
                <img src={fileUrl} alt={fileName} title={fileName} />

                <section>
                    <span title={isScouting ? t('cultivationPeriodsImagesModal.scoutingAttachedFile', 'File attached for scouting') : t('cultivationPeriodsImagesModal.cultivationPeriodAttachedFile', 'File attached for cultivaltion period')}>
                        <Svg iconId={isScouting ? 'icon-visibility_show' : 'icon-leafy'} width={32} height={32} />
                    </span>
                    <a title={fileName} href={fileUrl} target='_blank'>{fileName} ({dateTimeUtils.getDate(fileDate)})</a>

                    <span style={{ cursor: 'pointer' }} onClick={() => downloadFile()} title={t('default.download', 'Download')}>
                        <Svg iconId="icon-download" width={32} height={32} />
                    </span>
                </section>
            </div>
        </div>
    );
};

const ThumbnailRenderer = (props: ImageRendererProps) => {
    const { className, fileUrl, fileName, fileDate } = props;
    const isImg = isImageFormat(fileUrl);

    const fileFormat = fileUrl.split('.').pop() ?? '';

    const fileIconByFormat = (format: string) => {
        switch (format?.toLocaleLowerCase()) {
            case 'pdf':
                return 'icon-file_pdf';
            case 'doc':
            case 'docx':
                return 'icon-file_doc';
            case 'xls':
            case 'xlsx':
                return 'icon-file_xls';
            case 'ppt':
            case 'pptx':
                return 'icon-file_ppt';
            case 'zip':
            case 'rar':
                return 'icon-file_zip';
            case 'mp3':
            case 'wav':
                return 'icon-file_audio';
            case 'mp4':
            case 'avi':
            case 'mov':
                return 'icon-file_image';
            default:
                return 'icon-file_default';
        }
    };

    if (!isImg) {
        return (
            <div className={className}>
                <section title={fileName}>
                    <Svg iconId={fileIconByFormat(fileFormat)} />
                </section>
            </div>
        );
    }

    return (
        <div className={className}>
            <img title={fileName} src={fileUrl} alt={fileName} />
            {!isEmpty(fileDate) && (<span>{dateTimeUtils.getDate(fileDate)}</span>)}
        </div>
    );
};

export const useCultivationPeriodImagesModalLoadData = (props: CultivationPeriodImagesModalLoadDataProps) => {
    const { cultivationPeriodId, nonImageClass, thumbnailClass } = props;
    const [galleryImages, setGalleryImages] = useState<ImageProps[]>([]);
    const location = useLocation().pathname;
    const dispatch = useDispatch();

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const baseUrl = document.location.origin;
                const images: any[] = await cultivationPeriodsService.getCultivationPeriodFiles({ cultivationPeriodId });
                if (!isCancelled) {
                    images?.forEach(image => {
                        const fileUrl = `${baseUrl}/${image.fileUrl}`;
                        image.thumbnail = fileUrl;
                        image.srcSet = fileUrl;
                        image.thumbnailAlt = image.fileName;
                        image.originalAlt = image.fileName;
                        image.thumbnailTitle = image.fileName;
                        image.originalTitle = image.fileName;
                        // image.thumbnailClass = thumbnailClass;

                        image.renderItem = () => <ImageRenderer className={nonImageClass} fileName={image.fileName} fileUrl={fileUrl} isScouting={image.isScouting} fileDate={image.scoutingDate || image.fileDate} />;
                        image.renderThumbInner = () => <ThumbnailRenderer className={thumbnailClass} fileName={image.fileName} fileUrl={fileUrl} fileDate={image.scoutingDate || image.fileDate} />;
                        // if (!isImageFormat(image.fileUrl)) {
                        //     image.renderThumbInner = () => <div>anyad</div>
                        // }
                    });
                    setGalleryImages(images);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location?.pathname));
            }
        }
        if (!isNil(cultivationPeriodId) && location && !isNil(dispatch) && !isNil(nonImageClass) && !isNil(thumbnailClass)) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [cultivationPeriodId, location, dispatch, nonImageClass, thumbnailClass]);

    return { galleryImages };
};
