export const FILTERS = {
    SUBFILTERS: {
        RADIO: 'radio',
        SELECT: 'select',
    },
    TYPES: {
        PRODUCTIONYEARFILTER: { componentName: 'ProductionYearFilter', tagType: 'ProductionYearFilter' },
        PRODUCTIONYEARRANGE: { componentName: 'ProductionYearRange', tagType: 'ProductionYearRange' },
        DATEPERIODFILTER: { componentName: 'DatePeriodFilter', tagType: 'DatePeriodFilter' },
        CHECKBOXLIST: { componentName: 'CheckboxList', tagType: 'CheckboxTag' },
        FILTERTABLE: { componentName: 'FilterTablePanel', tagType: 'BasicTag' },
        RADIOLIST: { componentName: 'RadioList', tagType: 'RadioListNewTag' },
    },
    DEFAULTTRANSLATIONLABELS: {
        dateRange: 'Date range',
        workerIds: 'Employee',
        machineIds: 'Machine',
        workoperationTypeIds: 'Type',
        workoperationStateIds: 'Status',
        periodIds: 'Cultivation period',
        productionYear: 'Production year',
        partnerIds: 'Partner',
        productIds: 'Product',
        economicUnitIds: 'Economic unit',
        dateFilterTitle: 'Date',
        performanceIds: 'Performance',
        machineConnectionIds: 'Machine connection',
        otherIds: 'Other',
        creatorIds: 'Creator',
        showClosedPeriod: 'Show closed periods',
        cultureIds: 'Culture',
        farmIds: 'Farm',
        categoryIds: 'Category',
        priorityIds: 'Priority',
        farmIds: 'Farm',
        cultivationPeriodIds: 'Cultivation period',
        senderIds: 'Sender',
    },
};
