import { createUseStyles } from 'react-jss';
import Area from './Area';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        minWidth: 100,
        display: 'inline-block',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        margin: 'auto',
    },
    firstLine: {
        display: 'block',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        lineHeight: 1.125,

        fontSize: 15,
        fontWeight: 500,
        color: theme.color.jet,
    },
    inlineBlock: {
        display: 'inline-block',
    },
    areaContainer: {
        borderLeft: '1px solid',
        borderLeftColor: theme.color.pearl,
        paddingLeft: 5,
        marginLeft: 5,
    },
    secondLine: {
        display: 'flex',
        width: '100%',
        ...theme.overFlowText,
        height: 18,
        marginTop: 2,
        fontSize: 13,
        fontWeight: 400,
        lineHeight: 1.5,
        color: theme.color.raven,
    },
    area: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.color.raven,
    },
}));

export default function TwinLineWithArea(props) {
    const { line1, line2, area } = props;
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.firstLine}>
                {line1}
            </div>
            {(line2 || area)
                && (
                    <div className={classes.secondLine}>
                        {line2}
                        {area
                            && (
                                <span className={classes.areaContainer}>
                                    <Area value={area} style={classes.area} />
                                </span>)}
                    </div>)}
        </div>
    );
}
