import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import SearchBar from '@baseComponents/inputs/SearchBar';

const useStyles = createUseStyles({
    search: {
        margin: 5,
    },
});

export default function SearchDropDown(props) {
    const { search, isOpened } = props;
    const [searchValue, setSearchValue] = useState();
    const classes = useStyles();

    useEffect(() => {
        if (!isOpened && !isEmpty(searchValue)) {
            setSearchValue(undefined);
        }
    }, [isOpened, searchValue]);

    return (
        <SearchBar
            currentSearchValue={searchValue}
            setSearchValue={setSearchValue}
            onChange={search}
            className={classes.search}
        />
    );
}
