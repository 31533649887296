/* eslint-disable max-lines-per-function */
import { isEmpty, isArray } from 'lodash';
import { TABLE } from 'shared/src/constants';
import { simpleTableActions } from '../actions/simpleTableActions';

const initialState = {
    table: {},
    /*table: {
        'tableKey': {
            columnWidths,
            aggregateType: null,
            selectedItemIds: null,
            isEnabledSearching: null,
            globalSearchValue: null,
            globalColumnSearchValues: null,
            globalColumnSortingTypes: null,
            columns: null,
            tableData: null,
            filteredDatas: null,
            groupingBy: null,
            selectedGroup: null,
            isUpdateDomTable,
        },
    },*/
};

export function simpleTable(state = initialState, action) {
    switch (action.type) {
        case simpleTableActions.INIT_SIMPLE_TABLE: {
            const { tableKey, columns, tableData, tableGroup = null } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        //columnWidths: null,
                        //aggregateType: null,
                        selectedItemIds: null,
                        isEnabledSearching: null,
                        globalSearchValue: null,
                        globalColumnSearchValues: null,
                        globalColumnSortingTypes: null,
                        columns,
                        tableData,
                        filteredDatas: tableData,
                        groupingBy: tableGroup?.value,
                        selectedGroup: tableGroup,
                        multilineViewType: TABLE.VIEW_TYPE.SIMPLE,
                    },
                },
            };
        }
        case simpleTableActions.RESET_SIMPLE_TABLE: {
            delete state.table[action.payload.tableKey];
            return {
                ...state,
            };
        }
        case simpleTableActions.SET_COLUMN_WIDTHS: {
            const { tableKey, columnWidths } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        columnWidths,
                    },
                },
            };
        }
        case simpleTableActions.SET_AGGREGATE_TYPE: {
            const { tableKey, aggregateType } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        aggregateType,
                    },
                },
            };
        }
        case simpleTableActions.ADD_SELECTED_IDS: {
            const { tableKey, selectedIds } = action.payload;
            const selectedItemIds = filterAddedGroupItemIds(state.table[tableKey]?.selectedItemIds, selectedIds);
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        selectedItemIds,
                        allSelected: selectedItemIds.length === state.table[tableKey].tableData.length,
                    },
                },
            };
        }
        case simpleTableActions.REMOVE_SELECTED_IDS: {
            const { tableKey, selectedIds } = action.payload;
            const selectedItemIds = filterSelectedItem(state.table[tableKey]?.selectedItemIds, selectedIds);
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        selectedItemIds,
                        allSelected: selectedItemIds.length === state.table[tableKey].tableData.length,
                    },
                },
            };
        }
        case simpleTableActions.SET_SELECTED_IDS: {
            const { tableKey, selectedIds } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        selectedItemIds: selectedIds,
                        allSelected: selectedIds.length === state.table[tableKey].tableData.length,
                    },
                },
            };
        }
        case simpleTableActions.REMOVE_ALL_SELECTED_ID: {
            const { tableKey } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        selectedItemIds: [],
                        allSelected: false,
                    },
                },
            };
        }
        case simpleTableActions.SELECT_ALL_IDS: {
            const { tableKey } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        selectedItemIds: state.table[tableKey].tableData.map(row => row.id),
                        allSelected: true,
                    },
                },
            };
        }
        case simpleTableActions.SET_TABLE_ENABLED_SEARCHING: {
            const { tableKey, isEnabledSearching } = action.payload;
            //reset search filters
            if (!isEnabledSearching) {
                return {
                    ...state,
                    table: {
                        ...state.table,
                        [tableKey]: {
                            ...state.table[tableKey],
                            isEnabledSearching,
                            globalSearchValue: null,
                            globalColumnSearchValues: null,
                        },
                    },
                };
            }

            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        isEnabledSearching,
                    },
                },
            };
        }
        case simpleTableActions.SET_TABLE_GLOBAL_SEARCH_VALUE: {
            const { tableKey, globalSearchValue } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        globalSearchValue,
                    },
                },
            };
        }
        case simpleTableActions.SET_TABLE_GLOBAL_COLUMN_SEARCH_VALUES: {
            const { tableKey, globalColumnSearchValues } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        globalColumnSearchValues,
                    },
                },
            };
        }
        case simpleTableActions.ADD_TABLE_GLOBAL_COLUMN_SEARCH_VALUE: {
            const { tableKey, columnId, searchValue } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        globalColumnSearchValues: {
                            ...state.table[tableKey]?.globalColumnSearchValues,
                            [columnId]: searchValue,
                        },
                    },
                },
            };
        }
        case simpleTableActions.REMOVE_TABLE_GLOBAL_COLUMN_SEARCH_VALUE: {
            const { tableKey, columnId } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        globalColumnSearchValues: filterSearchValues(state.table[tableKey]?.globalColumnSearchValues, columnId),
                    },
                },
            };
        }
        case simpleTableActions.ADD_TABLE_GLOBAL_COLUMN_SORTING_TYPE: {
            const { tableKey, columnId, sortingType } = action.payload;
            if (sortingType === TABLE.SORTING.NONE) {
                const { [columnId]: value, ...stateWithoutDeletedItem } = state.table[tableKey]?.globalColumnSortingTypes;
                return {
                    ...state,
                    table: {
                        ...state.table,
                        [tableKey]: {
                            ...state.table[tableKey],
                            globalColumnSortingTypes: stateWithoutDeletedItem,
                        },
                    },
                };
            }
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        globalColumnSortingTypes: {
                            ...state.table[tableKey]?.globalColumnSortingTypes,
                            [action.payload.columnId]: action.payload.sortingType,
                        },
                    },
                },
            };
        }
        case simpleTableActions.REMOVE_TABLE_GLOBAL_COLUMN_SORTING_TYPE: {
            const { tableKey, columnId } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        globalColumnSortingTypes: filterSortingTypes(state.table[tableKey].globalColumnSortingTypes, columnId),
                    },
                },
            };
        }
        case simpleTableActions.SET_TABLE_FILTERED_DATAS: {
            const { tableKey, filteredDatas } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        filteredDatas,
                    },
                },
            };
        }
        case simpleTableActions.REMOVE_TABLE_FILTERED_DATAS: {
            const { tableKey } = action.payload;
            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        filteredDatas: null,
                    },
                },
            };
        }
        case simpleTableActions.SET_TABLE_DATA:
            return {
                ...state,
                table: {
                    ...state.table,
                    [action.payload.tableKey]: {
                        ...state.table[action.payload.tableKey],
                        tableData: action.payload.tableData,
                    },
                },
            };
        case simpleTableActions.REMOVE_TABLE_DATA_ROW_BY_ID: {
            const { tableKey, rowId, rowIdName } = action.payload;

            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        tableData: getTableDataByRemovedRowId(state.table[tableKey]?.tableData, rowId, rowIdName),
                    },
                },
            };
        }
        case simpleTableActions.UPDATE_ROW_IN_TABLE_DATA: {
            const { tableKey, rowId } = action.payload;

            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        tableData: getTableDataByRemovedRowId(state.table[tableKey]?.tableData, rowId),
                    },
                },
            };
        }
        case simpleTableActions.ADD_TABLE_DATA_ROW: {
            const { tableKey, rowData } = action.payload;

            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        tableData: [
                            ...state.table[tableKey].tableData,
                            rowData,
                        ],
                    },
                },
            };
        }
        case simpleTableActions.UPDATE_DOM_TABLE_DATA: {
            const { tableKey, isUpdateDomTable } = action.payload;

            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        isUpdateDomTable,
                    },
                },
            };
        }
        case simpleTableActions.SET_TABLE_MULTILINE_VIEW: {
            const { tableKey, multilineViewType } = action.payload;

            return {
                ...state,
                table: {
                    ...state.table,
                    [tableKey]: {
                        ...state.table[tableKey],
                        multilineViewType,
                    },
                },
            };
        }
        default:
            return state;
    }
}

function getTableDataByRemovedRowId(currentTableData, rowId, rowIdName = 'id') {
    if (isArray(currentTableData) && rowId) {
        return currentTableData?.filter(dataRow => dataRow[rowIdName] !== rowId);
    }
    return null;
}

function filterSelectedItem(selectedItemIds, selectedIds) {
    if (!isEmpty(selectedItemIds)) {
        if (isArray(selectedIds)) {
            return selectedItemIds.filter(selectedItemId => !selectedIds.includes(selectedItemId));
        }
        return selectedItemIds.filter(selectedItemId => selectedItemId !== selectedIds);
    }
    return null;
}

function filterSearchValues(globalColumnSearchValues, columnId) {
    if (!isEmpty(globalColumnSearchValues) && columnId) {
        return Object.keys(globalColumnSearchValues).filter(searchObjectKey => searchObjectKey !== columnId);
    }
    return null;
}

function filterSortingTypes(globalColumnSortingTypes, columnId) {
    if (!isEmpty(globalColumnSortingTypes) && columnId) {
        return Object.keys(globalColumnSortingTypes).filter(searchObjectKey => searchObjectKey !== columnId);
    }
    return null;
}

//add - remove selected item ids
function filterAddedGroupItemIds(currentState, addedIds) {
    const selectedIds = [...currentState || []];

    if (!currentState && !addedIds) {
        return null;
    }

    if (isArray(addedIds) && !isEmpty(addedIds)) {
        addedIds.forEach(addedId => {
            if (!selectedIds.find(selectedId => selectedId === addedId)) {
                selectedIds.push(addedId);
            }
        });
    }

    if (!isArray(addedIds) && addedIds && !selectedIds.find(id => id === addedIds)) {
        selectedIds.push(addedIds);
    }

    return selectedIds;
}
