import React from 'react';
import { createUseStyles } from 'react-jss';
import { Area } from '@baseComponents/formatters';

const useStyles = createUseStyles({
    areaCellWrapper: {
        width: '100%',
        height: '100%',
        minWidith: '100%',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'right',
    },
});

export default function AreaCell(props) {
    const { value, style } = props;
    const classes = useStyles();

    return (
        <div className={classes.areaCellWrapper}>
            <Area value={value} style={style} />
        </div>
    );
}
