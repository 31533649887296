import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    modalLeftPanelBodyWrapper: {
        paddingTop: 10,
        paddingLeft: 30,
        paddingRight: 30,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
        overflowY: 'auto',
        ...theme.verticalScrollbar,
    },
}));

export default function ModalLeftPanelBody(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.modalLeftPanelBodyWrapper, className)}>
            {children}
        </div>
    );
}
