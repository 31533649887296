import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const mapService = {
    getWeather,
    getNdvi,
    createNdvi,
    getDefaultMapCenter,
    getGeometryWithAttributes,
    getSingleGeometry,
    addHoleGeometry,
    addPolygonToGeometry,
    getWeatherForecast,
    getGeometries,
    getCultivationPeriodNearestLocation,
};

async function getWeather(periodId, mapCompanyId) {
    const url = routerHelper.getUrlWithParameters('/api/map/weather', { periodId, mapCompanyId });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getNdvi(yearId, mapCompanyId, avId, polygonType, maxNdviCount) {
    const url = routerHelper.getUrlWithParameters('/api/map/ndvi', { yearId, mapCompanyId, avId, polygonType, maxNdviCount });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function createNdvi(mapCompanyId, avId, polygonType) {
    const url = routerHelper.getUrlWithParameters('/api/map/ndvi', { mapCompanyId, avId, polygonType });
    const response = await sendServerRequest('POST', url);
    return response;
}

async function getDefaultMapCenter(mapCompanyId) {
    const url = routerHelper.getUrlWithParameters('/api/map/center', { mapCompanyId });
    const response = await sendServerRequest('POST', url);
    return response;
}

async function getGeometryWithAttributes(mapCompanyId, yearId, avType, userId) {
    const url = routerHelper.getUrlWithParameters('/api/map/geodata', { mapCompanyId, yearId, avType, userId });
    const response = await sendServerRequest('POST', url);
    return response;
}

async function getSingleGeometry(mapCompanyId, avType, avId) {
    const url = routerHelper.getUrlWithParameters('/api/map/geometry_single', { mapCompanyId, avType, avId });
    const response = await sendServerRequest('POST', url);
    return response;
}

async function addHoleGeometry(mapCompanyId, avId, holeGeometry, canvasGeometry) {
    const url = routerHelper.getUrlWithParameters('/api/map/add_hole_geometry', { mapCompanyId, avId, holeGeometry, canvasGeometry });
    const response = await sendServerRequest('POST', url);
    return response;
}

async function addPolygonToGeometry(mapCompanyId, avId, newGeometryElement, canvasGeometry) {
    const url = routerHelper.getUrlWithParameters('/api/map/add_polygon_to_geometry', { mapCompanyId, avId, newGeometryElement, canvasGeometry });
    const response = await sendServerRequest('POST', url);
    return response;
}

async function getWeatherForecast(periodId, mapCompanyId, forecastType) {
    const url = routerHelper.getUrlWithParameters('/api/map/get_weather_forecast', { periodId, mapCompanyId, forecastType });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getGeometries(mapCompanyId, avType, avIds) {
    const url = routerHelper.getUrlWithParameters('/api/map/geometries', { mapCompanyId, avType, avIds });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCultivationPeriodNearestLocation(cultivationPeriodId) {
    const url = routerHelper.getUrlWithParameters('/api/map/cultivation-period-nearest-location', { cultivationPeriodId });
    const response = await sendServerRequest('GET', url);
    return response;
}
