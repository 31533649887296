import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { theme } from '@styles/theme';
import area from '@turf/area';
import { Feature, FeatureCollection, GeoJsonProperties, Geometry, Polygon } from 'geojson';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import { feature as convertToFeature, featureCollection as convertToFeatureCollection } from '@turf/helpers';
import { isFunction, isNil } from 'lodash';
import { useSlovakReportContext } from '@map/services/slovakianReports/slovakReportContext';
import { useAlert } from '@utils/index';
import { ProcessGeomErrorCode, ProcessGeomWarningCode } from '@map/services/mapTypes';
import useTranslate from '@i18n/useTranslate';
import { useMapGetOnFileUploadError } from '@map/services/mapHooks';
import { validatePolygonForSK } from './skDrawManager';
import DrawMapScreenComponent from './DrawControlComponent';

const overrideMapOptions: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
};

enum DrawModes {
    NAVIGATION,
    DRAW,
    HOLE,
    UNION
}

type TProps = {
    setSavedGeomFeature?: (feature: Feature<Geometry, GeoJsonProperties> | null) => void,
    setSavedGeomId?: (id: number) => void,
    onDrawingSaveClick?: ({ selectedLpis }) => void;
    entityId?: number;
    parentId?: number;
    layerType: BuiltInLayerTypes;
    initDrawnFeature?: Feature<Geometry, GeoJsonProperties>;
    zoomEntityBBox?: google.maps.LatLngBounds,
    withDrawingSaveButton: boolean,
    setZoomEntityBBoxCallback?: (bbox: google.maps.LatLngBounds) => void,
    withSlovakOverlay?: boolean;
}

export default function DrawControlContainer({ setSavedGeomFeature, setSavedGeomId, entityId, parentId, layerType, onDrawingSaveClick, initDrawnFeature, zoomEntityBBox, withDrawingSaveButton, setZoomEntityBBoxCallback, withSlovakOverlay }: TProps) {
    const [store, controller] = useMapContext();
    const [skStore, skController] = useSlovakReportContext();
    const [isGeomLoaded, setIsGeomLoaded] = useState<boolean>(false);

    const drawingManager = store.googleRefs.drawingManager;
    const drawDataLayer = store.drawing.dataLayer;
    const drawnGeometry = store.drawing.drawnFeature;

    const selectedLpisPolygonRef = useRef<google.maps.Data.Feature | null>(null);

    const fileInput = useRef<HTMLInputElement>(null);

    const drawnFeatureInitialized = useRef(false);

    const [polygonArea, setPolygonArea] = useState<number>(0);
    const [drawMode, setDrawMode] = useState<DrawModes>(DrawModes.NAVIGATION);

    const calculateAreaInHa = (features: FeatureCollection | Feature) => area(features) / 10000;

    const onFileUploadError = useMapGetOnFileUploadError();

    useEffect(() => {
        if (!store.googleRefs.map) { return; }

        const newDrawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: false,
            polygonOptions: theme.map.drawPolygonStyle,
        });

        const newDrawDataLayer = new google.maps.Data();
        newDrawDataLayer.setMap(store.googleRefs.map);
        newDrawDataLayer.setStyle(theme.map.drawPolygonStyle);

        controller.setDrawingManagerRefs(newDrawingManager, newDrawDataLayer);
    }, [controller, store.googleRefs.map]);

    const preDrawnFeature = useRef<Feature<Geometry, GeoJsonProperties> | null>(null);

    useEffect(() => {
        function onDrawModeChange(isDrawMode: boolean) {
            if (!isDrawMode) {
                store.drawing.dataLayer?.forEach(feature => store.drawing.dataLayer?.remove(feature));
            }
        }

        if (withSlovakOverlay) {
            onDrawModeChange(skStore.isDrawMode);
        }
    }, [skStore.isDrawMode, store.drawing.dataLayer, store.googleRefs.drawingManager, store.googleRefs.map, withSlovakOverlay]);

    useEffect(() => {
        if (controller && setSavedGeomFeature && setSavedGeomId) {
            if (store.drawing.drawnFeature?.id) {
                setSavedGeomId(store.drawing.drawnFeature.id as number);
            }

            if (store.drawing.drawnFeature?.properties?.geomId) {
                setSavedGeomId(store.drawing.drawnFeature.properties.geomId as number);
            }

            if (!isEqual(store.drawing.drawnFeature, preDrawnFeature.current)) {
                preDrawnFeature.current = store.drawing.drawnFeature;
                setSavedGeomFeature(store.drawing.drawnFeature);
            }
        }
    }, [controller, store.drawing.drawnFeature, setSavedGeomFeature, setSavedGeomId]);

    useEffect(() => {
        if (!entityId || !store.layering.attributes || !layerType) {
            return;
        }

        store.layering.attributes?.filter(attribute => attribute.isActive && attribute.layerName === layerType).forEach(attribute => {
            attribute.dataLayer.forEach(feature => {
                if (feature.getProperty('entityId') === entityId) {
                    attribute.dataLayer.remove(feature);
                    attribute.labelLayer.forEach((marker, index) => {
                        if (marker?.get('entityId') === feature?.getProperty('entityId')) {
                            attribute.labelLayer.removeAt(index);
                            marker.unbindAll();
                            marker.setMap(null);
                        }
                    });
                    if (!isGeomLoaded) {
                        if (withDrawingSaveButton) {
                            feature.toGeoJson(geoJson => controller.addGeometryToDrawDataLayer(geoJson as Feature));
                        }
                        setIsGeomLoaded(true);
                    }
                }
            });
        });
    }, [controller, entityId, isGeomLoaded, layerType, store.layering.attributes, withDrawingSaveButton]);

    useEffect(() => {
        if (drawnGeometry) {
            setPolygonArea(calculateAreaInHa(drawnGeometry));
        } else {
            setPolygonArea(0);
        }
    }, [drawnGeometry]);

    useEffect(() => {
        selectedLpisPolygonRef.current = skStore.selectedLpisPolygon;
    }, [skStore.selectedLpisPolygon]);

    const overlayToFeature = overlay => {
        const overlayItem = Array.isArray(overlay) ? overlay[0] : overlay;
        const path = overlayItem.overlay ? overlayItem.overlay.getPath().getArray() : overlayItem.getPath().getArray();
        const polygon = new google.maps.Data.Polygon([path]);
        const feature = new google.maps.Data.Feature();
        feature.setGeometry(polygon);

        return feature;
    };

    useEffect(() => {
        if (!drawDataLayer) {
            return;
        }

        const mouseListener = drawDataLayer.addListener('mouseup', () => {
            setTimeout(() => {
                drawDataLayer?.toGeoJson((json: any) => {
                    const drawnFeature = json.features[0] ?? null;

                    if (withSlovakOverlay) {
                        if (selectedLpisPolygonRef.current && skStore.isDrawMode) {
                            const lpisLayer = store.layering.attributes?.find(attr => attr.layerName === BuiltInLayerTypes.LPIS_FIELD);

                            if (!lpisLayer) {
                                return;
                            }

                            validatePolygonForSK({ drawnFeature, selectedLpisPolygon: selectedLpisPolygonRef.current as any, dataLayer: lpisLayer.dataLayer }).then(({ errors, newPolygon }) => {
                                drawDataLayer?.forEach(feature => drawDataLayer.remove(feature));
                                drawDataLayer?.addGeoJson(newPolygon as any);
                                controller.setDrawnGeometry(newPolygon as any);
                            });
                        }
                    } else {
                        controller.setDrawnGeometry(drawnFeature);
                    }
                });
            }, 0);
        });

        return () => {
            google.maps.event.removeListener(mouseListener);
        };
    }, [controller, drawnGeometry, drawDataLayer, withSlovakOverlay, store.layering.attributes, skStore.isDrawMode]);

    const removeOverlayFromMap = overlay => {
        if (overlay.overlay) {
            overlay.overlay.setMap(null);
        } else {
            overlay.setMap(null);
        }
    };

    useEffect(() => {
        if (!drawingManager) {
            return;
        }

        const overlayListener = drawingManager.addListener('overlaycomplete', overlay => {
            removeOverlayFromMap(overlay);
            drawingManager.setMap(null);

            const feature = overlayToFeature(overlay);
            if (drawMode === DrawModes.HOLE) {
                feature.toGeoJson((jsonFeature: any) => {
                    controller.diffDrawnGeometryWithGeometry(jsonFeature as Feature);
                });
            } else if (drawMode === DrawModes.UNION) {
                feature.toGeoJson((jsonFeature: any) => {
                    controller.unionDrawnGeometryWithGeometry(jsonFeature as Feature);
                });
            } else if (!withSlovakOverlay) {
                drawDataLayer?.add(feature);
                feature.toGeoJson((feat: any) => controller.setDrawnGeometry(feat));
            } else if (selectedLpisPolygonRef.current && withSlovakOverlay) {
                feature.toGeoJson((jsonFeature: any) => {
                    const lpisLayer = store.layering.attributes?.find(attr => attr.layerName === BuiltInLayerTypes.LPIS_FIELD);

                    if (!lpisLayer) {
                        return;
                    }

                    validatePolygonForSK({ drawnFeature: jsonFeature, selectedLpisPolygon: selectedLpisPolygonRef.current as any, dataLayer: lpisLayer.dataLayer }).then(({ errors, newPolygon }) => {
                        drawDataLayer?.forEach(feature => drawDataLayer.remove(feature));
                        drawDataLayer?.addGeoJson(newPolygon as any);
                        feature.toGeoJson((feat: any) => controller.setDrawnGeometry(newPolygon as any));
                    });
                });
            }
        });

        return () => {
            google.maps.event.removeListener(overlayListener);
        };
    }, [controller, drawingManager, drawDataLayer, drawMode, withSlovakOverlay, store.layering.attributes]);

    useEffect(() => {
        if (drawingManager && initDrawnFeature && store.layering && store.googleRefs.map && controller.setDrawnGeometry) {
            if (drawnFeatureInitialized.current === false) {
                const feature = convertToFeature(initDrawnFeature.geometry);
                drawDataLayer?.addGeoJson(feature);
                controller.setDrawnGeometry(initDrawnFeature);

                const bounds = new google.maps.LatLngBounds();
                drawDataLayer?.forEach(f => f.getGeometry()?.forEachLatLng(latLng => bounds.extend(latLng)));
                store.googleRefs.map?.fitBounds(bounds);
                if (isFunction(setZoomEntityBBoxCallback)) {
                    setZoomEntityBBoxCallback(bounds);
                }
                drawnFeatureInitialized.current = true;
            }
        }
    }, [initDrawnFeature, drawingManager, drawDataLayer, store.layering, store.googleRefs.map, controller, setZoomEntityBBoxCallback]);

    const onNavigationSelect = () => {
        if (!isNil(zoomEntityBBox)) {
            store.googleRefs.map?.fitBounds(zoomEntityBBox);
            return;
        }

        setDrawMode(DrawModes.NAVIGATION);
        drawingManager?.setMap(null);
    };

    const deletePolygon = () => {
        drawDataLayer?.forEach(feature => drawDataLayer.remove(feature));
        controller.setDrawnGeometry(null);
    };

    const onDrawSelect = () => {
        setDrawMode(DrawModes.DRAW);
        deletePolygon();
        drawingManager?.setMap(store.googleRefs.map);
    };

    const onHoleDrawSelect = () => {
        setDrawMode(DrawModes.HOLE);
        drawingManager?.setMap(store.googleRefs.map);
    };

    const onUnionDrawSelect = () => {
        setDrawMode(DrawModes.UNION);
        drawingManager?.setMap(store.googleRefs.map);
    };

    const onInputClick = () => {
        fileInput.current?.click();
    };

    const onSave = () => {
        if (onDrawingSaveClick) {
            onDrawingSaveClick({ selectedLpis: selectedLpisPolygonRef.current });
        }
    };

    const onFillLPIS = () => {
        if (selectedLpisPolygonRef.current) {
            selectedLpisPolygonRef.current.toGeoJson((jsonFeature: any) => {
                drawDataLayer?.forEach(feature => drawDataLayer.remove(feature));
                drawDataLayer?.addGeoJson(jsonFeature);
                controller.setDrawnGeometry(jsonFeature as Feature);
            });
        }
    };

    const onFileUpload = async (ev: React.ChangeEvent<HTMLInputElement>) => {
        const formData = new FormData();
        if (ev.target.files && ev.target.files[0]) {
            formData.append('file', ev.target.files[0]);

            await controller.processGeometryFileAndSetAsDrawnGeometry(formData, onFileUploadError, withSlovakOverlay, selectedLpisPolygonRef.current);
            const bounds = new google.maps.LatLngBounds();

            drawDataLayer?.forEach(feature => {
                feature.getGeometry()?.forEachLatLng(latLng => bounds.extend(latLng));
            });

            if (!bounds.isEmpty()) {
                store.googleRefs.map?.fitBounds(bounds);
            }
        }
        if (!isNil(fileInput.current)) {
            fileInput.current.value = '';
        }
    };

    if (withSlovakOverlay && !skStore.isDrawMode) {
        return null;
    }

    return (
        <DrawMapScreenComponent
            onNavigationSelect={onNavigationSelect}
            onDrawSelect={onDrawSelect}
            deletePolygon={deletePolygon}
            onHoleDrawSelect={onHoleDrawSelect}
            onUnionDrawSelect={onUnionDrawSelect}
            onInputClick={onInputClick}
            onFileUpload={onFileUpload}
            onSaveClick={onSave}
            onFillLPIS={onFillLPIS}
            fileInputRef={fileInput}
            isCanvasNotBlank={!!drawnGeometry}
            polygonArea={polygonArea}
            withDrawingSaveButton={withDrawingSaveButton}
            withSlovakOverlay={withSlovakOverlay}
        />
    );
}
