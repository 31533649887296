/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isFunction, isEmpty } from 'lodash';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    iconSvgWrapper: {
        height: '100%',
        width: (props: any) => props.width || '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: (props: any) => !props.isShowName && 'center',
        cursor: (props: any) => props.isClickable && 'pointer',
    },
    iconSvg: {
        width: (props: any) => props.width || 24,
        height: (props: any) => props.height || 24,
        fill: (props: any) => props.iconColor || theme.color.jet,
    },
    name: {
        ...theme.overFlowText,
        color: theme.color.jet,
        display: 'block',
        height: '100%',
        lineHeight: '56px',
        fontSize: 13,
        fontWeight: 500,
        verticalAlign: 'middle',
        backgroundColor: 'transparent',
        paddingLeft: 5,
    },
}));

export default function IconSvgCell(props) {
    const { rowData, cellData, getIcon, getIconColor, getTitle, icon, iconColor, onClick, isShowName, getIsDisabled } = props;
    const { width, height, style, className } = props;
    const classes = useStyles({
        iconColor: iconColor || (isFunction(getIconColor) ? getIconColor(rowData) : null),
        isClickable: isFunction(onClick),
        isShowName,
        width,
        height,
    } as any);

    function onClickSvg(event) {
        if (isFunction(onClick)) {
            event.stopPropagation();
            onClick(rowData || cellData);
        }
    }

    if ((!isFunction(getIcon) && isEmpty(icon)) || (isFunction(getIcon) && isEmpty(getIcon(rowData))) || (isFunction(getIsDisabled) && getIsDisabled(rowData))) {
        return null;
    }

    return (
        <div className={clsx(classes.iconSvgWrapper, className)} title={isFunction(getTitle) ? getTitle(rowData) : getTitle} onClick={onClickSvg} role="button" tabIndex={0} style={style}>
            <Svg
                iconId={isFunction(getIcon) ? getIcon(rowData) : icon}
                style={classes.iconSvg}
            />
            {isShowName && (
                <span className={classes.name}>{getTitle(rowData)}</span>
            )}
        </div>
    );
}
