import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, head } from 'lodash';

import { getGroupOperationColumn } from '@baseComponents/tableNew/tableColumns/tableColumns';

//calculate fixed columns width and position group operation checkbox column
export default function useTableFixedColumn(props) {
    const { isBasePageTable, canUseGroupOperation, useRowSelection, tableKey, isLoaded } = props;

    const [leftSideWidth, setLeftSideWidth] = useState<any>(null);
    const [rightSideWidth, setRightSideWidth] = useState<any>(null);
    const [leftFixedColumns, setLeftFixedColumns] = useState<any>(null);
    const [rightFixedColumns, setRightFixedColumns] = useState<any>(null);
    const [mainColumns, setMainColumns] = useState<any>(null);
    const [hasScrollbar, setHasScrollbar] = useState(false);
    const columns = useSelector((state: any) => state.basePage?.table?.columns);
    const simpleTableColumns = useSelector((state: any) => state.simpleTable?.table?.[tableKey]?.columns);
    const isEnabledGroupOperation = useSelector((state: any) => state.basePage?.isEnabledGroupOperation);

    //simple table scroller
    useEffect(() => {
        if (tableKey && simpleTableColumns && !isBasePageTable && isLoaded) {
            setTimeout(() => {
                const midColNodes = document.querySelectorAll(`.headerRow.midCol${tableKey}`);
                const firstColNode: any = midColNodes ? head(midColNodes) : null;

                if (firstColNode) {
                    setHasScrollbar(+firstColNode.offsetWidth < +firstColNode.scrollWidth);
                }
            }, 300);
        }
    }, [tableKey, simpleTableColumns, isBasePageTable, isLoaded]);

    //basePage table - check fixed column has width
    useEffect(() => {
        if (isBasePageTable && !isEmpty(columns)) {
            handleColumnList(columns, canUseGroupOperation, null);
        }
    }, [columns, isBasePageTable, canUseGroupOperation]);

    //simple table - check fixed column has width
    useEffect(() => {
        if (!isBasePageTable && !isEmpty(simpleTableColumns)) {
            handleColumnList(simpleTableColumns, canUseGroupOperation, tableKey);
        }
    }, [simpleTableColumns, isBasePageTable, tableKey, canUseGroupOperation]);

    //dynamic column handler function
    function handleColumnList(columnList, canUseGroupOperations, tableId) {
        function handleSelectableRows(allColumn, canUseSelectableColumn) {
            const selectableColumn = getGroupOperationColumn(tableId);
            const isFirstColumn = columnList.find(column => column.isFirstColumn);

            if (!isEmpty(isFirstColumn)) {
                if (canUseSelectableColumn) {
                    allColumn.unshift(isFirstColumn, selectableColumn);
                } else {
                    allColumn.unshift(isFirstColumn);
                }
            } else if (canUseSelectableColumn) {
                allColumn.unshift(selectableColumn);
            }
        }

        const leftColumnList: any = [];
        const rightColumnList: any = [];
        const mainColumnList: any = [];
        let leftSideSumWidth = 0;
        let rightSideSumWidth = 0;

        columnList?.forEach(column => {
            if (column.isLeftFixed && !column.width) { //Bad left column -> is main column
                column.isLeftFixed = false;
                mainColumnList.push(column);
            } else if (column.isLeftFixed && column.width) { //is left column or title column-fix left side
                column.maxWidth = column.width;
                leftColumnList.push(column);
                leftSideSumWidth += Number(column.width);
            } else if (column.isRightFixed && !column.width) { ////Bad right column -> is main column
                column.isRightFixed = false;
                mainColumnList.push(column);
            } else if (column.isRightFixed && column.width) { //is right column
                column.maxWidth = column.width;
                rightColumnList.push(column);
                rightSideSumWidth += Number(column.width);
            } else { //is main column
                mainColumnList.push(column);
            }

            if (column.isFirstColumn) { //Column is the first. Don't need to show again in the leftColumnList
                leftColumnList.shift();
            }
        });

        //group operation checkbox
        const selectableColumn = canUseGroupOperation ? getGroupOperationColumn(tableId) : null;
        if (canUseGroupOperation && leftSideSumWidth && selectableColumn && isEnabledGroupOperation) {
            leftSideSumWidth += selectableColumn.width;
        } else if (useRowSelection && leftSideSumWidth && selectableColumn) {
            leftSideSumWidth += selectableColumn.width;
        } else if (!isBasePageTable && leftSideSumWidth && selectableColumn && canUseGroupOperation) {
            leftSideSumWidth += selectableColumn.width;
        } else if (isBasePageTable && selectableColumn && canUseGroupOperation) {
            leftSideSumWidth += selectableColumn.width;
        }

        //group operation
        if (leftSideSumWidth > 0) {
            handleSelectableRows(leftColumnList, canUseGroupOperations);
        } else {
            handleSelectableRows(mainColumnList, canUseGroupOperations);
        }

        setLeftFixedColumns(leftColumnList);
        setRightFixedColumns(rightColumnList);
        setMainColumns(mainColumnList);

        setLeftSideWidth(leftSideSumWidth);
        setRightSideWidth(rightSideSumWidth);
    }

    return {
        leftSideWidth,
        rightSideWidth,

        leftFixedColumns,
        rightFixedColumns,
        mainColumns,
        hasScrollbar,
    };
}
