import Svg from '@baseComponents/Svg';
import FarmModal from '@customComponents/modals/farmModal/FarmModal';
import renderModal from '@modals/modalNew/modalFunctions';
import useTranslate from '@i18n/useTranslate';
import { ThemeType } from '@map/services/mapTypes';
import { isArray } from 'lodash';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import MapFilterBase from './MapFilterBase';
import MapFilterFarmSelected from './MapFilterFarmSelected';
import { MapFilterSchema } from './MapFilterSchema';
import MapFilterWorkOperationMainGroupSelected from './MapFilterWorkOperationMainGroupSelected';
import WorkOperationSelectModal from './modals/WorkOperationModal/WorkOperationSelectModal';

type StlyeType = {
    buttonHovered: boolean
}

type Classes = 'container' | 'buttonContainer' | 'title' | 'openFilterTableButton' | 'addIcon' | 'filterTable' | 'addIcon' | 'tagOuterContainer'

const useStyles = createUseStyles<Classes, StlyeType, ThemeType>(theme => ({
    container: {
        width: 100,
    },
    buttonContainer: {
        width: 199,
    },
    title: {
        marginBottom: 15,
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
    },
    openFilterTableButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: '100%',
        border: `2px dashed ${theme.color.gray}`,
        borderRadius: 6,
        outline: 'none',
        cursor: 'pointer',
        borderColor: props => props.buttonHovered && theme.color.main,
        backgroundColor: props => props.buttonHovered && theme.color.opacity.main,
    },
    addIcon: {
        fill: props => props.buttonHovered && theme.color.shadow_green,
        color: theme.color.raven,
        height: 24,
    },
    filterTable: {
        overflow: 'scroll',
    },
    tagOuterContainer: {
        height: 40,
        marginTop: 15,
        '& span': {
            ...theme.overFlowText,
        },
    },
}));
export default function MapFilterWorkOperationMainGroup(props) {
    const filterContext = useFormContext<MapFilterSchema>();

    const [buttonHovered, setButtonHovered] = useState(false);

    const { t } = useTranslate();

    const classes = useStyles({ buttonHovered });

    const openFarmModal = () => {
        const workOperationProps: any = {
            isMultiselect: true,
            onChange: (selected: any) => {
                if (isArray(selected)) {
                    const woMainGroupIds = selected.map(workGrp => workGrp.id);
                    filterContext.setValue('workOperationMainGroupIds', woMainGroupIds);
                    filterContext.setValue('workOperationMainGroups', selected);
                } else {
                    filterContext.setValue('workOperationMainGroupIds', [selected.id]);
                    filterContext.setValue('workOperationMainGroups', [selected]);
                }
            },
            selectedRowIds: filterContext.getValues('workOperationMainGroupIds'),
        };
        renderModal(WorkOperationSelectModal, workOperationProps, true);
    };

    return (
        <MapFilterBase>
            <div className={classes.buttonContainer}>
                <div className={classes.title}>
                    {t('map.filterWorkOperation', 'Work Operation')}
                </div>
                <div
                    className={classes.openFilterTableButton}
                    onMouseOver={() => setButtonHovered(true)}
                    onMouseOut={() => setButtonHovered(false)}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onFocus={() => { }}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onBlur={() => { }}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        openFarmModal();
                    }}
                    aria-hidden="true"
                >
                    <Svg
                        iconId="icon-add"
                        style={classes.addIcon}
                    />
                </div>

                <div className={classes.tagOuterContainer}>
                    <MapFilterWorkOperationMainGroupSelected />
                </div>
            </div>
        </MapFilterBase>

    );
}
