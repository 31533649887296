import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    errorMessage: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        color: theme.color.destructive,
    },
}));

export default function InputErrorMessage(props) {
    const { isInvalid, errorMessage } = props;
    const classes = useStyles();

    if (!isInvalid || !errorMessage) {
        return null;
    }

    return (
        <div className={classes.errorMessage}>
            {errorMessage}
        </div>
    );
}
