import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes, MapMode } from '@map/services/mapEnums';
import { Layer, LayerAttribute, LayerAttributeStatus, ThemeType } from '@map/services/mapTypes';
import { isEmpty, isNull } from 'lodash';
import partition from 'lodash/partition';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import LayerControlPanelLayerSection from './LayerControlPanelLayerSection';
import LayerSelectPanel from './LayerSelectPanel';
import LegendPanel from './LegendPanel';

type Classes = 'overlay' | 'mainContainer' | 'panelContainer' | 'legendContainer' | 'panelHr' | 'hidden';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    overlay: {
        position: 'absolute',
        zIndex: 2,
        marginTop: 30,
        marginLeft: 30,
        display: 'flex',
        gap: 15,
        top: 0,
        left: 0,
    },
    mainContainer: {
        width: 280,
        alignSelf: 'flex-start',
    },
    panelContainer: {
        width: 280,
        backgroundColor: theme.color.white,
        borderRadius: 6,
        boxShadow: theme.shadows.map,
        alignSelf: 'flex-start',
    },
    legendContainer: {
        marginTop: 20,
        width: 280,
        backgroundColor: theme.color.white,
        borderRadius: 6,
        boxShadow: theme.shadows.map,
        alignSelf: 'flex-start',
    },
    panelHr: {
        margin: 0,
        height: 2,
        backgroundColor: theme.color.pearl,
    },
    hidden: {
        opacity: 0,
        pointerEvents: 'none',
    },
}));

type TProps = {
    setIsUploadWindowOpen: (isOpen: boolean) => void,
    setIsExportWindowOpen: (isOpen: boolean) => void,
    isMeasuring: boolean
    isFileUploadAllowed: boolean;
    isDefaultLayerTypeMandatory: boolean;
    layerType: BuiltInLayerTypes;
    filteredLayerTypes: BuiltInLayerTypes[];
    withSelector?: boolean;
}

export interface LayerWithAttributes extends Layer {
    attributes: LayerAttribute[]
}

export default function LayerControlPanel({ setIsUploadWindowOpen, setIsExportWindowOpen, isFileUploadAllowed, isDefaultLayerTypeMandatory, isMeasuring, layerType, filteredLayerTypes, withSelector }: TProps) {
    const [store, controller] = useMapContext();
    const classes = useStyles();

    const [isAddPanelOpen, setIsAddPanelOpen] = useState(false);
    const [isLegendPanelOpen, setIslegendPanelOpen] = useState(false);

    const [activeAttributes, disabledAttributes] = useMemo(() => partition(store.layering.attributes, attribute => attribute.isActive !== LayerAttributeStatus.INACTIVE), [store.layering.attributes]);

    const layersWithAttributes: LayerWithAttributes[] = useMemo(() => {
        if (!store.layering.layers) {
            return [];
        }
        return store.layering.layers?.filter(layer => !filteredLayerTypes.includes(layer.name as BuiltInLayerTypes)).map(layer => {
            const layerAttributes = disabledAttributes?.filter(attribute => attribute.layerId === layer.id).sort(attribute => (attribute.isDefault ? -1 : 1));

            return {
                ...layer,
                attributes: layerAttributes ?? [],
            };
        });
    }, [disabledAttributes, filteredLayerTypes, store.layering.layers]);

    useEffect(() => {
        store.layering.attributes?.forEach(attribute => {
            attribute.dataLayer.setStyle(
                {
                    ...attribute.dataLayer.getStyle(),
                    zIndex: [...store.layering.order].reverse().indexOf(attribute.id),
                },
            );
        });
        if (!isEmpty(store.layering.order)) {
            const id = store.layering.order?.[0];
            const layerAttribute = store.layering.attributes?.find(attribute => attribute.id === id);
            if (layerAttribute) {
                setIslegendPanelOpen(!isEmpty(layerAttribute.legendList));
            }
        } else {
            setIslegendPanelOpen(false);
        }
    }, [store.layering.order, store.layering.layers, store.layering.attributes]);

    return (
        <div id="overlayPanel" className={clsx(classes.overlay, store.mapMode === MapMode.PLANNING_EDIT ? null : null)}>
            <div className={classes.mainContainer}>
                <div className={classes.panelContainer}>
                    {(store.layering.layers) && <LayerControlPanelLayerSection
                        layerAttributes={activeAttributes}
                        setIsAddPanelOpen={setIsAddPanelOpen}
                        isLayerSelectOpen={isAddPanelOpen}
                        isMeasuring={isMeasuring}
                        isDefaultLayerTypeMandatory={isDefaultLayerTypeMandatory}
                        layerType={layerType}
                        withSelector={withSelector}
                    />}
                </div>
                <div className={classes.legendContainer}>
                    {isLegendPanelOpen && <LegendPanel />}
                </div>
            </div>
            {isAddPanelOpen && <LayerSelectPanel
                layersWithAttributes={layersWithAttributes}
                addAttributesToMap={controller.addLayerAttributesToMap}
                closePanel={() => setIsAddPanelOpen(false)}
                setIsUploadWindowOpen={isOpen => { setIsUploadWindowOpen(isOpen); setIsAddPanelOpen(!isOpen); }}
                setIsExportWindowOpen={isOpen => { setIsExportWindowOpen(isOpen); setIsAddPanelOpen(!isOpen); }}
                isFileUploadAllowed={isFileUploadAllowed}
            />}
        </div>
    );
}
