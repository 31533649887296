import { isBoolean, isEmpty, isUndefined } from 'lodash';
import { languageUtils } from 'shared/src/modules';
import { SETTINGS } from 'shared/src/constants';
import { companySettingsModule } from 'shared/src/company/companySettings';
import { hasEverySystemSettings } from 'shared/src/permissions';
import { companyService } from '@services';
import { simpleAction } from './baseActions';
// eslint-disable-next-line import/no-cycle
import { errorActions } from './errorActions';

const companyActions = {
    getUsingNdvi,
    getMaxNdviCount,
    getUsingWeather,
    getUsingSevenDaysWeather,
    getSetting,
    getSettings,
    reloadCompanyList,
    setActiveCompanyList,
    getActiveCompanyList,
    deleteActiveCompanyList,
    hasEverySettings,
    setSettings,
    setNumberFormatSettings,
    SET_COMPANY: 'COMPANY_SET_COMPANY',
    SET_SETTINGS: 'SETTINGS_SET_SETTINGS',
    SET_ACTIVE_COMPANY_LIST: 'COMPANY_SET_ACTIVE_COMPANY_LIST',
    SET_COMPANY_LIST: 'COMPANY_SET_COMPANY_LIST',
    SET_NUMBER_FORMAT_SETTINGS: 'COMPANY_SET_NUMBER_FORMAT_SETTINGS',
    //currency
    setCurrencyVisibility,
    setIsSelectedCurrencySwitch,
    setCurrentCurrency,
    setPrimaryCurrency,
    setSecondaryCurrency,
    getIsVisibleCurrencySwitch,
    getIsVisibleAndSelectedCurrencySwitch,
    getIsSelectedSecondaryCurrency,
    SET_CURRENCY_VISIBILITY: 'COMPANY_SET_CURRENCY_VISIBILITY',
    DELETE_ACTIVE_COMPANY_LIST: 'COMPANY_DELETE_ACTIVE_COMPANY_LIST',
    SET_IS_SELECTED_CURRENCRY_SWITCH: 'COMPANY_SET_IS_SELECTED_CURRENCRY_SWITCH',
    SET_CURRENT_CURRENCRY: 'COMPANY_SET_CURRENT_CURRENCRY',
    SET_PRIMARY_CURRENCY: 'COMPANY_SET_PRIMARY_CURRENCY',
    SET_SECONDARY_CURRENCY: 'COMPANY_SET_SECONDARY_CURRENCY',
};

export { companyActions };

function setActiveCompanyList(activeCompanyList) {
    return async dispatch => {
        dispatch(simpleAction(companyActions.SET_ACTIVE_COMPANY_LIST, { activeCompanyList }));
    };
}

function getActiveCompanyList() {
    return (__, getState) => {
        const { company } = getState();
        return company?.activeCompanyIds;
    };
}

function deleteActiveCompanyList() {
    simpleAction(companyActions.DELETE_ACTIVE_COMPANY_LIST);
}

function reloadCompanyList(location) {
    return async dispatch => {
        try {
            const company = await companyService.getCompany();
            dispatch(simpleAction(companyActions.SET_COMPANY_LIST, { companyList: company.companyList }));
        } catch (error) {
            dispatch(errorActions.setError(error, location));
        }
    };
}

function setSettings(settings) {
    return simpleAction(companyActions.SET_SETTINGS, { settings });
}

function setNumberFormatSettings(numberFormatSettings) {
    return simpleAction(companyActions.SET_NUMBER_FORMAT_SETTINGS, { numberFormatSettings });
}

//currency
function setCurrencyVisibility(isVisibleCurrencySwitch) {
    return (dispatch, getState) => {
        const { company } = getState();
        const visibilityState = isBoolean(isVisibleCurrencySwitch) ? isVisibleCurrencySwitch : !company.isVisibleCurrencySwitch;
        dispatch(simpleAction(companyActions.SET_CURRENCY_VISIBILITY, { isVisibleCurrencySwitch: visibilityState }));
    };
}

function setIsSelectedCurrencySwitch(isSelectedCurrencySwitch) {
    return (dispatch, getState) => {
        const { company } = getState();
        const isSelectedCurrencySwitcState = isBoolean(isSelectedCurrencySwitch) ? isSelectedCurrencySwitch : !company.isSelectedCurrencySwitch;
        dispatch(simpleAction(companyActions.SET_IS_SELECTED_CURRENCRY_SWITCH, { isSelectedCurrencySwitch: isSelectedCurrencySwitcState }));
    };
}

function setCurrentCurrency(currentCurrency) {
    return simpleAction(companyActions.SET_CURRENT_CURRENCRY, { currentCurrency });
}

function setPrimaryCurrency(primaryCurrency) {
    return simpleAction(companyActions.SET_PRIMARY_CURRENCY, { primaryCurrency });
}

function setSecondaryCurrency(secondaryCurrency) {
    return simpleAction(companyActions.SET_SECONDARY_CURRENCY, { secondaryCurrency });
}

function getIsVisibleAndSelectedCurrencySwitch() {
    return (__, getState) => {
        const { company } = getState();
        const isSelectedCurrencySwitch = company.company.currency.isSelectedCurrencySwitch;
        const isVisibleCurrencySwitch = company.company.currency.isVisibleCurrencySwitch;

        return (isSelectedCurrencySwitch && isVisibleCurrencySwitch);
    };
}

function getIsVisibleCurrencySwitch() {
    return (__, getState) => {
        const { company } = getState();
        const isVisibleCurrencySwitch = company.company.currency.isVisibleCurrencySwitch;

        return isVisibleCurrencySwitch;
    };
}

function getIsSelectedSecondaryCurrency() {
    return (__, getState) => {
        const { company } = getState();
        const isSelectedCurrencySwitch = company.company.currency.isSelectedCurrencySwitch;

        return isSelectedCurrencySwitch;
    };
}

function getSettings(settingsObject) {
    return (__, getState) => {
        const { company } = getState();
        const settings = company.company.settings;

        const settingResult = {};

        if (!isEmpty(settingsObject)) {
            const rightsObjectEntries = Object.entries(settingsObject);
            if (isEmpty(rightsObjectEntries)) {
                return settingResult;
            }

            rightsObjectEntries.forEach(([key, value]) => {
                if (!isUndefined(value)) {
                    settingResult[key] = companySettingsModule.getSettingValue(value, settings);
                }
            });
        }

        return settingResult;
    };
}

function getSetting(setting) {
    return (__, getState) => {
        const { company } = getState();
        const settings = company.company.settings;

        if (settings) {
            return companySettingsModule.getSettingValue(setting, settings);
        }

        return null;
    };
}

function getUsingNdvi() {
    return (__, getState) => {
        return false;
    };
}

function getMaxNdviCount() {
    return (__, getState) => {
        return 0;
    };
}

function getUsingWeather() {
    return (__, getState) => {
        return false;
    };
}

function getUsingSevenDaysWeather() {
    return (__, getState) => {
        return false;
    };
}

function hasEverySettings(settingList) {
    return (__, getState) => {
        const { company } = getState();
        const companySettings = company.company.settings;

        if (companySettings && settingList) {
            return hasEverySystemSettings(settingList, companySettings);
        }

        return null;
    };
}
