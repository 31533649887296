export const CROP = {
    TYPE: {
        MAIN_CROP: 1,
        SECOND_CROP: 2,
        PLANTATION: 3,
    },
    CROP_TYPE_BY_KEY: {
        1: 'Main crop',
        2: 'Second crop',
    },
};
