import { isFunction } from 'lodash';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import { useDispatch } from 'react-redux';
import Button from '@baseComponents/buttons/Button';
import { alertActions } from '@states/actions';

const useStyles = createUseStyles((theme: any) => ({
    importFileContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.color.white,
        padding: '20px 30px',
        overflow: 'auto',
    },
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        border: `3px dashed ${theme.color.pearl}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    description: {
        maxWidth: 300,
        textAlign: 'center',
        marginBottom: 15,
        fontSize: 14,
    },
    input: {
        opacity: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
}));

export default function ImportFile(props) {
    const { inputAccept, onChange, onClick } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();

    function onFileChange(event) {
        const file = event?.target?.files?.[0];
        if (!inputAccept.includes(file?.type)) {
            dispatch(alertActions.errorAlert(t('importFile.fileNotAccepted', 'File type is not accepted')));
        }
        if (file && inputAccept.includes(file?.type)) {
            onChange(event);
        }
    }

    function onClickFile(event) {
        if (isFunction(onClick)) {
            onClick(event);
        }
    }

    return (
        <div className={classes.importFileContainer}>
            <div className={classes.container}>
                <input
                    accept={inputAccept}
                    type="file"
                    className={classes.input}
                    onClick={onClickFile}
                    onChange={onFileChange}
                />
                <p className={classes.description}>
                    {t('importFile.dragFileOrPressButton', 'Drag here the file u want to upload, or use the browse button')}
                </p>
                <Button>
                    {t('importFile.browse', 'Browse')}
                </Button>
            </div>
        </div>
    );
}
