/* eslint-disable id-blacklist */
import HttpStatus from 'http-status-codes';
import { isEmpty, isString } from 'lodash';
// eslint-disable-next-line import/no-cycle
import { sessionService } from './sessionService';

export const SERVER_ERROR_SERVICE_UNAVAILABLE = 2;

export async function sendServerRequest(method: string, path: string, body?: any) {
    let response: any = null;
    try {
        const bearer = sessionService.getAuthToken();
        const gisBearer = sessionService.getGisToken();

        const options: any = {
            method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearer}`,
                lng: sessionService.getLangCode(),
            },
            timeout: 10000,
            body: JSON.stringify(body),
        };

        if (gisBearer) {
            options.headers['X-Auth-Gis'] = gisBearer;
        }

        response = await fetch(path, options);

        if (response.status === 401) {
            sessionService.removeAuthToken();
        }
        if (!response.ok || response.status !== 200) {
            throw handleServiceError(response, path);
        }

        try {
            return await response.json();
        } catch {
            return null;
        }
    } catch (error) {
        const responseStatus = response ? response?.status : HttpStatus.GATEWAY_TIMEOUT;
        throw handleServiceError(error, responseStatus);
    }
}

export async function sendAPIRequest(method, path, body) {
    let response: any = null;
    try {
        const options = {
            method,
            timeout: 10000,
            body: JSON.stringify(body),
        };

        response = await fetch(path, options);
        if (!response.ok || response.status !== 200) {
            throw handleServiceError(response, path);
        }

        return await response.json();
    } catch (error) {
        const responseStatus = response ? response.status : HttpStatus.GATEWAY_TIMEOUT;
        throw handleServiceError(error, responseStatus);
    }
}

//----------------------------------------
// PHP functions
export async function postPhpDefaultInfo(url, isJson = false) {
    let response: any = null;
    try {
        const options: any = {
            method: 'POST',
            credentials: 'include',
        };

        response = await fetch(url, options);
        if (!response.ok || response.status !== 200) {
            throw handleServiceError(response, url);
        }

        if (isJson) {
            return await getResponseText(response);
        }

        return await response.text();
    } catch (error) {
        const responseStatus = response ? response.status : HttpStatus.GATEWAY_TIMEOUT;
        throw handleServiceError(error, responseStatus);
    }
}

export async function postPhpContent(url, data?: any, isJson = false) {
    let response: any = null;
    try {
        const options: any = {
            method: 'POST',
            body: data ? JSON.stringify(data) : '',
            credentials: 'include',
        };

        response = await fetch(url, options);
        if (!response.ok || response.status !== 200) {
            throw handleServiceError(response, url);
        }

        if (isJson) {
            return await getResponseText(response);
        }

        return await response.text();
    } catch (error) {
        const responseStatus = response ? response.status : HttpStatus.GATEWAY_TIMEOUT;
        throw handleServiceError(error, responseStatus);
    }
}

export async function getResponseText(response) {
    const responseText = await response.text();
    if (isEmpty(responseText)) {
        return null;
    }

    return JSON.parse(responseText);
}

function handleServiceError(error, statusCode) {
    return {
        message: error?.message || (isString(error) ? error : 'No response from the node server.'),
        url: error?.url,
        statusCode,
        isServerError: true,
    };
}
