import { SimpleSelect } from '@baseComponents/select';
import BasicTag from '@baseComponents/tags/BasicTag';
import { ThemeType } from '@map/services/mapTypes';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { MapFilterSchema } from './MapFilterSchema';

type StlyeType = {
    buttonHovered: boolean
}

type Classes = 'container' | 'containerTitle' | 'datePeriodFilterContainer' | 'title' | 'titleContainer' | 'dateRangefilter' | 'dateRangeSelectContainer';

const useStyles = createUseStyles<Classes, StlyeType, ThemeType>(theme => ({
    container: {
        width: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    containerTitle: {

    },
    datePeriodFilterContainer: {
        width: 115,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // height: props => !props.isTagView && 170,
        overflow: 'visible',
        height: '100%',
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .title': {
            maxWidth: 140,
            fontSize: 14,
            fontWeight: 500,
            color: theme.color.raven,
            letterSpacing: 0,
            lineHeight: '20px',
        },
    },
    title: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
    },
    dateRangefilter: {
        '& .btn-primary.dropdown-toggle': {
            height: 30,
            maxHeight: 30,
            // lineHeight: '20px',
            border: `1px solid ${theme.color.filter}`,
            backgroundColor: 'rgba(154,103,0,0.2)',
            '&:focus, &:active': {
                border: `1px solid ${theme.color.filter}`,
                backgroundColor: 'rgba(154,103,0,0.2)',
            },
            '& span': {
                fontSize: 12,
                fontWeight: 500,
                color: `${theme.color.filter}`,
            },
        },

        '& svg': {
            fill: theme.color.filter,
        },
    },
    dateRangeSelectContainer: {
        paddingTop: 15,
        '& .select': {
            width: 199,
        },
    },
}));

type TProps = {
    filterSubmit: (formData: MapFilterSchema) => void
}

export default function MapFilterProdYearSelected({ filterSubmit }: TProps) {
    const filterContext = useFormContext<MapFilterSchema>();

    const periodYears = useSelector((state: any) => state.dataLists.productionYears);

    const [dataList, setDataList] = useState(periodYears?.map(period => ({ key: period.id, value: period.period })));

    const classes = useStyles();

    useEffect(() => {
        if (isEmpty(periodYears)) {
            return;
        }

        const years = periodYears?.map(year => ({ key: year.id, value: year.period }));

        if (years) {
            setDataList(years);
        }
    }, [periodYears]);

    const updateInterface = useCallback(yearId => {
        if (!yearId || Number(yearId) === 0) {
            return;
        }

        filterContext.setValue('productionYearId', Number(yearId));
        filterContext.handleSubmit(filterSubmit)();
    }, [filterContext, filterSubmit]);

    return (
        <div className={classes.datePeriodFilterContainer}>
            <Controller
                control={filterContext.control}
                name="productionYearId"
                render={field => (
                    <SimpleSelect
                        iconId="icon-calendar"
                        alignRight
                        className={classes.dateRangefilter}
                        selected={field.field.value}
                        dataList={dataList}
                        onChange={updateInterface}
                    />
                )}
            />
        </div>
    );
}
