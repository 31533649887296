import Button from '@baseComponents/buttons/Button';
import { SimpleSelect } from '@baseComponents/select';
import { ThemeType, TranslateType } from '@map/services/mapTypes';
import clsx from 'clsx';
import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import { values } from 'lodash';
import { TFunction } from 'i18next';
import { LayerModalBottomBar, LayerModalContainer, LayerModalContent, LayerModalHeader } from './LayerModal.styles';
import { LayerUploadSteps } from './modalSteps.enum';
import { FileProps, ModalStepProps } from './types';

type Classes = 'container' | 'gridContainer' | 'tableRow' | 'tableCell' | 'withLeftBorderRadius' | 'withRightBorderRadius'
| 'buttonRowContainer' | 'gridTitle' | 'doneIcon' | 'errorIcon' | 'fileName' |'warningText' | 'browseText';

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    container: {
        height: 394,
        width: '100%',
        marginBottom: 20,
    },
    gridContainer: {
        display: 'flex',
        width: '100%',
    },
    tableRow: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        outline: '1px solid red',
        backgroundColor: (props: StyleProps) => (props?.isError ? 'rgba(255, 0, 85, 0.1)' : 'transparent'),
        border: (props: StyleProps) => (props?.isError ? '1px solid rgba(255, 0, 85, 0.2)' : 'none'),
    },
    tableCell: {
        display: 'table-cell',
        padding: [5, 8, 5, 8],
        verticalAlign: 'middle',
        outline: '1px solid red',
    },
    withLeftBorderRadius: {
        borderRadius: '4px 0 0 4px',
    },
    withRightBorderRadius: {
        borderRadius: '0 4px 4px 0',
    },
    buttonRowContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    gridTitle: {
        color: theme.color.stone,
        fontWeight: 500,
        fontSize: 12,
    },
    doneIcon: {
        fill: (props: StyleProps) => (props?.isInactive ? theme.color.gray : theme.color.main),
    },
    errorIcon: {
        fill: theme.color.destructive,
    },
    fileName: {
        fontWeight: 500,
        fontSize: 16,
        flexGrow: 1,
    },
    warningText: {
        fontSize: 12,
        fontWeight: 400,
    },
    browseText: {
        fontWeight: 500,
        fontSize: 14,
        color: theme.color.main,
        textDecorationLine: 'underline',
    },
}));

type TProps = {
    file: FileProps
}

type StyleProps = {
    isError?: boolean,
    isInactive?: boolean,
}

function getMockType(index: number, translater:TFunction): string {
    switch (index) {
        case 1:
            return translater('map.yieldMap', 'Yield map');
        case 2:
            return translater('map.nitrogen', 'Nitrogen');
        case 3:
            return translater('map.dropFootage', 'Drop footage');
        default:
            return 'undefined mocktype';
    }
}

function FileRow({ file }: TProps) {
    const classes = useStyles({ isError: file.isError, isInactive: file.isInactive });
    const { t, translater }: TranslateType = useTranslate();

    const [selectedYear, setSelectedYear] = useState<number>(file.year);
    const [selectedPeriod, setSelectedPeriod] = useState<number>(file.period);
    return (
        <div className={classes.tableRow}>
            <div className={clsx(classes.fileName, classes.tableCell)}>
                {file.fileName}
                <div>
                    <span className={classes.warningText}>{getMockType(file.type, translater)}</span>
                </div>
            </div>
            <div className={classes.tableCell}>
                <SimpleSelect
                    dataList={[{ value: '2021/2022', key: 1 }]}
                    selected={selectedYear}
                    onChange={setSelectedYear}
                />
            </div>
            <div className={classes.tableCell}>
                <SimpleSelect
                    dataList={[{ value: 'K2', key: 1 }, { value: 'K4', key: 2 }, { value: 'E1', key: 3 }]}
                    selected={selectedPeriod}
                    onChange={setSelectedPeriod}
                />
            </div>
        </div>
    );
}

export default function LayerUploadModalAttach({ setStep, close }: ModalStepProps) {
    const classes = useStyles();
    const { t, translater }: TranslateType = useTranslate();

    const columnDefinitions = [
        { id: 1, title: t('map.layerEditTypeLayer', 'Layer'), size: 400 },
        { id: 2, title: t('map.productionYear', 'Production Year'), size: 160 },
        { id: 3, title: t('map.layerCultivationPeriod', 'Cultivation period'), size: 240 },
    ];

    const items = [
        { id: 1, fileName: 'K2_mutragya_03_15_2022.kml', type: 1, year: 1, period: 1 },
        { id: 2, fileName: 'K2_dron_03_15_2022.tif', type: 1, year: 1, period: -1 },
        { id: 3, fileName: 'K4_mutragya_03_16_2022.shp+dbf', type: 3, year: 1, period: 2 },
        { id: 4, fileName: 'E1_mutragya_03_15_2022.jpg', type: 2, year: 1, period: 3 },
    ];

    return (
        <LayerModalContainer>
            <LayerModalHeader
                title={t('map.attachLayer', 'Attach layer').toString()}
                close={close}
            />
            <LayerModalContent>
                <div className={classes.container}>
                    <div className={classes.gridContainer}>
                        {columnDefinitions.map(col => (
                            <div
                                className={clsx(classes.gridTitle, classes.tableCell)}
                                style={{ width: col.size }}
                                key={col.id}
                            >
                                {col.title}
                            </div>))}
                        {items.map(i => (
                            <FileRow file={i} key={i.id} />
                        ))}
                    </div>
                </div>
            </LayerModalContent>
            <LayerModalBottomBar>
                <Button
                    title={t('map.backText', 'Back')}
                    type="secondary"
                    iconId="icon-arrow_back"
                    iconPosition="before"
                    onClick={() => setStep(LayerUploadSteps.PAIR)}
                >
                    {t('map.backText', 'Back')}
                </Button>
                <Button
                    title={t('default.save', 'Save')}
                    iconId="icon-done"
                    iconPosition="before"
                    onClick={() => close()}
                >
                    {t('default.save', 'Save')}
                </Button>
            </LayerModalBottomBar>
        </LayerModalContainer>
    );
}
