import { useState } from 'react';
import useTranslate from '@i18n/useTranslate';
import { createUseStyles } from 'react-jss';
import { PARTNER } from 'shared/src/constants';
import Radio from '@baseComponents/controls/Radio';

const useStyles = createUseStyles((theme: any) => ({
    radiolistContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 180,
        width: 1199,
        overflow: 'hidden',
        marginTop: 10,
    },
    titleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .title': {
            maxWidth: 140,
            marginBottom: 7,
            fontSize: 11,
            fontWeight: 500,
            color: theme.color.jet,
            letterSpacing: 0,
            lineHeight: '20px',
            textTransform: 'uppercase',
        },
        '& .all': {
            color: theme.color.jet,
            fontSize: 11,
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: '20px',
            cursor: 'pointer',
            outline: 'none',
            '&:first-letter': {
                textTransform: 'capitalize',
            },
        },
    },
    radioboxContainer: {
        marginBottom: 5,
        marginRight: 20,
    },
    formContainer: {
        display: 'inline-flex',
    },
    radioboxLabel: {
        color: theme.color.jet,
        fontSize: '12px',
        letterSpacing: 0,
        lineHeight: '20px',
    },
    opacity: {
        top: '-5px',
        height: 20,
        width: '100%',
        background: theme.color.white,
        opacity: 0.5,
        position: 'relative',
    },
}));

export default function ActiveInactiveRadio(props) {
    const { title, defaultValue = PARTNER.ACTIVE_STATUS.ACTIVE, onChange } = props;
    const { t } = useTranslate();

    const classes = useStyles();

    const [isActive, setValue] = useState(defaultValue);

    function changeStatus(event) {
        const value = +event.target.value;
        setValue(value);
        if (onChange) {
            onChange(value);
        }
    }

    return (
        <div className={classes.radiolistContainer}>
            <div className={classes.titleContainer}>
                <div className="title">{title}</div>
            </div>
            <div className={classes.formContainer}>
                <Radio
                    text={t('productTableFilter.activeState', 'Active')}
                    size="small"
                    value={PARTNER.ACTIVE_STATUS.ACTIVE}
                    textStyle={classes.radioboxLabel}
                    className={classes.radioboxContainer}
                    checked={PARTNER.ACTIVE_STATUS.ACTIVE === isActive}
                    onChange={changeStatus}
                    group="activeinactive"
                />
                <Radio
                    text={t('productTableFilter.inactiveState', 'Inactive')}
                    size="small"
                    value={PARTNER.ACTIVE_STATUS.INACTIVE}
                    textStyle={classes.radioboxLabel}
                    className={classes.radioboxContainer}
                    checked={PARTNER.ACTIVE_STATUS.INACTIVE === isActive}
                    onChange={changeStatus}
                    group="activeinactive"
                />
            </div>
        </div>
    );
}
