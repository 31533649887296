import { isBoolean } from 'lodash';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { simpleAction } from './baseActions';

const basePageActions = {
    addFilter,
    setFilters,
    setCurrentFilters,
    setCurrentFilter,
    setIsResetFilters,
    setIsOpenedRightSide,
    setVisibleHeaderFunctionLine,
    setVisibleHeaderFunctionLineTable,
    setVisibleFixedColumnLine,
    setGroups,
    setGroupingBy,
    setIsAllTableCollapsed,
    setGroupOperation,
    setIsAllTableGroupSelected,
    removeIsAllTableCollapsed,
    setShowedColumnType,
    setSelectedCustomColumns,
    setShowMapView,
    ADD_FILTER: 'BASE_PAGE_ADD_FILTER',
    SET_FILTERS: 'BASE_PAGE_SET_FILTERS',
    SET_CURRENT_FILTERS: 'BASE_PAGE_SET_CURRENT_FILTERS',
    SET_CURRENT_FILTER: 'BASE_PAGE_SET_CURRENT_FILTER',
    SET_IS_RESET_FILTERS: 'BASE_PAGE_SET_IS_RESET_FILTERS',
    SET_OPENED_RIGHT_SIDE: 'BASE_PAGE_SET_OPENED_RIGHT_SIDE',
    SET_VISIBLE_HEADER_FUNCTION_LINE: 'BASE_PAGE_SET_VISIBLE_HEADER_FUNCTION_LINE',
    SET_VISIBLE_FIXED_COLUMN_LINE: 'BASE_PAGE_SET_VISIBLE_FIXED_COLUMN_LINE',
    SET_GROUPS: 'BASE_PAGE_SET_GROUPS',
    SET_GROUPING_BY: 'BASE_PAGE_SET_GROUPING_BY',
    SET_ALL_TABLE_COLLAPSED: 'BASE_PAGE_SET_ALL_TABLE_COLLAPSED',
    SET_GROUP_OPERATION: 'BASE_PAGE_SET_GROUP_OPERATION',
    SET_ALL_TABLE_GROUP_SELECTED: 'BASE_PAGE_SET_ALL_TABLE_GROUP_SELECTED',
    SET_SHOWED_COLUMN_TYPE: 'BASE_PAGE_SET_SHOWED_COLUMN_TYPE',
    SET_SELECTED_CUSTOM_COLUMNS: 'BASE_PAGE_SET_SELECTED_CUSTOM_COLUMNS',
    REMOVE_ALL_TABLE_COLLAPSED: 'BASE_PAGE_REMOVE_ALL_TABLE_COLLAPSED',
    SET_SHOW_MAP_VIEW: 'BASE_PAGE_SET_SHOW_MAP_VIEW',
    //basepage table
    setIsRenderedTable,
    setHeaderColumnWidths,
    addSelectedIds,
    removeAllSelectedId,
    setSelectedIds,
    removeSelectedIds,
    setTableMultilineView,
    setTableAggregateType,
    setTableColumns,
    setTableCustomColumns,
    resetTableCustomColumns,
    setTableData,
    setTableFixedHeaderRef,
    setTableFixedFooterRef,
    setTableEnabledSearching,
    setTableGlobalSearchValue,
    setTableGlobalColumnSearchValues,
    addTableGlobalColumnSearchValue,
    removeTableGlobalColumnSearchValue,
    addTableGlobalColumnSortingType,
    removeTableGlobalColumnSortingType,
    setTableFilteredDatas,
    removeTableFilteredDatas,
    setTableOtherDataList,
    setReloadTable,
    setTableFilteredData,
    //modal
    setModalVisible,
    resetModals,
    resetBasePageData,
    getTabledDatas,
    SET_TABLE_FILTERED_DATA: 'BASE_PAGE_SET_TABLE_FILTERED_DATA',
    SET_IS_RENDERED_TABLE: 'BASE_PAGE_SET_IS_RENDERED_TABLE',
    SET_HEADER_COLUMN_WIDTHS: 'BASE_PAGE_SET_HEADER_COLUMN_WIDTHS',
    ADD_SELECTED_IDS: 'BASE_PAGE_ADD_SELECTED_IDS',
    REMOVE_SELECTED_IDS: 'BASE_PAGE_REMOVE_SELECTED_IDS',
    SET_SELECTED_IDS: 'BASE_PAGE_SET_SELECTED_IDS',
    REMOVE_ALL_SELECTED_ID: 'BASE_PAGE_REMOVE_ALL_SELECTED_ID',
    SET_TABLE_MULTILINE_VIEW: 'BASE_PAGE_SET_TABLE_MULTILINE_VIEW',
    SET_TABLE_AGGREGATE_TYPE: 'BASE_PAGE_SET_TABLE_AGGREGATE_TYPE',
    SET_TABLE_COLUMNS: 'BASE_PAGE_SET_TABLE_COLUMNS',
    SET_TABLE_CUSTOM_COLUMNS: 'BASE_PAGE_SET_TABLE_CUSTOM_COLUMNS',
    RESET_TABLE_CUSTOM_COLUMNS: 'BASE_PAGE_RESET_TABLE_CUSTOM_COLUMNS',
    SET_TABLE_DATA: 'BASE_PAGE_SET_TABLE_DATA',
    SET_TABLE_FIXED_HEADER_REF: 'BASE_PAGE_SET_TABLE_FIXED_HEADER_REF',
    SET_TABLE_FIXED_FOOTER_REF: 'BASE_PAGE_SET_TABLE_FIXED_FOOTER_REF',
    SET_TABLE_ENABLED_SEARCHING: 'BASE_PAGE_SET_TABLE_ENABLED_SEARCHING',
    SET_TABLE_GLOBAL_SEARCH_VALUE: 'BASE_PAGE_SET_TABLE_GLOBAL_SEARCH_VALUE',
    SET_TABLE_GLOBAL_COLUMN_SEARCH_VALUES: 'BASE_PAGE_SET_TABLE_GLOBAL_COLUMN_SEARCH_VALUES',
    ADD_TABLE_GLOBAL_COLUMN_SEARCH_VALUE: 'BASE_PAGE_ADD_TABLE_GLOBAL_COLUMN_SEARCH_VALUE',
    REMOVE_TABLE_GLOBAL_COLUMN_SEARCH_VALUE: 'BASE_PAGE_REMOVE_TABLE_GLOBAL_COLUMN_SEARCH_VALUE',
    ADD_TABLE_GLOBAL_COLUMN_SORTING_TYPE: 'BASE_PAGE_ADD_TABLE_GLOBAL_COLUMN_SORTING_TYPE',
    REMOVE_TABLE_GLOBAL_COLUMN_SORTING_TYPE: 'BASE_PAGE_REMOVE_TABLE_GLOBAL_COLUMN_SORTING_TYPE',
    SET_TABLE_FILTERED_DATAS: 'BASE_PAGE_SET_TABLE_FILTERED_DATAS',
    REMOVE_TABLE_FILTERED_DATAS: 'BASE_PAGE_REMOVE_TABLE_FILTERED_DATAS',
    SET_TABLE_OTHER_DATA_LIST: 'BASE_PAGE_SET_TABLE_OTHER_DATA_LIST',
    SET_RELOAD_TABLE: 'BASE_PAGE_SET_RELOAD_TABLE',
    //modal
    SET_MODAL_VISIBILITY: 'BASE_PAGE_SET_MODAL_VISIBILITY',
    RESET_MODAL_VISIBILITY: 'BASE_PAGE_RESET_MODAL_VISIBILITY',
    RESET_BASE_PAGE_DATA: 'BASE_PAGE_RESET_BASE_PAGE_DATA',
};

export { basePageActions };

function addFilter(filterName, filterValue) {
    return simpleAction(basePageActions.ADD_FILTER, { filterName, filterValue });
}

function setFilters(filters) {
    return simpleAction(basePageActions.SET_FILTERS, { filters });
}

function setCurrentFilters(currentFilters) {
    return simpleAction(basePageActions.SET_CURRENT_FILTERS, { currentFilters });
}

function setCurrentFilter(currentFilterName, currentFilterValue) {
    return simpleAction(basePageActions.SET_CURRENT_FILTER, { currentFilterName, currentFilterValue });
}

function setIsResetFilters(isResetedFilters) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const resetedState = isBoolean(isResetedFilters) ? isResetedFilters : !basePage.isResetedFilters;
        dispatch(simpleAction(basePageActions.SET_IS_RESET_FILTERS, { isResetedFilters: resetedState }));
    };
}

function setIsOpenedRightSide(isOpenedRightSide) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const isOpenedState = isBoolean(isOpenedRightSide) ? isOpenedRightSide : !basePage.isOpenedRightSide;
        dispatch(simpleAction(basePageActions.SET_OPENED_RIGHT_SIDE, { isOpenedRightSide: isOpenedState }));
    };
}

function setVisibleHeaderFunctionLine(isVisibleHeaderFunctionLine) {
    return simpleAction(basePageActions.SET_VISIBLE_HEADER_FUNCTION_LINE, { isVisibleHeaderFunctionLine });
}

function setVisibleHeaderFunctionLineTable(isVisibleHeaderFunctionLine, direction) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const prevIsVisibleHeaderFunctionLine = basePage.isVisibleHeaderFunctionLine;
        if ((direction === 'backward' && !prevIsVisibleHeaderFunctionLine) || (direction === 'forward' && prevIsVisibleHeaderFunctionLine)) {
            dispatch(simpleAction(basePageActions.SET_VISIBLE_HEADER_FUNCTION_LINE, { isVisibleHeaderFunctionLine }));
        }
    };
}

function setVisibleFixedColumnLine(isVisibleFixedColumnLine) {
    return simpleAction(basePageActions.SET_VISIBLE_FIXED_COLUMN_LINE, { isVisibleFixedColumnLine });
}

function setGroups(groups) {
    return simpleAction(basePageActions.SET_GROUPS, { groups });
}

function setGroupingBy(groupingBy) {
    return simpleAction(basePageActions.SET_GROUPING_BY, { groupingBy });
}

function setShowedColumnType(columnType) {
    return simpleAction(basePageActions.SET_SHOWED_COLUMN_TYPE, { columnType });
}

function setGroupOperation(isEnabled) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const groupOperationState = isBoolean(isEnabled) ? isEnabled : !basePage.isEnabledGroupOperation;
        dispatch(simpleAction(basePageActions.SET_GROUP_OPERATION, { isEnabled: groupOperationState }));
    };
}

function setSelectedCustomColumns(selectedCustomColumns) {
    return simpleAction(basePageActions.SET_SELECTED_CUSTOM_COLUMNS, { selectedCustomColumns });
}

function setIsAllTableCollapsed(isAllTableCollapsed) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const collapsingState = isBoolean(isAllTableCollapsed) ? isAllTableCollapsed : !basePage.isAllTableCollapsed;
        dispatch(simpleAction(basePageActions.SET_ALL_TABLE_COLLAPSED, { isAllTableCollapsed: collapsingState }));
    };
}

function setIsAllTableGroupSelected(isAllTableGroupSelected) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const selectionState = isBoolean(isAllTableGroupSelected) ? isAllTableGroupSelected : !basePage.isAllTableGroupSelected;
        const selectedItemIds = selectionState ? arrayUtils.getAllValuesFromObject(basePage.table.filteredDatas, 'id') : [];
        dispatch(simpleAction(basePageActions.SET_ALL_TABLE_GROUP_SELECTED, { isAllTableGroupSelected: selectionState, selectedItemIds }));
    };
}

function removeIsAllTableCollapsed() {
    return simpleAction(basePageActions.REMOVE_ALL_TABLE_COLLAPSED);
}

function setShowMapView(showMapView) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const showState = isBoolean(showMapView) ? showMapView : !basePage.table.showMapView;
        dispatch(simpleAction(basePageActions.SET_SHOW_MAP_VIEW, { showMapView: showState }));
    };
}

function resetTableCustomColumns() {
    return simpleAction(basePageActions.RESET_TABLE_CUSTOM_COLUMNS);
}

//base page table
function setIsRenderedTable(isRenderedTable) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const showState = isBoolean(isRenderedTable) ? isRenderedTable : !basePage.table.isRenderedTable;
        dispatch(simpleAction(basePageActions.SET_IS_RENDERED_TABLE, { isRenderedTable: showState }));
    };
}

function setHeaderColumnWidths(headerColumnWidths) {
    return simpleAction(basePageActions.SET_HEADER_COLUMN_WIDTHS, { headerColumnWidths });
}

function addSelectedIds(selectedIds) {
    return simpleAction(basePageActions.ADD_SELECTED_IDS, { selectedIds });
}

function removeSelectedIds(selectedIds) {
    return simpleAction(basePageActions.REMOVE_SELECTED_IDS, { selectedIds });
}

function setSelectedIds(selectedIds) {
    return simpleAction(basePageActions.SET_SELECTED_IDS, { selectedIds });
}

function removeAllSelectedId() {
    return simpleAction(basePageActions.REMOVE_ALL_SELECTED_ID);
}

function setTableMultilineView(multilineViewType) {
    return simpleAction(basePageActions.SET_TABLE_MULTILINE_VIEW, { multilineViewType });
}

function setTableColumns(columns) {
    return simpleAction(basePageActions.SET_TABLE_COLUMNS, { columns });
}

function setTableCustomColumns(columns) {
    return simpleAction(basePageActions.SET_TABLE_CUSTOM_COLUMNS, { columns });
}

function setTableData(tableData) {
    return simpleAction(basePageActions.SET_TABLE_DATA, { tableData });
}

function setTableAggregateType(aggregateType) {
    return simpleAction(basePageActions.SET_TABLE_AGGREGATE_TYPE, { aggregateType });
}

function setTableFixedHeaderRef(fixedHeaderRef) {
    return simpleAction(basePageActions.SET_TABLE_FIXED_HEADER_REF, { fixedHeaderRef });
}

function setTableFixedFooterRef(fixedFooterRef) {
    return simpleAction(basePageActions.SET_TABLE_FIXED_FOOTER_REF, { fixedFooterRef });
}

function setTableEnabledSearching(isEnabledSearching) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const selectionState = isBoolean(isEnabledSearching) ? isEnabledSearching : !basePage.table.isEnabledSearching;
        dispatch(simpleAction(basePageActions.SET_TABLE_ENABLED_SEARCHING, { isEnabledSearching: selectionState }));
    };
}

function setTableGlobalSearchValue(globalSearchValue) {
    return simpleAction(basePageActions.SET_TABLE_GLOBAL_SEARCH_VALUE, { globalSearchValue });
}

function setTableGlobalColumnSearchValues(globalColumnSearchValues) {
    return simpleAction(basePageActions.SET_TABLE_GLOBAL_COLUMN_SEARCH_VALUES, { globalColumnSearchValues });
}

function addTableGlobalColumnSearchValue(columnId, searchValue) {
    return simpleAction(basePageActions.ADD_TABLE_GLOBAL_COLUMN_SEARCH_VALUE, { columnId, searchValue });
}

function removeTableGlobalColumnSearchValue(columnId) {
    return simpleAction(basePageActions.REMOVE_TABLE_GLOBAL_COLUMN_SEARCH_VALUE, { columnId });
}

function addTableGlobalColumnSortingType(columnId, sortingType) {
    return simpleAction(basePageActions.ADD_TABLE_GLOBAL_COLUMN_SORTING_TYPE, { columnId, sortingType });
}

function removeTableGlobalColumnSortingType(columnId) {
    return simpleAction(basePageActions.REMOVE_TABLE_GLOBAL_COLUMN_SORTING_TYPE, { columnId });
}

function setTableFilteredData(groupedData) {
    return simpleAction(basePageActions.SET_TABLE_FILTERED_DATA, { groupedData });
}

function setTableFilteredDatas(filteredDatas, groupName) {
    return simpleAction(basePageActions.SET_TABLE_FILTERED_DATAS, { filteredDatas, groupName });
}

function removeTableFilteredDatas() {
    return simpleAction(basePageActions.REMOVE_TABLE_FILTERED_DATAS);
}

function setTableOtherDataList(otherDataList) {
    return simpleAction(basePageActions.SET_TABLE_OTHER_DATA_LIST, { otherDataList });
}

function setReloadTable(reloadTable?: boolean) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const reloadState = isBoolean(reloadTable) ? reloadTable : !basePage.table.reloadTable;
        dispatch(simpleAction(basePageActions.SET_RELOAD_TABLE, { reloadTable: reloadState }));
    };
}

function getTabledDatas() {
    return (__, getState) => {
        const { basePage } = getState();
        return basePage.table.tableData;
    };
}

//modals
function setModalVisible(modalId, isVisible, values) {
    return (dispatch, getState) => {
        const { basePage } = getState();
        const showState = isBoolean(isVisible) ? isVisible : !basePage.modals[modalId];
        dispatch(simpleAction(basePageActions.SET_MODAL_VISIBILITY, { modalId, isVisible: showState, values }));
    };
}

function resetModals() {
    return simpleAction(basePageActions.RESET_MODAL_VISIBILITY);
}

function resetBasePageData() {
    return simpleAction(basePageActions.RESET_BASE_PAGE_DATA);
}
