import { FeatureCollection } from 'geojson';

async function getLpisFeaturesFromBBox(year: number, bbox: string) {
    const lpis = await fetch(
        `/gis-api/slovak-official-reports/layer/lpis?bbox=${bbox}&year=${year}`,
    );
    const jsonData = await lpis.json();
    return jsonData as FeatureCollection;
}

async function getPublishedPolygonsFromBBox(year: number, bbox: string) {
    const lpis = await fetch(
        `/gis-api/slovak-official-reports/layer/published-polygons?bbox=${bbox}&year=${year}`,
    );
    const jsonData = await lpis.json();
    return jsonData as FeatureCollection;
}

async function getExternalLayerByIds(year: number, bbox: string, layers?: number[]) {
    const layersParam = layers && layers?.map((l) => `layers=${l}`).join('&');
    const res = await fetch(`/gis-api/slovak-official-reports/layer/external?bbox=${bbox}&${layersParam}&year=${year}`);
    return res.json();
}

function getTerritorialLayerUrl(year: number, bbox: string) {
    return `/gis-api/slovak-official-reports/layer/territories?bbox=${bbox}&year=${year}`;
}

function getSlopeLayerUrl(year:number, bbox: string, layers?: number[]) {
    const layersParam = layers && layers?.map((l) => `layers=${l}`).join('&');
    return `/gis-api/slovak-official-reports/layer/slope?bbox=${bbox}&${layersParam}&year=${year}`;
}

export const slovakExternalServices = {
    getTerritorialLayerUrl,
    getSlopeLayerUrl,
    getExternalLayerByIds,
    getLpisFeaturesFromBBox,
    getPublishedPolygonsFromBBox,
};
