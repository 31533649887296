import React from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import useTranslate from '@i18n/useTranslate';
import { isEmpty } from 'lodash';
import { TABLE } from 'shared/src/constants';

import DropDownSorterListItem from './DropDownSorterListItem';
import TextButton from '../../../../buttons/TextButton';

const useStyles = createUseStyles((theme: any) => ({
    sorterContainer: {
        padding: [0, 15],
        display: 'block',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
        lineHeight: '30px',
    },
    sorterTitle: {
        color: theme.color.jet,
        display: 'inline-flex',
        width: 'auto',
        fontSize: 14,
        fontWeight: 400,
        margin: 0,
    },
    sorterListDeleteButton: {
        display: 'inline-block',
        width: 'auto',
        float: 'right',
        height: 30,
    },
    sorterListContainer: {
        display: 'block',
    },
}));

export default function DropDownSorterList(props) {
    const { onClickDeleteAllSorter, isBasePageTable, tableKey } = props;
    const globalColumnSortingTypes = useSelector((state: any) => state.basePage.table.globalColumnSortingTypes);
    const globalColumnSortingTypesSimpleTable = useSelector((state: any) => state.simpleTable.table[tableKey]?.globalColumnSortingTypes);
    const { t } = useTranslate();
    const classes = useStyles();

    function hasSortedColumn(sorterTypes) {
        if (!isEmpty(sorterTypes)) {
            return Object.entries(sorterTypes).find(([, sortingType]) => sortingType !== TABLE.SORTING.NONE);
        }
        return false;
    }

    function getSortingTypeList() {
        if (!isBasePageTable) {
            return Object.entries(globalColumnSortingTypesSimpleTable);
        }

        return Object.entries(globalColumnSortingTypes);
    }

    if ((isEmpty(globalColumnSortingTypes) || !hasSortedColumn(globalColumnSortingTypes)) && isBasePageTable) {
        return null;
    }

    if ((isEmpty(globalColumnSortingTypesSimpleTable) || !hasSortedColumn(globalColumnSortingTypesSimpleTable)) && !isBasePageTable) {
        return null;
    }

    return (
        <div className={classes.sorterContainer}>
            <h4 className={classes.sorterTitle}>{t('tableDropDown.sortBy', 'Sort by')}</h4>
            <TextButton className={classes.sorterListDeleteButton} type="destructive" onClick={onClickDeleteAllSorter}>{t('default.delete', 'Delete')}</TextButton>
            <div className={classes.sorterListContainer}>
                {getSortingTypeList().map(([columnId], index) => (
                    <DropDownSorterListItem
                        key={index}
                        columnId={columnId}
                        isBasePageTable={isBasePageTable}
                        tableKey={tableKey}
                    />
                ))}
            </div>
        </div>
    );
}
