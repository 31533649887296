import { useEffect, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { sessionService } from '@services';
import { phpHelper } from '@utils/php/PhpHelper';

import NotFound from '@screens/errors/NotFound';
import NoPermission from '@screens/errors/NoPermission';
import AppLoadingSpinner from '@baseComponents/loadSpinner/AppLoadingSpinner';
import { ErrorBoundary } from '@baseComponents/errorBoundary';

const useStyles = createUseStyles((theme: any) => ({
    sideMenuMain: {
        display: 'inline',
        position: 'relative',
        transition: 'all .15s',
        padding: 0,
        width: '100%',
        height: '100vh',
        background: theme.color.lilian,
        '& h1,h2,h3,h4': {
            fontFamily: 'Rubik',
        },
        overflow: 'hidden',
    },
}));

export default function MainContent(props) {
    const { routeList } = props;
    const agentName = sessionService.getAgentName();
    const planningMode = sessionService.getPlanningMode();
    const planningModeisActive = useSelector((state: any) => state.system.planningModeisActive);
    const location = useLocation().pathname;
    const classes = useStyles();

    useEffect(() => {
        phpHelper.clearQtipPanels();
    }, [location]);

    return (
        <main className={classes.sideMenuMain}>
            <ErrorBoundary>
                <Suspense fallback={<AppLoadingSpinner />}>
                    <Switch>
                        {(planningModeisActive || !planningMode) && routeList?.map(page => (
                            //<Route key={page.path} path={`/${agentName}/${page.path}`} exact render={() => <page.component />} /> //info to later - if component need props - use this
                            page.hasAccess
                                ? <Route key={`${page.path}Route`} path={`/${agentName}/${page.path}`} exact component={page.component} />
                                : <Route key={`${page.path}Route`} path={`/${agentName}/${page.path}`} component={NoPermission} />
                        ))}
                        {(planningModeisActive || !planningMode) && <Route component={NotFound} />}
                    </Switch>
                </Suspense>
            </ErrorBoundary>
        </main>
    );
}
