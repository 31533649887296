import React, { useEffect, useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import useTranslate from '@i18n/useTranslate';
import Button from '@baseComponents/buttons/Button';
import { ModalBody, ModalHeader, ModalContentWrapper, ModalFooter } from '@baseComponents/modals/modalNew';
import { Nullable } from '@flowsCommon/services/baseTypes';
import FormGroup from '@customComponents/form/FormGroup';
import { yupResolver } from '@hookform/resolvers/yup';
import { cloneDeep, isEmpty, isFunction } from 'lodash';
import { useForm } from 'react-hook-form';
import { FormSelect } from '@baseComponents/select';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles<any>((theme: any) => ({
    modalWrapper: {
        width: 400,
        // height: 'auto',
        height: '50vh',
    },
    modalBody: {
        display: 'flex',
        overflowY: 'auto',
        width: '100%',
    },
    datePicker: {
        width: '100%',
        display: 'block',
        '& > *': {
            width: '100%',
        },
    },
    formGroup: {
        margin: [16, 0],
    },
    footerButton: {
        width: '100%',
    },
    modalFooter: {
        padding: [0, 30],
    },
    modalSide: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '50vh',
    },
    attributeItem: {
        fontSize: 14,
        fontWeight: 500,
    },
    rowItem: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        margin: [5, 16],
    },
    rowPairingWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'stretch',
        width: '100%',
        flex: 1,
        '& > *': {
            width: '100%',
            flex: 1,
        },
    },
    iconBin: {
        margin: [0, 4],
        fill: theme.color.destructive,
        cursor: 'pointer',
    },
}));

export type AttributePairs = {
    newAttributeName: string;
    pairAttributeName?: Nullable<string>;
    attributeType: string;
    isDeleted: boolean;
};

type TProps = {
    currentLayerAttributes: object;
    existingLayerAttributes: object;
    hideModal?: () => void;
    onSubmit: (isCustomLayerUpload: boolean, layerAttributeWithTypes?: object) => void;
};

type TAttributeItemProps = {
    existingLayerAttributesList: any[],
    existingLayerAttributes: object;
    currentLayerAttributes: object;
    propertyName: string;
    classes: any,
    pairedAttributes: Nullable<AttributePairs[]> | undefined;
    setPairedAttributes: React.Dispatch<React.SetStateAction<AttributePairs[]>>,
}

function LayerAttributeItemBlock(props: TAttributeItemProps) {
    const { propertyName, classes, existingLayerAttributes, currentLayerAttributes, existingLayerAttributesList } = props;
    const { pairedAttributes, setPairedAttributes } = props;
    const [selectedAttribute, setSelectedAttribute] = useState<null | number>();

    const currentSelected = useMemo(() => pairedAttributes?.find(attr => attr.newAttributeName === propertyName), [pairedAttributes, propertyName]);

    const filteredDataList = useMemo(() => {
        const alreadySelectedValues = pairedAttributes?.map(attribute => attribute.pairAttributeName);
        const filteredList = existingLayerAttributesList
            ?.filter(attribute => !alreadySelectedValues?.includes(attribute?.value) || (currentSelected?.pairAttributeName === attribute?.value))
            ?.filter(attribute => (existingLayerAttributes[attribute?.value] === currentSelected?.attributeType) && !currentSelected?.isDeleted);
        return filteredList;
    }, [currentSelected, existingLayerAttributes, existingLayerAttributesList, pairedAttributes]);

    useEffect(() => {
        const existingPropertyKey = existingLayerAttributesList.find(existingAttr => existingAttr?.value === propertyName);

        if (existingPropertyKey) {
            setSelectedAttribute(existingPropertyKey?.key);
            setPairedAttributes(p => [...((p || [])?.filter(attribute => attribute.newAttributeName !== propertyName)), {
                newAttributeName: propertyName,
                attributeType: existingLayerAttributes?.[propertyName],
                pairAttributeName: propertyName,
                isDeleted: false,
            }]);
        }
    }, [propertyName, existingLayerAttributesList, setPairedAttributes, existingLayerAttributes]);

    function handleSelectChange(attributeKey) {
        const existingAttribute = filteredDataList?.find(attribute => +attribute?.key === +attributeKey);
        const existingAttributeName = existingAttribute?.value;

        setPairedAttributes(p => [...((p || [])?.filter(attribute => attribute.newAttributeName !== propertyName)), {
            newAttributeName: propertyName,
            attributeType: existingLayerAttributes?.[existingAttributeName],
            pairAttributeName: existingAttributeName,
            isDeleted: false,
        }]);
    }

    function handleDelete() {
        setPairedAttributes(p => [...(p || [])]?.map(filterAttr => {
            if (filterAttr.newAttributeName !== propertyName) {
                return filterAttr;
            }
            return {
                ...filterAttr,
                pairAttributeName: null,
                isDeleted: !filterAttr.isDeleted,
            };
        }));

        setSelectedAttribute(-1);
    }

    return (
        <div className={classes.rowItem}>
            <div className={classes.rowPairingWrapper}>

                <div className={classes.attributeItem}>
                    {propertyName}
                </div>

                <FormSelect
                    disabled={!isEmpty(selectedAttribute) || currentSelected?.isDeleted}
                    dataList={!currentSelected?.isDeleted ? filteredDataList : null}
                    onChange={value => handleSelectChange(value)}
                    selected={!currentSelected?.isDeleted ? selectedAttribute : null}
                />
            </div>

            <Svg style={classes.iconBin} onClick={handleDelete} iconId="icon-bin" width={32} height={32} />
        </div>
    );
}

export default function LayerUploadCustomLayerAttributesModal(props: TProps) {
    const { existingLayerAttributes, currentLayerAttributes, hideModal, onSubmit } = props;

    const classes = useStyles();
    const { t } = useTranslate();

    const [pairedAttributes, setPairedAttributes] = useState<AttributePairs[]>(Object.keys(currentLayerAttributes)?.map((attributeKey: string) => ({
        newAttributeName: attributeKey,
        attributeType: currentLayerAttributes?.[attributeKey],
        isDeleted: false,
    })));

    const existingLayerAttributesList = useMemo(() => Object.keys(existingLayerAttributes)?.map((property: string, idx) => ({
        value: property,
        key: idx + 1,
    })), [existingLayerAttributes]);

    const currentLayerAttributesList = useMemo(() => Object.keys(currentLayerAttributes)?.map((property: string, idx) => ({
        value: property,
        key: idx + 1,
    })), [currentLayerAttributes]);

    // const [validationSchema] = useState(
    //     yup.object().shape({
    //         featureProperty: yup.string()
    //             .typeError(t('layerUploadKeyPairingModal.specifyFeatureProperties', 'Specifying the feature properties is required'))
    //             .required(t('layerUploadKeyPairingModal.specifyFeatureProperties', 'Specifying the feature properties is required')),
    //         entityProperty: yup.string()
    //             .typeError(t('layerUploadKeyPairingModal.specifyEntityProperties', 'Specifying the entity properties is required'))
    //             .required(t('layerUploadKeyPairingModal.specifyEntityProperties', 'Specifying the entity properties is required')),
    //     }),
    // );

    const [defaultValues] = useState({ featureProperty: null, entityProperty: null });

    const { handleSubmit, watch, getValues, setValue, formState: { errors } } = useForm({
        // resolver: yupResolver(validationSchema),
        defaultValues: {
            ...defaultValues,
        },
        mode: 'onChange',
    });

    function onFormSubmit() {
        if (isFunction(onSubmit)) {
            onSubmit(true, currentLayerAttributes, pairedAttributes);

            if (isFunction(hideModal)) {
                hideModal();
            }
        }
    }

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {t('map.attributeMapping', 'Attribute mapping')}
            </ModalHeader>

            <ModalBody className={classes.modalBody}>
                <div className={classes.modalSide}>
                    {
                        currentLayerAttributesList?.map(layerAttribute => (
                            <LayerAttributeItemBlock
                                classes={classes}
                                propertyName={layerAttribute?.value}
                                currentLayerAttributes={currentLayerAttributes}
                                existingLayerAttributes={existingLayerAttributes}
                                existingLayerAttributesList={existingLayerAttributesList}
                                pairedAttributes={pairedAttributes}
                                setPairedAttributes={setPairedAttributes}
                            />
                        ))
                    }
                </div>

                {/* <div className={classes.modalSide}>
                    {
                        existingLayerAttributesList?.map(layerAttribute => <LayerAttributeItemBlock classes={classes} propertyName={layerAttribute?.value} />)
                    }
                </div> */}
                {/* <FormGroup label={t('layerUploadKeyPairingModal.featurePropertiesLabel', 'Feature property')} required className={classes.formGroup} hasValue={watch('featureProperty')} errorMessage={errors?.featureProperty?.message}>
                    <FormSelect
                        dataList={featurePropertySelectList}
                        onChange={value => setValue('featureProperty', value)}
                        selected={watch('featureProperty')}
                    />
                </FormGroup>

                <FormGroup label={t('layerUploadKeyPairingModal.entityPropertiesLabel', 'Entity property')} required className={classes.formGroup} hasValue={watch('entityProperty')} errorMessage={errors?.entityProperty?.message}>
                    <FormSelect
                        dataList={entityPropertySelectList}
                        onChange={value => setValue('entityProperty', value)}
                        selected={watch('entityProperty')}
                    />
                </FormGroup> */}
            </ModalBody>

            <ModalFooter className={classes.modalFooter}>
                <Button className={classes.footerButton} onClick={handleSubmit(onFormSubmit)}>
                    Mentés
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
