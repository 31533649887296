import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Money from '@baseComponents/formatters/Money';
import { LoadSpinner } from '@baseComponents/loadSpinner';

const useStyles = createUseStyles((theme: any) => ({
    costCardWrapper: {
        width: '100%',
        backgroundColor: theme.color.buttonHover.secondary,
        borderRadius: 4,
        border: `1px solid ${theme.color.pearl}`,
        padding: 20,
        fontSize: 13,
        fontWeight: 'normal',
        position: 'relative',
    },
    costTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
        marginBottom: 5,
    },
    costWithLabel: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
    },
    money: {
        marginLeft: 'auto',
        fontSize: 12,
        fontWeight: 500,
        textAlign: 'right',
        color: theme.color.raven,
    },
    totalCostWithLabel: {
        display: 'flex',
        paddingTop: 10,
        marginTop: 10,
        borderTop: '1px solid',
        borderTopColor: theme.color.pearl,
    },
    totalCost: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'right',
        color: theme.color.raven,
        marginLeft: 'auto',
    },
    marginTop: {
        marginTop: 15,
        marginBottom: 15,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    currencySwitch: {
        marginLeft: 'auto',
    },
}));

export default function PeriodViewCostCard(props) {
    const { costList } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <div className={clsx(classes.costCardWrapper, classes.marginTop)}>
            <LoadSpinner value={costList} isAutoDetect />
            <div className={classes.header}>
                <span className={classes.costTitle}>
                    {t('periodView.expenses', 'Expenses')}
                </span>
            </div>

            <div className={classes.costWithLabel}>
                <span>
                    {t('periodView.workOperation', 'Work operation')}
                </span>
                <Money value={costList?.operatingCost?.operatingCost} style={classes.money} cellId="costViewWorkOperationCosts" />
            </div>
            <div className={classes.costWithLabel}>
                <span>
                    {t('periodView.materialConsumption', 'Material consumption')}
                </span>
                <Money value={costList?.productCost?.productCost} style={classes.money} cellId="costViewProductCosts" />
            </div>
            <div className={classes.costWithLabel}>
                <span>
                    {t('periodView.additionalCost', 'Additional costs')}
                </span>
                <Money value={costList?.plusCost?.cost} style={classes.money} cellId="costViewPlustCosts" />
            </div>
            <div className={classes.totalCostWithLabel}>
                <span>
                    {t('periodView.total', 'Total')}
                </span>
                <Money value={costList?.totalCost?.cost} style={classes.totalCost} cellId="costViewTotalCosts" />
            </div>
        </div>
    );
}
