import React from 'react';
import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

const useStyles = createUseStyles({
    emptyCell: {
        width: props => props.width || 'auto',
        minWidth: props => props.minWidth,
        maxWidth: props => props.maxWidth,
    },
    hiddenSelectBox: {
        display: 'none',
    },
});

export default function TableHeaderEmptyCell({ column, isBasePageTable, isMiddleColumnCell }) {
    const { width, minWidth, maxWidth, isSelectRowColumn } = column;
    const classes = useStyles({ width, minWidth, maxWidth });
    const isEnabledGroupOperation = useSelector((state: any) => state.basePage.isEnabledGroupOperation);

    if (isSelectRowColumn) {
        return (
            <div className={clsx((isEnabledGroupOperation || !isBasePageTable) ? classes.emptyCell : classes.hiddenSelectBox)} />
        );
    }

    return (
        <div className={clsx(classes.emptyCell, (isMiddleColumnCell && 'headerCell'))} />
    );
}
