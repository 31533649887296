import React from 'react';
import { isEmpty } from 'lodash';

import ListHeaderRow from './ListHeaderRow';
import ListDataRow from './ListDataRow';
import ListFooterRow from './ListFooterRow';

export default function ListRow(props) {
    const { rowData, index, classes } = props; // common props
    const { isGroupCollapseable, isGroupCollapsed, isBasePageTable, onToggleCollapsable, canUseGroupOperation } = props; // header props
    const { onSelectRow, tableFunctions } = props; // data row props
    const { isDataCard, leftFixedColumns, mainColumns, rightFixedColumns, handleMidScroll, columnWidths, leftSideWidth, rightSideWidth } = props; // datarow and footer props
    const { aggregateType } = props; // footer props
    const { tableKey } = props; //simple table

    const getType = () => {
        if (rowData.isGroupHeader) {
            return 'header';
        }
        if (!rowData.isGroupHeader && !rowData.isGroupFooter) {
            return 'data';
        }
        if (rowData.isGroupFooter
            && !isEmpty(rowData.groupName)
            && !isDataCard) {
            return 'footer';
        }
    };

    switch (getType()) {
        case 'header': return (
            <ListHeaderRow
                classes={classes}
                rowStyle={classes.tableHeaderRow}
                groupName={rowData.groupName}
                groupItemCount={rowData.groupItemCount}
                leftFixedColumns={leftFixedColumns}
                mainColumns={mainColumns}
                rightFixedColumns={rightFixedColumns}
                handleMidScroll={handleMidScroll}
                columnWidths={columnWidths}
                leftSideWidth={leftSideWidth}
                rightSideWidth={rightSideWidth}
                isDataCard={isDataCard}
                aggregateType={aggregateType}
                canUseGroupOperation={canUseGroupOperation}
                isCollapsible={isGroupCollapseable(index)}
                isCollapsed={isGroupCollapsed(index)}
                isBasePageTable={isBasePageTable}
                onToggleCollapsable={onToggleCollapsable}
                tableKey={tableKey}
            />
        );
        case 'data': return (
            <ListDataRow
                classes={classes}
                rowStyle={classes.tableDataRow}
                rowData={rowData}
                leftFixedColumns={leftFixedColumns}
                mainColumns={mainColumns}
                rightFixedColumns={rightFixedColumns}
                handleMidScroll={handleMidScroll}
                columnWidths={columnWidths}
                leftSideWidth={leftSideWidth}
                rightSideWidth={rightSideWidth}
                isDataCard={isDataCard}
                onSelectRow={onSelectRow}
                tableFunctions={tableFunctions}
                isBasePageTable={isBasePageTable}
                tableKey={tableKey}
            />
        );
        case 'footer': return (
            <ListFooterRow
                classes={classes}
                rowStyle={classes.tableFooterRow}
                rowData={rowData}
                groupName={rowData.groupName}
                leftFixedColumns={leftFixedColumns}
                mainColumns={mainColumns}
                rightFixedColumns={rightFixedColumns}
                handleMidScroll={handleMidScroll}
                columnWidths={columnWidths}
                leftSideWidth={leftSideWidth}
                rightSideWidth={rightSideWidth}
                isDataCard={isDataCard}
                aggregateType={aggregateType}
                isBasePageTable={isBasePageTable}
                tableKey={tableKey}
            />
        );
        default: return null;
    }
}
