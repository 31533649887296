import { isEmpty } from 'lodash';

export default function useTableColumnFader() {
    const displayTableColumnFaders = midColItem => {
        const hasScrollbar = midColItem && midColItem?.offsetWidth < midColItem?.scrollWidth;
        const childNodes = midColItem?.parentElement?.childNodes;
        const leftFader = Object.values(childNodes)?.find(childNode => childNode.classList?.contains('leftFader'));
        const rightFader = Object.values(childNodes)?.find(childNode => childNode.classList?.contains('rightFader'));

        if (leftFader) {
            handleLeftFaderVisibility(leftFader, midColItem.scrollLeft, hasScrollbar);
        }

        if (rightFader) {
            handleRightFaderVisibility(rightFader, (midColItem.scrollLeft + 5) - (midColItem.scrollWidth - midColItem.offsetWidth), hasScrollbar);
        }
    };

    async function handleLeftFaderVisibility(leftFader, scrollPosition, hasScrollbar) {
        const faderDisplay = isEmpty(leftFader.style.display) ? 'none' : leftFader.style.display;

        if (hasScrollbar && scrollPosition >= 5 && faderDisplay === 'none') {
            leftFader.style.display = 'block';
        } else if (scrollPosition < 5 && faderDisplay === 'block') {
            leftFader.style.display = 'none';
        }
    }

    async function handleRightFaderVisibility(rightFader, scrollPosition, hasScrollbar) {
        const faderDisplay = isEmpty(rightFader.style.display) ? 'none' : rightFader.style.display;

        if (hasScrollbar && scrollPosition <= 0 && faderDisplay === 'none') {
            rightFader.style.display = 'block';
        } else if (scrollPosition > 0 && faderDisplay === 'block') {
            rightFader.style.display = 'none';
        }
    }

    return {
        displayTableColumnFaders,
    };
}
