import { RIGHTS, SYSTEM, SETTINGS } from '../constants';

export const LIVESTOCK_DAILY_TASKS = {
    MODULE_ID: 37,
    NAME: 'livestockDailyTasks',
    EXCLUDE_SYSTEM_MODULES: [
        SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
        SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD,
        SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
        SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
    ],
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockDailyTasks',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.livestockDailyTasks',
                TRANSLATOR_DEFAULT: 'Daily tasks',
            },
        },
        DAILY_TASKS: {
            CONTENT_URL_ID: { dailyTasks: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockDailyTasks',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.livestockDailyTasks',
                TRANSLATOR_DEFAULT: 'Daily tasks',
            },
            /*RIGHTS: [RIGHTS.DAILY_TASKS_INTERFACE],*/
        },
        VETERINARY_TREATMENTS: {
            CONTENT_URL_ID: { VeterinaryTreatments: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockVeterinaryTreatments',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockVeterinaryTreatments',
                TRANSLATOR_DEFAULT: 'Veterinary treatments',
            },
            RIGHTS: [RIGHTS.VETERINARY_TREATMENTS_INTERFACE],
        },
        VETERINARY_TREATMENT_EDIT: {
            CONTENT_URL_ID: { VeterinaryTreatmentEdit: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockVeterinaryTreatmentEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockVeterinaryTreatmentEdit',
                TRANSLATOR_DEFAULT: 'Veterinary treatment edit',
            },
            RIGHTS: [RIGHTS.VETERINARY_TREATMENT_EDIT_INTERFACE],
        },
        INSEMINATION: {
            CONTENT_URL_ID: { insemination: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockInsemination',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockInsemination',
                TRANSLATOR_DEFAULT: 'Insemination',
            },
            RIGHTS: [RIGHTS.INSEMINATION_INTERFACE],
        },
        INSEMINATION_EDIT: {
            CONTENT_URL_ID: { inseminationEdit: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockInseminationEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockInseminationEdit',
                TRANSLATOR_DEFAULT: 'Insemination edit',
            },
            RIGHTS: [RIGHTS.INSEMINATION_EDIT_INTERFACE],
        },
        CALVING: {
            CONTENT_URL_ID: { calving: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockCalving',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockCalving',
                TRANSLATOR_DEFAULT: 'Calving',
            },
            RIGHTS: [RIGHTS.CALVING_INTERFACE],
        },
        CALVING_EDIT: {
            CONTENT_URL_ID: { calvingEdit: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockCalvingEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockCalvingEdit',
                TRANSLATOR_DEFAULT: 'Calving edit',
            },
            RIGHTS: [RIGHTS.CALVING_EDIT_INTERFACE],
        },
        ANIMAL_MILKING: {
            CONTENT_URL_ID: { milking: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockMilking',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockMilking',
                TRANSLATOR_DEFAULT: 'Milking',
            },
            RIGHTS: [RIGHTS.ANIMAL_MILKING_INTERFACE],
        },
        ANIMAL_MILKING_EDIT: {
            CONTENT_URL_ID: { milkingEdit: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockMilkingEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockMilkingEdit',
                TRANSLATOR_DEFAULT: 'Milking edit',
            },
            RIGHTS: [RIGHTS.ANIMAL_MILKING_INTERFACE],
        },
        ANIMAL_DAILY_MILK: {
            CONTENT_URL_ID: { dailyMilk: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalkDailyMilk',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalDailyMilk',
                TRANSLATOR_DEFAULT: 'Daily milk',
            },
            RIGHTS: [RIGHTS.ANIMAL_DAILY_MILK_INTERFACE],
        },
        ANIMAL_DAILY_MILK_EDIT: {
            CONTENT_URL_ID: { dailyMilkEdit: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalDailyMilkEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalDailyMilkEdit',
                TRANSLATOR_DEFAULT: 'Daily milk edit',
            },
            RIGHTS: [RIGHTS.ANIMAL_DAILY_MILK_EDIT_INTERFACE],
        },
        ANIMAL_MILK_INCOMING_DATA: {
            CONTENT_URL_ID: { milkIncomingData: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'milkIncomingData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalDailyMilkXls',
                TRANSLATOR_DEFAULT: 'Milk incoming data',
            },
            RIGHTS: [RIGHTS.ANIMAL_RECORD_INTERFACE],
        },
        ANIMAL_DAILY_MILK_XLS_INTERRFACE: {
            CONTENT_URL_ID: { dailyMilkXlsInterface: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalDailyMilkXls',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalkDailyMilkXlsInterface',
                TRANSLATOR_DEFAULT: 'Daily milk from Excel interface',
            },
            RIGHTS: [RIGHTS.ANIMAL_RECORD_INTERFACE],
        },
        LIVESTOCK_WEIGHT_CERTIFICATE: {
            CONTENT_URL_ID: { livestockWeightCertificate: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockWeightCertificate',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockWeightCertificate',
                TRANSLATOR_DEFAULT: 'Preparation of a live animal body weight certificate',
            },
            RIGHTS: [RIGHTS.LIVESTOCK_WEIGHT_CERTIFICATE],
        },
        LIVESTOCK_PROTOCOL: {
            CONTENT_URL_ID: { livestockProtocol: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockProtocol',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockProtocol',
                TRANSLATOR_DEFAULT: 'Livestock protocol',
            },
            RIGHTS: [RIGHTS.LIVESTOCK_PROTOCOL],
            SETTINGS: [
                { [SETTINGS.INDIVIDUAL_ANIMAL_REGISTRATION]: 1 }
            ],
        },   
        RECORDING_ANIMAL_WEIGHT_GAIN_FOR_PERIODS: {
            CONTENT_URL_ID: { recordingAnimalWeightGainForPeriods: 17},
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'recordingAnimalWeightGainForPeriods',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.recordingAnimalWeightGainForPeriods',
                TRANSLATOR_DEFAULT: 'Recording animal weight gain for periods',
            },
            RIGHTS: [RIGHTS.INDIVIDUAL_ANIMAL_EDIT],
            SETTINGS: [
                { [SETTINGS.INDIVIDUAL_ANIMAL_REGISTRATION]: 1 }
            ],
        },
    },
};
