import { RIGHTS } from '../constants';

export const PARTNER = {
    MODULE_ID: 6,
    NAME: 'partners',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'partner',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.partner',
                TRANSLATOR_DEFAULT: 'Partner',
            },
        },
        PARTNERS: {
            CONTENT_URL_ID: { partners: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'partners',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.partners',
                TRANSLATOR_DEFAULT: 'Partners',
            },
            RIGHTS: [RIGHTS.PARTNER_INTERFACE],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        PARTNER_EDIT: {
            CONTENT_URL_ID: { partnerEdit: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'partnerEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.partnerEdit',
                TRANSLATOR_DEFAULT: 'Partner edit',
            },
            RIGHTS: [RIGHTS.ADDING_PARTNER],
        },
        COUNTIES: {
            CONTENT_URL_ID: { countries: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'counties',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.countries',
                TRANSLATOR_DEFAULT: 'Countries',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        PLACES: {
            CONTENT_URL_ID: { places: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'places',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.places',
                TRANSLATOR_DEFAULT: 'Places',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        METHODS_OF_PAYEMENT: {
            CONTENT_URL_ID: { methodsOfPayment: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'methodsOfPayement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.methodsOfPayement',
                TRANSLATOR_DEFAULT: 'Methods of payement',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        METHODES_OF_PAYEMENT_EDIT: {
            CONTENT_URL_ID: { methodsOfPaymentEdit: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'methodsOfPayementEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.methodsOfPayementEdit',
                TRANSLATOR_DEFAULT: 'Methods of payement edit',
            },
        },
        PARTNER_DISCOUNTS: {
            CONTENT_URL_ID: { partnerDiscounts: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'partnerDiscounts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.partnerDiscounts',
                TRANSLATOR_DEFAULT: 'Partner discounts',
            },
            RIGHTS: [RIGHTS.PARTNER_DISCOUNTS_INTERFACE],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        PARTNER_QUANTITY_DISCOUNTS: {
            CONTENT_URL_ID: { partnerQuantityDiscounts: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'partnerQuantityDiscounts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.partnerQuantityDiscounts',
                TRANSLATOR_DEFAULT: 'Partner quantity discounts',
            },
            RIGHTS: [RIGHTS.PARTNER_QUANTITY_DISCOUNTS_INTERFACE],
        },
    },
};
