import { Nullable } from '@flowsCommon/services/baseTypes';
import { SlovakReportContextProvider } from '@map/services/slovakianReports/slovakReportContext';
import SlovakReportLayers from './SlovakReportLayers/SlovakReportLayers';
import SlovakInfoPanel from './SlovakInfoPanel/SlovakInfoPanel';
import SlovakReportLayersLegend from './SlovakReportLayers/SlovakReportLayersLegend';

type SlovakReportsOverlayProps = {
    mapRef: Nullable<google.maps.Map>
    children: any;
    isEnabled: boolean;
    gsaaYear: number;
    gsaaKODKD: string;
};

export default function SlovakReportsOverlay(props: SlovakReportsOverlayProps) {
    const { mapRef, children, isEnabled, gsaaYear, gsaaKODKD } = props;

    return (
        <SlovakReportContextProvider gsaaYear={gsaaYear} gsaaKODKD={gsaaKODKD}>

            {
                isEnabled ? (
                    <>                    
                    <SlovakReportLayers map={mapRef} />
                    <SlovakReportLayersLegend />
                    <SlovakInfoPanel />
                    </>
                ) : null
            }

            {children}
        </SlovakReportContextProvider>
    );
}
