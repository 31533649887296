// eslint-disable-next-line no-restricted-imports
import { dateTimeUtils } from 'shared/src/modules';
// eslint-disable-next-line no-restricted-imports
import { MapSliderDatePicker, MapSliderIntervalMode } from '../MapSliderTypes';

export const calculateMapSliderOptions = (mapSliderMode: MapSliderIntervalMode, pickedDate: Date) => {
    const pickedDateMoment = dateTimeUtils.getDateObject(pickedDate);

    let sliderOptionMax: number;
    let sliderOptionDatePicker: MapSliderDatePicker[];
    let sliderOptionDefaultValue: number;

    /* eslint-disable no-case-declarations */
    switch (mapSliderMode) {
        case MapSliderIntervalMode.YEAR:
            const datePickerYear: MapSliderDatePicker[] = [];
            const numberOfDays: number = pickedDateMoment.isLeapYear() ? 366 : 365;
            const firstDayOfYear = pickedDateMoment.clone().set('month', 0).set('date', 1);

            for (let i = 0; i < numberOfDays; i++) {
                const picker: MapSliderDatePicker = { date: firstDayOfYear.toDate() };
                datePickerYear.push(picker);
                firstDayOfYear.add(1, 'days');
            }

            const defaultValueYear = pickedDateMoment.dayOfYear() - 1;

            sliderOptionMax = (numberOfDays - 1);
            sliderOptionDatePicker = datePickerYear;
            sliderOptionDefaultValue = defaultValueYear;

            break;
        case MapSliderIntervalMode.MONTH:
            const daysInMonth = pickedDateMoment.daysInMonth();
            const datePickerMonth: MapSliderDatePicker[] = [];
            const firsDayOfMonth = pickedDateMoment.clone().set('date', 1);

            for (let i = 0; i < daysInMonth; i++) {
                const picker: MapSliderDatePicker = { date: firsDayOfMonth.toDate() };
                datePickerMonth.push(picker);
                firsDayOfMonth.add(1, 'days');
            }

            const defaultValueMonth = pickedDateMoment.get('date') - 1;

            sliderOptionMax = (daysInMonth - 1);
            sliderOptionDatePicker = datePickerMonth;
            sliderOptionDefaultValue = defaultValueMonth;

            break;
        default:
            const datePickerWeek: MapSliderDatePicker[] = [];

            for (let i = 1; i <= 7; i++) {
                const oneDay = pickedDateMoment.clone().isoWeekday(i);
                const picker: MapSliderDatePicker = { date: oneDay.toDate() };
                datePickerWeek.push(picker);
            }

            const defaultValueWeek = pickedDateMoment.isoWeekday() - 1;

            sliderOptionMax = 6;
            sliderOptionDatePicker = datePickerWeek;
            sliderOptionDefaultValue = defaultValueWeek;
            break;
    }

    return {
        sliderOptionMax,
        sliderOptionDatePicker,
        sliderOptionDefaultValue,
    };
};
