import { useMemo, useState } from 'react';
import { isEmpty, isFunction, sum } from 'lodash';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { fileUtils } from 'shared/src/modules';
import NoData from '@baseComponents/chips/NoData';
import { FILES } from 'shared/src/constants';
import useTranslate from '@i18n/useTranslate';
import { errorActions } from '@states/actions';
import FileCard from './FileCard';
import FileButton from './FileButton';

const useStyles = createUseStyles((theme: any) => ({
    filesContainer: {
        width: '100%',
        minWidth: 1020,
        height: '100%',
        position: 'relative',
        backgroundColor: theme.color.lilian,
        borderBottomRightRadius: 12,
        display: 'flex',
        flexFlow: 'column',
        padding: [30, 30, 10, 30],
    },
    files: {
        display: 'grid',
        height: '100%',
        width: '100%',
        overflow: 'auto',
        ...theme.verticalScrollbar,
        padding: [20, 0],
        gridTemplateColumns: 'repeat( auto-fit, minmax(180px, 180px) )',
        gridColumnGap: 15,
        gridRowGap: 10,
    },
    fileSize: {
        fontSize: 14,
    },
    alert: {
        color: theme.color.destructive,
    },
}));

export default function AddFiles(props) {
    const { defaultFiles, setSelectedFiles, inputAccept } = props;
    const [files, setFiles] = useState(defaultFiles || []);
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();

    async function onClick(event) {
        try {
            const fileObj = event.target?.files?.[0];

            if (!fileObj) {
                return;
            }

            const base64File = await fileUtils.encodeFile(fileObj);
            setFiles(prevFiles => {
                const updatedFiles = [
                    ...(prevFiles || []),
                    {
                        buffer: base64File,
                        fileName: fileObj.name,
                        size: fileUtils.convertFileSize(fileObj?.size, FILES.UNITS.MB),
                    },
                ];
                if (isFunction(setSelectedFiles)) {
                    setSelectedFiles(updatedFiles);
                }

                return updatedFiles;
            });
        } catch (error) {
            dispatch(errorActions.setError(error, 'add files'));
        }
    }

    function onDeleteFile(index) {
        const filteredFiles = files?.filter((_file, fileIndex) => fileIndex !== index);
        if (isFunction(setSelectedFiles)) {
            setSelectedFiles(filteredFiles);
        }
        setFiles(filteredFiles);
    }

    const selectedFileSize = useMemo(() => {
        const fileSize = sum(files?.map(file => +file.size));
        return fileSize.toFixed(FILES.ROUND.MB);
    }, [files]);

    const filesSizeClasses = useMemo(() => {
        if (+selectedFileSize < +FILES.UPDATE_MAX_SIZE) {
            return classes.fileSize;
        }
        return clsx(classes.fileSize, classes.alert);
    }, [classes.alert, classes.fileSize, selectedFileSize]);

    return (
        <div className={classes.filesContainer}>
            <FileButton
                inputAccept={inputAccept}
                onClick={onClick}
            />
            {!isEmpty(files)
                ? (
                    <div className={classes.files}>
                        {files.map((file, index) => (
                            <FileCard
                                key={index}
                                file={file}
                                id={index}
                                onDeleteFile={onDeleteFile}
                            />
                        ))}
                    </div>)
                : <NoData isAdd />
            }
            <div className={filesSizeClasses}>
                {t('addFiles.usableSpace', 'Usable space')}: {selectedFileSize} {FILES.UNITS.MB}/{FILES.UPDATE_MAX_SIZE} {FILES.UNITS.MB}
            </div>
        </div>
    );
}
