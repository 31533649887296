import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const gnService = {
    getGnInformationObligedEventList,
    getGnWaterAbstractionLocationList,
};

/**
 * Get all information obliged events datas
 * HUN name: gazdasági napló, tájékoztatás köteles esemény
 * @return {array} Information obliged events array
 */
async function getGnInformationObligedEventList() {
    const response = await sendServerRequest('GET', '/api/gn/informationobligedevent');
    return response;
}

async function getGnWaterAbstractionLocationList(companyIds) {
    const url = routerHelper.getUrlWithParameters('/api/gn/waterabstractionlocation', { companyIds });
    const response = await sendServerRequest('GET', url);
    return response;
}
