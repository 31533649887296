import { useMapContext } from '@map/services/mapContext';
import { selectedFeatureStyle } from '@map/utils/mapUtils';
import { FeatureCollection } from 'geojson';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FeatureSelectorComponent } from './FeatureSelectorComponent';

type CType = {
    selectorDefaultEntityIds?: number[],
    onGeometrySelector?: (selectedEntityIds: number[]) => void,
}

export const FeatureSelector = ({ selectorDefaultEntityIds, onGeometrySelector }: CType) => {
    const [store, controller] = useMapContext();

    const [geometryClickListener, setGeometryClickListener] = useState<google.maps.MapsEventListener>();
    const [selectedLayerListener, setSelectedLayerListener] = useState<google.maps.MapsEventListener>();
    const attributeId = useRef<number>();

    const initGeomSelectorListener = (productionYearId, dataLayer: google.maps.Data) => {
        if (selectedLayerListener) {
            google.maps.event.removeListener(selectedLayerListener);
        }

        const listener = dataLayer.addListener('click', (ev: any) => {
            const feature = ev.feature as google.maps.Data.Feature;
            dataLayer.remove(feature);
            dataLayer.toGeoJson(geo => {
                const geojson = geo as FeatureCollection;
                controller.setMultiSelectedGeometriesFeatures(productionYearId, geojson.features);
            });
            dataLayer.overrideStyle(feature, selectedFeatureStyle);
            const currentAttribute = store.layering.attributes?.find(attribute => attribute.id === attributeId.current);
            currentAttribute?.dataLayer.add(feature);
        });

        setSelectedLayerListener(listener);
    };

    const initGeomSelector = (productionYearId: number) => {
        if (!store.googleRefs.map && !controller) {
            return;
        }

        const multiSelectedGeometry = store.multiSelectedGeometries.find(selectedGeometry => selectedGeometry.yearId === productionYearId);

        const dataLayer = !multiSelectedGeometry ? new google.maps.Data() : multiSelectedGeometry.dataLayer;

        if (isNil(dataLayer)) {
            return;
        }

        if (!multiSelectedGeometry) {
            initGeomSelectorListener(productionYearId, dataLayer);
            controller.setMultiSelectedGeometriesDataLayer(productionYearId, dataLayer);
        } else {
            initGeomSelectorListener(productionYearId, dataLayer);
        }
    };

    const showGeomSelector = (productionYearId: number) => {
        const multiSelector = store.multiSelectedGeometries.find(geometrySelector => geometrySelector.yearId === productionYearId);
        multiSelector?.dataLayer?.setMap(store.googleRefs.map);
    };

    const initMapGeometryListener = (productionYearId: number) => {
        if (geometryClickListener) {
            google.maps.event.removeListener(geometryClickListener);
        }

        if (store.googleRefs.map && store.layering.attributes && controller) {
            const activeAttribute = store.layering.attributes?.find(attribute => attribute.isActive);

            if (!activeAttribute) {
                return;
            }

            let filteredGeomIdsEntitiyIds: Array<{ geomId: number, entityId: number }> = [];

            if (selectorDefaultEntityIds) {
                const activeLayer = store.layering.layers?.find(layer => layer.id === activeAttribute.layerId);
                const features = activeLayer?.geometries?.features as any;
                const geomIdsEntitiyIds = features?.map(x => ({ geomId: x.id, entityId: x.properties.entityId }));
                filteredGeomIdsEntitiyIds = geomIdsEntitiyIds?.filter(geomEntitiyId => selectorDefaultEntityIds.includes(geomEntitiyId.entityId));
            }

            const multiSelectedGeometry = store.multiSelectedGeometries?.find(geometrySelector => geometrySelector.yearId === productionYearId);

            if (!multiSelectedGeometry) {
                return;
            }

            const { dataLayer } = multiSelectedGeometry;
            if (dataLayer && activeAttribute) {
                dataLayer?.forEach(feature => {
                    const featureId = feature.getId();

                    if (!isNil(featureId)) {
                        const featureToRemove = activeAttribute.dataLayer.getFeatureById(featureId);
                        if (featureToRemove) {
                            activeAttribute.dataLayer.remove(featureToRemove);
                        }
                    }
                });

                filteredGeomIdsEntitiyIds?.forEach(geomEntitiyId => {
                    const featureToRemove = activeAttribute.dataLayer.getFeatureById(geomEntitiyId.geomId);
                    if (featureToRemove) {
                        activeAttribute.dataLayer.remove(featureToRemove);
                        dataLayer.add(featureToRemove);
                        dataLayer.overrideStyle(featureToRemove, selectedFeatureStyle);
                    }
                });

                const listener = activeAttribute.dataLayer.addListener('click', ev => {
                    dataLayer?.forEach(feature => {
                        if (activeAttribute.dataLayer.contains(feature)) {
                            activeAttribute.dataLayer.remove(feature);
                        }
                    });

                    activeAttribute.dataLayer.remove(ev.feature);
                    store.multiSelectedGeometries?.find(geometrySelector => geometrySelector.yearId === productionYearId)?.dataLayer?.add(ev.feature);
                    store.multiSelectedGeometries?.find(geometrySelector => geometrySelector.yearId === productionYearId)?.dataLayer?.overrideStyle(ev.feature, selectedFeatureStyle);
                    store.multiSelectedGeometries?.find(geometrySelector => geometrySelector.yearId === productionYearId)?.dataLayer?.toGeoJson(geo => {
                        const geojson = geo as FeatureCollection;
                        controller.setMultiSelectedGeometriesFeatures(productionYearId, geojson.features);
                    });
                });

                attributeId.current = activeAttribute.id;
                setGeometryClickListener(listener);
            }
        }
    };

    useEffect(() => {
        if (!store.googleRefs.map && !store.layering.attributes) {
            return;
        }

        const { productionYearId } = store.filters;

        store.multiSelectedGeometries.forEach(geometrySelector => {
            geometrySelector.dataLayer?.setMap(null);
        });

        initGeomSelector(productionYearId);
        initMapGeometryListener(productionYearId);
        showGeomSelector(productionYearId);

        return (() => {
            if (geometryClickListener) {
                google.maps.event.removeListener(geometryClickListener);
            }
            if (selectedLayerListener) {
                google.maps.event.removeListener(selectedLayerListener);
            }
        });
    }, [store.googleRefs.map, store.layering.attributes, attributeId, controller, store.filters]);

    return (
        <FeatureSelectorComponent
            onGeometrySelector={onGeometrySelector}
            selectorDefaultEntityIds={selectorDefaultEntityIds}
        />);
};
