/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import useTranslate from '@i18n/useTranslate';
import Checkbox from '@baseComponents/controls/Checkbox';
import BaseTooltip from '@baseComponents/tooltips/tooltip/BaseTooltip';
import useBasePageRowSelection from './useBasePageRowSelection';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        fontSize: 12,
        lineHeight: 1.5,
        color: theme.color.jet,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        marginLeft: -10,
        ...theme.overFlowText,
        ...theme.disableOutline,
        ...theme.disableSelectionColor,
    },
}));

export default function SelectRowCell(props) {
    const { id, rowData, style, isBasePageTable, tableKey, onChangeSelect } = props;
    const [isDisabled] = useState(rowData?.isDisabled);
    const { t } = useTranslate();
    const [isSelectedRow, setIsSelectedRow] = useState(getIsSelectedRow());
    const classes = useStyles();

    const { changeCheckedState } = useBasePageRowSelection({ id, isBasePageTable, isSelectedRow, setIsSelectedRow, rowData, tableKey, onChangeSelect });

    function getIsSelectedRow() {
        return !!rowData.isSelectedRow;
    }

    //ignore data card onClick
    function onClick(event) {
        event.stopPropagation();
    }

    async function setSelectedOnClick() {
        setIsSelectedRow(currentState => {
            rowData.isSelectedRow = !currentState;
            return !currentState;
        });
    }

    return (
        <div className={clsx(classes.baseStyle, style)} onClick={onClick} role="button" tabIndex="0">
            <BaseTooltip
                placement="bottom"
                type="warning"
                message={t('default.dataIsNotCommutable', 'Data is not commutable!')}
                show={isDisabled}
            >
                <Checkbox size="small" checked={isSelectedRow} setChecked={setSelectedOnClick} onChange={changeCheckedState} disabled={isDisabled} />
            </BaseTooltip>
        </div>

    );
}
