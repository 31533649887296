import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { errorActions } from '@states/actions';

export default function usePeriodViewDataLoader(props) {
    const { cultivationPeriodValues } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //functions
    const [editFunctionObject, setEditFunctionObject] = useState();
    const [closePeriodFunctionObject, setClosePeriodFunctionObject] = useState();
    const [otherFunctionList, setOtherFunctionList] = useState();
    const [cultivationPeriodData, setCultivationPeriodData] = useState();
    //data lists
    const [supportList, setSupportList] = useState();
    const [registryNumberList, setRegistryNumberList] = useState();
    const [reproductionRateName, setReproductionRateName] = useState();
    const [basicFieldsList, setBasicFieldsList] = useState();
    const [costList, setCostList] = useState();

    useEffect(() => {
        let isCancelled = false;

        async function loadData(getDataFunction, setterFunction) {
            const dataList = await getDataFunction();

            if (!isCancelled) {
                setterFunction(dataList);
            }
        }

        async function getAdditionalCost(getDataFunction, setterFunction) {
            const costs = await getDataFunction();
            const cost = Number(costs.totalCost.cost) || 0;
            const operatingCost = Number(costs.operatingCost.operatingCost) || 0;
            const productCost = Number(costs.productCost.productCost) || 0;
            const additionalCost = cost - operatingCost - productCost;

            if (additionalCost) {
                costs.additionalCost = additionalCost;
            }

            if (!isCancelled) {
                setterFunction(costs);
            }
        }

        async function getRegistryNumberList(getDataFunction, setterFunction) {
            let registryNumbers = await getDataFunction();
            if (!isEmpty(registryNumbers)) {
                registryNumbers = registryNumbers.map(regNumObj => ({ ...regNumObj, name: regNumObj.registryNumber.name }));
            }

            if (!isCancelled) {
                setterFunction(registryNumbers);
            }
        }

        function getcropTypeName() {
            return cultivationPeriodValues.cultivationPeriodData?.isPlantation
                ? t('cropData.plantation', 'Plantation')
                : cultivationPeriodValues.cultivationPeriodData?.cropTypeName;
        }

        if (!isEmpty(cultivationPeriodValues)) {
            try {
                setEditFunctionObject(cultivationPeriodValues.functionList?.find(functionObj => functionObj.isEditFunction));
                setClosePeriodFunctionObject(cultivationPeriodValues.functionList?.find(functionObj => functionObj.isPeriodCloseFunction));
                setOtherFunctionList(cultivationPeriodValues.functionList?.filter(functionObj => !functionObj.isEditFunction && !functionObj.isPeriodCloseFunction));
                setCultivationPeriodData({
                    ...cultivationPeriodValues.cultivationPeriodData,
                    varietyName: cultivationPeriodValues.cultureVarietyName,
                    cropTypeName: getcropTypeName(),
                });

                loadData(cultivationPeriodValues.getReproductionRateName, setReproductionRateName);
                loadData(cultivationPeriodValues.getSupportList, setSupportList);
                loadData(cultivationPeriodValues.getBasicFields, setBasicFieldsList);
                getRegistryNumberList(cultivationPeriodValues.getRegistryNumberList, setRegistryNumberList);
                getAdditionalCost(cultivationPeriodValues.getCostList, setCostList);
            } catch (error) {
                dispatch(errorActions.setError(error, 'LoadPeriodViewData'));
            }

            return () => {
                isCancelled = true;
            };
        }
    }, [cultivationPeriodValues, t, dispatch]);

    return {
        editFunctionObject,
        closePeriodFunctionObject,
        otherFunctionList,
        cultivationPeriodData,

        reproductionRateName,
        supportList,
        basicFieldsList,
        registryNumberList,
        costList,
    };
}
