import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { hasPermission } from 'shared/src/permissions';
import { RIGHTS, SETTINGS } from 'shared/src/constants';
import { Link } from 'react-router-dom';
import { MODULES } from 'shared/src/routing';
import { numberUtils } from 'shared/src/modules';
import { errorActions } from '@states/actions/errorActions';
import { companyActions } from '@states/actions/companyActions';
import { routerHelper } from '@utils/router/RouterHelper';
import useTranslate from '@i18n/useTranslate';
import { analysisService } from '@services';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        display: 'block',
        position: 'bottom',
        width: '100%',
        background: theme.color.lilian,
        padding: (props: any) => (props.expanded ? '5px 20px' : '10px 0px'),
        borderTop: '1px solid',
        borderBottom: '1px solid',
        textAlign: (props: any) => !props.expanded && 'center',
        borderTopColor: theme.color.pearl,
        borderBottomColor: theme.color.pearl,
        color: theme.color.jet,
    },
    headerDQS: {
        '&:hover': {
            color: theme.color.main,
            textDecoration: 'underline !important',
            '& span': {
                color: theme.color.main,
                textDecoration: 'underline',
                '& svg': {
                    fill: theme.color.main,
                },
            },
        },
        '&:focus': {
            textDecoration: 'none',
        },
        '&:not(:hover)': {
            color: theme.color.black,
        },
    },
    DQStitle: {
        fontSize: (props: any) => (props.expanded ? 14 : 12),
        fontWeight: 'normal',
        lineHeight: (props: any) => (props.expanded ? 2.14 : 1.25),
    },
    DQSvalue: {
        display: (props: any) => !props.expanded && 'block',
        fontSize: (props: any) => (props.expanded ? 16 : 14),
        fontWeight: 'bold',
        lineHeight: (props: any) => (props.expanded ? 1.88 : 1.07),
    },
    dqsRight: {
        float: (props: any) => props.expanded && 'right',
    },
    DQSstate: {
        display: (props: any) => !props.expanded && 'none',
        marginLeft: 10,
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 'bold',
        lineHeight: '1.45',
        textAlign: 'right',
        color: (props: any) => {
            if (props.previousValueDQS < 0) {
                return theme.color.stop; //negative
            }
            if (props.previousValueDQS > 0) {
                return theme.color.go; //positive
            }
            return theme.color.jet; //equal
        },
    },
    icon: {
        height: 18,
        width: 18,
        marginTop: -3,
        marginLeft: -3,
        marginRight: -3,
        fill: (props: any) => {
            if (props.previousValueDQS < 0) {
                return theme.color.stop; //negative
            }
            if (props.previousValueDQS > 0) {
                return theme.color.go; //positive
            }
            return theme.color.jet; //equal
        },
    },
}));

export default function DQSLink(props) {
    const { expanded } = props;
    const { t, translater } = useTranslate();
    const dispatch = useDispatch();
    const userToRights = useSelector((state: any) => state.user.user.userToRights);
    const isHidden = useSelector((state: any) => state.user.user.isHidden);
    const [databaseId, setDatabaseId] = useState(useSelector((state: any) => state.company.company.globalData.supportId));
    const [usingDQS] = useState(dispatch(companyActions.getSetting(SETTINGS.USING_DQS)));
    const [valueDQS, setValueDQS] = useState(0);
    const [previousValueDQS, setPreviousValueDQS] = useState(0);
    const classes = useStyles({ previousValueDQS, expanded });

    function displayDQS() {
        return usingDQS && hasPermission(RIGHTS.DQS_INTERFACE, userToRights, isHidden);
    }

    useEffect(() => {
        if (usingDQS && numberUtils.isNumber(databaseId) && databaseId <= 0) {
            setDatabaseId(10002);
        }
    }, [usingDQS, databaseId]);

    useEffect(() => {
        if (usingDQS && numberUtils.isNumber(databaseId) && dispatch && !isEmpty(userToRights)) {
            if (hasPermission(RIGHTS.DQS_INTERFACE, userToRights, isHidden)) {
                try {
                    analysisService.getDqsSum(databaseId).then(sumData => {
                        const afmSum = sumData && sumData.afm_sum ? sumData.afm_sum : 0;
                        const afmMod = sumData && sumData.afm_mod ? sumData.afm_mod : 0;
                        setValueDQS(afmSum);
                        setPreviousValueDQS(afmMod);
                    });
                } catch (error) {
                    dispatch(errorActions.setError(error, 'DQSLink load data'));
                }
            }
        }
    }, [usingDQS, databaseId, dispatch, userToRights, isHidden]);

    return (
        displayDQS() ? (
            <div className={classes.container}>
                <Link className={classes.headerDQS} to={routerHelper.generateUrl(MODULES.DATA_ANALYSIS, MODULES.DATA_ANALYSIS.CONTENTS.DQS)}>
                    <span className={classes.DQStitle}>{t('headerbar.DQS', 'DQS')}</span>
                    <span className={classes.dqsRight}>
                        <span className={classes.DQSvalue} title={translater('headerbar.dataQualityScore', 'Data Quality Score')}>{`${valueDQS}%`}</span>
                        <span className={classes.DQSstate} title={translater('headerbar.previousDQS', 'DQS change from last week')}>
                            {previousValueDQS > 0 && <Svg style={classes.icon} iconId="icon-arrow_drop_up" />}
                            {previousValueDQS < 0 && <Svg style={classes.icon} iconId="icon-arrow_drop_down" />}
                            {`${Math.abs(previousValueDQS)}%`}
                        </span>
                    </span>
                </Link>
            </div>
        ) : null
    );
}
