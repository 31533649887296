import { Dropdown } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    toggleButton: {
        padding: 0,
        magin: 0,
        textAlign: 'left',
        display: 'inline-block',
        width: '100%',
        height: 40,
        lineHeight: '40px',
        border: 'none',
        color: theme.color.black,
        overflow: 'hidden',
        ...theme.disableOutline,
        '&:hover': {
            color: theme.color.black,
            backgroundColor: 'transparent',
            border: 'none',
        },
        '&.btn-primary.dropdown-toggle': {
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            '&:active': {
                backgroundColor: 'transparent',
                border: 'none',
            },
            '&:focus': {
                boxShadow: 'none',
                border: 'none',
            },
            '&:before': {
                content: 'none',
            },
        },
    },
}));

export default function EmptyDropdownToggleButton(props) {
    const { children, className, show } = props;
    const classes = useStyles();

    return (
        <Dropdown.Toggle className={clsx(classes.toggleButton, className)} show={show}>
            {children}
        </Dropdown.Toggle>
    );
}
