//import { SETTINGS, SUBSCRIPTION } from '../constants';

export const PRODUCTION = {
    MODULE_ID: 39,
    NAME: 'production',
    SETTINGS: [
        //SETTINGS.USING_MAP,
    ],
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            //COMPONENT_URL: 'contents/production/menu/Production',
            COMPONENT_URL: 'contents/production/productionProcess/ProductionProcess',
            EVENT_KEY: 'production',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.production',
                TRANSLATOR_DEFAULT: 'Production',
            },
        },
        MENU: {
            CONTENT_URL_ID: { menu: 1111 },
            COMPONENT_URL: 'contents/production/menu/Production',
            EVENT_KEY: 'productionMenu',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.production',
                TRANSLATOR_DEFAULT: 'Production',
            },
        },
        PRODUCTION_PROCESS: {
            CONTENT_URL_ID: { productionProcess: 2 },
            COMPONENT_URL: 'contents/production/productionProcess/ProductionProcess',
            EVENT_KEY: 'productionProcess',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productionProcess',
                TRANSLATOR_DEFAULT: 'Production process',
            },
        },
        RESOURCES: {
            CONTENT_URL_ID: { products: 3 },
            COMPONENT_URL: 'contents/production/menu/Production',
            EVENT_KEY: 'productionResources',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productionResources',
                TRANSLATOR_DEFAULT: 'Resources',
            },
        },
        REPORTS: {
            CONTENT_URL_ID: { reports: 4 },
            COMPONENT_URL: 'contents/production/menu/Production',
            EVENT_KEY: 'productionReports',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productionReports',
                TRANSLATOR_DEFAULT: 'Reports',
            },
        },
        PRODUCTION_LINES: {
            CONTENT_URL_ID: { productionLines: 5 },
            COMPONENT_URL: 'contents/production/menu/Production',
            EVENT_KEY: 'productionLines',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productionLines',
                TRANSLATOR_DEFAULT: 'Production lines',
            },
        },
        FACTORIES: {
            CONTENT_URL_ID: { factories: 6 },
            COMPONENT_URL: 'contents/production/menu/Production',
            EVENT_KEY: 'factories',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.factories',
                TRANSLATOR_DEFAULT: 'Factories',
            },
        },
        RECIPES: {
            CONTENT_URL_ID: { recipes: 7 },
            COMPONENT_URL: 'contents/production/recipes/Recipes',
            EVENT_KEY: 'recipes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.recipes',
                TRANSLATOR_DEFAULT: 'Recipes',
            },
        },
        PROCESS_TEMPLATE: {
            CONTENT_URL_ID: { processTemplate: 8 },
            COMPONENT_URL: 'contents/production/processTemplate/ProcessTemplate',
            EVENT_KEY: 'processTemplate',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.processTemplates',
                TRANSLATOR_DEFAULT: 'Process templates',
            },
        },
    },
};
