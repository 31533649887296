import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { SETTINGS, CURRENCY, INTERFACE_SETTINGS, INTERFACE_FILENAME } from 'shared/src/constants';
import { companySettingsModule } from 'shared/src/company/companySettings';
import { sessionService } from '@services';
import { companyActions } from '@states/actions/companyActions';
import { userActions } from '@states/actions/userActions';
import { routerHelper } from '@utils/router/RouterHelper';
import { useNumberFormat } from '@common_modules/numberFormat';

export default function useCurrency(props) {
    const { interfaceAutoSave = true, manualEnabled } = props || {};
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const companySettings = useSelector((state: any) => state.company.company.settings);

    const interfaceSettings = useSelector((state: any) => state.user.user.settings.interfaceSettings);
    const primaryCurrency = useSelector((state: any) => state.company.company.currency.primaryCurrency);
    const secondaryCurrency = useSelector((state: any) => state.company.company.currency.secondaryCurrency);
    const companyCurrencies = useSelector((state: any) => state.company.company.currencies);
    const [planningMode] = useState(sessionService.getPlanningMode());
    const [isVisibleSwitch, setIsVisibleSwitch] = useState(false);
    const [isCheckedSwitch, setIsCheckedSwitch] = useState(false); //TODO: php - php use this
    const { numberFormatter } = useNumberFormat();

    //get actual currency id
    useEffect(() => {
        if (interfaceSettings && primaryCurrency && secondaryCurrency && dispatch) {
            const activeCurrency = interfaceSettings[INTERFACE_FILENAME.CURRENCY_SWITCH]
                ? interfaceSettings[INTERFACE_FILENAME.CURRENCY_SWITCH][INTERFACE_SETTINGS.ACTIVE_CURRENCY]
                : CURRENCY.TYPE.PRIMARY;

            if (isVisibleSwitch && secondaryCurrency && activeCurrency && Number(activeCurrency.value) === CURRENCY.TYPE.SECONDARY) {
                dispatch(companyActions.setIsSelectedCurrencySwitch(true));
                setIsCheckedSwitch(true); //TODO: php - php use this
            } else {
                dispatch(companyActions.setIsSelectedCurrencySwitch(false));
                setIsCheckedSwitch(false); //TODO: php - php use this
            }
        }
    }, [interfaceSettings, isVisibleSwitch, primaryCurrency, secondaryCurrency, dispatch]);

    //get primary and secondary currency and settings
    useEffect(() => {
        let isInactivatedCurrency = false;

        function getPrimaryCurrency() {
            const primaryCurrencyId = companySettingsModule.getSettingValue(SETTINGS.PRIMARY_CURRENCY, companySettings);
            const format = numberFormatter.getCurrencyFormatById(primaryCurrencyId);
            if (format) {
                const primCurrency = companyCurrencies?.find(currency => currency.id === primaryCurrencyId);

                if (!primCurrency) {
                    isInactivatedCurrency = true;
                }

                const primaryCurrencyObject = {
                    id: primaryCurrencyId,
                    text: format.unit,
                    fullText: primCurrency.name,
                };

                //set primary currency
                dispatch(companyActions.setPrimaryCurrency(primaryCurrencyObject));

                //set default currency
                dispatch(companyActions.setCurrentCurrency(primaryCurrencyObject));
            }
        }

        function getSecondaryCurrency() {
            const secondaryCurrencyId = companySettingsModule.getSettingValue(SETTINGS.SECONDARY_CURRENCY, companySettings);
            const format = numberFormatter.getCurrencyFormatById(secondaryCurrencyId);
            if (format) {
                const secCurrency = companyCurrencies?.find(currency => currency.id === secondaryCurrencyId);

                if (!secCurrency) {
                    isInactivatedCurrency = true;
                }

                const secondaryCurrencyObject = {
                    id: secondaryCurrencyId,
                    text: format.unit,
                    fullText: secCurrency?.name,
                };

                //set secondary currency
                dispatch(companyActions.setSecondaryCurrency(secondaryCurrencyObject));
            }
        }

        function getCurrencySwitchIsVisible(hasPermission) {
            let isVisibleCurrencySwitch = false;
            const contentObj: any = routerHelper.getContentObjectByUrl(location);
            isVisibleCurrencySwitch = (routerHelper.isHomePage(location) && !planningMode && hasPermission)
                || (contentObj && contentObj.HAS_CURRENCY_SWITCH && hasPermission && !planningMode) || (hasPermission && manualEnabled);

            //set use secondary currency
            setIsVisibleSwitch(isVisibleCurrencySwitch);
            dispatch(companyActions.setIsSelectedCurrencySwitch(isVisibleCurrencySwitch));
        }

        if (companySettings && numberFormatter && location && companyCurrencies) {
            const usingSecondaryCurrency = companySettingsModule.getSettingValue(SETTINGS.USING_SECONDARY_CURRENCY, companySettings);
            if (usingSecondaryCurrency) {
                getPrimaryCurrency();
                getSecondaryCurrency();
            } else {
                getPrimaryCurrency();
            }

            if (!isInactivatedCurrency) {
                getCurrencySwitchIsVisible(usingSecondaryCurrency);
            } else {
                setIsVisibleSwitch(false);
            }
        }
    }, [companySettings, numberFormatter, location, planningMode, dispatch, companyCurrencies, manualEnabled]);

    //change currency switch
    function currencyOnChange(value) {
        //set in database
        if (interfaceAutoSave) {
            let activeCurrency = CURRENCY.TYPE.PRIMARY;

            if (value === secondaryCurrency.id) {
                activeCurrency = CURRENCY.TYPE.SECONDARY;
                setIsCheckedSwitch(true);
                dispatch(companyActions.setCurrentCurrency(primaryCurrency));
            } else {
                setIsCheckedSwitch(false);
                dispatch(companyActions.setCurrentCurrency(secondaryCurrency));
            }

            dispatch(userActions.setInterfaceSetting(INTERFACE_SETTINGS.ACTIVE_CURRENCY, activeCurrency, INTERFACE_FILENAME.CURRENCY_SWITCH));
        }
    }

    return {
        isVisibleSwitch,
        currencyOnChange,
        isCheckedSwitch, //php - php use this
    };
}
