import { RIGHTS, SETTINGS } from '../constants';

export const HANSEAGRO = {
    MODULE_ID: 31,
    NAME: 'hanseagro',
    CONTENTS: {
        CONSULTANCY: {
            CONTENT_URL_ID: { consultancy: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'consultancy',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cultivationPeriodsConsultancy',
                TRANSLATOR_DEFAULT: 'Consultancy',
            },
            RIGHTS: [RIGHTS.CONSULTANCY_INTERFACE],
            SETTINGS: [SETTINGS.PERIOD_CONSULTING],
        },
        REVIEW_EDIT: {
            CONTENT_URL_ID: { reviewEdit: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'reviewEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.reviewEdit',
                TRANSLATOR_DEFAULT: 'Review edit',
            },
        },
        CONSULTANCY_DATA: {
            CONTENT_URL_ID: { consultancyData: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'consultancyData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.consultancyData',
                TRANSLATOR_DEFAULT: 'Consultancy data',
            },
        },
    },
};
