import React, { useState } from 'react';
import useTranslate from '@i18n/useTranslate';
import { useFormContext } from 'react-hook-form';
import Button from '@baseComponents/buttons/Button';
import { alertActions, errorActions, tableFilterActions } from '@states/actions';
import { isEmpty, isNil, toString } from 'lodash';
import { useDispatch } from 'react-redux';
import { soilAnalysisService } from '@services';
import { ModalFooter } from '@baseComponents/modals/modalNew';
import { createUseStyles } from 'react-jss';
import { ConfirmDialog, renderModal } from '@baseComponents/modals';

const useStyles = createUseStyles({
    saveButton: {
        marginRight: 15,
    },
});

export default function SoilAnalysisImportFooter(props) {
    const { hideModal } = props;
    const [isSaving, setIsSaving] = useState();
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();

    const { handleSubmit, getValues } = useFormContext();

    async function onSubmit(saveData) {
        try {
            const { date, soilAnalysisData, registryNumber, validityDate, laboratoryId, samplingArea } = saveData;

            let isValid = true;

            const isCultivationPeriodsValid = soilAnalysisData?.every(soilAnalysisRow => {
                const isCultivationPeriodValid = !isEmpty(soilAnalysisRow.cultivationPeriod) && !isNil(soilAnalysisRow.cultivationPeriod) && +soilAnalysisRow.cultivationPeriod.id > 0;
                const isDepthValid = +(soilAnalysisRow?.depth || 0) > 0;
                const isCodeValid = !isEmpty(toString(soilAnalysisRow.code)) && toString(soilAnalysisRow.code).length > 0;
                return isCultivationPeriodValid && isDepthValid && isCodeValid;
            });

            isValid = isValid
                && (!isEmpty(date) && !isNil(date))
                && (!isEmpty(registryNumber) && !isNil(registryNumber))
                && (!isEmpty(validityDate) && !isNil(validityDate))
                && (!isNil(laboratoryId) && +laboratoryId > 0)
                && (!isNil(samplingArea) && +samplingArea > 0)
                && isCultivationPeriodsValid;

            if (!isValid) {
                dispatch(alertActions.errorAlert(t('default.signedFieldsRequired', 'Signed fields are required!')));
                setIsSaving(false);
                return;
            }

            const cultivationPeriodIds = soilAnalysisData.map(soilAnalysis => soilAnalysis.cultivationPeriod)?.map(cultivationPeriod => cultivationPeriod.id);
            const attachedCultivationPeriods = await soilAnalysisService.getSoilAnalysisAttachedCutlivationPeriods({ cultivationPeriodId: cultivationPeriodIds, registryNumber: saveData.registryNumber });

            const attachhedData = {};
            soilAnalysisData?.forEach(soilAnalysis => {
                const attachmentKey = `${soilAnalysis.cultivationPeriod?.id}_${soilAnalysis.code}_${registryNumber}`;
                attachhedData[attachmentKey] = { ...soilAnalysis?.cultivationPeriod, code: soilAnalysis.code };
            });
            const existingAttachments = attachedCultivationPeriods.map(attachedCultivationPeriod => `${attachedCultivationPeriod.cultivationPeriodId}_${attachedCultivationPeriod.code}_${attachedCultivationPeriod.registryNumber}`);

            const alreadyExistingPeriods: any[] = [];
            Object.entries(attachhedData).forEach(([key, cultivationPeriod]) => {
                if (existingAttachments.includes(key)) {
                    alreadyExistingPeriods.push(cultivationPeriod);
                }
            });

            const soilAnaylsisDataToSave = {
                registryNumber: saveData.registryNumber,
                validityDate: saveData.validityDate,
                laboratoryId: saveData.laboratoryId,
                samplingArea: saveData.samplingArea,
                isExtendedExamination: saveData.isExtendedExamination,
                date: saveData.date,
                soilAnalysisData: saveData.soilAnalysisData,
                isManual: !saveData.isExcelImport,
            };

            if (!isEmpty(alreadyExistingPeriods)) {
                const periodNames = Array.from(new Set(alreadyExistingPeriods.map(period => period.name))).join(', ');
                const isIgnoreDuplicates = await new Promise((res, reject) => renderModal(ConfirmDialog, {
                    iconId: 'icon-help',
                    hideAfterCancel: true,
                    isAcceptCancelDialog: true,
                    acceptButtonText: t('soilAnalysisImport.saveAll', 'Save all data'),
                    cancelButtonText: t('soilAnalysisImport.ignoreDuplicates', 'Ignore duplicates'),
                    descriptionText: periodNames,
                    questionText: t('soilAnalysisImport.alreadyExistingAttachment', 'The following cultivation periods are already attached to the soil analysis. Do you want to save all data or ignore duplicates?'),
                    confirmCallback: () => res(false),
                    onClickCancel: () => res(true),
                }, false));

                if (isIgnoreDuplicates) {
                    soilAnaylsisDataToSave.soilAnalysisData = soilAnaylsisDataToSave.soilAnalysisData.filter(soilAnalysisRow => {
                        const attachmentKey = `${soilAnalysisRow.cultivationPeriod.id}_${soilAnalysisRow.code}_${registryNumber}`;
                        return !existingAttachments.includes(attachmentKey);
                    });
                }
            }

            if (isEmpty(soilAnaylsisDataToSave.soilAnalysisData)) {
                dispatch(alertActions.errorAlert(t('default.noDataToSave', 'No data to save')));
                setIsSaving(false);
                return;
            }

            const saveSoilAnalysisData = await soilAnalysisService.setSoilAnalysis(
                soilAnaylsisDataToSave,
            );
            if (!saveSoilAnalysisData) {
                dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed.')));
                setIsSaving(false);
                return;
            }
            dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved.')));
            dispatch(tableFilterActions.getTableData());
            hideModal();
        } catch (error) {
            dispatch(errorActions.setError(error, 'Soil analysis excel import'));
        }
    }

    function onClickSave() {
        if (!getValues('soilAnalysisData')) {
            dispatch(alertActions.errorAlert(t('default.noDataToSave', 'No data to save')));
        } else {
            handleSubmit(onSubmit)();
        }
    }

    return (
        <ModalFooter>
            <Button size="large" onClick={onClickSave} disabled={isSaving} className={classes.saveButton}>
                {t('default.save', 'Save')}
            </Button>
            <Button size="large" type="secondary" onClick={hideModal}>
                {t('default.cancel', 'Cancel')}
            </Button>
        </ModalFooter>
    );
}
