import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { simpleTableActions } from '@states/actions';
import { PAGE_DATA } from 'shared/src/constants';

export default function useGlobalSearchInput(props) {
    const { tableKey, searchInput } = props;

    const globalSearchValue = useSelector((state: any) => state.simpleTable.table[tableKey]?.globalSearchValue);
    const columns = useSelector((state: any) => state.simpleTable.table[tableKey]?.columns);
    const tableData = useSelector((state: any) => state.simpleTable.table[tableKey]?.tableData);
    const dispatch = useDispatch();

    //reset global search input value
    useEffect(() => {
        if (columns && tableData && dispatch && searchInput) {
            searchInput.current.value = null;
        }
    }, [columns, tableData, dispatch, searchInput]);

    //onKeyUp change value - with timer
    useEffect(() => {
        let currentGlobalSearchInputValue = '';
        let keyUpTimer = null;

        function onKeyUp(event) {
            clearTimeout(keyUpTimer);
            currentGlobalSearchInputValue = event.target.value;
            keyUpTimer = setTimeout(triggerChange, PAGE_DATA.SEARCH_INPUT_TIMEOUT);
        }

        async function triggerChange() {
            dispatch(simpleTableActions.setTableGlobalSearchValue(tableKey, currentGlobalSearchInputValue));
        }

        if (searchInput && !isEmpty(searchInput.current) && tableKey && dispatch) {
            const inputNode = searchInput.current;
            inputNode.addEventListener('keyup', onKeyUp);
        }
    }, [searchInput, tableKey, dispatch]);

    function changeGlobalSearchingEnabled() {
        searchInput.current.value = null;
        dispatch(simpleTableActions.setTableEnabledSearching(tableKey));
    }

    function clearSearhValue() {
        searchInput.current.value = '';
        dispatch(simpleTableActions.setTableGlobalSearchValue(tableKey, null));
    }

    return {
        showInputDeleteButton: !isEmpty(globalSearchValue),
        changeGlobalSearchingEnabled,
        clearSearhValue,
    };
}
