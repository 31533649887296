import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { errorActions } from '@states/actions';
import { cultivationPeriodsService } from '@services';
import { CULTIVATION_PERIODS } from 'shared/src/constants';
import { useNumberFormat } from '@common_modules/numberFormat';
import { isEmpty, isNull } from 'lodash';

export default function useCultivationPeriodsModalLoadData(props) {
    const { partfieldType, t, excludedIds, cultureId } = props;
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState<Array<any>>();
    const [tablePartfieldType, setTablePartfieldType] = useState(partfieldType || CULTIVATION_PERIODS.PARTFIELD_TYPE.PERIOD);
    const [periodYear, setPeriodYear] = useState();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const companyIds = useSelector((state: any) => state.company.activeCompanyIds);

    const { numberFormatter } = useNumberFormat();

    const [partfieldTypesList] = useState([
        {
            key: `${CULTIVATION_PERIODS.PARTFIELD_TYPE.PERIOD}`,
            value: t('cultivationPeriodTableFilter.periods', 'Periods'),
        },
        {
            key: `${CULTIVATION_PERIODS.PARTFIELD_TYPE.PERIOD_GROUP}`,
            value: t('cultivationPeriodTableFilter.periodGroups', 'Period groups'),
        },
    ]);

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                if (!periodYear) { return; }

                setIsLoading(true);

                const params = {
                    companyIds,
                    productionYearId: periodYear,
                    partfieldType: tablePartfieldType,
                    typeId: 3,
                    statusId: 1,
                    showClosedPeriod: 1,
                };
                const cultvationPeriodsResponse = await cultivationPeriodsService.getCultivationPeriods(params);

                const cultivationPeriods = cultvationPeriodsResponse?.map(period => ({
                    ...period,
                    sownArea: numberFormatter.getFormattedArea(+period.sownArea),
                    culture: tablePartfieldType === CULTIVATION_PERIODS.PARTFIELD_TYPE.PERIOD ? period.culture?.name : period.culture,
                    cultureVariety: tablePartfieldType === CULTIVATION_PERIODS.PARTFIELD_TYPE.PERIOD ? period.cultureVariety?.name : period.cultureVariety,
                }))?.filter(period => ((!isEmpty(excludedIds) ? !excludedIds?.includes(period?.id) : true) && (cultureId || isNull(cultureId) ? +period.cultureId === +cultureId : true)));

                if (!isCancelled) {
                    setTableData(cultivationPeriods);
                }
                setIsLoading(false);
            } catch (error) {
                dispatch(errorActions.setError(error, location));
                setIsLoading(false);
            }
        }

        if (companyIds) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [dispatch, periodYear, tablePartfieldType, setIsLoading, location, numberFormatter, excludedIds, companyIds, cultureId]);

    return {
        tableData,
        periodYear,
        setPeriodYear,
        tablePartfieldType,
        setTablePartfieldType,
        partfieldTypesList,
        isLoading,
    };
}
