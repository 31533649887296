import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import { routerHelper } from '@utils/router/RouterHelper';
import useTranslate from '@i18n/useTranslate';

const useStyles = createUseStyles({
    titleLabel: {
        fontWeight: 'bold',
        fontStyle: 'normal',
        display: 'inline-block',
        verticalAlign: 'middle',
        textTransform: props => (props.ignoreTitleFormatter && 'lowercase'),
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
});

export default function HeaderTitle(props) {
    const { isSettingsContentActiv, className } = props; //TODO: php - hibrid
    const { t } = useTranslate();
    const location = useLocation().pathname;
    const locationParam = useLocation().search;
    const [ignoreTitleFormatter, setIgnoreTitleFormatter] = useState(false);
    const classes = useStyles({ ignoreTitleFormatter });

    function createHeaderTitle() {
        if (routerHelper.isHomePage(location)) {
            return t('pageTitle.home', 'Home');
        }

        if (isSettingsContentActiv) { //TODO: php - hibrid
            return t('sideMenuTitle.settings', 'Settings');
        }
        return routerHelper.getContentTitleByUrl(t, location, locationParam);
    }

    useEffect(() => {
        if (location) {
            const contentObject = routerHelper.getContentObjectByUrl(location);
            if (contentObject && contentObject.TITLE) {
                setIgnoreTitleFormatter(contentObject.TITLE.IGNORE_FORMATTER || false);
            }
        }
    }, [location]);

    return (
        <h1 className={clsx(classes.titleLabel, className)}>{createHeaderTitle()}</h1>
    );
}
