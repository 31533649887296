import { useState, useEffect } from 'react';
import useTranslate from '@i18n/useTranslate';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { WORKOPERATION } from 'shared/src/constants';
import { Area } from '@baseComponents/formatters';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    multilineWithApprovalAndAreaCellContainer: {
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
        height: theme.table.tableRowHeight,
        minWidth: 180,
    },
    firstLine: {
        display: 'block',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        height: 16,
        lineHeight: '16px',
        fontSize: 13,
        fontWeight: 500,
        color: theme.color.jet,
        ...theme.overFlowText,
    },
    secondLine: {
        display: 'flex',
        width: '100%',
        height: 16,
        lineHeight: '16px',
        fontSize: 13,
        fontWeight: 'normal',
        color: theme.color.raven,
        ...theme.overFlowText,
    },
    secondLineText: {
        display: 'inline-block',
        fontSize: 13,
        fontWeight: 'normal',
        color: theme.color.raven,

        overflowX: 'hidden',
        overflowY: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    area: {
        display: 'inline-block',
        marginLeft: 5,
        paddingLeft: 5,
        borderLeft: `1px solid ${theme.color.pearl}`,
    },
    textColumn: {
        display: 'inline-flex',
        width: '100%',
        minWidth: 180,
        flexDirection: 'column',
    },
    iconColumn: {
        display: 'inline-block',
        visible: (props: any) => !props.isApproval && 'none',
        marginRight: 12,
        width: 26,
        minWidth: 26,
        height: 32,
        lineHeight: '32px',
    },
    icon: {
        height: 26,
        width: 26,
    },
    areaCell: {
        fontWeight: 'normal',
    },

}));

export default function MultilineWithApprovalAndAreaCell(props) {
    const { rowData, approvalId, firstLineId, secondLineFirstId, secondLineId, areaId } = props;
    const { t, translater } = useTranslate();
    const [isApproval, setApproval] = useState(false);
    const classes = useStyles({ isApproval } as any);

    useEffect(() => {
        if (approvalId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, approvalId);

            if (!isEmpty(columnItem) && rowData) {
                setApproval(true);
            }
        }
    }, [approvalId, rowData]);

    function getApprovalSvg() {
        if (approvalId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, approvalId);
            return columnItem;
        }
        return null;
    }

    function getFirstLineText() {
        if (firstLineId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, firstLineId);
            return columnItem;
        }
        return null;
    }

    function getSecondLineText() {
        if (secondLineId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, secondLineId);
            return columnItem;
        }
        return null;
    }

    function getSecondLineFirstText() {
        if (secondLineFirstId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, secondLineFirstId);
            return columnItem;
        }
        return null;
    }

    function getAreaText() {
        if (secondLineId && areaId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, areaId);
            return +columnItem;
        }
        return null;
    }

    function getCostCenterUnit() {
        if (Number(getSecondLineFirstText()) > 1) {
            switch (rowData.typeId) {
                case WORKOPERATION.TYPE_ID.TABLE_WORK: {
                    return `${getSecondLineFirstText()} ${t('default.cultures', 'cultures')}`;
                }
                case WORKOPERATION.TYPE_ID.ECONOMIC_UNIT_WORK: {
                    return `${getSecondLineFirstText()} ${t('default.types', 'types')}`;
                }
                case WORKOPERATION.TYPE_ID.WAGE_WORK: {
                    return `${getSecondLineFirstText()} ${t('default.partners', 'partners')}`;
                }
                default:
                    return '';
            }
        } else {
            return getSecondLineText();
        }
    }

    return (
        <div className={classes.multilineWithApprovalAndAreaCellContainer}>
            <div className={classes.iconColumn} title={!isEmpty(getApprovalSvg()) ? translater('default.approved', 'Approved') : ''}>
                {!isEmpty(getApprovalSvg()) && rowData && <Svg iconId="icon-done_circle" style={classes.icon} /> }
            </div>
            <div className={classes.textColumn}>
                <div className={classes.firstLine}>
                    <span title={getFirstLineText()}>
                        {getFirstLineText()}
                    </span>
                </div>
                <div className={classes.secondLine}>
                    <div className={classes.secondLineText}>
                        <span title={getCostCenterUnit()}>
                            {getCostCenterUnit()}
                        </span>
                    </div>
                    {areaId && getAreaText()
                        ? (
                            <div className={classes.area}>
                                <Area value={getAreaText()} style={classes.areaCell} />
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    );
}
