import { createUseStyles } from 'react-jss';
import { isFunction } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import Separator from './Separator';

const useStyles = createUseStyles((theme: any) => ({
    multiselectHeaderWrapper: {
        marginTop: '0.3rem',
        marginLeft: '0.3rem',
        marginRight: '0.3rem',
    },
    multiselectHeaderButtonWrapper: {
        display: 'inline-flex',
        width: '100%',
    },
    multiselectHeaderButton: {
        display: 'inline-block',
        marginRight: 5,
        fontSize: 14,
        boxShadow: 'none !important',
        border: 'none',
        backgroundColor: 'white',
        '&:hover': {
            color: theme.color.main,
            textDecoration: 'underline',
        },
    },
    multiselectHeaderSeparator: {
        margin: '0.3rem',
    },
}));

export default function MultiselectHeader(props) {
    const { selectAll, clearSelection } = props;
    const { t } = useTranslate();
    const classes = useStyles();

    function onClickAllButton() {
        if (isFunction(selectAll)) {
            selectAll();
        }
    }

    function onClickClearButton() {
        if (isFunction(clearSelection)) {
            clearSelection();
        }
    }

    return (
        <div className={classes.multiselectHeaderWrapper}>
            <div className={classes.multiselectHeaderButtonWrapper}>
                <button onClick={onClickAllButton} type="button" className={classes.multiselectHeaderButton}>
                    {t('default.all', 'All')}
                </button>
                <button onClick={onClickClearButton} type="button" className={classes.multiselectHeaderButton}>
                    {t('default.nothing', 'Nothing')}
                </button>
            </div>
            <Separator className={classes.multiselectHeaderSeparator} />
        </div>
    );
}
