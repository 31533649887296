import { routerHelper } from '@utils/router/RouterHelper';
import { dateTimeUtils } from 'shared/src/modules';
import { sendServerRequest } from './baseService';

export const stockService = {
    getReleaseStockWarehousesByProducts,
    getProductsFromStock,
};

//kiadó raktárak termékek alapján
async function getReleaseStockWarehousesByProducts(props) {
    const { date = dateTimeUtils.getDefaultDateTimeNow(), isReturnTransfer = false } = props || {};
    const defaultDateFormat = dateTimeUtils.getDefaultDateTimeFormat(date, true);
    const url = routerHelper.getUrlWithParameters('/api/stock/getReleaseStockWarehousesByProducts', { ...props, date: defaultDateFormat, isReturnTransfer });
    const response = await sendServerRequest('GET', url);
    return response;
}

//termékek készletről
async function getProductsFromStock(props) {
    //companyIds, warehouseId, partnerId, date, showPesticideProduct, typeId, treatmentUnitTypeIds, hasWeightShiftNumber, notPlantProtectedProducts, affectedByStockRecords, recordsInManagementUnit
    const url = routerHelper.getUrlWithParameters('/api/stock/getProductsFromStock', props || {});
    const response = await sendServerRequest('GET', url);
    return response;
}
