import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AddCulture from '@customComponents/culture/AddCulture';
import AddSupport from '@customComponents/support/AddSupport';
import AddRegistryNumber from '@customComponents/registryNumber/AddRegistryNumber';
import AddBasicFields from '@customComponents/basicFields/AddBasicFields';
import AddInsurance from '@customComponents/insurance/AddInsurance';
import ModalWindow from '@baseComponents/modals/ModalWindow';
import { useCompanyPermission } from '@common_modules/permission';
import useLoadEditCultivationPeriodData from './useLoadEditCultivationPeriodData';

export default function NewCultivationPeriodDataWindows(props) {
    const { setIsFadeOn, isEdit, periodYearId, cultivationPeriodId, isFadeOn, setCultivationPeriodValues, setFormInitialValues } = props;
    const dispatch = useDispatch();

    const [windowContent, setWindowContent] = useState(false);
    const [cropExpanded, setCropExpanded] = useState(false);
    const [supportExpanded, setSupportExpanded] = useState(false);
    const [basicFieldsExpanded, setBasicFieldsExpanded] = useState(false);
    const [registryNumberExpanded, setRegistryNumberExpanded] = useState(false);
    const [insuranceDataExpanded, setInsuranceDataExpanded] = useState(false);
    const { companyPermissions } = useCompanyPermission();

    const { loadSupportList, loadBasicFieldsList, loadRegistryNumberList } = useLoadEditCultivationPeriodData({ periodYearId, cultivationPeriodId, setCultivationPeriodValues, setFormInitialValues });

    useEffect(() => {
        if (dispatch && !isFadeOn) {
            setWindowContent(false);
            setBasicFieldsExpanded(false);
            setRegistryNumberExpanded(false);
            setSupportExpanded(false);
            setCropExpanded(false);
            setInsuranceDataExpanded(false);
        }
    }, [setIsFadeOn, dispatch, isFadeOn]);

    function toggleWindow(value, setOpenWindow, state) {
        setWindowContent(value);
        setOpenWindow(state);
        setIsFadeOn(state);
        if (basicFieldsExpanded) {
            setBasicFieldsExpanded(false);
        }
        if (registryNumberExpanded) {
            setRegistryNumberExpanded(false);
        }
        if (supportExpanded) {
            setSupportExpanded(false);
        }
        if (cropExpanded) {
            setCropExpanded(false);
        }
        if (insuranceDataExpanded) {
            setInsuranceDataExpanded(false);
        }
    }

    function closeWindow(setShowWindow) {
        setShowWindow(false);
        setWindowContent(false);
        setIsFadeOn(false);
    }

    return (
        <>
            <AddBasicFields
                basicFieldsExpanded={basicFieldsExpanded}
                closeBasicFields={() => closeWindow(setBasicFieldsExpanded)}
                toggle={value => toggleWindow(value, setBasicFieldsExpanded, !basicFieldsExpanded)}
                loadedBasicFieldsList={!isEdit || !loadBasicFieldsList}
                isEdit={isEdit}
            />

            <AddCulture
                cropExpanded={cropExpanded}
                closeCrop={() => closeWindow(setCropExpanded)}
                toggle={value => toggleWindow(value, setCropExpanded, !cropExpanded)}
                setFormInitialValues={setFormInitialValues}
                cultivationPeriodId={cultivationPeriodId}
                periodYearId={periodYearId}
            />

            <AddSupport
                supportExpanded={supportExpanded}
                closeSupport={() => closeWindow(setSupportExpanded)}
                toggle={value => toggleWindow(value, setSupportExpanded, !supportExpanded)}
                loadedSupportList={!isEdit || !loadSupportList}
                isEdit={isEdit}
            />

            <AddRegistryNumber
                registryNumberExpanded={registryNumberExpanded}
                closeRegistryNumber={() => closeWindow(setRegistryNumberExpanded)}
                toggle={value => toggleWindow(value, setRegistryNumberExpanded, !registryNumberExpanded)}
                loadedRegistryNumberList={!isEdit || !loadRegistryNumberList}
                setFormInitialValues={setFormInitialValues}
                isEdit={isEdit}
            />

            {companyPermissions.hasCultivationPeriodBankAndInsurer() ? (
                <AddInsurance
                    insuranceDataExpanded={insuranceDataExpanded}
                    closeInsuranceData={() => closeWindow(setInsuranceDataExpanded)}
                    toggle={value => toggleWindow(value, setInsuranceDataExpanded, !insuranceDataExpanded)}
                    setFormInitialValues={setFormInitialValues}
                />
            ) : null}

            <ModalWindow windowContent={windowContent} />

        </>
    );
}
