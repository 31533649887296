import { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { PAGE_DATA } from 'shared/src/constants';
import { stringUtils } from 'shared/src/modules';
import { isEmpty, isNumber, isFunction, isNull, debounce } from 'lodash';
import clsx from 'clsx';

import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        height: (props: any) => `${props.height}px`,
        lineHeight: (props: any) => `${props.height}px`,
        width: (props: any) => (props.showSearchInput ? 'auto' : `${props.height}px`),
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        transition: 'width 0.5s',
        borderRadius: 4,
        color: theme.color.jet,
        backgroundColor: theme.color.white,
        boxShadow: '0 2px 3px 0 rgba(175, 180, 182, 0.15)',
        border: '1px solid',
        borderColor: (props: any) => (props.isInvalid && !props.hasInputValue ? theme.color.destructive : theme.color.gray),
        '&:focus-within': {
            boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15)',
            border: `1px solid ${theme.color.main}`,
            color: theme.color.jet,
        },
    },
    searchButton: {
        border: 'none',
        height: (props: any) => `${props.height}px` || 40,
        lineHeight: (props: any) => `${props.height}px` || '40px',
        width: (props: any) => `${props.height}px` || 40,
        minWidth: (props: any) => `${props.height}px` || 40,
        borderRadius: 4,
        alignSelf: 'flex-end',
        background: 'transparent',
        '&:focus': {
            outline: 'none',
        },
    },
    searchIcon: {
        height: 20,
        width: 20,
        fill: theme.color.stone,
        objectFit: 'contain',
        '&:hover': {
            cursor: (props: any) => props.isCollapsible && 'pointer',
        },
    },
    input: {
        lineHeight: '100%',
        height: 'auto',
        width: (props: any) => {
            if (props.showSearchInput) {
                if (props.hasInputValue) {
                    return 'calc(100% - 48px)';
                }
                return props.height ? `calc(100% - ${props.height}px)` : 'calc(100% - 40px)';
            }
            return 0;
        },
        transition: 'width 0.5s',
        borderRadius: 4,
        border: 'none',
        fontSize: 14,
        fontWeight: 300,
        textAlign: 'left',
        padding: 0,
        '&:focus': {
            outline: 'none',
        },
        '&::-webkit-search-cancel-button': {
            '-moz-appearance': 'none',
            '-webkit-appearance': 'none',
        },
    },
    deleteButton: {
        width: 16,
        height: 'auto',
        cursor: 'pointer',
        padding: 0,
        border: 'none',
        background: 'transparent',
        lineHeight: 1,
        ...theme.disableOutline,
        marginRight: 4,
    },
    deleteIcon: {
        height: 16,
        width: 16,
    },
}));

export default function SearchBar(props) {
    const { currentSearchValue, setSearchValue, className, height = 40, isCollapsible = false, isCollapsed = false, onChange, tableData, columnId, isSearchIcon = true, isInvalid, placeholder, onDelete } = props;
    const { t } = useTranslation();
    const [showSearchInput, setShowSearchInput] = useState(!isCollapsed);
    const [isUnfocusing, setIsUnfocusing] = useState(false);
    const [isClosingInprogress, setIsClosingInprogress] = useState(false);
    const [isVisibleClearButton, setIsVisibleClearButton] = useState(false);
    // const [resetTimeout, setResetTimeout] = useState(false);
    const searchInputRef = useRef<any>();
    const globalColumnSearchValues = useSelector((state: any) => state.basePage.table.globalColumnSearchValues);
    // let keyUpTimer = null;
    const classes = useStyles({ height, showSearchInput, isCollapsible, hasInputValue: hasInputValue(), isInvalid } as any);

    //reset input text
    useEffect(() => {
        if (isNull(globalColumnSearchValues) && !isEmpty(searchInputRef?.current?.value)) {
            searchInputRef.current.value = '';
        }
    }, [globalColumnSearchValues]);

    useEffect(() => {
        if (currentSearchValue) {
            searchInputRef.current.value = currentSearchValue;
        }
    }, [currentSearchValue]);

    //sync table group header and fixed header
    useEffect(() => {
        if (globalColumnSearchValues && columnId && searchInputRef && !isEmpty(searchInputRef.current)) {
            if (!isEmpty(globalColumnSearchValues)) {
                Object.entries(globalColumnSearchValues).forEach(([key, value]) => {
                    const currentValue = searchInputRef.current.value;
                    if (key === columnId && !stringUtils.isSame(currentValue, value)) {
                        searchInputRef.current.value = value;
                    }
                });
            }
        }
    }, [globalColumnSearchValues, columnId]);

    useEffect(() => {
        if (isEmpty(currentSearchValue)) {
            searchInputRef.current.value = '';
        }
    }, [tableData, currentSearchValue]);

    function searchButtonClick() {
        if (isCollapsible) {
            if (isUnfocusing) {
                setIsUnfocusing(false);
            }

            if (!showSearchInput && searchInputRef) {
                searchInputRef.current.focus();
            } else {
                deleteSearch();
            }

            setIsClosingInprogress(false);
            setShowSearchInput(!showSearchInput);
        }
    }

    function deleteSearch(focusInput?: any) {
        if (searchInputRef && searchInputRef.current) {
            searchInputRef.current.value = '';

            if (setSearchValue) {
                setSearchValue(null);
            }

            if (focusInput) {
                searchInputRef.current.focus();
            }
            setSearchValueTimer();

            if (isFunction(onDelete)) {
                onDelete();
            }

            setIsVisibleClearButton(false);
        }
    }

    const delayedQuery = useMemo(() => debounce(searchValue => {
        onChange(searchValue);
        if (setSearchValue) {
            setSearchValue(searchValue);
        }
        if (!isEmpty(searchValue) || isNumber(searchValue)) {
            setIsVisibleClearButton(true);
        } else {
            setIsVisibleClearButton(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, PAGE_DATA.SEARCH_INPUT_TIMEOUT), [onChange]);

    function searchBarOnKeyUp() {
        const inputValue = searchInputRef && searchInputRef.current ? searchInputRef.current.value : null;
        delayedQuery(inputValue);
        // if (resetTimeout === false) {
        //     setResetTimeout(true);
        //     keyUpTimer = setTimeout(setSearchValueTimer, PAGE_DATA.SEARCH_INPUT_TIMEOUT);
        // } else {
        //     clearTimeout(keyUpTimer);
        // }
    }

    function setSearchValueTimer() {
        const inputValue = searchInputRef && searchInputRef.current ? searchInputRef.current.value : null;
        // setResetTimeout(false);
        if (setSearchValue) {
            setSearchValue(inputValue);
        }
        if (onChange) {
            onChange(inputValue);
        }
    }

    function onLostFocus() {
        if (isCollapsible && searchInputRef && searchInputRef.current && !searchInputRef.current.value.length) {
            setIsUnfocusing(true);
            if (!isClosingInprogress) {
                setShowSearchInput(false);
            }
        }
    }

    function hasInputValue() {
        return (searchInputRef && searchInputRef.current && !isEmpty(searchInputRef.current.value) && showSearchInput) || !!currentSearchValue || isVisibleClearButton;
    }

    return (
        <div className={clsx(classes.container, className)}>
            {isSearchIcon && (
                <button type="button" className={classes.searchButton} onClick={searchButtonClick} onMouseDown={() => setIsClosingInprogress(true)}>
                    <Svg iconId="icon-search" style={classes.searchIcon} />
                </button>
            )}
            <input
                placeholder={placeholder || `${t('default.search', 'Search')}...`}
                type="search"
                className={classes.input}
                onChange={searchBarOnKeyUp}
                ref={searchInputRef}
                onBlur={onLostFocus}
            />

            {hasInputValue() && showSearchInput && (
            <button type="button" className={classes.deleteButton} onClick={() => deleteSearch(true)}>
                <Svg iconId="icon-close" style={classes.deleteIcon} />
            </button>
            )}
        </div>
    );
}
