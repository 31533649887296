import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isFunction, isEmpty } from 'lodash';
import Button from '@baseComponents/buttons/Button';

const useStyles = createUseStyles((theme: any) => ({
    iconButtonWrapper: {
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
        paddingRight: 5,
        cursor: (props: any) => (props.isDisabled ? 'default' : 'pointer'),
    },
    iconButton: {
        background: (props: any) => props.backgroundColor && props.backgroundColor,
        '&:hover': {
            background: (props: any) => props.backgroundColor && props.backgroundColor,
        },
        cursor: (props: any) => !props.onClick && 'default !important',
        boxShadow: (props: any) => {
            if (props.isDisabled) {
                return 'none';
            }
        },
        width: '100%',
        '& div': {
            padding: 0,
        },
        '& svg': {
            marginRight: 0,
            fill: (props: any) => props.iconColor || theme.color.gray,
        },
    },
}));

export default function IconButtonCell(props) {
    const { rowData, columnId, className, onClick, getCellTitle, getIcon, getIconColor, getBackgroundColor, icon, onAddButtonClicked, type = 'secondary', getIsDisabled, isUseRefreshCallback } = props;
    const classes = useStyles({
        onClick,
        iconColor: isFunction(getIconColor) && getIconColor(rowData),
        backgroundColor: isFunction(getBackgroundColor) && getBackgroundColor(rowData),
        isDisabled: isFunction(getIsDisabled) ? getIsDisabled(rowData) : false,
    });
    const [isDisabledOnClick, setIsDisabledOnClick] = useState(false);
    const refreshCellCallback = () => {
        setIsDisabledOnClick(false);
    };

    function ignoreRowSelection(event) {
        event.stopPropagation();
    }

    function onClickButton() {
        if (isFunction(onClick) && !isDisabledOnClick) {
            onClick(rowData, onAddButtonClicked || refreshCellCallback);

            if (isFunction(refreshCellCallback) && isUseRefreshCallback) {
                setIsDisabledOnClick(true);
            }
        }
    }

    function getButtonText() {
        if (!isEmpty(rowData) && !isEmpty(columnId)) {
            return rowData[columnId];
        }
        return null;
    }

    if (!isFunction(getIcon) && isEmpty(icon)) {
        return null;
    }

    return (
        <div className={clsx(classes.iconButtonWrapper, className)} onClick={ignoreRowSelection} onKeyDown={ignoreRowSelection} role="button" tabIndex={0}>
            <Button
                className={classes.iconButton}
                ignoreOtherClick
                size="small"
                type={type}
                iconId={isFunction(getIcon) ? getIcon(rowData) : icon}
                iconPosition="before"
                onClick={onClickButton}
                title={isFunction(getCellTitle) ? getCellTitle(rowData) : null}
                disabled={(isFunction(getIsDisabled) && getIsDisabled(rowData)) || isDisabledOnClick}
            >
                {getButtonText()}
            </Button>
        </div>
    );
}
