import useTranslate from '@i18n/useTranslate';
import BaseConfirmationModal from './BaseConfirmationModal';

type ConfirmDialogProps = {
    confirmCallback?: any,
    buttonText?: any,
    descriptionText?: any,
    questionText?: any,
    buttonType?: any,
    iconId?: any,
    reference?: any,
    hideModal?: any,
    isAcceptCancelDialog?: any,
    acceptButtonText?: any,
    cancelButtonText?: any,
    onClickCancel?: any,
    hideAfterCancel?: boolean,
    isHtmlDescription?: boolean,
};

export default function ConfirmDialog(props: ConfirmDialogProps) {
    const { t } = useTranslate();
    const {
        confirmCallback, buttonText = t('default.confirmSave', 'Confirm save'),
        descriptionText,
        questionText = t('default.questionSave', 'Are you sure you save data?'),
        buttonType = 'primary',
        iconId,
        reference,
        hideModal,
        isAcceptCancelDialog = false,
        acceptButtonText = t('default.save', 'Save'),
        cancelButtonText = t('default.cancel', 'Cancel'),
        onClickCancel,
        hideAfterCancel = false,
        isHtmlDescription = false,
    } = props;

    return (
        <BaseConfirmationModal
            buttonType={buttonType}
            buttonText={buttonText}
            questionText={questionText}
            descriptionText={descriptionText}
            iconId={iconId}
            hideModal={hideModal}
            handleConfirmClicked={confirmCallback}
            reference={reference}
            isAcceptCancelDialog={isAcceptCancelDialog}
            acceptButtonText={acceptButtonText}
            cancelButtonText={cancelButtonText}
            onClickCancel={onClickCancel}
            hideAfterCancel={hideAfterCancel}
            isHtmlDescription={isHtmlDescription}
        />
    );
}
