export const PRODUCT = {
    TREATMENT_UNIT: {
        RECORDING_IN_TREATMENT_UNIT_RELATION_REQUIRED: 1,
        RECORDING_IN_TREATMENT_UNIT_RELATION_DENIED: 3,
    },
    ECONOMIC_UNIT: {
        ECONOMIC_UNIT_RELATION_DENIED: 1,
        ECONOMIC_UNIT_RELATION_REQUIRED: 3,
    },
    ACTIVE_STATUS: {
        INACTIVE: 0,
        ACTIVE: 1,
        ALL: 2,
    },
    TREATMENT_UNIT_TYPE: {
        NON_TREATMENT_UNIT: 0,
        CONTAINER_NUMBER: 1,
        LOT_NUMBER: 2,
        PRODUCTION_NUMBER: 3,
        LOCK_NUMBER: 4,
    },
    TREATMENT_UNIT_SUBDIVISION_METHOD: {
        PIECE: 1,
        AVARAGE_WEIGHT: 2,
    },
    STOCK_TYPE: {
        WORKOPERATION: 1,
        WEIGHNING: 2,
        FOREIGN_STOCK: 3,
    },
    PRICING_MODE: {
        ACCOUNTING: 1,
        PURCHASE: 2,
    },
    PRODUCT_MAIN_TYPE: {
        PLANT_PROTECTING: 1,
    },
    TYPE_LIMIT: {
        PLANT_PROTECTION_MAX: 8,
        NUTRIENT_MIN: 11,
        NUTRIENT_MAX: 12,
        SEED: 14,
    },
    TYPE_ID: {
        FERTILIZER: 11, //Műtrágya
        MANURE: 12, //Szervestrágya
    },
    STOCK_MOVEMENT_ID: {
        INCOME: 1,
        RELEASE: 2,
        INVENTORY: 3,
    },
};
