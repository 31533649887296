import React from 'react';
import { createUseStyles } from 'react-jss';
import Button from '@baseComponents/buttons/Button';
import { isNumber } from 'lodash';

const useStyles = createUseStyles({
    serialNumberEditButtonCell: {
        display: 'flex',
        flexDirection: 'row',
    },
    serialNumber: {
        margin: 'auto',
        marginLeft: 0,
        marginRight: 5,
        fontSize: 15,
        fontWeight: 600,
    },
    orderDescButton: {
        width: 36,
        height: 36,
        '& button': {
            height: '100%',
            width: '100%',
        },
        '& .content': {
            padding: 0,
        },
    },
    orderAscButton: {
        width: 36,
        height: 36,
        '& button': {
            height: '100%',
            width: '100%',
        },
        '& .content': {
            padding: 0,
        },
    },
});

export default function SerialNumberEditButtonCell(props) {
    const { cellValue, firstSerialNumber = 1, rowCount, onAfterChange, onChange /*cellData, updateValue,*/ } = props;
    const classes = useStyles();

    function onClickDesc() {
        const newValue = (cellValue + 1);
        if (onChange) {
            onChange(cellValue, newValue);
            return;
        }

        if (onAfterChange && newValue <= rowCount) {
            onAfterChange(cellValue, `${newValue}_serialNumber`, false);
            onAfterChange(newValue, `${cellValue}_serialNumber`, true);
        }
        /*if (updateValue) {
            updateValue({ currentValue: cellValue, newValue: cellValue + 1, onAfterChange });
        }*/
    }

    function onClickAsc() {
        const newValue = (cellValue - 1);
        if (onChange) {
            onChange(cellValue, newValue);
            return;
        }

        if (onAfterChange && newValue > 0) {
            onAfterChange(cellValue, `${newValue}_serialNumber`, false);
            onAfterChange(newValue, `${cellValue}_serialNumber`, true);
        }
        /*if (updateValue) {
            updateValue({ currentValue: cellValue, newValue: cellValue - 1, cellData, onAfterChange });
        }*/
    }

    if (!isNumber(cellValue)) {
        return null;
    }

    return (
        <div className={classes.serialNumberEditButtonCell}>
            <div className={classes.serialNumber}>
                {cellValue}.
            </div>
            {/* Növeli  a sorszámot */}
            <div className={classes.orderAscButton}>
                {cellValue > firstSerialNumber && (
                    <Button size="small" type="secondary" iconId="icon-order_asc" onClick={onClickAsc} />
                )}
            </div>
            {/* Csökkenti  a sorszámot */}
            <div className={classes.orderDescButton}>
                {cellValue < rowCount && (
                    <Button size="small" type="secondary" iconId="icon-order_desc" onClick={onClickDesc} />
                )}
            </div>
        </div>
    );
}
