import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { useNumberFormat } from '@common_modules/numberFormat';
import clsx from 'clsx';
import { isNull } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    moneyFormatterBaseStyle: {
        fontSize: 12,
        fontWeight: 500,
        color: theme.color.jet,
        display: 'block',
        verticalAlign: 'middle',
        width: '100%',
        textAlign: 'right',
        ...theme.overFlowText,
    },
}));

export default function Money(props) {
    const { value, style, className, perUnit, unitPriceDecimals, cellId } = props;
    const classes = useStyles();

    const isSelectedCurrencySwitch = useSelector((state: any) => state.company.company.currency.isSelectedCurrencySwitch);
    const { numberFormatter } = useNumberFormat();

    function getMoneyValueWithCurrencyText() {
        let valueText = '';
        let unitPrice = false;

        if (perUnit || unitPriceDecimals) {
            unitPrice = true;
        }

        if (isSelectedCurrencySwitch) { // secondary
            valueText = numberFormatter.getFormattedSecondaryCurrency(value, true, unitPrice);
        } else { // primary
            valueText = numberFormatter.getFormattedPrimaryCurrency(value, true, unitPrice);
        }

        if (isNull(valueText)) {
            valueText = '0';
        }

        if (perUnit) {
            valueText = `${valueText}/${perUnit}`;
        }

        return valueText;
    }

    return (
        <div className={clsx(classes.moneyFormatterBaseStyle, style, className)} title={getMoneyValueWithCurrencyText()} id={cellId}>
            {getMoneyValueWithCurrencyText()}
        </div>
    );
}
