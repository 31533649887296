import Button from '@baseComponents/buttons/Button';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { useLayerCreateContext } from '@map/services/layerCreate/layerCreateContext';
import useTranslate from '@i18n/useTranslate';
import { ThemeType, TranslateType } from '@map/services/mapTypes';
import { alertActions } from '@states/actions';
import { useDispatch } from 'react-redux';
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { LayerModalContainer, LayerModalContent, LayerModalHeader } from './LayerModal.styles';
import { LayerUploadSteps } from './modalSteps.enum';
import { ModalStepProps } from './types';
import { useMapGetOnFileUploadError } from '@map/services/mapHooks';

type Classes = 'container' | 'browseWrapper' | 'description' | 'labelButton';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        width: '100%',
        height: '100%',
        border: `3px dashed ${theme.color.pearl}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    browseWrapper: {
        maxWidth: 300,
    },
    description: {
        maxWidth: 300,
        textAlign: 'center',
        marginBottom: 15,
        fontSize: 14,
    },
    labelButton: {
        pointerEvents: 'none',
    },
}));

export default function LayerUploadModalBrowse({ setStep, close }: ModalStepProps) {
    const [store, controller] = useLayerCreateContext();
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t, translater }: TranslateType = useTranslate();

    const [isLoading, setIsLoading] = useState<undefined | boolean>();

    const onFileUploadError = useMapGetOnFileUploadError();

    async function onFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files[0]) {
            setIsLoading(true);

            const isValidFile = await controller.uploadLayerFile(event.target.files[0], onFileUploadError);

            if (!isValidFile) {
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        if (store.upload.featureCollection) {
            setIsLoading(false);
            setStep(LayerUploadSteps.FILES);
        }
    }, [store.upload.featureCollection, setStep]);

    return (
        <LayerModalContainer>
            <LayerModalHeader
                title={translater<string>('map.uploadFileText', 'Upload file')}
                close={close}
            />
            <LayerModalContent>
                <LoadSpinner value={isUndefined(isLoading) || !isLoading} />
                <div className={classes.container}>
                    <p className={classes.description}>
                        {translater<string>('map.uploadFileHintText', 'Drop file here or use the browse button.')}
                    </p>
                    <input
                        id="layer-upload"
                        type="file"
                        hidden
                        onChange={onFileChange}
                    />
                    <label htmlFor="layer-upload" style={{ cursor: 'pointer' }}>
                        <Button
                            title={t('map.browseText', 'Browse')}
                            className={classes.labelButton}
                        >
                            {t('map.browseText', 'Browse')}
                        </Button>
                    </label>
                </div>
            </LayerModalContent>
        </LayerModalContainer>
    );
}
