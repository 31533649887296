import { registerLocale } from 'react-datepicker';
import { LANGUAGE } from 'shared/src/constants';
import { isEmpty, isString, lowerCase } from 'lodash';
import { errorActions } from '@states/actions/errorActions';
import * as locales from 'date-fns/locale';

export default function registerLocales(dispatch) {
    try {
        const langKeys = Object.entries(LANGUAGE.LANGUAGE_CODE);
        langKeys?.forEach(async langObject => {
            const [langKey, langCode] = langObject;
            if (!isEmpty(langKey) && isString(langCode) && !isEmpty(langCode)) {
                const locale = locales[langCode];
                if (locale) {
                    registerLocale(lowerCase(langKey), locale);
                }
            }
        });
    } catch (error) {
        dispatch(errorActions.setError(error, 'registerLocales'));
    }
}
