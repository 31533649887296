import Button from '@baseComponents/buttons/Button';
import OtherFunctionsCell from '@baseComponents/tableNew/cellTypes/otherFunctionsCell/OtherFunctionsCell';
import { useOnClickOutside } from '@flowsCommon/services/hooks';
import useTranslate from '@i18n/useTranslate';
import { Layer, LayerType, ThemeType, TranslateType } from '@map/services/mapTypes';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { LayerFolderEditMode } from './LayerFolderEdit.enum';
import { LayerFolderPopup } from './LayerFolderPopup';

type Tprops = {
    setEditPanelMode: (mode: LayerFolderEditMode) => void;
    layer: Layer
}

export function LayerFolderMoreOptions({ setEditPanelMode, layer }: Tprops) {
    const [otherFunctionList, setOtherFuntionList] = useState<any[]>([]);
    const { t, translater }: TranslateType = useTranslate();

    useEffect(() => {
        if (!isEmpty(otherFunctionList)) {
            return;
        }

        const selectEditMode = (mode: LayerFolderEditMode) => {
            setEditPanelMode(mode);
        };
        const isCreateDisabled = () => {
            if (layer.id === -1) {
                return true;
            }
            if (layer.type !== LayerType.FOLDER) {
                return true;
            }
            return false;
        };
        const isEditDisabled = () => {
            if (layer.readonly) {
                return true;
            }
            return false;
        };

        const otherFunctions: any[] = [

            {
                iconId: 'icon-edit',
                title: t('map.layerMoreOptionEdit', 'Edit'),
                onClick: () => {
                    selectEditMode(LayerFolderEditMode.EDIT);
                    document.body.click();
                },
                isDisabled: isEditDisabled,
            },
            {
                iconId: 'icon-bin',
                title: t('map.layerMoreOptionDelete', 'Delete'),
                onClick: () => {
                    selectEditMode(LayerFolderEditMode.DELETE);
                    document.body.click();
                },
                isDisabled: isEditDisabled,
                type: 'destructive',
            },
        ];

        if (layer.type === LayerType.FOLDER) {
            const addItem = {
                iconId: 'icon-add',
                title: t('map.layerMoreOptionCreate', 'Create'),
                onClick: () => {
                    selectEditMode(LayerFolderEditMode.CREATE);
                    document.body.click();
                },
                isDisabled: isCreateDisabled,
            };
            otherFunctions.splice(0, 0, addItem);
        }

        setOtherFuntionList([...otherFunctions]);
    }, [layer, otherFunctionList, setEditPanelMode, t]);

    return !isEmpty(otherFunctionList) ? (
        <OtherFunctionsCell placement="right" itemList={otherFunctionList} rowData={{ id: 1, name: 'myName' }} />
    ) : null;
}
