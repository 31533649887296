import { InfoPanelLayerAttribute, Layer, LayerAttribute } from '@map/services/mapTypes';
import { TFunction } from 'i18next';
import { camelCase } from 'lodash';

export const getLayerCaptionString = (translater: TFunction, layer?: Layer): string => {
    const defaultText = 'Unnamed layer';

    if (!layer?.readonly && layer) {
        return layer.name;
    }

    if (!layer?.name) {
        return defaultText;
    }

    return translater<string>(`map.${camelCase(`layer ${layer.name}`)}`, defaultText);
};

export const getLayerAttributeCaptionString = (translater: TFunction, layer?: Layer, layerAttribute?: InfoPanelLayerAttribute): string => {
    const defaultText = layerAttribute?.defaultAttributeKey ?? 'Unnamed layer attribute';

    if (!layer?.name || !layerAttribute) {
        return defaultText;
    }

    if (layerAttribute.displayName && layer.isCustomLayer) {
        return layerAttribute.displayName;
    }

    return translater<string>(`map.${camelCase(`layer ${layer.name} ${layerAttribute.defaultAttributeKey}`)}`, defaultText);
};
