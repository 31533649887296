import { useState } from 'react';
import { useSelector } from 'react-redux';
import { CompanyPermissions } from 'shared/src/company';

export default function useCompanyPermission() {
    const countryCode = useSelector((state: any) => state.system.systemInfo.countryCode);
    const [companyPermissions] = useState(new CompanyPermissions(countryCode));

    return {
        companyPermissions,
    };
}
