import { simpleAction } from './baseActions';

const contentDomActions = {
    addSelectedIds,
    removeAllSelectedId,
    setSelectedIds,
    removeSelectedIds,
    setAllCollapseGroups,
    removeAllCollapseGroups,
    ADD_SELECTED_IDS: 'CONTENT_DOM_ADD_SELECTED_IDS',
    REMOVE_SELECTED_IDS: 'CONTENT_DOM_REMOVE_SELECTED_IDS',
    SET_SELECTED_IDS: 'CONTENT_DOM_SET_SELECTED_IDS',
    REMOVE_ALL_SELECTED_ID: 'CONTENT_DOM_REMOVE_ALL_SELECTED_ID',
    SET_ALL_COLLAPSE_GROUPS: 'CONTENT_DOM_SET_ALL_COLLAPSE_GROUPS',
    REMOVE_ALL_COLLAPSE_GROUPS: 'CONTENT_DOM_REMOVE_ALL_COLLAPSE_GROUPS',
};

export { contentDomActions };

function addSelectedIds(selectedIds) {
    return simpleAction(contentDomActions.ADD_SELECTED_IDS, { selectedIds });
}

function removeSelectedIds(selectedIds) {
    return simpleAction(contentDomActions.REMOVE_SELECTED_IDS, { selectedIds });
}

function setSelectedIds(selectedIds) {
    return simpleAction(contentDomActions.SET_SELECTED_IDS, { selectedIds });
}

function removeAllSelectedId() {
    return simpleAction(contentDomActions.REMOVE_ALL_SELECTED_ID);
}

function setAllCollapseGroups(isCollepsedAll) {
    return simpleAction(contentDomActions.SET_ALL_COLLAPSE_GROUPS, { isCollepsedAll });
}

function removeAllCollapseGroups() {
    return simpleAction(contentDomActions.REMOVE_ALL_COLLAPSE_GROUPS);
}
