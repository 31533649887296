import useTranslate from '@i18n/useTranslate';
import { createUseStyles } from 'react-jss';
import Svg from '@baseComponents/Svg';
import SideBarLogo from './SideBarLogo';

const useStyles = createUseStyles((theme: any) => ({
    expandIconWrapper: {
        position: 'absolute',
        cursor: 'pointer',
        height: 30,
        width: 30,
        borderRadius: '50%',
        marginTop: 25,
        marginLeft: (props: any) => (props.expanded ? 225 : 45),
        backgroundColor: theme.color.pearl,
        zIndex: theme.zIndex.expanded,
        outline: 'none',
        top: '15%',
        boxShadow: 'rgb(0 0 0 / 30%) 0px 3px 5px 0px',
        transform: 'translateY(-50%)',
    },
    expandIcon: {
        marginLeft: 3,
        marginTop: 3,
        height: 24,
        width: 24,
    },
    sideBarHeader: {
        height: 80,
        paddingBottom: 17,
        display: 'block',
        position: 'relative',
    },
    testSystemSideWrapper: {
        position: 'absolute',
        height: '100%',
        width: (props: any) => (props.expanded ? 5 : 3),
        top: 0,
        left: 0,
        background: theme.color.test,
    },
}));

export default function SideNavBarHeader(props) {
    const { expanded, setExpanded, setShowUserMenu, isTestSystem } = props;
    const { t } = useTranslate();
    const classes = useStyles({ expanded });

    function toggleMenu() {
        setExpanded(currentState => !currentState);

        if ((window as any).global_grid_resize) {
            (window as any).global_grid_resize(); //php - php use this
        }
    }

    return (
        <>
            {isTestSystem && 
                <div className={classes.testSystemSideWrapper}></div>
            }
            <div className={classes.expandIconWrapper} onClick={toggleMenu} onKeyDown={toggleMenu} role="button" tabIndex={0}>
                <Svg iconId={expanded ? 'icon-chevron_left' : 'icon-chevron_right'} style={classes.expandIcon} />
            </div>
            <div className={classes.sideBarHeader}>
                <SideBarLogo expanded={expanded} setShowUserMenu={setShowUserMenu} isTestSystem={isTestSystem} />
            </div>
        </>
    );
}
