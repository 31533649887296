import { isString, isNaN } from 'lodash';
import { dateTimeUtils } from 'shared/src/modules';
import { DATE } from 'shared/src/constants';

export const isValidTime = val => {
    const parsedVal = isString(val) ? dateTimeUtils.convertTimeToSecondsDecimalIfNeeded(val) : +val;
    if (isNaN(parsedVal)) { return false; }

    return parsedVal >= DATE.SECONDS.MIN && parsedVal < DATE.SECONDS.MAX;
};

export const isNotNegative = val => +val >= 0;

export const higherThanZero = val => +val > 0;

const validationUtils = {
    isValidTime,
    isNotNegative,
    higherThanZero,
};

export default validationUtils;
