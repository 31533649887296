import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import { theme as themes } from '@styles/theme';
import { isArray, isNil } from 'lodash';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import { useLayerCreateContext } from '@map/services/layerCreate/layerCreateContext';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { rowFunctions } from './rowFunctions';
import LayerCreateController from '@map/services/layerCreate/layerCreateController';
import { TLayerCreateStore } from '@map/services/layerCreate/layerCreateStore';

const useStyles = createUseStyles((theme: any) => ({
    table: {
        '& .tableHeaderRow': {
            marginRight: 20,
        },
        '& .List': {
            marginRight: 20,
            marginLeft: 4,
        },
        '& .table-row': {
            marginRight: 20,
        },
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        overflowX: 'hidden',
    },
}));

interface TProps {
    tableKey: string,
    propertyDataList: unknown[],
    tableData: any[],
    idColumnName: string,
    currentModalComponent: Nullable<JSX.Element>,
    currentModalComponentProps?: any,
    isCustomLayerUpload: boolean;
    controller: LayerCreateController;
    pairableEntityIds: number[];
    doAlert: () => void;
}

export default function LayerUploadModalPairFeatureListTable(props: TProps) {
    const { tableKey, propertyDataList, tableData, idColumnName, currentModalComponent, currentModalComponentProps, isCustomLayerUpload, controller, pairableEntityIds, doAlert } = props;

    const { t } = useTranslate();
    const classes = useStyles();

    const [defaultColumns, setDefaultColumns] = useState<any>();
    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    useEffect(() => {
        if (propertyDataList && (currentModalComponent || isCustomLayerUpload) && !isNil(t)) {
            const allColumns = propertyDataList?.map(propertyData => ({
                type: columnName.simpleText,
                columnId: propertyData === 'id' ? idColumnName : propertyData,
                columnName: propertyData,
                width: 150,
                minWidth: 150,
                maxWidth: 150,
            }));

            allColumns.push({
                type: columnName.iconButton,
                columnId: 'icon-bin',
                componentProps: {
                    type: 'destructive',
                    getIcon: (rowData: any) => ((!rowData?.isExcluded) ? 'icon-bin' : 'icon-again'),
                    getCellTitle: () => t('default.delete', 'Delete'),
                    getBackgroundColor: () => themes.color.white,
                    getIconColor: () => themes.color.destructive,
                    onClick: (rowData: any) => {
                        rowFunctions.removeEntityPairing({
                            rowData, controller, isCustomLayer: isCustomLayerUpload,
                        });
                    },
                },
                isRightFixed: true,
                width: 65,
                minWidth: 65,
                maxWidth: 65,
            } as any);

            if (!isCustomLayerUpload) {
                allColumns.push({
                    type: columnName.modalButton,
                    columnId: 'pairedEntityName',
                    columnName: t('map.pairedEntityName', 'Paired Entityname'),
                    componentProps: {
                        onClick: (rowData: any) => {
                            rowFunctions.openEntityPairingModal({
                                doAlert,
                                rowData,
                                pairableEntityIds,
                                controller,
                                modalComponent: currentModalComponent,
                                modalComponentProps: { ...(currentModalComponentProps || {}), excludedIds: [] },
                            });
                        },
                    },
                    width: 250,
                    minWidth: 250,
                    maxWidth: 250,
                    isLeftFixed: true,
                } as any);
            }
            allColumns.push({
                type: columnName.area,
                columnId: 'calculatedArea',
                columnName: t('map.calculatedArea', 'Calculated Area (ha)'),
                width: 120,
                minWidth: 120,
                maxWidth: 120,
                isLeftFixed: true,
            } as any);

            allColumns.unshift({
                type: columnName.mapSvg,
                componentProps: {
                    mapId: 'mapSvg',
                },
            } as any);

            setDefaultColumns(allColumns);
        }
    }, [propertyDataList, currentModalComponent, t, controller, pairableEntityIds, idColumnName, currentModalComponentProps, isCustomLayerUpload, doAlert]);

    return (
        <>
            <TableGroup
                tableId={tableKey}
                tableData={tableData}
                tableColumns={columns}
                isBasePageTable={false}
                isCollapsible={false}
                className={classes.table}
                tableGroup={{
                    value: null,
                    text: 'No breakdown',
                }}
            />
            <TableScrollerFooter tableKey={tableKey} isLoaded={isArray(tableData)} />
        </>
    );
}
