import React from 'react';
import { createUseStyles } from 'react-jss';

import TableAggregateTitle from '../aggregate/TableAggregateTitle';
import TableAggregateCell from '../aggregate/TableAggregateCell';

const useStyle = createUseStyles({
    footerCellWrapper: {
        textAlign: 'left',
        display: props => (props.isSelectRowColumn && 'none'),
        overflow: 'hidden',
        padding: 0,
        width: props => props.width || '100%',
        minWidth: props => props.minWidth,
        maxWidth: props => props.maxWidth,
        height: '100%',
        verticalAlign: 'top',
        paddingLeft: 10,
        alignItems: 'center',
    },
});

export default function TableFooterCell(props) {
    const { cellWidth, filteredTableData, column, aggregateType } = props;
    const { isSelectRowColumn, width, minWidth, maxWidth } = column;

    const classes = useStyle({ isSelectRowColumn, width: (cellWidth || width), minWidth: (cellWidth || minWidth), maxWidth: (cellWidth || maxWidth) });

    const showAggregateCell = true;

    return (
        <div className={classes.footerCellWrapper}>
            {column.isTitle
                && <TableAggregateTitle aggregateType={aggregateType} tableRowCount={filteredTableData?.length} />
            }
            {showAggregateCell && !column.isTitle
                && <TableAggregateCell tableData={filteredTableData} column={column} />
            }
        </div>
    );
}
