import { RIGHTS, SYSTEM, COUNTRY, SETTINGS } from '../constants';

export const OFFICIAL_REPORTS = {
    MODULE_ID: 14,
    NAME: 'officialReports',
    EXCLUDE_COUNTRIES: [COUNTRY.ARGENTINA],
    EXCLUDE_SYSTEM_MODULES: [
        SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
        SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
        SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
        SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
    ],
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'officialReports',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.officialNotifications',
                TRANSLATOR_DEFAULT: 'Official notifications',
            },
        },
        LPIS_FIELD: {
            CONTENT_URL_ID: { lpisField: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'lpisField',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.lpisField',
                TRANSLATOR_DEFAULT: 'LPIS field',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
        LPIS_FIELD_EDIT: {
            CONTENT_URL_ID: { lpisFieldEdit: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'lpisFieldEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.lpisFieldEdit',
                TRANSLATOR_DEFAULT: 'LPIS field edit',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
        LPIS_TASK: {
            CONTENT_URL_ID: { lpisTask: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'lpisTask',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.lpisTask',
                TRANSLATOR_DEFAULT: 'LPIS task',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
        CREATE_LPIS_WORK_OPERATION: {
            CONTENT_URL_ID: { createLpisWorkOperation: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'createLpisWorkOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.createLpisWorkOperation',
                TRANSLATOR_DEFAULT: 'Create LPIS work operation',
            },
            EDIT_TITLE: {
                TRANSLATOR_KEY: 'pageTitle.editLpisWorkOperation',
                TRANSLATOR_DEFAULT: 'Edit LPIS work operation',
            },
        },
        CREATE_EGN_LPIS_WORK_OPERATION: {
            CONTENT_URL_ID: { createEGNLpisWorkOperation: 24 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'createEGNLpisWorkOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.createEGNLpisWorkOperation',
                TRANSLATOR_DEFAULT: 'Create work operation',
            },
            EDIT_TITLE: {
                TRANSLATOR_KEY: 'pageTitle.editEGNLpisWorkOperation',
                TRANSLATOR_DEFAULT: 'Edit work operation',
            },
        },
        LPIS_TASK_MATERIAL: {
            CONTENT_URL_ID: { lpisTaskMaterial: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'lpisTaskMaterial',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.lpisTaskMaterial',
                TRANSLATOR_DEFAULT: 'LPIS task material',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
        LPIS_TASK_YIELDS: {
            CONTENT_URL_ID: { lpisTaskYields: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'lpisTaskYields',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.lpisTaskYields',
                TRANSLATOR_DEFAULT: 'LPIS task yields',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
        ADD_MATERIAL_TOP_LPIS_WORK_OPERATION: {
            CONTENT_URL_ID: { addMaterialToLpisWorkOperation: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'addMaterialToLpisWorkOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.addMaterialToLpisWorkOperation',
                TRANSLATOR_DEFAULT: 'Add material to LPIS work operation',
            },
        },
        ADD_YIELD_TO_LPIS_WORK_OPERATION: {
            CONTENT_URL_ID: { addYieldToLpisWorkOperation: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'addYieldToLpisWorkOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.addYieldToLpisWorkOperation',
                TRANSLATOR_DEFAULT: 'Add yield to LPIS work operation',
            },
        },
        LPIS_PLANT_PROTECTION_OBSERVATIONS: {
            CONTENT_URL_ID: { lpisPlantProtectionObservations: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'lpisPlantProtectionObservations',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.lpisPlantProtectionObservations',
                TRANSLATOR_DEFAULT: 'LPIS plant protection observations',
            },
            EXCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
        LPIS_PLANT_PROTECTION_OBSERVATIONS_EDIT: {
            CONTENT_URL_ID: { lpisPlantProtectionObservationsEdit: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'lpisPlantProtectionObservationsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.lpisPlantProtectionObservationsEdit',
                TRANSLATOR_DEFAULT: 'LPIS plant protection observations edit',
            },
        },
        LPIS_IRROGATION_REGISTER: {
            CONTENT_URL_ID: { lpisIrrigationRegister: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'lpisIrrigationRegister',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.lpisIrrigationRegister',
                TRANSLATOR_DEFAULT: 'LPIS irrigation register',
            },
            EXCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
        LPIS_GRAZING_DIARY: {
            CONTENT_URL_ID: { lpisGrazingDiary: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'lpisGrazingDiary',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.lpisGrazingDiary',
                TRANSLATOR_DEFAULT: 'LPSI grazing diary',
            },
            EXCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
        CONTIGUOUS_AREAS_COMMITTED: {
            CONTENT_URL_ID: { contiguousAreasCommitted: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'contiguousAreasCommitted',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.contiguousAreasCommitted',
                TRANSLATOR_DEFAULT: 'Contiguous areas committed',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
        CAC_EDIT: {
            CONTENT_URL_ID: { cacEdit: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cacEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cacEdit',
                TRANSLATOR_DEFAULT: 'CAC edit',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
        AUTHORIZED_WATER_ABSTACTION_PLACES: {
            CONTENT_URL_ID: { authorizedWaterAbstractionPlaces: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'authorizedWaterAbstractionPlaces',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.authorizedWaterAbstractionPlaces',
                TRANSLATOR_DEFAULT: 'Authorized water abstraction places',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        GENERAL_FORM_ASSOCIATE: {
            CONTENT_URL_ID: { generalFormAssociate: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'generalFormAssociate',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Manuálisan társított ÁNYK GN szer lista',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        LIVESTOCK_CHANGE: {
            CONTENT_URL_ID: { LivestockChange: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockChange',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Állatállomány-változás nyilvántartás',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
            SETTINGS: [
                { [SETTINGS.USE_GLOBAL_LITE]: 0 }
            ],
        },
        LIVESTOCK_CHANGE_EDIT: {
            CONTENT_URL_ID: { livestockChangeEdit: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockChangeEdit',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Állatállomány-változás rögzítése',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
            SETTINGS: [
                { [SETTINGS.USE_GLOBAL_LITE]: 0 }
            ],
        },
        RELEVANT_REGIONAL_OFFICE_EDIT: {
            CONTENT_URL_ID: { relevantRegionalOfficeEdit: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'relevantRegionalOfficeEdit',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Illetékes szakigazgatási szerv lista',
            },
        },
        VALIDATION: {
            CONTENT_URL_ID: { validation: 21 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'validation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.validation',
                TRANSLATOR_DEFAULT: 'Validation',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
            SETTINGS: [SETTINGS.ACCESSING_VALIDATION_INTERFACE],
        },
        E_FARMING_DIARY: {
            CONTENT_URL_ID: { eFarmingDiary: 22 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'eFarmingDiary',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.eFarmingDiary',
                TRANSLATOR_DEFAULT: 'e-Farming diary',
            },
        },
        LPIS_FIELD_FROM_EXCEL: {
            CONTENT_URL_ID: { lpisFieldFromExcel: 23 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'lpisFieldFromExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.lpisFieldFromExcel',
                TRANSLATOR_DEFAULT: 'LPIS field from excel',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS],
        },
    },
};
