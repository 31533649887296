import { isFunction, isNil } from 'lodash';
import { dateTimeUtils } from 'shared/src/modules';
import { useRef } from 'react';
// eslint-disable-next-line no-restricted-imports
import { MapSliderDatePicker, MapSliderIntervalMode } from '../MapSliderTypes';

type TProps = {
    minMaxDateHasChanged? : (min: Date, max: Date, intervalMode: MapSliderIntervalMode) => void,
    mapSliderMode: MapSliderIntervalMode
}

export const useMapSliderIntervalChange = ({ minMaxDateHasChanged, mapSliderMode } : TProps) => {
    const currentDatePickerMinMax = useRef<{ min: dateTimeUtils.DateType, max: dateTimeUtils.DateType }>();

    const mapSliderIntervalChanged = (sliderOptionDatePicker: MapSliderDatePicker[]) => {
        const calculatedMinDate = dateTimeUtils.getDateObject(sliderOptionDatePicker[0].date);
        const calcilatedMaxDate = dateTimeUtils.getDateObject(sliderOptionDatePicker[sliderOptionDatePicker.length - 1].date);

        if (!isNil(currentDatePickerMinMax.current)) {
            if (!calculatedMinDate.isSame(currentDatePickerMinMax.current.min) || !calcilatedMaxDate.isSame(currentDatePickerMinMax.current.max)) {
                if (isFunction(minMaxDateHasChanged)) {
                    minMaxDateHasChanged(calculatedMinDate.toDate(), calcilatedMaxDate.toDate(), mapSliderMode);
                }
            }
        } else if (isFunction(minMaxDateHasChanged)) {
            minMaxDateHasChanged(calculatedMinDate.toDate(), calcilatedMaxDate.toDate(), mapSliderMode);
        }

        currentDatePickerMinMax.current = {
            min: calculatedMinDate,
            max: calcilatedMaxDate,
        };
    };

    return { mapSliderIntervalChanged };
};
