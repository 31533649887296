import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const economicUnitService = {
    getEconomicUnitList,
    getCostProfitCenterList,
    getEconomicUnitTypeList,
};

/**
 * Get all economic unit datas
 * @property {array<int>} companyIds IsOptional - Company ID list eg.: [1]
 * @property {array<int>} economicUnitTypeIds IsOptional - Economis unit type ID list eg.: [1]
    WORKSHOP: 1,
    ANIMAL_HUSBANDRY: 2,
    CROP_PRODUCTION: 3,
    SERVICE: 4,
    AUXILIARY_OPERATION: 5,
    GENERAL: 6,
    COST_PROFIT_CENTER: 7,
    WINE: 8,
    WINE_CELLAR: 9,
    PROCESSING_PLANT: 10,
    FRUIT_PREPARATIONS: 11,
    SEED_PLANT: 12,
    REPRODUCTIVE_MATERIAL: 13,
 * @property {int} isRelationToWorkoperationValidated IsOptional - Is workoperation filter list eg.: 1
 * @property {int} isRelationToProduct IsOptional - Is product filter list eg.: 1
 * @property {int} isProductionGroup IsOptional - Is product filter list eg.: 1
 *
 * @return {array} Economic unit array
 */
async function getEconomicUnitList(params) {
    const url = routerHelper.getUrlWithParameters('/api/economicUnit', params);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getCostProfitCenterList(companyIds, optionalFilters = {}) {
    const url = routerHelper.getUrlWithParameters('/api/economicUnit/costProfitCenter', { companyIds, optionalFilters: JSON.stringify(optionalFilters) });
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getEconomicUnitTypeList(typeIds = null) {
    const url = routerHelper.getUrlWithParameters('/api/economicUnit/economicUnitType', { typeIds });
    const response = await sendServerRequest('GET', url);
    return response;
}
