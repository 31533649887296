import { sendServerRequest } from './baseService';

export const visiblefarmService = {
    createPeriod,
    updatePeriod,
    updatePeriodBatch,
    deletePeriod,
};

/**
 * Sends a request for creating a period
 */
async function createPeriod(body) {
    try {
        const response = await sendServerRequest('POST', '/api/av-visible-farm/periods', {
            id: `${body.agentId}-${body.av_id}_${body.periodType}`,
        });
        return response;
    } catch (error) {
        return {};
    }
}

/**
 * Sends a request for updating a period
 */
async function updatePeriod(body) {
    try {
        const response = await sendServerRequest('PATCH', `/api/av-visible-farm/periods/${body.agentId}-${body.av_id}_${body.periodType}`, {
            id: `${body.agentId}-${body.av_id}_${body.periodType}`,
        });
        return response;
    } catch (error) {
        return {};
    }
}

/**
 * Sends a request for updating multiple periods of the same type
 */
async function updatePeriodBatch(body) {
    try {
        const response = await sendServerRequest('PATCH', '/api/av-visible-farm/periods/batch/by-ids', {
            agentId: body.agentId,
            periodType: body.periodType,
            ids: body.ids,
        });
        return response;
    } catch (error) {
        return {};
    }
}

/**
 * Sends a request for deleting a period
 */
async function deletePeriod(body) {
    try {
        const response = await sendServerRequest('DELETE', `/api/av-visible-farm/periods/${body.agentId}-${body.av_id}_${body.periodType}`, {
            id: `${body.agentId}-${body.av_id}_${body.periodType}`,
            oldData: {},
        });
        return response;
    } catch (error) {
        return {};
    }
}
