import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isNull } from 'lodash';

import { getRouteList } from '@utils/router/Router';
import { routerActions, alertActions, systemActions } from '@states/actions';
import { routerHelper } from '@utils/router/RouterHelper';
import { userService, sessionService } from '@services';

export function useRouter(props) {
    const { isCheckedPhpSession, hasPhpSession } = props;
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const userId = useSelector((state: any) => state.user.user.id);
    const activeSystemModule = useSelector((state: any) => state.user.user.settings?.activeSystemModule);
    const companySettings = useSelector((state: any) => state.company.company.settings);
    const subscriptions = useSelector((state: any) => state.company.company.globalData.companySubscriptionPackage);
    const isTestSystem = useSelector((state: any) => state.system.systemInfo.isTestSystem);
    const isServerOverload = useSelector((state: any) => state.router.isServerOverload);
    const isChangePasswordScreen = useSelector((state: any) => state.router.isChangePasswordScreen);
    const planningModeisActive = useSelector((state: any) => state.system.planningModeisActive);
    const countryCode = useSelector((state: any) => state.system.systemInfo.countryCode);

    const { userToRights, administrator, consultant, isHidden: isHiddenUser } = useSelector((state: any) => state.user.user);
    const { agent, agentError, userError, isCheckedUserToken } = useSelector((state: any) => state.user);
    const [routeList, setRouteList] = useState<Array<any>>([]);

    //get route list
    useEffect(() => {
        if (activeSystemModule && companySettings && subscriptions && userToRights && !isNull(administrator) && !isNull(consultant) && !isNull(isHiddenUser)) {
            const currentRouterList = getRouteList(t, userToRights, administrator, consultant, companySettings, activeSystemModule, isHiddenUser, countryCode);
            setRouteList(currentRouterList);
        }
    }, [activeSystemModule, companySettings, subscriptions, t, userToRights, administrator, consultant, isHiddenUser, planningModeisActive]);

    //check system info initialized
    useEffect(() => {
        if (history && dispatch) {
            const location = history.location.pathname;
            dispatch(routerActions.routeChanged(location));
        }
    }, [history, dispatch]);

    //check user agent
    useEffect(() => {
        if (history.location.pathname && dispatch) {
            const location = history.location.pathname;
            dispatch(routerActions.checkAgentByRoute(location));
        }
    }, [history.location.pathname, dispatch]);

    const isInitialized = useCallback(() => {
        if ((!agent && !agentError && !userError && !isServerOverload && !userId && !isCheckedPhpSession)
            || (!isCheckedUserToken && agent && userId)
            || (isNull(isTestSystem) && !isServerOverload && !agentError)) {
            return false;
        }
        return true;
    }, [agent, agentError, userId, userError, isServerOverload, isCheckedUserToken, isTestSystem, isCheckedPhpSession]);

    //check display screens
    const displayChangePasswordScreen = useCallback(() => {
        async function isValidPasswordChangeDate(agentName, location) {
            const hash = routerHelper.getHashCode(location);
            const isValidDate = await userService.isValidDate(agentName, hash);
            return isValidDate;
        }

        if (dispatch && history && t) {
            try {
                const location = history.location.pathname;
                const agentName = routerHelper.getAgentName(location);

                if (agent && !agentError && routerHelper.isPasswordConfirm(location)) {
                    isValidPasswordChangeDate(agentName, location).then(isValidPasswordReminderDate => {
                        if (!isValidPasswordReminderDate) {
                            dispatch(alertActions.errorAlert(t('router.invalidLink', 'This link can not be used for password change anymore.')));
                            history.push(`/${agentName}`);
                        }

                        const isVisibleScreen = (!userId || userError) && !agentError && agent && isValidPasswordReminderDate;

                        dispatch(routerActions.setChangePasswordScreen(isVisibleScreen));
                        return isVisibleScreen;
                    });
                } else if (!userId && agent && !routerHelper.isPasswordConfirm(location)) {
                    dispatch(routerActions.setChangePasswordScreen(false));
                    return false;
                } else {
                    return false;
                }
            } catch (error) {
                dispatch(routerActions.setServerOverload(true));
            }
        }
        return true;
    }, [dispatch, history, userId, userError, agentError, agent, t]);

    const displayLoginScreen = useCallback(() => (!userId || userError) && !agentError && agent && isCheckedUserToken && !isChangePasswordScreen,
        [userId, userError, agentError, agent, isCheckedUserToken, isChangePasswordScreen]);

    const displayMainPageScreen = useCallback(() => agent && !agentError && userId && !userError && isCheckedUserToken && !isChangePasswordScreen && hasPhpSession,
        [agent, agentError, userId, userError, isCheckedUserToken, isChangePasswordScreen, hasPhpSession]);

    const displayNotAvailableScreen = useCallback(() => !agent && agentError, [agent, agentError]);

    //user session expired - remove planning session
    useEffect(() => {
        if (dispatch && displayLoginScreen()) {
            sessionService.removePlanningData();
            sessionService.removePlanningMode();
            dispatch(systemActions.setPlanningModeActive(false));
        }
    }, [displayLoginScreen, dispatch]);

    return {
        isServerOverload,
        agentError,
        routeList,
        isInitialized,
        displayChangePasswordScreen,
        displayLoginScreen,
        displayMainPageScreen,
        displayNotAvailableScreen,
    };
}
