import React, { useEffect, useImperativeHandle } from 'react';
import { Popover } from 'react-bootstrap';

const UpdatingPopover = React.forwardRef(
    ({ forwardedRef, updateFunc, popper, children, show: _, ...props }, ref) => {
        useImperativeHandle(forwardedRef, () => ({
            popper,
        }));

        useEffect(() => {
            popper.scheduleUpdate();
        }, [children, popper]);

        return (
            <Popover ref={ref} {...props}>
                {children}
            </Popover>
        );
    },
);

export default UpdatingPopover;
