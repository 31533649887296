import i18next from 'i18next';
import { getPHPEndpoint } from 'shared/src/routing';
import { routerHelper } from '@utils/router/RouterHelper';
import { postPhpContent, postPhpDefaultInfo } from './baseService';
import { sessionService } from './sessionService';

export const phpService = {
    login,
    logout,
    getPhpContent,
    getPhpDefaultInfo,
    getPhpSelectPlanModal,
    getPesticideInfo,
    getExportLink,
    getPhpJavascript,
    getPhpAdModal,
    getSideMenuPermissions,
    checkPhpSession,
    postDataToPhp,
};

async function login(loginData) {
    const url = '/modules/login/control/login.php';
    loginData.tervezes = loginData.planning;
    loginData.langcode = i18next.language;
    const response = await postPhpContent(url, loginData);
    return response;
}

async function logout() {
    const response = await postPhpContent('/modules/login/control/logout.php');
    return response;
}

async function getPhpContent(currentUrl, isPlanningMode) {
    const phpUrlData = getPHPEndpoint(currentUrl, isPlanningMode);
    const url = '/modules/content_react.php';
    const response = await postPhpContent(url, phpUrlData, true);
    return response;
}

async function getPhpDefaultInfo() {
    const url = '/modules/index_react.php';
    const response = await postPhpDefaultInfo(url, false);
    return response;
}

async function getPhpSelectPlanModal() {
    const url = '/modules/tervezes/view/terv_lista_modal.php';
    const response = await postPhpContent(url, true);
    return response;
}

async function getPesticideInfo() {
    const url = '/modules/react/pesticide_info.php';
    const response = await postPhpContent(url, false, true);
    return (response && response.updateDate) ? response.updateDate : '-';
}

function getExportLink(link) {
    const agentName = sessionService.getAgentName();
    return `/export${link}&agentName=${agentName}`;
}

async function getPhpJavascript(scriptUrl) {
    const response = await postPhpDefaultInfo(scriptUrl);
    return response;
}

async function getPhpAdModal(adId) {
    const url = routerHelper.getUrlWithParameters('/modules/altalanos/view/ad_modal.php', { adId });
    const response = await postPhpContent(url, true);
    return response;
}

async function checkPhpSession() {
    const url = '/modules/react/sessionKeepingAlive.php';
    const agentName = sessionService.getAgentName();
    const response = await postPhpContent(url, { agentName }, true);
    return response;
}

async function getSideMenuPermissions(props = null) {
    const response = await postPhpContent('/modules/react/menuPermissionChecker.php', props, true);
    return response;
}

async function postDataToPhp(phpUrl, phpData) {
    const response = await postPhpContent(phpUrl, phpData, true);
    return response;
}
