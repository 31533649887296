import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import ErrorMessage from '@baseComponents/formParts/ErrorMessage';
import Label from '@baseComponents/formParts/Label';
import SimpleSelect from '../simpleSelect/SimpleSelect';

const useStyles = createUseStyles((theme: any) => ({
    selectContainer: {
        display: 'block',
        width: '100%',
    },
    error: {
        '& .dropdown-toggle': {
            borderColor: (props: any) => props.invalid && !props.selected && [theme.color.destructive, '!important'],
            '& span': {
                color: (props: any) => props.invalid && !props.selected && theme.color.destructive,
            },
        },
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        color: theme.color.destructive,
    },
}));

export default function FormSelect(props) {
    const { dataList, selected, onChange, className, disabled, errorMessage, invalid, search, label, required, addNew, emptyOption, placeholder = null, isClearItem, useAbsoluteMenu, seletDropdownClassName } = props;
    const classes = useStyles({ invalid, selected });

    return (
        <div className={clsx(classes.selectContainer, className)}>
            {label && <Label label={label} required={required} isValue={selected} />}
            <SimpleSelect
                dataList={dataList}
                selected={selected}
                onChange={onChange}
                className={classes.error}
                seletDropdownClassName={seletDropdownClassName}
                disabled={disabled}
                search={search}
                addNew={addNew}
                emptyOption={emptyOption}
                placeholder={placeholder}
                isClearItem={isClearItem}
                useAbsoluteMenu={useAbsoluteMenu}
            />
            <ErrorMessage invalid={props.invalid && !selected} errorMessage={errorMessage} className={classes.errorMessage} />
        </div>
    );
}
