import { isEmpty, values } from 'lodash';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import { theme as themes } from '@styles/theme';
import { simpleTableActions } from '@states/actions';

const cellFunctions = {
    deleteRow: (rowId, tableKey, rowIdName, dispatch) => {
        dispatch(simpleTableActions.removeRowFromTableDataById(tableKey, rowId, rowIdName));
    },

    updateRow: (rowId, columnId, updateValue, tableKey, dispatch, rowData) => {
        if (!tableKey) {
            return;
        }

        const tableData = dispatch(simpleTableActions.getTableDataList(tableKey));
        const updatedRow = tableData?.find(tableRow => tableRow.id === rowId);

        if (updatedRow && columnId) {
            rowData[columnId] = updateValue;
            updatedRow[columnId] = updateValue;
        }
    },
};

export function getAllColumns(t, showedColumns, dispatch, tableKey, deletePartnerFromTable, quantityFormats, onChangeQuantity) {
    const columns = {
        partner: {
            isLeftFixed: true,
            isTitle: true,
            type: columnName.simpleText,
            columnId: 'partner',
            columnName: t('multiPartnerReceptionModal.partner', 'Partner'),
            width: 230,
            minWidth: 230,
            maxWidth: 230,
        },
        settlement: {
            type: columnName.simpleText,
            columnId: 'partnerSettlement',
            columnName: t('multiPartnerReceptionModal.settlement', 'Settlement'),
            minWidth: 230,
        },
        address: {
            type: columnName.simpleText,
            columnId: 'partnerAddress',
            columnName: t('multiPartnerReceptionModal.address', 'Address'),
            minWidth: 230,
        },
        quantity: {
            type: columnName.quantityInput,
            columnId: 'quantity',
            columnName: t('multiPartnerReceptionModal.quantity', 'Quantity'),
            componentProps: {
                isRequired: true,
                quantityFormat: quantityFormats,
                unitId: 'productUnit',
                hasCustomUnit: true,
                minValue: 0,
                onBlur: (quantity, rowData) => {
                    rowData.quantity = quantity;
                    onChangeQuantity(quantity, rowData.rowId);
                },
            },
            width: 130,
            minWidth: 130,
            maxWidth: 130,
        },
        removeRowButton: {
            isRightFixed: true,
            type: columnName.iconSvg,
            componentProps: {
                getIcon: () => 'icon-bin',
                getIconColor: () => themes.color.icons.deleteIcon,
                onClick: rowData => {
                    cellFunctions.deleteRow(rowData.rowId, tableKey, 'rowId', dispatch);
                    deletePartnerFromTable(rowData.rowId);
                },
            },
            width: 55,
            minWidth: 55,
            maxWidth: 55,
        },
    };

    if (!isEmpty(showedColumns)) {
        const filteredColumns = [];
        const columnObj = Object.entries(columns);

        showedColumns.forEach(showedColName => {
            const column = columnObj.find(columnParts => columnParts[0].toString() === showedColName);
            if (column) {
                filteredColumns.push(column[1]);
            }
        });

        return filteredColumns;
    }

    return values(columns);
}
