import { slovakExternalServices } from '@map/services/slovakianReports/slovakReportExternalApis';
import { isEmpty } from 'lodash';
import { createLabel } from '@map/utils/mapLabelUtils';
import { GetSlovakExternalLayerResult, getBbox } from '../../slovakReportsUtils';
import { SKLayer, SK_EXTERNAL_LAYERS, SK_LAYERS_ARRAY, SK_NON_PRODUCTIVE_ELEMENTS_LAYER } from '../../slovakReportsLayers';

type GetExternalLayersProps = {
  map: google.maps.Map;
  dataLayer: google.maps.Data;
  activeLayers: number[];
  markers: React.MutableRefObject<google.maps.Marker[]>;
  gsaaYear: number;
};

export async function getExternalLayers(props: GetExternalLayersProps) {
    const { map, dataLayer, activeLayers, markers, gsaaYear } = props;

    const bbox = getBbox(map);

    markers.current.forEach(marker => {
        marker.setMap(null);
    });

    const activeExternalIds: number[] = [];
    const layers = { ...SK_EXTERNAL_LAYERS, SK_NON_PRODUCTIVE_ELEMENTS_LAYER };
    activeLayers?.forEach((layerId: number) => {
        const layer = Object.values(layers).find(l => l.id === layerId);

        if (layer && layer.id === layerId) {
            activeExternalIds.push(layer.externalId);
        }
    });

    if (isEmpty(activeExternalIds)) {
        return;
    }

    const externalLayers: GetSlovakExternalLayerResult[] = await slovakExternalServices.getExternalLayerByIds(gsaaYear, encodeURIComponent(JSON.stringify(bbox)), activeExternalIds);

    externalLayers?.forEach(layer => {
        if (isEmpty(layer.featureCollection)) {
            return;
        }

        dataLayer?.addGeoJson(layer.featureCollection);

        dataLayer?.forEach(feature => {
            const layerId = feature.getProperty('layerId');
            const centroid: google.maps.LatLng = feature.getProperty('centroid');

            const layerMetaData: SKLayer = SK_LAYERS_ARRAY.find(l => l.externalId === layerId) as SKLayer;

            if (!layerMetaData) {
                return;
            }

            let strokeWeight = 2;
            let strokeColor: string | null = null;
            let fillColor: string | null = null;
            let fillOpacity = 0;

            let labelText: string | null = null;

            const { legends, legendProperty, legendIdProperty } = layerMetaData;

            labelText = feature?.getProperty(legendProperty as string) ?? layerMetaData.name;

            if (!legendIdProperty) {
                if (legends?.length === 1) {
                    strokeColor = legends?.[0]?.strokeColor ?? null;
                    fillColor = legends?.[0]?.fillColor ?? null;
                    fillOpacity = legends?.[0]?.fillOpacity ?? 0;
                    strokeWeight = legends?.[0]?.strokeWeight ?? 2;
                } else {
                    const label = feature.getProperty(legendProperty ?? '');
                    const props = {};

                    const x = legends?.find(l => l.name === label);

                    strokeColor = x?.strokeColor ?? null;
                    fillColor = x?.fillColor ?? null;
                    fillOpacity = x?.fillOpacity ?? 0;
                    strokeWeight = x?.strokeWeight ?? 2;
                }
            } else {
                const id = feature.getProperty(legendIdProperty ?? '');

                const x = legends?.find(l => l.id === +id);

                strokeColor = x?.strokeColor ?? null;
                fillColor = x?.fillColor ?? null;
                fillOpacity = x?.fillOpacity ?? 0;
                strokeWeight = x?.strokeWeight ?? 2;
            }

            dataLayer?.overrideStyle(feature, { strokeColor: strokeColor ?? '#42f57e', strokeWeight, strokeOpacity: 1, fillOpacity, fillColor: fillColor ?? undefined });

            if (labelText && centroid) {
                const label = createLabel(centroid, labelText, map, strokeColor ?? '#42f57e');
                markers.current.push(label);
            }
        });
    });
}
