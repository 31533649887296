import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router';
import { Dropdown } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import useTranslate from '@i18n/useTranslate';
import { theme as themes } from '@styles/theme';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    linkDropdown: {
        padding: 0,
        zIndex: theme.zIndex.selectDropdown,
        background: theme.color.white,
        borderRadius: 4,
        border: '1px solid rgba(227, 230, 232, 0.5)',
        boxShadow: theme.shadows.selectDropdown,
        maxWidth: 200,
        maxHeight: 400,
        overflow: 'auto',
        ...theme.verticalScrollbar,
    },
    dropdownItem: {
        height: theme.subMenu.buttonHeight,
        padding: [0, 15],
        color: theme.color.jet,
        fontSize: 15,
        fontWeight: 500,
        borderRadius: 6,
        display: 'flex',
        alignItems: 'center',
        maxWidth: 400,
        width: '100%',
        '&:hover': {
            backgroundColor: theme.color.lilian,
            color: theme.color.main,
            textDecoration: 'none',
        },
        '&:focus': {
            textDecoration: 'none',
        },
    },
    dropdownList: {
        height: (props: any) => {
            if (props.dataListLength) {
                return props.dataListLength > 250 ? 250 : props.dataListLength;
            }
            return theme.subMenu.buttonHeight;
        },
    },
    scrollBar: {
        ...theme.verticalScrollbar,
    },
    hoverIcon: {
        width: 20,
        height: 20,
        margin: [0, 8],
        marginLeft: 0,
    },
}));

export default function LinkDropdownBox(props) {
    const { dataList } = props;
    const { t, translater } = useTranslate();
    const history = useHistory();
    const classes = useStyles({ dataListLength: ((dataList?.length || 0) * themes.subMenu.buttonHeight) } as any);

    function onClickMenu(event, href) {
        event.preventDefault();
        if (href && event?.ctrlKey) {
            window.open(href, '_blank');
        } else if (href) {
            history.push(href);
        }
    }

    function getTitle(dataListItem) {
        if (dataListItem?.translatorKey && dataListItem?.translatorDefault) {
            translater(dataListItem.translatorKey, dataListItem.translatorDefault);
        }
        return null;
    }

    return (
        <Dropdown.Menu className={classes.linkDropdown}>
            {!isEmpty(dataList)
                ? (
                    <div className={classes.dropdownList}>
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    height={height}
                                    width={width}
                                    itemCount={dataList.length}
                                    itemSize={themes.subMenu.buttonHeight}
                                    className={classes.scrollBar}
                                >
                                    {({ index, style }) => (
                                        <div key={index} style={style}>
                                            <Dropdown.Item key={index} href={dataList[index].url} className={classes.dropdownItem} onClick={event => onClickMenu(event, dataList[index].url)} title={getTitle()}>
                                                {!isEmpty(dataList[index]?.iconId)
                                                    && (
                                                        <span>
                                                            <Svg iconId={dataList[index]?.iconId} style={classes.hoverIcon} />
                                                        </span>
                                                    )
                                                }
                                                {dataList[index]?.translatorKey && dataList[index]?.translatorDefault
                                                    ? t(dataList[index].translatorKey, dataList[index].translatorDefault)
                                                    : dataList[index].text
                                                }
                                            </Dropdown.Item>
                                        </div>
                                    )}
                                </List>
                            )}
                        </AutoSizer>
                    </div>
                ) : null
            }
        </Dropdown.Menu>
    );
}
