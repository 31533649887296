/* eslint-disable no-undefined */
/* eslint-disable no-alert */
import BaseController from '@flowsCommon/services/BaseController';
import { Nullable, ResponseWrapper } from '@flowsCommon/services/baseTypes';
import { queryBuilder } from '@flowsCommon/services/queryBuilder';
import {
    clearMVCArray,
    createMachineMarker,
    createLabelsAndColoring,
    createLabel,
    createMeteorolyLabel,
} from '@map/utils/mapLabelUtils';
import { getAdditionalLayerAttributes, getDataForLayer, hasCommonValue } from '@map/utils/mapUtils';
import { sessionService } from '@services/sessionService';
import { userService } from '@services/userService';
import { theme } from '@styles/theme';
import {
    feature as convertToFeature,
    featureCollection as convertToFeatureCollection,
    geometry as convertToGeometry,
} from '@turf/helpers';
import calculateBBox from '@turf/bbox';
import FileSaver from 'file-saver';
import {
    Feature,
    FeatureCollection,
    GeoJsonProperties,
    Geometry,
} from 'geojson';
import {
    cloneDeep, compact, filter, groupBy, isArray, isEmpty, isEqual, isNil,
    isObject,
    map, maxBy,
} from 'lodash';
import {
    Cluster,
    MarkerClusterer,
    SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer';
import { CROP, INTERFACE_FILENAME } from 'shared/src/constants';
import { dateTimeUtils } from 'shared/src/modules';
import { MapSliderIntervalMode } from '@map/components/MapSlider/MapSliderTypes';
import { gisService } from '@services/gisService';
import { MAP_FILTER_STATUS } from '@map/components/MapFilter/MapFilterSchema';
import { validatePolygonForSK } from '@map/components/DrawControl/skDrawManager';
import * as apis from './mapApis';
import { BuiltInAttributeTypes, BuiltInLayerTypes, isAttributeWithCost, MapMode } from './mapEnums';
import { initStore, TMapStore } from './mapStore';
import MapStoreService from './mapStoreService';
import {
    Layer,
    LayerAttribute,
    LayerAttributeDto,
    MapFilters,
    CreateLayerDto,
    UpdateLayerDto,
    machineIDAndMarker,
    GetGeometriesForLayersProps,
    LayerSelectorSettings,
    LayerAttributeStatus,
    LayerGeomsDto,
    PlanningLayerData,
    LayersDto,
    LayerGeometriesWithBboxDto,
    DefaultCenterDto,
    ProcessGeomErrorCode,
} from './mapTypes';
import { MapClusterRenderer } from './MapClusterRenderer';

export class MapController extends BaseController<TMapStore, MapStoreService> {
    setFetchers = (layerFetcher: () => Promise<LayersDto>, geomFetcher?: () => Promise<LayerGeometriesWithBboxDto>) => {
        this.storeService.setFetchers(layerFetcher, geomFetcher);
    };

    getGisToken = async (userId: number, companyId: string | number, countryCode: string) => {
        const avAuthToken = sessionService.getAuthToken();
        const gisToken = sessionService.getGisTokenToAgent();

        if (!avAuthToken || isEmpty(avAuthToken)) {
            alert('Lejárt az AV token.');
            return;
        }

        //ha av token mellett van gis token is, akkor felesleges újra lekérdezni
        if (gisToken && !isEmpty(gisToken)) {
            this.storeService.setGisAuthAsAvailable();
            sessionStorage.setItem('gisAuthToken', gisToken);
            return;
        }

        const res = await apis.fetchMockGisToken({
            userId,
            companyId,
            avAuthToken,
            countryCode,
        });

        if (!res.ok) {
            alert('Unexpected error happened on get gis token');
            return;
        }

        const { accessToken } = res.data;
        sessionStorage.setItem('gisAuthToken', accessToken);

        sessionService.setGisTokenToAgent(accessToken);
        this.storeService.setGisAuthAsAvailable();
    };

    getMapDefaultCenter = async () => {
        //ha sessionben van default center, akkor felesleges újra lekérdezni
        const defaultCenter = sessionService.getGisDefaultCenter();
        if (defaultCenter && !isEmpty(defaultCenter) && defaultCenter !== '[object Object]') {
            const centerAsDto = JSON.parse(defaultCenter) as DefaultCenterDto;
            this.storeService.setMapCenter(centerAsDto);
            return;
        }

        const res = await apis.fetchMapDefaultCenter();

        if (!res.ok) {
            alert('Unexpected error happened on get map center');
            return;
        }

        const centerAsJson = JSON.stringify(res.data);
        sessionService.setGisDefaultCenter(centerAsJson);
        this.storeService.setMapCenter(res.data);
    };

  getEveryLayerAndAttribute = async (isMapRoute?: boolean) => {
      this.storeService.setLayeringLoading(true);

      let layerQuery: any;
      if (this.store.mapMode === MapMode.PLANNING) {
          layerQuery = queryBuilder()
              .with('productionYearId', this.store.filters.productionYearId)
              .with('isPlanning', 1)
              .with('isMapRoute', +(isMapRoute || 0))
              .build();
      }

      let res: any;

      if (!this.store.fetcher.layerFetcher) {
          res = await apis.fetchLayers(layerQuery);
      } else {
          res = await this.store.fetcher.layerFetcher();
      }

      if (!res.ok) {
          alert('Unexpected error happened on get layers');
          return;
      }

      const { layers: layersDto } = res.data;

      const layers: Layer[] = [];
      let layerAttributes: LayerAttribute[] = [];

      let planningData: PlanningLayerData[] = [];

      if (this.store.mapMode === MapMode.PLANNING) {
          const planIds: number[] = [];
          layersDto.forEach(layer => {
              if (!isNil(layer.planId)) {
                  planIds.push(layer.planId);
              }
          });

          planningData = (await gisService.getPlanningLayersData(planIds)) as PlanningLayerData[];
      }

      layersDto.forEach(layerDto => {
          const { id, name, attributes, readonly, type, parentId, defaultFillColor, defaultStrokeColor, isCustomLayer, planId } = layerDto;

          const layerPlanningData = planningData.find(pd => pd.id === layerDto.planId);

          const additionalAttributes = getAdditionalLayerAttributes(layerDto.name as BuiltInLayerTypes);

          layers.push({
              id,
              name,
              readonly,
              type,
              parentId,
              geometries: null,
              defaultFillColor,
              defaultStrokeColor,
              isCustomLayer,
              machines: null,
              costFetched: false,
              fetchedGeometries: null,
              planId,
              isPlanningOpen: layerPlanningData?.isPlanningOpen || (name === 'planningEdit' && id === 0),
              additionalAttributes,
          });
          layerAttributes = layerAttributes.concat(
              attributes.map(attributeDto => this.createInterfaceLayerAttributeFromDto(attributeDto, id, name)),
          );
      });
      await this.storeService.setLayersAndAttributes(layers, layerAttributes, []);
  };

  private overwriteCostAttributeSuffix = (attributeName: string, suffix: string) => {
      const layerAttributeKeys = [
          'totalCostServicePerHectare',
          'directCostPerHectare',
          'productCostPerHectare',
          'machineCostPerHectare',
          'wageCostPerHectare',
          'operationCostPerHectare',
          'totalCostPerHectare',
      ];

      if (!layerAttributeKeys.includes(attributeName)) {
          return suffix;
      }

      const { primaryCurrency, secondaryCurrancy, isPrimarySelected } = this.store.currency;

      if ((!secondaryCurrancy && !primaryCurrency) || isNil(isPrimarySelected)) {
          return suffix;
      }

      if (isPrimarySelected) {
          return `${primaryCurrency}/ha`;
      }
      return `${secondaryCurrancy}/ha`;
  };

  private createInterfaceLayerAttributeFromDto = (
      attributeDto: LayerAttributeDto,
      layerId: number,
      layerName: string,
  ): LayerAttribute => {
      const dataLayer = new google.maps.Data();

      const { attributeKey, ...attribute } = attributeDto;
      dataLayer.setStyle({
          ...theme.map.polygonStyle,
          fillColor: attributeDto.defaultFillColor,
          strokeColor: attributeDto.defaultStrokeColor,
          strokeWeight: 2,
      });

      let suffix = attribute.suffix;

      if (layerName === BuiltInLayerTypes.METEOROLOGY) {
          const [tempUnit, pressureUnit] = this.store.global.meteorologyUnits;
          if (attributeKey === BuiltInAttributeTypes.TEMPERATURE) { suffix = tempUnit; }
          if (attributeKey === BuiltInAttributeTypes.SOIL_TEMPERATURE) { suffix = tempUnit; }
          if (attributeKey === BuiltInAttributeTypes.ATMOSPHERIC_PRESSURE) { suffix = pressureUnit; }
      }

      suffix = this.overwriteCostAttributeSuffix(attributeKey, suffix ?? '');

      return {
          ...attribute,
          attributeKey,
          suffix,
          defaultAttributeKey: attributeKey,
          layerId,
          isActive: LayerAttributeStatus.INACTIVE,
          isLabelingEnabled: true,
          layerName,
          dataLayer,
          labelLayer: new google.maps.MVCArray<google.maps.Marker>(),
          machineLayer: new google.maps.MVCArray<machineIDAndMarker>(),
          markerCluster: new MarkerClusterer({
              algorithm: new SuperClusterAlgorithm({}),
              renderer: new MapClusterRenderer(),
          }),
          legendList: [],
      };
  };

  setMapFilters = (filters: MapFilters) => {
      this.storeService.setFilters(filters);
  };

  setMapFiltersAndReloadGeometries = async (filters: MapFilters) => {
      this.storeService.setFilters(filters);
      await this.reloadLayers(undefined, filters);
  };

  loadLayerUnloadedAttributes = async (customLayer: Layer) => {
      const res = await apis.getLayer(customLayer.id);

      if (res.ok) {
          const layerCurrentAttributeIds = this.store.layering.attributes
              ?.filter(attr => attr.layerId === customLayer.id)
              ?.map(attr => attr.id);

          if (!isNil(layerCurrentAttributeIds)) {
              const attributesToLoad = res.data.attributes.filter(
                  attr => !layerCurrentAttributeIds.includes(attr.id),
              );

              let unloadedLayerAttributes: LayerAttribute[] = [];

              if (!isNil(attributesToLoad)) {
                  attributesToLoad.forEach(attr => {
                      unloadedLayerAttributes = unloadedLayerAttributes.concat(
                          this.createInterfaceLayerAttributeFromDto(attr, customLayer.id, customLayer.name),
                      );
                  });
              }

              if (!isEmpty(unloadedLayerAttributes)) {
                  this.storeService.loadUnloadedLayerAttributes(
                      unloadedLayerAttributes,
                  );
              }
          }
      }
  };

  setMapFiltersAndReloadGeometriesWithoutFetch = async (filters: MapFilters, withAdditionalDataFetch: boolean) => {
      this.storeService.setFilters(filters);
      await this.reloadLayersWithoutFetching(withAdditionalDataFetch);
  };

  reloadLayersWithoutFetching = async (withAdditionalDataFetch: boolean) => {
      await this.storeService.removeFilteredGeometries();

      const layerAttributesOnMap = this.store.layering.order.flatMap(
          attributeId => {
              const matchingLayerAttribute = this.store.layering.attributes?.find(
                  attribute => attribute.id === attributeId,
              );
              if (!matchingLayerAttribute) {
                  return [];
              }
              return matchingLayerAttribute;
          },
      );

      if (withAdditionalDataFetch) {
          await this.storeService.removeAdditionalData();
      }

      if (!isEmpty(layerAttributesOnMap)) {
          await this.removeLayerAttributesFromMap(layerAttributesOnMap);
          await this.addLayerAttributesToMap(layerAttributesOnMap);
      }
  }

  reloadLayers = async (customLayers?: Layer[], customFilters?: MapFilters) => {
      const layers = customLayers
      ?? this.store.layering.layers?.filter(layer => layer.geometries);
      if (isNil(layers) || (isEmpty(layers) && this.store.mapMode === MapMode.DEFAULT)) {
          return;
      }

      await this.storeService.removeGeometries(layers);

      layers?.forEach(layer => {
          const layerAttributes = this.store.layering.attributes?.filter(
              attribute => attribute.layerId === layer.id,
          );

          layerAttributes?.forEach(attribute => {
              attribute.dataLayer.forEach(feature => attribute.dataLayer.remove(feature));
              clearMVCArray(attribute.labelLayer);
          });
      });

      const layerAttributesOnMap = this.store.layering.order.flatMap(
          attributeId => {
              const matchingLayerAttribute = this.store.layering.attributes?.find(
                  attribute => attribute.id === attributeId,
              );
              if (!matchingLayerAttribute) {
                  return [];
              }
              return matchingLayerAttribute;
          },
      );

      const attributeList = this.store.layering.order;
      const attributes = this.store.layering.attributes?.filter(attribute => attributeList.includes(attribute.id));

      if (attributes) {
          await this.removeLayerAttributesFromMap(attributes);
      }

      if (!isNil(this.store.mapMode) && this.store.mapMode === MapMode.PLANNING) {
          await this.storeService.setLayersAndAttributes(null, null, []);
          await this.getEveryLayerAndAttribute();
      }

      const layerAttributesOnMapAfterRefetch = layerAttributesOnMap.map(attr => attr.id).flatMap(
          attributeId => {
              const matchingLayerAttribute = this.store.layering.attributes?.find(
                  attribute => attribute.id === attributeId,
              );
              if (!matchingLayerAttribute) {
                  return [];
              }
              return matchingLayerAttribute;
          },
      );

      if (!isNil(this.store.mapMode) && this.store.mapMode === MapMode.PLANNING) {
          const selectedPlanId = sessionService.getPlanningData()?.selectedPlanId;
          if (!isNil(selectedPlanId)) {
              const planLayer = this.store.layering.layers?.find(l => l.planId === selectedPlanId);
              if (!isNil(planLayer)) {
                  const planAttribute = this.store.layering.attributes?.find(attr => attr.layerId === planLayer.id && attr.isDefault);
                  if (!isNil(planAttribute)) {
                      const anyAttributeOnMap = layerAttributesOnMapAfterRefetch?.find(attr => attr.layerId === planLayer.id);
                      if (isNil(anyAttributeOnMap)) {
                          layerAttributesOnMapAfterRefetch.splice(0, 0, planAttribute);
                      }
                  }
              }
          }
      }

      await this.addLayerAttributesToMap(
          layerAttributesOnMapAfterRefetch,
          false,
          customFilters,
      );
  };

  setLayerAttributeOpacity = (layerAttribute: LayerAttribute, opacity: number) => {
      this.storeService.setLayerAttributeOpacity(layerAttribute, opacity);
  }

  removeLayerAttributesFromMap = async (layerAttributes: LayerAttribute[]) => {
      for await (const layerAttr of layerAttributes) {
          layerAttr.dataLayer.setMap(null);
          layerAttr.machineLayer.forEach(m => { m.marker.setMap(null); });
          if (layerAttr.machineLayer?.getArray().length > 0) {
              this.closeMachinePanel();
          }
          layerAttr.markerCluster.clearMarkers();

          await this.setLayerAttributeLabeling(layerAttr, false);
      }

      await this.storeService.setLayerAttributesAsDisabled(layerAttributes);
  };

  setLayerAttributeLabeling = async (
      layerAttribute: LayerAttribute,
      isLabelEnabled: boolean,
  ) => {
      await this.storeService.setLayerAttributeLabeling(
          layerAttribute,
          isLabelEnabled,
      );
      this.recalculateGlobalLabeling();
  };

  recalculateGlobalLabeling = async () => {
      const activeLayerAttributes: LayerAttribute[] = this.store.layering.attributes?.filter(attr => attr.isActive && attr.isLabelingEnabled) ?? [];

      let labelLayer: google.maps.MVCArray<google.maps.Marker>;

      if (isNil(this.store.layering.globalLabelLayer)) {
          labelLayer = new google.maps.MVCArray<google.maps.Marker>();
          await this.storeService.setGlobalLabelLayer(labelLayer);
      } else {
          labelLayer = this.store.layering.globalLabelLayer;
          labelLayer.clear();
      }

      activeLayerAttributes.sort((a, b) => this.store.layering.order.indexOf(a.id) - this.store.layering.order.indexOf(b.id));

      let clusterer: MarkerClusterer;

      if (isNil(this.store.layering.globalLabelLayerCluster)) {
          clusterer = new MarkerClusterer({ algorithm: new SuperClusterAlgorithm({}), renderer: new MapClusterRenderer() });
          await this.storeService.setGlobalLabelLayerClusterer(clusterer);
      } else {
          clusterer = this.store.layering.globalLabelLayerCluster;
          this.store.layering.globalLabelLayerCluster.clearMarkers();
      }

      activeLayerAttributes?.forEach(attr => {
          const labelArray = labelLayer.getArray();

          attr.labelLayer.forEach(labelToAdd => {
              const duplicated = labelArray.find(currentLabel => {
                  const currentPosition = currentLabel.getPosition();
                  const labelToAddPosition = labelToAdd.getPosition();
                  if (!isNil(currentPosition) && !isNil(labelToAddPosition)) {
                      return currentPosition?.equals(labelToAddPosition);
                  }
                  return false;
              });
              if (!isNil(duplicated)) {
                  const duplicatedIndex = labelArray.indexOf(duplicated);
                  const duplicatedTitle = duplicated.getTitle() ?? 'N/A';
                  labelLayer.removeAt(duplicatedIndex);
                  duplicated.setMap(null);

                  const currentTitle = labelToAdd.getTitle() ?? 'N/A';

                  let labelToAddTitle;
                  if (!isNil(duplicatedTitle) && !isNil(currentTitle)) {
                      labelToAddTitle = `${duplicatedTitle}@|@${currentTitle}`;
                  }
                  const labelToAddPosition = duplicated.getPosition();
                  if (!isNil(labelToAddPosition)) {
                      labelLayer.push(createLabel(labelToAddPosition, labelToAddTitle, this.store.googleRefs.map));
                  }
              } else {
                  labelLayer.push(labelToAdd);
              }
          });
      });
      const markers = labelLayer.getArray();
      clusterer.addMarkers(markers);

      if (isNil(clusterer.getMap())) {
          clusterer.setMap(this.store.googleRefs.map);
      }
  };

  setLayerAttributeMachines = async (
      layerAttribute: LayerAttribute,
      isEnabled: boolean,
  ) => {
      if (isEnabled) {
          layerAttribute.markerCluster.clearMarkers();
          layerAttribute.markerCluster.setMap(null);
          layerAttribute.markerCluster.render();

          layerAttribute.machineLayer.forEach(machine => {
              layerAttribute.markerCluster.addMarker(machine.marker);
          });
          if (this.store.googleRefs.map) {
              layerAttribute.markerCluster.setMap(this.store.googleRefs.map);
              layerAttribute.markerCluster.render();
          }
      } else {
          layerAttribute.markerCluster.clearMarkers();
          layerAttribute.markerCluster.setMap(null);
      }
      this.storeService.setLayerAttributeLabeling(layerAttribute, isEnabled);
  };

  addLayerToMap = async (layers: Layer[]) => {
      layers.forEach(lay => {
          const attributes = this.store.layering.attributes?.filter(
              attr => !attr.isActive && attr.layerId === lay.id,
          );

          if (!isNil(attributes) && !isEmpty(attributes)) {
              this.addLayerAttributesToMap([attributes[0]]);
          }
      });
  };

  addLayerAttributesToMap = async (
      _layerAttributes: LayerAttribute[],
      withLayerPanel = true,
      customFilters?: MapFilters,
  ) => {
      const layerAttributes = compact(_layerAttributes);

      if (layerAttributes.length === 0) {
          return;
      }

      let shouldFetchCosts = false;

      let layersWithoutGeometries: Layer[] = [];
      const layerAttributesWithoutGeometries: LayerAttribute[] = [];

      layerAttributes.forEach(layerAttribute => {
          const parentLayer = this.store.layering.layers?.find(
              layer => layer.name === layerAttribute.layerName,
          );

          if (!isNil(parentLayer)) {
              if (parentLayer.name === BuiltInLayerTypes.CULTIVATION_PERIOD || parentLayer.name === BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP) {
                  if (isAttributeWithCost(layerAttribute.defaultAttributeKey)) {
                      if (!parentLayer.costFetched) {
                          shouldFetchCosts = true;
                          layersWithoutGeometries.push(parentLayer);
                          layerAttributesWithoutGeometries.push(layerAttribute);
                      }
                  } else if (parentLayer && isNil(parentLayer.fetchedGeometries)) {
                      layersWithoutGeometries.push(parentLayer);
                      layerAttributesWithoutGeometries.push(layerAttribute);
                  }
              } else if (parentLayer.name === BuiltInLayerTypes.MACHINES || parentLayer.name === BuiltInLayerTypes.IRRIGATION) {
                  if (isNil(parentLayer.machines)) {
                      layersWithoutGeometries.push(parentLayer);
                      layerAttributesWithoutGeometries.push(layerAttribute);
                  }
              } else if (parentLayer && isNil(parentLayer.fetchedGeometries)) {
                  layersWithoutGeometries.push(parentLayer);
                  layerAttributesWithoutGeometries.push(layerAttribute);
              }
          }
      });

      const layersWithoutGeometriesHasMachine = layersWithoutGeometries.find(layer => layer.name === BuiltInLayerTypes.MACHINES || layer.name === BuiltInLayerTypes.IRRIGATION);
      if (shouldFetchCosts) {
          const cultivationPeriod = this.store.layering.layers?.filter(layer => [BuiltInLayerTypes.CULTIVATION_PERIOD, BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP].includes(layer.name as BuiltInLayerTypes));
          if (!isNil(cultivationPeriod)) {
              await this.storeService.removeFilteredGeometries(cultivationPeriod);
          }
      }

      if (!isNil(layersWithoutGeometriesHasMachine)) {
          await this.getMachinesForLayer();
          layersWithoutGeometries = layersWithoutGeometries.filter(layer => layer.name !== BuiltInLayerTypes.MACHINES && layer.name !== BuiltInLayerTypes.IRRIGATION);
      }

      await this.getGeometriesForLayers({
          layers: layersWithoutGeometries,
          shouldFetchCosts,
          customFilters,
          layerAttributes: layerAttributesWithoutGeometries,
      });

      const layersWithoutFilteredGeoms: Layer[] = [];

      layerAttributes.forEach(attr => {
          const parentLayer = this.store.layering.layers?.find(layer => layer.id === attr.layerId);
          if (!isNil(parentLayer)) {
              if (isNil(parentLayer.geometries)) {
                  layersWithoutFilteredGeoms.push(parentLayer);
              }
          }
      });

      await this.filterGeometriesForLayers(layersWithoutFilteredGeoms);

      const hasLastWorkOperationMainGroup = layerAttributes.find(attr => attr.attributeKey === BuiltInAttributeTypes.LAST_WORK_OPERATION_MAIN_GROUP);

      if (!isNil(hasLastWorkOperationMainGroup)) {
          const additionalDataLayer = this.store.layering.layers?.find(layer => layer.id === hasLastWorkOperationMainGroup.layerId);
          if (!isNil(additionalDataLayer)) {
              if (isNil(additionalDataLayer.adittionalGeometryData)) {
                  await this.getAdditionalDataForLayer([additionalDataLayer]);
              }
          }
      }

      const isTimeLineActive = !isNil(this.store.mapSlider) && !isNil(this.store.mapSlider?.startDate) && !isNil(this.store.mapSlider?.endDate);

      layerAttributes.forEach(layerAttribute => {
          const parentLayer = this.store.layering.layers?.find(
              layer => layer.name === layerAttribute.layerName,
          );

          if (!parentLayer) {
              return;
          }
          if (parentLayer.name === BuiltInLayerTypes.METEOROLOGY) {
              if (this.store.googleRefs.map) {
                  const layerFeatureCollection = parentLayer.geometries as FeatureCollection;
                  if (!isTimeLineActive) {
                      this.loadGeometriesToDataLayer(layerAttribute, layerFeatureCollection);
                  } else {
                      this.loadAttributeByInterval(layerAttribute, this.store.mapSlider.startDate as Date, this.store.mapSlider.endDate);
                  }
              }
          } else if (parentLayer.name === BuiltInLayerTypes.MACHINES || parentLayer.name === BuiltInLayerTypes.IRRIGATION) {
              const layerMachines = parentLayer.machines;
              layerAttribute.machineLayer.clear();
              layerAttribute.dataLayer.setMap(this.store.googleRefs.map);
              layerMachines?.forEach(machine => {
                  let text = machine.deviceID.toString();
                  const machineLocation: google.maps.LatLngLiteral = { lat: machine.attributes.Position.coordinates[0], lng: machine.attributes.Position.coordinates[1] };
                  if (typeof (text) !== 'number' && (isEmpty(text) || isNil(text))) {
                      text = 'undefined';
                  }
                  const machineMarker = createMachineMarker(machineLocation, text, machine.profilePicture);
                  layerAttribute.machineLayer.push({
                      deviceId: machine.deviceID,
                      marker: machineMarker,
                  });
              });
              this.setLayerAttributeMachines(layerAttribute, true);
          } else if (!isNil(this.store.mapSlider) && !isNil(this.store.mapSlider?.startDate) && !isNil(this.store.mapSlider?.endDate)) {
              this.loadAttributeByInterval(layerAttribute, this.store.mapSlider.startDate, this.store.mapSlider.endDate);
          } else {
              const layerFeatureCollection = parentLayer.geometries as FeatureCollection;
              this.loadGeometriesToDataLayer(layerAttribute, layerFeatureCollection);
          }
      });

      this.storeService.setLayerAttributesAsActive(layerAttributes);
      // this.storeService.setLayeringLoading(false);
  };

  async filterGeometriesForLayers(layers: Layer[]) {
      // eslint-disable-next-line array-callback-return

      const layerIds = layers.map(layer => layer.id);

      const layersWithFetchGeoms = this.store.layering.layers?.filter(layer => !isNil(layer.fetchedGeometries) && layerIds.includes(layer.id));
      if (!isNil(layersWithFetchGeoms)) {
          const layerFilteredGeoms: Partial<LayerGeomsDto>[] = layersWithFetchGeoms.map(layer => {
              const collection = cloneDeep(layer.fetchedGeometries as FeatureCollection<Geometry, GeoJsonProperties>);

              //filter: showClosedPeriods
              if (layer.name === BuiltInLayerTypes.CULTIVATION_PERIOD && !this.store.filters.showClosedPeriods) {
                  const filteredFeatures = collection.features.filter(feature => isNil(feature.properties?.isClosedPeriod) || (!isNil(feature.properties?.isClosedPeriod) && Boolean(+feature.properties?.isClosedPeriod) === this.store.filters.showClosedPeriods));
                  collection.features = filteredFeatures;
              }

              //filter: croptype
              if (layer.name === BuiltInLayerTypes.CULTIVATION_PERIOD && this.store.filters.cropType !== CROP.TYPE.PLANTATION) {
                  const filteredFeatures = collection.features.filter(feature => feature.properties?.cropTypeId === this.store.filters.cropType);
                  collection.features = filteredFeatures;
              }

              if (MapMode.PLANNING && this.store.filters.cropType !== CROP.TYPE.PLANTATION) {
                  const isPlanningLayer = this.isPlanningLayer(layer.id);
                  if (isPlanningLayer) {
                      const filteredFeatures = collection.features.filter(feature => feature.properties?.cropTypeId === this.store.filters.cropType);
                      collection.features = filteredFeatures;
                  }
              }

              //filter: isActive
              if (this.store.filters.statusId !== MAP_FILTER_STATUS.ACTIVE_AND_INACTIVE) {
                  const filteredFeatures = collection?.features.filter(feature => isNil(feature.properties?.isActive)
                            || (!isNil(feature.properties?.isActive) && feature.properties?.isActive === this.store.filters.statusId));

                  collection.features = filteredFeatures;
              }

              //filter: companies
              if (!isEmpty(this.store.filters.companyIds)) {
                  const filteredFeatures = collection?.features.filter(feature => isNil(feature.properties?.companyIds)
                            || (!isNil(feature.properties?.companyIds) && hasCommonValue(feature.properties?.companyIds, this.store.filters.companyIds)));

                  collection.features = filteredFeatures;
              }

              //filter: cultures
              if (!isEmpty(this.store.filters.cultureIds)) {
                  const filteredFeatures = collection?.features.filter(feature => (isNil(feature.properties?.cultureId) && isEmpty(this.store.filters.cultureIds))
                          || (!isNil(feature.properties?.cultureId) && hasCommonValue([feature.properties?.cultureId], this.store.filters.cultureIds)));

                  collection.features = filteredFeatures;
              }

              //filter: farmId
              if (!isEmpty(this.store.filters.farmIds)) {
                  const filteredFeatures = collection?.features.filter(feature => isNil(feature.properties?.farmIds)
                          || (!isNil(feature.properties?.farmIds) && hasCommonValue(feature.properties?.farmIds, this.store.filters.farmIds)));

                  collection.features = filteredFeatures;
              }
              const bbox = calculateBBox(collection);

              const filteredGeoms = {
                  geoms: collection,
                  id: layer.id,
                  bbox: [bbox[0], bbox[1], bbox[2], bbox[3]],
              };

              if (collection.features.length === 0 && !isNil(this.store.center.bbox)) {
                  filteredGeoms.bbox = [...this.store.center.bbox];
              }

              return filteredGeoms;
          });
          this.storeService.setFilteredGeometriesForLayers(layerFilteredGeoms);
      }
  }

   getAdditionalDataForLayer = async (layers_: Layer[]) => {
       const layerIds = layers_.map(x => x.id);
       const layers = this.store.layering.layers?.filter(layer => layerIds.includes(layer.id));
       const cultivationPeriodLayer = layers?.find(layer => layer.name === BuiltInLayerTypes.CULTIVATION_PERIOD);
       const meterologyLayer = layers?.find(layer => layer.name === BuiltInLayerTypes.METEOROLOGY);
       let startDateToQuery: string;
       let endDateToQuery: string;

       if (!isNil(this.store.mapSlider.startDate) && this.store.mapSlider.isEnabled) {
           const day = dateTimeUtils.getDateObject(this.store.mapSlider.startDate);
           switch (this.store.mapSlider.intervalMode) {
               case MapSliderIntervalMode.MONTH:
                   startDateToQuery = day.clone().startOf('month').format('YYYY-MM-DD');
                   endDateToQuery = day.clone().endOf('month').format('YYYY-MM-DD');
                   break;
               case MapSliderIntervalMode.WEAK:
                   startDateToQuery = day.clone().startOf('week').format('YYYY-MM-DD');
                   endDateToQuery = day.clone().endOf('week').format('YYYY-MM-DD');
                   break;
               default:
                   //year
                   startDateToQuery = day.clone().startOf('year').format('YYYY-MM-DD');
                   endDateToQuery = day.clone().endOf('year').format('YYYY-MM-DD');
                   break;
           }
       } else {
           const todayKey = dateTimeUtils.getDefaultDateNow();
           startDateToQuery = todayKey;
           endDateToQuery = todayKey;
       }

       let params :any = {
           status: this.store.filters.workOperationStatus,
       };

       if (this.store.mapSlider.isEnabled) {
           params = {
               ...params,
               startDate: startDateToQuery,
               endDate: endDateToQuery,
           };
       }

       if (!isNil(cultivationPeriodLayer)) {
           const storeCultivationPeriod = this.store.layering.layers?.find(layer => layer.name === BuiltInLayerTypes.CULTIVATION_PERIOD);

           const featureIds:number[] = [];
           storeCultivationPeriod?.geometries?.features.forEach(feature => {
               const entitiyId = feature?.properties?.entityId;
               if (!isNil(entitiyId)) {
                   featureIds.push(Number(entitiyId));
               }
           });

           const additionalData = await gisService.getWorkoperationStatusData(params);
           await this.storeService.setAdditionalGeometryData(cultivationPeriodLayer, {
               geomData: additionalData,
           });
       }
       if (!isNil(meterologyLayer)) {
           const storeMeterology = this.store.layering.layers?.find(layer => layer.name === BuiltInLayerTypes.METEOROLOGY);

           const locationIds:number[] = [];
           storeMeterology?.geometries?.features.forEach(feature => {
               const locationId = feature?.properties?.entityId;
               if (!isNil(locationId)) {
                   locationIds.push(Number(locationId));
               }
           });

           const meteorologyAdditionalData = await gisService.getMeteorology({});

           const groupedMeteorologyData = groupBy(meteorologyAdditionalData, record => `${record.locationId}-${record.date}`);
           const meteorologyDatas = map(groupedMeteorologyData, group => maxBy(group, 'dateTime'));

           await this.storeService.setAdditionalGeometryData(meterologyLayer, {
               geomData: meteorologyDatas,
           });
       }
   }

  loadGeometriesToDataLayer = (layerAttribute: LayerAttribute, collection: FeatureCollection) => {
      if (isNil(collection?.features)) {
          return;
      }

      const findLayerAttribure = this.store.layering.attributes?.find(x => x.id === layerAttribute.id);

      //   if (findLayerAttribure?.isActive === LayerAttributeStatus.PENDING || findLayerAttribure?.layerName === Buil) {
      layerAttribute.dataLayer.forEach(f => {
          layerAttribute.dataLayer.remove(f);
      });
      clearMVCArray(layerAttribute.labelLayer);
      //   }

      const featuresAllIds = collection.features?.map(fet => Number(fet.id));
      const featuresOnLayersAttrIds:number[] = [];

      const featuresToAddIds: number[] = [];

      layerAttribute.dataLayer.forEach(feature => {
          const currentFeatureId = Number(feature.getId());
          if (currentFeatureId) {
              if (!featuresAllIds.includes(currentFeatureId)) {
                  layerAttribute.dataLayer.remove(feature);
              } else {
                  featuresOnLayersAttrIds.push(Number(currentFeatureId));
              }
          }
      });

      featuresAllIds.forEach(id => {
          if (!featuresOnLayersAttrIds.includes(id)) {
              featuresToAddIds.push(id);
          }
      });

      const labelRemoveIndexes:number[] = [];

      layerAttribute.labelLayer.forEach((label, i) => {
          const geomId = Number(label?.get('geomId'));
          if (!featuresOnLayersAttrIds.includes(geomId)) {
              labelRemoveIndexes.push(i);
              layerAttribute.markerCluster.removeMarker(label);
              label.unbindAll();
          }
      });

      labelRemoveIndexes.forEach((removeIndex, i) => {
          layerAttribute.labelLayer.removeAt(removeIndex - i);
      });

      const tDataLayer = new google.maps.Data();

      tDataLayer.addGeoJson(collection);

      tDataLayer.forEach(feature => {
          const currentFeatureId = Number(feature.getId());
          if (featuresToAddIds.includes(currentFeatureId)) {
              const nFeature = new google.maps.Data.Feature({ id: currentFeatureId });
              nFeature.setGeometry(feature.getGeometry());
              feature.forEachProperty((value, key) => {
                  nFeature.setProperty(key, value);
              });
              nFeature.setProperty('geomId', feature.getId());
              layerAttribute.dataLayer.add(nFeature);
          }
      });

      const parentLayer = this.store.layering.layers?.find(layer => layer.id === layerAttribute.layerId);

      layerAttribute.dataLayer.setMap(this.store.googleRefs.map);
      if (!isNil(parentLayer)) {
          createLabelsAndColoring(layerAttribute, this.store.global, this.storeService, this.store.mapSlider, parentLayer);
      }
      if (layerAttribute.isLabelingEnabled) {
          layerAttribute.dataLayer.setStyle({
              ...layerAttribute.dataLayer.getStyle(),
              fillOpacity: 0.8,
              strokeOpacity: 1,
          });
          if (!layerAttribute.isLabelingEnabled) {
              this.setLayerAttributeLabeling(layerAttribute, true);
          }
          layerAttribute.markerCluster.render();
      }

      if (layerAttribute.layerName === BuiltInLayerTypes.SENT_TASK) {
          layerAttribute.dataLayer.forEach(f => {
              layerAttribute.dataLayer.overrideStyle(f, {
                  icon: {
                      url: `${window.location.origin}/styles/img/blue-marker.png`,
                      scaledSize: new window.google.maps.Size(40, 40),
                  },
              });
          });
      }

      if (layerAttribute.layerName === BuiltInLayerTypes.RECEIVED_SCOUTINGS) {
          layerAttribute.dataLayer.forEach(f => {
              layerAttribute.dataLayer.overrideStyle(f, {
                  icon: {
                      url: `${window.location.origin}/styles/img/red-marker.png`,
                      scaledSize: new window.google.maps.Size(40, 40),
                  },
              });
          });
      }

      if (layerAttribute.layerName === BuiltInLayerTypes.METEOROLOGY) {
          layerAttribute.dataLayer.forEach(f => {
              layerAttribute.dataLayer.overrideStyle(f, {
                  icon: {
                      url: `${window.location.origin}/styles/img/orange-marker.png`,
                      scaledSize: new window.google.maps.Size(40, 40),
                  },
              });
          });
      }
  }

  getGeometriesForLayers = async (props: GetGeometriesForLayersProps) => {
      const { layers, customFilters, shouldFetchCosts = false, layerAttributes } = props;
      if (layers.length === 0) {
          return;
      }

      const isTimeLineActive = !isNil(this.store.mapSlider) && !isNil(this.store.mapSlider?.startDate) && !isNil(this.store.mapSlider?.endDate);

      const filters = customFilters ?? this.store.filters;

      const layerIds = layers.map(layer => layer.id);
      const filterEntityIds = filters.entity?.ids;
      const excludedIds = filters.entity?.excludedIds;
      const showClosedPeriods = !isEmpty(filters.showClosedPeriods || [true]);
      const statusId = filters?.statusId ?? 1;
      const cropType = filters?.cropType ?? CROP.TYPE.PLANTATION;
      const productionYearId = filters?.productionYearId;

      const geometryQuery = queryBuilder()
          .with('productionYear', this.store.mapSlider.isEnabled ? -1 : filters.productionYearId)
          .with('layers', layerIds)
          .with('entities', filterEntityIds?.join(','))
          .build();
      await this.storeService.setLayerAttributesAsPending(layerAttributes);

      let res;

      let isPlanningEdit = false;

      if (this.store.fetcher.geomFetcher) {
          res = await this.store.fetcher.geomFetcher();
          isPlanningEdit = true;
      } else {
          res = await apis.fetchGeometriesForLayers(geometryQuery);
      }

      if (!res.ok) {
          alert('Unexpected error while fetching geometries for layer');
      }

      layerAttributes.forEach(attr => {
          if (attr.layerName !== BuiltInLayerTypes.METEOROLOGY) {
              const featureCollection = res.data.layerGeoms.find(feature => feature.id === attr.layerId) as any;
              if (!isNil(featureCollection)) {
                  if (isNil(this.store.mapSlider) || (!this.store.mapSlider.isEnabled)) {
                      this.loadGeometriesToDataLayer(attr, featureCollection.geoms);
                  } else if (!isNil(this.store.mapSlider.startDate) && !isNil(this.store.mapSlider.endDate)) {
                      this.loadAttributeByInterval(attr, this.store.mapSlider.startDate, this.store.mapSlider.endDate, featureCollection.geoms);
                  }
              }
          }
      });

      const layersGeometryData = res.data;

      if (isEmpty(layersGeometryData.layerGeoms)) {
          return;
      }

      const filteredGeomDtos = layersGeometryData.layerGeoms.filter(layerGeomDto => layerGeomDto.name !== BuiltInLayerTypes.METEOROLOGY || !isTimeLineActive);

      const layerFeatureCollections = await Promise.all(
          filteredGeomDtos
          // .filter(layerGeom => layerGeom.geoms.features?.length > 0)
              .map(async layerGeom => {
                  const layerId = layerGeom.id;
                  const isCustomLayer = !Object.values(BuiltInLayerTypes).includes(
                      layerGeom.name as BuiltInLayerTypes,
                  );
                  const entityIds = layerGeom.geoms.features.map(
                      feature => feature.properties?.entityId as number,
                  );
                  const featureIds = layerGeom.geoms.features.map(
                      feature => feature.id as number,
                  );

                  const isPlanningLayer = this.isPlanningLayer(layerId);

                  let layerData: any[] = [];

                  if (isPlanningEdit) {
                      return layerGeom;
                  }

                  if (isPlanningLayer) {
                      const planId = this.store.layering.layers?.find(layer => layer.id === layerId)?.planId;
                      layerData = await getDataForLayer({
                          entityIds,
                          productionYearId,
                          planId,
                          layerName: BuiltInLayerTypes.PLANNING,
                      });
                  } else if (!isCustomLayer) {
                      layerData = await getDataForLayer({
                          entityIds,
                          shouldFetchCosts,
                          showClosedPeriods,
                          cropType,
                          statusId,
                          layerName: layerGeom.name,
                      });
                  } else {
                      layerData = await this.getGeomAttributeData(featureIds);
                  }

                  layerGeom.geoms.features = layerGeom.geoms.features
                      ?.map(feature => {
                          const entityData = (!isCustomLayer || isPlanningLayer)
                              ? layerData?.find(
                                  geomData => geomData.entityId === feature.properties?.entityId,
                              )
                              : layerData?.find(geomData => geomData.geomId === feature.id);
                          return {
                              ...feature,
                              properties: {
                                  ...feature.properties,
                                  ...entityData,
                                  shouldDelete: (isNil(entityData) && layerGeom.name === BuiltInLayerTypes.CULTIVATION_PERIOD),
                              },
                          };
                      })
                      ?.filter(feature => !feature.properties.shouldDelete)
                      ?.filter(feature => (isArray(excludedIds)
                          ? !excludedIds?.includes(feature?.properties?.entityId)
                          : true));

                  // ?.filter(feature => (isArray(filterEntityIds) ? filterEntityIds?.includes(feature?.properties?.entityId) : true));
                  return layerGeom;
              }),
      );

      if (isTimeLineActive) {
          const meterologyLayer = layers.find(layer => layer.name === BuiltInLayerTypes.METEOROLOGY);
          if (meterologyLayer) {
              const meterologyLayerStore = this.store.layering.layers?.find(layer => layer.id === meterologyLayer?.id) as Layer;
              const meterologyDataList = meterologyLayerStore.adittionalGeometryData?.geomData as any[];
              const meterologyGeoms = layersGeometryData.layerGeoms.find(layerGeom => layerGeom.name === BuiltInLayerTypes.METEOROLOGY);

              if (meterologyGeoms) {
                  const meterologyFeatures = meterologyDataList?.map(meterologyData => {
                      const geom = meterologyGeoms?.geoms.features.find(geomFeature => geomFeature.properties?.entityId === meterologyData.locationId);

                      return {
                          ...geom,
                          properties: {
                              ...(geom?.properties || {}),
                              ...meterologyData,
                              startDate: meterologyData?.date,
                              endDate: meterologyData?.date,
                          },
                      };
                  })?.filter(geom => !isNil(geom?.geometry));

                  meterologyGeoms.geoms.features = meterologyFeatures as Feature[];
              }

              if (meterologyGeoms) {
                  layerFeatureCollections.push(meterologyGeoms);
              }
          }
      }

      this.storeService.setGeometriesForLayers(layerFeatureCollections, shouldFetchCosts);
  };

  getGeomAttributeData = async (featureIds: number[]): Promise<any[]> => {
      const query = queryBuilder().with('geomIds', featureIds?.join(',')).build();

      const res = await apis.getGeomAttributeData(query);

      if (!res.ok) {
          alert('Error');
          return [];
      }

      return res.data;
  };

  getMachinesForLayer = async () => {
      const machines = await apis.fetchMachines();
      if (!machines.ok) {
          alert('Unexpected error while fetching machines for layer');
          return;
      }

      if (isEmpty(machines.data)) {
          return;
      }

      const bounds = new google.maps.LatLngBounds();
      machines?.data?.forEach(m => {
          bounds.extend(
              new google.maps.LatLng(
                  m.attributes.Position.coordinates[0],
                  m.attributes.Position.coordinates[1],
              ),
          );
      });
      this.store.googleRefs.map?.fitBounds(bounds);

      this.storeService.setMachines(machines.data, [
          bounds.getSouthWest().lng(),
          bounds.getSouthWest().lat(),
          bounds.getNorthEast().lng(),
          bounds.getNorthEast().lat(),
      ]);
  };

  setLayerAttributeOrder = async (order: number[]) => {
      this.storeService.setOrder(order);
  };

  openGeometryInfoPanel = (
      layerId: number,
      feature: google.maps.Data.Feature,
  ) => {
      feature.toGeoJson((json: object) => this.storeService.setSelectedGeometry({
          geom: json as Feature,
          layerId,
          isInfoModalOpen: true,
      }));
  };

  closeGeometryInfoPanel = () => {
      this.storeService.setSelectedGeometry(initStore.selectedGeometry);
  };

  openMachinePanel = (
      layerId: number,
      deviceId: number,
      machineMarker: google.maps.Marker,
  ) => {
      this.storeService.setSelectedMachine(
          machineMarker,
          layerId,
          deviceId,
          true,
      );
  };

  closeMachinePanel = () => {
      this.storeService.setSelectedMachine(null, -1, -1, false);
  };

  openGeometryLegendPanel = (
      layerId: number,
      feature: google.maps.Data.Feature,
  ) => {
      feature.toGeoJson((json: object) => this.storeService.setSelectedGeometry({
          geom: json as Feature,
          layerId,
          isInfoModalOpen: true,
      }));
  };

  closeGeometryLegendPanel = () => {
      this.storeService.setSelectedGeometry(initStore.selectedGeometry);
  };

  setGoogleRef = async (googleRef: typeof google) => {
      this.storeService.setGoogle(googleRef);
  };

  setMapRef = async (map: google.maps.Map) => {
      this.storeService.setMapRef(map);
  };

  setCommonData = (
      primaryCurrency: string,
      cultures: unknown[],
      workOperationMainGroups: unknown[],
      meteorolgyUnits: unknown[],
  ) => {
      this.storeService.setCommonData(
          primaryCurrency,
          cultures,
          workOperationMainGroups,
          meteorolgyUnits,
      );
  };

  setDrawingManagerRefs = async (
      drawingManager: google.maps.drawing.DrawingManager,
      drawDataLayer: google.maps.Data,
  ) => {
      this.storeService.setDrawingManagerRefs(drawingManager, drawDataLayer);
  };

  downloadShapefile = async (
      geomId: number,
      geomProperties: GeoJsonProperties,
  ) => {
      const res = await apis.getShapefileOfGeometry({
          body: { properties: geomProperties },
          geomId,
      });
      if (!res.ok) {
          alert('Unexpected error while downloading SHP file');
      }
      FileSaver.saveAs(res.data, `agrovir-shapefile-${geomId}.zip`);
  };

  processGeometryFileAndSetAsDrawnGeometry = async (formData: FormData, onFileUploadError, withSlovakOverlay = false, selectedLpisPolygon) => {
      this.storeService.setDrawingLoading(true);
      const res = await apis.processGeometryFile(formData);
      const drawDataLayer = this.store.drawing.dataLayer;
      const { error, warning, featureCollection } = res.data;

      if (res.status === 413) {
          onFileUploadError(ProcessGeomErrorCode.FILE_TOO_LARGE, warning);
          return;
      }

      onFileUploadError(error, warning);

      if (!res.ok) {
          return;
      }

      drawDataLayer?.forEach(feature => drawDataLayer.remove(feature));
      const [feature] = featureCollection.features;
      delete feature.properties?.geomId;
      delete feature?.id;

      if (withSlovakOverlay && drawDataLayer && feature && selectedLpisPolygon) {
          validatePolygonForSK({ drawnFeature: feature as any, dataLayer: drawDataLayer, selectedLpisPolygon }).then(({ errors, newPolygon }) => {
              drawDataLayer?.addGeoJson(newPolygon as any);
              this.setDrawnGeometry(newPolygon as any);
          });
      } else {
          drawDataLayer?.addGeoJson(feature);
          this.setDrawnGeometry(feature);
      }

      this.storeService.setDrawingLoading(false);
  };

  getGeomAndAddToDrawDataLayer = async (entityId: number, layerId: number) => {
      const query = queryBuilder()
          .with('entity', entityId)
          .with('layer', layerId)
          .build();
      const res = await apis.fetchGeometry(query);

      if (res.status === 404) {
          return;
      }
      if (!res.ok) {
          alert('Failed to fetch geometry');
          return;
      }

      const geometry = res.data;
      this.addGeometryToDrawDataLayer(geometry);
  };

  addGeometryToDrawDataLayer = (geometryFeature: Feature) => {
      this.setDrawnGeometry(geometryFeature);
      this.store.drawing.dataLayer?.addGeoJson(geometryFeature);
      const bounds = new google.maps.LatLngBounds();
      this.store.drawing.dataLayer?.forEach(feature => feature.getGeometry()?.forEachLatLng(latLng => bounds.extend(latLng)));
      this.store.googleRefs.map?.fitBounds(bounds);
  };

  setDrawnGeometry = async (geometryFeature: Nullable<Feature>) => {
      const currentGeometry = this.store.drawing.drawnFeature;
      if (currentGeometry && geometryFeature) {
          this.storeService.setDrawnGeometry({
              ...currentGeometry,
              geometry: geometryFeature.geometry,
          });
      } else {
          this.storeService.setDrawnGeometry(geometryFeature);
      }
  };

  diffDrawnGeometryWithGeometry = async (geometryFeature: Feature) => {
      await this.callGeometryOperation(geometryFeature, apis.diffGeometries);
  };

  unionDrawnGeometryWithGeometry = async (geometryFeature: Feature) => {
      await this.callGeometryOperation(geometryFeature, apis.unionGeometries);
  };

  callGeometryOperation = async (
      geometryFeature: Feature,
      apiCall: (
      body: unknown
    ) => Promise<ResponseWrapper<Geometry> | Record<string, never>>,
  ) => {
      const drawDataLayer = this.store.drawing.dataLayer;
      this.storeService.setDrawingLoading(true);

      drawDataLayer?.toGeoJson(async (json: object) => {
          const featureCollection = json as FeatureCollection;
          const reqBody = {
              baseGeometry: featureCollection.features[0]?.geometry,
              newGeometry: geometryFeature.geometry,
          };

          const res = await apiCall(reqBody);

          if (!res.ok) {
              alert('Unexpected error during geometry operation');
              return;
          }

          const newGeometry = res.data;

          if (newGeometry) {
              const newFeature = convertToFeature(newGeometry);
              this.setDrawnGeometry(newFeature);
              const newFeatureCollection = convertToFeatureCollection([newFeature]);
              drawDataLayer?.forEach(drawnFeature => drawDataLayer.remove(drawnFeature));
              drawDataLayer?.addGeoJson(newFeatureCollection);
          }
      });

      this.storeService.setDrawingLoading(false);
  };

  setMultiSelectedGeometriesDataLayer(
      yearId: number,
      dataLayer: google.maps.Data,
  ) {
      this.storeService.setMultiSelectedGeometriesDataLayer(yearId, dataLayer);
  }

  setMultiSelectedGeometriesFeatures(
      yearId: number,
      features: Nullable<Feature[]>,
  ) {
      this.storeService.setMultiSelectedGeometriesFeatures(yearId, features);
  }

  setMapIsMeasuring(isMeasuring: boolean) {
      this.storeService.setIsMapMeasuring(isMeasuring);
  }

  setMapMeasureInfo(area: number, length: number) {
      this.storeService.setMapMeasureInfo(area, length);
  }

  setGoogleMapStlyeOverride = (styles: google.maps.MapTypeStyle[]) => {
      this.storeService.setGoogleMapStlyeOverride(styles);
  };

  createLayerData = async (layerDto: CreateLayerDto) => {
      const res = await apis.createLayer(layerDto);
      if (res.status === 201) {
          if (res.data?.raw?.[0]) {
              const id = res.data.raw[0].id;
              const layer: Layer = {
                  defaultFillColor: layerDto.defaultFillColor,
                  defaultStrokeColor: layerDto.defaultStrokeColor,
                  geometries: null,
                  machines: null,
                  id,
                  name: layerDto.name,
                  parentId: layerDto.parentId,
                  readonly: false,
                  type: layerDto.type,
                  costFetched: false,
                  fetchedGeometries: null,
              };
              this.storeService.addLayer(layer);
          }
      }
  };

  updateLayerData = async (layerId: number, layerDto: UpdateLayerDto) => {
      const res = await apis.updateLayer(layerId, layerDto);
      if (res.status === 200) {
          this.storeService.updateLayer(layerDto);
      }
  };

  removeLayerData = async (layerId: number, childrenIds: number[]) => {
      const res = await apis.removeLayer(layerId);

      if (res.ok) {
          if (res.data?.affected === 1) {
              const layerIdsToRemove = [layerId, ...childrenIds];

              const attributesToRemove = this.store.layering.attributes?.filter(
                  attr => layerIdsToRemove.includes(attr.layerId),
              );
              attributesToRemove?.forEach(attr => {
                  clearMVCArray(attr.labelLayer);
              });

              if (attributesToRemove) {
                  this.removeLayerAttributesFromMap(attributesToRemove);
              }

              this.storeService.removeLayers(layerIdsToRemove);
          }
      }
  };

  setAutoZoom = (isAutoZoom: boolean) => {
      this.storeService.setAutoZoom(isAutoZoom);
  };

  setZoomLevel = (zoomLevel: number) => {
      this.storeService.setZoomLevel(zoomLevel);
  };

  setLayerSelectorSettings = (settings?: LayerSelectorSettings) => {
      this.storeService.setLayerSelectorSettings(settings);
  };

  setPrimaryCurrencySelected = async (
      isSelected: boolean,
      primaryCurrency: string,
      secondaryCurrancy: string,
  ) => {
      await this.storeService.setPrimaryCurrencySelected(
          isSelected,
          primaryCurrency,
          secondaryCurrancy,
      );
  };

  changeLayerAttributeKeyAndSuffix = async (
      layerAttribute: LayerAttribute,
      newLayerAttributeKey: string,
      newSuffix?: string,
  ) => {
      await this.storeService.setLayerAttributeAttributeKey(
          layerAttribute,
          newLayerAttributeKey,
          newSuffix,
      );
  };

  relabelLayerAttributeLabels = (layerAttribute: LayerAttribute) => {
      const attrLayer = this.store.layering.layers?.find(
          layer => layer.id === layerAttribute.layerId,
      );
      const attribute = this.store.layering.attributes?.find(
          attr => attr.id === layerAttribute.id,
      );
      if (!isNil(attribute) && !isNil(attrLayer)) {
          createLabelsAndColoring(
              attribute,
              this.store.global.cultures,
              this.storeService,
              this.store.mapSlider,
              attrLayer,
          );
      }
  };

  getInterfaceSettings = () => {
      const interfaceSettings = this.store.layering.order
          .map(layerAttributeId => this.store.layering.attributes?.find(
              layerAttributes => layerAttributes.id === layerAttributeId,
          ))
          ?.map(attr => ({
              id: attr?.id,
              isActive: attr?.isActive,
              isLabelingEnabled: attr?.isLabelingEnabled,
          }));
      const fileName = `${INTERFACE_FILENAME.MAP}_${this.store.mapId}_${window.location.pathname}`;

      const stringifiedInterfaceSettings = JSON.stringify(interfaceSettings ?? {});

      return [fileName, stringifiedInterfaceSettings];
  };

  loadAttributeByInterval(attr: LayerAttribute, startDate: Date, endDate: Date = startDate, preViewGeoms?: any) {
      const attrLayer = this.store.layering.layers?.find(layer => layer.id === attr.layerId);

      if (this.store.mapSlider.attributesToSkipInterval.includes(attr.layerName as BuiltInLayerTypes)) {
          return this.loadGeometriesToDataLayer(attr, !isNil(preViewGeoms) ? preViewGeoms : attrLayer?.geometries as FeatureCollection);
      }

      if (this.store.mapSlider.attributesToUseYearFilter.includes(attr.layerName as BuiltInLayerTypes)) {
          const storedAttribute = this.store.layering.attributes?.find(storedAttr => attr.id === storedAttr.id);
          const yearId = storedAttribute?.timelineProductionYearId;
          if (!isNil(yearId)) {
              const featureCollection = !isNil(preViewGeoms) ? preViewGeoms.features : attrLayer?.geometries?.features;
              const features = featureCollection.filter(feature => feature.properties?.productionYearId === yearId);
              const geoms = !isNil(preViewGeoms) ? cloneDeep(preViewGeoms) : cloneDeep(attrLayer?.geometries);

              if (!isNil(geoms) && !isNil(features)) {
                  geoms.features = features;
                  return this.loadGeometriesToDataLayer(attr, geoms as FeatureCollection);
              }
          }
      }

      const mStartDate = dateTimeUtils.getDateObject(startDate);
      const mEndDate = dateTimeUtils.getDateObject(endDate);

      if (!isNil(attrLayer)) {
          const geoms = !isNil(preViewGeoms) ? cloneDeep(preViewGeoms) : cloneDeep(attrLayer?.geometries);
          if (!isNil(geoms) && !isNil(geoms.features)) {
              const feautes = geoms.features.filter(feature => {
                  const featureStartDateText = feature.properties?.startDate;
                  const featureEndDateText = feature.properties?.endDate;

                  if (!isNil(featureStartDateText) && !isNil(featureEndDateText)) {
                      const mfeatureStartDate = dateTimeUtils.getDateObject(new Date(featureStartDateText));
                      const mfeatureEndDate = dateTimeUtils.getDateObject(new Date(featureEndDateText));

                      if (mfeatureStartDate.isSame(mStartDate, 'day') && mfeatureEndDate.isSame(mEndDate, 'day')) {
                          return true;
                      }
                      if (mfeatureStartDate.isBefore(mStartDate, 'day') && mfeatureEndDate.isBefore(mStartDate, 'day')) {
                          return false;
                      }
                      if (mfeatureStartDate.isAfter(mEndDate, 'day') && mfeatureEndDate.isAfter(mEndDate, 'day')) {
                          return false;
                      }
                      return true;
                  }

                  if (!isNil(featureStartDateText) && isNil(featureEndDateText)) {
                      const mfeatureStartDate = dateTimeUtils.getDateObject(new Date(featureStartDateText));
                      if (mfeatureStartDate.isSameOrBefore(mStartDate)) {
                          return true;
                      }
                      return false;
                  }

                  return false;
              });

              geoms.features = feautes;

              if (geoms) {
                  this.loadGeometriesToDataLayer(attr, geoms as FeatureCollection);
              }
          }
      }
  }

  reloadAttributesByInterval(startDate: Date, endDate: Date = startDate) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise<void>(async res => {
          const activeAttrs = this.store.layering?.attributes?.filter(attr => attr.isActive);

          await this.setMapSliderInterval(startDate, endDate);
          activeAttrs?.forEach(attr => {
              this.loadAttributeByInterval(attr, startDate, endDate);
          });
          this.recalculateGlobalLabeling();
          res();
      });
  }

  async setMapSliderInterval(startDate: Nullable<Date>, endDate: Nullable<Date> = startDate) {
      await this.storeService.setMapSliderInterval(startDate, endDate);
  }

  attributehasDataOnInterval(attr: LayerAttribute, startDate: Date, endDate: Date = startDate) {
      if (this.store.mapSlider.attributesToSkipInterval.includes(attr.layerName as BuiltInLayerTypes)) {
          return false;
      }

      if (this.store.mapSlider.attributesToUseYearFilter.includes(attr.layerName as BuiltInLayerTypes)) {
          return false;
      }

      const attrLayer = this.store.layering.layers?.find(layer => layer.id === attr.layerId);

      const mStartDate = dateTimeUtils.getDateObject(startDate);
      const mEndDate = dateTimeUtils.getDateObject(endDate);

      if (!isNil(attrLayer)) {
          const hasFeature = attrLayer.geometries?.features.find(feature => {
              const featureStartDateText = feature.properties?.startDate;
              const featureEndDateText = feature.properties?.endDate;

              if (!isNil(featureStartDateText) && !isNil(featureEndDateText)) {
                  const mfeatureStartDate = dateTimeUtils.getDateObject(new Date(featureStartDateText));
                  const mfeatureEndDate = dateTimeUtils.getDateObject(new Date(featureEndDateText));

                  if (mfeatureStartDate.isSame(mStartDate, 'day') && mfeatureEndDate.isSame(mEndDate, 'day')) {
                      return true;
                  }

                  if (mfeatureStartDate.isBefore(mStartDate, 'day') && mfeatureEndDate.isBefore(mStartDate, 'day')) {
                      return false;
                  }
                  if (mfeatureStartDate.isAfter(mEndDate, 'day') && mfeatureEndDate.isAfter(mEndDate, 'day')) {
                      return false;
                  }

                  return true;
              }

              if (!isNil(featureStartDateText) && isNil(featureEndDateText)) {
                  const mfeatureStartDate = dateTimeUtils.getDateObject(new Date(featureStartDateText));
                  if (mfeatureStartDate.isSameOrBefore(mStartDate)) {
                      return true;
                  }
                  return false;
              }

              return false;
          });

          return !isNil(hasFeature);
      }

      return false;
  }

  anyActiveAttributehasDataOnInterval(startDate: Date, endDate?: Date) {
      const activeAttrs = this.store.layering.attributes?.filter(attr => attr.isActive && attr.layerName !== BuiltInLayerTypes.MACHINES && attr.layerName !== BuiltInLayerTypes.IRRIGATION);

      if (!isNil(activeAttrs)) {
          for (const attr of activeAttrs) {
              if (this.attributehasDataOnInterval(attr, startDate, endDate)) {
                  return true;
              }
          }
      }

      return false;
  }

  async setAttributeTimeLineProductionYearId(attr: LayerAttribute, id: number) {
      await this.storeService.setAttributeTimeLineProductionYearId(attr, id);
  }

  setMapId(id: number) {
      this.storeService.setMapId(id);
  }

  setFilterInit(filterInit: Partial<MapFilters>) {
      this.storeService.setFilterInit(filterInit);
  }

  setMapSliderIntervalMode(mode: MapSliderIntervalMode) {
      this.storeService.setMapSliderIntervalMode(mode);
  }

  getLayerById(layerId: number) {
      return this.store.layering.layers?.find(layer => layer.id === layerId);
  }

  getIsLayerBuiltInLayer(layerId: number) {
      const layer = this.getLayerById(layerId);
      if (!isNil(layer)) {
          return layer.readonly;
      }
      return false;
  }

  getLayerByName = (name: string) => this.store.layering?.layers?.find(layer => layer.name === name);

  getLayerIsCustomLayer = (id: number) => {
      const layer = this.getLayerById(id);
      if (!isNil(layer)) {
          return !layer.readonly;
      }

      return false;
  }

  setMapMode = (mapMode: MapMode) => {
      this.storeService.setMapMode(mapMode);
  };

  isPlanningLayer = (layerId?: number) => {
      const currentLayer = this.store.layering.layers?.find(layer => layer.id === layerId);

      if (!isNil(currentLayer)) {
          const parentLayer = this.store.layering.layers?.find(layer => layer.id === currentLayer.parentId);

          if (!isNil(parentLayer) && parentLayer.name === BuiltInLayerTypes.PLANNING) {
              return true;
          }
      }
      return false;
  };

  updateLayerGeometryData = async (layer: Layer, geometryProps: any[]) => {
      await this.storeService.updateLayerGeometryData(layer, geometryProps);
      this.filterGeometriesForLayers([layer]);
      const attrs = this.store.layering.attributes?.filter(attr => attr.isActive && attr.layerId === layer.id);
      if (!isNil(attrs)) {
          await this.removeLayerAttributesFromMap(attrs);
          attrs.forEach(attr => {
              attr.dataLayer.forEach(featureToRemove => {
                  attr.dataLayer.remove(featureToRemove);
              });
          });
          await this.addLayerAttributesToMap(attrs);
      }
  }
}

export default MapController;
