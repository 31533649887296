export const RIGHTS = {
    CULTIVATION_TABLES_ACCESS_INTERFACE: 1, //Termesztési táblák felület elérése
    CULTIVATION_TABLES_ADD: 2, //Termesztési tábla hozzáadása
    CULTIVATION_TABLES_EDIT: 3, //Termesztési tábla módosítása
    CULTIVATION_PERIODS_ACCESS_INTERFACE: 5, //Termesztési időszak felületeinek elérése
    ADDING_CULTIVATION_PERIODS: 6, //Termesztési időszak hozzáadása
    EDIT_CULTIVATION_PERIODS: 7, //Termesztési időszak hozzáadása
    DELETE_CULTIVATION_PERIODS: 8, //Termesztési időszak törlése
    LAND_OWNERS_INTERFACE: 9, //Földtulajdonosok felület elérése
    REGISTRY_NUMBER_INTERFACE: 13, //Helyrajziszámok felület elérése
    ADDING_REGISTRY_NUMBER: 14, //Helyrajziszám hozzáadása
    LAND_LEASE_CONTRACTS_INTERFACE: 17, //Földbérleti szerződések felület elérése
    STOCKS_INTEFACE: 21, //Készletek felület elérése
    RECEIPTS_INTERFACE: 22, //Bizonylatok felület elérése
    INCOMES_INTERFACE: 24, //Bevételezések felület elérése
    CREATE_INCOMES: 25, //Bevételezés készítése
    SUPPLIER_INVOICE_MANAGEMENT_INTERFACE: 27, //Szállítói számla kezelés felület elérése
    SUPPLIER_INVOICE_RECORD: 28, //Szállítói számla rögzítése
    INVENTORY_TRANSFER_INTERFACE: 29, //Áttárolás felület elérése
    FOREIGN_TRANSFER_INTERFACE: 30, //Idegen készlet átruházása felület elérése
    CREATE_FOREIGN_TRANSFER: 31, //Idegen készlet átruházás készítése
    RELEASE_OF_WORK_OPERATION_INTERFACE: 33, //Munkaműveleti kiadás felület elérése
    RELEASE_OF_WORK_OPERATION_EDIT: 34, //Munkaműveleti kiadás készítése
    STORAGE_ACCOUNT_INTERFACE: 36, //Tárolási elszámolás felület elérése
    CREATE_STORAGE_ACCOUNT: 37, //Tárolási elszámolás készítése
    PRODUCT_INTERFACE: 39, //Termék törzs felület elérése
    ADDING_PRODUCT: 40, //Termék hozzáadása
    EDIT_PRODUCT: 41, //Termék szekesztése
    WEIGHT_RECEIPTS_INTERFACE: 43, //Mérlegjegyek felület elérése
    CREATE_WEIGHTING: 44, //Mérlegelés készítése
    INCOMPLETE_WEIGHINGS_INTERFACE: 47, //Befejezetlen mérések felület elérése
    ACCOUNTING_SYSTEM_OF_CROP_DRYING_INTERFACE: 48, //Terményszárítási elszámolás felület elérése
    CREATE_ACCOUNTING_SYSTEM_OF_CROP_DRYING: 49, //Terményszárítási elszámolás készítése
    WORK_OPERATIONS_INTERFACE: 51, //Munkaműveletek felület elérése
    CREATE_WORK_OPERATIONS: 52, //Munkaművelet készítése
    EDIT_WORK_OPERATION: 53, //Munkaművelet módosítása
    DELETE_WORK_OPERATION: 54,//Munkaművelet törlése
    APPROVAL_WORK_OPERATION: 55,//Munkaművelet jóváhagyása
    WITHDRAW_APPROVAL_WORK_OPERATION: 56,//Munkaművelet visszavonása
    TASK_USAGE_INTERFACE: 57, //Munkaműveleti felhasználás felület elérése
    SHOW_TOGETHER_USE_AND_RELEASE_OFWORK_OPERATIONS_INTERFACE: 58, //Munkaműveleti felhasználás - kiadás összevezetése felület elérése
    MONTHLY_DISPATCH_INTERFACE: 59, //Havi eligazítás felület elérése
    DAILY_DISPATCH_INTERFACE: 60, //Napi eligazítás felület elérése
    FIX_AUXILIARY_INTERFACE: 61, //Segédüzemi rögzítés felület elérése
    MACHINE_INTERFACE: 62, //Gépek felület elérése
    MACHINE_CONNECTION_INTERFACE: 68, //Gépkapcsolatok felület elérése
    LABOR_INTERFACE: 72, //Munkaerő felület elérése
    ADDING_LABOR: 73, //Munkaerő hozzáadása
    COMPANY_INTERFACE: 82, //Cégek felület elérése
    PARTNER_INTERFACE: 86, //Partnerek felület elérése
    ADDING_PARTNER: 87, //Partner hozzáadása
    STORAGE_ACCOUNTING_TEMPLATE_INTERFACE: 118, //Tárolási elszámolás sablonok felület elérése
    CROP_DRYING_FEES_INTERFACE: 134, //Terményszárítási elszámolás díjai felület elérése
    CROP_DRYING_TEMPLATE_INTERFACE: 135, //Terményszárítási elszámolás sablonok felület elérése
    WORKOPERATION_MAIN_GROUPS_INTERFACE: 139, //Munkaművelet főcsoportok felület elérése
    WORKOPERATION_GROUPS_INTERFACE: 143, //Munkaművelet csoportok felület elérése
    WORKOPERATION_BLOCKS_INTERFACE: 147, //Munkaművelet tömbök felület elérése
    ADD_VARIETY: 182, //Fajta hozzáadása
    MODIFY_VARIETY: 183, //Fajta módosítása
    CATEGORIZATION_OF_CROP_PRODUCTION_TYPE_INTERFACE: 189, //Növénytermesztés típusú gazdasági egység besorolás felület elérése
    CLASSIFICTION_OF_COST_CENTER_INTO_AUXILIARY_OPERATION_TYPE_INTERFACE: 190, //Segédüzem típusú gazdasági egység besorolás felület elérése
    ECONOMIC_UNIT_INTERFACE: 191, //Gazdasági egységek felület elérése
    CREATE_ECONOMIC_UNIT: 192, //Gazdasági egység hozzáadása
    RECORD_DRYING_DATA: 200, //Szárítási adatok rögzítése
    FUEL_EXCEL_INTERFACE: 202, //Tankolás excel fájlból felület elérése
    SALES_INTERFACE: 203, //Értékesítések felület elérése
    SALES_EDIT: 204, //Értékesítés készítése
    GARAGES_INTERFACE: 209, //Műhelyek felület elérése
    CREATE_TRANSFER: 211, //Áttárolás készítése
    TREATMENT_OF_NOT_CATEGORIZED_WORK_OPERATION_INTERFACE: 214, //Nem besorolt munkaműveletek kezelése felület elérése
    INVENTORY_RECORDING_SHEET_INTERFACE: 220, //Leltárfelvételi ívek felület elérése
    INVENTORY_RECORD_BOOKS_INTERFACE: 222, //Leltárjegyzőkönyvek felület elérése
    SCRAPPING_INTERFACE: 229, //Selejtezés felület elérése
    DELIVERY_NOTES_INTERFACE: 236, //Szállítólevelek felület elérése
    CREATE_DELIVERY_NOTE: 237, //Szállítólevelek felület elérése
    TABLES_OF_OFFICIAL_DATAS: 240, //Tábla hivatalos adatainak elérése
    TABLES_OF_OFFICIAL_DATAS_EDIT: 241, //Tábla hivatalos adatainak szerkesztése
    REPORT_ACCESS_CONTRACTED_OPERATION_GROUP: 242, //Bérmunka csoport kimutatásainak elérése
    INITIAL_STOCK_INTERFACE: 244, //Nyitókészlet felület elérése
    SUBSIDE_INTERFACE: 248, //Támogatások felület elérése
    LAND_LEASE_FEE_INTERFACE: 252, //Földbérleti díj egyenleg felület elérése
    YIELD_INTERFACE: 253, //Hozamolás felület elérése
    YEILD_EDIT: 254, //Hozamolás szerkesztése
    REPORT_ACCESS_STOCK_GROUP: 256, //Készlet nyilvántartás csoport kimutatásainak elérése
    REPORT_ACCESS_OFFICIAL_DOCUMENTS_GROUP: 257, //Hivatalos nyomtatványok csoport kimutatásainak elérése
    RE_QUALIFICATION_INTERFACE: 263, //Átminősítés felület elérése
    DEFAULT_DRYING_DATA_INTERFACE: 274, //Alapértelmezett szárítási adatok felület elérése
    SIMPLIFIED_EMPLOYMENT_INTERFACE: 277, //Egyszerűsített foglalkoztatás felület elérése
    REPORT_ACCESS_ECONOMIC_UNIT_GROUP: 278, //Gazdasági egység csoport kimutatásainak elérése
    ATTENDANCE_SHEET_INTERFACE: 279, //Jelenléti nyilvántartás felület elérése
    FUEL_DISTRIBUTION_INTERFACE: 280, //Üzemanyag felosztás felület elérése
    REPORT_ACCESS_LABOR_GROUP: 283, //Munkaerő csoport kimutatásainak elérése
    SUB_LEASING_EDIT_INTERFACE: 284, //Alhaszonbérlet felület elérése
    VALUE_ORDER_INTERFACE: 289, //Érték feladás felület elérése
    SALES_PRICES_INTERFACE: 291, //Értékesítési árak felület elérése
    REPORT_ACCESS_LAND_ADMINISTRATION_GROUP: 293, //Földügy csoport kimutatásainak elérése
    PRODUCTS_PRICES_INTERFACE: 294, //Elszámoló áras termékek árai felület elérése
    ACCOUNTING_CONTACT_INTERFACE: 296, //Könyvelési kapcsolat felületeinek elérése
    ANIMAL_RECORD_INTERFACE: 299, //Egyed nyilvántartás felület elérése
    INDIVIDUAL_ANIMAL_EDIT: 300, //Egyed nyilvántartás szerkesztése
    COST_EXCEL_INTERFACE: 303, //Költség import excel fájlból felület elérése
    PRODUCTION_GROUPS_INTERFACE: 304, //Termelési csoportok felület elérése
    PRODUCTION_GROUP_EDIT: 305, //Termelési csoportok szerkesztése
    STOCK_CHANGES_EDIT: 306, //Állomány változás szerkesztése
    STABLE_AGE_GROUP_DAILY_STOCK_INTERFACE: 308, //Fizikai hely, korcsoport napi állomány felület elérése
    STABLE_AGE_GROUP_CHART: 309, //Fizikai hely, korcsoport diagramok elérése
    CLASSIFICATION_LIVE_STOCK_TYPE_INTERFACE: 310, //Állattenyésztés típusú gazdasági egység besorolás felület elérése
    REPORT_ACCESS_EQUIPMENT_GROUP: 311, //Eszköz csoport kimutatásainak elérése
    STOCK_CHANGES_INTERFACE: 312, //Állomány változás felület elérése
    WINE_ACCOMPANYING_DOCUMENT_BLOCKS_ACCESS_EDIT: 313, //Borkísérő okmány tömbök elérése, szerkesztése
    WINE_ACCOMPANYING_DOCUMENTS_EDIT: 314, //Borkísérő okmány szerkesztése
    WINE_ACCOMPANYING_DOCUMENTS_INTERFACE: 315, //Borkísérő okmányok felület elérése
    WAGE_WORK_CONTRACT_BLOCKS_ACCESS_EDIT: 317, //Bérmunka szerződés tömbök elérése, szerkesztése
    CONTRACTED_OPERATION_CONTRACT_INTERFACE: 318, //Bérmunka szerződések felület elérése
    CONTRACTED_OPERATION_EDIT: 319, //Bérmunka szerződés szerkesztése
    WAGE_WORK_CONTRACT_TEMPLATE_ACCESS_EDIT: 320, //Bérmunka szerződés sablonok elérése, szerkesztése
    DETAILED_WORK_TIME_INTERFACE: 321, //Munkavállalói munkaidő részletesen felület elérése
    REPORT_ACCESS_SCALE_GROUP: 324, //Mérleg csoport kimutatásainak elérése
    WORKOPERATION_COMPLETELY_DELETE_RELEASE_AND_USEAGE: 325, //Munkaműveleti kiadás és felhasználás teljes körű stornója
    MONTHLY_WAGE_EXCEL_INTERFACE_ACCESS_EDIT: 326, //Bérmunka értékesítési ár felület elérése, szerkesztése
    STOCK_CHANGE_INTERFACE_ACCESS_EDIT: 327, //Készletcsere felület elérése, szerkesztése
    PRICE_OF_CONTRACTED_OPERATION_ACCESS_EDIT: 328, //Bérmunka értékesítési ár felület elérése, szerkesztése
    PLANNING_INTERFACE: 329, //Tervezés felületeinek elérése
    REPORT_ACCESS_FIELD_RECORDS_GROUP: 330, //Táblatörzskönyv csoport kimutatásainak elérése
    MACHINES_TASKS_INTERFACE: 332, //Gépek munkaműveletei felület elérése
    ECONOMIC_UNITS_INCOMES_INTERFACE: 334, //Gazdasági egységek bevételei felület elérése
    REPORT_ACCESS_LIVESTOCK_BREEDING_GROUP: 337, //Állattenyésztés csoport kimutatásainak elérése
    WORKING_TIME_RECORD_INTERFACE: 338, //Munkaidő nyilvántartás felületeinek elérése
    FUELING_FROM_PETROL_STATION_INTERFACE: 339, //Tankolás üzemanyagkút adatokból felület elérése
    MACHINE_SUBTYPES_INTERFACE: 340, //Gép altípusok felület elérése, szerkesztése
    OTHER_COST_TYPE_INTERFACE: 342, //Egyéb költség típusok felület elérése, szerkesztése
    REPORT_ACCESS_EMPLOYMENT: 344, //Foglalkoztatás csoport kimutatásainak elérése
    PARTY_RENT_PRODUCT_INTERFACE_ACCESS_EDIT: 347, //Feles haszonbér felületeinek elérése, szerkesztése
    CONSULTANCY_INTERFACE: 348, //Szaktanácsadás felület elérése
    PURCHASE_TICKETS_INTERFACE: 349, //Felvásárlási jegy felületeinek elérése
    STORAGE_LOSS_INTERFACE: 354, //Tárolási veszteség felület elérése
    CROP_SALES_CONTRACTS_INTERFACE_ACCESS_EDIT: 357, //Terményértékesítési szerződések felületeinek elérése, szerkesztése
    CHECK_WEIGHING_RECEIPTS_INTERFACE: 358, //Mérlegelés bizonylatainak ellenőrzése felület elérése
    CULTIVATION_PERIOD_FIX_UNFIX: 359, //Termesztési időszak rögzítése/feloldása
    RECEIVED_FLEET_DATA_INTERFACE: 360, //Beérkezett flotta / mobil adatok felület elérése
    WORKOPERATION_COST_PER_PARTNER_INTERFACE: 361, //Igénybevett munkaműveletek ára partnerenként felület elérése
    WAGE_WORK_SERVICE_PRICE_PER_PARTNER_INTERFACE: 362, //Bérmunka szolgáltatás ára partnerenként felület elérése
    FIXED_CURRENCIES_INTERFACE: 364, //Rögzített árfolyamok felület elérése
    COST_EXCEL_IMPORT_INTERFACE: 366, //Költség import excel fájlból felület elérése
    FARM_INTERFACE: 368, //Gazdaságok felület elérése, szerkesztése
    TEST_INTERFACE_ACCESS_EDIT: 370, //Kísérletek felület elérése, szerkesztése
    TEST_PARAMETERS_ACCESS_EDIT: 372, //Kísérleti paraméterek elérése, szerkesztése
    CUSTOMER_INVOICE_MANAGEMENT: 373, //Vevői számla kezelés
    CUSTOMER_INVOICE_RECORDING: 374, //Vevői számla rögzítés
    CONSOLIDATION_BUYER_SUPPLIER_INVOICES_INTERFACE: 377, //Vevői és szállítói számlák összevezetése felület elérése
    PATTERN_INTERFACE_ACCESS_EDIT: 378, //Minták felület elérése, szerkesztése
    YIELDS_EXCEL_INTERFACE: 396, //Hozamolás excelből felület elérése
    ORDERS_ACCESS_EDIT: 398, //Megrendelések elérése, szerkesztése
    TRANSFER_INTERFACE_ACCESS_EDIT: 400, //Utalások felület elérése, szerkesztése
    CREDITINGS_INTERFACE_ACCESS_EDDIT: 401, //Jóváírások felület elérése, szerkesztése
    INCOMES_EXCEL_INTERFACE: 404, //Bevételezés excelből felület elérése
    PARTNER_DISCOUNTS_INTERFACE: 405, //Partneri kedvezmények felület elérése
    MODIFICATION_FIRSTCOST_WITH_COST_ALLOCATION: 406, //Önköltség módosítás költség felosztással
    LAND_CONVENTIONS_RO: 407, //Drept definire tip convenție
    LAND_CONTRACTS_CONVENTIONS: 408, //Drept definire/modificare convenție arendator si plată
    WORKOPERATION_FROM_EXCEL: 409, //Munkaművelet excelből felület elérése
    ACCESS_CROP_SALES_CONTRACT_TEMPLATES_INTERFACE: 411, //Terményértékesítési szerződés sablonok felület elérése
    ACCESS_EDIT_CROP_PURCHASE_CONTRACT_INTERFACE: 415, //Terményfelvásárlási szerződések felületeinek elérése, szerkesztése
    ACCESS_CROP_PURCHASE_CONTRACT_TEMPLATES_INTERFACE: 416, //Terményfelvásárlási szerződés sablonok felület elérése
    ACCESSING_EDIT_SUSTAINABILITY_SURFACE_INTERFACE: 420, //Fenntarthatósági nyilatkozatok  felületeinek elérése, szerkesztése
    OTHER_REVENUE_TYPE_EDIT_INTERFACE: 422, //Egyéb bevétel típusok felület elérése, szerkesztése
    DQS_INTERFACE: 426, //Adatminőségi mutató felület elérése
    DATA_FROM_BOOKKEEPING: 410, //Könyveléssel szinkronizált törzsadatok felület elérése
    LIST_OF_LAND_OWNERS_CREDITINGS_INTERFACE: 427, //Földtulajdon bevételei felület elérése
    RECIPES_INTERFACE: 428, //Receptúrák felület elérése
    RECIPES_EDIT_INTERFACE: 429, //Receptúra szerkesztése
    FEEDING_PLANS_INTERFACE: 430, //Takarmányozás tervezése felület elérése
    REGISTRY_NUMBER_SALES_INTERFACE: 442, //Földtulajdon adásvétel
    WORKOPERATION_SUBGROUP_EDIT_INTERFACE: 453, //Munkaműveleti alcsoportok felület elérése, szerkesztése
    RETURN_GOODS_INTERFACE: 452, //Visszáru kezelés felület elérése
    VETERINARY_TREATMENT_EDIT_INTERFACE: 439, //Állategészségügyi kezelés rögzítése
    VETERINARY_TREATMENTS_INTERFACE: 443, //Állategészségügyi kezelések felület elérése
    INSEMINATION_EDIT_INTERFACE: 444, //Termékenyítés rögzítése
    INSEMINATION_INTERFACE: 447, //Termékenyítések felület elérése
    ANIMAL_SHEET_INTERFACE: 431, //Egyed adatlap
    CALVING_EDIT_INTERFACE: 448, //Ellések rögzítése
    CALVING_INTERFACE: 451, //Ellések felület elérése
    ANIMAL_LIVESTOCK_TREATMENT_INTERFACE: 456, //Állományok felület elérése
    ANIMAL_LIVESTOCK_SHEET_INTERFACE: 459, //Állomány adatlap felület elérése
    INVENTORY_TRANSFER_REQUEST_INTERFACE: 458, //Áttárolási kérvényezés funkció elérése
    ANIMAL_MORTALITY_INTERFACE: 463, //Elhullások felület elérése, szerkesztése
    ACCESSING_EDIT_DECLARATION_SURFACE_INTERFACE: 460, //Szándéknyilatkozat  felületeinek elérése, szerkesztése
    PARTNER_QUANTITY_DISCOUNTS_INTERFACE: 461, //Partneri mennyiség kedvezmények felület elérése
    CULTIVATION_PERIOD_COST_INTERFACE: 464, //Termesztési időszak költségek menü elérése, szerkesztése
    ACCESSING_STOCK_DISTRIBUTION_INTERFACE: 465, //Háztáji szétosztás felületeinek elérése, szerkesztése
    ANIMAL_MILK_HOUSE_INTERFACE: 467, //Tejház felület elérése, szerkesztése
    ANIMAL_MILKING_INTERFACE: 468, //Fejések felület elérése, szerkesztése
    ANIMAL_DAILY_MILK_EDIT_INTERFACE: 469, //Napi tej rögzítése
    ANIMAL_DAILY_MILK_INTERFACE: 472, //Napi tej felület elérése
    ANIMAL_MILK_DIAGRAM_INTERFACE: 473, //Tej diagram elérése
    ANIMAL_REPRODUCTION_ANALYSIS_INTERFACE: 474, //Szaporodásbiológiai elemzés elérése
    ANIMAL_MILKING_PARLOR_INTERFACE: 475, //Fejőház felület elérése, szerkesztése
    FEEDING_REPORTS_INTERFACE: 476, //Takarmányozási napló elérése, szerkesztése
    ANIMAL_CERTIFICATES_INTERFACE: 478, //Korcsoport bizonylatok felület elérése, szerkesztése
    ACCESSING_MANUFACTURING_ORDER_CULTIVATION_PERIOD_CLASSIFICATION_INTERFACE: 479, //Gyártási rendelés és termesztési időszak párosítás felület elérése
    FUEL_WORKS_INTERFACE: 488, //Tankolás works adatokból felület elérése
    SCOUTING_ACCESS_INTERFACE: 496, //Megfigyelés felület elérés
    WORKOPERATION_QUALIFICATION_INTERFACE: 503, //Munkaművelet minősítése felület elérés
    WORKOPERATION_QUALIFICATION_MODIFY: 504, //Munkaművelet minősítés módosítása
    WORKOPERATION_QUALIFICATION_DELETE: 505, //Munkaművelet minősítés törlése
    EXTERNAL_SCALE_SOFTWARE_CONNECTION: 507, //Mérlegstúdió kapcsolódás
    YIELDS_EXCEL_PROCESSING_PLANT_INTERFACE: 510, //Hozamolás excelből (feldolgozóüzem) felület elérése
    WITHDRAW_APPROVAL_VALIDATED_WORK_OPERATION: 522, //Munkaművelet jóváhagyás visszavonása könyvelt státuszú feladott adatok esetén
    LIVESTOCK_WEIGHT_CERTIFICATE: 511, // Tömeggyarapodások bizonylatolása
    LIVESTOCK_LISTING: 520, // Szerkeszthető listák felület elérése
    VALIDATED_DATA_FORCE_CANCEL: 521,
    ACCOUNTING_DISPATCH_LOGS: 523,
    MANAGEMENT_DASHBOARD: 525, // Vezetői dashboard felület elérése
    DATA_ANALYSIS_NOTIFICATIONS: 526, // Értesítések-figyelmeztetések felület elérése
    DATA_ANALYSIS_SETTINGS: 527, // Adatelemzés beállításainak elérése
    SCOUTING_APPROVE: 528, // Megfigyelés jóváhagyás
    SCOUTING_CREATE_EDIT: 529, // Megfigyelés létrehozása/szerkesztése
    SCOUTING_TASK: 531, // Megfigyelés feladat felület elérés
    SCOUTING_TASK_CREATE_EDIT_DELETE: 532, // Megfigyelés feladat létrehozás, szerkesztés törlés
    DEFAULT_WAREHOUSE_INTERFACE: 333, //Alapértelmezett termék raktár felület elérése
    DEVICES_ANALYSIS_INTERFACE: 535, // Eszközök/elemzések felület elérése
    EDIT_LIVESTOCK_STRAIN_DATA: 298, // Törzsadatok szerkesztése
    ACCESSING_START_PAGE_SETTINGS_INTERFACE: 536, // Kezdőlap beállításának elérése
    LIVESTOCK_PROTOCOL: 297, // Törzsadatok felületeinek elérése (állattenyésztés)
    EGN_SETTING_INTERFACE: 499, //e-GN beállítás elérése (állattenyésztés)
    EGN_ACCESS_INTERFACE: 501, //E-Gazdálkodási Napló felület elérése
    MACHINE_REGISTRATION_INTERFACE: 542, //Gép nyilvántartás felület elérése
    MACHINE_ANNOUNCEMENT_INTERFACE: 550, //Gépbejelentés felület elérése
    PLANNING_CREATE_EDIT_DELETE: 544, //Terv létrehozása/szerkesztése/törlése
    SUBSCRIPTION_SERVICE_INTERFACE: 539, //Előfizetések/szolgáltatások felület elérése
    RECURRING_SERVICE_WORK_OPERATION_INTERFACE: 553, //Ismétlődő szerviz munkaművelet elérése
};
