/* eslint-disable no-undefined */
import { useState } from 'react';
import { WAREHOUSE } from 'shared/src/constants';
import { dateTimeUtils } from 'shared/src/modules';
import useTranslate from '@i18n/useTranslate';
import * as yup from 'yup';

export default function useFormValidation() {
    const { translater } = useTranslate();

    const [warehouseTypeIds] = useState([WAREHOUSE.TYPE_ID.FOREIGN, WAREHOUSE.TYPE_ID.OWN_AND_FOREIGN]);
    const [validationSchema] = useState(
        yup.object().shape({
            companyId: yup.number()
                .typeError(translater('multiPartnerReceptionModal.requiredCompany', 'Specify the company'))
                .required(translater('multiPartnerReceptionModal.requiredCompany', 'Specify the company')),
            warehouseId: yup.number()
                .typeError(translater('multiPartnerReceptionModal.requiredWarehouse', 'Specify the warehouse'))
                .required(translater('multiPartnerReceptionModal.requiredWarehouse', 'Specify the warehouse')),
            date: yup.string()
                .typeError(translater('multiPartnerReceptionModal.requiredDate', 'Specify the date'))
                .required(translater('multiPartnerReceptionModal.requiredDate', 'Specify the date')),
            productId: yup.number()
                .typeError(translater('multiPartnerReceptionModal.requiredProduct', 'Specify the product'))
                .required(translater('multiPartnerReceptionModal.requiredProduct', 'Specify the product')),
            partners: yup.array()
                .typeError(translater('multiPartnerReceptionModal.requiredPartners', 'Specify at least one partner'))
                .min(1, translater('multiPartnerReceptionModal.requiredPartners', 'Specify at least one partner'))
                .required(translater('multiPartnerReceptionModal.requiredPartners', 'Specify at least one partner'))
                .test(
                    'requiredQuantity',
                    translater('multiPartnerReceptionModal.requiredPartnersQuantity', 'The marked fields are mandatory'),
                    partners => {
                        const isAllPartnerHasQuantity = partners?.every(partner => +partner?.quantity > 0);
                        return isAllPartnerHasQuantity;
                    },
                ),
        }),
    );
    const [defaultValues] = useState({
        companyId: undefined,
        warehouseId: null,
        warehouseName: null,
        productId: null,
        productName: null,
        productUnit: null,
        date: dateTimeUtils.getDefaultDateNow(),
        partners: [],
        totalQuantity: 0,
    });

    return {
        validationSchema,
        defaultValues,
        warehouseTypeIds,
    };
}
