import React from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import useTranslate from '@i18n/useTranslate';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import DataInput from '@baseComponents/inputs/DataInput';
import RegistryNumberOverall from './RegistryNumberOverall';
import RegistryNumberData from './RegistryNumberData';
import useLoadRegistryNumber from './useLoadRegistryNumber';

const useStyles = createUseStyles({
    registryNumberContainer: {
        position: 'relative',
    },
    marginTop: {
        marginTop: 20,
    },
});

export default function AddRegistryNumber(props) {
    const { registryNumberExpanded, closeRegistryNumber, toggle, isEdit, loadedRegistryNumberList, setFormInitialValues } = props;
    const { t } = useTranslate();
    const { values } = useFormikContext();
    const classes = useStyles();

    useLoadRegistryNumber({ loadedRegistryNumberList, registryNumberExpanded, setFormInitialValues });

    function toggleRegistryNumbers(state) {
        const windowContent = {
            component: RegistryNumberData,
            props: {
                close: closeRegistryNumber,
            },
        };
        toggle(state && windowContent);
    }

    return (
        <div className={classes.registryNumberContainer}>
            { isEdit && <LoadSpinner value={values.registryNumber} isAutoDetect />}
            <DataInput
                title={t('registryNumberData.registryNumber', 'Registry number')}
                label={t('registryNumberData.registryNumber', 'Registry number')}
                className={classes.marginTop}
                onClick={() => toggleRegistryNumbers(!registryNumberExpanded)}
            >
                {values && !isEmpty(values.selectedRegistryNumbers) && (
                    <RegistryNumberOverall registryNumber={values.selectedRegistryNumbers} />
                )}
            </DataInput>
        </div>
    );
}
