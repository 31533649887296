import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import Label from '@baseComponents/formParts/Label';
import { languageUtils } from 'shared/src/modules';
import { LANGUAGE } from 'shared/src/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import Radio from '@baseComponents/controls/Radio';
import { isEmpty } from 'lodash';
import useTranslate from '@i18n/useTranslate';

const useStyles = createUseStyles({
    radio: {
        marginTop: 5,
        '& .radioContainer': {
            fontSize: 14,
            lineHeight: 1.71,
        },
    },
});

export default function CropTypeSelect(props) {
    const { cropType, setCropType, className } = props;
    const location = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const { i18n } = useTranslation();
    const cropTypes = useSelector((state: any) => state.dataLists?.cropTypes);
    const [cropTypeList, setCropTypeList] = useState();
    const classes = useStyles();

    //load crop types
    useEffect(() => {
        function getCaption(captionObj) {
            if (!captionObj || !captionObj.name) { return ''; }
            if (languageUtils.isSameLanguage(i18n.language, LANGUAGE.HUNGARIAN) || !captionObj.otherLanguage) { return captionObj.name; }

            const caption = captionObj.otherLanguage.find(langItem => languageUtils.isSameLanguage(langItem.langCode, i18n.language))
                        || captionObj.otherLanguage.find(langItem => languageUtils.isSameLanguage(langItem.langCode, LANGUAGE.ENGLISH));

            return caption
                ? caption.name || caption.shortName
                : captionObj.name;
        }

        if (!isEmpty(cropTypes) && dispatch && location) {
            const selectCropTypes = cropTypes.map(cropTypeObj => ({
                key: cropTypeObj.id,
                value: getCaption(cropTypeObj),
            }));
            setCropTypeList(selectCropTypes);
        }
    }, [dispatch, location, i18n, cropTypes]);

    function getIsChecked(key) {
        return cropType === key;
    }

    return (
        <>
            <Label
                label={t('closeOpenCultivationPeriod.typeOfSownCrop', 'Type of sown crop')}
                className={className}
            />
            {cropTypeList?.map(option => (
                <div className={classes.radio} key={option.key}>
                    <Radio
                        text={option.value}
                        value={option.key}
                        checked={getIsChecked(option.key)}
                        onChange={() => setCropType(option.key)}
                    />
                </div>
            ))}
        </>
    );
}
