import { RIGHTS } from '../constants';

export const COMPANY = {
    MODULE_ID: 24,
    NAME: 'company',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        COMPANIES: {
            CONTENT_URL_ID: { companies: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'companies',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.companies',
                TRANSLATOR_DEFAULT: 'Companies',
            },
            RIGHTS: [
                RIGHTS.COMPANY_INTERFACE,
            ],
            SHOW_IN_PANNING_MODE: true,
        },
        COMPANY_GROUPS: {
            CONTENT_URL_ID: { companyGroups: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'companyGroups',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.companyGroups',
                TRANSLATOR_DEFAULT: 'Company groups',
            },
            SHOW_IN_PANNING_MODE: true,
        },
    },
};
