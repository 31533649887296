import * as yup from 'yup';
import { PlanningEditModalMode } from './planningEditModalTypes';

export enum MapEditModalCompanyOrPartner {
    company,
    partner
}

export interface PlanningEditModalSchema {
    schemaMode?: number,
    startDate?: string,
    endDate?: string,
    entityArea?: number,
    region?: string,
    description?: string,
    cultureId?: number,
    cultureVarietyId?: number,
    reproductionRateId?: number,
    technologyId?: number,
    cropTypeId?: number,
}

export interface PlanningEditModalSubmitSchema extends PlanningEditModalSchema{
    entityId: number,
}

export interface SetPlanningCultivationPeriodsDto {
    planningData: PlanningEditModalSubmitSchema[],
}

export type MAPFILTER_KEY = keyof PlanningEditModalSchema;

export const planningEditModalSchema = yup.object<PlanningEditModalSchema>({
    schemaMode: yup.number().default(PlanningEditModalMode.SINGLE),
    entityArea: yup.number().when('schemaMode', {
        is: PlanningEditModalMode.SINGLE,
        then: yup.number()
            .positive()
            .moreThan(0)
            .required(),
        otherwise: yup.number().optional(),
    }),
});
