import { isNumber, isString, isNull as isNullLodash, isUndefined as isUndefinedLodash } from 'lodash';

export const stringUtils = {
    capitalizeFirstLetterUpper,
    includesNoCase,
    isSame,
    isSameNoCase,
    isNull,
    isUndefined,
    getStringFromArray,
    removeAccents
};

function capitalizeFirstLetterUpper(capitalizeString) {
    const lowerCaseString = capitalizeString ? capitalizeString.toLowerCase() : capitalizeString;
    return lowerCaseString ? lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1) : lowerCaseString;
}

function includesNoCase(firstString, secondString, isNonAccentSearch = false) {
    if ((firstString || isNumber(firstString) || isString(firstString)) && (secondString || isNumber(secondString) || isString(secondString))) {
        
        let firstStr = isNonAccentSearch ? removeAccents(firstString) : firstString
        let secondStr = isNonAccentSearch ? removeAccents(secondString) : secondString

        return firstStr.toString().toLowerCase().includes(secondStr.toString().toLowerCase());
    }
    return false;
}

function isSame(firstString, secondString) {
    if ((firstString || isNumber(firstString)) && (secondString || isNumber(secondString))) {
        return firstString.toString() === secondString.toString();
    }
    return false;
}

function isSameNoCase(firstString, secondString) {
    if ((firstString || isNumber(firstString)) && (secondString || isNumber(secondString))) {
        return firstString.toString().toLowerCase() === secondString.toString().toLowerCase();
    }
    return false;
}


function isNull(string) {
    // eslint-disable-next-line no-restricted-syntax
    return isNullLodash(string) || string === 'null';
}

function isUndefined(string) {
    return isUndefinedLodash(string) || string === 'undefined';
}

function getStringFromArray(array) {
    if (array && array.toString()) {
        return array.toString().replaceAll(',', ', ');
    }
    return '';
}

function removeAccents(string) {
    return string.toString().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}

