import { useMapContext } from '@map/services/mapContext';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import BackToCenterComponent from './BackToCenterComponent';
import useFocusCenter from './useFocusCenter';

type TProps = {
    zoomEntityId?: number,
}

const BackToCenterContainer = ({ zoomEntityId }: TProps) => {
    const [store] = useMapContext();
    const [layerCount, setCount] = useState<number>(0);

    const focusCenter = useFocusCenter(store);

    useEffect(() => {
        if (!isEmpty(store.layering.order && isNil(zoomEntityId))) {
            if (layerCount !== store.layering.order.length) {
                setCount(store.layering.order.length);
                focusCenter();
            }
        }
    }, [store.layering.order, focusCenter, layerCount, zoomEntityId]);

    return (<BackToCenterComponent
        focusCenter={() => focusCenter()}
    />);
};

export default BackToCenterContainer;
