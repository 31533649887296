import Input from '@baseComponents/inputs/Input';
import Button from '@baseComponents/buttons/Button';
import SimpleColorPicker from '@baseComponents/colorPicker/SimpleColorPicker';
import CloseButton from '@baseComponents/buttons/CloseButton';
import SimpleSelect from '@baseComponents/select/simpleSelect/SimpleSelect';
import MapController from '@map/services/mapController';
import { CreateLayerDto, Layer, LayerType, ThemeType, UpdateLayerDto } from '@map/services/mapTypes';
import { Formik, FormikProps } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import * as Yup from 'yup';
import { LayerFolderEditMode } from './LayerFolderEdit.enum';
import { LayerFolderEditTitle } from './LayerFolderEditTitle';
import useTranslate from '@i18n/useTranslate';

type Classes = 'container' | 'closeIconContainer' | 'closeIcon' | 'containerHeader' | 'typeSelectorContainer' | 'input' | 'actionContainer' | 'typeSelect' | 'label' | 'labelDot' | 'labelContainer';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        position: 'absolute',
        zIndex: 1000003,
        width: 300,
        height: 'fit-content',
        backgroundColor: theme.color.white,
        borderRadius: 4,
        boxShadow: theme.shadows.map,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 30,
        // maxWidth: '100%',
    },
    containerHeader: {
        display: 'flex',
        width: '100%',
        maxWidth: '100%',
        height: 32,
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 30,
    },
    closeIconContainer: {
        width: 30,
        height: 30,
        borderColor: theme.color.black,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    closeIcon: {
        width: 18,
        height: 18,
    },
    typeSelectorContainer: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    input: {
        marginBottom: 10,
    },
    actionContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    typeSelect: {
        marginBottom: 10,
    },
    label: {
        height: 15,
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    labelDot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: theme.color.main,
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    labelContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        height: 'fit-content',
    },
}));

type Tprops = {
    setEditPanelMode: (mode: LayerFolderEditMode) => void;
    editPanelMode: LayerFolderEditMode
    layer: Layer,
    mapController: MapController,
    index: number
}

export function LayerFolderEdit({ setEditPanelMode, editPanelMode, layer, mapController, index }: Tprops) {
    const classes = useStyles();
    const { t, translater } = useTranslate();

    const [layerIsFolder, setLayerIsFolder] = useState(layer.type === LayerType.FOLDER ? 1 : 0);

    const closeEditPanel = () => {
        setEditPanelMode(LayerFolderEditMode.NONE);
    };

    async function handleEditSubmit(v) {
        const layerDto: CreateLayerDto = {
            parentId: layer.id,
            name: v.name,
            defaultFillColor: v.defaultFillColor,
            defaultStrokeColor: v.defaultStrokeColor,
            type: v.layerIsFolder ? LayerType.FOLDER : LayerType.FEATURE,
            domainId: 0,
        };

        if (editPanelMode === LayerFolderEditMode.CREATE) {
            await mapController.createLayerData(layerDto);
        }

        if (editPanelMode === LayerFolderEditMode.EDIT) {
            const updateLayerDto: UpdateLayerDto = {
                ...layerDto,
                id: layer.id,
                parentId: layer.parentId,
            };
            await mapController.updateLayerData(layer.id, updateLayerDto);
        }

        await mapController.reloadLayers(undefined);
        closeEditPanel();
    }

    const formikRef = useRef<FormikProps<any>>();

    useEffect(() => {
        if (editPanelMode === LayerFolderEditMode.CREATE && index >= 3) {
            formikRef.current?.setFieldValue('layerIsFolder', false);
            setLayerIsFolder(0);
        }
    }, [editPanelMode, index]);

    const cancelText = t('default.cancel', 'Cancel');
    const saveText = t('default.save', 'Save');

    return (
        <div className={classes.container}>
            <div className={classes.containerHeader}>
                <LayerFolderEditTitle
                    editPanelMode={editPanelMode}
                    layer={layer}
                />
                <CloseButton onClick={closeEditPanel} />
            </div>

            <Formik
                onSubmit={handleEditSubmit}
                validationSchema={
                    Yup.object({
                        layerIsFolder: Yup.boolean(),
                        name: Yup.string()
                            .required(translater<string>('map.layerEditNameRequired', 'Name field is required')),
                        defaultFillColor: Yup.string().when('layerIsFolder', {
                            is: false,
                            then: Yup.string().required(translater<string>('map.layerEditColorRequired', 'Color field is required')),
                        }),
                        defaultStrokeColor: Yup.string().when('layerIsFolder', {
                            is: false,
                            then: Yup.string().required(translater<string>('map.layerEditColorRequired', 'Color field is required')),
                        }),
                    })}
                initialValues={{
                    layerIsFolder: layer.type === LayerType.FOLDER,
                    name: editPanelMode === LayerFolderEditMode.EDIT ? layer.name : '',
                    defaultFillColor: editPanelMode === LayerFolderEditMode.EDIT ? layer.defaultFillColor : '#000000',
                    defaultStrokeColor: editPanelMode === LayerFolderEditMode.EDIT ? layer.defaultStrokeColor : '#000000',
                }}
                innerRef={ref => { formikRef.current = ref; }}
            >
                {props => (
                    <form onSubmit={event => { event.preventDefault(); }}>
                        {
                            editPanelMode === LayerFolderEditMode.CREATE && (
                                <>
                                    <div className={classes.labelContainer}>
                                        <p className={classes.label}>Típus</p>
                                        <div className={classes.labelDot} />
                                    </div>
                                    <SimpleSelect
                                        dataList={[{ key: 0, value: translater<string>('map.layerEditTypeLayer', 'Layer') }, { key: 1, value: translater<string>('map.layerEditTypeFolder', 'Folder') }]}
                                        selected={props.values.layerIsFolder ? 1 : 0}
                                        className={classes.typeSelect}
                                        disabled={index >= 3}
                                        setSelected={(id: any) => { props.setFieldValue('layerIsFolder', (Number(id) === 1)); setLayerIsFolder(Number(id)); }}
                                    />
                                </>
                            )
                        }

                        <Input
                            value={props.values.name}
                            onChange={props.handleChange}
                            onBlue={props.handleBlur}
                            placeholder="név"
                            required
                            id="name"
                            name="name"
                            type="text"
                            label="Név"
                            invalid={!!props.errors.name}
                            errorMessage={props.errors.name}
                            className={classes.input}
                        />
                        {
                            layerIsFolder === 0 && (
                                <>
                                    <SimpleColorPicker
                                        label="Szín"
                                        id="defaultFillColor"
                                        name="defaultFillColor"
                                        value={props.values.defaultFillColor}
                                        onChange={color => props.setFieldValue('defaultFillColor', color)}
                                        required
                                        className={classes.input}
                                    />
                                    <SimpleColorPicker
                                        label="Körvonal"
                                        value={props.values.defaultStrokeColor}
                                        onChange={color => props.setFieldValue('defaultStrokeColor', color)}
                                        required
                                        className={classes.input}
                                    />
                                </>
                            )
                        }

                        <div className={classes.actionContainer}>
                            <Button submit onClick={() => { props.handleSubmit(); }}>{saveText}</Button>
                            <Button type="secondary" onClick={closeEditPanel}>{cancelText}</Button>
                        </div>
                    </form>
                )}

            </Formik>
        </div>
    );
}
