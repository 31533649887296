/* eslint-disable no-case-declarations */
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { InfoPanelLayerAttribute, Layer } from '@map/services/mapTypes';
import { gisService } from '@services/gisService';
import { isArray, isNil, isEmpty } from 'lodash';

type GetDataForLayerProps = {
    layerName: string,
    entityIds?: number[],
    productionYearId?: number,
    planId?: number;
    shouldFetchCosts?: boolean;
    showClosedPeriods?: boolean;
    statusId?: number;
    cropType?: number;
}

export const getIsCustomLayer = (layer: Layer) => !layer.readonly;

export const hasCommonValue = (arr1: Array<any>, arr2: Array<any>) => {
    for (let i = 0; i < arr1.length; i++) {
        if (arr2.includes(arr1[i])) {
            return true;
        }
    }
    return false;
};

const mockSentTask = (query:any) : any[] => {
    const entityIds = query?.entityIds;
    if (!isNil(entityIds) && isArray(entityIds)) {
        const mockData:any[] = [];
        entityIds.forEach(entityId => {
            mockData.push({
                id: entityId,
                entityId,
                name: 'Test Name',
                comment: 'Some comment here',
            });
        });
        return mockData;
    }

    return [];
};

const mockScouting = (query:any) : any[] => {
    const entityIds = query?.entityIds;
    if (!isNil(entityIds) && isArray(entityIds)) {
        const mockData:any[] = [];
        entityIds.forEach(entityId => {
            mockData.push({
                id: entityId,
                entityId,
                createdUserName: 'Kiss Misi',
                categoryName: 'Permetezés',
                prioriy: 'Magas',
                createdAt: '2023.05.17.',
                scoutingNumber: '1111',
                files: [
                    {
                        date: '2022-07-06',
                        fileName: 'CAP7631335747435428224_1657109500440.jpg',
                        id: 6,
                        scoutingId: 5,
                        size: 'NaN',
                        typeId: 1,
                        url: false,
                    },
                    {
                        date: '2022-07-06',
                        fileName: 'CAP7631335747435428224_1657109500440.jpg',
                        id: 7,
                        scoutingId: 5,
                        size: 'NaN',
                        typeId: 1,
                        url: false,
                    },
                    {
                        date: '2022-07-06',
                        fileName: 'CAP7631335747435428224_1657109500440.jpg',
                        id: 7,
                        scoutingId: 5,
                        size: 'NaN',
                        typeId: 1,
                        url: false,
                    },
                    {
                        date: '2022-07-06',
                        fileName: 'CAP7631335747435428224_1657109500440.jpg',
                        id: 7,
                        scoutingId: 5,
                        size: 'NaN',
                        typeId: 1,
                        url: false,
                    },
                    {
                        date: '2022-07-06',
                        fileName: 'CAP7631335747435428224_1657109500440.jpg',
                        id: 7,
                        scoutingId: 5,
                        size: 'NaN',
                        typeId: 1,
                        url: false,
                    },
                    {
                        date: '2022-07-06',
                        fileName: 'CAP7631335747435428224_1657109500440.jpg',
                        id: 7,
                        scoutingId: 5,
                        size: 'NaN',
                        typeId: 1,
                        url: false,
                    },
                ],
            });
        });
        return mockData;
    }

    return [];
};

export const getDataForLayer = async (props: GetDataForLayerProps) => {
    const { layerName, entityIds, productionYearId, shouldFetchCosts = false, showClosedPeriods, statusId, planId } = props;
    const query: { entityIds?: number[], productionYearId?: number, companyIds?: number[], shouldFetchCosts?: boolean, showClosedPeriods?: boolean, statusId?: number, cropTypeId?: number, planId?: number } = {};

    if (planId) {
        query.planId = planId;
    }
    if (entityIds) {
        query.entityIds = entityIds;
    }
    if (productionYearId) {
        query.productionYearId = productionYearId;
    }

    if (shouldFetchCosts) {
        query.shouldFetchCosts = shouldFetchCosts;
    }

    if ((layerName === BuiltInLayerTypes.CULTIVATION_PERIOD)) {
        query.showClosedPeriods = showClosedPeriods;
    }

    if (!isNil(statusId)) {
        query.statusId = statusId;
    }

    switch (layerName) {
        case BuiltInLayerTypes.LOT:
            return gisService.getLots(query);
        case BuiltInLayerTypes.CULTIVATION_PERIOD:
            return gisService.getCultivationPeriods(query);
        case BuiltInLayerTypes.LPIS_FIELD:
            return gisService.getLpisFields(query);
        case BuiltInLayerTypes.REGISTRY_NUMBER:
            return gisService.getRegistryNumbers(query);
        case BuiltInLayerTypes.PREMISE:
            return gisService.getPremises(query);
        case BuiltInLayerTypes.STABLE:
            return gisService.getStables(query);
        case BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP:
            return gisService.getCultivationPeriodGroups(query);
        case BuiltInLayerTypes.MACHINES:
            return [];
        case BuiltInLayerTypes.RECEIVED_SCOUTINGS:
            return gisService.getScoutings(query);
        case BuiltInLayerTypes.SENT_TASK:
            return gisService.getScoutingTasks(query);
        case BuiltInLayerTypes.PLANNING:
            return !isEmpty(entityIds) ? gisService.getPlanningCultivationPeriods(query) : [];
        case BuiltInLayerTypes.METEOROLOGY: {
            const meteorologyQuery:any = { locationId: query.entityIds?.join(','), withLastRecord: 1 };
            const meteorologyData = await gisService.getMeteorology(meteorologyQuery);

            if (!isNil(meteorologyData)) {
                return meteorologyData.map(meteorology => ({ ...meteorology, entityId: meteorology?.locationId }));
            }
            return [];
        }
        default:
            return [];
    }
};

export const selectedFeatureStyle = {
    fillColor: '#FFFFFF',
    strokeColor: '#FFFFFF',
};

export const selectedFeatureStylePlanning = {
    fillColor: '#22c373',
    strokeColor: '#22c373',
};

export const selectedFeatureStylePlanningWithInfoPanel = {
    fillColor: selectedFeatureStylePlanning.fillColor,
    strokeColor: selectedFeatureStyle.strokeColor,
};

export const latLngToPixel = (map: google.maps.Map, position: any) => {
    if (!isNil(map)) {
        const zoom = map.getZoom();
        if (isNil(zoom)) {
            return;
        }
        const scale = 2 ** zoom;
        const proj = map.getProjection();
        const bounds = map.getBounds();

        if (isNil(proj) || isNil(bounds)) {
            return;
        }

        const nw = proj.fromLatLngToPoint(
            new google.maps.LatLng(
                bounds.getNorthEast().lat(),
                bounds.getSouthWest().lng(),
            ),
        );
        const point = proj.fromLatLngToPoint(position);

        if (isNil(point) || isNil(nw)) {
            return;
        }

        return new google.maps.Point(
            Math.floor((point.x - nw.x) * scale),
            Math.floor((point.y - nw.y) * scale),
        );
    }
};

export const getAdditionalLayerAttributes = (layer: BuiltInLayerTypes) : InfoPanelLayerAttribute[] | undefined => {
    switch (layer) {
        case BuiltInLayerTypes.RECEIVED_SCOUTINGS:
            return ([
                {
                    id: 1,
                    attributeKey: 'scoutingNumber',
                    defaultAttributeKey: 'scoutingNumber',
                },
                {
                    id: 2,
                    attributeKey: 'priorityName',
                    defaultAttributeKey: 'priorityName',
                },
                {
                    id: 3,
                    attributeKey: 'creatorName',
                    defaultAttributeKey: 'creatorName',
                },
                {
                    id: 4,
                    attributeKey: 'categoryName',
                    defaultAttributeKey: 'categoryName',
                },
                {
                    id: 5,
                    attributeKey: 'otherCategoryName',
                    defaultAttributeKey: 'otherCategoryName',
                },
                {
                    id: 6,
                    attributeKey: 'cultureName',
                    defaultAttributeKey: 'cultureName',
                },
                {
                    id: 7,
                    attributeKey: 'cultivationPeriodNames',
                    defaultAttributeKey: 'cultivationPeriodNames',
                },
                {
                    id: 8,
                    attributeKey: 'growthStageName',
                    defaultAttributeKey: 'growthStageName',
                },
                {
                    id: 9,
                    attributeKey: 'growthStageCode',
                    defaultAttributeKey: 'growthStageCode',
                },
                {
                    id: 10,
                    attributeKey: 'comment',
                    defaultAttributeKey: 'comment',
                },
                {
                    id: 11,
                    attributeKey: 'answer',
                    defaultAttributeKey: 'answer',
                },
            ]);
        case BuiltInLayerTypes.SENT_TASK:
            return ([
                {
                    id: 0,
                    attributeKey: 'cultureName',
                    defaultAttributeKey: 'cultureName',
                },
                {
                    id: 1,
                    attributeKey: 'workerName',
                    defaultAttributeKey: 'workerName',
                },
                {
                    id: 2,
                    attributeKey: 'senderName',
                    defaultAttributeKey: 'senderName',
                },
                {
                    id: 3,
                    attributeKey: 'comment',
                    defaultAttributeKey: 'comment',
                },
                {
                    id: 4,
                    attributeKey: 'cultivationPeriodNames',
                    defaultAttributeKey: 'cultivationPeriodNames',
                },
            ]);
        default:
    }
};
