import { userActions } from '../actions/userActions';

const initialState = {
    agent: null,
    agentError: null,
    user: {
        administrator: null,
        consultant: null,
        userToRights: null,
        settings: {
            activeSystemModule: null,
            interfaceSettings: null,
            columnOrders: null,
        },
        profilePictureUrl: null,
    },
    userError: null,
    isCheckedUserToken: null,
    isLoggedIn: false,
};

export function user(state = initialState, action) {
    switch (action.type) {
        case userActions.SET_AGENT:
            return {
                ...state,
                agent: action.payload.agent,
            };
        case userActions.SET_AGENT_ERROR:
            return {
                ...state,
                agentError: { error: action.payload.error },
            };
        case userActions.REMOVE_AGENT_ERROR:
            return {
                ...state,
                agentError: null,
            };
        case userActions.SET_USER:
            return {
                ...state,
                user: action.payload.user,
                isLoggedIn: true,
            };
        case userActions.SET_USER_ERROR:
            return {
                ...state,
                userError: { error: action.payload.error },
            };
        case userActions.REMOVE_USER_ERROR:
            return {
                ...state,
                userError: null,
            };
        case userActions.REMOVE_USER:
            return {
                ...state,
                user: {
                    administrator: null,
                    consultant: null,
                    userToRights: null,
                    settings: {
                        activeSystemModule: null,
                        interfaceSettings: null,
                        columnOrders: null,
                    },
                },
                isLoggedIn: false,
            };
        case userActions.SET_CHECKED_USER_TOKEN:
            return {
                ...state,
                isCheckedUserToken: action.payload.isCheckedUserToken,
            };
        case userActions.SET_ACTIVE_SYSTEM_MODULE:
            return {
                ...state,
                user: {
                    ...state.user,
                    settings: {
                        ...state.user.settings,
                        activeSystemModule: action.payload.moduleId,
                    },
                },
            };
        case userActions.SET_USER_INTERFACE_SETTING: {
            const { settingName, updateResult, fileName } = action.payload;

            return {
                ...state,
                user: {
                    ...state.user,
                    settings: {
                        ...state.user.settings,
                        interfaceSettings: {
                            ...state.user.settings.interfaceSettings,
                            [fileName]: {
                                ...state.user.settings.interfaceSettings[fileName],
                                [settingName]: updateResult,
                            },
                        },
                    },
                },
            };
        }
        case userActions.SET_RIGHTS:
            return {
                ...state,
                user: {
                    ...state.user,
                    userToRights: action.payload.rights,
                },
            };
        case userActions.SET_TABLE_COLUMN_ORDER:
            return {
                ...state,
                user: {
                    ...state.user,
                    settings: {
                        ...state.user.settings,
                        columnOrders: {
                            ...state.user.settings.columnOrders,
                            [action.payload.location]: action.payload.tableColumns,
                        },
                    },
                },
            };
        case userActions.SET_USER_PROFILE_PICTURE_URL:
            return {
                ...state,
                user: {
                    ...state.user,
                    profilePictureUrl: action.payload.profilePictureUrl,
                },
            };
        default:
            return state;
    }
}
