import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNumberFormat } from '@common_modules/numberFormat';
import { SETTINGS, INTERFACE_SETTINGS, CURRENCY, INTERFACE_FILENAME, UNITS } from 'shared/src/constants';
import { companySettingsModule } from 'shared/src/company/companySettings';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        height: 18,
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: 1.5,
        color: theme.color.raven,
        textAlign: 'right',
    },
}));

export default function CostPerArea(moneyProps) {
    const { value, style } = moneyProps;
    const { t } = useTranslation();

    const companySettingList = useSelector((state: any) => state.company.company.settings);
    const interfaceSettingList = useSelector((state: any) => state.user.user.settings.interfaceSettings);
    const companyCurrencies = useSelector((state: any) => state.company.company.currencies);
    const [currencyId, setCuerrencyId] = useState(0);
    const { numberFormatter } = useNumberFormat();

    const classes = useStyles();

    //get primary, secondary currency text and currency decimal number
    useEffect(() => {
        if (companySettingList && interfaceSettingList && companyCurrencies) {
            const activeCurrency = interfaceSettingList[INTERFACE_FILENAME.CURRENCY_SWITCH]
                ? interfaceSettingList[INTERFACE_FILENAME.CURRENCY_SWITCH][INTERFACE_SETTINGS.ACTIVE_CURRENCY] || CURRENCY.TYPE.PRIMARY
                : CURRENCY.TYPE.PRIMARY;
            const usingSecondary = companySettingsModule.checkSetting(SETTINGS.USING_SECONDARY_CURRENCY, companySettingList);

            if (usingSecondary && activeCurrency && Number(activeCurrency.value) === CURRENCY.TYPE.SECONDARY) {
                const secondaryCurrencyId = companySettingsModule.getSettingValue(SETTINGS.SECONDARY_CURRENCY, companySettingList);
                setCuerrencyId(secondaryCurrencyId);
            } else {
                const primaryCurrencyId = companySettingsModule.getSettingValue(SETTINGS.PRIMARY_CURRENCY, companySettingList);
                setCuerrencyId(primaryCurrencyId);
            }
        }
    }, [companySettingList, interfaceSettingList, companyCurrencies]);

    return (
        <div className={clsx(classes.baseStyle, style)}>
            {numberFormatter.getFormattedCurrencyById(currencyId, value, true, true)}/{t('default.areUnit', UNITS.DEFAULT_AREA)}
        </div>
    );
}
