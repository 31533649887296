/* eslint-disable id-match */
/* eslint-disable id-blacklist */
import { useMemo, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { UNITS, SETTINGS, METEOROLOGY } from 'shared/src/constants';
import { numberUtils } from 'shared/src/modules/numberUtils';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { theme } from '@styles/theme';
import useTranslate from '@i18n/useTranslate';
import { dateTimeUtils } from 'shared/src/modules';
import WeatherChart from '@baseComponents/charts/WeatherChart';
import Svg from '@baseComponents/Svg';
import clsx from 'clsx';
import { companyActions } from '@states/actions';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isNull } from 'lodash';

const useStyles = createUseStyles({
    weatherChartWrapper: {
        display: 'flex',
    },
    weatherChartData: {
        width: '100%',
        flex: 1,
        position: 'relative',
        '& .highcharts-container, .highcharts-container svg': {
            width: '100% !important',
        },
    },
    chartData: {
        height: '100px',
        marginBottom: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexFlow: 'column',
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 1.33,
    },
    averageData: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.25,
    },
    icons: {
        height: '25px !important',
        width: '25px !important',
    },
    temperatureColor: {
        fill: theme.color.status,
    },
    waterColor: {
        fill: theme.color.water,
    },
    mainColor: {
        fill: theme.color.main,
    },
});

export default function WeatherForecastModalCharts(props) {
    const { weatherData } = props;
    const { t, translater } = useTranslate();
    const dispatch = useDispatch();
    const [temperatureUnitId] = useState(dispatch(companyActions.getSetting(SETTINGS.METEOROLOGY_TEMPERATURE_UNIT)));
    const [pressureUnitId] = useState(dispatch(companyActions.getSetting(SETTINGS.METEOROLOGY_ATMOSPHERIC_PRESSURE_UNIT)));
    const temperatureUnits = useSelector((state: any) => state.dataLists.temperatureUnits);
    const atmosphericPressureUnits = useSelector((state: any) => state.dataLists.atmosphericPressureUnits);
    const classes = useStyles();

    const temperatureUnit = useMemo(() => {
        if (temperatureUnitId && !isEmpty(temperatureUnits)) {
            return temperatureUnits?.find(unitData => unitData.id === temperatureUnitId)?.name;
        }
        return UNITS.DEFAULT_TEMPERATURE;
    }, [temperatureUnitId, temperatureUnits]);

    const pressureUnit = useMemo(() => {
        if (pressureUnitId && !isEmpty(atmosphericPressureUnits)) {
            return atmosphericPressureUnits?.find(unitData => unitData.id === pressureUnitId)?.name;
        }
        return UNITS.DEFAULT_PRESSURE;
    }, [pressureUnitId, atmosphericPressureUnits]);

    function getDataForChart(breakdown) {
        const breakdownData = weatherData.map(chartData => ({
            date: chartData.date,
            data: breakdown === 'atmosphericPressure'
                ? (+chartData[breakdown] || 0)
                : numberUtils.decimalRound((+chartData[breakdown] || 0), UNITS.DECIMAL_NUMBER.WEATHER_DATA),
        }));

        return breakdownData;
    }

    function getAverageData(breakdown) {
        let dataSum = 0;
        let dataCount = 0;
        weatherData.forEach(dataObj => {
            if (!isNull(dataObj[breakdown])) {
                dataSum += (+dataObj[breakdown] || 0);
                dataCount += 1;
            }
        });
        return dataCount ? numberUtils.decimalRound(dataSum / dataCount, breakdown === 'atmosphericPressure' ? 4 : UNITS.DECIMAL_NUMBER.WEATHER_DATA) : '-';
    }

    function getSumData(breakdown) {
        const dataSum = arrayUtils.getSumByKeyString(weatherData, breakdown);
        return numberUtils.decimalRound(dataSum, UNITS.DECIMAL_NUMBER.WEATHER_DATA);
    }

    return (
        <div className={classes.weatherChartWrapper}>
            <div className={classes.weatherChartData}>
                <WeatherChart
                    type="areaspline"
                    iconId="icon-wind_thermometer"
                    weatherData={getDataForChart('temperature')}
                    color={theme.color.status}
                    dateFormatter={date => dateTimeUtils.getUTCFormat(date)}
                    unit={temperatureUnit}
                    isShowZero={METEOROLOGY.UNIT.CELSIUS === temperatureUnit}
                />
                <WeatherChart
                    type="column"
                    weatherData={getDataForChart('fall')}
                    color={theme.color.water}
                    iconId="icon-rain"
                    dateFormatter={date => dateTimeUtils.getUTCFormat(date)}
                    unit={UNITS.DEFAULT_PRECIPITATION}
                />
                <WeatherChart
                    type="areaspline"
                    iconId="icon-humidity"
                    weatherData={getDataForChart('humidity')}
                    color={theme.color.water}
                    dateFormatter={date => dateTimeUtils.getUTCFormat(date)}
                    unit={UNITS.DEFAULT_HUMIDITY}
                />
                <WeatherChart
                    type="areaspline"
                    iconId="icon-dew_point"
                    weatherData={getDataForChart('atmosphericPressure')}
                    color={theme.color.main}
                    dateFormatter={date => dateTimeUtils.getUTCFormat(date)}
                    unit={pressureUnit}
                />
            </div>
            <div className={classes.weatherChartAggregate}>
                <div className={classes.chartData} title={translater('meteorologyTable.temperature', 'Temperature')}>
                    <Svg iconId="icon-wind_thermometer" style={clsx(classes.icons, classes.temperatureColor)} />
                    {t('weatherModal.average', 'Average')}
                    <span className={classes.averageData}>{weatherData && getAverageData('temperature')} {temperatureUnit}</span>
                </div>
                <div className={classes.chartData} title={translater('meteorologyTable.fall', 'Fall')}>
                    <Svg iconId="icon-rain" style={clsx(classes.icons, classes.waterColor)} />
                    {t('default.total', 'total')}
                    <span className={classes.averageData}>{weatherData && getSumData('fall')} {UNITS.DEFAULT_PRECIPITATION}</span>
                </div>
                <div className={classes.chartData} title={translater('meteorologyTable.humidity', 'Humidity')}>
                    <Svg iconId="icon-humidity" style={clsx(classes.icons, classes.waterColor)} />
                    {t('weatherModal.average', 'Average')}
                    <span className={classes.averageData}>{weatherData && getAverageData('humidity')} {UNITS.DEFAULT_HUMIDITY}</span>
                </div>
                <div className={classes.chartData} title={translater('meteorologyTable.atmosphericPressure', 'Atmospheric pressure')}>
                    <Svg iconId="icon-clock" style={clsx(classes.icons, classes.mainColor)} />
                    {t('weatherModal.average', 'Average')}
                    <span className={classes.averageData}>{weatherData && getAverageData('atmosphericPressure')} {pressureUnit}</span>
                </div>
            </div>
        </div>
    );
}
