import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { isNull, isEmpty } from 'lodash';
import { dateTimeUtils } from 'shared/src/modules';
import Input from '../../inputs/Input';

const useStyles = createUseStyles({
    input: {
        marginBottom: 10,
    },
});

function DateInput(props) {
    const { date, setDate, isValidDate, setValidDate, minDate, maxDate, required } = props;
    const { t } = useTranslation();
    const [inputDate, setInputDate] = useState(date || '');
    const { getDateObject, isAfterDate, getDateMask, getMomentObjectFromDateString } = dateTimeUtils;
    const dateMask = getDateMask();
    const inputMask = dateMask.replace(/\d/g, '_');
    const classes = useStyles();

    useEffect(() => {
        if (!isNull(dateTimeUtils.getDate(date))) {
            setInputDate(dateTimeUtils.getDate(date));
        }
    }, [date]);

    function handleChangeInput(event) {
        const value = event.target.value;
        setInputDate(value);
        const momentDate = getMomentObjectFromDateString(event.target.value);

        if (dateTimeUtils.isValidDate(value) && ((!isEmpty(minDate) && isAfterDate(momentDate, minDate)) || (!isEmpty(maxDate) && isAfterDate(maxDate, momentDate)) || (isEmpty(minDate) && isEmpty(maxDate)))) {
            setDate(getDateObject(momentDate));
            setValidDate(true);
        } else if (!required && (value === inputMask || isEmpty(value))) {
            setDate(null);
            setValidDate(true);
        } else {
            setValidDate(false);
        }
    }

    return (
        <Input
            className={classes.input}
            value={inputDate}
            onChange={handleChangeInput}
            type="date"
            mask={dateMask}
            errorMessage={t('calendar.validDate', 'This date is not valid')}
            invalid={!isValidDate}
        />
    );
}

export default React.memo(DateInput);
