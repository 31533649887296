export const PLANNING = {
    ROUTE_KEY_EXTENSION: 'Index',
    MODULE: {
        CROP_ROTATION: 'cropRotationModule',
        TECHNOLOGIES: 'technologiesModule',        
        STOCK: 'stockModule',
        EQUIPMENT: 'equipmentModule',
        LABOR: 'laborModule',
        CONTROLLING: 'controllingModule',
        PRICE: 'priceModule',
        REPORTS: 'reportsModule',
        MAP: 'mapModule',
    },
};
