import { isEmpty } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { ImportFile } from '@baseComponents/inputs/files';
import { FILES } from 'shared/src/constants/files';
import useSoilAnalysisExcelImportData from './useSoilAnalysisExcelImportData';
import SoilAnalysisTable from './SoilAnalysisTable';

export default function SoilAnalysisExcelImport(props) {
    const { tableId, cultivationPeriodId } = props;

    const { watch, getValues } = useFormContext();

    const { onChangeImportData, onClickImportData } = useSoilAnalysisExcelImportData({ companyId: watch('companyId'), cultivationPeriodId });

    return (
        !isEmpty(watch('excelInterface')) && !isEmpty(watch('soilAnalysisData'))
            ? (
                <SoilAnalysisTable
                    tableData={getValues('soilAnalysisData')}
                    tableColumns={watch('excelInterface')}
                    tableId={tableId}
                />
            )
            : (
                <ImportFile
                    onChange={onChangeImportData}
                    onClick={onClickImportData}
                    inputAccept={FILES.INPUT_ACCEPT_TYPE.EXCEL}
                />
            )
    );
}
