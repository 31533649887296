import Checkbox from '@baseComponents/controls/Checkbox';
import Radio from '@baseComponents/controls/Radio';
import useTranslate from '@i18n/useTranslate';
import { MapFilters, ThemeType } from '@map/services/mapTypes';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import MapFilterBase from './MapFilterBase';
import { MapFilterSchema } from './MapFilterSchema';

type Classes = 'container' | 'title' | 'checkboxLabel' | 'checkboxContainer'

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    title: {
        marginBottom: 15,
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
    },
    checkboxContainer: {
        marginBottom: 10,
    },
    checkboxLabel: {
        color: theme.color.jet,
        fontSize: '12px',
        letterSpacing: 0,
        lineHeight: '20px',
    },
}));
export default function MapFilterStatus() {
    const filterContext = useFormContext<MapFilterSchema>();

    const classes = useStyles();

    const setShowClosedPeriods = (value: boolean) => {
        filterContext.setValue('showClosedPeriods', value);
    };

    const { translater, t } = useTranslate();

    return (
        <MapFilterBase>
            <div className={classes.container}>
                <div className={classes.title}>{t('filter.status', 'Status')}</div>
                <Controller
                    control={filterContext.control}
                    name="showClosedPeriods"
                    render={field => (
                        <Checkbox
                            className={classes.checkboxContainer}
                            text={translater('cultivationPeriods.showClosedPeriod', 'Show closed periods')}
                            checked={field.field.value}
                            setChecked={setShowClosedPeriods}
                            textStyle={classes.checkboxLabel}
                            size="small"
                            ignoreFirstLetterFormatter
                        />
                    )}
                />
            </div>
        </MapFilterBase>
    );
}
