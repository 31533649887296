import { useEffect, useState } from 'react';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { useMapContext } from '@map/services/mapContext';
import { isNil } from 'lodash';

type TProps = {
    selectedLayerId: Nullable<number>,
    productionYearId: Nullable<number>,
}

export default function useGetEntityPairingModal(props: TProps) {
    const { selectedLayerId, productionYearId } = props;
    const [currentModalComponent, setCurrentModalComponent] = useState();
    const [currentModalComponentProps, setCurrentModalComponentProps] = useState();
    const [mapStore, mapController] = useMapContext();

    useEffect(() => {
        const getCurrentModalComponent = async () => {
            let modalComponent;
            let modalComponentProps;

            if (isNil(selectedLayerId)) {
                return;
            }

            const layer = mapController.getLayerById(selectedLayerId);

            switch (layer?.name) {
                case BuiltInLayerTypes.LOT:
                    modalComponent = await import('@customComponents/modals/basicFieldsModal/BasicFieldsModal');
                    break;
                case BuiltInLayerTypes.CULTIVATION_PERIOD:
                    modalComponent = await import('@customComponents/modals/cultivationPeriodsModal/CultivationPeriodsModal');
                    modalComponentProps = { showPeriodYearSelect: false, defaultProductionYear: productionYearId };
                    break;
                case BuiltInLayerTypes.LPIS_FIELD:
                    modalComponent = await import('@customComponents/modals/lpisFieldsModal/LpisFieldsModal');
                    modalComponentProps = { productionYearId };
                    break;
                case BuiltInLayerTypes.REGISTRY_NUMBER:
                    modalComponent = await import('@customComponents/modals/registryNumberModal/RegistryNumbersModal');
                    break;
                case BuiltInLayerTypes.PREMISE:
                    modalComponent = await import('@customComponents/modals/premisesModal/PremisesModal');
                    break;
                case BuiltInLayerTypes.STABLE:
                    modalComponent = await import('@customComponents/modals/stablesModal/StablesModal');
                    break;
                case BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP:
                    modalComponent = await import('@customComponents/modals/cultivationPeriodGroupsModal/CultivationPeriodGroupsModal');
                    modalComponentProps = { productionYearId };
                    break;
                default:
                    break;
            }
            setCurrentModalComponent(modalComponent);
            setCurrentModalComponentProps(modalComponentProps);
        };

        if (selectedLayerId) {
            getCurrentModalComponent();
        }
    }, [selectedLayerId, productionYearId, mapController]);

    return {
        currentModalComponent: currentModalComponent as unknown as Nullable<JSX.Element>,
        currentModalComponentProps,
    };
}
