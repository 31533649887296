import { useSlovakReportContext } from '@map/services/slovakianReports/slovakReportContext';
import { useEffect } from 'react';

export default function useInitSlovakReportsOverlay(map: google.maps.Map | null) {
    const [store, controller] = useSlovakReportContext();

    useEffect(() => {
        async function init(m: google.maps.Map) {
            controller.initDataLayers(m);
        }
        if (!store.isInitalized && map && controller) {
            init(map);
        }
    }, [store.isInitalized, map, controller]);
}
