import { COUNTRY, SETTINGS as SETTING, RIGHTS } from '../constants';

export const SETTINGS = {
    MODULE_ID: 17,
    NAME: 'settings',
    RIGHTS: [],
    SETTINGS: [],
    EXCLUDE_COUNTRIES: [],
    SYSTEM_PERMISSIONS: {
        CONSULTANT: false,
    },
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'settings',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.settings',
                TRANSLATOR_DEFAULT: 'Settings',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        CUSTOMIZATION_OF_COST_CALCULATION: {
            CONTENT_URL_ID: { customizetionOfCostCalculation: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'customizationOfCostCalculation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.customizationOfCostCalculation',
                TRANSLATOR_DEFAULT: 'Customization of cost calculation',
            },
            SHOW_IN_PANNING_MODE: true,
            SETTINGS: [
                { [SETTING.USE_GLOBAL_LITE]: 0 }
            ],
        },
        SHOW_PRODUCT_TYPE_PER_COMPANY: {
            CONTENT_URL_ID: { showProductTypePerCompany: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'showProductTypePerCompany',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.showProductTypePerCompany',
                TRANSLATOR_DEFAULT: 'Show product type per company',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        WARNINGS: {
            CONTENT_URL_ID: { warnings: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'warnings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.warnings',
                TRANSLATOR_DEFAULT: 'Warnings',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        CONTROLLING_RECORDS: {
            CONTENT_URL_ID: { controllingRecords: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'controllingRecords',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.controllingRecords',
                TRANSLATOR_DEFAULT: 'Controlling records',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        WORKFLOW_RECORDS: {
            CONTENT_URL_ID: { workflowRecords: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workflowRecords',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workflowRecords',
                TRANSLATOR_DEFAULT: 'Workflow records',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        EQUIPMENT: {
            CONTENT_URL_ID: { customizeFuelInterface: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'customizeFuelInterface',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.customizeFuelInterface',
                TRANSLATOR_DEFAULT: 'Customize fuel interface',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        WEIGHING_RECORDS: {
            CONTENT_URL_ID: { weighingRecords: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'weighingRecords',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.weighingRecords',
                TRANSLATOR_DEFAULT: 'Weighing records',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        STOCK_RECORDS: {
            CONTENT_URL_ID: { stockRecords: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockRecords',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockRecords',
                TRANSLATOR_DEFAULT: 'Stock records',
            },
            SHOW_IN_PANNING_MODE: true,
            SETTINGS: [
                { [SETTING.USE_GLOBAL_LITE]: 0 }
            ],
        },
        LAND_ADMINISTRATION: {
            CONTENT_URL_ID: { landAdministration: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'landAdministrationSettings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.landAdministrationSettings',
                TRANSLATOR_DEFAULT: 'Land administration settings',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        FIELD_RECORDS: {
            CONTENT_URL_ID: { fieldRecords: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'fieldRecords',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.fieldRecords',
                TRANSLATOR_DEFAULT: 'Field records',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        ADMIN_SETTINGS: {
            CONTENT_URL_ID: { adminSettings: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'adminSettings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.adminSettings',
                TRANSLATOR_DEFAULT: 'Admin settings',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        DAILY_EMAIL_REPORT: {
            CONTENT_URL_ID: { dailyEmailReport: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'dailyEmailReport',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.dailyEmailReport',
                TRANSLATOR_DEFAULT: 'Daily email report',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        DEADLINES_OF_OFFICIAL_DOCUMENTS: {
            CONTENT_URL_ID: { deadlinesOfOfficialDocuments: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'deadlinesOfOfficialDocuments',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.deadlinesOfOfficialDocuments',
                TRANSLATOR_DEFAULT: 'Deadlines of official documents',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        OFFICIAL_DATA_MANAGEMENT: {
            CONTENT_URL_ID: { officialDataManagement: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'officialDataManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.officialDataManagement',
                TRANSLATOR_DEFAULT: 'Official data management',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        YIELDING_EXCEL: {
            CONTENT_URL_ID: { yieldingExcel: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'yieldingExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.yieldingExcel',
                TRANSLATOR_DEFAULT: 'Yielding excel',
            },
            SHOW_IN_PANNING_MODE: true,
            SETTINGS: [
                { [SETTING.USE_GLOBAL_LITE]: 0 }
            ],
        },
        INCOMINGS_EXCEL: {
            CONTENT_URL_ID: { incomingsExcel: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'incomingsExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.incomingsExcel',
                TRANSLATOR_DEFAULT: 'Incoming excel',
            },
            SHOW_IN_PANNING_MODE: true,
            SETTINGS: [
                { [SETTING.USE_GLOBAL_LITE]: 0 }
            ],
        },
        CUSTOMIZATION_BOOK_KEEPING_CONNECTION: {
            CONTENT_URL_ID: { customizationBookKeepingConnection: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'customizationBookKeepingConnection',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.customizationBookKeepingConnection',
                TRANSLATOR_DEFAULT: 'Customization of book-keeping connection',
            },
            SYSTEM_PERMISSIONS: {
                ADMINISTRATOR: true,
            },
            SHOW_IN_PANNING_MODE: true,
        },
        DYNTELL_DATA_RO: {
            CONTENT_URL_ID: { dyntellDataRO: 22 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'dyntellDataRO',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Date preluate din Dyntell',
            },
            SYSTEM_PERMISSIONS: {
                ADMINISTRATOR: true,
            },
            SETTINGS: [SETTING.DYNTELL_CONTACT],
            SHOW_IN_PANNING_MODE: true,
        },
        CUSTOMIZATION_WORKOPERATION_INTERFACE: {
            CONTENT_URL_ID: { customizationWorkoperationInterface: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'customizationWorkoperationInterface',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.customizationWorkoperationInterface',
                TRANSLATOR_DEFAULT: 'Customization of work operation interface',
            },
            SETTINGS: [SETTING.USING_WORKOPERATION_FROM_EXCEL],
            SHOW_IN_PANNING_MODE: true,
        },
        SYNCHRONIZE_CORE_DATA: {
            CONTENT_URL_ID: { synchronizeCoreData: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'synchronizeCoreData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.synchronizeCoreData',
                TRANSLATOR_DEFAULT: 'Synchronize core data',
            },
            SETTINGS: [
                { [SETTING.USE_GLOBAL_LITE]: 0 }
            ],
            SHOW_IN_PANNING_MODE: true,
        },
        REQUEST_MEASURED_DATA: {
            CONTENT_URL_ID: { requestMeasuredData: 21 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'requestMeasuredData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.requestMeasuredData',
                TRANSLATOR_DEFAULT: 'Request measured data',
            },
            SETTINGS: [
                { [SETTING.USE_GLOBAL_LITE]: 0 }
            ],
            SHOW_IN_PANNING_MODE: true,
        },
        RECIPES: {
            CONTENT_URL_ID: { recipes: 23 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'recipes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.recipes',
                TRANSLATOR_DEFAULT: 'Recipes',
            },
            SETTINGS: [SETTING.YIELD_USING_RAW_MATERIAL],
            SHOW_IN_PANNING_MODE: true,
        },
        CUSTOMIZE_GRAIN_MANAGEMENT: {
            CONTENT_URL_ID: { customizeGrainManagement: 24 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'customizeGrainManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.customizeGrainManagement',
                TRANSLATOR_DEFAULT: 'Customize grain management',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        RECEIVED_FLEET_MOBILE_DATA: {
            CONTENT_URL_ID: { receivedMobileFleetData: 25 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'receivedMobileFleetData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.receivedFleetData',
                TRANSLATOR_DEFAULT: 'Received fleet data',
            },
        },
        CUSTOMIZE_DELIVERY_NOTE: {
            CONTENT_URL_ID: { customizeDeliveryNote: 26 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'customizeDeliveryNote',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.customizeDeliveryNote',
                TRANSLATOR_DEFAULT: 'Customize delivery note',
            },
        },
        EXTERNAL_SCALE_SOFTWARE_CONNECTION: {
            CONTENT_URL_ID: { externalScaleMeasureImport: 27 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'externalScaleMeasureImport',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.externalScaleMeasureImport',
                TRANSLATOR_DEFAULT: 'External scale measure import',
            },
        },
        YIELDING_EXCEL_PROCESSING_PLANT: {
            CONTENT_URL_ID: { yieldingExcelProcessingPlant: 28 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'yieldingExcelProcessingPlant',
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            SHOW_IN_PANNING_MODE: true,
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Hozamolás excelből (feldolgozóüzem) interface testreszabása',
            },
        },
        RELEASES_EXCEL: {
            CONTENT_URL_ID: { releasesExcel: 29 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'releasesExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.releasesExcelInterface',
                TRANSLATOR_DEFAULT: 'Releases excel interface',
            },
            SHOW_IN_PANNING_MODE: true,
            SETTINGS: [
                { [SETTING.USE_GLOBAL_LITE]: 0 }
            ],
        },       
        SOIL_ANALYSIS_EXCEL: {
            CONTENT_URL_ID: { soilAnalysisExcel: 30 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'soilAnalysisExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.soilAnalysisExcelInterface',
                TRANSLATOR_DEFAULT: 'Soil analysis excel interface',
            },
            SHOW_IN_PANNING_MODE: true,
        },
        SOIL_PRODUCTIVITY_PARAMETERS: {
            CONTENT_URL_ID: { soilProductivityParameters: 31 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'soilProductivityParameters',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.soilProductivityParameters',
                TRANSLATOR_DEFAULT: 'Parameters',
            },
        },
        DATA_SYNCHRONIZATION: {
            CONTENT_URL_ID: { dataSynchronization: 32 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'dataSynchronization',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.dataSynchronization',
                TRANSLATOR_DEFAULT: 'Data synchronization',
            },
            RIGHTS: [RIGHTS.EGN_SETTING_INTERFACE],
        },
        TOKEN_MANAGEMENT: {
            CONTENT_URL_ID: { tokensManagement: 33 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'tokensManagement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.tokensManagement',
                TRANSLATOR_DEFAULT: 'Tokens management',
            },
            RIGHTS: [RIGHTS.EGN_SETTING_INTERFACE],
        },
        BUSINESS_INTELLIGENCE: {
            CONTENT_URL_ID: { businessIntelligence: 34 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'businessIntelligence',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.businessIntelligence',
                TRANSLATOR_DEFAULT: 'Business Intelligence',
            },
            SETTINGS: [SETTING.BUSINESS_INTELLIGENCE_EXPORT_ENABLED],
        },
        MINIMUM_PRODUCT_STOCK: {
            CONTENT_URL_ID: { minimumProductStock: 35 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'minimumProductStock',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.minimumProductStock',
                TRANSLATOR_DEFAULT: 'Minimum product stock',
            },
            SYSTEM_PERMISSIONS: {
                ADMINISTRATOR: true,
            },
            SHOW_IN_PANNING_MODE: true,
        },
        WORKOPERATION_QUALIFICATION: {
            CONTENT_URL_ID: { workoperationQualification: 36 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workoperationQualification',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workoperationQualification',
                TRANSLATOR_DEFAULT: 'Workoperation qualification',
            },
            SETTINGS: [SETTING.WORKOPERATION_QUALIFICATION],
        },
        EGN_USE_PERMIT: {
            CONTENT_URL_ID: { egnUsePermit: 37 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'egnUsePermit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.egnUsePermit',
                TRANSLATOR_DEFAULT: 'E-GN use permit',
            },
            SYSTEM_PERMISSIONS: {
                ADMINISTRATOR: true,
            },
            RIGHTS: [RIGHTS.EGN_SETTING_INTERFACE],
        },
        COMPANY_SETTINGS: {
            CONTENT_URL_ID: { companySettings: 38 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'companySettings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.companySettings',
                TRANSLATOR_DEFAULT: 'Company settings',
            },
            SETTINGS: [
                { [SETTING.USE_GLOBAL_LITE]: 0 }
            ],
            SHOW_IN_PANNING_MODE: true,
        },
        TREATMENT_UNITS: {
            CONTENT_URL_ID: { treatmentUnits: 39 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'treatmentUnits',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.treatmentUnits',
                TRANSLATOR_DEFAULT: 'Treatment units',
            },
            SETTINGS: [SETTING.USING_TREATMENT_UNIT],
            SHOW_IN_PANNING_MODE: true,
        },
        GSAA: {
            CONTENT_URL_ID: { gsaaSettings: 40 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'gsaa',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Nastavenia GSAA',
            },
            SHOW_IN_PANNING_MODE: false,
        },
    },
};
