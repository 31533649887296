import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

import useAggregate from './useAggregate';

const useStyles = createUseStyles((theme: any) => ({
    aggregateCellWrapper: {
        padding: 0,
        margin: 0,
        display: 'flex',
        height: '100%',
        width: '100%',
        alignItems: 'center',
    },
    cellHeight: {
        lineHeight: `${theme.table.tableRowHeight}px !important`,
    },
}));

export default function TableAggregateCell(props) {
    const { tableData, column, style } = props;
    const { aggregateType, aggregateFunction, aggregateUnit, columnId, componentProps, aggregateFormatter } = column; //column data
    const [aggregateCellId] = useState(componentProps?.cellId ? `${componentProps?.cellId}Total` : null);
    const { aggregateValue } = useAggregate({ aggregateType, aggregateFunction, aggregateUnit, tableData, columnId, componentProps, column });

    const classes = useStyles();

    return (
        <h4 className={clsx(classes.aggregateCellWrapper, style?.aggregateValue)} id={aggregateCellId}>
            {aggregateFormatter
                ? <column.aggregateFormatter value={aggregateValue || 0} className={clsx(style?.aggregateFormatter ? style.aggregateFormatter : classes.cellHeight)} />
                : aggregateValue
            }
        </h4>
    );
}
