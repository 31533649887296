/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { SketchPicker } from 'react-color';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import Button from '@baseComponents/buttons/Button';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        height: 60,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    pickedColorButton: {
        width: '100%',
        height: 40,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .title': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 0,
            height: 40,
        },
        '& .content': {
            display: 'flex',
            alignItems: 'center',
            margin: 0,
            padding: 0,
            height: '100%',
            width: '100%',
        },
    },
    pickedColorLabel: {
        fontSize: 15,
        fontWeight: 300,
        margin: 0,
        paddingLeft: 15,
    },
    pickedColor: {
        width: 25,
        height: 25,
        display: 'block',
        borderRadius: 3,
        marginRight: 6,
        background: (props: any) => props.color,
        border: 'solid 1px',
        borderColor: theme.color.gray,
    },
    label: {
        textTransform: 'uppercase',
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: theme.color.main,
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
    popover: {
        border: 'none',
        maxWidth: 'fit-content',
        '& .popover-body': {
            padding: 0,
        },
        '& .arrow::before': {
            borderColor: 'transparent',
            borderTopColor: 'white',
            borderWidth: 13,
            top: 4,
            left: -20,
        },
        zIndex: theme.zIndex.popover,
    },
    dropdownMenuWrapper: {
        borderRadius: 6,
        padding: 0,
    },
    dropdownMenuItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        minWidth: 250,
        fontSize: '15px',
        fontWeight: 'normal',
        lineHeight: '1.33',
        color: theme.color.jet,
        outline: 'none',
        '&:hover': {
            backgroundColor: theme.color.opacity.main,
            color: theme.color.main,
            cursor: 'pointer',
            '& $menuItemIcon': {
                fill: theme.color.main,
            },
        },
        '& .disabled': {
            opacity: 1,
            backgroundColor: theme.color.white,
            color: theme.color.gray,
            '&:hover': {
                backgroundColor: theme.color.white,
                cursor: 'default',
                color: theme.color.gray,
            },
        },
        '&:first-child': {
            '&:hover': {
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
            },
        },
    },
}));

export default function SimpleColorPicker(props) {
    const { value = '#ff0000', onChange, className, label, required } = props;
    const dropdownCellRef = useRef<any>();

    const [color, setColor] = useState(value);
    const classes = useStyles({ color });

    useEffect(() => {
        if (dropdownCellRef && !isEmpty(dropdownCellRef.current)) {
            dropdownCellRef.current.parentElement.style.overflow = 'visible';
            dropdownCellRef.current.parentElement.style.paddingLeft = 0;
        }
    }, [dropdownCellRef]);

    function onColorChange(colorResult) {
        setColor(colorResult.hex);
        if (onChange) {
            onChange(colorResult.hex);
        }
    }

    return (
        <div ref={dropdownCellRef} role="button" tabIndex={0}>
            <OverlayTrigger
                trigger="click"
                placement="top"
                rootClose
                overlay={
                    <Popover className={classes.popover}>
                        <Popover.Content>
                            <div className={classes.dropdownMenuWrapper}>
                                <SketchPicker
                                    color={color}
                                    onChange={onColorChange}
                                />
                            </div>
                        </Popover.Content>
                    </Popover>
                }
            >
                <div className={clsx(className, classes.container)}>
                    { label && (
                        <div className={classes.label}>
                            {label}
                            {required && <span className={classes.dot} />}
                        </div>
                    )}
                    <Button type="secondary" iconPosition="none" className={classes.pickedColorButton}>
                        <p className={classes.pickedColorLabel}>{color}</p>
                        <div className={classes.pickedColor} />
                    </Button>
                </div>
            </OverlayTrigger>
        </div>
    );
}
