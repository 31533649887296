import React, { useEffect, useState } from 'react';
import { FormSelect } from '@baseComponents/select';
import useTranslate from '@i18n/useTranslate';
import { useDispatch } from 'react-redux';
import { meteorologyService } from '@services/meteorologyService';
import { alertActions, errorActions } from '@states/actions';
import { arrayUtils } from 'shared/src/modules';
import renderModal from '@modals/modalNew/modalFunctions';
import LocationSelectorModal from '@map/modals/MeterologyModal';

export default function LocationSelect(props) {
    const { location, setLocation, className, isInvalid, errorMessage, isTitle = true, isAddNew = true } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const [locationList, setLocationList] = useState();

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                let locations = await meteorologyService.getLocations();

                if (!isCancelled) {
                    locations = arrayUtils.getSelectListFormat(locations, 'name', 'id');
                    setLocationList(locations);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'LocationSelector'));
            }
        }

        if (dispatch) {
            loadData();
        }
        return () => {
            isCancelled = true;
        };
    }, [dispatch]);

    function onSuccesNewLocation(response) {
        if (response.error) {
            dispatch(errorActions.setError(response.error, 'LocationSelector'));
        } else {
            setLocation(response.id);
            setLocationList(prevLocations => ([...prevLocations, { value: response.locationName, key: response.id }]));
            dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved')));
        }
    }

    function onFailureNewLocation() {
        dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed')));
    }

    function addNewLocation() {
        renderModal(LocationSelectorModal, {
            entityId: 0,
            onSuccess: onSuccesNewLocation,
            onFailure: onFailureNewLocation,
        }, true);
    }

    return (
        <FormSelect
            label={!!isTitle && t('location.location', 'Location')}
            dataList={locationList}
            selected={location}
            onChange={value => setLocation(+value)}
            className={className}
            addNew={!!isAddNew && addNewLocation}
            invalid={isInvalid}
            errorMessage={errorMessage}
        />
    );
}
