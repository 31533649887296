import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import renderModal from '@modals/modalNew/modalFunctions';
import ConfirmDialog from '@baseComponents/modals/ConfirmDialog';
import { sessionService, userService } from '@services';
import { numberUtils } from 'shared/src/modules';
import { userActions } from '@states/actions/userActions';

const useDarkMode = (isActiveDarkMode: boolean) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    function setDarkModeCss(isDarkMode) {
        const docBody = document.body;
        if (docBody) {
            docBody.classList.toggle('dark-mode', isDarkMode);
        }
    }

    async function enableDarkMode() {
        const successUpdate = await userService.setUserDarkMode(true);
        if (successUpdate) {
            dispatch(userActions.updateUser());
            sessionService.setDarkMode(true);
        }
    }

    async function askUserForDarkMode() {
        const readDarkModeQuestion = await userService.getReadUserQuestion('darkMode');
        if (!readDarkModeQuestion) {
            await new Promise(resolve => setTimeout(resolve, 1000));

            renderModal(ConfirmDialog, {
                iconId: 'icon-help',
                isAcceptCancelDialog: true,
                acceptButtonText: t('default.yes', 'Yes'),
                cancelButtonText: t('default.no', 'No'),
                questionText: t('modal.darkModeQuestionTitle', 'We noticed that you are using dark mode.'),
                descriptionText: t('modal.darkModeQuestion', 'Would you like to try AgroVIR\'s dark mode? You can adjust the setting when editing your profile.'),
                confirmCallback: () => {
                    setDarkModeCss(true);
                    enableDarkMode();
                },
            }, false, true);

            await userService.setReadUserQuestion('darkMode');
        }
    }

    useEffect(() => {
        const darkMode = numberUtils.getBoolean(isActiveDarkMode);
        setDarkModeCss(darkMode);
        if (!darkMode) {
            const browserIsDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (browserIsDarkMode) {
                askUserForDarkMode();
            }
        }

        sessionService.setDarkMode(darkMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActiveDarkMode]);
};

export default useDarkMode;
