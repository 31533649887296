import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import useTranslate from '@i18n/useTranslate';
import { errorActions } from '@states/actions';
import { soilAnalysisService } from '@services';
import { renderModal } from '@baseComponents/modals';
import AnalysisDataEdit from '@contents/cultivationPeriods/analysisDataService/analysisDataEdit/AnalysisDataEdit';
import DeleteSoilAnalysisModal from '@contents/cultivationPeriods/analysisDataService/deleteAnalysisData/DeleteAnalysisData';
import { theme } from '@styles/theme';

export default function useSoilAnalysisViewTable(props) {
    const { cultivationPeriodId, setIsLoadTableData } = props;
    const [parameters, setParameters] = useState<Array<any>>([]);
    const [soilAnalysisTableData, setSoilAnalysisTableData] = useState([]);
    const [refreshTable, setRefreshTable] = useState(0);
    const dispatch = useDispatch();
    const { t } = useTranslate();

    const defaultColumns = useMemo(() => {
        const soilAnalysisColumns: Array<any> = [
            {
                isLeftFixed: true,
                type: columnName.date,
                columnId: 'date',
                columnName: t('soilAnalysisViewImport.date', 'Date'),
                width: 80,
                minWidth: 80,
                maxWidth: 80,
            },
            {
                isLeftFixed: true,
                type: columnName.date,
                columnId: 'validityDate',
                columnName: t('soilAnalysisImport.validityDate', 'Validity date'),
                width: 80,
                minWidth: 80,
                maxWidth: 80,
            },
            {
                isLeftFixed: true,
                type: columnName.simpleText,
                columnName: t('soilAnalysisViewImport.registryNumber', 'Registry number'),
                columnId: 'registryNumber',
                width: 140,
                minWidth: 140,
                maxWidth: 140,
            },
            {
                isLeftFixed: true,
                type: columnName.simpleText,
                columnName: t('soilAnalysisImport.laboratory', 'Accredited laboratory'),
                columnId: 'laboratoryName',
                width: 140,
                minWidth: 140,
                maxWidth: 140,
            },
            {
                isLeftFixed: true,
                type: columnName.simpleText,
                columnId: 'code',
                columnName: t('soilAnalysisViewTable.code', 'Code'),
                width: 100,
                minWidth: 100,
                maxWidth: 100,
            },
            {
                isLeftFixed: true,
                type: columnName.quantity,
                columnId: 'depth',
                columnName: t('soilAnalysisViewImport.depth', 'Depth'),
                width: 100,
                minWidth: 100,
                maxWidth: 100,
                componentProps: {
                    defaultUnit: t('default.centimeter', 'cm'),
                },
            },
            {
                isLeftFixed: true,
                type: columnName.area,
                columnId: 'samplingArea',
                columnName: t('soilAnalysisImport.samplingArea', 'Sampling area'),
                width: 100,
                minWidth: 100,
                maxWidth: 100,
            },
            {
                type: columnName.editButton,
                columnId: 'editBtn',
                isRightFixed: true,
                componentProps: {
                    editFunction: {
                        title: t('default.editing', 'Editing'),
                        onClick: rowData => renderModal(AnalysisDataEdit, { ...rowData, id: rowData?.soilAnalysisId, onAfterHideModal: () => setRefreshTable(p => p + 1) }),
                        iconId: 'icon-edit',
                    },
                },
            },
            {
                type: columnName.iconButton,
                isRightFixed: true,
                componentProps: {
                    type: 'destructive',
                    getCellTitle: () => t('default.delete', 'Delete'),
                    onClick: rowData => renderModal(DeleteSoilAnalysisModal, { rowData, onAfterHideModal: () => setRefreshTable(p => p + 1), cultivationPeriodId, deleteByItemId: true }, false),
                    getIcon: () => 'icon-bin',
                    getIconColor: () => theme.color.destructive,
                },
                width: 65,
                minWidth: 65,
                maxWidth: 65,
            },
        ];

        parameters?.forEach(column => {
            soilAnalysisColumns.push({
                type: columnName.quantity,
                columnId: column.sqlName,
                columnName: column.name,
                width: 100,
                minWidth: 100,
                maxWidth: 100,
            });
        });

        return soilAnalysisColumns;
    }, [cultivationPeriodId, parameters, t]);

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const soilAnalysisParameters = await soilAnalysisService.getSoilProductivityParameters();
                const soilAnalysisData = await soilAnalysisService.getSoilAnalysisByIds({ cultivationPeriodId });
                if (!isCancelled) {
                    setParameters(soilAnalysisParameters);
                    setSoilAnalysisTableData(soilAnalysisData);
                    if (isEmpty(soilAnalysisData)) {
                        setIsLoadTableData(false);
                    }
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'LoadSoilAnalysisEditData'));
            }
        }

        if (cultivationPeriodId) {
            loadData();
            return () => {
                isCancelled = true;
            };
        }
    }, [cultivationPeriodId, dispatch, setIsLoadTableData, refreshTable]);

    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    return {
        columns,
        soilAnalysisTableData,
    };
}
