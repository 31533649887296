import { sendServerRequest } from './baseService';

export const translationService = {
    setLangTranslation,
    getVersions,
};

async function setLangTranslation(reactModule, reactKey, language, newCaption) {
    const response = await sendServerRequest('POST', '/api/translations/setLangTranslation', { reactModule, reactKey, language, newCaption });
    return response;
}

async function getVersions() {
    const response = await sendServerRequest('GET', '/api/translation/versions');
    return response;
}
