import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isNull, isEmpty, isUndefined } from 'lodash';

export default function useTableGroup(props) {
    const { isBasePageTable, isDataCard, groupedTableData, middleScrollLeftRef, displayTableColumnFaders, tableKey } = props;
    const [flatList, setFlatList] = useState([]);
    const [groups, setGroups] = useState([]);

    const isAllTableCollapsed = useSelector((state: any) => state.basePage.isAllTableCollapsed);
    const tableData = useSelector((state: any) => state.basePage.table.tableData);

    //table render speed up - prepare to new data
    useEffect(() => {
        if (!isNull(tableData) && isBasePageTable) {
            setFlatList([]);
        }
    }, [tableData, isBasePageTable]);

    //get groups collapsed state
    useEffect(() => {
        // TODO: do not unfold groups on sorting
        const currentGroups = groupedTableData
            ? groupedTableData.map(group => ({
                groupName: group.groupName,
                isCollapsed: isBasePageTable && isAllTableCollapsed,
            }))
            : [];
        setGroups(currentGroups);
    }, [groupedTableData, isAllTableCollapsed, isBasePageTable]);

    //get flat data list
    useEffect(() => {
        function getFlatGroupArray(groupArray, gName, currentGroup, groupId) {
            const getGroupArray = () => [...(groupArray || [])?.map(groupRow => ({ ...groupRow, groupName: gName, groupId }))];
            const getHeaderRow = () => [{ groupId, groupName: gName, isGroupHeader: true, groupItemCount: groupArray.length }];
            const getFooterRow = () => [{ groupId, groupName: gName, isGroupFooter: true }];

            //no breakdown
            if (isEmpty(gName)) {
                return getGroupArray();
            }

            //data card - without footer
            if (isDataCard) {
                return !currentGroup?.isCollapsed
                    ? [...getHeaderRow(), ...getGroupArray()]
                    : getHeaderRow();
            }

            //table group with header and footer - or only collapsed header
            return !currentGroup?.isCollapsed
                ? [...getHeaderRow(), ...getGroupArray(), ...getFooterRow()]
                : [...getHeaderRow()];
        }

        const flatlist = groupedTableData?.length && groups?.length
            ? groupedTableData.reduce((acc, curr) => {
                const { groupId, groupName: gName, groupArray } = curr;
                const currentGroup = groups.find(group => group.groupName === gName);
                const group = getFlatGroupArray(groupArray, gName, currentGroup, groupId);
                return [...acc, ...group];
            }, [])
            : [];
        setFlatList(flatlist);
    }, [isDataCard, groupedTableData, groups]);

    //get group collapsed state
    const isGroupCollapsed = useCallback(index => {
        const group = groups.find(groupsItem => groupsItem.groupName === flatList[index].groupName);
        return group?.isCollapsed;
    }, [groups, flatList]);

    //get group is collapsible
    const isGroupCollapseable = useCallback(index => {
        const group = groups.find(groupsItem => groupsItem.groupName === flatList[index].groupName);
        return !isUndefined(flatList[index + 1]) && flatList[index + 1].groupName === group?.groupName;
    }, [groups, flatList]);

    //change group collapsibel
    const toggleCollapsible = useCallback((__, ___, rowData) => {
        setGroups(
            prevGroups => prevGroups.reduce((acc, curr) => (curr.groupName === rowData.groupName
                ? [...acc, { ...curr, isCollapsed: !curr.isCollapsed }]
                : [...acc, curr]),
            []),
        );
    }, []);

    //fader handlers
    const onWindowResize = () => {
        document.querySelectorAll(`.midCol${tableKey}`).forEach(midColItem => {
            displayTableColumnFaders(midColItem);
        });
    };

    //scroll handler
    const handleMidScroll = event => {
        const { scrollLeft } = event.target;
        middleScrollLeftRef.current = scrollLeft;
        // TODO: use requestAnimationFrame
        document.querySelectorAll(`.midCol${tableKey}`).forEach(midColItem => {
            midColItem.scrollLeft = scrollLeft;
            displayTableColumnFaders(midColItem);
        });
    };

    //onScroll - show/hide basepage function line
    /*const handleScroll = useCallback(event => {
        if (isBasePageTable) {
            const { scrollDirection, scrollOffset } = event;

            if (scrollDirection === 'forward' && scrollOffset > 0) {
                dispatch(basePageActions.setVisibleHeaderFunctionLineTable(false, scrollDirection));
            } else if (scrollDirection === 'backward') {
                dispatch(basePageActions.setVisibleHeaderFunctionLineTable(true, scrollDirection));
            }
        }
    }, [dispatch, isBasePageTable]);*/

    return {
        flatList,
        isGroupCollapsed,
        isGroupCollapseable,
        toggleCollapsible,
        onWindowResize,
        handleMidScroll,
    };
}
