export const formUtils = {
    preventNonNumericalInput,
    isPressEnter,
};

//in input fields it, is only allow numbers to write in. Needed because of mozzila  number input handling
function preventNonNumericalInput(event) {
    const currentEvent = event || window.event;
    const charCode = (typeof currentEvent.which === 'undefined') ? currentEvent.keyCode : currentEvent.which;
    const charStr = String.fromCharCode(charCode);

    if (!charStr.match(/^[0-9]+$/) && !charStr.match(/^,+$/)) { currentEvent.preventDefault(); }
}

function isPressEnter(event) {
    if (event.keyCode === 13) {
        return true;
    }
    return false;
}
