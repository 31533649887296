import { useCallback } from 'react';
import { isEmpty, isFinite, isNil } from 'lodash';
import { TMapStore } from '@map/services/mapStore';
import { LayerAttributeStatus } from '@map/services/mapTypes';

export default function useFocusCenter(store: TMapStore) {
    const focusCenter = useCallback(() => {
        const bounds = new google.maps.LatLngBounds();
        let hasData = false;
        if (store.googleRefs.map && store.layering.autoZoom) {
            // Fit boundingbox to each active layer, not just the most relevant
            const activeLayerIds = (store.layering.attributes?.filter(att => att.isActive !== LayerAttributeStatus.INACTIVE))?.map(att => att.layerId);
            const activeLayers = (store.layering.layers?.filter(layer => activeLayerIds?.includes(layer.id)));
            activeLayers?.forEach(layer => {
                if (!isNil(layer.bbox) && !isEmpty(layer.bbox) && (layer.bbox?.length === 4)) {
                    if (!(layer.bbox[0] === 0 && layer.bbox[1] === 0) && isFinite(layer.bbox[0])) {
                        hasData = true;
                        bounds.extend(new google.maps.LatLng(layer.bbox[1], layer.bbox[0]));
                        bounds.extend(new google.maps.LatLng(layer.bbox[3], layer.bbox[2]));
                    }
                }
            });
            if (isEmpty(activeLayers)) {
                store.googleRefs.map.data.forEach(feature => {
                    feature.getGeometry()?.forEachLatLng(latLng => bounds.extend(latLng));
                });

                if (!bounds.isEmpty()) {
                    hasData = true;
                }
            }
            if (hasData) {
                store.googleRefs.map?.fitBounds(bounds);
            } else if (store.center.centerData?.coordinates) {
                bounds.extend(new google.maps.LatLng(store.center.centerData?.coordinates.lat, store.center.centerData?.coordinates.lng));
                store.googleRefs.map?.fitBounds(bounds);
                store.googleRefs.map?.setZoom(7);
            }
        }
    }, [store.center.centerData?.coordinates, store.googleRefs.map, store.layering.layers, store.layering.attributes, store.layering.autoZoom]);

    return focusCenter;
}
