import React from 'react';
import useTranslate from '@i18n/useTranslate';
import { createUseStyles } from 'react-jss';
import { ModalHeader } from '@baseComponents/modals/modalNew';

const useStyles = createUseStyles({
    modalHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
    },
});

export default function AnalysisDataEditHeader() {
    const { t } = useTranslate();
    const classes = useStyles();

    return (
        <ModalHeader className={classes.modalHeaderContainer}>
            {t('analysisDataEdit.analysisDataEdit', 'AnalysisDataEdit')}
        </ModalHeader>
    );
}
