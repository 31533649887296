import React from 'react';
import { createUseStyles } from 'react-jss';
import { isFunction } from 'lodash';

import Radio from '@baseComponents/controls/Radio';
import { LAYER_TYPES } from './LayerSelectorModal';
import useTranslate from '@i18n/useTranslate';



const useStyles = createUseStyles({
    layerTypeContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    layerTypeLabel: {
        marginTop: 0,
        marginBottom: 0,
        marginRight: 15,
        fontFamily: 'Rubik',
        fontWeight: 'normal',
    },
    layerTypeMargin: {
        marginRight: 15,
        marginBottom: 0,
        fontSize: 13,
    },
});

export default function LayerTypeRadio(props) {
    const { layerTypeId, setLayerTypeId } = props;
    const { t } = useTranslate();
    const classes = useStyles();

    function onChange(event) {
        if (isFunction(setLayerTypeId)) {
            const type = +event.target.value;
            setLayerTypeId(type);
        }
    }

    return (
        <div className={classes.layerTypeContainer}>
            <h4 className={classes.layerTypeLabel}>{t('layerSelectorModal.layerType', 'Layer type')}</h4>
            <Radio
                group="layerSelectorModalLayerType"
                text={t('layerSelectorModal.layerTypeAV', 'AgroVIR')}
                value={LAYER_TYPES.AGROVIR}
                checked={layerTypeId === LAYER_TYPES.AGROVIR}
                onChange={onChange}
                className={classes.layerTypeMargin}
            />
            <Radio
                group="layerSelectorModalLayerType"
                text={t('layerSelectorModal.layerTypeCustom', 'Custom')}
                value={LAYER_TYPES.CUSTOM}
                checked={layerTypeId === LAYER_TYPES.CUSTOM}
                onChange={onChange}
                className={classes.layerTypeMargin}
            />
            <Radio
                group="layerSelectorModalLayerType"
                text={t('layerSelectorModal.layerTypeAll', 'All layer')}
                value={LAYER_TYPES.ALL}
                checked={layerTypeId === LAYER_TYPES.ALL}
                onChange={onChange}
                className={classes.layerTypeMargin}
            />
        </div>
    );
}
