import ImageGallery from 'react-image-gallery';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import { ModalContentWrapper, ModalHeader, ModalBody } from '@baseComponents/modals/modalNew';
import { ThemeType } from '@map/services/mapTypes';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import { useCultivationPeriodImagesModalLoadData } from './useCultivationPeriodImagesModalLoadData';
import Svg from '@baseComponents/Svg';
import NoData from '@baseComponents/chips/NoData';
import useTranslate from '@i18n/useTranslate';
import 'react-image-gallery/styles/css/image-gallery.css';

type Classes = 'modalWrapper' | 'modalBody' | 'modalHeader' | 'galleryWrapper' | 'galleryImg' | 'nonImageClass' | 'navIcon' | 'thumbnailClass';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    modalWrapper: {
        width: 1000,
        height: 800,
        maxHeight: 800,
    },
    headerFunctionLine: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: theme.color.white,
        padding: '0 30px',
        marginBottom: 10,
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    footerButton: {
        width: 250,
    },
    closeButton: {
        width: 150,
    },
    addSelectedButton: {
        marginRight: 10,
    },
    functionLine: {
        display: 'inline-flex',
        alignItems: 'center',
        height: 40,
    },
    tableWrapper: {
        display: 'contents',
        height: '100%',
        width: '100%',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    galleryWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        padding: [20, 0],
        maxHeight: 800,
        position: 'relative',

        '& .image-gallery-thumbnails-wrapper': {
            maxWidth: 800,
        },

        '& .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover': {
            border: `2px solid ${theme.color.main}`,
            borderRadius: 5,
        },
    },
    galleryImg: {
        width: '100%',
        maxHeight: 700,
        objectFit: 'contain',
    },
    navIcon: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        cursor: 'pointer',
        '& svg': {
            fill: theme.color.white,
            '&:hover': {
                fill: theme.color.main,
            },
        },
    },
    thumbnailClass: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
        width: 100,
        outline: 'none !important',
        border: 'none !important',

        '& section': {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',

            '& svg': {
                width: 80,
                height: 80,
            },
        },

        '& img': {
            width: 'calc(100% - 22px)',
            height: 'calc(100% - 22px)',
            objectFit: 'scale-down',
            objectPosition: 'center',
        },

        '& span': {
            height: 20,
            lineHeight: '20px',
        },
    },
    nonImageClass: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 600,

        '& > section': {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: 800,
            height: 600,

            '& > section': {
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                flexFlow: 'row',
                position: 'absolute',
                bottom: 0,
                color: theme.color.white,
                padding: 16,
                justifyContent: 'space-between',
                background: 'rgba(0, 0, 0, 0.25)',
                '& svg': {
                    width: 32,
                    height: 32,
                    fill: theme.color.white,
                },
                '& a': {
                    color: theme.color.white,
                    textDecoration: 'none',
                    marginLeft: 10,
                    ' &:hover': {
                        color: theme.color.main,
                    },
                },
            },

            '& svg': {
                width: 200,
                height: 200,
            },
        },

        '& div': {
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',

            '& > section': {
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                flexFlow: 'row',
                position: 'absolute',
                bottom: 0,
                color: theme.color.white,
                padding: 16,
                justifyContent: 'space-between',
                background: 'rgba(0, 0, 0, 0.25)',
                '& svg': {
                    width: 32,
                    height: 32,
                    fill: theme.color.white,
                },
                '& a': {
                    color: theme.color.white,
                    textDecoration: 'none',
                    marginLeft: 10,
                    ' &:hover': {
                        color: theme.color.main,
                    },
                },
            },

            '& img': {
                width: 800,
                height: 600,
                objectFit: 'contain',
            },
        },
    },
}));

type CultivationPeriodImagesModalProps = {
    cultivationPeriodId: number[];
};

type NavProps = {
    onClick: () => void;
    disabled?: boolean;
    className: string;
};

const LeftNav = (props: NavProps) => {
    const { onClick, disabled, className } = props;
    return (
        <div className={className}>
            <Svg iconId="icon-chevron_left" width={80} height={80} onClick={onClick} disabled={disabled} />
        </div>
    );
};

const RightNav = (props: NavProps) => {
    const { onClick, disabled, className } = props;
    return (
        <div className={className} style={{ right: 0 }}>
            <Svg iconId="icon-chevron_right" width={80} height={80} onClick={onClick} disabled={disabled} />
        </div>
    );
};

export const CultivationPeriodImagesModal = (props: CultivationPeriodImagesModalProps) => {
    const { cultivationPeriodId } = props;
    const classes = useStyles();

    const { galleryImages } = useCultivationPeriodImagesModalLoadData({ cultivationPeriodId, nonImageClass: classes.nonImageClass, thumbnailClass: classes.thumbnailClass });

    const pImages = galleryImages?.map(image => ({ ...image, originalClass: classes.galleryImg }));
    const { t } = useTranslate();

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                <div className={classes.modalHeader}>
                    {t('cultivationPeriodsImagesModal.modalHeader', 'Cultivation Period attachments')}
                </div>
            </ModalHeader>
            <ModalBody className={classes.modalBody}>
                <LoadSpinner value={pImages} isAutoDetect />
                <div className={classes.galleryWrapper}>
                    <ImageGallery
                        items={pImages}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        autoPlay={false}
                        renderLeftNav={onClick => <LeftNav onClick={onClick} className={classes.navIcon} />}
                        renderRightNav={onClick => <RightNav onClick={onClick} className={classes.navIcon} />}
                    />

                    {isEmpty(pImages) && (<NoData isAdd={false} />)}
                </div>
            </ModalBody>
        </ModalContentWrapper>
    );
};
