import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { languageUtils, stringUtils } from 'shared/src/modules';
import Svg from '../Svg';

const useStyles = createUseStyles((theme: any) => ({
    //custom checkbox
    container: {
        margin: 0,
        display: 'flex',
        position: 'relative',
        outline: 'none',
        cursor: 'pointer',
        opacity: (props: any) => props.disabled && 0.4,
        //hide default checkbox
        '& input': {
            cursor: 'pointer',
            position: 'absolute',
            opacity: 0,
            height: 0,
            width: 0,
        },
        //when the checkbox is checked, add background color
        '& input:checked ~ $checkmark': {
            boxShadow: theme.shadows.primary.main,
            border: theme.border.main,
            backgroundColor: theme.color.main,
        },
        //checkbox hover state (box background)

        height: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 20;
                case 'large':
                    return 30;
                default:
                    return 24;
            }
        },
    },
    //custom checkbox
    checkmark: {
        top: 0,
        left: 0,
        height: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 20;
                case 'large':
                    return 30;
                default:
                    return 24;
            }
        },
        width: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 20;
                case 'large':
                    return 30;
                default:
                    return 24;
            }
        },
        minWidth: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 20;
                case 'large':
                    return 30;
                default:
                    return 24;
            }
        },
        borderRadius: 4,
        boxShadow: '0 2px 3px 0 rgba(175, 180, 182, 0.15)',
        border: `1px solid ${theme.color.gray}`,
        backgroundColor: theme.color.white,
        marginLeft: 10,
    },
    checkmarkIndicatorIcon: {
        height: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 16;
                case 'large':
                    return 26;
                default:
                    return 20;
            }
        },
        width: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 16;
                case 'large':
                    return 26;
                default:
                    return 20;
            }
        },
        marginTop: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 0;
                case 'large':
                    return 2;
                default:
                    return 2;
            }
        },
        marginBottom: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 2;
                case 'large':
                    return 0;
                default:
                    return 0;
            }
        },
        fill: theme.color.white,
    },
    checkboxText: {
        margin: 0,
        marginLeft: 10,
        fontSize: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 12;
                case 'large':
                    return 15;
                default:
                    return 14;
            }
        },
        fontWeight: 'normal',
        lineHeight: (props: any) => {
            switch (props.size) {
                case 'small':
                    return 1.67;
                case 'large':
                    return 2;
                default:
                    return 1.71;
            }
        },
        color: theme.color.jet,
        cursor: 'pointer',
        ...theme.disableSelectionColor,
        ...theme.overFlowText,
    },
    firstLetterUppercase: {
        ...theme.firstLetterUppercase,
    },
}));

export default function Checkbox(props) {
    const { text, size, checked = false, setChecked, onChange, className, ignoreFirstLetterFormatter = false, disabled } = props;
    const { t } = useTranslation();
    const [title] = useState(languageUtils.getTitle(t, text));
    const classes = useStyles({ size, disabled } as any);

    function changeCheckedState(event) {
        const checkedState = event.target.checked;

        if (setChecked) {
            setChecked(checkedState);
        }

        if (onChange) {
            onChange(checkedState, event);
        }
    }

    function getViewBoxSize() {
        switch (size) {
            case 'small':
                return '0 16 100 100';
            case 'large':
                return '0 0 100 100';
            default:
                return '0 18 100 100';
        }
    }

    function getStyle() {
        if (!ignoreFirstLetterFormatter) {
            return clsx(classes.checkboxText, classes.firstLetterUppercase);
        }
        return classes.checkboxText;
    }

    return (
        <label className={clsx(classes.container, className)}>
            <input type="checkbox" checked={checked} onChange={changeCheckedState} disabled={disabled} />
            <span className={classes.checkmark}>
                <Svg style={classes.checkmarkIndicatorIcon} iconId="icon-done" viewBox={getViewBoxSize()} />
            </span>
            {!isEmpty(text) && <div className={getStyle()} title={ignoreFirstLetterFormatter ? title : stringUtils.capitalizeFirstLetterUpper(title)}>{text}</div>}
        </label>
    );
}
