import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

import { TABLE } from 'shared/src/constants';
import Svg from '../../../Svg';

const useStyles = createUseStyles((theme: any) => ({
    sorterIcon: {
        verticalAlign: 'middle',
        objectFit: 'contain',
        fill: theme.color.raven,
        display: 'block',
        width: 20,
        height: 20,
    },
    activeSorterIcon: {
        fill: theme.color.white,
        display: 'inline-block',
        background: theme.color.main,
        borderRadius: 70,
        cursor: 'pointer',
    },
}));

export default function TableHeaderSorter(props) {
    const { sortingType, className } = props;
    const classes = useStyles();

    return (
        <>
            {sortingType === TABLE.SORTING.NONE
                && (
                    <>
                        <Svg id="sorterIcon" iconId="icon-arrow_drop_up" style={clsx(classes.sorterIcon, className)} viewBox="0 -25 100 100" />
                        <Svg id="sorterIcon" iconId="icon-arrow_drop_down" style={clsx(classes.sorterIcon, className)} viewBox="0 25 100 100" />
                    </>
                )
            }
            {sortingType === TABLE.SORTING.ASC
                && <Svg id="sorterIcon" iconId="icon-arrow_drop_up" style={clsx(classes.sorterIcon, classes.activeSorterIcon)} />
            }
            {sortingType === TABLE.SORTING.DESC
                && <Svg id="sorterIcon" iconId="icon-arrow_drop_down" style={clsx(classes.sorterIcon, classes.activeSorterIcon)} />
            }
        </>
    );
}
