import { RIGHTS, SYSTEM, SETTINGS } from '../constants';

export const LIVESTOCK_RECORDS = {
    MODULE_ID: 15,
    NAME: 'livestockRecords',
    EXCLUDE_SYSTEM_MODULES: [
        SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION,
        SYSTEM.SYSTEM_MODULE_TYPE.VINERY_VINEYARD,
        SYSTEM.SYSTEM_MODULE_TYPE.FRUIT_PLANTATION,
        SYSTEM.SYSTEM_MODULE_TYPE.SEED_PRODUCTION,
    ],
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockRecords',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.livestockRecords',
                TRANSLATOR_DEFAULT: 'Livestock records',
            },
        },
        PRODUCTION_GROUPS: {
            CONTENT_URL_ID: { productionGroups: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockProductionGroups',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockProductionGroups',
                TRANSLATOR_DEFAULT: 'Production groups',
            },
            RIGHTS: [RIGHTS.PRODUCTION_GROUPS_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        PRODUCTION_GROUP_EDIT: {
            CONTENT_URL_ID: { productionGroupsEdit: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockProductionGroupsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockProductionGroupsEdit',
                TRANSLATOR_DEFAULT: 'Production groups edit',
            },
            RIGHTS: [RIGHTS.PRODUCTION_GROUP_EDIT],
        },
        COST_OF_PRODUCTION_GROUP: {
            CONTENT_URL_ID: { costOfProductionGroup: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockCostOfProductionGroup',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockCostOfProductionGroup',
                TRANSLATOR_DEFAULT: 'Cost of production group',
            },
            HAS_CURRENCY_SWITCH: true,
        },
        ANIMAL_RECORD: {
            CONTENT_URL_ID: { animalRecord: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockAnimalRecord',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockAnimalRecord',
                TRANSLATOR_DEFAULT: 'Animal record',
            },
            RIGHTS: [RIGHTS.ANIMAL_RECORD_INTERFACE],
        },
        INDIVIDUAL_ANIMAL_EDIT: {
            CONTENT_URL_ID: { individualAnimalEdit: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockIndividualAnimalEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockIndividualAnimalEdit',
                TRANSLATOR_DEFAULT: 'Individual animal edit',
            },
            RIGHTS: [RIGHTS.INDIVIDUAL_ANIMAL_EDIT],
        },
        DETAILED_COSTS_OF_PRODUCTION_GROUPS: {
            CONTENT_URL_ID: { detailedCostsOfProductionGroups: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockDetailedCostsOfProductionGroups',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockDetailedCostsOfProductionGroups',
                TRANSLATOR_DEFAULT: 'Detailed costs of production groups',
            },
            RIGHTS: [RIGHTS.PRODUCTION_GROUPS_INTERFACE],
        },
        STOCK_CHANGES: {
            CONTENT_URL_ID: { stockChanges: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockStockChanges',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockStockChanges',
                TRANSLATOR_DEFAULT: 'Stock changes',
            },
            RIGHTS: [RIGHTS.STOCK_CHANGES_INTERFACE],
        },
        RECORDING_THE_CHANGES_IN_STOCK: {
            CONTENT_URL_ID: { recordingTheChangesInStock: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockRecordingTheChangesInStock',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockRecordingTheChangesInStock',
                TRANSLATOR_DEFAULT: 'Recording the changes in stock',
            },
            RIGHTS: [RIGHTS.STOCK_CHANGES_EDIT],
        },
        STABLE_AGE_GROUP_DAILY_STOCK: {
            CONTENT_URL_ID: { stableAgeGroupDailyStock: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockStableAgeGroupDailyStock',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockStableAgeGroupDailyStock',
                TRANSLATOR_DEFAULT: 'Stable age group daily stock',
            },
            RIGHTS: [RIGHTS.STABLE_AGE_GROUP_DAILY_STOCK_INTERFACE],
        },
        STABLE_AGE_GROUP_CHART: {
            CONTENT_URL_ID: { stableAgeGroupChart: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockStableAgeGroupChart',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockStableAgeGroupChart',
                TRANSLATOR_DEFAULT: 'Stable age group chart',
            },
            RIGHTS: [RIGHTS.STABLE_AGE_GROUP_CHART],
        },
        BREEDING_LIVE_STOCKS: {
            CONTENT_URL_ID: { breedingLiveStocks: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockBeedingLiveStocks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockBeedingLiveStocks',
                TRANSLATOR_DEFAULT: 'Breeding live stocks',
            },
        },
        FARMS: {
            CONTENT_URL_ID: { farms: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockFarms',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockFarms',
                TRANSLATOR_DEFAULT: 'Farms',
            },
        },
        STABLES: {
            CONTENT_URL_ID: { stables: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockStables',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockStables',
                TRANSLATOR_DEFAULT: 'Stables',
            },
        },
        AGE_GROUPS: {
            CONTENT_URL_ID: { ageGroups: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockAgeGroups',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockAgeGroups',
                TRANSLATOR_DEFAULT: 'Age groups',
            },
        },
        MOVEMENTS: {
            CONTENT_URL_ID: { movements: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockMovements',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockMovements',
                TRANSLATOR_DEFAULT: 'Movements',
            },
        },
        INITIAL_STOCK: {
            CONTENT_URL_ID: { initialStock: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockInitialStock',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockInitialStock',
                TRANSLATOR_DEFAULT: 'Initial stock',
            },
        },
        MANURE_PITS: {
            CONTENT_URL_ID: { manurePits: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockManurePits',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockManurePits',
                TRANSLATOR_DEFAULT: 'Manure pits',
            },
        },
        MANURE_BALANCE: {
            CONTENT_URL_ID: { manureBalance: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockManureBalance',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockManureBalance',
                TRANSLATOR_DEFAULT: 'Manure balance',
            },
        },
        BREEDING_ANIMALS: {
            CONTENT_URL_ID: { breedingAnimals: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockBreedingAnimals',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockBreedingAnimals',
                TRANSLATOR_DEFAULT: 'Breeding animals',
            },
            RIGHTS: [RIGHTS.BREEDING_ANIMALS_INTERFACE],
        },
        BREEDING_ANIMALS_EDIT: {
            CONTENT_URL_ID: { breedingAnimalsEdit: 21 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockBreedingAnimalsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockBreedingAnimalsEdit',
                TRANSLATOR_DEFAULT: 'Breeding animals edit',
            },
            RIGHTS: [RIGHTS.BREEDING_ANIMALS_EDIT],
        },
        ANIMAL_SHEET: {
            CONTENT_URL_ID: { animalSheet: 22 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockAnimalSheet',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockAnimalSheet',
                TRANSLATOR_DEFAULT: 'Animal sheet',
            },
            RIGHTS: [RIGHTS.ANIMAL_SHEET_INTERFACE],
        },
        ANIMAL_LIVESTOCK_TREATMENT: {
            CONTENT_URL_ID: { animalLivestockTreatment: 23 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockAnimalLivestockTreatment',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockAnimalLivestockTreatment',
                TRANSLATOR_DEFAULT: 'Stock treatment',
            },
            RIGHTS: [RIGHTS.ANIMAL_LIVESTOCK_TREATMENT_INTERFACE],
        },
        ANIMAL_LIVESTOCK_SHEET: {
            CONTENT_URL_ID: { animalLivestockSheet: 24 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockAnimalLivestockSheet',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockAnimalLivestockSheet',
                TRANSLATOR_DEFAULT: 'Livestock sheet',
            },
            RIGHTS: [RIGHTS.ANIMAL_LIVESTOCK_SHEET_INTERFACE],
        },
        ANIMAL_MORTALITY: {
            CONTENT_URL_ID: { animalMortality: 25 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockAnimalMortality',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockAnimalMortality',
                TRANSLATOR_DEFAULT: 'Aimal mortality',
            },
            RIGHTS: [RIGHTS.ANIMAL_MORTALITY_INTERFACE],
        },
        ANIMAL_MORTALITY_EDIT: {
            CONTENT_URL_ID: { animalMortalityEdit: 26 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockAnimalMortalityEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockAnimalMortalityEdit',
                TRANSLATOR_DEFAULT: 'Aimal mortality edit',
            },
            RIGHTS: [RIGHTS.ANIMAL_MORTALITY_INTERFACE],
        },
        ANIMAL_MILK_HOUSE: {
            CONTENT_URL_ID: { animalMilkHouse: 27 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalMilkHouse',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalMilkHouse',
                TRANSLATOR_DEFAULT: 'Milk house',
            },
            RIGHTS: [RIGHTS.ANIMAL_MILK_HOUSE_INTERFACE],
        },
        ANIMAL_MILK_DIAGRAM: {
            CONTENT_URL_ID: { animalMilkDiagram: 28 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalMilkDiagram',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalMilkDiagram',
                TRANSLATOR_DEFAULT: 'Milk diagram',
            },
            RIGHTS: [RIGHTS.ANIMAL_MILK_DIAGRAM_INTERFACE],
        },
        ANIMAL_REPRODUCTION_ANALYSIS: {
            CONTENT_URL_ID: { animalReproductionAnalysis: 29 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalReproductionAnalysis',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalReproductionAnalysis',
                TRANSLATOR_DEFAULT: 'Reproduction analysis',
            },
            RIGHTS: [RIGHTS.ANIMAL_REPRODUCTION_ANALYSIS_INTERFACE],
        },
        ANIMAL_MILKING_PARLOR: {
            CONTENT_URL_ID: { animalMilkingParlor: 30 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalMilkingParlor',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalMilkingParlor',
                TRANSLATOR_DEFAULT: 'Milking parlor',
            },
            RIGHTS: [RIGHTS.ANIMAL_MILKING_PARLOR_INTERFACE],
        },
        ANIMAL_CERTIFICATES: {
            CONTENT_URL_ID: { animalCertificates: 31 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalCertificates',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalCertificates',
                TRANSLATOR_DEFAULT: 'Age group certificates',
            },
            RIGHTS: [RIGHTS.ANIMAL_CERTIFICATES_INTERFACE],
        },
        ANIMAL_COST_LIST: {
            CONTENT_URL_ID: { animalCostList: 32 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalCostList',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalCostList',
                TRANSLATOR_DEFAULT: 'Animal cost list',
            },
            RIGHTS: [RIGHTS.ANIMAL_RECORD_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        ANIMAL_COST: {
            CONTENT_URL_ID: { animalCost: 33 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalCost',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalCost',
                TRANSLATOR_DEFAULT: 'Animal cost',
            },
            RIGHTS: [RIGHTS.ANIMAL_RECORD_INTERFACE],
            HAS_CURRENCY_SWITCH: true,
        },
        ANIMAL_FREE_ID_NUMBERS: {
            CONTENT_URL_ID: { freeIdNumbers: 34 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'freeIdNumbers',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.freeIdNumbers',
                TRANSLATOR_DEFAULT: 'Free ID numbers',
            },
            RIGHTS: [RIGHTS.INDIVIDUAL_ANIMAL_EDIT],
        },
        ANIMAL_DIAGNOSIS: {
            CONTENT_URL_ID: { animalDiagnosis: 35 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalDiagnosis',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalDiagnosis',
                TRANSLATOR_DEFAULT: 'Animal diagnosis',
            },
            RIGHTS: [RIGHTS.ANIMAL_RECORD_INTERFACE],
        },  
        ANIMAL_HISTORY_IMPORT_XLS: {
            CONTENT_URL_ID: { historyImportXls: 36 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'historyImportXls',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.histroryImportXls',
                TRANSLATOR_DEFAULT: 'Animal history import',
            },
            RIGHTS: [RIGHTS.ANIMAL_RECORD_INTERFACE],
        },
        ANIMAL_IMPORT_EXCEL: {
            CONTENT_URL_ID: { animalImportExcel: 37 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalImportExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalImportExcel',
                TRANSLATOR_DEFAULT: 'Animal import from excel',
            },
            RIGHTS: [RIGHTS.ANIMAL_RECORD_INTERFACE],
        }, 
        ANIMAL_LISTING: {
            CONTENT_URL_ID: { livestockListing: 38 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockListing',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockListing',
                TRANSLATOR_DEFAULT: 'Animal editable listing',
            },
            RIGHTS: [RIGHTS.LIVESTOCK_LISTING],
        },
        ANIMAL_MOVEMENT_WITHOUT_CERT: {
            CONTENT_URL_ID: { animalMovementWithoutCert: 39 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalMovementWithoutCert',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalMovementWithoutCert',
                TRANSLATOR_DEFAULT: 'Animal movement without certificate',
            },
            RIGHTS: [RIGHTS.INDIVIDUAL_ANIMAL_EDIT],
        },
        ANIMAL_COW_DOMAIN_SETTINGS: {
            CONTENT_URL_ID: { animalCowDomainSettings: 40 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalCowDomainSettings',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.settings',
                TRANSLATOR_DEFAULT: 'Settings',
            },
            RIGHTS: [RIGHTS.EDIT_LIVESTOCK_STRAIN_DATA],
        },
        ANIMAL_TREATMENT_PROTOCOL: {
            CONTENT_URL_ID: { animalTreatmentProtocol: 41 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalTreatmentProtocol',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalTreatmentProtocol',
                TRANSLATOR_DEFAULT: 'Animal Treatment Protocol',
            },
            RIGHTS: [RIGHTS.LIVESTOCK_PROTOCOL],
            SETTINGS: [
                { [SETTINGS.INDIVIDUAL_ANIMAL_REGISTRATION]: 1 }
            ],
        },
        ANIMAL_WARNINGS: {
            CONTENT_URL_ID: { animalWarnings: 42 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'animalWarnings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.animalWarnings',
                TRANSLATOR_DEFAULT: 'Animal Warnings',
            },
            RIGHTS: [RIGHTS.INDIVIDUAL_ANIMAL_EDIT],
            SETTINGS: [
                { [SETTINGS.INDIVIDUAL_ANIMAL_REGISTRATION]: 1 }
            ],
        },
    },
};
