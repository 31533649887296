import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import { useDispatch } from 'react-redux';
import { isUndefined, isEmpty } from 'lodash';

import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import ErrorMessage from '@baseComponents/formParts/ErrorMessage';
import { getAllColumns } from './allColumns';

const useStyles = createUseStyles((theme: any) => ({
    partnerTableWrapper: {
        height: '100%',
        width: '100%',
    },
    table: {
        height: '100%',
        width: '100%',
    },
    errorMessage: {
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        color: theme.color.destructive,
    },
}));

function PartnerTable(props) {
    const { tableKey, partners, deletePartnerFromTable, errorMessage, quantityFormats, onChangeQuantity } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [defaultColumns] = useState(getAllColumns(t, ['partner', 'settlement', 'address', 'quantity', 'removeRowButton'], dispatch, tableKey, deletePartnerFromTable, quantityFormats, onChangeQuantity));
    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    const [tableData, setTableData] = useState([]);

    //kapott partners tömb megjelenítése
    useEffect(() => {
        if (!isUndefined(partners) && t) {
            partners?.forEach((partner, index) => {
                partner.rowId = index + 1;
            });
            setTableData(partners);
        }
    }, [partners, t]);

    return (
        <div className={classes.partnerTableWrapper}>
            <ErrorMessage invalid={!isEmpty(errorMessage) || isEmpty(partners)} errorMessage={errorMessage} className={classes.errorMessage} />
            <TableGroup
                tableId={tableKey}
                tableData={tableData}
                tableColumns={columns}
                isBasePageTable={false}
                isCollapsible={false}
                tableGroup={{
                    value: null,
                    text: 'No breakdown',
                }}
                isDataCard
                useRowSelection={false}
                className={classes.table}
            />
            <TableScrollerFooter tableKey={tableKey} />
        </div>
    );
}

export default React.memo(PartnerTable);
