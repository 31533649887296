import { ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';

type Classes = 'tumbWrapper' | 'thumbContainer' | 'thumbTopLeft' | 'thumbTopRight' | 'thumbBottomRight' | 'thumbBottomLeft' | 'thumbMiddle' | 'thumbText' | 'thumbMiddleRight';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    tumbWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: 50,
    },
    thumbContainer: {
        height: 50,
        position: 'relative',
        width: 9,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    thumbTopLeft: {
        position: 'absolute',
        top: -3,
        left: -3,
        width: 9,
        height: 9,
        display: 'block',
        background: '#fb9404',
        transform: 'rotate(45deg)',
        zIndex: 400,
    },
    thumbTopRight: {
        position: 'absolute',
        top: -3,
        right: -3,
        width: 9,
        height: 9,
        display: 'block',
        background: '#fb9404',
        transform: 'rotate(45deg)',
        zIndex: 400,
    },
    thumbBottomLeft: {
        position: 'absolute',
        bottom: -3,
        left: -3,
        width: 9,
        height: 9,
        display: 'block',
        background: '#fb9404',
        transform: 'rotate(45deg)',
        zIndex: 400,
    },
    thumbBottomRight: {
        position: 'absolute',
        bottom: -3,
        width: 9,
        height: 9,
        right: -3,
        display: 'block',
        background: '#fb9404',
        transform: 'rotate(45deg)',
        zIndex: 400,
    },
    thumbMiddleRight: {
        right: 0,
        position: 'absolute',
        width: 2,
        height: '100%',
        background: 'red',
    },
    thumbMiddle: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: '#fb9404',
    },
    thumbText: {
        position: 'absolute',
        top: -15,
        color: 'white',
        zIndex: 400,
        textShadow: '3px 3px 3px #000000',
    },
}));

export const MapSliderThumb = () => {
    const classes = useStyles();

    return (
        <div className={classes.tumbWrapper}>
            <div className={classes.thumbTopLeft} />
            <div className={classes.thumbTopRight} />
            <div className={classes.thumbMiddle} />
            <div className={classes.thumbBottomLeft} />
            <div className={classes.thumbBottomRight} />
        </div>
    );
};
