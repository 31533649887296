import { UNITS } from '../constants';
import { numberUtils } from './numberUtils';
import { companySettingsModule } from '../company/companySettings';
import { clone, toUpper, isEmpty } from 'lodash';

export class NumberFormatUtils {
    constructor(props) {
        const { settingList } = props || {};
        const { DECIMAL_POINT, THOUSAND_SEPARATOR, DECIMAL_NUMBER } = UNITS;

        this.initSettings(settingList);
        
        this.defaultFormats = {
            decimalPoint: DECIMAL_POINT.DEFAULT, // ',',
            thousandSeparator: THOUSAND_SEPARATOR.DEFAULT, //' '
            decimals: DECIMAL_NUMBER.DEFAULT, //2
            unit: '',
            unitLong: '',
            displayUnitAsPrefix: false,
        };
    }

    initSettings(settingList) {
        this.settingList = settingList;
    }

    getCustomFormats({ currentFormats = null, decimalPoint, thousandSeparator, decimals, unit = '', unitLong = '' }) { 
        const formats = clone(currentFormats) || clone(this.defaultFormats);

        if (decimalPoint) {
            formats.decimalPoint = decimalPoint;
        }

        if (thousandSeparator) {
            formats.thousandSeparator = thousandSeparator;
        }

        if (numberUtils.isNumber(decimals)) {
            formats.decimals = decimals;
        }

        if (unit) {
            formats.unit = unit;
        }

        if (unitLong) {
            formats.unitLong = unitLong;
        }

        return formats;
    }

    //get default from constants
    getDefaultUnits(type) {
        const { DECIMAL_POINT, THOUSAND_SEPARATOR, DECIMAL_NUMBER } = UNITS;

        const decimalPoint = DECIMAL_POINT[type];
        const thousandSeparator = THOUSAND_SEPARATOR[type];
        const decimals = DECIMAL_NUMBER[type];

        if (type && decimalPoint && thousandSeparator && numberUtils.isNumber(decimals)) {
            return this.getCustomFormats({ decimalPoint, thousandSeparator, decimals });
        }

        return this.defaultFormats;
    }

    //get format from settings
    getFormatFromSettings(type) {
        const format = this.getDefaultUnits(type);
        let decimals = null;
        let unit = null;
        if (isEmpty(this.settingList)) {
            return format;
        }

        const { TYPE_SETTINGS, UNIT_TEXT } = UNITS;
        const setting = TYPE_SETTINGS[type];
        const defaultUnit = UNIT_TEXT[type] || null;

        if (type && setting) {
            decimals = companySettingsModule.getSettingValue(setting, this.settingList);
        }

        return this.getCustomFormats({ currentFormats: format, decimals, unit: unit || defaultUnit });
    }

    //usable functions - expendable parts
    //get format datas
    getFormats(type) {
        return this.getFormatFromSettings(type);
    }

    getDefaultFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.DEFAULT);
    }

    getAreaFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.AREA);
    }
    
    getLandAdministrationAreaFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.LAND_ADMINISTRATION_AREA_HA);
    }

    getLandAdministrationAreaAKFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.LAND_ADMINISTRATION_AREA_AK);
    }

    getPrimaryCurrencyFormats(unitPrice = false) {
        const { TYPE_SETTINGS } = UNITS;
        const primary_currency = TYPE_SETTINGS['PRIMARY_CURRENCY_ID'];
        const currencyId = companySettingsModule.getSettingValue(primary_currency, this.settingList);
        return this.getCurrencyFormatById(currencyId, unitPrice);
    }

    getSecondaryCurrencyFormats(unitPrice = false) {
        const { TYPE_SETTINGS } = UNITS;
        const secondary_currency = TYPE_SETTINGS['SECONDARY_CURRENCY_ID'];
        const currencyId = companySettingsModule.getSettingValue(secondary_currency, this.settingList);
        return this.getCurrencyFormatById(currencyId, unitPrice);
    }

    getCurrencyFormatById(currencyId, unitPrice = false){
        const format = this.getDefaultUnits(UNITS.TYPES.CURRENCY);
        if (isEmpty(this.settingList)) {
            return format;
        }

        let decimals = null;
        let unit = null;
        let unitLong = null;

        if (currencyId) {
            let key_currency_decimal = `CURRENCY_DECIMALS_${toUpper(currencyId)}`;
            if(unitPrice){
                key_currency_decimal = `CURRENCY_UNIT_PRICE_DECIMALS_${toUpper(currencyId)}`;
            }
            decimals = companySettingsModule.getSettingValue(key_currency_decimal, this.settingList);
            unit = companySettingsModule.getSettingValue(`CURRENCY_SHORTNAME_${toUpper(currencyId)}`, this.settingList);
            unitLong = companySettingsModule.getSettingValue(`CURRENCY_NAME_${toUpper(currencyId)}`, this.settingList);
        }

        return this.getCustomFormats({ currentFormats: format, decimals, unit, unitLong });
    }

    getWeightFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.WEIGHT);
    }

    getSpeedFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.SPEED);
    }

    getTemperatureFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.TEMPERATURE);
    }

    getPercentageFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.PERCENTAGE);
    }

    /*getQuantityFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.QUANTITY);
    }*/

    getProductQuantityFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.PRODUCT_QUANTITY);
    }
    
    getDoseFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.DOSE);
    }

    getWorkoperationPerformanceFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.WORKOPERATION_PERFORMANCE);
    }

    getMachinePerformanceFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.MACHINE_PERFORMANCE);
    }

    getWorkingHoursPerformanceFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.WORKING_HOURS_PERFORMANCE);
    }

    getTimeFormats() {
        return this.getFormatFromSettings(UNITS.TYPES.TIME);
    }


    //get formatted values
    getValueFromString(valueString) {
        if (!valueString) {
            return null;
        }

        const valueConvertToString = valueString.toString();

        let valueFromString = valueConvertToString ? valueConvertToString.replace(',', '.') : ''; //replace decimal point
        valueFromString = valueFromString ? valueFromString.replace(/[^\d.-]/g, '') : ''; //replace thousand separator

        return numberUtils.isNumber(valueFromString) ? Number(valueFromString) : null;
    }

    getRoundedValue(value, format, decimalSeparator = '.') {
        if (!numberUtils.isNumber(value) || !format) {
            return value;
        }

        let formattedValue = numberUtils.getFormattedValue(value, format.decimals, decimalSeparator, '');

        return formattedValue;
    }

    getFormattedValue(value, format, showUnit = false) {
        if (showUnit && !numberUtils.isNumber(value) && !isEmpty(format.unit)) {
            return format.displayUnitAsPrefix ? `${format.unit} 0` : `0 ${format.unit}`;
        }

        if (!numberUtils.isNumber(value) || !format) {
            return value;
        }

        let formattedValue = numberUtils.getFormattedValue(value, format.decimals, format.decimalPoint, format.thousandSeparator);

        if (showUnit && !isEmpty(format.unit)) {
            formattedValue = format.displayUnitAsPrefix
                ? `${format.unit} ${formattedValue}`
                : `${formattedValue} ${format.unit}`;
        }

        return formattedValue;
    }

    getFormattedArea(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.AREA);
        return this.getFormattedValue(value, format, showUnit);
    }
    
    getFormattedLandAdministrationArea(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.LAND_ADMINISTRATION_AREA_HA);
        return this.getFormattedValue(value, format, showUnit);
    }

    getFormattedLandAdministrationAreaAK(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.LAND_ADMINISTRATION_AREA_AK);
        return this.getFormattedValue(value, format, showUnit);
    }

    getFormattedPrimaryCurrency(value, showUnit, unitPrice = false) {
        const format = this.getPrimaryCurrencyFormats(unitPrice);
        return this.getFormattedValue(value, format, showUnit);
    }

    getFormattedSecondaryCurrency(value, showUnit, unitPrice = false) {
        const format = this.getSecondaryCurrencyFormats(unitPrice);
        return this.getFormattedValue(value, format, showUnit);
    }

    getFormattedCurrencyById(currencyId, value, showUnit, unitPrice = false){
        const format = this.getCurrencyFormatById(currencyId, unitPrice)
        return this.getFormattedValue(value, format, showUnit);
    }

    getFormattedWeight(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.WEIGHT);
        return this.getFormattedValue(value, format, showUnit);
    }

    getFormattedSpeed(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.SPEED);
        return this.getFormattedValue(value, format, showUnit);
    }

    getFormattedTemperature(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.TEMPERATURE);
        return this.getFormattedValue(value, format, showUnit);
    }

    getFormattedPercentage(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.PERCENTAGE);
        return this.getFormattedValue(value, format, showUnit);
    }

    /*getFormattedQuantity(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.QUANTITY);
        return this.getFormattedValue(value, format, showUnit);
    }*/
    
    getFormattedProductQuantity(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.PRODUCT_QUANTITY);
        return this.getFormattedValue(value, format, showUnit);
    }
    
    getFormattedDose(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.DOSE);
        return this.getFormattedValue(value, format, showUnit);
    }
    
    getFormattedWorkoperationPerformance(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.WORKOPERATION_PERFORMANCE);
        return this.getFormattedValue(value, format, showUnit);
    }
    
    getFormattedMachinePerformance(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.MACHINE_PERFORMANCE);
        return this.getFormattedValue(value, format, showUnit);
    }

    getFormattedWorkingHoursPerformance(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.WORKING_HOURS_PERFORMANCE);
        return this.getFormattedValue(value, format, showUnit);
    }
    
    getFormattedTime(value, showUnit = false) {
        const format = this.getFormatFromSettings(UNITS.TYPES.TIME);
        return this.getFormattedValue(value, format, showUnit);
    }
}
