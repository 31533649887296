export const ERRORS = {
    SIDE: {
        CLIENT: 'client',
        SERVER: 'server',
    },
    LEVEL: {
        DEBUG: 0,
        INFO: 1,
        WARN: 2,
        ERROR: 3,
        FATAL: 4,
    },
    SEVERITY: {
        CRITICAL: 100,
        NON_CRITICAL: 50,
    },
    SERVER_ERROR_CODE: {
        DUPLICATED_ENTRY: 'ER_DUP_ENTRY',
    },
    HTTP_STATUS_CODES: {
        DUPLICATED_ENTRY: 409,
    },
};
