import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routerHelper } from '@utils';
import { sessionService, phpService } from '@services';
import { phpHelper } from '@utils/php/PhpHelper';
import { isFunction } from 'lodash';
import { getAPIEndpoint } from 'shared/src/routing';

export default function usePhpWindowFunctions() {
    const history = useHistory();
    const [agentName] = useState(sessionService.getAgentName());
    const [isPlanningMode] = useState(sessionService.getPlanningMode());

    useEffect(() => {
        if (agentName && history && !isFunction((window as any).openUrlWindowInReact)) {
            (window as any).openUrlWindowInReact = (url, blank) => {
                if (url) {
                    const hrefObj = phpHelper.getHrefExportUrl(url);

                    //href blank exportlink
                    if (hrefObj && hrefObj.exportLink) {
                        const exportLink = phpService.getExportLink(hrefObj.exportLink);
                        window.open(exportLink, '_blank');
                        return false;
                    }

                    //simple new blank
                    const hash = routerHelper.getHashFromLocation(url);
                    const redirectUrl = getAPIEndpoint(hash, agentName, isPlanningMode);
                    if (!redirectUrl) {
                        return false;
                    }

                    if (blank) {
                        window.open(redirectUrl, '_blank');
                    } else {
                        history.push(redirectUrl);
                    }
                }
            };
        }
    }, [isPlanningMode, history, agentName]);
}
