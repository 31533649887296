import React from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { useNumberFormat } from '@common_modules/numberFormat';

import { Area } from '@baseComponents/formatters';

const useStyles = createUseStyles((theme: any) => ({
    multilineTitleWrapper: {
        width: '100%',
        height: '100%',
        display: 'inline-block',
        margin: 'auto',
        textAlign: 'right',
    },
    firstLine: {
        display: 'flex',
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        lineHeight: '16px',
        height: props => (props.singleLine ? '100%' : '50%'),
        alignItems: props => (props.singleLine ? 'center' : 'flex-end'),
    },
    firstLineText: {
        fontSize: 13,
        fontWeight: 500,
        color: theme.color.jet,
        display: 'block',
        ...theme.overFlowText,
        '& div': {
            fontSize: 13,
        },
    },
    secondLine: {
        display: 'flex',
        width: '100%',
        lineHeight: '16px',
        fontSize: 13,
        fontWeight: 'normal',
        color: theme.color.raven,
        ...theme.overFlowText,
        height: '50%',
    },
    secondLineId: {
        width: '100%',
        height: 28,
    },
    area: {
        fontSize: 13,
        fontWeight: 400,
        color: theme.color.raven,
    },
}));

export default function MultilineWithUnitCell(props) {
    const { value, rowData, secondLineId, unitId } = props;
    const { t } = useTranslation();
    const { numberFormatter } = useNumberFormat();
    const classes = useStyles({ singleLine: !secondLineId });

    function getTextById(id) {
        if (id) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, id);
            return columnItem || null;
        }
        return null;
    }

    return (
        <div className={classes.multilineTitleWrapper}>
            <div className={classes.firstLine}>
                <Area value={numberFormatter.getFormattedArea(value) || 0} unit={getTextById(unitId)} />
            </div>
            {secondLineId
                ? (
                    <div className={classes.secondLineId}>
                        {numberFormatter.getFormattedWorkingHoursPerformance(getTextById(secondLineId)) || 0} {t('workOperationsColumn.hour', 'hour')}
                    </div>
                ) : null
            }
        </div>
    );
}
