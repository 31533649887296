/* eslint-disable id-blacklist */
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '260px',
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fit, minmax(40%, 1fr) )',
        gridGap: '20px',
    },
    itemContainer: {
        float: 'left',
        cursor: 'pointer',
        outline: 'none',
        width: 122,
        borderRadius: 4,
        boxShadow: '0 2px 3px 0 rgba(175, 180, 182, 0.15)',
        border: `1px solid ${theme.color.gray}`,
        backgroundColor: theme.color.white,
        padding: [12, 0],
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '1.43',
        textAlign: 'center',
        color: theme.color.jet,
    },
    selectedItemContainer: {
        float: 'left',
        cursor: 'pointer',
        outline: 'none',
        width: 122,
        borderRadius: 4,
        boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15)',
        border: 'solid 1px',
        borderColor: theme.color.main,
        backgroundColor: 'rgba(34, 195, 115, 0.2)',
        padding: [12, 0],
        fontSize: '14px',
        fontWeight: '500',
        lineHeight: '1.43',
        textAlign: 'center',
        color: theme.color.main,
    },
}));

export default function SimpleDates(props) {
    const { itemList, startDate, setStartDate } = props;

    function onClick(item) {
        setStartDate(item.id);
    }
    const classes = useStyles();

    return (
        <div className={classes.container}>
            {itemList && itemList.map(item => (
                <button
                    type="button"
                    key={item.key}
                    className={item.text === startDate ? classes.selectedItemContainer : classes.itemContainer}
                    onClick={() => onClick(item)}
                >
                    {item.year}
                </button>
            ))}
        </div>
    );
}
