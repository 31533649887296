import React, { useState, useCallback, useEffect } from 'react';
import Radio from '@baseComponents/controls/Radio';
import Svg from '@baseComponents/Svg';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        position: 'relative',
    },
    toggleButton: {
        display: 'flex',
        width: '100%',
        height: 32,
        margin: '2px 0px',
        marginRight: 0,
        paddingRight: 0,
        border: '1px solid',
        borderRadius: 4,
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        opacity: 1,
        backgroundColor: theme.color.white,
        borderColor: theme.color.gray,
        '&.btn-primary.dropdown-toggle': {
            borderColor: theme.color.gray,
            cursor: 'pointer',
            color: theme.color.jet,
            backgroundColor: theme.color.white,
            boxShadow: theme.shadows.secondary.normal,
            minWidth: 50,
            '&:active': {
                color: theme.color.jet,
                backgroundColor: theme.color.white,
                borderColor: theme.color.main,
            },
            '&:focus': {
                boxShadow: theme.shadows.primary.focus,
                borderColor: theme.color.main,
            },
        },
    },
    toggleIcon: {
        height: 24,
        width: 24,
        marginLeft: 'auto',
    },
    selectedItems: {
        width: '100%',
        textAlign: 'left',
        ...theme.overFlowText,
        fontSize: 12,
        fontWeight: 400,
        marginLeft: 8,
    },
    popover: {
        width: '100%',
        left: 0,
    }
}));

export default function RadioSearcher(props) {
    const { column, onChange } = props;
    const { searchDataList } = column || {};
    const [activeState, setActiveState] = useState(column.defaultOption || null);
    const [activeOptionName, setActiveOptionName] = useState(searchDataList?.find(defaultOption => defaultOption.id === column.defaultOption)?.name || null);
    const [selectAllOption] = useState(column.selectAllOption);
    const [isOpened, setIsOpened] = useState(false);

    const classes = useStyles();

    function onClick(event) {
        event.stopPropagation();
    }

    const handleRadioChange = useCallback(event => {
        const newState = event.target.value;
        setActiveState(+newState);
        setActiveOptionName(searchDataList?.find(defaultOption => defaultOption.id === +newState)?.name);
        onChange(+newState === selectAllOption ? null : newState);
    }, [onChange, setActiveState, setActiveOptionName, selectAllOption, searchDataList]);

    function onToggle() {
        setIsOpened(!isOpened);
    }

    const menuPopover = (
        <Popover className={classes.popover}>
            <Popover.Content>
                {searchDataList?.map(option => <Radio checked={option.id === activeState} text={option.name} key={option.id} value={option.id} onChange={handleRadioChange} />)}
            </Popover.Content>
        </Popover>
    );

    return (
        <div onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0} className={classes.container}>
            <OverlayTrigger
                show={isOpened}
                trigger="click"
                placement="bottom"
                overlay={menuPopover}
                rootCloseEvent="mousedown"
                rootClose
                onToggle={onToggle}
            >
                <div className={classes.toggleButton}>
                    <span className={classes.selectedItems}>{activeOptionName}</span>
                    {isOpened
                        ? <Svg iconId="icon-arrow_drop_up" style={classes.toggleIcon} />
                        : <Svg iconId="icon-arrow_drop_down" style={classes.toggleIcon} />}
                </div>
            </OverlayTrigger>
        </div>
    );
}
