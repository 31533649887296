import { isArray } from 'lodash';
import { contentDomActions } from '../actions/contentDomActions';

const initialState = {
    selectedItemIds: [],
    isCollapsedAllGroups: null,
};

export function contentDom(state = initialState, action) {
    switch (action.type) {
    case contentDomActions.ADD_SELECTED_IDS:
        if (isArray(action.payload.selectedIds)) {
            return {
                ...state,
                selectedItemIds: [...state.selectedItemIds, ...action.payload.selectedIds],
            };
        }
        return {
            ...state,
            selectedItemIds: [...state.selectedItemIds, action.payload.selectedIds],
        };
    case contentDomActions.REMOVE_SELECTED_IDS:
        return {
            ...state,
            selectedItemIds: filterSelectedItem(state.selectedItemIds, action.payload.selectedIds),
        };
    case contentDomActions.SET_SELECTED_IDS:
        return {
            ...state,
            selectedItemIds: action.payload.selectedIds,
        };
    case contentDomActions.REMOVE_ALL_SELECTED_ID:
        return {
            ...state,
            selectedItemIds: [],
        };
    case contentDomActions.SET_ALL_COLLAPSE_GROUPS:
        return {
            ...state,
            isCollapsedAllGroups: action.payload.isCollepsedAll,
        };
    case contentDomActions.REMOVE_ALL_COLLAPSE_GROUPS:
        return {
            ...state,
            isCollapsedAllGroups: null,
        };
    default:
        return state;
    }
}

function filterSelectedItem(selectedItemIds, selectedIds) {
    if (isArray(selectedIds)) {
        return selectedItemIds.filter(selectedItemId => !selectedIds.includes(selectedItemId));
    }
    return selectedItemIds.filter(selectedItemId => selectedItemId !== selectedIds);
}
