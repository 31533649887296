import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import { cultureService } from '@services';
import { errorActions } from '@states/actions/errorActions';

export default function useLoadReproductionRateName(props) {
    const reproductionRates = useSelector((state: any) => state.dataLists.reproductionRates);
    const dispatch = useDispatch();
    const { values, setFieldValue } = useFormikContext();
    const location = useLocation();

    const { setFormInitialValues } = props;

    //load culture variety
    useEffect(() => {
        let isCancelled = false;

        async function getCultureVariety(cultureId) {
            try {
                const varieties = await cultureService.getCultureVariety(cultureId);
                if (!isCancelled) {
                    const cultureVarietyList = [];
                    varieties.forEach(varietyObj => {
                        cultureVarietyList.push({ value: varietyObj.name, key: varietyObj.id });
                    });
                    setFieldValue('varietyList', cultureVarietyList);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }

        if (values.crop.culture && dispatch && location) {
            getCultureVariety(values.crop.culture);
            return () => {
                isCancelled = true;
            };
        }
    }, [values.crop.culture, dispatch, location, setFieldValue]);

    //load reproduction rate
    useEffect(() => {
        if (!isEmpty(reproductionRates) && dispatch && location) {
            const selectReproductionRates = reproductionRates.map(reproductionRateObj => ({
                value: reproductionRateObj.name,
                key: reproductionRateObj.id,
            }));
            setFieldValue('reproductionRateList', selectReproductionRates);
        }
    }, [dispatch, location, reproductionRates, setFieldValue]);

    //load reproduction name
    useEffect(() => {
        if (values.crop.reproductionRate && !isEmpty(reproductionRates) && isEmpty(values.crop.reproductionRateName)) {
            const reproductionRateName = reproductionRates.find(reproductionRateObj => reproductionRateObj.id === +values.crop.reproductionRate).name;
            setFieldValue('crop.reproductionRateName', reproductionRateName);
            if (setFormInitialValues) {
                setFormInitialValues(preInitialvalues => ({
                    ...preInitialvalues,
                    crop: {
                        ...(preInitialvalues.crop),
                        reproductionRateName,
                    },
                }));
            }
        }
    }, [values.crop.reproductionRate, values.crop.reproductionRateName, reproductionRates, setFieldValue, setFormInitialValues]);
}
