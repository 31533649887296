/* eslint-disable no-loop-func */
/* eslint-disable guard-for-in */
import { useEffect } from 'react';
import { soilAnalysisService } from '@services';
import { alertActions, errorActions } from '@states/actions';
import { useDispatch, useSelector } from 'react-redux';
import { head, isEmpty, keys } from 'lodash';
import * as XLSX from 'xlsx';
import { useFormContext } from 'react-hook-form';
import useTranslate from '@i18n/useTranslate';

export default function useSoilAnalysisExcelImportData(props) {
    const { companyId, cultivationPeriodId } = props;
    const cultivationPeriodList = useSelector((state: any) => state.basePage.table.tableData);

    const dispatch = useDispatch();
    const { t } = useTranslate();
    const { watch, setValue, getValues, clearErrors, formState } = useFormContext();
    const { errors } = formState;

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const soilAnalysisExcelInterface = await soilAnalysisService.getSoilAnalysisExcelInterface({ companyId });

                if (!isCancelled) {
                    if (isEmpty(soilAnalysisExcelInterface)) {
                        dispatch(alertActions.errorAlert(t('soilAnalysisImport.excelInterfaceIsNotSetted', 'Excel interface is not setted')));
                    } else {
                        setValue('excelInterface', soilAnalysisExcelInterface);
                    }
                }
            } catch (error) {
                dispatch(errorActions.setError(error, 'LoadSoilAnalysisExcelInterface'));
            }
        }

        if (dispatch && companyId && t) {
            loadData();
            return () => {
                isCancelled = true;
            };
        }
    }, [dispatch, companyId, setValue, t]);

    function loadExcelData(event) {
        const file = head(event?.target?.files);
        const excelInterface = getValues('excelInterface');
        if (file) {
            const reader = new FileReader();

            reader.readAsArrayBuffer(file);
            reader.onload = async () => {
                const buffer = reader.result;
                const workbook = XLSX.read(buffer, { type: 'array' });
                const workSheetName = head(workbook.SheetNames);
                const workSheet = workbook.Sheets[workSheetName];
                let row = -1;
                const soilAnalysisData = [];
                for (const cellName in workSheet) {
                    const column = head(cellName);
                    if (column === 'A') {
                        row += 1;
                    }
                    const parameters = keys(excelInterface);
                    if (!soilAnalysisData[row]) {
                        soilAnalysisData[row] = {
                            rowId: row + 1,
                            id: row + 1,
                        };
                    }

                    parameters?.forEach(parameter => {
                        if (parameter === 'cultivationPeriod' && !isEmpty(cultivationPeriodList)) {
                            let cultivationPeriodMatches = cultivationPeriodList?.filter(cultivationPeriod => cultivationPeriod.name === workSheet[cellName].v);

                            if (cultivationPeriodMatches?.length > 1) {
                                cultivationPeriodMatches = cultivationPeriodMatches.filter(cultivationPeriod => +(cultivationPeriod?.companyId) === companyId);
                            } else if (cultivationPeriodMatches?.length === 0 && cultivationPeriodId) {
                                cultivationPeriodMatches = cultivationPeriodList?.filter(cultivationPeriod => cultivationPeriod.id === cultivationPeriodId);
                            }

                            const cultivationPeriod = cultivationPeriodMatches?.[0];

                            if (cultivationPeriod) {
                                soilAnalysisData[row].cultivationPeriod = { id: cultivationPeriod.id, name: cultivationPeriod.name, companyName: cultivationPeriod.companyName };
                                soilAnalysisData[row].cultivationPeriodCompanyName = cultivationPeriod.companyName;
                            }
                        } else if (excelInterface[parameter].column === column) {
                            soilAnalysisData[row][parameter] = workSheet[cellName].v;
                        }
                    });
                }
                if (errors?.soilAnalysisData?.message) {
                    clearErrors('soilAnalysisData');
                }
                setValue('soilAnalysisData', soilAnalysisData);
            };
        }
    }

    function onChangeImportData(event) {
        if (!watch('companyId')) {
            dispatch(alertActions.errorAlert(t('soilAnalysis.companyIsRequired', 'Company is required')));
        } else {
            loadExcelData(event);
        }
    }

    function onClickImportData(event) {
        if (!watch('companyId')) {
            event.preventDefault();
            dispatch(alertActions.errorAlert(t('soilAnalysis.companyIsRequired', 'Company is required')));
        }
    }

    return {
        onChangeImportData,
        onClickImportData,
    };
}
