import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { dateTimeUtils } from 'shared/src/modules';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        ...theme.overFlowText,
        color: theme.color.jet,
        display: 'block',
        height: '100%',
        lineHeight: '56px',
        fontSize: 12,
        fontWeight: 500,
        verticalAlign: 'middle',
    },
    multilineWrapper: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    firstLine: {
        height: '50%',
    },
    secondLine: {
        height: '50%',
    },
}));

export default function DateMultilineCell(props) {
    const { cellValue, firstLineId, secondLineId } = props;
    const { date1, date2 } = cellValue || { date1: firstLineId, date2: secondLineId };
    const classes = useStyles();

    const getFormattedDate = useCallback(date => (dateTimeUtils.isValidDate(date) ? dateTimeUtils.getDate(date) : null), []);

    return (
        <div className={classes.baseStyle}>
            <div className={classes.multilineWrapper}>
                <div className={classes.firstLine}>
                    {getFormattedDate(date1)}
                </div>
                <div className={classes.secondLine}>
                    {getFormattedDate(date2)}
                </div>
            </div>
        </div>
    );
}
