export const WAREHOUSE = {
    TYPE_ID: {
        OWN: 1,
        FOREIGN: 2,
        OWN_AND_FOREIGN: 3,
    },
    OWNERSHIP: {
        OWN: 1,
        FOREIGN: 2,
    },
    USAGE_TYPE: {
        NEW: 1,
        WITHDRAW: 2,
    },
    RELEASE_TYPE: {
        FROM_OWN: 1,
        FROM_EXTERNAL_PARTNER: 2,
        FROM_PARTNER: 3,
        NOT_FROM_STOCK: 4
    },
};
