/* eslint-disable no-undefined */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useContext, useMemo, useState } from 'react';
import { MapController } from './mapController';
import { initStore, TMapStore } from './mapStore';
import MapStoreService from './mapStoreService';

export type TMapContext = Readonly<[store: TMapStore, controller: MapController]>;

const MapContext = createContext<TMapContext>([initStore, new MapController(initStore, new MapStoreService(initStore, () => { }))]);

type TProps = {
    children: React.ReactNode
};

export const MapContextProvider = ({ children }: TProps) => {
    const [store, setStore] = useState(initStore);

    const [storeService, controller] = useMemo<[MapStoreService, MapController]>(() => {
        const mapStoreService = new MapStoreService(initStore, () => { });
        const mapController = new MapController(initStore, mapStoreService);

        return [mapStoreService, mapController];
    }, []);

    storeService.bind(store, setStore);
    controller.bind(store, storeService);

    return (
        <MapContext.Provider value={[store, controller]}>
            {children}
        </MapContext.Provider>
    );
};

export const useMapContext = () => useContext(MapContext);
