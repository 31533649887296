import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createUseStyles } from 'react-jss';
import { DATE } from 'shared/src/constants';
import useTranslate from '@i18n/useTranslate';
import Svg from '@baseComponents/Svg';
import SegmentToogle from '@baseComponents/controls/SegmentToogle';
import { companyActions } from '@states/actions/companyActions';

const useStyles = createUseStyles((theme: any) => ({
    headerContainer: {
        borderRadius: [10, 10, 0, 0],
        padding: [15, 30],
        width: '100%',
        height: 94,
        display: 'flex',
        borderBottom: '2px solid',
        borderBottomColor: theme.color.pearl,
        justifyContent: 'center',
        alignItems: 'center',
    },
    headerLeft: {
        display: 'block',
        position: 'absolute',
        left: '30px',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        lineHeight: 2.0,
    },
    next: {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 20,
        width: 20,
        marginLeft: 5,
        backgroundColor: theme.color.pearl,
        borderRadius: '50%',
    },
    icon: {
        height: 12,
        width: 12,
    },
    headerRight: {
        position: 'absolute',
        right: '30px',
    },
    closeIcon: {
        height: 24,
        width: 24,
        fill: theme.color.jet,
    },
    close: {
        height: 40,
        width: 40,
        borderRadius: '50%',
        backgroundColor: theme.color.pearl,
        border: 'none',
    },
    periods: {
        display: 'block',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: 1.25,
        color: theme.color.raven,
        backgroundColor: theme.color.white,
        border: 'none',
        padding: 0,
        '&:hover': {
            textDecoration: 'underline',
            color: theme.color.main,
        },
    },
}));

export default function WeatherForecastModalHeader(props) {
    const { showPeriodView, periodName, selectedBreak, setSelectedBreak, hideModal, setWeatherData } = props;
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();

    const [usingSevenDaysWeather] = useState(dispatch(companyActions.getUsingSevenDaysWeather()));

    const weatherForesactTypes = [
        {
            key: DATE.BREAKDOWN.DAY,
            value: t('weatherModal.daily', 'Daily'),
            onClick: () => setWeatherData(),
        },
        {
            key: DATE.BREAKDOWN.WEEK,
            value: t('weatherModal.sevenDays', '7 days'),
            onClick: () => setWeatherData(),
        },
    ];

    function changeBreak(key) {
        setSelectedBreak(key);
    }

    return (
        <div className={classes.headerContainer}>
            <div className={classes.headerLeft}>
                <span className={classes.title}>{t('weatherModal.weatherForecast', 'Weather forecast')}</span>
                <button className={classes.periods} type="button" onClick={showPeriodView}>
                    {periodName}
                    <span className={classes.next}>
                        <Svg iconId="icon-arrow_forward" style={classes.icon} />
                    </span>
                </button>
            </div>
            {usingSevenDaysWeather &&
            <div>
                <SegmentToogle size="large" selected={selectedBreak} items={weatherForesactTypes} onChange={changeBreak} />
            </div>}
            <div className={classes.headerRight}>
                <button type="button" className={classes.close} onClick={hideModal}>
                    <Svg iconId="icon-close" style={classes.closeIcon} />
                </button>
            </div>
        </div>
    );
}
