import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { alertActions, systemActions, userActions } from '@states/actions';
import { routerHelper, phpHelper } from '@utils';
import { sessionService } from '@services';

export function useRouteEventHandler() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const maintenance = useSelector((state: any) => state.system.systemInfo.isMaintained);
    const activeSystemModule = useSelector((state: any) => state.user.user.settings?.activeSystemModule);
    const companySettings = useSelector((state: any) => state.company.company.settings);
    const [isLoadedSystemInfo, setIsLoadedSystemInfo] = useState(false);
    const agent = useSelector((state: any) => state.user.agent);

    //check maintenance
    useEffect(() => {
        if (maintenance && dispatch && t) {
            dispatch(alertActions.infoAlert(t('router.maintenance', 'The AgroVIR is updating. Please try again later.')));
            dispatch(systemActions.removeMaintenance());
        }
    }, [maintenance, dispatch, t]);

    //detect module change and check router permission
    useEffect(() => {
        if (history && activeSystemModule && companySettings) {
            const location = history.location.pathname;
            const moduleObj = routerHelper.getModuleObjectByUrl(location);
            const contentObj = routerHelper.getContentObjectByUrl(location);

            if (moduleObj && contentObj) {
                const agentName = sessionService.getAgentName();
                const hasAccess = dispatch(userActions.hasUserAccessToContent(moduleObj, contentObj));

                if (!hasAccess) {
                    history.push(`/${agentName}`);
                }

                phpHelper.clearQtipPanels();
            }
        }
    }, [activeSystemModule, companySettings, history, dispatch]);

    //onLoad - get default system info
    useEffect(() => {
        async function getSystemInfo() {
            try {
                const systemInfo = await dispatch(systemActions.getSystemInfo()) as any;

                if (systemInfo) {
                    dispatch(userActions.login(maintenance));
                }
            } catch (error: any) {
                throw new Error(error);
            }
        }

        if (!isLoadedSystemInfo && agent) {
            setIsLoadedSystemInfo(true);
            getSystemInfo();
        }
    }, [isLoadedSystemInfo, agent, maintenance, dispatch]);
}
