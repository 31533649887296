import { isObject, isUndefined } from 'lodash';

export const objectUtils = {
    parseObjectToJson,
    getClassFromObject,
    parseJSONToArray,
    parseJSONToObject,
};

function parseObjectToJson(object) {
    if (!isObject(object)) {
        return {};
    }

    try {
        const parsedData = JSON.stringify(object);
        return parsedData;
    } catch (error) {
        return {};
    }
}

function parseJSONToArray(text) {
    if (typeof text !== 'string') {
        return [];
    }

    try {
        const parsedData = JSON.parse(text);
        return parsedData;
    } catch (error) {
        return [];
    }
}

function getClassFromObject(EntityClass, object) {
    if (!EntityClass || !object || !isObject(EntityClass) || !isObject(object)) {
        return null;
    }

    const entityClass = new EntityClass();
    const classKeys = Object.getOwnPropertyNames(entityClass);

    //convert object into class
    Object.entries(object).forEach(([key, value]) => {
        if (classKeys.includes(key) && !isUndefined(value) && !isObject(value)) {
            entityClass[key] = value;
        }
    });

    //remove connection
    Object.entries(entityClass).forEach(([key, value]) => {
        if (isUndefined(value)) {
            delete entityClass[key];
        }
    });

    return entityClass;
}

function parseJSONToObject(text) {
    if (typeof text !== 'string') {
        return {};
    }

    try {
        const parsedData = JSON.parse(text);
        return parsedData;
    } catch (error) {
        return {};
    }
}
