/* eslint-disable id-blacklist */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-lines-per-function */
/* eslint-disable no-undef */
import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { SYSTEM, COUNTRY } from 'shared/src/constants';
import { getSideMenuList } from 'shared/src/sideMenu/sideMenuList';
import { isFunction, isNull, isEmpty, isUndefined } from 'lodash';
import { routerHelper } from '@utils/router/RouterHelper';
import { routerActions } from '@states/actions/routerActions';

import { LoadSpinner } from '@baseComponents/loadSpinner';
import AdModal from '@modals/adsModal/AdModal';
import registerLocales from '@baseComponents/datepickers/registerLocales';
import { alertActions, userActions } from '@states/actions';
import { sessionService, phpService } from '@services';
import usePhpReactSessionHandler from './screenLoader/usePhpReactSessionHandler';
import SideNavBar from '../components/sideNavBar/SideNavBar';
import MainContent from '../components/mainPage/MainContent';
import useDarkMode from './screenLoader/useDarkMode';

const useStyle = createUseStyles({
    defaultInfo: {
        display: 'none',
    },
    mainPageContainer: {
        display: 'flex',
        position: 'relative',
    },
});

export default function MainPage(props) {
    const { routeList } = props;
    const userToRights = useSelector((state: any) => state.user.user.userToRights);
    const isHiddenUser = useSelector((state: any) => state.user.user.isHidden);
    const administrator = useSelector((state: any) => state.user.user.administrator);
    const consultant = useSelector((state: any) => state.user.user.consultant);
    const companySettings = useSelector((state: any) => state.company.company.settings);
    const currentSystemModule = useSelector((state: any) => state.user.user.settings.activeSystemModule);
    const countryCode = useSelector((state: any) => state.system.systemInfo.countryCode);
    const planningModeisActive = useSelector((state: any) => state.system.planningModeisActive);
    const isActiveDarkMode = useSelector((state: any) => state.user.user.darkMode);
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    const [sideMenuList, setSideMenuList] = useState<Array<any>>([]);
    const [expanded, setExpanded] = useState(true);
    const [defaultInfoLoaded, setDefaultInfoLoaded] = useState(false);
    const [indexContentId] = useState('reactPhpIndexContent');
    const [indexContentRefreshId] = useState('reactPhpIndexContentRefresh');
    const classes = useStyle();
    const prevMenuState = useRef(true);

    usePhpReactSessionHandler({ phpJavascriptLoaded: defaultInfoLoaded });

    useDarkMode(isActiveDarkMode);

    //Get side menu list
    useEffect(() => {
        async function getSideMenuListWithPermission() {
            let planningMode = sessionService.getPlanningMode();
            let planningData = null;

            if (planningMode) {
                planningData = sessionService.getPlanningData();
                planningMode = !isEmpty(planningData);
            }

            const permissionList = await phpService.getSideMenuPermissions();

            //php session expired
            if (isEmpty(permissionList)) {
                dispatch(userActions.logout());
                dispatch(alertActions.infoAlert(t('default.sessionExpired', 'Session expired. Please log in again.')));
                return;
            }

            setSideMenuList(getSideMenuList(userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, permissionList, isHiddenUser, countryCode));
        }

        if (currentSystemModule && companySettings && userToRights && !isNull(administrator) && !isNull(consultant) && dispatch && t) {
            getSideMenuListWithPermission();
        }
    }, [currentSystemModule, companySettings, userToRights, administrator, consultant, dispatch, t, planningModeisActive]);

    //php index initialize
    useEffect(() => {
        async function loadIndexReact() {
            const baseUrl = `${window.location.protocol}//${window.location.host}`;

            //SETUP default ajax call for react
            $.ajaxSetup({
                crossDomain: true,
                xhrFields: {
                    withCredentials: true,
                },
                beforeSend(__, options) {
                    const pattern = /^(http|https|ftp)/;
                    if (!pattern.test(options.url)) {
                        options.url = baseUrl + options.url;
                    }
                },
            });

            //Load index php
            $.ajax({
                type: 'POST',
                url: '/modules/index_react.php',
                dataType: 'text',
                success: indexContent => {
                    //set dom
                    try {
                        $(`#${indexContentId}`).html(indexContent);
                        refreshDateLang();
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    }
                },
                error: (__, error) => {
                    // eslint-disable-next-line no-console
                    console.log('Hibrid php index react ajax loader error: ', error);
                },
            });
        }

        function refreshDateLang() {
            $.ajax({
                type: 'POST',
                url: '/modules/index_react_refresh.php',
                dataType: 'text',
                success: indexContent => {
                    //set dom
                    try {
                        $(`#${indexContentRefreshId}`).html(indexContent);
                        setDefaultInfoLoaded(true);
                    } catch (error) {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    }
                },
                error: (__, error) => {
                    // eslint-disable-next-line no-console
                    console.log('Hibrid php refresh refresh date/lang ajax loader error: ', error);
                },
            });
        }

        if ($ && !(window as any).isInitializedIndexReact) {
            loadIndexReact();
        } else if ($ && (window as any).isInitializedIndexReact) { //refresh things in index php
            refreshDateLang();

            if ((window as any).refreshPhpJsScripts) {
                (window as any).refreshPhpJsScripts();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [$]);

    //set default page item
    useEffect(() => {
        if (dispatch && history && routeList) {
            const routeItem = routerHelper.getCurrentRouteItem(history, routeList);
            const menuItem = routerHelper.getCurrentMenuItem(sideMenuList, routeItem, SYSTEM.MENU_DEFAULT_PAGE);
            if (!isUndefined(menuItem)) {
                dispatch(routerActions.setCurrentPageKey(menuItem.eventKey));
            }
        }
    }, [sideMenuList, dispatch, history, routeList]);

    //register locales for datePicker
    useEffect(() => {
        if (isFunction((window as any).refreshPhpCegMultiselect) && (window as any).isInitializedIndexReact) { //refresh php ceg_nav multiselect
            (window as any).refreshPhpCegMultiselect();
        }
        registerLocales(dispatch); //register locales for datePicker
    }, [dispatch]);

    useEffect(() => () => {
        if (location && !location.includes('/map/map')) {
            prevMenuState.current = expanded;
        }
    }, [expanded, location]);

    useEffect(() => {
        if (!location) {
            // console.log(location, prevLocation);
            return;
        }

        if (location.includes('/map/map')) {
            setExpanded(false);
        } else {
            setExpanded(prevMenuState.current);
        }
    }, [location]);

    useEffect(() => {
        $('.qtip-av-content').qtip('hide');
    }, [expanded]);

    return (
        <div className={classes.mainPageContainer}>
            <LoadSpinner value={defaultInfoLoaded} isAutoDetect />

            {countryCode && countryCode === COUNTRY.HUNGARY && defaultInfoLoaded && <AdModal />}

            <SideNavBar routeList={routeList} sideMenuList={sideMenuList} expanded={expanded} setExpanded={setExpanded} defaultInfoLoaded={defaultInfoLoaded} />
            {defaultInfoLoaded && <MainContent routeList={routeList} />}
        </div>
    );
}
