import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    slider: {
        position: 'relative',
    },
    sliderLine: {
        width: '100%',
        height: 4,
        borderRadius: 2,
        backgroundColor: theme.color.gray,
        position: 'absolute',
    },
    sliderLeft: {
        width: (props: any) => `${((props.value - props.min) / (props.max - props.min)) * 100}%`,
        height: 4,
        borderRadius: 2,
        backgroundColor: theme.color.main,
        position: 'absolute',
    },
    range: {
        width: '100%',
        position: 'absolute',
        WebkitAppearance: 'initial',
        marginTop: 16,
        '&::-webkit-slider-thumb': {
            WebkitAppearance: 'none',
            appearance: 'none',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            border: `4px solid ${theme.color.main}`,
            backgroundColor: theme.color.white,
            cursor: 'pointer',
            transform: 'translateY(-100%)',
        },
        '&::-moz-range-thumb': {
            position: 'absolute',
            appearance: 'none',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            border: `4px solid ${theme.color.main}`,
            backgroundColor: theme.color.white,
            cursor: 'pointer',
            transform: 'translateY(-100%)',
        },
    },
}));

export default function Slider(props) {
    const { value, min, max, className, onChange, onBlur } = props;
    const classes = useStyles({ value, min, max });

    return (
        <div className={clsx(classes.slider, className)}>
            <span className={classes.sliderLine} />
            <span className={classes.sliderLeft} />
            <input type="range" min={min} max={max} className={classes.range} value={value} onChange={onChange} onBlur={onBlur} onMouseUp={onBlur} />
        </div>
    );
}
