import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { TABLE } from 'shared/src/constants';
import { useLocation } from 'react-router';
import { basePageActions } from '@states/actions/basePageActions';
import { routerHelper } from '@utils/router/RouterHelper';
import { getColumns } from './tableColumns';

export default function useTableColumn(props) {
    const { defaultColumns, firstTypeColumns, secondTypeColumns, thirdTypeColumns, customTypeColumns, isSimpleTable = false } = props;
    const [columns, setColumns] = useState(null);
    const [location] = useState(routerHelper.getModuleContentNameByUrl(useLocation().pathname));
    const showedColumnType = useSelector((state: any) => state.basePage.showedColumnType);
    const selectedCustomColumns = useSelector((state: any) => state.basePage.selectedCustomColumns);
    const columnOrders = useSelector((state: any) => state.user.user.settings?.columnOrders[location]);
    const dispatch = useDispatch();

    //set default custom columns
    useEffect(() => {
        if (!isSimpleTable) {
            if (customTypeColumns && dispatch) {
                dispatch(basePageActions.setTableCustomColumns(getColumns(customTypeColumns)));
            } else {
                dispatch(basePageActions.resetTableCustomColumns());
            }
        }
    }, [customTypeColumns, dispatch, isSimpleTable]);

    //set actual columns
    useEffect(() => {
        if ((defaultColumns || firstTypeColumns || customTypeColumns) && dispatch) {
            let columnList = null;

            if (showedColumnType && !isSimpleTable) {
                switch (showedColumnType) {
                    case TABLE.COLUMN_TYPE.FIRST_TYPE:
                        columnList = getColumns(firstTypeColumns || defaultColumns);
                        break;
                    case TABLE.COLUMN_TYPE.SECOND_TYPE:
                        columnList = getColumns(secondTypeColumns);
                        break;
                    case TABLE.COLUMN_TYPE.THIRD_TYPE:
                        columnList = getColumns(thirdTypeColumns);
                        break;
                    case TABLE.COLUMN_TYPE.CUSTOM_TYPE:
                        columnList = getColumns(customTypeColumns); //current columns
                        columnList = getSelectedCustomTypeColumns(columnList, selectedCustomColumns);
                        break;
                    default:
                        columnList = getColumns(defaultColumns);
                        break;
                }
            } else {
                columnList = getColumns(defaultColumns);
            }

            if (!isEmpty(columnOrders) && columnList && !isSimpleTable) {
                columnList.forEach(column => {
                    const columnOrder = columnOrders.find(columnOrderItem => columnOrderItem.columnId === column.columnId);
                    if (columnOrder) {
                        column.index = columnOrder.index;
                    } else {
                        column.index = 0;
                    }

                    //set left - right fixed column index
                    if (column.isLeftFixed) {
                        column.index = TABLE.ORDER_INDEX.LEFT_FIXED;
                    } else if (column.isRightFixed) {
                        column.index = TABLE.ORDER_INDEX.RIGHT_FIXED;
                    }
                });

                columnList.sort((a, b) => a.index - b.index);
            }

            if (!isSimpleTable) {
                dispatch(basePageActions.setTableColumns(columnList));
            }
            setColumns(columnList);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnOrders, defaultColumns, showedColumnType, firstTypeColumns, secondTypeColumns, thirdTypeColumns, customTypeColumns, selectedCustomColumns, dispatch]);

    function getSelectedCustomTypeColumns(currentColumns, selectedCustomTypeColumns) {
        const columnList = [];

        if (isEmpty(selectedCustomTypeColumns)) {
            return currentColumns;
        }

        currentColumns.forEach(column => {
            if (selectedCustomTypeColumns.includes(column.columnId) || column.isLeftFixed || column.isRightFixed || column.isTitle) {
                columnList.push(column);
            }
        });

        return columnList;
    }

    return {
        columns,
    };
}
