import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import FileInput from '@baseComponents/inputs/DataInput';
import CropOverall from '@customComponents/culture/CropOverall';
import { DoubleDatePicker } from '@customComponents/datePickers';
import Map from '@map/screens/Map';
import { companyActions } from '@states/actions/companyActions';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import { SETTINGS } from 'shared/src/constants';
import { useCompanyPermission } from '@common_modules';
import PeriodViewCommentCard from './PeriodViewCommentCard';
import PeriodViewAncestorChildCard from './PeriodViewAncestorChildCard';
import PeriodViewCostCard from './PeriodViewCostCard';
import PeriodViewFooterButtons from './PeriodViewFooterButtons';
import PeriodViewOverallCard from './PeriodViewOverallCard';
import PeriodViewTitleCard from './PeriodViewTitleCard';
import usePeriodViewDataLoader from './usePeriodViewDataLoader';

const useStyles = createUseStyles((theme: any) => ({
    periodsModal: {
        height: 'calc(100vh - 80px)',
        width: 'calc(100vw - 120px)',
        display: 'flex',
    },
    periodsModalLeft: {
        minWidth: 380,
        maxWidth: 380,
        height: '100%',
        padding: [25, 0],
        display: 'inline-flex',
        flexDirection: 'column',
        ...theme.verticalScrollbar,
    },
    periodsModalRight: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.color.map,
        borderBottomRightRadius: 12,
        position: 'relative',
    },
    marginTop: {
        marginTop: 15,
    },
    periodViewContainer: {
        marginTop: 15,
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',
        padding: [0, 30],
        ...theme.verticalScrollbar,
    },
    periodViewContainerWidth: {
        width: 320,
    },
}));

export default function PeriodView(props) {
    const { hideModal, cultivationPeriodValues } = props;
    const dispatch = useDispatch();
    const [usingMap] = useState(dispatch(companyActions.getSetting(SETTINGS.USING_MAP)));
    const classes = useStyles();
    const { companyPermissions } = useCompanyPermission();

    const {
        editFunctionObject,
        closePeriodFunctionObject,
        otherFunctionList,
        cultivationPeriodData,

        reproductionRateName,
        supportList,
        basicFieldsList,
        registryNumberList,
        costList,
    } = usePeriodViewDataLoader({ cultivationPeriodValues });

    return (
        <div className={classes.periodsModal}>
            <div className={classes.periodsModalLeft}>
                <PeriodViewTitleCard
                    periodYear={cultivationPeriodData?.productionYear}
                    name={cultivationPeriodData?.name}
                    companyName={cultivationPeriodData?.companyName}
                    sownArea={cultivationPeriodData?.sownArea || 0}
                    isFixed={Number(cultivationPeriodData?.isFixed)}
                />

                <div className={classes.periodViewContainer}>
                    <div className={classes.periodViewContainerWidth}>
                        <DoubleDatePicker
                            disabled
                            startDate={cultivationPeriodData?.startDate}
                            endDate={cultivationPeriodData?.endDate}
                        />

                        <FileInput className={classes.marginTop} disabled>
                            <CropOverall
                                cultureName={cultivationPeriodData?.cultureName}
                                typeName={cultivationPeriodData?.cropTypeName}
                                reproductionRateName={reproductionRateName}
                                varietyName={cultivationPeriodData?.cultureVarietyName}
                            />
                        </FileInput>

                        <PeriodViewOverallCard supportList={supportList} basicFieldsList={basicFieldsList} registryNumberList={registryNumberList} />

                        <PeriodViewCostCard costList={costList} />

                        {!isEmpty(cultivationPeriodData?.ancestorCultivationPeriods)
                            && <PeriodViewAncestorChildCard
                                cardData={cultivationPeriodData?.ancestorCultivationPeriods}
                                isChild={false}
                            />
                        }

                        {!isEmpty(cultivationPeriodData?.childCultivationPeriods)
                            && <PeriodViewAncestorChildCard
                                cardData={cultivationPeriodData?.childCultivationPeriods}
                                isChild
                            />
                        }

                        {cultivationPeriodData?.comment
                            && <PeriodViewCommentCard comment={cultivationPeriodData?.comment} />
                        }

                        {cultivationPeriodData?.comment2 && companyPermissions.hasCultivationPeriodBankAndInsurer()
                            && <PeriodViewCommentCard comment={cultivationPeriodData?.comment2} serialNumber={2}/>
                        }

                        {cultivationPeriodData?.comment3 && companyPermissions.hasCultivationPeriodBankAndInsurer()
                            && <PeriodViewCommentCard comment={cultivationPeriodData?.comment3} serialNumber={3}/>
                        }
                    </div>
                </div>

                <PeriodViewFooterButtons
                    cultivationPeriodData={cultivationPeriodData}
                    closePeriodFunctionObject={closePeriodFunctionObject}
                    editFunctionObject={editFunctionObject}
                    otherFunctionList={otherFunctionList}
                    closeModal={hideModal}
                />
            </div>
            <div className={classes.periodsModalRight}>
                {(cultivationPeriodData && usingMap) && (
                    <Map
                        withMapStyleModifier
                        productionYearId={cultivationPeriodData.yearId}
                        entityId={cultivationPeriodData.id}
                        withInfoPanel
                        withMeasureTool={false}
                        withCurrencySwitch={false}
                        withMapSearch={false}
                    />
                )}
            </div>
        </div>
    );
}
