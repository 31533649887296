import { useState, useEffect } from 'react';
import { isNaN, isNumber } from 'lodash';
import { useErrorHandler } from '@utils';
import { warehouseService, stockService } from '@services';
import { WAREHOUSE } from 'shared/src/constants';

export default function useReleasingWarehouseLoadData(props) {
    const { partnerId, companyIds, typeIds, isRecordsInTreatmentUnit, isOnlyFromStock, productIds, date } = props;
    const errorHandler = useErrorHandler();
    const [isReloadTable, setIsReloadTable] = useState(false);
    const [tableData, setTableData] = useState();

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                let warehouses = await warehouseService.getWarehouses({
                    companyIds,
                    typeIds: typeIds || [WAREHOUSE.TYPE_ID.OWN, WAREHOUSE.TYPE_ID.FOREIGN, WAREHOUSE.TYPE_ID.OWN_AND_FOREIGN],
                });

                if (!isNaN(+isRecordsInTreatmentUnit) && isRecordsInTreatmentUnit) {
                    warehouses = warehouses?.filter(warehouse => +warehouse.isTreatmentUnit === +isRecordsInTreatmentUnit);
                }

                warehouses = warehouses?.map(warehouse => ({
                    ...warehouse,
                    partnerCompanyName: warehouse.partnerName,
                }));

                if (!isCancelled) {
                    setTableData(warehouses);
                }
            } catch (error) {
                errorHandler(error);
            }
        }

        async function loadDataFromStock() {
            try {
                const endPointProps: any = {
                    companyIds,
                    productIds,
                    date,
                    isShowStockPrice: true,
                };
                if (isNumber(partnerId)) {
                    endPointProps.partnerId = partnerId;
                }
                let warehouses = await stockService.getReleaseStockWarehousesByProducts(endPointProps);

                if (!isNaN(+isRecordsInTreatmentUnit) && isRecordsInTreatmentUnit) {
                    warehouses = warehouses?.filter(warehouse => +warehouse.isRecordsInTreatmentUnit === +isRecordsInTreatmentUnit);
                }
                warehouses = warehouses?.map(warehouse => ({
                    id: warehouse.warehouseId,
                    name: warehouse.warehouseName,
                    partnerName: warehouse.currentPartnerName,
                    afterQuantity: warehouse.afterQuantity,
                    partnerId: warehouse.partnerId,
                    companyId: warehouse.companyId,
                    companyName: warehouse.companyName,
                    partnerCompanyName: warehouse.partnerCompanyName,
                    unitId: warehouse.unitId,
                    unitShortName: warehouse.unitShortName,
                    stockPrice: warehouse.stockPrice,
                    isRecordsInTreatmentUnit: warehouse.isRecordsInTreatmentUnit,
                }));

                if (!isCancelled) {
                    setTableData(warehouses);
                }
            } catch (error) {
                errorHandler(error);
            }
        }

        if (isOnlyFromStock) {
            loadDataFromStock();
        } else {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [isReloadTable, errorHandler, companyIds, typeIds, isRecordsInTreatmentUnit, isOnlyFromStock, productIds, date, partnerId]);

    function reloadTable() {
        setIsReloadTable(currentState => !currentState);
    }

    return {
        tableData,
        reloadTable,
    };
}
