import BaseController from '@flowsCommon/services/BaseController';
import {SKInfoPanelTabData} from '@map/components/SlovakReportsOverlay/slovakReportsUtils';
import {TSlovakReportStore} from './slovakReportStore';
import {SlovakReportStoreService} from './slovakReportStoreService';
import {InfoPanelTab} from '@map/components/SlovakReportsOverlay/SlovakInfoPanel/SlovakInfoPanel';
import {isFunction} from 'lodash';

export class SlovakReportController extends BaseController<TSlovakReportStore, SlovakReportStoreService> {
  initDataLayers = (map: google.maps.Map) => {
    const lpisDataLayer = new google.maps.Data();
    const publishedPolygonsDataLayer = new google.maps.Data();
    const dataLayer = new google.maps.Data();
    lpisDataLayer.setMap(map);
    dataLayer.setMap(map);
    publishedPolygonsDataLayer.setMap(map);
    this.storeService.initDataLayers(map, dataLayer, lpisDataLayer, publishedPolygonsDataLayer);
  };

  setActiveLayers = (layers: number[]) => {
    this.storeService.setActiveLayers(layers);
  };

  setInfoPanel(isOpen: boolean, posX: number, posY: number, tabType: InfoPanelTab, infoPanelData?: SKInfoPanelTabData[], onClose?: () => void) {
    if (this.store.infoPanel.tabType !== tabType) {
      if (isFunction(this.store.infoPanel.onClose)) {
        this.store.infoPanel.onClose();
      }
    }
    this.storeService.setInfoPanel(isOpen, posX, posY, tabType, infoPanelData, onClose);
  }

  setSelectedLpisPolygon = (feature: google.maps.Data.Feature | null) => {
    this.storeService.setSelectedLpisPolygon(feature);
  };

  setDrawMode = (drawMode: boolean) => {
    this.storeService.setDrawMode(drawMode);
  };
}
