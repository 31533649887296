import React from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
    emptyCell: {
        display: 'none',
    },
});

export default function EmptyCell() {
    const classes = useStyles();

    return (
        <div className={classes.emptyCell} />
    );
}
