import { useEffect, useRef } from 'react';
import { numberUtils } from 'shared/src/modules';

const useWhyDidYouUpdateEnabledTemporarly = false;

export default function useWhyDidYouUpdate(name, props) {
    const previousProps = useRef();

    useEffect(() => {
        if ((useWhyDidYouUpdateEnabledTemporarly || numberUtils.getBoolean(process.env.REACT_APP_USEWHYDIDUUPDATE)) && process.env.NODE_ENV !== 'production' && previousProps.current) {
        // Get all keys from previous and current props
            const allKeys = Object.keys({ ...previousProps.current, ...props });
            // Use this object to keep track of changed props
            const changesObj = {};
            // Iterate through keys
            allKeys.forEach(key => {
                // If previous is different from current
                if (previousProps.current[key] !== props[key]) {
                    // Add to changesObj
                    changesObj[key] = {
                        from: previousProps.current[key],
                        to: props[key],
                    };
                }
            });

            // If changesObj not empty then output to console
            if (Object.keys(changesObj).length) {
                // eslint-disable-next-line no-console
                console.log('[why-did-you-update]', name, changesObj);
            }
        }
        // Finally update previousProps with current props for next hook call
        previousProps.current = props;
    });
}
