import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function useLoadMultiLineViewType(props) {
    const { tableKey } = props;
    const basePageMultilineViewType = useSelector((state: any) => state.basePage.table.multilineViewType);
    const simpleTableMultilineViewType = useSelector((state: any) => state.simpleTable.table[tableKey]?.multilineViewType);
    const [multilineViewType, setMultilineView] = useState();

    useEffect(() => {
        setMultilineView(tableKey ? simpleTableMultilineViewType : basePageMultilineViewType);
    }, [basePageMultilineViewType, tableKey, simpleTableMultilineViewType]);

    return multilineViewType;
}
