import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import Svg from '@baseComponents/Svg';
import renderModal from '@modals/modalNew/modalFunctions';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { Layer, ThemeType, TranslateType } from '@map/services/mapTypes';
import clsx from 'clsx';
import { Feature } from 'geojson';
import { isNil } from 'lodash';
import { createUseStyles } from 'react-jss';
import PlanningEditModal from '../ContextMenu/modals/PlanningEditModal';
import { RIGHTS } from 'shared/src/constants';
import { userActions } from '@states/actions';

type Classes = 'downloadWrapper' | 'downloadBtnDiv' | 'iconStyle' | 'iconBtnWrapper' | 'iconBtnWrapperSeparator' | 'iconWidth60' | 'iconWidth50' | 'iconWidth40';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    downloadWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        borderRadius: '0px 0px 5px 5px',
        borderTop: '1px solid rgb(227, 230, 232)',
        backgroundColor: 'white',
        color: theme.color.jet,
        '& > *:hover': {
            backgroundColor: '#22C37320',
            color: theme.color.jet,
        },
    },
    downloadBtnDiv: {
        height: 40,
        textAlign: 'center',
        lineHeight: '40px',
        cursor: 'pointer',
        textDecoration: 'none',
        fontFamily: 'Rubik',
        fontSize: 13,
        fontWeight: 500,
        paddingLeft: 2,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    iconStyle: {
        fill: theme.color.jet,
        cursor: 'pointer',
        minWidth: 24,
    },
    iconBtnWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 5,
        paddingRight: 5,
    },
    iconWidth60: {
        width: '60%',
    },
    iconWidth50: {
        width: '50%',
    },
    iconWidth40: {
        width: '40%',
    },
    iconBtnWrapperSeparator: {
        borderRight: '1px solid rgb(227, 230, 232)',
    },
}));

type TProps = {
    geom: Feature,
    selectedLayer: Layer | undefined;
    geomId: number,
    isMapRoute: boolean,
}

export default function ActionButtons({ geom, selectedLayer, geomId, isMapRoute }: TProps) {
    const classes = useStyles();
    const [store, controller] = useMapContext();
    const { t }: TranslateType = useTranslate();
    const dispatch = useDispatch();
    const [hasPlanningEditRight] = useState(
        dispatch(userActions.getRight(RIGHTS.PLANNING_CREATE_EDIT_DELETE)),
    );

    const isPlanningLayer = controller.isPlanningLayer(selectedLayer?.id);
    const iconWidthTableClass = isPlanningLayer ? classes.iconWidth50 : classes.iconWidth60;
    const iconWidthClass = isPlanningLayer ? classes.iconWidth50 : classes.iconWidth40;

    const isPlanningOpen = isPlanningLayer ? selectedLayer?.isPlanningOpen && hasPlanningEditRight : false;

    const pdfDownloadPush = () => {
        const entityId = geom.properties?.entityId;
        const id = typeof entityId === 'string' ? Number(entityId) : entityId;
        window.parent.postMessage(
            {
                func: 'OpenAVModal',
                modal_name: 'TablaKartonClick',
                idoszak_id: id,
            },
            '*',
        );
    };

    const editPlanningData = useCallback(() => {
        if (!selectedLayer) {
            return null;
        }
        const planLayer = store.layering.layers?.find(layer => selectedLayer.id === layer.id);
        if (!isNil(planLayer)) {
            const planId = planLayer?.planId;

            if (!isNil(planId)) {
                const planningEditProps: any = {
                    entityIds: [geom.properties?.entityId],
                    planId,
                    productionYearId: store.filters.productionYearId,
                    mapStore: store,
                    mapController: controller,
                };
                // eslint-disable-next-line no-undefined
                renderModal(PlanningEditModal, planningEditProps, true);
            }
        }
    }, [selectedLayer, store, geom.properties?.entityId, controller]);

    const downloadShape = () => {
        controller.downloadShapefile(geomId, geom.properties);
    };

    const isTableDataDownload = useMemo(() => [BuiltInLayerTypes.CULTIVATION_PERIOD, BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP].includes(selectedLayer?.name as BuiltInLayerTypes), [selectedLayer?.name]);

    return (
        <div className={classes.downloadWrapper}>
            {isTableDataDownload
                ? (
                    <div className={clsx(classes.iconBtnWrapper, iconWidthTableClass, classes.iconBtnWrapperSeparator)}>
                        <Svg style={classes.iconStyle} iconId="icon-file_pdf" width={24} height={24} />
                        <div
                            className={clsx(classes.downloadBtnDiv)}
                            onClick={() => pdfDownloadPush()}
                            onKeyDown={() => pdfDownloadPush()}
                            role="button"
                            aria-hidden="true"
                        >
                            {t('map.layerInfoPanelDownloadTable', 'Download field folder')}
                        </div>
                    </div>
                )
                : null
            }
            {
                isPlanningLayer && isPlanningOpen ? (
                    <div className={clsx(classes.iconBtnWrapper, iconWidthClass, classes.iconBtnWrapperSeparator)}>
                        <Svg style={classes.iconStyle} iconId="icon-edit" width={24} height={24} />
                        <div
                            className={clsx(classes.downloadBtnDiv)}
                            onClick={() => editPlanningData()}
                            onKeyDown={() => editPlanningData()}
                            role="button"
                            aria-hidden="true"
                        >
                            {t('map.planningContextMenuEdit', 'Edit')}
                        </div>
                    </div>
                ) : null
            }
            <div className={clsx(classes.iconBtnWrapper, iconWidthClass)}>
                <Svg style={classes.iconStyle} iconId="icon-download" width={24} height={24} />
                <div
                    className={clsx(classes.downloadBtnDiv)}
                    onClick={() => downloadShape()}
                    onKeyDown={() => downloadShape()}
                    role="button"
                    aria-hidden="true"
                >
                    {t('map.layerInfoPanelDownloadShp', 'Download SHP')}
                </div>
            </div>
        </div>
    );
}
