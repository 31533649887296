import React from 'react';
import { createUseStyles } from 'react-jss';
import { useFormikContext } from 'formik';
import { CROP } from 'shared/src/constants';
import useTranslate from '@i18n/useTranslate';
import Svg from '@baseComponents/Svg';
import SegmentToogle from '@baseComponents/controls/SegmentToogle';
import PlantationForm from '@customComponents/plantation/PlantationForm';
import CropForm from './CropForm';
import PrevPeriodForm from './PrevPeriodForm';
import useLoadCultivationData from './useLoadCultivationData';

const useStyles = createUseStyles((theme: any) => ({
    cropContainer: {
        width: 380,
        height: '100%',
        padding: '40px 30px',
        zIndex: theme.zIndex.modalWindow,
        overflow: 'auto',
        ...theme.verticalScrollbar,
        '& h2': {
            fontFamily: 'Rubik',
            ...theme.firstLetterUppercase,
        },
    },
    expand: {
        cursor: 'pointer',
        height: 30,
        width: 30,
        borderRadius: '50%',
        backgroundColor: theme.color.pearl,
        outline: 'none',
        marginBottom: 15,
    },
    toggle: {
        marginLeft: 3,
        marginTop: 3,
        height: 24,
        width: 24,
    },
    segmentToogle: {
        marginBottom: 5,
        margin: 'auto',
    },
}));

export default function CropData(props) {
    const { close, cultivationPeriodId, periodYearId } = props;
    const { t } = useTranslate();
    const { values, setFieldValue } = useFormikContext();
    const classes = useStyles();

    const { cropTypeList, cultureList } = useLoadCultivationData();

    function handleChangeCropType(value) {
        setFieldValue('cropType', value);
    }

    return (
        <div className={classes.cropContainer}>
            <div className={classes.expand} onClick={close} onKeyDown={close} role="button" tabIndex="0">
                <Svg iconId="icon-chevron_left" style={classes.toggle} />
            </div>
            <h2>
                {t('cropData.cultivationData', 'Cultivation data')}
            </h2>
            <SegmentToogle
                fixedSize
                items={cropTypeList}
                selected={values.cropType}
                onChange={value => handleChangeCropType(value)}
                className={classes.segmentToogle}
            />
            {values.cropType === CROP.TYPE.PLANTATION ? (
                <PlantationForm
                    cultureList={cultureList}
                />
            ) : (
                <CropForm
                    cultureList={cultureList}
                />
            )}
            <PrevPeriodForm cultureList={cultureList} cultivationPeriodId={cultivationPeriodId} periodYearId={periodYearId} />
        </div>
    );
}
