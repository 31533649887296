export const SUBSCRIPTION = {
    TEST_PERIOD_DATE: {
        AVIATOR: new Date('2020-07-01'),
        WORKS: new Date('2021-01-01'),
        CONNECT: new Date('2021-01-01'),
    },
    NAME: {
        CURRENT_WEATHER: 'current_weather',
        WEATHER_HISTORY: 'weather_history',
        WEATHER_FORECAST: 'weather_forecast',
        NDVI_5: 'ndvi_5',
        NDVI_15: 'ndvi_15',
        NDVI_FULL: 'ndvi_full',
        COMPANY_GROUP_YIELD_AND_COST_BENCHMARK: 'company_group_yield_and_cost_benchmark',
        ECONOMY_YEAR_CULTURE_FILTER: 'economy_year_culture_filter',
        COMPANY_YIELD_AND_COST_BENCHMARK: 'company_yield_and_cost_benchmark',
        REGION_FIELDSIZE_PRECIPITATIONQUANTITY_FILTER: 'region_fieldsize_precipitationquantity_filter',
        SEED_YIELD_ANALYSIS: 'seeds_yield_analysis',
        INPUT_MATERIALS_COST_ANALYSIS: 'input_materials_cost_analysis',
        APP_FIELD_WORK_OPERATION: 'app_field_work_operation',
        CONN_MACHINE_INFORMATION: 'conn_machine_information',
    },
    PACKAGE: {
        IS_STANDARD: 1,
    },
    PACKAGE_GROUP: {
        AVIATOR: 1,
        WORKS: 2,
        CONNECT: 3,
    },
    NDVI: {
        NDVI_5: 5,
        NDVI_15: 15,
        NDVI_FULL: 1000000,
    },
    WORKS: 20,
    CONNECT_MACHINE_SYNC: 22,
    CONNECT_CONTRACT_STATUS: {
        VALID: 1,
        PENDING_FOR_CREATE: 4,
    },
    CONNECT_ENTITY_TYPE: {
        COMPANY: 1,
    },
    ADVANCER: 25,
};
