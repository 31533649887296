import { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isEmpty, findLastIndex } from 'lodash';
import { TableFixedFooter } from '@baseComponents/tableNew';
import useTableFixedColumn from '@baseComponents/tableNew/tableParts/useTableFixedColumn';

const useStyles = createUseStyles((theme: any) => ({
    row: {
        display: 'flex',
        ...theme.disableOutline,

        tableLayout: 'auto',
        width: '100%',
        whiteSpace: 'nowrap',
    },
    tableLeft: {
        display: 'flex',
        width: (props: any) => props.leftSideWidth,
        minWidth: (props: any) => props.leftSideWidth,
    },
    tableMiddle: {
        display: 'flex',
        position: 'relative',
    },
    tableRight: {
        display: 'flex',
        justifyContent: 'end',
        width: (props: any) => props.rightSideWidth,
        minWidth: (props: any) => props.rightSideWidth,
    },
    footerWrapper: {
        bottom: 0,
        left: 0,
        backgroundColor: theme.color.fog,
        lineHeight: '34px',
        overflow: 'visible',
        position: 'sticky',
        paddingLeft: (props: any) => props.useDefaultWrapperPadding && 60,
        paddingRight: (props: any) => props.useDefaultWrapperPadding && 80,
        width: '100%',
        //height: 18,
        display: 'inline-flex',
        flexDirection: 'column',
        height: (props: any) => {
            if (props.hasScrollbar && props.isHasSumFooter) {
                return 68;
            }
            if (!props.hasScrollbar && props.isHasSumFooter) {
                return 45;
            }
            return 18;
        },
    },
    bodyCell: {
        height: '100%',
        minHeight: '1px',
    },
    scrollable: {
        position: 'relative',
        overflowX: 'auto',
        overflowY: 'hidden',
        width: '100%',
        whiteSpace: 'nowrap',
        userSelect: 'none',
    },
    tableFixedFooter: {
        marginBottom: (props: any) => props.hasScrollbar ? 5 : 0
    },
}));

export default function TableScrollerFooter(props) {
    const { tableKey, useDefaultWrapperPadding, isLoaded, isHasSumFooter, isBasePageTable, useRowSelection } = props;
    const { leftSideWidth, rightSideWidth, mainColumns, hasScrollbar } = useTableFixedColumn({ isBasePageTable, tableKey, isLoaded, canUseGroupOperation: useRowSelection });

    const componentClasses = useStyles({
        leftSideWidth,
        rightSideWidth,
        useDefaultWrapperPadding,
        hasScrollbar,
        isHasSumFooter,
    } as any);
    const midColRef = useRef(null);

    //scroll - táblázat többi elemével sync
    const handleMidScroll = event => {
        const { scrollLeft } = event.target;
        document.querySelectorAll(`.midCol${tableKey}`).forEach(midColItem => {
            midColItem.scrollLeft = scrollLeft;
        });
    };

    //ha nincs sum footer és nem kell scrollbar
    if ((!hasScrollbar && !isHasSumFooter) || !tableKey) {
        return null;
    }

    return (
        <div className={clsx(componentClasses.footerWrapper, 'footerWrapper')}>
            {isHasSumFooter && (
                <div className={clsx(componentClasses.row, componentClasses.tableFixedFooter)}>
                    <TableFixedFooter tableKey={tableKey} isBasePageTable={isBasePageTable} />
                </div>
            )}
            {hasScrollbar && (
                <div className={clsx(componentClasses.row)}>
                    <div className={componentClasses.tableLeft} />
                    <div id="simpleTableContentScrollerFooter" className={clsx(componentClasses.scrollable, componentClasses.tableMiddle, `midCol${tableKey}`)} ref={midColRef} onScroll={handleMidScroll}>
                        {!isEmpty(mainColumns) && (mainColumns as any)?.map((mnColumn, idx) => (
                            <div
                                key={idx}
                                style={{
                                    width: mnColumn.maxWidth || '100%',
                                    minWidth: mnColumn.minWidth || mnColumn.minWidth,
                                    maxWidth: mnColumn.maxWidth || mnColumn.maxWidth,
                                    marginRight: idx === findLastIndex(mainColumns) ? 30 : 0,
                                    paddingRight: idx === findLastIndex(mainColumns) ? 30 : 0,
                                }}
                                className={componentClasses.bodyCell}
                                draggable="false"
                            />
                        ))}
                    </div>

                    <div className={componentClasses.tableRight} />
                </div>
            )}
        </div>
    );
}
