import clsx from 'clsx';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { FILES } from 'shared/src/constants';
import { fileUtils } from 'shared/src/modules';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    fileContainer: {
        position: 'relative',
        height: 180,
        width: 180,
        '& button': {
            display: 'none',
        },
        '&:hover button': {
            display: 'flex',
        },
    },
    file: {
        position: 'relative',
        height: 180,
        width: 180,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 8,
        overflow: 'hidden',
        color: theme.color.white,
        '&:hover': {
            color: theme.color.jet,
            '& svg': {
                fill: [theme.color.jet, '!important'],
            },
        },
    },
    iconFile: {
        backgroundColor: theme.color.stone,
        color: theme.color.white,
    },
    iconSvg: {
        height: 50,
        width: 50,
        fill: theme.color.white,
    },
    image: {
        alignSelf: 'stretch',
    },
    fileClose: {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        right: 0,
        borderRadius: '50%',
        backgroundColor: theme.color.jet,
        padding: 4,
        border: 'none',
        zIndex: 2,
        '&:hover': {
            backgroundColor: theme.color.destructive,
        },
    },
    fileCloseSvg: {
        width: 12,
        height: 12,
        fill: theme.color.white,
    },
}));

export default function FileCard(props) {
    const { file, onDeleteFile, id } = props;
    const classes = useStyles();

    function getFileType() {
        const fileType = fileUtils.getFileType(file.fileName);
        return fileType;
    }

    function getFileTypeIcon() {
        const fileType = getFileType();
        return fileType ? `icon-file_${fileType}` : 'icon-file_default';
    }

    function getFileClassName() {
        if (file.typeId) {
            return clsx(classes.file, file.typeId !== FILES.TYPE_ID.IMAGE && classes.iconFile);
        }

        const fileType = getFileType();
        return clsx(classes.file, (!file.buffer || fileType !== 'image') && classes.iconFile);
    }

    function displayFile() {
        let fileSrc = '';
        if (file.url && file.typeId === FILES.TYPE_ID.IMAGE) {
            fileSrc = file.url;
        } else {
            const fileType = getFileType();
            if (file.buffer && fileType === 'image') {
                fileSrc = file.buffer;
            }
        }

        if (!isEmpty(fileSrc)) {
            return <img alt="" src={fileSrc} className={classes.image} />;
        }

        return (
            <>
                <Svg
                    iconId={file.typeId === FILES.TYPE_ID.VOICE ? 'icon-file_audio' : getFileTypeIcon()}
                    style={classes.iconSvg}
                />
                <span>{file?.size} {FILES.UNITS.MB}</span>
            </>
        );
    }

    return (
        file.url
            ? (
                <div className={classes.fileContainer}>
                    <button type="button" className={classes.fileClose} onClick={() => onDeleteFile(id)}>
                        <Svg iconId="icon-close" style={classes.fileCloseSvg} />
                    </button>
                    <Link to={file.url} target="_blank" download className={getFileClassName()} key={file.id}>
                        {displayFile()}
                    </Link>
                </div>
            )
            : (
                <div className={classes.fileContainer}>
                    <button type="button" className={classes.fileClose} onClick={() => onDeleteFile(id)}>
                        <Svg iconId="icon-close" style={classes.fileCloseSvg} />
                    </button>
                    <div className={getFileClassName()} key={file.id}>
                        {displayFile()}
                    </div>
                </div>
            )
    );
}
