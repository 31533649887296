import { createUseStyles } from 'react-jss';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isEmpty, head } from 'lodash';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    toggleButton: {
        display: 'flex',
        width: '100%',
        height: 40,
        border: '1px solid',
        borderRadius: 4,
        alignItems: 'center',
        paddingTop: 0,
        paddingBottom: 0,
        opacity: 1,
        ...theme.disableOutline,
        '&:hover': {
            backgroundColor: theme.color.white,
            borderColor: theme.color.gray,
        },
        '&.btn-primary.dropdown-toggle': {
            borderColor: theme.color.gray,
            cursor: 'pointer',
            color: theme.color.jet,
            backgroundColor: theme.color.white,
            boxShadow: theme.shadows.secondary.normal,
            minWidth: 50,
            '&:active': {
                color: theme.color.jet,
                backgroundColor: theme.color.white,
                borderColor: theme.color.main,
            },
            '&:focus': {
                boxShadow: theme.shadows.primary.focus,
                borderColor: theme.color.main,
            },
        },
    },
    toggleIcon: {
        height: 24,
        width: 24,
        marginLeft: 'auto',
    },
    selectedItems: {
        width: '100%',
        textAlign: 'left',
        ...theme.overFlowText,
        fontSize: 15,
        fontWeight: 400,
    },
}));

export default function MultiSelectToggleButton(props) {
    const { isOpened, dataList, isDisabled } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    function getSelectedItems() {
        if (!isEmpty(dataList)) {
            const selectedItems: any = dataList.filter(listItem => listItem.isChecked);
            if (selectedItems.length > 1) {
                return `${selectedItems.length} ${t('multiselect.itemsAreSelected', 'items are selected')}`;
            }
            if (selectedItems.length === 1) {
                return head(selectedItems)?.value;
            }
        }
    }

    return (
        <Dropdown.Toggle className={classes.toggleButton} title={getSelectedItems()} disabled={isDisabled}>
            <span className={classes.selectedItems}>{getSelectedItems()}</span>
            {isOpened
                ? <Svg iconId="icon-arrow_drop_up" style={classes.toggleIcon} />
                : <Svg iconId="icon-arrow_drop_down" style={classes.toggleIcon} />}
        </Dropdown.Toggle>
    );
}
