import { useEffect } from 'react';

export default function useFocusGlobalSearchInput(tableKey) {
    useEffect(() => {
        const globalSearchInput = document.querySelector(`#${tableKey}_globalSearchInput`);
        setTimeout(() => {
            globalSearchInput?.focus({ focusVisible: true });
        }, 0);
    }, [tableKey]);
}
