import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { isEmpty, isNumber } from 'lodash';
import { arrayUtils, dateTimeUtils } from 'shared/src/modules';
import { DATE } from 'shared/src/constants';
import { errorActions, alertActions, dataListsActions } from '@states/actions';
import useTranslate from '@i18n/useTranslate';
import { productionYearService } from '@services';

export default function useNewPeriodYear(props) {
    const { periodYearList, handleCloseClicked, changeSelectedYear, setPeriodYearList, productionPeriodRef } = props;
    const { t } = useTranslate();
    const [formIsDirty, setFormIsDirty] = useState(false);
    const [periodValue, setPeriodValue] = useState();
    const dispatch = useDispatch();
    const periodYears = periodYearList ? periodYearList.map(periodYear => periodYear.year) : [];

    const { values, setFieldValue, handleSubmit, errors } = useFormik({
        initialValues: { calendarYearValue: dateTimeUtils.getDateYearNow() },
        validationSchema: Yup.object({
            calendarYearValue: Yup.number()
                .positive(t('default.requiredFieldsMandatory', 'The marked fields are mandatory..'))
                .moreThan(DATE.MIN_VALUE, t('default.yearMustBeFourCharacters', 'Year must be 4 characters...'))
                .required(t('default.requiredFieldsMandatory', 'The marked fields are mandatory..'))
                .notOneOf(periodYears, t('default.existingData', 'Existing data, failed to save')),
        }),
        validateOnChange: formIsDirty,
        onSubmit: (value, action) => saveNewPeriodYear(value, action),
    });

    useEffect(() => {
        setPeriodValue(getCalculatedPeriodValue(+values?.calendarYearValue));
        if (!isEmpty(productionPeriodRef.current)) {
            productionPeriodRef.current.value = getCalculatedPeriodValue(+values?.calendarYearValue);
        }
    }, [values.calendarYearValue, productionPeriodRef]);

    function getCalculatedPeriodValue(endYear) {
        if (!isNumber(endYear)) {
            return null;
        }
        const startYear = endYear - 1;
        return `${startYear.toString()}/${endYear.toString()}`;
    }

    async function saveNewPeriodYear() {
        try {
            const newPeriodYearId = await productionYearService.setProductionYear(values.calendarYearValue, periodValue);
            if (newPeriodYearId.newProductionYearId) {
                dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved')));
                const newYearobj = { year: values.calendarYearValue, id: newPeriodYearId.newProductionYearId, period: periodValue };
                dispatch(dataListsActions.addNewProductionYear(newYearobj));
                const orderedPeriodYearList = [...periodYearList || [], { year: values.calendarYearValue, key: newPeriodYearId.newProductionYearId, value: periodValue }];
                arrayUtils.sortByParamNumberDesc(orderedPeriodYearList, 'year');
                setPeriodYearList(orderedPeriodYearList);

                changeSelectedYear(newPeriodYearId.newProductionYearId, +values.calendarYearValue);
                handleCloseClicked();
            } else if (newPeriodYearId.isDuplicateError) {
                dispatch(alertActions.errorAlert(t('default.existingData', 'Existing data, failed to save')));
            } else {
                dispatch(alertActions.errorAlert(t('default.failedSave', 'Failed to save')));
            }
        } catch (error) {
            dispatch(errorActions.setError(error, 'Error saving new period year'));
        }
    }

    return {
        setFormIsDirty,
        values,
        setFieldValue,
        handleSubmit,
        errors,
    };
}
