import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import useTranslate from '@i18n/useTranslate';
import { isArray, isEmpty, isFunction, uniqBy, uniqueId } from 'lodash';
import { simpleTableActions, alertActions } from '@states/actions';
import { TableGroup, TableScrollerFooter } from '@baseComponents/tableNew';
import Button from '@baseComponents/buttons/Button';
import CloseButton from '@baseComponents/buttons/CloseButton';
import { LoadSpinner } from '@baseComponents/loadSpinner';
import useTableColumn from '@baseComponents/tableNew/tableColumns/useTableColumn';
import GlobalSearchInput from '@baseComponents/tableNew/tableParts/globalSearchInput/GlobalSearchInput';
import { ModalContentWrapper, ModalHeader, ModalBody, ModalFooter } from '@baseComponents/modals/modalNew';

import useRegistryNumbersModalData from './useRegistryNumbersModalLoadData';
import getAllColumns from './allColumns';

const useStyles = createUseStyles((theme: any) => ({
    modalWrapper: {
        width: 1100,
    },
    headerFunctionLine: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        background: theme.color.white,
        padding: '0 30px',
        marginBottom: 10,
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    tableWrapper: {
        display: 'contents',
        height: '100%',
        width: '100%',
    },
    addSelectedButton: {
        marginRight: 10,
    },
    footerButton: {
        width: 250,
    },
    closeButton: {
        width: 150,
    },
    table: {
        '& .tableHeaderRow': {
            marginRight: 20,
            paddingRight: 10,
        },
        '& .List': {
            marginRight: 20,
            paddingRight: 10,
        },
        '& .table-row': {
            marginRight: 20,
            paddingRight: '10px !important',
        },
        width: '100%',
    },
}));

const TABLE_KEY = uniqueId('registryNumbersModal');

export default function RegistryNumbersModal(props) {
    const { partnerId, partnerType } = props;
    const { onChange, hideModal, isMultiselect, rowFormatFnc = obj => obj, onSelectedValue, selectedRegNums, excludedIds } = props;
    const { t, translater } = useTranslate();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [defaultColumns, setDefaultColumns] = useState();
    const { columns } = useTableColumn({ defaultColumns, isSimpleTable: true });

    const { tableData } = useRegistryNumbersModalData({ partnerId, partnerType, selectedRegNums, excludedIds });

    useEffect(() => {
        const showedColumns = ['addIcon', 'name', 'postCode', 'ownerName', 'sizeOfTheArea', 'ak'];

        const filteredColumns = getAllColumns(t, translater, showedColumns);
        setDefaultColumns(filteredColumns);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAddSelectedButtonClicked(clickedRowData = null) {
        const selectedRegNumIds = dispatch(simpleTableActions.getSelectedRowIds(TABLE_KEY));
        const simpleTableData = dispatch(simpleTableActions.getTableDataList(TABLE_KEY));

        let selectedRegNumsFiltered = simpleTableData?.filter(rowData => selectedRegNumIds?.includes(rowData.id))?.map(registryNumber => (
            rowFormatFnc(registryNumber)
        ));

        if (clickedRowData) {
            selectedRegNumsFiltered = uniqBy([...clickedRowData, ...selectedRegNumsFiltered], element => element.id);
        }

        if (!isEmpty(selectedRegNumsFiltered)) {
            if (isFunction(onChange)) { onChange(selectedRegNumsFiltered); }
            if (isFunction(hideModal)) { hideModal(); }
        } else {
            dispatch(alertActions.warningAlert(t('registryNumbersModal.noRegNumsSelected', 'No registry numbers were selected')));
        }
    }

    function onAddButtonClicked(selectedRegNum) {
        if (!isMultiselect) {
            if (isFunction(onSelectedValue)) {
                onSelectedValue(rowFormatFnc(selectedRegNum));
            }

            if (isFunction(onChange)) {
                onChange(rowFormatFnc(selectedRegNum));
            }

            if (isFunction(hideModal)) {
                hideModal();
            }
        } else {
            onAddSelectedButtonClicked([selectedRegNum]);
        }
    }

    const [tableFunctions] = useState({
        onAddButtonClicked,
    });

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {t('registryNumbersModal.headerLabel', 'Select registry number')}
                <CloseButton onClick={hideModal} />
            </ModalHeader>
            <div className={classes.headerFunctionLine}>
                <GlobalSearchInput tableKey={TABLE_KEY} isSearchButton={false} isSearchIcon />
            </div>
            <ModalBody className={classes.modalBody}>
                <LoadSpinner value={tableData} isAutoDetect />
                <div className={classes.tableWrapper}>
                    <TableGroup
                        tableId={TABLE_KEY}
                        tableData={tableData}
                        tableColumns={columns}
                        isBasePageTable={false}
                        isCollapsible={false}
                        tableGroup={{
                            value: null,
                            text: 'No breakdown',
                        }}
                        className={classes.table}
                        tableFunctions={tableFunctions}
                        useRowSelection={isMultiselect}
                        selectedRowIds={!isEmpty(selectedRegNums) ? selectedRegNums?.map(regNum => regNum.id) : null}
                    />
                </div>
                <TableScrollerFooter tableKey={TABLE_KEY} isLoaded={isArray(tableData)} />
            </ModalBody>
            <ModalFooter>
                {isMultiselect ? (
                    <Button className={clsx(classes.footerButton, classes.addSelectedButton)} onClick={(event) => onAddSelectedButtonClicked()}>
                        {t('default.addSelected', 'Add selected')}
                    </Button>
                ) : null}
                <Button type="secondary" className={classes.closeButton} onClick={hideModal}>
                    {t('default.close', 'Close')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
