import { isEmpty } from 'lodash';
import { createUseStyles } from 'react-jss';
import DatePicker from 'react-datepicker';
import { sessionService } from '@services';
import { dateTimeUtils } from 'shared/src/modules';

const useStyles = createUseStyles((theme: any) => ({
    calendar: {
        '& .react-datepicker__navigation--next': {
            right: 0,
            outline: 'none',
        },
        '& .react-datepicker__navigation--previous': {
            right: 0,
            outline: 'none',
        },
        '& .react-datepicker__day--selected': {
            width: '28px',
            borderRadius: 5,
            backgroundColor: theme.color.main,
            fontWeight: 'bold',
            color: theme.color.white,
        },
        '& .calendar': {
            marginBottom: 10,
        },
        '& .react-datepicker__header__dropdown': {
            display: 'inline-flex',
            paddingBottom: 5,
            marginLeft: 5,
            fontSize: 12,
            '& .react-datepicker__year-dropdown-container, .react-datepicker__month-dropdown-container': {
                paddingLeft: 5,
                paddingRight: 5,
            },
            '& select': {
                WebkitAppearance: 'none',
                MozAppearance: 'none',
                appearance: 'none',
                paddingTop: '5px !important',
                paddingBottom: '5px !important',
                paddingLeft: '5px !important',
                outline: 'none',
                background: 'revert !important',
                boxShadow: theme.shadows.secondary.normal,
                border: 'solid 1px',
                borderColor: theme.color.gray,
                borderRadius: 4,
                height: 32,
            },
        },
    },
}));
export default function Calendar(props) {
    const { date, setDate, setValidDate, minDate, maxDate, filterDate, excludeDates } = props;
    const langCode = sessionService.getLangCode();
    const classes = useStyles();

    function handleChangeDate(dateItem) {
        setDate(dateTimeUtils.getDateObject(dateItem));

        if (setValidDate) {
            const isValidDate = (isEmpty(minDate)
                || (minDate && dateTimeUtils.isAfterDate(dateItem, minDate)))
                || (isEmpty(maxDate)
                || (maxDate && dateTimeUtils.isAfterDate(maxDate, dateItem)));
            setValidDate(isValidDate);
        }
    }

    function getOpenDate() {
        if (date) {
            return date;
        }
        if (maxDate) { return maxDate; }
        if (minDate) { return minDate; }
    }

    function getDate(usingDate) {
        return !isEmpty(usingDate) && dateTimeUtils.isValidDate(usingDate) && usingDate.toDate();
    }

    return (
        <div className={classes.calendar}>
            <DatePicker
                calendarClassName="calendar"
                locale={langCode}
                selected={getDate(date)}
                onChange={dateItem => handleChangeDate(dateItem)}
                minDate={getDate(minDate)}
                maxDate={getDate(maxDate)}
                openToDate={getDate(getOpenDate())}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                dropdownMode="select"
                inline
                filterDate={filterDate}
                excludeDates={excludeDates}
            />
        </div>
    );
}
