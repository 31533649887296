import { RIGHTS, SETTINGS } from '../constants';

export const EMPLOYMENT = {
    MODULE_ID: 8,
    NAME: 'employment',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            //COMPONENT_URL: 'contents/workOperations/menu/WorkOperationsMenu',
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'employment',
            SETTINGS_URL: 'setupcontent=1&menuindex=7',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.employment',
                TRANSLATOR_DEFAULT: 'Employment',
            },
        },
        WORK_OPERATIONS: {
            CONTENT_URL_ID: { workOperations: 2 },
            //COMPONENT_URL: 'contents/workOperations/workOperationsManagement/WorkOperationsManagement',
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'employementWorkOperations',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.employementWorkOperations',
                TRANSLATOR_DEFAULT: 'Employement work operations',
            },
            RIGHTS: [RIGHTS.WORK_OPERATIONS_INTERFACE],
        },
        NEW_WORK_OPERATIONS: {
            CONTENT_URL_ID: { newWorkOperations: 2 },
            COMPONENT_URL: 'contents/workOperations/workOperationsManagement/WorkOperationsManagement',
            EVENT_KEY: 'employementWorkOperations',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.employementWorkOperations',
                TRANSLATOR_DEFAULT: 'Employement work operations',
            },
            RIGHTS: [RIGHTS.WORK_OPERATIONS_INTERFACE],
        },
        TASK_CALENDAR: {
            CONTENT_URL_ID: { taskCalendar: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'taskCalendar',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.taskCalendar',
                TRANSLATOR_DEFAULT: 'Monthly calendar',
            },
            RIGHTS: [RIGHTS.MONTHLY_DISPATCH_INTERFACE],
        },
        TASK_USAGE: {
            CONTENT_URL_ID: { taskUsage: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'activitaskUsageties',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.taskUsage',
                TRANSLATOR_DEFAULT: 'Activity task usageties',
            },
            RIGHTS: [RIGHTS.TASK_USAGE_INTERFACE],
        },
        DAILY_BRIEFING: {
            CONTENT_URL_ID: { dailyBriefing: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'dailyBriefing',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.dailyBriefing',
                TRANSLATOR_DEFAULT: 'Daily briefing',
            },
            RIGHTS: [RIGHTS.DAILY_DISPATCH_INTERFACE],
        },
        CONTRACTED_OPERATION_CONTRACT: {
            CONTENT_URL_ID: { contractedOperationContract: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'contractedOperationContract',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.contractedOperationContract',
                TRANSLATOR_DEFAULT: 'Contracted operation contract',
            },
            RIGHTS: [RIGHTS.CONTRACTED_OPERATION_CONTRACT_INTERFACE],
        },
        MACHINES_TASKS: {
            CONTENT_URL_ID: { machinesTasks: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machinesTasks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machinesTasks',
                TRANSLATOR_DEFAULT: 'Machines tasks',
            },
            RIGHTS: [RIGHTS.MACHINES_TASKS_INTERFACE],
        },
        DETAILED_WORK_TIME: {
            CONTENT_URL_ID: { detailedWorkTime: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'detailedWorkTime',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.detailedWorkTime',
                TRANSLATOR_DEFAULT: 'Detailed work time',
            },
            RIGHTS: [RIGHTS.DETAILED_WORK_TIME_INTERFACE],
        },
        RELEASE_OF_WORK_OPERATION_EDIT: {
            CONTENT_URL_ID: { releaseOfWorkOperationEdit: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'releaseOfWorkOperationEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.releaseOfWorkOperationEdit',
                TRANSLATOR_DEFAULT: 'Release of work operation edit',
            },
            RIGHTS: [RIGHTS.RELEASE_OF_WORK_OPERATION_EDIT],
            CREATE_NEW_SETTINGS: [
                { [SETTINGS.MATERIAL_USE_WITH_EXPENDITURE]: [0] },
            ],
        },
        CREATE_WORK_OPERATION: {
            CONTENT_URL_ID: { createWorkOperation: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'createWorkOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.createWorkOperation',
                TRANSLATOR_DEFAULT: 'Create work operation',
            },
            RIGHTS: [RIGHTS.CREATE_WORK_OPERATIONS],
        },
        FIX_AUXILIARY_OPERATION: {
            CONTENT_URL_ID: { fixAuxiliaryOperation: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'fixAuxiliaryOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.fixAuxiliaryOperation',
                TRANSLATOR_DEFAULT: 'Fix auxiliary operation',
            },
            RIGHTS: [RIGHTS.FIX_AUXILIARY_INTERFACE],
        },
        FIX_AUXILIARY_LABOR: {
            CONTENT_URL_ID: { fixAuxiliaryLabor: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'fixAuxiliaryLabor',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.fixAuxiliaryLabor',
                TRANSLATOR_DEFAULT: 'Fix auxiliary labor',
            },
            RIGHTS: [RIGHTS.FIX_AUXILIARY_INTERFACE],
        },
        SHOW_TOGETHER_USE_AND_RELEASE_OFWORK_OPERATIONS: {
            CONTENT_URL_ID: { showTogetherUseAndReleaseOfWorkOperations: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'showTogetherUseAndReleaseOfWorkOperations',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.showTogetherUseAndReleaseOfWorkOperations',
                TRANSLATOR_DEFAULT: 'Consumption / release deficit',
            },
            RIGHTS: [RIGHTS.SHOW_TOGETHER_USE_AND_RELEASE_OFWORK_OPERATIONS_INTERFACE],
        },
        CONTRACTED_OPERATION_EDIT: {
            CONTENT_URL_ID: { contractedOperationEdit: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'contractedOperationEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.contractedOperationEdit',
                TRANSLATOR_DEFAULT: 'Contracted operation edit',
            },
            RIGHTS: [RIGHTS.CONTRACTED_OPERATION_EDIT],
        },
        PRICE_OF_CONTRACTED_OPERATION: {
            CONTENT_URL_ID: { priceOfContractedOperation: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'priceOfContractedOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.priceOfContractedOperation',
                TRANSLATOR_DEFAULT: 'Sale prices of the products of contracted operation',
            },
            RIGHTS: [RIGHTS.PRICE_OF_CONTRACTED_OPERATION_ACCESS_EDIT],
        },
        FUEL_DISTRIBUTION: {
            CONTENT_URL_ID: { fuelDistribution: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'fuelDistribution',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.fuelDistribution',
                TRANSLATOR_DEFAULT: 'Fuel distribution',
            },
            RIGHTS: [RIGHTS.FUEL_DISTRIBUTION_INTERFACE],
        },
        WORK_OPERATION_MAIN_GROUP: {
            CONTENT_URL_ID: { workOperationMainGroup: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workOperationMainGroup',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workOperationMainGroup',
                TRANSLATOR_DEFAULT: 'Work operation main group',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.WORKOPERATION_MAIN_GROUPS_INTERFACE],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        WORK_OPERATION_GROUPS: {
            CONTENT_URL_ID: { workOperationGroups: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workOperationGroups',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workOperationGroups',
                TRANSLATOR_DEFAULT: 'Work operation groups',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.WORKOPERATION_GROUPS_INTERFACE],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        PRICE_OF_USED_WORK_OPERATIONS_PER_PARTNER: {
            CONTENT_URL_ID: { priceOfUsedWorkOperationsPerPartner: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'priceOfUsedWorkOperationsPerPartner',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.priceOfUsedWorkOperationsPerPartner',
                TRANSLATOR_DEFAULT: 'Price of used work operations per partner',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.WORKOPERATION_COST_PER_PARTNER_INTERFACE],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        WORK_OPERATION_BLOCK: {
            CONTENT_URL_ID: { workOperationBlock: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workOperationBlock',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workOperationBlock',
                TRANSLATOR_DEFAULT: 'Work operation block',
            },
            SETTING_MENU_RIGHTS: [
                RIGHTS.WORKOPERATION_BLOCKS_INTERFACE,
                RIGHTS.WAGE_WORK_CONTRACT_BLOCKS_ACCESS_EDIT,
            ],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        TEMPLATE_FOR_CONTRACTED_OPERATION: {
            CONTENT_URL_ID: { templateForContractedOperation: 21 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'templateForContractedOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.templateForContractedOperation',
                TRANSLATOR_DEFAULT: 'Template for contracted operation',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.WAGE_WORK_CONTRACT_TEMPLATE_ACCESS_EDIT],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        RECEIVED_FLEET_DATA: {
            CONTENT_URL_ID: { receivedFleetData: 22 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'receivedFleetData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.receivedFleetData',
                TRANSLATOR_DEFAULT: 'Received fleet data',
            },
            RIGHTS: [RIGHTS.RECEIVED_FLEET_DATA_INTERFACE],
        },
        PRICE_OF_CONTRACTED_OPERATION_PER_PARTNER: {
            CONTENT_URL_ID: { priceOfContractedOperationPerPartner: 23 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'priceOfContractedOperationPerPartner',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.priceOfContractedOperationPerPartner',
                TRANSLATOR_DEFAULT: 'Price of contracted operation per partner',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.WAGE_WORK_SERVICE_PRICE_PER_PARTNER_INTERFACE],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        WORK_OPERATION_SHIFTS: {
            CONTENT_URL_ID: { workOperationShifts: 24 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workOperationShifts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workOperationShifts',
                TRANSLATOR_DEFAULT: 'Work operations shifts',
            },
            SETTING_MENU_SYSTEM_PERMISSIONS: {
                ADMINISTRATOR: true,
            },
        },
        WORK_OPERATION_EXCEL: {
            CONTENT_URL_ID: { workOperationExcel: 25 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workOperationExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workOperationExcel',
                TRANSLATOR_DEFAULT: 'Work operations excel',
            },
            RIGHTS: [RIGHTS.WORKOPERATION_FROM_EXCEL],
            SETTINGS: [
                SETTINGS.USING_WORKOPERATION_FROM_EXCEL,
            ],
        },
        WORKOPERATION_SUBGROUP: {
            CONTENT_URL_ID: { workOperationSubgroup: 26 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'workOperationSubgroup',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workOperationSubgroup',
                TRANSLATOR_DEFAULT: 'Work operation subgroup',
            },
            SETTINGS: [
                SETTINGS.USING_WORKOPERATION_SUBGROUP,
            ],
            SETTING_MENU_RIGHTS: [
                RIGHTS.WORKOPERATION_SUBGROUP_EDIT_INTERFACE,
            ],
        },
        RECEIVED_FLEET_MOBILE_DATA: {
            CONTENT_URL_ID: { receivedFleetMobileData: 27 },
            COMPONENT_URL: 'contents/workOperations/receivedFleetMobileData/ReceivedFleetMobileData',
            EVENT_KEY: 'receivedFleetMobileData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.receivedFleetDataNew',
                TRANSLATOR_DEFAULT: 'Received fleet data',
            },
            RIGHTS: [RIGHTS.RECEIVED_FLEET_DATA_INTERFACE],
        },
        WORKOPERATION_QUALIFICATION: {
            CONTENT_URL_ID: { workoperationQualification: 28 },
            COMPONENT_URL: 'contents/workOperations/workOperationsQualification/WorkOperationsQualification',
            EVENT_KEY: 'workoperationQualification',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.workoperationQualification',
                TRANSLATOR_DEFAULT: 'Workoperation qualification',
            },
            SETTINGS: [SETTINGS.WORKOPERATION_QUALIFICATION], 
            RIGHTS: [RIGHTS.WORKOPERATION_QUALIFICATION_INTERFACE],
        },        

        //PHP - old php contents - refactored -
        PHP_WORK_OPERATIONS: {
            CONTENT_URL_ID: { phpWorkOperations: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'phpEmployementWorkOperations',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.employementWorkOperations',
                TRANSLATOR_DEFAULT: 'Employement work operations',
            },
            RIGHTS: [RIGHTS.CREATE_ACCOUNTING_SYSTEM_OF_CROP_DRYING],
        },
        PHP_TASK_USAGE: {
            CONTENT_URL_ID: { taskUsage: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'phpActivitaskUsageties',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.taskUsage',
                TRANSLATOR_DEFAULT: 'Activity task usageties',
            },
            RIGHTS: [RIGHTS.TASK_USAGE_INTERFACE],
        },
    },
};
