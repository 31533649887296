import React, { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { TABLE } from 'shared/src/constants';
import { arrayUtils } from 'shared/src/modules/arrayUtils';

import { Money } from '../../../formatters';

const useStyles = createUseStyles((theme: any) => ({
    costPerWeightWrapper: {
        height: '100%',
        width: '100%',
        fontSize: 12,
        fontWeight: 'normal',
        color: theme.color.raven,
        display: 'flex',
        alignItems: 'center',
    },
    costFormatter: {
        lineHeight: `${theme.table.tableRowHeight}px`,
    },
}));

export default function CostPerWeightCell(props) {
    const { value, columnId, secondLineId, unitId, rowData } = props;
    const multilineViewType = useSelector((state: any) => state.basePage.table.multilineViewType);
    const isSelectedCurrencySwitch = useSelector((state: any) => state.company.company.currency.isSelectedCurrencySwitch);
    const isVisibleCurrencySwitch = useSelector((state: any) => state.company.company.currency.isVisibleCurrencySwitch);
    const [unit, setUnit] = useState(null);
    const classes = useStyles();

    //set unit
    useEffect(() => {
        if (unitId && rowData) {
            const columnItem = arrayUtils.getArrayItemByKeyString(rowData, unitId);
            setUnit(columnItem);
        }
    }, [unitId, rowData]);

    function getValue() {
        if (columnId && isSelectedCurrencySwitch && isVisibleCurrencySwitch) {
            return arrayUtils.getArrayItemByKeyString(rowData, `${columnId}SC`);
        }
        return value;
    }

    function getValuePerUnit() {
        if (secondLineId && rowData) {
            let lineId = secondLineId;
            if (isSelectedCurrencySwitch && isVisibleCurrencySwitch) {
                lineId += 'SC';
            }

            return arrayUtils.getArrayItemByKeyString(rowData, lineId);
        }
        return null;
    }

    return (
        <div className={classes.costPerWeightWrapper}>
            {(multilineViewType === TABLE.VIEW_TYPE.SIMPLE || !secondLineId)
                && <Money value={getValue()} perUnit={unit} className={classes.costFormatter} />
            }
            {(multilineViewType === TABLE.VIEW_TYPE.VALUE_PER_UNIT && secondLineId)
                && <Money value={getValuePerUnit()} perUnit={unit} className={classes.costFormatter} />
            }
        </div>
    );
}
