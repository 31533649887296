import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Svg from '@baseComponents/Svg';
import PropTypes from 'prop-types';
import useGlobalSearchInput from './useGlobalSearchInput';

const useStyles = createUseStyles((theme: any) => ({
    globalSearchInputWrapper: {
        display: 'inline-block',
        right: 0,
    },
    searchIconButton: {
        width: 30,
        height: 30,
        padding: 0,
        border: 'none',
        backgroundColor: 'transparent',
        display: 'inline-block',
        ...theme.disableOutline,
        cursor: 'default !important',
    },
    searchIconButtonSvg: {
        width: 30,
        height: 30,
        fill: theme.color.raven,
        verticalAlign: 'top',
    },

    searchInputWrapper: {
        overtflow: 'hidden',
        height: 36,
        display: 'inline-flex',
        verticalAlign: 'top',
        margin: 'auto',
        borderRadius: 4,
        boxShadow: '0 2px 3px 0 rgba(175, 180, 182, 0.15)',
        border: `1px solid ${theme.color.gray}`,
        width: 220,
        transition: 'width 0.5s',
        paddingLeft: 0,
        background: theme.color.white,
        '&:focus-within': {
            boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15)',
            border: `1px solid ${theme.color.main}`,
            color: theme.color.jet,
        },
        overflow: 'hidden',
    },
    searchInput: {
        display: 'inline-block',
        width: '100%',
        border: 'none',
        height: '100%',
        fontSize: 13,
        fontWeight: 'normal',
        outline: 'none',
        background: theme.color.white,
    },

    searchDeleteButton: {
        cursor: 'pointer',
        border: 'none',
        height: '100%',
        borderRadius: 4,
        backgroundColor: theme.color.white,
        '&:focus': {
            outline: 'none',
        },
    },
    searchDeleteIcon: {
        display: 'block',
        height: 15,
        width: 15,
        fill: theme.color.raven,
        verticalAlign: 'middle',
    },

    searchButton: {
        height: '100%',
        marginRight: 5,
        border: 'none',
        width: 36,
        minWidth: 36,
        borderRadius: 4,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        alignSelf: 'center',
        background: theme.color.lilian,
        '&:focus': {
            outline: 'none',
        },
        '&:hover': {
            background: theme.color.pearl,
        },
    },
    searchIcon: {
        height: 20,
        width: 20,
        fill: theme.color.stone,
        objectFit: 'contain',
    },
}));

export default function GlobalSearchInput(props) {
    const { tableKey, className, isSearchButton, isSearchIcon } = props;
    const { t } = useTranslation();
    const searchInput = useRef();
    const classes = useStyles();

    const { showInputDeleteButton, changeGlobalSearchingEnabled, clearSearhValue } = useGlobalSearchInput({ searchInput, tableKey });

    return (
        <div className={clsx(classes.globalSearchInputWrapper, className)}>
            <div className={classes.searchInputWrapper}>
                {isSearchIcon
                    && (
                        <button type="button" className={classes.searchButton} onClick={changeGlobalSearchingEnabled}>
                            <Svg iconId="icon-search" style={classes.searchIcon} />
                        </button>
                    )
                }
                <input id={`${tableKey}_globalSearchInput`} className={classes.searchInput} placeholder={`${t('default.search', 'Search')}...`} ref={searchInput} />
                {showInputDeleteButton
                    && (
                        <button type="button" className={classes.searchDeleteButton} onClick={clearSearhValue} title={t('default.delete', 'Delete')}>
                            <Svg iconId="icon-close" style={classes.searchDeleteIcon} />
                        </button>
                    )
                }
            </div>
            {isSearchButton && (
                <button type="button" className={classes.searchIconButton}>
                    <Svg iconId="icon-search" style={classes.searchIconButtonSvg} />
                </button>)}
        </div>
    );
}

GlobalSearchInput.propTypes = {
    tableKey: PropTypes.string,
    className: PropTypes.string,
    isSearchButton: PropTypes.bool,
    isSearchIcon: PropTypes.bool,
};

GlobalSearchInput.defaultProps = {
    tableKey: null,
    className: null,
    isSearchButton: true,
    isSearchIcon: false,
};
