import React from 'react';
import useTranslate from '@i18n/useTranslate';
import { TABLE } from 'shared/src/constants';

import useTableHeaderSorterHandler from '../../sorter/useTableHeaderSorterHandler';
import TableHeaderSorter from '../../sorter/TableHeaderSorter';
import DropDownSorterList from './DropDownSorterList';

export default function TableHeaderColumnDropDown(props) {
    const { column, setShow, className } = props;
    const { isBasePageTable, tableKey } = props; //simple table
    const { columnId } = column;
    const { t } = useTranslate();

    const { sortingType, onClickSorterIcon, onClickDeleteAllSorter } = useTableHeaderSorterHandler({ columnId, isBasePageTable, tableKey });

    function onMenuItemClick(event) {
        event.stopPropagation();
        onClickSorterIcon();
    }

    function onClickDeleteSorters(event) {
        event.preventDefault();
        event.stopPropagation();

        if (setShow) {
            setShow(false);
        }
        onClickDeleteAllSorter();
    }

    return (
        <>
            <DropDownSorterList
                onClickDeleteAllSorter={onClickDeleteSorters}
                tableKey={tableKey}
                isBasePageTable={isBasePageTable}
            />

            {sortingType === TABLE.SORTING.NONE
                && (
                <div className={className.itemButton} onClick={onMenuItemClick} onKeyDown={onMenuItemClick} role="button" tabIndex="0">
                    <TableHeaderSorter
                        column={column}
                        showSortingIcon
                        className={className.itemButtonBeforIcon}
                        tableKey={tableKey}
                        isBasePageTable={isBasePageTable}
                    />
                    <div className={className.itemButtonText}>{t('tableDropDown.sortBy', 'Sort by')}</div>
                </div>
                )}
        </>
    );
}
