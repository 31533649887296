import { createUseStyles } from 'react-jss';
import { Dropdown } from 'react-bootstrap';
import clsx from 'clsx';

const useStyles = createUseStyles({
    dropdownMenuWrapper: {
        borderRadius: '6px !important',
        padding: 0,
    },
});

export default function DropdownMenu(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <Dropdown.Menu className={clsx(classes.dropdownMenuWrapper, className)}>
            {children}
        </Dropdown.Menu>
    );
}
