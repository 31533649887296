/* eslint-disable no-restricted-imports */
import Button from '@baseComponents/buttons/Button';
import Checkbox from '@baseComponents/controls/Checkbox';
import { OpenModalButton } from '@baseComponents/modals';
import { SimpleSelect } from '@baseComponents/select';
import { FormRangeCalendar } from '@customComponents/datePickers';
import PeriodYearDropdown from '@customComponents/dropdowns/periodYearDropdown/PeriodYearDropdown';
import FormGroup from '@customComponents/form/FormGroup';
import FarmModal from '@customComponents/modals/farmModal/FarmModal';
import PartnersModal from '@customComponents/modals/partnersModal/PartnersModal';
import useTranslate from '@i18n/useTranslate';
import LayerSelectorModal, { LAYER_TYPES } from '@map/components/LayerSelectorModal/LayerSelectorModal';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { ThemeType, TranslateType } from '@map/services/mapTypes';
import { isArray, isEmpty } from 'lodash';
import { useState } from 'react';
import ReactDOM from 'react-dom';
import { createUseStyles } from 'react-jss';
import { LayerModalBottomBar, LayerModalContainer, LayerModalContent, LayerModalHeader } from '../LayerModal.styles';
import useLayerExportModalData from './useLayerExportModalData';

type Classes = 'formGroup'

const useStyles = createUseStyles<Classes, ThemeType>(theme => ({
    formGroup: {
        display: 'flex',
        flexFlow: 'column',
        // height: '100%',
        marginBottom: 12,
        margin: 0,
    },
}));

type TProps = {
    setIsExportWindowOpen: (isOpen: boolean) => void,
}

export default function LayerExportModal({ setIsExportWindowOpen }: TProps) {
    const { t, translater }: TranslateType = useTranslate();

    const classes = useStyles();

    const dataProps = useLayerExportModalData();
    const {
        layerName, layerId, selectableLayers, isFarmDisabled, usingFarm, isCustomLayer, productionYearId, startDate, endDate, isProductionYearDisabled, isDownloadDisabled,
        setStartDate, setEndDate, setProductionYearId, setLayerId, setLayerName, onDownload,
    } = dataProps;

    const { periodDataList, companyIds, companyNames, farmIds, farmNames, showClosedPeriods, setCompanyIds, setCompanyNames, setFarmIds, setFarmNames, setShowClosedPeriods } = dataProps;

    const onClose = () => {
        setIsExportWindowOpen(false);
    };

    return ReactDOM.createPortal(
        <>
            <div className="modal-backdrop show" />
            <LayerModalContainer width={400} height="fit-content">
                <LayerModalHeader
                    title={translater<string>('map.exportLayerText', 'Export layer')}
                    close={onClose}
                />
                <LayerModalContent height="fit-content">
                    <FormGroup className={classes.formGroup} label={t('map.layerType', 'Layer Type')} required hasValue={layerId > 0}>
                        <OpenModalButton
                            text={layerName}
                            currentSelectedValue={layerId}
                            hasDeleteButton
                            modalComponentProps={{
                                isMultiselect: false,
                                tableData: selectableLayers,
                                showLayerType: false,
                                allowedLayerTypes: LAYER_TYPES.AGROVIR,
                            }}
                            modalComponent={LayerSelectorModal}
                            id={layerId}
                            name={layerName}
                            onChange={layer => {
                                const layerNameTemp = layer?.layerName;
                                setLayerName(layerNameTemp);
                                setLayerId(layer?.id);
                            }}
                            onDelete={() => {
                                setLayerId(-1);
                                setLayerName(null);
                            }}
                        />
                    </FormGroup>

                    {
                        layerName !== BuiltInLayerTypes.CULTIVATION_PERIOD ? null : (
                            <FormGroup className={classes.formGroup}>
                                <Checkbox text={t('cultivationPeriods.showClosedPeriod', 'Show closed periods')} checked={showClosedPeriods} setChecked={setShowClosedPeriods} />
                            </FormGroup>
                        )
                    }

                    <FormGroup
                        className={classes.formGroup}
                        label={t('map.productionYear', 'Production Year')}
                        required={!isProductionYearDisabled || isCustomLayer}
                        hasValue={isCustomLayer ? (startDate && endDate) : productionYearId > 0}
                    >
                        <SimpleSelect
                            setSelected={setProductionYearId}
                            disabled={isProductionYearDisabled}
                            dataList={periodDataList}
                        />
                        {/* <FormRangeCalendar
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        /> */}
                    </FormGroup>

                    {!usingFarm ? null : (<FormGroup className={classes.formGroup} label={t('map.farm', 'Farm')}>
                        <OpenModalButton
                            isDisabled={isFarmDisabled}
                            hasDeleteButton
                            currentSelectedValue={farmIds}
                            modalComponentProps={{
                                isMultiselect: true,
                                ignoreDefaultSelection: true,
                            }}
                            modalComponent={FarmModal}
                            id={farmIds}
                            name={farmNames}
                            onChange={farms => {
                                const selectedFarms = isArray(farms) ? farms : [farms];
                                const farmIdsSelected = selectedFarms?.map(farm => farm?.id);

                                if (!isEmpty(farmIdsSelected)) {
                                    const farmNameLabel = selectedFarms?.map(farm => farm?.farmName)?.join(',');
                                    setFarmNames(farmNameLabel);
                                    setFarmIds(farmIdsSelected);
                                }
                            }}
                            onDelete={() => {
                                setFarmIds([]);
                                setFarmNames(null);
                            }}
                        />
                    </FormGroup> )}

                    <FormGroup className={classes.formGroup} label={t('map.layerLpisFieldCompanyName', 'Company')} required={isCustomLayer} hasValue={!isEmpty(companyIds)}>
                        <OpenModalButton
                            hasDeleteButton
                            modalComponentProps={{
                                isMultiselect: true,
                                ignoreDefaultSelection: true,
                                hasDeleteButton: true,
                                defaultPartnerType: 2,
                            }}
                            modalComponent={PartnersModal}
                            id={companyIds}
                            name={companyNames}
                            onChange={companies => {
                                const selectedCompanies = isArray(companies) ? companies : [companies];
                                const companyIdsSelected = selectedCompanies?.map(company => company?.id);

                                if (!isEmpty(companyIdsSelected)) {
                                    const companyNameLabel = selectedCompanies?.map(company => company?.name)?.join(',');
                                    setCompanyNames(companyNameLabel);
                                    setCompanyIds(companyIdsSelected);
                                }
                            }}
                            onDelete={() => {
                                setCompanyIds([]);
                                setCompanyNames(null);
                            }}
                        />
                    </FormGroup>
                </LayerModalContent>
                <LayerModalBottomBar justifyContent="center">
                    <Button
                        disabled={isDownloadDisabled}
                        title={t('map.layerInfoPanelDownloadShp', 'Download SHP')}
                        onClick={() => onDownload()}
                    >
                        {t('map.layerInfoPanelDownloadShp', 'Download SHP')}
                    </Button>
                </LayerModalBottomBar>
            </LayerModalContainer>
        </>,
        document.getElementById('portal-root') as Element,
    );
}
