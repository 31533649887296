import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { errorActions } from '@states/actions';
import { cultivationTableService } from '@services';
import { arrayUtils } from 'shared/src/modules';
import { isEmpty } from 'lodash';

export default function useBasicFieldsModalLoadData(props) {
    const { periodYearId, partnerId, excludedIds } = props;
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                setIsLoading(true);
                let cultivationTables = await cultivationTableService.getCultivationTables(periodYearId, partnerId);
                cultivationTables = cultivationTables?.map(cultivationTableObj => ({
                    ...cultivationTableObj,
                    companyName: arrayUtils.getArrayItemByKeyString(cultivationTableObj, 'cultivationTableCompany.partner.name'),
                }))?.filter(table => (!isEmpty(excludedIds) ? !excludedIds?.includes(table?.id) : true));
                if (!isCancelled) {
                    setTableData(cultivationTables);
                    setIsLoading(false);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
                setIsLoading(false);
            }
        }
        if (dispatch) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [dispatch, location, periodYearId, partnerId, excludedIds]);

    return {
        tableData,
        isLoading,
    };
}
