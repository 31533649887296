import { createUseStyles } from 'react-jss';
import { UNITS } from 'shared/src/constants';
import { useNumberFormat } from '@common_modules/numberFormat';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        height: 18,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        color: theme.color.jet,
    },
}));

export default function Dose(props) {
    const { value, unit, style, cellId } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const { numberFormatter } = useNumberFormat();

    return (
        <div id={cellId} className={clsx(classes.baseStyle, style)}>
            {numberFormatter.getFormattedDose(value)} {unit}/{t('default.areaUnit', UNITS.DEFAULT_AREA)}
        </div>
    );
}
