import React from 'react';
import { Helmet } from 'react-helmet';
import { DEFAULT_SYSTEM_DATA } from 'shared/src/constants';
import useSiteHelmetTitle from './useSiteHelmetTitle';

export default function SiteHelmet(props) {
    const { routeList } = props;
    const { pageTitle } = useSiteHelmetTitle({ routeList });

    return (
        <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={DEFAULT_SYSTEM_DATA.SITE_DATA.DESCRIPTION} />
            <meta charset={DEFAULT_SYSTEM_DATA.SITE_DATA.CHARSET} />
        </Helmet>
    );
}
