import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isString } from 'lodash';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    iconStyle: {
        width: 32,
        height: 32,
        marginRight: 4,
        fill: theme.color.destructive,
    },
    errorMessage: {
        display: (props: any) => props.iconId && 'flex',
        alignItems: (props: any) => props.iconId && 'center',
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.67,
        color: (props: any) => (!props.iconId ? theme.color.destructive : theme.color.yet),
    },
}));

export default function ErrorMessage(props) {
    const { invalid, errorMessage, className, iconId } = props;
    const classes = useStyles({ iconId } as any);

    if (!invalid && !errorMessage && !isString(errorMessage)) {
        return null;
    }

    return (
        invalid && errorMessage ? (
            <div className={clsx(classes.errorMessage, className)}>
                {iconId && (
                    <Svg style={classes.iconStyle} iconId={iconId} />
                )}
                {errorMessage}
            </div>
        ) : null
    );
}
