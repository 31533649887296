import { numberUtils, arrayUtils, languageUtils, dateTimeUtils, stringUtils } from 'shared/src/modules';
import { TABLE, UNITS } from 'shared/src/constants';
import { isNull, isEmpty, isArray, isObject, isNumber } from 'lodash';
import { sessionService } from '@services';

export const tableUtils = {
    isMultilineCell,
    isDataPerHectare,
    getUnitToColumnTitle,
    getDataPerHectare,
    sortDataArrayByType,
    sortDataSubArrayByType,
    getGroupNameFilterResult,
    isContainFilterValue,
    getColumnValueFromLangCaption,
    getFormattedColumnValue,
    getColumnIdByViewType,
};

function isMultilineCell(column) {
    if (column.hasSecondViewType && column.componentProps && column.componentProps.secondLineId) {
        return true;
    }

    if (column.componentProps && (column.componentProps.secondLineId || column.componentProps.areaId)) {
        return true;
    }

    return false;
}

function isDataPerHectare(columnObject) {
    if (columnObject && columnObject.componentProps && columnObject.componentProps.areaId) {
        return true;
    }

    return false;
}

function getUnitToColumnTitle(t, columnObject, currencyText, isDataPerHectareUnit = false) {
    if (!columnObject || !columnObject.dataType || !currencyText) {
        return null;
    }

    switch (columnObject.dataType) {
        case TABLE.DATA_TYPE.AREA:
            return `(${t('default.areUnit', UNITS.DEFAULT_AREA)})`;
        case TABLE.DATA_TYPE.WEIGHT:
            return `(${t('default.weight', UNITS.DEFAULT_WEIGHT)})`;
        case TABLE.DATA_TYPE.COST:
            return `(${currencyText})`;

        case TABLE.DATA_TYPE.COST_PER_AREA:
            if (isDataPerHectareUnit) {
                return `(${currencyText}/${t('default.areaUnit', UNITS.DEFAULT_AREA)})`;
            }
            return `(${currencyText})`;
        case TABLE.DATA_TYPE.WEIGHT_PER_AREA:
            if (isDataPerHectareUnit) {
                return `(${t('default.weight', UNITS.DEFAULT_WEIGHT)}/${t('default.areUnit', UNITS.DEFAULT_AREA)})`;
            }
            return `(${t('default.weight', UNITS.DEFAULT_WEIGHT)})`;
        case TABLE.DATA_TYPE.COST_PER_WEIGHT:
            if (isDataPerHectareUnit) {
                return `(${currencyText}/${t('default.weight', UNITS.DEFAULT_WEIGHT)})`;
            }
            return `(${currencyText})`;
        case TABLE.DATA_TYPE.QUANTITY:
            if (isDataPerHectareUnit) {
                return `(/${t('default.areUnit', UNITS.DEFAULT_AREA)})`;
            }
            return null;
        default: //TEXT
            return null;
    }
}

//temp function - for old table
function getDataPerHectare(dataItem, areaItem) {
    if (numberUtils.isNumber(dataItem) && numberUtils.isNumber(areaItem)) {
        if (isEmpty(areaItem)) {
            return dataItem;
        }
        return dataItem / areaItem;
    }
    return 0;
}

function sortDataArrayByType(dataArray, columnObject, sortType, lineViewType) {
    if (columnObject) {
        const { searchAndSortType } = columnObject;
        const sortedColumnId = getColumnIdByViewType(columnObject, lineViewType);

        switch (searchAndSortType) {
            case TABLE.SEARCH_TYPE.STRING: //string
                if (sortType === TABLE.SORTING.ASC) {
                    arrayUtils.sortByParamAsc(dataArray, sortedColumnId);
                } else if (sortType === TABLE.SORTING.DESC) {
                    arrayUtils.sortByParamDesc(dataArray, sortedColumnId);
                }
                break;
            case TABLE.SEARCH_TYPE.NUMBER: //number
                if (sortType === TABLE.SORTING.ASC) {
                    arrayUtils.sortByParamNumberAsc(dataArray, sortedColumnId);
                } else if (sortType === TABLE.SORTING.DESC) {
                    arrayUtils.sortByParamNumberDesc(dataArray, sortedColumnId);
                }
                break;
            case TABLE.SEARCH_TYPE.DATE: //date
                if (sortType === TABLE.SORTING.ASC) {
                    arrayUtils.sortDateByParamAsc(dataArray, sortedColumnId);
                } else if (sortType === TABLE.SORTING.DESC) {
                    arrayUtils.sortDateByParamDesc(dataArray, sortedColumnId);
                }
                break;
            case TABLE.SEARCH_TYPE.BOOLEAN: //boolean
                if (sortType === TABLE.SORTING.ASC) {
                    arrayUtils.sortBooleanParamAsc(dataArray, sortedColumnId);
                } else if (sortType === TABLE.SORTING.DESC) {
                    arrayUtils.sortBooleanParamDesc(dataArray, sortedColumnId);
                }
                break;
            default: //string
                if (sortType === TABLE.SORTING.ASC) {
                    arrayUtils.sortByParamAsc(dataArray, sortedColumnId);
                } else if (sortType === TABLE.SORTING.DESC) {
                    arrayUtils.sortByParamDesc(dataArray, sortedColumnId);
                }
                break;
        }
    }
}

function sortDataSubArrayByType(dataArray, searchAndSortType, sortType, param, key, itemIndex) {
    if (searchAndSortType) {
        switch (searchAndSortType) {
            case TABLE.SEARCH_TYPE.STRING: //string
                if (sortType === TABLE.SORTING.ASC) {
                    arrayUtils.sortBySubArrayItemParamAsc(dataArray, param, key, itemIndex);
                } else if (sortType === TABLE.SORTING.DESC) {
                    arrayUtils.sortBySubArrayItemParamDesc(dataArray, param, key, itemIndex);
                }
                break;
            case TABLE.SEARCH_TYPE.NUMBER: //number
                if (sortType === TABLE.SORTING.ASC) {
                    arrayUtils.sortBySubArrayParamNumberAsc(dataArray, param, key, itemIndex);
                } else if (sortType === TABLE.SORTING.DESC) {
                    arrayUtils.sortBySubArrayParamNumberDesc(dataArray, param, key, itemIndex);
                }
                break;
            case TABLE.SEARCH_TYPE.DATE: //date
                if (sortType === TABLE.SORTING.ASC) {
                    arrayUtils.sortDateByParamAsc(dataArray, param);
                } else if (sortType === TABLE.SORTING.DESC) {
                    arrayUtils.sortDateByParamDesc(dataArray, param);
                }
                break;
            default: //string
                if (sortType === TABLE.SORTING.ASC) {
                    arrayUtils.sortBySubArrayItemParamAsc(dataArray, param, key, itemIndex);
                } else if (sortType === TABLE.SORTING.DESC) {
                    arrayUtils.sortBySubArrayItemParamDesc(dataArray, param, key, itemIndex);
                }
                break;
        }
    }
}

function getGroupNameFilterResult(rowData, searchValue, selectedGroupColumnId) {
    if (isEmpty(searchValue) || isNull(selectedGroupColumnId) || isEmpty(searchValue)) {
        return true;
    }

    let columnValue = arrayUtils.getArrayItemByKeyString(rowData, selectedGroupColumnId);
    if (columnValue || numberUtils.isNumber(columnValue)) {
        columnValue = columnValue.toString().toLowerCase();
        const searchValueLowerCase = searchValue.toLowerCase();
        return columnValue.includes(searchValueLowerCase);
    }

    return false;
}

function isNotEmptyOrNumber(searchValue) {
    return !isEmpty(searchValue) || (numberUtils.isNumber(searchValue) && !isNull(searchValue));
}

//check cell include the filter values
function isContainFilterValue(rowData, column, columnId, columnSearchValue, currentCurrencyDecimalNumber, currentCurrencyUnitPriceDecimalNumber, isGlobalSearching, isNonAccentSearch) {
    const { searchAndSortType, searchColumnId } = column || {};
    const columnValue = arrayUtils.getArrayItemByKeyString(rowData, searchColumnId || columnId);

    //multiselect filtering
    if (searchAndSortType === TABLE.SEARCH_TYPE.MULTISELECT && !isGlobalSearching) {
        let includeSearchValue = true;

        if (isArray(columnValue) && isArray(columnSearchValue) && !isEmpty(columnSearchValue)) { //tömb szűrése id tömb alapján
            includeSearchValue = columnValue.some((columnObj: any) => (isObject(columnObj) ? columnSearchValue.includes((columnObj as any).id) : columnSearchValue.find(searchValue => searchValue === columnObj)));
        } else if (!isArray(columnValue) && isArray(columnSearchValue) && !isEmpty(columnSearchValue)) { //oszlop érték szűrése id tömb alapján
            includeSearchValue = columnSearchValue.some(searchValue => searchValue === columnValue);
        }

        return includeSearchValue;
    }

    //boolean filtering
    if (searchAndSortType === TABLE.SEARCH_TYPE.BOOLEAN && !isGlobalSearching && !isNull(columnSearchValue)) {
        return numberUtils.getBoolean(columnSearchValue) === numberUtils.getBoolean(columnValue);
    }

    const formattedColumnValue = tableUtils.getFormattedColumnValue(columnValue, column, currentCurrencyDecimalNumber, currentCurrencyUnitPriceDecimalNumber); //formatted and lower case
    const unitText = column?.componentProps?.unitId ? arrayUtils.getArrayItemByKeyString(rowData, column.componentProps.unitId) : null;
    let includeSearchValue = false;
    let includeValueInUnit = false;

    if (unitText) {
        includeValueInUnit = stringUtils.includesNoCase(unitText, columnSearchValue, isNonAccentSearch);
    }

    //return if empty column
    if (!isNotEmptyOrNumber(columnValue) && !includeValueInUnit) {
        return includeSearchValue;
    }

    //check column contain column search value
    if (isNotEmptyOrNumber(columnSearchValue) && isNotEmptyOrNumber(formattedColumnValue)) {
        includeSearchValue = stringUtils.includesNoCase(formattedColumnValue, columnSearchValue, isNonAccentSearch);
    }

    return includeSearchValue || includeValueInUnit;
}

function getFormattedColumnValue(columnValue, column, currentCurrencyDecimalNumber, currentCurrencyUnitPriceDecimalNumber) {
    const { componentProps, searchAndSortType, dataType } = column;
    let formattedColumnValue = columnValue;

    //lang caption
    if (componentProps?.hasLangCaption && (formattedColumnValue || isNumber(formattedColumnValue))) {
        formattedColumnValue = tableUtils.getColumnValueFromLangCaption(formattedColumnValue);
    }

    switch (searchAndSortType) {
        case TABLE.SEARCH_TYPE.STRING:
            formattedColumnValue = formattedColumnValue || '';
            break;
        case TABLE.SEARCH_TYPE.NUMBER:
            if (dataType && (dataType === TABLE.DATA_TYPE.COST)) {
                formattedColumnValue = getColumnValueNumberNumberFormat(formattedColumnValue, currentCurrencyDecimalNumber);
            } else if (dataType && (dataType === TABLE.DATA_TYPE.COST_PER_AREA || dataType === TABLE.DATA_TYPE.COST_PER_WEIGHT)) {
                formattedColumnValue = getColumnValueNumberNumberFormat(formattedColumnValue, currentCurrencyUnitPriceDecimalNumber);
            } else if (dataType && (dataType === TABLE.DATA_TYPE.WEIGHT || dataType === TABLE.DATA_TYPE.WEIGHT_PER_AREA)) {
                formattedColumnValue = getColumnValueNumberNumberFormat(formattedColumnValue, UNITS.DECIMAL_NUMBER.WEIGHT);
            } else {
                formattedColumnValue = getColumnValueNumberNumberFormat(formattedColumnValue, null);
            }
            break;
        case TABLE.SEARCH_TYPE.DATE:
            formattedColumnValue = getColumnValueDateFormat(formattedColumnValue);
            break;
        default:
            formattedColumnValue = formattedColumnValue || '';
            break;
    }

    if (!isEmpty(formattedColumnValue) || (numberUtils.isNumber(formattedColumnValue) && !isNull(formattedColumnValue))) {
        formattedColumnValue = formattedColumnValue.toString().toLowerCase();
    }

    return formattedColumnValue;
}

function getColumnValueFromLangCaption(columnValueObj) {
    const language = sessionService.getLangCode();
    return languageUtils.getLangCaptionFromObject(language, columnValueObj);
}

function getColumnValueDateFormat(columnValue) {
    return columnValue ? dateTimeUtils.getDate(columnValue) : null;
}

function getColumnValueNumberNumberFormat(columnValue, decimalNumber) {
    if (numberUtils.isNumber(columnValue) && (numberUtils.isFloat(columnValue) || numberUtils.isInteger(columnValue))) {
        return numberUtils.getAreaFormatWithDecimalPoint(columnValue, decimalNumber);
    }

    return 0;
}

function getColumnIdByViewType(column, viewType) {
    if (column.hasSecondViewType && viewType === TABLE.VIEW_TYPE.VALUE_PER_UNIT && column?.componentProps?.secondLineId) { //show second line
        return column.componentProps.secondLineId;
    }
    return column.columnId;
}
