import React, { useMemo } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

import useRowSelection from '../hooks/useRowSelection';

const useStyle = createUseStyles({
    bodyCell: {
        width: props => props.width || '100%',
        minWidth: props => props.minWidth,
        maxWidth: props => props.maxWidth,
        height: '100%',
        verticalAlign: 'top',
        padding: 0,
        paddingLeft: 10,
        overflow: 'visible',
    },
    hideOverflow: {
        overflow: 'hidden',
    },
    hiddenSelectRowCell: {
        display: 'none',
    },
    isExcludedRow: {
        opacity: 0.4,
    },
});

export default function TableBodyCell(props) {
    const { column, cellValue, rowData, cellWidth } = props;
    const { width, componentProps, componentFunctions, isSelectRowColumn, minWidth, maxWidth, columnId, isShowOverflow, unitPriceDecimals, type } = column; //column data
    const { isBasePageTable } = props; //table type
    const classes = useStyle({ width: cellWidth || width, minWidth: cellWidth || minWidth, maxWidth: cellWidth || maxWidth });
    const { tableFunctions } = props; //table functions

    const { isShowSelectColumn } = useRowSelection({ isSelectRowColumn }); //group operation hook

    const isColumnExcludable = useMemo(() => !((type || '').toLowerCase().includes('button')), [type]);

    function getClassName() {
        if (isSelectRowColumn && !isShowSelectColumn) {
            return clsx(classes.bodyCell, (!isShowOverflow && classes.hideOverflow), classes.hiddenSelectRowCell, (rowData?.isExcluded && isColumnExcludable && classes.isExcludedRow));
        }
        return clsx(classes.bodyCell, (!isShowOverflow && classes.hideOverflow), (rowData?.isExcluded && isColumnExcludable && classes.isExcludedRow));
    }

    return (
        <div className={getClassName()} draggable="false">
            <column.component
                {...componentProps}
                {...componentFunctions}
                {...tableFunctions}
                value={cellValue}
                id={rowData?.id}
                rowData={rowData}
                columnId={columnId}
                isBasePageTable={isBasePageTable}
                unitPriceDecimals={unitPriceDecimals}
            />
        </div>
    );
}
