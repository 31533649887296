import { useEffect, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { isEmpty, isArray, isUndefined } from 'lodash';
import '@styles/components/pageloader/pageloader.css';

const useStyles = createUseStyles((theme: any) => ({
    pageLoaderWrapper: {
        display: (props: any) => (props.isAutoDetect ? 'flex' : 'none'),
        zIndex: (props: any) => !props.isAutoDetect && theme.zIndex.loadSpinner,
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        background: 'transparent',
    },
    fader: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: theme.color.white,
        opacity: 0.3,
        zIndex: 1,
    },
    spinnerWrapper: {
        height: 'auto',
        width: '100%',
        margin: 'auto',
        alignItems: 'center',
        zIndex: 2,
        display: 'block',
    },
    spinnerIcon: {
        display: 'block',
        margin: 'auto',
        maxWidth: 55,
        maxHeight: 55,
        minWidth: 15,
        minHeight: 15,
        width: '10%',
    },
    pageLoaderSpinnerText: {
        marginTop: 10,
        display: 'block',
        fontSize: 15,
        fontWeight: 500,
        color: theme.color.black,
        textTransform: 'uppercase',
        textAlign: 'center',
    },
}));

export default function LoadSpinner(props) {
    const { reference, value, someValues, everyValues, orValue, isAutoDetect } = props;
    const { t } = useTranslation();
    const spinnerRef = useRef<any>();
    const classes = useStyles({ isAutoDetect });

    //value auto detect
    useEffect(() => {
        function isLoadedData() {
            return (!isUndefined(value) && value !== false)
                || (isArray(someValues) && someValues?.some(valueItem => !isUndefined(valueItem) && valueItem !== false))
                || (isArray(everyValues) && everyValues?.every(valueItem => !isUndefined(valueItem) && valueItem !== false))
                || (!isUndefined(orValue) && orValue !== false);
        }

        if (!isEmpty(spinnerRef?.current)) {
            const spinnerDisplay = !isEmpty(spinnerRef.current.style.display) ? spinnerRef.current.style.display : 'none';
            if (isLoadedData() && spinnerDisplay === 'flex') { //is loaded data
                spinnerRef.current.style.display = 'none';
            } else if (!isLoadedData() && spinnerDisplay === 'none') { //loading in progress
                spinnerRef.current.style.display = 'flex';
            } else {
                spinnerRef.current.style.display = 'none';
            }
        }
    }, [value, someValues, everyValues, orValue, spinnerRef]);

    return (
        <div id="loadSpinner" className={classes.pageLoaderWrapper} ref={reference || spinnerRef}>
            <div className={classes.fader} />
            <div className={classes.spinnerWrapper}>
                <img alt="" src="/styles/img/page_loading.gif" className={classes.spinnerIcon} />
                {!isAutoDetect
                    && (
                    <span className={classes.pageLoaderSpinnerText}>
                        {t && (`${t('default.loading', 'Loading')}...`)}
                    </span>
                    )
                }
            </div>
        </div>
    );
}
