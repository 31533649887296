import { simpleAction } from './baseActions';

const inventoryTransferRequestActions = {
    getInventoryTransferRequestFilters,
    getInventoryTransferList,
    GET_INVENTORY_TRANSFER_REQUEST_FILTERS_REQUESTED: 'GET_INVENTORY_TRANSFER_REQUEST_FILTERS_REQUESTED',
    GET_INVENTORY_TRANSFER_REQUEST_FILTERS_SUCCEEDED: 'GET_INVENTORY_TRANSFER_REQUEST_FILTERS_SUCCEEDED',
    GET_INVENTORY_TRANSFER_REQUEST_FILTERS_FAILED: 'GET_INVENTORY_TRANSFER_REQUEST_FILTERS_FAILED',
    GET_INVENTORY_TRANSFER_REQUEST_REQUESTED: 'GET_INVENTORY_TRANSFER_REQUEST_REQUESTED',
    GET_INVENTORY_TRANSFER_REQUEST_SUCCEEDED: 'GET_INVENTORY_TRANSFER_REQUEST_SUCCEEDED',
    GET_INVENTORY_TRANSFER_REQUEST_FAILED: 'GET_INVENTORY_TRANSFER_REQUEST_FAILED',
};

export { inventoryTransferRequestActions };

function getInventoryTransferRequestFilters() {
    return simpleAction(inventoryTransferRequestActions.GET_INVENTORY_TRANSFER_REQUEST_FILTERS_REQUESTED);
}

function getInventoryTransferList() {
    return simpleAction(inventoryTransferRequestActions.GET_INVENTORY_TRANSFER_REQUEST_REQUESTED);
}
