import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const analysisService = {
    getDqsSum,
    getVarietyStrains,
    setVarietyStrains,
    getFieldMeteorologyHistory,
};

async function getDqsSum(databaseId, year = new Date().getFullYear()) {
    if (databaseId && databaseId > 0) {
        const url = routerHelper.getUrlWithParameters('/api/analysis/getDqsSum', { databaseId, year: year.toString(), module: 'remote_login/getsum', callFunction: 'afm_getsum' });
        const response = await sendServerRequest('GET', url);

        return response ? response.dqsSumValues : null;
    }
    return null;
}

async function getVarietyStrains(props) {
    const url = routerHelper.getUrlWithParameters('/api/analysis/getVarietyStrains', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function setVarietyStrains(props) {
    const url = routerHelper.getUrlWithParameters('/api/analysis/setVarietyStrains', props);
    const response = await sendServerRequest('POST', url, props);
    return response;
}

export interface IMeteorologyData {
    soilTemperature10cm: number;
    meanTemperature: number;
    maximumTemperature: number;
    minimumTemperature: number;
};

export type GetFieldMeteorologyResponse = {
    [date: string]: IMeteorologyData
};

async function getFieldMeteorologyHistory(props) {
    const url = routerHelper.getUrlWithParameters('/api/analysis/getFieldMeteorologyHistory', props);
    const response = await sendServerRequest('GET', url);
    return response as Promise<GetFieldMeteorologyResponse>;
}
