import { inventoryTransferRequestActions } from '../actions/inventoryTransferRequestActions';
import { setLoadingStates } from '../utils';

const initialState = {
    loadingStates: {
        inventoryTransferRequests: {
            inventoryTransferRequestsIsLoading: false,
            inventoryTransferRequestsErrorMessage: '',
            inventoryTransferRequestsHasLoadingError: false,
        },
        inventoryTransferRequestFilters: {
            inventoryTransferRequestsFiltersIsLoading: false,
            inventoryTransferRequestsFiltersErrorMessage: '',
            inventoryTransferRequestsFiltersHasLoadingError: false,
        },
    },
};

export function inventoryTransferRequest(state = initialState, action) {
    switch (action.type) {
        case inventoryTransferRequestActions.GET_INVENTORY_TRANSFER_REQUEST_FILTERS_REQUESTED:
            return {
                ...state,
                loadingStates: setLoadingStates('inventoryTransferRequestFilters', state, true),
            };
        case inventoryTransferRequestActions.GET_INVENTORY_TRANSFER_REQUEST_FILTERS_SUCCEEDED:
            return {
                ...state,
                loadingStates: setLoadingStates('inventoryTransferRequestFilters', state, false, false, ''),
            };
        case inventoryTransferRequestActions.GET_INVENTORY_TRANSFER_REQUEST_FILTERS_FAILED: {
            const { errorMessage } = action.payload;
            return {
                ...state,
                loadingStates: setLoadingStates('inventoryTransferRequestFilters', state, false, true, errorMessage),
            };
        }
        case inventoryTransferRequestActions.GET_INVENTORY_TRANSFER_REQUEST_REQUESTED:
            return {
                ...state,
                loadingStates: setLoadingStates('inventoryTransferRequest', state, true),
            };
        case inventoryTransferRequestActions.GET_INVENTORY_TRANSFER_REQUEST_SUCCEEDED:
            return {
                ...state,
                loadingStates: setLoadingStates('inventoryTransferRequest', state, false, false, ''),
            };
        case inventoryTransferRequestActions.GET_INVENTORY_TRANSFER_REQUEST_FAILED: {
            const { errorMessage } = action.payload;
            return {
                ...state,
                loadingStates: setLoadingStates('inventoryTransferRequest', state, false, true, errorMessage),
            };
        }
        default:
            return state;
    }
}
