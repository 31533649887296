import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TABLE } from 'shared/src/constants';
import { isNull, isNumber } from 'lodash';
import { basePageActions } from '@states/actions/basePageActions';
import { simpleTableActions } from '@states/actions/simpleTableActions';

export default function useTableHeaderSorterHandler(props) {
    const { columnId, isBasePageTable, tableKey } = props;
    const globalColumnSortingTypes = useSelector((state: any) => state.basePage.table.globalColumnSortingTypes);
    const globalColumnSortingTypesSimpleTable = useSelector((state: any) => state.simpleTable.table[tableKey]?.globalColumnSortingTypes);
    const dispatch = useDispatch();

    const sortingType = useCallback(() => {
        function getCurrentSorterState(currentSorterState) {
            if (isNull(currentSorterState)) {
                return TABLE.SORTING.NONE;
            }
            if (currentSorterState && isNumber(currentSorterState[columnId])) {
                return currentSorterState[columnId];
            }
            return TABLE.SORTING.NONE;
        }

        if (isBasePageTable && globalColumnSortingTypes && columnId) {
            return getCurrentSorterState(globalColumnSortingTypes);
        }
        if (!isBasePageTable && tableKey && globalColumnSortingTypesSimpleTable && columnId) {
            return getCurrentSorterState(globalColumnSortingTypesSimpleTable);
        }
        return TABLE.SORTING.NONE;
    }, [isBasePageTable, globalColumnSortingTypes, globalColumnSortingTypesSimpleTable, columnId, tableKey]);

    function getNextSortingType(type) {
        switch (type) {
            case TABLE.SORTING.NONE:
                return TABLE.SORTING.ASC;
            case TABLE.SORTING.ASC:
                return TABLE.SORTING.DESC;
            case TABLE.SORTING.DESC:
                return TABLE.SORTING.NONE;
            default:
                return TABLE.SORTING.ASC;
        }
    }

    function onClickSorterIcon() {
        if (isBasePageTable) {
            const nextSortingType = getNextSortingType(sortingType());
            dispatch(basePageActions.addTableGlobalColumnSortingType(columnId, nextSortingType));
        } else {
            const nextSortingType = getNextSortingType(sortingType());
            dispatch(simpleTableActions.addTableGlobalColumnSortingType(tableKey, columnId, nextSortingType));
        }
    }

    function onClickDeleteAllSorter() {
        if (isBasePageTable) {
            dispatch(basePageActions.removeTableGlobalColumnSortingType());
        } else {
            dispatch(simpleTableActions.removeTableGlobalColumnSortingType(tableKey));
        }
    }

    return {
        sortingType: sortingType(),
        onClickSorterIcon,
        onClickDeleteAllSorter,
    };
}
