import OtherFunctionsCell from '@baseComponents/tableNew/cellTypes/otherFunctionsCell/OtherFunctionsCell';
import useTranslate from '@i18n/useTranslate';
import { MapController } from '@map/services/mapController';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { Layer, LayerAttribute, ThemeType, TranslateType } from '@map/services/mapTypes';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';

type Classes = 'container' | 'containerWrapper';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    containerWrapper: {
        maxWidth: 50,
        background: 'yellow',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    container: {
        marginLeft: -10,
    },
}));

type Tprops = {
    hasOpacity: boolean,
    setIsLabelingVisible: (visible: boolean) => void
    isLabelingVisible: boolean,
    setAsHidden: () => void,
    setAsVisible: () => void,
    isRemoveDisabled: boolean,
    mapController: MapController,
    layerAttribute: LayerAttribute,
}

export function LayerControlPanelLayerRowOptions(
    {
        hasOpacity,
        setIsLabelingVisible,
        isLabelingVisible,
        setAsHidden,
        isRemoveDisabled,
        mapController,
        layerAttribute,
        setAsVisible,
    }: Tprops,
) {
    const classes = useStyles();
    const [otherFunctionList, setOtherFuntionList] = useState<any[]>([]);
    const { t, translater }: TranslateType = useTranslate();

    useEffect(() => {
        let otherFunctions: any[] = [];

        if (layerAttribute.layerName === BuiltInLayerTypes.SENT_TASK || layerAttribute.layerName === BuiltInLayerTypes.RECEIVED_SCOUTINGS) {
            if (hasOpacity) {
                otherFunctions = otherFunctions.concat([
                    {
                        iconId: 'icon-visibility_hide',
                        title: (t('map.hideIcons', 'Hide Icons')),
                        onClick: () => {
                            setAsHidden();
                            document.body.click();
                        },
                    },
                ]);
            } else {
                otherFunctions = otherFunctions.concat([
                    {
                        iconId: 'icon-visibility_show',
                        title: (t('map.showIcons', 'Show Icons')),
                        onClick: () => {
                            setAsVisible();
                            document.body.click();
                        },
                    },
                ]);
            }
            setOtherFuntionList([...otherFunctions]);
            return;
        }

        if (layerAttribute.layerName === BuiltInLayerTypes.MACHINES || layerAttribute.layerName === BuiltInLayerTypes.IRRIGATION) {
            otherFunctions = otherFunctions.concat([
                {
                    iconId: isLabelingVisible ? 'icon-visibility_hide' : 'icon-visibility_show',
                    title: isLabelingVisible ? (t('map.hideIcons', 'Hide Icons')) : (t('map.showIcons', 'Show Icons')),
                    onClick: () => {
                        setIsLabelingVisible(!isLabelingVisible);
                        document.body.click();
                    },
                    isDisabled: () => !hasOpacity,
                },
            ]);
            setOtherFuntionList([...otherFunctions]);
            return;
        }

        otherFunctions = otherFunctions.concat([
            {
                iconId: isLabelingVisible ? 'icon-visibility_hide' : 'icon-visibility_show',
                title: isLabelingVisible ? (t('map.hideLabels', 'Hide labels')) : (t('map.showLabels', 'Show labels')),
                onClick: () => {
                    setIsLabelingVisible(!isLabelingVisible);
                    document.body.click();
                },
                isDisabled: () => !hasOpacity,
            },
        ]);

        if (hasOpacity) {
            otherFunctions.push({
                iconId: 'icon-visibility_hide',
                title: (t('map.hideLayer', 'Hide layer')),
                onClick: () => {
                    if (!isRemoveDisabled) {
                        setAsHidden();
                    }
                    document.body.click();
                },
                isDisabled: () => !hasOpacity,
            });
        } else {
            otherFunctions.push({
                iconId: 'icon-visibility_show',
                title: (t('map.showLayer', 'Show layer')),
                onClick: () => {
                    if (!isRemoveDisabled) {
                        setAsVisible();
                    }
                    document.body.click();
                },
                isDisabled: () => hasOpacity,
            });
        }

        setOtherFuntionList([...otherFunctions]);
    }, [hasOpacity, isLabelingVisible, isRemoveDisabled, layerAttribute, mapController, setAsHidden, setIsLabelingVisible, setAsVisible, t]);

    return !isEmpty(otherFunctionList) ? (
        <div className={classes.containerWrapper}>
            <div className={classes.container}>
                <OtherFunctionsCell maxWidth={40} maxHeight={30} placement="right" itemList={otherFunctionList} rowData={{ id: 1, name: 'myName' }} />
            </div>
        </div>
    ) : null;
}
