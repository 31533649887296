import React from 'react';
import { createUseStyles } from 'react-jss';
import Svg from '../../../Svg';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'inherit',
        border: 'none',
        marginRight: 'auto',
    },
    deleteIcon: {
        height: 24,
        width: 24,
        fill: theme.color.destructive,
    },
}));

export default function DeleteCell(props) {
    const { value, deleteRow, rowData } = props;
    const classes = useStyles();

    return (
        <button type="button" className={classes.baseStyle} onClick={() => deleteRow(value, rowData.tableName)}>
            <Svg style={classes.deleteIcon} iconId="icon-bin" />
        </button>
    );
}
