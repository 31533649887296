/* eslint-disable no-magic-numbers */
import { isEmpty, isObject, isNumber, isNull } from 'lodash';
import { SETTINGS, SUBSCRIPTION } from '../constants';

class CompanySettingsModule {
    getSettingValue(setting, settingsList) {
        if(isEmpty(settingsList)){
            return null;
        }

        const currentSettingItem = settingsList.find(settingItem => settingItem.name === setting);
        return currentSettingItem ? Number.isNaN(Number(currentSettingItem.value)) ? currentSettingItem.value : Number(currentSettingItem.value) : null;
    }

    checkSetting(setting, settingsList) {
        const currentSettingItem = settingsList.find(settingItem => settingItem.name === setting);

        if (!currentSettingItem) {
            return false;
        }
        const currentValue = Number(currentSettingItem.value) || 0;
        const settingValue = currentValue === 0 || currentValue === 1 ? currentValue : false;

        return Boolean(settingValue);
    }

    //TODO: Temp settings checker for route list. It is not yet prepared for all cases. If new setting types are also encountered, this must be modified.
    checkSettingValues(setting, settingsList) {
        let settingName = setting;
        let settingValue = null;
        let currentSettingItem = null;
        let currentSettingsValue = 0;

        if (setting && setting instanceof Object) { //ha object
            const settingParts = Object.entries(setting)[0];
            settingName = settingParts[0].toString();
            settingValue = settingParts[1];
            currentSettingItem = settingsList.find(settingItem => settingItem.name === settingName);
        } else { //ha tömb
            currentSettingItem = settingsList.find(settingItem => settingItem.name === setting);
            if (currentSettingItem) {
                currentSettingsValue = Number(currentSettingItem.value) || 0;
            }
            settingValue = currentSettingsValue > 1 ? false : currentSettingsValue;
        }

        //nincs ilyen beállítás
        if (!currentSettingItem) {
            return false;
        }

        //ha beállításhoz tömb tartozik
        if (Array.isArray(settingValue)) {
            let hasSetting = false;

            settingValue.forEach(currentSettingValue => {
                if (!hasSetting && !isNull(currentSettingItem.value) && currentSettingValue.toString() === currentSettingItem.value.toString()) {
                    hasSetting = true;
                }
            });

            return hasSetting;
        } else if (isObject(setting) && isNumber(+settingValue)) { //object esetén csak azt hasonlítjuk össze
            settingValue = +currentSettingItem.value === +settingValue;
        }

        return Boolean(settingValue);
    }

    createSettingsList(settings, customList) {
        const settingList = {};
        customList.forEach(settingItem => {
            settingList[settingItem.name] = this.getSettingValue(settingItem.setting, settings);
        });
        return settingList;
    }

    /* ------------ */
    /* SUBSCRIPTION */
    hasModuleSubscriptionFunction(module, subscriptions) {
        const hasFunction = module.SUBSCRIPTION_FUNCTIONS.find(subscriptionFunction => subscriptions.find(subscription => !!subscription.packageFunctions.find(functionItem => functionItem.subscriptionFunction.functionName === subscriptionFunction)));
        return hasFunction;
    }

    hasSubscriptionFunction(functionName, subscriptions) {
        return subscriptions.find(subscription => {
            const packages = subscription.packageFunctions;
            const hasFunction = packages.find(packageFunction => packageFunction.subscriptionFunction.functionName === functionName);
            return !!hasFunction;
        });
    }

    hasSomeSubscriptionFunction(functionNames, subscriptions) {
        return subscriptions.some(subscription => {
            const packages = subscription.packageFunctions;
            const hasFunction = packages.find(packageFunction => functionNames.find(functionName => packageFunction.subscriptionFunction.functionName === functionName));
            return !!hasFunction;
        });
    }

    getPackageFunctionGroups(packageFunctions) {
        const ids = [];
        const groups = [];

        packageFunctions.map(functionItem => {
            if (functionItem.subscriptionFunction.subscriptionFunctionGroup) {
                const itemIndex = ids.indexOf(functionItem.subscriptionFunction.subscriptionFunctionGroup.id);
                if (itemIndex === -1) {
                    ids.push(functionItem.subscriptionFunction.subscriptionFunctionGroup.id);
                    groups.push(functionItem.subscriptionFunction.subscriptionFunctionGroup);
                }
                return true;
            }
            return false;
        });

        return groups;
    }

    isUsingNdvi(companySubscriptions) {
        //const usingNdvi5 = this.hasSubscriptionFunction(SUBSCRIPTION.NAME.NDVI_5, companySubscriptions);
        //const usingNdvi15 = this.hasSubscriptionFunction(SUBSCRIPTION.NAME.NDVI_15, companySubscriptions);
        //const usingNdviFull = this.hasSubscriptionFunction(SUBSCRIPTION.NAME.NDVI_FULL, companySubscriptions);
        //const usingNdviResult = usingNdvi5 || usingNdvi15 || usingNdviFull;

        //return usingNdviResult;
        return false;
    }

    getMaxNdviCount(companySubscriptions) {
        const usingNdvi5 = this.hasSubscriptionFunction(SUBSCRIPTION.NAME.NDVI_5, companySubscriptions);
        const usingNdvi15 = this.hasSubscriptionFunction(SUBSCRIPTION.NAME.NDVI_15, companySubscriptions);
        const usingNdviFull = this.hasSubscriptionFunction(SUBSCRIPTION.NAME.NDVI_FULL, companySubscriptions);

        if (usingNdviFull) {
            return SUBSCRIPTION.NDVI.NDVI_FULL;
        }
        if (usingNdvi15) {
            return SUBSCRIPTION.NDVI.NDVI_15;
        }
        if (usingNdvi5) {
            return SUBSCRIPTION.NDVI.NDVI_5;
        }
        return 0;
    }
}

export const companySettingsModule = new CompanySettingsModule();
