import { RIGHTS, SETTINGS, PLANNING as PLANNING_CONSTANTS } from '../constants';

export const EQUIPMENT = {
    MODULE_ID: 9,
    NAME: 'equipment',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'equipment',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.equipment',
                TRANSLATOR_DEFAULT: 'Equipment',
            },
        },
        MACHINE: {
            CONTENT_URL_ID: { machines: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machines',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machine',
                TRANSLATOR_DEFAULT: 'Machine',
            },
            RIGHTS: [
                RIGHTS.MACHINE_INTERFACE,
                RIGHTS.MACHINE_CONNECTION_INTERFACE,
            ],
            HAS_CURRENCY_SWITCH: true,
        },
        FUEL_EXCEL: {
            CONTENT_URL_ID: { fuelExcel: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'fuelExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.fuelExcel',
                TRANSLATOR_DEFAULT: 'Fuel excel',
            },
            RIGHTS: [RIGHTS.FUEL_EXCEL_INTERFACE],
        },
        COST_EXCEL: {
            CONTENT_URL_ID: { costExcel: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'costExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costExcelImport',
                TRANSLATOR_DEFAULT: 'Cost excel',
            },
            RIGHTS: [RIGHTS.COST_EXCEL_INTERFACE],
        },
        MACHINE_CONNECTION_EDIT: {
            CONTENT_URL_ID: { machineConnectionEdit: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineConnectionEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineConnectionEdit',
                TRANSLATOR_DEFAULT: 'Machine connection edit',
            },
        },
        MACHINE_EDIT: {
            CONTENT_URL_ID: { machineEdit: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineEdit',
                TRANSLATOR_DEFAULT: 'Machine edit',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
            PLANNING_MODULE: PLANNING_CONSTANTS.MODULE.EQUIPMENT,
            PLANNING_SETTINGS: {
                DEPTH_ID: 1,
            },
        },
        COST_OF_MACHINES: {
            CONTENT_URL_ID: { costOfMachines: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'costOfMachines',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costOfMachines',
                TRANSLATOR_DEFAULT: 'Cost of machines',
            },
            HAS_CURRENCY_SWITCH: true,
        },
        FUELING_FROM_PETROL_STATION: {
            CONTENT_URL_ID: { fuelingFromPetrolStation: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'fuelingFromPetrolStation',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Tankolás üzemanyagkút adatokból',
            },
            RIGHTS: [RIGHTS.FUELING_FROM_PETROL_STATION_INTERFACE],
            SETTINGS: [SETTINGS.REFUELING_FROM_MMSQL],
        },
        CUSTOMIZING_EQUIPMENTS: {
            CONTENT_URL_ID: { customizingEquipments: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'customizingEquipments',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Eszközök testreszabása',
            },
        },
        SUB_TYPES_OF_MACHINE: {
            CONTENT_URL_ID: { subTypesOfMachine: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'subTypesOfMachine',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.subTypesOfMachine',
                TRANSLATOR_DEFAULT: 'Sub types of machines',
            },
            SETTING_MENU_RIGHTS: [RIGHTS.MACHINE_SUBTYPES_INTERFACE],
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        PRODUCTION_LINE_EDIT: {
            CONTENT_URL_ID: { productionLineEdit: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'productionLineEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productionLineEdit',
                TRANSLATOR_DEFAULT: 'Production line edit',
            },
        },
        MACHINE_OPERATING_HOURS_DATA: {
            CONTENT_URL_ID: { machineOperatingHoursData: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineOperatingHoursData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineOperatingHoursData',
                TRANSLATOR_DEFAULT: 'Machine operating hours data',
            },
            RIGHTS: [RIGHTS.MACHINE_INTERFACE],
        },
        PARTNER_MACHINE_EDIT: {
            CONTENT_URL_ID: { partnerMachineEdit: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'partnerMachineEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.partnerMachineEdit',
                TRANSLATOR_DEFAULT: 'Partner machine edit',
            },
        },
        FUEL_WORKS: {
            CONTENT_URL_ID: { fuelWorks: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'fuelWorks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.fuelWorks',
                TRANSLATOR_DEFAULT: 'Fuel works',
            },
            RIGHTS: [RIGHTS.FUEL_WORKS_INTERFACE],
        },
        ANALYSIS: {
            CONTENT_URL_ID: { analysis: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'analysis',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.analysis',
                TRANSLATOR_DEFAULT: 'Analysis',
            },
            HAS_CURRENCY_SWITCH: true,
            RIGHTS: [RIGHTS.DEVICES_ANALYSIS_INTERFACE],
        },
        MACHINE_REGISTRATION: {
            CONTENT_URL_ID: { machineRegistration: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineRegistration',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineRegistration',
                TRANSLATOR_DEFAULT: 'Machine registration',
            },
            RIGHTS: [
                RIGHTS.MACHINE_REGISTRATION_INTERFACE,
            ],
        },
        MACHINE_ANNOUNCEMENT: {
            CONTENT_URL_ID: { machineAnnouncement: 17 },
            COMPONENT_URL: 'contents/equipments/MachineAnnouncement/MachineAnnouncement',
            EVENT_KEY: 'machineAnnouncement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineAnnouncement',
                TRANSLATOR_DEFAULT: 'Machine announcement',
            },
            RIGHTS: [
                RIGHTS.MACHINE_ANNOUNCEMENT_INTERFACE,
            ],
        },
        MACHINE_SETTINGS: {
            CONTENT_URL_ID: { machineSettings: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'machineSettings',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.machineSettings',
                TRANSLATOR_DEFAULT: 'Machine settings',
            },
        },
        RECURRING_SERVICE_WORK_OPERATION: {
            CONTENT_URL_ID: { recurringServiceWorkOperation: 19 },
            COMPONENT_URL: 'contents/equipments/recurringServiceWorkOperation/RecurringServiceWorkOperation',
            EVENT_KEY: 'recurringServiceWorkOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.recurringServiceWorkOperation',
                TRANSLATOR_DEFAULT: 'Recurring service work operation',
            },
            RIGHTS: [
                RIGHTS.RECURRING_SERVICE_WORK_OPERATION_INTERFACE,
            ],
        },
    },
};
