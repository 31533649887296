/* eslint-disable react-hooks/exhaustive-deps */
import Radio from '@baseComponents/controls/Radio';
import useTranslate from '@i18n/useTranslate';
import { useMapContext } from '@map/services/mapContext';
import { ThemeType } from '@map/services/mapTypes';
import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { createUseStyles } from 'react-jss';
import MapFilterBase from './MapFilterBase';
import { MapFilterSchema, MAP_FILTER_STATUS } from './MapFilterSchema';

type Classes = 'container' | 'title';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    title: {
        marginBottom: 15,
        fontSize: 14,
        fontWeight: 500,
        color: theme.color.raven,
    },
}));
export default function MapFilterState(props) {
    const classes = useStyles();
    const filterContext = useFormContext<MapFilterSchema>();

    function changeTypeFilter(event: any) {
        const value = event.target.value;
        filterContext.setValue('statusId', Number(value));
    }

    const { translater, t } = useTranslate();

    const statusId = filterContext.watch('statusId') ?? 1;

    return (
        <MapFilterBase>
            <div className={classes.container}>
                <div className={classes.title}>{t('productTableFilter.status', 'Status')}</div>
                <form>
                    <Radio text={translater('productTableFilter.activeState', 'Active')} checked={statusId === MAP_FILTER_STATUS.ACTIVE} group="stategrp" value={MAP_FILTER_STATUS.ACTIVE} onChange={changeTypeFilter} />
                    <Radio text={translater('productTableFilter.inactiveState', 'Inactive')} checked={statusId === MAP_FILTER_STATUS.INACTIVE} group="stategrp" value={MAP_FILTER_STATUS.INACTIVE} onChange={changeTypeFilter} />
                    <Radio text={translater('map.filterWorkOpertaionStateInBoth', 'Both')} checked={statusId === MAP_FILTER_STATUS.ACTIVE_AND_INACTIVE} group="stategrp" value={MAP_FILTER_STATUS.ACTIVE_AND_INACTIVE} onChange={changeTypeFilter} />
                </form>
            </div>
        </MapFilterBase>
    );
}
