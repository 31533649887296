/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import { isFunction } from 'lodash';
import { reduxStore } from '@states/store';
import { modalActions } from '@states/actions/modalActions';
import ModalNew from '@baseComponents/modals/modalNew/ModalNew';

export default async function renderModal(Component, modalProps: any | null = null, hasCloseIcon = true, onCloseModal?: any, isMaxZIndex = false) {
    function onClose(hideModalFunction) {
        if (isFunction(hideModalFunction)) {
            hideModalFunction();
        }
        if (isFunction(modalProps?.cancelCallback)) {
            modalProps.cancelCallback();
        }
    }

    const componentWithModal = key => {
        if (Component) {
            return (
                <ModalNew
                    key={key}
                    isOpen
                    hasCloseDialog={modalProps?.hasCloseDialog}
                    hasCloseIcon={hasCloseIcon}
                    handleClose={() => onClose(hideModal)}
                    isMaxZIndex={isMaxZIndex}
                    checkForChanges={modalProps?.checkForChanges}
                    isIgnoreOuterClick={modalProps?.isIgnoreOuterClick}
                >
                    <Component hideModal={hideModal} {...modalProps} />
                </ModalNew>
            );
        }
        return null;
    };

    const MemoizedModal = React.memo(componentWithModal);

    const hideModal = () => {
        if (isFunction(onCloseModal)) {
            onCloseModal();
        }

        reduxStore.dispatch(modalActions.hideModal(MemoizedModal));
    };

    reduxStore.dispatch(modalActions.showModal(MemoizedModal));
}
