import { useRef } from 'react';
import { isEmpty } from 'lodash';

export default function useLoader() {
    const ref = useRef<any>();

    function setLoader(visibility = true) {
        if (ref && !isEmpty(ref.current)) {
            ref.current.style.display = visibility ? 'flex' : 'none';
        }
    }

    return {
        ref,
        setLoader,
    };
}
