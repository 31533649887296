import Button from '@baseComponents/buttons/Button';
import useTranslate from '@i18n/useTranslate';
import { ThemeType } from '@map/services/mapTypes';
import clsx from 'clsx';
import { Ref } from 'react';
import { createUseStyles } from 'react-jss';

type Classes = 'drawMapMenuContainer' | 'buttonContainer' | 'editFunctionButton' | 'fileUpload' | 'measureLabelContainer' | 'sumLength' | 'area' | 'fillLpisButton';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    drawMapMenuContainer: {
        position: 'absolute',
        zIndex: 4,
        marginLeft: 'calc(50% - 222px)',
        bottom: 30,
        padding: 5,
        backgroundColor: 'white',
        borderRadius: 8,
        height: 45,
    },
    buttonContainer: {
        display: 'inline-block',
        verticalAlign: 'top',
    },
    editFunctionButton: {
        border: 'none',
        marginRight: 0,
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: theme.color.opacity.main,
            border: 'none',
        },
        '&:focus': {
            backgroundColor: theme.color.opacity.main,
            border: 'none',
        },
    },
    fileUpload: {
        display: 'none !important',
    },
    measureLabelContainer: {
        display: 'inline-block',
        marginLeft: 20,
        paddingRight: 10,
        lineHeight: 1.2,
    },
    sumLength: {
        fontSize: 12,
        fontWeight: 400,
        color: '#454F54',
    },
    area: {
        fontSize: 16,
        fontWeight: 500,
        color: '#21282C',
    },
    fillLpisButton: {
        '& .title': {
            display: 'block',
        },
    },
}));

type TProps = {
    onNavigationSelect: () => void;
    onDrawSelect: () => void;
    deletePolygon: () => void;
    onHoleDrawSelect: () => void;
    onUnionDrawSelect: () => void;
    onInputClick: () => void;
    onSaveClick?: () => void;
    onFillLPIS?: () => void;
    onFileUpload: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    fileInputRef: Ref<HTMLInputElement>;
    isCanvasNotBlank: boolean;
    polygonArea: number;
    withDrawingSaveButton: boolean;
    withSlovakOverlay: boolean;
}

export default function DrawControlComponent({
    onNavigationSelect,
    onDrawSelect,
    deletePolygon,
    onHoleDrawSelect,
    onUnionDrawSelect,
    onInputClick,
    onFileUpload,
    onSaveClick,
    onFillLPIS,
    fileInputRef,
    isCanvasNotBlank,
    polygonArea,
    withDrawingSaveButton,
    withSlovakOverlay,
}: TProps) {
    const { t } = useTranslate();
    const classes = useStyles();

    return (
        <div className={classes.drawMapMenuContainer}>
            <div className={classes.buttonContainer}>
                {
                    onSaveClick && (
                        <Button
                            title={withDrawingSaveButton ? t('default.save', 'Save') : t('default.apply', 'Apply')}
                            size="small"
                            type="secondary"
                            iconId="icon-save"
                            iconColor="black"
                            onClick={() => { onSaveClick(); }}
                            className={classes.editFunctionButton}
                        />
                    )
                }

                <Button
                    title={t('drawMap.navigationTitle', 'Navigation')}
                    size="small"
                    type="secondary"
                    iconId="icon-cursor"
                    iconColor="black"
                    onClick={() => onNavigationSelect()}
                    className={classes.editFunctionButton}
                />
                <Button
                    title={t('drawMap.drawTitle', 'Draw')}
                    size="small"
                    type="secondary"
                    iconId="icon-edit"
                    iconColor="black"
                    onClick={() => onDrawSelect()}
                    className={classes.editFunctionButton}
                />
                <Button
                    title={t('drawMap.eraseTitle', 'Erase')}
                    size="small"
                    type="secondary"
                    iconId="icon-eraser"
                    iconColor="black"
                    onClick={() => deletePolygon()}
                    className={classes.editFunctionButton}
                />
                <Button
                    disabled={!isCanvasNotBlank}
                    title={t('drawMap.drawHoleTitle', 'Draw hole')}
                    size="small"
                    type="secondary"
                    iconId="icon-substract_table"
                    iconColor="black"
                    onClick={() => onHoleDrawSelect()}
                    className={classes.editFunctionButton}
                />
                <Button
                    disabled={!isCanvasNotBlank}
                    title={t('drawMap.addPolygonTitle', 'Add polygon')}
                    size="small"
                    type="secondary"
                    iconId="icon-edit_table"
                    iconColor="black"
                    onClick={() => onUnionDrawSelect()}
                    className={classes.editFunctionButton}
                />
                <span>
                    <Button
                        title={t('drawMap.upload', 'Upload polygon')}
                        size="small"
                        type="secondary"
                        iconId="icon-upload_table"
                        iconColor="black"
                        onClick={onInputClick}
                        className={classes.editFunctionButton}
                    />
                    <input ref={fileInputRef} className={classes.fileUpload} type="file" name="myImage" onChange={onFileUpload} />
                </span>
                {
                    withSlovakOverlay ? (
                        <Button
                            title="Prebrát z LPIS"
                            size="small"
                            type="secondary"
                            iconId="icon-slovak"
                            iconColor="black"
                            onClick={onFillLPIS}
                            className={clsx(classes.editFunctionButton, classes.fillLpisButton)}
                        >Prebrát z LPIS
                        </Button>
                    ) : null
                }
                {/*
                TODO: Enable this
                <Button disabled={copyButtonIsEnabled} title={t('drawMap.copyTitle', 'Copy from previous period')} size="small" type="secondary" iconId="icon-calendar" iconColor="black" onClick={copyPolygon} className={classes.editFunctionButton} />
                <Button disabled={addPolygonButtonIsEnabled} title={t('drawMap.addPolygonTitle', 'Add polygon')} size="small" type="secondary" iconId="icon-edit_table" iconColor="black" onClick={addPolygon} className={classes.editFunctionButton} /> */}
            </div>
            {polygonArea > 0
                && (
                    <div className={classes.measureLabelContainer}>
                        <div className={classes.sumLength}>{t('drawMap.polygonArea', 'Area')}</div>
                        <div className={classes.area}>{polygonArea.toFixed(2)} ha</div>
                    </div>
                )
            }
        </div>
    );
}
