import { useSlovakReportContext } from '@map/services/slovakianReports/slovakReportContext';
import { slovakExternalServices } from '@map/services/slovakianReports/slovakReportExternalApis';
import { useEffect } from 'react';
import { isEmpty, isNil } from 'lodash';
import { getImageMapType } from '../slovakReportsUtils';
import { SK_HEAT_MAP_LAYERS } from '../slovakReportsLayers';

export const SK_LAYER_NAMES = {
    TERRITORIAL: 'TERRITORIAL',
    SLOPE: 'SLOPE',
};

export default function useSlovakWMS(map: google.maps.Map | null, activeLayers: number[]) {
    const [store] = useSlovakReportContext();
    const { gsaaYear } = store;

    useEffect(() => {
        if (map && !isNil(activeLayers)) {
            const activeHeatMapLayerIds: number[] = [];

            activeLayers?.forEach((layerId: number) => {
                const layer = Object.values(SK_HEAT_MAP_LAYERS).find(l => l.id === layerId);

                if (layer && layer.id === layerId) {
                    activeHeatMapLayerIds.push(layer.externalId);
                }
            });

            const territorialOverlay = getImageMapType({
                name: SK_LAYER_NAMES.TERRITORIAL,
                map,
                options: { opacity: 1 },
                getUrl: (bbox: string) => slovakExternalServices.getTerritorialLayerUrl(gsaaYear, bbox),
            });
            const slopeOverlay = getImageMapType({ name: SK_LAYER_NAMES.SLOPE, map, getUrl: (bbox: string) => slovakExternalServices.getSlopeLayerUrl(gsaaYear, bbox, activeHeatMapLayerIds) });

            if (!isEmpty(activeHeatMapLayerIds)) {
                map.overlayMapTypes.push(slopeOverlay);
            }
            map.overlayMapTypes.push(territorialOverlay);
        }
    }, [map, activeLayers, gsaaYear]);
}
