import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { errorActions, companyActions } from '@states/actions';
import { warehouseService } from '@services';
import { stringUtils } from 'shared/src/modules/stringUtils';
import { WAREHOUSE, SETTINGS } from 'shared/src/constants';
import { isUndefined, isNull, isEmpty } from 'lodash';

export default function useReceivingWarehouse(props) {
    const { warehouseId, setWarehouseId } = props;
    const { companyIds, typeIds } = props; //filters
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const [warehouseList, setWarehouseList] = useState(null);
    const [filteredWarehouseList, setFilteredWarehouseList] = useState();
    const [selectedWarehouseId, setSelectedWarehouseId] = useState(null);
    const [isNonAccentSearch] = useState(dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)));

    //initial datas
    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const warehouses = await warehouseService.getWarehouses({
                    companyIds,
                    typeIds: typeIds || [WAREHOUSE.TYPE_ID.OWN, WAREHOUSE.TYPE_ID.FOREIGN, WAREHOUSE.TYPE_ID.OWN_AND_FOREIGN],
                });

                const formattedWarehouses = warehouses?.map(warehouseObj => ({ value: warehouseObj.name, key: warehouseObj.id }));

                if (!isCancelled) {
                    setWarehouseList(formattedWarehouses);
                    setFilteredWarehouseList(formattedWarehouses);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (!isUndefined(companyIds) && !isNull(companyIds) && location) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [companyIds, typeIds, dispatch, location]);

    //init selected
    useEffect(() => {
        if (warehouseId && !isEmpty(warehouseList) && selectedWarehouseId !== warehouseId) {
            const currentWarehouse = warehouseList?.find(warehouse => warehouse.key === warehouseId);
            setSelectedWarehouseId(currentWarehouse?.key);
        } else if (!warehouseId && selectedWarehouseId) { //reset
            setSelectedWarehouseId();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseId, warehouseList]);

    //search function
    function searchWarehouse(searchValue) {
        if (!isEmpty(searchValue) && !isEmpty(warehouseList)) {
            const filteredList = warehouseList?.filter(companyObj => stringUtils.includesNoCase(companyObj.value, searchValue, isNonAccentSearch));
            setFilteredWarehouseList(filteredList);
        } else {
            setFilteredWarehouseList(warehouseList);
        }
    }

    function changeWarehouse(selectedId) {
        setSelectedWarehouseId(selectedId);

        if (setWarehouseId) {
            setWarehouseId(selectedId);
        }
    }

    return {
        selectedWarehouseId,
        filteredWarehouseList,
        changeWarehouse,
        searchWarehouse,
    };
}
