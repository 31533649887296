import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const treatmentUnitService = {
    getTreatmentUnitTypes,
    getTreatmentUnits,
    getTreatmentUnitDivides,
    getTreatmentUnitCompanyContainerMaxSerialNumber,
};

/**
 * Get all treatment unit types datas
 * HUN name: kezelési egység típus
 * @return {array} Treatment unit types array
 */
async function getTreatmentUnitTypes() {
    const response = await sendServerRequest('GET', '/api/treatmentUnit/types');
    return response;
}

/**
 * Get treatment unit list
 *
 * @param {number} productId - required
 * @param {date} date - optional
 * @param {number} warehouseIds  - optional
 * @param {number} typeIds - optional
 * @param {boolean} isShowQualityPreservationDate - optional (minoseg_megorzes_datuma_megjelenit) DEFAULT: true
 * @param {number} partnerId - optional - ha partner_id üres akkor saját + idegen is jön DEFAULT: 0
 * @param {boolean} isAllProductQuantitiesDisplayedInKg - optional - (minden_termek_mennyiseg_kg_megjelenitesben)
 * @param {List<number>} canSelectIds - optional - (kivalaszthato_ids)
 * @param {boolean} isCanEditAllTypesOfQuantity - optional - (minden_tipus_mennyiseg_atirhato)
 * @param {number} receiptPartnerId - optional - (bizonylat_partner_id)
 *
 * @return {array} Treatment units
 */
async function getTreatmentUnits(props) {
    const url = routerHelper.getUrlWithParameters('/api/treatmentUnit/treatmentUnits', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get treatment unit divides - felosztások lekérdezése
 *
 * @param {number} productId - isOptional: ha nem készlet alapján kérjük le az adatokat, akkor a termék adatokat kérdezzük le a felosztás létrehozáshoz
 * @param {List<number>} stockIds - isOptional: készlet alapján kérdezzük le a felosztás információkat
 *
 * @return {array} Treatment unit divides
 */
async function getTreatmentUnitDivides(props) {
    const url = routerHelper.getUrlWithParameters('/api/treatmentUnit/treatmentUnitDivides', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get treatment unit container max serial number - konténer sorszám lekérdezés cégenként
 * @return {array} Treatment unit divides
 */
async function getTreatmentUnitCompanyContainerMaxSerialNumber() {
    const response = await sendServerRequest('GET', '/api/treatmentUnit/companyContainerMaxSerialNumber');
    return response;
}
