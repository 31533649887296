import React, { useState } from 'react';
import useTranslate from '@i18n/useTranslate';
import { useFormContext } from 'react-hook-form';
import Button from '@baseComponents/buttons/Button';
import { alertActions, errorActions, tableFilterActions } from '@states/actions';
import { isEmpty, isFunction, isNil, toString } from 'lodash';
import { useDispatch } from 'react-redux';
import { soilAnalysisService } from '@services';
import { ModalFooter } from '@baseComponents/modals/modalNew';
import renderModal from '@modals/modalNew/modalFunctions';
import { createUseStyles } from 'react-jss';
import DeleteSoilAnalysisModal from '../deleteAnalysisData/DeleteAnalysisData';

const useStyles = createUseStyles({
    saveButton: {
        marginRight: 15,
    },
});

export default function SoilAnalysisImportFooter(props) {
    const { rowData, hideModal, onAfterHideModal } = props;
    const [isSaving, setIsSaving] = useState<boolean>();
    const { t } = useTranslate();
    const dispatch = useDispatch();
    const classes = useStyles();

    const { getValues, handleSubmit } = useFormContext();

    async function onSubmit(saveData) {
        try {
            const { date, soilAnalysisData, registryNumber, validityDate, laboratoryId, samplingArea } = saveData;
            let isValid = true;

            const isCultivationPeriodsValid = soilAnalysisData?.every(soilAnalysisRow => {
                const isCultivationPeriodValid = !isEmpty(soilAnalysisRow.cultivationPeriod) && !isNil(soilAnalysisRow.cultivationPeriod);
                const isDepthValid = +(soilAnalysisRow?.depth || 0) > 0;
                const isCodeValid = !isEmpty(toString(soilAnalysisRow.code)) && soilAnalysisRow.code.length > 0;
                return isCultivationPeriodValid && isDepthValid && isCodeValid;
            });

            isValid = isValid
                && (!isEmpty(date) && !isNil(date))
                && (!isEmpty(registryNumber) && !isNil(registryNumber))
                && (!isEmpty(validityDate) && !isNil(validityDate))
                && (!isNil(laboratoryId) && laboratoryId > 0)
                && (!isNil(samplingArea) && samplingArea > 0)
                && isCultivationPeriodsValid;

            if (!isValid) {
                dispatch(alertActions.errorAlert(t('default.signedFieldsRequired', 'Signed fields are required!')));
                setIsSaving(false);
                return;
            }

            const saveSoilAnalysisData = await soilAnalysisService.setSoilAnalysis(
                {
                    id: saveData.id,
                    registryNumber: saveData.registryNumber,
                    date: saveData.date,
                    validityDate: saveData.validityDate,
                    soilAnalysisData: saveData.soilAnalysisData,
                    isManual: saveData.isManual,
                    samplingArea: saveData.samplingArea,
                    laboratoryId: saveData.laboratoryId,
                    isExtendedExamination: saveData.isExtendedExamination,
                },
            );
            if (!saveSoilAnalysisData) {
                dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed.')));
                setIsSaving(false);
                return;
            }
            dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved.')));
            dispatch(tableFilterActions.getTableData());
            hideModal();

            if (onAfterHideModal && isFunction(onAfterHideModal)) {
                onAfterHideModal();
            }
        } catch (error) {
            dispatch(errorActions.setError(error, 'Soil analysis excel import'));
        }
    }

    function onClickSave() {
        if (isEmpty(getValues('soilAnalysisData'))) {
            renderModal(DeleteSoilAnalysisModal, { rowData }, false);
        } else {
            handleSubmit(onSubmit)();
        }
    }

    return (
        <ModalFooter>
            <Button size="large" onClick={onClickSave} disabled={isSaving} className={classes.saveButton}>
                {t('default.save', 'Save')}
            </Button>
            <Button size="large" type="secondary" onClick={hideModal}>
                {t('default.cancel', 'Cancel')}
            </Button>
        </ModalFooter>
    );
}
