import BaseStoreService from '@flowsCommon/services/BaseStoreService';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { MACHINE_PANEL_PROP } from '@map/components/MachinePanel/MachinePanelProperty';
import { MapSliderIntervalMode } from '@map/components/MapSlider/MapSliderTypes';
import calculateBBox from '@turf/bbox';
import { Feature } from 'geojson';
import { isEmpty, isNil, isFinite, cloneDeep } from 'lodash';
import * as turf from '@turf/helpers';
import { BuiltInLayerTypes, MapMode } from './mapEnums';
import { TMapStore } from './mapStore';
import {
    DefaultCenterDto,
    Layer,
    LayerAttribute,
    LayerGeomsDto,
    legendEntity,
    MapFilters,
    MultiGeometrySelector,
    UpdateLayerDto,
    LayerAttributeColoringTypes,
    MachineDataResponse,
    LayerSelectorSettings,
    LayerAttributeStatus,
    AddittionalGeometryData,
    LayersDto,
    LayerGeometriesWithBboxDto,
} from './mapTypes';

export class MapStoreService extends BaseStoreService<TMapStore> {
    setFetchers = (layerFetcher: () => Promise<LayersDto>, geomFetcher: () => Promise<LayerGeometriesWithBboxDto>) => {
        this.setStore(draftStore => {
            draftStore.fetcher.layerFetcher = layerFetcher;
            draftStore.fetcher.geomFetcher = geomFetcher;
        });
    };

    setMapCenter = (center: DefaultCenterDto) => {
        this.setStore(draftStore => {
            draftStore.center.centerData = center;
        });
    };

    setLayeringLoading = (loading: boolean) => {
        this.setStore(draftStore => {
            draftStore.layering.loading = loading;
        });
    };

    setLayersAndAttributes = (layers: Nullable<Layer[]>, attributes: Nullable<LayerAttribute[]>, layerOrder: number[]) => new Promise<void>(res => {
        this.setStore(draftStore => {
            draftStore.layering.loading = false;
            draftStore.layering.layers = layers;
            draftStore.layering.attributes = attributes;
            draftStore.layering.order = layerOrder;
            res();
        });
    });

    setGoogle = (google: any) => {
        this.setStore(draftStore => {
            draftStore.googleRefs.google = google;
        });
    };

    setFilters = (filters: MapFilters) => {
        this.setStore(draftStore => {
            draftStore.filters = filters;
        });
    };

    setMapRef = (map: google.maps.Map) => {
        this.setStore(draftStore => {
            draftStore.googleRefs.map = map;
        });
    };

    setDrawingManagerRefs = (drawingManager: google.maps.drawing.DrawingManager, drawDataLayer: google.maps.Data) => {
        this.setStore(draftStore => {
            draftStore.googleRefs.drawingManager = drawingManager;
            draftStore.drawing.dataLayer = drawDataLayer;
        });
    };

    setGeometriesForLayers = (layersGeoms: LayerGeomsDto[], shouldFetchCosts: boolean) => {
        this.setStore(draftStore => {
            layersGeoms?.forEach(layerGeoms => {
                const index = draftStore.layering.layers?.findIndex(layer => layer.id === layerGeoms.id) as number;
                if (index !== -1 && draftStore.layering.layers) {
                    draftStore.layering.layers[index].fetchedGeometries = layerGeoms.geoms;
                    draftStore.layering.layers[index].bbox = layerGeoms.bbox;
                    if (layerGeoms.name === BuiltInLayerTypes.CULTIVATION_PERIOD) {
                        if (shouldFetchCosts) {
                            draftStore.layering.layers[index].costFetched = true;
                        }
                    }
                }
            });
            draftStore.layering.loading = false;
        });
    };

    setFilteredGeometriesForLayers = (layersGeoms: Partial<LayerGeomsDto>[]) => {
        this.setStore(draftStore => {
            const bounds = new google.maps.LatLngBounds();
            layersGeoms.forEach(lg => {
                if (!isNil(lg.bbox) && isFinite(lg.bbox[0])) {
                    bounds.extend(new google.maps.LatLng(lg.bbox[1], lg.bbox[0]));
                    bounds.extend(new google.maps.LatLng(lg.bbox[3], lg.bbox[2]));
                }
            });
            draftStore.center.bbox = [bounds.getSouthWest().lng(), bounds.getSouthWest().lat(), bounds.getNorthEast().lng(), bounds.getNorthEast().lat()];

            layersGeoms?.forEach(layerGeoms => {
                const index = draftStore.layering.layers?.findIndex(layer => layer.id === layerGeoms.id) as number;
                if (index !== -1 && draftStore.layering.layers) {
                    if (!isNil(layerGeoms.geoms)) {
                        draftStore.layering.layers[index].geometries = layerGeoms.geoms;
                    }
                    if (!isNil(layerGeoms.bbox)) {
                        draftStore.layering.layers[index].bbox = layerGeoms.bbox;
                    }
                }
            });
        });
    };

    setMachines = (allMachinesData: MachineDataResponse[], bbox: number[]) => {
        this.setStore(draftStore => {
            draftStore.center.bbox = bbox;

            const irrigationDatas = allMachinesData.filter(machineData => Number(machineData?.mainTypeId ?? 0) === 8);
            if (!isEmpty(irrigationDatas)) {
                const irrigationPoints = irrigationDatas.map(irrigationData => (irrigationData.attributes.Position.coordinates));
                const irrigationCalculatedBBox = calculateBBox(turf.lineString(irrigationPoints));

                const irrigationIndex = draftStore.layering.layers?.findIndex(layer => layer.name === BuiltInLayerTypes.IRRIGATION) as number;
                if (irrigationIndex !== -1 && draftStore.layering.layers) {
                    draftStore.layering.layers[irrigationIndex].machines = irrigationDatas;
                    draftStore.layering.layers[irrigationIndex].bbox = [irrigationCalculatedBBox[1], irrigationCalculatedBBox[0], irrigationCalculatedBBox[3], irrigationCalculatedBBox[2]];
                }
            }

            const machinesData = allMachinesData.filter(machineData => Number(machineData?.mainTypeId ?? 0) !== 8);
            if (!isEmpty(machinesData)) {
                const machineDataPoints = machinesData.map(machineData => (machineData.attributes.Position.coordinates));
                const machineCalculatedBBox = calculateBBox(turf.lineString(machineDataPoints));

                const machineIndex = draftStore.layering.layers?.findIndex(layer => layer.name === BuiltInLayerTypes.MACHINES) as number;
                if (machineIndex !== -1 && draftStore.layering.layers) {
                    draftStore.layering.layers[machineIndex].machines = machinesData;
                    draftStore.layering.layers[machineIndex].bbox = [machineCalculatedBBox[1], machineCalculatedBBox[0], machineCalculatedBBox[3], machineCalculatedBBox[2]];
                }
            }

            draftStore.layering.loading = false;
        });
    };

    setLayerAttributesAsActive = (layerAttributes: LayerAttribute[]) => {
        this.setStore(draftStore => {
            const layerAttributesToAdd = layerAttributes.filter(attr => !draftStore.layering.order.includes(attr.id));
            draftStore.layering.order = [...(layerAttributesToAdd.map(layerAttribute => layerAttribute.id)), ...draftStore.layering.order];
            layerAttributes.forEach(layerAttribute => {
                const index = draftStore.layering.attributes?.findIndex(storeAttribute => storeAttribute.id === layerAttribute.id) as number;
                if (index !== -1 && draftStore.layering.attributes) {
                    draftStore.layering.attributes[index].isActive = LayerAttributeStatus.ACTIVE;
                }
            });
        });
    };

    setLayerAttributesAsPending = (layerAttributes: LayerAttribute[]) => new Promise<void>(res => {
        this.setStore(draftStore => {
            draftStore.layering.order = [...(layerAttributes.map(layerAttribute => layerAttribute.id)), ...draftStore.layering.order];
            layerAttributes.forEach(layerAttribute => {
                const index = draftStore.layering.attributes?.findIndex(storeAttribute => storeAttribute.id === layerAttribute.id) as number;
                if (index !== -1 && draftStore.layering.attributes) {
                    draftStore.layering.attributes[index].isActive = LayerAttributeStatus.PENDING;
                }
            });
            res();
        });
    })

    setLayerAttributesAsDisabled = (layerAttributes: LayerAttribute[]) => new Promise<void>(res => {
        this.setStore(draftStore => {
            draftStore.layering.order = draftStore.layering.order.filter(attributeId => !layerAttributes.map(attribute => attribute.id).includes(attributeId));
            layerAttributes.forEach(layerAttribute => {
                const index = draftStore.layering.attributes?.findIndex(storeAttribute => storeAttribute.id === layerAttribute.id) as number;
                if (index !== -1 && draftStore.layering.attributes) {
                    draftStore.layering.attributes[index].isActive = LayerAttributeStatus.INACTIVE;
                }
            });
            res();
        });
    });


    setLayerAttributeOpacity = (layerAttribute: LayerAttribute, opacity: number) => {
        this.setStore(draftStore => {
            const layerAttributeIndex = (draftStore.layering.attributes ?? []).findIndex(attribute => attribute.id === layerAttribute.id);

            if (draftStore.layering.attributes && layerAttributeIndex > -1) {
                draftStore.layering.attributes[layerAttributeIndex].opacity = opacity;
            }
        });
    }

    setLayerAttributeLabeling = (layerAttribute: LayerAttribute, isEnabled: boolean) => new Promise<void>(res => {
        this.setStore(draftStore => {
            const layerAttributeIndex = (draftStore.layering.attributes ?? []).findIndex(attribute => attribute.id === layerAttribute.id);

            if (draftStore.layering.attributes && layerAttributeIndex > -1) {
                draftStore.layering.attributes[layerAttributeIndex].isLabelingEnabled = isEnabled;
            }
            res();
        });
    });

    setLayerAttributeMachines = (layerAttribute: LayerAttribute, isEnabled: boolean) => {
        this.setStore(draftStore => {
            const layerAttributeIndex = (draftStore.layering.attributes ?? []).findIndex(attribute => attribute.id === layerAttribute.id);

            if (draftStore.layering.attributes && layerAttributeIndex > -1) {
                draftStore.layering.attributes[layerAttributeIndex].isLabelingEnabled = isEnabled;
            }
        });
    };

    setLayerAttributeLegend = (layerAttribute: LayerAttribute, legendItems: legendEntity[]) => {
        this.setStore(draftStore => {
            const layerAttributeIndex = (draftStore.layering.attributes ?? []).findIndex(attribute => attribute.id === layerAttribute.id);

            if (draftStore.layering.attributes && layerAttributeIndex > -1) {
                draftStore.layering.attributes[layerAttributeIndex].legendList = [...legendItems];
            }
        });
    };

    setSelectedGeometry = (selectedGeometry: { geom: Nullable<Feature>, layerId: number, isInfoModalOpen: boolean }) => {
        this.setStore(draftStore => {
            draftStore.selectedGeometry = selectedGeometry;
        });
    }

    setSelectedMachine = (_machineMarker: google.maps.Marker | null, _layerId: number, _deviceId: number, _isMachineModalOpen: boolean) => {
        this.setStore(draftStore => {
            if (_deviceId !== -1) {
                const selectedMachineData = draftStore.layering.layers?.find(layer => layer.id === _layerId)?.machines?.find(machine => machine.deviceID === Number(_deviceId));
                if (selectedMachineData) {
                    draftStore.selectedMachine = {
                        isMachineModalOpen: _isMachineModalOpen,
                        layerId: _layerId,
                        machine: selectedMachineData,
                    };
                } else {
                    draftStore.selectedMachine = {
                        isMachineModalOpen: false,
                        layerId: -1,
                        machine: null,
                    };
                }
            } else {
                draftStore.selectedMachine = {
                    isMachineModalOpen: false,
                    layerId: -1,
                    machine: null,
                };
            }
        });
    }

    setOrder = (order: number[]) => {
        this.setStore(draftStore => {
            draftStore.layering.order = order;
        });
    };

    setGisAuthAsAvailable = () => {
        this.setStore(draftStore => {
            draftStore.gisAuth.isAvailable = true;
        });
    };

    setCommonData = (primaryCurrency: string, cultures: any[], workOperationMainGroups: any[], meteorologyUnits: any[]) => {
        this.setStore(draftStore => {
            draftStore.global.primaryCurrency = primaryCurrency;
            draftStore.global.cultures = cultures;
            draftStore.global.workOperationMainGroups = workOperationMainGroups;
            draftStore.global.meteorologyUnits = meteorologyUnits;
        });
    }

    setDrawingLoading = (isLoading: boolean) => {
        this.setStore(draftStore => {
            draftStore.drawing.loading = isLoading;
        });
    }

    setDrawnGeometry = (geometryFeature: Nullable<Feature>) => {
        this.setStore(draftStore => {
            draftStore.drawing.drawnFeature = geometryFeature;
        });
    }

    setIsMapMeasuring(isMeasuring: boolean) {
        this.setStore(draftStore => {
            draftStore.tools.isMeasuring = isMeasuring;
        });
    }

    setMultiSelectedGeometriesDataLayer(yearId: number, dataLayer: google.maps.Data) {
        this.setStore(draftStore => {
            const multiGeometrySelector = draftStore.multiSelectedGeometries?.find(selectedGeometry => selectedGeometry.yearId === yearId);
            if (isNil(multiGeometrySelector)) {
                const selector: MultiGeometrySelector = {
                    dataLayer,
                    yearId,
                    entityIds: [],
                    selectedFeatures: [],
                };
                draftStore.multiSelectedGeometries = [...draftStore.multiSelectedGeometries, selector];
            } else {
                multiGeometrySelector.dataLayer = dataLayer;
            }
        });
    }

    setMultiSelectedGeometriesFeatures(yearId: number, features: Nullable<Feature[]>) {
        this.setStore(draftStore => {
            const multiGeometrySelector = draftStore.multiSelectedGeometries.find(geometrySelector => geometrySelector.yearId === yearId);
            if (!isNil(multiGeometrySelector)) {
                multiGeometrySelector.selectedFeatures = features;
            }
        });
    }

    setMapMeasureInfo(area: number, length: number) {
        this.setStore(draftStore => {
            draftStore.tools.measureArea = area;
            draftStore.tools.measureLenght = length;
        });
    }

    setGoogleMapStlyeOverride = (styles: google.maps.MapTypeStyle[]) => {
        this.setStore(draftstore => {
            draftstore.googleMapSettingsOverride.styles = styles;
        });
    };

    addLayer = (layer: Layer) => {
        this.setStore(draftStore => {
            if (draftStore.layering.layers) {
                draftStore.layering.layers = [...draftStore.layering.layers, layer];
            } else {
                draftStore.layering.layers = [layer];
            }
        });
    };

    updateLayer = (updateLayer: UpdateLayerDto) => {
        this.setStore(draftStore => {
            if (draftStore.layering.layers) {
                const findLayer = draftStore.layering.layers?.find(layer => updateLayer.id === layer.id);

                const attributes = draftStore.layering.attributes?.filter(attr => attr.layerId === findLayer?.id && attr.coloringType === LayerAttributeColoringTypes.DISCRETE);

                attributes?.forEach(attr => {
                    attr.dataLayer.forEach(feature => {
                        attr.dataLayer.overrideStyle(feature, {
                            fillColor: updateLayer.defaultFillColor,
                            strokeColor: updateLayer.defaultStrokeColor,
                        });
                    });
                    attr.defaultFillColor = updateLayer.defaultFillColor;
                    attr.defaultStrokeColor = updateLayer.defaultStrokeColor;
                });
                if (findLayer) {
                    const findLayerIndex = draftStore.layering.layers.indexOf(findLayer);
                    const updatedLayer: Layer = {
                        ...findLayer,
                        name: updateLayer.name,
                        defaultFillColor: updateLayer.defaultFillColor,
                        defaultStrokeColor: updateLayer.defaultStrokeColor,
                    };
                    draftStore.layering.layers.splice(findLayerIndex, 1, updatedLayer);
                }
            }
        });
    }

    removeLayers = (layerIds: number[]) => {
        this.setStore(draftStore => {
            if (draftStore.layering.layers) {
                const layers = draftStore.layering.layers?.filter(layer => !layerIds.includes(layer.id));
                draftStore.layering.layers = layers;

                const attributesToStore = draftStore.layering.attributes?.filter(attr => !layerIds.includes(attr.layerId));

                if (attributesToStore) {
                    if (draftStore.layering.attributes) {
                        // eslint-disable-next-line no-multi-assign
                        draftStore.layering.attributes = [...attributesToStore];
                    }
                }
            }
        });
    };

    setAutoZoom = (isAutoZoom: boolean) => {
        this.setStore(draftStore => {
            draftStore.layering.autoZoom = isAutoZoom;
        });
    };

    loadUnloadedLayerAttributes = (unloadedLayerAttributes: LayerAttribute[]) => {
        this.setStore(draftStore => {
            if (!isNil(draftStore.layering)) {
                if (!isNil(draftStore.layering.attributes)) {
                    draftStore.layering.attributes = draftStore.layering.attributes?.concat(unloadedLayerAttributes);
                } else {
                    draftStore.layering.attributes = [...unloadedLayerAttributes];
                }
            }
        });
    };

    setZoomLevel = (zoomLevel: number) => {
        this.setStore(draftStore => {
            draftStore.zoomControl.currentLevel = zoomLevel;
        });
    };

    setLayerSelectorSettings = (settings?: LayerSelectorSettings) => {
        this.setStore(draftStore => {
            if (isNil(settings)) {
                draftStore.layerSelector = {
                    layerFilter: {
                        blackList: null,
                    },
                    attributeFilter: {
                        whiteList: null,
                    },
                };
            } else {
                draftStore.layerSelector = settings;
            }
        });
    }

    setMapSliderInterval = (startDate: Nullable<Date>, endDate: Nullable<Date>) => new Promise<void>(res => {
        this.setStore(draftStore => {
            if (!isNil(startDate) && !isNil(endDate)) {
                draftStore.mapSlider = {
                    ...draftStore.mapSlider,
                    isEnabled: true,
                    endDate,
                    startDate,
                };

                const findMeparAttrs = draftStore.layering.attributes?.filter(attr => attr.layerName === BuiltInLayerTypes.LPIS_FIELD);
                if (!isEmpty(findMeparAttrs)) {
                    findMeparAttrs?.forEach(attr => {
                        const attrIndex = draftStore.layering.attributes?.indexOf(attr);
                        if (!isNil(draftStore.layering.attributes) && !isNil(attrIndex)) {
                            if (isNil(draftStore.layering.attributes[attrIndex].timelineProductionYearId)) {
                                draftStore.layering.attributes[attrIndex] = {
                                    ...draftStore.layering.attributes[attrIndex],
                                    timelineProductionYearId: draftStore.filters.productionYearId,
                                };
                            }
                        }
                    });
                }
            } else {
                delete draftStore.mapSlider.endDate;
                delete draftStore.mapSlider.startDate;
                draftStore.mapSlider.isEnabled = false;

                const findMeparAttrs = draftStore.layering.attributes?.filter(attr => attr.layerName === BuiltInLayerTypes.LPIS_FIELD);
                if (!isEmpty(findMeparAttrs)) {
                    findMeparAttrs?.forEach(attr => {
                        const attrIndex = draftStore.layering.attributes?.indexOf(attr);
                        if (!isNil(draftStore.layering.attributes) && !isNil(attrIndex)) {
                            draftStore.layering.attributes[attrIndex] = {
                                ...draftStore.layering.attributes[attrIndex],
                                // eslint-disable-next-line no-undefined
                                timelineProductionYearId: undefined,
                            };
                        }
                    });
                }
            }
            res();
        });
    })

    setAttributeTimeLineProductionYearId = (attr: LayerAttribute, productionYearId: number) => new Promise<void>(res => {
        this.setStore(draftStore => {
            const attrIndex = draftStore.layering.attributes?.findIndex(findAttr => findAttr.id === attr.id);
            if (!isNil(draftStore.layering.attributes) && !isNil(attrIndex)) {
                const newAttr: LayerAttribute[] = [{
                    ...draftStore.layering.attributes[attrIndex],
                    timelineProductionYearId: productionYearId,
                }];
                draftStore.layering.attributes.splice(attrIndex, 1, ...newAttr);
            }
            res();
        });
    })

    setGlobalLabelLayer = (labelLayer: google.maps.MVCArray<google.maps.Marker>) => new Promise<void>(res => {
        this.setStore(draftStore => {
            draftStore.layering.globalLabelLayer = labelLayer;
            res();
        });
    })

    setGlobalLabelLayerClusterer = (cluterer: MarkerClusterer) => new Promise<void>(res => {
        this.setStore(draftStore => {
            draftStore.layering.globalLabelLayerCluster = cluterer;
            res();
        });
    });

    setPrimaryCurrencySelected = (isSelected: boolean, primaryCurrency: string, secondaryCurrancy: string) => new Promise<void>(res => {
        this.setStore(draftStore => {
            draftStore.currency.isPrimarySelected = isSelected;
            draftStore.currency.primaryCurrency = primaryCurrency;
            draftStore.currency.secondaryCurrancy = secondaryCurrancy;
            res();
        });
    })

    setLayerAttributeAttributeKey = (layerAttribute: LayerAttribute, attributeKey: string, suffix?: string) => new Promise<void>(res => {
        this.setStore(draftStore => {
            const attribute = draftStore.layering.attributes?.find(attr => attr.id === layerAttribute.id);

            if (!isNil(attribute)) {
                const attributeIndex = draftStore.layering.attributes?.indexOf(attribute);
                if (!isNil(attributeIndex) && attributeIndex !== -1) {
                    if (!isNil(draftStore.layering.attributes) && !isNil(draftStore.layering.attributes?.[attributeIndex].attributeKey)) {
                        draftStore.layering.attributes[attributeIndex].attributeKey = attributeKey;

                        if (!isNil(suffix)) {
                            draftStore.layering.attributes[attributeIndex].suffix = suffix;
                        }

                        return res();
                    }
                }
            }
            res();
        });
    });

    removeGeometries = (layers: Layer[]) => new Promise<void>(res => {
        this.setStore(draftStore => {
            layers?.forEach(layer => {
                const index = draftStore.layering.layers?.findIndex(l => layer.id === l.id) as number;
                if (index !== -1 && draftStore.layering.layers) {
                    draftStore.layering.layers[index].geometries = null;
                    draftStore.layering.layers[index].fetchedGeometries = null;
                    draftStore.layering.layers[index].costFetched = false;
                    draftStore.layering.layers[index].machines = null;
                    delete draftStore.layering.layers[index].adittionalGeometryData;
                }
            });
            res();
        });
    });

    removeFilteredGeometries = (layers?: Layer[]) => new Promise<void>(res => {
        this.setStore(draftStore => {
            const layersToRemove = layers ?? draftStore.layering.layers;

            if (!isNil(layersToRemove)) {
                layersToRemove.forEach(layer => {
                    const index = draftStore.layering.layers?.findIndex(l => layer.id === l.id) as number;
                    if (index !== -1 && draftStore.layering.layers) {
                        draftStore.layering.layers[index].geometries = null;
                    }
                });
            }
            res();
        });
    });

    setMapId(id: number) {
        this.setStore(draftStore => {
            draftStore.mapId = id;
        });
    }

    setFilterInit(filter: Partial<MapFilters>) {
        this.setStore(draftStore => {
            draftStore.filterInit = filter;
        });
    }

    setMapSliderIntervalMode = (mode: MapSliderIntervalMode) => {
        this.setStore(draftStore => {
            draftStore.mapSlider.intervalMode = mode;
        });
    }

    setAdditionalGeometryData = (layer: Layer, adittionalData: AddittionalGeometryData) => new Promise<void>(res => {
        this.setStore(draftStore => {
            const layerId = layer.id;
            const indexOfLayer = draftStore.layering.layers?.findIndex(l => l.id === layerId);
            if (!isNil(indexOfLayer)) {
                if (!isNil(draftStore.layering.layers)) {
                    if (!isNil(draftStore.layering.layers?.[indexOfLayer])) {
                        draftStore.layering.layers[indexOfLayer].adittionalGeometryData = adittionalData;
                    }
                }
            }
            res();
        });
    })

    removeAdditionalData = () => new Promise<void>(res => {
        this.setStore(draftStore => {
            draftStore.layering.layers?.forEach(layer => {
                delete layer.adittionalGeometryData;
            });
            res();
        });
    })

    setMapMode = (mapMode: MapMode) => {
        this.setStore(draftStore => {
            draftStore.mapMode = mapMode;
        });
    }

    updateLayerGeometryData = (layer: Layer, geometryProps: any[]) => new Promise<void>(res => {
        this.setStore(draftStore => {
            const layerId = layer.id;
            const indexOfLayer = draftStore.layering.layers?.findIndex(l => l.id === layerId);
            if (!isNil(indexOfLayer)) {
                if (!isNil(draftStore.layering.layers)) {
                    if (!isNil(draftStore.layering.layers?.[indexOfLayer])) {
                        const entityIdsToUpdate: number[] = geometryProps?.map(prop => prop?.entityId);
                        if (!isNil(draftStore.layering.layers[indexOfLayer].fetchedGeometries) && !isNil(draftStore.layering.layers[indexOfLayer].fetchedGeometries?.features)) {
                            const features = cloneDeep(draftStore.layering.layers[indexOfLayer].fetchedGeometries?.features);
                            const modifiedFeatures = features?.map(feature => {
                                const entityId: number = feature.properties?.entityId;
                                if (isNil(entityId)) {
                                    return feature;
                                }
                                if (entityIdsToUpdate.includes(entityId)) {
                                    const propsToInsert = geometryProps.find(props => props?.entityId === entityId);
                                    const copyFeature = cloneDeep(feature);
                                    copyFeature.properties = {
                                        ...copyFeature.properties,
                                        ...propsToInsert,
                                    };
                                    return copyFeature;
                                }
                                return feature;
                            });
                            if (!isNil(modifiedFeatures)) {
                                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                                draftStore.layering.layers[indexOfLayer].fetchedGeometries!.features = modifiedFeatures;
                            }
                        }
                    }
                }
            }
            res();
        });
    })
}

export default MapStoreService;
