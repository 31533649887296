import { MapContextProvider } from '@map/services/mapContext';
import TranslaterProvider from '@i18n/TranslaterProvider';
import { reduxStore } from '@states/store';
import { theme } from '@styles/theme';
import { ThemeProvider } from 'react-jss';
import { Provider } from 'react-redux';
import { LayerCreateContextProvider } from '@map/services/layerCreate/layerCreateContext';
import { MeteorologyModal } from './MeterologyModal';

export const MeteorologyModalModalContainer = (props: any) => (
    <ThemeProvider theme={theme}>
        <TranslaterProvider>
            <Provider store={reduxStore}>
                <LayerCreateContextProvider>
                    <MapContextProvider>
                        <MeteorologyModal {...props} />
                    </MapContextProvider>
                </LayerCreateContextProvider>
            </Provider>
        </TranslaterProvider>
    </ThemeProvider>);
