import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isArray, isObject, isEmpty } from 'lodash';
import { basePageActions, simpleTableActions } from '@states/actions';

//table group select all rows
export default function useTableGroupSelect(props) {
    const { setIsSelectedGroup, isSelectedGroup, groupName } = props;
    const { isBasePageTable, tableKey } = props; //simple table

    //basePage
    const filteredTableData = useSelector((state: any) => state.basePage.table.filteredDatas?.[groupName]);
    const selectedItemIds = useSelector((state: any) => state.basePage.table.selectedItemIds);
    //simpleTable
    const [simpleTableFilteredTableData, setSimpleTableFilteredTableData] = useState();
    const simpleTableFilteredTableDataArray = useSelector((state: any) => state.simpleTable.table[tableKey]?.filteredDatas);
    const simpleTableSelectedItemIds = useSelector((state: any) => state.simpleTable.table[tableKey]?.selectedItemIds);
    const dispatch = useDispatch();

    useEffect(() => {
        if (groupName) {
            if (isArray(simpleTableFilteredTableDataArray)) {
                setSimpleTableFilteredTableData(simpleTableFilteredTableDataArray?.find(group => group.groupName === groupName)?.groupArray);
                return;
            }
            if (isObject(simpleTableFilteredTableDataArray) && simpleTableFilteredTableDataArray?.groupName === groupName) {
                setSimpleTableFilteredTableData(simpleTableFilteredTableDataArray?.groupArray);
            }
        }
    }, [simpleTableFilteredTableDataArray, groupName]);

    //basePage table - get group selection from redux
    useEffect(() => {
        if (isBasePageTable) {
            const isAllItemsSelected = isAllItemSelectedInGroup(filteredTableData, selectedItemIds);
            setIsSelectedGroup(isAllItemsSelected);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBasePageTable, filteredTableData, selectedItemIds, setIsSelectedGroup]);

    //simple table - get group selection from redux
    useEffect(() => {
        if (!isBasePageTable) {
            const isAllItemsSelected = isAllItemSelectedInGroup(simpleTableFilteredTableData, simpleTableSelectedItemIds);
            setIsSelectedGroup(isAllItemsSelected);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBasePageTable, simpleTableFilteredTableData, simpleTableSelectedItemIds, setIsSelectedGroup]);

    function isAllItemSelectedInGroup(groupDataArray, selectedIds) {
        return !isEmpty(selectedIds) && !isEmpty(groupDataArray)
            ? getGroupItemIds(groupDataArray).every(dataItemId => selectedIds.includes(dataItemId))
            : false;
    }

    //onClick change checked state
    async function changeGroupCheckedState() {
        if (!isEmpty(filteredTableData) && isBasePageTable) {
            const tableItemIds = getGroupItemIds(filteredTableData);
            selectedItemIdsHandler(isSelectedGroup, tableItemIds);
            return;
        }
        if (!isEmpty(simpleTableFilteredTableData) && !isBasePageTable) {
            const tableItemIds = getGroupItemIds(simpleTableFilteredTableData);
            selectedItemIdsHandler(isSelectedGroup, tableItemIds);
        }
    }

    //get group item ids
    function getGroupItemIds(tableData) {
        if (!isEmpty(tableData)) {
            return tableData.map(dataRow => dataRow.id);
        }
        return null;
    }

    //set selected item ids in redux
    function selectedItemIdsHandler(isSelectedGroupItems, tableItemIds) {
        function setSelectedIdsInRedux(isAllSelected, reduxAddFunction, reduxRemoveFunction, selectedIds) {
            //basePage
            if (isBasePageTable && !isAllSelected) {
                dispatch(reduxAddFunction(selectedIds));
                return;
            }
            if (isBasePageTable && isAllSelected) {
                dispatch(reduxRemoveFunction(selectedIds));
                return;
            }

            //simpleTable
            if (!isAllSelected) {
                dispatch(reduxAddFunction(tableKey, selectedIds));
                return;
            }
            dispatch(reduxRemoveFunction(tableKey, selectedIds));
        }

        if (!isEmpty(tableItemIds)) {
            if (isBasePageTable) { //basePage
                setSelectedIdsInRedux(isSelectedGroupItems, basePageActions.addSelectedIds, basePageActions.removeSelectedIds, tableItemIds);
            } else { //simple table
                setSelectedIdsInRedux(isSelectedGroupItems, simpleTableActions.addSelectedIds, simpleTableActions.removeSelectedIds, tableItemIds);
            }
        }
    }

    return {
        changeGroupCheckedState,
    };
}
