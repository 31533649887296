import { VALIDATION } from 'shared/src/constants';
import { isValidTime, isNotNegative, higherThanZero } from './validatonUtils';

const { ERRORS } = VALIDATION;
const { VALUE_IS_NEGATIVE, INVALID_TIME, VALUE_IS_NOT_HIGHER_THAN_ZERO } = ERRORS;
const validators = {
    [VALUE_IS_NEGATIVE.ERROR_CODE]: {
        errorCode: VALUE_IS_NEGATIVE.ERROR_CODE,
        errorMessage: VALUE_IS_NEGATIVE.ERROR_MESSAGE,
        validate: isNotNegative,
    },
    [VALUE_IS_NOT_HIGHER_THAN_ZERO.ERROR_CODE]: {
        errorCode: VALUE_IS_NOT_HIGHER_THAN_ZERO.ERROR_CODE,
        errorMessage: VALUE_IS_NOT_HIGHER_THAN_ZERO.ERROR_MESSAGE,
        validate: higherThanZero,
    },
    [INVALID_TIME.ERROR_CODE]: {
        errorCode: INVALID_TIME.ERROR_CODE,
        errorMessage: INVALID_TIME.ERROR_MESSAGE,
        validate: isValidTime,
    },
};

export default validators;
