import { useState, useEffect } from 'react';
import ProptTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { dateTimeUtils } from 'shared/src/modules/dateTimeUtils';
import Select from '../../inputs/select/Select';

export default function DateRangeSelect({ setStartDate, setEndDate, setInputStartDate, endDate, startDate, width, className }) {
    const { t } = useTranslation();
    const [dateList, setDateList] = useState<any>(null);
    const [selectedDate, setSelectedDate] = useState(startDate || null);

    const [periodsSelectList] = useState({
        TWO_WEEKS: t('periodSelect.twoWeeks', '2 weeks'),
        ACTUAL_MONTH: t('periodSelect.actualMonth', 'Actual month'),
        CURRENT_CULTIVATION_PERIOD: t('periodSelect.currentCultivationYear', 'Current cultivation year'),
        A_WEEK: t('periodSelect.week', 'A week'),
        A_MONTH: t('periodSelect.month', 'A month'),
        A_QUARTER_YEAR: t('periodSelect.quarterYear', 'A quarter year'),
        A_HALF_YEAR: t('periodSelect.halfYear', 'A half year'),
    });

    useEffect(() => {
        if (endDate && dateTimeUtils.isValidDate(endDate)) {
            const start = dateTimeUtils.getFirstDayOfYear(dateTimeUtils.getDateObject(endDate)?.year());
            setDateList(dateTimeUtils.getDateList(start, dateTimeUtils.getMoment(), periodsSelectList));
            setSelectedDate(startDate);
        }
    }, [startDate, endDate, setDateList, setSelectedDate, periodsSelectList]);

    function onChange(date) {
        const formattedDate = dateTimeUtils.getMomentObjectFromDateString(date);
        setEndDate(dateTimeUtils.getDefaultDateNow());
        setStartDate(date);
        if (setInputStartDate) {
            setInputStartDate(formattedDate);
        }
    }

    return (
        <Select listData={dateList} selected={selectedDate} setSelected={setSelectedDate} onChange={onChange} width={width} className={className} />
    );
}

DateRangeSelect.propTypes = {
    setStartDate: ProptTypes.func.isRequired,
    setInputStartDate: ProptTypes.func,
    width: ProptTypes.number,
    endDate: ProptTypes.oneOfType([
        ProptTypes.shape({}),
        ProptTypes.string,
    ]),
    startDate: ProptTypes.oneOfType([
        ProptTypes.shape({}),
        ProptTypes.string,
    ]),
};

DateRangeSelect.defaultProps = {
    width: null,
    startDate: null,
    endDate: null,
    setInputStartDate: null,
};
