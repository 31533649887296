import { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useMapContext } from '@map/services/mapContext';
import { ThemeType } from '@map/services/mapTypes';
import { isNil } from 'lodash';
import { MeasureToolInfoRow } from './MeasureToolInfoRow';

type Classes = 'container' | 'layersWrapper' | 'buttonsLayersWrapper' | 'paddedContainer';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        width: 200,
        height: 60,
        background: '#fff',
        borderRadius: 8,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
    },
    paddedContainer: {
        padding: 15,
    },
    layersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        fontSize: 16,
        color: theme.color.jet,
        overflow: 'scroll',
        flexGrow: 1,
    },
    buttonsLayersWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
}));

type MeasureInfo = {
    area: number;
    length: number;
}

export const MeasureToolInfoComponent = () => {
    const classes = useStyles();

    const [store] = useMapContext();
    const [isMeasuring, setIsMeasuring] = useState(false);
    const [measureInfo, setMeasureInfo] = useState<MeasureInfo>({ area: 0, length: 0 });

    useEffect(() => {
        setIsMeasuring(store.tools.isMeasuring);
        if (isMeasuring === true) {
            setMeasureInfo({ area: 0, length: 0 });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.tools.isMeasuring]);

    useEffect(() => {
        if (isNil(store.tools.measureLenght) || isNil(store.tools.measureArea) || !isMeasuring) {
            setMeasureInfo({ area: 0, length: 0 });
            return;
        }

        const { measureLenght, measureArea } = store.tools;

        const measureLengthToKm = measureLenght / 1000;
        const areaToHa = measureArea / 10000;
        setMeasureInfo({ area: areaToHa, length: measureLengthToKm });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.tools.measureLenght, store.tools.measureArea]);

    //TODO: Translate titles

    return isMeasuring ? (<div className={classes.container}>
        {measureInfo.area !== 0 ? (
            <>
                <MeasureToolInfoRow titleText={'Terület'} dataText={`${measureInfo.area.toFixed(2)} ha`} />
                <MeasureToolInfoRow titleText={'Kerület'} dataText={`${measureInfo.length.toFixed(2)} km`} />
            </>
        ) : <MeasureToolInfoRow titleText={'Mérés'} dataText={`${measureInfo.length.toFixed(2)} km`} />
        }

    </div>) : null
};
