/* eslint-disable no-magic-numbers */
import { isEmpty } from 'lodash';

export function getFirstName(user) {
    const userName = user.name.split(' ');
    return userName[0] || user.name;
}

export function getLastName(user) {
    const userName = user.name.split(' ');
    return userName[1] || user.name;
}

export function getMonogram(userName) {
    if (isEmpty(userName)) {
        return null;
    }

    return userName.split(' ')
        .map(char => char.charAt(0)).join('')
        .substring(0, 2)
        .toUpperCase();
}

export function getFullNameWithNickname(firstName, surName, nickName) {
    const nick = '';
    if (nickName && nickName.length > 0) {
        nick.concat(' (', nickName, ')');
    }
    const fullNameWithNickName = surName.concat(' ', firstName, nick);

    return fullNameWithNickName;
}
