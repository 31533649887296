import { createUseStyles } from 'react-jss';
import { InputGroup as RbInputGroup } from 'react-bootstrap';
import { Input } from '@baseComponents/inputs/inputField';
import clsx from 'clsx';

import InputPretend from './InputPretend';
import InputAppend from './InputAppend';

const useStyles = createUseStyles({
    inputGroup: {
        boxSizing: 'border-box',
        position: 'relative',
        display: 'flex',
        //flexWrap: 'wrap',
        alignItems: 'stretch',
        width: '100%',
        height: 40,
    },
    inputFromGroup: {
        boxSizing: 'border-box',
        position: 'relative',
        flex: '1 1 auto',
        width: '1% !important',
        minWidth: 0,
        marginBottom: 0,

        borderTopLeftRadius: (props: any) => props.hasPretendText && 0,
        borderBottomLeftRadius: (props: any) => props.hasPretendText && 0,

        borderTopRightRadius: (props: any) => props.hasAppendText && 0,
        borderBottomRightRadius: (props: any) => props.hasAppendText && 0,
    },
});

export default function InputGroup(props) {
    const { pretendText, pretendIcon, pretendClassName, pretendTitle, pretendOnClick } = props;
    const { appendText, appendIcon, appendClassName, appendTitle, appendOnClick } = props;
    const { groupClassName, className } = props;
    const classes = useStyles({ hasPretendText: !!pretendText || !!pretendIcon, hasAppendText: !!appendText || !!appendIcon });

    return (
        <RbInputGroup className={clsx(classes.inputGroup, groupClassName)}>
            {(pretendText || pretendIcon)
                && <InputPretend
                    text={pretendText}
                    icon={pretendIcon}
                    className={pretendClassName}
                    title={pretendTitle}
                    onClick={pretendOnClick}
                />}

            <Input {...props} className={clsx(classes.inputFromGroup, className)} />

            {(appendText || appendIcon)
                && <InputAppend
                    text={appendText}
                    icon={appendIcon}
                    className={appendClassName}
                    title={appendTitle}
                    onClick={appendOnClick}
                />}
        </RbInputGroup>
    );
}
