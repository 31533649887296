import { simpleAction } from './baseActions';

const translationActions = {
    setTranslateFuncion,
    SET_TRANSLATE_FUNCTION: 'TRANSLATION_SET_TRANSLATE_FUNCTION',
};

export { translationActions };

function setTranslateFuncion(t) {
    return simpleAction(translationActions.SET_TRANSLATE_FUNCTION, { t });
}
