import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import SelectToggleButton from '@baseComponents/select/selectParts/SelectToggleButton';
import SelectDropDown from '@baseComponents/select/selectParts/SelectDropDown';
import useSelectEventHandler from '@baseComponents/select/selectParts/useSelectEventHandler';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    otherFunctionCellWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        cursor: 'default',
    },
    popover: {
        border: 'none',
        maxWidth: 'fit-content',
        background: 'transparent',
        '& .popover-body': {
            padding: 0,
        },
        '& .arrow::before': {
            borderColor: 'transparent',
        },
        '& .arrow': {
            borderLeftColor: theme.color.red,
        },
        zIndex: 9999999,
    },
    dropdownMenuWrapper: {
        borderRadius: 6,
        padding: 0,
    },
    dropdownMenuItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        minWidth: 250,
        fontSize: '15px',
        fontWeight: 'normal',
        lineHeight: '1.33',
        color: theme.color.jet,
        outline: 'none',
        '&:hover': {
            backgroundColor: theme.color.opacity.main,
            color: theme.color.main,
            cursor: 'pointer',
            '& $menuItemIcon': {
                fill: theme.color.main,
            },
        },
        '& .disabled': {
            opacity: 1,
            backgroundColor: theme.color.white,
            color: theme.color.gray,
            '&:hover': {
                backgroundColor: theme.color.white,
                cursor: 'default',
                color: theme.color.gray,
            },
        },
        '&:first-child': {
            '&:hover': {
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6,
            },
        },
        '&:last-child': {
            '&:hover': {
                borderBottomLeftRadius: 6,
                borderBottomRightRadius: 6,
            },
        },
    },
    dropdownMenuItemDestructive: {
        color: theme.color.destructive,
        backgroundColor: theme.color.white,
        outline: 'none',
        '&:hover': {
            color: theme.color.destructive,
            backgroundColor: theme.color.destructiveFaded,
            cursor: 'pointer',
            '& $menuItemIcon': {
                fill: theme.color.destructive,
            },
        },
    },
    menuItemIcon: {
        width: 20,
        height: 20,
        marginRight: 10,
    },
    disabledIcon: {
        opacity: 0.5,
        fill: [theme.color.jet, '!important'],
    },
    destructiveIcon: {
        fill: theme.color.destructive,
    },
    otherFunctionButton: {
        width: 50,
        '& div': {
            padding: 0,
        },
        '& svg': {
            marginRight: 0,
        },
    },
}));

export default function SimpleSelectOverFlow(props) {
    const { placement = 'bottom-start', customSelected, dataList, selected, setSelected, onChange, placeholder = null, iconId, className, addNew, search, disabled, onlyDropdown, emptyOption, onActive, onInactive, alignRight } = props;
    const { t } = useTranslation();
    const [placeholderText] = useState(placeholder || `${t('default.pleaseSelect', 'Please select')}...`);
    const classes = useStyles();

    const { selectedItem, isOpened, onChangeSelected, onToggle } = useSelectEventHandler({ dataList, selected, customSelected, setSelected, onChange, search, disabled, onActive, onInactive });

    return (
        <OverlayTrigger
            trigger="click"
            placement={placement}
            rootClose
            show={isOpened}
            popperConfig={{ modifiers: [
                {
                    name: 'offset',
                    enabled: false,
                    padding: 0,
                },
            ] }}
            overlay={
                <Popover className={classes.popover}>
                    <Popover.Content>
                        <div className={classes.dropdownMenuWrapper}>
                            <Dropdown onSelect={onChangeSelected} onToggle={onToggle} className={className} show={isOpened}>
                                <Dropdown.Toggle style={{ display: 'none' }} variant="success" id="dropdown-basic" />
                                <SelectDropDown
                                    dataList={dataList}
                                    selectedItem={selectedItem}
                                    addNew={addNew}
                                    search={search}
                                    isOpened
                                    emptyOption={emptyOption}
                                    alignRight={alignRight}
                                />
                            </Dropdown>
                        </div>
                    </Popover.Content>
                </Popover>
            }
        >
            <div>
                <Dropdown onSelect={onChangeSelected} onToggle={onToggle} className={className} show={isOpened}>
                    <SelectToggleButton
                        placeholder={placeholderText}
                        selectedItem={selectedItem}
                        isOpened={isOpened}
                        iconId={iconId}
                        disabled={disabled}
                        onlyDropdown={onlyDropdown}
                    />
                </Dropdown>
            </div>
        </OverlayTrigger>
    );
}
