import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    modalFooter: {
        height: 46,
        marginTop: 15,
        marginBottom: 15,
        background: theme.color.white,
        textAlign: 'center',
    },
}));

export default function ModalFooter(props) {
    const { children, className } = props;
    const classes = useStyles();

    return (
        <div className={clsx(classes.modalFooter, className)}>
            {children}
        </div>
    );
}
