import { createUseStyles } from 'react-jss';
import { ThemeType, TranslateType } from '@map/services/mapTypes';
import useTranslate from '@i18n/useTranslate';
import { Nullable } from '@flowsCommon/services/baseTypes';
import useSlovakWMS from '@map/components/SlovakReportsOverlay/geoserver/useSlovakWMS';
import useSlovakWFS from '@map/components/SlovakReportsOverlay/geoserver/useSlovakWFS';
import Checkbox from '@baseComponents/controls/Checkbox';
import Svg from '@baseComponents/Svg';
import { useState } from 'react';
import useSlovakLayersData from './useSlovakLayersData';
import useInitSlovakReportsOverlay from '../useInitSlovakReportsOverlay';
import { SK_LAYERS } from '../slovakReportsLayers';

type Props = {
  map: Nullable<google.maps.Map>;
};

const useStyles = createUseStyles<ThemeType | any>((theme: any) => ({
    overlay: {
        position: 'absolute',
        zIndex: 2,
        marginBottom: 30,
        marginLeft: 30,
        display: 'flex',
        gap: 15,
        bottom: 0,
        left: 0,
    },
    container: {
        width: 280,
        backgroundColor: theme.color.white,
        borderRadius: 6,
        boxShadow: theme.shadows.map,
        alignSelf: 'flex-start',
        padding: 10,
        paddingRight: 0,
    },
    itemTitle: {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 600,
        color: theme.color.raven,
    },
    headerWrapper: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 15,
        justifyContent: 'space-between',
    },
    selectBar: {
        width: '100%',
        '& *': {
            maxHeight: 250,
        },
    },
    layerWrapper: {
        overflowY: 'auto',
        ...theme.verticalScrollbar,
        maxHeight: 400,
    },
    layerGroupWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        paddingTop: 10,
        paddingRight: 10,
    },
    layerGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        paddingTop: 4,
        paddingBottom: 12,
        '& h3': {
            fontSize: 14,
            margin: 0,
        },

        '& svg': {
            width: 24,
            height: 24,
            cursor: 'pointer',
        },
    },
    layerRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    layerGroupHeader: {
        display: 'flex',
        alignItems: 'center',

        '& h3': {
            marginLeft: 10,
        },

        '& span': {
            marginLeft: 0,
        },
    },
}));

export default function SlovakReportLayers(props: Props) {
    const { map } = props;
    const classes = useStyles();
    const { t }: TranslateType = useTranslate();

    const [openLayerGroups, setOpenLayerGroups] = useState<number[]>([]);
    const { dataList, onLayersChange } = useSlovakLayersData({ map });

    useInitSlovakReportsOverlay(map);
    useSlovakWMS(map, dataList);
    useSlovakWFS(map, dataList);

    return (
        <div className={classes.overlay}>
            <div className={classes.container}>
                <div className={classes.headerWrapper}>
                    <span className={classes.itemTitle}>{t('map.layersText', 'Layers')}</span>
                </div>

                <div className={classes.layerWrapper}>
                    {SK_LAYERS.map((layer, idx) => (
                        <div key={`SK_LAYER_GROUP_${idx}`} className={classes.layerGroupWrapper}>
                            <div className={classes.layerGroup}>
                                <div className={classes.layerGroupHeader}>
                                    <Checkbox size="small" checked={layer.children.every(c => dataList.includes(c.id as number))} onChange={() => onLayersChange(layer.children.map(c => +(c?.id ?? 0)))} />
                                    <h3>{layer.name}</h3>
                                </div>
                                {layer.children.length > 1 ? (
                                    <Svg onClick={() => setOpenLayerGroups(p => (p.includes(idx) ? p.filter(x => x !== idx) : [...p, idx]))} iconId={openLayerGroups.includes(idx) ? 'icon-subtract' : 'icon-add'} />
                                ) : null}
                            </div>

                            {openLayerGroups.includes(idx)
                && layer.children.map((child, idx) => (
                    <div key={`SK_LAYER_IDX_${idx}`} className={classes.layerRow}>
                        <p>{child.name}</p>
                        <Checkbox size="small" checked={dataList.includes(child.id as number)} onChange={() => onLayersChange([child.id as number])} />
                    </div>
                ))}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
