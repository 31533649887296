import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useHistory } from 'react-router-dom';
import { sessionService } from '@services';

import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    iconButton: {
        width: 30,
        height: 30,
        padding: 0,
        borderRadius: 15,
        border: '1px solid',
        borderColor: theme.color.pearl,
        backgroundColor: 'transparent',
        marginRight: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...theme.disableOutline,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    iconButtonSvg: {
        width: 20,
        height: 20,
        fill: theme.color.raven,
        lineHeight: '40px',
        verticalAlign: 'middle',
    },
}));

export default function HeaderBackButton(props) {
    const { url, className } = props;
    const { t } = useTranslation();
    const history = useHistory();
    const agentName = sessionService.getAgentName();
    const classes = useStyles();

    function navigateToUrl() {
        if (url) {
            history.push(url);
        } else {
            history.push(`/${agentName}`);
        }
    }

    return (
        <button type="button" onClick={navigateToUrl} title={t('default.back', 'Back')} className={clsx(classes.iconButton, className)}>
            <Svg iconId="icon-arrow_back" style={classes.iconButtonSvg} />
        </button>
    );
}
