import { useRef } from 'react';
import DataInput from '@baseComponents/inputs/DataInput';
import useTranslate from '@i18n/useTranslate';

export default function FileButton(props) {
    const { inputAccept, onClick } = props;
    const { t } = useTranslate();
    const fileInputRef = useRef<any>();

    function onClickInputFile() {
        fileInputRef.current.click();
    }

    return (
        <>
            <DataInput
                type="dashed"
                title={t('fileButton.addFile', 'Add file')}
                onClick={onClickInputFile}
            />
            <input
                accept={inputAccept}
                style={{ display: 'none' }}
                ref={fileInputRef}
                type="file"
                onChange={onClick}
            />
        </>
    );
}
