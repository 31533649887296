import { useEffect, useState, useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, isFunction } from 'lodash';
import { SYSTEM } from 'shared/src/constants';
import { getSystemModuleList, checkSystemModuleIsVisible } from 'shared/src/systemModuls/systemModuleList';
import { userActions } from '@states/actions/userActions';
import useTranslate from '@i18n/useTranslate';
import { sessionService } from '@services';
import CompanyMultiselect from '@baseComponents/companyMultiselect/CompanyMultiselect';
import PlanningMode from '../planningMode/PlanningMode';

const useStyles = createUseStyles((theme: any) => ({
    userMenuContentWrapper: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        overflowY: 'auto',
        ...theme.verticalScrollbar,
    },
    contentContainers: {
        padding: {
            top: 20,
            left: 30,
        },
    },
    contentTitles: {
        fontSize: 11,
        fontWeight: 500,
        lineHeight: 1.36,
        textTransform: 'uppercase',
    },
    systemModuleButtons: {
        height: 50,
        width: 90,
        cursor: 'pointer',
        marginRight: 15,
        marginTop: 15,
        borderRadius: 4,
        border: '1px solid',
        borderColor: theme.color.pearl,
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center center !important',
        backgroundColor: 'rgba(255, 255, 255, 1) !important',
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.3) !important',
            borderColor: 'rgba(0, 0, 0, 0.1)',
        },
    },
    selectedSystemModuleButtons: {
        height: 50,
        width: 90,
        cursor: 'pointer',
        marginRight: 15,
        marginTop: 15,
        borderRadius: 4,
        border: '1px solid',
        borderColor: theme.color.main,
        boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15)',
        backgroundRepeat: 'no-repeat !important',
        backgroundPosition: 'center center !important',
        backgroundColor: 'rgba(255, 255, 255, 1) !important',
    },
    multiselect: {
        marginTop: 5,
    },
}));

export default function UserMenuContent(props) {
    const { defaultInfoLoaded, closeUserMenu } = props;
    const { i18n } = useTranslation();
    const { t } = useTranslate();
    const dispatch = useDispatch();

    const isActivePlanningMode = useSelector((state: any) => state.system.planningModeisActive);
    const currentSystemModule = useSelector((state: any) => state.user.user.settings.activeSystemModule);
    const activeSystemModules = useSelector((state: any) => state.company.company.systemModules);
    const [systemModuleList, setSystemModuleList] = useState<any>(null);
    const [isPlanningMode, setIsPlanningMode] = useState(!isEmpty(sessionService.getPlanningData()));

    const classes = useStyles();

    const changeSystemModule = useCallback(moduleId => {
        if (moduleId !== currentSystemModule && isFunction((window as any).updatePhpSystemModule)) {
            (window as any).updatePhpSystemModule(moduleId); /*TODO: php - call php session update function */
            dispatch(userActions.setActiveSystemModule(moduleId));
        }
    }, [currentSystemModule, dispatch]);

    useEffect(() => {
        const isSelectedModuleVisible = checkSystemModuleIsVisible(currentSystemModule, isActivePlanningMode);

        if (!isSelectedModuleVisible) {
            changeSystemModule(SYSTEM.SYSTEM_MODULE_TYPE.CROP_PRODUCTION);
        }
    }, [isActivePlanningMode, currentSystemModule, changeSystemModule]);

    //init system module list
    useEffect(() => {
        if (currentSystemModule && activeSystemModules && i18n) {
            setSystemModuleList(getSystemModuleList(i18n, activeSystemModules, currentSystemModule));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentSystemModule, activeSystemModules, i18n]);

    return (
        <div className={classes.userMenuContentWrapper}>
            <div className={classes.contentContainers}>
                {defaultInfoLoaded && <PlanningMode setIsPlanningMode={setIsPlanningMode} closeUserMenu={closeUserMenu} />}

                {!isPlanningMode ? (
                    <div>
                        <span className={classes.contentTitles}>
                            {t('userMenu.activeModule', 'Active module')}
                        </span>
                        <div>
                            {systemModuleList?.map(
                                systemModule => systemModule.isVisible && (
                                    <button
                                        id="reactModuleButton" /*php - php use this variable */
                                        type="button"
                                        key={systemModule.eventKey}
                                        className={+systemModule.id === +currentSystemModule ? classes.selectedSystemModuleButtons : classes.systemModuleButtons}
                                        style={systemModule.style}
                                        title={systemModule.title}
                                        aria-label={systemModule.title}
                                        onClick={() => changeSystemModule(systemModule.id)}
                                        module_id={systemModule.id} /*php - php use this variable */
                                    />
                                ),
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
            <div className={classes.contentContainers}>
                <span className={classes.contentTitles}>
                    {t('userMenu.companies', 'Companies')}
                </span>
                <div className={classes.multiselect}>
                    <CompanyMultiselect multiselectId="companyMultiselect" />
                </div>
            </div>
        </div>
    );
}
