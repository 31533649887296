import React from 'react';
import { createUseStyles } from 'react-jss';

import DropDownSorter from './DropDownSorter/DropDownSorter';
import DropDownSearcher from './DropDownSearcher/DropDownSearcher';

const useStyles = createUseStyles((theme: any) => ({
    itemButton: {
        height: 40,
        padding: [0, 15],
        color: theme.color.jet,
        fontSize: 15,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.hover,
            color: theme.color.main,
            textDecoration: 'none',

            '& $itemButtonBeforIcon': { //icon hover color
                fill: theme.color.main,
            },
        },
        '&:focus': {
            textDecoration: 'none',
            outline: 'none',
        },
        '&:active': {
            outline: 'none',
        },
    },
    itemButtonBeforIcon: {
        verticalAlign: 'middle',
        width: 20,
        height: 20,
        marginRight: 10,
        fill: theme.color.jet,
        display: 'block',
    },
    itemButtonText: {
        display: 'inline-block',
    },
}));

export default function TableHeaderColumnDropDown(props) {
    const { setShowColumnDropDown, column } = props;
    const { tableKey, isBasePageTable } = props; //simple table
    const { isSortable } = column;
    const classes = useStyles();

    return (
        <>
            {isSortable
                && <DropDownSorter
                    column={column}
                    setShow={setShowColumnDropDown}
                    className={classes}
                    tableKey={tableKey}
                    isBasePageTable={isBasePageTable}
                />
            }

            <DropDownSearcher
                setShow={setShowColumnDropDown}
                className={classes}
                tableKey={tableKey}
                isBasePageTable={isBasePageTable}
            />
        </>
    );
}
