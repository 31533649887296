import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty, isFunction } from 'lodash';
import Button from '@baseComponents/buttons/Button';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    editButtonWrapper: {
        cursor: (props: any) => (props.isDisabled ? 'default !important' : 'pointer !important'),
        height: '100%',
        width: '100%',
        alignItems: 'center',
        display: 'flex',
    },
    editButton: {
        width: '100%',
        '& div': {
            padding: 0,
        },
        '& svg': {
            fill: (props: any) => (props.isDisabled ? theme.color.gray : theme.color.jet),
            marginRight: 0,
        },
    },
}));

export default function EditButtonCell(props) {
    const { rowData, editFunction, className, isUseRefreshCallback } = props;
    const [isDisabled, setIsDisabled] = useState(true);
    const classes = useStyles({ isDisabled });
    const [isDisabledOnClick, setIsDisabledOnClick] = useState(false);
    const refreshCellCallback = () => {
        setIsDisabledOnClick(false);
    };

    function ignoreRowSelection(event) {
        event.stopPropagation();
    }

    useEffect(() => {
        if (editFunction && !isEmpty(rowData)) {
            setIsDisabled(isFunction(editFunction?.isDisabled) ? editFunction.isDisabled(rowData) : false);
        }
    }, [editFunction, rowData]);

    function onClickButton() {
        if (isFunction(editFunction.onClick) && !isDisabledOnClick) {
            editFunction.onClick(rowData, refreshCellCallback);

            if (isFunction(refreshCellCallback) && isUseRefreshCallback) {
                setIsDisabledOnClick(true);
            }
        }
    }

    if (!editFunction) {
        return null;
    }

    return (
        <div className={clsx(classes.editButtonWrapper, className)} onClick={ignoreRowSelection} onKeyDown={ignoreRowSelection} role="button" tabIndex={0}>
            <Button
                className={classes.editButton}
                ignoreOtherClick
                size="small"
                type="secondary"
                iconId="icon-edit"
                iconPosition="before"
                onClick={() => (editFunction?.onClick && !isDisabled) && onClickButton()}
                disabled={isDisabled}
                title={editFunction?.title}
            />
        </div>
    );
}
