import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import { isFunction } from 'lodash';
import { useDispatch } from 'react-redux';
import useTranslate from '@i18n/useTranslate';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ERRORS } from 'shared/src/constants';
import FormGroup from '@customComponents/form/FormGroup';
import { OpenModalButton } from '@baseComponents/modals';
import Input from '@baseComponents/inputs/Input';
import Radio from '@baseComponents/controls/Radio';
import Button from '@baseComponents/buttons/Button';
import CultureDropdown from '@customComponents/dropdowns/cultureDropdown/CultureDropdown';
import { ModalBody, ModalHeader, ModalContentWrapper, ModalFooter } from '@baseComponents/modals/modalNew';
import VarietyStrainModal from '@customComponents/modals/varietyStrainModal/VarietyStrainModal';
import { analysisService } from '@services';
import { alertActions } from '@states/actions';

const useStyles = createUseStyles({
    modalWrapper: {
        width: 400,
        height: 'auto',
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        padding: [12, 30],
    },
    cultureDropDown: {
        maxHeight: 200,
    },
    varietyCheckBox: {
        '& > span': {
            marginLeft: 0,
        },
    },
    formGroup: {
        marginTop: 8,
        marginBottom: 8,
    },
    footerButton: {
        width: '100%',
    },
    modalFooter: {
        padding: [0, 30],
    },
    flexRow: {
        display: 'flex',
        '& > *': {
            marginBottom: 0,
        },
        '& > *:not(:first-child)': {
            marginLeft: 16,
        },
    },
});

export default function CultureAndVarietyEditModal(props) {
    const { hideModal, defaultCultureId, onSuccess, closeModalOnSuccess = true } = props;
    const classes = useStyles();
    const { t } = useTranslate();
    const dispatch = useDispatch();

    const [selectedVarietyName, setSelectedVarietyName] = useState();

    const [validationSchema] = useState(
        yup.object().shape({
            cultureId: yup.number()
                .typeError(t('newCultureVarietyModal.specifyCulture', 'Specify the culture'))
                .required(t('newCultureVarietyModal.specifyCulture', 'Specify the culture')),
            varietyName: yup.string()
                .typeError(t('newCultureVarietyModal.specifyVarietyName', 'Specify the name'))
                .required(t('newCultureVarietyModal.specifyVarietyName', 'Specify the name')),
        }),
    );

    const [defaultValues] = useState({
        isActive: true,
        cultureId: defaultCultureId,
        varietyName: '',
        varietyStrainId: 0,
    });

    const { handleSubmit, watch, getValues, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            ...defaultValues,
        },
        mode: 'onChange',
    });

    async function onSubmit(formData) {
        try {
            const updateResult = await analysisService.setVarietyStrains({
                ...formData,
            });

            if (updateResult) {
                onSuccess(updateResult);
            }

            if (closeModalOnSuccess && isFunction(hideModal)) {
                hideModal();
            }
        } catch (error) {
            if (error.statusCode === ERRORS.HTTP_STATUS_CODES.DUPLICATED_ENTRY) {
                dispatch(alertActions.errorAlert(t('default.duplicatedEntry', 'Saving failed, duplicated data.')));
            } else {
                dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed.')));
            }
        }
    }

    return (
        <ModalContentWrapper className={classes.modalWrapper}>
            <ModalHeader>
                {t('newCultureVarietyModal.modalTitle', 'Add New Variety')}
            </ModalHeader>

            <ModalBody className={classes.modalBody}>
                <FormGroup label={t('newCultureVarietyModal.status', 'Status')} className={classes.formGroup}>
                    <div className={classes.flexRow}>
                        <Radio text={t('newCultureVarietyModal.active', 'Active')} checked={!!watch('isActive')} onChange={() => setValue('isActive', true)} />
                        <Radio text={t('newCultureVarietyModal.inactive', 'Inactive')} checked={!(watch('isActive'))} onChange={() => setValue('isActive', false)} />
                    </div>
                </FormGroup>

                <FormGroup label={t('newCultureVarietyModal.culture', 'Culture')} required className={classes.formGroup} hasValue={getValues('cultureId')} errorMessage={errors?.cultureId?.message}>
                    <CultureDropdown className={classes.cultureDropDown} cultureId={watch('cultureId')} setCultureId={cultureId => setValue('cultureId', cultureId)} />
                </FormGroup>

                <FormGroup label={t('newCultureVarietyModal.varietyStrain', 'Variety Strain')} className={classes.formGroup} errorMessage={errors?.partnerId?.message} hasValue={watch('varietyStrainId')}>
                    <OpenModalButton
                        modalCloseButton
                        onDelete={() => { setValue('varietyStrainId', 0); setSelectedVarietyName(); }}
                        hasDeleteButton
                        currentSelectedValue={watch('varietyStrainId')}
                        modalComponent={VarietyStrainModal}
                        modalComponentProps={{}}
                        id={watch('varietyStrainId')}
                        name={selectedVarietyName}
                        onChange={varietyStrain => {
                            setValue('varietyName', varietyStrain?.name);
                            setValue('varietyStrainId', varietyStrain?.id);
                            setSelectedVarietyName(varietyStrain?.name);
                        }}
                    />
                </FormGroup>

                <FormGroup label={t('newCultureVarietyModal.name', 'Name')} required className={classes.formGroup} hasValue={getValues('varietyName')} errorMessage={errors?.varietyName?.message}>
                    <Input value={watch('varietyName')} onChange={event => setValue('varietyName', event?.target?.value)} />
                </FormGroup>
            </ModalBody>

            <ModalFooter className={classes.modalFooter}>
                <Button className={classes.footerButton} onClick={handleSubmit(onSubmit)}>
                    {t('default.save', 'Save')}
                </Button>
            </ModalFooter>
        </ModalContentWrapper>
    );
}
