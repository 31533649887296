import { createUseStyles } from 'react-jss';
import { useNumberFormat } from '@common_modules/numberFormat';
import clsx from 'clsx';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        height: 18,
        fontSize: 12,
        fontWeight: 500,
        lineHeight: 1.5,
        color: theme.color.jet,
        display: 'block',
        width: '100%',
        textAlign: 'right',
    },
}));

export default function Percent(props) {
    const { value, style } = props;
    const { numberFormatter } = useNumberFormat();
    const classes = useStyles();

    return (
        <div className={clsx(classes.baseStyle, style)}>
            {Number(value) >= 100 ? numberFormatter.getFormattedPercentage(100, true) : numberFormatter.getFormattedPercentage(value, true)}
        </div>
    );
}
