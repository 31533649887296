export const CULTIVATION_PERIODS = {
    DEFAULT_COST_FILTER_DATE: {
        START_MONTH: '08',
        START_DAY: '01',
        END_MONTH: '07',
        END_DAY: '31',
    },
    INCOME_TYPE: {
        SUPPORT: 1,
        WILDLIFE_DAMAGE: 2,
        INSURANCE: 3,
        OTHER_INCOME: 4,
    },
    NUMBER_OF_SHOWN_CHARTS: 5,
    TYPE: {
        PERIOD: 1,
        PERIOD_GROUP: 2,
    },
    PARTFIELD_TYPE: {
        PERIOD: 0,
        PERIOD_GROUP: 1,
    },
    UPDATE_COSTS: {
        WORK_OPERATION_COSTS: 1,
        LAND_LEAS_FEE: 2,
        DRYING_FEE: 3,
    },
    OWNER_TYPE: {
        OWN: 1,
        FOREIGN: 2,
    },
    FIELD_FEATURES_ICONS: [
        {
            featureId: 1,
            iconId: 'icon-field-feature-precision-farming'
        },
        {
            featureId: 2,
            iconId: 'icon-field-feature-irrigation'
        },
        {
            featureId: 3,
            iconId: 'icon-field-feature-bio'
        },
        {
            featureId: 4,
            iconId: 'icon-field-feature-bag'
        },
        {
            featureId: 5,
            iconId: 'icon-field-feature-organic-manure'
        },
        {
            featureId: 6,
            iconId: 'icon-field-feature-no-till'
        },
        {
            featureId: 7,
            iconId: 'icon-field-feature-strip-till'
        },
        {
            featureId: 8,
            iconId: 'icon-field-feature-co2'
        },
    ],
    QUALIFICATION: {
        VERY_BAD: 1,
        BAD: 2,
        MIDDLE: 3,
        GOOD: 4,
        VERY_GOOD: 5,
        TOP: 6,
    },
    COST_FUNCTIONS: {
        OPEN_COST: 1,
        OPEN_PRODUCT_COSTS: 2,
        OPEN_CATEGORIZATION: 3,
        DOWNLOAD_FIELD_FEATURE: 4,
        OPEN_ATTACHED_FILES: 5,
    },
    COST_VIEW_TABS: {
        WORKOPERATION_COSTS: 1,
        CHARGED_COSTS: 2,
    },
    NHA_CATEGORIES: {
        NHA_CATEGORY_1: 1,
        NHA_CATEGORY_2: 2,
        NHA_CATEGORY_3: 3,
        NHA_CATEGORY_4: 4,
    },
    CULTURE_STRAIN: {
        CULTURE_IDS: {
            CORN: 1,
            CABBAGE_RAPE: 2,
            WHEAT: 3,
            SUNFLOWER: 4,
        },
    },
};
