/* eslint-disable no-nested-ternary */
import { createUseStyles } from 'react-jss';
import Svg from '@baseComponents/Svg';
import Button from '@baseComponents/buttons/Button';
import useTranslate from '@i18n/useTranslate';
import ReactHtmlParser from 'react-html-parser';
import { isNull } from 'lodash';
import { routerHelper } from '@utils/router/RouterHelper';
import { dateTimeUtils } from 'shared/src/modules';
import { MESSAGES } from 'shared/src/constants';

const useStyles = createUseStyles((theme: any) => ({
    openedMessageHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: '25px',
        borderBottom: `1px solid ${theme.color.pearl}`,
    },
    openedMessageSubject: {
        fontSize: '18px',
        fontWeight: '500',
        color: theme.color.jet,
    },
    openedMessageClose: {
        width: '40px',
        height: '40px',
        border: 'none',
        borderRadius: '50px',
        backgroundColor: theme.color.pearl,
    },
    menuBottomControlsSearch: {
        width: '20px',
        height: '20px',
    },
    openedMessageHeading2: {
        paddingTop: '25px',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: '30px',
    },
    heading2Left: {
        display: 'flex',
    },
    openedMessageIconContainer: {
        backgroundColor: theme.color.white,
        borderRadius: '50%',
        height: 36,
        width: 36,
    },
    openedMessageIcon: {
        height: 36,
        width: 36,
        minWidth: 36,
        minHeight: 36,
        display: 'block',
        backgroundImage: (props: any) => (props.userProfilePicUrl ? `url("${props.userProfilePicUrl}"), url("/styles/img/ures-profil.png")` : 'url("/styles/img/av_logo_slidebar_left.png")'),
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: (props: any) => (props.userProfilePicUrl ? 'contain' : '20px'),
        backgroundPositionX: (props: any) => (props.userProfilePicUrl ? 'center' : '9px'),
        borderRadius: '50%',
        border: (props: any) => (props.userProfilePicUrl ? `1px solid ${theme.color.pearl}` : `1px solid ${theme.color.main}`),
    },
    openedMessageName: {
        paddingLeft: '10px',
        fontSize: '15px',
        fontWeight: '500',
        color: theme.color.jet,
    },
    openedMessageDate: {
        fontSize: '13px',
        fontWeight: 'normal',
    },
    parserParent: {
        fontSize: '15px',
        color: theme.color.jet,
        overflowX: 'auto',
        overflowY: 'auto',
    },
    replyButtonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '30px',
    },
    replyButtonLeftContainer: {
        display: 'flex',
    },
    messageToManyButton: {
        marginLeft: '5px',
    },
    deleteMessageButton: {
        border: `1px solid ${theme.color.destructive}`,
        backgroundColor: 'transparent',
        borderRadius: '5px',
        paddingLeft: '12px',
        paddingRight: '12px',
        paddingTop: '6px',
        paddingBottom: '6px',
    },
    deleteMessageIcon: {
        fill: theme.color.destructive,
        width: '25px',
        height: '20px',
    },
}));

export default function MessageDetails(props) {
    const { selectedMessage, user, handleSelectedMessageClose, answerToOne, answerToMany, handleDeleteOutgoingMessage, handleDeleteIncomingMessage, handleDeleteSystemMessage } = props;
    const userProfilePicUrl = getProfilePic();
    const classes = useStyles({ userProfilePicUrl });
    const { t } = useTranslate();

    const isSystemMessageDeleteAllowed = selectedMessage?.isDeleteAllowed === 1;
    let addressNumber = 0;

    const getRecipientsForMultiMessage = () => [selectedMessage?.user?.name, ...selectedMessage?.messageToUser?.filter(messageObj => messageObj?.user?.id !== user?.id)?.map(messageItem => messageItem?.user?.name)]?.join(', ');

    function getProfilePic() {
        if (isNull(selectedMessage)) {
            return null;
        }

        if (selectedMessage.direction === MESSAGES.TYPE.INCOMING) {
            return selectedMessage.user ? routerHelper.getUserProfilePicUrl(selectedMessage?.user?.id) : null;
        }
        return selectedMessage.messageToUser ? routerHelper.getUserProfilePicUrl(selectedMessage?.messageToUser?.[0]?.userId) : null;
    }

    return (
        <div>
            <div className={classes.openedMessageHeading}>
                <div className={classes.openedMessageSubject}>{selectedMessage && selectedMessage.subject}</div>
                <button onClick={handleSelectedMessageClose} className={classes.openedMessageClose} type="button">
                    <Svg style={classes.menuBottomControlsSearch} iconId="icon-close" />
                </button>
            </div>
            <div className={classes.openedMessageHeading2}>
                <div className={classes.heading2Left}>
                    <div className={classes.openedMessageIconContainer}>
                        <div className={classes.openedMessageIcon} />
                    </div>
                    <div className={classes.openedMessageName}>
                        {selectedMessage && selectedMessage.type === MESSAGES.TYPE.INCOMING && (selectedMessage.userId ? (selectedMessage?.messageToUser?.length > 1 ? getRecipientsForMultiMessage() : selectedMessage.user.name) : t('messages.systemMessage', 'System message'))}
                        {selectedMessage && selectedMessage.type === MESSAGES.TYPE.OUTGOING && selectedMessage.messageToUser && selectedMessage?.messageToUser?.map(messageItem => {
                            addressNumber += 1;
                            if (addressNumber === 1) {
                                return `${messageItem.user.name}`;
                            }
                            return `, ${messageItem.user.name}`;
                        })}
                        <div className={classes.openedMessageDate}>{selectedMessage && dateTimeUtils.getDateTime(selectedMessage.date)}</div>
                    </div>
                </div>

                { selectedMessage && selectedMessage.type === MESSAGES.TYPE.OUTGOING
                && (<button className={classes.deleteMessageButton} onClick={handleDeleteOutgoingMessage} type="button">
                    <Svg style={classes.deleteMessageIcon} iconId="icon-bin" />
                    </button>)}
                { selectedMessage && selectedMessage.type === MESSAGES.TYPE.INCOMING && selectedMessage.userId
                && (<button className={classes.deleteMessageButton} onClick={handleDeleteIncomingMessage} type="button">
                    <Svg style={classes.deleteMessageIcon} iconId="icon-bin" />
                    </button>)}
                {!isSystemMessageDeleteAllowed ? null : (
                    <button className={classes.deleteMessageButton} onClick={handleDeleteSystemMessage} type="button">
                        <Svg style={classes.deleteMessageIcon} iconId="icon-bin" />
                    </button>
                )}
            </div>
            <div className={classes.parserParent}>
                {ReactHtmlParser(selectedMessage && selectedMessage.message)}
            </div>

            <div className={classes.replyButtonContainer}>
                <div className={classes.replyButtonLeftContainer}>
                    { selectedMessage && selectedMessage.type === MESSAGES.TYPE.INCOMING && selectedMessage.userId && (
                        <div>
                            <Button onClick={answerToOne}>{t('messages.reply', 'Reply')}</Button>
                        </div>
                    )}
                    { selectedMessage && selectedMessage.type === MESSAGES.TYPE.INCOMING && selectedMessage.userId && (selectedMessage?.messageToUser?.length > 1) && (
                        <div className={classes.messageToManyButton}>
                            <Button onClick={answerToMany}>{t('messages.reply_all', 'Reply to all')}</Button>
                        </div>
                    )}
                </div>
            </div>
        </div>);
}
