import { useTranslation } from 'react-i18next';
import TexButton from '../../buttons/TextButton';

export default function AddNewButton(props) {
    const { addNew } = props;
    const { t } = useTranslation();

    return (
        <TexButton iconId="icon-add" iconPosition="before" onClick={addNew}>
            {t('default.addNewItem', 'Add new item')}
        </TexButton>
    );
}
