import { FILES } from '../constants';

export const fileUtils = {
    getFileType,
    encodeFile,
    convertFileSize,
    decodeFile,
};

function getFileType(fileName) {
    if (!fileName) {
        return null;
    }

    const parts = fileName.split('.');
    const fileType = parts.length > 1 ? parts[parts.length - 1].toLowerCase() : null;

    switch (fileType) {
        case 'docx':
        case 'doc': 
            return 'doc';
        case 'xls':
        case 'xlsx': 
            return 'xls';
        case 'ppt':
        case 'pptx': 
            return 'ppt';
        case 'zip': 
            return 'zip';
        case 'pdf': 
            return 'pdf';
        case 'jpg':
        case 'jpeg':
        case 'gif':
        case 'png':
        case 'mp4':
            return 'image';
        case 'mid':
        case 'midi':
        case 'wav':
            return 'audio';
        default:
            return 'default';
    }
}

async function encodeFile(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

function decodeFile(fileString) {
    const matches = fileString.replace(/^data:([A-Za-z-+/]+);base64,/, "");

    return Buffer.from(matches, 'base64');
}

function convertFileSize(fileSize, fileUnit) {
    if (!fileSize) {
        return fileSize;
    }
    return (fileSize / FILES.SIZE_EXCHANGE[fileUnit]).toFixed(FILES.ROUND[fileUnit]);
}
