import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isFunction, isNull } from 'lodash';

import { TABLE } from 'shared/src/constants';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import useLoadMultiLineViewType from '@customComponents/multilineView/useLoadMultiLineViewType';

export default function useAggregateHooks(props) {
    const { aggregateType, aggregateFunction, tableData, columnId, componentProps = {}, column } = props;
    const { shiftNumberId } = componentProps;
    const { aggregateDivisor, aggregateProps, aggregateFixedDivisor } = column;
    const [aggregateValue, setAggregateValue] = useState(null);
    const isVisibleCurrencySwitch = useSelector((state: any) => state.company.company.currency.isVisibleCurrencySwitch);
    const isSelectedCurrencySwitch = useSelector((state: any) => state.company.company.currency.isSelectedCurrencySwitch);

    const multilineViewType = useLoadMultiLineViewType({ tableKey: componentProps?.tableKey });

    //get aggregate function and calculate aggregate value
    useEffect(() => {
        function sumFunctionSelector(dataArray, keyString, shiftNumberString, aggregateFunctionRef = null) {
            let sumValue = 0;
            const dynamicAggregateFunction = aggregateFunctionRef || sumFunction;
            const dynamicAggregateWithDivisorFunction = aggregateFunctionRef || sumFunctionWithDisivor;
            const dynamicAggregateWithFixedDivisorFunction = aggregateFunctionRef || sumFunctionWithFixedDisivor;
            const dynamicAggregateWithShiftFunction = aggregateFunctionRef || sumWithShiftNumber;
            const dynamicAggregateWithShiftAndDivisorFunction = aggregateFunctionRef || sumWithShiftNumberAndDivisor;

            switch (multilineViewType) {
                case TABLE.VIEW_TYPE.SIMPLE:
                    if (shiftNumberId) {
                        sumValue = dynamicAggregateWithShiftFunction(dataArray, keyString, shiftNumberString); //sumWithShiftNumber(dataArray, keyString, shiftNumberString);
                    } else {
                        sumValue = dynamicAggregateFunction(dataArray, keyString, aggregateProps, aggregateDivisor); //sumFunction(dataArray, keyString);
                    }
                    break;
                case TABLE.VIEW_TYPE.VALUE_PER_UNIT:
                    // value/unit sum calculation
                    if (aggregateDivisor && shiftNumberId) {
                        sumValue = dynamicAggregateWithShiftAndDivisorFunction(dataArray, keyString, shiftNumberString); //sumWithShiftNumberAndDivisor(dataArray, keyString, shiftNumberString, aggregateDivisor);
                    } else if (aggregateDivisor && !shiftNumberId) {
                        if (!aggregateFixedDivisor) {
                            sumValue = dynamicAggregateWithDivisorFunction(dataArray, keyString, aggregateProps, aggregateDivisor); //sumFunctionWithDisivor(dataArray, keyString, aggregateDivisor);
                        } else {
                            sumValue = dynamicAggregateWithFixedDivisorFunction(dataArray, keyString, aggregateProps, aggregateFixedDivisor);
                        }
                    }
                    // value sum calculation
                    if (!aggregateDivisor && shiftNumberId) {
                        sumValue = dynamicAggregateWithShiftFunction(dataArray, keyString, shiftNumberString); //sumWithShiftNumber(dataArray, keyString, shiftNumberString);
                    } else if (!aggregateDivisor && !shiftNumberId) {
                        sumValue = dynamicAggregateFunction(dataArray, keyString, aggregateProps); //sumFunction(dataArray, keyString);
                    }
                    break;
                default:
                    sumValue = sumFunction(dataArray, keyString);
                    break;
            }

            return sumValue;
        }

        if ((aggregateType || aggregateFunction) && !isEmpty(tableData) && columnId) {
            let aggregateColumnId = columnId;
            let columnIdExtension = '';

            //secondary currency extension
            if (isVisibleCurrencySwitch && isSelectedCurrencySwitch && column.hasSecondaryCurrency) {
                columnIdExtension = 'SC';
            }

            //get column id by view type and currency type
            aggregateColumnId = `${columnId}${columnIdExtension}`;

            //get sum value by aggregate function
            if (aggregateFunction && isFunction(aggregateFunction)) {
                setAggregateValue(aggregateFunction(tableData, aggregateColumnId));
            } else if (column.aggregateType) {
                let sumValue = 0;

                switch (aggregateType) {
                    case TABLE.AGGREGATE.SUM:
                        sumValue = sumFunctionSelector(tableData, aggregateColumnId, shiftNumberId);
                        break;
                    case TABLE.AGGREGATE.SUM_SPECIFIC_COST:
                        sumValue = sumFunctionSelector(tableData, aggregateColumnId, shiftNumberId, sumSpecificCost);
                        break;
                    case TABLE.AGGREGATE.SUM_CROP_YIELD:
                        sumValue = sumFunctionSelector(tableData, aggregateColumnId, shiftNumberId, cropYield);
                        break;
                    case TABLE.AGGREGATE.SUM_VALUE_PER_HECTARE:
                        sumValue = sumFunctionSelector(tableData, aggregateColumnId, shiftNumberId, sumValuePerHectare);
                        break;
                    case TABLE.AGGREGATE.SUM_MARGIN:
                        sumValue = sumFunctionSelector(tableData, aggregateColumnId, shiftNumberId, sumMargin);
                        break;
                    default:
                        sumValue = sumFunction(tableData, aggregateColumnId);
                        break;
                }
                setAggregateValue(sumValue);
            }
        } else {
            setAggregateValue(currentValue => (!isEmpty(currentValue) ? currentValue : null));
        }
    }, [aggregateType, aggregateFunction, tableData, columnId, componentProps, multilineViewType, shiftNumberId, column, aggregateDivisor, aggregateProps, isVisibleCurrencySwitch, isSelectedCurrencySwitch, aggregateFixedDivisor]);

    function sumFunction(dataArray, keyString) {
        return arrayUtils.getSumByKeyString(dataArray, keyString);
    }

    function sumWithShiftNumber(dataArray, keyString, shiftNumberString) {
        if (dataArray && keyString && shiftNumberString) {
            return dataArray.reduce((sum, dataItem) => {
                const shiftNumber = Number(arrayUtils.getArrayItemByKeyString(dataItem, shiftNumberString) || 1);
                return sum + Number(arrayUtils.getArrayItemByKeyString(dataItem, keyString) || 0) * shiftNumber;
            }, 0);
        }
        return 0;
    }

    // value/unit sum calculation
    function sumFunctionWithDisivor(dataArray, keyString, __, divisorId) {
        const summedValue = arrayUtils.getSumByKeyString(dataArray, keyString);
        const summedDivisorValue = arrayUtils.getSumByKeyString(dataArray, divisorId);

        if (summedValue && summedDivisorValue) {
            return summedValue / summedDivisorValue;
        }

        return 0;
    }

    function sumFunctionWithFixedDisivor(dataArray, keyString, __, divisorValue) {
        const summedValue = arrayUtils.getSumByKeyString(dataArray, keyString);

        if (summedValue && divisorValue) {
            return summedValue / divisorValue;
        }

        return 0;
    }

    function sumWithShiftNumberAndDivisor(dataArray, keyString, shiftNumberString, divisorId) {
        let summedValue = 0;
        let summedDivisorValue = 1;

        if (dataArray && divisorId) {
            summedDivisorValue = arrayUtils.getSumByKeyString(dataArray, divisorId);
        }

        if (dataArray && keyString && shiftNumberString && summedDivisorValue) {
            summedValue = dataArray.reduce((sum, dataItem) => {
                const shiftNumber = Number(arrayUtils.getArrayItemByKeyString(dataItem, shiftNumberString) || 1);
                return sum + Number(arrayUtils.getArrayItemByKeyString(dataItem, keyString) || 0) * shiftNumber;
            }, 0);
        }

        if (summedValue && summedDivisorValue) {
            return summedValue / summedDivisorValue;
        }

        return 0;
    }

    //custom aggregate functions
    function sumMargin(dataArray, __, aggregatePropsObj) {
        const { incomeColumnId, costColumnId, rowColumnId, areaColumnId } = aggregatePropsObj || {};
        const periodIds = [];
        let summedCost = 0;
        let summedIncome = 0;
        let summedArea = 0;

        let SC = isVisibleCurrencySwitch && isSelectedCurrencySwitch ? 'SC' : '';

        dataArray?.forEach(dataItem => {
            const cost = Number(arrayUtils.getArrayItemByKeyString(dataItem, `${costColumnId}${SC}`) || null);
            const income = Number(arrayUtils.getArrayItemByKeyString(dataItem, `${incomeColumnId}${SC}`) || null);
            const rowId = arrayUtils.getArrayItemByKeyString(dataItem, rowColumnId);
            const area = Number(arrayUtils.getArrayItemByKeyString(dataItem, areaColumnId) || 0);

            if (!isNull(cost)) {
                if (rowId && !periodIds.includes(rowId)) {
                    summedCost += cost;
                    periodIds.push(rowId);
                }
            }

            if (!isNull(income)) {
                summedIncome += income;
            }

            summedArea += area;
        });

        let coverageAmount = (summedIncome - summedCost);

        if (multilineViewType) {
            coverageAmount /= summedArea;
        }

        return coverageAmount;
    }

    function sumSpecificCost(dataArray, __, aggregatePropsObj) {
        const { aggregateColumnId, quantityColumnId, rowColumnId } = aggregatePropsObj || {};
        const periodIds = [];
        let summedCost = 0;
        let summedQuantity = 0;
        
        let SC = isVisibleCurrencySwitch && isSelectedCurrencySwitch ? 'SC' : '';

        dataArray?.forEach(dataItem => {
            const cost = Number(arrayUtils.getArrayItemByKeyString(dataItem, `${aggregateColumnId}${SC}`) || null);
            const rowId = arrayUtils.getArrayItemByKeyString(dataItem, rowColumnId);
            const quantity = Number(arrayUtils.getArrayItemByKeyString(dataItem, quantityColumnId) || 0);

            if (!isNull(cost)) {
                if (rowId && !periodIds.includes(rowId)) {
                    summedCost += cost;
                    periodIds.push(rowId);
                }
            }

            if (quantity > 0) {
                summedQuantity += quantity;
            }
        });

        if (summedQuantity > 0) {
            return summedCost / summedQuantity;
        }

        return 0;
    }

    function cropYield(dataArray, __, aggregatePropsObj) {
        const { areaColumnId, quantityColumnId } = aggregatePropsObj || {};
        let summedArea = 0;
        let summedQuantity = 0;

        dataArray?.forEach(dataItem => {
            const area = Number(arrayUtils.getArrayItemByKeyString(dataItem, areaColumnId) || 0);
            const quantity = Number(arrayUtils.getArrayItemByKeyString(dataItem, quantityColumnId) || 0);

            if (area && quantity) {
                summedArea += area;
                summedQuantity += quantity;
            }
        });

        if (summedArea > 0) {
            return summedQuantity / summedArea;
        }

        return 0;
    }

    function sumValuePerHectare(dataArray, __, aggregatePropsObj, aggregateDivisorId) {
        const { sumValueColumnId } = aggregatePropsObj || {};
        let summedArea = 0;
        let summedValue = 0;

        const secondaryCurrencyExtension = isVisibleCurrencySwitch && isSelectedCurrencySwitch ? 'SC' : '';

        dataArray?.forEach(dataItem => {
            const area = Number(arrayUtils.getArrayItemByKeyString(dataItem, aggregateDivisorId) || 0);
            const columnValue = Number(arrayUtils.getArrayItemByKeyString(dataItem, `${sumValueColumnId}${secondaryCurrencyExtension}`) || 0);

            if (columnValue) {
                summedValue += columnValue;
            }
            if (area) {
                summedArea += area;
            }
        });

        if (summedArea > 0) {
            return summedValue / summedArea;
        }

        return 0;
    }

    return {
        aggregateValue,
    };
}
