import { useCallback } from 'react';
import { useSelector } from 'react-redux';

export default function useRowSelection(props) {
    const { isSelectRowColumn, isBasePageTable } = props;
    const isEnabledGroupOperation = useSelector((state: any) => {
        if (isSelectRowColumn) {
            return state.basePage.isEnabledGroupOperation;
        }
        return null;
    });

    //check fixed column has width
    const isShowSelectColumn = useCallback(() => {
        if (isSelectRowColumn && isEnabledGroupOperation) {
            return true;
        }
        return false;
    }, [isSelectRowColumn, isEnabledGroupOperation]);

    return {
        isShowSelectColumn: isBasePageTable ? isShowSelectColumn() : true,
    };
}
