import clsx from 'clsx';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles((theme: any) => ({
    label: {
        textTransform: 'uppercase',
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 1.36,
        color: theme.color.jet,
        marginBottom: 5,
    },
    dot: {
        height: 6,
        width: 6,
        borderRadius: '50%',
        backgroundColor: (props: any) => (props.isValue ? theme.color.main : theme.color.destructive),
        display: 'inline-block',
        marginLeft: 2,
        marginBottom: 3,
    },
}));

export default function Label(props) {
    const { label, required, isValue, className } = props;
    const classes = useStyles({ isValue } as any);

    return (
        <div className={clsx(classes.label, 'labelText', className)}>
            {label}
            {required && label && <span className={classes.dot} />}
        </div>
    );
}
