import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { dataListsActions } from '@states/actions/dataListsActions';
import Checkbox from '@baseComponents/controls/Checkbox';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        height: 'auto',
        fontSize: 12,
        lineHeight: 1.5,
        color: theme.color.jet,
        ...theme.overFlowText,
        ...theme.disableOutline,
        ...theme.disableSelectionColor,
    },
}));

export default function CheckboxCell(props) {
    const { id, style, rowData } = props;
    const [isSelectedRow, setIsSelectedRow] = useState(false);
    const dispatch = useDispatch();
    const checkedItemList = useSelector((state: any) => state.dataLists.checkedItems);
    const checkedList = checkedItemList && checkedItemList.map(checkedObj => checkedObj.id);
    const classes = useStyles();

    useEffect(() => {
        if (id && checkedList) {
            const isChecked = checkedList.includes(id);
            setIsSelectedRow(isChecked);
        }
    }, [id, checkedList]);

    function onClick(event) {
        event.stopPropagation();
    }

    function onChangeCheckBox() {
        const isChecked = checkedList && checkedList.includes(id);
        if (!isChecked) {
            dispatch(dataListsActions.addCheckedItem(rowData));
        } else {
            dispatch(dataListsActions.removeCheckedItem(id));
        }
    }

    return (
        <div className={clsx(classes.baseStyle, style)} onClick={onClick} onKeyDown={onClick} role="button" tabIndex="0">
            <Checkbox checked={isSelectedRow} setChecked={setIsSelectedRow} onChange={onChangeCheckBox} />
        </div>
    );
}
