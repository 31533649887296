import { latLngToPixel } from '@map/utils/mapUtils';
import { isEmpty, isNil } from 'lodash';
import { SKCultureCodeList, SKInfoPanelTabData, SKLpis, getBbox } from '@map/components/SlovakReportsOverlay/slovakReportsUtils';
import { slovakExternalServices } from '@map/services/slovakianReports/slovakReportExternalApis';
import { createLabel } from '@map/utils/mapLabelUtils';
import { InfoPanelCallback, InfoPanelTab } from '../../SlovakInfoPanel/SlovakInfoPanel';

const selectedStyle = { strokeColor: '#FFFFFF', strokeWeight: 3, strokeOpacity: 1, zIndex: 1001 };
const nonSelectedStyle = { fillOpacity: 0, strokeColor: '#FF0000', strokeWeight: 2, strokeOpacity: 1, zIndex: 1001 };

type GetLpisLayerProps = {
  map: google.maps.Map;
  dataLayer: google.maps.Data;
  markers: React.MutableRefObject<google.maps.Marker[]>;
  infoPanelCallback: InfoPanelCallback;
  selectedLpisPolygon: google.maps.Data.Feature | null;
  setSelectedLpisPolygon: (feature: google.maps.Data.Feature | null) => void;
  gsaaYear: number;
};

export const generateLpisTabData = (geomProperties: Partial<SKLpis>, year: number) => ({
    header: `LPIS ${year}`,
    properties: {
        'Skrátený kód dielu': geomProperties.ZKODKD,
        'Výmera dielu [ha]': geomProperties.VYMERA,
        'Kód dielu': geomProperties.KDIEL,
        Lokalita: geomProperties.LOKALITA_ZKODKD,
        'Kultúra dielu': geomProperties.KULTURA,
    },
    isDraw: true,
});

export const updateLpisLayer = async (props: any) => {
    const { gsaaYear, gsaaKODKD, map, dataLayer, markers, selectedLpisPolygon, infoPanelCallback, setSelectedLpisPolygon, setDrawMode } = props;

    if (gsaaKODKD && !selectedLpisPolygon) {
        dataLayer.forEach(feature => {
            const kodkdProperty = feature.getProperty('KODKD');
            if (kodkdProperty === gsaaKODKD) {
                setDrawMode(true);
                setSelectedLpisPolygon(feature);
            } else {
                dataLayer.remove(feature);
            }
        });
    }

    dataLayer.forEach(feature => {
        const centroid: google.maps.LatLng = feature.getProperty('centroid');
        const labelText = feature.getProperty('ZKODKD');

        const label = createLabel(centroid, labelText, map, '#FF0000', '#FFFFFF');
        markers.current.push(label);

        if (selectedLpisPolygon && selectedLpisPolygon.getProperty('ZKODKD') === feature.getProperty('ZKODKD')) {
            dataLayer.overrideStyle(feature, selectedStyle);
        } else {
            dataLayer.overrideStyle(feature, nonSelectedStyle);
        }
    });

    const clickListener = dataLayer.addListener('click', (event: google.maps.Data.MouseEvent) => {
        const clickedFeature = event.feature;

        setSelectedLpisPolygon(clickedFeature);

        const pixelCoordinates = latLngToPixel(map, event.latLng);

        const geomProperties: Partial<SKLpis> = {};
        clickedFeature.forEachProperty((value, property) => {
            geomProperties[property] = value;
        });

        if (pixelCoordinates) {
            const tabData: SKInfoPanelTabData[] = [];
            tabData.push(generateLpisTabData(geomProperties, gsaaYear));

            const onClose = () => {
                dataLayer.forEach(feature => {
                    dataLayer.overrideStyle(feature, nonSelectedStyle);
                });
            };

            infoPanelCallback(true, pixelCoordinates?.x, pixelCoordinates?.y, InfoPanelTab.LPIS, tabData, onClose);
        }

        dataLayer.forEach(feature => {
            dataLayer.overrideStyle(feature, nonSelectedStyle);
        });

        dataLayer.overrideStyle(clickedFeature, selectedStyle);
    });

    return clickListener as Promise<google.maps.MapsEventListener | null>;
};

export default async function getLpisLayer(props: GetLpisLayerProps) {
    const { map, dataLayer, gsaaYear } = props;
    const bbox = getBbox(map);
    const featureCollection = await slovakExternalServices.getLpisFeaturesFromBBox(gsaaYear, encodeURIComponent(JSON.stringify(bbox)));

    if (isEmpty(featureCollection) || isNil(featureCollection.features)) {
        return null;
    }

    dataLayer.addGeoJson(featureCollection);
}
