import { MODULES } from 'shared/src/routing';
import { routerHelper } from '@utils/router/RouterHelper';
import { alertActions, basePageActions } from '@states/actions';
import {
    sessionService, cultivationPeriodsService, reproductionRateService, phpService, cultivationTableService, visiblefarmService, masterDataService,
} from '@services';

import renderModal from '@modals/modalNew/modalFunctions';
import PeriodView from '@contents/cultivationPeriods/cultivationPeriodsManagement/periodViews/PeriodView';
import NewCultivationPeriod from '@contents/cultivationPeriods/cultivationPeriodsManagement/newCultivationPeriod/NewCultivationPeriod';
import DeleteCultivationPeriodModal from '@contents/cultivationPeriods/cultivationPeriodsManagement/deleteCultivationPeriod/DeleteCultivationPeriodModal';
import FixOrUnlockPeriod from '@contents/cultivationPeriods/cultivationPeriodsManagement/fixOrUnlockPeriod/FixOrUnlockPeriod';
import CloseAndOpenCultivationPeriod from '@contents/cultivationPeriods/cultivationPeriodsManagement/closeAndOpenCultivationPeriod/CloseAndOpenCultivationPeriod';
import SoilAnalysisView from '@contents/cultivationPeriods/soilAnalysis/soilAnalysisView/SoilAnalysisView';
import MapModal from '@map/modals/MapModal';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { CultivationPeriodImagesModal } from '@customComponents/modals/cultivationPeriodImagesModal/CultivationPeriodImagesModal';
import CultivationPeriodMeteorologyModal from '@customComponents/modals/cultivationPeriodMeteorologyModal/CultivationPeriodMeteorologyModal';

export const rowFunctions = {
    openMap,
    editPeriod,
    showPeriodModal,

    //TODO: php functions
    fixingOrUnlockPeriod,
    createLPIS,
    editPeriodOnMap,
    showPeriodOnMap,
    downloadFieldFile,
    getAttachedFiles,
    dividePeriod,
    fieldOpenClose,
    deletePeriod,
    showSoilAnalysisViewModal,
    addLocation,
    openAttachedFiles,
    openMeteorologyModal,
};

function openMap(rowData) {
    const { id, mapSvg, yearId } = rowData;

    if (!mapSvg) {
        return;
    }

    renderModal(MapModal, { layerType: BuiltInLayerTypes.CULTIVATION_PERIOD, entityId: +id, zoomEntityId: +id, withBackToGeom: true, productionYearId: +yearId }, true);
}

async function editPeriod(rowData, setPeriodYearFilter) {
    const cultivationPeriodId = rowData?.id;

    const componentProps = {
        cultivationPeriodId,
        setPeriodYearFilter,
        hasCloseDialog: true,
    };
    renderModal(NewCultivationPeriod, componentProps);
}

async function showPeriodModal(rowData) {
    try {
        const { id, reproductionRateId, otherFunctionList, yearId } = rowData;
        const periodValues = {
            cultivationPeriodValues: {
                getSupportList: () => loadAllSupport(id),
                getRegistryNumberList: () => loadAllRegistryNumber(id),
                getCostList: () => getAllCost(rowData),
                getReproductionRateName: () => getReproductionRateName(reproductionRateId),
                getBasicFields: () => cultivationTableService.getCultivationTableToPeriod(id, yearId),
                cultivationPeriodData: rowData,
                functionList: otherFunctionList,
            },
        };
        renderModal(PeriodView, periodValues);
    } catch (error) {
        throw new Error(error);
    }
}

//TODO: php functions
function fixingOrUnlockPeriod(rowData, isFixing) {
    const { id } = rowData;
    const periodIds = [];
    periodIds.push(id);

    renderModal(FixOrUnlockPeriod, { periodIds, isFixing: +isFixing }, false);
}

function createLPIS(rowData, history, event) {
    const { id } = rowData;
    const agentName = sessionService.getAgentName();
    //owner page
    const ownerModuleId = MODULES.CULTIVATION_PERIODS.MODULE_ID;
    const ownerContentId = routerHelper.getContentId(MODULES.CULTIVATION_PERIODS.CONTENTS.CULTIVATION_PERIODS_MANAGEMENT);
    const ownerPage = `${ownerModuleId}/${ownerContentId}`;
    //redirect page
    const moduleName = MODULES.OFFICIAL_REPORTS.NAME;
    const contentName = routerHelper.getContentName(MODULES.OFFICIAL_REPORTS.CONTENTS.LPIS_FIELD_EDIT);
    const parameters = `?owner_page=${ownerPage}&idoszak_id=${id}`;
    const mapUrl = `/${agentName}/${moduleName}/${contentName}${parameters}`;

    if (event?.ctrlKey) {
        window.open(mapUrl, '_blank');
    } else {
        history.push(mapUrl);
    }
}

function editPeriodOnMap(rowData, agentCompanyId) {
    const { id, yearId, startDate, endDate } = rowData;

    const onSave = async () => {
        await masterDataService.updateVersion({ masterdata: 'partfield' });

        await visiblefarmService.updatePeriod({
            agentId: agentCompanyId,
            av_id: id,
            periodType: 0,
        });
    };

    renderModal(MapModal, { layerType: BuiltInLayerTypes.CULTIVATION_PERIOD, entityId: +id, productionYearId: +yearId, findGeomId: true, reloadTable: true, withDrawing: true, startDate, endDate, onSave }, true);
}

function showPeriodOnMap(rowData) {
    const { id, yearId } = rowData;
    renderModal(MapModal, { layerType: BuiltInLayerTypes.CULTIVATION_PERIOD, entityId: +id, zoomEntityId: +id, withBackToGeom: true, productionYearId: +yearId }, true);
}

function downloadFieldFile(rowData) {
    const { id, yearId } = rowData;
    const exportLink = `/export.php?dl=29&year_id=${yearId}&period_ids=${id}`;
    const exportUrl = phpService.getExportLink(exportLink);

    window.open(exportUrl, '_blank');
}

function getAttachedFiles(rowData) {
    const { id } = rowData;

    window.cultivationPeriods_get_csatolt_fajl_ids(id);
}

function openAttachedFiles(rowData) {
    const cultivationPeriodId = [rowData.id];
    renderModal(CultivationPeriodImagesModal, { cultivationPeriodId });
}

function openMeteorologyModal(rowData) {
    const { id } = rowData;
    if (!id) {
        return;
    }
    renderModal(CultivationPeriodMeteorologyModal, { periodId: id });
}

function dividePeriod(rowData, history, event, dispatch, t) {
    const { id, ongoingWorkOperationCount } = rowData;

    if (ongoingWorkOperationCount) {
        dispatch(alertActions.warningAlert(t('cultivationPeriods.isOngoingWorkOpperationAtDivideCultivationPeriod', 'The cultivation period has ongoing work operation. Please, close the work operation, before you continue to divide the cultivation period.')));
        return;
    }

    const agentName = sessionService.getAgentName();
    const moduleName = MODULES.CULTIVATION_PERIODS.NAME;
    const contentName = routerHelper.getContentName(MODULES.CULTIVATION_PERIODS.CONTENTS.DISASSEMBLY);
    const parameters = `?id=${id}`;
    const mapUrl = `/${agentName}/${moduleName}/${contentName}${parameters}`;

    if (event?.ctrlKey) {
        window.open(mapUrl, '_blank');
    } else {
        history.push(mapUrl);
    }
}

function fieldOpenClose(rowData) {
    const { id, name, cultureId, varietyId, sownArea, cropTypeId, mapSvg } = rowData;
    const cultivationPeriods = [{
        id, name, culture: cultureId, cultureVariety: varietyId, sownArea, cropType: cropTypeId, mapSvg,
    }];
    renderModal(CloseAndOpenCultivationPeriod, { cultivationPeriods });
}

function deletePeriod(rowData, t, dispatch) {
    const { id, hasPeriodYield } = rowData;
    if (hasPeriodYield) {
        dispatch(alertActions.errorAlert(t('deleteCultivationPeriod.deletedCultivationPeriodHasYield', 'The cultivation period cannot be deleted because it has yield')));
        return;
    }

    renderModal(DeleteCultivationPeriodModal, { cultivationPeriodIds: [id] }, false);
}

async function addLocation(rowData, t, dispatch) {
    try {
        const isUpdateSuccess = await cultivationPeriodsService.setCultivationPeriodsNearestLocation({ cultivationPeriodIds: [rowData?.id] });

        if (isUpdateSuccess) {
            dispatch(alertActions.successAlert(t('default.successfullySaved', 'Successfully saved')));
            dispatch(basePageActions.setReloadTable());
        } else {
            dispatch(alertActions.errorAlert(t('default.savingFailed', 'Saving failed')));
        }
    } catch (error) {
        throw Error(error);
    }
}

//other data gather functions
async function loadAllSupport(periodId) {
    try {
        const subsidyList = await cultivationPeriodsService.getCultivationPeriodSupport(periodId);
        return subsidyList;
    } catch (error) {
        throw Error(error);
    }
}

async function loadAllRegistryNumber(periodId) {
    try {
        const regNumberList = await cultivationPeriodsService.getCultivationPeriodRegistryNumber(periodId);
        return regNumberList;
    } catch (error) {
        throw Error(error);
    }
}

async function getReproductionRateName(reproductionRateId) {
    try {
        if (reproductionRateId && reproductionRateId !== 0) {
            const reproductionRate = await reproductionRateService.getReproductionRateById(reproductionRateId);
            return reproductionRate[0].name;
        }
    } catch (error) {
        throw Error(error);
    }
}

async function getAllCost(cultivationPeriod) {
    try {
        const periodCostList = await cultivationPeriodsService.getCultivationPeriodCosts(
            cultivationPeriod.companyId, cultivationPeriod.cultureId, cultivationPeriod.yearId, cultivationPeriod.id,
        );
        return periodCostList;
    } catch (error) {
        throw Error(error);
    }
}

async function showSoilAnalysisViewModal(rowData) {
    try {
        const { id, reproductionRateId, yearId } = rowData;

        const periodValues = {
            cultivationPeriodValues: {
                getSupportList: () => loadAllSupport(id),
                getRegistryNumberList: () => loadAllRegistryNumber(id),
                getReproductionRateName: () => getReproductionRateName(reproductionRateId),
                getBasicFields: () => cultivationTableService.getCultivationTableToPeriod(id, yearId),
                cultivationPeriodData: rowData,
            },
        };
        renderModal(SoilAnalysisView, periodValues);
    } catch (error) {
        throw new Error(error);
    }
}
