import { PARTNER, CROP } from 'shared/src/constants';

export const newCultivationPeriodInitialValues = {
    ownerType: PARTNER.TYPES.COMPANY,
    owner: '',
    name: '',
    startDate: '',
    endDate: '',
    region: '',
    sownArea: '',
    cropType: CROP.TYPE.MAIN_CROP,
    crop: {
        type: CROP.TYPE.MAIN_CROP,
        typeName: '',
        //crop
        culture: '',
        cultureName: '',
        variety: '',
        varietyName: '',
        reproductionRate: '',
        reproductionRateName: '',
        //plantation
        plantationType: '',
        year: '',
        rowNumber: '',
        lineLength: '',
        lineSpacing: '',
        plantDistance: '',
    },
    fieldFeatures: '',
    support: '',
    registryNumber: '',
    basicFields: '',
    comment: '',
    comment2: '',
    comment3: '',
    bankId: 0,
    insurerId: 0,
    insuranceCountyNumber: '',
    damageFileNumber: '',
    geometry: false,
    period: '',
    periodYear: '',
    farmId: '',
    qualification: null,
    previousPeriods: [],
    manualPrevCultureId: '',
    isActive: PARTNER.ACTIVE_STATUS.ACTIVE,
};
