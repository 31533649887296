import { Nullable } from '@flowsCommon/services/baseTypes';
import { ThemeType } from '@map/services/mapTypes';
import { isFunction, isNil } from 'lodash';
import { dateTimeUtils } from 'shared/src/modules';
import {
    forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { createUseStyles } from 'react-jss';
// eslint-disable-next-line no-restricted-imports
import { MapSliderIntervalMode, MapSliderOptions } from '../MapSliderTypes';
// eslint-disable-next-line no-restricted-imports
import { calculateMapSliderOptions } from '../services/mapSliderUtil';
// eslint-disable-next-line no-restricted-imports
import { useMapSliderCache } from '../services/useMapSliderCache';
// eslint-disable-next-line no-restricted-imports
import { useMapSliderIntervalChange } from '../services/useMapSliderIntervalChange';
import { MapSliderComponent } from './MapSliderComponent';
import { MapSliderDataContainer } from './MapSliderDataContainer';
import { MapSliderLabelContainer } from './MapSliderLabelContainer';

type Classes = 'sliderWrapper';

const useStyles = createUseStyles<Classes, undefined, ThemeType>(theme => ({
    sliderWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        height: 50,
        position: 'relative',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'center',
        margin: [0, 5],
        paddingBottom: 0,
    },
}));

type TProps = {
    mapSliderMode: MapSliderIntervalMode,
    pickedDate: Date,
    withDataViewer?: boolean;
    calcDateHasDate?: (startDate: Date) => boolean;
    onChange?: (date: Date) => void,
    minMaxDateHasChanged?: (min: Date, max: Date, intervalMode: MapSliderIntervalMode) => void,
}

export type MapSliderContainerRef = {
    invalidateCache: () => void,
    moveDay: (numberOfDays: number) => void
}

export const MapSliderContainer = forwardRef<MapSliderContainerRef, TProps>(({ mapSliderMode, withDataViewer = false, pickedDate, onChange, calcDateHasDate, minMaxDateHasChanged }: TProps, ref) => {
    const classes = useStyles();

    const [mapSliderOptions, setMapSliderOptions] = useState<MapSliderOptions>();
    const currentPickedDate = useRef<Date>();
    const currentPickedValue = useRef<number>();
    const currentSliderMode = useRef<MapSliderIntervalMode>();

    const { readMapSliderCache, invalidateMapSiderCache } = useMapSliderCache({ withDataViewer, mapSliderMode, calcDateHasDate });
    const { mapSliderIntervalChanged } = useMapSliderIntervalChange({ minMaxDateHasChanged, mapSliderMode });

    useEffect(() => {
        if (currentPickedDate.current === pickedDate && currentSliderMode.current === mapSliderMode) {
            return;
        }

        const {
            sliderOptionDatePicker,
            sliderOptionDefaultValue,
            sliderOptionMax,
        } = calculateMapSliderOptions(mapSliderMode, pickedDate);

        mapSliderIntervalChanged(sliderOptionDatePicker);

        const sliderOptionDatePickerFromChache = readMapSliderCache(sliderOptionDatePicker);

        setMapSliderOptions({
            min: 0,
            max: sliderOptionMax,
            datePicker: sliderOptionDatePickerFromChache,
            defaultValue: sliderOptionDefaultValue,
        });

        currentSliderMode.current = mapSliderMode;
        currentPickedDate.current = pickedDate;
    }, [calcDateHasDate, mapSliderMode, minMaxDateHasChanged, pickedDate, readMapSliderCache, mapSliderIntervalChanged, withDataViewer]);

    const onSliderChange = useCallback((startDate: Date, value: number) => {
        currentPickedDate.current = startDate;
        currentPickedValue.current = value;
        if (isFunction(onChange)) {
            onChange(startDate);
        }
    }, [onChange]);

    const invalidateCacheAndRecalcData = useCallback(() => {
        setMapSliderOptions(options => {
            invalidateMapSiderCache();
            if (!isNil(options)) {
                const calculatedPicker = readMapSliderCache(options.datePicker);
                const calcDefaultValue = isNil(currentPickedValue.current) ? options.defaultValue : currentPickedValue.current;
                return {
                    ...options,
                    defaultValue: calcDefaultValue,
                    datePicker: calculatedPicker,
                };
            }
        });
    }, [invalidateMapSiderCache, readMapSliderCache]);

    const moveSliderDay = useCallback((numberOfDays: number) => {
        if (!isFunction(onChange)) {
            return;
        }

        const pickedDateMoment = dateTimeUtils.getDateObject(pickedDate);

        if (numberOfDays > 0) {
            const forWardDate = pickedDateMoment.add(1, 'days');
            onChange(forWardDate.toDate());
        }
        if (numberOfDays < 0) {
            const forWardDate = pickedDateMoment.subtract(1, 'days');
            onChange(forWardDate.toDate());
        }
    }, [onChange, pickedDate]);

    useImperativeHandle(ref, () => ({
        invalidateCache() {
            invalidateCacheAndRecalcData();
        },
        moveDay(numberOfDays) {
            moveSliderDay(numberOfDays);
        },
    }));

    return (
        <div className={classes.sliderWrapper}>
            <MapSliderComponent mapSliderOptions={mapSliderOptions} onChange={onSliderChange} />
            <MapSliderLabelContainer pickedDate={pickedDate} mapSliderMode={mapSliderMode} />
            {withDataViewer && <MapSliderDataContainer mapSliderOptions={mapSliderOptions} />}
        </div>
    );
});
