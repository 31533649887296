import React from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import { numberUtils } from 'shared/src/modules/numberUtils';
import Area from '@baseComponents/formatters/Area';

const useStyles = createUseStyles((theme: any) => ({
    registryNumberContainer: {
        width: '100%',
        height: '100%',
        fontSize: 15,
        fontWeight: 'normal',
    },
    registryNumberItem: {
        height: 40,
        padding: [0, 20],
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid',
        borderBottomColor: props => (!props.disabled ? theme.color.gray : theme.color.pearl),
        '&:last-child ': {
            borderBottom: 'none',
        },
    },
    nameWrapper: {
        width: '100%',
        textAlign: 'left',
        display: 'grid',
    },
    registryNumberName: {
        fontWeight: 500,
        marginRight: 'auto',
    },
    areaWrapper: {
        marginLeft: 'auto',
    },
    area: {
        whiteSpace: 'nowrap',
        margin: 'auto',
    },
}));
export default function RegistryNumberOverall(props) {
    const { registryNumber, disabled } = props;
    const { t } = useTranslate();
    const classes = useStyles({ disabled });

    return (
        <div className={classes.registryNumberContainer}>
            {registryNumber && registryNumber.map((regNumber, index) => (
                <div key={index} className={classes.registryNumberItem}>
                    <div className={classes.nameWrapper}>
                        <span className={classes.registryNumberName}>
                            {regNumber.name} {t('registryNumberOverall.registryNumberOverallText', 'reg. num')}.
                        </span>
                    </div>
                    {numberUtils.isNumber(regNumber.area)
                        ? (
                            <div className={classes.nameWrapper}>
                                <Area value={regNumber.area || 0} style={classes.area} />
                            </div>
                        ) : null
                    }
                </div>
            ))}
        </div>
    );
}
