import React from 'react';
import { createUseStyles } from 'react-jss';
import { ModalTemplate } from '@baseComponents/modals';
import { FormProvider, useForm } from 'react-hook-form';
import AnalysisDataEditBody from './AnalysisDataEditBody';
import AnalysisDataEditFooter from './AnalysisDataEditFooter';
import AnalysisDataEditHeader from './AnalysisDataEditHeader';

const useStyles = createUseStyles({
    modalContainer: {
        display: 'block',
    },
});

export const SOIL_ANALYSIS_TABLE_ID = 'soilAnalysisEditTable';

export default function AnalysisDataEdit(props) {
    const { hideModal, id, isManual, date, registryNumber, isExtendedExamination, laboratoryId, validityDate, samplingArea, onAfterHideModal } = props;
    const classes = useStyles();

    const methods = useForm({
        defaultValues: {
            id,
            isManual,
            date,
            samplingArea,
            registryNumber,
            validityDate,
            laboratoryId,
            isExtendedExamination,
            isLoading: true,
        },
        mode: 'onSubmit',
        reValidateMode: 'onChange',
    });

    return (
        <ModalTemplate hideModal={hideModal} className={classes.modalContainer}>
            <FormProvider {...methods}>
                <AnalysisDataEditHeader />
                <AnalysisDataEditBody
                    tableId={SOIL_ANALYSIS_TABLE_ID}
                />
                <AnalysisDataEditFooter
                    onAfterHideModal={onAfterHideModal}
                    hideModal={hideModal}
                    rowData={props}
                />
            </FormProvider>
        </ModalTemplate>
    );
}
