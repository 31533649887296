import React from 'react';
import { createUseStyles } from 'react-jss';
import { useFormikContext } from 'formik';
import useTranslate from '@i18n/useTranslate';
import DataInput from '@baseComponents/inputs/DataInput';
import CropOverall from './CropOverall';
import CropData from './CropData';
import useLoadCropData from './useLoadCropData';

const useStyles = createUseStyles({
    crop: {
        marginTop: 20,
    },
});

export default function AddCulture(props) {
    const { closeCrop, toggle, cropExpanded, cultivationPeriodId, periodYearId } = props;
    const { t } = useTranslate();
    const { values, errors } = useFormikContext();
    const classes = useStyles();

    const { setFormInitialValues } = props;

    useLoadCropData({ setFormInitialValues });

    function toggleCropData(state) {
        const windowContent = {
            component: CropData,
            props: {
                close: closeCrop,
                cultivationPeriodId,
                periodYearId,
            },
        };
        toggle(state && windowContent);
    }

    return (
        <>
            <DataInput
                title={t('cropForm.culture', 'Culture')}
                label={t('cropForm.culture', 'Culture')}
                className={classes.crop}
                onClick={() => toggleCropData(!cropExpanded)}
                invalid={errors.crop}
                errorMessage={t('newCultivationPeriod.cropDataRequired', 'Crop data is required')}
            >
                {values.crop.cultureName && (
                    <CropOverall
                        cultureName={values.crop.cultureName}
                        typeName={values.crop.typeName}
                        reproductionRateName={values.crop.reproductionRateName}
                        varietyName={values.crop.varietyName}
                    />
                )}
            </DataInput>
        </>
    );
}
