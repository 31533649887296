import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { languageUtils } from 'shared/src/modules/languageUtils';

const useStyles = createUseStyles((theme: any) => ({
    button: {
        display: 'inline-flex',
        padding: 0,
        cursor: 'pointer',
        borderRadius: 4,
        boxShadow: theme.shadows.secondary.normal,
        border: 'solid 1px',
        borderColor: theme.color.gray,
        backgroundColor: theme.color.white,
        color: theme.color.jet,
        '&:hover': {
            background: theme.color.buttonHover.secondary,
            border: 'solid 1px',
            borderColor: theme.color.gray,
            boxShadow: theme.shadows.secondary.hover,
        },
        outline: 'none',
    },
    button_primary: {
        boxShadow: theme.shadows.primary.normal,
        border: 'solid 2px',
        borderColor: 'rgba(255, 255, 255, 0.3)',
        backgroundColor: theme.color.main,
        color: theme.color.white,
        '&:hover': {
            background: theme.color.buttonHover.default,
            border: 'solid 2px',
            borderColor: 'rgba(255, 255, 255, 0.15)',
            boxShadow: theme.shadows.primary.hover,
        },
    },
    button_destructive: {
        boxShadow: theme.shadows.destructive.normal,
        border: 'solid 1px',
        borderColor: theme.color.destructive,
        backgroundColor: theme.color.white,
        color: theme.color.destructive,
        '&:hover': {
            background: theme.color.buttonHover.destructive,
            border: 'solid 1px',
            borderColor: theme.color.destructive,
            boxShadow: theme.shadows.destructive.hover,
        },
    },
    disabled: {
        cursor: 'default',
        opacity: 0.5,
        pointerEvents: (props: any) => {
            if (props.disabledPointerEvents) {
                return props.disabledPointerEvents;
            }
        },
    },
}));

export default function CardButton(props) {
    const { type, title, onClick, children, className, isDisabled } = props;
    const { t } = useTranslation();
    const classes = useStyles({ isDisabled, type, onClick });

    const getSpecClasses = buttonType => {
        if (buttonType === 'primary') {
            return classes.button_primary;
        }
        if (buttonType === 'destructive') {
            return classes.button_destructive;
        }
    };

    return (
        <button
            type="button"
            title={languageUtils.getTitle(t, title)}
            className={clsx(classes.button, getSpecClasses(type), isDisabled && classes.disabled, className)}
            onClick={onClick}
        >
            {children}
        </button>
    );
}
