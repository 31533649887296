import * as Sentry from '@sentry/react';
import { numberUtils } from 'shared/src/modules';

export const sentryService = {
    initSentry,
    captureMessage,
    captureException,
    createReduxEnhancer,
};

let sentryInited = false;

function initSentry() {
    const isEnabled = numberUtils.getBoolean(process.env?.REACT_APP_SENTRY_ENABLED);
    if (isEnabled) {
        if (!sentryInited) {
            Sentry.init({
                dsn: process.env.REACT_APP_SENTRY_DSN,
            });

            sentryInited = true;
        }
        return sentryInited;
    }

    return false;
}

function captureException(error) {
    if (initSentry()) {
        //console.log('new sentry exception:', error);
        Sentry.captureException(error);
    }
}

function captureMessage(message) {
    if (initSentry()) {
        //console.log('new sentry message:', message);
        Sentry.captureMessage(message);
    }
}

function createReduxEnhancer() {
    if (initSentry()) {
        return Sentry.createReduxEnhancer({
            // Optionally pass options listed below
        });
    }
}
