import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isBoolean, isNull, isArray, isEmpty, isObject } from 'lodash';

import { numberUtils } from 'shared/src/modules';
import { TABLE, SETTINGS } from 'shared/src/constants';
import { companyActions } from '@states/actions/companyActions';
import { tableUtils } from '@utils';
import useTableDataHandlerInitValues from './useTableDataHandlerInitValues';

export default function useTableDataHandler(props) {
    const { isBasePageTable, tableKey } = props;
    const {
        tableData,
        columns,
        tableLineViewType,
        currentCurrencyDecimalNumber,
        currentCurrencyUnitPriceDecimalNumber,
        globalColumnSortingTypes,
        globalSearchValue,
        columnSearchValues,
        selectedGroup,
    } = useTableDataHandlerInitValues({ isBasePageTable, tableKey });

    const dispatch = useDispatch();
    const [isNonAccentSearch] = useState(dispatch(companyActions.getSetting(SETTINGS.ACCENT_INSENSITIVE_SEARCH)));
    const [sortedTableData, setSortedTableData] = useState(null);
    const [sortedAndFilteredTableData, setsortedAndFilteredTableData] = useState(null);
    const [isInitializedTableData, setIsInitializedTableData] = useState(false);
    const isSelectedCurrencySwitch = useSelector((state: any) => state.company.company.currency.isSelectedCurrencySwitch);

    //new table data after init - reset init state
    useEffect(() => {
        if (isArray(tableData) && !isEmpty(columns)) {
            setIsInitializedTableData(currentState => (currentState ? false : currentState));
        }
    }, [tableData, columns, tableLineViewType]);

    //init new data and sort the default array
    useEffect(() => {
        if (isArray(tableData) && !isEmpty(columns) && !isInitializedTableData) {
            const sortedDatas = getSortedData(tableData, columns, tableLineViewType, globalColumnSortingTypes);
            setSortedTableData({ sortedDatas });
            setIsInitializedTableData(state => !state);
        }
    }, [tableData, columns, globalColumnSortingTypes, tableLineViewType, isInitializedTableData]);

    //sort the current filtered data
    useEffect(() => {
        function hasSorters() {
            if (isEmpty(globalColumnSortingTypes)) {
                return false;
            }
            return Object.values(globalColumnSortingTypes).find(sorterType => sorterType !== TABLE.SORTING.NONE);
        }

        if (hasSorters(globalColumnSortingTypes) && isInitializedTableData) {
            sortedTableData.sortedDatas = getSortedData(sortedTableData.sortedDatas, columns, tableLineViewType, globalColumnSortingTypes); //sort original data
            setsortedAndFilteredTableData(currentTableData => getSortedData(currentTableData, columns, tableLineViewType, globalColumnSortingTypes)); //sort filtered data
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [globalColumnSortingTypes, columns]); //sortedTableData, isInitializedTableData, tableLineViewType

    //use all filters on default sorted array
    useEffect(() => {
        function hasGlobalSearchValue() {
            return !isNull(globalSearchValue) && !isEmpty(globalSearchValue);
        }

        function hasColumnSearchValue() {
            if (!isNull(columnSearchValues) && isNotEmptyOrNumber(columnSearchValues)) {
                const columnValue = Object.values(columnSearchValues)?.find(searchValue => !isEmpty(searchValue) || isBoolean(searchValue));
                if (isBoolean(columnValue)) {
                    return true;
                }
                return columnValue;
            }
            return false;
        }

        function isNotEmptyOrNumber(searchValue) {
            return !isEmpty(searchValue) || (numberUtils.isNumber(searchValue) && !isNull(searchValue)) || isBoolean(searchValue);
        }

        //check all row and all column
        function filterDataArrayBySearcher() {
            return sortedTableData?.sortedDatas.filter(rowData => {
                //filter by columns searcher
                let globalSearchResult = false;
                const columnSearchResult = columns.every(column => {
                    let columnId = tableUtils.getColumnIdByViewType(column, tableLineViewType);
                    if (isSelectedCurrencySwitch && column.hasSecondaryCurrency) { // secondary
                        columnId += 'SC';
                    }

                    const searchColumnId = column.searchColumnId || column.columnId;
                    const columnSearchValue = columnSearchValues ? columnSearchValues[searchColumnId] : null;

                    //filter by global search value
                    if (!hasGlobalSearchValue()) {
                        globalSearchResult = true;
                    } else if (!globalSearchResult && !column.ignoreSearching) {
                        const selectedGroupColumnId = selectedGroup ? selectedGroup.value : null;
                        const isGlobalSearching = true;
                        globalSearchResult = tableUtils.isContainFilterValue(rowData, column, columnId, globalSearchValue, currentCurrencyDecimalNumber, currentCurrencyUnitPriceDecimalNumber, isGlobalSearching, isNonAccentSearch)
                            || (!isEmpty(selectedGroupColumnId) && tableUtils.getGroupNameFilterResult(rowData, globalSearchValue, selectedGroupColumnId));

                        //second line filtering
                        if (!globalSearchResult && column.searchInSecondLine && column?.componentProps?.secondLineId) {
                            globalSearchResult = tableUtils.isContainFilterValue(rowData, column, column.componentProps.secondLineId, globalSearchValue, currentCurrencyDecimalNumber, currentCurrencyUnitPriceDecimalNumber, false, isNonAccentSearch);
                        }
                    }

                    //cell ignored searching
                    if ((!columnSearchValue && !isBoolean(columnSearchValue)) || column.ignoreSearching) {
                        return true;
                    }

                    let isContainFilterValue;
                    if (isObject(columnSearchValue) && column.isSearchSeparatelyLines) {
                        if (columnSearchValue.columnId !== TABLE.COLUMN_LINE.SECOND_LINE) {
                            isContainFilterValue = tableUtils.isContainFilterValue(rowData, column, columnId, columnSearchValue.searchValue, currentCurrencyDecimalNumber, currentCurrencyUnitPriceDecimalNumber, false, isNonAccentSearch);
                        }
                        if (((!isContainFilterValue && columnSearchValue.columnId === TABLE.COLUMN_LINE.BOTH_LINE) || columnSearchValue.columnId === TABLE.COLUMN_LINE.SECOND_LINE) && column?.componentProps?.secondLineId) {
                            isContainFilterValue = tableUtils.isContainFilterValue(rowData, column, column.componentProps.secondLineId, columnSearchValue.searchValue, currentCurrencyDecimalNumber, currentCurrencyUnitPriceDecimalNumber, false, isNonAccentSearch);
                        }
                    } else {
                        //simple filter
                        isContainFilterValue = tableUtils.isContainFilterValue(rowData, column, columnId, columnSearchValue, currentCurrencyDecimalNumber, currentCurrencyUnitPriceDecimalNumber, false, isNonAccentSearch);

                        //second line filtering
                        if (!isContainFilterValue && column.searchInSecondLine && column?.componentProps?.secondLineId) {
                            isContainFilterValue = tableUtils.isContainFilterValue(rowData, column, column.componentProps.secondLineId, columnSearchValue, currentCurrencyDecimalNumber, currentCurrencyUnitPriceDecimalNumber, false, isNonAccentSearch);
                        }
                    }

                    return isContainFilterValue;
                });

                //if user use only global searcher
                if (!hasColumnSearchValue() && hasGlobalSearchValue()) {
                    return globalSearchResult;
                }

                return columnSearchResult && globalSearchResult;
            });
        }

        if (sortedTableData && isArray(sortedTableData.sortedDatas) && !isEmpty(columns) && !isEmpty(selectedGroup)) {
            if (hasGlobalSearchValue() || hasColumnSearchValue()) {
                const filteredData = filterDataArrayBySearcher();
                setsortedAndFilteredTableData(filteredData);
            } else {
                setsortedAndFilteredTableData(sortedTableData.sortedDatas);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortedTableData, columns, globalSearchValue, columnSearchValues, currentCurrencyDecimalNumber, currentCurrencyUnitPriceDecimalNumber, selectedGroup]); //tableLineViewType

    //data sorter function
    function getSortedData(dataList, tableColumns, lineViewType, sortingTypes) {
        function hasSorters() {
            if (isEmpty(sortingTypes)) {
                return false;
            }
            return Object.values(sortingTypes).find(sorterType => sorterType !== TABLE.SORTING.NONE);
        }

        function sortFilteredData() {
            const sortedDataArray = [...dataList];

            if (!isEmpty(sortedDataArray) && !isEmpty(sortingTypes) && tableColumns) {
                Object.entries(sortingTypes).reverse().forEach(([columnId, sortType]) => {
                    const columnObject = tableColumns.find(column => column.columnId === columnId);
                    tableUtils.sortDataArrayByType(sortedDataArray, columnObject, sortType, lineViewType); //sort data array
                });
            }
            return sortedDataArray;
        }

        if (hasSorters() && !isEmpty(tableColumns) && !isEmpty(dataList)) {
            const sortedData = sortFilteredData();
            return sortedData;
        }
        return dataList;
    }

    return {
        sortedAndFilteredTableData,
    };
}
