import { arrayUtils } from 'shared/src/modules';
import { dataListsActions } from '@states/actions/dataListsActions';

const initialState = {
    productionYears: null,
    cultures: null,
    cultureVarieties: null,
    cropTypes: null, //is fix list
    reproductionRates: null,
    gnInformationObligedEventList: null, //is fix list (gn_tajekoztatas_koteles_esemeny)
    productTypes: null, //is fix list
    treatmentUnitTypes: null, //is fix list (kezelési egység típus)
    warehouses: null,
    farms: null,

    economicUnitList: null,
    machineList: null,
    employeeList: null,
    workOperationMainGroups: null,

    qualificationParameters: null,
};

export function dataLists(state = initialState, action) {
    switch (action.type) {
        case dataListsActions.ADD_DATA_LIST: {
            return {
                ...state,
                [action.payload.listName]: action.payload.dataList,
            };
        }
        case dataListsActions.ADD_PRODUCTION_YEAR: {
            const productionYears = [...state.productionYears || [], action.payload.periodYear];
            arrayUtils.sortByParamNumberDesc(productionYears, 'year');
            return {
                ...state,
                productionYears,
            };
        }
        case dataListsActions.REMOVE_DATA_LIST: {
            return {
                ...state,
                [action.payload.listName]: null,
            };
        }
        default:
            return state;
    }
}
