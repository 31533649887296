import { useState, useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { getMonogram } from 'shared/src/user/user';
import clsx from 'clsx';
import { isEmpty } from 'lodash';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: (props: any) => (props.size === 'small' ? 30 : 40),
        height: (props: any) => (props.size === 'small' ? 30 : 40),
        minWidth: (props: any) => (props.size === 'small' ? 30 : 40),
        minHeight: (props: any) => (props.size === 'small' ? 30 : 40),
        borderRadius: 4,
        border: 'solid 2px',
        borderColor: theme.color.white,
        backgroundColor: (props: any) => (props.hasImage ? theme.color.white : theme.color.lightCyan),
        outline: 'none',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    image: {
        height: (props: any) => (props.size === 'small' ? 26 : 36),
        width: (props: any) => (props.size === 'small' ? 26 : 36),
        borderRadius: 4,
    },
    name: {
        textAlign: 'center',
        color: theme.color.white,
        fontSize: (props: any) => (props.size === 'small' ? 12 : 18),
        fontWeight: 'bold',
    },
    icon: {
        height: 25,
        width: 25,
        fill: theme.color.white,
    },
}));

export default function MachineAvatar(props) {
    const { name, image, size, onClick, imageUrl, className, isShowTitle = false } = props;
    const [monogram, setMonogram] = useState();
    const classes = useStyles({ image, size, hasImage: !isEmpty(imageUrl) });

    useEffect(() => {
        if (name) {
            setMonogram(getMonogram(name));
        }
    }, [name]);

    function getAvatarTitle() {
        if (isShowTitle) {
            return name;
        }
    }

    return (
        <div className={clsx(classes.container, className)} onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0} title={getAvatarTitle()}>
            {!isEmpty(imageUrl)
                ? (<img alt="" src={imageUrl} className={classes.image} />)
                : (<div className={classes.name}>{monogram}</div>)}
        </div>
    );
}
