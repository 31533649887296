export const WAREHOUSE = {
    MODULE_ID: 23,
    NAME: 'warehouse',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        WAREHOUSES: {
            CONTENT_URL_ID: { warehouses: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'warehouses',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.warehouses',
                TRANSLATOR_DEFAULT: 'Warehouses',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
    },
};
