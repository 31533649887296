import { values, isEmpty, isFunction } from 'lodash';
import { columnName } from '@baseComponents/tableNew/tableColumns/tableColumns';
import { theme as themes } from '@styles/theme';

export default function getAllColumns(t, translater, showedColumns) {
    const columns = {
        addIcon: {
            type: columnName.iconButton,
            columnId: 'icon-add',
            componentProps: {
                icon: 'icon-add',
                getCellTitle: () => translater('default.select', 'Select'),
                getBackgroundColor: () => themes.color.main,
                getIconColor: () => themes.color.white,
                onClick: (rowData, onAddButtonClicked) => {
                    if (isFunction(onAddButtonClicked)) {
                        onAddButtonClicked(rowData);
                    }
                },
            },
            isLeftFixed: true,
            width: 80,
            minWidth: 80,
            maxWidth: 80,
        },
        name: {
            type: columnName.simpleText,
            columnId: 'name',
            columnName: t('releasingWarehouseTableFilter.name', 'Name'),
            minWidth: 280,
        },
        partnerName: {
            type: columnName.simpleText,
            columnId: 'partnerCompanyName',
            columnName: t('releasingWarehouseTableFilter.companyPartner', 'Company/Partner'),
            minWidth: 280,
        },
        quantity: {
            type: columnName.quantity,
            columnId: 'afterQuantity',
            columnName: t('releasingWarehouseTableFilter.quantity', 'Quantity'),
            componentProps: {
                unitId: 'unitShortName',
                hasCustomUnit: true,
            },
        },
        price: {
            type: columnName.costPerArea,
            columnId: 'stockPrice',
            columnName: t('releasingWarehouseTableFilter.stockPrice', 'Stock price'),
            unitPriceDecimals: false,
        },
        unit: {
            type: columnName.simpleText,
            columnId: 'unitShortName',
            columnName: t('releasingWarehouseTableFilter.unit', 'Unit'),
            minWidth: 90,
        },
        companyPartner: {
            type: columnName.simpleText,
            columnId: 'partnerName',
            columnName: t('warehouseTableFilter.partnerCompany', 'Partner/Company'),
            minWidth: 200,
            width: 200,
            marginLeft: 30,
        },
        typeName: {
            type: columnName.simpleText,
            columnId: 'typeName',
            columnName: t('warehouseTableFilter.warehouseType', 'Type'),
            minWidth: 200,
            width: 200,
            marginLeft: 30,
        },
    };

    if (!isEmpty(showedColumns)) {
        const filteredColumns: any = [];
        const columnObj = Object.entries(columns);

        showedColumns?.forEach(showedColName => {
            const column = columnObj.find(columnParts => columnParts[0].toString() === showedColName);
            if (column) {
                filteredColumns.push(column[1]);
            }
        });

        return filteredColumns;
    }

    return values(columns);
}
