import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import useTranslate from '@i18n/useTranslate';
import { dateTimeUtils } from 'shared/src/modules';
import Button from '@baseComponents/buttons/Button';
import useCalendarData from './useCalendarData';
import Calendar from './Calendar';
// eslint-disable-next-line no-restricted-imports
import DateInput from '../calendarParts/DateInput';

const useStyles = createUseStyles({
    calendarContainer: {
        padding: 20,
    },
});

export default function CalendarWithInput(props) {
    const { date, setDate, close, minDate, maxDate, required, filterDate, excludeDates } = props;
    const { t } = useTranslate();
    const [isValidDate, setValidDate] = useState(true);
    const [calendarDate, setCalendarDate] = useState(date || null);

    const classes = useStyles();

    useCalendarData();

    function apply() {
        if (setDate) {
            if (calendarDate) {
                setDate(dateTimeUtils.getDefaultDateFormat(calendarDate));
            } else {
                setDate();
            }
        }
        setValidDate(true);
        close();
    }

    return (
        <div className={classes.calendarContainer}>
            <Calendar
                date={calendarDate}
                setDate={setCalendarDate}
                setValidDate={setValidDate}
                minDate={minDate}
                maxDate={maxDate}
                filterDate={filterDate}
                excludeDates={excludeDates}
            />
            <DateInput
                date={calendarDate}
                setDate={setCalendarDate}
                isValidDate={isValidDate}
                setValidDate={setValidDate}
                minDate={minDate}
                maxDate={maxDate}
                required={required}
            />
            <Button onClick={apply} fill>
                {t('default.apply', 'Apply')}
            </Button>
        </div>
    );
}
