import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { isEmpty, gt } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import BasicFieldCard from './BasicFieldCard';

const useStyles = createUseStyles((theme: any) => ({
    tableContainer: {
        maxHeight: '80%',
        overflow: 'hidden',
        overflowY: 'auto',
        ...theme.verticalScrollbar,
    },
    name: {
        paddingLeft: 5,
        paddingRight: 5,
        margin: 0,
        width: 202,
    },
    area: {
        paddingLeft: 5,
        paddingRight: 5,
        width: 100,
        textAlign: 'right',
    },
    areaInput: {
        paddingLeft: 5,
        paddingRight: 5,
        width: 120,
        textAlign: 'right',
    },
    headerTitles: {
        display: 'flex',
    },
    title: {
        color: theme.color.stone,
        fontSize: 12,
        lineHeight: 1.667,
        fontWeight: 500,
        ...theme.firstLetterUppercase,
    },
    cardContainer: {
        height: 'auto',
        overflow: 'hidden',
        overflowY: 'auto',
        ...theme.verticalScrollbar,
    },
}));

export default function BasicFieldsTable(props) {
    const { basicFields, setBasicFields } = props;
    const { t } = useTranslate();

    const classes = useStyles();

    const [headerTitles] = useState([
        {
            title: t('basicFields.name', 'NAME'),
            className: classes.name,
        },
        {
            title: t('basicFields.cultivatedArea', 'CULTIVATED AREA(HA)'),
            className: classes.area,
        },
        {
            title: t('basicFields.categorizedArea', 'CATEGORIZED AREA'),
            className: classes.areaInput,
        },
        {
            title: t('basicFields.uncategorizedArea', 'UNCATEGORIZED AREA'),
            className: classes.area,
        },
    ]);

    function removeBasicField(basicFieldId) {
        const filteredBasicFieldList = basicFields.filter(basicFieldObj => basicFieldObj.id !== basicFieldId);
        setBasicFields(filteredBasicFieldList);
    }

    function setBasicField(basicField) {
        const basicFieldsList = basicFields.map(basicFieldObj => {
            if (basicFieldObj.id === basicField.id) {
                return basicField;
            }
            return basicFieldObj;
        });
        setBasicFields(basicFieldsList);
    }

    return (
        <div className={classes.tableContainer}>
            <div className={classes.headerTitles}>
                {headerTitles?.map((titleObj, index) => (
                    <div key={index} className={clsx(titleObj.className, classes.title)}>
                        {titleObj.title}
                    </div>
                ))}
            </div>
            <div className={classes.cardContainer}>
                {!isEmpty(basicFields) && basicFields.map(basicFieldObj => (
                    <BasicFieldCard
                        key={basicFieldObj.id}
                        basicField={basicFieldObj}
                        removeCard={removeBasicField}
                        setBasicField={setBasicField}
                        isDelete={gt(basicFields.length, 0)}
                    />
                ))}
            </div>
        </div>
    );
}
