export const SETTINGS = {
    TABLE_AND_PERIOD_DIFFERENT_COMPANY: 'tabla_idoszak_azonos_ceg', //1
    MATERIAL_USE_WITH_EXPENDITURE: 'anyagfelhasznalas_kiadassal', //2
    DAILY_DATA_SERVICE_COMPANY_DIFFERENCE_TYPE: 'napi_adat_szolgaltatas_ceg_elteres_tipus', //3
    WORK_OPERATION_BASE_APPROVAL_DATE: 'munkamuvelet_alapertelmezett_jovahagyas_datum', //6
    PARTNER_OFFICIAL_DATA: 'partner_hivatalos_adatok', //15
    ACCOUNTING_SYSTEM: 'konyvelesi_rendszer', //16
    PRIMARY_CURRENCY: 'alap_penznem', //21
    PLANNING_MODE: 'tervezes', //25
    USING_MAP: 'terkep', //39
    LAND_ADMINISTRATION_AREA_AK_DECIMAL: 'ak_tizedesjegy', //45
    LAND_ADMINISTRATION_AREA_HA_DECIMAL: 'ha_tizedesjegy', //46
    WORKING_TIME_RECORDS: 'munkaido_nyilvantartas', //50
    REFUELING_FROM_MMSQL: 'tankolas_mssql_adatbazisbol', //51
    OPEN_SEVERAL_SERVICE_WORK_SHEETS_PER_MACHINE: 'gepenkent_tobb_szerviz_munkalap', //55
    WORKOPERATION_GROUP_BY_COMPANY_BLOCK: 'munkamuvelet_csoport_megjelenitese_tomb_ceg_szerint', //80
    USING_ITERIS_DATA: 'terkep_iteris', //81
    HALF_BENEFIT: 'feles_haszonber', //64
    PERIOD_CONSULTING: 'idoszak_szaktanacsadas', //67
    USING_SECONDARY_CURRENCY: 'masodlagos_penznem_hasznalata', //84
    SECONDARY_CURRENCY: 'masodlagos_penznem', //85
    USING_TREATMENT_UNIT: 'kezelesi_egyseg_hasznalata',
    USING_TESTS: 'kiserlet_hasznalata', //91
    USING_PERIOD_DIVIDE_MERGE: 'idoszak_szetbontas_osszevonas_hasznalata', //95
    USE_YIELD_FROM_EXCEL: 'hozamolas_excelbol_hasznalata', //98
    USE_OF_ORDERS: 'megrendelesek_hasznalata', //99
    OPERATION_COST_CALCULATION_WAGE_WORK: 'muveleti_koltseg_szamitas_bermunka', //104
    USE_REVENUE_FROM_EXCEL: 'bevetelezes_excelbol_hasznalata', //105
    USING_WORKOPERATION_FROM_EXCEL: 'munkamuvelet_excelbol_hasznalata', //107
    SUSTAINABILITY_STATEMENTS: 'fenntarthatosagi_nyilatkozatok', //137
    DYNTELL_CONTACT: 'dyntell', //140
    ADMINISTRATION_OF_OWN_LAND_LEASING: 'sajat_foldtulajdon_berbeadas_adminisztralasa', //158
    YIELD_USING_RAW_MATERIAL: 'hozamolas_alapanyag_felhasznalassal', //159
    REGISTRY_NUMBER_SALES_ADMINISTRATION: 'hrsz_adasvetel_adminisztralasa', //160
    USING_WORKOPERATION_SUBGROUP: 'munkamuvelet_alcsoport_hasznalata', //165
    USING_FARM: 'gazdasag_hasznalata', //166
    USING_INVENTORY_TRANSFER_REQUEST: 'attarolasi_kervenyezes_hasznalata', //168
    USING_DQS: 'adm_engedelyezese', //169
    DECLARATION: 'szandeknyilatkozatok_elerese', //179
    RECEIVED_FLEET_MOBILE_DATA_PRIMARY_VALUE: 'erkezett_adatok_elsodleges_ertekek', //192
    STOCK_DISTRIBUTION: 'haztaji_szetosztas_elerese', //198
    MULTIPARTNER_RECEPTION: 'tobbpartneres_bevetelezes_engedelyezese', //203
    ACCENT_INSENSITIVE_SEARCH: 'accent_insensitive_search', // 204
    REQUIRED_PERIOD_QUALIFICATION: 'idoszak_minosites_kotelezo', //206
    ACCESSING_VALIDATION_INTERFACE: 'validalo_felulet_elerese', //215
    ACCOUNTING_MANUFACTURING_ORDER: 'konyveles_gyartasi_rendeles_torzs', //189
    USE_YIELD_PRODUCT_APPROVED_WORKOPERATION: 'lezart_munkamuvelet_hozam_termek', //225
    USE_TANK_MIX: 'tankkeverek_funkcio_hasznalata', //230
    EXTERNAL_SCALE_SOFTWARE_CONNECTION: 'merlegstudio_kapcsolodas', // 236
    WORKOPERATION_QUALIFICATION: 'munkamuvelet_minosites_funkcio', //234
    TREATMENT_UNIT_NUMBER_MAX_LENGTH: 'kezelesi_egyseg_szam_maximalis_hossza', //244
    STOCK_MOVEMENT_AUTO_DISPATCH: 'konyveles_automatikus_keszlet_feladas', //246
    USE_YIELD_FROM_EXCEL_PROCESSING_PLANT: 'hozamolas_excelbol_feldolgozouzem_hasznalata', //247
    LIVESTOCK_WEIGHT_CERTIFICATE: 'testtomeg_gyarapodas_folyamatos_bizonylatolasa', // 248
    MESSAGE_UPDATE_INTERVAL: 'uzenet_frissites_intervallum', // 252
    ACCOUNTING_DISPATCH_STOCK_MOVEMENT_SEND_ONE_BY_ONE: 'konyveles_tetelenkenti_feladas',
    METEOROLOGY_TEMPERATURE_UNIT: 'meteorologia_homerseklet_mertekegyseg', //249
    METEOROLOGY_ATMOSPHERIC_PRESSURE_UNIT: 'meteorologia_legnyomas_mertekegyseg', //250
    USE_VALIDATION: 'validalo_felulet_elerese', //215
    BUSINESS_INTELLIGENCE_EXPORT_ENABLED: 'business_intelligence_export_enabled', // 275
    USE_GLOBAL_LITE: 'global_lite_hasznalata', // 273
    INDIVIDUAL_ANIMAL_REGISTRATION: 'allat_egyedi_nyilvantartas', //229
    NORM_CALCULATION_METHOD: 'teljesitmeny_norma_funkcio_hasznalata', //291
    ACCOUNTING_FORRAS_DIRECT_STOCK_DISPATCH: 'konyveles_forras_kozvetlen_keszlet_feladas', // 288
    ACCOUNTING_FORRAS_DIRECT_CUSTOMER_INVOICE: 'konyveles_forras_kozvetlen_vevoi_szamla', // 289
    GSAA_IS_ENABLED: 'gsaa_is_enabled', // 306
};
