import React, { useCallback, useMemo, useEffect, useState, useRef } from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';
import { dateTimeUtils } from 'shared/src/modules';
import { isFunction, isEmpty } from 'lodash';
import RangeDatePicker from '@baseComponents/datepickers/rangeCalendar/RangeDatePicker';
import Svg from '@baseComponents/Svg';
import useCalendarData from '@baseComponents/datepickers/simpleCalendar/useCalendarData';
import BasicButton from '@baseComponents/buttons/Button';
import Label from '@baseComponents/formParts/Label';
import ErrorMessage from '@baseComponents/formParts/ErrorMessage';
import FormRangeCalendarInputs from './FormRangeCalendarInputs';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: '100%',
        position: 'relative',
        display: 'inline-block',
        ...theme.formatBootstrapButton.secondary,
    },
    dateBtn: {
        width: '100%',
        height: 40,
        borderRadius: 4,
        boxShadow: theme.shadows.secondary.input,
        border: 'solid 1px',
        borderColor: (props: any) => (props.invalid ? theme.color.destructive : theme.color.gray),
        backgroundColor: theme.color.white,
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: '0px 15px',
        fontFamily: 'Rubik',
        fontSize: 15,
        fontWeight: 300,
        color: theme.color.jet,
        '&:disabled': {
            color: (props: any) => props.showDate && theme.color.jet,
        },
    },
    icon: {
        width: 24,
        height: 24,
        marginLeft: 'auto',
        fill: theme.color.stone,
    },
    popover: {
        zIndex: 9999999, //theme.zIndex.popover,
        boxShadow: theme.shadows.dropdown,
        backgroundColor: theme.color.white,
        borderRadius: 6,
        border: 'none',
        padding: 20,
        minWidth: 300,
        '& .arrow::before': {
            borderWidth: 8,
            top: -10,
        },
        '& .popover-body': {
            padding: 0,
        },
        '& input': {
            fontSize: 14,
        },
    },
    popoverArrowRight: {
        '& .arrow::before': {
            borderRightColor: theme.color.white,
            left: -20,
        },
    },
    popoverArrowLeft: {
        '& .arrow::before': {
            borderLeftColor: theme.color.white,
            right: -20,
        },
    },
    applyBtn: {
        marginTop: 15,
    },
}));

export default function FormRangeCalendar(props) {
    const {
        startDate, setStartDate, endDate, setEndDate, label, required, className,
        errorMessage, invalid, showDate, disabled = false, onClickApply, endDateDaysFromStartDate, isMonthDayFormat, placement = 'right',
        onPreventDateChange,
    } = props;

    const [isOnCalendar, setOnCalendar] = useState(false); // toggles dropdown visibility
    const [isApplyDisabled, setIsApplyDisabled] = useState(false);
    const [isFirstPick, setFirstPick] = useState(true);
    const { t } = useTranslation();
    const [date, setDate] = useState({
        start: startDate,
        end: endDate,
    });

    const [inputStartDate, setInputStartDate] = useState('');
    const [inputEndDate, setInputEndDate] = useState('');
    const [formDate, setFormDate] = useState();
    const [isApplyPressed, setIsApplyPressed] = useState(false);
    const isPreventPopoverQuestion = useRef(false);
    const classes = useStyles({ startDate, endDate, showDate, placement } as any);

    useCalendarData();

    const getFormDate = useCallback((startFormDate, endFormDate) => {
        if (isMonthDayFormat) {
            return `${dateTimeUtils.getMonthAndDay(startFormDate)} - ${dateTimeUtils.getMonthAndDay(endFormDate)}`;
        }
        return `${dateTimeUtils.getDate(startFormDate)} - ${dateTimeUtils.getDate(endFormDate)}`;
    }, [isMonthDayFormat]);

    //handle startDate from parent
    useEffect(() => {
        if (dateTimeUtils.isValidDate(startDate)) {
            const inputDate = dateTimeUtils.getDate(startDate);
            setInputStartDate(inputDate);

            setDate(currentState => {
                let calculatedEndDate = currentState.end;

                if (endDateDaysFromStartDate) {
                    calculatedEndDate = dateTimeUtils.getMomentObjectFromDateString(dateTimeUtils.dateAddDays(startDate, endDateDaysFromStartDate)).toDate();
                    calculatedEndDate = dateTimeUtils.getDateObject(calculatedEndDate);
                }

                setFormDate(getFormDate(startDate, calculatedEndDate));
                if (dateTimeUtils.isEqual(currentState.startDate, startDate) && !endDateDaysFromStartDate) {
                    return { currentState };
                }
                return { start: dateTimeUtils.getDateObject(startDate), end: calculatedEndDate };
            });
        }
    }, [startDate, endDateDaysFromStartDate, getFormDate]);

    //handle endDate from parent
    useEffect(() => {
        if (endDateDaysFromStartDate) {
            return;
        }

        if (dateTimeUtils.isValidDate(endDate)) {
            const inputDate = dateTimeUtils.getDate(endDate);
            setInputEndDate(inputDate);
            setDate(currentState => {
                setFormDate(`${dateTimeUtils.getDate(currentState.start)} - ${dateTimeUtils.getDate(endDate)}`);
                if (dateTimeUtils.isEqual(currentState.endDate, endDate)) {
                    return currentState;
                }
                return { ...currentState, end: dateTimeUtils.getDateObject(endDate) };
            });
        }
    }, [endDate, endDateDaysFromStartDate]);

    function closeCalendar() {
        document.body.click();
    }

    function applyDate() {
        setFormDate(`${dateTimeUtils.getDate(date.start)} - ${dateTimeUtils.getDate(date.end)}`);
        setStartDate(dateTimeUtils.getDefaultDateFormat(date.start));
        setEndDate(dateTimeUtils.getDefaultDateFormat(date.end) || '');
        setFormDate(`${dateTimeUtils.getDate(date.start)} - ${dateTimeUtils.getDate(date.end)}`);

        if (isFunction(onClickApply)) {
            onClickApply(date.start, date.end);
        }

        closeCalendar();
        setIsApplyPressed(true);
    }

    function exitPopover() {
        if (!isApplyPressed) {
            setInputStartDate(dateTimeUtils.getDate(startDate));
            setInputEndDate(dateTimeUtils.getDate(endDate));
            setDate({
                start: dateTimeUtils.getDateObject(startDate),
                end: dateTimeUtils.getDateObject(endDate),
            });
        }
        setIsApplyPressed(false);
    }

    const handleStartDate = useCallback(value => {
        if (!endDateDaysFromStartDate) {
            return setDate({ ...date, start: value });
        }

        const calculatedEndDate = dateTimeUtils.getMomentObjectFromDateString(value).add(endDateDaysFromStartDate, 'days');
        setInputEndDate(dateTimeUtils.getDate(calculatedEndDate));
        setDate({ start: value, end: calculatedEndDate });
    }, [date, endDateDaysFromStartDate]);

    const handleEndDate = useCallback(value => {
        if (!endDateDaysFromStartDate) {
            return setDate({ ...date, end: value });
        }

        const calculatedStartDate = dateTimeUtils.getMomentObjectFromDateString(value).add(endDateDaysFromStartDate * -1, 'days');

        setInputStartDate(dateTimeUtils.getDate(calculatedStartDate));
        setDate({ end: value, start: calculatedStartDate });
    }, [date, endDateDaysFromStartDate]);

    const popoverArrowClasses = useMemo(() => {
        if (placement === 'left') {
            return classes.popoverArrowLeft;
        }
        return classes.popoverArrowRight;
    }, [classes.popoverArrowLeft, classes.popoverArrowRight, placement]);

    const onToggleCalendar = useCallback(state => {
        if (!state && !isPreventPopoverQuestion?.current) {
            setOnCalendar(state);
        }
    }, []);

    function onClickCalendar() {
        if (isPreventPopoverQuestion?.current) {
            return;
        }

        function onConfirmToggle() {
            isPreventPopoverQuestion.current = false;
            setOnCalendar(currentState => !currentState);
        }

        function onCancellToggle() {
            isPreventPopoverQuestion.current = false;
        }

        if (isFunction(onPreventDateChange)) {
            isPreventPopoverQuestion.current = true;
            onPreventDateChange(onConfirmToggle, onCancellToggle);
            return;
        }

        onConfirmToggle();
    }

    return (
        <div className={clsx(classes.container, className)}>
            {label && <Label label={label} required={required} isValue={startDate && endDate} />}
            <OverlayTrigger
                show={isOnCalendar}
                trigger="click"
                rootClose
                onToggle={onToggleCalendar}
                onExited={exitPopover}
                placement={placement}
                overlay={
                    <Popover className={clsx(classes.popover, popoverArrowClasses)} show={isOnCalendar}>
                        <Popover.Content>
                            <RangeDatePicker
                                startDate={date.start}
                                endDate={date.end}
                                isFirstPick={isFirstPick}
                                setStartDate={handleStartDate}
                                setEndDate={handleEndDate}
                                setInputStartDate={value => setInputStartDate(value)}
                                setInputEndDate={value => setInputEndDate(value)}
                                setFirstPick={setFirstPick}
                                endDateDaysFromStartDate={endDateDaysFromStartDate}
                            />

                            <FormRangeCalendarInputs
                                date={date}
                                setDate={setDate}
                                endDate={date.end}
                                startDate={date.start}
                                inputStartDate={inputStartDate}
                                setInputStartDate={setInputStartDate}
                                inputEndDate={inputEndDate}
                                setInputEndDate={setInputEndDate}
                                setIsDisabled={setIsApplyDisabled}
                                isFirstPick={isFirstPick}
                                setFirstPick={setFirstPick}
                                endDateDaysFromStartDate={endDateDaysFromStartDate}
                            />

                            <BasicButton className={classes.applyBtn} onClick={applyDate} disabled={isApplyDisabled} fill>
                                {t('default.apply', 'Apply')}
                            </BasicButton>
                        </Popover.Content>
                    </Popover>
                }
            >
                <Button disabled={disabled} type="button" className={classes.dateBtn} onClick={onClickCalendar}>
                    {formDate}
                    <Svg style={classes.icon} iconId="icon-calendar" />
                </Button>
            </OverlayTrigger>
            {(isEmpty(startDate) || isEmpty(endDate))
                && <ErrorMessage invalid={invalid} errorMessage={errorMessage} />}
        </div>
    );
}
