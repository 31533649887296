import React from 'react';
import { createUseStyles } from 'react-jss';
import { dateTimeUtils } from 'shared/src/modules';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        ...theme.overFlowText,
        color: theme.color.jet,
        display: 'block',
        height: '100%',
        lineHeight: '56px',
        fontSize: 12,
        fontWeight: 500,
        verticalAlign: 'middle',
    },
}));

export default function DateTimeCell(props) {
    const { value } = props;
    const classes = useStyles();

    function getFormattedDate() {
        return dateTimeUtils.isValidDate(value) ? dateTimeUtils.getDateTimeFormat(value) : null;
    }

    return (
        <div className={classes.baseStyle} title={getFormattedDate()}>
            {getFormattedDate()}
        </div>
    );
}
