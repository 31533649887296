import { simpleAction } from './baseActions';

const workOperationsManagementActions = {
    setNewMaterialMultiGridHydrationData,
    resetNewMaterialMultiGridHydrationData,
    setNewMaterialMultiGridisLoaded,
    SET_NEWMATERIALMULTIGRID_HYDRATIONDATA: 'WORKOPERATIONS_SET_NEWMATERIALMULTIGRID_HYDRATIONDATA',
    RESET_NEWMATERIALMULTIGRID_HYDRATIONDATA: 'WORKOPERATIONS_RESET_NEWMATERIALMULTIGRID_HYDRATIONDATA',
    SET_NEWMATERIALMULTIGRID_ISMULTIGRIDLOADED: 'WORKOPERATIONS_SET_NEWMATERIALMULTIGRID_ISMULTIGRIDLOADED',
};

export { workOperationsManagementActions };

function setNewMaterialMultiGridHydrationData(hydrationData) {
    return (simpleAction(workOperationsManagementActions.SET_NEWMATERIALMULTIGRID_HYDRATIONDATA, { hydrationData }));
}

function setNewMaterialMultiGridisLoaded(isMultiGridLoaded) {
    return (simpleAction(workOperationsManagementActions.SET_NEWMATERIALMULTIGRID_ISMULTIGRIDLOADED, { isMultiGridLoaded }));
}

function resetNewMaterialMultiGridHydrationData() {
    return (simpleAction(workOperationsManagementActions.RESET_NEWMATERIALMULTIGRID_HYDRATIONDATA));
}
