import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { ALERT } from 'shared/src/constants';
import { alertActions } from '@states/actions/alertActions';
import { dateTimeUtils } from 'shared/src/modules';
import { isArray, isEmpty } from 'lodash';
import { sentryService } from '@services';

const { ADD_ALERT } = alertActions;

const { getValuesByKey } = arrayUtils;

export function showErrorAlert(message, error) {
    if (error) {
        sentryService.captureException(error);
    } else {
        sentryService.captureMessage(message);
    }

    const alertId = dateTimeUtils.getTimestampNow();
    return {
        type: ADD_ALERT,
        payload: {
            id: alertId,
            type: ALERT.TYPE.ERROR,
            message,
        },
    };
}

export function setApiParams(paramObject, fixParams, filterTypes) {
    const params = { ...fixParams };
    Object.entries(paramObject)?.forEach(filterItem => {
        const [filterName, filterValue] = filterItem;
        const isDisabledFilter = filterTypes?.[filterName]?.isDisabledAsParams;

        if (isDisabledFilter) {
            return;
        }

        if (filterName === 'dateRange') {
            params.startDate = filterValue?.startDate;
            params.endDate = filterValue?.endDate;
        } else if (!isArray(filterValue)) {
            params[filterName] = filterValue;
        } else {
            params[filterName] = getValuesByKey(filterValue, 'id', 'id');
        }
    });
    return params;
}

export function setInterfaceData(types, values) {
    const interFaceData = {};

    if (isEmpty(types)) {
        return null;
    }

    Object.entries(types).filter(filterType => filterType[1].saveInterfaceSetting).forEach(filterType => {
        interFaceData[filterType[0]] = values[filterType[0]];
    });
    return JSON.stringify(interFaceData);
}
