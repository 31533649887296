export const deleteProperty = (key, { [key]: valueTodelete, ...newObject }) => newObject;

export const setLoadingStates = (key, loadingStates, isLoading, hasLoadingError = null, errorMessage = null) => (
    {
        ...loadingStates,
        [key]: {
            [`${key}IsLoading`]: isLoading,
            [`${key}HasLoadingError`]: hasLoadingError || loadingStates[key][`${key}HasLoadingError`],
            [`${key}ErrorMessage`]: errorMessage || loadingStates[key][`${key}ErrorMessage`],
        },
    });

export const setSingleLoadingState = (loadingStates, isLoading, hasLoadingError = null, errorMessage = null) => ({
    isLoading,
    hasLoadingError: hasLoadingError || loadingStates.hasLoadingError,
    errorMessage: errorMessage || loadingStates.errorMessage,
});
