import { Nullable } from '@flowsCommon/services/baseTypes';
import { SKInfoPanelTabData } from '@map/components/SlovakReportsOverlay/slovakReportsUtils';
import { SlovakLayer } from './slovakReportTypes';
import { InfoPanelTab } from '@map/components/SlovakReportsOverlay/SlovakInfoPanel/SlovakInfoPanel';

export type TSlovakReportStore = {
  gsaaYear: number;
  gsaaKODKD: Nullable<string>;
  auth: {
    token: string;
  },
  isDrawMode: boolean;
  map?: Nullable<google.maps.Map>;
  isInitalized: boolean;
  dataLayer: Nullable<google.maps.Data>;
  lpisDataLayer?: Nullable<google.maps.Data>;
  publishedPolygonsDataLayer?: Nullable<google.maps.Data>;
  selectedLpisPolygon: google.maps.Data.Feature | null;
  layering: {
    layers: Nullable<number[]>;
  };
  infoPanel: {
    isOpen: boolean,
    posX: number,
    posY: number,
    tabType: InfoPanelTab,
    tabData: SKInfoPanelTabData[],
    onClose?: () => void,
  }
};

export const initSlovakReportStore: TSlovakReportStore = {
    gsaaYear: -1,
    gsaaKODKD: null,
    auth: {
        token: '',
    },
    map: null,
    isDrawMode: false,
    isInitalized: false,
    lpisDataLayer: null,
    publishedPolygonsDataLayer: null,
    selectedLpisPolygon: null,
    dataLayer: null,
    layering: {
        layers: null,
    },
    infoPanel: {
        isOpen: false,
        tabType: InfoPanelTab.NONE,
        posX: 0,
        posY: 0,
        tabData: [],
    },
};
