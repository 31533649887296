import * as yup from 'yup';

export interface MeteorolgyLocationType {
    lat?: number,
    lng?: number
}

export interface MeteorolgyFormType {
    id: number,
    name: string,
    cityId: number,
    companyIds: number[],
    location: MeteorolgyLocationType,
    deviceReference: string
}

export const meteorologySchema = yup.object<MeteorolgyFormType>({
    id: yup.number().required(),
    name: yup.string().required(),
    cityId: yup.number().required(),
    companyIds: yup.array<number>().required().min(1),
    location: yup.object<MeteorolgyLocationType>().shape({
        lat: yup.number().required(),
        lng: yup.number().required(),
    }).required(),
    deviceReference: yup.string(),
});
