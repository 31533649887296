import { MapContextProvider } from '@map/services/mapContext';
import MapScreenContainer, { MapProps } from './MapScreenContainer';

export default function Map(props: MapProps) {
    return (
        <MapContextProvider>
            <MapScreenContainer {...props} />
        </MapContextProvider>
    );
}
