import { useSlovakReportContext } from '@map/services/slovakianReports/slovakReportContext';
import { createUseStyles } from 'react-jss';
import { isFunction, map } from 'lodash';
import { useEffect, useState } from 'react';
import Svg from '@baseComponents/Svg';
import { Button } from '@baseComponents/buttons';
import { SKInfoPanelTabData } from '../slovakReportsUtils';
import { useMapContext } from '@map/services/mapContext';

export type InfoPanelCallback = (isOpen: boolean, posX: number, posY: number, tabType: InfoPanelTab, tabData?: SKInfoPanelTabData[], onClose?: () => void) => void;

export enum InfoPanelTab {
  NONE = 'NONE',
  LPIS = 'LPIS',
  PUBLISHED_POLYGON = 'PUBLISHED_POLYGON',
}

const useStyles = createUseStyles((theme: any) => ({
    container: {
        width: 300,
        display: 'inline-table',
        position: 'absolute',
        background: theme.color.white,
        zIndex: 5,
        backgroundColor: theme.color.white,
        fontFamily: 'Rubik',
        borderRadius: 5,
        maxHeight: 'max(60%, 300px)',
        whiteSpace: 'nowrap',
    // height: 'max(20%, 300px)',
    },
    closeButton: {
        position: 'absolute',
        right: 10,
        top: 12,
        display: 'block',
        boxSizing: 'border-box',
        width: 30,
        height: 30,
        borderWidth: 8,
        borderStyle: 'solid',
        borderColor: theme.color.pearl,
        borderRadius: '100%',
        background:
      '-webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, #0B0D0F 46%, #0B0D0F 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, #0B0D0F 46%, #0B0D0F 56%,transparent 56%, transparent 100%)',
        backgroundColor: '#E3E6E8',
        transition: 'all 0.3s ease',
        cursor: 'pointer',
    },
    itemTitle: {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 600,
        color: theme.color.raven,
    },
    tabContainer: {
        padding: [0, 10],
    },
    tabPropertyWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: 4,
    },
    tabProperty: {
        width: '100%',
        fontSize: 12,
        marginBottom: 0,
        flex: 1,
    },
    tabBottom: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 'auto',
        marginTop: 10,
        width: 'auto',
        '& svg': {
            width: 24,
            height: 24,
            cursor: 'pointer',
        },
    },
    drawButton: {
        padding: 10,
        paddingBottom: 0,
    },
}));

function SlovakInfoPanelTab({ tabData, classes }: {tabData: SKInfoPanelTabData; classes: any}) {
    const { header, properties } = tabData ?? {};

    if (!properties) {
        return null;
    }

    return (
        <div className={classes.tabContainer}>
            <h2 className={classes.itemTitle}>{header}</h2>
            {Object.entries(properties).map(([key, value]) => (
                <div key={`sk_info_panel_prop_${key}`} className={classes.tabPropertyWrapper}>
                    <p className={classes.tabProperty}>{key}:</p>
                    <p className={classes.tabProperty}>{value}</p>
                </div>
            ))}
        </div>
    );
}

export default function SlovakInfoPanel() {
    const [store, controller] = useSlovakReportContext();
    const [mapStore, mapController] = useMapContext();
    const classes = useStyles();

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const { isOpen, posX, posY, tabData, onClose } = store.infoPanel;
    const { isDrawMode } = store;

    useEffect(() => {
        setActiveTabIndex(0);
    }, [tabData]);

    function closeInfoPanel() {
        if (isFunction(onClose)) {
            onClose();
        }
        setActiveTabIndex(0);
        controller.setInfoPanel(false, 0, 0, InfoPanelTab.NONE);
    }

    function onDrawClick(drawMode = !isDrawMode) {
        controller.setDrawMode(drawMode);
        const currentLpisPolygon = store.selectedLpisPolygon;

        if (drawMode) {
            mapStore.drawing.dataLayer?.forEach(feature => mapStore.drawing.dataLayer?.remove(feature));

            store.lpisDataLayer?.forEach(feature => {
                if (feature.getId() !== currentLpisPolygon?.getId()) {
                    store.lpisDataLayer?.remove(feature);
                }
            });
        }

        closeInfoPanel();
    }

    if (!isOpen) {
        return null;
    }

    const isDrawButton = tabData?.[activeTabIndex]?.isDraw;

    return (
        <div className={classes.container} style={{ left: posX, top: posY }}>
            <span className={classes.closeButton} role="button" aria-label="Close" aria-hidden="true" onClick={() => closeInfoPanel()} onKeyDown={() => closeInfoPanel()} />
            <SlovakInfoPanelTab tabData={tabData?.[activeTabIndex]} classes={classes} />

            {isDrawButton ? (
                <div className={classes.drawButton}>
                    <Button size="small" type="secondary" onClick={() => onDrawClick()}>{isDrawMode ? 'VYBERTE REŽIM' : 'REŽIM KRESLENIA'}</Button>
                </div>
            ) : null}

            <div className={classes.tabBottom}>
                <Svg iconId="icon-chevron_left" onClick={() => setActiveTabIndex(prev => Math.max(prev - 1, 0))} />
                {activeTabIndex + 1} / {tabData.length}
                <Svg iconId="icon-chevron_right" onClick={() => setActiveTabIndex(prev => Math.min(prev + 1, tabData.length - 1))} />
            </div>
        </div>
    );
}
