import React, { useRef } from 'react';
import { createUseStyles } from 'react-jss';
import { Modal } from 'react-bootstrap';
import { isNumber } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import { DATE } from 'shared/src/constants';

import Counter from '@baseComponents/inputs/Counter';
import Input from '@baseComponents/inputs/Input';
import Button from '@baseComponents/buttons/Button';
import Svg from '@baseComponents/Svg';
import useNewPeriodYear from './useNewPeridodYear';

const useStyles = createUseStyles((theme: any) => ({
    fade: {
        height: '100vh',
        width: '100vw',
        opacity: 0.8,
        backgroundColor: theme.color.jet,
        zIndex: 9999,
        position: 'absolute',
        left: -60,
        top: -40,
    },
    modal: {
        '& .modal-content': {
            width: '480px',
            margin: 'auto',
        },
        '& .modal-header': {
            alignItems: 'center',
            padding: '15px 30px 15px 30px',
            margin: 0,
            '& h4': {
                fontSize: '20px !important',
                fontWeight: 700,
                fontFamily: 'Rubik',
            },
        },
        '& .modal-body': {
            display: 'block',
            padding: '30px 30px 50px 30px',
            backgroundColor: theme.color.lilian,
        },
        '& .modal-footer': {
            padding: '15px 30px 25px 30px',
            margin: 0,
            bottom: 0,
        },
        '& .close': {
            top: 0,
        },
        '& .modal-dialog.modal-xl.modal-dialog-centered': {
            left: 0,
        },
    },
    iconProp: {
        height: 60,
        width: 60,
        borderRadius: '50%',
        backgroundColor: theme.color.pearl,
        padding: '10px 10px 10px 10px',
    },
    inputs: {
        display: 'grid',
        gridTemplateColumns: 'repeat( auto-fit, minmax(45%, 1fr) )',
        gridGap: 15,
    },
    close: {
        cursor: 'pointer',
        height: 40,
        width: 40,
        borderRadius: '50%',
        backgroundColor: theme.color.pearl,
        zIndex: theme.zIndex.expanded,
        outline: 'none',
        border: 'none',
    },
    toggle: {
        height: 24,
        width: 24,
    },
    saveButton: {
        marginRight: 10,
        width: 150,
    },
    closeButton: {
        marginLeft: 0,
        width: 100,
    },
}));

export default function AddNewPeriodYearModal(props) {
    const { show, handleCloseClicked, reference, periodYearList, changeSelectedYear, setPeriodYearList } = props;
    const { t } = useTranslate();
    const productionPeriodRef = useRef();
    const { setFormIsDirty, values, setFieldValue, handleSubmit, errors } = useNewPeriodYear({ periodYearList, changeSelectedYear, setPeriodYearList, handleCloseClicked, productionPeriodRef });
    const classes = useStyles();

    function handleChangecalendarYear(selectedPeriodYear) {
        if (isNumber(selectedPeriodYear)) {
            setFieldValue('calendarYearValue', selectedPeriodYear);
        } else {
            setFieldValue('calendarYearValue', '');
        }
    }

    function savePeriodYear() {
        setFormIsDirty(true);
        handleSubmit();
    }

    return (
        <>
            <div className={classes.fade} />
            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleCloseClicked} className={classes.modal} ref={reference}>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4>{t('periodYear.addNewPeriodYear', 'Add new period year')}</h4>
                    </Modal.Title>
                    <button className={classes.close} type="button" onClick={handleCloseClicked}>
                        <Svg iconId="icon-close" style={classes.toggle} />
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className={classes.inputs}>
                        <Counter
                            name="calendarYear"
                            value={+values.calendarYearValue}
                            setValue={handleChangecalendarYear}
                            label={t('periodYear.calendarYear', 'Calendar year')}
                            required
                            invalid={!!errors.calendarYearValue}
                            errorMessage={errors.calendarYearValue}
                            maxCounterLength={DATE.MAX_LENGTH}
                        />
                        <Input
                            ref={productionPeriodRef}
                            type="text"
                            name="productionYear"
                            label={t('periodYear.period', 'Period')}
                            disabled
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={classes.saveButton} onClick={() => savePeriodYear()}>
                        {t('default.save', 'SAVE')}
                    </Button>
                    <Button className={classes.closeButton} type="secondary" onClick={handleCloseClicked}>
                        {t('default.cancel', 'CANCEL')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
