import { RIGHTS, SETTINGS, SYSTEM } from '../constants';

export const TEST = {
    MODULE_ID: 35,
    NAME: 'test',
    RIGHTS: [],
    SETTINGS: [
        { [SETTINGS.USING_TESTS]: 1 },
        { [SETTINGS.USE_GLOBAL_LITE]: 0 },
    ],
    EXCLUDE_SYSTEM_MODULES: [
        SYSTEM.SYSTEM_MODULE_TYPE.CATTLE_FARMING,
        SYSTEM.SYSTEM_MODULE_TYPE.PIG_FARM,
        SYSTEM.SYSTEM_MODULE_TYPE.RABBIT_FARM,
        SYSTEM.SYSTEM_MODULE_TYPE.POULTRY_FARM,
    ],
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'test',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.test',
                TRANSLATOR_DEFAULT: 'Test',
            },
        },
        TEST: {
            CONTENT_URL_ID: { test: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'tests',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.test',
                TRANSLATOR_DEFAULT: 'Test',
            },
            RIGHTS: [RIGHTS.TEST_INTERFACE_ACCESS_EDIT],
        },
        TEST_EDIT: {
            CONTENT_URL_ID: { testEdit: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'testEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.testEdit',
                TRANSLATOR_DEFAULT: 'Test edit',
            },
            RIGHTS: [RIGHTS.TEST_INTERFACE_ACCESS_EDIT],
        },
        TEST_PARAMETERS: {
            CONTENT_URL_ID: { testParameters: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'testParameters',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.testParameters',
                TRANSLATOR_DEFAULT: 'Test parameters',
            },
            RIGHTS: [RIGHTS.TEST_PARAMETERS_ACCESS_EDIT],
            SHOW_IN_PANNING_MODE: true,
        },
        TEST_PARAMETER_GROUPS: {
            CONTENT_URL_ID: { testParameterGroups: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'testParameterGroups',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.testParameterGroups',
                TRANSLATOR_DEFAULT: 'Test parameter groups',
            },
            RIGHTS: [RIGHTS.TEST_PARAMETERS_ACCESS_EDIT],
            SHOW_IN_PANNING_MODE: true,
        },
        TEST_PARAMETER_UNITS_OF_MEASUREMENT: {
            CONTENT_URL_ID: { testParameterUnitsOfMeasurement: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'testParameterUnitsOfMeasurement',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.testParameterUnitsOfMeasurement',
                TRANSLATOR_DEFAULT: 'Test parameter units of measurement',
            },
            RIGHTS: [RIGHTS.TEST_PARAMETERS_ACCESS_EDIT],
            SHOW_IN_PANNING_MODE: true,
        },
        PATTERNS: {
            CONTENT_URL_ID: { patterns: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'patterns',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.patterns',
                TRANSLATOR_DEFAULT: 'Patterns',
            },
            RIGHTS: [RIGHTS.PATTERN_INTERFACE_ACCESS_EDIT],
        },
        PATTERN_EDIT: {
            CONTENT_URL_ID: { patternEdit: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'patternEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.patternEdit',
                TRANSLATOR_DEFAULT: 'Pattern edit',
            },
            RIGHTS: [RIGHTS.PATTERN_INTERFACE_ACCESS_EDIT],
        },
        LIVESTOCK_REVIEW: {
            CONTENT_URL_ID: { livestockReview: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockReview',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockReview',
                TRANSLATOR_DEFAULT: 'Live stock review',
            },
            RIGHTS: [RIGHTS.PATTERN_INTERFACE_ACCESS_EDIT],
        },
        LIVESTOCK_REVIEW_EDIT: {
            CONTENT_URL_ID: { livestockReviewEdit: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'livestockReviewEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.livestockReviewEdit',
                TRANSLATOR_DEFAULT: 'Live stock review edit',
            },
            RIGHTS: [RIGHTS.PATTERN_INTERFACE_ACCESS_EDIT],
        },
        SOIL_TESTING_DATA: {
            CONTENT_URL_ID: { soilTestingData: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'soilTestingData',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.soilTestingData',
                TRANSLATOR_DEFAULT: 'Soil testing data',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS_EDIT],
        },
        SOIL_TESTING_DATA_EDIT: {
            CONTENT_URL_ID: { soilTestingDataEdit: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'soilTestingDataEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.soilTestingDataEdit',
                TRANSLATOR_DEFAULT: 'Edit soil testing data',
            },
            RIGHTS: [RIGHTS.TABLES_OF_OFFICIAL_DATAS_EDIT],
        },
    },
};
