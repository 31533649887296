import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';

import Checkbox from '@baseComponents/controls/Checkbox';
import useTableGroupSelect from './useTableGroupSelect';

const useStyle = createUseStyles((theme: any) => ({
    groupCheckboxContainer: {
        display: 'inline-block',
        ...theme.disableOutline,
        ...theme.disableSelectionColor,
    },
}));

export default function TableGroupCheckbox(props) {
    const { groupName, isBasePageTable, tableKey } = props;
    const isEnabledGroupOperation = useSelector((state: any) => state.basePage.isEnabledGroupOperation);
    const [isSelectedGroup, setIsSelectedGroup] = useState(false);
    const classes = useStyle();
    const { changeGroupCheckedState } = useTableGroupSelect({ setIsSelectedGroup, isSelectedGroup, groupName, isBasePageTable, tableKey });

    async function setSelectedOnClick(status) {
        setIsSelectedGroup(currentStateus => status || !currentStateus);
    }

    if ((!isEnabledGroupOperation && isBasePageTable) || (!isBasePageTable && !tableKey)) {
        return null;
    }

    return (
        <div className={classes.groupCheckboxContainer}>
            <Checkbox checked={isSelectedGroup} size="small" setChecked={setSelectedOnClick} onChange={changeGroupCheckedState} />
        </div>
    );
}
