/* eslint-disable max-lines-per-function */
import { isNull, isArray, isEmpty } from 'lodash';
import { TABLE } from 'shared/src/constants';
import { basePageActions } from '../actions/basePageActions';

const initialState = {
    filters: null,
    currentFilters: null,
    isResetedFilters: false,
    isOpenedRightSide: null,
    isVisibleHeaderFunctionLine: true,
    isVisibleFixedColumnLine: false,
    groups: null,
    groupingBy: null,
    selectedGroup: null,
    isAllTableCollapsed: null,
    isAllTableGroupSelected: false,
    showedColumnType: null,
    selectedCustomColumns: null,
    showMapView: false,
    table: {
        isRenderedTable: false,
        otherDataList: null,
        headerColumnWidths: null,
        fixedHeaderRef: null,
        fixedFooterRef: null,
        selectedItemIds: null,
        multilineViewType: TABLE.VIEW_TYPE.SIMPLE, //cost, cost/area view
        aggregateType: null,
        columns: null,
        customColumns: null,
        tableData: null,
        isEnabledSearching: false,
        globalSearchValue: null, //global search input
        globalColumnSearchValues: null, //columns search input
        globalColumnSortingTypes: null, //column sort types
        filteredDatas: null,
        reloadTable: false,
    },
    modals: {},
};

export function basePage(state = initialState, action) {
    switch (action.type) {
        case basePageActions.RESET_BASE_PAGE_DATA:
            return {
                ...state,
                currentFilters: null,
                isResetedFilters: null,
                isOpenedRightSide: null,
                isVisibleHeaderFunctionLine: true,
                isVisibleFixedColumnLine: false,
                isAllTableCollapsed: null,
                isAllTableGroupSelected: false,
                isEnabledGroupOperation: false,
                showedColumnType: null,
                showMapView: false,
                groupingBy: null,
                selectedGroup: null,
                selectedCustomColumns: null,
                table: {
                    ...state.table,
                    tableData: null,
                    selectedItemIds: null,
                    isEnabledSearching: null,
                    globalSearchValue: null,
                    globalColumnSearchValues: null,
                    globalColumnSortingTypes: null,
                    filteredDatas: null,
                    multilineViewType: TABLE.VIEW_TYPE.SIMPLE,
                },
            };
        case basePageActions.SET_VISIBLE_HEADER_FUNCTION_LINE:
            return {
                ...state,
                isVisibleHeaderFunctionLine: action.payload.isVisibleHeaderFunctionLine,
            };
        case basePageActions.SET_VISIBLE_FIXED_COLUMN_LINE:
            return {
                ...state,
                isVisibleFixedColumnLine: action.payload.isVisibleFixedColumnLine,
            };
        case basePageActions.ADD_FILTER:
            return {
                ...state,
                isAllTableGroupSelected: action.payload.filterName === 'periodYearFilter' ? false : state.isAllTableGroupSelected,
                filters: {
                    ...state.filters,
                    [action.payload.filterName]: action.payload.filterValue,
                },
            };
        case basePageActions.SET_FILTERS:
            return {
                ...state,
                filters: action.payload.filters,
            };
        case basePageActions.SET_CURRENT_FILTERS:
            return {
                ...state,
                currentFilters: action.payload.currentFilters,
            };
        case basePageActions.SET_CURRENT_FILTER:
            return {
                ...state,
                currentFilters: {
                    ...state.currentFilters,
                    [action.payload.currentFilterName]: action.payload.currentFilterValue,
                },
            };
        case basePageActions.SET_IS_RESET_FILTERS:
            return {
                ...state,
                isResetedFilters: action.payload.isResetedFilters,
            };
        case basePageActions.SET_OPENED_RIGHT_SIDE: {
            return {
                ...state,
                isOpenedRightSide: action.payload.isOpenedRightSide,
            };
        }
        case basePageActions.SET_GROUPS:
            return {
                ...state,
                groups: action.payload.groups,
            };
        case basePageActions.SET_GROUPING_BY:
            return {
                ...state,
                groupingBy: action.payload.groupingBy,
                selectedGroup: getSelectedGroup(state.groups, action.payload.groupingBy),
            };
        case basePageActions.SET_GROUP_OPERATION:
            //reset group operation selected ids
            if (!action.payload.isEnabled) {
                return {
                    ...state,
                    isEnabledGroupOperation: action.payload.isEnabled,
                    isAllTableGroupSelected: false,
                    table: {
                        ...state.table,
                        selectedItemIds: null,
                    },
                };
            }
            return {
                ...state,
                isEnabledGroupOperation: action.payload.isEnabled,
            };
        case basePageActions.SET_ALL_TABLE_COLLAPSED:
            return {
                ...state,
                isAllTableCollapsed: action.payload.isAllTableCollapsed,
            };
        case basePageActions.SET_ALL_TABLE_GROUP_SELECTED: {
            const { isAllTableGroupSelected, selectedItemIds } = action.payload;
            return {
                ...state,
                isAllTableGroupSelected,
                table: {
                    ...state.table,
                    selectedItemIds,
                },
            };
        }
        case basePageActions.SET_SHOWED_COLUMN_TYPE:
            return {
                ...state,
                showedColumnType: action.payload.columnType,
            };
        case basePageActions.SET_SELECTED_CUSTOM_COLUMNS:
            return {
                ...state,
                selectedCustomColumns: action.payload.selectedCustomColumns,
            };
        case basePageActions.REMOVE_ALL_TABLE_COLLAPSED:
            return {
                ...state,
                isAllTableCollapsed: null,
            };
        case basePageActions.SET_SHOW_MAP_VIEW:
            return {
                ...state,
                showMapView: action.payload.showMapView,
            };
        //table
        case basePageActions.SET_IS_RENDERED_TABLE:
            return {
                ...state,
                table: {
                    ...state.table,
                    isRenderedTable: action.payload.isRenderedTable,
                },
            };
        case basePageActions.SET_HEADER_COLUMN_WIDTHS:
            return {
                ...state,
                table: {
                    ...state.table,
                    headerColumnWidths: action.payload.headerColumnWidths,
                },
            };
        case basePageActions.ADD_SELECTED_IDS:
            return {
                ...state,
                table: {
                    ...state.table,
                    selectedItemIds: filterAddedGroupItemIds(state.table.selectedItemIds, action.payload.selectedIds),
                },
            };
        case basePageActions.REMOVE_SELECTED_IDS:
            return {
                ...state,
                table: {
                    ...state.table,
                    selectedItemIds: filterSelectedItem(state.table.selectedItemIds, action.payload.selectedIds),
                },
            };
        case basePageActions.SET_SELECTED_IDS:
            return {
                ...state,
                table: {
                    ...state.table,
                    selectedItemIds: action.payload.selectedIds,
                },
            };
        case basePageActions.REMOVE_ALL_SELECTED_ID:
            return {
                ...state,
                table: {
                    ...state.table,
                    selectedItemIds: [],
                },
            };
        case basePageActions.SET_TABLE_MULTILINE_VIEW:
            return {
                ...state,
                table: {
                    ...state.table,
                    multilineViewType: action.payload.multilineViewType,
                },
            };
        case basePageActions.SET_TABLE_COLUMNS:
            return {
                ...state,
                table: {
                    ...state.table,
                    columns: action.payload.columns,
                },
            };
        case basePageActions.SET_TABLE_CUSTOM_COLUMNS:
            return {
                ...state,
                table: {
                    ...state.table,
                    customColumns: action.payload.columns,
                },
            };
        case basePageActions.RESET_TABLE_CUSTOM_COLUMNS:
            return {
                ...state,
                table: {
                    ...state.table,
                    customColumns: null,
                },
            };
        case basePageActions.SET_TABLE_DATA:
            return {
                ...state,
                isAllTableGroupSelected: false,
                table: {
                    ...state.table,
                    selectedItemIds: null,
                    tableData: action.payload.tableData,
                },
            };
        case basePageActions.SET_TABLE_AGGREGATE_TYPE:
            return {
                ...state,
                table: {
                    ...state.table,
                    aggregateType: action.payload.aggregateType,
                },
            };
        case basePageActions.SET_TABLE_FIXED_HEADER_REF:
            return {
                ...state,
                table: {
                    ...state.table,
                    fixedHeaderRef: action.payload.fixedHeaderRef,
                },
            };
        case basePageActions.SET_TABLE_FIXED_FOOTER_REF:
            return {
                ...state,
                table: {
                    ...state.table,
                    fixedFooterRef: action.payload.fixedFooterRef,
                },
            };
        case basePageActions.SET_TABLE_ENABLED_SEARCHING:
            //reset search filters
            if (!action.payload.isEnabledSearching) {
                return {
                    ...state,
                    table: {
                        ...state.table,
                        isEnabledSearching: action.payload.isEnabledSearching,
                        globalSearchValue: null,
                        globalColumnSearchValues: null,
                    },
                };
            }

            return {
                ...state,
                table: {
                    ...state.table,
                    isEnabledSearching: action.payload.isEnabledSearching,
                },
            };
        case basePageActions.SET_TABLE_GLOBAL_SEARCH_VALUE:
            return {
                ...state,
                table: {
                    ...state.table,
                    globalSearchValue: action.payload.globalSearchValue,
                },
            };
        case basePageActions.SET_TABLE_GLOBAL_COLUMN_SEARCH_VALUES:
            return {
                ...state,
                table: {
                    ...state.table,
                    globalColumnSearchValues: action.payload.globalColumnSearchValues,
                },
            };
        case basePageActions.ADD_TABLE_GLOBAL_COLUMN_SEARCH_VALUE:
            return {
                ...state,
                table: {
                    ...state.table,
                    globalColumnSearchValues: {
                        ...state.table.globalColumnSearchValues,
                        [action.payload.columnId]: action.payload.searchValue,
                    },
                },
            };
        case basePageActions.REMOVE_TABLE_GLOBAL_COLUMN_SEARCH_VALUE:
            return {
                ...state,
                table: {
                    ...state.table,
                    globalColumnSearchValues: filterSearchValues(state.table.globalColumnSearchValues, action.payload.columnId),
                },
            };
        case basePageActions.ADD_TABLE_GLOBAL_COLUMN_SORTING_TYPE: {
            const { columnId, sortingType } = action.payload;
            if (sortingType === TABLE.SORTING.NONE) {
                const { [columnId]: value, ...stateWithoutDeletedItem } = state.table.globalColumnSortingTypes;
                return {
                    ...state,
                    table: {
                        ...state.table,
                        globalColumnSortingTypes: stateWithoutDeletedItem,
                    },
                };
            }
            return {
                ...state,
                table: {
                    ...state.table,
                    globalColumnSortingTypes: {
                        ...state.table.globalColumnSortingTypes,
                        [action.payload.columnId]: action.payload.sortingType,
                    },
                },
            };
        }
        case basePageActions.REMOVE_TABLE_GLOBAL_COLUMN_SORTING_TYPE:
            return {
                ...state,
                table: {
                    ...state.table,
                    globalColumnSortingTypes: filterSortingTypes(state.table.globalColumnSortingTypes, action.payload.columnId),
                },
            };
        case basePageActions.SET_TABLE_FILTERED_DATAS:
            return {
                ...state,
                table: {
                    ...state.table,
                    filteredDatas: {
                        ...state.table.filteredDatas,
                        [action.payload.groupName]: action.payload.filteredDatas,
                    },
                },
            };
        case basePageActions.SET_TABLE_FILTERED_DATA:
            return {
                ...state,
                table: {
                    ...state.table,
                    filteredDatas: getFilteredTableData(action.payload.groupedData),
                },
            };
        case basePageActions.REMOVE_TABLE_FILTERED_DATAS:
            return {
                ...state,
                table: {
                    ...state.table,
                    filteredDatas: null,
                },
            };
        case basePageActions.SET_MODAL_VISIBILITY:
            return {
                ...state,
                modals: {
                    ...state.modals,
                    [action.payload.modalId]: {
                        isVisible: action.payload.isVisible,
                        values: action.payload.values,
                    },
                },
            };
        case basePageActions.RESET_MODAL_VISIBILITY:
            return {
                ...state,
                modals: {},
            };
        case basePageActions.SET_TABLE_OTHER_DATA_LIST:
            return {
                ...state,
                table: {
                    ...state.table,
                    otherDataList: action.payload.otherDataList,
                },
            };
        case basePageActions.SET_RELOAD_TABLE:
            return {
                ...state,
                table: {
                    ...state.table,
                    reloadTable: action.payload.reloadTable,
                },
            };
        default:
            return state;
    }
}

function getSelectedGroup(groups, groupingBy) {
    if (!isEmpty(groups) && (!isEmpty(groupingBy) || isNull(groupingBy))) {
        return groups.length && groups.find(group => group.value === groupingBy);
    }
    return null;
}

function filterSelectedItem(selectedItemIds, selectedIds) {
    if (!isEmpty(selectedItemIds)) {
        if (isArray(selectedIds)) {
            return selectedItemIds.filter(selectedItemId => !selectedIds.includes(selectedItemId));
        }
        return selectedItemIds.filter(selectedItemId => selectedItemId !== selectedIds);
    }
    return null;
}

function filterAddedGroupItemIds(currentState, addedIds) {
    const selectedIds = [...currentState || []];

    if (!currentState && !addedIds) {
        return null;
    }

    if (isArray(addedIds) && !isEmpty(addedIds)) {
        addedIds.forEach(addedId => {
            if (!selectedIds.find(selectedId => selectedId === addedId)) {
                selectedIds.push(addedId);
            }
        });
    }

    if (!isArray(addedIds) && addedIds && !selectedIds.find(id => id === addedIds)) {
        selectedIds.push(addedIds);
    }

    return selectedIds;
}

function filterSearchValues(globalColumnSearchValues, columnId) {
    if (!isEmpty(globalColumnSearchValues) && columnId) {
        return Object.keys(globalColumnSearchValues).filter(searchObjectKey => searchObjectKey !== columnId);
    }
    return null;
}

function filterSortingTypes(globalColumnSortingTypes, columnId) {
    if (!isEmpty(globalColumnSortingTypes) && columnId) {
        return Object.keys(globalColumnSortingTypes).filter(searchObjectKey => searchObjectKey !== columnId);
    }
    return null;
}

function getFilteredTableData(groupedData) {
    return groupedData
        ? groupedData.reduce((acc, curr) => {
            const { groupName, groupArray } = curr;
            return { ...acc, [groupName]: groupArray };
        }, [])
        : [];
}
