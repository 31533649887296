import { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Timeit } from 'react-timeit';
import useTranslate from '@i18n/useTranslate';
import BasicButton from '@baseComponents/buttons/Button';

const useStyles = createUseStyles((theme: any) => ({
    timePicker: {
        color: theme.color.main,
        '& .timeit-selector-1-1-6': {
            backgroundColor: theme.color.main,
        },
        '& svg': {
            fill: theme.color.jet,
        },
    },
    applyBtn: {
        marginTop: 15,
    },
}));

export default function TimeCalendar(props) {
    const { defaultTime, setDefaultTime, close } = props;
    const { t } = useTranslate();
    const [time, setTime] = useState();
    const classes = useStyles();

    function applyTime() {
        setDefaultTime(time);
        close();
    }

    return (
        <div className={classes.timePicker}>
            <Timeit
                defualtValue={defaultTime}
                onChange={setTime}
            />
            <BasicButton className={classes.applyBtn} onClick={applyTime} fill>
                {t('default.apply', 'Apply')}
            </BasicButton>
        </div>
    );
}
