import MapIconButton from '@map/components/MapIconButton';
import { MapMultiModeProps } from '@map/services/mapTypes';

export const MapMultiModeButton = ({ isMultiModeEnabled, setIsMultiModeEnabled }: MapMultiModeProps) => {
    const onButtonClick = () => {
        setIsMultiModeEnabled(!isMultiModeEnabled);
    };

    return (
        <MapIconButton
            iconId={isMultiModeEnabled ? 'icon-map-single' : 'icon-map-double'
            }
            onClick={onButtonClick}
            isActive={isMultiModeEnabled}
        />
    );
};
