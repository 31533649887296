/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { isFunction } from 'lodash';
import { SETTINGS } from 'shared/src/constants';
import { MODULES } from 'shared/src/routing';
import { routerHelper } from '@utils/router/RouterHelper';
import { companyActions } from '@states/actions/companyActions';

import renderModal from '@modals/modalNew/modalFunctions';
import MultiPartnerReceptionModal from '@contents/inventory/receptions/multiPartnerReception/MultiPartnerReceptionModal';
import CultureAndVarietyEditModal from '@contents/cultivationPeriods/cultivationPeriodsManagement/cultureAndVarietyEdit/CultureAndVarietyEditModal';
import MapModal from '@map/modals/MapModal';

import GeometrySelectorModal from '@map/modals/GeometrySelectorModal';
import LocationSelectorModal from '@map/modals/MeterologyModal';

import MapWidget from '@map/widgets/MapWidget';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import SlovakReportsSyncModal from '@map/modals/SlovakReportsSyncModal/SlovakReportsSyncModal';

export default function usePhpHibridFunctions() {
    const location = useLocation().pathname;
    const dispatch = useDispatch();
    const [usingMultiPartnerReception] = useState(dispatch(companyActions.getSetting(SETTINGS.MULTIPARTNER_RECEPTION)));

    //init hibrid functions
    useEffect(() => {
        let isCancelled = false;

        if (location) {
            const moduleContentName = routerHelper.getModuleContentNameByUrl(location);
            switch (moduleContentName) {
                case `${MODULES.STOCK.NAME}/${Object.keys(MODULES.STOCK.CONTENTS.INCOMES.CONTENT_URL_ID)[0]}`: //bevételezések
                    initMultiPartnerReceptionModal();
                    initBuiltInLayerTypes();
                    break;
                case `${MODULES.LAND_ADMINISTRATION.NAME}/${Object.keys(MODULES.LAND_ADMINISTRATION.CONTENTS.REGISTRY_NUMBER.CONTENT_URL_ID)[0]}`: //helyrajzi számok
                    initLandAdministrationModal();
                    initBuiltInLayerTypes();
                    break;
                case `${MODULES.LAND_ADMINISTRATION.NAME}/${Object.keys(MODULES.LAND_ADMINISTRATION.CONTENTS.REGISTRY_NUMBER_EDIT.CONTENT_URL_ID)[0]}`: //helyrajzi számok
                    initLandAdministrationModal();
                    initBuiltInLayerTypes();
                    break;
                case `${MODULES.OFFICIAL_REPORTS.NAME}/${Object.keys(MODULES.OFFICIAL_REPORTS.CONTENTS.LPIS_FIELD.CONTENT_URL_ID)[0]}`: //mepártáblák
                    initOfficialReportsModal();
                    initBuiltInLayerTypes();
                    break;
                case `${MODULES.OFFICIAL_REPORTS.NAME}/${Object.keys(MODULES.OFFICIAL_REPORTS.CONTENTS.LPIS_FIELD_EDIT.CONTENT_URL_ID)[0]}`: //mepártábla edit számok
                    initOfficialReportsModal();
                    initBuiltInLayerTypes();
                    break;
                case `${MODULES.CULTIVATION_PERIODS.NAME}/${Object.keys(MODULES.CULTIVATION_PERIODS.CONTENTS.CULTIVATION_TABLES.CONTENT_URL_ID)[0]}`: //alaptáblák
                    initCultivationPeriodCultivationTablesModal();
                    initBuiltInLayerTypes();
                    break;
                case `${MODULES.GENERAL.NAME}/${Object.keys(MODULES.GENERAL.CONTENTS.FARMS.CONTENT_URL_ID)[0]}`: //telephelyek (beállítások/kontrolling/telephelyek)
                    initFarmsModal();
                    initBuiltInLayerTypes();
                    break;
                case `${MODULES.CULTIVATION_PERIODS.NAME}/${Object.keys(MODULES.CULTIVATION_PERIODS.CONTENTS.GROUPS.CONTENT_URL_ID)[0]}`: //természeti időszak csoportok
                    initCultivationPeriodGroupsModal();
                    initBuiltInLayerTypes();
                    initCultureAndVarietyModal();
                    break;
                case `${MODULES.LIVESTOCK_RECORDS.NAME}/${Object.keys(MODULES.LIVESTOCK_RECORDS.CONTENTS.STABLES.CONTENT_URL_ID)[0]}`: // fizikai helyek
                    initStablesModal();
                    initBuiltInLayerTypes();
                    break;
                case `${MODULES.EMPLOYMENT.NAME}/${Object.keys(MODULES.EMPLOYMENT.CONTENTS.CREATE_WORK_OPERATION.CONTENT_URL_ID)[0]}`: //munkamuvelet teruletkivalasztas terkeprol
                    initCreateWorkOperationModal();
                    initBuiltInLayerTypes();
                    break;
                case 'null/null': //főképernyő
                    initDashboardMapWidget();
                    initBuiltInLayerTypes();
                    initPlanningEditModal();
                    break;
                case `${MODULES.GENERAL.NAME}/${Object.keys(MODULES.GENERAL.CONTENTS.METEOROLOGY.CONTENT_URL_ID)[0]}`: //meteorologia
                    initMeterologyMapSelector();
                    break;
                case `${MODULES.PLANNING.NAME}/${Object.keys(MODULES.PLANNING.CONTENTS.CULTIVATION_PERIODS_MANAGEMENT.CONTENT_URL_ID)[0]}`: //meteorologia
                    initPlanningModal(); // tervezes termesztesi idpszakok kezelese
                    break;
                default:
                    break;
            }
        }

        function initBuiltInLayerTypes() {
            window.BuiltInLayerTypes = BuiltInLayerTypes;
        }

        //php table refresh function
        function companyRefresh() {
            // eslint-disable-next-line no-undef
            if (isFunction(ceg_refresh)) { ceg_refresh(); }
        }

        //multi-partner reception modal open
        function initMultiPartnerReceptionModal() {
            if (usingMultiPartnerReception && !isCancelled) {
                window.openMultiPartnerReceptionModal = () => {
                    renderModal(MultiPartnerReceptionModal, { companyRefresh }, true);
                };
            }
        }

        function initCultureAndVarietyModal() {
            if (!isCancelled) {
                window.openCultureAndVarietyChangeModal = ({ seletedPeriodIdList, hasCulture, cultureId, onSuccess }) => {
                    renderModal(CultureAndVarietyEditModal, { seletedPeriodIdList, hasCulture, cultureId, onSuccess }, true);
                };
            }
        }

        function initLandAdministrationModal() {
            window.openMapModal = ({ layerType, entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, withDrawingSaveButton }) => {
                renderModal(MapModal, { layerType, entityId: +entityId, drawnFeature, productionYearId, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, withDrawingSaveButton }, true);
            };
        }

        function initOfficialReportsModal() {
            window.openMapModal = ({ withSlovakOverlay, layerType, entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, withDrawingSaveButton, gsaaYear, gsaaKODKD }) => {
                renderModal(MapModal, { withSlovakOverlay, layerType, entityId: +entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, withDrawingSaveButton, gsaaYear, gsaaKODKD }, true);
            };
        }

        function initCultivationPeriodCultivationTablesModal() {
            window.openMapModal = ({ layerType, entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, withProdYearSelector, startDate, endDate }) => {
                renderModal(MapModal, { layerType, entityId: +entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, withProdYearSelector, startDate, endDate }, true);
            };
        }

        function initFarmsModal() {
            window.openMapModal = ({ layerType, entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withProdYearSelector, withBackToGeom }) => {
                renderModal(MapModal, { layerType, entityId: +entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withProdYearSelector, withBackToGeom }, true);
            };
        }

        function initCultivationPeriodGroupsModal() {
            window.openMapModal = ({ layerType, entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, startDate, endDate }) => {
                renderModal(MapModal, { layerType, entityId: +entityId, productionYearId: +productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, startDate, endDate }, true);
            };
        }

        function initStablesModal() {
            window.openMapModal = ({ layerType, entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom }) => {
                renderModal(MapModal, { layerType, entityId: +entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom }, true);
            };
        }

        function initCreateWorkOperationModal() {
            window.OpenGeometrySelectorModal = ({ entityIds, selectedEntityIds, onSelect, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, layerId }) => {
                renderModal(GeometrySelectorModal, { entityIds, selectedEntityIds, onSelect, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, layerId }, true);
            };
        }

        function initMeterologyMapSelector() {
            window.openMeterologyModal = ({ entityId, settlementId, locationName, deviceReference, onSuccess, onFailure }) => {
                renderModal(LocationSelectorModal, { entityId, onSuccess, settlementId, locationName, deviceReference, onFailure }, true);
            };
        }

        function initPlanningModal() {
            window.openMapModal = ({ layerType, entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, isPlanning, fetchers }) => {
                renderModal(MapModal, { layerType, entityId: +entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, isPlanning, fetchers, mapMode: 1 }, true);
            };
        }

        function initPlanningEditModal() {
            window.openPlanningEditMapModal = ({ layerType, entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, isPlanning, fetchers, planningEditData, onSavePlanningEditModal }) => {
                renderModal(MapModal, { layerType, entityId: +entityId, productionYearId, drawnFeature, onSave, findGeomId, withDrawing, withInfoPanel, withSelectorFilter, withBackToGeom, isPlanning, fetchers, planningEditData, mapMode: 2, onSavePlanningEditModal }, true, null, true);
            };
        }

        function initDashboardMapWidget() {
            window.openMapWidget = () => {
                ReactDOM.render(
                    <MapWidget />,
                    document.getElementById('terkep_panel_body'),
                );
            };
        }

        return () => {
            isCancelled = true;
        };
    }, [location, usingMultiPartnerReception]);
}
