import { RIGHTS, COUNTRY, SETTINGS } from '../constants';

export const STOCK = {
    MODULE_ID: 2,
    NAME: 'stock',
    SHOW_IN_PANNING_MODE: true,
    CONTENTS: {
        INDEX: {
            CONTENT_URL_ID: { index: 1 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stock',
            TITLE: {
                TRANSLATOR_KEY: 'sideMenuTitle.stockInventory',
                TRANSLATOR_DEFAULT: 'Stock inventory',
            },
        },
        RECEIPTS: {
            CONTENT_URL_ID: { receipts: 2 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'receipts',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.receipts',
                TRANSLATOR_DEFAULT: 'Receipts',
            },
            RIGHTS: [RIGHTS.RECEIPTS_INTERFACE],
        },
        STOCK: {
            CONTENT_URL_ID: { stock: 3 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stocks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stock',
                TRANSLATOR_DEFAULT: 'Stock',
            },
            RIGHTS: [RIGHTS.STOCKS_INTEFACE],
        },
        INCOMES: {
            CONTENT_URL_ID: { incomes: 4 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockIncomes',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockIncomes',
                TRANSLATOR_DEFAULT: 'Stock incomes',
            },
            RIGHTS: [RIGHTS.INCOMES_INTERFACE],
        },
        SALES: {
            CONTENT_URL_ID: { sales: 5 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockSales',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockSales',
                TRANSLATOR_DEFAULT: 'Stock sales',
            },
            RIGHTS: [RIGHTS.SALES_INTERFACE],
        },
        OTHER_STOCKMOVEMENTS: {
            CONTENT_URL_ID: { otherStockMovements: 6 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'otherStockMovements',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.otherStockMovements',
                TRANSLATOR_DEFAULT: 'Other stock movements',
            },
        },
        STOCK_STORAGE_ACCOUNT: {
            CONTENT_URL_ID: { stockStorageAccount: 7 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockStorageAccount',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockStorageAccount',
                TRANSLATOR_DEFAULT: 'Storage account',
            },
        },
        PRODUCT_INFORMATION: {
            CONTENT_URL_ID: { productInformation: 8 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'productInformation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.productInformation',
                TRANSLATOR_DEFAULT: 'Product information',
            },
        },
        INCOMES_EDIT: {
            CONTENT_URL_ID: { incomesEdit: 9 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockIncomesEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockIncomesEdit',
                TRANSLATOR_DEFAULT: 'Stock incomes edit',
            },
            RIGHTS: [RIGHTS.CREATE_INCOMES],
        },
        YIELD_EDIT: {
            CONTENT_URL_ID: { yieldEdit: 10 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockYieldEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockYieldEdit',
                TRANSLATOR_DEFAULT: 'Stock yield edit',
            },
            RIGHTS: [RIGHTS.YEILD_EDIT],
        },
        INCOMES_INVOICE: {
            CONTENT_URL_ID: { incomesInvoice: 11 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'incomesInvoice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.incomesInvoice',
                TRANSLATOR_DEFAULT: 'Incomes invoice',
            },
            RIGHTS: [RIGHTS.SUPPLIER_INVOICE_RECORD],
        },
        YIELDING: {
            CONTENT_URL_ID: { yielding: 12 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockYielding',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockYielding',
                TRANSLATOR_DEFAULT: 'Stock yielding',
            },
            RIGHTS: [RIGHTS.YIELD_INTERFACE],
        },
        PURCHASE_EDIT: {
            CONTENT_URL_ID: { purchaseEdit: 13 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockPurchaseEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockPurchaseEdit',
                TRANSLATOR_DEFAULT: 'Stock purchase edit',
            },
            RIGHTS: [RIGHTS.SALES_EDIT],
        },
        RELEASE_OF_WORK_OPERATION: {
            CONTENT_URL_ID: { releaseOfWorkOperation: 14 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'releaseOfWorkOperation',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.releaseOfWorkOperation',
                TRANSLATOR_DEFAULT: 'Release of work operation',
            },
            RIGHTS: [RIGHTS.RELEASE_OF_WORK_OPERATION_INTERFACE],
        },
        INVENTORY_TRANSFER: {
            CONTENT_URL_ID: { inventoryTransfer: 15 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'inventoryTransfer',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.inventoryTransfer',
                TRANSLATOR_DEFAULT: 'Inventory transfer',
            },
            RIGHTS: [RIGHTS.INVENTORY_TRANSFER_INTERFACE],
        },
        INVENTORY_TRANSFER_EDIT: {
            CONTENT_URL_ID: { inventoryTransferEdit: 16 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'inventoryTransferEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.inventoryTransferEdit',
                TRANSLATOR_DEFAULT: 'Inventory transfer edit',
            },
            RIGHTS: [RIGHTS.CREATE_TRANSFER],
        },
        SALES_RO: {
            CONTENT_URL_ID: { salesRO: 17 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'salesRO',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Vânzări',
            },
            INCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.SALES_INTERFACE],
        },
        SALES_EDIT_RO: {
            CONTENT_URL_ID: { salesEditRO: 18 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'salesEditRO',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Vânzare nouă',
            },
            INCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.SALES_EDIT],
        },
        FOREIGN_TRANSFER: {
            CONTENT_URL_ID: { foreignTransfer: 19 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'foreignTransfer',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.foreignTransfer',
                TRANSLATOR_DEFAULT: 'Foreign transfer',
            },
            RIGHTS: [RIGHTS.FOREIGN_TRANSFER_INTERFACE],
        },
        FOREIGN_TRANSFER_EDIT: {
            CONTENT_URL_ID: { foreignTransferEdit: 20 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'foreignTransferEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.foreignTransferEdit',
                TRANSLATOR_DEFAULT: 'Foreign transfer edit',
            },
            RIGHTS: [RIGHTS.CREATE_FOREIGN_TRANSFER],
        },
        RE_QUALIFICATION: {
            CONTENT_URL_ID: { reQualification: 21 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'reQualification',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.reQualification',
                TRANSLATOR_DEFAULT: 'Requalification',
            },
            EXCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.RE_QUALIFICATION_INTERFACE],
        },
        RE_QUALIFICATION_EDIT: {
            CONTENT_URL_ID: { reQualificationEdit: 22 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'reQualificationEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.reQualificationEdit',
                TRANSLATOR_DEFAULT: 'Requalification edit',
            },
        },
        RE_QUALIFICATION_RO: {
            CONTENT_URL_ID: { reQualificationRO: 23 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'reQualificationRO',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Recalificare',
            },
            INCLUDE_COUNTRIES: [COUNTRY.ROMANIA],
            RIGHTS: [RIGHTS.RE_QUALIFICATION_INTERFACE],
        },
        RE_QUALIFICATION_EDIT_RO: {
            CONTENT_URL_ID: { reQualificationEditRO: 24 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'reQualificationEditRO',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Recalificare nouă',
            },
        },
        CHANGE: {
            CONTENT_URL_ID: { change: 25 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockChange',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockChange',
                TRANSLATOR_DEFAULT: 'Stock change',
            },
            RIGHTS: [RIGHTS.STOCK_CHANGE_INTERFACE_ACCESS_EDIT],
        },
        EXCHANGE_EDIT: {
            CONTENT_URL_ID: { exchangeEdit: 26 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'exchangeEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.exchangeEdit',
                TRANSLATOR_DEFAULT: 'Exchange edit',
            },
        },
        INVENTORY_RECORDING_SHEET: {
            CONTENT_URL_ID: { inventoryRecordingSheet: 27 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'inventoryRecordingSheet',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.inventoryRecordingSheet',
                TRANSLATOR_DEFAULT: 'Inventory recording sheet',
            },
            RIGHTS: [RIGHTS.INVENTORY_RECORDING_SHEET_INTERFACE],
        },
        INVENTORY_SHEET_EDIT: {
            CONTENT_URL_ID: { inventorySheetEdit: 28 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'inventorySheetEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.inventorySheetEdit',
                TRANSLATOR_DEFAULT: 'Inventory sheet edit',
            },
        },
        INVENTORY_RECORD_BOOKS: {
            CONTENT_URL_ID: { inventoryRecordBooks: 29 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'inventoryRecordBooks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.inventoryRecordBooks',
                TRANSLATOR_DEFAULT: 'Inventory record books',
            },
            RIGHTS: [RIGHTS.INVENTORY_RECORD_BOOKS_INTERFACE],
        },
        INVENTORY_RECORDS_EDIT: {
            CONTENT_URL_ID: { inventoryRecordsEdit: 30 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'inventoryRecordsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.inventoryRecordsEdit',
                TRANSLATOR_DEFAULT: 'Inventory records edit',
            },
        },
        SCRAPPING: {
            CONTENT_URL_ID: { scrapping: 31 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'scrapping',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.scrapping',
                TRANSLATOR_DEFAULT: 'Scrapping',
            },
            RIGHTS: [RIGHTS.SCRAPPING_INTERFACE],
        },
        SCRAPPING_EDIT: {
            CONTENT_URL_ID: { scrappingEdit: 32 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'scrappingEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.scrappingEdit',
                TRANSLATOR_DEFAULT: 'Scrapping edit',
            },
        },
        INITIAL_STOCK: {
            CONTENT_URL_ID: { initialStock: 33 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'initialStock',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.initialStock',
                TRANSLATOR_DEFAULT: 'Initial stock',
            },
            RIGHTS: [RIGHTS.INITIAL_STOCK_INTERFACE],
        },
        INITIAL_STOCK_EDIT: {
            CONTENT_URL_ID: { initialStockEdit: 34 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'initialStockEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.initialStockEdit',
                TRANSLATOR_DEFAULT: 'Initial stock edit',
            },
        },
        COST_RELEASE: {
            CONTENT_URL_ID: { costRelease: 35 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'costRelease',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.costRelease',
                TRANSLATOR_DEFAULT: 'Cost release',
            },
        },
        PURCHASE_TICKETS: {
            CONTENT_URL_ID: { purchaseTickets: 36 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'purchaseTickets',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Felvásárlási jegyek',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.PURCHASE_TICKETS_INTERFACE],
        },
        PURCHASE_TICKETS_EDIT: {
            CONTENT_URL_ID: { purchaseTicketsEdit: 37 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'purchaseTicketsEdit',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Felvásárlási jegy szerkesztése',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            RIGHTS: [RIGHTS.PURCHASE_TICKETS_INTERFACE],
        },
        OWNERSHIP_TRANSFER_DECLARATION_TEMPLATES: {
            CONTENT_URL_ID: { ownershipTransferDeclarationTemplates: 38 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'ownershipTransferDeclarationTemplates',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.ownershipTransferDeclarationTemplates',
                TRANSLATOR_DEFAULT: 'Ownership transfer declaration templates',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
        },
        OWNERSHIP_TRANSFER_DECLARATION_TEMPLATES_RO: {
            CONTENT_URL_ID: { ownershipTransferDeclarationTemplatesRO: 39 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'ownershipTransferDeclarationTemplatesRO',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Șabloane proces-verbal de recalificare',
            },
        },
        BLOCKS: {
            CONTENT_URL_ID: { blocks: 40 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'blocks',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.blocks',
                TRANSLATOR_DEFAULT: 'Blocks',
            },
            CAN_USE_PLANNING: true,
            SHOW_IN_PANNING_MODE: true,
            SETTINGS: [
                { [SETTINGS.USE_GLOBAL_LITE]: 0 }
            ],
        },
        STORAGE_LOSS: {
            CONTENT_URL_ID: { storageLoss: 41 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'storageLoss',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.storageLoss',
                TRANSLATOR_DEFAULT: 'Storage loss',
            },
            RIGHTS: [RIGHTS.STORAGE_LOSS_INTERFACE],
        },
        STORAGE_LOSS_EDIT: {
            CONTENT_URL_ID: { storageLossEdit: 42 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'storageLossEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.storageLossEdit',
                TRANSLATOR_DEFAULT: 'Storage loss edit',
            },
        },
        CROP_SALES_CONTRACTS: {
            CONTENT_URL_ID: { cropSalesContracts: 43 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockInventocropSalesContractsry',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockInventocropSalesContractsry',
                TRANSLATOR_DEFAULT: 'Stock inventory crop sales constractsry',
            },
            RIGHTS: [RIGHTS.CROP_SALES_CONTRACTS_INTERFACE_ACCESS_EDIT],
        },
        CROPS_SALES_CONTRACTS_EDIT: {
            CONTENT_URL_ID: { cropsSalesContractsEdit: 44 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'cropsSalesContractsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.cropsSalesContractsEdit',
                TRANSLATOR_DEFAULT: 'Crops sales contracts edit',
            },
            RIGHTS: [RIGHTS.CROP_SALES_CONTRACTS_INTERFACE_ACCESS_EDIT],
        },
        PRICING: {
            CONTENT_URL_ID: { pricing: 45 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockPricing',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockPricing',
                TRANSLATOR_DEFAULT: 'Stock pricing',
            },
            SETTINGS: [
                { [SETTINGS.USE_GLOBAL_LITE]: 0 }
            ],
        },
        PURCHASE_INVOICE: {
            CONTENT_URL_ID: { purchaseInvoice: 46 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockPurchaseInvoice',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockPurchaseInvoice',
                TRANSLATOR_DEFAULT: 'Stock purchase invoice',
            },
            RIGHTS: [RIGHTS.TRANSFER_INTERFACE_ACCESS_EDIT],
        },
        ORDERS: {
            CONTENT_URL_ID: { orders: 47 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockOrders',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockOrders',
                TRANSLATOR_DEFAULT: 'Stock orders',
            },
            RIGHTS: [RIGHTS.ORDERS_ACCESS_EDIT],
            SETTINGS: [SETTINGS.USE_OF_ORDERS],
        },
        ORDER_EDIT: {
            CONTENT_URL_ID: { orderEdit: 48 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockOrderEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockOrderEdit',
                TRANSLATOR_DEFAULT: 'Stock order edit',
            },
            RIGHTS: [RIGHTS.ORDERS_ACCESS_EDIT],
            SETTINGS: [SETTINGS.USE_OF_ORDERS],
        },
        YIELDING_EXCEL: {
            CONTENT_URL_ID: { yieldingExcel: 49 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockYieldingExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockYieldingExcel',
                TRANSLATOR_DEFAULT: 'Stock yielding excel',
            },
            RIGHTS: [RIGHTS.YIELDS_EXCEL_INTERFACE],
            SETTINGS: [SETTINGS.USE_YIELD_FROM_EXCEL],
        },
        INCOMES_EXCEL: {
            CONTENT_URL_ID: { incomesExcel: 50 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockIncomesExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.stockIncomesExcel',
                TRANSLATOR_DEFAULT: 'Stock incomes excel',
            },
            RIGHTS: [RIGHTS.INCOMES_EXCEL_INTERFACE],
            SETTINGS: [SETTINGS.USE_REVENUE_FROM_EXCEL],
        },
        RETURN_GOODS: {
            CONTENT_URL_ID: { returnGoods: 51 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'returnGoods',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.returnGoods',
                TRANSLATOR_DEFAULT: 'Return Goods',
            },
            RIGHTS: [RIGHTS.RETURN_GOODS_INTERFACE],
        },
        RETURN_GOODS_EDIT: {
            CONTENT_URL_ID: { returnGoodsEdit: 52 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'returnGoodsEdit',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.returnGoodsEdit',
                TRANSLATOR_DEFAULT: 'Return Goods edit',
            },
            RIGHTS: [RIGHTS.RETURN_GOODS_INTERFACE],
        },
        INVENTORY_TRANSFER_REQUEST: {
            CONTENT_URL_ID: { inventoryTransferRequest: 53 },
            COMPONENT_URL: 'contents/inventory/inventoryTransferRequest/InventoryTransferRequest',
            EVENT_KEY: 'inventoryTransferRequest',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.inventoryTransferRequest',
                TRANSLATOR_DEFAULT: 'Inventory transfer request',
            },
            SETTINGS: [SETTINGS.USING_INVENTORY_TRANSFER_REQUEST],
            RIGHTS: [RIGHTS.INVENTORY_TRANSFER_REQUEST_INTERFACE],
        },
        YIELDING_EXCEL_PROCESSING_PLANT: {
            CONTENT_URL_ID: { yieldingExcelProcessingPlant: 54 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockYieldingExcelProcessingPlant',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Hozamolás excelből (feldolgozóüzem)',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            SETTINGS: [SETTINGS.USE_YIELD_FROM_EXCEL_PROCESSING_PLANT],
            RIGHTS: [RIGHTS.YIELDS_EXCEL_PROCESSING_PLANT_INTERFACE],
        },
        YIELDING_PROCESSING_PLANT: {
            CONTENT_URL_ID: { yieldingProcessingPlant: 55 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'stockYieldingProcessingPlant',
            TITLE: {
                USE_TRANSLATOR: false,
                DEFAULT_TEXT: 'Feldolgozóüzemi hozamolás',
            },
            INCLUDE_COUNTRIES: [COUNTRY.HUNGARY],
            SETTINGS: [SETTINGS.USE_YIELD_FROM_EXCEL_PROCESSING_PLANT],
            RIGHTS: [RIGHTS.YIELDS_EXCEL_PROCESSING_PLANT_INTERFACE],
        },
        RELEASES_EXCEL: {
            CONTENT_URL_ID: { releasesExcel: 56 },
            COMPONENT_URL: 'contents/PhpContent',
            EVENT_KEY: 'releasesExcel',
            TITLE: {
                TRANSLATOR_KEY: 'pageTitle.releasesExcel',
                TRANSLATOR_DEFAULT: 'Releases Excel',
            }
        },                        
    },
};
