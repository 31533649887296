import { LayerCreateContextProvider } from '@map/services/layerCreate/layerCreateContext';
import { MapContextProvider } from '@map/services/mapContext';
import { MapModalContainer } from './MapModalContainer';

export default function MapModal(props) {
    return (
        <LayerCreateContextProvider>
            <MapContextProvider>
                <MapModalContainer {...props} />
            </MapContextProvider>
        </LayerCreateContextProvider>
    );
}
