import React from 'react';
import { createUseStyles } from 'react-jss';
import { upperFirst, toLower } from 'lodash';
import { useTranslation } from 'react-i18next';
import { languageUtils } from 'shared/src/modules';

const useStyles = createUseStyles((theme: any) => ({
    columnName: {
        width: '100%',
        lineHeight: '40px !important',
        color: theme.color.stone,
        fontFamily: 'Rubik',
        fontSize: 12,
        fontWeight: 500,
        display: 'block',
        margin: 'auto',
        marginLeft: 0,
        marginRight: 0,
        ...theme.overFlowText,
        maxWidth: (props: any) => !props.isRightAlign && 'min-content',
        minWidth: (props: any) => (!props.isRightAlign ? 30 : '100%'),
        textAlign: (props: any) => (props.isRightAlign ? 'right' : 'left'),
        '& span': {
            width: '100%',
            textAlign: (props: any) => (props.isRightAlign ? 'right' : 'left'),
        },
    },
}));

export default function ColumnName(props) {
    const { text, isSortable, isRightAlign } = props;
    const { t } = useTranslation();
    const classes = useStyles({ isSortable, isRightAlign } as any);

    function getTitle(title) {
        return upperFirst(toLower(languageUtils.getTitle(t, title)));
    }

    return (
        <h4 className={classes.columnName} title={getTitle(text)}>
            {text}
        </h4>
    );
}
