import React from 'react';
import { createUseStyles } from 'react-jss';

import { OpenModalButton } from '@baseComponents/modals';
import { isEmpty, isFunction } from 'lodash';

const useStyles = createUseStyles({
    modalAddButtonCell: {
        height: (props: any) => (props.isMultiGrid ? 34 : '100%'),
        display: (props: any) => (props.isMultiGrid ? 'block' : 'flex'),
        justifyContent: 'center',
        alignItems: 'center',
        '& .openModalButton': {
            height: (props: any) => (props.isMultiGrid ? 34 : 36),
            width: '100%',
        },
    },
});

function ModalAddButtonCell(props) {
    const {
        updateValue, onDelete, modalComponent, modalComponentProps, hasDeleteButton, hasModalCloseButton, isDisabled,
        preventOnChange, preventOnShow, cellValue, cellData, rowData, columnId, isRequired, defaultText, onChange,
        isAlignRight,
    } = props;
    const { id, name } = cellValue || {};
    const { line1Id, line2Id } = props;
    const classes = useStyles({ isMultiGrid: !isEmpty(cellValue) } as any);

    return (
        <div className={classes.modalAddButtonCell}>
            <OpenModalButton
                cellData={cellData}
                currentSelectedValue={id}
                defaultValue={rowData?.[columnId]}
                preventOnShow={preventOnShow}
                modalComponent={modalComponent}
                id={id}
                name={name}
                onChange={itemObj => {
                    if (isFunction(preventOnChange)) {
                        preventOnChange(cellData, () => updateValue(itemObj, cellData, rowData));
                        return;
                    }
                    if (isFunction(onChange)) {
                        onChange({ value: itemObj, cellData, rowData, updateValue });
                        return;
                    }
                    updateValue(itemObj, cellData, rowData);
                }}
                onDelete={onDelete}
                modalComponentProps={{ text: !isEmpty(name) && id && name, ...modalComponentProps }}
                modalCloseButton={hasModalCloseButton}
                hasDeleteButton={hasDeleteButton}
                isDisabled={isDisabled}
                isRequired={isRequired}
                line1Id={line1Id}
                line2Id={line2Id}
                defaultText={defaultText}
                //style
                isAlignRight={isAlignRight}
            />
        </div>
    );
}

export default React.memo(ModalAddButtonCell);
