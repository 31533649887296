import { createUseStyles } from 'react-jss';
import clsx from 'clsx';

import { InputGroup } from '@baseComponents/inputs/inputField';
import InputErrorMessage from './InputErrorMessage';
import InputLabel from './InputLabel';

const useStyles = createUseStyles((theme: any) => ({
    groupClassName: {
        overflow: 'hidden',
        opacity: (props: any) => props.isDisabled && 0.3,
        borderRadius: 4,
        border: (props: any) => {
            if (props.hideBorder) {
                return 'none';
            }
            if (props.isValid) {
                return `1px solid ${theme.color.main}`;
            }
            if (props.isInvalid) {
                return `1px solid ${theme.color.destructive}`;
            }
            return `1px solid ${theme.color.gray}`;
        },
        boxShadow: (props: any) => {
            if (props.isValid) {
                return `0 2px 3px 0 rgba(${theme.rgbColor.main}, 15%)`;
            }
            if (props.isInvalid) {
                return theme.shadows.destructive.normal;
            }
            return '0 2px 3px 0 rgba(175, 180, 182, 0.15)';
        },

        //input design
        '& .input': {
            border: 0,
            boxShadow: 'none',

            '&:active': {
                border: 'none',
                boxShadow: 'none',
            },
            '&:focus': {
                border: 'none',
                boxShadow: 'none',
            },
            '&:hover': {
                border: 'none',
                boxShadow: 'none',
            },

            '&.is-valid': {
                border: 'none',
                boxShadow: 'none',
            },
            '&.is-invalid': {
                border: 'none',
                boxShadow: 'none',
            },
        },
        //pretend and append design
        '& .inputPretend .input-group-text, .inputAppend .input-group-text': {
            border: 0,
        },
        '& .inputAppend span': {
            padding: 0,
        },
        '& .inputPretend span': {
            padding: 0,
        },
        '& .inputAppend': {
            paddingLeft: 5,
            paddingRight: 5,
        },
        '& .inputPretend': {
            paddingRight: 5,
            paddingLeft: 5,
        },

        //input css use to group
        '&:active': {
            border: props => (!props.isDisabled && !props.hideBorder && !props.isInvalid) && `1px solid ${theme.color.main}`,
        },
        '&:focus': {
            border: props => (!props.isDisabled && !props.hideBorder && !props.isInvalid) && `1px solid ${theme.color.main}`,
        },
        '&:focus-within': {
            border: props => (!props.isDisabled && !props.hideBorder && !props.isInvalid) && `1px solid ${theme.color.main}`,
        },

        '&.is-valid': {
            border: `1px solid ${theme.color.main}`,
        },
        '&.is-invalid': {
            border: `1px solid ${theme.color.destructiv}`,
        },
    },
}));

export default function UnitInput(props) {
    const { id } = props;
    const { placeholder, defaultValue = null, type = 'number', maxLength, onFocus, onChange, onBlur, value, isValid, isInvalid, errorMessage, isDisabled, className, hideBorder } = props;
    const { positiveOnly, isInteger, min, max, numberFormat } = props; //formatting
    const { label, isRequired, isLeftAlign } = props;
    const { displayUnitAsPrefix, unit } = numberFormat || {};

    const classes = useStyles({ isValid, isInvalid, isDisabled, hideBorder });

    return (
        <div id={id} className={clsx(className, 'unitInput')}>
            <InputLabel label={label} isRequired={isRequired} isInvalid={isInvalid} />
            <InputGroup
                //display
                isLeftAlign={isLeftAlign}
                groupClassName={classes.groupClassName}
                //{...props}
                pretendText={displayUnitAsPrefix ? unit : null}
                appendText={displayUnitAsPrefix ? null : unit}
                defaultValue={defaultValue}
                type={type}
                maxLength={maxLength}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                //formatting
                positiveOnly={positiveOnly}
                isInteger={isInteger}
                min={min}
                max={max}
                numberFormat={numberFormat}
                //input
                placeholder={placeholder}
                value={value}
                isValid={isValid}
                isInvalid={isInvalid}
                isDisabled={isDisabled}
            />
            <InputErrorMessage isInvalid={isInvalid} errorMessage={errorMessage} />
        </div>
    );
}
