import { simpleAction } from './baseActions';

const modalActions = {
    showModal,
    hideModal,
    setPreventCloseDialog,
    MODAL_ADD_NEW_MODAL_ITEM: 'MODAL_ADD_NEW_MODAL_ITEM',
    MODAL_REMOVE_MODAL_ITEM: 'MODAL_REMOVE_MODAL_ITEM',
    SET_PREVENT_CLOSE_DIALOG: 'MODAL_SET_PREVENT_CLOSE_DIALOG',
};

export { modalActions };

function showModal(component) {
    return simpleAction(modalActions.MODAL_ADD_NEW_MODAL_ITEM, { component });
}

function hideModal(component) {
    return simpleAction(modalActions.MODAL_REMOVE_MODAL_ITEM, { component });
}

function setPreventCloseDialog(hasPreventCloseDialog) {
    return simpleAction(modalActions.SET_PREVENT_CLOSE_DIALOG, { hasPreventCloseDialog });
}
