import React, { useEffect, useState, useRef, useMemo } from 'react';
import useTranslate from '@i18n/useTranslate';
import { createUseStyles } from 'react-jss';
import { useSelector } from 'react-redux';
import { PAGE_DATA, TABLE } from 'shared/src/constants';
import { stringUtils } from 'shared/src/modules';
import { isNull, isEmpty, debounce } from 'lodash';
import clsx from 'clsx';
import Svg from '@baseComponents/Svg';
import OptionalSearchPopover from './OptionalSearchPopover';

const useStyles = createUseStyles((theme: any) => ({
    container: {
        height: (props: any) => `${props.height}px`,
        lineHeight: (props: any) => `${props.height}px`,
        width: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        transition: 'width 0.5s',
        borderRadius: 4,
        color: theme.color.jet,
        backgroundColor: theme.color.white,
        boxShadow: '0 2px 3px 0 rgba(175, 180, 182, 0.15)',
        border: '1px solid',
        borderColor: (props: any) => (props.isInvalid ? theme.color.destructive : theme.color.gray),
        '&:focus-within': {
            boxShadow: '0 3px 5px 0 rgba(34, 195, 115, 0.15)',
            border: `1px solid ${theme.color.main}`,
            color: theme.color.jet,
        },
    },
    searchButton: {
        border: 'none',
        height: (props: any) => `${props.height}px` || 40,
        lineHeight: (props: any) => `${props.height}px` || '40px',
        width: (props: any) => `${props.height}px` || 40,
        minWidth: (props: any) => `${props.height}px` || 40,
        borderRadius: 4,
        alignSelf: 'flex-end',
        background: 'transparent',
        '&:focus': {
            outline: 'none',
        },
    },
    searchIcon: {
        height: 20,
        width: 20,
        fill: theme.color.stone,
        objectFit: 'contain',
    },
    input: {
        lineHeight: '100%',
        height: 'auto',
        width: (props: any) => (props.height ? `calc(100% - ${props.height}px)` : 'calc(100% - 40px)'),
        transition: 'width 0.5s',
        borderRadius: 4,
        border: 'none',
        fontSize: 14,
        fontWeight: 300,
        textAlign: 'left',
        padding: 0,
        '&:focus': {
            outline: 'none',
        },
        '&::-webkit-search-cancel-button': {
            '-moz-appearance': 'none',
            '-webkit-appearance': 'none',
        },
    },
    arrowContainer: {
        width: 25,
        height: 38,
        marginRight: 5,
    },
}));

export default function OptionSearchBar(props) {
    const { currentSearchValue, setSearchValue, className, height = 40, onChange, tableData, columnId, isSearchIcon = true, isInvalid, placeholder, firstOptionName, secondOptionName } = props;
    const { t, translater } = useTranslate();
    const [searchId, setSearchId] = useState(TABLE.COLUMN_LINE.BOTH_LINE);
    const searchInputRef = useRef();
    const globalColumnSearchValues = useSelector((state: any) => state.basePage.table.globalColumnSearchValues);
    const [searchIds] = useState([
        {
            key: TABLE.COLUMN_LINE.BOTH_LINE,
            value: t('default.both', 'Both'),
        },
        {
            key: TABLE.COLUMN_LINE.FIRST_LINE,
            value: firstOptionName,
        },
        {
            key: TABLE.COLUMN_LINE.SECOND_LINE,
            value: secondOptionName,
        },
    ]);
    const classes = useStyles({ height, isInvalid });

    //reset input text
    useEffect(() => {
        if (isNull(globalColumnSearchValues) && !isEmpty(searchInputRef?.current?.value)) {
            searchInputRef.current.value = '';
        }
    }, [globalColumnSearchValues]);

    useEffect(() => {
        if (currentSearchValue) {
            searchInputRef.current.value = currentSearchValue;
        }
    }, [currentSearchValue]);

    //sync table group header and fixed header
    useEffect(() => {
        if (globalColumnSearchValues && columnId && searchInputRef && !isEmpty(searchInputRef.current)) {
            if (!isEmpty(globalColumnSearchValues)) {
                Object.entries(globalColumnSearchValues).forEach(([key, value]) => {
                    const currentValue = searchInputRef?.current?.value;
                    if (key === columnId && !stringUtils.isSame(currentValue, value.searchValue)) {
                        searchInputRef.current.value = value.searchValue;
                    }
                });
            }
        }
    }, [globalColumnSearchValues, columnId]);

    useEffect(() => {
        if (isEmpty(currentSearchValue)) {
            searchInputRef.current.value = '';
        }
    }, [tableData, currentSearchValue]);

    const delayedQuery = useMemo(() => debounce(searchValue => {
        const searchObject = {
            searchValue,
            columnId: searchId,
        };
        onChange(searchObject);
        if (setSearchValue) {
            setSearchValue(searchValue);
        }
    }, PAGE_DATA.SEARCH_INPUT_TIMEOUT), [onChange, searchId, setSearchValue]);

    function searchBarOnKeyUp() {
        const inputValue = searchInputRef && searchInputRef.current ? searchInputRef?.current?.value : null;
        delayedQuery(inputValue);
    }

    function onKeyDown(event) {
        let indexOfSearchOption = searchIds.findIndex(searchOptionId => searchOptionId.key === searchId);
        if (event.key === 'ArrowUp') {
            indexOfSearchOption -= 1;
        } else if (event.key === 'ArrowDown') {
            indexOfSearchOption += 1;
        } else if (event.key === 'Enter') {
            search();
        }
        if (indexOfSearchOption < 0) {
            indexOfSearchOption = searchIds.length - 1;
        } else if (indexOfSearchOption > searchIds.length - 1) {
            indexOfSearchOption = 0;
        }
        if (indexOfSearchOption) {
            setSearchId(searchIds[indexOfSearchOption].key);
        }
    }

    function search(valueId) {
        const searchObject = {
            searchValue: searchInputRef?.current?.value,
            columnId: valueId || searchId,
        };
        onChange(searchObject);
        if (setSearchValue) {
            setSearchValue(searchInputRef?.current?.value);
        }
    }

    return (
        <div className={clsx(classes.container, className)}>
            {isSearchIcon
                    && (
                        <button type="button" className={classes.searchButton} onClick={search}>
                            <Svg iconId="icon-search" style={classes.searchIcon} />
                        </button>)}
            <input
                placeholder={placeholder || `${translater('default.search', 'Search')}...`}
                type="search"
                className={classes.input}
                onChange={searchBarOnKeyUp}
                ref={searchInputRef}
                onKeyDown={onKeyDown}
            />

            <div className={classes.arrowContainer}>
                <OptionalSearchPopover
                    search={search}
                    searchId={searchId}
                    setSearchId={setSearchId}
                    searchIds={searchIds}
                />
            </div>
        </div>
    );
}
