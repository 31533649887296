import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { arrayUtils } from 'shared/src/modules/arrayUtils';
import { useFormikContext } from 'formik';
import { supportService } from '@services';
import { errorActions } from '@states/actions/errorActions';

export default function useLoadSupportData(props) {
    const { loadedSupportList } = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const { values, setFieldValue } = useFormikContext();

    //load all supports
    useEffect(() => {
        let isCancelled = false;
        async function loadAllSupport(year) {
            try {
                const supports = await supportService.getSupportsByYear(year);
                if (!isCancelled) {
                    setFieldValue('supportList', supports);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (dispatch && location && values.periodYear && loadedSupportList) {
            loadAllSupport(values.periodYear);
            return () => {
                isCancelled = true;
            };
        }
    }, [dispatch, location, values.periodYear, setFieldValue, loadedSupportList]);
}
