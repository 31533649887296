import { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';
import { useFormikContext } from 'formik';
import useTranslate from '@i18n/useTranslate';
import DataInput from '@baseComponents/inputs/DataInput';
import InsuranceOverall from './InsuranceOverall';
import InsuranceData from './InsuranceData';
import useLoadInsuranceData from './useLoadInsuranceData';

const useStyles = createUseStyles({
    insurance: {
        marginTop: 20,
    },
});

export default function AddInsurance(props) {
    const { closeInsuranceData, toggle, insuranceDataExpanded } = props;
    const { t } = useTranslate();
    const { values, setFieldValue } = useFormikContext();
    const classes = useStyles();
    const { partnerList } = useLoadInsuranceData();

    useEffect(() => {
        if (partnerList && !isEmpty(partnerList)) {
            if (values.bankId && values.bankId > 0 && isEmpty(values.bankName)) {
                selectBank(values.bankId);
            }
            if (values.insurerId && values.insurerId > 0 && isEmpty(values.insurerName)) {
                selectInsurer(values.insurerId);
            }
        }
    }, [partnerList, values, setFieldValue]);

    function selectBank(value) {
        if (+value > 0) {
            const bankName = partnerList?.find(obj => obj.key === +value).value;
            setFieldValue('bankName', bankName);
            setFieldValue('bankId', value);
        } else {
            setFieldValue('bankName', '');
            setFieldValue('bankId', 0);
        }
    }

    function selectInsurer(value) {
        if (+value > 0) {
            const insurerName = partnerList?.find(obj => obj.key === +value).value;
            setFieldValue('insurerName', insurerName);
            setFieldValue('insurerId', value);
        } else {
            setFieldValue('insurerName', '');
            setFieldValue('insurerId', 0);
        }
    }

    function toggleInsuranceData(state) {
        const windowContent = {
            component: InsuranceData,
            props: {
                close: closeInsuranceData,
                partnerList,
                selectBank,
                selectInsurer,
            },
        };
        toggle(state && windowContent);
    }

    return (
        <DataInput
            title={t('cultivationPeriodsColumn.bank', 'Bank')}
            label={t('cultivationPeriodsColumn.bank', 'Bank')}
            className={classes.insurance}
            onClick={() => toggleInsuranceData(!insuranceDataExpanded)}
        >
            {(!isEmpty(values.bankName) || !isEmpty(values.insurerName)) && (
                <InsuranceOverall
                    bankName={values.bankName}
                    insurerName={values.insurerName}
                />
            )}
        </DataInput>
    );
}
