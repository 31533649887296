import { ModalTemplate } from '@baseComponents/modals';
import { SimpleSelect } from '@baseComponents/select';
import { Nullable } from '@flowsCommon/services/baseTypes';
import { MAP_FILTER_STATUS } from '@map/components/MapFilter/MapFilterSchema';
import MapScreenContainer from '@map/screens/Map/MapScreenContainer';
import { useLayerCreateContext } from '@map/services/layerCreate/layerCreateContext';
import { useMapContext } from '@map/services/mapContext';
import { BuiltInLayerTypes } from '@map/services/mapEnums';
import { GeomDto, GeomSaveEntity, LayerSelectorSettings, ThemeType } from '@map/services/mapTypes';
import { MapWidgetProdYearSelectorItem } from '@map/widgets/MapWidget/MapWidgetComponent';
import { basePageActions } from '@states/actions/basePageActions';
import { Feature, GeoJsonProperties, Geometry } from 'geojson';
import { isEmpty, isNil } from 'lodash';
import { dateTimeUtils } from 'shared/src/modules';
import { useEffect, useMemo, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useDispatch, useSelector } from 'react-redux';

type Classes = 'container' | 'selectorContainer' | 'select';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        position: 'relative',
        '& #overlayPanel': {
            marginTop: '60px !important',
        },
    },
    selectorContainer: {
        background: theme.color.white,
        width: '100%',
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'rgba(0,0,0,0.5)',
        justifyContent: 'flex-start',
        zIndex: 10,
        padding: [4, 30, 4, 30],
    },
    select: {
        width: 150,
    },
}));

export const MapModalContainer = (props: any) => {
    const {
        hideModal,
        layerType,
        entityId,
        productionYearId,
        startDate,
        endDate,
        onSave,
        drawnFeature,
        findGeomId,
        reloadTable,
        withDrawing = false,
        withInfoPanel = false,
        withSelectorFilter = true,
        withBackToGeom = false,
        withProdYearSelector = false,
        withDrawingSaveButton = true,
        isPlanning = false,
        withSlovakOverlay = false,
        gsaaYear,
        gsaaKODKD,
        planningEditData,
        mapMode,
        fetchers,
        onSavePlanningEditModal,
    } = props;
    const [geomFeature, setGeomFeature] = useState<Nullable<Feature<Geometry, GeoJsonProperties>>>();
    const [geomId, setGeomId] = useState<number>();
    const foundGeom = useRef<GeomDto | null>(null);

    const noGeomCompanyIds = useRef<number[] | null>(null);
    const noGeomFarmId = useRef<number[] | null>(null);
    const [layerCreatStore, layerCreateController] = useLayerCreateContext();
    const [mapStore, mapContoller] = useMapContext();
    const [layerId, setLayerId] = useState<number>();

    const dispatch = useDispatch();

    //yearselector related
    const periodYears = useSelector((state: any) => state.dataLists.productionYears);
    const [selectorYears, setSelectorYears] = useState<MapWidgetProdYearSelectorItem[]>();
    const [defaultYear, setDefaultYear] = useState();
    const [selectedYear, setSelectedYear] = useState();

    const classes = useStyles();

    useEffect(() => {
        if (isEmpty(periodYears)) {
            return;
        }

        const years = periodYears?.map(year => ({ key: year.id, value: year.period }));
        const sortedYears = periodYears?.sort((yearA, yearB) => yearB.year - yearA.year);
        const defYear = sortedYears?.[0]?.id;

        if (years) {
            setSelectorYears(years);
        }
        if (defYear) {
            setDefaultYear(defYear);
            setSelectedYear(defYear);
        }
    }, [periodYears]);

    useEffect(() => {
        if (isNil(mapStore.layering.layers)) {
            return;
        }

        const layer = mapContoller.getLayerByName(layerType);

        if (isNil(layer)) {
            return;
        }
        setLayerId(layer.id);

        const findGeomFc = async () => {
            if (!isNil(entityId) && !isNil(layerType)) {
                foundGeom.current = await layerCreateController.findGeometryByEntityId(entityId, layer.id, productionYearId);
                if (foundGeom.current) {
                    setGeomId(foundGeom.current.id);
                }
            }

            if (isNil(foundGeom.current)) {
                const companyLayer = isPlanning ? 'planning' : layer.id;
                const { companyIds, farmIds } = await layerCreateController.getGeometryCompanyIds(entityId, companyLayer);
                if (!isNil(companyIds)) {
                    noGeomCompanyIds.current = companyIds;
                }

                if (!isNil(farmIds)) {
                    noGeomFarmId.current = farmIds;
                }
            }
        };

        if (findGeomId === true) {
            findGeomFc();
        }
    }, [findGeomId, productionYearId, entityId, layerType, layerCreateController, mapStore.layering.layers, mapContoller, isPlanning]);

    const startDateToStore = useMemo(() => {
        if (isNil(startDate) || isEmpty(startDate)) {
            return null;
        }

        const momentStartDate = dateTimeUtils.getDateObject(startDate) as moment.Moment;
        if (momentStartDate.isValid()) {
            return startDate;
        }

        // eslint-disable-next-line no-undefined
        return undefined;
    }, [startDate]);

    const endDateToStore = useMemo(() => {
        if (isNil(endDate) || isEmpty(endDate)) {
            return null;
        }

        const momentEndDate = dateTimeUtils.getDateObject(endDate) as moment.Moment;
        if (momentEndDate.isValid()) {
            return endDate;
        }

        // eslint-disable-next-line no-undefined
        return undefined;
    }, [endDate]);

    const panelSelectorFilter: LayerSelectorSettings | undefined = useMemo(() => {
        if (withSelectorFilter) {
            return {
                attributeFilter: {
                    whiteList: [
                        {
                            layerName: BuiltInLayerTypes.CULTIVATION_PERIOD.valueOf(),
                            attributeKey: 'name',
                        },
                        {
                            layerName: BuiltInLayerTypes.CULTIVATION_PERIOD.valueOf(),
                            attributeKey: 'culture',
                        },
                        {
                            layerName: BuiltInLayerTypes.CULTIVATION_PERIOD.valueOf(),
                            attributeKey: 'type',
                        },
                        {
                            layerName: BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP.valueOf(),
                            attributeKey: 'name',
                        },
                        {
                            layerName: BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP.valueOf(),
                            attributeKey: 'culture',
                        },
                        {
                            layerName: BuiltInLayerTypes.CULTIVATION_PERIOD_GROUP.valueOf(),
                            attributeKey: 'type',
                        },
                    ],
                },
                layerFilter: {
                    blackList: [BuiltInLayerTypes.MACHINES.valueOf()],
                },
            };
        }
    }, [withSelectorFilter]);

    const save = async ({ selectedLpis } : { selectedLpis: google.maps.Data.Feature | null }) => {
        let kodkd;
        let zkodkd;

        if (isNil(layerId)) {
            return;
        }

        if (selectedLpis) {
            try {
                kodkd = selectedLpis.getProperty('KODKD');
                zkodkd = selectedLpis.getProperty('ZKODKD');
            } catch (error) {
                console.log(error);
            }
        }

        if (entityId !== 0 && withDrawingSaveButton) {
            if (isNil(geomId)) {
                if (!isNil(noGeomCompanyIds.current) && geomFeature) {
                    const geomSaveEntitiy: GeomSaveEntity = {
                        entityId,
                        productionYearId,
                        layerId,
                        companyIds: noGeomCompanyIds.current,
                        farmIds: !isNil(noGeomFarmId.current) ? noGeomFarmId.current : null,
                        geometry: geomFeature.geometry,
                        startDate: startDateToStore,
                        endDate: endDateToStore,
                    };
                    await layerCreateController.saveGeometries({
                        isCustomLayer: false,
                        geomRequestData: [geomSaveEntitiy],
                    });
                }
            } else if (foundGeom.current) {
                if (geomFeature) {
                    const geomSaveEntitiy: GeomSaveEntity = {
                        entityId: foundGeom.current.entityId,
                        productionYearId: foundGeom.current.productionYearId,
                        layerId,
                        geometry: geomFeature.geometry,
                        // farmId: foundGeom.current.farmId,
                        farmIds: foundGeom.current.farms?.map(farm => farm.farmId),
                        startDate: startDateToStore,
                        endDate: endDateToStore,
                        // eslint-disable-next-line no-undefined
                        companyIds: !isNil(noGeomCompanyIds.current) ? noGeomCompanyIds.current : undefined,
                    };
                    await layerCreateController.updateGeometry(geomId, geomSaveEntitiy);
                } else {
                    await layerCreateController.deleteGeometry(geomId);
                }
            }
            if (reloadTable) {
                dispatch(basePageActions.setReloadTable());
            }
        }

        if (onSave) {
            onSave({ feature: geomFeature, geomId: geomId ?? 0, gsaa: { kodkd, zkodkd } });
        }

        if (hideModal) {
            hideModal();
        }
    };

    const drawnFeatureisValid = useMemo(() => {
        if (entityId !== 0 && withDrawingSaveButton) {
            return false;
        }
        if (drawnFeature) {
            if (drawnFeature?.geometry?.coordinates.flatMap(coor => coor).length !== 0) {
                return true;
            }
        }
        return false;
    }, [drawnFeature, entityId, withDrawingSaveButton]);

    if (withProdYearSelector) {
        return (
            <ModalTemplate hideModal={hideModal}>
                <div className={classes.container}>
                    <div className={classes.selectorContainer}>
                        <SimpleSelect
                            dataList={selectorYears}
                            selected={defaultYear}
                            setSelected={(id: any) => { setSelectedYear(id); }}
                            className={classes.select}
                            iconId="icon-calendar"
                        />
                    </div>
                    {
                        selectedYear && <MapScreenContainer
                            defaultMapFilter={{ productionYearId: selectedYear }}
                            // eslint-disable-next-line no-undefined
                            initDrawnFeature={drawnFeatureisValid ? drawnFeature : undefined}
                            withCoordinates
                            withDrawing={withDrawing}
                            layerPanelOptions={{
                                withLayerPanel: true,
                            }}
                            mapMode={mapMode}
                            withSlovakOverlay={withSlovakOverlay}
                            gsaaYear={gsaaYear}
                            gsaaKODKD={gsaaKODKD}
                            withMachinePanel
                            onDrawingSaveClick={save}
                            // eslint-disable-next-line no-undefined
                            entityId={entityId}
                            withInfoPanel={withInfoPanel}
                            withBackToGeom={withBackToGeom}
                            // eslint-disable-next-line no-undefined
                            zoomEntityId={withDrawing ? undefined : entityId}
                            layerType={layerType}
                            setSavedGeomFeature={setGeomFeature}
                            setSavedGeomId={setGeomId}
                            withMapStyleModifier
                            layerSelectorSettings={panelSelectorFilter}
                            fetchers={fetchers}
                            onSavePlanningEditModal={onSavePlanningEditModal}
                            planningEditData={planningEditData}
                        />
                    }
                </div>
            </ModalTemplate>);
    }

    return (
        <ModalTemplate hideModal={hideModal}>
            <MapScreenContainer
                defaultMapFilter={{ productionYearId: productionYearId ?? 0, statusId: MAP_FILTER_STATUS.ACTIVE_AND_INACTIVE }}
                // eslint-disable-next-line no-undefined
                initDrawnFeature={drawnFeatureisValid ? drawnFeature : undefined}
                withCoordinates
                isMapRoute={false}
                withDrawing={withDrawing}
                layerPanelOptions={{
                    withLayerPanel: true,
                }}
                withMachinePanel
                onDrawingSaveClick={save}
                // eslint-disable-next-line no-undefined
                entityId={entityId}
                withInfoPanel={withInfoPanel}
                withBackToGeom={withBackToGeom}
                withSlovakOverlay={withSlovakOverlay}
                gsaaYear={gsaaYear}
                gsaaKODKD={gsaaKODKD}
                // eslint-disable-next-line no-undefined
                zoomEntityId={withDrawing ? undefined : entityId}
                layerType={layerType}
                mapMode={mapMode}
                setSavedGeomFeature={setGeomFeature}
                setSavedGeomId={setGeomId}
                withMapStyleModifier
                withDrawingSaveButton={withDrawingSaveButton}
                layerSelectorSettings={panelSelectorFilter}
                fetchers={fetchers}
                onSavePlanningEditModal={onSavePlanningEditModal}
                planningEditData={planningEditData}
            />
        </ModalTemplate>
    );
};
