import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { dateTimeUtils } from 'shared/src/modules';
import { isFunction } from 'lodash';
import FormDatePicker from '@customComponents/datePickers/formCalendars/FormDatePicker';

const useStyles = createUseStyles((theme: any) => ({
    baseStyle: {
        color: theme.color.jet,
        display: 'flex',
        height: '100%',
        lineHeight: '56px',
        alignItems: 'center',
    },
}));

export default function DateEditCell(props) {
    const { value, onChange, getIsDisabled, getIsInvalid, rowData } = props;
    const classes = useStyles();
    const [date, setDate] = useState(getFormattedDate(value));

    function getFormattedDate() {
        return dateTimeUtils.isValidDate(value) ? dateTimeUtils.getDefaultDateFormat(value) : null;
    }

    function onChangeDate(dateStringInDefaultFormat) {
        setDate(dateStringInDefaultFormat);

        if (isFunction(onChange)) {
            onChange(dateStringInDefaultFormat, rowData);
        }
    }

    function checkIsDisabled() {
        if (isFunction(getIsDisabled)) {
            return getIsDisabled(rowData);
        }
        return false;
    }

    function checkIsValid() {
        if (isFunction(getIsInvalid)) {
            return getIsInvalid(rowData);
        }
        return false;
    }

    return (
        <div className={classes.baseStyle}>
            <FormDatePicker
                date={date}
                setDate={onChangeDate}
                disabled={checkIsDisabled()}
                invalid={checkIsValid()}
            />
        </div>
    );
}
