import { LayerCreateContextProvider } from '@map/services/layerCreate/layerCreateContext';
import { MapContextProvider } from '@map/services/mapContext';
import { GeomertySelectorContainer } from './GeomertySelectorContainer';

export default function GeometrySelectorModal(props) {
    return (
        <LayerCreateContextProvider>
            <MapContextProvider>
                <GeomertySelectorContainer {...props} />
            </MapContextProvider>
        </LayerCreateContextProvider>
    );
}
