import React from 'react';
import { createUseStyles } from 'react-jss';
import { isArray, isEmpty } from 'lodash';
import useTranslate from '@i18n/useTranslate';
import { useFormikContext } from 'formik';
import Svg from '@baseComponents/Svg';
import Button from '@baseComponents/buttons/Button';
import renderModal from '@modals/modalNew/modalFunctions';
import BasicFieldsModal from '@customComponents/modals/basicFieldsModal/BasicFieldsModal';
import BasicFieldsTable from './BasicFieldsTable';

const useStyles = createUseStyles((theme: any) => ({
    basicFieldContainer: {
        width: 650,
        height: '100%',
        padding: '40px 30px',
        flexFlow: 'column',
        position: 'relative',
        '& h2': {
            fontFamily: 'Rubik',
            marginBottom: 10,
            ...theme.firstLetterUppercase,
        },
    },
    expand: {
        cursor: 'pointer',
        height: 30,
        width: 30,
        borderRadius: '50%',
        backgroundColor: theme.color.pearl,
        outline: 'none',
        marginBottom: 15,
    },
    toggle: {
        marginLeft: 3,
        marginTop: 3,
        height: 24,
        width: 24,
    },
    basicFieldHeader: {
        borderBottom: '1px solid',
        borderBottomColor: theme.color.pearl,
        display: 'flex',
        paddingBottom: 20,
        marginBottom: 15,
    },
}));

export default function BasicFieldsData(props) {
    const { close } = props;
    const { t } = useTranslate();
    const { values, setFieldValue } = useFormikContext();
    const classes = useStyles();

    function onUpdateModal(selectedPeriods) {
        let newSelectedPeriods = isArray(selectedPeriods) ? selectedPeriods : [selectedPeriods];
        const oldSelectedValues = values?.basicFields || [];

        if (!isEmpty(oldSelectedValues)) {
            newSelectedPeriods = newSelectedPeriods?.filter(filteredRegNum => !oldSelectedValues?.find(selectedRegNum => selectedRegNum.id === filteredRegNum.id));
        }

        newSelectedPeriods = newSelectedPeriods?.map(selectedPeriod => ({ ...selectedPeriod, freeArea: 0, categorizedArea: selectedPeriod.freeArea, defaultFreeArea: selectedPeriod.freeArea }));

        setFieldValue('basicFields', [...oldSelectedValues, ...newSelectedPeriods]);
    }

    return (
        <>
            <div className={classes.basicFieldContainer}>
                <div className={classes.expand} onClick={close} onKeyDown={close} role="button" tabIndex={0}>
                    <Svg iconId="icon-chevron_left" style={classes.toggle} />
                </div>
                <h2>
                    {t('basicFields.basicFields', 'BASIC FIELDS')}
                </h2>

                <div className={classes.basicFieldHeader}>
                    <Button
                        iconId="icon-add"
                        iconPosition="before"
                        onClick={() => renderModal(BasicFieldsModal,
                            {
                                periodYearId: values.period,
                                partnerId: values.owner,
                                onChange: onUpdateModal,
                                isMultiselect: true,
                            },
                            false)}
                    >
                        {t('basicFieldsModal.addBasicFieldButton', 'Add Basic Field')}
                    </Button>
                </div>

                <BasicFieldsTable
                    basicFields={values.basicFields}
                    setBasicFields={value => setFieldValue('basicFields', value)}
                />
            </div>
        </>
    );
}
