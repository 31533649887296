import * as yup from 'yup';
import { CROP } from 'shared/src/constants';

export interface MapFilterSchema {
    showClosedPeriods: boolean,
    farmIds: number[],
    cultureIds: number[],
    cultures?: any[],
    productionYearId: number,
    statusId: number,
    workOperationStatus: number,
    companyIds: number[],
    companies?: any[],
    farms?: any[],
    cropType: number,
    workOperationMainGroupIds: number[],
    workOperationMainGroups?: any[],
}

export type MAPFILTER_KEY = keyof MapFilterSchema;

export enum MAP_FILTER_STATUS {
    ACTIVE = 1,
    INACTIVE = 0,
    ACTIVE_AND_INACTIVE = 2
}

export enum MAP_FILTER_WORK_OPERATION_STATUS {
    IN_PROGRESS = 1,
    FINISHED = 0,
    IN_PROGRESS_AND_DONE = 2
}

export const mapFilterSchema = yup.object<MapFilterSchema>({
    showClosedPeriods: yup.boolean().default(false).required(),
    farmIds: yup.array<number>().default([]).defined(),
    productionYearId: yup.number().required(),
    statusId: yup.number().default(MAP_FILTER_STATUS.ACTIVE).required(),
    workOperationStatus: yup.number().default(MAP_FILTER_WORK_OPERATION_STATUS.IN_PROGRESS).required(),
    companyIds: yup.array<number>().default([]).defined(),
    companies: yup.array<any>().default([]),
    cultureIds: yup.array<number>().default([]).defined(),
    cultures: yup.array<any>().default([]),
    farms: yup.array<any>().default([]),
    cropType: yup.number().default(CROP.TYPE.PLANTATION).required(),
    workOperationMainGroupIds: yup.array<number>().default([]).defined(),
    workOperationMainGroups: yup.array<any>().default([]),
});
