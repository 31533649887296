import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import Svg from '../Svg';

const useStyles = createUseStyles((theme: any) => ({
    closeButton: {
        position: 'absolute',
        top: (props: any) => (props.isModal ? -20 : -17.5),
        right: (props: any) => (props.isModal ? -20 : -17.5),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'none',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit',
        height: (props: any) => (props.isModal ? 40 : 35),
        width: (props: any) => (props.isModal ? 40 : 35),
        backgroundColor: theme.color.white,
        borderRadius: '50%',
        boxShadow: theme.shadows.dropdown,
        zIndex: theme.zIndex.modalClose,
        '&:hover': {
            boxShadow: '0 6px 22px 4px rgba(33, 40, 44, 0.2), 0 12px 18px 2px rgba(33, 40, 44, 0.3), 0 4px 8px -4px rgba(33, 40, 44, 0.3)',
            backgroundColor: theme.color.lilian,
        },
    },
    closeIcon: {
        width: (props: any) => (props.isModal ? 24 : 22),
        height: (props: any) => (props.isModal ? 24 : 22),
    },
}));

export default function CloseButton(props) {
    const { onClick, className, isModal } = props;
    const classes = useStyles({ isModal });

    return (
        <button className={clsx('closeButton', classes.closeButton, className)} type="button" onClick={onClick}>
            <Svg iconId="icon-close" style={classes.closeIcon} />
        </button>
    );
}
