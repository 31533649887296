import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NumberFormatUtils } from 'shared/src/modules';
import { isEmpty, toUpper } from 'lodash';
import { SETTINGS } from 'shared/src/constants/settings';

export default function useNumberFormat() {
    const companySettings = useSelector((state: any) => state.company.company.settings);
    const numberFormatSettings = useSelector((state: any) => state.company.company.numberFormatSettings);
    const currenciesSettings = useSelector((state: any) => state.company.company.currencies);
    const [numberFormatter] = useState(new NumberFormatUtils([]));
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (numberFormatter && companySettings && numberFormatSettings && currenciesSettings && !isInitialized) {
            const settings: any = [];

            if (!isEmpty(companySettings)) {
                companySettings.forEach(compSet => {
                    if (compSet.name === SETTINGS.LAND_ADMINISTRATION_AREA_HA_DECIMAL || compSet.name === SETTINGS.LAND_ADMINISTRATION_AREA_AK_DECIMAL ||
                        compSet.name === SETTINGS.PRIMARY_CURRENCY || compSet.name === SETTINGS.SECONDARY_CURRENCY) {
                        settings.push({ name: compSet.name, value: compSet.value });
                    }
                });
            }

            if (!isEmpty(numberFormatSettings)) {
                numberFormatSettings.forEach(numberFormatSetting => {
                    settings.push({ name: toUpper(numberFormatSetting.typeName), value: numberFormatSetting.decimals });
                });
            }

            if (!isEmpty(currenciesSettings)) {
                currenciesSettings.forEach(currency => {
                    settings.push({ name: `CURRENCY_DECIMALS_${toUpper(currency.id)}`, value: currency.decimals });
                    settings.push({ name: `CURRENCY_UNIT_PRICE_DECIMALS_${toUpper(currency.id)}`, value: currency.unitPriceDecimals });
                    settings.push({ name: `CURRENCY_NAME_${toUpper(currency.id)}`, value: currency.name });
                    settings.push({ name: `CURRENCY_SHORTNAME_${toUpper(currency.id)}`, value: currency.shortName });
                });
            }

            numberFormatter.initSettings(settings);
            setIsInitialized(true);
        }
    }, [numberFormatter, companySettings, numberFormatSettings, currenciesSettings, isInitialized]);

    return {
        numberFormatter,
        isInitialized,
    };
}
