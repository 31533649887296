import React from 'react';
import { isFunction } from 'lodash';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { modalActions } from '@states/actions/modalActions';
import Modal from 'react-bootstrap/Modal';
import renderModal from '@modals/modalNew/modalFunctions';
import { createUseStyles } from 'react-jss';
import ConfirmDialog from '@baseComponents/modals/ConfirmDialog';
import { useTranslation } from 'react-i18next';
import Svg from '@baseComponents/Svg';

const useStyles = createUseStyles((theme: any) => ({
    simpleModal: {
        '& .modal-content': {
            display: 'inline-block',
            width: 'auto',
        },
        '& .modal-dialog': {
            display: 'flex',
            justifyContent: 'center',
            padding: 0,
        },
        overflow: 'hidden !important',
    },
    fullSimpleModal: {
        '& .modal-dialog-centered': {
            margin: 'auto',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'normal',
            padding: 0,
            position: 'fixed',
            top: 0,
            left: -1,
        },
        '& .modal-content': {
            borderRadius: 0,
        },
    },
    zStyle: {
        zIndex: '99999 !important',
    },
    modalHeaderContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        padding: 0,
        marginRight: -20,
        marginBottom: -40,
        borderBottom: 'none',
    },
    closeButton: {
        position: 'relative',
        bottom: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'none',
        border: 'none',
        padding: 0,
        font: 'inherit',
        cursor: 'pointer',
        outline: 'inherit',
        height: '40px',
        width: '40px',
        backgroundColor: theme.color.white,
        borderRadius: '50%',
        zIndex: theme.zIndex.modalClose,
        boxShadow: theme.shadows.dropdown,
        '&:hover': {
            boxShadow: '0 6px 22px 4px rgba(33, 40, 44, 0.2), 0 12px 18px 2px rgba(33, 40, 44, 0.3), 0 4px 8px -4px rgba(33, 40, 44, 0.3)',
            backgroundColor: theme.color.lilian,
        },
    },
    closeIcon: {
        width: 24,
        height: 24,
    },
}));

export default function ModalNew(props) {
    const { children, isOpen, handleClose, hasCloseIcon, hasCloseDialog = false, isMaxZIndex, checkForChanges, isFullModal = false, isIgnoreOuterClick } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const hasPreventCloseDialog = useSelector((state: any) => state.modal.hasPreventCloseDialog);
    const dispatch = useDispatch();

    const renderConfirmationModal = confirmCallback => {
        renderModal(ConfirmDialog, {
            iconId: 'icon-help',
            isAcceptCancelDialog: true,
            acceptButtonText: t('default.yes', 'Yes'),
            cancelButtonText: t('default.no', 'No'),
            questionText: t('modal.areYouSureToQuit', 'Are you sure you want to quit?'),
            descriptionText: t('modal.unsavedModifications', 'You have unsaved modifications.'),
            confirmCallback: () => {
                if (isFunction(confirmCallback)) {
                    confirmCallback();
                }
            },
        }, false);
    };

    const closeWithDialog = () => {
        const isDetectedChanges = isFunction(checkForChanges) ? checkForChanges() : false;
        if ((hasPreventCloseDialog || isDetectedChanges) && hasCloseDialog) {
            const confirmCallback = () => {
                handleClose();
                dispatch(modalActions.setPreventCloseDialog(false));
            };
            renderConfirmationModal(confirmCallback);
            return;
        }
        handleClose();
    };

    const outerClickModal = () => {
        if (!isIgnoreOuterClick) {
            closeWithDialog();
        }
    };

    return (
        <Modal className={clsx(classes.simpleModal, isMaxZIndex && classes.zStyle, isFullModal && classes.fullSimpleModal)} show={isOpen} onHide={outerClickModal} animation={false} centered enforceFocus={false}>
            {hasCloseIcon
                ? (
                    <Modal.Header className={classes.modalHeaderContainer}>
                        <button className={classes.closeButton} type="button" onClick={closeWithDialog}>
                            <Svg iconId="icon-close" style={classes.closeIcon} />
                        </button>
                    </Modal.Header>
                ) : null
            }
            {React.cloneElement(children, { handleClose, closeWithDialog })}
        </Modal>
    );
}
