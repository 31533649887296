/* eslint-disable react/forbid-prop-types */
import { useTranslation } from 'react-i18next';
import { CROP } from 'shared/src/constants';
import * as Yup from 'yup';

export default function useNewCultivationPeriodValidation(props) {
    const { isQulificationRequired } = props;
    const { t } = useTranslation();

    const validationSchema = Yup.object().shape({
        owner: Yup.number()
            .integer()
            .required(t('newCultivationPeriod.categorizeCompanyPartner', 'Categorize the company and partner.')),
        ownerType: Yup.number()
            .integer()
            .required(),
        name: Yup.string()
            .required(t('newCultivationPeriod.specifyName', 'Specify the name')),
        startDate: Yup.string()
            .required(t('newCultivationPeriod.startDateRequired', 'Start date is required')),
        sownArea: Yup.number()
            .positive(t('newCultivationPeriod.sownAreaRequired', 'Sown area is required'))
            .moreThan(0, t('newCultivationPeriod.sownAreaRequired', 'Sown area is required'))
            .required(t('newCultivationPeriod.sownAreaRequired', 'Sown area is required')),
        crop: Yup.object({
            plantationType: Yup.number().when('type', {
                is: value => value && value === CROP.TYPE.PLANTATION,
                then: Yup.number().required(t('newCultivationPeriod.specifyPlantationType', 'Specify the type of the plantation')),
                otherwise: Yup.number(),
            }),
            year: Yup.string().when('type', {
                is: value => value && value === CROP.TYPE.PLANTATION,
                then: Yup.string().required(t('newCultivationPeriod.specifyPlantationInstallationYear', 'Specify the installation year of the plantation')),
                otherwise: Yup.string(),
            }),
        }),
        basicFields: Yup.array().required(t('basicFields.specifyTheBasicField', 'Specify the basic field.')),
        period: Yup.number()
            .integer()
            .moreThan(0, t('default.productionYearRequired', 'Production year is required'))
            .required(t('default.productionYearRequired', 'Production year is required')),
        qualification:
            isQulificationRequired ? Yup.number()
                .required(t('newCultivationPeriod.qualificationRequired', 'Qualification is required')).nullable(true)
                : null,
    });

    return {
        validationSchema,
    };
}
