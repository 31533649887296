import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { errorActions } from '@states/actions';
import { analysisService } from '@services';

export default function useVarietyStrainModalLoadData() {
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState();
    const location = useLocation();

    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const varietyStrains = await analysisService.getVarietyStrains();
                if (!isCancelled) {
                    setTableData(varietyStrains);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (dispatch) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [dispatch, location]);

    return {
        tableData,
    };
}
