import { ThemeType } from '@map/services/mapTypes';
import { isArray, isFunction, isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
import ReactSlider from 'react-slider';
// eslint-disable-next-line no-restricted-imports
import { MapSliderOptions } from '../MapSliderTypes';
import { MapSliderThumb } from './MapSliderThumb';

type Classes = 'sliderContainer' | 'slider' | 'sliderTrack' | 'sliderThumb';

const useStyles = createUseStyles<Classes, StyleProps, ThemeType>(theme => ({
    sliderContainer: {
        position: 'absolute',
        width: '100%',
        zIndex: 250,
        display: 'flex',
        justifyContent: 'center',
        height: 50,
    },
    slider: {
        width: '100%',
        height: 50,
        top: 0,
        cursor: 'pointer',
    },
    sliderThumb: {
        textAlign: 'center',
        verticalAlign: 'middle',
        backgroundColor: 'transparent',
        color: 'white',
        boxSizing: 'border-box',
        height: 50,
        borderRadius: 2,
        background: 'white',
        width: props => `${(100 / (props.interval + 1))}%`,
    },
    sliderTrack: {
        position: 'relative',
        background: 'transparent',
        height: 50,
        borderRadius: 5,
    },
    sliderThumbeInnerActive: {
        position: 'absolute',
        top: -20,
        whiteSpace: 'nowrap',
        fontSize: '1.1em',
        textShadow: '1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000',
    },
}));

type TProps = {
    onChange: (date: Date, value: number) => void,
    mapSliderOptions?: MapSliderOptions
}

type StyleProps = {
    interval: number,
}

export const MapSliderComponent = ({ mapSliderOptions, onChange }: TProps) => {
    const sliderRef = useRef<any>(null);

    const classes = useStyles({ interval: (mapSliderOptions ? (mapSliderOptions.max - mapSliderOptions.min) : 2) });

    const [isSliderVisible, setIsSliderVisible] = useState(true);

    useEffect(() => {
        setIsSliderVisible(false);
        setTimeout(() => {
            setIsSliderVisible(true);
        }, 0);
    }, [mapSliderOptions]);

    const onSliderAfterChange = (value: number, index: number) => {
        const choosenDate = mapSliderOptions?.datePicker?.[value].date;

        if (choosenDate) {
            onChange(choosenDate, value);
        }
    };

    return (
        <div className={classes.sliderContainer}>
            {!isNil(mapSliderOptions) && isSliderVisible && (
                <ReactSlider<number>
                    ref={sliderRef}
                    className={classes.slider}
                    thumbClassName={classes.sliderThumb}
                    trackClassName={classes.sliderTrack}
                    ariaValuetext={state => `Thumb value ${state.valueNow}`}
                    defaultValue={mapSliderOptions.defaultValue}
                    ariaLabel="Leftmost thumb"
                    pearling
                    min={mapSliderOptions.min}
                    max={mapSliderOptions.max}
                    onAfterChange={onSliderAfterChange}
                    renderThumb={props => <div {...props}><MapSliderThumb /></div>}
                />
            )
            }

        </div>
    );
};
