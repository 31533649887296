import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { isEmpty } from 'lodash';
import { usePhpWindowFunctions } from '@utils';
import { errorActions } from '@states/actions';
import { certificateService } from '@services';

export default function useCertificatesModalLoadData(props) {
    const { requestIds, releasedCertificateIds, receptionCertificateIds } = props || {};
    const dispatch = useDispatch();
    const location = useLocation().pathname;
    const [isReloadTable, setIsReloadTable] = useState(false);
    const [tableData, setTableData] = useState();
    usePhpWindowFunctions();

    function reloadTable() {
        setIsReloadTable(!isReloadTable);
    }

    //bizonylat id alapján betöltés
    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const certificates = await certificateService.getCertificatesByIds({ releasedCertificateIds, receptionCertificateIds });
                if (!isCancelled) {
                    setTableData(certificates);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if ((!isEmpty(releasedCertificateIds) || !isEmpty(receptionCertificateIds)) && location && dispatch) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [isReloadTable, releasedCertificateIds, receptionCertificateIds, location, dispatch]);

    //kérvény id alapján betöltés
    useEffect(() => {
        let isCancelled = false;

        async function loadData() {
            try {
                const certificates = await certificateService.getCertificatesByRequestIds({ requestIds });
                if (!isCancelled) {
                    setTableData(certificates);
                }
            } catch (error) {
                dispatch(errorActions.setError(error, location));
            }
        }
        if (!isEmpty(requestIds) && location && dispatch) {
            loadData();
        }

        return () => {
            isCancelled = true;
        };
    }, [isReloadTable, requestIds, location, dispatch]);

    return {
        tableData,
        reloadTable,
    };
}
