import useTranslate from '@i18n/useTranslate';
import { Layer, ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';
import { LayerFolderEditMode } from './LayerFolderEdit.enum';
import { getLayerName } from './LayerFolderUtils';

type Classes = 'headerTitle'

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    headerTitle: {
        padding: 0,
        fontSize: 22,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: theme.color.jet,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
}));

type Tprops = {
    editPanelMode: LayerFolderEditMode,
    layer: Layer,
}

export function LayerFolderEditTitle({ editPanelMode, layer }: Tprops) {
    const classes = useStyles();
    const { translater, t } = useTranslate();

    if (editPanelMode === LayerFolderEditMode.EDIT) {
        return (
            <p className={classes.headerTitle}>
                {getLayerName(layer, translater)} - {t('map.layerMoreOptionEdit', 'Edit')}
            </p>
        );
    }

    if (editPanelMode === LayerFolderEditMode.CREATE) {
        return (
            <p className={classes.headerTitle}>
                {getLayerName(layer, translater)} - {t('map.layerMoreOptionCreate', 'Create')}
            </p>
        );
    }

    return null;
}
