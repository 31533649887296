/* eslint-disable import/no-cycle */
import { hasPermission, hasEveryPermissions as hasEveryPermissionChecker, hasAccessToContent } from 'shared/src/permissions';
import { isSubscribedServiceAccess } from 'shared/src/user/userSettings';
import { isEmpty, isNumber, isUndefined } from 'lodash';
import { userService, companyService, sessionService, hotjarService } from '@services';
import * as apis from '@map/services/mapApis';
import { simpleAction } from './baseActions';
import { companyActions } from './companyActions';
import { systemActions } from './systemActions';
import { errorActions } from './errorActions';

export const userActions = {
    getTwoFactorAuth,
    login,
    logout,
    setActiveSystemModule,
    updateUser,
    setInterfaceSetting,
    hasEveryPermissions,
    getRight,
    getRights,
    setRights,
    setUserData,
    hasSubscribedServiceAccess,
    hasUserAccessToContent,
    setTableColumnOrder,
    getInterfaceSetting,
    getUserDatas,
    setUserProfilePictureUrl,
    SET_USER: 'USERS_SET_USER',
    REMOVE_USER: 'USERS_REMOVE_USER',
    SET_AGENT: 'USERS_SET_AGENT',
    SET_AGENT_ERROR: 'USERS_SET_AGENT_ERROR',
    REMOVE_AGENT_ERROR: 'USERS_REMOVE_AGENT_ERROR',
    SET_USER_ERROR: 'USERS_SET_USER_ERROR',
    REMOVE_USER_ERROR: 'USERS_REMOVE_USER_ERROR',
    SET_CHECKED_USER_TOKEN: 'USERS_SET_CHECKED_USER_TOKEN',
    SET_ACTIVE_SYSTEM_MODULE: 'USERS_SET_ACTIVE_SYSTEM_MODULE',
    SET_USER_INTERFACE_SETTING: 'USERS_SET_USER_INTERFACE_SETTING',
    SET_RIGHTS: 'USER_SET_RIGHTS',
    SET_TABLE_COLUMN_ORDER: 'USER_SET_TABLE_COLUMN_ORDER',
    SET_USER_PROFILE_PICTURE_URL: 'USER_SET_USER_PROFILE_PICTURE_URL',
};

function getTwoFactorAuth(userName, password, agentName) {
    return async dispatch => {
        try {
            const result = await userService.getTwoFactorAuth(userName, password, agentName);
            return result;
        } catch (error) {
            dispatch(simpleAction(userActions.SET_USER_ERROR, { error: { error, message: 'User login error!' } }));
            return null;
        }
    };
}

function login(isMaintained, loginData = null) {
    return async dispatch => {
        try {
            //get user info
            let token = sessionService.getAuthToken();
            if (isMaintained === true) {
                if (token) {
                    logout();
                }
                dispatch(simpleAction(userActions.SET_CHECKED_USER_TOKEN, { isCheckedUserToken: true }));
                return null;
            }

            if (!token && !loginData) {
                dispatch(simpleAction(userActions.SET_CHECKED_USER_TOKEN, { isCheckedUserToken: true }));
                return null;
            }

            if (!token) {
                token = await userService.login(loginData);
                sessionService.setAuthToken(token);
            }

            if (token) {
                dispatch(simpleAction(userActions.REMOVE_USER_ERROR));

                if (isMaintained) {
                    sessionService.removeAuthToken();
                    dispatch(simpleAction(userActions.SET_CHECKED_USER_TOKEN, { isCheckedUserToken: true }));
                    return null;
                }
            }

            // successfully login
            if (token) {
                const company = await companyService.getCompany();
                dispatch(simpleAction(companyActions.SET_COMPANY, { company }));

                const user = await userService.getCurrentUser();
                dispatch(simpleAction(userActions.SET_USER, { user }));

                if (sessionService.getPlanningMode() && !isEmpty(sessionService.getPlanningData())) {
                    dispatch(systemActions.setPlanningModeActive(true));
                }

                hotjarService.identifyUser(user.id, user);

                dispatch(simpleAction(userActions.SET_USER, { user }));
                const domainName = sessionService.getAgentName();
                const avAuthToken = sessionService.getAuthToken();
                const countryCode = company?.globalData?.countryCode;

                const res = await apis.fetchMockGisToken({ userId: user.id, companyId: domainName, avAuthToken, countryCode });
                if (res.ok) {
                    const { accessToken } = res.data;
                    sessionStorage.setItem('gisAuthToken', accessToken);
                    sessionService.setGisTokenToAgent(accessToken);
                }
            } else {
                dispatch(simpleAction(userActions.SET_USER_ERROR, { error: { message: 'Invalid username or password!' } }));
            }
            dispatch(simpleAction(userActions.SET_CHECKED_USER_TOKEN, { isCheckedUserToken: true }));
            return true;
        } catch (error) {
            sessionService.removeAuthToken();
            dispatch(simpleAction(userActions.SET_USER_ERROR, { error: { error, message: 'User login error!' } }));
            return null;
        }
    };
}

function logout() {
    sessionService.removePlanningData();
    sessionService.removePlanningMode();
    sessionService.removeAuthToken();
    simpleAction(systemActions.SET_PLANNING_MODE, { planningModeisActive: false });
    simpleAction(companyActions.deleteActiveCompanyList());
    return simpleAction(userActions.REMOVE_USER);
}

function setActiveSystemModule(moduleId) {
    return async dispatch => {
        try {
            const activeModuleSetResult = await userService.setActiveModule(moduleId);
            if (activeModuleSetResult) {
                dispatch(simpleAction(userActions.SET_ACTIVE_SYSTEM_MODULE, { moduleId }));
            }
        } catch (error) {
            dispatch(simpleAction(userActions.SET_USER_ERROR, { error: { error, message: 'The update did not execute!' } }));
        }
    };
}

function updateUser() {
    return async dispatch => {
        try {
            const user = await userService.getCurrentUser();
            dispatch(simpleAction(userActions.SET_USER, { user }));
        } catch (error) {
            dispatch(simpleAction(userActions.SET_USER_ERROR, { error: { error, message: 'The update did not execute!' } }));
        }
    };
}

function setInterfaceSetting(settingName, settingValue, fileName) {
    return async dispatch => {
        try {
            const updateResult = await userService.setInterfaceSetting(settingName, settingValue, fileName);
            if (updateResult) {
                dispatch(simpleAction(userActions.SET_USER_INTERFACE_SETTING, { settingName, updateResult, fileName }));
            }
        } catch (error) {
            dispatch(simpleAction(userActions.SET_USER_ERROR, { error: { error, message: 'The update did not execute!' } }));
        }
    };
}

function hasEveryPermissions(rightIds) {
    return (__, getState) => {
        const { user } = getState();
        const userToRights = user.user.userToRights;
        const isHiddenUser = user.user.isHidden;

        if (!isEmpty(userToRights) && isNumber(Number(isHiddenUser))) {
            return hasEveryPermissionChecker(rightIds, userToRights, isHiddenUser);
        }

        return false;
    };
}

function getRights(rightsObject) {
    return (__, getState) => {
        const { user } = getState();
        const userToRights = user.user.userToRights;
        const isHiddenUser = user.user.isHidden;

        const rightResult = {};

        if (!isEmpty(rightsObject)) {
            const rightsObjectEntries = Object.entries(rightsObject);
            if (isEmpty(rightsObjectEntries)) {
                return rightResult;
            }

            rightsObjectEntries.forEach(([key, value]) => {
                if (!isUndefined(value)) {
                    rightResult[key] = hasPermission(value, userToRights, isHiddenUser);
                }
            });
        }

        return rightResult;
    };
}

function getRight(right) {
    return (__, getState) => {
        const { user } = getState();
        const userToRights = user.user.userToRights;
        const isHiddenUser = user.user.isHidden;

        return hasPermission(right, userToRights, isHiddenUser);
    };
}

function setRights(rights) {
    return simpleAction(userActions.SET_RIGHTS, { rights });
}

function setUserData(userObject) {
    return simpleAction(userActions.SET_USER, { user: userObject });
}

function hasSubscribedServiceAccess(serviceAccess) {
    return (__, getState) => {
        const { user, company } = getState();
        const companySubscriptions = company.company.globalData.companySubscriptionPackage;

        return isEmpty(companySubscriptions) ? true : isSubscribedServiceAccess(user.user, serviceAccess);
    };
}

function hasUserAccessToContent(moduleObject, contentObject) {
    return (__, getState) => {
        const { user, company, system } = getState();
        const { userToRights, administrator, consultant, currentSystemModule, isHiddenUser } = user.user;
        const companySettings = company.company.settings;
        const planningMode = sessionService.getPlanningMode();
        const getPlanningData = sessionService.getPlanningData();
        const countryCode = system.systemInfo.countryCode;

        if (!isEmpty(moduleObject) && !isEmpty(contentObject) && companySettings) {
            return hasAccessToContent(moduleObject, contentObject, userToRights, administrator, consultant, companySettings, currentSystemModule, planningMode, countryCode, isHiddenUser, getPlanningData);
        }

        return false;
    };
}

function setTableColumnOrder(location, tableColumns) {
    return async dispatch => {
        try {
            dispatch(simpleAction(userActions.SET_TABLE_COLUMN_ORDER, { location, tableColumns }));
            userService.setTableColumnOrder(location, tableColumns);
        } catch (error) {
            dispatch(errorActions.setError(error, 'BasePageActions - column order'));
        }
    };
}

function getInterfaceSetting(saveFileName, settingName) {
    return (__, getState) => {
        const { user } = getState();
        return user.user.settings.interfaceSettings[saveFileName] ? user.user.settings.interfaceSettings[saveFileName][settingName].value : null;
    };
}

function getUserDatas() {
    return (__, getState) => {
        const { user } = getState();
        return user?.user;
    };
}

function setUserProfilePictureUrl(profilePictureUrl) {
    return dispatch => {
        dispatch(simpleAction(userActions.SET_USER_PROFILE_PICTURE_URL, { profilePictureUrl }));
    };
}
