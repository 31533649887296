import { routerHelper } from '@utils/router/RouterHelper';
import { sendServerRequest } from './baseService';

export const workOperationsService = {
    getWorkOperationMenuList,
    getWorkOperations,
    getWorkOperationById,
    getWorkOperationToDataSheetView,
    getWorkOperationCultivationPeriodOrPeriodGroupList,
    getWorkOperationEconomicUnitList,
    getWorkOperationPartnerList,
    getWorkOperationMaingroup,
    getWorkOperationGroup,
    getWorkOperationBlock,
    getWorkOperationServiceReasons,
    getWorkOperationTypes,
    getAllWorkShiftWithTime,
    getWorkOperationsToFilter,
    getWorkOperationsNamesWithoutPlannedProductUsage,
    getWorkOperationsNumberOfUnqualifiedDailyData,
    getDeletebleWorkOperations,
    getEditWorkOperationResources,
    getWorkOperationServiceData,
    getEditWorkOperationProduct,
    setWorkOperationsApproval,
    deleteWorkOperations,
    setWorkOperation,
    getWorkOperationQualifications,
    getWorkOperationQualificationsCultivationPeriods,
    deleteWorkOperationQualifications,
    updateWorkOperationQualifications,
    getWorkOperationQualificationParameters,

    //segédüzemi rögzítés
    getWorkOperationActivityDataRecord,
    setWorkOperationActivityDataRecord,
    deleteWorkOperationActivityDataRecords,
    getWorkOperationDailyDataServiceUnitPrice,
    getWorkOperationMaterialUsageData,
};

/**
 * Get work operations in grouped status to menu/index  page
 * @property {int} companyIds IsRequired - Company id list eg.: [1]
 *
 * @return {array} Work operations list
 */
async function getWorkOperationMenuList(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workoperationMenuList', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get all work operation datas
 * @property {array<int>} companyIds IsOptional - Company ID list eg.: [1]
 * @property {date} startDate IsRequired - Work operation start date eg.: 2022-01-01
 * @property {date} endDate IsRequired - Work operation end date eg.: 2022-01-11
 * @property {array<int>} workoperationTypeIds IsOptional - Work operation type ids eg.: [1]
 * 1 - Table
 * 2 - Economic unit
 * 3 - Service
 * 4 - Wage work
 * @property {array<int>} workoperationStateIds IsOptional - State ids eg.: [1]
 * 1 - Template
 * 2 - Ongoing
 * 3 - Planned
 * 4 - Completed
 * @property {array<int>} workerIds IsOptional - Optional filter - Filter by workers eg.: [1]
 * @property {array<int>} machineIds IsOptional - Optional filter - Filter by machines eg.: [1]
 * @property {array<int>} periodIds IsOptional - Optional filter - Filter by periods eg.: [1]
 * @property {array<int>} partnerIds IsOptional - Optional filter - Filter by partners eg.: [1]
 * @property {array<int>} productIds IsOptional - Optional filter - Filter by products eg.: [1]
 * @property {array<int>} economicUnitIds IsOptional - Optional filter - Filter by economic units eg.: [1]
 * @property {array<int>} economicUnitProductIds IsOptional - Optional filter - Filter by economic unit products eg.: [1]
 * @property {array<int>} economicUnitFacilityIds IsOptional - Optional filter - Filter by economic unit facilities eg.: [1]
 * @property {array<int>} performanceIds IsOptional - Optional filter - Filter by performances eg.: [1]
 * @property {array<int>} machineConnectionIds IsOptional - Optional filter - Filter by machine connection eg.: [1]
 * @property {array<int>} creatorIds IsOptional - Optional filter - Filter by creators eg.: [1]
 * @property {array<int>} otherIds IsOptional - Optional filter - Filter by others eg.: [1]
 * @property {array<int>} farmIds IsOptional - Optional filter - Filter by farms eg.: [1]
 * @return {array} Work operations array
 */
async function getWorkOperations(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationList', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get work operation detailed data to edit modal
 * @property {int} workOperationId IsOptional - Work operation id eg.: 1
 *
 * @return {object} Work operation detailed data
 */
async function getWorkOperationById(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationById', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get a work operation to data sheet view
 * @property {int} workOperationId IsRequired - Work operation id eg.: 1
 * @property {bool} isPeriodGroup IsRequired - Work operation is period group (table work operation on period group) flag eg.: 1 or true
 * @property {array<int>} workOperationTypeId IsRequired - Work operation type id eg.: 1
 * 1 - Table
 * 2 - Economic unit
 * 3 - Service
 * 4 - Wage work
 * @property {array<int>} workOperationStateId IsRequired - Work operation state id eg.: 1
 * 1 - Template
 * 2 - Ongoing
 * 3 - Planned
 * 4 - Completed
 * @property {int} mapCompanyId IsOptional - Map company id eg.: 1
 *
 * @return {object} Work operation datas
 */
async function getWorkOperationToDataSheetView(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationToDataSheetView', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get cultivation periods or period group list to new work operation
 * @property {array<int>} companyIds IsRequired - Company id list eg.: [1]
 * @property {array<int>} economicUnitTypeIds IsOptional - Economic unit type id list eg.: [1]
 * @property {int} workOperationId IsOptional - Work operation id (workoperationId - fixed item) eg.: 1
 * @property {date} plannedDate IsOptional - Work operation planned date eg.: '2022-01-24'
 * @property {int} mapCompanyId IsOptional -Map company id eg.: 1
 * @property {bool} isSaveAs IsOptional - Save as flag eg.: 0 or 1
 * @property {bool} isCalculateRemainingArea IsOptional - Calculate remaining area flag eg.: 0 or 1
 * @property {bool} isAllPeriodShowed IsOptional - All period showed flag eg.: 0 or 1
 * @property {bool} isPeriodGroup IsOptional - Cultivation period group flag eg.: 0 or 1
 * 0 - cultivation period
 * 1 - cultivation period group
 * @return {array} Cultivation period/period group list
 */
async function getWorkOperationCultivationPeriodOrPeriodGroupList(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workoperationCultivationPeriodOrGroupList', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get economic unit list to new work operation
 * @property {int} workOperationId IsOptional - Work operation id (workoperationId - fixed item) eg.: 1
 * @property {date} date IsOptional - Work operation date eg.: '2022-01-24'
 * @property {int} isPeriodGroup IsOptional - Cultivation period group flag eg.: 0 or 1
 * 0 - cultivation period
 * 1 - cultivation period group
 * @return {array} Economic unit list
 */
async function getWorkOperationEconomicUnitList(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationEconomicUnitList', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get partner list - has work operation
 * @property {List<int>} companyIds IsRequired - Company id list eg.: [1]
 * @property {int} workOperationId IsOptional - Work operation id eg.: 1
 * @property {bool} hasWorkOperationPartner IsOptional - Is work operation partner flag eg.: 1 or true
 * @return {array} Partner list
 */
async function getWorkOperationPartnerList(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationPartners', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get work operation main groups
 * @property {array<int>} workoperationTypeIds IsRequired - Work operation type id list eg.: [1]
 * @property {int} mainGroupStatus IsOptional - Work operation status eg.: 1
 * default: 1 - active
 * @return {array} Work operation main group list
 */
async function getWorkOperationMaingroup(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationsMaingroup', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get work operation main groups
 * @property {array<int>} workoperationTypeIds IsRequired - Work operation type id list eg.: [1]
 * @property {int} groupStatus IsOptional - Work operation status eg.: 1
 * default: 1 - active
 * @property {bool} isWithWorkOperationMainGroup IsOptional - Extra data flag - show work operation main group eg.: 1 or true
 * @property {bool} optionalFilters IsOptional - Extra data in json format eg.: {}
 * @return {array} Work operation group list
 */
async function getWorkOperationGroup(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationsGroup', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get work operation blocks
 * @property {array<int>} workOperationTypeIds IsRequired - Work operation type id list eg.: [1]
 * @property {array<int>} companyIds IsRequired - Company id list eg.: [1]
 * @property {int} blockStatus IsOptional - Work operation block status eg.: 1
 * default: 1 - active
 * @return {array} Work operation block list
 */
async function getWorkOperationBlock(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationsBlock', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get work operation reason of repair
 *
 * @return {array} Work operation reason of repair list
 */
async function getWorkOperationServiceReasons() {
    const response = await sendServerRequest('GET', '/api/workOperations/serviceReasons');
    return response;
}

/**
 * Get work operation types
 *
 * @return {array} Work operation type list
 */
async function getWorkOperationTypes() {
    const response = await sendServerRequest('GET', '/api/workOperationTypes');
    return response;
}

/**
 * Get all workshift with time arrays
 *
 * @return {array} Workshifts with time list
 */
async function getAllWorkShiftWithTime() {
    const response = await sendServerRequest('GET', '/api/workOperations/workShiftWithTime');
    return response;
}

/**
 * Get work operation to filters - default infos
 * @property {array<int>} workOperationTypeId IsRequired - Work operation type id eg.: 1
 * @property {array<int>} companyIds IsRequired - Company id list eg.: [1]
 * @property {int} startDate IsOptional - Work operation start date id eg.: '2022-01-20'
 * @property {int} endDate IsOptional - Work operation end date eg.: '2022-01-20'
 * @property {int} partfieldType IsOptional - Work operation partfield type id eg.: 1
 * 1 - Period
 * 2 - Period group
 * default: 1 - Period
 * @property {int} workOperationStateId IsOptional - Work operation state id eg.: [1]
 * 1 - Template
 * 2 - Ongoing
 * 3 - Planned
 * 4 - Completed
 * default: 2 - ONGOING
 * @return {array} Work operation list
 */
async function getWorkOperationsToFilter(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workoperationsToFilter', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get work operations name if the work operations has planned product usage but don't have used ammount - work operation approve
 * @property {List<int>} workOperationIds IsRequired - Work operation id list eg.: [1]
 * @return {array} Work operations name list
 */
async function getWorkOperationsNamesWithoutPlannedProductUsage(workOperationIds) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationsWithoutPlannedProductUsage', { workOperationIds });
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get work operation datas which can't be delete
 * @property {List<int>} workOperationIds IsRequired - Work operation id list eg.: [1]
 * @return {obj} Work operation obj - withDailyData, withOutgoings, withUsage
 */
async function getDeletebleWorkOperations(workOperationIds) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/deletebleWorkOperations', { workOperationIds });
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get edited work operation resource list
 * @property {int} workOperationId IsRequired - Work operation id eg.: 1
 * @property {bool} isServiceWork IsRequired - Work operation is service work flag eg.: 1 or true
 * @return {list} Work operation resources
 */
async function getEditWorkOperationResources(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationMachineConnectionEdit', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get edited work operation service data
 * @property {int} workOperationId IsRequired - Work operation id eg.: 1
 * @return {obj} Work operation service data
 */
async function getWorkOperationServiceData(workOperationId) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationServiceData', { workOperationId });
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get edited work operation product list
 * @property {int} workOperationId IsRequired - Work operation id eg.: 1
 * @property {int} workOperationTypeId IsRequired - Work operation type id eg.: 1
 * @property {int} mapCompanyId IsOptional - Map company id eg.: 1
 * @property {bool} isPeriodGroup IsRequired - Work operation is period group flag eg.: 1 or true
 * @return {list} Work operation resources
 */
async function getEditWorkOperationProduct(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationMaterialEdit', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Set work operations approval date and/or status
 * @property {List<obj>} workOperations IsRequired - Work operation list - contain work operation id and date eg.: [{ id: 1, date: '2022-01-25' }]
 * @property {int} workOperationsStatusId IsRequired - Work operation status id eg.: 1 (ONGOING)
 * @return {bool} Work operation update is successfull
 */
async function setWorkOperationsApproval(props) {
    const response = await sendServerRequest('PATCH', '/api/workOperations/workOperationsDateAndState', props);
    return response;
}

/**
 * Delete work operations
 * @property {List<int>} workOperationIds IsRequired - Work operation id list eg.: [1]
 * @return {bool} Work operations delete was successfull
 */
async function deleteWorkOperations(props) {
    const response = await sendServerRequest('DELETE', '/api/workOperations/deleteWorkOperations', props);
    return response;
}

/**
 * Delete work operations
 * @property {obj} workoperationData IsRequired - Work operation object to save
 * @return {bool} Work operations save was successfull
 */
async function setWorkOperation(workoperationData) {
    const response = await sendServerRequest('POST', '/api/workOperations/workOperation', workoperationData);
    return response;
}

async function getWorkOperationQualifications(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationQualificationList', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getWorkOperationQualificationsCultivationPeriods(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationQualificationCultivationPeriodsList', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function deleteWorkOperationQualifications(props) {
    const response = await sendServerRequest('DELETE', '/api/workOperations/deleteWorkOperationQualifications', props);
    return response;
}

async function updateWorkOperationQualifications(props) {
    const response = await sendServerRequest('POST', '/api/workOperations/updateWorkOperationQualifications', props);
    return response;
}

async function getWorkOperationQualificationParameters(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/qualificationParameters', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get work operation daily datas (segédüzemi rögzítés)
 * @property {int} workOperationId IsRequired - Work operation id eg.: 1
 * @property {int} workOperationTypeId IsRequired - Work operation type id  eg.: 1
 * @property {int} mapCompanyId IsOptional - Map company id eg.: 1
 * 1 - Table
 * 2 - economic unit
 * 3 - service
 * 4 - wage work
 * @property {bool} isPeriodGrouping IsRequired - Work operation period or period group eg.: 1 or true
 * 0 - period
 * 1 - period group
 * @property {array<int>} companyIds IsOptional - Company ID list eg.: [1]
 * @return {obj} Work operations daily data
 */
async function getWorkOperationActivityDataRecord(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/activityDataRecord', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Set work operation daily datas
 * @property {obj} dailyDataObject IsRequired - Work operation daily data object to save
 * @return {bool} Work operations save was successfull
 */
async function setWorkOperationActivityDataRecord(dailyDataObject) {
    const response = await sendServerRequest('POST', '/api/workOperations/activityDataRecord', dailyDataObject);
    return response;
}

/**
 * Delete work operation daily datas
 * @property {obj} date IsRequired - Work operation daily data date eg.: 2022-03-08
 * @property {obj} workOperationId IsRequired - Work operation id
 * @return {bool} Work operations delete was successfull
 */
async function deleteWorkOperationActivityDataRecords({ props }) {
    const response = await sendServerRequest('DELETE', '/api/workOperations/activityDataRecord', props);
    return response;
}

/**
 * Get work operation daily data service unit price (segédüzemi rögzítés - szolg. egységár)
 * @property {int} workOperationId IsRequired - Work operation id eg.: 1
 * @property {int} workOperationTypeId IsRequired - Work operation type id eg.: 1
 * @property {date} date IsRequired - Work operation date eg.: 2022-03-08
 * @return {obj} Work operations daily data service unit price
 */
async function getWorkOperationDailyDataServiceUnitPrice(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/activityDataRecordServiceUnitPrice', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

/**
 * Get work operation daily data service unit price (segédüzemi rögzítés - szolg. egységár)
 * @property {int} workOperationIds IsRequired - Work operation id or id list eg.: 1 or [1]
 * @property {int} workOperationTypeId IsRequired - Work operation type id eg.: 1
 * @property {int} productId IsOptional - ... eg.: 1
 * @property {bool} isExistingProduct IsOptional - flag eg.: 1
 * @return {obj} Work operations daily data service unit price
 */
async function getWorkOperationMaterialUsageData(props) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/materialUsageData', props);
    const response = await sendServerRequest('GET', url);
    return response;
}

async function getWorkOperationsNumberOfUnqualifiedDailyData(workOperationIds) {
    const url = routerHelper.getUrlWithParameters('/api/workOperations/workOperationsNumberOfUnqualifiedDailyData', { workOperationIds });
    const response = await sendServerRequest('GET', url);
    return response;
}
