import Svg from '@baseComponents/Svg';
import { ThemeType } from '@map/services/mapTypes';
import { createUseStyles } from 'react-jss';

type Classes = 'icon' | 'iconWrapper';

const useStyles = createUseStyles<Classes, unknown, ThemeType>(theme => ({
    icon: {
        height: 30,
        width: 30,
    },
    iconWrapper: {
        padding: 5,
        border: '2px solid #E3E6E8',
        borderRadius: 5,
        float: 'left',
    },
}));

export default function CultureIcon() {
    const classes = useStyles();

    return (
        <div className={classes.iconWrapper}>
            <Svg iconId="icon-leafy" style={classes.icon} />
        </div>
    );
}
