import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TABLE, UNITS } from 'shared/src/constants';
import { useNumberFormat } from '@common_modules/numberFormat';
import { isEmpty } from 'lodash';

export default function useTableDataHandlerInitValues(props) {
    const { isBasePageTable, tableKey } = props;

    //basePage table - default data
    const tableData = useSelector((state: any) => state.basePage.table.tableData);
    const columns = useSelector((state: any) => state.basePage.table.columns);
    const tableLineViewType = useSelector((state: any) => state.basePage.table.multilineViewType);
    const isSelectedCurrencySwitch = useSelector((state: any) => state.company.company.currency.isSelectedCurrencySwitch);
    const { numberFormatter } = useNumberFormat();

    //sorting
    const globalColumnSortingTypes = useSelector((state: any) => state.basePage.table.globalColumnSortingTypes);
    //searching
    const globalSearchValue = useSelector((state: any) => state.basePage.table.globalSearchValue);
    const columnSearchValues = useSelector((state: any) => state.basePage.table.globalColumnSearchValues);
    const selectedGroup = useSelector((state: any) => state.basePage.selectedGroup);

    //simple table - default data
    const simpleTableTableData = useSelector((state: any) => state.simpleTable.table[tableKey]?.tableData);
    const simpleTableColumns = useSelector((state: any) => state.simpleTable.table[tableKey]?.columns);
    //sorting
    const simpleTableGlobalColumnSortingTypes = useSelector((state: any) => state.simpleTable.table[tableKey]?.globalColumnSortingTypes);
    //searching
    const simpleTableGlobalSearchValue = useSelector((state: any) => state.simpleTable.table[tableKey]?.globalSearchValue);
    const simpleTableColumnSearchValues = useSelector((state: any) => state.simpleTable.table[tableKey]?.globalColumnSearchValues);
    const simpleTableSelectedGroup = useSelector((state: any) => state.simpleTable.table[tableKey]?.selectedGroup);

    const [initValues, setInitValues] = useState({
        tableData: null,
        columns: null,
        tableLineViewType: null,
        currentCurrencyDecimalNumber: null,
        currentCurrencyUnitPriceDecimalNumber: null,
        globalColumnSortingTypes: null,
        globalSearchValue: null,
        columnSearchValues: null,
        selectedGroup: null,
    });

    //init basePage table data
    useEffect(() => {
        if (isBasePageTable && !isEmpty(columns)) {
            let format = null;
            let formatUnitPrice = null;

            if (isSelectedCurrencySwitch) { // secondary
                formatUnitPrice = numberFormatter.getSecondaryCurrencyFormats(true);
                format = numberFormatter.getSecondaryCurrencyFormats();
            } else { // primary
                formatUnitPrice = numberFormatter.getPrimaryCurrencyFormats(true);
                format = numberFormatter.getPrimaryCurrencyFormats();
            }

            const currentCurrencyDecimalNumber = format.decimals || UNITS.DECIMAL_NUMBER.CURRENCY;
            const currentCurrencyUnitPriceDecimalNumber = formatUnitPrice.decimals || UNITS.DECIMAL_NUMBER.CURRENCY;

            setInitValues({
                tableData,
                columns,
                tableLineViewType,
                currentCurrencyDecimalNumber,
                currentCurrencyUnitPriceDecimalNumber,
                globalColumnSortingTypes,
                globalSearchValue,
                columnSearchValues,
                selectedGroup,
            });
        }
    }, [isBasePageTable, tableData, columns, tableLineViewType, numberFormatter, isSelectedCurrencySwitch, globalColumnSortingTypes, globalSearchValue, columnSearchValues, selectedGroup]);

    //init simple table data
    useEffect(() => {
        if (!isBasePageTable && !isEmpty(simpleTableColumns)) {
            let format = null;
            let formatUnitPrice = null;

            if (isSelectedCurrencySwitch) { // secondary
                formatUnitPrice = numberFormatter.getSecondaryCurrencyFormats(true);
                format = numberFormatter.getSecondaryCurrencyFormats();
            } else { // primary
                formatUnitPrice = numberFormatter.getPrimaryCurrencyFormats(true);
                format = numberFormatter.getPrimaryCurrencyFormats();
            }

            const currentCurrencyDecimalNumber = format.decimals || UNITS.DECIMAL_NUMBER.CURRENCY;
            const currentCurrencyUnitPriceDecimalNumber = formatUnitPrice.decimals || UNITS.DECIMAL_NUMBER.CURRENCY;

            setInitValues({
                tableData: simpleTableTableData,
                columns: simpleTableColumns,
                tableLineViewType: TABLE.VIEW_TYPE.SIMPLE,
                currentCurrencyDecimalNumber,
                currentCurrencyUnitPriceDecimalNumber,
                globalColumnSortingTypes: simpleTableGlobalColumnSortingTypes,
                globalSearchValue: simpleTableGlobalSearchValue,
                columnSearchValues: simpleTableColumnSearchValues,
                selectedGroup: simpleTableSelectedGroup,
            });
        }
    }, [isBasePageTable, simpleTableTableData, simpleTableColumns, numberFormatter, isSelectedCurrencySwitch, simpleTableGlobalColumnSortingTypes, simpleTableGlobalSearchValue, simpleTableColumnSearchValues, simpleTableSelectedGroup]);

    return initValues;
}
