import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { isEmpty } from 'lodash';

const useStyles = createUseStyles({
    errorContainer: {
        width: 500,
        margin: 'auto',
        textAlign: 'center',
    },
    avLogo: {
        width: 106,
        margin: '30px auto',
    },
    center: {
        padding: 100,
        background: '#FFFFFF',
        boxShadow: '0 2px 33px 0 rgba(0,0,0,0.15)',
    },
    logoContainer: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: 50,
        color: '#252525',
    },
    textContainer: {
        fontSize: 16,
        color: '#252525',
        marginTop: 10,
    },
    secondLineText: {
        marginTop: 15,
        display: 'block',
    },
});

export default function ServerOverload(props) {
    const { isTranslaterError } = props;
    const { t, i18n } = useTranslation();
    const classes = useStyles();

    return (
        <div className={classes.errorContainer}>
            <div className={classes.avLogo}>
                <img src="/styles/img/av_small_logo.png" alt="Logo" width="106" height="38" />
            </div>
            <div className={classes.center}>
                <div className={classes.logoContainer}>
                    <img src="/styles/img/av_logo_symbol.png" alt="Logo" width="100" height="100" />
                    503
                </div>
                <div className={classes.textContainer}>
                    <span>
                        {!isEmpty(i18n)
                            ? t('errorPage.serverOverload', 'The server is temporarily unavailable due to overload or maintenance.') as any
                            : 'The server is temporarily unavailable due to overload or maintenance.'}
                    </span>
                    {isTranslaterError ? <span className={classes.secondLineText}>A szerver átmenetileg nem érhető el túlterhelés vagy karbantartás miatt.</span> : null}
                </div>
            </div>
        </div>
    );
}
